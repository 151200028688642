import styled from "@emotion/styled";
import React, { useEffect } from "react";
import * as Icon from "react-feather";

import { useToast } from "./ToastContext";

const ToastWrapper = styled("li")<{ background: string }>`
  background: ${(props) => props.background};
  border-radius: 8px;
  max-width: 560px;
  min-width: 300px;
  margin: 0 auto;
  padding: 0.65rem;
  display: flex;
  align-items: center;
  color: ${(props) => props.theme.color.default};

  svg {
    margin-right: 0.75rem;
  }
`;

const toast: { [key: string]: { icon: React.ReactNode; color: string } } = {
  info: {
    icon: <Icon.Info />,
    color: "#3182ce",
  },
  warning: {
    icon: <Icon.XCircle />,
    color: "#dd6b20",
  },
  success: {
    icon: <Icon.CheckCircle />,
    color: "#38a169",
  },
  error: {
    icon: <Icon.XCircle />,
    color: "#e53e3e",
  },
};

const Toast: React.FC<ToastType> = ({
  id,
  variant = "info",
  isClosable = false,
  title,
  description,
}) => {
  const helpers = useToast();

  useEffect(() => {
    const timeId = setTimeout(() => {
      helpers?.removeToast(id);
    }, 6000);
    return () => {
      clearTimeout(timeId);
    };
  }, [helpers, id]);

  return (
    <ToastWrapper background={toast[variant]?.color}>
      {toast[variant]?.icon}
      {description}
    </ToastWrapper>
  );
};

export default Toast;
