import styled from "@emotion/styled";
import { ReactChild } from "react";
import Table from "../Table";

export const TableWrapper = styled.div`
  margin: 0.75rem 0 0.5rem 0.5rem;
  width: 95%;
  height: 70vh;
  overflow-x: scroll;

  .name {
    color: ${(props) => props.theme.color.dark};
  }

  td {
    padding: 0.75rem 0.75rem 0.75rem 0;
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;

    &:first-of-type {
      border-left: 0 !important;
      border-top-left-radius: 0 !important;
      border-bottom-left-radius: 0 !important;
      padding-left: 0 !important;
    }

    &:last-of-type {
      border-right: 0 !important;
      border-top-right-radius: 0 !important;
      border-bottom-right-radius: 0 !important;
      padding-right: 0.25rem !important;
    }
  }

  thead td {
    padding: 0 0.75rem 0 0;
  }

  tbody {
    tr {
      td {
        border-bottom: 0;
        padding-top: 1.25rem;
      }
      &:last-of-type {
        td {
          border-bottom: 1px solid ${(props) => props.theme.color.border};
          padding-bottom: 1.25rem;
        }
      }
    }
  }

  &.bulk-payment-table {
    tbody {
      tr {
        td {
          padding-top: 0.625rem;
        }
        &:last-of-type {
          td {
            padding-bottom: 0.625rem;
          }
        }
      }
    }
  }
`;

export const SetupTable: React.FC<{
  children: ReactChild;
  columnNames: string[];
}> = ({ children, columnNames }) => {
  return (
    <Table>
      <Table.Header key={"thead"}>
        <tr>
          {columnNames.map((columnName) => (
            <Table.HeaderCell key={`setup-table-col${columnName}`}>
              {columnName}
            </Table.HeaderCell>
          ))}
        </tr>
      </Table.Header>
      <Table.Body>{children}</Table.Body>
    </Table>
  );
};
