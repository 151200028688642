import React from "react";
import styled from "@emotion/styled";
import { FieldProps } from "formik";

import Input, { InputError } from "../inputs/Input";

interface CheckboxFormFieldProps {
  label?: string;
}

export const CheckboxWrapper = styled("div")`
  padding: 1px;
  // border: solid 1px ${(props) => props.theme.color.blue.default};
  border-radius: 4px;

  label {
    display: inline-flex;
    align-items: center;
    font-size: 0.85rem;
  }

  span {
    margin-left: 0.5rem;
    color: ${(props) => props.theme.color.grey};
    font-size: 0.9rem;
  }

  // margin-right: 3rem;
`;

const CheckboxFormField: React.FC<CheckboxFormFieldProps & FieldProps> = ({
  field,
  form: { touched, errors },
  label,
  ...props
}) => {
  return (
    <CheckboxWrapper>
      <label htmlFor={field.name}>
        <Input type='checkbox' id={field.name} {...field} {...props} />
        {label && <span>{label}</span>}
      </label>

      {touched[field.name] && errors[field.name] && <InputError />}
    </CheckboxWrapper>
  );
};

export default CheckboxFormField;
