import styled from "@emotion/styled";
import * as React from "react";
import { HTMLAttributes } from "react";

const TableWrapper = styled("table")`
  display: table;
  border-collapse: separate;
  width: 100%;

  border-spacing: 0 0.5rem;

  // @media (max-width: 480px) {
  //   border-spacing: 0 0.75rem;
  // }
`;

const Row = styled("tr")`
  display: table-row;
`;

const Cell = styled("td")<{ collapsing?: boolean; compact?: boolean }>`
  -webkit-box-align: center;
  align-items: center;
  position: relative;
  box-sizing: border-box;
  min-height: 40px;
  display: table-cell;
  vertical-align: middle;
  ${(props) =>
    props.collapsing
      ? `
    width: 1px;
    white-space: nowrap;
  `
      : ""}
`;

// const TableHeaderRow = styled(Row)`
//   td:first-of-type {
//     border-top-left-radius: 0;
//     border-bottom-left-radius: 0;
//   }
//   td:last-child {
//     border-top-right-radius: 0;
//     border-bottom-right-radius: 0;
//   }
// `;

const TableHeaderCell = styled(Cell)`
  color: ${(props) => props.theme.color.primary};
  // font-weight: bold;
  padding: ${(props) => (props.compact ? "10px 1.35rem 0" : "10px 1.625rem 0")};
  line-height: 1.2;
  white-space: nowrap;
  @media (max-width: 480px) {
    padding: 6px 12px;
  }
`;

interface ITableRowProps {
  active?: boolean;
  canceled?: boolean;
  disabled?: boolean;
}

// @ts-ignore
const TableRow = styled(Row)<ITableRowProps>`
  text-decoration: none;
  border-spacing: 1rem;
  background: ${(props) => props.theme.color.default};
  padding: 9px 20px;

  :hover {
    border-color: ${(props) => props.theme.color.blue.default};
    background: ${(props) => props.theme.color.light};
  }

  td:first-of-type {
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
    border-left: solid 1px ${(props) => props.theme.color.border};
    padding-left: 1rem;
  }

  td:last-child {
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
    border-right: solid 1px ${(props) => props.theme.color.border};
    padding-right: 1rem;
  }

  td {
    background: ${(props) => props.theme.color.default};
  }
`;

const TableCell = styled(Cell)`
  font-size: 0.9em;
  padding: 10px 1.625rem;
  line-height: 1.2;
  white-space: nowrap;
  border-bottom: solid 1px ${(props) => props.theme.color.border};
  border-top: solid 1px ${(props) => props.theme.color.border};

  &.action {
    > * {
      visibility: hidden;
    }
  }
  @media (min-width: 320px) and (max-width: 480px) {
    padding: 8px 12px;
  }
`;

const TableHead = styled("thead")``;
//   > tr {
//     > td {
//       background: transparent;
//     }
//   }
// `;

const TableBody = styled("tbody")``;

const TableFooter = styled("tfoot")``;

export default class Table extends React.Component<
  HTMLAttributes<HTMLDivElement>
> {
  public static Header = TableHead;

  // public static HeaderRow = TableHeaderRow;

  public static HeaderCell = TableHeaderCell;

  public static Body = TableBody;

  public static Row = TableRow;

  public static Cell = TableCell;

  public static Footer = TableFooter;

  public render() {
    const { children } = this.props;
    return <TableWrapper>{children}</TableWrapper>;
  }
}
