import React from "react";
import { useDropzone } from "react-dropzone";
import * as Icon from "react-feather";
import styled from "@emotion/styled";
import { Typography } from "components/ui";

const getColor = (props: {
  isDragAccept?: boolean;
  isDragReject?: boolean;
  isFocused?: boolean;
  theme: any;
}) => {
  if (props.isDragAccept) {
    return props.theme.color.primary;
  }
  if (props.isDragReject) {
    return "#ff1744";
  }
  if (props.isFocused) {
    return props.theme.color.dark;
  }
  return "#2364ec26";
};

const FileUploadWrapper = styled.div<{
  isDragAccept?: boolean;
  isDragReject?: boolean;
  isFocused?: boolean;
}>`
  border-color: ${(props) => getColor(props)};
  border-style: ${(props) => (props.isDragAccept ? "solid" : "dashed")};
  background-color: ${(props) => props.theme.color.light};
  outline: none;
  padding: 1.5rem 1rem;
  text-align: center;
  transition: border 0.24s ease-in-out;
  width: 95%;
  width: -webkit-fill-available;

  svg {
    color: ${(props) => props.theme.color.grey};
  }

  b {
    cursor: pointer;
  }
`;

const FileUpload: React.FC<{
  handleUpload: Function;
  FieldDescription: any;
  fileTypes: string;
  maxFiles?: number;
  maxSize?: number;
}> = ({
  handleUpload,
  FieldDescription,
  fileTypes,
  maxFiles = 0,
  maxSize,
  ...props
}) => {
  const {
    getRootProps,
    getInputProps,
    open,
    isDragAccept,
    isFocused,
    isDragReject,
  } = useDropzone({
    // @ts-ignore
    accept: fileTypes,
    maxFiles,
    maxSize,
    onDrop: (files) => handleUpload(files),
    ...props,
  });

  return (
    <FileUploadWrapper
      {...getRootProps({
        className: "dropzone",
        isDragAccept,
        isFocused,
        isDragReject,
      })}
    >
      <input {...getInputProps()} />
      <Icon.UploadCloud />
      <FieldDescription open={open} />
    </FileUploadWrapper>
  );
};

export default FileUpload;
