import React from "react";
import styled from "@emotion/styled";
import { FieldProps } from "formik";

import Input, { InputError } from "../inputs/Input";

interface RadioFormFieldProps {
  label?: string;
}

export const RadioWrapper = styled("div")`
  padding: 1px;
  // border: solid 1px ${(props) => props.theme.color.blue.default};
  border-radius: 4px;

  label {
    display: inline-flex;
    align-items: center;
  }

  span {
    margin-left: 0.5rem;
    color: ${(props) => props.theme.color.grey};
    font-size: 0.9rem;
  }
`;

const RadioFormField: React.FC<RadioFormFieldProps & FieldProps> = ({
  field,
  form: { touched, errors },
  label,
  ...props
}) => {
  return (
    <RadioWrapper>
      <label htmlFor={field.name}>
        <Input type='radio' id={field.name} {...field} {...props} />
        {label && <span>{label}</span>}
      </label>

      {touched[field.name] && errors[field.name] && <InputError />}
    </RadioWrapper>
  );
};

export default RadioFormField;
