import React, { useEffect } from "react";
import ReactDOM from "react-dom";
import styled from "@emotion/styled";
import * as Icon from "react-feather";

import FlexWrapper from "./FlexWrapper";
import Typography from "./Typography";

const DialogWrapper = styled("div")<{ show: boolean }>`
  width: 100%;
  height: 100%;
  position: fixed;
  right: 0;
  left: 0;
  top: 0;
  z-index: 10;
  background: rgba(0, 0, 0, 0.15);
  transform: translateY(${(props) => (props.show ? "0" : "-100%")});
  opacity: ${(props) => (props.show ? "1" : "0")};
  padding: 1.5rem;
  backdrop-filter: blur(3px);
  box-sizing: border-box;
`;

const DialogContainer = styled("div")`
  width: 90%;
  max-width: 460px;
  border-radius: 8px;
  position: relative;
  background: ${(props) => props.theme.color.default};
  margin: 6rem auto 0;

  > .content {
    padding: 0.75rem 1.25rem;
  }
`;

const DialogHeader = styled(FlexWrapper)`
  color: ${(props) => props.theme.color.dark};
  margin-bottom: 0.25rem;
  padding: 0.75rem 1.25rem;
  border-bottom: solid 1px ${(props) => props.theme.color.border};

  h4 {
    padding: 0;
  }

  svg {
    cursor: pointer;
    height: 16px;
    width: 16px;
    color: ${(props) => props.theme.color.grey};
  }
`;

const node = document.getElementById("modal-root");

interface DialogProps {
  show: boolean;
  header: string;
  onClose: () => void;
  children: any;
}

const containerEl: HTMLDivElement = document.createElement("div");

const Dialog: React.FC<DialogProps> = ({ header, children, show, onClose }) => {
  useEffect(() => {
    containerEl.id = `modal-${header}`;
    node?.appendChild(containerEl);
    return () => {
      containerEl.remove();
      // node?.removeChild(containerEl);
    };
  }, []);

  return ReactDOM.createPortal(
    <DialogWrapper show={show}>
      <DialogContainer className='container'>
        <DialogHeader justify='space-between'>
          <Typography component='h4'>{header}</Typography>

          <Icon.X onClick={onClose} strokeWidth={2.5} />
        </DialogHeader>
        <div className='content'>{children}</div>
      </DialogContainer>
    </DialogWrapper>,
    containerEl
  );
};

export default Dialog;
