import React from "react";
import * as Icon from "react-feather";
import { Link } from "react-router-dom";
import styled from "@emotion/styled";

import Avatar from "components/ui/Avatar";
import { Dropdown, DropdownItem, ItemDivider } from "components/ui";
import useAuth from "auth/useAuth";

const AuthAvatarWrapper = styled("div")`
  position: relative;

  ul > li {
    svg {
      background: none;
    }
  }
`;

const AuthAvatarContainer = styled("div")`
  background: ${(props) => props.theme.color.default};
  color: ${(props) => props.theme.color.dark};
  border: solid 1px ${(props) => props.theme.color.border};
  border-radius: 8px;
  padding: 4px 6px;
  padding-left: 10px;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  font-size: 1rem;
  max-height: 38px;
  cursor: pointer;
`;

const AuthAvatar: React.FC<{ name: string; icon?: string; alt?: string }> = ({
  name,
  icon,
  alt,
}) => {
  const { logout } = useAuth();

  return (
    <AuthAvatarWrapper>
      <Dropdown
        // onChange={handleOptionClick}
        position='right'
        trigger={
          <AuthAvatarContainer>
            {name}&nbsp;&nbsp;
            <Avatar src={icon} alt={alt} size={30} />
          </AuthAvatarContainer>
        }
      >
        <DropdownItem>
          <Link to='/account/profile'>
            <span className='icon'>
              <Icon.User strokeWidth={3} />
            </span>
            Profile
          </Link>
        </DropdownItem>
        <ItemDivider />
        <DropdownItem onClick={logout}>Logout</DropdownItem>
      </Dropdown>
    </AuthAvatarWrapper>
  );
};

export default AuthAvatar;
