import Table from "components/ui/Table";
import { TableWrapper } from "components/ui/tabs/setup-tab-shared";
import { ReactChild } from "react";

const BulkPaymentTable: React.FC<{
  children: ReactChild;
  columnNames: string[];
}> = ({ children, columnNames }) => {
  return (
    <TableWrapper className='bulk-payment-table'>
      <Table>
        <Table.Header key={"thead"}>
          <tr>
            {columnNames.map((columnName) => (
              <Table.HeaderCell
                key={`bulk-payment-table-col${columnName}`}
                style={{ opacity: columnName === "Old balance" ? 0.5 : 1 }}
              >
                {columnName}
              </Table.HeaderCell>
            ))}
          </tr>
        </Table.Header>
        <Table.Body>{children}</Table.Body>
      </Table>
    </TableWrapper>
  );
};

export default BulkPaymentTable;
