import { FieldProps } from "formik";
import React from "react";

import {
  InputError,
  InputLabel,
  TextAreaInput,
  TextAreaInputProps,
} from "../inputs/Input";
import FormFieldWrapper from "./FormFieldWrapper";

interface TextareaFormFieldProps extends TextAreaInputProps {
  label?: string;
}

const TextareaFormField: React.FC<TextareaFormFieldProps & FieldProps> = ({
  field,
  form: { touched, errors },
  label,
  ...props
}) => {
  return (
    <FormFieldWrapper>
      {label && <InputLabel>{label}</InputLabel>}
      <TextAreaInput {...field} {...props} />
      {touched[field.name] && errors[field.name] && <InputError />}
    </FormFieldWrapper>
  );
};

export default TextareaFormField;
