import React, { useEffect, useState, useRef } from "react";
import Calendar from "@atlaskit/calendar";
import styled from "@emotion/styled";
import { format } from "date-fns";
import * as Icon from "react-feather";

import Button from "./Button";

const DatePickerWrapper = styled("div")`
  position: relative;
`;

const CalendarWrapper = styled("div")<{ show: boolean }>`
  position: absolute;
  left: 0;
  top: 100%;
  margin-top: 5px;
  border: solid 1px ${(props) => props.theme.color.border};
  border-radius: 8px;
  display: ${(props) => (props.show ? "initial" : "none")};

  > div > div {
    border-radius: 8px;
  }
`;

const DatePicker: React.FC<{
  onChange: (fromDate: string, toDate: string) => void;
}> = ({ onChange }) => {
  const pickerEl = useRef(null);

  const [selected, setSelected] = useState<{ start: string[]; end: string[] }>({
    start: ["2021-07-11", "2021-07-12", "2021-07-13"],
    end: [new Date().toDateString()],
  });

  const [show, setShow] = useState<boolean>(false);

  const [activeDate, setActiveDate] = useState<"start" | "end">("start");

  useEffect(() => {
    const handleClick = (e: unknown) => {
      // @ts-ignore
      if (pickerEl?.current?.contains(e.target)) {
        setShow(true);
      } else {
        setShow(false);
      }
    };

    document.addEventListener("mousedown", handleClick, false);
    return () => {
      document.removeEventListener("mousedown", handleClick, false);
    };
  }, []);

  const handleSelect = (selectedDate: { iso: string }) => {
    setSelected({ ...selected, [activeDate]: [selectedDate.iso] });
    if (activeDate === "start") {
      setActiveDate("end");
    } else {
      setActiveDate("start");
      onChange(selected.start[0], selected.end[0]);
    }
  };

  return (
    <DatePickerWrapper ref={pickerEl}>
      <Button color='dark' onClick={() => setShow(true)}>
        <Icon.Calendar />
        {format(new Date(selected.start[0]), "d MMM yyyy")} -{" "}
        {format(new Date(selected.end[0]), "d MMM yyyy")}
      </Button>
      <CalendarWrapper show={show}>
        <Calendar onSelect={handleSelect} selected={selected[activeDate]} />
      </CalendarWrapper>
    </DatePickerWrapper>
  );
};

export default DatePicker;
