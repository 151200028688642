import styled from "@emotion/styled";
import { FieldProps } from "formik";
import React from "react";

import Input, { InputError, InputLabel } from "../inputs/Input";
import FormFieldWrapper from "./FormFieldWrapper";

const FormFieldIcon = styled("span")`
  color: ${(props) => props.theme.color.grey};
  opacity: 0.4;
  position: absolute;
  right: 10px;
  bottom: 6px;

  > svg {
    height: 16px;
    width: 16px;
  }
`;

interface InputFormFieldProps {
  type: "text" | "email" | "number" | "url";
  label?: string;
  icon?: React.ReactNode;
}

const InputFormField: React.FC<InputFormFieldProps & FieldProps> = ({
  type = "text",
  field,
  form: { touched, errors },
  label,
  icon,
  ...props
}) => {
  return (
    <FormFieldWrapper>
      {label && <InputLabel>{label}</InputLabel>}
      <Input type={type} {...field} {...props} />
      {icon && <FormFieldIcon>{icon}</FormFieldIcon>}
      {touched[field.name] && errors[field.name] && <InputError />}
    </FormFieldWrapper>
  );
};

export default InputFormField;
