import { jwtDecode } from "jwt-decode";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

type AccessToken = {
  partyId?: string | null;
  accessToken?: string | null;
};

type AccessTokenPayload = {
  id?: string;
  iat?: string;
  exp?: string;
};

type UseAuthHook = () => {
  isAuthenticated: boolean;
  partyId: string;

  login: (payload: AccessToken, nextLocation?: string) => void;
  logout: () => void;
  forceAuthentication: (next?: string) => void;
};

export const storageKey = "access_token";

const useAuth: UseAuthHook = () => {
  let navigate = useNavigate();

  const [authState, setAuthState] = useState<
    (AccessTokenPayload & AccessToken) | null
  >(null);

  useEffect(() => {
    if (window) {
      let accessToken = localStorage.getItem(storageKey);

      if (accessToken) {
        const token: AccessToken | null = JSON.parse(accessToken);
        refreshState(token);
      }
    }
  }, []);

  const refreshState = (token: AccessToken | null) => {
    const payload: AccessTokenPayload | null = decryptToken(token?.accessToken);
    setAuthState({
      partyId: token?.partyId || "",
      accessToken: token?.accessToken,
      id: payload?.id,
      iat: payload?.iat,
      exp: payload?.exp,
    });
  };

  const login = (token: AccessToken, nextLocation?: string) => {
    localStorage.setItem(storageKey, JSON.stringify(token));
    refreshState(token);
    document.location.href = nextLocation || "/";
    // navigate.push("/") //TODO: Investigate issue with token not being picked
  };

  const logout = () => {
    localStorage.removeItem(storageKey);
    document.location.href = "/login";
  };

  const decryptToken = (
    accessToken?: string | null
  ): AccessTokenPayload | null => {
    if (accessToken) return jwtDecode(accessToken);

    return null;
  };

  const isAuthenticated = (): boolean => {
    const token = localStorage.getItem(storageKey);

    return Boolean(token);
  };

  const forceAuthentication = (next?: string) => {
    localStorage.removeItem(storageKey);
    if (next && next !== "/login") {
      navigate(`/login?next=${next}`, { replace: true });
    } else {
      navigate("/login", { replace: true });
    }
  };

  return {
    isAuthenticated: isAuthenticated(),
    partyId: authState?.partyId || "",
    logout,
    login,
    forceAuthentication,
  };
};

export default useAuth;
