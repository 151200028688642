import styled from "@emotion/styled";
import ErrorBoundary from "components/common/ErrorBoundary";
import React, { useEffect } from "react";
import ReactDOM from "react-dom";

const DrawerWrapper = styled("div")<{ open: boolean; w: string }>`
  width: ${(props) => props.w};
  height: calc(100% - 65px);
  border-left: solid 1px ${(props) => props.theme.color.border};
  position: fixed;
  background: #fff;
  z-index: 2;
  top: 65px;
  right: 0;
  display: ${(props) => (props.open ? "block" : "none")};
`;

export const FormSectionWrapper = styled.div<{ show: boolean }>`
  display: ${(props) => (props.show ? "block" : "none")};
`;

export const DrawerFormButtonsWrapper = styled.div`
  bottom: 1rem;
  position: absolute;
  width: 95%;

  div:last-of-type {
    position: absolute;
    right: 0;
  }
`;

const node = document.getElementById("drawer-root");

const Drawer: React.FC<{
  open: boolean;
  onClose?: () => void;
  width?: string;
  children: any;
}> = ({ open, onClose, children, width = "380px" }) => {
  const containerEl: HTMLDivElement = document.createElement("div");

  useEffect(() => {
    node?.appendChild(containerEl);

    return () => {
      node?.removeChild(containerEl);
    };
  }, [containerEl]);

  return ReactDOM.createPortal(
    <DrawerWrapper w={width} open={open}>
      <ErrorBoundary>{children}</ErrorBoundary>
    </DrawerWrapper>,
    containerEl
  );
};

export default Drawer;
