import React from "react";
import { ErrorMessage, Field } from "formik";
import * as Icon from "react-feather";

import { validateField } from "components/ui/form/validate";
import {
  CheckboxFormField,
  DateTimeFormField,
  InputFormField,
  RadioFormField,
  SelectFormField,
  TextareaFormField,
} from "components/ui/form";
import { InputError, InputLabel } from "../inputs/Input";
import DateFormField from "../fields/DateFormField";

const getField = ({
  fieldId,
  typesMap,
  typeId,
  setFieldValue,
  field_name,
  label,
  placeholder,
  options,
  step,
  _units,
  default_value,
  min,
  max,
  validation_regex,
  is_required,
  props,
  suffix = "",
}: any) => {
  const map: { [key: string]: any } = {
    FORM_FIELD: (
      <Field
        component={InputFormField}
        icon={undefined}
        type='text'
        label={label}
        name={`${field_name}${suffix}`}
        placeholder={placeholder}
        defaultValue={default_value}
        validate={validateField({
          isRequired: is_required,
          errorMessageText: `Invalid ${label.toLowerCase()}`,
          regexString: validation_regex,
        })}
        {...props}
      />
    ),
    EMAIL_FORM_FIELD: (
      <Field
        component={InputFormField}
        icon={<Icon.Mail />}
        type='email'
        label={label}
        name={`${field_name}${suffix}`}
        placeholder={placeholder}
        defaultValue={default_value}
        validate={validateField({
          isRequired: is_required,
          errorMessageText: `Invalid ${label.toLowerCase()}`,
          regexString: validation_regex,
        })}
        {...props}
      />
    ),
    PHONE_NUMBER_FORM_FIELD: (
      <Field
        component={InputFormField}
        icon={<Icon.Phone />}
        type='text'
        label={label}
        name={`${field_name}${suffix}`}
        placeholder={placeholder}
        validate={validateField({
          isRequired: is_required,
          errorMessageText: `Invalid ${label.toLowerCase()}`,
          regexString: validation_regex,
        })}
        {...props}
      />
    ),
    PERCENT_FORM_FIELD: (
      <Field
        component={InputFormField}
        icon={<Icon.Percent />}
        type='numeric'
        label={label}
        name={`${field_name}${suffix}`}
        placeholder={placeholder}
        defaultValue={default_value}
        step={step}
        min={0}
        max={100}
        validate={validateField({
          isRequired: is_required,
          errorMessageText: `Invalid ${label.toLowerCase()}`,
          regexString: "^([0-9]|[1-9][0-9]|100)$",
        })}
        {...props}
      />
    ),
    CURRENCY_FORM_FIELD: (
      <Field
        component={InputFormField}
        icon={null}
        type='number'
        label={label}
        name={`${field_name}${suffix}`}
        placeholder={placeholder}
        defaultValue={default_value}
        step={step}
        min={0}
        max={100}
        validate={validateField({
          isRequired: is_required,
          errorMessageText: `Invalid ${label.toLowerCase()}`,
          regexString: validation_regex,
        })}
        {...props}
      />
    ),
    LOCATION_FORM_FIELD: <></>,
    DROPDOWN_FORM_FIELD: (
      <Field
        component={SelectFormField}
        label={label}
        name={`${field_name}${suffix}`}
        placeholder={placeholder}
        defaultValue={default_value}
        options={options}
        validate={validateField({
          isRequired: is_required,
          errorMessageText: `Invalid ${label.toLowerCase()} selection`,
          regexString: validation_regex,
        })}
        {...props}
      />
    ),
    TEXTAREA_FORM_FIELD: (
      <Field
        component={TextareaFormField}
        icon={undefined}
        type='textarea'
        label={label}
        name={`${field_name}${suffix}`}
        placeholder={placeholder}
        defaultValue={default_value}
        validate={validateField({
          isRequired: is_required,
          errorMessageText: `Invalid ${label.toLowerCase()}`,
          regexString: validation_regex,
        })}
        {...props}
      />
    ),
    NUMERIC_FORM_FIELD: (
      <Field
        component={InputFormField}
        icon={null}
        type='number'
        label={label}
        name={`${field_name}${suffix}`}
        step={step}
        min={min}
        max={max}
        defaultValue={default_value}
        placeholder={placeholder}
        validate={validateField({
          isRequired: is_required,
          errorMessageText: `Invalid ${label.toLowerCase()}`,
          regexString: validation_regex,
        })}
        {...props}
      />
    ),
    PICKLIST_FORM_FIELD:
      options &&
      options.map((option: any, index: number) => (
        <Field
          key={`${field_name}-radio-option-${index + 1}`}
          component={RadioFormField}
          icon={null}
          type='radio'
          label={option.label}
          value={option.value}
          name={`${field_name}${suffix}`}
          validate={validateField({
            isRequired: is_required,
            errorMessageText: `Invalid ${label.toLowerCase()}`,
            regexString: validation_regex,
          })}
          {...props}
        />
      )),
    MULTI_PICKLIST_FORM_FIELD:
      options &&
      options.map((option: any, index: number) => (
        <Field
          key={`${field_name}-checkbox-option-${index + 1}`}
          component={CheckboxFormField}
          icon={null}
          type='checkbox'
          label={option.label}
          value={option.value}
          name={`${field_name}${suffix}`}
          validate={validateField({
            isRequired: is_required,
            errorMessageText: `Invalid ${label.toLowerCase()}`,
            regexString: validation_regex,
          })}
          {...props}
        />
      )),
    UPLOAD_FORM_FIELD: (
      <>
        <InputLabel>{label}</InputLabel>
        <input
          id={`${field_name}${suffix}`}
          name={`${field_name}${suffix}`}
          type='file'
          onChange={(event) => {
            const files = event.target.files;
            // const myFiles = Array.from(files!);
            // setFieldValue(field_name, myFiles);
            setFieldValue(field_name, files![0]);
          }}
          // multiple
        />
        <br />
      </>
    ),
    DATE_FORM_FIELD: (
      <DateFormField
        label={label}
        name={`${field_name}${suffix}`}
        placeholder={placeholder}
        defaultValue={default_value}
        {...props}
        onChange={(value: string) => setFieldValue(field_name, value)}
      />
    ),
    TIME_FORM_FIELD: <></>,
    DATETIME_FORM_FIELD: (
      <DateTimeFormField
        label={label}
        name={`${field_name}${suffix}`}
        placeholder={placeholder}
        defaultValue={default_value}
        {...props}
        onChange={(value: string) => setFieldValue(field_name, value)}
      />
    ),
    HIDDEN_FORM_FIELD: <Field type='hidden' name={`${field_name}${suffix}`} />,
  };

  const fieldType = typesMap[typeId];
  return (
    <React.Fragment key={fieldId}>
      {map[fieldType.value]}
      <InputError
        style={{
          marginTop: "-1em",
          marginBottom: "1em",
        }}
      >
        <ErrorMessage name={`${field_name}${suffix}`} />
      </InputError>
    </React.Fragment>
  );
};

export const renderFormField = (
  field: any,
  props: any,
  typesMap: { [key: string]: any },
  suffix?: string | number
) => {
  return getField({
    fieldId: field.id,
    typesMap,
    typeId: field.type_id,
    setFieldValue: props.setFieldValue,
    ...field,
    props,
    suffix,
  });
};
export default getField;
