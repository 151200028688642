import React, { useState } from "react";
import styled from "@emotion/styled";
import { FieldProps } from "formik";
import Toggle from "react-toggle";
import "react-toggle/style.css";

import { InputError } from "../inputs/Input";

interface ToggleFormFieldProps {
  label?: string;
  size?: "default" | "small";
}

export const ToggleWrapper = styled.div<{ size?: string }>`
  label {
    display: inline-flex;
    align-items: center;
  }

  &.small {
    .react-toggle-track {
      width: 32px;
      height: 17px;
    }

    .react-toggle-thumb {
      height: 15px;
      width: 14px;
    }

    .react-toggle--checked .react-toggle-thumb {
      left: 17px;
    }

    span {
      font-size: 0.75rem;
    }
  }

  span {
    margin-right: 0.5rem;
    color: ${(props) => props.theme.color.grey};
    font-size: 0.9rem;
  }
`;

const ToggleFormField: React.FC<ToggleFormFieldProps & FieldProps> = ({
  field,
  form,
  label,
  ...props
}) => {
  const [checked, setChecked] = useState(false);
  return (
    <ToggleWrapper {...props} className={props.size ? props.size : ""}>
      <label htmlFor={field.name}>
        {label && <span>{label}</span>}
        <Toggle
          icons={false}
          checked={checked || field.value}
          onChange={(e: any) => {
            setChecked(e.target.checked);
            form.setFieldValue(field.name, e.target.checked);
          }}
        />
      </label>
      {form.touched[field.name] && form.errors[field.name] && <InputError />}
    </ToggleWrapper>
  );
};

export default ToggleFormField;
