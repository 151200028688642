import React from "react";
import styled from "@emotion/styled";

import Input from "./Input";

export const CheckboxWrapper = styled("span")`
  padding: 1px;
  border: solid 1px ${(props) => props.theme.color.blue.default};
  display: inline-flex;
  border-radius: 4px;
`;

const Checkbox = () => {
  return (
    <CheckboxWrapper>
      <Input type='checkbox' />
    </CheckboxWrapper>
  );
};

export default Checkbox;
