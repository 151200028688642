import styled from "@emotion/styled";
import React from "react";
import { NavLink } from "react-router-dom";

export const MenuWrapper = styled("ul")`
  list-style: none;
`;

export const MenuItem = styled("li")`
  margin-bottom: 0.5rem;
  :last-of-type {
    margin-bottom: 0;
  }

  a,
  button {
    background-color: transparent;
    border: 0;
    border-radius: 5px;
    color: ${(props) => props.theme.color.grey};
    display: block;
    font-size: 0.95rem;
    padding: 8px 12px;
    text-decoration: none;

    &.active {
      background: rgba(35, 100, 236, 0.15);
      color: ${(props) => props.theme.color.dark};
      font-weight: bold;
      font-size: 0.9rem;
    }

    &.button-active {
      background: ${(props) => props.theme.color.grey}1F;
      color: ${(props) => props.theme.color.grey};
    }

    > svg {
      margin-right: 0.75rem;
      height: 15px;
      width: 15px;
      opacity: 0.66;
      vertical-align: bottom;
    }
  }
`;

export type MenuItemType = {
  key: string;
  label: React.ReactNode;
  to: string;
  icon?: React.ReactNode;
  exact?: boolean;
  isActive?: boolean;
};

interface MenuProps {
  items: Array<MenuItemType>;
  onChange: (item: MenuItemType) => void;
}

const Menu: React.FC<MenuProps> = ({ items = [], onChange }) => {
  return (
    <MenuWrapper>
      {items.map((item) => (
        <MenuItem key={item.key} onClick={() => onChange(item)}>
          <NavLink
            end={item?.exact}
            to={item.to}
            className={({ isActive }) => {
              if (!!item.isActive) {
                return "active";
              }
              if (!isActive) {
                return "";
              }
              return "active";
            }}
          >
            {item.icon}
            {item.label}
          </NavLink>
        </MenuItem>
      ))}
    </MenuWrapper>
  );
};

export default Menu;
