import styled from "@emotion/styled";

const FormFieldWrapper = styled("div")`
  margin-bottom: 1rem;
  position: relative;

  &[type="radio"] {
  }

  &[type="checkbox"] {
    :last-of-type {
      margin-right: 0;
    }
  }
`;

export default FormFieldWrapper;
