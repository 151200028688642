/** @jsxRuntime classic */
/** @jsx jsx */
import React, { useState } from "react";
import * as Icon from "react-feather";
import { jsx, css } from "@emotion/react";

import { Button, Dialog, FlexWrapper, Typography } from "components/ui";
import { GenericSelectFormField } from "components/ui/form";
import { theme } from "theme";

const UpdateStatusDialog: React.FC<{
  show: boolean;
  submitting: boolean;
  currentStatus: { [key: string]: string };
  options: IOption[];
  onClose: () => void;
  onSubmit: (status: IOption) => void;
}> = ({ show, onClose, onSubmit, submitting, options, currentStatus }) => {
  const [active, setActive] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState<IOption>();

  return (
    <React.Fragment>
      <Dialog header='Change Status' show={show} onClose={onClose}>
        <GenericSelectFormField
          name='status'
          label='Status'
          onClick={setSelectedStatus}
          value={selectedStatus}
          setActive={setActive}
          active={active}
          options={options}
        />
        {/* Visual representation of change */}
        {selectedStatus &&
          currentStatus &&
          selectedStatus.label !== currentStatus.name && (
            <FlexWrapper
              direction='row'
              align='center'
              justify='flex-start'
              columnGap={2}
              css={css`
                margin: 1.5rem 0 2rem;
                padding-left: 0.625rem;
                max-width: 75%;
                p {
                  font-size: 0.8rem;
                  margin-bottom: 0.25rem;
                }
                h3 {
                  font-size: 1.125rem;
                }
              `}
            >
              {currentStatus && (
                <FlexWrapper direction='column' align='flex-start'>
                  <FlexWrapper align='flex-start'>
                    <Typography component='p' color='primary'>
                      Existing Status
                    </Typography>
                  </FlexWrapper>
                  <FlexWrapper>
                    <Typography component='h3' color='dark'>
                      {currentStatus.name}
                    </Typography>
                  </FlexWrapper>
                </FlexWrapper>
              )}
              {currentStatus && (
                <FlexWrapper>
                  <Icon.ChevronRight color={theme.color.grey} size={16} />
                </FlexWrapper>
              )}
              <FlexWrapper direction='column' align='flex-start'>
                <FlexWrapper align='flex-start'>
                  <Typography component='p' color='primary'>
                    New Status
                  </Typography>
                </FlexWrapper>
                <FlexWrapper align='flex-start'>
                  <Typography component='h3' color='dark'>
                    {selectedStatus.label}
                  </Typography>
                </FlexWrapper>
              </FlexWrapper>
            </FlexWrapper>
          )}
        <FlexWrapper justify='flex-end'>
          <Button
            color='light'
            contained
            type='button'
            onClick={() => {
              setSelectedStatus(undefined);
              setActive(false);
              onClose();
            }}
            disabled={submitting}
          >
            Cancel
          </Button>
          <Button
            color='primary'
            contained
            type='submit'
            ml={1}
            onClick={() => onSubmit(selectedStatus!)}
            disabled={submitting}
          >
            Confirm
          </Button>
        </FlexWrapper>
      </Dialog>
    </React.Fragment>
  );
};

export default UpdateStatusDialog;
