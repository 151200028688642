import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> &
  { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> &
  { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Map: any;
  NewFormFields: any;
  TemplateData: any;
  TemplateElements: any;
  Time: any;
  _uuid: any;
  bigint: any;
  date: any;
  float8: any;
  jsonObject: any;
  jsonb: any;
  numeric: any;
  timestamp: any;
  timestamptz: any;
  uuid: any;
};

export type AddMfaMethodInput = {
  mfaMethod: Scalars["String"];
};

export type AddMfaMethodOutput = {
  __typename?: "AddMFAMethodOutput";
  message?: Maybe<Scalars["String"]>;
  qrCodeURL?: Maybe<Scalars["String"]>;
};

export type Aggergate = {
  __typename?: "Aggergate";
  totalCount: Scalars["Int"];
};

export enum AllowedPaymentMethod {
  Cash = "CASH",
  Cheque = "CHEQUE",
  CreditCard = "CREDIT_CARD",
  CustomerBalance = "CUSTOMER_BALANCE",
  MpesaB2B = "MPESA_B2B",
  MpesaB2C = "MPESA_B2C",
  MpesaC2B = "MPESA_C2B",
}

export type Balance = {
  __typename?: "Balance";
  credits: Scalars["Int"];
  debits: Scalars["Int"];
};

/** Boolean expression to compare columns of type "Boolean". All fields are combined with logical 'AND'. */
export type Boolean_Comparison_Exp = {
  _eq?: InputMaybe<Scalars["Boolean"]>;
  _gt?: InputMaybe<Scalars["Boolean"]>;
  _gte?: InputMaybe<Scalars["Boolean"]>;
  _in?: InputMaybe<Array<Scalars["Boolean"]>>;
  _is_null?: InputMaybe<Scalars["Boolean"]>;
  _lt?: InputMaybe<Scalars["Boolean"]>;
  _lte?: InputMaybe<Scalars["Boolean"]>;
  _neq?: InputMaybe<Scalars["Boolean"]>;
  _nin?: InputMaybe<Array<Scalars["Boolean"]>>;
};

export type CanMakeProductPaymentInput = {
  partyId: Scalars["uuid"];
  productId: Scalars["uuid"];
};

export type CanMakeProductPaymentOutput = {
  __typename?: "CanMakeProductPaymentOutput";
  balance?: Maybe<Scalars["jsonObject"]>;
  canPayProduct: Scalars["Boolean"];
  product?: Maybe<Products>;
  productId?: Maybe<Scalars["uuid"]>;
};

export type CancelSubscriptionInput = {
  narration: Scalars["String"];
  reason?: InputMaybe<Scalars["String"]>;
  subscriptionId: Scalars["uuid"];
};

export type CancelSubscriptionOutput = {
  __typename?: "CancelSubscriptionOutput";
  message: Scalars["String"];
  taskId?: Maybe<Scalars["uuid"]>;
};

export type ChangePasswordInput = {
  email: Scalars["String"];
  newPassword: Scalars["String"];
  oldPassword: Scalars["String"];
};

export type ChangePasswordOutput = {
  __typename?: "ChangePasswordOutput";
  code?: Maybe<Scalars["String"]>;
  message?: Maybe<Scalars["String"]>;
};

export type CompleteLoginInput = {
  accessToken: Scalars["String"];
  mfaMethod: Scalars["String"];
  otp: Scalars["String"];
};

export type CompleteLoginOutput = {
  __typename?: "CompleteLoginOutput";
  accessToken: Scalars["String"];
  message?: Maybe<Scalars["String"]>;
  partyId?: Maybe<Scalars["String"]>;
  type?: Maybe<Scalars["String"]>;
};

export type CompleteMemberLoginInput = {
  otp: Scalars["String"];
  phoneNumber: Scalars["String"];
};

export type CompleteMemberLoginOutput = {
  __typename?: "CompleteMemberLoginOutput";
  accessToken: Scalars["String"];
  message?: Maybe<Scalars["String"]>;
  partyId?: Maybe<Scalars["String"]>;
  type?: Maybe<Scalars["String"]>;
};

export type CreateAndSubscribeToChannelInput = {
  channelDescription?: InputMaybe<Scalars["String"]>;
  channelName: Scalars["String"];
  deliveryAddresses?: InputMaybe<Scalars["jsonObject"]>;
  deliveryMethods?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  metadata?: InputMaybe<Scalars["jsonObject"]>;
  partyIds: Array<InputMaybe<Scalars["uuid"]>>;
};

export type CreateAndSubscribeToChannelOutput = {
  __typename?: "CreateAndSubscribeToChannelOutput";
  body?: Maybe<Scalars["jsonObject"]>;
  message?: Maybe<Scalars["String"]>;
  type?: Maybe<Scalars["String"]>;
};

export type CreateFromTemplateInput = {
  templateId: Scalars["uuid"];
  templateType: TemplateType;
};

export type CreateFromTemplateOutput = {
  __typename?: "CreateFromTemplateOutput";
  accessToken: Scalars["String"];
};

export type CreateLedgerAccountInput = {
  code: Scalars["String"];
  description: Scalars["String"];
  ledger_id: Scalars["uuid"];
  metadata: Scalars["Map"];
  name: Scalars["String"];
  normal_balance: NormalBalance;
  organisation_id: Scalars["uuid"];
  type_id: Scalars["uuid"];
};

export type CreateLedgerInput = {
  currency?: InputMaybe<Scalars["String"]>;
  description: Scalars["String"];
  metadata: Scalars["Map"];
  name: Scalars["String"];
  organisation_id: Scalars["uuid"];
  party_id: Scalars["uuid"];
  type: Scalars["String"];
};

export type CreatePaymentIntentInput = {
  allowed_payment_methods: Array<AllowedPaymentMethod>;
  amount: Scalars["Int"];
  cancellation_reason?: InputMaybe<PaymentIntentCancellationReason>;
  cancelled_at?: InputMaybe<Scalars["Time"]>;
  currency: Scalars["String"];
  description: Scalars["String"];
  external_id: Scalars["String"];
  metadata: Scalars["Map"];
  next_action: PaymentIntentNextAction;
  organisation_id: Scalars["uuid"];
  party_to_pay_id: Scalars["uuid"];
  recording_party_id: Scalars["uuid"];
  status: PaymentIntentStatus;
};

export type CreatePaymentIntentOutput = {
  __typename?: "CreatePaymentIntentOutput";
  affected_rows: Scalars["Int"];
  returning?: Maybe<IdReturn>;
};

export type CreateTableTriggerInput = {
  schemaName?: InputMaybe<Scalars["String"]>;
  tableName: Scalars["String"];
};

export type CreateTableTriggerOutput = {
  __typename?: "CreateTableTriggerOutput";
  code?: Maybe<Scalars["String"]>;
  message?: Maybe<Scalars["String"]>;
};

export type CreateTemplateInput = {
  templateData: Scalars["TemplateData"];
  templateElements: Scalars["TemplateElements"];
  templateType: TemplateType;
};

export type CreateTemplateOutput = {
  __typename?: "CreateTemplateOutput";
  accessToken: Scalars["String"];
};

export type DuplicateFormInput = {
  formId: Scalars["uuid"];
  newformFields?: InputMaybe<Array<InputMaybe<Scalars["NewFormFields"]>>>;
  parentFormId?: InputMaybe<Scalars["uuid"]>;
};

export type DuplicateFormOutput = {
  __typename?: "DuplicateFormOutput";
  error?: Maybe<Scalars["String"]>;
  formId: Scalars["uuid"];
  message?: Maybe<Scalars["String"]>;
  type?: Maybe<Scalars["String"]>;
};

export type DuplicateTaskInput = {
  assignedBy?: InputMaybe<Scalars["uuid"]>;
  assignedTo: Scalars["uuid"];
  elementsToDuplicate?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  parentTaskId?: InputMaybe<Scalars["uuid"]>;
  taskId: Scalars["uuid"];
  taskName?: InputMaybe<Scalars["uuid"]>;
};

export type DuplicateTaskOutput = {
  __typename?: "DuplicateTaskOutput";
  error?: Maybe<Scalars["String"]>;
  message?: Maybe<Scalars["String"]>;
  taskId?: Maybe<Scalars["uuid"]>;
  type?: Maybe<Scalars["String"]>;
};

export type EditWorkflowSubmissionOutput = {
  __typename?: "EditWorkflowSubmissionOutput";
  message: Scalars["String"];
};

/** Boolean expression to compare columns of type "Float". All fields are combined with logical 'AND'. */
export type Float_Comparison_Exp = {
  _eq?: InputMaybe<Scalars["Float"]>;
  _gt?: InputMaybe<Scalars["Float"]>;
  _gte?: InputMaybe<Scalars["Float"]>;
  _in?: InputMaybe<Array<Scalars["Float"]>>;
  _is_null?: InputMaybe<Scalars["Boolean"]>;
  _lt?: InputMaybe<Scalars["Float"]>;
  _lte?: InputMaybe<Scalars["Float"]>;
  _neq?: InputMaybe<Scalars["Float"]>;
  _nin?: InputMaybe<Array<Scalars["Float"]>>;
};

export type GenerateMagicLoginLinkInput = {
  email: Scalars["String"];
};

export type GenerateMagicLoginLinkOutput = {
  __typename?: "GenerateMagicLoginLinkOutput";
  code?: Maybe<Scalars["String"]>;
  magicLink: Scalars["String"];
};

export type GenerateOtpOutput = {
  __typename?: "GenerateOTPOutput";
  message?: Maybe<Scalars["String"]>;
  otp?: Maybe<Scalars["String"]>;
};

export type GetLastReadMessagesInput = {
  channelIds: Array<InputMaybe<Scalars["uuid"]>>;
};

export type GetLastReadMessagesOutput = {
  __typename?: "GetLastReadMessagesOutput";
  lastReadMessages?: Maybe<Array<Maybe<Scalars["jsonObject"]>>>;
};

export type GetReadMessagesInput = {
  channelId: Scalars["uuid"];
  messageId: Scalars["uuid"];
};

export type GetReadMessagesOutput = {
  __typename?: "GetReadMessagesOutput";
  code?: Maybe<Scalars["String"]>;
  hasReadMessage: Scalars["String"];
  lastReadMessage?: Maybe<Scalars["String"]>;
  type?: Maybe<Scalars["String"]>;
};

export type GetScheduledJobsInput = {
  limit?: InputMaybe<Scalars["Int"]>;
  status: ScheduledJobStatus;
};

export type GetScheduledJobsOutput = {
  __typename?: "GetScheduledJobsOutput";
  jobs: Scalars["jsonObject"];
};

export type IdReturn = {
  __typename?: "IDReturn";
  id?: Maybe<Scalars["uuid"]>;
};

export type InitiateMemberLoginOutput = {
  __typename?: "InitiateMemberLoginOutput";
  message?: Maybe<Scalars["String"]>;
  type?: Maybe<Scalars["String"]>;
};

/** Boolean expression to compare columns of type "Int". All fields are combined with logical 'AND'. */
export type Int_Comparison_Exp = {
  _eq?: InputMaybe<Scalars["Int"]>;
  _gt?: InputMaybe<Scalars["Int"]>;
  _gte?: InputMaybe<Scalars["Int"]>;
  _in?: InputMaybe<Array<Scalars["Int"]>>;
  _is_null?: InputMaybe<Scalars["Boolean"]>;
  _lt?: InputMaybe<Scalars["Int"]>;
  _lte?: InputMaybe<Scalars["Int"]>;
  _neq?: InputMaybe<Scalars["Int"]>;
  _nin?: InputMaybe<Array<Scalars["Int"]>>;
};

export type IntiatePasswordResetInput = {
  email: Scalars["String"];
};

export type IntiatePasswordResetOutput = {
  __typename?: "IntiatePasswordResetOutput";
  message: Scalars["String"];
};

export type Ledger = {
  __typename?: "Ledger";
  created_at: Scalars["Time"];
  currency: Scalars["String"];
  description: Scalars["String"];
  id: Scalars["uuid"];
  ledger_accounts?: Maybe<Array<LedgerAccount>>;
  metadata: Scalars["Map"];
  name: Scalars["String"];
  organisation_id: Scalars["uuid"];
  party_id: Scalars["uuid"];
  type: Type;
  type_id: Scalars["uuid"];
  updated_at: Scalars["Time"];
  version: Scalars["Int"];
};

export type LedgerAccount = {
  __typename?: "LedgerAccount";
  code: Scalars["String"];
  created_at: Scalars["Time"];
  description: Scalars["String"];
  id: Scalars["uuid"];
  ledger: Ledger;
  ledger_id: Scalars["uuid"];
  metadata: Scalars["Map"];
  name: Scalars["String"];
  normal_balance: Scalars["String"];
  type: Type;
  type_id: Scalars["uuid"];
  updated_at: Scalars["Time"];
  version: Scalars["Int"];
};

export type LedgerAccountMutationOutput = {
  __typename?: "LedgerAccountMutationOutput";
  affected_rows: Scalars["Int"];
  returning: IdReturn;
};

export type LedgerAccountReport = {
  __typename?: "LedgerAccountReport";
  closing_balance: Balance;
  credits: Scalars["Int"];
  debits: Scalars["Int"];
  id: Scalars["uuid"];
  opening_balance: Balance;
};

export type LedgerEntry = {
  __typename?: "LedgerEntry";
  amount: Scalars["Int"];
  created_at: Scalars["Time"];
  entry_type: Scalars["String"];
  id: Scalars["uuid"];
  is_voided: Scalars["Boolean"];
  ledger: Ledger;
  ledger_account: LedgerAccount;
  ledger_account_id: Scalars["uuid"];
  ledger_id: Scalars["uuid"];
  metadata: Scalars["Map"];
  transaction: Transaction;
  transaction_id: Scalars["uuid"];
  updated_at: Scalars["Time"];
};

export type LedgerMutationOutput = {
  __typename?: "LedgerMutationOutput";
  affected_rows: Scalars["Int"];
  returning: IdReturn;
};

export type LoanProductSummaryOutput = {
  __typename?: "LoanProductSummaryOutput";
  amountInDefault?: Maybe<Scalars["Int"]>;
  currentLoans?: Maybe<Scalars["Int"]>;
  disbursedAmount?: Maybe<Scalars["Int"]>;
  disbursedCount?: Maybe<Scalars["Int"]>;
  floatBalance?: Maybe<Scalars["Int"]>;
};

export type LoginInput = {
  email: Scalars["String"];
  password: Scalars["String"];
  rememberMe?: InputMaybe<Scalars["Boolean"]>;
};

export type LoginOutput = {
  __typename?: "LoginOutput";
  accessToken: Scalars["String"];
  availableMFAMethods?: Maybe<Array<Maybe<Scalars["String"]>>>;
  message?: Maybe<Scalars["String"]>;
  partyId?: Maybe<Scalars["String"]>;
  requiresMFA: Scalars["Boolean"];
  type?: Maybe<Scalars["String"]>;
};

export type MagicLinkLoginInput = {
  code: Scalars["String"];
};

export type MagicLinkLoginOutput = {
  __typename?: "MagicLinkLoginOutput";
  accessToken: Scalars["String"];
  id?: Maybe<Scalars["String"]>;
  message?: Maybe<Scalars["String"]>;
  type?: Maybe<Scalars["String"]>;
};

export type MakeInvoicePaymentInput = {
  amount: Scalars["Int"];
  effectiveDate?: InputMaybe<Scalars["String"]>;
  invoiceId: Scalars["String"];
  memo?: InputMaybe<Scalars["String"]>;
  partyId: Scalars["uuid"];
  paymentAllocations: Array<InputMaybe<Scalars["jsonObject"]>>;
  paymentDate: Scalars["String"];
  productId: Scalars["uuid"];
  subscriptionId: Scalars["uuid"];
};

export type MakeInvoicePaymentOutput = {
  __typename?: "MakeInvoicePaymentOutput";
  message: Scalars["String"];
  subscriptionPaymentId: Scalars["uuid"];
};

export type MakeSubscriptionPaymentInput = {
  amount: Scalars["Int"];
  effectiveDate?: InputMaybe<Scalars["String"]>;
  invoiceId: Scalars["String"];
  memo?: InputMaybe<Scalars["String"]>;
  partyId: Scalars["uuid"];
  postedAt?: InputMaybe<Scalars["String"]>;
  productId: Scalars["uuid"];
  subscriptionId: Scalars["uuid"];
};

export type MakeSubscriptionPaymentOutput = {
  __typename?: "MakeSubscriptionPaymentOutput";
  message: Scalars["String"];
  subscriptionPaymentId: Scalars["uuid"];
};

export type MarkMessageAsReadInput = {
  channelId: Scalars["uuid"];
  messageId: Scalars["uuid"];
};

export type MarkMessageAsReadOutput = {
  __typename?: "MarkMessageAsReadOutput";
  code?: Maybe<Scalars["String"]>;
  message?: Maybe<Scalars["String"]>;
  type?: Maybe<Scalars["String"]>;
};

export type MeOutput = {
  __typename?: "MeOutput";
  avatar?: Maybe<Scalars["String"]>;
  created_at?: Maybe<Scalars["Boolean"]>;
  email?: Maybe<Scalars["String"]>;
  id: Scalars["uuid"];
  locale?: Maybe<Scalars["String"]>;
  location?: Maybe<Scalars["String"]>;
  metadata?: Maybe<Scalars["jsonObject"]>;
  name?: Maybe<Scalars["String"]>;
  nickname?: Maybe<Scalars["String"]>;
  phone_number?: Maybe<Scalars["String"]>;
};

export enum MessageDeliveryMethod {
  Email = "EMAIL",
  Inapp = "INAPP",
  Sms = "SMS",
}

export enum MethodStatus {
  Active = "ACTIVE",
  Disabled = "DISABLED",
  Inactive = "INACTIVE",
}

export type MoMData = {
  __typename?: "MoMData";
  current_month_value: Scalars["Int"];
  month: Scalars["String"];
  percentage_growth: Scalars["String"];
  previous_month_value: Scalars["Int"];
};

export type MoMGrowthReport = {
  __typename?: "MoMGrowthReport";
  report_data: Array<MoMData>;
  report_name: Scalars["String"];
};

export enum NormalBalance {
  Credit = "credit",
  Debit = "debit",
}

export type PageInfo = {
  __typename?: "PageInfo";
  endCursor: Scalars["String"];
  hasNextPage: Scalars["Boolean"];
};

export type Payment = {
  __typename?: "Payment";
  amount: Scalars["Int"];
  attachments: Array<Scalars["String"]>;
  charges: Scalars["Int"];
  created_at: Scalars["Time"];
  currency: Scalars["String"];
  description: Scalars["String"];
  effective_date: Scalars["Time"];
  external_reference: Scalars["String"];
  id: Scalars["uuid"];
  ledger: Ledger;
  ledger_id: Scalars["uuid"];
  metadata: Scalars["Map"];
  organisation_id: Scalars["uuid"];
  originating_ledger_account: LedgerAccount;
  originating_ledger_account_id: Scalars["uuid"];
  originating_party_id: Scalars["uuid"];
  payment_intent_id: Scalars["uuid"];
  payment_sub_type: Type;
  payment_sub_type_id: Scalars["uuid"];
  payment_type: Type;
  payment_type_id: Scalars["uuid"];
  receiving_ledger_account: LedgerAccount;
  receiving_ledger_account_id: Scalars["uuid"];
  receiving_party_id: Scalars["uuid"];
  recording_party_id: Scalars["uuid"];
  status: Status;
  status_id: Scalars["uuid"];
  transaction: Transaction;
  transaction_id: Scalars["uuid"];
  transaction_type: Scalars["String"];
  updated_at: Scalars["Time"];
  version: Scalars["Int"];
};

export type PaymentIntent = {
  __typename?: "PaymentIntent";
  allowed_payment_methods: Array<Scalars["String"]>;
  amount: Scalars["Int"];
  cancellation_reason?: Maybe<Scalars["String"]>;
  cancelled_at?: Maybe<Scalars["Time"]>;
  created_at: Scalars["Time"];
  currency: Scalars["String"];
  description: Scalars["String"];
  external_id: Scalars["String"];
  id: Scalars["uuid"];
  metadata: Scalars["Map"];
  next_action: PaymentIntentNextAction;
  organisation_id: Scalars["uuid"];
  party_to_pay_id: Scalars["uuid"];
  recording_party_id: Scalars["uuid"];
  status: Status;
  status_id: Scalars["uuid"];
  updated_at: Scalars["Time"];
};

export enum PaymentIntentCancellationReason {
  Abandoned = "ABANDONED",
  Automatic = "AUTOMATIC",
  Duplicate = "DUPLICATE",
  Failed = "FAILED",
  Fradulent = "FRADULENT",
  RequestedByCustomer = "REQUESTED_BY_CUSTOMER",
  Void = "VOID",
}

export enum PaymentIntentNextAction {
  RedirectToUrl = "REDIRECT_TO_URL",
}

export enum PaymentIntentStatus {
  Cancelled = "CANCELLED",
  Failed = "FAILED",
  Processing = "PROCESSING",
  RequiresAction = "REQUIRES_ACTION",
  RequiresConfirmation = "REQUIRES_CONFIRMATION",
  RequiresPaymentMethod = "REQUIRES_PAYMENT_METHOD",
  Succeeded = "SUCCEEDED",
}

export type ProcessWorkflowSubmissionInput = {
  formId: Scalars["String"];
  submissionData: Scalars["jsonObject"];
};

export type ProcessWorkflowSubmissionOutput = {
  __typename?: "ProcessWorkflowSubmissionOutput";
  message?: Maybe<Scalars["String"]>;
  party?: Maybe<Parties>;
  partyId: Scalars["String"];
};

export type QrAuthenticatorOutput = {
  __typename?: "QRAuthenticatorOutput";
  message?: Maybe<Scalars["String"]>;
  qrCodeURL: Scalars["String"];
};

export type RecordPaymentInput = {
  amount: Scalars["Int"];
  attachments?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  charges: Scalars["Int"];
  currency: Scalars["String"];
  description: Scalars["String"];
  effective_date: Scalars["Time"];
  external_reference: Scalars["String"];
  ledger_id: Scalars["uuid"];
  metadata: Scalars["Map"];
  organisation_id: Scalars["uuid"];
  originating_ledger_account_id: Scalars["uuid"];
  originating_party_id: Scalars["uuid"];
  payment_sub_type: Scalars["String"];
  payment_type: Scalars["String"];
  receiving_ledger_account_id: Scalars["uuid"];
  receiving_party_id: Scalars["uuid"];
  recording_party_id: Scalars["uuid"];
  status: Scalars["String"];
  transaction_id: Scalars["uuid"];
  transaction_type: NormalBalance;
};

export type RecordPaymentOutput = {
  __typename?: "RecordPaymentOutput";
  affected_rows: Scalars["Int"];
  returning?: Maybe<IdReturn>;
};

export type RecordTransactionInput = {
  description: Scalars["String"];
  effective_date: Scalars["Time"];
  external_reference: Scalars["String"];
  ledger_entries: Array<TransactionLedgerEntryInput>;
  ledger_id: Scalars["uuid"];
  metadata: Scalars["Map"];
  organisation_id: Scalars["uuid"];
  payment_id?: InputMaybe<Scalars["uuid"]>;
  posted_at?: InputMaybe<Scalars["Time"]>;
  status: Scalars["String"];
  type: Scalars["String"];
};

export type RecordTransactionOutput = {
  __typename?: "RecordTransactionOutput";
  affected_rows: Scalars["Int"];
  returning?: Maybe<RecordTransactionOutputReturning>;
};

export type RecordTransactionOutputReturning = {
  __typename?: "RecordTransactionOutputReturning";
  entry_ids: Array<Scalars["uuid"]>;
  id: Scalars["uuid"];
  transaction_ids: Array<Scalars["uuid"]>;
  transactions_map: Array<TransactionMap>;
};

export type RejectTaskApprovalInput = {
  narration: Scalars["String"];
  rejectingPartyId: Scalars["uuid"];
  taskId: Scalars["uuid"];
};

export type RejectTaskApprovalOutput = {
  __typename?: "RejectTaskApprovalOutput";
  code?: Maybe<Scalars["String"]>;
  message?: Maybe<Scalars["String"]>;
};

export type RemoveInvoiceItemsInput = {
  invoiceId: Scalars["String"];
  invoiceItemIds: Array<Scalars["uuid"]>;
};

export type RescheduleInvoiceInput = {
  newDate: Scalars["String"];
  subscriptionId: Scalars["String"];
};

export type RescheduleInvoiceOutput = {
  __typename?: "RescheduleInvoiceOutput";
  message: Scalars["String"];
};

export type ResetPasswordInput = {
  email: Scalars["String"];
  newPassword: Scalars["String"];
  token: Scalars["String"];
};

export type ResetPasswordOutput = {
  __typename?: "ResetPasswordOutput";
  code?: Maybe<Scalars["String"]>;
  message: Scalars["String"];
};

export type RetrySendingMessagesInput = {
  messageIds: Array<Scalars["uuid"]>;
};

export type RetrySendingMessagesOutput = {
  __typename?: "RetrySendingMessagesOutput";
  result?: Maybe<Scalars["String"]>;
};

export type ScheduleMessageInput = {
  body: Scalars["jsonObject"];
  channelId: Scalars["String"];
  deliveryMethods: Array<InputMaybe<Scalars["String"]>>;
  dispatchAfter: Scalars["Int"];
  messageType: Scalars["String"];
  metadata?: InputMaybe<Scalars["jsonObject"]>;
  sender: Scalars["String"];
  subject: Scalars["String"];
};

export type ScheduleMessageOutput = {
  __typename?: "ScheduleMessageOutput";
  message?: Maybe<Scalars["String"]>;
  type?: Maybe<Scalars["String"]>;
};

export enum ScheduledJobStatus {
  Active = "active",
  Completed = "completed",
  Delayed = "delayed",
  Failed = "failed",
  /** waiting-children */
  Paused = "paused",
  Repeat = "repeat",
  Wait = "wait",
  Waiting = "waiting",
}

export type SendAdHocMessageInput = {
  body: Scalars["jsonObject"];
  deliveryMethods: Array<InputMaybe<Scalars["String"]>>;
  messageType: Scalars["String"];
  metadata?: InputMaybe<Scalars["jsonObject"]>;
  partyIds: Array<InputMaybe<Scalars["uuid"]>>;
  sender: Scalars["String"];
  subject?: InputMaybe<Scalars["String"]>;
};

export type SendMessageInput = {
  body: Scalars["jsonObject"];
  channelId: Scalars["String"];
  deliveryMethods: Array<InputMaybe<Scalars["String"]>>;
  messageType: Scalars["String"];
  metadata?: InputMaybe<Scalars["jsonObject"]>;
  sender: Scalars["String"];
  subject?: InputMaybe<Scalars["String"]>;
};

export type SendMessageOutput = {
  __typename?: "SendMessageOutput";
  message: Scalars["String"];
  type?: Maybe<Scalars["String"]>;
};

export type SignupInput = {
  avatar?: InputMaybe<Scalars["String"]>;
  email: Scalars["String"];
  enableMFA: Scalars["Boolean"];
  locale?: InputMaybe<Scalars["String"]>;
  location?: InputMaybe<Scalars["String"]>;
  name: Scalars["String"];
  nickname?: InputMaybe<Scalars["String"]>;
  orgId: Scalars["uuid"];
  partyType: Scalars["String"];
  password: Scalars["String"];
  phoneNumber: Scalars["String"];
};

export type SignupOutput = {
  __typename?: "SignupOutput";
  data?: Maybe<Scalars["String"]>;
  message?: Maybe<Scalars["String"]>;
  type?: Maybe<Scalars["String"]>;
};

export type Status = {
  __typename?: "Status";
  created_at: Scalars["Time"];
  description: Scalars["String"];
  id: Scalars["uuid"];
  name: Scalars["String"];
  updated_at: Scalars["Time"];
  value: Scalars["String"];
};

/** Boolean expression to compare columns of type "String". All fields are combined with logical 'AND'. */
export type String_Array_Comparison_Exp = {
  /** is the array contained in the given array value */
  _contained_in?: InputMaybe<Array<Scalars["String"]>>;
  /** does the array contain the given value */
  _contains?: InputMaybe<Array<Scalars["String"]>>;
  _eq?: InputMaybe<Array<Scalars["String"]>>;
  _gt?: InputMaybe<Array<Scalars["String"]>>;
  _gte?: InputMaybe<Array<Scalars["String"]>>;
  _in?: InputMaybe<Array<Array<Scalars["String"]>>>;
  _is_null?: InputMaybe<Scalars["Boolean"]>;
  _lt?: InputMaybe<Array<Scalars["String"]>>;
  _lte?: InputMaybe<Array<Scalars["String"]>>;
  _neq?: InputMaybe<Array<Scalars["String"]>>;
  _nin?: InputMaybe<Array<Array<Scalars["String"]>>>;
};

/** Boolean expression to compare columns of type "String". All fields are combined with logical 'AND'. */
export type String_Comparison_Exp = {
  _eq?: InputMaybe<Scalars["String"]>;
  _gt?: InputMaybe<Scalars["String"]>;
  _gte?: InputMaybe<Scalars["String"]>;
  /** does the column match the given case-insensitive pattern */
  _ilike?: InputMaybe<Scalars["String"]>;
  _in?: InputMaybe<Array<Scalars["String"]>>;
  /** does the column match the given POSIX regular expression, case insensitive */
  _iregex?: InputMaybe<Scalars["String"]>;
  _is_null?: InputMaybe<Scalars["Boolean"]>;
  /** does the column match the given pattern */
  _like?: InputMaybe<Scalars["String"]>;
  _lt?: InputMaybe<Scalars["String"]>;
  _lte?: InputMaybe<Scalars["String"]>;
  _neq?: InputMaybe<Scalars["String"]>;
  /** does the column NOT match the given case-insensitive pattern */
  _nilike?: InputMaybe<Scalars["String"]>;
  _nin?: InputMaybe<Array<Scalars["String"]>>;
  /** does the column NOT match the given POSIX regular expression, case insensitive */
  _niregex?: InputMaybe<Scalars["String"]>;
  /** does the column NOT match the given pattern */
  _nlike?: InputMaybe<Scalars["String"]>;
  /** does the column NOT match the given POSIX regular expression, case sensitive */
  _nregex?: InputMaybe<Scalars["String"]>;
  /** does the column NOT match the given SQL regular expression */
  _nsimilar?: InputMaybe<Scalars["String"]>;
  /** does the column match the given POSIX regular expression, case sensitive */
  _regex?: InputMaybe<Scalars["String"]>;
  /** does the column match the given SQL regular expression */
  _similar?: InputMaybe<Scalars["String"]>;
};

export type SubscribeToChannelInput = {
  channelId: Scalars["String"];
  deliveryAddress: Scalars["String"];
  deliveryMethod: Scalars["String"];
  metadata?: InputMaybe<Scalars["jsonObject"]>;
  partyId: Scalars["String"];
};

export type SubscribeToChannelOutput = {
  __typename?: "SubscribeToChannelOutput";
  body?: Maybe<Scalars["jsonObject"]>;
  message?: Maybe<Scalars["String"]>;
  type?: Maybe<Scalars["String"]>;
};

export type TaskApprovalGrantedInput = {
  narration?: InputMaybe<Scalars["String"]>;
  taskId: Scalars["uuid"];
};

export type TaskApprovalGrantedOutput = {
  __typename?: "TaskApprovalGrantedOutput";
  code?: Maybe<Scalars["String"]>;
  message?: Maybe<Scalars["String"]>;
};

export type TaskApprovalInput = {
  approverPartyId: Scalars["uuid"];
  narration?: InputMaybe<Scalars["String"]>;
  taskId: Scalars["uuid"];
};

export type TaskApprovalOutput = {
  __typename?: "TaskApprovalOutput";
  code?: Maybe<Scalars["String"]>;
  message?: Maybe<Scalars["String"]>;
};

export enum TemplateType {
  Form = "FORM",
  Task = "TASK",
}

export type Time_Comparison_Exp = {
  _eq?: InputMaybe<Scalars["Time"]>;
  _gt?: InputMaybe<Scalars["Time"]>;
  _gte?: InputMaybe<Scalars["Time"]>;
  _in?: InputMaybe<Array<Scalars["Time"]>>;
  _is_null?: InputMaybe<Scalars["Boolean"]>;
  _lt?: InputMaybe<Scalars["Time"]>;
  _lte?: InputMaybe<Scalars["Time"]>;
  _neq?: InputMaybe<Scalars["Time"]>;
  _nin?: InputMaybe<Array<Scalars["Time"]>>;
};

export type ToggleSubscriptionDeliveryMethodStatusInput = {
  channelId: Scalars["uuid"];
  deliveryMethod: MessageDeliveryMethod;
  partyId: Scalars["uuid"];
  status: MethodStatus;
};

export type ToggleSubscriptionDeliveryMethodStatusOutput = {
  __typename?: "ToggleSubscriptionDeliveryMethodStatusOutput";
  message: Scalars["String"];
};

export type Transaction = {
  __typename?: "Transaction";
  created_at: Scalars["Time"];
  description: Scalars["String"];
  effective_date: Scalars["Time"];
  external_reference: Scalars["String"];
  id: Scalars["uuid"];
  ledger: Ledger;
  ledger_entries: Array<LedgerEntry>;
  ledger_id: Scalars["uuid"];
  metadata: Scalars["Map"];
  payment_id: Scalars["uuid"];
  posted_at: Scalars["Time"];
  status: Status;
  status_id: Scalars["uuid"];
  type: Type;
  type_id: Scalars["uuid"];
  updated_at: Scalars["Time"];
  version: Scalars["Int"];
};

export type TransactionLedgerEntry = {
  __typename?: "TransactionLedgerEntry";
  amount: Scalars["Int"];
  created_at: Scalars["Time"];
  description: Scalars["String"];
  effective_date: Scalars["Time"];
  entry_type: Scalars["String"];
  external_reference: Scalars["String"];
  is_voided: Scalars["Boolean"];
  ledger_account_id: Scalars["uuid"];
  ledger_entry_id: Scalars["uuid"];
  ledger_id: Scalars["uuid"];
  ledger_transaction_id: Scalars["uuid"];
  metadata: Scalars["Map"];
  payment_id: Scalars["uuid"];
  posted_at: Scalars["Time"];
  status: Status;
  status_id: Scalars["uuid"];
  subscription_items: Array<Subscription_Items>;
  subscription_items_aggregate: Subscription_Items_Aggregate;
  type: Type;
  type_id: Scalars["uuid"];
  updated_at: Scalars["Time"];
};

export type TransactionLedgerEntrySubscription_ItemsArgs = {
  distinct_on?: InputMaybe<Array<Subscription_Items_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Subscription_Items_Order_By>>;
  where?: InputMaybe<Subscription_Items_Bool_Exp>;
};

export type TransactionLedgerEntrySubscription_Items_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Subscription_Items_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Subscription_Items_Order_By>>;
  where?: InputMaybe<Subscription_Items_Bool_Exp>;
};

export type TransactionLedgerEntryConnection = {
  __typename?: "TransactionLedgerEntryConnection";
  aggregate: Aggergate;
  entries: Array<TransactionLedgerEntry>;
  pageInfo: PageInfo;
  summary: TransactionLedgerEntrySummary;
};

export type TransactionLedgerEntryInput = {
  amount: Scalars["Int"];
  entry_type: NormalBalance;
  ledger_account_id: Scalars["uuid"];
  metadata: Scalars["Map"];
};

export type TransactionLedgerEntrySummary = {
  __typename?: "TransactionLedgerEntrySummary";
  total_credits: Scalars["Int"];
  total_debits: Scalars["Int"];
  transaction_count: Scalars["Int"];
};

export type TransactionMap = {
  __typename?: "TransactionMap";
  ledger_account_id: Scalars["uuid"];
  transaction_id: Scalars["uuid"];
};

export type TransferFundsInput = {
  amount: Scalars["Int"];
  amount_in_cents: Scalars["Boolean"];
  attachments?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  charges: Scalars["Int"];
  currency: Scalars["String"];
  description: Scalars["String"];
  external_reference: Scalars["String"];
  ledger_id: Scalars["uuid"];
  metadata: Scalars["Map"];
  organisation_id: Scalars["uuid"];
  originating_ledger_account_id: Scalars["uuid"];
  originating_party_id: Scalars["uuid"];
  payment_sub_type: Scalars["String"];
  payment_type: Scalars["String"];
  receiving_ledger_account_id: Scalars["uuid"];
  receiving_party_id: Scalars["uuid"];
  recording_party_id: Scalars["uuid"];
  transfer_transaction_type: TransferTransactionType;
};

export type TransferFundsOutput = {
  __typename?: "TransferFundsOutput";
  affected_rows: Scalars["Int"];
  returning?: Maybe<TransferFundsOutputReturning>;
};

export type TransferFundsOutputReturning = {
  __typename?: "TransferFundsOutputReturning";
  payment_id: Scalars["uuid"];
  transaction_id: Scalars["uuid"];
};

export enum TransferTransactionType {
  AccountDeposit = "ACCOUNT_DEPOSIT",
  AccountTransfer = "ACCOUNT_TRANSFER",
  AccountWithdrawal = "ACCOUNT_WITHDRAWAL",
}

export type TrialBalanceReport = {
  __typename?: "TrialBalanceReport";
  from_date: Scalars["String"];
  ledger_account_reports: Array<LedgerAccountReport>;
  to_date: Scalars["String"];
};

export type Type = {
  __typename?: "Type";
  created_at: Scalars["Time"];
  description: Scalars["String"];
  id: Scalars["uuid"];
  name: Scalars["String"];
  updated_at: Scalars["Time"];
  value: Scalars["String"];
};

export type UpdateInvoiceInput = {
  invoiceId: Scalars["String"];
  invoiceItems: Scalars["jsonObject"];
};

export type UpdateInvoiceOutput = {
  __typename?: "UpdateInvoiceOutput";
  message: Scalars["String"];
};

export type UpdateLedgerAccountInput = {
  code?: InputMaybe<Scalars["String"]>;
  description?: InputMaybe<Scalars["String"]>;
  id: Scalars["uuid"];
  metadata: Scalars["Map"];
  name?: InputMaybe<Scalars["String"]>;
  normal_balance?: InputMaybe<NormalBalance>;
  type_id?: InputMaybe<Scalars["uuid"]>;
};

export type UpdateLedgerInput = {
  description?: InputMaybe<Scalars["String"]>;
  id: Scalars["uuid"];
  metadata: Scalars["Map"];
  name?: InputMaybe<Scalars["String"]>;
};

export type UpdatePaymentInput = {
  attachments?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  description?: InputMaybe<Scalars["String"]>;
  id: Scalars["uuid"];
  metadata: Scalars["Map"];
  status?: InputMaybe<Scalars["String"]>;
};

export type UpdatePaymentIntentInput = {
  allowed_payment_methods?: InputMaybe<Array<AllowedPaymentMethod>>;
  amount?: InputMaybe<Scalars["Int"]>;
  cancellation_reason?: InputMaybe<PaymentIntentCancellationReason>;
  cancelled_at?: InputMaybe<Scalars["Time"]>;
  description?: InputMaybe<Scalars["String"]>;
  id: Scalars["uuid"];
  metadata: Scalars["Map"];
  next_action?: InputMaybe<PaymentIntentNextAction>;
  status?: InputMaybe<PaymentIntentStatus>;
};

export type UpdateSubscriptionDeliveryMethodAddressInput = {
  channelId: Scalars["uuid"];
  deliveryAddress: Scalars["String"];
  deliveryMethod: MessageDeliveryMethod;
  partyId: Scalars["uuid"];
};

export type UpdateSubscriptionDeliveryMethodAddressOutput = {
  __typename?: "UpdateSubscriptionDeliveryMethodAddressOutput";
  message: Scalars["String"];
};

export type UpdateTransactionInput = {
  description?: InputMaybe<Scalars["String"]>;
  id: Scalars["uuid"];
  metadata: Scalars["Map"];
  status?: InputMaybe<Scalars["String"]>;
};

export type UpdateTransactionOutput = {
  __typename?: "UpdateTransactionOutput";
  affected_rows: Scalars["Int"];
  returning: IdReturn;
};

export type VerifyAuthFactorInput = {
  mfaMethod: Scalars["String"];
  otp: Scalars["String"];
};

export type VerifyAuthFactorOutput = {
  __typename?: "VerifyAuthFactorOutput";
  message?: Maybe<Scalars["String"]>;
  response: Scalars["Boolean"];
};

/** columns and relationships of "_case_dod_data" */
export type _Case_Dod_Data = {
  __typename?: "_case_dod_data";
  current_day_count?: Maybe<Scalars["bigint"]>;
  day?: Maybe<Scalars["date"]>;
  growth?: Maybe<Scalars["String"]>;
  org_id?: Maybe<Scalars["uuid"]>;
  previous_day_count?: Maybe<Scalars["bigint"]>;
};

/** aggregated selection of "_case_dod_data" */
export type _Case_Dod_Data_Aggregate = {
  __typename?: "_case_dod_data_aggregate";
  aggregate?: Maybe<_Case_Dod_Data_Aggregate_Fields>;
  nodes: Array<_Case_Dod_Data>;
};

/** aggregate fields of "_case_dod_data" */
export type _Case_Dod_Data_Aggregate_Fields = {
  __typename?: "_case_dod_data_aggregate_fields";
  avg?: Maybe<_Case_Dod_Data_Avg_Fields>;
  count: Scalars["Int"];
  max?: Maybe<_Case_Dod_Data_Max_Fields>;
  min?: Maybe<_Case_Dod_Data_Min_Fields>;
  stddev?: Maybe<_Case_Dod_Data_Stddev_Fields>;
  stddev_pop?: Maybe<_Case_Dod_Data_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<_Case_Dod_Data_Stddev_Samp_Fields>;
  sum?: Maybe<_Case_Dod_Data_Sum_Fields>;
  var_pop?: Maybe<_Case_Dod_Data_Var_Pop_Fields>;
  var_samp?: Maybe<_Case_Dod_Data_Var_Samp_Fields>;
  variance?: Maybe<_Case_Dod_Data_Variance_Fields>;
};

/** aggregate fields of "_case_dod_data" */
export type _Case_Dod_Data_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<_Case_Dod_Data_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** aggregate avg on columns */
export type _Case_Dod_Data_Avg_Fields = {
  __typename?: "_case_dod_data_avg_fields";
  current_day_count?: Maybe<Scalars["Float"]>;
  previous_day_count?: Maybe<Scalars["Float"]>;
};

/** Boolean expression to filter rows from the table "_case_dod_data". All fields are combined with a logical 'AND'. */
export type _Case_Dod_Data_Bool_Exp = {
  _and?: InputMaybe<Array<_Case_Dod_Data_Bool_Exp>>;
  _not?: InputMaybe<_Case_Dod_Data_Bool_Exp>;
  _or?: InputMaybe<Array<_Case_Dod_Data_Bool_Exp>>;
  current_day_count?: InputMaybe<Bigint_Comparison_Exp>;
  day?: InputMaybe<Date_Comparison_Exp>;
  growth?: InputMaybe<String_Comparison_Exp>;
  org_id?: InputMaybe<Uuid_Comparison_Exp>;
  previous_day_count?: InputMaybe<Bigint_Comparison_Exp>;
};

/** aggregate max on columns */
export type _Case_Dod_Data_Max_Fields = {
  __typename?: "_case_dod_data_max_fields";
  current_day_count?: Maybe<Scalars["bigint"]>;
  day?: Maybe<Scalars["date"]>;
  growth?: Maybe<Scalars["String"]>;
  org_id?: Maybe<Scalars["uuid"]>;
  previous_day_count?: Maybe<Scalars["bigint"]>;
};

/** aggregate min on columns */
export type _Case_Dod_Data_Min_Fields = {
  __typename?: "_case_dod_data_min_fields";
  current_day_count?: Maybe<Scalars["bigint"]>;
  day?: Maybe<Scalars["date"]>;
  growth?: Maybe<Scalars["String"]>;
  org_id?: Maybe<Scalars["uuid"]>;
  previous_day_count?: Maybe<Scalars["bigint"]>;
};

/** Ordering options when selecting data from "_case_dod_data". */
export type _Case_Dod_Data_Order_By = {
  current_day_count?: InputMaybe<Order_By>;
  day?: InputMaybe<Order_By>;
  growth?: InputMaybe<Order_By>;
  org_id?: InputMaybe<Order_By>;
  previous_day_count?: InputMaybe<Order_By>;
};

/** select columns of table "_case_dod_data" */
export enum _Case_Dod_Data_Select_Column {
  /** column name */
  CurrentDayCount = "current_day_count",
  /** column name */
  Day = "day",
  /** column name */
  Growth = "growth",
  /** column name */
  OrgId = "org_id",
  /** column name */
  PreviousDayCount = "previous_day_count",
}

/** aggregate stddev on columns */
export type _Case_Dod_Data_Stddev_Fields = {
  __typename?: "_case_dod_data_stddev_fields";
  current_day_count?: Maybe<Scalars["Float"]>;
  previous_day_count?: Maybe<Scalars["Float"]>;
};

/** aggregate stddev_pop on columns */
export type _Case_Dod_Data_Stddev_Pop_Fields = {
  __typename?: "_case_dod_data_stddev_pop_fields";
  current_day_count?: Maybe<Scalars["Float"]>;
  previous_day_count?: Maybe<Scalars["Float"]>;
};

/** aggregate stddev_samp on columns */
export type _Case_Dod_Data_Stddev_Samp_Fields = {
  __typename?: "_case_dod_data_stddev_samp_fields";
  current_day_count?: Maybe<Scalars["Float"]>;
  previous_day_count?: Maybe<Scalars["Float"]>;
};

/** Streaming cursor of the table "_case_dod_data" */
export type _Case_Dod_Data_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: _Case_Dod_Data_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type _Case_Dod_Data_Stream_Cursor_Value_Input = {
  current_day_count?: InputMaybe<Scalars["bigint"]>;
  day?: InputMaybe<Scalars["date"]>;
  growth?: InputMaybe<Scalars["String"]>;
  org_id?: InputMaybe<Scalars["uuid"]>;
  previous_day_count?: InputMaybe<Scalars["bigint"]>;
};

/** aggregate sum on columns */
export type _Case_Dod_Data_Sum_Fields = {
  __typename?: "_case_dod_data_sum_fields";
  current_day_count?: Maybe<Scalars["bigint"]>;
  previous_day_count?: Maybe<Scalars["bigint"]>;
};

/** aggregate var_pop on columns */
export type _Case_Dod_Data_Var_Pop_Fields = {
  __typename?: "_case_dod_data_var_pop_fields";
  current_day_count?: Maybe<Scalars["Float"]>;
  previous_day_count?: Maybe<Scalars["Float"]>;
};

/** aggregate var_samp on columns */
export type _Case_Dod_Data_Var_Samp_Fields = {
  __typename?: "_case_dod_data_var_samp_fields";
  current_day_count?: Maybe<Scalars["Float"]>;
  previous_day_count?: Maybe<Scalars["Float"]>;
};

/** aggregate variance on columns */
export type _Case_Dod_Data_Variance_Fields = {
  __typename?: "_case_dod_data_variance_fields";
  current_day_count?: Maybe<Scalars["Float"]>;
  previous_day_count?: Maybe<Scalars["Float"]>;
};

/** columns and relationships of "_closed_cases_dod_data" */
export type _Closed_Cases_Dod_Data = {
  __typename?: "_closed_cases_dod_data";
  current_day_count?: Maybe<Scalars["bigint"]>;
  day?: Maybe<Scalars["date"]>;
  growth?: Maybe<Scalars["String"]>;
  org_id?: Maybe<Scalars["uuid"]>;
  previous_day_count?: Maybe<Scalars["bigint"]>;
};

/** aggregated selection of "_closed_cases_dod_data" */
export type _Closed_Cases_Dod_Data_Aggregate = {
  __typename?: "_closed_cases_dod_data_aggregate";
  aggregate?: Maybe<_Closed_Cases_Dod_Data_Aggregate_Fields>;
  nodes: Array<_Closed_Cases_Dod_Data>;
};

/** aggregate fields of "_closed_cases_dod_data" */
export type _Closed_Cases_Dod_Data_Aggregate_Fields = {
  __typename?: "_closed_cases_dod_data_aggregate_fields";
  avg?: Maybe<_Closed_Cases_Dod_Data_Avg_Fields>;
  count: Scalars["Int"];
  max?: Maybe<_Closed_Cases_Dod_Data_Max_Fields>;
  min?: Maybe<_Closed_Cases_Dod_Data_Min_Fields>;
  stddev?: Maybe<_Closed_Cases_Dod_Data_Stddev_Fields>;
  stddev_pop?: Maybe<_Closed_Cases_Dod_Data_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<_Closed_Cases_Dod_Data_Stddev_Samp_Fields>;
  sum?: Maybe<_Closed_Cases_Dod_Data_Sum_Fields>;
  var_pop?: Maybe<_Closed_Cases_Dod_Data_Var_Pop_Fields>;
  var_samp?: Maybe<_Closed_Cases_Dod_Data_Var_Samp_Fields>;
  variance?: Maybe<_Closed_Cases_Dod_Data_Variance_Fields>;
};

/** aggregate fields of "_closed_cases_dod_data" */
export type _Closed_Cases_Dod_Data_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<_Closed_Cases_Dod_Data_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** aggregate avg on columns */
export type _Closed_Cases_Dod_Data_Avg_Fields = {
  __typename?: "_closed_cases_dod_data_avg_fields";
  current_day_count?: Maybe<Scalars["Float"]>;
  previous_day_count?: Maybe<Scalars["Float"]>;
};

/** Boolean expression to filter rows from the table "_closed_cases_dod_data". All fields are combined with a logical 'AND'. */
export type _Closed_Cases_Dod_Data_Bool_Exp = {
  _and?: InputMaybe<Array<_Closed_Cases_Dod_Data_Bool_Exp>>;
  _not?: InputMaybe<_Closed_Cases_Dod_Data_Bool_Exp>;
  _or?: InputMaybe<Array<_Closed_Cases_Dod_Data_Bool_Exp>>;
  current_day_count?: InputMaybe<Bigint_Comparison_Exp>;
  day?: InputMaybe<Date_Comparison_Exp>;
  growth?: InputMaybe<String_Comparison_Exp>;
  org_id?: InputMaybe<Uuid_Comparison_Exp>;
  previous_day_count?: InputMaybe<Bigint_Comparison_Exp>;
};

/** aggregate max on columns */
export type _Closed_Cases_Dod_Data_Max_Fields = {
  __typename?: "_closed_cases_dod_data_max_fields";
  current_day_count?: Maybe<Scalars["bigint"]>;
  day?: Maybe<Scalars["date"]>;
  growth?: Maybe<Scalars["String"]>;
  org_id?: Maybe<Scalars["uuid"]>;
  previous_day_count?: Maybe<Scalars["bigint"]>;
};

/** aggregate min on columns */
export type _Closed_Cases_Dod_Data_Min_Fields = {
  __typename?: "_closed_cases_dod_data_min_fields";
  current_day_count?: Maybe<Scalars["bigint"]>;
  day?: Maybe<Scalars["date"]>;
  growth?: Maybe<Scalars["String"]>;
  org_id?: Maybe<Scalars["uuid"]>;
  previous_day_count?: Maybe<Scalars["bigint"]>;
};

/** Ordering options when selecting data from "_closed_cases_dod_data". */
export type _Closed_Cases_Dod_Data_Order_By = {
  current_day_count?: InputMaybe<Order_By>;
  day?: InputMaybe<Order_By>;
  growth?: InputMaybe<Order_By>;
  org_id?: InputMaybe<Order_By>;
  previous_day_count?: InputMaybe<Order_By>;
};

/** select columns of table "_closed_cases_dod_data" */
export enum _Closed_Cases_Dod_Data_Select_Column {
  /** column name */
  CurrentDayCount = "current_day_count",
  /** column name */
  Day = "day",
  /** column name */
  Growth = "growth",
  /** column name */
  OrgId = "org_id",
  /** column name */
  PreviousDayCount = "previous_day_count",
}

/** aggregate stddev on columns */
export type _Closed_Cases_Dod_Data_Stddev_Fields = {
  __typename?: "_closed_cases_dod_data_stddev_fields";
  current_day_count?: Maybe<Scalars["Float"]>;
  previous_day_count?: Maybe<Scalars["Float"]>;
};

/** aggregate stddev_pop on columns */
export type _Closed_Cases_Dod_Data_Stddev_Pop_Fields = {
  __typename?: "_closed_cases_dod_data_stddev_pop_fields";
  current_day_count?: Maybe<Scalars["Float"]>;
  previous_day_count?: Maybe<Scalars["Float"]>;
};

/** aggregate stddev_samp on columns */
export type _Closed_Cases_Dod_Data_Stddev_Samp_Fields = {
  __typename?: "_closed_cases_dod_data_stddev_samp_fields";
  current_day_count?: Maybe<Scalars["Float"]>;
  previous_day_count?: Maybe<Scalars["Float"]>;
};

/** Streaming cursor of the table "_closed_cases_dod_data" */
export type _Closed_Cases_Dod_Data_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: _Closed_Cases_Dod_Data_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type _Closed_Cases_Dod_Data_Stream_Cursor_Value_Input = {
  current_day_count?: InputMaybe<Scalars["bigint"]>;
  day?: InputMaybe<Scalars["date"]>;
  growth?: InputMaybe<Scalars["String"]>;
  org_id?: InputMaybe<Scalars["uuid"]>;
  previous_day_count?: InputMaybe<Scalars["bigint"]>;
};

/** aggregate sum on columns */
export type _Closed_Cases_Dod_Data_Sum_Fields = {
  __typename?: "_closed_cases_dod_data_sum_fields";
  current_day_count?: Maybe<Scalars["bigint"]>;
  previous_day_count?: Maybe<Scalars["bigint"]>;
};

/** aggregate var_pop on columns */
export type _Closed_Cases_Dod_Data_Var_Pop_Fields = {
  __typename?: "_closed_cases_dod_data_var_pop_fields";
  current_day_count?: Maybe<Scalars["Float"]>;
  previous_day_count?: Maybe<Scalars["Float"]>;
};

/** aggregate var_samp on columns */
export type _Closed_Cases_Dod_Data_Var_Samp_Fields = {
  __typename?: "_closed_cases_dod_data_var_samp_fields";
  current_day_count?: Maybe<Scalars["Float"]>;
  previous_day_count?: Maybe<Scalars["Float"]>;
};

/** aggregate variance on columns */
export type _Closed_Cases_Dod_Data_Variance_Fields = {
  __typename?: "_closed_cases_dod_data_variance_fields";
  current_day_count?: Maybe<Scalars["Float"]>;
  previous_day_count?: Maybe<Scalars["Float"]>;
};

/** columns and relationships of "_cus_growth_data" */
export type _Cus_Growth_Data = {
  __typename?: "_cus_growth_data";
  current_day_count?: Maybe<Scalars["bigint"]>;
  day?: Maybe<Scalars["date"]>;
  growth?: Maybe<Scalars["String"]>;
  org_id?: Maybe<Scalars["uuid"]>;
  previous_day_count?: Maybe<Scalars["bigint"]>;
};

/** aggregated selection of "_cus_growth_data" */
export type _Cus_Growth_Data_Aggregate = {
  __typename?: "_cus_growth_data_aggregate";
  aggregate?: Maybe<_Cus_Growth_Data_Aggregate_Fields>;
  nodes: Array<_Cus_Growth_Data>;
};

/** aggregate fields of "_cus_growth_data" */
export type _Cus_Growth_Data_Aggregate_Fields = {
  __typename?: "_cus_growth_data_aggregate_fields";
  avg?: Maybe<_Cus_Growth_Data_Avg_Fields>;
  count: Scalars["Int"];
  max?: Maybe<_Cus_Growth_Data_Max_Fields>;
  min?: Maybe<_Cus_Growth_Data_Min_Fields>;
  stddev?: Maybe<_Cus_Growth_Data_Stddev_Fields>;
  stddev_pop?: Maybe<_Cus_Growth_Data_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<_Cus_Growth_Data_Stddev_Samp_Fields>;
  sum?: Maybe<_Cus_Growth_Data_Sum_Fields>;
  var_pop?: Maybe<_Cus_Growth_Data_Var_Pop_Fields>;
  var_samp?: Maybe<_Cus_Growth_Data_Var_Samp_Fields>;
  variance?: Maybe<_Cus_Growth_Data_Variance_Fields>;
};

/** aggregate fields of "_cus_growth_data" */
export type _Cus_Growth_Data_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<_Cus_Growth_Data_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** aggregate avg on columns */
export type _Cus_Growth_Data_Avg_Fields = {
  __typename?: "_cus_growth_data_avg_fields";
  current_day_count?: Maybe<Scalars["Float"]>;
  previous_day_count?: Maybe<Scalars["Float"]>;
};

/** Boolean expression to filter rows from the table "_cus_growth_data". All fields are combined with a logical 'AND'. */
export type _Cus_Growth_Data_Bool_Exp = {
  _and?: InputMaybe<Array<_Cus_Growth_Data_Bool_Exp>>;
  _not?: InputMaybe<_Cus_Growth_Data_Bool_Exp>;
  _or?: InputMaybe<Array<_Cus_Growth_Data_Bool_Exp>>;
  current_day_count?: InputMaybe<Bigint_Comparison_Exp>;
  day?: InputMaybe<Date_Comparison_Exp>;
  growth?: InputMaybe<String_Comparison_Exp>;
  org_id?: InputMaybe<Uuid_Comparison_Exp>;
  previous_day_count?: InputMaybe<Bigint_Comparison_Exp>;
};

/** aggregate max on columns */
export type _Cus_Growth_Data_Max_Fields = {
  __typename?: "_cus_growth_data_max_fields";
  current_day_count?: Maybe<Scalars["bigint"]>;
  day?: Maybe<Scalars["date"]>;
  growth?: Maybe<Scalars["String"]>;
  org_id?: Maybe<Scalars["uuid"]>;
  previous_day_count?: Maybe<Scalars["bigint"]>;
};

/** aggregate min on columns */
export type _Cus_Growth_Data_Min_Fields = {
  __typename?: "_cus_growth_data_min_fields";
  current_day_count?: Maybe<Scalars["bigint"]>;
  day?: Maybe<Scalars["date"]>;
  growth?: Maybe<Scalars["String"]>;
  org_id?: Maybe<Scalars["uuid"]>;
  previous_day_count?: Maybe<Scalars["bigint"]>;
};

/** Ordering options when selecting data from "_cus_growth_data". */
export type _Cus_Growth_Data_Order_By = {
  current_day_count?: InputMaybe<Order_By>;
  day?: InputMaybe<Order_By>;
  growth?: InputMaybe<Order_By>;
  org_id?: InputMaybe<Order_By>;
  previous_day_count?: InputMaybe<Order_By>;
};

/** select columns of table "_cus_growth_data" */
export enum _Cus_Growth_Data_Select_Column {
  /** column name */
  CurrentDayCount = "current_day_count",
  /** column name */
  Day = "day",
  /** column name */
  Growth = "growth",
  /** column name */
  OrgId = "org_id",
  /** column name */
  PreviousDayCount = "previous_day_count",
}

/** aggregate stddev on columns */
export type _Cus_Growth_Data_Stddev_Fields = {
  __typename?: "_cus_growth_data_stddev_fields";
  current_day_count?: Maybe<Scalars["Float"]>;
  previous_day_count?: Maybe<Scalars["Float"]>;
};

/** aggregate stddev_pop on columns */
export type _Cus_Growth_Data_Stddev_Pop_Fields = {
  __typename?: "_cus_growth_data_stddev_pop_fields";
  current_day_count?: Maybe<Scalars["Float"]>;
  previous_day_count?: Maybe<Scalars["Float"]>;
};

/** aggregate stddev_samp on columns */
export type _Cus_Growth_Data_Stddev_Samp_Fields = {
  __typename?: "_cus_growth_data_stddev_samp_fields";
  current_day_count?: Maybe<Scalars["Float"]>;
  previous_day_count?: Maybe<Scalars["Float"]>;
};

/** Streaming cursor of the table "_cus_growth_data" */
export type _Cus_Growth_Data_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: _Cus_Growth_Data_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type _Cus_Growth_Data_Stream_Cursor_Value_Input = {
  current_day_count?: InputMaybe<Scalars["bigint"]>;
  day?: InputMaybe<Scalars["date"]>;
  growth?: InputMaybe<Scalars["String"]>;
  org_id?: InputMaybe<Scalars["uuid"]>;
  previous_day_count?: InputMaybe<Scalars["bigint"]>;
};

/** aggregate sum on columns */
export type _Cus_Growth_Data_Sum_Fields = {
  __typename?: "_cus_growth_data_sum_fields";
  current_day_count?: Maybe<Scalars["bigint"]>;
  previous_day_count?: Maybe<Scalars["bigint"]>;
};

/** aggregate var_pop on columns */
export type _Cus_Growth_Data_Var_Pop_Fields = {
  __typename?: "_cus_growth_data_var_pop_fields";
  current_day_count?: Maybe<Scalars["Float"]>;
  previous_day_count?: Maybe<Scalars["Float"]>;
};

/** aggregate var_samp on columns */
export type _Cus_Growth_Data_Var_Samp_Fields = {
  __typename?: "_cus_growth_data_var_samp_fields";
  current_day_count?: Maybe<Scalars["Float"]>;
  previous_day_count?: Maybe<Scalars["Float"]>;
};

/** aggregate variance on columns */
export type _Cus_Growth_Data_Variance_Fields = {
  __typename?: "_cus_growth_data_variance_fields";
  current_day_count?: Maybe<Scalars["Float"]>;
  previous_day_count?: Maybe<Scalars["Float"]>;
};

/** columns and relationships of "active_product_subscriptions" */
export type Active_Product_Subscriptions = {
  __typename?: "active_product_subscriptions";
  count?: Maybe<Scalars["bigint"]>;
  organisation_id?: Maybe<Scalars["uuid"]>;
  product_id?: Maybe<Scalars["uuid"]>;
};

/** aggregated selection of "active_product_subscriptions" */
export type Active_Product_Subscriptions_Aggregate = {
  __typename?: "active_product_subscriptions_aggregate";
  aggregate?: Maybe<Active_Product_Subscriptions_Aggregate_Fields>;
  nodes: Array<Active_Product_Subscriptions>;
};

/** aggregate fields of "active_product_subscriptions" */
export type Active_Product_Subscriptions_Aggregate_Fields = {
  __typename?: "active_product_subscriptions_aggregate_fields";
  avg?: Maybe<Active_Product_Subscriptions_Avg_Fields>;
  count: Scalars["Int"];
  max?: Maybe<Active_Product_Subscriptions_Max_Fields>;
  min?: Maybe<Active_Product_Subscriptions_Min_Fields>;
  stddev?: Maybe<Active_Product_Subscriptions_Stddev_Fields>;
  stddev_pop?: Maybe<Active_Product_Subscriptions_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Active_Product_Subscriptions_Stddev_Samp_Fields>;
  sum?: Maybe<Active_Product_Subscriptions_Sum_Fields>;
  var_pop?: Maybe<Active_Product_Subscriptions_Var_Pop_Fields>;
  var_samp?: Maybe<Active_Product_Subscriptions_Var_Samp_Fields>;
  variance?: Maybe<Active_Product_Subscriptions_Variance_Fields>;
};

/** aggregate fields of "active_product_subscriptions" */
export type Active_Product_Subscriptions_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Active_Product_Subscriptions_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** aggregate avg on columns */
export type Active_Product_Subscriptions_Avg_Fields = {
  __typename?: "active_product_subscriptions_avg_fields";
  count?: Maybe<Scalars["Float"]>;
};

/** Boolean expression to filter rows from the table "active_product_subscriptions". All fields are combined with a logical 'AND'. */
export type Active_Product_Subscriptions_Bool_Exp = {
  _and?: InputMaybe<Array<Active_Product_Subscriptions_Bool_Exp>>;
  _not?: InputMaybe<Active_Product_Subscriptions_Bool_Exp>;
  _or?: InputMaybe<Array<Active_Product_Subscriptions_Bool_Exp>>;
  count?: InputMaybe<Bigint_Comparison_Exp>;
  organisation_id?: InputMaybe<Uuid_Comparison_Exp>;
  product_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** input type for inserting data into table "active_product_subscriptions" */
export type Active_Product_Subscriptions_Insert_Input = {
  count?: InputMaybe<Scalars["bigint"]>;
  organisation_id?: InputMaybe<Scalars["uuid"]>;
  product_id?: InputMaybe<Scalars["uuid"]>;
};

/** aggregate max on columns */
export type Active_Product_Subscriptions_Max_Fields = {
  __typename?: "active_product_subscriptions_max_fields";
  count?: Maybe<Scalars["bigint"]>;
  organisation_id?: Maybe<Scalars["uuid"]>;
  product_id?: Maybe<Scalars["uuid"]>;
};

/** aggregate min on columns */
export type Active_Product_Subscriptions_Min_Fields = {
  __typename?: "active_product_subscriptions_min_fields";
  count?: Maybe<Scalars["bigint"]>;
  organisation_id?: Maybe<Scalars["uuid"]>;
  product_id?: Maybe<Scalars["uuid"]>;
};

/** input type for inserting object relation for remote table "active_product_subscriptions" */
export type Active_Product_Subscriptions_Obj_Rel_Insert_Input = {
  data: Active_Product_Subscriptions_Insert_Input;
};

/** Ordering options when selecting data from "active_product_subscriptions". */
export type Active_Product_Subscriptions_Order_By = {
  count?: InputMaybe<Order_By>;
  organisation_id?: InputMaybe<Order_By>;
  product_id?: InputMaybe<Order_By>;
};

/** select columns of table "active_product_subscriptions" */
export enum Active_Product_Subscriptions_Select_Column {
  /** column name */
  Count = "count",
  /** column name */
  OrganisationId = "organisation_id",
  /** column name */
  ProductId = "product_id",
}

/** aggregate stddev on columns */
export type Active_Product_Subscriptions_Stddev_Fields = {
  __typename?: "active_product_subscriptions_stddev_fields";
  count?: Maybe<Scalars["Float"]>;
};

/** aggregate stddev_pop on columns */
export type Active_Product_Subscriptions_Stddev_Pop_Fields = {
  __typename?: "active_product_subscriptions_stddev_pop_fields";
  count?: Maybe<Scalars["Float"]>;
};

/** aggregate stddev_samp on columns */
export type Active_Product_Subscriptions_Stddev_Samp_Fields = {
  __typename?: "active_product_subscriptions_stddev_samp_fields";
  count?: Maybe<Scalars["Float"]>;
};

/** Streaming cursor of the table "active_product_subscriptions" */
export type Active_Product_Subscriptions_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Active_Product_Subscriptions_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Active_Product_Subscriptions_Stream_Cursor_Value_Input = {
  count?: InputMaybe<Scalars["bigint"]>;
  organisation_id?: InputMaybe<Scalars["uuid"]>;
  product_id?: InputMaybe<Scalars["uuid"]>;
};

/** aggregate sum on columns */
export type Active_Product_Subscriptions_Sum_Fields = {
  __typename?: "active_product_subscriptions_sum_fields";
  count?: Maybe<Scalars["bigint"]>;
};

/** aggregate var_pop on columns */
export type Active_Product_Subscriptions_Var_Pop_Fields = {
  __typename?: "active_product_subscriptions_var_pop_fields";
  count?: Maybe<Scalars["Float"]>;
};

/** aggregate var_samp on columns */
export type Active_Product_Subscriptions_Var_Samp_Fields = {
  __typename?: "active_product_subscriptions_var_samp_fields";
  count?: Maybe<Scalars["Float"]>;
};

/** aggregate variance on columns */
export type Active_Product_Subscriptions_Variance_Fields = {
  __typename?: "active_product_subscriptions_variance_fields";
  count?: Maybe<Scalars["Float"]>;
};

/** columns and relationships of "activity_logs" */
export type Activity_Logs = {
  __typename?: "activity_logs";
  actor: Scalars["jsonb"];
  created_at?: Maybe<Scalars["timestamptz"]>;
  event_timestamp?: Maybe<Scalars["timestamptz"]>;
  id: Scalars["uuid"];
  object: Scalars["jsonb"];
  organisation_id?: Maybe<Scalars["uuid"]>;
  render_template?: Maybe<Scalars["String"]>;
  service: Scalars["String"];
  target: Scalars["jsonb"];
  verb: Scalars["String"];
};

/** columns and relationships of "activity_logs" */
export type Activity_LogsActorArgs = {
  path?: InputMaybe<Scalars["String"]>;
};

/** columns and relationships of "activity_logs" */
export type Activity_LogsObjectArgs = {
  path?: InputMaybe<Scalars["String"]>;
};

/** columns and relationships of "activity_logs" */
export type Activity_LogsTargetArgs = {
  path?: InputMaybe<Scalars["String"]>;
};

/** aggregated selection of "activity_logs" */
export type Activity_Logs_Aggregate = {
  __typename?: "activity_logs_aggregate";
  aggregate?: Maybe<Activity_Logs_Aggregate_Fields>;
  nodes: Array<Activity_Logs>;
};

export type Activity_Logs_Aggregate_Bool_Exp = {
  count?: InputMaybe<Activity_Logs_Aggregate_Bool_Exp_Count>;
};

export type Activity_Logs_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Activity_Logs_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<Activity_Logs_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "activity_logs" */
export type Activity_Logs_Aggregate_Fields = {
  __typename?: "activity_logs_aggregate_fields";
  count: Scalars["Int"];
  max?: Maybe<Activity_Logs_Max_Fields>;
  min?: Maybe<Activity_Logs_Min_Fields>;
};

/** aggregate fields of "activity_logs" */
export type Activity_Logs_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Activity_Logs_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "activity_logs" */
export type Activity_Logs_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Activity_Logs_Max_Order_By>;
  min?: InputMaybe<Activity_Logs_Min_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Activity_Logs_Append_Input = {
  actor?: InputMaybe<Scalars["jsonb"]>;
  object?: InputMaybe<Scalars["jsonb"]>;
  target?: InputMaybe<Scalars["jsonb"]>;
};

/** input type for inserting array relation for remote table "activity_logs" */
export type Activity_Logs_Arr_Rel_Insert_Input = {
  data: Array<Activity_Logs_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Activity_Logs_On_Conflict>;
};

/** Boolean expression to filter rows from the table "activity_logs". All fields are combined with a logical 'AND'. */
export type Activity_Logs_Bool_Exp = {
  _and?: InputMaybe<Array<Activity_Logs_Bool_Exp>>;
  _not?: InputMaybe<Activity_Logs_Bool_Exp>;
  _or?: InputMaybe<Array<Activity_Logs_Bool_Exp>>;
  actor?: InputMaybe<Jsonb_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  event_timestamp?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  object?: InputMaybe<Jsonb_Comparison_Exp>;
  organisation_id?: InputMaybe<Uuid_Comparison_Exp>;
  render_template?: InputMaybe<String_Comparison_Exp>;
  service?: InputMaybe<String_Comparison_Exp>;
  target?: InputMaybe<Jsonb_Comparison_Exp>;
  verb?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "activity_logs" */
export enum Activity_Logs_Constraint {
  /** unique or primary key constraint on columns "id" */
  ActivityLogsPkey = "activity_logs_pkey",
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Activity_Logs_Delete_At_Path_Input = {
  actor?: InputMaybe<Array<Scalars["String"]>>;
  object?: InputMaybe<Array<Scalars["String"]>>;
  target?: InputMaybe<Array<Scalars["String"]>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Activity_Logs_Delete_Elem_Input = {
  actor?: InputMaybe<Scalars["Int"]>;
  object?: InputMaybe<Scalars["Int"]>;
  target?: InputMaybe<Scalars["Int"]>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Activity_Logs_Delete_Key_Input = {
  actor?: InputMaybe<Scalars["String"]>;
  object?: InputMaybe<Scalars["String"]>;
  target?: InputMaybe<Scalars["String"]>;
};

/** input type for inserting data into table "activity_logs" */
export type Activity_Logs_Insert_Input = {
  actor?: InputMaybe<Scalars["jsonb"]>;
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  event_timestamp?: InputMaybe<Scalars["timestamptz"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  object?: InputMaybe<Scalars["jsonb"]>;
  organisation_id?: InputMaybe<Scalars["uuid"]>;
  render_template?: InputMaybe<Scalars["String"]>;
  service?: InputMaybe<Scalars["String"]>;
  target?: InputMaybe<Scalars["jsonb"]>;
  verb?: InputMaybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Activity_Logs_Max_Fields = {
  __typename?: "activity_logs_max_fields";
  created_at?: Maybe<Scalars["timestamptz"]>;
  event_timestamp?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["uuid"]>;
  organisation_id?: Maybe<Scalars["uuid"]>;
  render_template?: Maybe<Scalars["String"]>;
  service?: Maybe<Scalars["String"]>;
  verb?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "activity_logs" */
export type Activity_Logs_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  event_timestamp?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  organisation_id?: InputMaybe<Order_By>;
  render_template?: InputMaybe<Order_By>;
  service?: InputMaybe<Order_By>;
  verb?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Activity_Logs_Min_Fields = {
  __typename?: "activity_logs_min_fields";
  created_at?: Maybe<Scalars["timestamptz"]>;
  event_timestamp?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["uuid"]>;
  organisation_id?: Maybe<Scalars["uuid"]>;
  render_template?: Maybe<Scalars["String"]>;
  service?: Maybe<Scalars["String"]>;
  verb?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "activity_logs" */
export type Activity_Logs_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  event_timestamp?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  organisation_id?: InputMaybe<Order_By>;
  render_template?: InputMaybe<Order_By>;
  service?: InputMaybe<Order_By>;
  verb?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "activity_logs" */
export type Activity_Logs_Mutation_Response = {
  __typename?: "activity_logs_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Activity_Logs>;
};

/** on_conflict condition type for table "activity_logs" */
export type Activity_Logs_On_Conflict = {
  constraint: Activity_Logs_Constraint;
  update_columns?: Array<Activity_Logs_Update_Column>;
  where?: InputMaybe<Activity_Logs_Bool_Exp>;
};

/** Ordering options when selecting data from "activity_logs". */
export type Activity_Logs_Order_By = {
  actor?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  event_timestamp?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  object?: InputMaybe<Order_By>;
  organisation_id?: InputMaybe<Order_By>;
  render_template?: InputMaybe<Order_By>;
  service?: InputMaybe<Order_By>;
  target?: InputMaybe<Order_By>;
  verb?: InputMaybe<Order_By>;
};

/** primary key columns input for table: activity_logs */
export type Activity_Logs_Pk_Columns_Input = {
  id: Scalars["uuid"];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Activity_Logs_Prepend_Input = {
  actor?: InputMaybe<Scalars["jsonb"]>;
  object?: InputMaybe<Scalars["jsonb"]>;
  target?: InputMaybe<Scalars["jsonb"]>;
};

/** select columns of table "activity_logs" */
export enum Activity_Logs_Select_Column {
  /** column name */
  Actor = "actor",
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  EventTimestamp = "event_timestamp",
  /** column name */
  Id = "id",
  /** column name */
  Object = "object",
  /** column name */
  OrganisationId = "organisation_id",
  /** column name */
  RenderTemplate = "render_template",
  /** column name */
  Service = "service",
  /** column name */
  Target = "target",
  /** column name */
  Verb = "verb",
}

/** input type for updating data in table "activity_logs" */
export type Activity_Logs_Set_Input = {
  actor?: InputMaybe<Scalars["jsonb"]>;
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  event_timestamp?: InputMaybe<Scalars["timestamptz"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  object?: InputMaybe<Scalars["jsonb"]>;
  organisation_id?: InputMaybe<Scalars["uuid"]>;
  render_template?: InputMaybe<Scalars["String"]>;
  service?: InputMaybe<Scalars["String"]>;
  target?: InputMaybe<Scalars["jsonb"]>;
  verb?: InputMaybe<Scalars["String"]>;
};

/** Streaming cursor of the table "activity_logs" */
export type Activity_Logs_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Activity_Logs_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Activity_Logs_Stream_Cursor_Value_Input = {
  actor?: InputMaybe<Scalars["jsonb"]>;
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  event_timestamp?: InputMaybe<Scalars["timestamptz"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  object?: InputMaybe<Scalars["jsonb"]>;
  organisation_id?: InputMaybe<Scalars["uuid"]>;
  render_template?: InputMaybe<Scalars["String"]>;
  service?: InputMaybe<Scalars["String"]>;
  target?: InputMaybe<Scalars["jsonb"]>;
  verb?: InputMaybe<Scalars["String"]>;
};

/** update columns of table "activity_logs" */
export enum Activity_Logs_Update_Column {
  /** column name */
  Actor = "actor",
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  EventTimestamp = "event_timestamp",
  /** column name */
  Id = "id",
  /** column name */
  Object = "object",
  /** column name */
  OrganisationId = "organisation_id",
  /** column name */
  RenderTemplate = "render_template",
  /** column name */
  Service = "service",
  /** column name */
  Target = "target",
  /** column name */
  Verb = "verb",
}

export type Activity_Logs_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Activity_Logs_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Activity_Logs_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Activity_Logs_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Activity_Logs_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Activity_Logs_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Activity_Logs_Set_Input>;
  /** filter the rows which have to be updated */
  where: Activity_Logs_Bool_Exp;
};

/** columns and relationships of "audit_logs" */
export type Audit_Logs = {
  __typename?: "audit_logs";
  change_data?: Maybe<Scalars["jsonb"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  event_name: Scalars["String"];
  id: Scalars["uuid"];
  organisation_id?: Maybe<Scalars["uuid"]>;
  reason?: Maybe<Scalars["String"]>;
  resource: Scalars["String"];
  service: Scalars["String"];
  user_id?: Maybe<Scalars["uuid"]>;
};

/** columns and relationships of "audit_logs" */
export type Audit_LogsChange_DataArgs = {
  path?: InputMaybe<Scalars["String"]>;
};

/** aggregated selection of "audit_logs" */
export type Audit_Logs_Aggregate = {
  __typename?: "audit_logs_aggregate";
  aggregate?: Maybe<Audit_Logs_Aggregate_Fields>;
  nodes: Array<Audit_Logs>;
};

export type Audit_Logs_Aggregate_Bool_Exp = {
  count?: InputMaybe<Audit_Logs_Aggregate_Bool_Exp_Count>;
};

export type Audit_Logs_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Audit_Logs_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<Audit_Logs_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "audit_logs" */
export type Audit_Logs_Aggregate_Fields = {
  __typename?: "audit_logs_aggregate_fields";
  count: Scalars["Int"];
  max?: Maybe<Audit_Logs_Max_Fields>;
  min?: Maybe<Audit_Logs_Min_Fields>;
};

/** aggregate fields of "audit_logs" */
export type Audit_Logs_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Audit_Logs_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "audit_logs" */
export type Audit_Logs_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Audit_Logs_Max_Order_By>;
  min?: InputMaybe<Audit_Logs_Min_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Audit_Logs_Append_Input = {
  change_data?: InputMaybe<Scalars["jsonb"]>;
};

/** input type for inserting array relation for remote table "audit_logs" */
export type Audit_Logs_Arr_Rel_Insert_Input = {
  data: Array<Audit_Logs_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Audit_Logs_On_Conflict>;
};

/** Boolean expression to filter rows from the table "audit_logs". All fields are combined with a logical 'AND'. */
export type Audit_Logs_Bool_Exp = {
  _and?: InputMaybe<Array<Audit_Logs_Bool_Exp>>;
  _not?: InputMaybe<Audit_Logs_Bool_Exp>;
  _or?: InputMaybe<Array<Audit_Logs_Bool_Exp>>;
  change_data?: InputMaybe<Jsonb_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  event_name?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  organisation_id?: InputMaybe<Uuid_Comparison_Exp>;
  reason?: InputMaybe<String_Comparison_Exp>;
  resource?: InputMaybe<String_Comparison_Exp>;
  service?: InputMaybe<String_Comparison_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "audit_logs" */
export enum Audit_Logs_Constraint {
  /** unique or primary key constraint on columns "id" */
  AuditLogsPkey = "audit_logs_pkey",
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Audit_Logs_Delete_At_Path_Input = {
  change_data?: InputMaybe<Array<Scalars["String"]>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Audit_Logs_Delete_Elem_Input = {
  change_data?: InputMaybe<Scalars["Int"]>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Audit_Logs_Delete_Key_Input = {
  change_data?: InputMaybe<Scalars["String"]>;
};

/** input type for inserting data into table "audit_logs" */
export type Audit_Logs_Insert_Input = {
  change_data?: InputMaybe<Scalars["jsonb"]>;
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  event_name?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  organisation_id?: InputMaybe<Scalars["uuid"]>;
  reason?: InputMaybe<Scalars["String"]>;
  resource?: InputMaybe<Scalars["String"]>;
  service?: InputMaybe<Scalars["String"]>;
  user_id?: InputMaybe<Scalars["uuid"]>;
};

/** aggregate max on columns */
export type Audit_Logs_Max_Fields = {
  __typename?: "audit_logs_max_fields";
  created_at?: Maybe<Scalars["timestamptz"]>;
  event_name?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["uuid"]>;
  organisation_id?: Maybe<Scalars["uuid"]>;
  reason?: Maybe<Scalars["String"]>;
  resource?: Maybe<Scalars["String"]>;
  service?: Maybe<Scalars["String"]>;
  user_id?: Maybe<Scalars["uuid"]>;
};

/** order by max() on columns of table "audit_logs" */
export type Audit_Logs_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  event_name?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  organisation_id?: InputMaybe<Order_By>;
  reason?: InputMaybe<Order_By>;
  resource?: InputMaybe<Order_By>;
  service?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Audit_Logs_Min_Fields = {
  __typename?: "audit_logs_min_fields";
  created_at?: Maybe<Scalars["timestamptz"]>;
  event_name?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["uuid"]>;
  organisation_id?: Maybe<Scalars["uuid"]>;
  reason?: Maybe<Scalars["String"]>;
  resource?: Maybe<Scalars["String"]>;
  service?: Maybe<Scalars["String"]>;
  user_id?: Maybe<Scalars["uuid"]>;
};

/** order by min() on columns of table "audit_logs" */
export type Audit_Logs_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  event_name?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  organisation_id?: InputMaybe<Order_By>;
  reason?: InputMaybe<Order_By>;
  resource?: InputMaybe<Order_By>;
  service?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "audit_logs" */
export type Audit_Logs_Mutation_Response = {
  __typename?: "audit_logs_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Audit_Logs>;
};

/** on_conflict condition type for table "audit_logs" */
export type Audit_Logs_On_Conflict = {
  constraint: Audit_Logs_Constraint;
  update_columns?: Array<Audit_Logs_Update_Column>;
  where?: InputMaybe<Audit_Logs_Bool_Exp>;
};

/** Ordering options when selecting data from "audit_logs". */
export type Audit_Logs_Order_By = {
  change_data?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  event_name?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  organisation_id?: InputMaybe<Order_By>;
  reason?: InputMaybe<Order_By>;
  resource?: InputMaybe<Order_By>;
  service?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: audit_logs */
export type Audit_Logs_Pk_Columns_Input = {
  id: Scalars["uuid"];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Audit_Logs_Prepend_Input = {
  change_data?: InputMaybe<Scalars["jsonb"]>;
};

/** select columns of table "audit_logs" */
export enum Audit_Logs_Select_Column {
  /** column name */
  ChangeData = "change_data",
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  EventName = "event_name",
  /** column name */
  Id = "id",
  /** column name */
  OrganisationId = "organisation_id",
  /** column name */
  Reason = "reason",
  /** column name */
  Resource = "resource",
  /** column name */
  Service = "service",
  /** column name */
  UserId = "user_id",
}

/** input type for updating data in table "audit_logs" */
export type Audit_Logs_Set_Input = {
  change_data?: InputMaybe<Scalars["jsonb"]>;
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  event_name?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  organisation_id?: InputMaybe<Scalars["uuid"]>;
  reason?: InputMaybe<Scalars["String"]>;
  resource?: InputMaybe<Scalars["String"]>;
  service?: InputMaybe<Scalars["String"]>;
  user_id?: InputMaybe<Scalars["uuid"]>;
};

/** Streaming cursor of the table "audit_logs" */
export type Audit_Logs_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Audit_Logs_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Audit_Logs_Stream_Cursor_Value_Input = {
  change_data?: InputMaybe<Scalars["jsonb"]>;
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  event_name?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  organisation_id?: InputMaybe<Scalars["uuid"]>;
  reason?: InputMaybe<Scalars["String"]>;
  resource?: InputMaybe<Scalars["String"]>;
  service?: InputMaybe<Scalars["String"]>;
  user_id?: InputMaybe<Scalars["uuid"]>;
};

/** update columns of table "audit_logs" */
export enum Audit_Logs_Update_Column {
  /** column name */
  ChangeData = "change_data",
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  EventName = "event_name",
  /** column name */
  Id = "id",
  /** column name */
  OrganisationId = "organisation_id",
  /** column name */
  Reason = "reason",
  /** column name */
  Resource = "resource",
  /** column name */
  Service = "service",
  /** column name */
  UserId = "user_id",
}

export type Audit_Logs_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Audit_Logs_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Audit_Logs_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Audit_Logs_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Audit_Logs_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Audit_Logs_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Audit_Logs_Set_Input>;
  /** filter the rows which have to be updated */
  where: Audit_Logs_Bool_Exp;
};

/** Boolean expression to compare columns of type "bigint". All fields are combined with logical 'AND'. */
export type Bigint_Comparison_Exp = {
  _eq?: InputMaybe<Scalars["bigint"]>;
  _gt?: InputMaybe<Scalars["bigint"]>;
  _gte?: InputMaybe<Scalars["bigint"]>;
  _in?: InputMaybe<Array<Scalars["bigint"]>>;
  _is_null?: InputMaybe<Scalars["Boolean"]>;
  _lt?: InputMaybe<Scalars["bigint"]>;
  _lte?: InputMaybe<Scalars["bigint"]>;
  _neq?: InputMaybe<Scalars["bigint"]>;
  _nin?: InputMaybe<Array<Scalars["bigint"]>>;
};

/** columns and relationships of "campaign_contacts" */
export type Campaign_Contacts = {
  __typename?: "campaign_contacts";
  /** An object relationship */
  campaign: Campaigns;
  campaign_id: Scalars["uuid"];
  /** An object relationship */
  contact: Contacts;
  contact_id: Scalars["uuid"];
  created_at: Scalars["timestamptz"];
  id: Scalars["uuid"];
  /** An object relationship */
  status: Statuses;
  status_id: Scalars["uuid"];
  updated_at: Scalars["timestamptz"];
};

/** aggregated selection of "campaign_contacts" */
export type Campaign_Contacts_Aggregate = {
  __typename?: "campaign_contacts_aggregate";
  aggregate?: Maybe<Campaign_Contacts_Aggregate_Fields>;
  nodes: Array<Campaign_Contacts>;
};

export type Campaign_Contacts_Aggregate_Bool_Exp = {
  count?: InputMaybe<Campaign_Contacts_Aggregate_Bool_Exp_Count>;
};

export type Campaign_Contacts_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Campaign_Contacts_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<Campaign_Contacts_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "campaign_contacts" */
export type Campaign_Contacts_Aggregate_Fields = {
  __typename?: "campaign_contacts_aggregate_fields";
  count: Scalars["Int"];
  max?: Maybe<Campaign_Contacts_Max_Fields>;
  min?: Maybe<Campaign_Contacts_Min_Fields>;
};

/** aggregate fields of "campaign_contacts" */
export type Campaign_Contacts_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Campaign_Contacts_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "campaign_contacts" */
export type Campaign_Contacts_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Campaign_Contacts_Max_Order_By>;
  min?: InputMaybe<Campaign_Contacts_Min_Order_By>;
};

/** input type for inserting array relation for remote table "campaign_contacts" */
export type Campaign_Contacts_Arr_Rel_Insert_Input = {
  data: Array<Campaign_Contacts_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Campaign_Contacts_On_Conflict>;
};

/** Boolean expression to filter rows from the table "campaign_contacts". All fields are combined with a logical 'AND'. */
export type Campaign_Contacts_Bool_Exp = {
  _and?: InputMaybe<Array<Campaign_Contacts_Bool_Exp>>;
  _not?: InputMaybe<Campaign_Contacts_Bool_Exp>;
  _or?: InputMaybe<Array<Campaign_Contacts_Bool_Exp>>;
  campaign?: InputMaybe<Campaigns_Bool_Exp>;
  campaign_id?: InputMaybe<Uuid_Comparison_Exp>;
  contact?: InputMaybe<Contacts_Bool_Exp>;
  contact_id?: InputMaybe<Uuid_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  status?: InputMaybe<Statuses_Bool_Exp>;
  status_id?: InputMaybe<Uuid_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "campaign_contacts" */
export enum Campaign_Contacts_Constraint {
  /** unique or primary key constraint on columns "id" */
  CampaignContactsPkey = "campaign_contacts_pkey",
}

/** input type for inserting data into table "campaign_contacts" */
export type Campaign_Contacts_Insert_Input = {
  campaign?: InputMaybe<Campaigns_Obj_Rel_Insert_Input>;
  campaign_id?: InputMaybe<Scalars["uuid"]>;
  contact?: InputMaybe<Contacts_Obj_Rel_Insert_Input>;
  contact_id?: InputMaybe<Scalars["uuid"]>;
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  status?: InputMaybe<Statuses_Obj_Rel_Insert_Input>;
  status_id?: InputMaybe<Scalars["uuid"]>;
  updated_at?: InputMaybe<Scalars["timestamptz"]>;
};

/** aggregate max on columns */
export type Campaign_Contacts_Max_Fields = {
  __typename?: "campaign_contacts_max_fields";
  campaign_id?: Maybe<Scalars["uuid"]>;
  contact_id?: Maybe<Scalars["uuid"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["uuid"]>;
  status_id?: Maybe<Scalars["uuid"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
};

/** order by max() on columns of table "campaign_contacts" */
export type Campaign_Contacts_Max_Order_By = {
  campaign_id?: InputMaybe<Order_By>;
  contact_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  status_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Campaign_Contacts_Min_Fields = {
  __typename?: "campaign_contacts_min_fields";
  campaign_id?: Maybe<Scalars["uuid"]>;
  contact_id?: Maybe<Scalars["uuid"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["uuid"]>;
  status_id?: Maybe<Scalars["uuid"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
};

/** order by min() on columns of table "campaign_contacts" */
export type Campaign_Contacts_Min_Order_By = {
  campaign_id?: InputMaybe<Order_By>;
  contact_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  status_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "campaign_contacts" */
export type Campaign_Contacts_Mutation_Response = {
  __typename?: "campaign_contacts_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Campaign_Contacts>;
};

/** on_conflict condition type for table "campaign_contacts" */
export type Campaign_Contacts_On_Conflict = {
  constraint: Campaign_Contacts_Constraint;
  update_columns?: Array<Campaign_Contacts_Update_Column>;
  where?: InputMaybe<Campaign_Contacts_Bool_Exp>;
};

/** Ordering options when selecting data from "campaign_contacts". */
export type Campaign_Contacts_Order_By = {
  campaign?: InputMaybe<Campaigns_Order_By>;
  campaign_id?: InputMaybe<Order_By>;
  contact?: InputMaybe<Contacts_Order_By>;
  contact_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  status?: InputMaybe<Statuses_Order_By>;
  status_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: campaign_contacts */
export type Campaign_Contacts_Pk_Columns_Input = {
  id: Scalars["uuid"];
};

/** select columns of table "campaign_contacts" */
export enum Campaign_Contacts_Select_Column {
  /** column name */
  CampaignId = "campaign_id",
  /** column name */
  ContactId = "contact_id",
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  Id = "id",
  /** column name */
  StatusId = "status_id",
  /** column name */
  UpdatedAt = "updated_at",
}

/** input type for updating data in table "campaign_contacts" */
export type Campaign_Contacts_Set_Input = {
  campaign_id?: InputMaybe<Scalars["uuid"]>;
  contact_id?: InputMaybe<Scalars["uuid"]>;
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  status_id?: InputMaybe<Scalars["uuid"]>;
  updated_at?: InputMaybe<Scalars["timestamptz"]>;
};

/** Streaming cursor of the table "campaign_contacts" */
export type Campaign_Contacts_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Campaign_Contacts_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Campaign_Contacts_Stream_Cursor_Value_Input = {
  campaign_id?: InputMaybe<Scalars["uuid"]>;
  contact_id?: InputMaybe<Scalars["uuid"]>;
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  status_id?: InputMaybe<Scalars["uuid"]>;
  updated_at?: InputMaybe<Scalars["timestamptz"]>;
};

/** update columns of table "campaign_contacts" */
export enum Campaign_Contacts_Update_Column {
  /** column name */
  CampaignId = "campaign_id",
  /** column name */
  ContactId = "contact_id",
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  Id = "id",
  /** column name */
  StatusId = "status_id",
  /** column name */
  UpdatedAt = "updated_at",
}

export type Campaign_Contacts_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Campaign_Contacts_Set_Input>;
  /** filter the rows which have to be updated */
  where: Campaign_Contacts_Bool_Exp;
};

/** columns and relationships of "campaign_performance_indicators" */
export type Campaign_Performance_Indicators = {
  __typename?: "campaign_performance_indicators";
  /** An object relationship */
  campaign: Campaigns;
  campaign_id: Scalars["uuid"];
  created_at: Scalars["timestamptz"];
  id: Scalars["uuid"];
  is_active: Scalars["Boolean"];
  /** An object relationship */
  performance_indicator: Performance_Indicators;
  performance_indicator_id: Scalars["uuid"];
  updated_at: Scalars["timestamptz"];
};

/** aggregated selection of "campaign_performance_indicators" */
export type Campaign_Performance_Indicators_Aggregate = {
  __typename?: "campaign_performance_indicators_aggregate";
  aggregate?: Maybe<Campaign_Performance_Indicators_Aggregate_Fields>;
  nodes: Array<Campaign_Performance_Indicators>;
};

export type Campaign_Performance_Indicators_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Campaign_Performance_Indicators_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Campaign_Performance_Indicators_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Campaign_Performance_Indicators_Aggregate_Bool_Exp_Count>;
};

export type Campaign_Performance_Indicators_Aggregate_Bool_Exp_Bool_And = {
  arguments: Campaign_Performance_Indicators_Select_Column_Campaign_Performance_Indicators_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<Campaign_Performance_Indicators_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Campaign_Performance_Indicators_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Campaign_Performance_Indicators_Select_Column_Campaign_Performance_Indicators_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<Campaign_Performance_Indicators_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Campaign_Performance_Indicators_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Campaign_Performance_Indicators_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<Campaign_Performance_Indicators_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "campaign_performance_indicators" */
export type Campaign_Performance_Indicators_Aggregate_Fields = {
  __typename?: "campaign_performance_indicators_aggregate_fields";
  count: Scalars["Int"];
  max?: Maybe<Campaign_Performance_Indicators_Max_Fields>;
  min?: Maybe<Campaign_Performance_Indicators_Min_Fields>;
};

/** aggregate fields of "campaign_performance_indicators" */
export type Campaign_Performance_Indicators_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Campaign_Performance_Indicators_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "campaign_performance_indicators" */
export type Campaign_Performance_Indicators_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Campaign_Performance_Indicators_Max_Order_By>;
  min?: InputMaybe<Campaign_Performance_Indicators_Min_Order_By>;
};

/** input type for inserting array relation for remote table "campaign_performance_indicators" */
export type Campaign_Performance_Indicators_Arr_Rel_Insert_Input = {
  data: Array<Campaign_Performance_Indicators_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Campaign_Performance_Indicators_On_Conflict>;
};

/** Boolean expression to filter rows from the table "campaign_performance_indicators". All fields are combined with a logical 'AND'. */
export type Campaign_Performance_Indicators_Bool_Exp = {
  _and?: InputMaybe<Array<Campaign_Performance_Indicators_Bool_Exp>>;
  _not?: InputMaybe<Campaign_Performance_Indicators_Bool_Exp>;
  _or?: InputMaybe<Array<Campaign_Performance_Indicators_Bool_Exp>>;
  campaign?: InputMaybe<Campaigns_Bool_Exp>;
  campaign_id?: InputMaybe<Uuid_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  is_active?: InputMaybe<Boolean_Comparison_Exp>;
  performance_indicator?: InputMaybe<Performance_Indicators_Bool_Exp>;
  performance_indicator_id?: InputMaybe<Uuid_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "campaign_performance_indicators" */
export enum Campaign_Performance_Indicators_Constraint {
  /** unique or primary key constraint on columns "id" */
  CampaignPerformanceIndicatorsPkey = "campaign_performance_indicators_pkey",
}

/** input type for inserting data into table "campaign_performance_indicators" */
export type Campaign_Performance_Indicators_Insert_Input = {
  campaign?: InputMaybe<Campaigns_Obj_Rel_Insert_Input>;
  campaign_id?: InputMaybe<Scalars["uuid"]>;
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  is_active?: InputMaybe<Scalars["Boolean"]>;
  performance_indicator?: InputMaybe<Performance_Indicators_Obj_Rel_Insert_Input>;
  performance_indicator_id?: InputMaybe<Scalars["uuid"]>;
  updated_at?: InputMaybe<Scalars["timestamptz"]>;
};

/** aggregate max on columns */
export type Campaign_Performance_Indicators_Max_Fields = {
  __typename?: "campaign_performance_indicators_max_fields";
  campaign_id?: Maybe<Scalars["uuid"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["uuid"]>;
  performance_indicator_id?: Maybe<Scalars["uuid"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
};

/** order by max() on columns of table "campaign_performance_indicators" */
export type Campaign_Performance_Indicators_Max_Order_By = {
  campaign_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  performance_indicator_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Campaign_Performance_Indicators_Min_Fields = {
  __typename?: "campaign_performance_indicators_min_fields";
  campaign_id?: Maybe<Scalars["uuid"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["uuid"]>;
  performance_indicator_id?: Maybe<Scalars["uuid"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
};

/** order by min() on columns of table "campaign_performance_indicators" */
export type Campaign_Performance_Indicators_Min_Order_By = {
  campaign_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  performance_indicator_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "campaign_performance_indicators" */
export type Campaign_Performance_Indicators_Mutation_Response = {
  __typename?: "campaign_performance_indicators_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Campaign_Performance_Indicators>;
};

/** on_conflict condition type for table "campaign_performance_indicators" */
export type Campaign_Performance_Indicators_On_Conflict = {
  constraint: Campaign_Performance_Indicators_Constraint;
  update_columns?: Array<Campaign_Performance_Indicators_Update_Column>;
  where?: InputMaybe<Campaign_Performance_Indicators_Bool_Exp>;
};

/** Ordering options when selecting data from "campaign_performance_indicators". */
export type Campaign_Performance_Indicators_Order_By = {
  campaign?: InputMaybe<Campaigns_Order_By>;
  campaign_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  is_active?: InputMaybe<Order_By>;
  performance_indicator?: InputMaybe<Performance_Indicators_Order_By>;
  performance_indicator_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: campaign_performance_indicators */
export type Campaign_Performance_Indicators_Pk_Columns_Input = {
  id: Scalars["uuid"];
};

/** select columns of table "campaign_performance_indicators" */
export enum Campaign_Performance_Indicators_Select_Column {
  /** column name */
  CampaignId = "campaign_id",
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  Id = "id",
  /** column name */
  IsActive = "is_active",
  /** column name */
  PerformanceIndicatorId = "performance_indicator_id",
  /** column name */
  UpdatedAt = "updated_at",
}

/** select "campaign_performance_indicators_aggregate_bool_exp_bool_and_arguments_columns" columns of table "campaign_performance_indicators" */
export enum Campaign_Performance_Indicators_Select_Column_Campaign_Performance_Indicators_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  IsActive = "is_active",
}

/** select "campaign_performance_indicators_aggregate_bool_exp_bool_or_arguments_columns" columns of table "campaign_performance_indicators" */
export enum Campaign_Performance_Indicators_Select_Column_Campaign_Performance_Indicators_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  IsActive = "is_active",
}

/** input type for updating data in table "campaign_performance_indicators" */
export type Campaign_Performance_Indicators_Set_Input = {
  campaign_id?: InputMaybe<Scalars["uuid"]>;
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  is_active?: InputMaybe<Scalars["Boolean"]>;
  performance_indicator_id?: InputMaybe<Scalars["uuid"]>;
  updated_at?: InputMaybe<Scalars["timestamptz"]>;
};

/** Streaming cursor of the table "campaign_performance_indicators" */
export type Campaign_Performance_Indicators_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Campaign_Performance_Indicators_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Campaign_Performance_Indicators_Stream_Cursor_Value_Input = {
  campaign_id?: InputMaybe<Scalars["uuid"]>;
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  is_active?: InputMaybe<Scalars["Boolean"]>;
  performance_indicator_id?: InputMaybe<Scalars["uuid"]>;
  updated_at?: InputMaybe<Scalars["timestamptz"]>;
};

/** update columns of table "campaign_performance_indicators" */
export enum Campaign_Performance_Indicators_Update_Column {
  /** column name */
  CampaignId = "campaign_id",
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  Id = "id",
  /** column name */
  IsActive = "is_active",
  /** column name */
  PerformanceIndicatorId = "performance_indicator_id",
  /** column name */
  UpdatedAt = "updated_at",
}

export type Campaign_Performance_Indicators_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Campaign_Performance_Indicators_Set_Input>;
  /** filter the rows which have to be updated */
  where: Campaign_Performance_Indicators_Bool_Exp;
};

/** columns and relationships of "campaign_rewards" */
export type Campaign_Rewards = {
  __typename?: "campaign_rewards";
  /** An object relationship */
  campaign: Campaigns;
  campaign_id: Scalars["uuid"];
  created_at: Scalars["timestamptz"];
  id: Scalars["uuid"];
  /** An object relationship */
  reward: Rewards;
  reward_id: Scalars["uuid"];
  /** An object relationship */
  status: Statuses;
  status_id: Scalars["uuid"];
  updated_at: Scalars["timestamptz"];
};

/** aggregated selection of "campaign_rewards" */
export type Campaign_Rewards_Aggregate = {
  __typename?: "campaign_rewards_aggregate";
  aggregate?: Maybe<Campaign_Rewards_Aggregate_Fields>;
  nodes: Array<Campaign_Rewards>;
};

export type Campaign_Rewards_Aggregate_Bool_Exp = {
  count?: InputMaybe<Campaign_Rewards_Aggregate_Bool_Exp_Count>;
};

export type Campaign_Rewards_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Campaign_Rewards_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<Campaign_Rewards_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "campaign_rewards" */
export type Campaign_Rewards_Aggregate_Fields = {
  __typename?: "campaign_rewards_aggregate_fields";
  count: Scalars["Int"];
  max?: Maybe<Campaign_Rewards_Max_Fields>;
  min?: Maybe<Campaign_Rewards_Min_Fields>;
};

/** aggregate fields of "campaign_rewards" */
export type Campaign_Rewards_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Campaign_Rewards_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "campaign_rewards" */
export type Campaign_Rewards_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Campaign_Rewards_Max_Order_By>;
  min?: InputMaybe<Campaign_Rewards_Min_Order_By>;
};

/** input type for inserting array relation for remote table "campaign_rewards" */
export type Campaign_Rewards_Arr_Rel_Insert_Input = {
  data: Array<Campaign_Rewards_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Campaign_Rewards_On_Conflict>;
};

/** Boolean expression to filter rows from the table "campaign_rewards". All fields are combined with a logical 'AND'. */
export type Campaign_Rewards_Bool_Exp = {
  _and?: InputMaybe<Array<Campaign_Rewards_Bool_Exp>>;
  _not?: InputMaybe<Campaign_Rewards_Bool_Exp>;
  _or?: InputMaybe<Array<Campaign_Rewards_Bool_Exp>>;
  campaign?: InputMaybe<Campaigns_Bool_Exp>;
  campaign_id?: InputMaybe<Uuid_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  reward?: InputMaybe<Rewards_Bool_Exp>;
  reward_id?: InputMaybe<Uuid_Comparison_Exp>;
  status?: InputMaybe<Statuses_Bool_Exp>;
  status_id?: InputMaybe<Uuid_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "campaign_rewards" */
export enum Campaign_Rewards_Constraint {
  /** unique or primary key constraint on columns "id" */
  CampaignRewardsPkey = "campaign_rewards_pkey",
}

/** input type for inserting data into table "campaign_rewards" */
export type Campaign_Rewards_Insert_Input = {
  campaign?: InputMaybe<Campaigns_Obj_Rel_Insert_Input>;
  campaign_id?: InputMaybe<Scalars["uuid"]>;
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  reward?: InputMaybe<Rewards_Obj_Rel_Insert_Input>;
  reward_id?: InputMaybe<Scalars["uuid"]>;
  status?: InputMaybe<Statuses_Obj_Rel_Insert_Input>;
  status_id?: InputMaybe<Scalars["uuid"]>;
  updated_at?: InputMaybe<Scalars["timestamptz"]>;
};

/** aggregate max on columns */
export type Campaign_Rewards_Max_Fields = {
  __typename?: "campaign_rewards_max_fields";
  campaign_id?: Maybe<Scalars["uuid"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["uuid"]>;
  reward_id?: Maybe<Scalars["uuid"]>;
  status_id?: Maybe<Scalars["uuid"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
};

/** order by max() on columns of table "campaign_rewards" */
export type Campaign_Rewards_Max_Order_By = {
  campaign_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  reward_id?: InputMaybe<Order_By>;
  status_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Campaign_Rewards_Min_Fields = {
  __typename?: "campaign_rewards_min_fields";
  campaign_id?: Maybe<Scalars["uuid"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["uuid"]>;
  reward_id?: Maybe<Scalars["uuid"]>;
  status_id?: Maybe<Scalars["uuid"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
};

/** order by min() on columns of table "campaign_rewards" */
export type Campaign_Rewards_Min_Order_By = {
  campaign_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  reward_id?: InputMaybe<Order_By>;
  status_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "campaign_rewards" */
export type Campaign_Rewards_Mutation_Response = {
  __typename?: "campaign_rewards_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Campaign_Rewards>;
};

/** on_conflict condition type for table "campaign_rewards" */
export type Campaign_Rewards_On_Conflict = {
  constraint: Campaign_Rewards_Constraint;
  update_columns?: Array<Campaign_Rewards_Update_Column>;
  where?: InputMaybe<Campaign_Rewards_Bool_Exp>;
};

/** Ordering options when selecting data from "campaign_rewards". */
export type Campaign_Rewards_Order_By = {
  campaign?: InputMaybe<Campaigns_Order_By>;
  campaign_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  reward?: InputMaybe<Rewards_Order_By>;
  reward_id?: InputMaybe<Order_By>;
  status?: InputMaybe<Statuses_Order_By>;
  status_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: campaign_rewards */
export type Campaign_Rewards_Pk_Columns_Input = {
  id: Scalars["uuid"];
};

/** select columns of table "campaign_rewards" */
export enum Campaign_Rewards_Select_Column {
  /** column name */
  CampaignId = "campaign_id",
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  Id = "id",
  /** column name */
  RewardId = "reward_id",
  /** column name */
  StatusId = "status_id",
  /** column name */
  UpdatedAt = "updated_at",
}

/** input type for updating data in table "campaign_rewards" */
export type Campaign_Rewards_Set_Input = {
  campaign_id?: InputMaybe<Scalars["uuid"]>;
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  reward_id?: InputMaybe<Scalars["uuid"]>;
  status_id?: InputMaybe<Scalars["uuid"]>;
  updated_at?: InputMaybe<Scalars["timestamptz"]>;
};

/** Streaming cursor of the table "campaign_rewards" */
export type Campaign_Rewards_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Campaign_Rewards_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Campaign_Rewards_Stream_Cursor_Value_Input = {
  campaign_id?: InputMaybe<Scalars["uuid"]>;
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  reward_id?: InputMaybe<Scalars["uuid"]>;
  status_id?: InputMaybe<Scalars["uuid"]>;
  updated_at?: InputMaybe<Scalars["timestamptz"]>;
};

/** update columns of table "campaign_rewards" */
export enum Campaign_Rewards_Update_Column {
  /** column name */
  CampaignId = "campaign_id",
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  Id = "id",
  /** column name */
  RewardId = "reward_id",
  /** column name */
  StatusId = "status_id",
  /** column name */
  UpdatedAt = "updated_at",
}

export type Campaign_Rewards_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Campaign_Rewards_Set_Input>;
  /** filter the rows which have to be updated */
  where: Campaign_Rewards_Bool_Exp;
};

/** columns and relationships of "campaign_rules" */
export type Campaign_Rules = {
  __typename?: "campaign_rules";
  /** An object relationship */
  campaign: Campaigns;
  campaign_id: Scalars["uuid"];
  created_at: Scalars["timestamptz"];
  id: Scalars["uuid"];
  is_active: Scalars["Boolean"];
  /** An object relationship */
  rule: Rules;
  rule_id: Scalars["uuid"];
  updated_at: Scalars["timestamptz"];
};

/** aggregated selection of "campaign_rules" */
export type Campaign_Rules_Aggregate = {
  __typename?: "campaign_rules_aggregate";
  aggregate?: Maybe<Campaign_Rules_Aggregate_Fields>;
  nodes: Array<Campaign_Rules>;
};

export type Campaign_Rules_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Campaign_Rules_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Campaign_Rules_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Campaign_Rules_Aggregate_Bool_Exp_Count>;
};

export type Campaign_Rules_Aggregate_Bool_Exp_Bool_And = {
  arguments: Campaign_Rules_Select_Column_Campaign_Rules_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<Campaign_Rules_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Campaign_Rules_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Campaign_Rules_Select_Column_Campaign_Rules_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<Campaign_Rules_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Campaign_Rules_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Campaign_Rules_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<Campaign_Rules_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "campaign_rules" */
export type Campaign_Rules_Aggregate_Fields = {
  __typename?: "campaign_rules_aggregate_fields";
  count: Scalars["Int"];
  max?: Maybe<Campaign_Rules_Max_Fields>;
  min?: Maybe<Campaign_Rules_Min_Fields>;
};

/** aggregate fields of "campaign_rules" */
export type Campaign_Rules_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Campaign_Rules_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "campaign_rules" */
export type Campaign_Rules_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Campaign_Rules_Max_Order_By>;
  min?: InputMaybe<Campaign_Rules_Min_Order_By>;
};

/** input type for inserting array relation for remote table "campaign_rules" */
export type Campaign_Rules_Arr_Rel_Insert_Input = {
  data: Array<Campaign_Rules_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Campaign_Rules_On_Conflict>;
};

/** Boolean expression to filter rows from the table "campaign_rules". All fields are combined with a logical 'AND'. */
export type Campaign_Rules_Bool_Exp = {
  _and?: InputMaybe<Array<Campaign_Rules_Bool_Exp>>;
  _not?: InputMaybe<Campaign_Rules_Bool_Exp>;
  _or?: InputMaybe<Array<Campaign_Rules_Bool_Exp>>;
  campaign?: InputMaybe<Campaigns_Bool_Exp>;
  campaign_id?: InputMaybe<Uuid_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  is_active?: InputMaybe<Boolean_Comparison_Exp>;
  rule?: InputMaybe<Rules_Bool_Exp>;
  rule_id?: InputMaybe<Uuid_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "campaign_rules" */
export enum Campaign_Rules_Constraint {
  /** unique or primary key constraint on columns "id" */
  CampaignRulesPkey = "campaign_rules_pkey",
}

/** input type for inserting data into table "campaign_rules" */
export type Campaign_Rules_Insert_Input = {
  campaign?: InputMaybe<Campaigns_Obj_Rel_Insert_Input>;
  campaign_id?: InputMaybe<Scalars["uuid"]>;
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  is_active?: InputMaybe<Scalars["Boolean"]>;
  rule?: InputMaybe<Rules_Obj_Rel_Insert_Input>;
  rule_id?: InputMaybe<Scalars["uuid"]>;
  updated_at?: InputMaybe<Scalars["timestamptz"]>;
};

/** aggregate max on columns */
export type Campaign_Rules_Max_Fields = {
  __typename?: "campaign_rules_max_fields";
  campaign_id?: Maybe<Scalars["uuid"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["uuid"]>;
  rule_id?: Maybe<Scalars["uuid"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
};

/** order by max() on columns of table "campaign_rules" */
export type Campaign_Rules_Max_Order_By = {
  campaign_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  rule_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Campaign_Rules_Min_Fields = {
  __typename?: "campaign_rules_min_fields";
  campaign_id?: Maybe<Scalars["uuid"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["uuid"]>;
  rule_id?: Maybe<Scalars["uuid"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
};

/** order by min() on columns of table "campaign_rules" */
export type Campaign_Rules_Min_Order_By = {
  campaign_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  rule_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "campaign_rules" */
export type Campaign_Rules_Mutation_Response = {
  __typename?: "campaign_rules_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Campaign_Rules>;
};

/** on_conflict condition type for table "campaign_rules" */
export type Campaign_Rules_On_Conflict = {
  constraint: Campaign_Rules_Constraint;
  update_columns?: Array<Campaign_Rules_Update_Column>;
  where?: InputMaybe<Campaign_Rules_Bool_Exp>;
};

/** Ordering options when selecting data from "campaign_rules". */
export type Campaign_Rules_Order_By = {
  campaign?: InputMaybe<Campaigns_Order_By>;
  campaign_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  is_active?: InputMaybe<Order_By>;
  rule?: InputMaybe<Rules_Order_By>;
  rule_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: campaign_rules */
export type Campaign_Rules_Pk_Columns_Input = {
  id: Scalars["uuid"];
};

/** select columns of table "campaign_rules" */
export enum Campaign_Rules_Select_Column {
  /** column name */
  CampaignId = "campaign_id",
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  Id = "id",
  /** column name */
  IsActive = "is_active",
  /** column name */
  RuleId = "rule_id",
  /** column name */
  UpdatedAt = "updated_at",
}

/** select "campaign_rules_aggregate_bool_exp_bool_and_arguments_columns" columns of table "campaign_rules" */
export enum Campaign_Rules_Select_Column_Campaign_Rules_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  IsActive = "is_active",
}

/** select "campaign_rules_aggregate_bool_exp_bool_or_arguments_columns" columns of table "campaign_rules" */
export enum Campaign_Rules_Select_Column_Campaign_Rules_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  IsActive = "is_active",
}

/** input type for updating data in table "campaign_rules" */
export type Campaign_Rules_Set_Input = {
  campaign_id?: InputMaybe<Scalars["uuid"]>;
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  is_active?: InputMaybe<Scalars["Boolean"]>;
  rule_id?: InputMaybe<Scalars["uuid"]>;
  updated_at?: InputMaybe<Scalars["timestamptz"]>;
};

/** Streaming cursor of the table "campaign_rules" */
export type Campaign_Rules_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Campaign_Rules_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Campaign_Rules_Stream_Cursor_Value_Input = {
  campaign_id?: InputMaybe<Scalars["uuid"]>;
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  is_active?: InputMaybe<Scalars["Boolean"]>;
  rule_id?: InputMaybe<Scalars["uuid"]>;
  updated_at?: InputMaybe<Scalars["timestamptz"]>;
};

/** update columns of table "campaign_rules" */
export enum Campaign_Rules_Update_Column {
  /** column name */
  CampaignId = "campaign_id",
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  Id = "id",
  /** column name */
  IsActive = "is_active",
  /** column name */
  RuleId = "rule_id",
  /** column name */
  UpdatedAt = "updated_at",
}

export type Campaign_Rules_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Campaign_Rules_Set_Input>;
  /** filter the rows which have to be updated */
  where: Campaign_Rules_Bool_Exp;
};

/** columns and relationships of "campaign_tasks" */
export type Campaign_Tasks = {
  __typename?: "campaign_tasks";
  /** An object relationship */
  campaign: Campaigns;
  campaign_id: Scalars["uuid"];
  created_at?: Maybe<Scalars["timestamptz"]>;
  id: Scalars["uuid"];
  /** An object relationship */
  task: Tasks;
  task_id: Scalars["uuid"];
};

/** aggregated selection of "campaign_tasks" */
export type Campaign_Tasks_Aggregate = {
  __typename?: "campaign_tasks_aggregate";
  aggregate?: Maybe<Campaign_Tasks_Aggregate_Fields>;
  nodes: Array<Campaign_Tasks>;
};

export type Campaign_Tasks_Aggregate_Bool_Exp = {
  count?: InputMaybe<Campaign_Tasks_Aggregate_Bool_Exp_Count>;
};

export type Campaign_Tasks_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Campaign_Tasks_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<Campaign_Tasks_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "campaign_tasks" */
export type Campaign_Tasks_Aggregate_Fields = {
  __typename?: "campaign_tasks_aggregate_fields";
  count: Scalars["Int"];
  max?: Maybe<Campaign_Tasks_Max_Fields>;
  min?: Maybe<Campaign_Tasks_Min_Fields>;
};

/** aggregate fields of "campaign_tasks" */
export type Campaign_Tasks_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Campaign_Tasks_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "campaign_tasks" */
export type Campaign_Tasks_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Campaign_Tasks_Max_Order_By>;
  min?: InputMaybe<Campaign_Tasks_Min_Order_By>;
};

/** input type for inserting array relation for remote table "campaign_tasks" */
export type Campaign_Tasks_Arr_Rel_Insert_Input = {
  data: Array<Campaign_Tasks_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Campaign_Tasks_On_Conflict>;
};

/** Boolean expression to filter rows from the table "campaign_tasks". All fields are combined with a logical 'AND'. */
export type Campaign_Tasks_Bool_Exp = {
  _and?: InputMaybe<Array<Campaign_Tasks_Bool_Exp>>;
  _not?: InputMaybe<Campaign_Tasks_Bool_Exp>;
  _or?: InputMaybe<Array<Campaign_Tasks_Bool_Exp>>;
  campaign?: InputMaybe<Campaigns_Bool_Exp>;
  campaign_id?: InputMaybe<Uuid_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  task?: InputMaybe<Tasks_Bool_Exp>;
  task_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "campaign_tasks" */
export enum Campaign_Tasks_Constraint {
  /** unique or primary key constraint on columns "id" */
  CampaignTasksPkey = "campaign_tasks_pkey",
}

/** input type for inserting data into table "campaign_tasks" */
export type Campaign_Tasks_Insert_Input = {
  campaign?: InputMaybe<Campaigns_Obj_Rel_Insert_Input>;
  campaign_id?: InputMaybe<Scalars["uuid"]>;
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  task?: InputMaybe<Tasks_Obj_Rel_Insert_Input>;
  task_id?: InputMaybe<Scalars["uuid"]>;
};

/** aggregate max on columns */
export type Campaign_Tasks_Max_Fields = {
  __typename?: "campaign_tasks_max_fields";
  campaign_id?: Maybe<Scalars["uuid"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["uuid"]>;
  task_id?: Maybe<Scalars["uuid"]>;
};

/** order by max() on columns of table "campaign_tasks" */
export type Campaign_Tasks_Max_Order_By = {
  campaign_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  task_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Campaign_Tasks_Min_Fields = {
  __typename?: "campaign_tasks_min_fields";
  campaign_id?: Maybe<Scalars["uuid"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["uuid"]>;
  task_id?: Maybe<Scalars["uuid"]>;
};

/** order by min() on columns of table "campaign_tasks" */
export type Campaign_Tasks_Min_Order_By = {
  campaign_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  task_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "campaign_tasks" */
export type Campaign_Tasks_Mutation_Response = {
  __typename?: "campaign_tasks_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Campaign_Tasks>;
};

/** on_conflict condition type for table "campaign_tasks" */
export type Campaign_Tasks_On_Conflict = {
  constraint: Campaign_Tasks_Constraint;
  update_columns?: Array<Campaign_Tasks_Update_Column>;
  where?: InputMaybe<Campaign_Tasks_Bool_Exp>;
};

/** Ordering options when selecting data from "campaign_tasks". */
export type Campaign_Tasks_Order_By = {
  campaign?: InputMaybe<Campaigns_Order_By>;
  campaign_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  task?: InputMaybe<Tasks_Order_By>;
  task_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: campaign_tasks */
export type Campaign_Tasks_Pk_Columns_Input = {
  id: Scalars["uuid"];
};

/** select columns of table "campaign_tasks" */
export enum Campaign_Tasks_Select_Column {
  /** column name */
  CampaignId = "campaign_id",
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  Id = "id",
  /** column name */
  TaskId = "task_id",
}

/** input type for updating data in table "campaign_tasks" */
export type Campaign_Tasks_Set_Input = {
  campaign_id?: InputMaybe<Scalars["uuid"]>;
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  task_id?: InputMaybe<Scalars["uuid"]>;
};

/** Streaming cursor of the table "campaign_tasks" */
export type Campaign_Tasks_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Campaign_Tasks_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Campaign_Tasks_Stream_Cursor_Value_Input = {
  campaign_id?: InputMaybe<Scalars["uuid"]>;
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  task_id?: InputMaybe<Scalars["uuid"]>;
};

/** update columns of table "campaign_tasks" */
export enum Campaign_Tasks_Update_Column {
  /** column name */
  CampaignId = "campaign_id",
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  Id = "id",
  /** column name */
  TaskId = "task_id",
}

export type Campaign_Tasks_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Campaign_Tasks_Set_Input>;
  /** filter the rows which have to be updated */
  where: Campaign_Tasks_Bool_Exp;
};

/** columns and relationships of "campaign_workflows" */
export type Campaign_Workflows = {
  __typename?: "campaign_workflows";
  /** An object relationship */
  campaign: Campaigns;
  campaign_id: Scalars["uuid"];
  created_at: Scalars["timestamptz"];
  /** An object relationship */
  form: Forms;
  form_id: Scalars["uuid"];
  id: Scalars["uuid"];
  is_active: Scalars["Boolean"];
  updated_at: Scalars["timestamptz"];
};

/** aggregated selection of "campaign_workflows" */
export type Campaign_Workflows_Aggregate = {
  __typename?: "campaign_workflows_aggregate";
  aggregate?: Maybe<Campaign_Workflows_Aggregate_Fields>;
  nodes: Array<Campaign_Workflows>;
};

export type Campaign_Workflows_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Campaign_Workflows_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Campaign_Workflows_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Campaign_Workflows_Aggregate_Bool_Exp_Count>;
};

export type Campaign_Workflows_Aggregate_Bool_Exp_Bool_And = {
  arguments: Campaign_Workflows_Select_Column_Campaign_Workflows_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<Campaign_Workflows_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Campaign_Workflows_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Campaign_Workflows_Select_Column_Campaign_Workflows_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<Campaign_Workflows_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Campaign_Workflows_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Campaign_Workflows_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<Campaign_Workflows_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "campaign_workflows" */
export type Campaign_Workflows_Aggregate_Fields = {
  __typename?: "campaign_workflows_aggregate_fields";
  count: Scalars["Int"];
  max?: Maybe<Campaign_Workflows_Max_Fields>;
  min?: Maybe<Campaign_Workflows_Min_Fields>;
};

/** aggregate fields of "campaign_workflows" */
export type Campaign_Workflows_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Campaign_Workflows_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "campaign_workflows" */
export type Campaign_Workflows_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Campaign_Workflows_Max_Order_By>;
  min?: InputMaybe<Campaign_Workflows_Min_Order_By>;
};

/** input type for inserting array relation for remote table "campaign_workflows" */
export type Campaign_Workflows_Arr_Rel_Insert_Input = {
  data: Array<Campaign_Workflows_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Campaign_Workflows_On_Conflict>;
};

/** Boolean expression to filter rows from the table "campaign_workflows". All fields are combined with a logical 'AND'. */
export type Campaign_Workflows_Bool_Exp = {
  _and?: InputMaybe<Array<Campaign_Workflows_Bool_Exp>>;
  _not?: InputMaybe<Campaign_Workflows_Bool_Exp>;
  _or?: InputMaybe<Array<Campaign_Workflows_Bool_Exp>>;
  campaign?: InputMaybe<Campaigns_Bool_Exp>;
  campaign_id?: InputMaybe<Uuid_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  form?: InputMaybe<Forms_Bool_Exp>;
  form_id?: InputMaybe<Uuid_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  is_active?: InputMaybe<Boolean_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "campaign_workflows" */
export enum Campaign_Workflows_Constraint {
  /** unique or primary key constraint on columns "id" */
  CampaignWorkflowsPkey = "campaign_workflows_pkey",
}

/** input type for inserting data into table "campaign_workflows" */
export type Campaign_Workflows_Insert_Input = {
  campaign?: InputMaybe<Campaigns_Obj_Rel_Insert_Input>;
  campaign_id?: InputMaybe<Scalars["uuid"]>;
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  form?: InputMaybe<Forms_Obj_Rel_Insert_Input>;
  form_id?: InputMaybe<Scalars["uuid"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  is_active?: InputMaybe<Scalars["Boolean"]>;
  updated_at?: InputMaybe<Scalars["timestamptz"]>;
};

/** aggregate max on columns */
export type Campaign_Workflows_Max_Fields = {
  __typename?: "campaign_workflows_max_fields";
  campaign_id?: Maybe<Scalars["uuid"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  form_id?: Maybe<Scalars["uuid"]>;
  id?: Maybe<Scalars["uuid"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
};

/** order by max() on columns of table "campaign_workflows" */
export type Campaign_Workflows_Max_Order_By = {
  campaign_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  form_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Campaign_Workflows_Min_Fields = {
  __typename?: "campaign_workflows_min_fields";
  campaign_id?: Maybe<Scalars["uuid"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  form_id?: Maybe<Scalars["uuid"]>;
  id?: Maybe<Scalars["uuid"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
};

/** order by min() on columns of table "campaign_workflows" */
export type Campaign_Workflows_Min_Order_By = {
  campaign_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  form_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "campaign_workflows" */
export type Campaign_Workflows_Mutation_Response = {
  __typename?: "campaign_workflows_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Campaign_Workflows>;
};

/** on_conflict condition type for table "campaign_workflows" */
export type Campaign_Workflows_On_Conflict = {
  constraint: Campaign_Workflows_Constraint;
  update_columns?: Array<Campaign_Workflows_Update_Column>;
  where?: InputMaybe<Campaign_Workflows_Bool_Exp>;
};

/** Ordering options when selecting data from "campaign_workflows". */
export type Campaign_Workflows_Order_By = {
  campaign?: InputMaybe<Campaigns_Order_By>;
  campaign_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  form?: InputMaybe<Forms_Order_By>;
  form_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  is_active?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: campaign_workflows */
export type Campaign_Workflows_Pk_Columns_Input = {
  id: Scalars["uuid"];
};

/** select columns of table "campaign_workflows" */
export enum Campaign_Workflows_Select_Column {
  /** column name */
  CampaignId = "campaign_id",
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  FormId = "form_id",
  /** column name */
  Id = "id",
  /** column name */
  IsActive = "is_active",
  /** column name */
  UpdatedAt = "updated_at",
}

/** select "campaign_workflows_aggregate_bool_exp_bool_and_arguments_columns" columns of table "campaign_workflows" */
export enum Campaign_Workflows_Select_Column_Campaign_Workflows_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  IsActive = "is_active",
}

/** select "campaign_workflows_aggregate_bool_exp_bool_or_arguments_columns" columns of table "campaign_workflows" */
export enum Campaign_Workflows_Select_Column_Campaign_Workflows_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  IsActive = "is_active",
}

/** input type for updating data in table "campaign_workflows" */
export type Campaign_Workflows_Set_Input = {
  campaign_id?: InputMaybe<Scalars["uuid"]>;
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  form_id?: InputMaybe<Scalars["uuid"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  is_active?: InputMaybe<Scalars["Boolean"]>;
  updated_at?: InputMaybe<Scalars["timestamptz"]>;
};

/** Streaming cursor of the table "campaign_workflows" */
export type Campaign_Workflows_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Campaign_Workflows_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Campaign_Workflows_Stream_Cursor_Value_Input = {
  campaign_id?: InputMaybe<Scalars["uuid"]>;
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  form_id?: InputMaybe<Scalars["uuid"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  is_active?: InputMaybe<Scalars["Boolean"]>;
  updated_at?: InputMaybe<Scalars["timestamptz"]>;
};

/** update columns of table "campaign_workflows" */
export enum Campaign_Workflows_Update_Column {
  /** column name */
  CampaignId = "campaign_id",
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  FormId = "form_id",
  /** column name */
  Id = "id",
  /** column name */
  IsActive = "is_active",
  /** column name */
  UpdatedAt = "updated_at",
}

export type Campaign_Workflows_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Campaign_Workflows_Set_Input>;
  /** filter the rows which have to be updated */
  where: Campaign_Workflows_Bool_Exp;
};

/** columns and relationships of "campaigns" */
export type Campaigns = {
  __typename?: "campaigns";
  /** An array relationship */
  campaign_contacts: Array<Campaign_Contacts>;
  /** An aggregate relationship */
  campaign_contacts_aggregate: Campaign_Contacts_Aggregate;
  /** An array relationship */
  campaign_performance_indicators: Array<Campaign_Performance_Indicators>;
  /** An aggregate relationship */
  campaign_performance_indicators_aggregate: Campaign_Performance_Indicators_Aggregate;
  /** An array relationship */
  campaign_rewards: Array<Campaign_Rewards>;
  /** An aggregate relationship */
  campaign_rewards_aggregate: Campaign_Rewards_Aggregate;
  /** An array relationship */
  campaign_rules: Array<Campaign_Rules>;
  /** An aggregate relationship */
  campaign_rules_aggregate: Campaign_Rules_Aggregate;
  /** An array relationship */
  campaign_tasks: Array<Campaign_Tasks>;
  /** An aggregate relationship */
  campaign_tasks_aggregate: Campaign_Tasks_Aggregate;
  /** An array relationship */
  campaign_workflows: Array<Campaign_Workflows>;
  /** An aggregate relationship */
  campaign_workflows_aggregate: Campaign_Workflows_Aggregate;
  /** An object relationship */
  channel: Channels;
  channel_id: Scalars["uuid"];
  created_at: Scalars["timestamptz"];
  description?: Maybe<Scalars["String"]>;
  id: Scalars["uuid"];
  is_active: Scalars["Boolean"];
  name: Scalars["String"];
  organisation_id?: Maybe<Scalars["uuid"]>;
  /** An object relationship */
  party: Parties;
  party_id: Scalars["uuid"];
  start_date?: Maybe<Scalars["timestamptz"]>;
  stop_date?: Maybe<Scalars["timestamptz"]>;
  /** An object relationship */
  type: Types;
  type_id: Scalars["uuid"];
  updated_at: Scalars["timestamptz"];
};

/** columns and relationships of "campaigns" */
export type CampaignsCampaign_ContactsArgs = {
  distinct_on?: InputMaybe<Array<Campaign_Contacts_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Campaign_Contacts_Order_By>>;
  where?: InputMaybe<Campaign_Contacts_Bool_Exp>;
};

/** columns and relationships of "campaigns" */
export type CampaignsCampaign_Contacts_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Campaign_Contacts_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Campaign_Contacts_Order_By>>;
  where?: InputMaybe<Campaign_Contacts_Bool_Exp>;
};

/** columns and relationships of "campaigns" */
export type CampaignsCampaign_Performance_IndicatorsArgs = {
  distinct_on?: InputMaybe<
    Array<Campaign_Performance_Indicators_Select_Column>
  >;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Campaign_Performance_Indicators_Order_By>>;
  where?: InputMaybe<Campaign_Performance_Indicators_Bool_Exp>;
};

/** columns and relationships of "campaigns" */
export type CampaignsCampaign_Performance_Indicators_AggregateArgs = {
  distinct_on?: InputMaybe<
    Array<Campaign_Performance_Indicators_Select_Column>
  >;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Campaign_Performance_Indicators_Order_By>>;
  where?: InputMaybe<Campaign_Performance_Indicators_Bool_Exp>;
};

/** columns and relationships of "campaigns" */
export type CampaignsCampaign_RewardsArgs = {
  distinct_on?: InputMaybe<Array<Campaign_Rewards_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Campaign_Rewards_Order_By>>;
  where?: InputMaybe<Campaign_Rewards_Bool_Exp>;
};

/** columns and relationships of "campaigns" */
export type CampaignsCampaign_Rewards_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Campaign_Rewards_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Campaign_Rewards_Order_By>>;
  where?: InputMaybe<Campaign_Rewards_Bool_Exp>;
};

/** columns and relationships of "campaigns" */
export type CampaignsCampaign_RulesArgs = {
  distinct_on?: InputMaybe<Array<Campaign_Rules_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Campaign_Rules_Order_By>>;
  where?: InputMaybe<Campaign_Rules_Bool_Exp>;
};

/** columns and relationships of "campaigns" */
export type CampaignsCampaign_Rules_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Campaign_Rules_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Campaign_Rules_Order_By>>;
  where?: InputMaybe<Campaign_Rules_Bool_Exp>;
};

/** columns and relationships of "campaigns" */
export type CampaignsCampaign_TasksArgs = {
  distinct_on?: InputMaybe<Array<Campaign_Tasks_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Campaign_Tasks_Order_By>>;
  where?: InputMaybe<Campaign_Tasks_Bool_Exp>;
};

/** columns and relationships of "campaigns" */
export type CampaignsCampaign_Tasks_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Campaign_Tasks_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Campaign_Tasks_Order_By>>;
  where?: InputMaybe<Campaign_Tasks_Bool_Exp>;
};

/** columns and relationships of "campaigns" */
export type CampaignsCampaign_WorkflowsArgs = {
  distinct_on?: InputMaybe<Array<Campaign_Workflows_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Campaign_Workflows_Order_By>>;
  where?: InputMaybe<Campaign_Workflows_Bool_Exp>;
};

/** columns and relationships of "campaigns" */
export type CampaignsCampaign_Workflows_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Campaign_Workflows_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Campaign_Workflows_Order_By>>;
  where?: InputMaybe<Campaign_Workflows_Bool_Exp>;
};

/** aggregated selection of "campaigns" */
export type Campaigns_Aggregate = {
  __typename?: "campaigns_aggregate";
  aggregate?: Maybe<Campaigns_Aggregate_Fields>;
  nodes: Array<Campaigns>;
};

export type Campaigns_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Campaigns_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Campaigns_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Campaigns_Aggregate_Bool_Exp_Count>;
};

export type Campaigns_Aggregate_Bool_Exp_Bool_And = {
  arguments: Campaigns_Select_Column_Campaigns_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<Campaigns_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Campaigns_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Campaigns_Select_Column_Campaigns_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<Campaigns_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Campaigns_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Campaigns_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<Campaigns_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "campaigns" */
export type Campaigns_Aggregate_Fields = {
  __typename?: "campaigns_aggregate_fields";
  count: Scalars["Int"];
  max?: Maybe<Campaigns_Max_Fields>;
  min?: Maybe<Campaigns_Min_Fields>;
};

/** aggregate fields of "campaigns" */
export type Campaigns_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Campaigns_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "campaigns" */
export type Campaigns_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Campaigns_Max_Order_By>;
  min?: InputMaybe<Campaigns_Min_Order_By>;
};

/** input type for inserting array relation for remote table "campaigns" */
export type Campaigns_Arr_Rel_Insert_Input = {
  data: Array<Campaigns_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Campaigns_On_Conflict>;
};

/** Boolean expression to filter rows from the table "campaigns". All fields are combined with a logical 'AND'. */
export type Campaigns_Bool_Exp = {
  _and?: InputMaybe<Array<Campaigns_Bool_Exp>>;
  _not?: InputMaybe<Campaigns_Bool_Exp>;
  _or?: InputMaybe<Array<Campaigns_Bool_Exp>>;
  campaign_contacts?: InputMaybe<Campaign_Contacts_Bool_Exp>;
  campaign_contacts_aggregate?: InputMaybe<Campaign_Contacts_Aggregate_Bool_Exp>;
  campaign_performance_indicators?: InputMaybe<Campaign_Performance_Indicators_Bool_Exp>;
  campaign_performance_indicators_aggregate?: InputMaybe<Campaign_Performance_Indicators_Aggregate_Bool_Exp>;
  campaign_rewards?: InputMaybe<Campaign_Rewards_Bool_Exp>;
  campaign_rewards_aggregate?: InputMaybe<Campaign_Rewards_Aggregate_Bool_Exp>;
  campaign_rules?: InputMaybe<Campaign_Rules_Bool_Exp>;
  campaign_rules_aggregate?: InputMaybe<Campaign_Rules_Aggregate_Bool_Exp>;
  campaign_tasks?: InputMaybe<Campaign_Tasks_Bool_Exp>;
  campaign_tasks_aggregate?: InputMaybe<Campaign_Tasks_Aggregate_Bool_Exp>;
  campaign_workflows?: InputMaybe<Campaign_Workflows_Bool_Exp>;
  campaign_workflows_aggregate?: InputMaybe<Campaign_Workflows_Aggregate_Bool_Exp>;
  channel?: InputMaybe<Channels_Bool_Exp>;
  channel_id?: InputMaybe<Uuid_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  is_active?: InputMaybe<Boolean_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  organisation_id?: InputMaybe<Uuid_Comparison_Exp>;
  party?: InputMaybe<Parties_Bool_Exp>;
  party_id?: InputMaybe<Uuid_Comparison_Exp>;
  start_date?: InputMaybe<Timestamptz_Comparison_Exp>;
  stop_date?: InputMaybe<Timestamptz_Comparison_Exp>;
  type?: InputMaybe<Types_Bool_Exp>;
  type_id?: InputMaybe<Uuid_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "campaigns" */
export enum Campaigns_Constraint {
  /** unique or primary key constraint on columns "id" */
  CampaignsPkey = "campaigns_pkey",
}

/** input type for inserting data into table "campaigns" */
export type Campaigns_Insert_Input = {
  campaign_contacts?: InputMaybe<Campaign_Contacts_Arr_Rel_Insert_Input>;
  campaign_performance_indicators?: InputMaybe<Campaign_Performance_Indicators_Arr_Rel_Insert_Input>;
  campaign_rewards?: InputMaybe<Campaign_Rewards_Arr_Rel_Insert_Input>;
  campaign_rules?: InputMaybe<Campaign_Rules_Arr_Rel_Insert_Input>;
  campaign_tasks?: InputMaybe<Campaign_Tasks_Arr_Rel_Insert_Input>;
  campaign_workflows?: InputMaybe<Campaign_Workflows_Arr_Rel_Insert_Input>;
  channel?: InputMaybe<Channels_Obj_Rel_Insert_Input>;
  channel_id?: InputMaybe<Scalars["uuid"]>;
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  description?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  is_active?: InputMaybe<Scalars["Boolean"]>;
  name?: InputMaybe<Scalars["String"]>;
  organisation_id?: InputMaybe<Scalars["uuid"]>;
  party?: InputMaybe<Parties_Obj_Rel_Insert_Input>;
  party_id?: InputMaybe<Scalars["uuid"]>;
  start_date?: InputMaybe<Scalars["timestamptz"]>;
  stop_date?: InputMaybe<Scalars["timestamptz"]>;
  type?: InputMaybe<Types_Obj_Rel_Insert_Input>;
  type_id?: InputMaybe<Scalars["uuid"]>;
  updated_at?: InputMaybe<Scalars["timestamptz"]>;
};

/** aggregate max on columns */
export type Campaigns_Max_Fields = {
  __typename?: "campaigns_max_fields";
  channel_id?: Maybe<Scalars["uuid"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  description?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["uuid"]>;
  name?: Maybe<Scalars["String"]>;
  organisation_id?: Maybe<Scalars["uuid"]>;
  party_id?: Maybe<Scalars["uuid"]>;
  start_date?: Maybe<Scalars["timestamptz"]>;
  stop_date?: Maybe<Scalars["timestamptz"]>;
  type_id?: Maybe<Scalars["uuid"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
};

/** order by max() on columns of table "campaigns" */
export type Campaigns_Max_Order_By = {
  channel_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  organisation_id?: InputMaybe<Order_By>;
  party_id?: InputMaybe<Order_By>;
  start_date?: InputMaybe<Order_By>;
  stop_date?: InputMaybe<Order_By>;
  type_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Campaigns_Min_Fields = {
  __typename?: "campaigns_min_fields";
  channel_id?: Maybe<Scalars["uuid"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  description?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["uuid"]>;
  name?: Maybe<Scalars["String"]>;
  organisation_id?: Maybe<Scalars["uuid"]>;
  party_id?: Maybe<Scalars["uuid"]>;
  start_date?: Maybe<Scalars["timestamptz"]>;
  stop_date?: Maybe<Scalars["timestamptz"]>;
  type_id?: Maybe<Scalars["uuid"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
};

/** order by min() on columns of table "campaigns" */
export type Campaigns_Min_Order_By = {
  channel_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  organisation_id?: InputMaybe<Order_By>;
  party_id?: InputMaybe<Order_By>;
  start_date?: InputMaybe<Order_By>;
  stop_date?: InputMaybe<Order_By>;
  type_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "campaigns" */
export type Campaigns_Mutation_Response = {
  __typename?: "campaigns_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Campaigns>;
};

/** input type for inserting object relation for remote table "campaigns" */
export type Campaigns_Obj_Rel_Insert_Input = {
  data: Campaigns_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Campaigns_On_Conflict>;
};

/** on_conflict condition type for table "campaigns" */
export type Campaigns_On_Conflict = {
  constraint: Campaigns_Constraint;
  update_columns?: Array<Campaigns_Update_Column>;
  where?: InputMaybe<Campaigns_Bool_Exp>;
};

/** Ordering options when selecting data from "campaigns". */
export type Campaigns_Order_By = {
  campaign_contacts_aggregate?: InputMaybe<Campaign_Contacts_Aggregate_Order_By>;
  campaign_performance_indicators_aggregate?: InputMaybe<Campaign_Performance_Indicators_Aggregate_Order_By>;
  campaign_rewards_aggregate?: InputMaybe<Campaign_Rewards_Aggregate_Order_By>;
  campaign_rules_aggregate?: InputMaybe<Campaign_Rules_Aggregate_Order_By>;
  campaign_tasks_aggregate?: InputMaybe<Campaign_Tasks_Aggregate_Order_By>;
  campaign_workflows_aggregate?: InputMaybe<Campaign_Workflows_Aggregate_Order_By>;
  channel?: InputMaybe<Channels_Order_By>;
  channel_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  is_active?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  organisation_id?: InputMaybe<Order_By>;
  party?: InputMaybe<Parties_Order_By>;
  party_id?: InputMaybe<Order_By>;
  start_date?: InputMaybe<Order_By>;
  stop_date?: InputMaybe<Order_By>;
  type?: InputMaybe<Types_Order_By>;
  type_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: campaigns */
export type Campaigns_Pk_Columns_Input = {
  id: Scalars["uuid"];
};

/** select columns of table "campaigns" */
export enum Campaigns_Select_Column {
  /** column name */
  ChannelId = "channel_id",
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  Description = "description",
  /** column name */
  Id = "id",
  /** column name */
  IsActive = "is_active",
  /** column name */
  Name = "name",
  /** column name */
  OrganisationId = "organisation_id",
  /** column name */
  PartyId = "party_id",
  /** column name */
  StartDate = "start_date",
  /** column name */
  StopDate = "stop_date",
  /** column name */
  TypeId = "type_id",
  /** column name */
  UpdatedAt = "updated_at",
}

/** select "campaigns_aggregate_bool_exp_bool_and_arguments_columns" columns of table "campaigns" */
export enum Campaigns_Select_Column_Campaigns_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  IsActive = "is_active",
}

/** select "campaigns_aggregate_bool_exp_bool_or_arguments_columns" columns of table "campaigns" */
export enum Campaigns_Select_Column_Campaigns_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  IsActive = "is_active",
}

/** input type for updating data in table "campaigns" */
export type Campaigns_Set_Input = {
  channel_id?: InputMaybe<Scalars["uuid"]>;
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  description?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  is_active?: InputMaybe<Scalars["Boolean"]>;
  name?: InputMaybe<Scalars["String"]>;
  organisation_id?: InputMaybe<Scalars["uuid"]>;
  party_id?: InputMaybe<Scalars["uuid"]>;
  start_date?: InputMaybe<Scalars["timestamptz"]>;
  stop_date?: InputMaybe<Scalars["timestamptz"]>;
  type_id?: InputMaybe<Scalars["uuid"]>;
  updated_at?: InputMaybe<Scalars["timestamptz"]>;
};

/** Streaming cursor of the table "campaigns" */
export type Campaigns_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Campaigns_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Campaigns_Stream_Cursor_Value_Input = {
  channel_id?: InputMaybe<Scalars["uuid"]>;
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  description?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  is_active?: InputMaybe<Scalars["Boolean"]>;
  name?: InputMaybe<Scalars["String"]>;
  organisation_id?: InputMaybe<Scalars["uuid"]>;
  party_id?: InputMaybe<Scalars["uuid"]>;
  start_date?: InputMaybe<Scalars["timestamptz"]>;
  stop_date?: InputMaybe<Scalars["timestamptz"]>;
  type_id?: InputMaybe<Scalars["uuid"]>;
  updated_at?: InputMaybe<Scalars["timestamptz"]>;
};

/** update columns of table "campaigns" */
export enum Campaigns_Update_Column {
  /** column name */
  ChannelId = "channel_id",
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  Description = "description",
  /** column name */
  Id = "id",
  /** column name */
  IsActive = "is_active",
  /** column name */
  Name = "name",
  /** column name */
  OrganisationId = "organisation_id",
  /** column name */
  PartyId = "party_id",
  /** column name */
  StartDate = "start_date",
  /** column name */
  StopDate = "stop_date",
  /** column name */
  TypeId = "type_id",
  /** column name */
  UpdatedAt = "updated_at",
}

export type Campaigns_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Campaigns_Set_Input>;
  /** filter the rows which have to be updated */
  where: Campaigns_Bool_Exp;
};

/** columns and relationships of "case_assignments" */
export type Case_Assignments = {
  __typename?: "case_assignments";
  assigned_by: Scalars["uuid"];
  assigned_to: Scalars["uuid"];
  /** An object relationship */
  case: Cases;
  case_id: Scalars["uuid"];
  created_at: Scalars["timestamptz"];
  id: Scalars["uuid"];
  is_active: Scalars["Boolean"];
  /** An object relationship */
  party: Parties;
  /** An object relationship */
  partyByAssignedTo: Parties;
  updated_at: Scalars["timestamptz"];
};

/** aggregated selection of "case_assignments" */
export type Case_Assignments_Aggregate = {
  __typename?: "case_assignments_aggregate";
  aggregate?: Maybe<Case_Assignments_Aggregate_Fields>;
  nodes: Array<Case_Assignments>;
};

export type Case_Assignments_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Case_Assignments_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Case_Assignments_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Case_Assignments_Aggregate_Bool_Exp_Count>;
};

export type Case_Assignments_Aggregate_Bool_Exp_Bool_And = {
  arguments: Case_Assignments_Select_Column_Case_Assignments_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<Case_Assignments_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Case_Assignments_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Case_Assignments_Select_Column_Case_Assignments_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<Case_Assignments_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Case_Assignments_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Case_Assignments_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<Case_Assignments_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "case_assignments" */
export type Case_Assignments_Aggregate_Fields = {
  __typename?: "case_assignments_aggregate_fields";
  count: Scalars["Int"];
  max?: Maybe<Case_Assignments_Max_Fields>;
  min?: Maybe<Case_Assignments_Min_Fields>;
};

/** aggregate fields of "case_assignments" */
export type Case_Assignments_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Case_Assignments_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "case_assignments" */
export type Case_Assignments_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Case_Assignments_Max_Order_By>;
  min?: InputMaybe<Case_Assignments_Min_Order_By>;
};

/** input type for inserting array relation for remote table "case_assignments" */
export type Case_Assignments_Arr_Rel_Insert_Input = {
  data: Array<Case_Assignments_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Case_Assignments_On_Conflict>;
};

/** Boolean expression to filter rows from the table "case_assignments". All fields are combined with a logical 'AND'. */
export type Case_Assignments_Bool_Exp = {
  _and?: InputMaybe<Array<Case_Assignments_Bool_Exp>>;
  _not?: InputMaybe<Case_Assignments_Bool_Exp>;
  _or?: InputMaybe<Array<Case_Assignments_Bool_Exp>>;
  assigned_by?: InputMaybe<Uuid_Comparison_Exp>;
  assigned_to?: InputMaybe<Uuid_Comparison_Exp>;
  case?: InputMaybe<Cases_Bool_Exp>;
  case_id?: InputMaybe<Uuid_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  is_active?: InputMaybe<Boolean_Comparison_Exp>;
  party?: InputMaybe<Parties_Bool_Exp>;
  partyByAssignedTo?: InputMaybe<Parties_Bool_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "case_assignments" */
export enum Case_Assignments_Constraint {
  /** unique or primary key constraint on columns "id" */
  CaseAssignmentsPkey = "case_assignments_pkey",
}

/** input type for inserting data into table "case_assignments" */
export type Case_Assignments_Insert_Input = {
  assigned_by?: InputMaybe<Scalars["uuid"]>;
  assigned_to?: InputMaybe<Scalars["uuid"]>;
  case?: InputMaybe<Cases_Obj_Rel_Insert_Input>;
  case_id?: InputMaybe<Scalars["uuid"]>;
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  is_active?: InputMaybe<Scalars["Boolean"]>;
  party?: InputMaybe<Parties_Obj_Rel_Insert_Input>;
  partyByAssignedTo?: InputMaybe<Parties_Obj_Rel_Insert_Input>;
  updated_at?: InputMaybe<Scalars["timestamptz"]>;
};

/** aggregate max on columns */
export type Case_Assignments_Max_Fields = {
  __typename?: "case_assignments_max_fields";
  assigned_by?: Maybe<Scalars["uuid"]>;
  assigned_to?: Maybe<Scalars["uuid"]>;
  case_id?: Maybe<Scalars["uuid"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["uuid"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
};

/** order by max() on columns of table "case_assignments" */
export type Case_Assignments_Max_Order_By = {
  assigned_by?: InputMaybe<Order_By>;
  assigned_to?: InputMaybe<Order_By>;
  case_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Case_Assignments_Min_Fields = {
  __typename?: "case_assignments_min_fields";
  assigned_by?: Maybe<Scalars["uuid"]>;
  assigned_to?: Maybe<Scalars["uuid"]>;
  case_id?: Maybe<Scalars["uuid"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["uuid"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
};

/** order by min() on columns of table "case_assignments" */
export type Case_Assignments_Min_Order_By = {
  assigned_by?: InputMaybe<Order_By>;
  assigned_to?: InputMaybe<Order_By>;
  case_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "case_assignments" */
export type Case_Assignments_Mutation_Response = {
  __typename?: "case_assignments_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Case_Assignments>;
};

/** on_conflict condition type for table "case_assignments" */
export type Case_Assignments_On_Conflict = {
  constraint: Case_Assignments_Constraint;
  update_columns?: Array<Case_Assignments_Update_Column>;
  where?: InputMaybe<Case_Assignments_Bool_Exp>;
};

/** Ordering options when selecting data from "case_assignments". */
export type Case_Assignments_Order_By = {
  assigned_by?: InputMaybe<Order_By>;
  assigned_to?: InputMaybe<Order_By>;
  case?: InputMaybe<Cases_Order_By>;
  case_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  is_active?: InputMaybe<Order_By>;
  party?: InputMaybe<Parties_Order_By>;
  partyByAssignedTo?: InputMaybe<Parties_Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: case_assignments */
export type Case_Assignments_Pk_Columns_Input = {
  id: Scalars["uuid"];
};

/** select columns of table "case_assignments" */
export enum Case_Assignments_Select_Column {
  /** column name */
  AssignedBy = "assigned_by",
  /** column name */
  AssignedTo = "assigned_to",
  /** column name */
  CaseId = "case_id",
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  Id = "id",
  /** column name */
  IsActive = "is_active",
  /** column name */
  UpdatedAt = "updated_at",
}

/** select "case_assignments_aggregate_bool_exp_bool_and_arguments_columns" columns of table "case_assignments" */
export enum Case_Assignments_Select_Column_Case_Assignments_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  IsActive = "is_active",
}

/** select "case_assignments_aggregate_bool_exp_bool_or_arguments_columns" columns of table "case_assignments" */
export enum Case_Assignments_Select_Column_Case_Assignments_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  IsActive = "is_active",
}

/** input type for updating data in table "case_assignments" */
export type Case_Assignments_Set_Input = {
  assigned_by?: InputMaybe<Scalars["uuid"]>;
  assigned_to?: InputMaybe<Scalars["uuid"]>;
  case_id?: InputMaybe<Scalars["uuid"]>;
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  is_active?: InputMaybe<Scalars["Boolean"]>;
  updated_at?: InputMaybe<Scalars["timestamptz"]>;
};

/** Streaming cursor of the table "case_assignments" */
export type Case_Assignments_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Case_Assignments_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Case_Assignments_Stream_Cursor_Value_Input = {
  assigned_by?: InputMaybe<Scalars["uuid"]>;
  assigned_to?: InputMaybe<Scalars["uuid"]>;
  case_id?: InputMaybe<Scalars["uuid"]>;
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  is_active?: InputMaybe<Scalars["Boolean"]>;
  updated_at?: InputMaybe<Scalars["timestamptz"]>;
};

/** update columns of table "case_assignments" */
export enum Case_Assignments_Update_Column {
  /** column name */
  AssignedBy = "assigned_by",
  /** column name */
  AssignedTo = "assigned_to",
  /** column name */
  CaseId = "case_id",
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  Id = "id",
  /** column name */
  IsActive = "is_active",
  /** column name */
  UpdatedAt = "updated_at",
}

export type Case_Assignments_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Case_Assignments_Set_Input>;
  /** filter the rows which have to be updated */
  where: Case_Assignments_Bool_Exp;
};

/** columns and relationships of "case_details" */
export type Case_Details = {
  __typename?: "case_details";
  /** An object relationship */
  case: Cases;
  case_id: Scalars["uuid"];
  created_at: Scalars["timestamptz"];
  /** An object relationship */
  form: Forms;
  /** An object relationship */
  form_field: Form_Fields;
  form_field_id: Scalars["uuid"];
  form_id: Scalars["uuid"];
  id: Scalars["uuid"];
  updated_at: Scalars["timestamptz"];
  value?: Maybe<Scalars["String"]>;
};

/** aggregated selection of "case_details" */
export type Case_Details_Aggregate = {
  __typename?: "case_details_aggregate";
  aggregate?: Maybe<Case_Details_Aggregate_Fields>;
  nodes: Array<Case_Details>;
};

export type Case_Details_Aggregate_Bool_Exp = {
  count?: InputMaybe<Case_Details_Aggregate_Bool_Exp_Count>;
};

export type Case_Details_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Case_Details_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<Case_Details_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "case_details" */
export type Case_Details_Aggregate_Fields = {
  __typename?: "case_details_aggregate_fields";
  count: Scalars["Int"];
  max?: Maybe<Case_Details_Max_Fields>;
  min?: Maybe<Case_Details_Min_Fields>;
};

/** aggregate fields of "case_details" */
export type Case_Details_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Case_Details_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "case_details" */
export type Case_Details_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Case_Details_Max_Order_By>;
  min?: InputMaybe<Case_Details_Min_Order_By>;
};

/** input type for inserting array relation for remote table "case_details" */
export type Case_Details_Arr_Rel_Insert_Input = {
  data: Array<Case_Details_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Case_Details_On_Conflict>;
};

/** Boolean expression to filter rows from the table "case_details". All fields are combined with a logical 'AND'. */
export type Case_Details_Bool_Exp = {
  _and?: InputMaybe<Array<Case_Details_Bool_Exp>>;
  _not?: InputMaybe<Case_Details_Bool_Exp>;
  _or?: InputMaybe<Array<Case_Details_Bool_Exp>>;
  case?: InputMaybe<Cases_Bool_Exp>;
  case_id?: InputMaybe<Uuid_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  form?: InputMaybe<Forms_Bool_Exp>;
  form_field?: InputMaybe<Form_Fields_Bool_Exp>;
  form_field_id?: InputMaybe<Uuid_Comparison_Exp>;
  form_id?: InputMaybe<Uuid_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "case_details" */
export enum Case_Details_Constraint {
  /** unique or primary key constraint on columns "id" */
  CaseDetailsPkey = "case_details_pkey",
}

/** input type for inserting data into table "case_details" */
export type Case_Details_Insert_Input = {
  case?: InputMaybe<Cases_Obj_Rel_Insert_Input>;
  case_id?: InputMaybe<Scalars["uuid"]>;
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  form?: InputMaybe<Forms_Obj_Rel_Insert_Input>;
  form_field?: InputMaybe<Form_Fields_Obj_Rel_Insert_Input>;
  form_field_id?: InputMaybe<Scalars["uuid"]>;
  form_id?: InputMaybe<Scalars["uuid"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  updated_at?: InputMaybe<Scalars["timestamptz"]>;
  value?: InputMaybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Case_Details_Max_Fields = {
  __typename?: "case_details_max_fields";
  case_id?: Maybe<Scalars["uuid"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  form_field_id?: Maybe<Scalars["uuid"]>;
  form_id?: Maybe<Scalars["uuid"]>;
  id?: Maybe<Scalars["uuid"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
  value?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "case_details" */
export type Case_Details_Max_Order_By = {
  case_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  form_field_id?: InputMaybe<Order_By>;
  form_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Case_Details_Min_Fields = {
  __typename?: "case_details_min_fields";
  case_id?: Maybe<Scalars["uuid"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  form_field_id?: Maybe<Scalars["uuid"]>;
  form_id?: Maybe<Scalars["uuid"]>;
  id?: Maybe<Scalars["uuid"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
  value?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "case_details" */
export type Case_Details_Min_Order_By = {
  case_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  form_field_id?: InputMaybe<Order_By>;
  form_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "case_details" */
export type Case_Details_Mutation_Response = {
  __typename?: "case_details_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Case_Details>;
};

/** on_conflict condition type for table "case_details" */
export type Case_Details_On_Conflict = {
  constraint: Case_Details_Constraint;
  update_columns?: Array<Case_Details_Update_Column>;
  where?: InputMaybe<Case_Details_Bool_Exp>;
};

/** Ordering options when selecting data from "case_details". */
export type Case_Details_Order_By = {
  case?: InputMaybe<Cases_Order_By>;
  case_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  form?: InputMaybe<Forms_Order_By>;
  form_field?: InputMaybe<Form_Fields_Order_By>;
  form_field_id?: InputMaybe<Order_By>;
  form_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: case_details */
export type Case_Details_Pk_Columns_Input = {
  id: Scalars["uuid"];
};

/** select columns of table "case_details" */
export enum Case_Details_Select_Column {
  /** column name */
  CaseId = "case_id",
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  FormFieldId = "form_field_id",
  /** column name */
  FormId = "form_id",
  /** column name */
  Id = "id",
  /** column name */
  UpdatedAt = "updated_at",
  /** column name */
  Value = "value",
}

/** input type for updating data in table "case_details" */
export type Case_Details_Set_Input = {
  case_id?: InputMaybe<Scalars["uuid"]>;
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  form_field_id?: InputMaybe<Scalars["uuid"]>;
  form_id?: InputMaybe<Scalars["uuid"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  updated_at?: InputMaybe<Scalars["timestamptz"]>;
  value?: InputMaybe<Scalars["String"]>;
};

/** Streaming cursor of the table "case_details" */
export type Case_Details_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Case_Details_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Case_Details_Stream_Cursor_Value_Input = {
  case_id?: InputMaybe<Scalars["uuid"]>;
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  form_field_id?: InputMaybe<Scalars["uuid"]>;
  form_id?: InputMaybe<Scalars["uuid"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  updated_at?: InputMaybe<Scalars["timestamptz"]>;
  value?: InputMaybe<Scalars["String"]>;
};

/** update columns of table "case_details" */
export enum Case_Details_Update_Column {
  /** column name */
  CaseId = "case_id",
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  FormFieldId = "form_field_id",
  /** column name */
  FormId = "form_id",
  /** column name */
  Id = "id",
  /** column name */
  UpdatedAt = "updated_at",
  /** column name */
  Value = "value",
}

export type Case_Details_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Case_Details_Set_Input>;
  /** filter the rows which have to be updated */
  where: Case_Details_Bool_Exp;
};

/** columns and relationships of "case_documents" */
export type Case_Documents = {
  __typename?: "case_documents";
  /** An object relationship */
  case: Cases;
  case_id: Scalars["uuid"];
  created_at?: Maybe<Scalars["timestamptz"]>;
  /** An object relationship */
  document: Documents;
  document_id: Scalars["uuid"];
  id: Scalars["uuid"];
};

/** aggregated selection of "case_documents" */
export type Case_Documents_Aggregate = {
  __typename?: "case_documents_aggregate";
  aggregate?: Maybe<Case_Documents_Aggregate_Fields>;
  nodes: Array<Case_Documents>;
};

export type Case_Documents_Aggregate_Bool_Exp = {
  count?: InputMaybe<Case_Documents_Aggregate_Bool_Exp_Count>;
};

export type Case_Documents_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Case_Documents_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<Case_Documents_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "case_documents" */
export type Case_Documents_Aggregate_Fields = {
  __typename?: "case_documents_aggregate_fields";
  count: Scalars["Int"];
  max?: Maybe<Case_Documents_Max_Fields>;
  min?: Maybe<Case_Documents_Min_Fields>;
};

/** aggregate fields of "case_documents" */
export type Case_Documents_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Case_Documents_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "case_documents" */
export type Case_Documents_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Case_Documents_Max_Order_By>;
  min?: InputMaybe<Case_Documents_Min_Order_By>;
};

/** input type for inserting array relation for remote table "case_documents" */
export type Case_Documents_Arr_Rel_Insert_Input = {
  data: Array<Case_Documents_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Case_Documents_On_Conflict>;
};

/** Boolean expression to filter rows from the table "case_documents". All fields are combined with a logical 'AND'. */
export type Case_Documents_Bool_Exp = {
  _and?: InputMaybe<Array<Case_Documents_Bool_Exp>>;
  _not?: InputMaybe<Case_Documents_Bool_Exp>;
  _or?: InputMaybe<Array<Case_Documents_Bool_Exp>>;
  case?: InputMaybe<Cases_Bool_Exp>;
  case_id?: InputMaybe<Uuid_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  document?: InputMaybe<Documents_Bool_Exp>;
  document_id?: InputMaybe<Uuid_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "case_documents" */
export enum Case_Documents_Constraint {
  /** unique or primary key constraint on columns "id" */
  CaseDocumentsPkey = "case_documents_pkey",
}

/** input type for inserting data into table "case_documents" */
export type Case_Documents_Insert_Input = {
  case?: InputMaybe<Cases_Obj_Rel_Insert_Input>;
  case_id?: InputMaybe<Scalars["uuid"]>;
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  document?: InputMaybe<Documents_Obj_Rel_Insert_Input>;
  document_id?: InputMaybe<Scalars["uuid"]>;
  id?: InputMaybe<Scalars["uuid"]>;
};

/** aggregate max on columns */
export type Case_Documents_Max_Fields = {
  __typename?: "case_documents_max_fields";
  case_id?: Maybe<Scalars["uuid"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  document_id?: Maybe<Scalars["uuid"]>;
  id?: Maybe<Scalars["uuid"]>;
};

/** order by max() on columns of table "case_documents" */
export type Case_Documents_Max_Order_By = {
  case_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  document_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Case_Documents_Min_Fields = {
  __typename?: "case_documents_min_fields";
  case_id?: Maybe<Scalars["uuid"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  document_id?: Maybe<Scalars["uuid"]>;
  id?: Maybe<Scalars["uuid"]>;
};

/** order by min() on columns of table "case_documents" */
export type Case_Documents_Min_Order_By = {
  case_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  document_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "case_documents" */
export type Case_Documents_Mutation_Response = {
  __typename?: "case_documents_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Case_Documents>;
};

/** on_conflict condition type for table "case_documents" */
export type Case_Documents_On_Conflict = {
  constraint: Case_Documents_Constraint;
  update_columns?: Array<Case_Documents_Update_Column>;
  where?: InputMaybe<Case_Documents_Bool_Exp>;
};

/** Ordering options when selecting data from "case_documents". */
export type Case_Documents_Order_By = {
  case?: InputMaybe<Cases_Order_By>;
  case_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  document?: InputMaybe<Documents_Order_By>;
  document_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: case_documents */
export type Case_Documents_Pk_Columns_Input = {
  id: Scalars["uuid"];
};

/** select columns of table "case_documents" */
export enum Case_Documents_Select_Column {
  /** column name */
  CaseId = "case_id",
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  DocumentId = "document_id",
  /** column name */
  Id = "id",
}

/** input type for updating data in table "case_documents" */
export type Case_Documents_Set_Input = {
  case_id?: InputMaybe<Scalars["uuid"]>;
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  document_id?: InputMaybe<Scalars["uuid"]>;
  id?: InputMaybe<Scalars["uuid"]>;
};

/** Streaming cursor of the table "case_documents" */
export type Case_Documents_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Case_Documents_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Case_Documents_Stream_Cursor_Value_Input = {
  case_id?: InputMaybe<Scalars["uuid"]>;
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  document_id?: InputMaybe<Scalars["uuid"]>;
  id?: InputMaybe<Scalars["uuid"]>;
};

/** update columns of table "case_documents" */
export enum Case_Documents_Update_Column {
  /** column name */
  CaseId = "case_id",
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  DocumentId = "document_id",
  /** column name */
  Id = "id",
}

export type Case_Documents_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Case_Documents_Set_Input>;
  /** filter the rows which have to be updated */
  where: Case_Documents_Bool_Exp;
};

/** columns and relationships of "case_flags" */
export type Case_Flags = {
  __typename?: "case_flags";
  /** An object relationship */
  case: Cases;
  case_id: Scalars["uuid"];
  created_at?: Maybe<Scalars["timestamptz"]>;
  /** An object relationship */
  flag: Flags;
  flag_id: Scalars["uuid"];
  id: Scalars["uuid"];
};

/** aggregated selection of "case_flags" */
export type Case_Flags_Aggregate = {
  __typename?: "case_flags_aggregate";
  aggregate?: Maybe<Case_Flags_Aggregate_Fields>;
  nodes: Array<Case_Flags>;
};

export type Case_Flags_Aggregate_Bool_Exp = {
  count?: InputMaybe<Case_Flags_Aggregate_Bool_Exp_Count>;
};

export type Case_Flags_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Case_Flags_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<Case_Flags_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "case_flags" */
export type Case_Flags_Aggregate_Fields = {
  __typename?: "case_flags_aggregate_fields";
  count: Scalars["Int"];
  max?: Maybe<Case_Flags_Max_Fields>;
  min?: Maybe<Case_Flags_Min_Fields>;
};

/** aggregate fields of "case_flags" */
export type Case_Flags_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Case_Flags_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "case_flags" */
export type Case_Flags_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Case_Flags_Max_Order_By>;
  min?: InputMaybe<Case_Flags_Min_Order_By>;
};

/** input type for inserting array relation for remote table "case_flags" */
export type Case_Flags_Arr_Rel_Insert_Input = {
  data: Array<Case_Flags_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Case_Flags_On_Conflict>;
};

/** Boolean expression to filter rows from the table "case_flags". All fields are combined with a logical 'AND'. */
export type Case_Flags_Bool_Exp = {
  _and?: InputMaybe<Array<Case_Flags_Bool_Exp>>;
  _not?: InputMaybe<Case_Flags_Bool_Exp>;
  _or?: InputMaybe<Array<Case_Flags_Bool_Exp>>;
  case?: InputMaybe<Cases_Bool_Exp>;
  case_id?: InputMaybe<Uuid_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  flag?: InputMaybe<Flags_Bool_Exp>;
  flag_id?: InputMaybe<Uuid_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "case_flags" */
export enum Case_Flags_Constraint {
  /** unique or primary key constraint on columns "id" */
  CaseFlagsPkey = "case_flags_pkey",
}

/** input type for inserting data into table "case_flags" */
export type Case_Flags_Insert_Input = {
  case?: InputMaybe<Cases_Obj_Rel_Insert_Input>;
  case_id?: InputMaybe<Scalars["uuid"]>;
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  flag?: InputMaybe<Flags_Obj_Rel_Insert_Input>;
  flag_id?: InputMaybe<Scalars["uuid"]>;
  id?: InputMaybe<Scalars["uuid"]>;
};

/** aggregate max on columns */
export type Case_Flags_Max_Fields = {
  __typename?: "case_flags_max_fields";
  case_id?: Maybe<Scalars["uuid"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  flag_id?: Maybe<Scalars["uuid"]>;
  id?: Maybe<Scalars["uuid"]>;
};

/** order by max() on columns of table "case_flags" */
export type Case_Flags_Max_Order_By = {
  case_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  flag_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Case_Flags_Min_Fields = {
  __typename?: "case_flags_min_fields";
  case_id?: Maybe<Scalars["uuid"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  flag_id?: Maybe<Scalars["uuid"]>;
  id?: Maybe<Scalars["uuid"]>;
};

/** order by min() on columns of table "case_flags" */
export type Case_Flags_Min_Order_By = {
  case_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  flag_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "case_flags" */
export type Case_Flags_Mutation_Response = {
  __typename?: "case_flags_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Case_Flags>;
};

/** on_conflict condition type for table "case_flags" */
export type Case_Flags_On_Conflict = {
  constraint: Case_Flags_Constraint;
  update_columns?: Array<Case_Flags_Update_Column>;
  where?: InputMaybe<Case_Flags_Bool_Exp>;
};

/** Ordering options when selecting data from "case_flags". */
export type Case_Flags_Order_By = {
  case?: InputMaybe<Cases_Order_By>;
  case_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  flag?: InputMaybe<Flags_Order_By>;
  flag_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: case_flags */
export type Case_Flags_Pk_Columns_Input = {
  id: Scalars["uuid"];
};

/** select columns of table "case_flags" */
export enum Case_Flags_Select_Column {
  /** column name */
  CaseId = "case_id",
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  FlagId = "flag_id",
  /** column name */
  Id = "id",
}

/** input type for updating data in table "case_flags" */
export type Case_Flags_Set_Input = {
  case_id?: InputMaybe<Scalars["uuid"]>;
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  flag_id?: InputMaybe<Scalars["uuid"]>;
  id?: InputMaybe<Scalars["uuid"]>;
};

/** Streaming cursor of the table "case_flags" */
export type Case_Flags_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Case_Flags_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Case_Flags_Stream_Cursor_Value_Input = {
  case_id?: InputMaybe<Scalars["uuid"]>;
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  flag_id?: InputMaybe<Scalars["uuid"]>;
  id?: InputMaybe<Scalars["uuid"]>;
};

/** update columns of table "case_flags" */
export enum Case_Flags_Update_Column {
  /** column name */
  CaseId = "case_id",
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  FlagId = "flag_id",
  /** column name */
  Id = "id",
}

export type Case_Flags_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Case_Flags_Set_Input>;
  /** filter the rows which have to be updated */
  where: Case_Flags_Bool_Exp;
};

/** columns and relationships of "case_interactions" */
export type Case_Interactions = {
  __typename?: "case_interactions";
  /** An object relationship */
  case: Cases;
  case_id: Scalars["uuid"];
  created_at?: Maybe<Scalars["timestamptz"]>;
  id: Scalars["uuid"];
  /** An object relationship */
  interaction: Interactions;
  interaction_id: Scalars["uuid"];
};

/** aggregated selection of "case_interactions" */
export type Case_Interactions_Aggregate = {
  __typename?: "case_interactions_aggregate";
  aggregate?: Maybe<Case_Interactions_Aggregate_Fields>;
  nodes: Array<Case_Interactions>;
};

export type Case_Interactions_Aggregate_Bool_Exp = {
  count?: InputMaybe<Case_Interactions_Aggregate_Bool_Exp_Count>;
};

export type Case_Interactions_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Case_Interactions_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<Case_Interactions_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "case_interactions" */
export type Case_Interactions_Aggregate_Fields = {
  __typename?: "case_interactions_aggregate_fields";
  count: Scalars["Int"];
  max?: Maybe<Case_Interactions_Max_Fields>;
  min?: Maybe<Case_Interactions_Min_Fields>;
};

/** aggregate fields of "case_interactions" */
export type Case_Interactions_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Case_Interactions_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "case_interactions" */
export type Case_Interactions_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Case_Interactions_Max_Order_By>;
  min?: InputMaybe<Case_Interactions_Min_Order_By>;
};

/** input type for inserting array relation for remote table "case_interactions" */
export type Case_Interactions_Arr_Rel_Insert_Input = {
  data: Array<Case_Interactions_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Case_Interactions_On_Conflict>;
};

/** Boolean expression to filter rows from the table "case_interactions". All fields are combined with a logical 'AND'. */
export type Case_Interactions_Bool_Exp = {
  _and?: InputMaybe<Array<Case_Interactions_Bool_Exp>>;
  _not?: InputMaybe<Case_Interactions_Bool_Exp>;
  _or?: InputMaybe<Array<Case_Interactions_Bool_Exp>>;
  case?: InputMaybe<Cases_Bool_Exp>;
  case_id?: InputMaybe<Uuid_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  interaction?: InputMaybe<Interactions_Bool_Exp>;
  interaction_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "case_interactions" */
export enum Case_Interactions_Constraint {
  /** unique or primary key constraint on columns "id" */
  CaseInteractionsPkey = "case_interactions_pkey",
}

/** input type for inserting data into table "case_interactions" */
export type Case_Interactions_Insert_Input = {
  case?: InputMaybe<Cases_Obj_Rel_Insert_Input>;
  case_id?: InputMaybe<Scalars["uuid"]>;
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  interaction?: InputMaybe<Interactions_Obj_Rel_Insert_Input>;
  interaction_id?: InputMaybe<Scalars["uuid"]>;
};

/** aggregate max on columns */
export type Case_Interactions_Max_Fields = {
  __typename?: "case_interactions_max_fields";
  case_id?: Maybe<Scalars["uuid"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["uuid"]>;
  interaction_id?: Maybe<Scalars["uuid"]>;
};

/** order by max() on columns of table "case_interactions" */
export type Case_Interactions_Max_Order_By = {
  case_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  interaction_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Case_Interactions_Min_Fields = {
  __typename?: "case_interactions_min_fields";
  case_id?: Maybe<Scalars["uuid"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["uuid"]>;
  interaction_id?: Maybe<Scalars["uuid"]>;
};

/** order by min() on columns of table "case_interactions" */
export type Case_Interactions_Min_Order_By = {
  case_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  interaction_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "case_interactions" */
export type Case_Interactions_Mutation_Response = {
  __typename?: "case_interactions_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Case_Interactions>;
};

/** on_conflict condition type for table "case_interactions" */
export type Case_Interactions_On_Conflict = {
  constraint: Case_Interactions_Constraint;
  update_columns?: Array<Case_Interactions_Update_Column>;
  where?: InputMaybe<Case_Interactions_Bool_Exp>;
};

/** Ordering options when selecting data from "case_interactions". */
export type Case_Interactions_Order_By = {
  case?: InputMaybe<Cases_Order_By>;
  case_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  interaction?: InputMaybe<Interactions_Order_By>;
  interaction_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: case_interactions */
export type Case_Interactions_Pk_Columns_Input = {
  id: Scalars["uuid"];
};

/** select columns of table "case_interactions" */
export enum Case_Interactions_Select_Column {
  /** column name */
  CaseId = "case_id",
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  Id = "id",
  /** column name */
  InteractionId = "interaction_id",
}

/** input type for updating data in table "case_interactions" */
export type Case_Interactions_Set_Input = {
  case_id?: InputMaybe<Scalars["uuid"]>;
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  interaction_id?: InputMaybe<Scalars["uuid"]>;
};

/** Streaming cursor of the table "case_interactions" */
export type Case_Interactions_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Case_Interactions_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Case_Interactions_Stream_Cursor_Value_Input = {
  case_id?: InputMaybe<Scalars["uuid"]>;
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  interaction_id?: InputMaybe<Scalars["uuid"]>;
};

/** update columns of table "case_interactions" */
export enum Case_Interactions_Update_Column {
  /** column name */
  CaseId = "case_id",
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  Id = "id",
  /** column name */
  InteractionId = "interaction_id",
}

export type Case_Interactions_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Case_Interactions_Set_Input>;
  /** filter the rows which have to be updated */
  where: Case_Interactions_Bool_Exp;
};

/** columns and relationships of "case_mentions" */
export type Case_Mentions = {
  __typename?: "case_mentions";
  /** An object relationship */
  case: Cases;
  case_id: Scalars["uuid"];
  created_at?: Maybe<Scalars["timestamptz"]>;
  id: Scalars["uuid"];
  mentioned_by: Scalars["uuid"];
  mentioned_party: Scalars["uuid"];
  /** An object relationship */
  party: Parties;
  /** An object relationship */
  partyByMentionedParty: Parties;
};

/** aggregated selection of "case_mentions" */
export type Case_Mentions_Aggregate = {
  __typename?: "case_mentions_aggregate";
  aggregate?: Maybe<Case_Mentions_Aggregate_Fields>;
  nodes: Array<Case_Mentions>;
};

export type Case_Mentions_Aggregate_Bool_Exp = {
  count?: InputMaybe<Case_Mentions_Aggregate_Bool_Exp_Count>;
};

export type Case_Mentions_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Case_Mentions_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<Case_Mentions_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "case_mentions" */
export type Case_Mentions_Aggregate_Fields = {
  __typename?: "case_mentions_aggregate_fields";
  count: Scalars["Int"];
  max?: Maybe<Case_Mentions_Max_Fields>;
  min?: Maybe<Case_Mentions_Min_Fields>;
};

/** aggregate fields of "case_mentions" */
export type Case_Mentions_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Case_Mentions_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "case_mentions" */
export type Case_Mentions_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Case_Mentions_Max_Order_By>;
  min?: InputMaybe<Case_Mentions_Min_Order_By>;
};

/** input type for inserting array relation for remote table "case_mentions" */
export type Case_Mentions_Arr_Rel_Insert_Input = {
  data: Array<Case_Mentions_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Case_Mentions_On_Conflict>;
};

/** Boolean expression to filter rows from the table "case_mentions". All fields are combined with a logical 'AND'. */
export type Case_Mentions_Bool_Exp = {
  _and?: InputMaybe<Array<Case_Mentions_Bool_Exp>>;
  _not?: InputMaybe<Case_Mentions_Bool_Exp>;
  _or?: InputMaybe<Array<Case_Mentions_Bool_Exp>>;
  case?: InputMaybe<Cases_Bool_Exp>;
  case_id?: InputMaybe<Uuid_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  mentioned_by?: InputMaybe<Uuid_Comparison_Exp>;
  mentioned_party?: InputMaybe<Uuid_Comparison_Exp>;
  party?: InputMaybe<Parties_Bool_Exp>;
  partyByMentionedParty?: InputMaybe<Parties_Bool_Exp>;
};

/** unique or primary key constraints on table "case_mentions" */
export enum Case_Mentions_Constraint {
  /** unique or primary key constraint on columns "id" */
  CaseMentionsPkey = "case_mentions_pkey",
}

/** input type for inserting data into table "case_mentions" */
export type Case_Mentions_Insert_Input = {
  case?: InputMaybe<Cases_Obj_Rel_Insert_Input>;
  case_id?: InputMaybe<Scalars["uuid"]>;
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  mentioned_by?: InputMaybe<Scalars["uuid"]>;
  mentioned_party?: InputMaybe<Scalars["uuid"]>;
  party?: InputMaybe<Parties_Obj_Rel_Insert_Input>;
  partyByMentionedParty?: InputMaybe<Parties_Obj_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Case_Mentions_Max_Fields = {
  __typename?: "case_mentions_max_fields";
  case_id?: Maybe<Scalars["uuid"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["uuid"]>;
  mentioned_by?: Maybe<Scalars["uuid"]>;
  mentioned_party?: Maybe<Scalars["uuid"]>;
};

/** order by max() on columns of table "case_mentions" */
export type Case_Mentions_Max_Order_By = {
  case_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  mentioned_by?: InputMaybe<Order_By>;
  mentioned_party?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Case_Mentions_Min_Fields = {
  __typename?: "case_mentions_min_fields";
  case_id?: Maybe<Scalars["uuid"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["uuid"]>;
  mentioned_by?: Maybe<Scalars["uuid"]>;
  mentioned_party?: Maybe<Scalars["uuid"]>;
};

/** order by min() on columns of table "case_mentions" */
export type Case_Mentions_Min_Order_By = {
  case_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  mentioned_by?: InputMaybe<Order_By>;
  mentioned_party?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "case_mentions" */
export type Case_Mentions_Mutation_Response = {
  __typename?: "case_mentions_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Case_Mentions>;
};

/** on_conflict condition type for table "case_mentions" */
export type Case_Mentions_On_Conflict = {
  constraint: Case_Mentions_Constraint;
  update_columns?: Array<Case_Mentions_Update_Column>;
  where?: InputMaybe<Case_Mentions_Bool_Exp>;
};

/** Ordering options when selecting data from "case_mentions". */
export type Case_Mentions_Order_By = {
  case?: InputMaybe<Cases_Order_By>;
  case_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  mentioned_by?: InputMaybe<Order_By>;
  mentioned_party?: InputMaybe<Order_By>;
  party?: InputMaybe<Parties_Order_By>;
  partyByMentionedParty?: InputMaybe<Parties_Order_By>;
};

/** primary key columns input for table: case_mentions */
export type Case_Mentions_Pk_Columns_Input = {
  id: Scalars["uuid"];
};

/** select columns of table "case_mentions" */
export enum Case_Mentions_Select_Column {
  /** column name */
  CaseId = "case_id",
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  Id = "id",
  /** column name */
  MentionedBy = "mentioned_by",
  /** column name */
  MentionedParty = "mentioned_party",
}

/** input type for updating data in table "case_mentions" */
export type Case_Mentions_Set_Input = {
  case_id?: InputMaybe<Scalars["uuid"]>;
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  mentioned_by?: InputMaybe<Scalars["uuid"]>;
  mentioned_party?: InputMaybe<Scalars["uuid"]>;
};

/** Streaming cursor of the table "case_mentions" */
export type Case_Mentions_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Case_Mentions_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Case_Mentions_Stream_Cursor_Value_Input = {
  case_id?: InputMaybe<Scalars["uuid"]>;
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  mentioned_by?: InputMaybe<Scalars["uuid"]>;
  mentioned_party?: InputMaybe<Scalars["uuid"]>;
};

/** update columns of table "case_mentions" */
export enum Case_Mentions_Update_Column {
  /** column name */
  CaseId = "case_id",
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  Id = "id",
  /** column name */
  MentionedBy = "mentioned_by",
  /** column name */
  MentionedParty = "mentioned_party",
}

export type Case_Mentions_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Case_Mentions_Set_Input>;
  /** filter the rows which have to be updated */
  where: Case_Mentions_Bool_Exp;
};

/** columns and relationships of "case_statuses" */
export type Case_Statuses = {
  __typename?: "case_statuses";
  /** An object relationship */
  case: Cases;
  case_id: Scalars["uuid"];
  created_at?: Maybe<Scalars["timestamptz"]>;
  id: Scalars["uuid"];
  /** An object relationship */
  status: Statuses;
  status_id: Scalars["uuid"];
};

/** aggregated selection of "case_statuses" */
export type Case_Statuses_Aggregate = {
  __typename?: "case_statuses_aggregate";
  aggregate?: Maybe<Case_Statuses_Aggregate_Fields>;
  nodes: Array<Case_Statuses>;
};

export type Case_Statuses_Aggregate_Bool_Exp = {
  count?: InputMaybe<Case_Statuses_Aggregate_Bool_Exp_Count>;
};

export type Case_Statuses_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Case_Statuses_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<Case_Statuses_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "case_statuses" */
export type Case_Statuses_Aggregate_Fields = {
  __typename?: "case_statuses_aggregate_fields";
  count: Scalars["Int"];
  max?: Maybe<Case_Statuses_Max_Fields>;
  min?: Maybe<Case_Statuses_Min_Fields>;
};

/** aggregate fields of "case_statuses" */
export type Case_Statuses_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Case_Statuses_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "case_statuses" */
export type Case_Statuses_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Case_Statuses_Max_Order_By>;
  min?: InputMaybe<Case_Statuses_Min_Order_By>;
};

/** input type for inserting array relation for remote table "case_statuses" */
export type Case_Statuses_Arr_Rel_Insert_Input = {
  data: Array<Case_Statuses_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Case_Statuses_On_Conflict>;
};

/** Boolean expression to filter rows from the table "case_statuses". All fields are combined with a logical 'AND'. */
export type Case_Statuses_Bool_Exp = {
  _and?: InputMaybe<Array<Case_Statuses_Bool_Exp>>;
  _not?: InputMaybe<Case_Statuses_Bool_Exp>;
  _or?: InputMaybe<Array<Case_Statuses_Bool_Exp>>;
  case?: InputMaybe<Cases_Bool_Exp>;
  case_id?: InputMaybe<Uuid_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  status?: InputMaybe<Statuses_Bool_Exp>;
  status_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "case_statuses" */
export enum Case_Statuses_Constraint {
  /** unique or primary key constraint on columns "id" */
  CaseStatusesPkey = "case_statuses_pkey",
}

/** input type for inserting data into table "case_statuses" */
export type Case_Statuses_Insert_Input = {
  case?: InputMaybe<Cases_Obj_Rel_Insert_Input>;
  case_id?: InputMaybe<Scalars["uuid"]>;
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  status?: InputMaybe<Statuses_Obj_Rel_Insert_Input>;
  status_id?: InputMaybe<Scalars["uuid"]>;
};

/** aggregate max on columns */
export type Case_Statuses_Max_Fields = {
  __typename?: "case_statuses_max_fields";
  case_id?: Maybe<Scalars["uuid"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["uuid"]>;
  status_id?: Maybe<Scalars["uuid"]>;
};

/** order by max() on columns of table "case_statuses" */
export type Case_Statuses_Max_Order_By = {
  case_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  status_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Case_Statuses_Min_Fields = {
  __typename?: "case_statuses_min_fields";
  case_id?: Maybe<Scalars["uuid"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["uuid"]>;
  status_id?: Maybe<Scalars["uuid"]>;
};

/** order by min() on columns of table "case_statuses" */
export type Case_Statuses_Min_Order_By = {
  case_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  status_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "case_statuses" */
export type Case_Statuses_Mutation_Response = {
  __typename?: "case_statuses_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Case_Statuses>;
};

/** on_conflict condition type for table "case_statuses" */
export type Case_Statuses_On_Conflict = {
  constraint: Case_Statuses_Constraint;
  update_columns?: Array<Case_Statuses_Update_Column>;
  where?: InputMaybe<Case_Statuses_Bool_Exp>;
};

/** Ordering options when selecting data from "case_statuses". */
export type Case_Statuses_Order_By = {
  case?: InputMaybe<Cases_Order_By>;
  case_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  status?: InputMaybe<Statuses_Order_By>;
  status_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: case_statuses */
export type Case_Statuses_Pk_Columns_Input = {
  id: Scalars["uuid"];
};

/** select columns of table "case_statuses" */
export enum Case_Statuses_Select_Column {
  /** column name */
  CaseId = "case_id",
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  Id = "id",
  /** column name */
  StatusId = "status_id",
}

/** input type for updating data in table "case_statuses" */
export type Case_Statuses_Set_Input = {
  case_id?: InputMaybe<Scalars["uuid"]>;
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  status_id?: InputMaybe<Scalars["uuid"]>;
};

/** Streaming cursor of the table "case_statuses" */
export type Case_Statuses_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Case_Statuses_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Case_Statuses_Stream_Cursor_Value_Input = {
  case_id?: InputMaybe<Scalars["uuid"]>;
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  status_id?: InputMaybe<Scalars["uuid"]>;
};

/** update columns of table "case_statuses" */
export enum Case_Statuses_Update_Column {
  /** column name */
  CaseId = "case_id",
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  Id = "id",
  /** column name */
  StatusId = "status_id",
}

export type Case_Statuses_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Case_Statuses_Set_Input>;
  /** filter the rows which have to be updated */
  where: Case_Statuses_Bool_Exp;
};

/** columns and relationships of "cases" */
export type Cases = {
  __typename?: "cases";
  /** An object relationship */
  assigned_party: Parties;
  assigned_to: Scalars["uuid"];
  /** An array relationship */
  case_assignments: Array<Case_Assignments>;
  /** An aggregate relationship */
  case_assignments_aggregate: Case_Assignments_Aggregate;
  /** An array relationship */
  case_details: Array<Case_Details>;
  /** An aggregate relationship */
  case_details_aggregate: Case_Details_Aggregate;
  /** An array relationship */
  case_documents: Array<Case_Documents>;
  /** An aggregate relationship */
  case_documents_aggregate: Case_Documents_Aggregate;
  /** An array relationship */
  case_flags: Array<Case_Flags>;
  /** An aggregate relationship */
  case_flags_aggregate: Case_Flags_Aggregate;
  /** An array relationship */
  case_interactions: Array<Case_Interactions>;
  /** An aggregate relationship */
  case_interactions_aggregate: Case_Interactions_Aggregate;
  /** An array relationship */
  case_mentions: Array<Case_Mentions>;
  /** An aggregate relationship */
  case_mentions_aggregate: Case_Mentions_Aggregate;
  /** An object relationship */
  case_party: Parties;
  /** An array relationship */
  case_statuses: Array<Case_Statuses>;
  /** An aggregate relationship */
  case_statuses_aggregate: Case_Statuses_Aggregate;
  created_at: Scalars["timestamptz"];
  id: Scalars["uuid"];
  organisation_id?: Maybe<Scalars["uuid"]>;
  party_id: Scalars["uuid"];
  /** An array relationship */
  subscription_cases: Array<Subscription_Cases>;
  /** An aggregate relationship */
  subscription_cases_aggregate: Subscription_Cases_Aggregate;
  title: Scalars["String"];
  /** An object relationship */
  type: Types;
  type_id: Scalars["uuid"];
  updated_at: Scalars["timestamptz"];
};

/** columns and relationships of "cases" */
export type CasesCase_AssignmentsArgs = {
  distinct_on?: InputMaybe<Array<Case_Assignments_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Case_Assignments_Order_By>>;
  where?: InputMaybe<Case_Assignments_Bool_Exp>;
};

/** columns and relationships of "cases" */
export type CasesCase_Assignments_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Case_Assignments_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Case_Assignments_Order_By>>;
  where?: InputMaybe<Case_Assignments_Bool_Exp>;
};

/** columns and relationships of "cases" */
export type CasesCase_DetailsArgs = {
  distinct_on?: InputMaybe<Array<Case_Details_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Case_Details_Order_By>>;
  where?: InputMaybe<Case_Details_Bool_Exp>;
};

/** columns and relationships of "cases" */
export type CasesCase_Details_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Case_Details_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Case_Details_Order_By>>;
  where?: InputMaybe<Case_Details_Bool_Exp>;
};

/** columns and relationships of "cases" */
export type CasesCase_DocumentsArgs = {
  distinct_on?: InputMaybe<Array<Case_Documents_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Case_Documents_Order_By>>;
  where?: InputMaybe<Case_Documents_Bool_Exp>;
};

/** columns and relationships of "cases" */
export type CasesCase_Documents_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Case_Documents_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Case_Documents_Order_By>>;
  where?: InputMaybe<Case_Documents_Bool_Exp>;
};

/** columns and relationships of "cases" */
export type CasesCase_FlagsArgs = {
  distinct_on?: InputMaybe<Array<Case_Flags_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Case_Flags_Order_By>>;
  where?: InputMaybe<Case_Flags_Bool_Exp>;
};

/** columns and relationships of "cases" */
export type CasesCase_Flags_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Case_Flags_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Case_Flags_Order_By>>;
  where?: InputMaybe<Case_Flags_Bool_Exp>;
};

/** columns and relationships of "cases" */
export type CasesCase_InteractionsArgs = {
  distinct_on?: InputMaybe<Array<Case_Interactions_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Case_Interactions_Order_By>>;
  where?: InputMaybe<Case_Interactions_Bool_Exp>;
};

/** columns and relationships of "cases" */
export type CasesCase_Interactions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Case_Interactions_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Case_Interactions_Order_By>>;
  where?: InputMaybe<Case_Interactions_Bool_Exp>;
};

/** columns and relationships of "cases" */
export type CasesCase_MentionsArgs = {
  distinct_on?: InputMaybe<Array<Case_Mentions_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Case_Mentions_Order_By>>;
  where?: InputMaybe<Case_Mentions_Bool_Exp>;
};

/** columns and relationships of "cases" */
export type CasesCase_Mentions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Case_Mentions_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Case_Mentions_Order_By>>;
  where?: InputMaybe<Case_Mentions_Bool_Exp>;
};

/** columns and relationships of "cases" */
export type CasesCase_StatusesArgs = {
  distinct_on?: InputMaybe<Array<Case_Statuses_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Case_Statuses_Order_By>>;
  where?: InputMaybe<Case_Statuses_Bool_Exp>;
};

/** columns and relationships of "cases" */
export type CasesCase_Statuses_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Case_Statuses_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Case_Statuses_Order_By>>;
  where?: InputMaybe<Case_Statuses_Bool_Exp>;
};

/** columns and relationships of "cases" */
export type CasesSubscription_CasesArgs = {
  distinct_on?: InputMaybe<Array<Subscription_Cases_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Subscription_Cases_Order_By>>;
  where?: InputMaybe<Subscription_Cases_Bool_Exp>;
};

/** columns and relationships of "cases" */
export type CasesSubscription_Cases_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Subscription_Cases_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Subscription_Cases_Order_By>>;
  where?: InputMaybe<Subscription_Cases_Bool_Exp>;
};

/** aggregated selection of "cases" */
export type Cases_Aggregate = {
  __typename?: "cases_aggregate";
  aggregate?: Maybe<Cases_Aggregate_Fields>;
  nodes: Array<Cases>;
};

export type Cases_Aggregate_Bool_Exp = {
  count?: InputMaybe<Cases_Aggregate_Bool_Exp_Count>;
};

export type Cases_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Cases_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<Cases_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "cases" */
export type Cases_Aggregate_Fields = {
  __typename?: "cases_aggregate_fields";
  count: Scalars["Int"];
  max?: Maybe<Cases_Max_Fields>;
  min?: Maybe<Cases_Min_Fields>;
};

/** aggregate fields of "cases" */
export type Cases_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Cases_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "cases" */
export type Cases_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Cases_Max_Order_By>;
  min?: InputMaybe<Cases_Min_Order_By>;
};

/** input type for inserting array relation for remote table "cases" */
export type Cases_Arr_Rel_Insert_Input = {
  data: Array<Cases_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Cases_On_Conflict>;
};

/** Boolean expression to filter rows from the table "cases". All fields are combined with a logical 'AND'. */
export type Cases_Bool_Exp = {
  _and?: InputMaybe<Array<Cases_Bool_Exp>>;
  _not?: InputMaybe<Cases_Bool_Exp>;
  _or?: InputMaybe<Array<Cases_Bool_Exp>>;
  assigned_party?: InputMaybe<Parties_Bool_Exp>;
  assigned_to?: InputMaybe<Uuid_Comparison_Exp>;
  case_assignments?: InputMaybe<Case_Assignments_Bool_Exp>;
  case_assignments_aggregate?: InputMaybe<Case_Assignments_Aggregate_Bool_Exp>;
  case_details?: InputMaybe<Case_Details_Bool_Exp>;
  case_details_aggregate?: InputMaybe<Case_Details_Aggregate_Bool_Exp>;
  case_documents?: InputMaybe<Case_Documents_Bool_Exp>;
  case_documents_aggregate?: InputMaybe<Case_Documents_Aggregate_Bool_Exp>;
  case_flags?: InputMaybe<Case_Flags_Bool_Exp>;
  case_flags_aggregate?: InputMaybe<Case_Flags_Aggregate_Bool_Exp>;
  case_interactions?: InputMaybe<Case_Interactions_Bool_Exp>;
  case_interactions_aggregate?: InputMaybe<Case_Interactions_Aggregate_Bool_Exp>;
  case_mentions?: InputMaybe<Case_Mentions_Bool_Exp>;
  case_mentions_aggregate?: InputMaybe<Case_Mentions_Aggregate_Bool_Exp>;
  case_party?: InputMaybe<Parties_Bool_Exp>;
  case_statuses?: InputMaybe<Case_Statuses_Bool_Exp>;
  case_statuses_aggregate?: InputMaybe<Case_Statuses_Aggregate_Bool_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  organisation_id?: InputMaybe<Uuid_Comparison_Exp>;
  party_id?: InputMaybe<Uuid_Comparison_Exp>;
  subscription_cases?: InputMaybe<Subscription_Cases_Bool_Exp>;
  subscription_cases_aggregate?: InputMaybe<Subscription_Cases_Aggregate_Bool_Exp>;
  title?: InputMaybe<String_Comparison_Exp>;
  type?: InputMaybe<Types_Bool_Exp>;
  type_id?: InputMaybe<Uuid_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "cases" */
export enum Cases_Constraint {
  /** unique or primary key constraint on columns "id" */
  CasesPkey = "cases_pkey",
}

/** input type for inserting data into table "cases" */
export type Cases_Insert_Input = {
  assigned_party?: InputMaybe<Parties_Obj_Rel_Insert_Input>;
  assigned_to?: InputMaybe<Scalars["uuid"]>;
  case_assignments?: InputMaybe<Case_Assignments_Arr_Rel_Insert_Input>;
  case_details?: InputMaybe<Case_Details_Arr_Rel_Insert_Input>;
  case_documents?: InputMaybe<Case_Documents_Arr_Rel_Insert_Input>;
  case_flags?: InputMaybe<Case_Flags_Arr_Rel_Insert_Input>;
  case_interactions?: InputMaybe<Case_Interactions_Arr_Rel_Insert_Input>;
  case_mentions?: InputMaybe<Case_Mentions_Arr_Rel_Insert_Input>;
  case_party?: InputMaybe<Parties_Obj_Rel_Insert_Input>;
  case_statuses?: InputMaybe<Case_Statuses_Arr_Rel_Insert_Input>;
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  organisation_id?: InputMaybe<Scalars["uuid"]>;
  party_id?: InputMaybe<Scalars["uuid"]>;
  subscription_cases?: InputMaybe<Subscription_Cases_Arr_Rel_Insert_Input>;
  title?: InputMaybe<Scalars["String"]>;
  type?: InputMaybe<Types_Obj_Rel_Insert_Input>;
  type_id?: InputMaybe<Scalars["uuid"]>;
  updated_at?: InputMaybe<Scalars["timestamptz"]>;
};

/** aggregate max on columns */
export type Cases_Max_Fields = {
  __typename?: "cases_max_fields";
  assigned_to?: Maybe<Scalars["uuid"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["uuid"]>;
  organisation_id?: Maybe<Scalars["uuid"]>;
  party_id?: Maybe<Scalars["uuid"]>;
  title?: Maybe<Scalars["String"]>;
  type_id?: Maybe<Scalars["uuid"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
};

/** order by max() on columns of table "cases" */
export type Cases_Max_Order_By = {
  assigned_to?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  organisation_id?: InputMaybe<Order_By>;
  party_id?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
  type_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Cases_Min_Fields = {
  __typename?: "cases_min_fields";
  assigned_to?: Maybe<Scalars["uuid"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["uuid"]>;
  organisation_id?: Maybe<Scalars["uuid"]>;
  party_id?: Maybe<Scalars["uuid"]>;
  title?: Maybe<Scalars["String"]>;
  type_id?: Maybe<Scalars["uuid"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
};

/** order by min() on columns of table "cases" */
export type Cases_Min_Order_By = {
  assigned_to?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  organisation_id?: InputMaybe<Order_By>;
  party_id?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
  type_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "cases" */
export type Cases_Mutation_Response = {
  __typename?: "cases_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Cases>;
};

/** input type for inserting object relation for remote table "cases" */
export type Cases_Obj_Rel_Insert_Input = {
  data: Cases_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Cases_On_Conflict>;
};

/** on_conflict condition type for table "cases" */
export type Cases_On_Conflict = {
  constraint: Cases_Constraint;
  update_columns?: Array<Cases_Update_Column>;
  where?: InputMaybe<Cases_Bool_Exp>;
};

/** Ordering options when selecting data from "cases". */
export type Cases_Order_By = {
  assigned_party?: InputMaybe<Parties_Order_By>;
  assigned_to?: InputMaybe<Order_By>;
  case_assignments_aggregate?: InputMaybe<Case_Assignments_Aggregate_Order_By>;
  case_details_aggregate?: InputMaybe<Case_Details_Aggregate_Order_By>;
  case_documents_aggregate?: InputMaybe<Case_Documents_Aggregate_Order_By>;
  case_flags_aggregate?: InputMaybe<Case_Flags_Aggregate_Order_By>;
  case_interactions_aggregate?: InputMaybe<Case_Interactions_Aggregate_Order_By>;
  case_mentions_aggregate?: InputMaybe<Case_Mentions_Aggregate_Order_By>;
  case_party?: InputMaybe<Parties_Order_By>;
  case_statuses_aggregate?: InputMaybe<Case_Statuses_Aggregate_Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  organisation_id?: InputMaybe<Order_By>;
  party_id?: InputMaybe<Order_By>;
  subscription_cases_aggregate?: InputMaybe<Subscription_Cases_Aggregate_Order_By>;
  title?: InputMaybe<Order_By>;
  type?: InputMaybe<Types_Order_By>;
  type_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: cases */
export type Cases_Pk_Columns_Input = {
  id: Scalars["uuid"];
};

/** select columns of table "cases" */
export enum Cases_Select_Column {
  /** column name */
  AssignedTo = "assigned_to",
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  Id = "id",
  /** column name */
  OrganisationId = "organisation_id",
  /** column name */
  PartyId = "party_id",
  /** column name */
  Title = "title",
  /** column name */
  TypeId = "type_id",
  /** column name */
  UpdatedAt = "updated_at",
}

/** input type for updating data in table "cases" */
export type Cases_Set_Input = {
  assigned_to?: InputMaybe<Scalars["uuid"]>;
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  organisation_id?: InputMaybe<Scalars["uuid"]>;
  party_id?: InputMaybe<Scalars["uuid"]>;
  title?: InputMaybe<Scalars["String"]>;
  type_id?: InputMaybe<Scalars["uuid"]>;
  updated_at?: InputMaybe<Scalars["timestamptz"]>;
};

/** Streaming cursor of the table "cases" */
export type Cases_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Cases_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Cases_Stream_Cursor_Value_Input = {
  assigned_to?: InputMaybe<Scalars["uuid"]>;
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  organisation_id?: InputMaybe<Scalars["uuid"]>;
  party_id?: InputMaybe<Scalars["uuid"]>;
  title?: InputMaybe<Scalars["String"]>;
  type_id?: InputMaybe<Scalars["uuid"]>;
  updated_at?: InputMaybe<Scalars["timestamptz"]>;
};

/** update columns of table "cases" */
export enum Cases_Update_Column {
  /** column name */
  AssignedTo = "assigned_to",
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  Id = "id",
  /** column name */
  OrganisationId = "organisation_id",
  /** column name */
  PartyId = "party_id",
  /** column name */
  Title = "title",
  /** column name */
  TypeId = "type_id",
  /** column name */
  UpdatedAt = "updated_at",
}

export type Cases_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Cases_Set_Input>;
  /** filter the rows which have to be updated */
  where: Cases_Bool_Exp;
};

/** columns and relationships of "channel_flags" */
export type Channel_Flags = {
  __typename?: "channel_flags";
  /** An object relationship */
  channel: Channels;
  channel_id: Scalars["uuid"];
  created_at?: Maybe<Scalars["timestamptz"]>;
  /** An object relationship */
  flag: Flags;
  flag_id: Scalars["uuid"];
  id: Scalars["uuid"];
};

/** aggregated selection of "channel_flags" */
export type Channel_Flags_Aggregate = {
  __typename?: "channel_flags_aggregate";
  aggregate?: Maybe<Channel_Flags_Aggregate_Fields>;
  nodes: Array<Channel_Flags>;
};

export type Channel_Flags_Aggregate_Bool_Exp = {
  count?: InputMaybe<Channel_Flags_Aggregate_Bool_Exp_Count>;
};

export type Channel_Flags_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Channel_Flags_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<Channel_Flags_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "channel_flags" */
export type Channel_Flags_Aggregate_Fields = {
  __typename?: "channel_flags_aggregate_fields";
  count: Scalars["Int"];
  max?: Maybe<Channel_Flags_Max_Fields>;
  min?: Maybe<Channel_Flags_Min_Fields>;
};

/** aggregate fields of "channel_flags" */
export type Channel_Flags_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Channel_Flags_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "channel_flags" */
export type Channel_Flags_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Channel_Flags_Max_Order_By>;
  min?: InputMaybe<Channel_Flags_Min_Order_By>;
};

/** input type for inserting array relation for remote table "channel_flags" */
export type Channel_Flags_Arr_Rel_Insert_Input = {
  data: Array<Channel_Flags_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Channel_Flags_On_Conflict>;
};

/** Boolean expression to filter rows from the table "channel_flags". All fields are combined with a logical 'AND'. */
export type Channel_Flags_Bool_Exp = {
  _and?: InputMaybe<Array<Channel_Flags_Bool_Exp>>;
  _not?: InputMaybe<Channel_Flags_Bool_Exp>;
  _or?: InputMaybe<Array<Channel_Flags_Bool_Exp>>;
  channel?: InputMaybe<Channels_Bool_Exp>;
  channel_id?: InputMaybe<Uuid_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  flag?: InputMaybe<Flags_Bool_Exp>;
  flag_id?: InputMaybe<Uuid_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "channel_flags" */
export enum Channel_Flags_Constraint {
  /** unique or primary key constraint on columns "id" */
  ChannelFlagsPkey = "channel_flags_pkey",
}

/** input type for inserting data into table "channel_flags" */
export type Channel_Flags_Insert_Input = {
  channel?: InputMaybe<Channels_Obj_Rel_Insert_Input>;
  channel_id?: InputMaybe<Scalars["uuid"]>;
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  flag?: InputMaybe<Flags_Obj_Rel_Insert_Input>;
  flag_id?: InputMaybe<Scalars["uuid"]>;
  id?: InputMaybe<Scalars["uuid"]>;
};

/** aggregate max on columns */
export type Channel_Flags_Max_Fields = {
  __typename?: "channel_flags_max_fields";
  channel_id?: Maybe<Scalars["uuid"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  flag_id?: Maybe<Scalars["uuid"]>;
  id?: Maybe<Scalars["uuid"]>;
};

/** order by max() on columns of table "channel_flags" */
export type Channel_Flags_Max_Order_By = {
  channel_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  flag_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Channel_Flags_Min_Fields = {
  __typename?: "channel_flags_min_fields";
  channel_id?: Maybe<Scalars["uuid"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  flag_id?: Maybe<Scalars["uuid"]>;
  id?: Maybe<Scalars["uuid"]>;
};

/** order by min() on columns of table "channel_flags" */
export type Channel_Flags_Min_Order_By = {
  channel_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  flag_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "channel_flags" */
export type Channel_Flags_Mutation_Response = {
  __typename?: "channel_flags_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Channel_Flags>;
};

/** on_conflict condition type for table "channel_flags" */
export type Channel_Flags_On_Conflict = {
  constraint: Channel_Flags_Constraint;
  update_columns?: Array<Channel_Flags_Update_Column>;
  where?: InputMaybe<Channel_Flags_Bool_Exp>;
};

/** Ordering options when selecting data from "channel_flags". */
export type Channel_Flags_Order_By = {
  channel?: InputMaybe<Channels_Order_By>;
  channel_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  flag?: InputMaybe<Flags_Order_By>;
  flag_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: channel_flags */
export type Channel_Flags_Pk_Columns_Input = {
  id: Scalars["uuid"];
};

/** select columns of table "channel_flags" */
export enum Channel_Flags_Select_Column {
  /** column name */
  ChannelId = "channel_id",
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  FlagId = "flag_id",
  /** column name */
  Id = "id",
}

/** input type for updating data in table "channel_flags" */
export type Channel_Flags_Set_Input = {
  channel_id?: InputMaybe<Scalars["uuid"]>;
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  flag_id?: InputMaybe<Scalars["uuid"]>;
  id?: InputMaybe<Scalars["uuid"]>;
};

/** Streaming cursor of the table "channel_flags" */
export type Channel_Flags_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Channel_Flags_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Channel_Flags_Stream_Cursor_Value_Input = {
  channel_id?: InputMaybe<Scalars["uuid"]>;
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  flag_id?: InputMaybe<Scalars["uuid"]>;
  id?: InputMaybe<Scalars["uuid"]>;
};

/** update columns of table "channel_flags" */
export enum Channel_Flags_Update_Column {
  /** column name */
  ChannelId = "channel_id",
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  FlagId = "flag_id",
  /** column name */
  Id = "id",
}

export type Channel_Flags_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Channel_Flags_Set_Input>;
  /** filter the rows which have to be updated */
  where: Channel_Flags_Bool_Exp;
};

/** columns and relationships of "channel_parties" */
export type Channel_Parties = {
  __typename?: "channel_parties";
  /** An object relationship */
  channel: Channels;
  channel_id: Scalars["uuid"];
  created_at?: Maybe<Scalars["timestamptz"]>;
  id: Scalars["uuid"];
  /** An object relationship */
  party: Parties;
  party_id: Scalars["uuid"];
};

/** aggregated selection of "channel_parties" */
export type Channel_Parties_Aggregate = {
  __typename?: "channel_parties_aggregate";
  aggregate?: Maybe<Channel_Parties_Aggregate_Fields>;
  nodes: Array<Channel_Parties>;
};

export type Channel_Parties_Aggregate_Bool_Exp = {
  count?: InputMaybe<Channel_Parties_Aggregate_Bool_Exp_Count>;
};

export type Channel_Parties_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Channel_Parties_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<Channel_Parties_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "channel_parties" */
export type Channel_Parties_Aggregate_Fields = {
  __typename?: "channel_parties_aggregate_fields";
  count: Scalars["Int"];
  max?: Maybe<Channel_Parties_Max_Fields>;
  min?: Maybe<Channel_Parties_Min_Fields>;
};

/** aggregate fields of "channel_parties" */
export type Channel_Parties_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Channel_Parties_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "channel_parties" */
export type Channel_Parties_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Channel_Parties_Max_Order_By>;
  min?: InputMaybe<Channel_Parties_Min_Order_By>;
};

/** input type for inserting array relation for remote table "channel_parties" */
export type Channel_Parties_Arr_Rel_Insert_Input = {
  data: Array<Channel_Parties_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Channel_Parties_On_Conflict>;
};

/** Boolean expression to filter rows from the table "channel_parties". All fields are combined with a logical 'AND'. */
export type Channel_Parties_Bool_Exp = {
  _and?: InputMaybe<Array<Channel_Parties_Bool_Exp>>;
  _not?: InputMaybe<Channel_Parties_Bool_Exp>;
  _or?: InputMaybe<Array<Channel_Parties_Bool_Exp>>;
  channel?: InputMaybe<Channels_Bool_Exp>;
  channel_id?: InputMaybe<Uuid_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  party?: InputMaybe<Parties_Bool_Exp>;
  party_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "channel_parties" */
export enum Channel_Parties_Constraint {
  /** unique or primary key constraint on columns "party_id", "channel_id" */
  ChannelPartiesChannelIdPartyIdKey = "channel_parties_channel_id_party_id_key",
  /** unique or primary key constraint on columns "id" */
  ChannelPartiesPkey = "channel_parties_pkey",
  /** unique or primary key constraint on columns "party_id", "channel_id" */
  UniquePartiesInChannel = "unique_parties_in_channel",
}

/** input type for inserting data into table "channel_parties" */
export type Channel_Parties_Insert_Input = {
  channel?: InputMaybe<Channels_Obj_Rel_Insert_Input>;
  channel_id?: InputMaybe<Scalars["uuid"]>;
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  party?: InputMaybe<Parties_Obj_Rel_Insert_Input>;
  party_id?: InputMaybe<Scalars["uuid"]>;
};

/** aggregate max on columns */
export type Channel_Parties_Max_Fields = {
  __typename?: "channel_parties_max_fields";
  channel_id?: Maybe<Scalars["uuid"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["uuid"]>;
  party_id?: Maybe<Scalars["uuid"]>;
};

/** order by max() on columns of table "channel_parties" */
export type Channel_Parties_Max_Order_By = {
  channel_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  party_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Channel_Parties_Min_Fields = {
  __typename?: "channel_parties_min_fields";
  channel_id?: Maybe<Scalars["uuid"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["uuid"]>;
  party_id?: Maybe<Scalars["uuid"]>;
};

/** order by min() on columns of table "channel_parties" */
export type Channel_Parties_Min_Order_By = {
  channel_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  party_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "channel_parties" */
export type Channel_Parties_Mutation_Response = {
  __typename?: "channel_parties_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Channel_Parties>;
};

/** on_conflict condition type for table "channel_parties" */
export type Channel_Parties_On_Conflict = {
  constraint: Channel_Parties_Constraint;
  update_columns?: Array<Channel_Parties_Update_Column>;
  where?: InputMaybe<Channel_Parties_Bool_Exp>;
};

/** Ordering options when selecting data from "channel_parties". */
export type Channel_Parties_Order_By = {
  channel?: InputMaybe<Channels_Order_By>;
  channel_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  party?: InputMaybe<Parties_Order_By>;
  party_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: channel_parties */
export type Channel_Parties_Pk_Columns_Input = {
  id: Scalars["uuid"];
};

/** select columns of table "channel_parties" */
export enum Channel_Parties_Select_Column {
  /** column name */
  ChannelId = "channel_id",
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  Id = "id",
  /** column name */
  PartyId = "party_id",
}

/** input type for updating data in table "channel_parties" */
export type Channel_Parties_Set_Input = {
  channel_id?: InputMaybe<Scalars["uuid"]>;
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  party_id?: InputMaybe<Scalars["uuid"]>;
};

/** Streaming cursor of the table "channel_parties" */
export type Channel_Parties_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Channel_Parties_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Channel_Parties_Stream_Cursor_Value_Input = {
  channel_id?: InputMaybe<Scalars["uuid"]>;
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  party_id?: InputMaybe<Scalars["uuid"]>;
};

/** update columns of table "channel_parties" */
export enum Channel_Parties_Update_Column {
  /** column name */
  ChannelId = "channel_id",
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  Id = "id",
  /** column name */
  PartyId = "party_id",
}

export type Channel_Parties_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Channel_Parties_Set_Input>;
  /** filter the rows which have to be updated */
  where: Channel_Parties_Bool_Exp;
};

/** columns and relationships of "channel_statuses" */
export type Channel_Statuses = {
  __typename?: "channel_statuses";
  /** An object relationship */
  channel: Channels;
  channel_id: Scalars["uuid"];
  created_at?: Maybe<Scalars["timestamptz"]>;
  id: Scalars["uuid"];
  /** An object relationship */
  status: Statuses;
  status_id: Scalars["uuid"];
};

/** aggregated selection of "channel_statuses" */
export type Channel_Statuses_Aggregate = {
  __typename?: "channel_statuses_aggregate";
  aggregate?: Maybe<Channel_Statuses_Aggregate_Fields>;
  nodes: Array<Channel_Statuses>;
};

export type Channel_Statuses_Aggregate_Bool_Exp = {
  count?: InputMaybe<Channel_Statuses_Aggregate_Bool_Exp_Count>;
};

export type Channel_Statuses_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Channel_Statuses_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<Channel_Statuses_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "channel_statuses" */
export type Channel_Statuses_Aggregate_Fields = {
  __typename?: "channel_statuses_aggregate_fields";
  count: Scalars["Int"];
  max?: Maybe<Channel_Statuses_Max_Fields>;
  min?: Maybe<Channel_Statuses_Min_Fields>;
};

/** aggregate fields of "channel_statuses" */
export type Channel_Statuses_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Channel_Statuses_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "channel_statuses" */
export type Channel_Statuses_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Channel_Statuses_Max_Order_By>;
  min?: InputMaybe<Channel_Statuses_Min_Order_By>;
};

/** input type for inserting array relation for remote table "channel_statuses" */
export type Channel_Statuses_Arr_Rel_Insert_Input = {
  data: Array<Channel_Statuses_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Channel_Statuses_On_Conflict>;
};

/** Boolean expression to filter rows from the table "channel_statuses". All fields are combined with a logical 'AND'. */
export type Channel_Statuses_Bool_Exp = {
  _and?: InputMaybe<Array<Channel_Statuses_Bool_Exp>>;
  _not?: InputMaybe<Channel_Statuses_Bool_Exp>;
  _or?: InputMaybe<Array<Channel_Statuses_Bool_Exp>>;
  channel?: InputMaybe<Channels_Bool_Exp>;
  channel_id?: InputMaybe<Uuid_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  status?: InputMaybe<Statuses_Bool_Exp>;
  status_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "channel_statuses" */
export enum Channel_Statuses_Constraint {
  /** unique or primary key constraint on columns "id" */
  ChannelStatusesPkey = "channel_statuses_pkey",
}

/** input type for inserting data into table "channel_statuses" */
export type Channel_Statuses_Insert_Input = {
  channel?: InputMaybe<Channels_Obj_Rel_Insert_Input>;
  channel_id?: InputMaybe<Scalars["uuid"]>;
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  status?: InputMaybe<Statuses_Obj_Rel_Insert_Input>;
  status_id?: InputMaybe<Scalars["uuid"]>;
};

/** aggregate max on columns */
export type Channel_Statuses_Max_Fields = {
  __typename?: "channel_statuses_max_fields";
  channel_id?: Maybe<Scalars["uuid"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["uuid"]>;
  status_id?: Maybe<Scalars["uuid"]>;
};

/** order by max() on columns of table "channel_statuses" */
export type Channel_Statuses_Max_Order_By = {
  channel_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  status_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Channel_Statuses_Min_Fields = {
  __typename?: "channel_statuses_min_fields";
  channel_id?: Maybe<Scalars["uuid"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["uuid"]>;
  status_id?: Maybe<Scalars["uuid"]>;
};

/** order by min() on columns of table "channel_statuses" */
export type Channel_Statuses_Min_Order_By = {
  channel_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  status_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "channel_statuses" */
export type Channel_Statuses_Mutation_Response = {
  __typename?: "channel_statuses_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Channel_Statuses>;
};

/** on_conflict condition type for table "channel_statuses" */
export type Channel_Statuses_On_Conflict = {
  constraint: Channel_Statuses_Constraint;
  update_columns?: Array<Channel_Statuses_Update_Column>;
  where?: InputMaybe<Channel_Statuses_Bool_Exp>;
};

/** Ordering options when selecting data from "channel_statuses". */
export type Channel_Statuses_Order_By = {
  channel?: InputMaybe<Channels_Order_By>;
  channel_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  status?: InputMaybe<Statuses_Order_By>;
  status_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: channel_statuses */
export type Channel_Statuses_Pk_Columns_Input = {
  id: Scalars["uuid"];
};

/** select columns of table "channel_statuses" */
export enum Channel_Statuses_Select_Column {
  /** column name */
  ChannelId = "channel_id",
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  Id = "id",
  /** column name */
  StatusId = "status_id",
}

/** input type for updating data in table "channel_statuses" */
export type Channel_Statuses_Set_Input = {
  channel_id?: InputMaybe<Scalars["uuid"]>;
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  status_id?: InputMaybe<Scalars["uuid"]>;
};

/** Streaming cursor of the table "channel_statuses" */
export type Channel_Statuses_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Channel_Statuses_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Channel_Statuses_Stream_Cursor_Value_Input = {
  channel_id?: InputMaybe<Scalars["uuid"]>;
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  status_id?: InputMaybe<Scalars["uuid"]>;
};

/** update columns of table "channel_statuses" */
export enum Channel_Statuses_Update_Column {
  /** column name */
  ChannelId = "channel_id",
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  Id = "id",
  /** column name */
  StatusId = "status_id",
}

export type Channel_Statuses_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Channel_Statuses_Set_Input>;
  /** filter the rows which have to be updated */
  where: Channel_Statuses_Bool_Exp;
};

/** columns and relationships of "channel_subscriptions" */
export type Channel_Subscriptions = {
  __typename?: "channel_subscriptions";
  /** An object relationship */
  channel: Channels;
  channel_id: Scalars["uuid"];
  created_at: Scalars["timestamptz"];
  delivery_address: Scalars["String"];
  /** An object relationship */
  delivery_method: Delivery_Methods;
  delivery_method_id: Scalars["uuid"];
  id: Scalars["uuid"];
  metadata?: Maybe<Scalars["jsonb"]>;
  /** An object relationship */
  party: Parties;
  party_id: Scalars["uuid"];
  /** An object relationship */
  status: Statuses;
  status_id: Scalars["uuid"];
  updated_at: Scalars["timestamptz"];
};

/** columns and relationships of "channel_subscriptions" */
export type Channel_SubscriptionsMetadataArgs = {
  path?: InputMaybe<Scalars["String"]>;
};

/** aggregated selection of "channel_subscriptions" */
export type Channel_Subscriptions_Aggregate = {
  __typename?: "channel_subscriptions_aggregate";
  aggregate?: Maybe<Channel_Subscriptions_Aggregate_Fields>;
  nodes: Array<Channel_Subscriptions>;
};

export type Channel_Subscriptions_Aggregate_Bool_Exp = {
  count?: InputMaybe<Channel_Subscriptions_Aggregate_Bool_Exp_Count>;
};

export type Channel_Subscriptions_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Channel_Subscriptions_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<Channel_Subscriptions_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "channel_subscriptions" */
export type Channel_Subscriptions_Aggregate_Fields = {
  __typename?: "channel_subscriptions_aggregate_fields";
  count: Scalars["Int"];
  max?: Maybe<Channel_Subscriptions_Max_Fields>;
  min?: Maybe<Channel_Subscriptions_Min_Fields>;
};

/** aggregate fields of "channel_subscriptions" */
export type Channel_Subscriptions_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Channel_Subscriptions_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "channel_subscriptions" */
export type Channel_Subscriptions_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Channel_Subscriptions_Max_Order_By>;
  min?: InputMaybe<Channel_Subscriptions_Min_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Channel_Subscriptions_Append_Input = {
  metadata?: InputMaybe<Scalars["jsonb"]>;
};

/** input type for inserting array relation for remote table "channel_subscriptions" */
export type Channel_Subscriptions_Arr_Rel_Insert_Input = {
  data: Array<Channel_Subscriptions_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Channel_Subscriptions_On_Conflict>;
};

/** Boolean expression to filter rows from the table "channel_subscriptions". All fields are combined with a logical 'AND'. */
export type Channel_Subscriptions_Bool_Exp = {
  _and?: InputMaybe<Array<Channel_Subscriptions_Bool_Exp>>;
  _not?: InputMaybe<Channel_Subscriptions_Bool_Exp>;
  _or?: InputMaybe<Array<Channel_Subscriptions_Bool_Exp>>;
  channel?: InputMaybe<Channels_Bool_Exp>;
  channel_id?: InputMaybe<Uuid_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  delivery_address?: InputMaybe<String_Comparison_Exp>;
  delivery_method?: InputMaybe<Delivery_Methods_Bool_Exp>;
  delivery_method_id?: InputMaybe<Uuid_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  metadata?: InputMaybe<Jsonb_Comparison_Exp>;
  party?: InputMaybe<Parties_Bool_Exp>;
  party_id?: InputMaybe<Uuid_Comparison_Exp>;
  status?: InputMaybe<Statuses_Bool_Exp>;
  status_id?: InputMaybe<Uuid_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "channel_subscriptions" */
export enum Channel_Subscriptions_Constraint {
  /** unique or primary key constraint on columns "id" */
  ChannelSubscriptionsPkey = "channel_subscriptions_pkey",
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Channel_Subscriptions_Delete_At_Path_Input = {
  metadata?: InputMaybe<Array<Scalars["String"]>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Channel_Subscriptions_Delete_Elem_Input = {
  metadata?: InputMaybe<Scalars["Int"]>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Channel_Subscriptions_Delete_Key_Input = {
  metadata?: InputMaybe<Scalars["String"]>;
};

/** input type for inserting data into table "channel_subscriptions" */
export type Channel_Subscriptions_Insert_Input = {
  channel?: InputMaybe<Channels_Obj_Rel_Insert_Input>;
  channel_id?: InputMaybe<Scalars["uuid"]>;
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  delivery_address?: InputMaybe<Scalars["String"]>;
  delivery_method?: InputMaybe<Delivery_Methods_Obj_Rel_Insert_Input>;
  delivery_method_id?: InputMaybe<Scalars["uuid"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  metadata?: InputMaybe<Scalars["jsonb"]>;
  party?: InputMaybe<Parties_Obj_Rel_Insert_Input>;
  party_id?: InputMaybe<Scalars["uuid"]>;
  status?: InputMaybe<Statuses_Obj_Rel_Insert_Input>;
  status_id?: InputMaybe<Scalars["uuid"]>;
  updated_at?: InputMaybe<Scalars["timestamptz"]>;
};

/** aggregate max on columns */
export type Channel_Subscriptions_Max_Fields = {
  __typename?: "channel_subscriptions_max_fields";
  channel_id?: Maybe<Scalars["uuid"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  delivery_address?: Maybe<Scalars["String"]>;
  delivery_method_id?: Maybe<Scalars["uuid"]>;
  id?: Maybe<Scalars["uuid"]>;
  party_id?: Maybe<Scalars["uuid"]>;
  status_id?: Maybe<Scalars["uuid"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
};

/** order by max() on columns of table "channel_subscriptions" */
export type Channel_Subscriptions_Max_Order_By = {
  channel_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  delivery_address?: InputMaybe<Order_By>;
  delivery_method_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  party_id?: InputMaybe<Order_By>;
  status_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Channel_Subscriptions_Min_Fields = {
  __typename?: "channel_subscriptions_min_fields";
  channel_id?: Maybe<Scalars["uuid"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  delivery_address?: Maybe<Scalars["String"]>;
  delivery_method_id?: Maybe<Scalars["uuid"]>;
  id?: Maybe<Scalars["uuid"]>;
  party_id?: Maybe<Scalars["uuid"]>;
  status_id?: Maybe<Scalars["uuid"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
};

/** order by min() on columns of table "channel_subscriptions" */
export type Channel_Subscriptions_Min_Order_By = {
  channel_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  delivery_address?: InputMaybe<Order_By>;
  delivery_method_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  party_id?: InputMaybe<Order_By>;
  status_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "channel_subscriptions" */
export type Channel_Subscriptions_Mutation_Response = {
  __typename?: "channel_subscriptions_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Channel_Subscriptions>;
};

/** on_conflict condition type for table "channel_subscriptions" */
export type Channel_Subscriptions_On_Conflict = {
  constraint: Channel_Subscriptions_Constraint;
  update_columns?: Array<Channel_Subscriptions_Update_Column>;
  where?: InputMaybe<Channel_Subscriptions_Bool_Exp>;
};

/** Ordering options when selecting data from "channel_subscriptions". */
export type Channel_Subscriptions_Order_By = {
  channel?: InputMaybe<Channels_Order_By>;
  channel_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  delivery_address?: InputMaybe<Order_By>;
  delivery_method?: InputMaybe<Delivery_Methods_Order_By>;
  delivery_method_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  metadata?: InputMaybe<Order_By>;
  party?: InputMaybe<Parties_Order_By>;
  party_id?: InputMaybe<Order_By>;
  status?: InputMaybe<Statuses_Order_By>;
  status_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: channel_subscriptions */
export type Channel_Subscriptions_Pk_Columns_Input = {
  id: Scalars["uuid"];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Channel_Subscriptions_Prepend_Input = {
  metadata?: InputMaybe<Scalars["jsonb"]>;
};

/** select columns of table "channel_subscriptions" */
export enum Channel_Subscriptions_Select_Column {
  /** column name */
  ChannelId = "channel_id",
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  DeliveryAddress = "delivery_address",
  /** column name */
  DeliveryMethodId = "delivery_method_id",
  /** column name */
  Id = "id",
  /** column name */
  Metadata = "metadata",
  /** column name */
  PartyId = "party_id",
  /** column name */
  StatusId = "status_id",
  /** column name */
  UpdatedAt = "updated_at",
}

/** input type for updating data in table "channel_subscriptions" */
export type Channel_Subscriptions_Set_Input = {
  channel_id?: InputMaybe<Scalars["uuid"]>;
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  delivery_address?: InputMaybe<Scalars["String"]>;
  delivery_method_id?: InputMaybe<Scalars["uuid"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  metadata?: InputMaybe<Scalars["jsonb"]>;
  party_id?: InputMaybe<Scalars["uuid"]>;
  status_id?: InputMaybe<Scalars["uuid"]>;
  updated_at?: InputMaybe<Scalars["timestamptz"]>;
};

/** Streaming cursor of the table "channel_subscriptions" */
export type Channel_Subscriptions_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Channel_Subscriptions_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Channel_Subscriptions_Stream_Cursor_Value_Input = {
  channel_id?: InputMaybe<Scalars["uuid"]>;
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  delivery_address?: InputMaybe<Scalars["String"]>;
  delivery_method_id?: InputMaybe<Scalars["uuid"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  metadata?: InputMaybe<Scalars["jsonb"]>;
  party_id?: InputMaybe<Scalars["uuid"]>;
  status_id?: InputMaybe<Scalars["uuid"]>;
  updated_at?: InputMaybe<Scalars["timestamptz"]>;
};

/** update columns of table "channel_subscriptions" */
export enum Channel_Subscriptions_Update_Column {
  /** column name */
  ChannelId = "channel_id",
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  DeliveryAddress = "delivery_address",
  /** column name */
  DeliveryMethodId = "delivery_method_id",
  /** column name */
  Id = "id",
  /** column name */
  Metadata = "metadata",
  /** column name */
  PartyId = "party_id",
  /** column name */
  StatusId = "status_id",
  /** column name */
  UpdatedAt = "updated_at",
}

export type Channel_Subscriptions_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Channel_Subscriptions_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Channel_Subscriptions_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Channel_Subscriptions_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Channel_Subscriptions_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Channel_Subscriptions_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Channel_Subscriptions_Set_Input>;
  /** filter the rows which have to be updated */
  where: Channel_Subscriptions_Bool_Exp;
};

/** columns and relationships of "channels" */
export type Channels = {
  __typename?: "channels";
  /** An array relationship */
  campaigns: Array<Campaigns>;
  /** An aggregate relationship */
  campaigns_aggregate: Campaigns_Aggregate;
  /** An array relationship */
  channel_flags: Array<Channel_Flags>;
  /** An aggregate relationship */
  channel_flags_aggregate: Channel_Flags_Aggregate;
  /** An array relationship */
  channel_parties: Array<Channel_Parties>;
  /** An aggregate relationship */
  channel_parties_aggregate: Channel_Parties_Aggregate;
  /** An array relationship */
  channel_statuses: Array<Channel_Statuses>;
  /** An aggregate relationship */
  channel_statuses_aggregate: Channel_Statuses_Aggregate;
  /** An array relationship */
  channel_subscriptions: Array<Channel_Subscriptions>;
  /** An aggregate relationship */
  channel_subscriptions_aggregate: Channel_Subscriptions_Aggregate;
  created_at: Scalars["timestamptz"];
  description?: Maybe<Scalars["String"]>;
  id: Scalars["uuid"];
  /** An array relationship */
  messages: Array<Messages>;
  /** An aggregate relationship */
  messages_aggregate: Messages_Aggregate;
  name: Scalars["String"];
  organisation_id: Scalars["uuid"];
  /** An object relationship */
  type: Types;
  type_id: Scalars["uuid"];
  updated_at: Scalars["timestamptz"];
};

/** columns and relationships of "channels" */
export type ChannelsCampaignsArgs = {
  distinct_on?: InputMaybe<Array<Campaigns_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Campaigns_Order_By>>;
  where?: InputMaybe<Campaigns_Bool_Exp>;
};

/** columns and relationships of "channels" */
export type ChannelsCampaigns_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Campaigns_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Campaigns_Order_By>>;
  where?: InputMaybe<Campaigns_Bool_Exp>;
};

/** columns and relationships of "channels" */
export type ChannelsChannel_FlagsArgs = {
  distinct_on?: InputMaybe<Array<Channel_Flags_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Channel_Flags_Order_By>>;
  where?: InputMaybe<Channel_Flags_Bool_Exp>;
};

/** columns and relationships of "channels" */
export type ChannelsChannel_Flags_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Channel_Flags_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Channel_Flags_Order_By>>;
  where?: InputMaybe<Channel_Flags_Bool_Exp>;
};

/** columns and relationships of "channels" */
export type ChannelsChannel_PartiesArgs = {
  distinct_on?: InputMaybe<Array<Channel_Parties_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Channel_Parties_Order_By>>;
  where?: InputMaybe<Channel_Parties_Bool_Exp>;
};

/** columns and relationships of "channels" */
export type ChannelsChannel_Parties_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Channel_Parties_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Channel_Parties_Order_By>>;
  where?: InputMaybe<Channel_Parties_Bool_Exp>;
};

/** columns and relationships of "channels" */
export type ChannelsChannel_StatusesArgs = {
  distinct_on?: InputMaybe<Array<Channel_Statuses_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Channel_Statuses_Order_By>>;
  where?: InputMaybe<Channel_Statuses_Bool_Exp>;
};

/** columns and relationships of "channels" */
export type ChannelsChannel_Statuses_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Channel_Statuses_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Channel_Statuses_Order_By>>;
  where?: InputMaybe<Channel_Statuses_Bool_Exp>;
};

/** columns and relationships of "channels" */
export type ChannelsChannel_SubscriptionsArgs = {
  distinct_on?: InputMaybe<Array<Channel_Subscriptions_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Channel_Subscriptions_Order_By>>;
  where?: InputMaybe<Channel_Subscriptions_Bool_Exp>;
};

/** columns and relationships of "channels" */
export type ChannelsChannel_Subscriptions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Channel_Subscriptions_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Channel_Subscriptions_Order_By>>;
  where?: InputMaybe<Channel_Subscriptions_Bool_Exp>;
};

/** columns and relationships of "channels" */
export type ChannelsMessagesArgs = {
  distinct_on?: InputMaybe<Array<Messages_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Messages_Order_By>>;
  where?: InputMaybe<Messages_Bool_Exp>;
};

/** columns and relationships of "channels" */
export type ChannelsMessages_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Messages_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Messages_Order_By>>;
  where?: InputMaybe<Messages_Bool_Exp>;
};

/** aggregated selection of "channels" */
export type Channels_Aggregate = {
  __typename?: "channels_aggregate";
  aggregate?: Maybe<Channels_Aggregate_Fields>;
  nodes: Array<Channels>;
};

/** aggregate fields of "channels" */
export type Channels_Aggregate_Fields = {
  __typename?: "channels_aggregate_fields";
  count: Scalars["Int"];
  max?: Maybe<Channels_Max_Fields>;
  min?: Maybe<Channels_Min_Fields>;
};

/** aggregate fields of "channels" */
export type Channels_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Channels_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** Boolean expression to filter rows from the table "channels". All fields are combined with a logical 'AND'. */
export type Channels_Bool_Exp = {
  _and?: InputMaybe<Array<Channels_Bool_Exp>>;
  _not?: InputMaybe<Channels_Bool_Exp>;
  _or?: InputMaybe<Array<Channels_Bool_Exp>>;
  campaigns?: InputMaybe<Campaigns_Bool_Exp>;
  campaigns_aggregate?: InputMaybe<Campaigns_Aggregate_Bool_Exp>;
  channel_flags?: InputMaybe<Channel_Flags_Bool_Exp>;
  channel_flags_aggregate?: InputMaybe<Channel_Flags_Aggregate_Bool_Exp>;
  channel_parties?: InputMaybe<Channel_Parties_Bool_Exp>;
  channel_parties_aggregate?: InputMaybe<Channel_Parties_Aggregate_Bool_Exp>;
  channel_statuses?: InputMaybe<Channel_Statuses_Bool_Exp>;
  channel_statuses_aggregate?: InputMaybe<Channel_Statuses_Aggregate_Bool_Exp>;
  channel_subscriptions?: InputMaybe<Channel_Subscriptions_Bool_Exp>;
  channel_subscriptions_aggregate?: InputMaybe<Channel_Subscriptions_Aggregate_Bool_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  messages?: InputMaybe<Messages_Bool_Exp>;
  messages_aggregate?: InputMaybe<Messages_Aggregate_Bool_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  organisation_id?: InputMaybe<Uuid_Comparison_Exp>;
  type?: InputMaybe<Types_Bool_Exp>;
  type_id?: InputMaybe<Uuid_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "channels" */
export enum Channels_Constraint {
  /** unique or primary key constraint on columns "id" */
  ChannelsPkey = "channels_pkey",
}

/** input type for inserting data into table "channels" */
export type Channels_Insert_Input = {
  campaigns?: InputMaybe<Campaigns_Arr_Rel_Insert_Input>;
  channel_flags?: InputMaybe<Channel_Flags_Arr_Rel_Insert_Input>;
  channel_parties?: InputMaybe<Channel_Parties_Arr_Rel_Insert_Input>;
  channel_statuses?: InputMaybe<Channel_Statuses_Arr_Rel_Insert_Input>;
  channel_subscriptions?: InputMaybe<Channel_Subscriptions_Arr_Rel_Insert_Input>;
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  description?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  messages?: InputMaybe<Messages_Arr_Rel_Insert_Input>;
  name?: InputMaybe<Scalars["String"]>;
  organisation_id?: InputMaybe<Scalars["uuid"]>;
  type?: InputMaybe<Types_Obj_Rel_Insert_Input>;
  type_id?: InputMaybe<Scalars["uuid"]>;
  updated_at?: InputMaybe<Scalars["timestamptz"]>;
};

/** aggregate max on columns */
export type Channels_Max_Fields = {
  __typename?: "channels_max_fields";
  created_at?: Maybe<Scalars["timestamptz"]>;
  description?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["uuid"]>;
  name?: Maybe<Scalars["String"]>;
  organisation_id?: Maybe<Scalars["uuid"]>;
  type_id?: Maybe<Scalars["uuid"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
};

/** aggregate min on columns */
export type Channels_Min_Fields = {
  __typename?: "channels_min_fields";
  created_at?: Maybe<Scalars["timestamptz"]>;
  description?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["uuid"]>;
  name?: Maybe<Scalars["String"]>;
  organisation_id?: Maybe<Scalars["uuid"]>;
  type_id?: Maybe<Scalars["uuid"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
};

/** response of any mutation on the table "channels" */
export type Channels_Mutation_Response = {
  __typename?: "channels_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Channels>;
};

/** input type for inserting object relation for remote table "channels" */
export type Channels_Obj_Rel_Insert_Input = {
  data: Channels_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Channels_On_Conflict>;
};

/** on_conflict condition type for table "channels" */
export type Channels_On_Conflict = {
  constraint: Channels_Constraint;
  update_columns?: Array<Channels_Update_Column>;
  where?: InputMaybe<Channels_Bool_Exp>;
};

/** Ordering options when selecting data from "channels". */
export type Channels_Order_By = {
  campaigns_aggregate?: InputMaybe<Campaigns_Aggregate_Order_By>;
  channel_flags_aggregate?: InputMaybe<Channel_Flags_Aggregate_Order_By>;
  channel_parties_aggregate?: InputMaybe<Channel_Parties_Aggregate_Order_By>;
  channel_statuses_aggregate?: InputMaybe<Channel_Statuses_Aggregate_Order_By>;
  channel_subscriptions_aggregate?: InputMaybe<Channel_Subscriptions_Aggregate_Order_By>;
  created_at?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  messages_aggregate?: InputMaybe<Messages_Aggregate_Order_By>;
  name?: InputMaybe<Order_By>;
  organisation_id?: InputMaybe<Order_By>;
  type?: InputMaybe<Types_Order_By>;
  type_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: channels */
export type Channels_Pk_Columns_Input = {
  id: Scalars["uuid"];
};

/** select columns of table "channels" */
export enum Channels_Select_Column {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  Description = "description",
  /** column name */
  Id = "id",
  /** column name */
  Name = "name",
  /** column name */
  OrganisationId = "organisation_id",
  /** column name */
  TypeId = "type_id",
  /** column name */
  UpdatedAt = "updated_at",
}

/** input type for updating data in table "channels" */
export type Channels_Set_Input = {
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  description?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  name?: InputMaybe<Scalars["String"]>;
  organisation_id?: InputMaybe<Scalars["uuid"]>;
  type_id?: InputMaybe<Scalars["uuid"]>;
  updated_at?: InputMaybe<Scalars["timestamptz"]>;
};

/** Streaming cursor of the table "channels" */
export type Channels_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Channels_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Channels_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  description?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  name?: InputMaybe<Scalars["String"]>;
  organisation_id?: InputMaybe<Scalars["uuid"]>;
  type_id?: InputMaybe<Scalars["uuid"]>;
  updated_at?: InputMaybe<Scalars["timestamptz"]>;
};

/** update columns of table "channels" */
export enum Channels_Update_Column {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  Description = "description",
  /** column name */
  Id = "id",
  /** column name */
  Name = "name",
  /** column name */
  OrganisationId = "organisation_id",
  /** column name */
  TypeId = "type_id",
  /** column name */
  UpdatedAt = "updated_at",
}

export type Channels_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Channels_Set_Input>;
  /** filter the rows which have to be updated */
  where: Channels_Bool_Exp;
};

export type Closed_Cases_Daily_Args = {
  start_date?: InputMaybe<Scalars["date"]>;
  stop_date?: InputMaybe<Scalars["date"]>;
};

/** columns and relationships of "conditions" */
export type Conditions = {
  __typename?: "conditions";
  created_at: Scalars["timestamptz"];
  /** Table name */
  fact: Scalars["String"];
  /** Use colon to denote nested hierarchy */
  hierarchy_resolver?: Maybe<Scalars["String"]>;
  id: Scalars["uuid"];
  is_active: Scalars["Boolean"];
  operator: Scalars["String"];
  organisation_id?: Maybe<Scalars["uuid"]>;
  params?: Maybe<Scalars["jsonb"]>;
  path: Scalars["String"];
  priority: Scalars["Int"];
  root_operator: Scalars["String"];
  /** An object relationship */
  rule: Rules;
  rule_id: Scalars["uuid"];
  updated_at: Scalars["timestamptz"];
  value: Scalars["String"];
};

/** columns and relationships of "conditions" */
export type ConditionsParamsArgs = {
  path?: InputMaybe<Scalars["String"]>;
};

/** aggregated selection of "conditions" */
export type Conditions_Aggregate = {
  __typename?: "conditions_aggregate";
  aggregate?: Maybe<Conditions_Aggregate_Fields>;
  nodes: Array<Conditions>;
};

export type Conditions_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Conditions_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Conditions_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Conditions_Aggregate_Bool_Exp_Count>;
};

export type Conditions_Aggregate_Bool_Exp_Bool_And = {
  arguments: Conditions_Select_Column_Conditions_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<Conditions_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Conditions_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Conditions_Select_Column_Conditions_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<Conditions_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Conditions_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Conditions_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<Conditions_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "conditions" */
export type Conditions_Aggregate_Fields = {
  __typename?: "conditions_aggregate_fields";
  avg?: Maybe<Conditions_Avg_Fields>;
  count: Scalars["Int"];
  max?: Maybe<Conditions_Max_Fields>;
  min?: Maybe<Conditions_Min_Fields>;
  stddev?: Maybe<Conditions_Stddev_Fields>;
  stddev_pop?: Maybe<Conditions_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Conditions_Stddev_Samp_Fields>;
  sum?: Maybe<Conditions_Sum_Fields>;
  var_pop?: Maybe<Conditions_Var_Pop_Fields>;
  var_samp?: Maybe<Conditions_Var_Samp_Fields>;
  variance?: Maybe<Conditions_Variance_Fields>;
};

/** aggregate fields of "conditions" */
export type Conditions_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Conditions_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "conditions" */
export type Conditions_Aggregate_Order_By = {
  avg?: InputMaybe<Conditions_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Conditions_Max_Order_By>;
  min?: InputMaybe<Conditions_Min_Order_By>;
  stddev?: InputMaybe<Conditions_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Conditions_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Conditions_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Conditions_Sum_Order_By>;
  var_pop?: InputMaybe<Conditions_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Conditions_Var_Samp_Order_By>;
  variance?: InputMaybe<Conditions_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Conditions_Append_Input = {
  params?: InputMaybe<Scalars["jsonb"]>;
};

/** input type for inserting array relation for remote table "conditions" */
export type Conditions_Arr_Rel_Insert_Input = {
  data: Array<Conditions_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Conditions_On_Conflict>;
};

/** aggregate avg on columns */
export type Conditions_Avg_Fields = {
  __typename?: "conditions_avg_fields";
  priority?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "conditions" */
export type Conditions_Avg_Order_By = {
  priority?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "conditions". All fields are combined with a logical 'AND'. */
export type Conditions_Bool_Exp = {
  _and?: InputMaybe<Array<Conditions_Bool_Exp>>;
  _not?: InputMaybe<Conditions_Bool_Exp>;
  _or?: InputMaybe<Array<Conditions_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  fact?: InputMaybe<String_Comparison_Exp>;
  hierarchy_resolver?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  is_active?: InputMaybe<Boolean_Comparison_Exp>;
  operator?: InputMaybe<String_Comparison_Exp>;
  organisation_id?: InputMaybe<Uuid_Comparison_Exp>;
  params?: InputMaybe<Jsonb_Comparison_Exp>;
  path?: InputMaybe<String_Comparison_Exp>;
  priority?: InputMaybe<Int_Comparison_Exp>;
  root_operator?: InputMaybe<String_Comparison_Exp>;
  rule?: InputMaybe<Rules_Bool_Exp>;
  rule_id?: InputMaybe<Uuid_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "conditions" */
export enum Conditions_Constraint {
  /** unique or primary key constraint on columns "id" */
  ConditionsPkey = "conditions_pkey",
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Conditions_Delete_At_Path_Input = {
  params?: InputMaybe<Array<Scalars["String"]>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Conditions_Delete_Elem_Input = {
  params?: InputMaybe<Scalars["Int"]>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Conditions_Delete_Key_Input = {
  params?: InputMaybe<Scalars["String"]>;
};

/** input type for incrementing numeric columns in table "conditions" */
export type Conditions_Inc_Input = {
  priority?: InputMaybe<Scalars["Int"]>;
};

/** input type for inserting data into table "conditions" */
export type Conditions_Insert_Input = {
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  /** Table name */
  fact?: InputMaybe<Scalars["String"]>;
  /** Use colon to denote nested hierarchy */
  hierarchy_resolver?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  is_active?: InputMaybe<Scalars["Boolean"]>;
  operator?: InputMaybe<Scalars["String"]>;
  organisation_id?: InputMaybe<Scalars["uuid"]>;
  params?: InputMaybe<Scalars["jsonb"]>;
  path?: InputMaybe<Scalars["String"]>;
  priority?: InputMaybe<Scalars["Int"]>;
  root_operator?: InputMaybe<Scalars["String"]>;
  rule?: InputMaybe<Rules_Obj_Rel_Insert_Input>;
  rule_id?: InputMaybe<Scalars["uuid"]>;
  updated_at?: InputMaybe<Scalars["timestamptz"]>;
  value?: InputMaybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Conditions_Max_Fields = {
  __typename?: "conditions_max_fields";
  created_at?: Maybe<Scalars["timestamptz"]>;
  /** Table name */
  fact?: Maybe<Scalars["String"]>;
  /** Use colon to denote nested hierarchy */
  hierarchy_resolver?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["uuid"]>;
  operator?: Maybe<Scalars["String"]>;
  organisation_id?: Maybe<Scalars["uuid"]>;
  path?: Maybe<Scalars["String"]>;
  priority?: Maybe<Scalars["Int"]>;
  root_operator?: Maybe<Scalars["String"]>;
  rule_id?: Maybe<Scalars["uuid"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
  value?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "conditions" */
export type Conditions_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  /** Table name */
  fact?: InputMaybe<Order_By>;
  /** Use colon to denote nested hierarchy */
  hierarchy_resolver?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  operator?: InputMaybe<Order_By>;
  organisation_id?: InputMaybe<Order_By>;
  path?: InputMaybe<Order_By>;
  priority?: InputMaybe<Order_By>;
  root_operator?: InputMaybe<Order_By>;
  rule_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Conditions_Min_Fields = {
  __typename?: "conditions_min_fields";
  created_at?: Maybe<Scalars["timestamptz"]>;
  /** Table name */
  fact?: Maybe<Scalars["String"]>;
  /** Use colon to denote nested hierarchy */
  hierarchy_resolver?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["uuid"]>;
  operator?: Maybe<Scalars["String"]>;
  organisation_id?: Maybe<Scalars["uuid"]>;
  path?: Maybe<Scalars["String"]>;
  priority?: Maybe<Scalars["Int"]>;
  root_operator?: Maybe<Scalars["String"]>;
  rule_id?: Maybe<Scalars["uuid"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
  value?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "conditions" */
export type Conditions_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  /** Table name */
  fact?: InputMaybe<Order_By>;
  /** Use colon to denote nested hierarchy */
  hierarchy_resolver?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  operator?: InputMaybe<Order_By>;
  organisation_id?: InputMaybe<Order_By>;
  path?: InputMaybe<Order_By>;
  priority?: InputMaybe<Order_By>;
  root_operator?: InputMaybe<Order_By>;
  rule_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "conditions" */
export type Conditions_Mutation_Response = {
  __typename?: "conditions_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Conditions>;
};

/** on_conflict condition type for table "conditions" */
export type Conditions_On_Conflict = {
  constraint: Conditions_Constraint;
  update_columns?: Array<Conditions_Update_Column>;
  where?: InputMaybe<Conditions_Bool_Exp>;
};

/** Ordering options when selecting data from "conditions". */
export type Conditions_Order_By = {
  created_at?: InputMaybe<Order_By>;
  fact?: InputMaybe<Order_By>;
  hierarchy_resolver?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  is_active?: InputMaybe<Order_By>;
  operator?: InputMaybe<Order_By>;
  organisation_id?: InputMaybe<Order_By>;
  params?: InputMaybe<Order_By>;
  path?: InputMaybe<Order_By>;
  priority?: InputMaybe<Order_By>;
  root_operator?: InputMaybe<Order_By>;
  rule?: InputMaybe<Rules_Order_By>;
  rule_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: conditions */
export type Conditions_Pk_Columns_Input = {
  id: Scalars["uuid"];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Conditions_Prepend_Input = {
  params?: InputMaybe<Scalars["jsonb"]>;
};

/** select columns of table "conditions" */
export enum Conditions_Select_Column {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  Fact = "fact",
  /** column name */
  HierarchyResolver = "hierarchy_resolver",
  /** column name */
  Id = "id",
  /** column name */
  IsActive = "is_active",
  /** column name */
  Operator = "operator",
  /** column name */
  OrganisationId = "organisation_id",
  /** column name */
  Params = "params",
  /** column name */
  Path = "path",
  /** column name */
  Priority = "priority",
  /** column name */
  RootOperator = "root_operator",
  /** column name */
  RuleId = "rule_id",
  /** column name */
  UpdatedAt = "updated_at",
  /** column name */
  Value = "value",
}

/** select "conditions_aggregate_bool_exp_bool_and_arguments_columns" columns of table "conditions" */
export enum Conditions_Select_Column_Conditions_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  IsActive = "is_active",
}

/** select "conditions_aggregate_bool_exp_bool_or_arguments_columns" columns of table "conditions" */
export enum Conditions_Select_Column_Conditions_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  IsActive = "is_active",
}

/** input type for updating data in table "conditions" */
export type Conditions_Set_Input = {
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  /** Table name */
  fact?: InputMaybe<Scalars["String"]>;
  /** Use colon to denote nested hierarchy */
  hierarchy_resolver?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  is_active?: InputMaybe<Scalars["Boolean"]>;
  operator?: InputMaybe<Scalars["String"]>;
  organisation_id?: InputMaybe<Scalars["uuid"]>;
  params?: InputMaybe<Scalars["jsonb"]>;
  path?: InputMaybe<Scalars["String"]>;
  priority?: InputMaybe<Scalars["Int"]>;
  root_operator?: InputMaybe<Scalars["String"]>;
  rule_id?: InputMaybe<Scalars["uuid"]>;
  updated_at?: InputMaybe<Scalars["timestamptz"]>;
  value?: InputMaybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type Conditions_Stddev_Fields = {
  __typename?: "conditions_stddev_fields";
  priority?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "conditions" */
export type Conditions_Stddev_Order_By = {
  priority?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Conditions_Stddev_Pop_Fields = {
  __typename?: "conditions_stddev_pop_fields";
  priority?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "conditions" */
export type Conditions_Stddev_Pop_Order_By = {
  priority?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Conditions_Stddev_Samp_Fields = {
  __typename?: "conditions_stddev_samp_fields";
  priority?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "conditions" */
export type Conditions_Stddev_Samp_Order_By = {
  priority?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "conditions" */
export type Conditions_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Conditions_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Conditions_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  /** Table name */
  fact?: InputMaybe<Scalars["String"]>;
  /** Use colon to denote nested hierarchy */
  hierarchy_resolver?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  is_active?: InputMaybe<Scalars["Boolean"]>;
  operator?: InputMaybe<Scalars["String"]>;
  organisation_id?: InputMaybe<Scalars["uuid"]>;
  params?: InputMaybe<Scalars["jsonb"]>;
  path?: InputMaybe<Scalars["String"]>;
  priority?: InputMaybe<Scalars["Int"]>;
  root_operator?: InputMaybe<Scalars["String"]>;
  rule_id?: InputMaybe<Scalars["uuid"]>;
  updated_at?: InputMaybe<Scalars["timestamptz"]>;
  value?: InputMaybe<Scalars["String"]>;
};

/** aggregate sum on columns */
export type Conditions_Sum_Fields = {
  __typename?: "conditions_sum_fields";
  priority?: Maybe<Scalars["Int"]>;
};

/** order by sum() on columns of table "conditions" */
export type Conditions_Sum_Order_By = {
  priority?: InputMaybe<Order_By>;
};

/** update columns of table "conditions" */
export enum Conditions_Update_Column {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  Fact = "fact",
  /** column name */
  HierarchyResolver = "hierarchy_resolver",
  /** column name */
  Id = "id",
  /** column name */
  IsActive = "is_active",
  /** column name */
  Operator = "operator",
  /** column name */
  OrganisationId = "organisation_id",
  /** column name */
  Params = "params",
  /** column name */
  Path = "path",
  /** column name */
  Priority = "priority",
  /** column name */
  RootOperator = "root_operator",
  /** column name */
  RuleId = "rule_id",
  /** column name */
  UpdatedAt = "updated_at",
  /** column name */
  Value = "value",
}

export type Conditions_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Conditions_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Conditions_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Conditions_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Conditions_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Conditions_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Conditions_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Conditions_Set_Input>;
  /** filter the rows which have to be updated */
  where: Conditions_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Conditions_Var_Pop_Fields = {
  __typename?: "conditions_var_pop_fields";
  priority?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "conditions" */
export type Conditions_Var_Pop_Order_By = {
  priority?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Conditions_Var_Samp_Fields = {
  __typename?: "conditions_var_samp_fields";
  priority?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "conditions" */
export type Conditions_Var_Samp_Order_By = {
  priority?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Conditions_Variance_Fields = {
  __typename?: "conditions_variance_fields";
  priority?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "conditions" */
export type Conditions_Variance_Order_By = {
  priority?: InputMaybe<Order_By>;
};

/** columns and relationships of "contact_details" */
export type Contact_Details = {
  __typename?: "contact_details";
  /** An object relationship */
  contact: Contacts;
  contact_id: Scalars["uuid"];
  created_at: Scalars["timestamptz"];
  /** An object relationship */
  form: Forms;
  /** An object relationship */
  form_field: Form_Fields;
  form_field_id: Scalars["uuid"];
  form_id: Scalars["uuid"];
  id: Scalars["uuid"];
  updated_at: Scalars["timestamptz"];
  value?: Maybe<Scalars["String"]>;
};

/** aggregated selection of "contact_details" */
export type Contact_Details_Aggregate = {
  __typename?: "contact_details_aggregate";
  aggregate?: Maybe<Contact_Details_Aggregate_Fields>;
  nodes: Array<Contact_Details>;
};

export type Contact_Details_Aggregate_Bool_Exp = {
  count?: InputMaybe<Contact_Details_Aggregate_Bool_Exp_Count>;
};

export type Contact_Details_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Contact_Details_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<Contact_Details_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "contact_details" */
export type Contact_Details_Aggregate_Fields = {
  __typename?: "contact_details_aggregate_fields";
  count: Scalars["Int"];
  max?: Maybe<Contact_Details_Max_Fields>;
  min?: Maybe<Contact_Details_Min_Fields>;
};

/** aggregate fields of "contact_details" */
export type Contact_Details_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Contact_Details_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "contact_details" */
export type Contact_Details_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Contact_Details_Max_Order_By>;
  min?: InputMaybe<Contact_Details_Min_Order_By>;
};

/** input type for inserting array relation for remote table "contact_details" */
export type Contact_Details_Arr_Rel_Insert_Input = {
  data: Array<Contact_Details_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Contact_Details_On_Conflict>;
};

/** Boolean expression to filter rows from the table "contact_details". All fields are combined with a logical 'AND'. */
export type Contact_Details_Bool_Exp = {
  _and?: InputMaybe<Array<Contact_Details_Bool_Exp>>;
  _not?: InputMaybe<Contact_Details_Bool_Exp>;
  _or?: InputMaybe<Array<Contact_Details_Bool_Exp>>;
  contact?: InputMaybe<Contacts_Bool_Exp>;
  contact_id?: InputMaybe<Uuid_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  form?: InputMaybe<Forms_Bool_Exp>;
  form_field?: InputMaybe<Form_Fields_Bool_Exp>;
  form_field_id?: InputMaybe<Uuid_Comparison_Exp>;
  form_id?: InputMaybe<Uuid_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "contact_details" */
export enum Contact_Details_Constraint {
  /** unique or primary key constraint on columns "id" */
  ContactDetailsPkey = "contact_details_pkey",
}

/** input type for inserting data into table "contact_details" */
export type Contact_Details_Insert_Input = {
  contact?: InputMaybe<Contacts_Obj_Rel_Insert_Input>;
  contact_id?: InputMaybe<Scalars["uuid"]>;
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  form?: InputMaybe<Forms_Obj_Rel_Insert_Input>;
  form_field?: InputMaybe<Form_Fields_Obj_Rel_Insert_Input>;
  form_field_id?: InputMaybe<Scalars["uuid"]>;
  form_id?: InputMaybe<Scalars["uuid"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  updated_at?: InputMaybe<Scalars["timestamptz"]>;
  value?: InputMaybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Contact_Details_Max_Fields = {
  __typename?: "contact_details_max_fields";
  contact_id?: Maybe<Scalars["uuid"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  form_field_id?: Maybe<Scalars["uuid"]>;
  form_id?: Maybe<Scalars["uuid"]>;
  id?: Maybe<Scalars["uuid"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
  value?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "contact_details" */
export type Contact_Details_Max_Order_By = {
  contact_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  form_field_id?: InputMaybe<Order_By>;
  form_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Contact_Details_Min_Fields = {
  __typename?: "contact_details_min_fields";
  contact_id?: Maybe<Scalars["uuid"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  form_field_id?: Maybe<Scalars["uuid"]>;
  form_id?: Maybe<Scalars["uuid"]>;
  id?: Maybe<Scalars["uuid"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
  value?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "contact_details" */
export type Contact_Details_Min_Order_By = {
  contact_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  form_field_id?: InputMaybe<Order_By>;
  form_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "contact_details" */
export type Contact_Details_Mutation_Response = {
  __typename?: "contact_details_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Contact_Details>;
};

/** on_conflict condition type for table "contact_details" */
export type Contact_Details_On_Conflict = {
  constraint: Contact_Details_Constraint;
  update_columns?: Array<Contact_Details_Update_Column>;
  where?: InputMaybe<Contact_Details_Bool_Exp>;
};

/** Ordering options when selecting data from "contact_details". */
export type Contact_Details_Order_By = {
  contact?: InputMaybe<Contacts_Order_By>;
  contact_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  form?: InputMaybe<Forms_Order_By>;
  form_field?: InputMaybe<Form_Fields_Order_By>;
  form_field_id?: InputMaybe<Order_By>;
  form_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: contact_details */
export type Contact_Details_Pk_Columns_Input = {
  id: Scalars["uuid"];
};

/** select columns of table "contact_details" */
export enum Contact_Details_Select_Column {
  /** column name */
  ContactId = "contact_id",
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  FormFieldId = "form_field_id",
  /** column name */
  FormId = "form_id",
  /** column name */
  Id = "id",
  /** column name */
  UpdatedAt = "updated_at",
  /** column name */
  Value = "value",
}

/** input type for updating data in table "contact_details" */
export type Contact_Details_Set_Input = {
  contact_id?: InputMaybe<Scalars["uuid"]>;
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  form_field_id?: InputMaybe<Scalars["uuid"]>;
  form_id?: InputMaybe<Scalars["uuid"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  updated_at?: InputMaybe<Scalars["timestamptz"]>;
  value?: InputMaybe<Scalars["String"]>;
};

/** Streaming cursor of the table "contact_details" */
export type Contact_Details_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Contact_Details_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Contact_Details_Stream_Cursor_Value_Input = {
  contact_id?: InputMaybe<Scalars["uuid"]>;
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  form_field_id?: InputMaybe<Scalars["uuid"]>;
  form_id?: InputMaybe<Scalars["uuid"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  updated_at?: InputMaybe<Scalars["timestamptz"]>;
  value?: InputMaybe<Scalars["String"]>;
};

/** update columns of table "contact_details" */
export enum Contact_Details_Update_Column {
  /** column name */
  ContactId = "contact_id",
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  FormFieldId = "form_field_id",
  /** column name */
  FormId = "form_id",
  /** column name */
  Id = "id",
  /** column name */
  UpdatedAt = "updated_at",
  /** column name */
  Value = "value",
}

export type Contact_Details_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Contact_Details_Set_Input>;
  /** filter the rows which have to be updated */
  where: Contact_Details_Bool_Exp;
};

/** columns and relationships of "contact_interactions" */
export type Contact_Interactions = {
  __typename?: "contact_interactions";
  /** An object relationship */
  contact: Contacts;
  contact_id: Scalars["uuid"];
  created_at?: Maybe<Scalars["timestamptz"]>;
  id: Scalars["uuid"];
  /** An object relationship */
  interaction: Interactions;
  interaction_id: Scalars["uuid"];
};

/** aggregated selection of "contact_interactions" */
export type Contact_Interactions_Aggregate = {
  __typename?: "contact_interactions_aggregate";
  aggregate?: Maybe<Contact_Interactions_Aggregate_Fields>;
  nodes: Array<Contact_Interactions>;
};

export type Contact_Interactions_Aggregate_Bool_Exp = {
  count?: InputMaybe<Contact_Interactions_Aggregate_Bool_Exp_Count>;
};

export type Contact_Interactions_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Contact_Interactions_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<Contact_Interactions_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "contact_interactions" */
export type Contact_Interactions_Aggregate_Fields = {
  __typename?: "contact_interactions_aggregate_fields";
  count: Scalars["Int"];
  max?: Maybe<Contact_Interactions_Max_Fields>;
  min?: Maybe<Contact_Interactions_Min_Fields>;
};

/** aggregate fields of "contact_interactions" */
export type Contact_Interactions_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Contact_Interactions_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "contact_interactions" */
export type Contact_Interactions_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Contact_Interactions_Max_Order_By>;
  min?: InputMaybe<Contact_Interactions_Min_Order_By>;
};

/** input type for inserting array relation for remote table "contact_interactions" */
export type Contact_Interactions_Arr_Rel_Insert_Input = {
  data: Array<Contact_Interactions_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Contact_Interactions_On_Conflict>;
};

/** Boolean expression to filter rows from the table "contact_interactions". All fields are combined with a logical 'AND'. */
export type Contact_Interactions_Bool_Exp = {
  _and?: InputMaybe<Array<Contact_Interactions_Bool_Exp>>;
  _not?: InputMaybe<Contact_Interactions_Bool_Exp>;
  _or?: InputMaybe<Array<Contact_Interactions_Bool_Exp>>;
  contact?: InputMaybe<Contacts_Bool_Exp>;
  contact_id?: InputMaybe<Uuid_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  interaction?: InputMaybe<Interactions_Bool_Exp>;
  interaction_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "contact_interactions" */
export enum Contact_Interactions_Constraint {
  /** unique or primary key constraint on columns "id" */
  ContactInteractionsPkey = "contact_interactions_pkey",
}

/** input type for inserting data into table "contact_interactions" */
export type Contact_Interactions_Insert_Input = {
  contact?: InputMaybe<Contacts_Obj_Rel_Insert_Input>;
  contact_id?: InputMaybe<Scalars["uuid"]>;
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  interaction?: InputMaybe<Interactions_Obj_Rel_Insert_Input>;
  interaction_id?: InputMaybe<Scalars["uuid"]>;
};

/** aggregate max on columns */
export type Contact_Interactions_Max_Fields = {
  __typename?: "contact_interactions_max_fields";
  contact_id?: Maybe<Scalars["uuid"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["uuid"]>;
  interaction_id?: Maybe<Scalars["uuid"]>;
};

/** order by max() on columns of table "contact_interactions" */
export type Contact_Interactions_Max_Order_By = {
  contact_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  interaction_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Contact_Interactions_Min_Fields = {
  __typename?: "contact_interactions_min_fields";
  contact_id?: Maybe<Scalars["uuid"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["uuid"]>;
  interaction_id?: Maybe<Scalars["uuid"]>;
};

/** order by min() on columns of table "contact_interactions" */
export type Contact_Interactions_Min_Order_By = {
  contact_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  interaction_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "contact_interactions" */
export type Contact_Interactions_Mutation_Response = {
  __typename?: "contact_interactions_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Contact_Interactions>;
};

/** on_conflict condition type for table "contact_interactions" */
export type Contact_Interactions_On_Conflict = {
  constraint: Contact_Interactions_Constraint;
  update_columns?: Array<Contact_Interactions_Update_Column>;
  where?: InputMaybe<Contact_Interactions_Bool_Exp>;
};

/** Ordering options when selecting data from "contact_interactions". */
export type Contact_Interactions_Order_By = {
  contact?: InputMaybe<Contacts_Order_By>;
  contact_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  interaction?: InputMaybe<Interactions_Order_By>;
  interaction_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: contact_interactions */
export type Contact_Interactions_Pk_Columns_Input = {
  id: Scalars["uuid"];
};

/** select columns of table "contact_interactions" */
export enum Contact_Interactions_Select_Column {
  /** column name */
  ContactId = "contact_id",
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  Id = "id",
  /** column name */
  InteractionId = "interaction_id",
}

/** input type for updating data in table "contact_interactions" */
export type Contact_Interactions_Set_Input = {
  contact_id?: InputMaybe<Scalars["uuid"]>;
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  interaction_id?: InputMaybe<Scalars["uuid"]>;
};

/** Streaming cursor of the table "contact_interactions" */
export type Contact_Interactions_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Contact_Interactions_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Contact_Interactions_Stream_Cursor_Value_Input = {
  contact_id?: InputMaybe<Scalars["uuid"]>;
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  interaction_id?: InputMaybe<Scalars["uuid"]>;
};

/** update columns of table "contact_interactions" */
export enum Contact_Interactions_Update_Column {
  /** column name */
  ContactId = "contact_id",
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  Id = "id",
  /** column name */
  InteractionId = "interaction_id",
}

export type Contact_Interactions_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Contact_Interactions_Set_Input>;
  /** filter the rows which have to be updated */
  where: Contact_Interactions_Bool_Exp;
};

/** columns and relationships of "contact_relationships" */
export type Contact_Relationships = {
  __typename?: "contact_relationships";
  /** An object relationship */
  contact: Contacts;
  /** An object relationship */
  contactByParticipant: Contacts;
  created_at: Scalars["timestamptz"];
  id: Scalars["uuid"];
  name: Scalars["String"];
  owner: Scalars["uuid"];
  participant: Scalars["uuid"];
  /** An object relationship */
  type: Types;
  type_id: Scalars["uuid"];
  updated_at: Scalars["timestamptz"];
};

/** aggregated selection of "contact_relationships" */
export type Contact_Relationships_Aggregate = {
  __typename?: "contact_relationships_aggregate";
  aggregate?: Maybe<Contact_Relationships_Aggregate_Fields>;
  nodes: Array<Contact_Relationships>;
};

export type Contact_Relationships_Aggregate_Bool_Exp = {
  count?: InputMaybe<Contact_Relationships_Aggregate_Bool_Exp_Count>;
};

export type Contact_Relationships_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Contact_Relationships_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<Contact_Relationships_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "contact_relationships" */
export type Contact_Relationships_Aggregate_Fields = {
  __typename?: "contact_relationships_aggregate_fields";
  count: Scalars["Int"];
  max?: Maybe<Contact_Relationships_Max_Fields>;
  min?: Maybe<Contact_Relationships_Min_Fields>;
};

/** aggregate fields of "contact_relationships" */
export type Contact_Relationships_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Contact_Relationships_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "contact_relationships" */
export type Contact_Relationships_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Contact_Relationships_Max_Order_By>;
  min?: InputMaybe<Contact_Relationships_Min_Order_By>;
};

/** input type for inserting array relation for remote table "contact_relationships" */
export type Contact_Relationships_Arr_Rel_Insert_Input = {
  data: Array<Contact_Relationships_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Contact_Relationships_On_Conflict>;
};

/** Boolean expression to filter rows from the table "contact_relationships". All fields are combined with a logical 'AND'. */
export type Contact_Relationships_Bool_Exp = {
  _and?: InputMaybe<Array<Contact_Relationships_Bool_Exp>>;
  _not?: InputMaybe<Contact_Relationships_Bool_Exp>;
  _or?: InputMaybe<Array<Contact_Relationships_Bool_Exp>>;
  contact?: InputMaybe<Contacts_Bool_Exp>;
  contactByParticipant?: InputMaybe<Contacts_Bool_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  owner?: InputMaybe<Uuid_Comparison_Exp>;
  participant?: InputMaybe<Uuid_Comparison_Exp>;
  type?: InputMaybe<Types_Bool_Exp>;
  type_id?: InputMaybe<Uuid_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "contact_relationships" */
export enum Contact_Relationships_Constraint {
  /** unique or primary key constraint on columns "id" */
  ContactRelationshipsPkey = "contact_relationships_pkey",
}

/** input type for inserting data into table "contact_relationships" */
export type Contact_Relationships_Insert_Input = {
  contact?: InputMaybe<Contacts_Obj_Rel_Insert_Input>;
  contactByParticipant?: InputMaybe<Contacts_Obj_Rel_Insert_Input>;
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  name?: InputMaybe<Scalars["String"]>;
  owner?: InputMaybe<Scalars["uuid"]>;
  participant?: InputMaybe<Scalars["uuid"]>;
  type?: InputMaybe<Types_Obj_Rel_Insert_Input>;
  type_id?: InputMaybe<Scalars["uuid"]>;
  updated_at?: InputMaybe<Scalars["timestamptz"]>;
};

/** aggregate max on columns */
export type Contact_Relationships_Max_Fields = {
  __typename?: "contact_relationships_max_fields";
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["uuid"]>;
  name?: Maybe<Scalars["String"]>;
  owner?: Maybe<Scalars["uuid"]>;
  participant?: Maybe<Scalars["uuid"]>;
  type_id?: Maybe<Scalars["uuid"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
};

/** order by max() on columns of table "contact_relationships" */
export type Contact_Relationships_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  owner?: InputMaybe<Order_By>;
  participant?: InputMaybe<Order_By>;
  type_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Contact_Relationships_Min_Fields = {
  __typename?: "contact_relationships_min_fields";
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["uuid"]>;
  name?: Maybe<Scalars["String"]>;
  owner?: Maybe<Scalars["uuid"]>;
  participant?: Maybe<Scalars["uuid"]>;
  type_id?: Maybe<Scalars["uuid"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
};

/** order by min() on columns of table "contact_relationships" */
export type Contact_Relationships_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  owner?: InputMaybe<Order_By>;
  participant?: InputMaybe<Order_By>;
  type_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "contact_relationships" */
export type Contact_Relationships_Mutation_Response = {
  __typename?: "contact_relationships_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Contact_Relationships>;
};

/** on_conflict condition type for table "contact_relationships" */
export type Contact_Relationships_On_Conflict = {
  constraint: Contact_Relationships_Constraint;
  update_columns?: Array<Contact_Relationships_Update_Column>;
  where?: InputMaybe<Contact_Relationships_Bool_Exp>;
};

/** Ordering options when selecting data from "contact_relationships". */
export type Contact_Relationships_Order_By = {
  contact?: InputMaybe<Contacts_Order_By>;
  contactByParticipant?: InputMaybe<Contacts_Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  owner?: InputMaybe<Order_By>;
  participant?: InputMaybe<Order_By>;
  type?: InputMaybe<Types_Order_By>;
  type_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: contact_relationships */
export type Contact_Relationships_Pk_Columns_Input = {
  id: Scalars["uuid"];
};

/** select columns of table "contact_relationships" */
export enum Contact_Relationships_Select_Column {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  Id = "id",
  /** column name */
  Name = "name",
  /** column name */
  Owner = "owner",
  /** column name */
  Participant = "participant",
  /** column name */
  TypeId = "type_id",
  /** column name */
  UpdatedAt = "updated_at",
}

/** input type for updating data in table "contact_relationships" */
export type Contact_Relationships_Set_Input = {
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  name?: InputMaybe<Scalars["String"]>;
  owner?: InputMaybe<Scalars["uuid"]>;
  participant?: InputMaybe<Scalars["uuid"]>;
  type_id?: InputMaybe<Scalars["uuid"]>;
  updated_at?: InputMaybe<Scalars["timestamptz"]>;
};

/** Streaming cursor of the table "contact_relationships" */
export type Contact_Relationships_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Contact_Relationships_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Contact_Relationships_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  name?: InputMaybe<Scalars["String"]>;
  owner?: InputMaybe<Scalars["uuid"]>;
  participant?: InputMaybe<Scalars["uuid"]>;
  type_id?: InputMaybe<Scalars["uuid"]>;
  updated_at?: InputMaybe<Scalars["timestamptz"]>;
};

/** update columns of table "contact_relationships" */
export enum Contact_Relationships_Update_Column {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  Id = "id",
  /** column name */
  Name = "name",
  /** column name */
  Owner = "owner",
  /** column name */
  Participant = "participant",
  /** column name */
  TypeId = "type_id",
  /** column name */
  UpdatedAt = "updated_at",
}

export type Contact_Relationships_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Contact_Relationships_Set_Input>;
  /** filter the rows which have to be updated */
  where: Contact_Relationships_Bool_Exp;
};

/** columns and relationships of "contacts" */
export type Contacts = {
  __typename?: "contacts";
  /** An array relationship */
  campaign_contacts: Array<Campaign_Contacts>;
  /** An aggregate relationship */
  campaign_contacts_aggregate: Campaign_Contacts_Aggregate;
  /** An array relationship */
  contactRelationshipsByParticipant: Array<Contact_Relationships>;
  /** An aggregate relationship */
  contactRelationshipsByParticipant_aggregate: Contact_Relationships_Aggregate;
  /** An array relationship */
  contact_details: Array<Contact_Details>;
  /** An aggregate relationship */
  contact_details_aggregate: Contact_Details_Aggregate;
  /** An array relationship */
  contact_interactions: Array<Contact_Interactions>;
  /** An aggregate relationship */
  contact_interactions_aggregate: Contact_Interactions_Aggregate;
  /** An array relationship */
  contact_relationships: Array<Contact_Relationships>;
  /** An aggregate relationship */
  contact_relationships_aggregate: Contact_Relationships_Aggregate;
  created_at: Scalars["timestamptz"];
  id: Scalars["uuid"];
  name: Scalars["String"];
  organisation_id?: Maybe<Scalars["uuid"]>;
  /** An array relationship */
  party_contacts: Array<Party_Contacts>;
  /** An aggregate relationship */
  party_contacts_aggregate: Party_Contacts_Aggregate;
  /** An object relationship */
  status: Statuses;
  status_id: Scalars["uuid"];
  /** An object relationship */
  type: Types;
  type_id: Scalars["uuid"];
  updated_at: Scalars["timestamptz"];
};

/** columns and relationships of "contacts" */
export type ContactsCampaign_ContactsArgs = {
  distinct_on?: InputMaybe<Array<Campaign_Contacts_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Campaign_Contacts_Order_By>>;
  where?: InputMaybe<Campaign_Contacts_Bool_Exp>;
};

/** columns and relationships of "contacts" */
export type ContactsCampaign_Contacts_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Campaign_Contacts_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Campaign_Contacts_Order_By>>;
  where?: InputMaybe<Campaign_Contacts_Bool_Exp>;
};

/** columns and relationships of "contacts" */
export type ContactsContactRelationshipsByParticipantArgs = {
  distinct_on?: InputMaybe<Array<Contact_Relationships_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Contact_Relationships_Order_By>>;
  where?: InputMaybe<Contact_Relationships_Bool_Exp>;
};

/** columns and relationships of "contacts" */
export type ContactsContactRelationshipsByParticipant_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Contact_Relationships_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Contact_Relationships_Order_By>>;
  where?: InputMaybe<Contact_Relationships_Bool_Exp>;
};

/** columns and relationships of "contacts" */
export type ContactsContact_DetailsArgs = {
  distinct_on?: InputMaybe<Array<Contact_Details_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Contact_Details_Order_By>>;
  where?: InputMaybe<Contact_Details_Bool_Exp>;
};

/** columns and relationships of "contacts" */
export type ContactsContact_Details_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Contact_Details_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Contact_Details_Order_By>>;
  where?: InputMaybe<Contact_Details_Bool_Exp>;
};

/** columns and relationships of "contacts" */
export type ContactsContact_InteractionsArgs = {
  distinct_on?: InputMaybe<Array<Contact_Interactions_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Contact_Interactions_Order_By>>;
  where?: InputMaybe<Contact_Interactions_Bool_Exp>;
};

/** columns and relationships of "contacts" */
export type ContactsContact_Interactions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Contact_Interactions_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Contact_Interactions_Order_By>>;
  where?: InputMaybe<Contact_Interactions_Bool_Exp>;
};

/** columns and relationships of "contacts" */
export type ContactsContact_RelationshipsArgs = {
  distinct_on?: InputMaybe<Array<Contact_Relationships_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Contact_Relationships_Order_By>>;
  where?: InputMaybe<Contact_Relationships_Bool_Exp>;
};

/** columns and relationships of "contacts" */
export type ContactsContact_Relationships_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Contact_Relationships_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Contact_Relationships_Order_By>>;
  where?: InputMaybe<Contact_Relationships_Bool_Exp>;
};

/** columns and relationships of "contacts" */
export type ContactsParty_ContactsArgs = {
  distinct_on?: InputMaybe<Array<Party_Contacts_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Party_Contacts_Order_By>>;
  where?: InputMaybe<Party_Contacts_Bool_Exp>;
};

/** columns and relationships of "contacts" */
export type ContactsParty_Contacts_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Party_Contacts_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Party_Contacts_Order_By>>;
  where?: InputMaybe<Party_Contacts_Bool_Exp>;
};

/** aggregated selection of "contacts" */
export type Contacts_Aggregate = {
  __typename?: "contacts_aggregate";
  aggregate?: Maybe<Contacts_Aggregate_Fields>;
  nodes: Array<Contacts>;
};

export type Contacts_Aggregate_Bool_Exp = {
  count?: InputMaybe<Contacts_Aggregate_Bool_Exp_Count>;
};

export type Contacts_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Contacts_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<Contacts_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "contacts" */
export type Contacts_Aggregate_Fields = {
  __typename?: "contacts_aggregate_fields";
  count: Scalars["Int"];
  max?: Maybe<Contacts_Max_Fields>;
  min?: Maybe<Contacts_Min_Fields>;
};

/** aggregate fields of "contacts" */
export type Contacts_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Contacts_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "contacts" */
export type Contacts_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Contacts_Max_Order_By>;
  min?: InputMaybe<Contacts_Min_Order_By>;
};

/** input type for inserting array relation for remote table "contacts" */
export type Contacts_Arr_Rel_Insert_Input = {
  data: Array<Contacts_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Contacts_On_Conflict>;
};

/** Boolean expression to filter rows from the table "contacts". All fields are combined with a logical 'AND'. */
export type Contacts_Bool_Exp = {
  _and?: InputMaybe<Array<Contacts_Bool_Exp>>;
  _not?: InputMaybe<Contacts_Bool_Exp>;
  _or?: InputMaybe<Array<Contacts_Bool_Exp>>;
  campaign_contacts?: InputMaybe<Campaign_Contacts_Bool_Exp>;
  campaign_contacts_aggregate?: InputMaybe<Campaign_Contacts_Aggregate_Bool_Exp>;
  contactRelationshipsByParticipant?: InputMaybe<Contact_Relationships_Bool_Exp>;
  contactRelationshipsByParticipant_aggregate?: InputMaybe<Contact_Relationships_Aggregate_Bool_Exp>;
  contact_details?: InputMaybe<Contact_Details_Bool_Exp>;
  contact_details_aggregate?: InputMaybe<Contact_Details_Aggregate_Bool_Exp>;
  contact_interactions?: InputMaybe<Contact_Interactions_Bool_Exp>;
  contact_interactions_aggregate?: InputMaybe<Contact_Interactions_Aggregate_Bool_Exp>;
  contact_relationships?: InputMaybe<Contact_Relationships_Bool_Exp>;
  contact_relationships_aggregate?: InputMaybe<Contact_Relationships_Aggregate_Bool_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  organisation_id?: InputMaybe<Uuid_Comparison_Exp>;
  party_contacts?: InputMaybe<Party_Contacts_Bool_Exp>;
  party_contacts_aggregate?: InputMaybe<Party_Contacts_Aggregate_Bool_Exp>;
  status?: InputMaybe<Statuses_Bool_Exp>;
  status_id?: InputMaybe<Uuid_Comparison_Exp>;
  type?: InputMaybe<Types_Bool_Exp>;
  type_id?: InputMaybe<Uuid_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "contacts" */
export enum Contacts_Constraint {
  /** unique or primary key constraint on columns "id" */
  ContactsPkey = "contacts_pkey",
}

/** input type for inserting data into table "contacts" */
export type Contacts_Insert_Input = {
  campaign_contacts?: InputMaybe<Campaign_Contacts_Arr_Rel_Insert_Input>;
  contactRelationshipsByParticipant?: InputMaybe<Contact_Relationships_Arr_Rel_Insert_Input>;
  contact_details?: InputMaybe<Contact_Details_Arr_Rel_Insert_Input>;
  contact_interactions?: InputMaybe<Contact_Interactions_Arr_Rel_Insert_Input>;
  contact_relationships?: InputMaybe<Contact_Relationships_Arr_Rel_Insert_Input>;
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  name?: InputMaybe<Scalars["String"]>;
  organisation_id?: InputMaybe<Scalars["uuid"]>;
  party_contacts?: InputMaybe<Party_Contacts_Arr_Rel_Insert_Input>;
  status?: InputMaybe<Statuses_Obj_Rel_Insert_Input>;
  status_id?: InputMaybe<Scalars["uuid"]>;
  type?: InputMaybe<Types_Obj_Rel_Insert_Input>;
  type_id?: InputMaybe<Scalars["uuid"]>;
  updated_at?: InputMaybe<Scalars["timestamptz"]>;
};

/** aggregate max on columns */
export type Contacts_Max_Fields = {
  __typename?: "contacts_max_fields";
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["uuid"]>;
  name?: Maybe<Scalars["String"]>;
  organisation_id?: Maybe<Scalars["uuid"]>;
  status_id?: Maybe<Scalars["uuid"]>;
  type_id?: Maybe<Scalars["uuid"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
};

/** order by max() on columns of table "contacts" */
export type Contacts_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  organisation_id?: InputMaybe<Order_By>;
  status_id?: InputMaybe<Order_By>;
  type_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Contacts_Min_Fields = {
  __typename?: "contacts_min_fields";
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["uuid"]>;
  name?: Maybe<Scalars["String"]>;
  organisation_id?: Maybe<Scalars["uuid"]>;
  status_id?: Maybe<Scalars["uuid"]>;
  type_id?: Maybe<Scalars["uuid"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
};

/** order by min() on columns of table "contacts" */
export type Contacts_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  organisation_id?: InputMaybe<Order_By>;
  status_id?: InputMaybe<Order_By>;
  type_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "contacts" */
export type Contacts_Mutation_Response = {
  __typename?: "contacts_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Contacts>;
};

/** input type for inserting object relation for remote table "contacts" */
export type Contacts_Obj_Rel_Insert_Input = {
  data: Contacts_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Contacts_On_Conflict>;
};

/** on_conflict condition type for table "contacts" */
export type Contacts_On_Conflict = {
  constraint: Contacts_Constraint;
  update_columns?: Array<Contacts_Update_Column>;
  where?: InputMaybe<Contacts_Bool_Exp>;
};

/** Ordering options when selecting data from "contacts". */
export type Contacts_Order_By = {
  campaign_contacts_aggregate?: InputMaybe<Campaign_Contacts_Aggregate_Order_By>;
  contactRelationshipsByParticipant_aggregate?: InputMaybe<Contact_Relationships_Aggregate_Order_By>;
  contact_details_aggregate?: InputMaybe<Contact_Details_Aggregate_Order_By>;
  contact_interactions_aggregate?: InputMaybe<Contact_Interactions_Aggregate_Order_By>;
  contact_relationships_aggregate?: InputMaybe<Contact_Relationships_Aggregate_Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  organisation_id?: InputMaybe<Order_By>;
  party_contacts_aggregate?: InputMaybe<Party_Contacts_Aggregate_Order_By>;
  status?: InputMaybe<Statuses_Order_By>;
  status_id?: InputMaybe<Order_By>;
  type?: InputMaybe<Types_Order_By>;
  type_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: contacts */
export type Contacts_Pk_Columns_Input = {
  id: Scalars["uuid"];
};

/** select columns of table "contacts" */
export enum Contacts_Select_Column {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  Id = "id",
  /** column name */
  Name = "name",
  /** column name */
  OrganisationId = "organisation_id",
  /** column name */
  StatusId = "status_id",
  /** column name */
  TypeId = "type_id",
  /** column name */
  UpdatedAt = "updated_at",
}

/** input type for updating data in table "contacts" */
export type Contacts_Set_Input = {
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  name?: InputMaybe<Scalars["String"]>;
  organisation_id?: InputMaybe<Scalars["uuid"]>;
  status_id?: InputMaybe<Scalars["uuid"]>;
  type_id?: InputMaybe<Scalars["uuid"]>;
  updated_at?: InputMaybe<Scalars["timestamptz"]>;
};

/** Streaming cursor of the table "contacts" */
export type Contacts_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Contacts_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Contacts_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  name?: InputMaybe<Scalars["String"]>;
  organisation_id?: InputMaybe<Scalars["uuid"]>;
  status_id?: InputMaybe<Scalars["uuid"]>;
  type_id?: InputMaybe<Scalars["uuid"]>;
  updated_at?: InputMaybe<Scalars["timestamptz"]>;
};

/** update columns of table "contacts" */
export enum Contacts_Update_Column {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  Id = "id",
  /** column name */
  Name = "name",
  /** column name */
  OrganisationId = "organisation_id",
  /** column name */
  StatusId = "status_id",
  /** column name */
  TypeId = "type_id",
  /** column name */
  UpdatedAt = "updated_at",
}

export type Contacts_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Contacts_Set_Input>;
  /** filter the rows which have to be updated */
  where: Contacts_Bool_Exp;
};

/** columns and relationships of "credit_profile_flags" */
export type Credit_Profile_Flags = {
  __typename?: "credit_profile_flags";
  created_at?: Maybe<Scalars["timestamptz"]>;
  /** An object relationship */
  credit_profile: Credit_Profiles;
  credit_profile_id: Scalars["uuid"];
  /** An object relationship */
  flag: Flags;
  flag_id: Scalars["uuid"];
  id: Scalars["uuid"];
};

/** aggregated selection of "credit_profile_flags" */
export type Credit_Profile_Flags_Aggregate = {
  __typename?: "credit_profile_flags_aggregate";
  aggregate?: Maybe<Credit_Profile_Flags_Aggregate_Fields>;
  nodes: Array<Credit_Profile_Flags>;
};

export type Credit_Profile_Flags_Aggregate_Bool_Exp = {
  count?: InputMaybe<Credit_Profile_Flags_Aggregate_Bool_Exp_Count>;
};

export type Credit_Profile_Flags_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Credit_Profile_Flags_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<Credit_Profile_Flags_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "credit_profile_flags" */
export type Credit_Profile_Flags_Aggregate_Fields = {
  __typename?: "credit_profile_flags_aggregate_fields";
  count: Scalars["Int"];
  max?: Maybe<Credit_Profile_Flags_Max_Fields>;
  min?: Maybe<Credit_Profile_Flags_Min_Fields>;
};

/** aggregate fields of "credit_profile_flags" */
export type Credit_Profile_Flags_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Credit_Profile_Flags_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "credit_profile_flags" */
export type Credit_Profile_Flags_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Credit_Profile_Flags_Max_Order_By>;
  min?: InputMaybe<Credit_Profile_Flags_Min_Order_By>;
};

/** input type for inserting array relation for remote table "credit_profile_flags" */
export type Credit_Profile_Flags_Arr_Rel_Insert_Input = {
  data: Array<Credit_Profile_Flags_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Credit_Profile_Flags_On_Conflict>;
};

/** Boolean expression to filter rows from the table "credit_profile_flags". All fields are combined with a logical 'AND'. */
export type Credit_Profile_Flags_Bool_Exp = {
  _and?: InputMaybe<Array<Credit_Profile_Flags_Bool_Exp>>;
  _not?: InputMaybe<Credit_Profile_Flags_Bool_Exp>;
  _or?: InputMaybe<Array<Credit_Profile_Flags_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  credit_profile?: InputMaybe<Credit_Profiles_Bool_Exp>;
  credit_profile_id?: InputMaybe<Uuid_Comparison_Exp>;
  flag?: InputMaybe<Flags_Bool_Exp>;
  flag_id?: InputMaybe<Uuid_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "credit_profile_flags" */
export enum Credit_Profile_Flags_Constraint {
  /** unique or primary key constraint on columns "id" */
  CreditProfileFlagsPkey = "credit_profile_flags_pkey",
}

/** input type for inserting data into table "credit_profile_flags" */
export type Credit_Profile_Flags_Insert_Input = {
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  credit_profile?: InputMaybe<Credit_Profiles_Obj_Rel_Insert_Input>;
  credit_profile_id?: InputMaybe<Scalars["uuid"]>;
  flag?: InputMaybe<Flags_Obj_Rel_Insert_Input>;
  flag_id?: InputMaybe<Scalars["uuid"]>;
  id?: InputMaybe<Scalars["uuid"]>;
};

/** aggregate max on columns */
export type Credit_Profile_Flags_Max_Fields = {
  __typename?: "credit_profile_flags_max_fields";
  created_at?: Maybe<Scalars["timestamptz"]>;
  credit_profile_id?: Maybe<Scalars["uuid"]>;
  flag_id?: Maybe<Scalars["uuid"]>;
  id?: Maybe<Scalars["uuid"]>;
};

/** order by max() on columns of table "credit_profile_flags" */
export type Credit_Profile_Flags_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  credit_profile_id?: InputMaybe<Order_By>;
  flag_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Credit_Profile_Flags_Min_Fields = {
  __typename?: "credit_profile_flags_min_fields";
  created_at?: Maybe<Scalars["timestamptz"]>;
  credit_profile_id?: Maybe<Scalars["uuid"]>;
  flag_id?: Maybe<Scalars["uuid"]>;
  id?: Maybe<Scalars["uuid"]>;
};

/** order by min() on columns of table "credit_profile_flags" */
export type Credit_Profile_Flags_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  credit_profile_id?: InputMaybe<Order_By>;
  flag_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "credit_profile_flags" */
export type Credit_Profile_Flags_Mutation_Response = {
  __typename?: "credit_profile_flags_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Credit_Profile_Flags>;
};

/** on_conflict condition type for table "credit_profile_flags" */
export type Credit_Profile_Flags_On_Conflict = {
  constraint: Credit_Profile_Flags_Constraint;
  update_columns?: Array<Credit_Profile_Flags_Update_Column>;
  where?: InputMaybe<Credit_Profile_Flags_Bool_Exp>;
};

/** Ordering options when selecting data from "credit_profile_flags". */
export type Credit_Profile_Flags_Order_By = {
  created_at?: InputMaybe<Order_By>;
  credit_profile?: InputMaybe<Credit_Profiles_Order_By>;
  credit_profile_id?: InputMaybe<Order_By>;
  flag?: InputMaybe<Flags_Order_By>;
  flag_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: credit_profile_flags */
export type Credit_Profile_Flags_Pk_Columns_Input = {
  id: Scalars["uuid"];
};

/** select columns of table "credit_profile_flags" */
export enum Credit_Profile_Flags_Select_Column {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  CreditProfileId = "credit_profile_id",
  /** column name */
  FlagId = "flag_id",
  /** column name */
  Id = "id",
}

/** input type for updating data in table "credit_profile_flags" */
export type Credit_Profile_Flags_Set_Input = {
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  credit_profile_id?: InputMaybe<Scalars["uuid"]>;
  flag_id?: InputMaybe<Scalars["uuid"]>;
  id?: InputMaybe<Scalars["uuid"]>;
};

/** Streaming cursor of the table "credit_profile_flags" */
export type Credit_Profile_Flags_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Credit_Profile_Flags_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Credit_Profile_Flags_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  credit_profile_id?: InputMaybe<Scalars["uuid"]>;
  flag_id?: InputMaybe<Scalars["uuid"]>;
  id?: InputMaybe<Scalars["uuid"]>;
};

/** update columns of table "credit_profile_flags" */
export enum Credit_Profile_Flags_Update_Column {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  CreditProfileId = "credit_profile_id",
  /** column name */
  FlagId = "flag_id",
  /** column name */
  Id = "id",
}

export type Credit_Profile_Flags_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Credit_Profile_Flags_Set_Input>;
  /** filter the rows which have to be updated */
  where: Credit_Profile_Flags_Bool_Exp;
};

/** columns and relationships of "credit_profile_statuses" */
export type Credit_Profile_Statuses = {
  __typename?: "credit_profile_statuses";
  created_at?: Maybe<Scalars["timestamptz"]>;
  credit_profile_id: Scalars["uuid"];
  id: Scalars["uuid"];
  /** An object relationship */
  status: Statuses;
  status_id: Scalars["uuid"];
};

/** aggregated selection of "credit_profile_statuses" */
export type Credit_Profile_Statuses_Aggregate = {
  __typename?: "credit_profile_statuses_aggregate";
  aggregate?: Maybe<Credit_Profile_Statuses_Aggregate_Fields>;
  nodes: Array<Credit_Profile_Statuses>;
};

export type Credit_Profile_Statuses_Aggregate_Bool_Exp = {
  count?: InputMaybe<Credit_Profile_Statuses_Aggregate_Bool_Exp_Count>;
};

export type Credit_Profile_Statuses_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Credit_Profile_Statuses_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<Credit_Profile_Statuses_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "credit_profile_statuses" */
export type Credit_Profile_Statuses_Aggregate_Fields = {
  __typename?: "credit_profile_statuses_aggregate_fields";
  count: Scalars["Int"];
  max?: Maybe<Credit_Profile_Statuses_Max_Fields>;
  min?: Maybe<Credit_Profile_Statuses_Min_Fields>;
};

/** aggregate fields of "credit_profile_statuses" */
export type Credit_Profile_Statuses_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Credit_Profile_Statuses_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "credit_profile_statuses" */
export type Credit_Profile_Statuses_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Credit_Profile_Statuses_Max_Order_By>;
  min?: InputMaybe<Credit_Profile_Statuses_Min_Order_By>;
};

/** input type for inserting array relation for remote table "credit_profile_statuses" */
export type Credit_Profile_Statuses_Arr_Rel_Insert_Input = {
  data: Array<Credit_Profile_Statuses_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Credit_Profile_Statuses_On_Conflict>;
};

/** Boolean expression to filter rows from the table "credit_profile_statuses". All fields are combined with a logical 'AND'. */
export type Credit_Profile_Statuses_Bool_Exp = {
  _and?: InputMaybe<Array<Credit_Profile_Statuses_Bool_Exp>>;
  _not?: InputMaybe<Credit_Profile_Statuses_Bool_Exp>;
  _or?: InputMaybe<Array<Credit_Profile_Statuses_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  credit_profile_id?: InputMaybe<Uuid_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  status?: InputMaybe<Statuses_Bool_Exp>;
  status_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "credit_profile_statuses" */
export enum Credit_Profile_Statuses_Constraint {
  /** unique or primary key constraint on columns "id" */
  CreditProfileStatusesPkey = "credit_profile_statuses_pkey",
}

/** input type for inserting data into table "credit_profile_statuses" */
export type Credit_Profile_Statuses_Insert_Input = {
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  credit_profile_id?: InputMaybe<Scalars["uuid"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  status?: InputMaybe<Statuses_Obj_Rel_Insert_Input>;
  status_id?: InputMaybe<Scalars["uuid"]>;
};

/** aggregate max on columns */
export type Credit_Profile_Statuses_Max_Fields = {
  __typename?: "credit_profile_statuses_max_fields";
  created_at?: Maybe<Scalars["timestamptz"]>;
  credit_profile_id?: Maybe<Scalars["uuid"]>;
  id?: Maybe<Scalars["uuid"]>;
  status_id?: Maybe<Scalars["uuid"]>;
};

/** order by max() on columns of table "credit_profile_statuses" */
export type Credit_Profile_Statuses_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  credit_profile_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  status_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Credit_Profile_Statuses_Min_Fields = {
  __typename?: "credit_profile_statuses_min_fields";
  created_at?: Maybe<Scalars["timestamptz"]>;
  credit_profile_id?: Maybe<Scalars["uuid"]>;
  id?: Maybe<Scalars["uuid"]>;
  status_id?: Maybe<Scalars["uuid"]>;
};

/** order by min() on columns of table "credit_profile_statuses" */
export type Credit_Profile_Statuses_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  credit_profile_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  status_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "credit_profile_statuses" */
export type Credit_Profile_Statuses_Mutation_Response = {
  __typename?: "credit_profile_statuses_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Credit_Profile_Statuses>;
};

/** on_conflict condition type for table "credit_profile_statuses" */
export type Credit_Profile_Statuses_On_Conflict = {
  constraint: Credit_Profile_Statuses_Constraint;
  update_columns?: Array<Credit_Profile_Statuses_Update_Column>;
  where?: InputMaybe<Credit_Profile_Statuses_Bool_Exp>;
};

/** Ordering options when selecting data from "credit_profile_statuses". */
export type Credit_Profile_Statuses_Order_By = {
  created_at?: InputMaybe<Order_By>;
  credit_profile_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  status?: InputMaybe<Statuses_Order_By>;
  status_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: credit_profile_statuses */
export type Credit_Profile_Statuses_Pk_Columns_Input = {
  id: Scalars["uuid"];
};

/** select columns of table "credit_profile_statuses" */
export enum Credit_Profile_Statuses_Select_Column {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  CreditProfileId = "credit_profile_id",
  /** column name */
  Id = "id",
  /** column name */
  StatusId = "status_id",
}

/** input type for updating data in table "credit_profile_statuses" */
export type Credit_Profile_Statuses_Set_Input = {
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  credit_profile_id?: InputMaybe<Scalars["uuid"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  status_id?: InputMaybe<Scalars["uuid"]>;
};

/** Streaming cursor of the table "credit_profile_statuses" */
export type Credit_Profile_Statuses_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Credit_Profile_Statuses_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Credit_Profile_Statuses_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  credit_profile_id?: InputMaybe<Scalars["uuid"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  status_id?: InputMaybe<Scalars["uuid"]>;
};

/** update columns of table "credit_profile_statuses" */
export enum Credit_Profile_Statuses_Update_Column {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  CreditProfileId = "credit_profile_id",
  /** column name */
  Id = "id",
  /** column name */
  StatusId = "status_id",
}

export type Credit_Profile_Statuses_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Credit_Profile_Statuses_Set_Input>;
  /** filter the rows which have to be updated */
  where: Credit_Profile_Statuses_Bool_Exp;
};

/** columns and relationships of "credit_profiles" */
export type Credit_Profiles = {
  __typename?: "credit_profiles";
  created_at: Scalars["timestamptz"];
  /** An array relationship */
  credit_profile_flags: Array<Credit_Profile_Flags>;
  /** An aggregate relationship */
  credit_profile_flags_aggregate: Credit_Profile_Flags_Aggregate;
  /** An array relationship */
  credit_profile_statuses: Array<Credit_Profile_Statuses>;
  /** An aggregate relationship */
  credit_profile_statuses_aggregate: Credit_Profile_Statuses_Aggregate;
  id: Scalars["uuid"];
  limit: Scalars["bigint"];
  organisation_id: Scalars["uuid"];
  /** An object relationship */
  party: Parties;
  party_id: Scalars["uuid"];
  risk_rating: Scalars["String"];
  score: Scalars["Int"];
  updated_at: Scalars["timestamptz"];
};

/** columns and relationships of "credit_profiles" */
export type Credit_ProfilesCredit_Profile_FlagsArgs = {
  distinct_on?: InputMaybe<Array<Credit_Profile_Flags_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Credit_Profile_Flags_Order_By>>;
  where?: InputMaybe<Credit_Profile_Flags_Bool_Exp>;
};

/** columns and relationships of "credit_profiles" */
export type Credit_ProfilesCredit_Profile_Flags_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Credit_Profile_Flags_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Credit_Profile_Flags_Order_By>>;
  where?: InputMaybe<Credit_Profile_Flags_Bool_Exp>;
};

/** columns and relationships of "credit_profiles" */
export type Credit_ProfilesCredit_Profile_StatusesArgs = {
  distinct_on?: InputMaybe<Array<Credit_Profile_Statuses_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Credit_Profile_Statuses_Order_By>>;
  where?: InputMaybe<Credit_Profile_Statuses_Bool_Exp>;
};

/** columns and relationships of "credit_profiles" */
export type Credit_ProfilesCredit_Profile_Statuses_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Credit_Profile_Statuses_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Credit_Profile_Statuses_Order_By>>;
  where?: InputMaybe<Credit_Profile_Statuses_Bool_Exp>;
};

/** aggregated selection of "credit_profiles" */
export type Credit_Profiles_Aggregate = {
  __typename?: "credit_profiles_aggregate";
  aggregate?: Maybe<Credit_Profiles_Aggregate_Fields>;
  nodes: Array<Credit_Profiles>;
};

export type Credit_Profiles_Aggregate_Bool_Exp = {
  count?: InputMaybe<Credit_Profiles_Aggregate_Bool_Exp_Count>;
};

export type Credit_Profiles_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Credit_Profiles_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<Credit_Profiles_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "credit_profiles" */
export type Credit_Profiles_Aggregate_Fields = {
  __typename?: "credit_profiles_aggregate_fields";
  avg?: Maybe<Credit_Profiles_Avg_Fields>;
  count: Scalars["Int"];
  max?: Maybe<Credit_Profiles_Max_Fields>;
  min?: Maybe<Credit_Profiles_Min_Fields>;
  stddev?: Maybe<Credit_Profiles_Stddev_Fields>;
  stddev_pop?: Maybe<Credit_Profiles_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Credit_Profiles_Stddev_Samp_Fields>;
  sum?: Maybe<Credit_Profiles_Sum_Fields>;
  var_pop?: Maybe<Credit_Profiles_Var_Pop_Fields>;
  var_samp?: Maybe<Credit_Profiles_Var_Samp_Fields>;
  variance?: Maybe<Credit_Profiles_Variance_Fields>;
};

/** aggregate fields of "credit_profiles" */
export type Credit_Profiles_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Credit_Profiles_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "credit_profiles" */
export type Credit_Profiles_Aggregate_Order_By = {
  avg?: InputMaybe<Credit_Profiles_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Credit_Profiles_Max_Order_By>;
  min?: InputMaybe<Credit_Profiles_Min_Order_By>;
  stddev?: InputMaybe<Credit_Profiles_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Credit_Profiles_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Credit_Profiles_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Credit_Profiles_Sum_Order_By>;
  var_pop?: InputMaybe<Credit_Profiles_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Credit_Profiles_Var_Samp_Order_By>;
  variance?: InputMaybe<Credit_Profiles_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "credit_profiles" */
export type Credit_Profiles_Arr_Rel_Insert_Input = {
  data: Array<Credit_Profiles_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Credit_Profiles_On_Conflict>;
};

/** aggregate avg on columns */
export type Credit_Profiles_Avg_Fields = {
  __typename?: "credit_profiles_avg_fields";
  limit?: Maybe<Scalars["Float"]>;
  score?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "credit_profiles" */
export type Credit_Profiles_Avg_Order_By = {
  limit?: InputMaybe<Order_By>;
  score?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "credit_profiles". All fields are combined with a logical 'AND'. */
export type Credit_Profiles_Bool_Exp = {
  _and?: InputMaybe<Array<Credit_Profiles_Bool_Exp>>;
  _not?: InputMaybe<Credit_Profiles_Bool_Exp>;
  _or?: InputMaybe<Array<Credit_Profiles_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  credit_profile_flags?: InputMaybe<Credit_Profile_Flags_Bool_Exp>;
  credit_profile_flags_aggregate?: InputMaybe<Credit_Profile_Flags_Aggregate_Bool_Exp>;
  credit_profile_statuses?: InputMaybe<Credit_Profile_Statuses_Bool_Exp>;
  credit_profile_statuses_aggregate?: InputMaybe<Credit_Profile_Statuses_Aggregate_Bool_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  limit?: InputMaybe<Bigint_Comparison_Exp>;
  organisation_id?: InputMaybe<Uuid_Comparison_Exp>;
  party?: InputMaybe<Parties_Bool_Exp>;
  party_id?: InputMaybe<Uuid_Comparison_Exp>;
  risk_rating?: InputMaybe<String_Comparison_Exp>;
  score?: InputMaybe<Int_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "credit_profiles" */
export enum Credit_Profiles_Constraint {
  /** unique or primary key constraint on columns "id" */
  CreditProfilesPkey = "credit_profiles_pkey",
}

/** input type for incrementing numeric columns in table "credit_profiles" */
export type Credit_Profiles_Inc_Input = {
  limit?: InputMaybe<Scalars["bigint"]>;
  score?: InputMaybe<Scalars["Int"]>;
};

/** input type for inserting data into table "credit_profiles" */
export type Credit_Profiles_Insert_Input = {
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  credit_profile_flags?: InputMaybe<Credit_Profile_Flags_Arr_Rel_Insert_Input>;
  credit_profile_statuses?: InputMaybe<Credit_Profile_Statuses_Arr_Rel_Insert_Input>;
  id?: InputMaybe<Scalars["uuid"]>;
  limit?: InputMaybe<Scalars["bigint"]>;
  organisation_id?: InputMaybe<Scalars["uuid"]>;
  party?: InputMaybe<Parties_Obj_Rel_Insert_Input>;
  party_id?: InputMaybe<Scalars["uuid"]>;
  risk_rating?: InputMaybe<Scalars["String"]>;
  score?: InputMaybe<Scalars["Int"]>;
  updated_at?: InputMaybe<Scalars["timestamptz"]>;
};

/** aggregate max on columns */
export type Credit_Profiles_Max_Fields = {
  __typename?: "credit_profiles_max_fields";
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["uuid"]>;
  limit?: Maybe<Scalars["bigint"]>;
  organisation_id?: Maybe<Scalars["uuid"]>;
  party_id?: Maybe<Scalars["uuid"]>;
  risk_rating?: Maybe<Scalars["String"]>;
  score?: Maybe<Scalars["Int"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
};

/** order by max() on columns of table "credit_profiles" */
export type Credit_Profiles_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  limit?: InputMaybe<Order_By>;
  organisation_id?: InputMaybe<Order_By>;
  party_id?: InputMaybe<Order_By>;
  risk_rating?: InputMaybe<Order_By>;
  score?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Credit_Profiles_Min_Fields = {
  __typename?: "credit_profiles_min_fields";
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["uuid"]>;
  limit?: Maybe<Scalars["bigint"]>;
  organisation_id?: Maybe<Scalars["uuid"]>;
  party_id?: Maybe<Scalars["uuid"]>;
  risk_rating?: Maybe<Scalars["String"]>;
  score?: Maybe<Scalars["Int"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
};

/** order by min() on columns of table "credit_profiles" */
export type Credit_Profiles_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  limit?: InputMaybe<Order_By>;
  organisation_id?: InputMaybe<Order_By>;
  party_id?: InputMaybe<Order_By>;
  risk_rating?: InputMaybe<Order_By>;
  score?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "credit_profiles" */
export type Credit_Profiles_Mutation_Response = {
  __typename?: "credit_profiles_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Credit_Profiles>;
};

/** input type for inserting object relation for remote table "credit_profiles" */
export type Credit_Profiles_Obj_Rel_Insert_Input = {
  data: Credit_Profiles_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Credit_Profiles_On_Conflict>;
};

/** on_conflict condition type for table "credit_profiles" */
export type Credit_Profiles_On_Conflict = {
  constraint: Credit_Profiles_Constraint;
  update_columns?: Array<Credit_Profiles_Update_Column>;
  where?: InputMaybe<Credit_Profiles_Bool_Exp>;
};

/** Ordering options when selecting data from "credit_profiles". */
export type Credit_Profiles_Order_By = {
  created_at?: InputMaybe<Order_By>;
  credit_profile_flags_aggregate?: InputMaybe<Credit_Profile_Flags_Aggregate_Order_By>;
  credit_profile_statuses_aggregate?: InputMaybe<Credit_Profile_Statuses_Aggregate_Order_By>;
  id?: InputMaybe<Order_By>;
  limit?: InputMaybe<Order_By>;
  organisation_id?: InputMaybe<Order_By>;
  party?: InputMaybe<Parties_Order_By>;
  party_id?: InputMaybe<Order_By>;
  risk_rating?: InputMaybe<Order_By>;
  score?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: credit_profiles */
export type Credit_Profiles_Pk_Columns_Input = {
  id: Scalars["uuid"];
};

/** select columns of table "credit_profiles" */
export enum Credit_Profiles_Select_Column {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  Id = "id",
  /** column name */
  Limit = "limit",
  /** column name */
  OrganisationId = "organisation_id",
  /** column name */
  PartyId = "party_id",
  /** column name */
  RiskRating = "risk_rating",
  /** column name */
  Score = "score",
  /** column name */
  UpdatedAt = "updated_at",
}

/** input type for updating data in table "credit_profiles" */
export type Credit_Profiles_Set_Input = {
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  limit?: InputMaybe<Scalars["bigint"]>;
  organisation_id?: InputMaybe<Scalars["uuid"]>;
  party_id?: InputMaybe<Scalars["uuid"]>;
  risk_rating?: InputMaybe<Scalars["String"]>;
  score?: InputMaybe<Scalars["Int"]>;
  updated_at?: InputMaybe<Scalars["timestamptz"]>;
};

/** aggregate stddev on columns */
export type Credit_Profiles_Stddev_Fields = {
  __typename?: "credit_profiles_stddev_fields";
  limit?: Maybe<Scalars["Float"]>;
  score?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "credit_profiles" */
export type Credit_Profiles_Stddev_Order_By = {
  limit?: InputMaybe<Order_By>;
  score?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Credit_Profiles_Stddev_Pop_Fields = {
  __typename?: "credit_profiles_stddev_pop_fields";
  limit?: Maybe<Scalars["Float"]>;
  score?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "credit_profiles" */
export type Credit_Profiles_Stddev_Pop_Order_By = {
  limit?: InputMaybe<Order_By>;
  score?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Credit_Profiles_Stddev_Samp_Fields = {
  __typename?: "credit_profiles_stddev_samp_fields";
  limit?: Maybe<Scalars["Float"]>;
  score?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "credit_profiles" */
export type Credit_Profiles_Stddev_Samp_Order_By = {
  limit?: InputMaybe<Order_By>;
  score?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "credit_profiles" */
export type Credit_Profiles_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Credit_Profiles_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Credit_Profiles_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  limit?: InputMaybe<Scalars["bigint"]>;
  organisation_id?: InputMaybe<Scalars["uuid"]>;
  party_id?: InputMaybe<Scalars["uuid"]>;
  risk_rating?: InputMaybe<Scalars["String"]>;
  score?: InputMaybe<Scalars["Int"]>;
  updated_at?: InputMaybe<Scalars["timestamptz"]>;
};

/** aggregate sum on columns */
export type Credit_Profiles_Sum_Fields = {
  __typename?: "credit_profiles_sum_fields";
  limit?: Maybe<Scalars["bigint"]>;
  score?: Maybe<Scalars["Int"]>;
};

/** order by sum() on columns of table "credit_profiles" */
export type Credit_Profiles_Sum_Order_By = {
  limit?: InputMaybe<Order_By>;
  score?: InputMaybe<Order_By>;
};

/** update columns of table "credit_profiles" */
export enum Credit_Profiles_Update_Column {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  Id = "id",
  /** column name */
  Limit = "limit",
  /** column name */
  OrganisationId = "organisation_id",
  /** column name */
  PartyId = "party_id",
  /** column name */
  RiskRating = "risk_rating",
  /** column name */
  Score = "score",
  /** column name */
  UpdatedAt = "updated_at",
}

export type Credit_Profiles_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Credit_Profiles_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Credit_Profiles_Set_Input>;
  /** filter the rows which have to be updated */
  where: Credit_Profiles_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Credit_Profiles_Var_Pop_Fields = {
  __typename?: "credit_profiles_var_pop_fields";
  limit?: Maybe<Scalars["Float"]>;
  score?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "credit_profiles" */
export type Credit_Profiles_Var_Pop_Order_By = {
  limit?: InputMaybe<Order_By>;
  score?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Credit_Profiles_Var_Samp_Fields = {
  __typename?: "credit_profiles_var_samp_fields";
  limit?: Maybe<Scalars["Float"]>;
  score?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "credit_profiles" */
export type Credit_Profiles_Var_Samp_Order_By = {
  limit?: InputMaybe<Order_By>;
  score?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Credit_Profiles_Variance_Fields = {
  __typename?: "credit_profiles_variance_fields";
  limit?: Maybe<Scalars["Float"]>;
  score?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "credit_profiles" */
export type Credit_Profiles_Variance_Order_By = {
  limit?: InputMaybe<Order_By>;
  score?: InputMaybe<Order_By>;
};

/** ordering argument of a cursor */
export enum Cursor_Ordering {
  /** ascending ordering of the cursor */
  Asc = "ASC",
  /** descending ordering of the cursor */
  Desc = "DESC",
}

export type Customer_Daily_Growth_Args = {
  start_date?: InputMaybe<Scalars["date"]>;
  stop_date?: InputMaybe<Scalars["date"]>;
};

/** Boolean expression to compare columns of type "date". All fields are combined with logical 'AND'. */
export type Date_Comparison_Exp = {
  _eq?: InputMaybe<Scalars["date"]>;
  _gt?: InputMaybe<Scalars["date"]>;
  _gte?: InputMaybe<Scalars["date"]>;
  _in?: InputMaybe<Array<Scalars["date"]>>;
  _is_null?: InputMaybe<Scalars["Boolean"]>;
  _lt?: InputMaybe<Scalars["date"]>;
  _lte?: InputMaybe<Scalars["date"]>;
  _neq?: InputMaybe<Scalars["date"]>;
  _nin?: InputMaybe<Array<Scalars["date"]>>;
};

/** columns and relationships of "delivery_methods" */
export type Delivery_Methods = {
  __typename?: "delivery_methods";
  /** An array relationship */
  channel_subscriptions: Array<Channel_Subscriptions>;
  /** An aggregate relationship */
  channel_subscriptions_aggregate: Channel_Subscriptions_Aggregate;
  created_at: Scalars["timestamptz"];
  description?: Maybe<Scalars["String"]>;
  id: Scalars["uuid"];
  /** An array relationship */
  message_delivery_methods: Array<Message_Delivery_Methods>;
  /** An aggregate relationship */
  message_delivery_methods_aggregate: Message_Delivery_Methods_Aggregate;
  /** An array relationship */
  message_delivery_responses: Array<Message_Delivery_Responses>;
  /** An aggregate relationship */
  message_delivery_responses_aggregate: Message_Delivery_Responses_Aggregate;
  /** An array relationship */
  message_statuses: Array<Message_Statuses>;
  /** An aggregate relationship */
  message_statuses_aggregate: Message_Statuses_Aggregate;
  metdata?: Maybe<Scalars["jsonb"]>;
  name: Scalars["String"];
  updated_at: Scalars["timestamptz"];
  value?: Maybe<Scalars["String"]>;
};

/** columns and relationships of "delivery_methods" */
export type Delivery_MethodsChannel_SubscriptionsArgs = {
  distinct_on?: InputMaybe<Array<Channel_Subscriptions_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Channel_Subscriptions_Order_By>>;
  where?: InputMaybe<Channel_Subscriptions_Bool_Exp>;
};

/** columns and relationships of "delivery_methods" */
export type Delivery_MethodsChannel_Subscriptions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Channel_Subscriptions_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Channel_Subscriptions_Order_By>>;
  where?: InputMaybe<Channel_Subscriptions_Bool_Exp>;
};

/** columns and relationships of "delivery_methods" */
export type Delivery_MethodsMessage_Delivery_MethodsArgs = {
  distinct_on?: InputMaybe<Array<Message_Delivery_Methods_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Message_Delivery_Methods_Order_By>>;
  where?: InputMaybe<Message_Delivery_Methods_Bool_Exp>;
};

/** columns and relationships of "delivery_methods" */
export type Delivery_MethodsMessage_Delivery_Methods_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Message_Delivery_Methods_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Message_Delivery_Methods_Order_By>>;
  where?: InputMaybe<Message_Delivery_Methods_Bool_Exp>;
};

/** columns and relationships of "delivery_methods" */
export type Delivery_MethodsMessage_Delivery_ResponsesArgs = {
  distinct_on?: InputMaybe<Array<Message_Delivery_Responses_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Message_Delivery_Responses_Order_By>>;
  where?: InputMaybe<Message_Delivery_Responses_Bool_Exp>;
};

/** columns and relationships of "delivery_methods" */
export type Delivery_MethodsMessage_Delivery_Responses_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Message_Delivery_Responses_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Message_Delivery_Responses_Order_By>>;
  where?: InputMaybe<Message_Delivery_Responses_Bool_Exp>;
};

/** columns and relationships of "delivery_methods" */
export type Delivery_MethodsMessage_StatusesArgs = {
  distinct_on?: InputMaybe<Array<Message_Statuses_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Message_Statuses_Order_By>>;
  where?: InputMaybe<Message_Statuses_Bool_Exp>;
};

/** columns and relationships of "delivery_methods" */
export type Delivery_MethodsMessage_Statuses_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Message_Statuses_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Message_Statuses_Order_By>>;
  where?: InputMaybe<Message_Statuses_Bool_Exp>;
};

/** columns and relationships of "delivery_methods" */
export type Delivery_MethodsMetdataArgs = {
  path?: InputMaybe<Scalars["String"]>;
};

/** aggregated selection of "delivery_methods" */
export type Delivery_Methods_Aggregate = {
  __typename?: "delivery_methods_aggregate";
  aggregate?: Maybe<Delivery_Methods_Aggregate_Fields>;
  nodes: Array<Delivery_Methods>;
};

/** aggregate fields of "delivery_methods" */
export type Delivery_Methods_Aggregate_Fields = {
  __typename?: "delivery_methods_aggregate_fields";
  count: Scalars["Int"];
  max?: Maybe<Delivery_Methods_Max_Fields>;
  min?: Maybe<Delivery_Methods_Min_Fields>;
};

/** aggregate fields of "delivery_methods" */
export type Delivery_Methods_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Delivery_Methods_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Delivery_Methods_Append_Input = {
  metdata?: InputMaybe<Scalars["jsonb"]>;
};

/** Boolean expression to filter rows from the table "delivery_methods". All fields are combined with a logical 'AND'. */
export type Delivery_Methods_Bool_Exp = {
  _and?: InputMaybe<Array<Delivery_Methods_Bool_Exp>>;
  _not?: InputMaybe<Delivery_Methods_Bool_Exp>;
  _or?: InputMaybe<Array<Delivery_Methods_Bool_Exp>>;
  channel_subscriptions?: InputMaybe<Channel_Subscriptions_Bool_Exp>;
  channel_subscriptions_aggregate?: InputMaybe<Channel_Subscriptions_Aggregate_Bool_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  message_delivery_methods?: InputMaybe<Message_Delivery_Methods_Bool_Exp>;
  message_delivery_methods_aggregate?: InputMaybe<Message_Delivery_Methods_Aggregate_Bool_Exp>;
  message_delivery_responses?: InputMaybe<Message_Delivery_Responses_Bool_Exp>;
  message_delivery_responses_aggregate?: InputMaybe<Message_Delivery_Responses_Aggregate_Bool_Exp>;
  message_statuses?: InputMaybe<Message_Statuses_Bool_Exp>;
  message_statuses_aggregate?: InputMaybe<Message_Statuses_Aggregate_Bool_Exp>;
  metdata?: InputMaybe<Jsonb_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "delivery_methods" */
export enum Delivery_Methods_Constraint {
  /** unique or primary key constraint on columns "id" */
  DeliveryMethodsPkey = "delivery_methods_pkey",
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Delivery_Methods_Delete_At_Path_Input = {
  metdata?: InputMaybe<Array<Scalars["String"]>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Delivery_Methods_Delete_Elem_Input = {
  metdata?: InputMaybe<Scalars["Int"]>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Delivery_Methods_Delete_Key_Input = {
  metdata?: InputMaybe<Scalars["String"]>;
};

/** input type for inserting data into table "delivery_methods" */
export type Delivery_Methods_Insert_Input = {
  channel_subscriptions?: InputMaybe<Channel_Subscriptions_Arr_Rel_Insert_Input>;
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  description?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  message_delivery_methods?: InputMaybe<Message_Delivery_Methods_Arr_Rel_Insert_Input>;
  message_delivery_responses?: InputMaybe<Message_Delivery_Responses_Arr_Rel_Insert_Input>;
  message_statuses?: InputMaybe<Message_Statuses_Arr_Rel_Insert_Input>;
  metdata?: InputMaybe<Scalars["jsonb"]>;
  name?: InputMaybe<Scalars["String"]>;
  updated_at?: InputMaybe<Scalars["timestamptz"]>;
  value?: InputMaybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Delivery_Methods_Max_Fields = {
  __typename?: "delivery_methods_max_fields";
  created_at?: Maybe<Scalars["timestamptz"]>;
  description?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["uuid"]>;
  name?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
  value?: Maybe<Scalars["String"]>;
};

/** aggregate min on columns */
export type Delivery_Methods_Min_Fields = {
  __typename?: "delivery_methods_min_fields";
  created_at?: Maybe<Scalars["timestamptz"]>;
  description?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["uuid"]>;
  name?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
  value?: Maybe<Scalars["String"]>;
};

/** response of any mutation on the table "delivery_methods" */
export type Delivery_Methods_Mutation_Response = {
  __typename?: "delivery_methods_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Delivery_Methods>;
};

/** input type for inserting object relation for remote table "delivery_methods" */
export type Delivery_Methods_Obj_Rel_Insert_Input = {
  data: Delivery_Methods_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Delivery_Methods_On_Conflict>;
};

/** on_conflict condition type for table "delivery_methods" */
export type Delivery_Methods_On_Conflict = {
  constraint: Delivery_Methods_Constraint;
  update_columns?: Array<Delivery_Methods_Update_Column>;
  where?: InputMaybe<Delivery_Methods_Bool_Exp>;
};

/** Ordering options when selecting data from "delivery_methods". */
export type Delivery_Methods_Order_By = {
  channel_subscriptions_aggregate?: InputMaybe<Channel_Subscriptions_Aggregate_Order_By>;
  created_at?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  message_delivery_methods_aggregate?: InputMaybe<Message_Delivery_Methods_Aggregate_Order_By>;
  message_delivery_responses_aggregate?: InputMaybe<Message_Delivery_Responses_Aggregate_Order_By>;
  message_statuses_aggregate?: InputMaybe<Message_Statuses_Aggregate_Order_By>;
  metdata?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: delivery_methods */
export type Delivery_Methods_Pk_Columns_Input = {
  id: Scalars["uuid"];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Delivery_Methods_Prepend_Input = {
  metdata?: InputMaybe<Scalars["jsonb"]>;
};

/** select columns of table "delivery_methods" */
export enum Delivery_Methods_Select_Column {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  Description = "description",
  /** column name */
  Id = "id",
  /** column name */
  Metdata = "metdata",
  /** column name */
  Name = "name",
  /** column name */
  UpdatedAt = "updated_at",
  /** column name */
  Value = "value",
}

/** input type for updating data in table "delivery_methods" */
export type Delivery_Methods_Set_Input = {
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  description?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  metdata?: InputMaybe<Scalars["jsonb"]>;
  name?: InputMaybe<Scalars["String"]>;
  updated_at?: InputMaybe<Scalars["timestamptz"]>;
  value?: InputMaybe<Scalars["String"]>;
};

/** Streaming cursor of the table "delivery_methods" */
export type Delivery_Methods_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Delivery_Methods_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Delivery_Methods_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  description?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  metdata?: InputMaybe<Scalars["jsonb"]>;
  name?: InputMaybe<Scalars["String"]>;
  updated_at?: InputMaybe<Scalars["timestamptz"]>;
  value?: InputMaybe<Scalars["String"]>;
};

/** update columns of table "delivery_methods" */
export enum Delivery_Methods_Update_Column {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  Description = "description",
  /** column name */
  Id = "id",
  /** column name */
  Metdata = "metdata",
  /** column name */
  Name = "name",
  /** column name */
  UpdatedAt = "updated_at",
  /** column name */
  Value = "value",
}

export type Delivery_Methods_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Delivery_Methods_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Delivery_Methods_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Delivery_Methods_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Delivery_Methods_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Delivery_Methods_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Delivery_Methods_Set_Input>;
  /** filter the rows which have to be updated */
  where: Delivery_Methods_Bool_Exp;
};

/** columns and relationships of "document_interactions" */
export type Document_Interactions = {
  __typename?: "document_interactions";
  created_at?: Maybe<Scalars["timestamptz"]>;
  /** An object relationship */
  document: Documents;
  document_id: Scalars["uuid"];
  id: Scalars["uuid"];
  /** An object relationship */
  interaction: Interactions;
  interaction_id: Scalars["uuid"];
};

/** aggregated selection of "document_interactions" */
export type Document_Interactions_Aggregate = {
  __typename?: "document_interactions_aggregate";
  aggregate?: Maybe<Document_Interactions_Aggregate_Fields>;
  nodes: Array<Document_Interactions>;
};

export type Document_Interactions_Aggregate_Bool_Exp = {
  count?: InputMaybe<Document_Interactions_Aggregate_Bool_Exp_Count>;
};

export type Document_Interactions_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Document_Interactions_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<Document_Interactions_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "document_interactions" */
export type Document_Interactions_Aggregate_Fields = {
  __typename?: "document_interactions_aggregate_fields";
  count: Scalars["Int"];
  max?: Maybe<Document_Interactions_Max_Fields>;
  min?: Maybe<Document_Interactions_Min_Fields>;
};

/** aggregate fields of "document_interactions" */
export type Document_Interactions_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Document_Interactions_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "document_interactions" */
export type Document_Interactions_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Document_Interactions_Max_Order_By>;
  min?: InputMaybe<Document_Interactions_Min_Order_By>;
};

/** input type for inserting array relation for remote table "document_interactions" */
export type Document_Interactions_Arr_Rel_Insert_Input = {
  data: Array<Document_Interactions_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Document_Interactions_On_Conflict>;
};

/** Boolean expression to filter rows from the table "document_interactions". All fields are combined with a logical 'AND'. */
export type Document_Interactions_Bool_Exp = {
  _and?: InputMaybe<Array<Document_Interactions_Bool_Exp>>;
  _not?: InputMaybe<Document_Interactions_Bool_Exp>;
  _or?: InputMaybe<Array<Document_Interactions_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  document?: InputMaybe<Documents_Bool_Exp>;
  document_id?: InputMaybe<Uuid_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  interaction?: InputMaybe<Interactions_Bool_Exp>;
  interaction_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "document_interactions" */
export enum Document_Interactions_Constraint {
  /** unique or primary key constraint on columns "id" */
  DocumentInteractionsPkey = "document_interactions_pkey",
}

/** input type for inserting data into table "document_interactions" */
export type Document_Interactions_Insert_Input = {
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  document?: InputMaybe<Documents_Obj_Rel_Insert_Input>;
  document_id?: InputMaybe<Scalars["uuid"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  interaction?: InputMaybe<Interactions_Obj_Rel_Insert_Input>;
  interaction_id?: InputMaybe<Scalars["uuid"]>;
};

/** aggregate max on columns */
export type Document_Interactions_Max_Fields = {
  __typename?: "document_interactions_max_fields";
  created_at?: Maybe<Scalars["timestamptz"]>;
  document_id?: Maybe<Scalars["uuid"]>;
  id?: Maybe<Scalars["uuid"]>;
  interaction_id?: Maybe<Scalars["uuid"]>;
};

/** order by max() on columns of table "document_interactions" */
export type Document_Interactions_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  document_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  interaction_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Document_Interactions_Min_Fields = {
  __typename?: "document_interactions_min_fields";
  created_at?: Maybe<Scalars["timestamptz"]>;
  document_id?: Maybe<Scalars["uuid"]>;
  id?: Maybe<Scalars["uuid"]>;
  interaction_id?: Maybe<Scalars["uuid"]>;
};

/** order by min() on columns of table "document_interactions" */
export type Document_Interactions_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  document_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  interaction_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "document_interactions" */
export type Document_Interactions_Mutation_Response = {
  __typename?: "document_interactions_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Document_Interactions>;
};

/** on_conflict condition type for table "document_interactions" */
export type Document_Interactions_On_Conflict = {
  constraint: Document_Interactions_Constraint;
  update_columns?: Array<Document_Interactions_Update_Column>;
  where?: InputMaybe<Document_Interactions_Bool_Exp>;
};

/** Ordering options when selecting data from "document_interactions". */
export type Document_Interactions_Order_By = {
  created_at?: InputMaybe<Order_By>;
  document?: InputMaybe<Documents_Order_By>;
  document_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  interaction?: InputMaybe<Interactions_Order_By>;
  interaction_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: document_interactions */
export type Document_Interactions_Pk_Columns_Input = {
  id: Scalars["uuid"];
};

/** select columns of table "document_interactions" */
export enum Document_Interactions_Select_Column {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  DocumentId = "document_id",
  /** column name */
  Id = "id",
  /** column name */
  InteractionId = "interaction_id",
}

/** input type for updating data in table "document_interactions" */
export type Document_Interactions_Set_Input = {
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  document_id?: InputMaybe<Scalars["uuid"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  interaction_id?: InputMaybe<Scalars["uuid"]>;
};

/** Streaming cursor of the table "document_interactions" */
export type Document_Interactions_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Document_Interactions_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Document_Interactions_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  document_id?: InputMaybe<Scalars["uuid"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  interaction_id?: InputMaybe<Scalars["uuid"]>;
};

/** update columns of table "document_interactions" */
export enum Document_Interactions_Update_Column {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  DocumentId = "document_id",
  /** column name */
  Id = "id",
  /** column name */
  InteractionId = "interaction_id",
}

export type Document_Interactions_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Document_Interactions_Set_Input>;
  /** filter the rows which have to be updated */
  where: Document_Interactions_Bool_Exp;
};

/** columns and relationships of "documents" */
export type Documents = {
  __typename?: "documents";
  /** An array relationship */
  case_documents: Array<Case_Documents>;
  /** An aggregate relationship */
  case_documents_aggregate: Case_Documents_Aggregate;
  created_at: Scalars["timestamptz"];
  created_by?: Maybe<Scalars["uuid"]>;
  /** An array relationship */
  document_interactions: Array<Document_Interactions>;
  /** An aggregate relationship */
  document_interactions_aggregate: Document_Interactions_Aggregate;
  id: Scalars["uuid"];
  name?: Maybe<Scalars["String"]>;
  organisation_id?: Maybe<Scalars["uuid"]>;
  /** An object relationship */
  party?: Maybe<Parties>;
  /** An array relationship */
  party_documents: Array<Party_Documents>;
  /** An aggregate relationship */
  party_documents_aggregate: Party_Documents_Aggregate;
  path: Scalars["String"];
  size: Scalars["bigint"];
  /** An array relationship */
  task_documents: Array<Task_Documents>;
  /** An aggregate relationship */
  task_documents_aggregate: Task_Documents_Aggregate;
  /** An object relationship */
  type: Types;
  type_id: Scalars["uuid"];
  updated_at: Scalars["timestamptz"];
};

/** columns and relationships of "documents" */
export type DocumentsCase_DocumentsArgs = {
  distinct_on?: InputMaybe<Array<Case_Documents_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Case_Documents_Order_By>>;
  where?: InputMaybe<Case_Documents_Bool_Exp>;
};

/** columns and relationships of "documents" */
export type DocumentsCase_Documents_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Case_Documents_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Case_Documents_Order_By>>;
  where?: InputMaybe<Case_Documents_Bool_Exp>;
};

/** columns and relationships of "documents" */
export type DocumentsDocument_InteractionsArgs = {
  distinct_on?: InputMaybe<Array<Document_Interactions_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Document_Interactions_Order_By>>;
  where?: InputMaybe<Document_Interactions_Bool_Exp>;
};

/** columns and relationships of "documents" */
export type DocumentsDocument_Interactions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Document_Interactions_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Document_Interactions_Order_By>>;
  where?: InputMaybe<Document_Interactions_Bool_Exp>;
};

/** columns and relationships of "documents" */
export type DocumentsParty_DocumentsArgs = {
  distinct_on?: InputMaybe<Array<Party_Documents_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Party_Documents_Order_By>>;
  where?: InputMaybe<Party_Documents_Bool_Exp>;
};

/** columns and relationships of "documents" */
export type DocumentsParty_Documents_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Party_Documents_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Party_Documents_Order_By>>;
  where?: InputMaybe<Party_Documents_Bool_Exp>;
};

/** columns and relationships of "documents" */
export type DocumentsTask_DocumentsArgs = {
  distinct_on?: InputMaybe<Array<Task_Documents_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Task_Documents_Order_By>>;
  where?: InputMaybe<Task_Documents_Bool_Exp>;
};

/** columns and relationships of "documents" */
export type DocumentsTask_Documents_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Task_Documents_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Task_Documents_Order_By>>;
  where?: InputMaybe<Task_Documents_Bool_Exp>;
};

/** aggregated selection of "documents" */
export type Documents_Aggregate = {
  __typename?: "documents_aggregate";
  aggregate?: Maybe<Documents_Aggregate_Fields>;
  nodes: Array<Documents>;
};

export type Documents_Aggregate_Bool_Exp = {
  count?: InputMaybe<Documents_Aggregate_Bool_Exp_Count>;
};

export type Documents_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Documents_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<Documents_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "documents" */
export type Documents_Aggregate_Fields = {
  __typename?: "documents_aggregate_fields";
  avg?: Maybe<Documents_Avg_Fields>;
  count: Scalars["Int"];
  max?: Maybe<Documents_Max_Fields>;
  min?: Maybe<Documents_Min_Fields>;
  stddev?: Maybe<Documents_Stddev_Fields>;
  stddev_pop?: Maybe<Documents_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Documents_Stddev_Samp_Fields>;
  sum?: Maybe<Documents_Sum_Fields>;
  var_pop?: Maybe<Documents_Var_Pop_Fields>;
  var_samp?: Maybe<Documents_Var_Samp_Fields>;
  variance?: Maybe<Documents_Variance_Fields>;
};

/** aggregate fields of "documents" */
export type Documents_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Documents_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "documents" */
export type Documents_Aggregate_Order_By = {
  avg?: InputMaybe<Documents_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Documents_Max_Order_By>;
  min?: InputMaybe<Documents_Min_Order_By>;
  stddev?: InputMaybe<Documents_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Documents_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Documents_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Documents_Sum_Order_By>;
  var_pop?: InputMaybe<Documents_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Documents_Var_Samp_Order_By>;
  variance?: InputMaybe<Documents_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "documents" */
export type Documents_Arr_Rel_Insert_Input = {
  data: Array<Documents_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Documents_On_Conflict>;
};

/** aggregate avg on columns */
export type Documents_Avg_Fields = {
  __typename?: "documents_avg_fields";
  size?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "documents" */
export type Documents_Avg_Order_By = {
  size?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "documents". All fields are combined with a logical 'AND'. */
export type Documents_Bool_Exp = {
  _and?: InputMaybe<Array<Documents_Bool_Exp>>;
  _not?: InputMaybe<Documents_Bool_Exp>;
  _or?: InputMaybe<Array<Documents_Bool_Exp>>;
  case_documents?: InputMaybe<Case_Documents_Bool_Exp>;
  case_documents_aggregate?: InputMaybe<Case_Documents_Aggregate_Bool_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  created_by?: InputMaybe<Uuid_Comparison_Exp>;
  document_interactions?: InputMaybe<Document_Interactions_Bool_Exp>;
  document_interactions_aggregate?: InputMaybe<Document_Interactions_Aggregate_Bool_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  organisation_id?: InputMaybe<Uuid_Comparison_Exp>;
  party?: InputMaybe<Parties_Bool_Exp>;
  party_documents?: InputMaybe<Party_Documents_Bool_Exp>;
  party_documents_aggregate?: InputMaybe<Party_Documents_Aggregate_Bool_Exp>;
  path?: InputMaybe<String_Comparison_Exp>;
  size?: InputMaybe<Bigint_Comparison_Exp>;
  task_documents?: InputMaybe<Task_Documents_Bool_Exp>;
  task_documents_aggregate?: InputMaybe<Task_Documents_Aggregate_Bool_Exp>;
  type?: InputMaybe<Types_Bool_Exp>;
  type_id?: InputMaybe<Uuid_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "documents" */
export enum Documents_Constraint {
  /** unique or primary key constraint on columns "id" */
  DocumentsPkey = "documents_pkey",
}

/** input type for incrementing numeric columns in table "documents" */
export type Documents_Inc_Input = {
  size?: InputMaybe<Scalars["bigint"]>;
};

/** input type for inserting data into table "documents" */
export type Documents_Insert_Input = {
  case_documents?: InputMaybe<Case_Documents_Arr_Rel_Insert_Input>;
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  created_by?: InputMaybe<Scalars["uuid"]>;
  document_interactions?: InputMaybe<Document_Interactions_Arr_Rel_Insert_Input>;
  id?: InputMaybe<Scalars["uuid"]>;
  name?: InputMaybe<Scalars["String"]>;
  organisation_id?: InputMaybe<Scalars["uuid"]>;
  party?: InputMaybe<Parties_Obj_Rel_Insert_Input>;
  party_documents?: InputMaybe<Party_Documents_Arr_Rel_Insert_Input>;
  path?: InputMaybe<Scalars["String"]>;
  size?: InputMaybe<Scalars["bigint"]>;
  task_documents?: InputMaybe<Task_Documents_Arr_Rel_Insert_Input>;
  type?: InputMaybe<Types_Obj_Rel_Insert_Input>;
  type_id?: InputMaybe<Scalars["uuid"]>;
  updated_at?: InputMaybe<Scalars["timestamptz"]>;
};

/** aggregate max on columns */
export type Documents_Max_Fields = {
  __typename?: "documents_max_fields";
  created_at?: Maybe<Scalars["timestamptz"]>;
  created_by?: Maybe<Scalars["uuid"]>;
  id?: Maybe<Scalars["uuid"]>;
  name?: Maybe<Scalars["String"]>;
  organisation_id?: Maybe<Scalars["uuid"]>;
  path?: Maybe<Scalars["String"]>;
  size?: Maybe<Scalars["bigint"]>;
  type_id?: Maybe<Scalars["uuid"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
};

/** order by max() on columns of table "documents" */
export type Documents_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  organisation_id?: InputMaybe<Order_By>;
  path?: InputMaybe<Order_By>;
  size?: InputMaybe<Order_By>;
  type_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Documents_Min_Fields = {
  __typename?: "documents_min_fields";
  created_at?: Maybe<Scalars["timestamptz"]>;
  created_by?: Maybe<Scalars["uuid"]>;
  id?: Maybe<Scalars["uuid"]>;
  name?: Maybe<Scalars["String"]>;
  organisation_id?: Maybe<Scalars["uuid"]>;
  path?: Maybe<Scalars["String"]>;
  size?: Maybe<Scalars["bigint"]>;
  type_id?: Maybe<Scalars["uuid"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
};

/** order by min() on columns of table "documents" */
export type Documents_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  organisation_id?: InputMaybe<Order_By>;
  path?: InputMaybe<Order_By>;
  size?: InputMaybe<Order_By>;
  type_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "documents" */
export type Documents_Mutation_Response = {
  __typename?: "documents_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Documents>;
};

/** input type for inserting object relation for remote table "documents" */
export type Documents_Obj_Rel_Insert_Input = {
  data: Documents_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Documents_On_Conflict>;
};

/** on_conflict condition type for table "documents" */
export type Documents_On_Conflict = {
  constraint: Documents_Constraint;
  update_columns?: Array<Documents_Update_Column>;
  where?: InputMaybe<Documents_Bool_Exp>;
};

/** Ordering options when selecting data from "documents". */
export type Documents_Order_By = {
  case_documents_aggregate?: InputMaybe<Case_Documents_Aggregate_Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  document_interactions_aggregate?: InputMaybe<Document_Interactions_Aggregate_Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  organisation_id?: InputMaybe<Order_By>;
  party?: InputMaybe<Parties_Order_By>;
  party_documents_aggregate?: InputMaybe<Party_Documents_Aggregate_Order_By>;
  path?: InputMaybe<Order_By>;
  size?: InputMaybe<Order_By>;
  task_documents_aggregate?: InputMaybe<Task_Documents_Aggregate_Order_By>;
  type?: InputMaybe<Types_Order_By>;
  type_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: documents */
export type Documents_Pk_Columns_Input = {
  id: Scalars["uuid"];
};

/** select columns of table "documents" */
export enum Documents_Select_Column {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  CreatedBy = "created_by",
  /** column name */
  Id = "id",
  /** column name */
  Name = "name",
  /** column name */
  OrganisationId = "organisation_id",
  /** column name */
  Path = "path",
  /** column name */
  Size = "size",
  /** column name */
  TypeId = "type_id",
  /** column name */
  UpdatedAt = "updated_at",
}

/** input type for updating data in table "documents" */
export type Documents_Set_Input = {
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  created_by?: InputMaybe<Scalars["uuid"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  name?: InputMaybe<Scalars["String"]>;
  organisation_id?: InputMaybe<Scalars["uuid"]>;
  path?: InputMaybe<Scalars["String"]>;
  size?: InputMaybe<Scalars["bigint"]>;
  type_id?: InputMaybe<Scalars["uuid"]>;
  updated_at?: InputMaybe<Scalars["timestamptz"]>;
};

/** aggregate stddev on columns */
export type Documents_Stddev_Fields = {
  __typename?: "documents_stddev_fields";
  size?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "documents" */
export type Documents_Stddev_Order_By = {
  size?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Documents_Stddev_Pop_Fields = {
  __typename?: "documents_stddev_pop_fields";
  size?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "documents" */
export type Documents_Stddev_Pop_Order_By = {
  size?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Documents_Stddev_Samp_Fields = {
  __typename?: "documents_stddev_samp_fields";
  size?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "documents" */
export type Documents_Stddev_Samp_Order_By = {
  size?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "documents" */
export type Documents_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Documents_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Documents_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  created_by?: InputMaybe<Scalars["uuid"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  name?: InputMaybe<Scalars["String"]>;
  organisation_id?: InputMaybe<Scalars["uuid"]>;
  path?: InputMaybe<Scalars["String"]>;
  size?: InputMaybe<Scalars["bigint"]>;
  type_id?: InputMaybe<Scalars["uuid"]>;
  updated_at?: InputMaybe<Scalars["timestamptz"]>;
};

/** aggregate sum on columns */
export type Documents_Sum_Fields = {
  __typename?: "documents_sum_fields";
  size?: Maybe<Scalars["bigint"]>;
};

/** order by sum() on columns of table "documents" */
export type Documents_Sum_Order_By = {
  size?: InputMaybe<Order_By>;
};

/** update columns of table "documents" */
export enum Documents_Update_Column {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  CreatedBy = "created_by",
  /** column name */
  Id = "id",
  /** column name */
  Name = "name",
  /** column name */
  OrganisationId = "organisation_id",
  /** column name */
  Path = "path",
  /** column name */
  Size = "size",
  /** column name */
  TypeId = "type_id",
  /** column name */
  UpdatedAt = "updated_at",
}

export type Documents_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Documents_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Documents_Set_Input>;
  /** filter the rows which have to be updated */
  where: Documents_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Documents_Var_Pop_Fields = {
  __typename?: "documents_var_pop_fields";
  size?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "documents" */
export type Documents_Var_Pop_Order_By = {
  size?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Documents_Var_Samp_Fields = {
  __typename?: "documents_var_samp_fields";
  size?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "documents" */
export type Documents_Var_Samp_Order_By = {
  size?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Documents_Variance_Fields = {
  __typename?: "documents_variance_fields";
  size?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "documents" */
export type Documents_Variance_Order_By = {
  size?: InputMaybe<Order_By>;
};

/** columns and relationships of "flags" */
export type Flags = {
  __typename?: "flags";
  /** An array relationship */
  case_flags: Array<Case_Flags>;
  /** An aggregate relationship */
  case_flags_aggregate: Case_Flags_Aggregate;
  /** An array relationship */
  channel_flags: Array<Channel_Flags>;
  /** An aggregate relationship */
  channel_flags_aggregate: Channel_Flags_Aggregate;
  created_at: Scalars["timestamptz"];
  description?: Maybe<Scalars["String"]>;
  id: Scalars["uuid"];
  /** An array relationship */
  message_flags: Array<Message_Flags>;
  /** An aggregate relationship */
  message_flags_aggregate: Message_Flags_Aggregate;
  name: Scalars["String"];
  /** An array relationship */
  party_flags: Array<Party_Flags>;
  /** An aggregate relationship */
  party_flags_aggregate: Party_Flags_Aggregate;
  /** An array relationship */
  task_flags: Array<Task_Flags>;
  /** An aggregate relationship */
  task_flags_aggregate: Task_Flags_Aggregate;
  /** An array relationship */
  territory_flags: Array<Territory_Flags>;
  /** An aggregate relationship */
  territory_flags_aggregate: Territory_Flags_Aggregate;
  updated_at: Scalars["timestamptz"];
  /** An array relationship */
  user_flags: Array<User_Flags>;
  /** An aggregate relationship */
  user_flags_aggregate: User_Flags_Aggregate;
  value: Scalars["String"];
};

/** columns and relationships of "flags" */
export type FlagsCase_FlagsArgs = {
  distinct_on?: InputMaybe<Array<Case_Flags_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Case_Flags_Order_By>>;
  where?: InputMaybe<Case_Flags_Bool_Exp>;
};

/** columns and relationships of "flags" */
export type FlagsCase_Flags_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Case_Flags_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Case_Flags_Order_By>>;
  where?: InputMaybe<Case_Flags_Bool_Exp>;
};

/** columns and relationships of "flags" */
export type FlagsChannel_FlagsArgs = {
  distinct_on?: InputMaybe<Array<Channel_Flags_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Channel_Flags_Order_By>>;
  where?: InputMaybe<Channel_Flags_Bool_Exp>;
};

/** columns and relationships of "flags" */
export type FlagsChannel_Flags_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Channel_Flags_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Channel_Flags_Order_By>>;
  where?: InputMaybe<Channel_Flags_Bool_Exp>;
};

/** columns and relationships of "flags" */
export type FlagsMessage_FlagsArgs = {
  distinct_on?: InputMaybe<Array<Message_Flags_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Message_Flags_Order_By>>;
  where?: InputMaybe<Message_Flags_Bool_Exp>;
};

/** columns and relationships of "flags" */
export type FlagsMessage_Flags_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Message_Flags_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Message_Flags_Order_By>>;
  where?: InputMaybe<Message_Flags_Bool_Exp>;
};

/** columns and relationships of "flags" */
export type FlagsParty_FlagsArgs = {
  distinct_on?: InputMaybe<Array<Party_Flags_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Party_Flags_Order_By>>;
  where?: InputMaybe<Party_Flags_Bool_Exp>;
};

/** columns and relationships of "flags" */
export type FlagsParty_Flags_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Party_Flags_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Party_Flags_Order_By>>;
  where?: InputMaybe<Party_Flags_Bool_Exp>;
};

/** columns and relationships of "flags" */
export type FlagsTask_FlagsArgs = {
  distinct_on?: InputMaybe<Array<Task_Flags_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Task_Flags_Order_By>>;
  where?: InputMaybe<Task_Flags_Bool_Exp>;
};

/** columns and relationships of "flags" */
export type FlagsTask_Flags_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Task_Flags_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Task_Flags_Order_By>>;
  where?: InputMaybe<Task_Flags_Bool_Exp>;
};

/** columns and relationships of "flags" */
export type FlagsTerritory_FlagsArgs = {
  distinct_on?: InputMaybe<Array<Territory_Flags_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Territory_Flags_Order_By>>;
  where?: InputMaybe<Territory_Flags_Bool_Exp>;
};

/** columns and relationships of "flags" */
export type FlagsTerritory_Flags_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Territory_Flags_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Territory_Flags_Order_By>>;
  where?: InputMaybe<Territory_Flags_Bool_Exp>;
};

/** columns and relationships of "flags" */
export type FlagsUser_FlagsArgs = {
  distinct_on?: InputMaybe<Array<User_Flags_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<User_Flags_Order_By>>;
  where?: InputMaybe<User_Flags_Bool_Exp>;
};

/** columns and relationships of "flags" */
export type FlagsUser_Flags_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Flags_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<User_Flags_Order_By>>;
  where?: InputMaybe<User_Flags_Bool_Exp>;
};

/** aggregated selection of "flags" */
export type Flags_Aggregate = {
  __typename?: "flags_aggregate";
  aggregate?: Maybe<Flags_Aggregate_Fields>;
  nodes: Array<Flags>;
};

/** aggregate fields of "flags" */
export type Flags_Aggregate_Fields = {
  __typename?: "flags_aggregate_fields";
  count: Scalars["Int"];
  max?: Maybe<Flags_Max_Fields>;
  min?: Maybe<Flags_Min_Fields>;
};

/** aggregate fields of "flags" */
export type Flags_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Flags_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** Boolean expression to filter rows from the table "flags". All fields are combined with a logical 'AND'. */
export type Flags_Bool_Exp = {
  _and?: InputMaybe<Array<Flags_Bool_Exp>>;
  _not?: InputMaybe<Flags_Bool_Exp>;
  _or?: InputMaybe<Array<Flags_Bool_Exp>>;
  case_flags?: InputMaybe<Case_Flags_Bool_Exp>;
  case_flags_aggregate?: InputMaybe<Case_Flags_Aggregate_Bool_Exp>;
  channel_flags?: InputMaybe<Channel_Flags_Bool_Exp>;
  channel_flags_aggregate?: InputMaybe<Channel_Flags_Aggregate_Bool_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  message_flags?: InputMaybe<Message_Flags_Bool_Exp>;
  message_flags_aggregate?: InputMaybe<Message_Flags_Aggregate_Bool_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  party_flags?: InputMaybe<Party_Flags_Bool_Exp>;
  party_flags_aggregate?: InputMaybe<Party_Flags_Aggregate_Bool_Exp>;
  task_flags?: InputMaybe<Task_Flags_Bool_Exp>;
  task_flags_aggregate?: InputMaybe<Task_Flags_Aggregate_Bool_Exp>;
  territory_flags?: InputMaybe<Territory_Flags_Bool_Exp>;
  territory_flags_aggregate?: InputMaybe<Territory_Flags_Aggregate_Bool_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user_flags?: InputMaybe<User_Flags_Bool_Exp>;
  user_flags_aggregate?: InputMaybe<User_Flags_Aggregate_Bool_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "flags" */
export enum Flags_Constraint {
  /** unique or primary key constraint on columns "id" */
  FlagsPkey = "flags_pkey",
  /** unique or primary key constraint on columns "value" */
  FlagsValueUnique = "flags_value_unique",
}

/** input type for inserting data into table "flags" */
export type Flags_Insert_Input = {
  case_flags?: InputMaybe<Case_Flags_Arr_Rel_Insert_Input>;
  channel_flags?: InputMaybe<Channel_Flags_Arr_Rel_Insert_Input>;
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  description?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  message_flags?: InputMaybe<Message_Flags_Arr_Rel_Insert_Input>;
  name?: InputMaybe<Scalars["String"]>;
  party_flags?: InputMaybe<Party_Flags_Arr_Rel_Insert_Input>;
  task_flags?: InputMaybe<Task_Flags_Arr_Rel_Insert_Input>;
  territory_flags?: InputMaybe<Territory_Flags_Arr_Rel_Insert_Input>;
  updated_at?: InputMaybe<Scalars["timestamptz"]>;
  user_flags?: InputMaybe<User_Flags_Arr_Rel_Insert_Input>;
  value?: InputMaybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Flags_Max_Fields = {
  __typename?: "flags_max_fields";
  created_at?: Maybe<Scalars["timestamptz"]>;
  description?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["uuid"]>;
  name?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
  value?: Maybe<Scalars["String"]>;
};

/** aggregate min on columns */
export type Flags_Min_Fields = {
  __typename?: "flags_min_fields";
  created_at?: Maybe<Scalars["timestamptz"]>;
  description?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["uuid"]>;
  name?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
  value?: Maybe<Scalars["String"]>;
};

/** response of any mutation on the table "flags" */
export type Flags_Mutation_Response = {
  __typename?: "flags_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Flags>;
};

/** input type for inserting object relation for remote table "flags" */
export type Flags_Obj_Rel_Insert_Input = {
  data: Flags_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Flags_On_Conflict>;
};

/** on_conflict condition type for table "flags" */
export type Flags_On_Conflict = {
  constraint: Flags_Constraint;
  update_columns?: Array<Flags_Update_Column>;
  where?: InputMaybe<Flags_Bool_Exp>;
};

/** Ordering options when selecting data from "flags". */
export type Flags_Order_By = {
  case_flags_aggregate?: InputMaybe<Case_Flags_Aggregate_Order_By>;
  channel_flags_aggregate?: InputMaybe<Channel_Flags_Aggregate_Order_By>;
  created_at?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  message_flags_aggregate?: InputMaybe<Message_Flags_Aggregate_Order_By>;
  name?: InputMaybe<Order_By>;
  party_flags_aggregate?: InputMaybe<Party_Flags_Aggregate_Order_By>;
  task_flags_aggregate?: InputMaybe<Task_Flags_Aggregate_Order_By>;
  territory_flags_aggregate?: InputMaybe<Territory_Flags_Aggregate_Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_flags_aggregate?: InputMaybe<User_Flags_Aggregate_Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: flags */
export type Flags_Pk_Columns_Input = {
  id: Scalars["uuid"];
};

/** select columns of table "flags" */
export enum Flags_Select_Column {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  Description = "description",
  /** column name */
  Id = "id",
  /** column name */
  Name = "name",
  /** column name */
  UpdatedAt = "updated_at",
  /** column name */
  Value = "value",
}

/** input type for updating data in table "flags" */
export type Flags_Set_Input = {
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  description?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  name?: InputMaybe<Scalars["String"]>;
  updated_at?: InputMaybe<Scalars["timestamptz"]>;
  value?: InputMaybe<Scalars["String"]>;
};

/** Streaming cursor of the table "flags" */
export type Flags_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Flags_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Flags_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  description?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  name?: InputMaybe<Scalars["String"]>;
  updated_at?: InputMaybe<Scalars["timestamptz"]>;
  value?: InputMaybe<Scalars["String"]>;
};

/** update columns of table "flags" */
export enum Flags_Update_Column {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  Description = "description",
  /** column name */
  Id = "id",
  /** column name */
  Name = "name",
  /** column name */
  UpdatedAt = "updated_at",
  /** column name */
  Value = "value",
}

export type Flags_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Flags_Set_Input>;
  /** filter the rows which have to be updated */
  where: Flags_Bool_Exp;
};

/** Boolean expression to compare columns of type "float8". All fields are combined with logical 'AND'. */
export type Float8_Comparison_Exp = {
  _eq?: InputMaybe<Scalars["float8"]>;
  _gt?: InputMaybe<Scalars["float8"]>;
  _gte?: InputMaybe<Scalars["float8"]>;
  _in?: InputMaybe<Array<Scalars["float8"]>>;
  _is_null?: InputMaybe<Scalars["Boolean"]>;
  _lt?: InputMaybe<Scalars["float8"]>;
  _lte?: InputMaybe<Scalars["float8"]>;
  _neq?: InputMaybe<Scalars["float8"]>;
  _nin?: InputMaybe<Array<Scalars["float8"]>>;
};

/** columns and relationships of "form_fields" */
export type Form_Fields = {
  __typename?: "form_fields";
  /** An array relationship */
  case_details: Array<Case_Details>;
  /** An aggregate relationship */
  case_details_aggregate: Case_Details_Aggregate;
  /** An array relationship */
  contact_details: Array<Contact_Details>;
  /** An aggregate relationship */
  contact_details_aggregate: Contact_Details_Aggregate;
  created_at: Scalars["timestamptz"];
  default_value?: Maybe<Scalars["String"]>;
  description?: Maybe<Scalars["String"]>;
  display_if?: Maybe<Scalars["jsonb"]>;
  field_name?: Maybe<Scalars["String"]>;
  /** An object relationship */
  form: Forms;
  form_id: Scalars["uuid"];
  id: Scalars["uuid"];
  /** An array relationship */
  interaction_data: Array<Interaction_Details>;
  /** An aggregate relationship */
  interaction_data_aggregate: Interaction_Details_Aggregate;
  is_active: Scalars["Boolean"];
  is_required: Scalars["Boolean"];
  label: Scalars["String"];
  max?: Maybe<Scalars["bigint"]>;
  min?: Maybe<Scalars["bigint"]>;
  options?: Maybe<Scalars["jsonb"]>;
  placeholder?: Maybe<Scalars["String"]>;
  position: Scalars["Int"];
  step?: Maybe<Scalars["String"]>;
  /** An object relationship */
  type: Types;
  type_id: Scalars["uuid"];
  units?: Maybe<Scalars["String"]>;
  updated_at: Scalars["timestamptz"];
  validation_regex?: Maybe<Scalars["String"]>;
};

/** columns and relationships of "form_fields" */
export type Form_FieldsCase_DetailsArgs = {
  distinct_on?: InputMaybe<Array<Case_Details_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Case_Details_Order_By>>;
  where?: InputMaybe<Case_Details_Bool_Exp>;
};

/** columns and relationships of "form_fields" */
export type Form_FieldsCase_Details_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Case_Details_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Case_Details_Order_By>>;
  where?: InputMaybe<Case_Details_Bool_Exp>;
};

/** columns and relationships of "form_fields" */
export type Form_FieldsContact_DetailsArgs = {
  distinct_on?: InputMaybe<Array<Contact_Details_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Contact_Details_Order_By>>;
  where?: InputMaybe<Contact_Details_Bool_Exp>;
};

/** columns and relationships of "form_fields" */
export type Form_FieldsContact_Details_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Contact_Details_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Contact_Details_Order_By>>;
  where?: InputMaybe<Contact_Details_Bool_Exp>;
};

/** columns and relationships of "form_fields" */
export type Form_FieldsDisplay_IfArgs = {
  path?: InputMaybe<Scalars["String"]>;
};

/** columns and relationships of "form_fields" */
export type Form_FieldsInteraction_DataArgs = {
  distinct_on?: InputMaybe<Array<Interaction_Details_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Interaction_Details_Order_By>>;
  where?: InputMaybe<Interaction_Details_Bool_Exp>;
};

/** columns and relationships of "form_fields" */
export type Form_FieldsInteraction_Data_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Interaction_Details_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Interaction_Details_Order_By>>;
  where?: InputMaybe<Interaction_Details_Bool_Exp>;
};

/** columns and relationships of "form_fields" */
export type Form_FieldsOptionsArgs = {
  path?: InputMaybe<Scalars["String"]>;
};

/** aggregated selection of "form_fields" */
export type Form_Fields_Aggregate = {
  __typename?: "form_fields_aggregate";
  aggregate?: Maybe<Form_Fields_Aggregate_Fields>;
  nodes: Array<Form_Fields>;
};

export type Form_Fields_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Form_Fields_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Form_Fields_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Form_Fields_Aggregate_Bool_Exp_Count>;
};

export type Form_Fields_Aggregate_Bool_Exp_Bool_And = {
  arguments: Form_Fields_Select_Column_Form_Fields_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<Form_Fields_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Form_Fields_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Form_Fields_Select_Column_Form_Fields_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<Form_Fields_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Form_Fields_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Form_Fields_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<Form_Fields_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "form_fields" */
export type Form_Fields_Aggregate_Fields = {
  __typename?: "form_fields_aggregate_fields";
  avg?: Maybe<Form_Fields_Avg_Fields>;
  count: Scalars["Int"];
  max?: Maybe<Form_Fields_Max_Fields>;
  min?: Maybe<Form_Fields_Min_Fields>;
  stddev?: Maybe<Form_Fields_Stddev_Fields>;
  stddev_pop?: Maybe<Form_Fields_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Form_Fields_Stddev_Samp_Fields>;
  sum?: Maybe<Form_Fields_Sum_Fields>;
  var_pop?: Maybe<Form_Fields_Var_Pop_Fields>;
  var_samp?: Maybe<Form_Fields_Var_Samp_Fields>;
  variance?: Maybe<Form_Fields_Variance_Fields>;
};

/** aggregate fields of "form_fields" */
export type Form_Fields_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Form_Fields_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "form_fields" */
export type Form_Fields_Aggregate_Order_By = {
  avg?: InputMaybe<Form_Fields_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Form_Fields_Max_Order_By>;
  min?: InputMaybe<Form_Fields_Min_Order_By>;
  stddev?: InputMaybe<Form_Fields_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Form_Fields_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Form_Fields_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Form_Fields_Sum_Order_By>;
  var_pop?: InputMaybe<Form_Fields_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Form_Fields_Var_Samp_Order_By>;
  variance?: InputMaybe<Form_Fields_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Form_Fields_Append_Input = {
  display_if?: InputMaybe<Scalars["jsonb"]>;
  options?: InputMaybe<Scalars["jsonb"]>;
};

/** input type for inserting array relation for remote table "form_fields" */
export type Form_Fields_Arr_Rel_Insert_Input = {
  data: Array<Form_Fields_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Form_Fields_On_Conflict>;
};

/** aggregate avg on columns */
export type Form_Fields_Avg_Fields = {
  __typename?: "form_fields_avg_fields";
  max?: Maybe<Scalars["Float"]>;
  min?: Maybe<Scalars["Float"]>;
  position?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "form_fields" */
export type Form_Fields_Avg_Order_By = {
  max?: InputMaybe<Order_By>;
  min?: InputMaybe<Order_By>;
  position?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "form_fields". All fields are combined with a logical 'AND'. */
export type Form_Fields_Bool_Exp = {
  _and?: InputMaybe<Array<Form_Fields_Bool_Exp>>;
  _not?: InputMaybe<Form_Fields_Bool_Exp>;
  _or?: InputMaybe<Array<Form_Fields_Bool_Exp>>;
  case_details?: InputMaybe<Case_Details_Bool_Exp>;
  case_details_aggregate?: InputMaybe<Case_Details_Aggregate_Bool_Exp>;
  contact_details?: InputMaybe<Contact_Details_Bool_Exp>;
  contact_details_aggregate?: InputMaybe<Contact_Details_Aggregate_Bool_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  default_value?: InputMaybe<String_Comparison_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  display_if?: InputMaybe<Jsonb_Comparison_Exp>;
  field_name?: InputMaybe<String_Comparison_Exp>;
  form?: InputMaybe<Forms_Bool_Exp>;
  form_id?: InputMaybe<Uuid_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  interaction_data?: InputMaybe<Interaction_Details_Bool_Exp>;
  interaction_data_aggregate?: InputMaybe<Interaction_Details_Aggregate_Bool_Exp>;
  is_active?: InputMaybe<Boolean_Comparison_Exp>;
  is_required?: InputMaybe<Boolean_Comparison_Exp>;
  label?: InputMaybe<String_Comparison_Exp>;
  max?: InputMaybe<Bigint_Comparison_Exp>;
  min?: InputMaybe<Bigint_Comparison_Exp>;
  options?: InputMaybe<Jsonb_Comparison_Exp>;
  placeholder?: InputMaybe<String_Comparison_Exp>;
  position?: InputMaybe<Int_Comparison_Exp>;
  step?: InputMaybe<String_Comparison_Exp>;
  type?: InputMaybe<Types_Bool_Exp>;
  type_id?: InputMaybe<Uuid_Comparison_Exp>;
  units?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  validation_regex?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "form_fields" */
export enum Form_Fields_Constraint {
  /** unique or primary key constraint on columns "id" */
  FormFieldsPkey = "form_fields_pkey",
  /** unique or primary key constraint on columns "field_name", "form_id" */
  UniqueFormFieldName = "unique_form_field_name",
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Form_Fields_Delete_At_Path_Input = {
  display_if?: InputMaybe<Array<Scalars["String"]>>;
  options?: InputMaybe<Array<Scalars["String"]>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Form_Fields_Delete_Elem_Input = {
  display_if?: InputMaybe<Scalars["Int"]>;
  options?: InputMaybe<Scalars["Int"]>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Form_Fields_Delete_Key_Input = {
  display_if?: InputMaybe<Scalars["String"]>;
  options?: InputMaybe<Scalars["String"]>;
};

/** input type for incrementing numeric columns in table "form_fields" */
export type Form_Fields_Inc_Input = {
  max?: InputMaybe<Scalars["bigint"]>;
  min?: InputMaybe<Scalars["bigint"]>;
  position?: InputMaybe<Scalars["Int"]>;
};

/** input type for inserting data into table "form_fields" */
export type Form_Fields_Insert_Input = {
  case_details?: InputMaybe<Case_Details_Arr_Rel_Insert_Input>;
  contact_details?: InputMaybe<Contact_Details_Arr_Rel_Insert_Input>;
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  default_value?: InputMaybe<Scalars["String"]>;
  description?: InputMaybe<Scalars["String"]>;
  display_if?: InputMaybe<Scalars["jsonb"]>;
  field_name?: InputMaybe<Scalars["String"]>;
  form?: InputMaybe<Forms_Obj_Rel_Insert_Input>;
  form_id?: InputMaybe<Scalars["uuid"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  interaction_data?: InputMaybe<Interaction_Details_Arr_Rel_Insert_Input>;
  is_active?: InputMaybe<Scalars["Boolean"]>;
  is_required?: InputMaybe<Scalars["Boolean"]>;
  label?: InputMaybe<Scalars["String"]>;
  max?: InputMaybe<Scalars["bigint"]>;
  min?: InputMaybe<Scalars["bigint"]>;
  options?: InputMaybe<Scalars["jsonb"]>;
  placeholder?: InputMaybe<Scalars["String"]>;
  position?: InputMaybe<Scalars["Int"]>;
  step?: InputMaybe<Scalars["String"]>;
  type?: InputMaybe<Types_Obj_Rel_Insert_Input>;
  type_id?: InputMaybe<Scalars["uuid"]>;
  units?: InputMaybe<Scalars["String"]>;
  updated_at?: InputMaybe<Scalars["timestamptz"]>;
  validation_regex?: InputMaybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Form_Fields_Max_Fields = {
  __typename?: "form_fields_max_fields";
  created_at?: Maybe<Scalars["timestamptz"]>;
  default_value?: Maybe<Scalars["String"]>;
  description?: Maybe<Scalars["String"]>;
  field_name?: Maybe<Scalars["String"]>;
  form_id?: Maybe<Scalars["uuid"]>;
  id?: Maybe<Scalars["uuid"]>;
  label?: Maybe<Scalars["String"]>;
  max?: Maybe<Scalars["bigint"]>;
  min?: Maybe<Scalars["bigint"]>;
  placeholder?: Maybe<Scalars["String"]>;
  position?: Maybe<Scalars["Int"]>;
  step?: Maybe<Scalars["String"]>;
  type_id?: Maybe<Scalars["uuid"]>;
  units?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
  validation_regex?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "form_fields" */
export type Form_Fields_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  default_value?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  field_name?: InputMaybe<Order_By>;
  form_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  label?: InputMaybe<Order_By>;
  max?: InputMaybe<Order_By>;
  min?: InputMaybe<Order_By>;
  placeholder?: InputMaybe<Order_By>;
  position?: InputMaybe<Order_By>;
  step?: InputMaybe<Order_By>;
  type_id?: InputMaybe<Order_By>;
  units?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  validation_regex?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Form_Fields_Min_Fields = {
  __typename?: "form_fields_min_fields";
  created_at?: Maybe<Scalars["timestamptz"]>;
  default_value?: Maybe<Scalars["String"]>;
  description?: Maybe<Scalars["String"]>;
  field_name?: Maybe<Scalars["String"]>;
  form_id?: Maybe<Scalars["uuid"]>;
  id?: Maybe<Scalars["uuid"]>;
  label?: Maybe<Scalars["String"]>;
  max?: Maybe<Scalars["bigint"]>;
  min?: Maybe<Scalars["bigint"]>;
  placeholder?: Maybe<Scalars["String"]>;
  position?: Maybe<Scalars["Int"]>;
  step?: Maybe<Scalars["String"]>;
  type_id?: Maybe<Scalars["uuid"]>;
  units?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
  validation_regex?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "form_fields" */
export type Form_Fields_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  default_value?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  field_name?: InputMaybe<Order_By>;
  form_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  label?: InputMaybe<Order_By>;
  max?: InputMaybe<Order_By>;
  min?: InputMaybe<Order_By>;
  placeholder?: InputMaybe<Order_By>;
  position?: InputMaybe<Order_By>;
  step?: InputMaybe<Order_By>;
  type_id?: InputMaybe<Order_By>;
  units?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  validation_regex?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "form_fields" */
export type Form_Fields_Mutation_Response = {
  __typename?: "form_fields_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Form_Fields>;
};

/** input type for inserting object relation for remote table "form_fields" */
export type Form_Fields_Obj_Rel_Insert_Input = {
  data: Form_Fields_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Form_Fields_On_Conflict>;
};

/** on_conflict condition type for table "form_fields" */
export type Form_Fields_On_Conflict = {
  constraint: Form_Fields_Constraint;
  update_columns?: Array<Form_Fields_Update_Column>;
  where?: InputMaybe<Form_Fields_Bool_Exp>;
};

/** Ordering options when selecting data from "form_fields". */
export type Form_Fields_Order_By = {
  case_details_aggregate?: InputMaybe<Case_Details_Aggregate_Order_By>;
  contact_details_aggregate?: InputMaybe<Contact_Details_Aggregate_Order_By>;
  created_at?: InputMaybe<Order_By>;
  default_value?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  display_if?: InputMaybe<Order_By>;
  field_name?: InputMaybe<Order_By>;
  form?: InputMaybe<Forms_Order_By>;
  form_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  interaction_data_aggregate?: InputMaybe<Interaction_Details_Aggregate_Order_By>;
  is_active?: InputMaybe<Order_By>;
  is_required?: InputMaybe<Order_By>;
  label?: InputMaybe<Order_By>;
  max?: InputMaybe<Order_By>;
  min?: InputMaybe<Order_By>;
  options?: InputMaybe<Order_By>;
  placeholder?: InputMaybe<Order_By>;
  position?: InputMaybe<Order_By>;
  step?: InputMaybe<Order_By>;
  type?: InputMaybe<Types_Order_By>;
  type_id?: InputMaybe<Order_By>;
  units?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  validation_regex?: InputMaybe<Order_By>;
};

/** primary key columns input for table: form_fields */
export type Form_Fields_Pk_Columns_Input = {
  id: Scalars["uuid"];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Form_Fields_Prepend_Input = {
  display_if?: InputMaybe<Scalars["jsonb"]>;
  options?: InputMaybe<Scalars["jsonb"]>;
};

/** select columns of table "form_fields" */
export enum Form_Fields_Select_Column {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  DefaultValue = "default_value",
  /** column name */
  Description = "description",
  /** column name */
  DisplayIf = "display_if",
  /** column name */
  FieldName = "field_name",
  /** column name */
  FormId = "form_id",
  /** column name */
  Id = "id",
  /** column name */
  IsActive = "is_active",
  /** column name */
  IsRequired = "is_required",
  /** column name */
  Label = "label",
  /** column name */
  Max = "max",
  /** column name */
  Min = "min",
  /** column name */
  Options = "options",
  /** column name */
  Placeholder = "placeholder",
  /** column name */
  Position = "position",
  /** column name */
  Step = "step",
  /** column name */
  TypeId = "type_id",
  /** column name */
  Units = "units",
  /** column name */
  UpdatedAt = "updated_at",
  /** column name */
  ValidationRegex = "validation_regex",
}

/** select "form_fields_aggregate_bool_exp_bool_and_arguments_columns" columns of table "form_fields" */
export enum Form_Fields_Select_Column_Form_Fields_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  IsActive = "is_active",
  /** column name */
  IsRequired = "is_required",
}

/** select "form_fields_aggregate_bool_exp_bool_or_arguments_columns" columns of table "form_fields" */
export enum Form_Fields_Select_Column_Form_Fields_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  IsActive = "is_active",
  /** column name */
  IsRequired = "is_required",
}

/** input type for updating data in table "form_fields" */
export type Form_Fields_Set_Input = {
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  default_value?: InputMaybe<Scalars["String"]>;
  description?: InputMaybe<Scalars["String"]>;
  display_if?: InputMaybe<Scalars["jsonb"]>;
  field_name?: InputMaybe<Scalars["String"]>;
  form_id?: InputMaybe<Scalars["uuid"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  is_active?: InputMaybe<Scalars["Boolean"]>;
  is_required?: InputMaybe<Scalars["Boolean"]>;
  label?: InputMaybe<Scalars["String"]>;
  max?: InputMaybe<Scalars["bigint"]>;
  min?: InputMaybe<Scalars["bigint"]>;
  options?: InputMaybe<Scalars["jsonb"]>;
  placeholder?: InputMaybe<Scalars["String"]>;
  position?: InputMaybe<Scalars["Int"]>;
  step?: InputMaybe<Scalars["String"]>;
  type_id?: InputMaybe<Scalars["uuid"]>;
  units?: InputMaybe<Scalars["String"]>;
  updated_at?: InputMaybe<Scalars["timestamptz"]>;
  validation_regex?: InputMaybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type Form_Fields_Stddev_Fields = {
  __typename?: "form_fields_stddev_fields";
  max?: Maybe<Scalars["Float"]>;
  min?: Maybe<Scalars["Float"]>;
  position?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "form_fields" */
export type Form_Fields_Stddev_Order_By = {
  max?: InputMaybe<Order_By>;
  min?: InputMaybe<Order_By>;
  position?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Form_Fields_Stddev_Pop_Fields = {
  __typename?: "form_fields_stddev_pop_fields";
  max?: Maybe<Scalars["Float"]>;
  min?: Maybe<Scalars["Float"]>;
  position?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "form_fields" */
export type Form_Fields_Stddev_Pop_Order_By = {
  max?: InputMaybe<Order_By>;
  min?: InputMaybe<Order_By>;
  position?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Form_Fields_Stddev_Samp_Fields = {
  __typename?: "form_fields_stddev_samp_fields";
  max?: Maybe<Scalars["Float"]>;
  min?: Maybe<Scalars["Float"]>;
  position?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "form_fields" */
export type Form_Fields_Stddev_Samp_Order_By = {
  max?: InputMaybe<Order_By>;
  min?: InputMaybe<Order_By>;
  position?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "form_fields" */
export type Form_Fields_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Form_Fields_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Form_Fields_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  default_value?: InputMaybe<Scalars["String"]>;
  description?: InputMaybe<Scalars["String"]>;
  display_if?: InputMaybe<Scalars["jsonb"]>;
  field_name?: InputMaybe<Scalars["String"]>;
  form_id?: InputMaybe<Scalars["uuid"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  is_active?: InputMaybe<Scalars["Boolean"]>;
  is_required?: InputMaybe<Scalars["Boolean"]>;
  label?: InputMaybe<Scalars["String"]>;
  max?: InputMaybe<Scalars["bigint"]>;
  min?: InputMaybe<Scalars["bigint"]>;
  options?: InputMaybe<Scalars["jsonb"]>;
  placeholder?: InputMaybe<Scalars["String"]>;
  position?: InputMaybe<Scalars["Int"]>;
  step?: InputMaybe<Scalars["String"]>;
  type_id?: InputMaybe<Scalars["uuid"]>;
  units?: InputMaybe<Scalars["String"]>;
  updated_at?: InputMaybe<Scalars["timestamptz"]>;
  validation_regex?: InputMaybe<Scalars["String"]>;
};

/** aggregate sum on columns */
export type Form_Fields_Sum_Fields = {
  __typename?: "form_fields_sum_fields";
  max?: Maybe<Scalars["bigint"]>;
  min?: Maybe<Scalars["bigint"]>;
  position?: Maybe<Scalars["Int"]>;
};

/** order by sum() on columns of table "form_fields" */
export type Form_Fields_Sum_Order_By = {
  max?: InputMaybe<Order_By>;
  min?: InputMaybe<Order_By>;
  position?: InputMaybe<Order_By>;
};

/** update columns of table "form_fields" */
export enum Form_Fields_Update_Column {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  DefaultValue = "default_value",
  /** column name */
  Description = "description",
  /** column name */
  DisplayIf = "display_if",
  /** column name */
  FieldName = "field_name",
  /** column name */
  FormId = "form_id",
  /** column name */
  Id = "id",
  /** column name */
  IsActive = "is_active",
  /** column name */
  IsRequired = "is_required",
  /** column name */
  Label = "label",
  /** column name */
  Max = "max",
  /** column name */
  Min = "min",
  /** column name */
  Options = "options",
  /** column name */
  Placeholder = "placeholder",
  /** column name */
  Position = "position",
  /** column name */
  Step = "step",
  /** column name */
  TypeId = "type_id",
  /** column name */
  Units = "units",
  /** column name */
  UpdatedAt = "updated_at",
  /** column name */
  ValidationRegex = "validation_regex",
}

export type Form_Fields_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Form_Fields_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Form_Fields_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Form_Fields_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Form_Fields_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Form_Fields_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Form_Fields_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Form_Fields_Set_Input>;
  /** filter the rows which have to be updated */
  where: Form_Fields_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Form_Fields_Var_Pop_Fields = {
  __typename?: "form_fields_var_pop_fields";
  max?: Maybe<Scalars["Float"]>;
  min?: Maybe<Scalars["Float"]>;
  position?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "form_fields" */
export type Form_Fields_Var_Pop_Order_By = {
  max?: InputMaybe<Order_By>;
  min?: InputMaybe<Order_By>;
  position?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Form_Fields_Var_Samp_Fields = {
  __typename?: "form_fields_var_samp_fields";
  max?: Maybe<Scalars["Float"]>;
  min?: Maybe<Scalars["Float"]>;
  position?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "form_fields" */
export type Form_Fields_Var_Samp_Order_By = {
  max?: InputMaybe<Order_By>;
  min?: InputMaybe<Order_By>;
  position?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Form_Fields_Variance_Fields = {
  __typename?: "form_fields_variance_fields";
  max?: Maybe<Scalars["Float"]>;
  min?: Maybe<Scalars["Float"]>;
  position?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "form_fields" */
export type Form_Fields_Variance_Order_By = {
  max?: InputMaybe<Order_By>;
  min?: InputMaybe<Order_By>;
  position?: InputMaybe<Order_By>;
};

/** columns and relationships of "form_rules" */
export type Form_Rules = {
  __typename?: "form_rules";
  created_at: Scalars["timestamptz"];
  /** An object relationship */
  form: Forms;
  form_id: Scalars["uuid"];
  id: Scalars["uuid"];
  is_active: Scalars["Boolean"];
  /** An object relationship */
  rule: Rules;
  rule_id: Scalars["uuid"];
  updated_at: Scalars["timestamptz"];
};

/** aggregated selection of "form_rules" */
export type Form_Rules_Aggregate = {
  __typename?: "form_rules_aggregate";
  aggregate?: Maybe<Form_Rules_Aggregate_Fields>;
  nodes: Array<Form_Rules>;
};

export type Form_Rules_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Form_Rules_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Form_Rules_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Form_Rules_Aggregate_Bool_Exp_Count>;
};

export type Form_Rules_Aggregate_Bool_Exp_Bool_And = {
  arguments: Form_Rules_Select_Column_Form_Rules_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<Form_Rules_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Form_Rules_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Form_Rules_Select_Column_Form_Rules_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<Form_Rules_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Form_Rules_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Form_Rules_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<Form_Rules_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "form_rules" */
export type Form_Rules_Aggregate_Fields = {
  __typename?: "form_rules_aggregate_fields";
  count: Scalars["Int"];
  max?: Maybe<Form_Rules_Max_Fields>;
  min?: Maybe<Form_Rules_Min_Fields>;
};

/** aggregate fields of "form_rules" */
export type Form_Rules_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Form_Rules_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "form_rules" */
export type Form_Rules_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Form_Rules_Max_Order_By>;
  min?: InputMaybe<Form_Rules_Min_Order_By>;
};

/** input type for inserting array relation for remote table "form_rules" */
export type Form_Rules_Arr_Rel_Insert_Input = {
  data: Array<Form_Rules_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Form_Rules_On_Conflict>;
};

/** Boolean expression to filter rows from the table "form_rules". All fields are combined with a logical 'AND'. */
export type Form_Rules_Bool_Exp = {
  _and?: InputMaybe<Array<Form_Rules_Bool_Exp>>;
  _not?: InputMaybe<Form_Rules_Bool_Exp>;
  _or?: InputMaybe<Array<Form_Rules_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  form?: InputMaybe<Forms_Bool_Exp>;
  form_id?: InputMaybe<Uuid_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  is_active?: InputMaybe<Boolean_Comparison_Exp>;
  rule?: InputMaybe<Rules_Bool_Exp>;
  rule_id?: InputMaybe<Uuid_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "form_rules" */
export enum Form_Rules_Constraint {
  /** unique or primary key constraint on columns "id" */
  FormRulesPkey = "form_rules_pkey",
}

/** input type for inserting data into table "form_rules" */
export type Form_Rules_Insert_Input = {
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  form?: InputMaybe<Forms_Obj_Rel_Insert_Input>;
  form_id?: InputMaybe<Scalars["uuid"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  is_active?: InputMaybe<Scalars["Boolean"]>;
  rule?: InputMaybe<Rules_Obj_Rel_Insert_Input>;
  rule_id?: InputMaybe<Scalars["uuid"]>;
  updated_at?: InputMaybe<Scalars["timestamptz"]>;
};

/** aggregate max on columns */
export type Form_Rules_Max_Fields = {
  __typename?: "form_rules_max_fields";
  created_at?: Maybe<Scalars["timestamptz"]>;
  form_id?: Maybe<Scalars["uuid"]>;
  id?: Maybe<Scalars["uuid"]>;
  rule_id?: Maybe<Scalars["uuid"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
};

/** order by max() on columns of table "form_rules" */
export type Form_Rules_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  form_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  rule_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Form_Rules_Min_Fields = {
  __typename?: "form_rules_min_fields";
  created_at?: Maybe<Scalars["timestamptz"]>;
  form_id?: Maybe<Scalars["uuid"]>;
  id?: Maybe<Scalars["uuid"]>;
  rule_id?: Maybe<Scalars["uuid"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
};

/** order by min() on columns of table "form_rules" */
export type Form_Rules_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  form_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  rule_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "form_rules" */
export type Form_Rules_Mutation_Response = {
  __typename?: "form_rules_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Form_Rules>;
};

/** on_conflict condition type for table "form_rules" */
export type Form_Rules_On_Conflict = {
  constraint: Form_Rules_Constraint;
  update_columns?: Array<Form_Rules_Update_Column>;
  where?: InputMaybe<Form_Rules_Bool_Exp>;
};

/** Ordering options when selecting data from "form_rules". */
export type Form_Rules_Order_By = {
  created_at?: InputMaybe<Order_By>;
  form?: InputMaybe<Forms_Order_By>;
  form_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  is_active?: InputMaybe<Order_By>;
  rule?: InputMaybe<Rules_Order_By>;
  rule_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: form_rules */
export type Form_Rules_Pk_Columns_Input = {
  id: Scalars["uuid"];
};

/** select columns of table "form_rules" */
export enum Form_Rules_Select_Column {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  FormId = "form_id",
  /** column name */
  Id = "id",
  /** column name */
  IsActive = "is_active",
  /** column name */
  RuleId = "rule_id",
  /** column name */
  UpdatedAt = "updated_at",
}

/** select "form_rules_aggregate_bool_exp_bool_and_arguments_columns" columns of table "form_rules" */
export enum Form_Rules_Select_Column_Form_Rules_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  IsActive = "is_active",
}

/** select "form_rules_aggregate_bool_exp_bool_or_arguments_columns" columns of table "form_rules" */
export enum Form_Rules_Select_Column_Form_Rules_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  IsActive = "is_active",
}

/** input type for updating data in table "form_rules" */
export type Form_Rules_Set_Input = {
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  form_id?: InputMaybe<Scalars["uuid"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  is_active?: InputMaybe<Scalars["Boolean"]>;
  rule_id?: InputMaybe<Scalars["uuid"]>;
  updated_at?: InputMaybe<Scalars["timestamptz"]>;
};

/** Streaming cursor of the table "form_rules" */
export type Form_Rules_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Form_Rules_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Form_Rules_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  form_id?: InputMaybe<Scalars["uuid"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  is_active?: InputMaybe<Scalars["Boolean"]>;
  rule_id?: InputMaybe<Scalars["uuid"]>;
  updated_at?: InputMaybe<Scalars["timestamptz"]>;
};

/** update columns of table "form_rules" */
export enum Form_Rules_Update_Column {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  FormId = "form_id",
  /** column name */
  Id = "id",
  /** column name */
  IsActive = "is_active",
  /** column name */
  RuleId = "rule_id",
  /** column name */
  UpdatedAt = "updated_at",
}

export type Form_Rules_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Form_Rules_Set_Input>;
  /** filter the rows which have to be updated */
  where: Form_Rules_Bool_Exp;
};

/** columns and relationships of "forms" */
export type Forms = {
  __typename?: "forms";
  /** An array relationship */
  campaign_workflows: Array<Campaign_Workflows>;
  /** An aggregate relationship */
  campaign_workflows_aggregate: Campaign_Workflows_Aggregate;
  /** An array relationship */
  case_details: Array<Case_Details>;
  /** An aggregate relationship */
  case_details_aggregate: Case_Details_Aggregate;
  /** An array relationship */
  contact_details: Array<Contact_Details>;
  /** An aggregate relationship */
  contact_details_aggregate: Contact_Details_Aggregate;
  created_at: Scalars["timestamptz"];
  description?: Maybe<Scalars["String"]>;
  display_if?: Maybe<Scalars["jsonb"]>;
  /** An object relationship */
  form?: Maybe<Forms>;
  /** An array relationship */
  form_fields: Array<Form_Fields>;
  /** An aggregate relationship */
  form_fields_aggregate: Form_Fields_Aggregate;
  /** An array relationship */
  form_rules: Array<Form_Rules>;
  /** An aggregate relationship */
  form_rules_aggregate: Form_Rules_Aggregate;
  /** An array relationship */
  forms: Array<Forms>;
  /** An aggregate relationship */
  forms_aggregate: Forms_Aggregate;
  id: Scalars["uuid"];
  /** An array relationship */
  interaction_data: Array<Interaction_Details>;
  /** An aggregate relationship */
  interaction_data_aggregate: Interaction_Details_Aggregate;
  is_active: Scalars["Boolean"];
  name: Scalars["String"];
  organisation_id?: Maybe<Scalars["uuid"]>;
  parent_form_id?: Maybe<Scalars["uuid"]>;
  /** An array relationship */
  parties: Array<Parties>;
  /** An aggregate relationship */
  parties_aggregate: Parties_Aggregate;
  /** An array relationship */
  subscription_details: Array<Subscription_Details>;
  /** An aggregate relationship */
  subscription_details_aggregate: Subscription_Details_Aggregate;
  /** An object relationship */
  type: Types;
  type_id: Scalars["uuid"];
  updated_at: Scalars["timestamptz"];
  workflow_type?: Maybe<Scalars["String"]>;
};

/** columns and relationships of "forms" */
export type FormsCampaign_WorkflowsArgs = {
  distinct_on?: InputMaybe<Array<Campaign_Workflows_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Campaign_Workflows_Order_By>>;
  where?: InputMaybe<Campaign_Workflows_Bool_Exp>;
};

/** columns and relationships of "forms" */
export type FormsCampaign_Workflows_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Campaign_Workflows_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Campaign_Workflows_Order_By>>;
  where?: InputMaybe<Campaign_Workflows_Bool_Exp>;
};

/** columns and relationships of "forms" */
export type FormsCase_DetailsArgs = {
  distinct_on?: InputMaybe<Array<Case_Details_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Case_Details_Order_By>>;
  where?: InputMaybe<Case_Details_Bool_Exp>;
};

/** columns and relationships of "forms" */
export type FormsCase_Details_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Case_Details_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Case_Details_Order_By>>;
  where?: InputMaybe<Case_Details_Bool_Exp>;
};

/** columns and relationships of "forms" */
export type FormsContact_DetailsArgs = {
  distinct_on?: InputMaybe<Array<Contact_Details_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Contact_Details_Order_By>>;
  where?: InputMaybe<Contact_Details_Bool_Exp>;
};

/** columns and relationships of "forms" */
export type FormsContact_Details_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Contact_Details_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Contact_Details_Order_By>>;
  where?: InputMaybe<Contact_Details_Bool_Exp>;
};

/** columns and relationships of "forms" */
export type FormsDisplay_IfArgs = {
  path?: InputMaybe<Scalars["String"]>;
};

/** columns and relationships of "forms" */
export type FormsForm_FieldsArgs = {
  distinct_on?: InputMaybe<Array<Form_Fields_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Form_Fields_Order_By>>;
  where?: InputMaybe<Form_Fields_Bool_Exp>;
};

/** columns and relationships of "forms" */
export type FormsForm_Fields_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Form_Fields_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Form_Fields_Order_By>>;
  where?: InputMaybe<Form_Fields_Bool_Exp>;
};

/** columns and relationships of "forms" */
export type FormsForm_RulesArgs = {
  distinct_on?: InputMaybe<Array<Form_Rules_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Form_Rules_Order_By>>;
  where?: InputMaybe<Form_Rules_Bool_Exp>;
};

/** columns and relationships of "forms" */
export type FormsForm_Rules_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Form_Rules_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Form_Rules_Order_By>>;
  where?: InputMaybe<Form_Rules_Bool_Exp>;
};

/** columns and relationships of "forms" */
export type FormsFormsArgs = {
  distinct_on?: InputMaybe<Array<Forms_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Forms_Order_By>>;
  where?: InputMaybe<Forms_Bool_Exp>;
};

/** columns and relationships of "forms" */
export type FormsForms_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Forms_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Forms_Order_By>>;
  where?: InputMaybe<Forms_Bool_Exp>;
};

/** columns and relationships of "forms" */
export type FormsInteraction_DataArgs = {
  distinct_on?: InputMaybe<Array<Interaction_Details_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Interaction_Details_Order_By>>;
  where?: InputMaybe<Interaction_Details_Bool_Exp>;
};

/** columns and relationships of "forms" */
export type FormsInteraction_Data_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Interaction_Details_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Interaction_Details_Order_By>>;
  where?: InputMaybe<Interaction_Details_Bool_Exp>;
};

/** columns and relationships of "forms" */
export type FormsPartiesArgs = {
  distinct_on?: InputMaybe<Array<Parties_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Parties_Order_By>>;
  where?: InputMaybe<Parties_Bool_Exp>;
};

/** columns and relationships of "forms" */
export type FormsParties_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Parties_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Parties_Order_By>>;
  where?: InputMaybe<Parties_Bool_Exp>;
};

/** columns and relationships of "forms" */
export type FormsSubscription_DetailsArgs = {
  distinct_on?: InputMaybe<Array<Subscription_Details_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Subscription_Details_Order_By>>;
  where?: InputMaybe<Subscription_Details_Bool_Exp>;
};

/** columns and relationships of "forms" */
export type FormsSubscription_Details_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Subscription_Details_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Subscription_Details_Order_By>>;
  where?: InputMaybe<Subscription_Details_Bool_Exp>;
};

/** aggregated selection of "forms" */
export type Forms_Aggregate = {
  __typename?: "forms_aggregate";
  aggregate?: Maybe<Forms_Aggregate_Fields>;
  nodes: Array<Forms>;
};

export type Forms_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Forms_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Forms_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Forms_Aggregate_Bool_Exp_Count>;
};

export type Forms_Aggregate_Bool_Exp_Bool_And = {
  arguments: Forms_Select_Column_Forms_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<Forms_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Forms_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Forms_Select_Column_Forms_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<Forms_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Forms_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Forms_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<Forms_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "forms" */
export type Forms_Aggregate_Fields = {
  __typename?: "forms_aggregate_fields";
  count: Scalars["Int"];
  max?: Maybe<Forms_Max_Fields>;
  min?: Maybe<Forms_Min_Fields>;
};

/** aggregate fields of "forms" */
export type Forms_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Forms_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "forms" */
export type Forms_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Forms_Max_Order_By>;
  min?: InputMaybe<Forms_Min_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Forms_Append_Input = {
  display_if?: InputMaybe<Scalars["jsonb"]>;
};

/** input type for inserting array relation for remote table "forms" */
export type Forms_Arr_Rel_Insert_Input = {
  data: Array<Forms_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Forms_On_Conflict>;
};

/** Boolean expression to filter rows from the table "forms". All fields are combined with a logical 'AND'. */
export type Forms_Bool_Exp = {
  _and?: InputMaybe<Array<Forms_Bool_Exp>>;
  _not?: InputMaybe<Forms_Bool_Exp>;
  _or?: InputMaybe<Array<Forms_Bool_Exp>>;
  campaign_workflows?: InputMaybe<Campaign_Workflows_Bool_Exp>;
  campaign_workflows_aggregate?: InputMaybe<Campaign_Workflows_Aggregate_Bool_Exp>;
  case_details?: InputMaybe<Case_Details_Bool_Exp>;
  case_details_aggregate?: InputMaybe<Case_Details_Aggregate_Bool_Exp>;
  contact_details?: InputMaybe<Contact_Details_Bool_Exp>;
  contact_details_aggregate?: InputMaybe<Contact_Details_Aggregate_Bool_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  display_if?: InputMaybe<Jsonb_Comparison_Exp>;
  form?: InputMaybe<Forms_Bool_Exp>;
  form_fields?: InputMaybe<Form_Fields_Bool_Exp>;
  form_fields_aggregate?: InputMaybe<Form_Fields_Aggregate_Bool_Exp>;
  form_rules?: InputMaybe<Form_Rules_Bool_Exp>;
  form_rules_aggregate?: InputMaybe<Form_Rules_Aggregate_Bool_Exp>;
  forms?: InputMaybe<Forms_Bool_Exp>;
  forms_aggregate?: InputMaybe<Forms_Aggregate_Bool_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  interaction_data?: InputMaybe<Interaction_Details_Bool_Exp>;
  interaction_data_aggregate?: InputMaybe<Interaction_Details_Aggregate_Bool_Exp>;
  is_active?: InputMaybe<Boolean_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  organisation_id?: InputMaybe<Uuid_Comparison_Exp>;
  parent_form_id?: InputMaybe<Uuid_Comparison_Exp>;
  parties?: InputMaybe<Parties_Bool_Exp>;
  parties_aggregate?: InputMaybe<Parties_Aggregate_Bool_Exp>;
  subscription_details?: InputMaybe<Subscription_Details_Bool_Exp>;
  subscription_details_aggregate?: InputMaybe<Subscription_Details_Aggregate_Bool_Exp>;
  type?: InputMaybe<Types_Bool_Exp>;
  type_id?: InputMaybe<Uuid_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  workflow_type?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "forms" */
export enum Forms_Constraint {
  /** unique or primary key constraint on columns "id" */
  FormsPkey = "forms_pkey",
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Forms_Delete_At_Path_Input = {
  display_if?: InputMaybe<Array<Scalars["String"]>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Forms_Delete_Elem_Input = {
  display_if?: InputMaybe<Scalars["Int"]>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Forms_Delete_Key_Input = {
  display_if?: InputMaybe<Scalars["String"]>;
};

/** input type for inserting data into table "forms" */
export type Forms_Insert_Input = {
  campaign_workflows?: InputMaybe<Campaign_Workflows_Arr_Rel_Insert_Input>;
  case_details?: InputMaybe<Case_Details_Arr_Rel_Insert_Input>;
  contact_details?: InputMaybe<Contact_Details_Arr_Rel_Insert_Input>;
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  description?: InputMaybe<Scalars["String"]>;
  display_if?: InputMaybe<Scalars["jsonb"]>;
  form?: InputMaybe<Forms_Obj_Rel_Insert_Input>;
  form_fields?: InputMaybe<Form_Fields_Arr_Rel_Insert_Input>;
  form_rules?: InputMaybe<Form_Rules_Arr_Rel_Insert_Input>;
  forms?: InputMaybe<Forms_Arr_Rel_Insert_Input>;
  id?: InputMaybe<Scalars["uuid"]>;
  interaction_data?: InputMaybe<Interaction_Details_Arr_Rel_Insert_Input>;
  is_active?: InputMaybe<Scalars["Boolean"]>;
  name?: InputMaybe<Scalars["String"]>;
  organisation_id?: InputMaybe<Scalars["uuid"]>;
  parent_form_id?: InputMaybe<Scalars["uuid"]>;
  parties?: InputMaybe<Parties_Arr_Rel_Insert_Input>;
  subscription_details?: InputMaybe<Subscription_Details_Arr_Rel_Insert_Input>;
  type?: InputMaybe<Types_Obj_Rel_Insert_Input>;
  type_id?: InputMaybe<Scalars["uuid"]>;
  updated_at?: InputMaybe<Scalars["timestamptz"]>;
  workflow_type?: InputMaybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Forms_Max_Fields = {
  __typename?: "forms_max_fields";
  created_at?: Maybe<Scalars["timestamptz"]>;
  description?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["uuid"]>;
  name?: Maybe<Scalars["String"]>;
  organisation_id?: Maybe<Scalars["uuid"]>;
  parent_form_id?: Maybe<Scalars["uuid"]>;
  type_id?: Maybe<Scalars["uuid"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
  workflow_type?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "forms" */
export type Forms_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  organisation_id?: InputMaybe<Order_By>;
  parent_form_id?: InputMaybe<Order_By>;
  type_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  workflow_type?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Forms_Min_Fields = {
  __typename?: "forms_min_fields";
  created_at?: Maybe<Scalars["timestamptz"]>;
  description?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["uuid"]>;
  name?: Maybe<Scalars["String"]>;
  organisation_id?: Maybe<Scalars["uuid"]>;
  parent_form_id?: Maybe<Scalars["uuid"]>;
  type_id?: Maybe<Scalars["uuid"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
  workflow_type?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "forms" */
export type Forms_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  organisation_id?: InputMaybe<Order_By>;
  parent_form_id?: InputMaybe<Order_By>;
  type_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  workflow_type?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "forms" */
export type Forms_Mutation_Response = {
  __typename?: "forms_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Forms>;
};

/** input type for inserting object relation for remote table "forms" */
export type Forms_Obj_Rel_Insert_Input = {
  data: Forms_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Forms_On_Conflict>;
};

/** on_conflict condition type for table "forms" */
export type Forms_On_Conflict = {
  constraint: Forms_Constraint;
  update_columns?: Array<Forms_Update_Column>;
  where?: InputMaybe<Forms_Bool_Exp>;
};

/** Ordering options when selecting data from "forms". */
export type Forms_Order_By = {
  campaign_workflows_aggregate?: InputMaybe<Campaign_Workflows_Aggregate_Order_By>;
  case_details_aggregate?: InputMaybe<Case_Details_Aggregate_Order_By>;
  contact_details_aggregate?: InputMaybe<Contact_Details_Aggregate_Order_By>;
  created_at?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  display_if?: InputMaybe<Order_By>;
  form?: InputMaybe<Forms_Order_By>;
  form_fields_aggregate?: InputMaybe<Form_Fields_Aggregate_Order_By>;
  form_rules_aggregate?: InputMaybe<Form_Rules_Aggregate_Order_By>;
  forms_aggregate?: InputMaybe<Forms_Aggregate_Order_By>;
  id?: InputMaybe<Order_By>;
  interaction_data_aggregate?: InputMaybe<Interaction_Details_Aggregate_Order_By>;
  is_active?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  organisation_id?: InputMaybe<Order_By>;
  parent_form_id?: InputMaybe<Order_By>;
  parties_aggregate?: InputMaybe<Parties_Aggregate_Order_By>;
  subscription_details_aggregate?: InputMaybe<Subscription_Details_Aggregate_Order_By>;
  type?: InputMaybe<Types_Order_By>;
  type_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  workflow_type?: InputMaybe<Order_By>;
};

/** primary key columns input for table: forms */
export type Forms_Pk_Columns_Input = {
  id: Scalars["uuid"];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Forms_Prepend_Input = {
  display_if?: InputMaybe<Scalars["jsonb"]>;
};

/** select columns of table "forms" */
export enum Forms_Select_Column {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  Description = "description",
  /** column name */
  DisplayIf = "display_if",
  /** column name */
  Id = "id",
  /** column name */
  IsActive = "is_active",
  /** column name */
  Name = "name",
  /** column name */
  OrganisationId = "organisation_id",
  /** column name */
  ParentFormId = "parent_form_id",
  /** column name */
  TypeId = "type_id",
  /** column name */
  UpdatedAt = "updated_at",
  /** column name */
  WorkflowType = "workflow_type",
}

/** select "forms_aggregate_bool_exp_bool_and_arguments_columns" columns of table "forms" */
export enum Forms_Select_Column_Forms_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  IsActive = "is_active",
}

/** select "forms_aggregate_bool_exp_bool_or_arguments_columns" columns of table "forms" */
export enum Forms_Select_Column_Forms_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  IsActive = "is_active",
}

/** input type for updating data in table "forms" */
export type Forms_Set_Input = {
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  description?: InputMaybe<Scalars["String"]>;
  display_if?: InputMaybe<Scalars["jsonb"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  is_active?: InputMaybe<Scalars["Boolean"]>;
  name?: InputMaybe<Scalars["String"]>;
  organisation_id?: InputMaybe<Scalars["uuid"]>;
  parent_form_id?: InputMaybe<Scalars["uuid"]>;
  type_id?: InputMaybe<Scalars["uuid"]>;
  updated_at?: InputMaybe<Scalars["timestamptz"]>;
  workflow_type?: InputMaybe<Scalars["String"]>;
};

/** Streaming cursor of the table "forms" */
export type Forms_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Forms_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Forms_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  description?: InputMaybe<Scalars["String"]>;
  display_if?: InputMaybe<Scalars["jsonb"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  is_active?: InputMaybe<Scalars["Boolean"]>;
  name?: InputMaybe<Scalars["String"]>;
  organisation_id?: InputMaybe<Scalars["uuid"]>;
  parent_form_id?: InputMaybe<Scalars["uuid"]>;
  type_id?: InputMaybe<Scalars["uuid"]>;
  updated_at?: InputMaybe<Scalars["timestamptz"]>;
  workflow_type?: InputMaybe<Scalars["String"]>;
};

/** update columns of table "forms" */
export enum Forms_Update_Column {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  Description = "description",
  /** column name */
  DisplayIf = "display_if",
  /** column name */
  Id = "id",
  /** column name */
  IsActive = "is_active",
  /** column name */
  Name = "name",
  /** column name */
  OrganisationId = "organisation_id",
  /** column name */
  ParentFormId = "parent_form_id",
  /** column name */
  TypeId = "type_id",
  /** column name */
  UpdatedAt = "updated_at",
  /** column name */
  WorkflowType = "workflow_type",
}

export type Forms_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Forms_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Forms_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Forms_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Forms_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Forms_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Forms_Set_Input>;
  /** filter the rows which have to be updated */
  where: Forms_Bool_Exp;
};

export type Get_Party_Channel_Args = {
  party_count?: InputMaybe<Scalars["Int"]>;
  party_ids?: InputMaybe<Scalars["_uuid"]>;
};

/** columns and relationships of "interaction_details" */
export type Interaction_Details = {
  __typename?: "interaction_details";
  created_at: Scalars["timestamptz"];
  /** An object relationship */
  form: Forms;
  /** An object relationship */
  form_field: Form_Fields;
  form_field_id: Scalars["uuid"];
  form_id: Scalars["uuid"];
  id: Scalars["uuid"];
  /** An object relationship */
  interaction: Interactions;
  interaction_id: Scalars["uuid"];
  updated_at: Scalars["timestamptz"];
  value?: Maybe<Scalars["String"]>;
};

/** aggregated selection of "interaction_details" */
export type Interaction_Details_Aggregate = {
  __typename?: "interaction_details_aggregate";
  aggregate?: Maybe<Interaction_Details_Aggregate_Fields>;
  nodes: Array<Interaction_Details>;
};

export type Interaction_Details_Aggregate_Bool_Exp = {
  count?: InputMaybe<Interaction_Details_Aggregate_Bool_Exp_Count>;
};

export type Interaction_Details_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Interaction_Details_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<Interaction_Details_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "interaction_details" */
export type Interaction_Details_Aggregate_Fields = {
  __typename?: "interaction_details_aggregate_fields";
  count: Scalars["Int"];
  max?: Maybe<Interaction_Details_Max_Fields>;
  min?: Maybe<Interaction_Details_Min_Fields>;
};

/** aggregate fields of "interaction_details" */
export type Interaction_Details_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Interaction_Details_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "interaction_details" */
export type Interaction_Details_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Interaction_Details_Max_Order_By>;
  min?: InputMaybe<Interaction_Details_Min_Order_By>;
};

/** input type for inserting array relation for remote table "interaction_details" */
export type Interaction_Details_Arr_Rel_Insert_Input = {
  data: Array<Interaction_Details_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Interaction_Details_On_Conflict>;
};

/** Boolean expression to filter rows from the table "interaction_details". All fields are combined with a logical 'AND'. */
export type Interaction_Details_Bool_Exp = {
  _and?: InputMaybe<Array<Interaction_Details_Bool_Exp>>;
  _not?: InputMaybe<Interaction_Details_Bool_Exp>;
  _or?: InputMaybe<Array<Interaction_Details_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  form?: InputMaybe<Forms_Bool_Exp>;
  form_field?: InputMaybe<Form_Fields_Bool_Exp>;
  form_field_id?: InputMaybe<Uuid_Comparison_Exp>;
  form_id?: InputMaybe<Uuid_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  interaction?: InputMaybe<Interactions_Bool_Exp>;
  interaction_id?: InputMaybe<Uuid_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "interaction_details" */
export enum Interaction_Details_Constraint {
  /** unique or primary key constraint on columns "id" */
  InteractionDataPkey = "interaction_data_pkey",
}

/** input type for inserting data into table "interaction_details" */
export type Interaction_Details_Insert_Input = {
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  form?: InputMaybe<Forms_Obj_Rel_Insert_Input>;
  form_field?: InputMaybe<Form_Fields_Obj_Rel_Insert_Input>;
  form_field_id?: InputMaybe<Scalars["uuid"]>;
  form_id?: InputMaybe<Scalars["uuid"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  interaction?: InputMaybe<Interactions_Obj_Rel_Insert_Input>;
  interaction_id?: InputMaybe<Scalars["uuid"]>;
  updated_at?: InputMaybe<Scalars["timestamptz"]>;
  value?: InputMaybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Interaction_Details_Max_Fields = {
  __typename?: "interaction_details_max_fields";
  created_at?: Maybe<Scalars["timestamptz"]>;
  form_field_id?: Maybe<Scalars["uuid"]>;
  form_id?: Maybe<Scalars["uuid"]>;
  id?: Maybe<Scalars["uuid"]>;
  interaction_id?: Maybe<Scalars["uuid"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
  value?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "interaction_details" */
export type Interaction_Details_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  form_field_id?: InputMaybe<Order_By>;
  form_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  interaction_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Interaction_Details_Min_Fields = {
  __typename?: "interaction_details_min_fields";
  created_at?: Maybe<Scalars["timestamptz"]>;
  form_field_id?: Maybe<Scalars["uuid"]>;
  form_id?: Maybe<Scalars["uuid"]>;
  id?: Maybe<Scalars["uuid"]>;
  interaction_id?: Maybe<Scalars["uuid"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
  value?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "interaction_details" */
export type Interaction_Details_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  form_field_id?: InputMaybe<Order_By>;
  form_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  interaction_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "interaction_details" */
export type Interaction_Details_Mutation_Response = {
  __typename?: "interaction_details_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Interaction_Details>;
};

/** on_conflict condition type for table "interaction_details" */
export type Interaction_Details_On_Conflict = {
  constraint: Interaction_Details_Constraint;
  update_columns?: Array<Interaction_Details_Update_Column>;
  where?: InputMaybe<Interaction_Details_Bool_Exp>;
};

/** Ordering options when selecting data from "interaction_details". */
export type Interaction_Details_Order_By = {
  created_at?: InputMaybe<Order_By>;
  form?: InputMaybe<Forms_Order_By>;
  form_field?: InputMaybe<Form_Fields_Order_By>;
  form_field_id?: InputMaybe<Order_By>;
  form_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  interaction?: InputMaybe<Interactions_Order_By>;
  interaction_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: interaction_details */
export type Interaction_Details_Pk_Columns_Input = {
  id: Scalars["uuid"];
};

/** select columns of table "interaction_details" */
export enum Interaction_Details_Select_Column {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  FormFieldId = "form_field_id",
  /** column name */
  FormId = "form_id",
  /** column name */
  Id = "id",
  /** column name */
  InteractionId = "interaction_id",
  /** column name */
  UpdatedAt = "updated_at",
  /** column name */
  Value = "value",
}

/** input type for updating data in table "interaction_details" */
export type Interaction_Details_Set_Input = {
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  form_field_id?: InputMaybe<Scalars["uuid"]>;
  form_id?: InputMaybe<Scalars["uuid"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  interaction_id?: InputMaybe<Scalars["uuid"]>;
  updated_at?: InputMaybe<Scalars["timestamptz"]>;
  value?: InputMaybe<Scalars["String"]>;
};

/** Streaming cursor of the table "interaction_details" */
export type Interaction_Details_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Interaction_Details_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Interaction_Details_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  form_field_id?: InputMaybe<Scalars["uuid"]>;
  form_id?: InputMaybe<Scalars["uuid"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  interaction_id?: InputMaybe<Scalars["uuid"]>;
  updated_at?: InputMaybe<Scalars["timestamptz"]>;
  value?: InputMaybe<Scalars["String"]>;
};

/** update columns of table "interaction_details" */
export enum Interaction_Details_Update_Column {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  FormFieldId = "form_field_id",
  /** column name */
  FormId = "form_id",
  /** column name */
  Id = "id",
  /** column name */
  InteractionId = "interaction_id",
  /** column name */
  UpdatedAt = "updated_at",
  /** column name */
  Value = "value",
}

export type Interaction_Details_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Interaction_Details_Set_Input>;
  /** filter the rows which have to be updated */
  where: Interaction_Details_Bool_Exp;
};

/** columns and relationships of "interactions" */
export type Interactions = {
  __typename?: "interactions";
  /** An array relationship */
  case_interactions: Array<Case_Interactions>;
  /** An aggregate relationship */
  case_interactions_aggregate: Case_Interactions_Aggregate;
  /** An array relationship */
  contact_interactions: Array<Contact_Interactions>;
  /** An aggregate relationship */
  contact_interactions_aggregate: Contact_Interactions_Aggregate;
  created_at?: Maybe<Scalars["timestamptz"]>;
  created_by: Scalars["uuid"];
  /** An array relationship */
  document_interactions: Array<Document_Interactions>;
  /** An aggregate relationship */
  document_interactions_aggregate: Document_Interactions_Aggregate;
  id: Scalars["uuid"];
  /** An array relationship */
  interaction_data: Array<Interaction_Details>;
  /** An aggregate relationship */
  interaction_data_aggregate: Interaction_Details_Aggregate;
  /** An array relationship */
  invoice_interactions: Array<Invoice_Interactions>;
  /** An aggregate relationship */
  invoice_interactions_aggregate: Invoice_Interactions_Aggregate;
  organisation_id: Scalars["uuid"];
  /** An object relationship */
  party: Parties;
  /** An array relationship */
  party_interactions: Array<Party_Interactions>;
  /** An aggregate relationship */
  party_interactions_aggregate: Party_Interactions_Aggregate;
  /** An array relationship */
  product_interactions: Array<Product_Interactions>;
  /** An aggregate relationship */
  product_interactions_aggregate: Product_Interactions_Aggregate;
  /** An array relationship */
  subscription_interactions: Array<Subscription_Interactions>;
  /** An aggregate relationship */
  subscription_interactions_aggregate: Subscription_Interactions_Aggregate;
  /** An array relationship */
  task_interactions: Array<Task_Interactions>;
  /** An aggregate relationship */
  task_interactions_aggregate: Task_Interactions_Aggregate;
  /** An array relationship */
  territory_interactions: Array<Territory_Interactions>;
  /** An aggregate relationship */
  territory_interactions_aggregate: Territory_Interactions_Aggregate;
  /** An object relationship */
  type: Types;
  type_id: Scalars["uuid"];
};

/** columns and relationships of "interactions" */
export type InteractionsCase_InteractionsArgs = {
  distinct_on?: InputMaybe<Array<Case_Interactions_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Case_Interactions_Order_By>>;
  where?: InputMaybe<Case_Interactions_Bool_Exp>;
};

/** columns and relationships of "interactions" */
export type InteractionsCase_Interactions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Case_Interactions_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Case_Interactions_Order_By>>;
  where?: InputMaybe<Case_Interactions_Bool_Exp>;
};

/** columns and relationships of "interactions" */
export type InteractionsContact_InteractionsArgs = {
  distinct_on?: InputMaybe<Array<Contact_Interactions_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Contact_Interactions_Order_By>>;
  where?: InputMaybe<Contact_Interactions_Bool_Exp>;
};

/** columns and relationships of "interactions" */
export type InteractionsContact_Interactions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Contact_Interactions_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Contact_Interactions_Order_By>>;
  where?: InputMaybe<Contact_Interactions_Bool_Exp>;
};

/** columns and relationships of "interactions" */
export type InteractionsDocument_InteractionsArgs = {
  distinct_on?: InputMaybe<Array<Document_Interactions_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Document_Interactions_Order_By>>;
  where?: InputMaybe<Document_Interactions_Bool_Exp>;
};

/** columns and relationships of "interactions" */
export type InteractionsDocument_Interactions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Document_Interactions_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Document_Interactions_Order_By>>;
  where?: InputMaybe<Document_Interactions_Bool_Exp>;
};

/** columns and relationships of "interactions" */
export type InteractionsInteraction_DataArgs = {
  distinct_on?: InputMaybe<Array<Interaction_Details_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Interaction_Details_Order_By>>;
  where?: InputMaybe<Interaction_Details_Bool_Exp>;
};

/** columns and relationships of "interactions" */
export type InteractionsInteraction_Data_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Interaction_Details_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Interaction_Details_Order_By>>;
  where?: InputMaybe<Interaction_Details_Bool_Exp>;
};

/** columns and relationships of "interactions" */
export type InteractionsInvoice_InteractionsArgs = {
  distinct_on?: InputMaybe<Array<Invoice_Interactions_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Invoice_Interactions_Order_By>>;
  where?: InputMaybe<Invoice_Interactions_Bool_Exp>;
};

/** columns and relationships of "interactions" */
export type InteractionsInvoice_Interactions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Invoice_Interactions_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Invoice_Interactions_Order_By>>;
  where?: InputMaybe<Invoice_Interactions_Bool_Exp>;
};

/** columns and relationships of "interactions" */
export type InteractionsParty_InteractionsArgs = {
  distinct_on?: InputMaybe<Array<Party_Interactions_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Party_Interactions_Order_By>>;
  where?: InputMaybe<Party_Interactions_Bool_Exp>;
};

/** columns and relationships of "interactions" */
export type InteractionsParty_Interactions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Party_Interactions_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Party_Interactions_Order_By>>;
  where?: InputMaybe<Party_Interactions_Bool_Exp>;
};

/** columns and relationships of "interactions" */
export type InteractionsProduct_InteractionsArgs = {
  distinct_on?: InputMaybe<Array<Product_Interactions_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Product_Interactions_Order_By>>;
  where?: InputMaybe<Product_Interactions_Bool_Exp>;
};

/** columns and relationships of "interactions" */
export type InteractionsProduct_Interactions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Product_Interactions_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Product_Interactions_Order_By>>;
  where?: InputMaybe<Product_Interactions_Bool_Exp>;
};

/** columns and relationships of "interactions" */
export type InteractionsSubscription_InteractionsArgs = {
  distinct_on?: InputMaybe<Array<Subscription_Interactions_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Subscription_Interactions_Order_By>>;
  where?: InputMaybe<Subscription_Interactions_Bool_Exp>;
};

/** columns and relationships of "interactions" */
export type InteractionsSubscription_Interactions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Subscription_Interactions_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Subscription_Interactions_Order_By>>;
  where?: InputMaybe<Subscription_Interactions_Bool_Exp>;
};

/** columns and relationships of "interactions" */
export type InteractionsTask_InteractionsArgs = {
  distinct_on?: InputMaybe<Array<Task_Interactions_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Task_Interactions_Order_By>>;
  where?: InputMaybe<Task_Interactions_Bool_Exp>;
};

/** columns and relationships of "interactions" */
export type InteractionsTask_Interactions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Task_Interactions_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Task_Interactions_Order_By>>;
  where?: InputMaybe<Task_Interactions_Bool_Exp>;
};

/** columns and relationships of "interactions" */
export type InteractionsTerritory_InteractionsArgs = {
  distinct_on?: InputMaybe<Array<Territory_Interactions_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Territory_Interactions_Order_By>>;
  where?: InputMaybe<Territory_Interactions_Bool_Exp>;
};

/** columns and relationships of "interactions" */
export type InteractionsTerritory_Interactions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Territory_Interactions_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Territory_Interactions_Order_By>>;
  where?: InputMaybe<Territory_Interactions_Bool_Exp>;
};

/** aggregated selection of "interactions" */
export type Interactions_Aggregate = {
  __typename?: "interactions_aggregate";
  aggregate?: Maybe<Interactions_Aggregate_Fields>;
  nodes: Array<Interactions>;
};

export type Interactions_Aggregate_Bool_Exp = {
  count?: InputMaybe<Interactions_Aggregate_Bool_Exp_Count>;
};

export type Interactions_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Interactions_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<Interactions_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "interactions" */
export type Interactions_Aggregate_Fields = {
  __typename?: "interactions_aggregate_fields";
  count: Scalars["Int"];
  max?: Maybe<Interactions_Max_Fields>;
  min?: Maybe<Interactions_Min_Fields>;
};

/** aggregate fields of "interactions" */
export type Interactions_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Interactions_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "interactions" */
export type Interactions_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Interactions_Max_Order_By>;
  min?: InputMaybe<Interactions_Min_Order_By>;
};

/** input type for inserting array relation for remote table "interactions" */
export type Interactions_Arr_Rel_Insert_Input = {
  data: Array<Interactions_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Interactions_On_Conflict>;
};

/** Boolean expression to filter rows from the table "interactions". All fields are combined with a logical 'AND'. */
export type Interactions_Bool_Exp = {
  _and?: InputMaybe<Array<Interactions_Bool_Exp>>;
  _not?: InputMaybe<Interactions_Bool_Exp>;
  _or?: InputMaybe<Array<Interactions_Bool_Exp>>;
  case_interactions?: InputMaybe<Case_Interactions_Bool_Exp>;
  case_interactions_aggregate?: InputMaybe<Case_Interactions_Aggregate_Bool_Exp>;
  contact_interactions?: InputMaybe<Contact_Interactions_Bool_Exp>;
  contact_interactions_aggregate?: InputMaybe<Contact_Interactions_Aggregate_Bool_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  created_by?: InputMaybe<Uuid_Comparison_Exp>;
  document_interactions?: InputMaybe<Document_Interactions_Bool_Exp>;
  document_interactions_aggregate?: InputMaybe<Document_Interactions_Aggregate_Bool_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  interaction_data?: InputMaybe<Interaction_Details_Bool_Exp>;
  interaction_data_aggregate?: InputMaybe<Interaction_Details_Aggregate_Bool_Exp>;
  invoice_interactions?: InputMaybe<Invoice_Interactions_Bool_Exp>;
  invoice_interactions_aggregate?: InputMaybe<Invoice_Interactions_Aggregate_Bool_Exp>;
  organisation_id?: InputMaybe<Uuid_Comparison_Exp>;
  party?: InputMaybe<Parties_Bool_Exp>;
  party_interactions?: InputMaybe<Party_Interactions_Bool_Exp>;
  party_interactions_aggregate?: InputMaybe<Party_Interactions_Aggregate_Bool_Exp>;
  product_interactions?: InputMaybe<Product_Interactions_Bool_Exp>;
  product_interactions_aggregate?: InputMaybe<Product_Interactions_Aggregate_Bool_Exp>;
  subscription_interactions?: InputMaybe<Subscription_Interactions_Bool_Exp>;
  subscription_interactions_aggregate?: InputMaybe<Subscription_Interactions_Aggregate_Bool_Exp>;
  task_interactions?: InputMaybe<Task_Interactions_Bool_Exp>;
  task_interactions_aggregate?: InputMaybe<Task_Interactions_Aggregate_Bool_Exp>;
  territory_interactions?: InputMaybe<Territory_Interactions_Bool_Exp>;
  territory_interactions_aggregate?: InputMaybe<Territory_Interactions_Aggregate_Bool_Exp>;
  type?: InputMaybe<Types_Bool_Exp>;
  type_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "interactions" */
export enum Interactions_Constraint {
  /** unique or primary key constraint on columns "id" */
  InteractionsPkey = "interactions_pkey",
}

/** input type for inserting data into table "interactions" */
export type Interactions_Insert_Input = {
  case_interactions?: InputMaybe<Case_Interactions_Arr_Rel_Insert_Input>;
  contact_interactions?: InputMaybe<Contact_Interactions_Arr_Rel_Insert_Input>;
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  created_by?: InputMaybe<Scalars["uuid"]>;
  document_interactions?: InputMaybe<Document_Interactions_Arr_Rel_Insert_Input>;
  id?: InputMaybe<Scalars["uuid"]>;
  interaction_data?: InputMaybe<Interaction_Details_Arr_Rel_Insert_Input>;
  invoice_interactions?: InputMaybe<Invoice_Interactions_Arr_Rel_Insert_Input>;
  organisation_id?: InputMaybe<Scalars["uuid"]>;
  party?: InputMaybe<Parties_Obj_Rel_Insert_Input>;
  party_interactions?: InputMaybe<Party_Interactions_Arr_Rel_Insert_Input>;
  product_interactions?: InputMaybe<Product_Interactions_Arr_Rel_Insert_Input>;
  subscription_interactions?: InputMaybe<Subscription_Interactions_Arr_Rel_Insert_Input>;
  task_interactions?: InputMaybe<Task_Interactions_Arr_Rel_Insert_Input>;
  territory_interactions?: InputMaybe<Territory_Interactions_Arr_Rel_Insert_Input>;
  type?: InputMaybe<Types_Obj_Rel_Insert_Input>;
  type_id?: InputMaybe<Scalars["uuid"]>;
};

/** aggregate max on columns */
export type Interactions_Max_Fields = {
  __typename?: "interactions_max_fields";
  created_at?: Maybe<Scalars["timestamptz"]>;
  created_by?: Maybe<Scalars["uuid"]>;
  id?: Maybe<Scalars["uuid"]>;
  organisation_id?: Maybe<Scalars["uuid"]>;
  type_id?: Maybe<Scalars["uuid"]>;
};

/** order by max() on columns of table "interactions" */
export type Interactions_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  organisation_id?: InputMaybe<Order_By>;
  type_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Interactions_Min_Fields = {
  __typename?: "interactions_min_fields";
  created_at?: Maybe<Scalars["timestamptz"]>;
  created_by?: Maybe<Scalars["uuid"]>;
  id?: Maybe<Scalars["uuid"]>;
  organisation_id?: Maybe<Scalars["uuid"]>;
  type_id?: Maybe<Scalars["uuid"]>;
};

/** order by min() on columns of table "interactions" */
export type Interactions_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  organisation_id?: InputMaybe<Order_By>;
  type_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "interactions" */
export type Interactions_Mutation_Response = {
  __typename?: "interactions_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Interactions>;
};

/** input type for inserting object relation for remote table "interactions" */
export type Interactions_Obj_Rel_Insert_Input = {
  data: Interactions_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Interactions_On_Conflict>;
};

/** on_conflict condition type for table "interactions" */
export type Interactions_On_Conflict = {
  constraint: Interactions_Constraint;
  update_columns?: Array<Interactions_Update_Column>;
  where?: InputMaybe<Interactions_Bool_Exp>;
};

/** Ordering options when selecting data from "interactions". */
export type Interactions_Order_By = {
  case_interactions_aggregate?: InputMaybe<Case_Interactions_Aggregate_Order_By>;
  contact_interactions_aggregate?: InputMaybe<Contact_Interactions_Aggregate_Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  document_interactions_aggregate?: InputMaybe<Document_Interactions_Aggregate_Order_By>;
  id?: InputMaybe<Order_By>;
  interaction_data_aggregate?: InputMaybe<Interaction_Details_Aggregate_Order_By>;
  invoice_interactions_aggregate?: InputMaybe<Invoice_Interactions_Aggregate_Order_By>;
  organisation_id?: InputMaybe<Order_By>;
  party?: InputMaybe<Parties_Order_By>;
  party_interactions_aggregate?: InputMaybe<Party_Interactions_Aggregate_Order_By>;
  product_interactions_aggregate?: InputMaybe<Product_Interactions_Aggregate_Order_By>;
  subscription_interactions_aggregate?: InputMaybe<Subscription_Interactions_Aggregate_Order_By>;
  task_interactions_aggregate?: InputMaybe<Task_Interactions_Aggregate_Order_By>;
  territory_interactions_aggregate?: InputMaybe<Territory_Interactions_Aggregate_Order_By>;
  type?: InputMaybe<Types_Order_By>;
  type_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: interactions */
export type Interactions_Pk_Columns_Input = {
  id: Scalars["uuid"];
};

/** select columns of table "interactions" */
export enum Interactions_Select_Column {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  CreatedBy = "created_by",
  /** column name */
  Id = "id",
  /** column name */
  OrganisationId = "organisation_id",
  /** column name */
  TypeId = "type_id",
}

/** input type for updating data in table "interactions" */
export type Interactions_Set_Input = {
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  created_by?: InputMaybe<Scalars["uuid"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  organisation_id?: InputMaybe<Scalars["uuid"]>;
  type_id?: InputMaybe<Scalars["uuid"]>;
};

/** Streaming cursor of the table "interactions" */
export type Interactions_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Interactions_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Interactions_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  created_by?: InputMaybe<Scalars["uuid"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  organisation_id?: InputMaybe<Scalars["uuid"]>;
  type_id?: InputMaybe<Scalars["uuid"]>;
};

/** update columns of table "interactions" */
export enum Interactions_Update_Column {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  CreatedBy = "created_by",
  /** column name */
  Id = "id",
  /** column name */
  OrganisationId = "organisation_id",
  /** column name */
  TypeId = "type_id",
}

export type Interactions_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Interactions_Set_Input>;
  /** filter the rows which have to be updated */
  where: Interactions_Bool_Exp;
};

/** columns and relationships of "invoice_interactions" */
export type Invoice_Interactions = {
  __typename?: "invoice_interactions";
  created_at?: Maybe<Scalars["timestamptz"]>;
  id: Scalars["uuid"];
  /** An object relationship */
  interaction: Interactions;
  interaction_id: Scalars["uuid"];
  /** An object relationship */
  invoice: Invoices;
  invoice_id: Scalars["uuid"];
};

/** aggregated selection of "invoice_interactions" */
export type Invoice_Interactions_Aggregate = {
  __typename?: "invoice_interactions_aggregate";
  aggregate?: Maybe<Invoice_Interactions_Aggregate_Fields>;
  nodes: Array<Invoice_Interactions>;
};

export type Invoice_Interactions_Aggregate_Bool_Exp = {
  count?: InputMaybe<Invoice_Interactions_Aggregate_Bool_Exp_Count>;
};

export type Invoice_Interactions_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Invoice_Interactions_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<Invoice_Interactions_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "invoice_interactions" */
export type Invoice_Interactions_Aggregate_Fields = {
  __typename?: "invoice_interactions_aggregate_fields";
  count: Scalars["Int"];
  max?: Maybe<Invoice_Interactions_Max_Fields>;
  min?: Maybe<Invoice_Interactions_Min_Fields>;
};

/** aggregate fields of "invoice_interactions" */
export type Invoice_Interactions_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Invoice_Interactions_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "invoice_interactions" */
export type Invoice_Interactions_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Invoice_Interactions_Max_Order_By>;
  min?: InputMaybe<Invoice_Interactions_Min_Order_By>;
};

/** input type for inserting array relation for remote table "invoice_interactions" */
export type Invoice_Interactions_Arr_Rel_Insert_Input = {
  data: Array<Invoice_Interactions_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Invoice_Interactions_On_Conflict>;
};

/** Boolean expression to filter rows from the table "invoice_interactions". All fields are combined with a logical 'AND'. */
export type Invoice_Interactions_Bool_Exp = {
  _and?: InputMaybe<Array<Invoice_Interactions_Bool_Exp>>;
  _not?: InputMaybe<Invoice_Interactions_Bool_Exp>;
  _or?: InputMaybe<Array<Invoice_Interactions_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  interaction?: InputMaybe<Interactions_Bool_Exp>;
  interaction_id?: InputMaybe<Uuid_Comparison_Exp>;
  invoice?: InputMaybe<Invoices_Bool_Exp>;
  invoice_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "invoice_interactions" */
export enum Invoice_Interactions_Constraint {
  /** unique or primary key constraint on columns "id" */
  InvoiceInteractionsPkey = "invoice_interactions_pkey",
}

/** input type for inserting data into table "invoice_interactions" */
export type Invoice_Interactions_Insert_Input = {
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  interaction?: InputMaybe<Interactions_Obj_Rel_Insert_Input>;
  interaction_id?: InputMaybe<Scalars["uuid"]>;
  invoice?: InputMaybe<Invoices_Obj_Rel_Insert_Input>;
  invoice_id?: InputMaybe<Scalars["uuid"]>;
};

/** aggregate max on columns */
export type Invoice_Interactions_Max_Fields = {
  __typename?: "invoice_interactions_max_fields";
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["uuid"]>;
  interaction_id?: Maybe<Scalars["uuid"]>;
  invoice_id?: Maybe<Scalars["uuid"]>;
};

/** order by max() on columns of table "invoice_interactions" */
export type Invoice_Interactions_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  interaction_id?: InputMaybe<Order_By>;
  invoice_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Invoice_Interactions_Min_Fields = {
  __typename?: "invoice_interactions_min_fields";
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["uuid"]>;
  interaction_id?: Maybe<Scalars["uuid"]>;
  invoice_id?: Maybe<Scalars["uuid"]>;
};

/** order by min() on columns of table "invoice_interactions" */
export type Invoice_Interactions_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  interaction_id?: InputMaybe<Order_By>;
  invoice_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "invoice_interactions" */
export type Invoice_Interactions_Mutation_Response = {
  __typename?: "invoice_interactions_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Invoice_Interactions>;
};

/** on_conflict condition type for table "invoice_interactions" */
export type Invoice_Interactions_On_Conflict = {
  constraint: Invoice_Interactions_Constraint;
  update_columns?: Array<Invoice_Interactions_Update_Column>;
  where?: InputMaybe<Invoice_Interactions_Bool_Exp>;
};

/** Ordering options when selecting data from "invoice_interactions". */
export type Invoice_Interactions_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  interaction?: InputMaybe<Interactions_Order_By>;
  interaction_id?: InputMaybe<Order_By>;
  invoice?: InputMaybe<Invoices_Order_By>;
  invoice_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: invoice_interactions */
export type Invoice_Interactions_Pk_Columns_Input = {
  id: Scalars["uuid"];
};

/** select columns of table "invoice_interactions" */
export enum Invoice_Interactions_Select_Column {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  Id = "id",
  /** column name */
  InteractionId = "interaction_id",
  /** column name */
  InvoiceId = "invoice_id",
}

/** input type for updating data in table "invoice_interactions" */
export type Invoice_Interactions_Set_Input = {
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  interaction_id?: InputMaybe<Scalars["uuid"]>;
  invoice_id?: InputMaybe<Scalars["uuid"]>;
};

/** Streaming cursor of the table "invoice_interactions" */
export type Invoice_Interactions_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Invoice_Interactions_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Invoice_Interactions_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  interaction_id?: InputMaybe<Scalars["uuid"]>;
  invoice_id?: InputMaybe<Scalars["uuid"]>;
};

/** update columns of table "invoice_interactions" */
export enum Invoice_Interactions_Update_Column {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  Id = "id",
  /** column name */
  InteractionId = "interaction_id",
  /** column name */
  InvoiceId = "invoice_id",
}

export type Invoice_Interactions_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Invoice_Interactions_Set_Input>;
  /** filter the rows which have to be updated */
  where: Invoice_Interactions_Bool_Exp;
};

/** columns and relationships of "invoice_items" */
export type Invoice_Items = {
  __typename?: "invoice_items";
  amount: Scalars["float8"];
  created_at?: Maybe<Scalars["timestamptz"]>;
  id: Scalars["uuid"];
  /** An object relationship */
  invoice: Invoices;
  invoice_id: Scalars["uuid"];
  /** An object relationship */
  product: Products;
  product_id: Scalars["uuid"];
  /** An object relationship */
  product_item: Product_Items;
  product_item_id: Scalars["uuid"];
  quantity: Scalars["float8"];
  /** An object relationship */
  subscription: Subscriptions;
  subscription_id: Scalars["uuid"];
  /** An object relationship */
  subscription_item: Subscription_Items;
  subscription_item_id: Scalars["uuid"];
  /** An array relationship */
  subscription_payment_transactions: Array<Subscription_Payment_Transactions>;
  /** An aggregate relationship */
  subscription_payment_transactions_aggregate: Subscription_Payment_Transactions_Aggregate;
};

/** columns and relationships of "invoice_items" */
export type Invoice_ItemsSubscription_Payment_TransactionsArgs = {
  distinct_on?: InputMaybe<
    Array<Subscription_Payment_Transactions_Select_Column>
  >;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Subscription_Payment_Transactions_Order_By>>;
  where?: InputMaybe<Subscription_Payment_Transactions_Bool_Exp>;
};

/** columns and relationships of "invoice_items" */
export type Invoice_ItemsSubscription_Payment_Transactions_AggregateArgs = {
  distinct_on?: InputMaybe<
    Array<Subscription_Payment_Transactions_Select_Column>
  >;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Subscription_Payment_Transactions_Order_By>>;
  where?: InputMaybe<Subscription_Payment_Transactions_Bool_Exp>;
};

/** aggregated selection of "invoice_items" */
export type Invoice_Items_Aggregate = {
  __typename?: "invoice_items_aggregate";
  aggregate?: Maybe<Invoice_Items_Aggregate_Fields>;
  nodes: Array<Invoice_Items>;
};

export type Invoice_Items_Aggregate_Bool_Exp = {
  avg?: InputMaybe<Invoice_Items_Aggregate_Bool_Exp_Avg>;
  corr?: InputMaybe<Invoice_Items_Aggregate_Bool_Exp_Corr>;
  count?: InputMaybe<Invoice_Items_Aggregate_Bool_Exp_Count>;
  covar_samp?: InputMaybe<Invoice_Items_Aggregate_Bool_Exp_Covar_Samp>;
  max?: InputMaybe<Invoice_Items_Aggregate_Bool_Exp_Max>;
  min?: InputMaybe<Invoice_Items_Aggregate_Bool_Exp_Min>;
  stddev_samp?: InputMaybe<Invoice_Items_Aggregate_Bool_Exp_Stddev_Samp>;
  sum?: InputMaybe<Invoice_Items_Aggregate_Bool_Exp_Sum>;
  var_samp?: InputMaybe<Invoice_Items_Aggregate_Bool_Exp_Var_Samp>;
};

export type Invoice_Items_Aggregate_Bool_Exp_Avg = {
  arguments: Invoice_Items_Select_Column_Invoice_Items_Aggregate_Bool_Exp_Avg_Arguments_Columns;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<Invoice_Items_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Invoice_Items_Aggregate_Bool_Exp_Corr = {
  arguments: Invoice_Items_Aggregate_Bool_Exp_Corr_Arguments;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<Invoice_Items_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Invoice_Items_Aggregate_Bool_Exp_Corr_Arguments = {
  X: Invoice_Items_Select_Column_Invoice_Items_Aggregate_Bool_Exp_Corr_Arguments_Columns;
  Y: Invoice_Items_Select_Column_Invoice_Items_Aggregate_Bool_Exp_Corr_Arguments_Columns;
};

export type Invoice_Items_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Invoice_Items_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<Invoice_Items_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

export type Invoice_Items_Aggregate_Bool_Exp_Covar_Samp = {
  arguments: Invoice_Items_Aggregate_Bool_Exp_Covar_Samp_Arguments;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<Invoice_Items_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Invoice_Items_Aggregate_Bool_Exp_Covar_Samp_Arguments = {
  X: Invoice_Items_Select_Column_Invoice_Items_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns;
  Y: Invoice_Items_Select_Column_Invoice_Items_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns;
};

export type Invoice_Items_Aggregate_Bool_Exp_Max = {
  arguments: Invoice_Items_Select_Column_Invoice_Items_Aggregate_Bool_Exp_Max_Arguments_Columns;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<Invoice_Items_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Invoice_Items_Aggregate_Bool_Exp_Min = {
  arguments: Invoice_Items_Select_Column_Invoice_Items_Aggregate_Bool_Exp_Min_Arguments_Columns;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<Invoice_Items_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Invoice_Items_Aggregate_Bool_Exp_Stddev_Samp = {
  arguments: Invoice_Items_Select_Column_Invoice_Items_Aggregate_Bool_Exp_Stddev_Samp_Arguments_Columns;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<Invoice_Items_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Invoice_Items_Aggregate_Bool_Exp_Sum = {
  arguments: Invoice_Items_Select_Column_Invoice_Items_Aggregate_Bool_Exp_Sum_Arguments_Columns;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<Invoice_Items_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Invoice_Items_Aggregate_Bool_Exp_Var_Samp = {
  arguments: Invoice_Items_Select_Column_Invoice_Items_Aggregate_Bool_Exp_Var_Samp_Arguments_Columns;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<Invoice_Items_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

/** aggregate fields of "invoice_items" */
export type Invoice_Items_Aggregate_Fields = {
  __typename?: "invoice_items_aggregate_fields";
  avg?: Maybe<Invoice_Items_Avg_Fields>;
  count: Scalars["Int"];
  max?: Maybe<Invoice_Items_Max_Fields>;
  min?: Maybe<Invoice_Items_Min_Fields>;
  stddev?: Maybe<Invoice_Items_Stddev_Fields>;
  stddev_pop?: Maybe<Invoice_Items_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Invoice_Items_Stddev_Samp_Fields>;
  sum?: Maybe<Invoice_Items_Sum_Fields>;
  var_pop?: Maybe<Invoice_Items_Var_Pop_Fields>;
  var_samp?: Maybe<Invoice_Items_Var_Samp_Fields>;
  variance?: Maybe<Invoice_Items_Variance_Fields>;
};

/** aggregate fields of "invoice_items" */
export type Invoice_Items_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Invoice_Items_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "invoice_items" */
export type Invoice_Items_Aggregate_Order_By = {
  avg?: InputMaybe<Invoice_Items_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Invoice_Items_Max_Order_By>;
  min?: InputMaybe<Invoice_Items_Min_Order_By>;
  stddev?: InputMaybe<Invoice_Items_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Invoice_Items_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Invoice_Items_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Invoice_Items_Sum_Order_By>;
  var_pop?: InputMaybe<Invoice_Items_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Invoice_Items_Var_Samp_Order_By>;
  variance?: InputMaybe<Invoice_Items_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "invoice_items" */
export type Invoice_Items_Arr_Rel_Insert_Input = {
  data: Array<Invoice_Items_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Invoice_Items_On_Conflict>;
};

/** aggregate avg on columns */
export type Invoice_Items_Avg_Fields = {
  __typename?: "invoice_items_avg_fields";
  amount?: Maybe<Scalars["Float"]>;
  quantity?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "invoice_items" */
export type Invoice_Items_Avg_Order_By = {
  amount?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "invoice_items". All fields are combined with a logical 'AND'. */
export type Invoice_Items_Bool_Exp = {
  _and?: InputMaybe<Array<Invoice_Items_Bool_Exp>>;
  _not?: InputMaybe<Invoice_Items_Bool_Exp>;
  _or?: InputMaybe<Array<Invoice_Items_Bool_Exp>>;
  amount?: InputMaybe<Float8_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  invoice?: InputMaybe<Invoices_Bool_Exp>;
  invoice_id?: InputMaybe<Uuid_Comparison_Exp>;
  product?: InputMaybe<Products_Bool_Exp>;
  product_id?: InputMaybe<Uuid_Comparison_Exp>;
  product_item?: InputMaybe<Product_Items_Bool_Exp>;
  product_item_id?: InputMaybe<Uuid_Comparison_Exp>;
  quantity?: InputMaybe<Float8_Comparison_Exp>;
  subscription?: InputMaybe<Subscriptions_Bool_Exp>;
  subscription_id?: InputMaybe<Uuid_Comparison_Exp>;
  subscription_item?: InputMaybe<Subscription_Items_Bool_Exp>;
  subscription_item_id?: InputMaybe<Uuid_Comparison_Exp>;
  subscription_payment_transactions?: InputMaybe<Subscription_Payment_Transactions_Bool_Exp>;
  subscription_payment_transactions_aggregate?: InputMaybe<Subscription_Payment_Transactions_Aggregate_Bool_Exp>;
};

/** unique or primary key constraints on table "invoice_items" */
export enum Invoice_Items_Constraint {
  /** unique or primary key constraint on columns "subscription_id", "subscription_item_id", "invoice_id" */
  InvoiceItemsInvoiceIdSubscriptionIdSubscriptionItemIdK = "invoice_items_invoice_id_subscription_id_subscription_item_id_k",
  /** unique or primary key constraint on columns "id" */
  InvoiceItemsPkey = "invoice_items_pkey",
}

/** input type for incrementing numeric columns in table "invoice_items" */
export type Invoice_Items_Inc_Input = {
  amount?: InputMaybe<Scalars["float8"]>;
  quantity?: InputMaybe<Scalars["float8"]>;
};

/** input type for inserting data into table "invoice_items" */
export type Invoice_Items_Insert_Input = {
  amount?: InputMaybe<Scalars["float8"]>;
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  invoice?: InputMaybe<Invoices_Obj_Rel_Insert_Input>;
  invoice_id?: InputMaybe<Scalars["uuid"]>;
  product?: InputMaybe<Products_Obj_Rel_Insert_Input>;
  product_id?: InputMaybe<Scalars["uuid"]>;
  product_item?: InputMaybe<Product_Items_Obj_Rel_Insert_Input>;
  product_item_id?: InputMaybe<Scalars["uuid"]>;
  quantity?: InputMaybe<Scalars["float8"]>;
  subscription?: InputMaybe<Subscriptions_Obj_Rel_Insert_Input>;
  subscription_id?: InputMaybe<Scalars["uuid"]>;
  subscription_item?: InputMaybe<Subscription_Items_Obj_Rel_Insert_Input>;
  subscription_item_id?: InputMaybe<Scalars["uuid"]>;
  subscription_payment_transactions?: InputMaybe<Subscription_Payment_Transactions_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Invoice_Items_Max_Fields = {
  __typename?: "invoice_items_max_fields";
  amount?: Maybe<Scalars["float8"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["uuid"]>;
  invoice_id?: Maybe<Scalars["uuid"]>;
  product_id?: Maybe<Scalars["uuid"]>;
  product_item_id?: Maybe<Scalars["uuid"]>;
  quantity?: Maybe<Scalars["float8"]>;
  subscription_id?: Maybe<Scalars["uuid"]>;
  subscription_item_id?: Maybe<Scalars["uuid"]>;
};

/** order by max() on columns of table "invoice_items" */
export type Invoice_Items_Max_Order_By = {
  amount?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  invoice_id?: InputMaybe<Order_By>;
  product_id?: InputMaybe<Order_By>;
  product_item_id?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  subscription_id?: InputMaybe<Order_By>;
  subscription_item_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Invoice_Items_Min_Fields = {
  __typename?: "invoice_items_min_fields";
  amount?: Maybe<Scalars["float8"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["uuid"]>;
  invoice_id?: Maybe<Scalars["uuid"]>;
  product_id?: Maybe<Scalars["uuid"]>;
  product_item_id?: Maybe<Scalars["uuid"]>;
  quantity?: Maybe<Scalars["float8"]>;
  subscription_id?: Maybe<Scalars["uuid"]>;
  subscription_item_id?: Maybe<Scalars["uuid"]>;
};

/** order by min() on columns of table "invoice_items" */
export type Invoice_Items_Min_Order_By = {
  amount?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  invoice_id?: InputMaybe<Order_By>;
  product_id?: InputMaybe<Order_By>;
  product_item_id?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  subscription_id?: InputMaybe<Order_By>;
  subscription_item_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "invoice_items" */
export type Invoice_Items_Mutation_Response = {
  __typename?: "invoice_items_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Invoice_Items>;
};

/** input type for inserting object relation for remote table "invoice_items" */
export type Invoice_Items_Obj_Rel_Insert_Input = {
  data: Invoice_Items_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Invoice_Items_On_Conflict>;
};

/** on_conflict condition type for table "invoice_items" */
export type Invoice_Items_On_Conflict = {
  constraint: Invoice_Items_Constraint;
  update_columns?: Array<Invoice_Items_Update_Column>;
  where?: InputMaybe<Invoice_Items_Bool_Exp>;
};

/** Ordering options when selecting data from "invoice_items". */
export type Invoice_Items_Order_By = {
  amount?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  invoice?: InputMaybe<Invoices_Order_By>;
  invoice_id?: InputMaybe<Order_By>;
  product?: InputMaybe<Products_Order_By>;
  product_id?: InputMaybe<Order_By>;
  product_item?: InputMaybe<Product_Items_Order_By>;
  product_item_id?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  subscription?: InputMaybe<Subscriptions_Order_By>;
  subscription_id?: InputMaybe<Order_By>;
  subscription_item?: InputMaybe<Subscription_Items_Order_By>;
  subscription_item_id?: InputMaybe<Order_By>;
  subscription_payment_transactions_aggregate?: InputMaybe<Subscription_Payment_Transactions_Aggregate_Order_By>;
};

/** primary key columns input for table: invoice_items */
export type Invoice_Items_Pk_Columns_Input = {
  id: Scalars["uuid"];
};

/** select columns of table "invoice_items" */
export enum Invoice_Items_Select_Column {
  /** column name */
  Amount = "amount",
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  Id = "id",
  /** column name */
  InvoiceId = "invoice_id",
  /** column name */
  ProductId = "product_id",
  /** column name */
  ProductItemId = "product_item_id",
  /** column name */
  Quantity = "quantity",
  /** column name */
  SubscriptionId = "subscription_id",
  /** column name */
  SubscriptionItemId = "subscription_item_id",
}

/** select "invoice_items_aggregate_bool_exp_avg_arguments_columns" columns of table "invoice_items" */
export enum Invoice_Items_Select_Column_Invoice_Items_Aggregate_Bool_Exp_Avg_Arguments_Columns {
  /** column name */
  Amount = "amount",
  /** column name */
  Quantity = "quantity",
}

/** select "invoice_items_aggregate_bool_exp_corr_arguments_columns" columns of table "invoice_items" */
export enum Invoice_Items_Select_Column_Invoice_Items_Aggregate_Bool_Exp_Corr_Arguments_Columns {
  /** column name */
  Amount = "amount",
  /** column name */
  Quantity = "quantity",
}

/** select "invoice_items_aggregate_bool_exp_covar_samp_arguments_columns" columns of table "invoice_items" */
export enum Invoice_Items_Select_Column_Invoice_Items_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns {
  /** column name */
  Amount = "amount",
  /** column name */
  Quantity = "quantity",
}

/** select "invoice_items_aggregate_bool_exp_max_arguments_columns" columns of table "invoice_items" */
export enum Invoice_Items_Select_Column_Invoice_Items_Aggregate_Bool_Exp_Max_Arguments_Columns {
  /** column name */
  Amount = "amount",
  /** column name */
  Quantity = "quantity",
}

/** select "invoice_items_aggregate_bool_exp_min_arguments_columns" columns of table "invoice_items" */
export enum Invoice_Items_Select_Column_Invoice_Items_Aggregate_Bool_Exp_Min_Arguments_Columns {
  /** column name */
  Amount = "amount",
  /** column name */
  Quantity = "quantity",
}

/** select "invoice_items_aggregate_bool_exp_stddev_samp_arguments_columns" columns of table "invoice_items" */
export enum Invoice_Items_Select_Column_Invoice_Items_Aggregate_Bool_Exp_Stddev_Samp_Arguments_Columns {
  /** column name */
  Amount = "amount",
  /** column name */
  Quantity = "quantity",
}

/** select "invoice_items_aggregate_bool_exp_sum_arguments_columns" columns of table "invoice_items" */
export enum Invoice_Items_Select_Column_Invoice_Items_Aggregate_Bool_Exp_Sum_Arguments_Columns {
  /** column name */
  Amount = "amount",
  /** column name */
  Quantity = "quantity",
}

/** select "invoice_items_aggregate_bool_exp_var_samp_arguments_columns" columns of table "invoice_items" */
export enum Invoice_Items_Select_Column_Invoice_Items_Aggregate_Bool_Exp_Var_Samp_Arguments_Columns {
  /** column name */
  Amount = "amount",
  /** column name */
  Quantity = "quantity",
}

/** input type for updating data in table "invoice_items" */
export type Invoice_Items_Set_Input = {
  amount?: InputMaybe<Scalars["float8"]>;
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  invoice_id?: InputMaybe<Scalars["uuid"]>;
  product_id?: InputMaybe<Scalars["uuid"]>;
  product_item_id?: InputMaybe<Scalars["uuid"]>;
  quantity?: InputMaybe<Scalars["float8"]>;
  subscription_id?: InputMaybe<Scalars["uuid"]>;
  subscription_item_id?: InputMaybe<Scalars["uuid"]>;
};

/** aggregate stddev on columns */
export type Invoice_Items_Stddev_Fields = {
  __typename?: "invoice_items_stddev_fields";
  amount?: Maybe<Scalars["Float"]>;
  quantity?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "invoice_items" */
export type Invoice_Items_Stddev_Order_By = {
  amount?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Invoice_Items_Stddev_Pop_Fields = {
  __typename?: "invoice_items_stddev_pop_fields";
  amount?: Maybe<Scalars["Float"]>;
  quantity?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "invoice_items" */
export type Invoice_Items_Stddev_Pop_Order_By = {
  amount?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Invoice_Items_Stddev_Samp_Fields = {
  __typename?: "invoice_items_stddev_samp_fields";
  amount?: Maybe<Scalars["Float"]>;
  quantity?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "invoice_items" */
export type Invoice_Items_Stddev_Samp_Order_By = {
  amount?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "invoice_items" */
export type Invoice_Items_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Invoice_Items_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Invoice_Items_Stream_Cursor_Value_Input = {
  amount?: InputMaybe<Scalars["float8"]>;
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  invoice_id?: InputMaybe<Scalars["uuid"]>;
  product_id?: InputMaybe<Scalars["uuid"]>;
  product_item_id?: InputMaybe<Scalars["uuid"]>;
  quantity?: InputMaybe<Scalars["float8"]>;
  subscription_id?: InputMaybe<Scalars["uuid"]>;
  subscription_item_id?: InputMaybe<Scalars["uuid"]>;
};

/** aggregate sum on columns */
export type Invoice_Items_Sum_Fields = {
  __typename?: "invoice_items_sum_fields";
  amount?: Maybe<Scalars["float8"]>;
  quantity?: Maybe<Scalars["float8"]>;
};

/** order by sum() on columns of table "invoice_items" */
export type Invoice_Items_Sum_Order_By = {
  amount?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
};

/** update columns of table "invoice_items" */
export enum Invoice_Items_Update_Column {
  /** column name */
  Amount = "amount",
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  Id = "id",
  /** column name */
  InvoiceId = "invoice_id",
  /** column name */
  ProductId = "product_id",
  /** column name */
  ProductItemId = "product_item_id",
  /** column name */
  Quantity = "quantity",
  /** column name */
  SubscriptionId = "subscription_id",
  /** column name */
  SubscriptionItemId = "subscription_item_id",
}

export type Invoice_Items_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Invoice_Items_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Invoice_Items_Set_Input>;
  /** filter the rows which have to be updated */
  where: Invoice_Items_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Invoice_Items_Var_Pop_Fields = {
  __typename?: "invoice_items_var_pop_fields";
  amount?: Maybe<Scalars["Float"]>;
  quantity?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "invoice_items" */
export type Invoice_Items_Var_Pop_Order_By = {
  amount?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Invoice_Items_Var_Samp_Fields = {
  __typename?: "invoice_items_var_samp_fields";
  amount?: Maybe<Scalars["Float"]>;
  quantity?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "invoice_items" */
export type Invoice_Items_Var_Samp_Order_By = {
  amount?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Invoice_Items_Variance_Fields = {
  __typename?: "invoice_items_variance_fields";
  amount?: Maybe<Scalars["Float"]>;
  quantity?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "invoice_items" */
export type Invoice_Items_Variance_Order_By = {
  amount?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
};

/** columns and relationships of "invoices" */
export type Invoices = {
  __typename?: "invoices";
  amount_due: Scalars["float8"];
  created_at: Scalars["timestamptz"];
  due_at: Scalars["timestamptz"];
  id: Scalars["uuid"];
  /** An array relationship */
  invoice_interactions: Array<Invoice_Interactions>;
  /** An aggregate relationship */
  invoice_interactions_aggregate: Invoice_Interactions_Aggregate;
  /** An array relationship */
  invoice_items: Array<Invoice_Items>;
  /** An aggregate relationship */
  invoice_items_aggregate: Invoice_Items_Aggregate;
  metadata?: Maybe<Scalars["jsonb"]>;
  /** An object relationship */
  organisation: Organisations;
  organisation_id: Scalars["uuid"];
  /** An object relationship */
  party: Parties;
  party_id: Scalars["uuid"];
  /** An object relationship */
  product: Products;
  product_id: Scalars["uuid"];
  /** An object relationship */
  status: Statuses;
  status_id: Scalars["uuid"];
  /** An object relationship */
  subscription: Subscriptions;
  subscription_id: Scalars["uuid"];
  /** An array relationship */
  subscription_payment_transactions: Array<Subscription_Payment_Transactions>;
  /** An aggregate relationship */
  subscription_payment_transactions_aggregate: Subscription_Payment_Transactions_Aggregate;
  /** An array relationship */
  subscription_payments: Array<Subscription_Payments>;
  /** An aggregate relationship */
  subscription_payments_aggregate: Subscription_Payments_Aggregate;
  /** An object relationship */
  type: Types;
  type_id: Scalars["uuid"];
  updated_at: Scalars["timestamptz"];
};

/** columns and relationships of "invoices" */
export type InvoicesInvoice_InteractionsArgs = {
  distinct_on?: InputMaybe<Array<Invoice_Interactions_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Invoice_Interactions_Order_By>>;
  where?: InputMaybe<Invoice_Interactions_Bool_Exp>;
};

/** columns and relationships of "invoices" */
export type InvoicesInvoice_Interactions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Invoice_Interactions_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Invoice_Interactions_Order_By>>;
  where?: InputMaybe<Invoice_Interactions_Bool_Exp>;
};

/** columns and relationships of "invoices" */
export type InvoicesInvoice_ItemsArgs = {
  distinct_on?: InputMaybe<Array<Invoice_Items_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Invoice_Items_Order_By>>;
  where?: InputMaybe<Invoice_Items_Bool_Exp>;
};

/** columns and relationships of "invoices" */
export type InvoicesInvoice_Items_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Invoice_Items_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Invoice_Items_Order_By>>;
  where?: InputMaybe<Invoice_Items_Bool_Exp>;
};

/** columns and relationships of "invoices" */
export type InvoicesMetadataArgs = {
  path?: InputMaybe<Scalars["String"]>;
};

/** columns and relationships of "invoices" */
export type InvoicesSubscription_Payment_TransactionsArgs = {
  distinct_on?: InputMaybe<
    Array<Subscription_Payment_Transactions_Select_Column>
  >;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Subscription_Payment_Transactions_Order_By>>;
  where?: InputMaybe<Subscription_Payment_Transactions_Bool_Exp>;
};

/** columns and relationships of "invoices" */
export type InvoicesSubscription_Payment_Transactions_AggregateArgs = {
  distinct_on?: InputMaybe<
    Array<Subscription_Payment_Transactions_Select_Column>
  >;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Subscription_Payment_Transactions_Order_By>>;
  where?: InputMaybe<Subscription_Payment_Transactions_Bool_Exp>;
};

/** columns and relationships of "invoices" */
export type InvoicesSubscription_PaymentsArgs = {
  distinct_on?: InputMaybe<Array<Subscription_Payments_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Subscription_Payments_Order_By>>;
  where?: InputMaybe<Subscription_Payments_Bool_Exp>;
};

/** columns and relationships of "invoices" */
export type InvoicesSubscription_Payments_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Subscription_Payments_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Subscription_Payments_Order_By>>;
  where?: InputMaybe<Subscription_Payments_Bool_Exp>;
};

/** aggregated selection of "invoices" */
export type Invoices_Aggregate = {
  __typename?: "invoices_aggregate";
  aggregate?: Maybe<Invoices_Aggregate_Fields>;
  nodes: Array<Invoices>;
};

export type Invoices_Aggregate_Bool_Exp = {
  avg?: InputMaybe<Invoices_Aggregate_Bool_Exp_Avg>;
  corr?: InputMaybe<Invoices_Aggregate_Bool_Exp_Corr>;
  count?: InputMaybe<Invoices_Aggregate_Bool_Exp_Count>;
  covar_samp?: InputMaybe<Invoices_Aggregate_Bool_Exp_Covar_Samp>;
  max?: InputMaybe<Invoices_Aggregate_Bool_Exp_Max>;
  min?: InputMaybe<Invoices_Aggregate_Bool_Exp_Min>;
  stddev_samp?: InputMaybe<Invoices_Aggregate_Bool_Exp_Stddev_Samp>;
  sum?: InputMaybe<Invoices_Aggregate_Bool_Exp_Sum>;
  var_samp?: InputMaybe<Invoices_Aggregate_Bool_Exp_Var_Samp>;
};

export type Invoices_Aggregate_Bool_Exp_Avg = {
  arguments: Invoices_Select_Column_Invoices_Aggregate_Bool_Exp_Avg_Arguments_Columns;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<Invoices_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Invoices_Aggregate_Bool_Exp_Corr = {
  arguments: Invoices_Aggregate_Bool_Exp_Corr_Arguments;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<Invoices_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Invoices_Aggregate_Bool_Exp_Corr_Arguments = {
  X: Invoices_Select_Column_Invoices_Aggregate_Bool_Exp_Corr_Arguments_Columns;
  Y: Invoices_Select_Column_Invoices_Aggregate_Bool_Exp_Corr_Arguments_Columns;
};

export type Invoices_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Invoices_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<Invoices_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

export type Invoices_Aggregate_Bool_Exp_Covar_Samp = {
  arguments: Invoices_Aggregate_Bool_Exp_Covar_Samp_Arguments;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<Invoices_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Invoices_Aggregate_Bool_Exp_Covar_Samp_Arguments = {
  X: Invoices_Select_Column_Invoices_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns;
  Y: Invoices_Select_Column_Invoices_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns;
};

export type Invoices_Aggregate_Bool_Exp_Max = {
  arguments: Invoices_Select_Column_Invoices_Aggregate_Bool_Exp_Max_Arguments_Columns;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<Invoices_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Invoices_Aggregate_Bool_Exp_Min = {
  arguments: Invoices_Select_Column_Invoices_Aggregate_Bool_Exp_Min_Arguments_Columns;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<Invoices_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Invoices_Aggregate_Bool_Exp_Stddev_Samp = {
  arguments: Invoices_Select_Column_Invoices_Aggregate_Bool_Exp_Stddev_Samp_Arguments_Columns;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<Invoices_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Invoices_Aggregate_Bool_Exp_Sum = {
  arguments: Invoices_Select_Column_Invoices_Aggregate_Bool_Exp_Sum_Arguments_Columns;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<Invoices_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Invoices_Aggregate_Bool_Exp_Var_Samp = {
  arguments: Invoices_Select_Column_Invoices_Aggregate_Bool_Exp_Var_Samp_Arguments_Columns;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<Invoices_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

/** aggregate fields of "invoices" */
export type Invoices_Aggregate_Fields = {
  __typename?: "invoices_aggregate_fields";
  avg?: Maybe<Invoices_Avg_Fields>;
  count: Scalars["Int"];
  max?: Maybe<Invoices_Max_Fields>;
  min?: Maybe<Invoices_Min_Fields>;
  stddev?: Maybe<Invoices_Stddev_Fields>;
  stddev_pop?: Maybe<Invoices_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Invoices_Stddev_Samp_Fields>;
  sum?: Maybe<Invoices_Sum_Fields>;
  var_pop?: Maybe<Invoices_Var_Pop_Fields>;
  var_samp?: Maybe<Invoices_Var_Samp_Fields>;
  variance?: Maybe<Invoices_Variance_Fields>;
};

/** aggregate fields of "invoices" */
export type Invoices_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Invoices_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "invoices" */
export type Invoices_Aggregate_Order_By = {
  avg?: InputMaybe<Invoices_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Invoices_Max_Order_By>;
  min?: InputMaybe<Invoices_Min_Order_By>;
  stddev?: InputMaybe<Invoices_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Invoices_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Invoices_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Invoices_Sum_Order_By>;
  var_pop?: InputMaybe<Invoices_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Invoices_Var_Samp_Order_By>;
  variance?: InputMaybe<Invoices_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Invoices_Append_Input = {
  metadata?: InputMaybe<Scalars["jsonb"]>;
};

/** input type for inserting array relation for remote table "invoices" */
export type Invoices_Arr_Rel_Insert_Input = {
  data: Array<Invoices_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Invoices_On_Conflict>;
};

/** aggregate avg on columns */
export type Invoices_Avg_Fields = {
  __typename?: "invoices_avg_fields";
  amount_due?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "invoices" */
export type Invoices_Avg_Order_By = {
  amount_due?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "invoices". All fields are combined with a logical 'AND'. */
export type Invoices_Bool_Exp = {
  _and?: InputMaybe<Array<Invoices_Bool_Exp>>;
  _not?: InputMaybe<Invoices_Bool_Exp>;
  _or?: InputMaybe<Array<Invoices_Bool_Exp>>;
  amount_due?: InputMaybe<Float8_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  due_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  invoice_interactions?: InputMaybe<Invoice_Interactions_Bool_Exp>;
  invoice_interactions_aggregate?: InputMaybe<Invoice_Interactions_Aggregate_Bool_Exp>;
  invoice_items?: InputMaybe<Invoice_Items_Bool_Exp>;
  invoice_items_aggregate?: InputMaybe<Invoice_Items_Aggregate_Bool_Exp>;
  metadata?: InputMaybe<Jsonb_Comparison_Exp>;
  organisation?: InputMaybe<Organisations_Bool_Exp>;
  organisation_id?: InputMaybe<Uuid_Comparison_Exp>;
  party?: InputMaybe<Parties_Bool_Exp>;
  party_id?: InputMaybe<Uuid_Comparison_Exp>;
  product?: InputMaybe<Products_Bool_Exp>;
  product_id?: InputMaybe<Uuid_Comparison_Exp>;
  status?: InputMaybe<Statuses_Bool_Exp>;
  status_id?: InputMaybe<Uuid_Comparison_Exp>;
  subscription?: InputMaybe<Subscriptions_Bool_Exp>;
  subscription_id?: InputMaybe<Uuid_Comparison_Exp>;
  subscription_payment_transactions?: InputMaybe<Subscription_Payment_Transactions_Bool_Exp>;
  subscription_payment_transactions_aggregate?: InputMaybe<Subscription_Payment_Transactions_Aggregate_Bool_Exp>;
  subscription_payments?: InputMaybe<Subscription_Payments_Bool_Exp>;
  subscription_payments_aggregate?: InputMaybe<Subscription_Payments_Aggregate_Bool_Exp>;
  type?: InputMaybe<Types_Bool_Exp>;
  type_id?: InputMaybe<Uuid_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "invoices" */
export enum Invoices_Constraint {
  /** unique or primary key constraint on columns "id" */
  InvoicesPkey = "invoices_pkey",
  /** unique or primary key constraint on columns "subscription_id", "due_at" */
  InvoicesSubscriptionIdDueAtKey = "invoices_subscription_id_due_at_key",
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Invoices_Delete_At_Path_Input = {
  metadata?: InputMaybe<Array<Scalars["String"]>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Invoices_Delete_Elem_Input = {
  metadata?: InputMaybe<Scalars["Int"]>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Invoices_Delete_Key_Input = {
  metadata?: InputMaybe<Scalars["String"]>;
};

/** input type for incrementing numeric columns in table "invoices" */
export type Invoices_Inc_Input = {
  amount_due?: InputMaybe<Scalars["float8"]>;
};

/** input type for inserting data into table "invoices" */
export type Invoices_Insert_Input = {
  amount_due?: InputMaybe<Scalars["float8"]>;
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  due_at?: InputMaybe<Scalars["timestamptz"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  invoice_interactions?: InputMaybe<Invoice_Interactions_Arr_Rel_Insert_Input>;
  invoice_items?: InputMaybe<Invoice_Items_Arr_Rel_Insert_Input>;
  metadata?: InputMaybe<Scalars["jsonb"]>;
  organisation?: InputMaybe<Organisations_Obj_Rel_Insert_Input>;
  organisation_id?: InputMaybe<Scalars["uuid"]>;
  party?: InputMaybe<Parties_Obj_Rel_Insert_Input>;
  party_id?: InputMaybe<Scalars["uuid"]>;
  product?: InputMaybe<Products_Obj_Rel_Insert_Input>;
  product_id?: InputMaybe<Scalars["uuid"]>;
  status?: InputMaybe<Statuses_Obj_Rel_Insert_Input>;
  status_id?: InputMaybe<Scalars["uuid"]>;
  subscription?: InputMaybe<Subscriptions_Obj_Rel_Insert_Input>;
  subscription_id?: InputMaybe<Scalars["uuid"]>;
  subscription_payment_transactions?: InputMaybe<Subscription_Payment_Transactions_Arr_Rel_Insert_Input>;
  subscription_payments?: InputMaybe<Subscription_Payments_Arr_Rel_Insert_Input>;
  type?: InputMaybe<Types_Obj_Rel_Insert_Input>;
  type_id?: InputMaybe<Scalars["uuid"]>;
  updated_at?: InputMaybe<Scalars["timestamptz"]>;
};

/** aggregate max on columns */
export type Invoices_Max_Fields = {
  __typename?: "invoices_max_fields";
  amount_due?: Maybe<Scalars["float8"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  due_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["uuid"]>;
  organisation_id?: Maybe<Scalars["uuid"]>;
  party_id?: Maybe<Scalars["uuid"]>;
  product_id?: Maybe<Scalars["uuid"]>;
  status_id?: Maybe<Scalars["uuid"]>;
  subscription_id?: Maybe<Scalars["uuid"]>;
  type_id?: Maybe<Scalars["uuid"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
};

/** order by max() on columns of table "invoices" */
export type Invoices_Max_Order_By = {
  amount_due?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  due_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  organisation_id?: InputMaybe<Order_By>;
  party_id?: InputMaybe<Order_By>;
  product_id?: InputMaybe<Order_By>;
  status_id?: InputMaybe<Order_By>;
  subscription_id?: InputMaybe<Order_By>;
  type_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Invoices_Min_Fields = {
  __typename?: "invoices_min_fields";
  amount_due?: Maybe<Scalars["float8"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  due_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["uuid"]>;
  organisation_id?: Maybe<Scalars["uuid"]>;
  party_id?: Maybe<Scalars["uuid"]>;
  product_id?: Maybe<Scalars["uuid"]>;
  status_id?: Maybe<Scalars["uuid"]>;
  subscription_id?: Maybe<Scalars["uuid"]>;
  type_id?: Maybe<Scalars["uuid"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
};

/** order by min() on columns of table "invoices" */
export type Invoices_Min_Order_By = {
  amount_due?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  due_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  organisation_id?: InputMaybe<Order_By>;
  party_id?: InputMaybe<Order_By>;
  product_id?: InputMaybe<Order_By>;
  status_id?: InputMaybe<Order_By>;
  subscription_id?: InputMaybe<Order_By>;
  type_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "invoices" */
export type Invoices_Mutation_Response = {
  __typename?: "invoices_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Invoices>;
};

/** input type for inserting object relation for remote table "invoices" */
export type Invoices_Obj_Rel_Insert_Input = {
  data: Invoices_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Invoices_On_Conflict>;
};

/** on_conflict condition type for table "invoices" */
export type Invoices_On_Conflict = {
  constraint: Invoices_Constraint;
  update_columns?: Array<Invoices_Update_Column>;
  where?: InputMaybe<Invoices_Bool_Exp>;
};

/** Ordering options when selecting data from "invoices". */
export type Invoices_Order_By = {
  amount_due?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  due_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  invoice_interactions_aggregate?: InputMaybe<Invoice_Interactions_Aggregate_Order_By>;
  invoice_items_aggregate?: InputMaybe<Invoice_Items_Aggregate_Order_By>;
  metadata?: InputMaybe<Order_By>;
  organisation?: InputMaybe<Organisations_Order_By>;
  organisation_id?: InputMaybe<Order_By>;
  party?: InputMaybe<Parties_Order_By>;
  party_id?: InputMaybe<Order_By>;
  product?: InputMaybe<Products_Order_By>;
  product_id?: InputMaybe<Order_By>;
  status?: InputMaybe<Statuses_Order_By>;
  status_id?: InputMaybe<Order_By>;
  subscription?: InputMaybe<Subscriptions_Order_By>;
  subscription_id?: InputMaybe<Order_By>;
  subscription_payment_transactions_aggregate?: InputMaybe<Subscription_Payment_Transactions_Aggregate_Order_By>;
  subscription_payments_aggregate?: InputMaybe<Subscription_Payments_Aggregate_Order_By>;
  type?: InputMaybe<Types_Order_By>;
  type_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: invoices */
export type Invoices_Pk_Columns_Input = {
  id: Scalars["uuid"];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Invoices_Prepend_Input = {
  metadata?: InputMaybe<Scalars["jsonb"]>;
};

/** select columns of table "invoices" */
export enum Invoices_Select_Column {
  /** column name */
  AmountDue = "amount_due",
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  DueAt = "due_at",
  /** column name */
  Id = "id",
  /** column name */
  Metadata = "metadata",
  /** column name */
  OrganisationId = "organisation_id",
  /** column name */
  PartyId = "party_id",
  /** column name */
  ProductId = "product_id",
  /** column name */
  StatusId = "status_id",
  /** column name */
  SubscriptionId = "subscription_id",
  /** column name */
  TypeId = "type_id",
  /** column name */
  UpdatedAt = "updated_at",
}

/** select "invoices_aggregate_bool_exp_avg_arguments_columns" columns of table "invoices" */
export enum Invoices_Select_Column_Invoices_Aggregate_Bool_Exp_Avg_Arguments_Columns {
  /** column name */
  AmountDue = "amount_due",
}

/** select "invoices_aggregate_bool_exp_corr_arguments_columns" columns of table "invoices" */
export enum Invoices_Select_Column_Invoices_Aggregate_Bool_Exp_Corr_Arguments_Columns {
  /** column name */
  AmountDue = "amount_due",
}

/** select "invoices_aggregate_bool_exp_covar_samp_arguments_columns" columns of table "invoices" */
export enum Invoices_Select_Column_Invoices_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns {
  /** column name */
  AmountDue = "amount_due",
}

/** select "invoices_aggregate_bool_exp_max_arguments_columns" columns of table "invoices" */
export enum Invoices_Select_Column_Invoices_Aggregate_Bool_Exp_Max_Arguments_Columns {
  /** column name */
  AmountDue = "amount_due",
}

/** select "invoices_aggregate_bool_exp_min_arguments_columns" columns of table "invoices" */
export enum Invoices_Select_Column_Invoices_Aggregate_Bool_Exp_Min_Arguments_Columns {
  /** column name */
  AmountDue = "amount_due",
}

/** select "invoices_aggregate_bool_exp_stddev_samp_arguments_columns" columns of table "invoices" */
export enum Invoices_Select_Column_Invoices_Aggregate_Bool_Exp_Stddev_Samp_Arguments_Columns {
  /** column name */
  AmountDue = "amount_due",
}

/** select "invoices_aggregate_bool_exp_sum_arguments_columns" columns of table "invoices" */
export enum Invoices_Select_Column_Invoices_Aggregate_Bool_Exp_Sum_Arguments_Columns {
  /** column name */
  AmountDue = "amount_due",
}

/** select "invoices_aggregate_bool_exp_var_samp_arguments_columns" columns of table "invoices" */
export enum Invoices_Select_Column_Invoices_Aggregate_Bool_Exp_Var_Samp_Arguments_Columns {
  /** column name */
  AmountDue = "amount_due",
}

/** input type for updating data in table "invoices" */
export type Invoices_Set_Input = {
  amount_due?: InputMaybe<Scalars["float8"]>;
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  due_at?: InputMaybe<Scalars["timestamptz"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  metadata?: InputMaybe<Scalars["jsonb"]>;
  organisation_id?: InputMaybe<Scalars["uuid"]>;
  party_id?: InputMaybe<Scalars["uuid"]>;
  product_id?: InputMaybe<Scalars["uuid"]>;
  status_id?: InputMaybe<Scalars["uuid"]>;
  subscription_id?: InputMaybe<Scalars["uuid"]>;
  type_id?: InputMaybe<Scalars["uuid"]>;
  updated_at?: InputMaybe<Scalars["timestamptz"]>;
};

/** aggregate stddev on columns */
export type Invoices_Stddev_Fields = {
  __typename?: "invoices_stddev_fields";
  amount_due?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "invoices" */
export type Invoices_Stddev_Order_By = {
  amount_due?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Invoices_Stddev_Pop_Fields = {
  __typename?: "invoices_stddev_pop_fields";
  amount_due?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "invoices" */
export type Invoices_Stddev_Pop_Order_By = {
  amount_due?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Invoices_Stddev_Samp_Fields = {
  __typename?: "invoices_stddev_samp_fields";
  amount_due?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "invoices" */
export type Invoices_Stddev_Samp_Order_By = {
  amount_due?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "invoices" */
export type Invoices_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Invoices_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Invoices_Stream_Cursor_Value_Input = {
  amount_due?: InputMaybe<Scalars["float8"]>;
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  due_at?: InputMaybe<Scalars["timestamptz"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  metadata?: InputMaybe<Scalars["jsonb"]>;
  organisation_id?: InputMaybe<Scalars["uuid"]>;
  party_id?: InputMaybe<Scalars["uuid"]>;
  product_id?: InputMaybe<Scalars["uuid"]>;
  status_id?: InputMaybe<Scalars["uuid"]>;
  subscription_id?: InputMaybe<Scalars["uuid"]>;
  type_id?: InputMaybe<Scalars["uuid"]>;
  updated_at?: InputMaybe<Scalars["timestamptz"]>;
};

/** aggregate sum on columns */
export type Invoices_Sum_Fields = {
  __typename?: "invoices_sum_fields";
  amount_due?: Maybe<Scalars["float8"]>;
};

/** order by sum() on columns of table "invoices" */
export type Invoices_Sum_Order_By = {
  amount_due?: InputMaybe<Order_By>;
};

/** update columns of table "invoices" */
export enum Invoices_Update_Column {
  /** column name */
  AmountDue = "amount_due",
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  DueAt = "due_at",
  /** column name */
  Id = "id",
  /** column name */
  Metadata = "metadata",
  /** column name */
  OrganisationId = "organisation_id",
  /** column name */
  PartyId = "party_id",
  /** column name */
  ProductId = "product_id",
  /** column name */
  StatusId = "status_id",
  /** column name */
  SubscriptionId = "subscription_id",
  /** column name */
  TypeId = "type_id",
  /** column name */
  UpdatedAt = "updated_at",
}

export type Invoices_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Invoices_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Invoices_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Invoices_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Invoices_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Invoices_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Invoices_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Invoices_Set_Input>;
  /** filter the rows which have to be updated */
  where: Invoices_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Invoices_Var_Pop_Fields = {
  __typename?: "invoices_var_pop_fields";
  amount_due?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "invoices" */
export type Invoices_Var_Pop_Order_By = {
  amount_due?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Invoices_Var_Samp_Fields = {
  __typename?: "invoices_var_samp_fields";
  amount_due?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "invoices" */
export type Invoices_Var_Samp_Order_By = {
  amount_due?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Invoices_Variance_Fields = {
  __typename?: "invoices_variance_fields";
  amount_due?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "invoices" */
export type Invoices_Variance_Order_By = {
  amount_due?: InputMaybe<Order_By>;
};

export type Jsonb_Cast_Exp = {
  String?: InputMaybe<String_Comparison_Exp>;
};

/** Boolean expression to compare columns of type "jsonb". All fields are combined with logical 'AND'. */
export type Jsonb_Comparison_Exp = {
  _cast?: InputMaybe<Jsonb_Cast_Exp>;
  /** is the column contained in the given json value */
  _contained_in?: InputMaybe<Scalars["jsonb"]>;
  /** does the column contain the given json value at the top level */
  _contains?: InputMaybe<Scalars["jsonb"]>;
  _eq?: InputMaybe<Scalars["jsonb"]>;
  _gt?: InputMaybe<Scalars["jsonb"]>;
  _gte?: InputMaybe<Scalars["jsonb"]>;
  /** does the string exist as a top-level key in the column */
  _has_key?: InputMaybe<Scalars["String"]>;
  /** do all of these strings exist as top-level keys in the column */
  _has_keys_all?: InputMaybe<Array<Scalars["String"]>>;
  /** do any of these strings exist as top-level keys in the column */
  _has_keys_any?: InputMaybe<Array<Scalars["String"]>>;
  _in?: InputMaybe<Array<Scalars["jsonb"]>>;
  _is_null?: InputMaybe<Scalars["Boolean"]>;
  _lt?: InputMaybe<Scalars["jsonb"]>;
  _lte?: InputMaybe<Scalars["jsonb"]>;
  _neq?: InputMaybe<Scalars["jsonb"]>;
  _nin?: InputMaybe<Array<Scalars["jsonb"]>>;
};

export type Ledger_Accounts_Bool_Exp = {
  code?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Time_Comparison_Exp>;
  ledger_id?: InputMaybe<Uuid_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  normal_balance?: InputMaybe<Int_Comparison_Exp>;
  type_id?: InputMaybe<Uuid_Comparison_Exp>;
  updated_at?: InputMaybe<Time_Comparison_Exp>;
};

export type Ledger_Entries_Bool_Exp = {
  amount?: InputMaybe<Int_Comparison_Exp>;
  code?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Time_Comparison_Exp>;
  effective_date?: InputMaybe<Time_Comparison_Exp>;
  entry_type?: InputMaybe<String_Comparison_Exp>;
  external_reference?: InputMaybe<String_Comparison_Exp>;
  posted_at?: InputMaybe<Time_Comparison_Exp>;
  status_id?: InputMaybe<Uuid_Comparison_Exp>;
  type_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** columns and relationships of "message_delivery_methods" */
export type Message_Delivery_Methods = {
  __typename?: "message_delivery_methods";
  created_at?: Maybe<Scalars["timestamptz"]>;
  /** An object relationship */
  delivery_method: Delivery_Methods;
  delivery_method_id: Scalars["uuid"];
  id: Scalars["uuid"];
  /** An object relationship */
  message: Messages;
  message_id: Scalars["uuid"];
};

/** aggregated selection of "message_delivery_methods" */
export type Message_Delivery_Methods_Aggregate = {
  __typename?: "message_delivery_methods_aggregate";
  aggregate?: Maybe<Message_Delivery_Methods_Aggregate_Fields>;
  nodes: Array<Message_Delivery_Methods>;
};

export type Message_Delivery_Methods_Aggregate_Bool_Exp = {
  count?: InputMaybe<Message_Delivery_Methods_Aggregate_Bool_Exp_Count>;
};

export type Message_Delivery_Methods_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Message_Delivery_Methods_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<Message_Delivery_Methods_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "message_delivery_methods" */
export type Message_Delivery_Methods_Aggregate_Fields = {
  __typename?: "message_delivery_methods_aggregate_fields";
  count: Scalars["Int"];
  max?: Maybe<Message_Delivery_Methods_Max_Fields>;
  min?: Maybe<Message_Delivery_Methods_Min_Fields>;
};

/** aggregate fields of "message_delivery_methods" */
export type Message_Delivery_Methods_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Message_Delivery_Methods_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "message_delivery_methods" */
export type Message_Delivery_Methods_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Message_Delivery_Methods_Max_Order_By>;
  min?: InputMaybe<Message_Delivery_Methods_Min_Order_By>;
};

/** input type for inserting array relation for remote table "message_delivery_methods" */
export type Message_Delivery_Methods_Arr_Rel_Insert_Input = {
  data: Array<Message_Delivery_Methods_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Message_Delivery_Methods_On_Conflict>;
};

/** Boolean expression to filter rows from the table "message_delivery_methods". All fields are combined with a logical 'AND'. */
export type Message_Delivery_Methods_Bool_Exp = {
  _and?: InputMaybe<Array<Message_Delivery_Methods_Bool_Exp>>;
  _not?: InputMaybe<Message_Delivery_Methods_Bool_Exp>;
  _or?: InputMaybe<Array<Message_Delivery_Methods_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  delivery_method?: InputMaybe<Delivery_Methods_Bool_Exp>;
  delivery_method_id?: InputMaybe<Uuid_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  message?: InputMaybe<Messages_Bool_Exp>;
  message_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "message_delivery_methods" */
export enum Message_Delivery_Methods_Constraint {
  /** unique or primary key constraint on columns "id" */
  MessageDeliveryMethodsPkey = "message_delivery_methods_pkey",
}

/** input type for inserting data into table "message_delivery_methods" */
export type Message_Delivery_Methods_Insert_Input = {
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  delivery_method?: InputMaybe<Delivery_Methods_Obj_Rel_Insert_Input>;
  delivery_method_id?: InputMaybe<Scalars["uuid"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  message?: InputMaybe<Messages_Obj_Rel_Insert_Input>;
  message_id?: InputMaybe<Scalars["uuid"]>;
};

/** aggregate max on columns */
export type Message_Delivery_Methods_Max_Fields = {
  __typename?: "message_delivery_methods_max_fields";
  created_at?: Maybe<Scalars["timestamptz"]>;
  delivery_method_id?: Maybe<Scalars["uuid"]>;
  id?: Maybe<Scalars["uuid"]>;
  message_id?: Maybe<Scalars["uuid"]>;
};

/** order by max() on columns of table "message_delivery_methods" */
export type Message_Delivery_Methods_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  delivery_method_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  message_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Message_Delivery_Methods_Min_Fields = {
  __typename?: "message_delivery_methods_min_fields";
  created_at?: Maybe<Scalars["timestamptz"]>;
  delivery_method_id?: Maybe<Scalars["uuid"]>;
  id?: Maybe<Scalars["uuid"]>;
  message_id?: Maybe<Scalars["uuid"]>;
};

/** order by min() on columns of table "message_delivery_methods" */
export type Message_Delivery_Methods_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  delivery_method_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  message_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "message_delivery_methods" */
export type Message_Delivery_Methods_Mutation_Response = {
  __typename?: "message_delivery_methods_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Message_Delivery_Methods>;
};

/** on_conflict condition type for table "message_delivery_methods" */
export type Message_Delivery_Methods_On_Conflict = {
  constraint: Message_Delivery_Methods_Constraint;
  update_columns?: Array<Message_Delivery_Methods_Update_Column>;
  where?: InputMaybe<Message_Delivery_Methods_Bool_Exp>;
};

/** Ordering options when selecting data from "message_delivery_methods". */
export type Message_Delivery_Methods_Order_By = {
  created_at?: InputMaybe<Order_By>;
  delivery_method?: InputMaybe<Delivery_Methods_Order_By>;
  delivery_method_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  message?: InputMaybe<Messages_Order_By>;
  message_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: message_delivery_methods */
export type Message_Delivery_Methods_Pk_Columns_Input = {
  id: Scalars["uuid"];
};

/** select columns of table "message_delivery_methods" */
export enum Message_Delivery_Methods_Select_Column {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  DeliveryMethodId = "delivery_method_id",
  /** column name */
  Id = "id",
  /** column name */
  MessageId = "message_id",
}

/** input type for updating data in table "message_delivery_methods" */
export type Message_Delivery_Methods_Set_Input = {
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  delivery_method_id?: InputMaybe<Scalars["uuid"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  message_id?: InputMaybe<Scalars["uuid"]>;
};

/** Streaming cursor of the table "message_delivery_methods" */
export type Message_Delivery_Methods_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Message_Delivery_Methods_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Message_Delivery_Methods_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  delivery_method_id?: InputMaybe<Scalars["uuid"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  message_id?: InputMaybe<Scalars["uuid"]>;
};

/** update columns of table "message_delivery_methods" */
export enum Message_Delivery_Methods_Update_Column {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  DeliveryMethodId = "delivery_method_id",
  /** column name */
  Id = "id",
  /** column name */
  MessageId = "message_id",
}

export type Message_Delivery_Methods_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Message_Delivery_Methods_Set_Input>;
  /** filter the rows which have to be updated */
  where: Message_Delivery_Methods_Bool_Exp;
};

/** columns and relationships of "message_delivery_responses" */
export type Message_Delivery_Responses = {
  __typename?: "message_delivery_responses";
  created_at: Scalars["timestamptz"];
  /** An object relationship */
  delivery_method: Delivery_Methods;
  delivery_method_id: Scalars["uuid"];
  id: Scalars["uuid"];
  /** An object relationship */
  message: Messages;
  message_id: Scalars["uuid"];
  response_data?: Maybe<Scalars["jsonb"]>;
  updated_at: Scalars["timestamptz"];
};

/** columns and relationships of "message_delivery_responses" */
export type Message_Delivery_ResponsesResponse_DataArgs = {
  path?: InputMaybe<Scalars["String"]>;
};

/** aggregated selection of "message_delivery_responses" */
export type Message_Delivery_Responses_Aggregate = {
  __typename?: "message_delivery_responses_aggregate";
  aggregate?: Maybe<Message_Delivery_Responses_Aggregate_Fields>;
  nodes: Array<Message_Delivery_Responses>;
};

export type Message_Delivery_Responses_Aggregate_Bool_Exp = {
  count?: InputMaybe<Message_Delivery_Responses_Aggregate_Bool_Exp_Count>;
};

export type Message_Delivery_Responses_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Message_Delivery_Responses_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<Message_Delivery_Responses_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "message_delivery_responses" */
export type Message_Delivery_Responses_Aggregate_Fields = {
  __typename?: "message_delivery_responses_aggregate_fields";
  count: Scalars["Int"];
  max?: Maybe<Message_Delivery_Responses_Max_Fields>;
  min?: Maybe<Message_Delivery_Responses_Min_Fields>;
};

/** aggregate fields of "message_delivery_responses" */
export type Message_Delivery_Responses_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Message_Delivery_Responses_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "message_delivery_responses" */
export type Message_Delivery_Responses_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Message_Delivery_Responses_Max_Order_By>;
  min?: InputMaybe<Message_Delivery_Responses_Min_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Message_Delivery_Responses_Append_Input = {
  response_data?: InputMaybe<Scalars["jsonb"]>;
};

/** input type for inserting array relation for remote table "message_delivery_responses" */
export type Message_Delivery_Responses_Arr_Rel_Insert_Input = {
  data: Array<Message_Delivery_Responses_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Message_Delivery_Responses_On_Conflict>;
};

/** Boolean expression to filter rows from the table "message_delivery_responses". All fields are combined with a logical 'AND'. */
export type Message_Delivery_Responses_Bool_Exp = {
  _and?: InputMaybe<Array<Message_Delivery_Responses_Bool_Exp>>;
  _not?: InputMaybe<Message_Delivery_Responses_Bool_Exp>;
  _or?: InputMaybe<Array<Message_Delivery_Responses_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  delivery_method?: InputMaybe<Delivery_Methods_Bool_Exp>;
  delivery_method_id?: InputMaybe<Uuid_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  message?: InputMaybe<Messages_Bool_Exp>;
  message_id?: InputMaybe<Uuid_Comparison_Exp>;
  response_data?: InputMaybe<Jsonb_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "message_delivery_responses" */
export enum Message_Delivery_Responses_Constraint {
  /** unique or primary key constraint on columns "id" */
  MessageDeliveryResponsesPkey = "message_delivery_responses_pkey",
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Message_Delivery_Responses_Delete_At_Path_Input = {
  response_data?: InputMaybe<Array<Scalars["String"]>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Message_Delivery_Responses_Delete_Elem_Input = {
  response_data?: InputMaybe<Scalars["Int"]>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Message_Delivery_Responses_Delete_Key_Input = {
  response_data?: InputMaybe<Scalars["String"]>;
};

/** input type for inserting data into table "message_delivery_responses" */
export type Message_Delivery_Responses_Insert_Input = {
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  delivery_method?: InputMaybe<Delivery_Methods_Obj_Rel_Insert_Input>;
  delivery_method_id?: InputMaybe<Scalars["uuid"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  message?: InputMaybe<Messages_Obj_Rel_Insert_Input>;
  message_id?: InputMaybe<Scalars["uuid"]>;
  response_data?: InputMaybe<Scalars["jsonb"]>;
  updated_at?: InputMaybe<Scalars["timestamptz"]>;
};

/** aggregate max on columns */
export type Message_Delivery_Responses_Max_Fields = {
  __typename?: "message_delivery_responses_max_fields";
  created_at?: Maybe<Scalars["timestamptz"]>;
  delivery_method_id?: Maybe<Scalars["uuid"]>;
  id?: Maybe<Scalars["uuid"]>;
  message_id?: Maybe<Scalars["uuid"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
};

/** order by max() on columns of table "message_delivery_responses" */
export type Message_Delivery_Responses_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  delivery_method_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  message_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Message_Delivery_Responses_Min_Fields = {
  __typename?: "message_delivery_responses_min_fields";
  created_at?: Maybe<Scalars["timestamptz"]>;
  delivery_method_id?: Maybe<Scalars["uuid"]>;
  id?: Maybe<Scalars["uuid"]>;
  message_id?: Maybe<Scalars["uuid"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
};

/** order by min() on columns of table "message_delivery_responses" */
export type Message_Delivery_Responses_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  delivery_method_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  message_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "message_delivery_responses" */
export type Message_Delivery_Responses_Mutation_Response = {
  __typename?: "message_delivery_responses_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Message_Delivery_Responses>;
};

/** on_conflict condition type for table "message_delivery_responses" */
export type Message_Delivery_Responses_On_Conflict = {
  constraint: Message_Delivery_Responses_Constraint;
  update_columns?: Array<Message_Delivery_Responses_Update_Column>;
  where?: InputMaybe<Message_Delivery_Responses_Bool_Exp>;
};

/** Ordering options when selecting data from "message_delivery_responses". */
export type Message_Delivery_Responses_Order_By = {
  created_at?: InputMaybe<Order_By>;
  delivery_method?: InputMaybe<Delivery_Methods_Order_By>;
  delivery_method_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  message?: InputMaybe<Messages_Order_By>;
  message_id?: InputMaybe<Order_By>;
  response_data?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: message_delivery_responses */
export type Message_Delivery_Responses_Pk_Columns_Input = {
  id: Scalars["uuid"];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Message_Delivery_Responses_Prepend_Input = {
  response_data?: InputMaybe<Scalars["jsonb"]>;
};

/** select columns of table "message_delivery_responses" */
export enum Message_Delivery_Responses_Select_Column {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  DeliveryMethodId = "delivery_method_id",
  /** column name */
  Id = "id",
  /** column name */
  MessageId = "message_id",
  /** column name */
  ResponseData = "response_data",
  /** column name */
  UpdatedAt = "updated_at",
}

/** input type for updating data in table "message_delivery_responses" */
export type Message_Delivery_Responses_Set_Input = {
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  delivery_method_id?: InputMaybe<Scalars["uuid"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  message_id?: InputMaybe<Scalars["uuid"]>;
  response_data?: InputMaybe<Scalars["jsonb"]>;
  updated_at?: InputMaybe<Scalars["timestamptz"]>;
};

/** Streaming cursor of the table "message_delivery_responses" */
export type Message_Delivery_Responses_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Message_Delivery_Responses_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Message_Delivery_Responses_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  delivery_method_id?: InputMaybe<Scalars["uuid"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  message_id?: InputMaybe<Scalars["uuid"]>;
  response_data?: InputMaybe<Scalars["jsonb"]>;
  updated_at?: InputMaybe<Scalars["timestamptz"]>;
};

/** update columns of table "message_delivery_responses" */
export enum Message_Delivery_Responses_Update_Column {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  DeliveryMethodId = "delivery_method_id",
  /** column name */
  Id = "id",
  /** column name */
  MessageId = "message_id",
  /** column name */
  ResponseData = "response_data",
  /** column name */
  UpdatedAt = "updated_at",
}

export type Message_Delivery_Responses_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Message_Delivery_Responses_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Message_Delivery_Responses_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Message_Delivery_Responses_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Message_Delivery_Responses_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Message_Delivery_Responses_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Message_Delivery_Responses_Set_Input>;
  /** filter the rows which have to be updated */
  where: Message_Delivery_Responses_Bool_Exp;
};

/** columns and relationships of "message_flags" */
export type Message_Flags = {
  __typename?: "message_flags";
  created_at?: Maybe<Scalars["timestamptz"]>;
  /** An object relationship */
  flag: Flags;
  flag_id: Scalars["uuid"];
  id: Scalars["uuid"];
  /** An object relationship */
  message: Messages;
  message_id: Scalars["uuid"];
};

/** aggregated selection of "message_flags" */
export type Message_Flags_Aggregate = {
  __typename?: "message_flags_aggregate";
  aggregate?: Maybe<Message_Flags_Aggregate_Fields>;
  nodes: Array<Message_Flags>;
};

export type Message_Flags_Aggregate_Bool_Exp = {
  count?: InputMaybe<Message_Flags_Aggregate_Bool_Exp_Count>;
};

export type Message_Flags_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Message_Flags_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<Message_Flags_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "message_flags" */
export type Message_Flags_Aggregate_Fields = {
  __typename?: "message_flags_aggregate_fields";
  count: Scalars["Int"];
  max?: Maybe<Message_Flags_Max_Fields>;
  min?: Maybe<Message_Flags_Min_Fields>;
};

/** aggregate fields of "message_flags" */
export type Message_Flags_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Message_Flags_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "message_flags" */
export type Message_Flags_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Message_Flags_Max_Order_By>;
  min?: InputMaybe<Message_Flags_Min_Order_By>;
};

/** input type for inserting array relation for remote table "message_flags" */
export type Message_Flags_Arr_Rel_Insert_Input = {
  data: Array<Message_Flags_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Message_Flags_On_Conflict>;
};

/** Boolean expression to filter rows from the table "message_flags". All fields are combined with a logical 'AND'. */
export type Message_Flags_Bool_Exp = {
  _and?: InputMaybe<Array<Message_Flags_Bool_Exp>>;
  _not?: InputMaybe<Message_Flags_Bool_Exp>;
  _or?: InputMaybe<Array<Message_Flags_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  flag?: InputMaybe<Flags_Bool_Exp>;
  flag_id?: InputMaybe<Uuid_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  message?: InputMaybe<Messages_Bool_Exp>;
  message_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "message_flags" */
export enum Message_Flags_Constraint {
  /** unique or primary key constraint on columns "id" */
  MessageFlagsPkey = "message_flags_pkey",
}

/** input type for inserting data into table "message_flags" */
export type Message_Flags_Insert_Input = {
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  flag?: InputMaybe<Flags_Obj_Rel_Insert_Input>;
  flag_id?: InputMaybe<Scalars["uuid"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  message?: InputMaybe<Messages_Obj_Rel_Insert_Input>;
  message_id?: InputMaybe<Scalars["uuid"]>;
};

/** aggregate max on columns */
export type Message_Flags_Max_Fields = {
  __typename?: "message_flags_max_fields";
  created_at?: Maybe<Scalars["timestamptz"]>;
  flag_id?: Maybe<Scalars["uuid"]>;
  id?: Maybe<Scalars["uuid"]>;
  message_id?: Maybe<Scalars["uuid"]>;
};

/** order by max() on columns of table "message_flags" */
export type Message_Flags_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  flag_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  message_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Message_Flags_Min_Fields = {
  __typename?: "message_flags_min_fields";
  created_at?: Maybe<Scalars["timestamptz"]>;
  flag_id?: Maybe<Scalars["uuid"]>;
  id?: Maybe<Scalars["uuid"]>;
  message_id?: Maybe<Scalars["uuid"]>;
};

/** order by min() on columns of table "message_flags" */
export type Message_Flags_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  flag_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  message_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "message_flags" */
export type Message_Flags_Mutation_Response = {
  __typename?: "message_flags_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Message_Flags>;
};

/** on_conflict condition type for table "message_flags" */
export type Message_Flags_On_Conflict = {
  constraint: Message_Flags_Constraint;
  update_columns?: Array<Message_Flags_Update_Column>;
  where?: InputMaybe<Message_Flags_Bool_Exp>;
};

/** Ordering options when selecting data from "message_flags". */
export type Message_Flags_Order_By = {
  created_at?: InputMaybe<Order_By>;
  flag?: InputMaybe<Flags_Order_By>;
  flag_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  message?: InputMaybe<Messages_Order_By>;
  message_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: message_flags */
export type Message_Flags_Pk_Columns_Input = {
  id: Scalars["uuid"];
};

/** select columns of table "message_flags" */
export enum Message_Flags_Select_Column {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  FlagId = "flag_id",
  /** column name */
  Id = "id",
  /** column name */
  MessageId = "message_id",
}

/** input type for updating data in table "message_flags" */
export type Message_Flags_Set_Input = {
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  flag_id?: InputMaybe<Scalars["uuid"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  message_id?: InputMaybe<Scalars["uuid"]>;
};

/** Streaming cursor of the table "message_flags" */
export type Message_Flags_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Message_Flags_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Message_Flags_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  flag_id?: InputMaybe<Scalars["uuid"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  message_id?: InputMaybe<Scalars["uuid"]>;
};

/** update columns of table "message_flags" */
export enum Message_Flags_Update_Column {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  FlagId = "flag_id",
  /** column name */
  Id = "id",
  /** column name */
  MessageId = "message_id",
}

export type Message_Flags_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Message_Flags_Set_Input>;
  /** filter the rows which have to be updated */
  where: Message_Flags_Bool_Exp;
};

/** columns and relationships of "message_statuses" */
export type Message_Statuses = {
  __typename?: "message_statuses";
  created_at?: Maybe<Scalars["timestamptz"]>;
  /** An object relationship */
  delivery_method: Delivery_Methods;
  delivery_method_id: Scalars["uuid"];
  id: Scalars["uuid"];
  /** An object relationship */
  message: Messages;
  message_id: Scalars["uuid"];
  /** An object relationship */
  status: Statuses;
  status_id: Scalars["uuid"];
};

/** aggregated selection of "message_statuses" */
export type Message_Statuses_Aggregate = {
  __typename?: "message_statuses_aggregate";
  aggregate?: Maybe<Message_Statuses_Aggregate_Fields>;
  nodes: Array<Message_Statuses>;
};

export type Message_Statuses_Aggregate_Bool_Exp = {
  count?: InputMaybe<Message_Statuses_Aggregate_Bool_Exp_Count>;
};

export type Message_Statuses_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Message_Statuses_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<Message_Statuses_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "message_statuses" */
export type Message_Statuses_Aggregate_Fields = {
  __typename?: "message_statuses_aggregate_fields";
  count: Scalars["Int"];
  max?: Maybe<Message_Statuses_Max_Fields>;
  min?: Maybe<Message_Statuses_Min_Fields>;
};

/** aggregate fields of "message_statuses" */
export type Message_Statuses_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Message_Statuses_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "message_statuses" */
export type Message_Statuses_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Message_Statuses_Max_Order_By>;
  min?: InputMaybe<Message_Statuses_Min_Order_By>;
};

/** input type for inserting array relation for remote table "message_statuses" */
export type Message_Statuses_Arr_Rel_Insert_Input = {
  data: Array<Message_Statuses_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Message_Statuses_On_Conflict>;
};

/** Boolean expression to filter rows from the table "message_statuses". All fields are combined with a logical 'AND'. */
export type Message_Statuses_Bool_Exp = {
  _and?: InputMaybe<Array<Message_Statuses_Bool_Exp>>;
  _not?: InputMaybe<Message_Statuses_Bool_Exp>;
  _or?: InputMaybe<Array<Message_Statuses_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  delivery_method?: InputMaybe<Delivery_Methods_Bool_Exp>;
  delivery_method_id?: InputMaybe<Uuid_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  message?: InputMaybe<Messages_Bool_Exp>;
  message_id?: InputMaybe<Uuid_Comparison_Exp>;
  status?: InputMaybe<Statuses_Bool_Exp>;
  status_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "message_statuses" */
export enum Message_Statuses_Constraint {
  /** unique or primary key constraint on columns "id" */
  MessageStatusesPkey = "message_statuses_pkey",
}

/** input type for inserting data into table "message_statuses" */
export type Message_Statuses_Insert_Input = {
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  delivery_method?: InputMaybe<Delivery_Methods_Obj_Rel_Insert_Input>;
  delivery_method_id?: InputMaybe<Scalars["uuid"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  message?: InputMaybe<Messages_Obj_Rel_Insert_Input>;
  message_id?: InputMaybe<Scalars["uuid"]>;
  status?: InputMaybe<Statuses_Obj_Rel_Insert_Input>;
  status_id?: InputMaybe<Scalars["uuid"]>;
};

/** aggregate max on columns */
export type Message_Statuses_Max_Fields = {
  __typename?: "message_statuses_max_fields";
  created_at?: Maybe<Scalars["timestamptz"]>;
  delivery_method_id?: Maybe<Scalars["uuid"]>;
  id?: Maybe<Scalars["uuid"]>;
  message_id?: Maybe<Scalars["uuid"]>;
  status_id?: Maybe<Scalars["uuid"]>;
};

/** order by max() on columns of table "message_statuses" */
export type Message_Statuses_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  delivery_method_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  message_id?: InputMaybe<Order_By>;
  status_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Message_Statuses_Min_Fields = {
  __typename?: "message_statuses_min_fields";
  created_at?: Maybe<Scalars["timestamptz"]>;
  delivery_method_id?: Maybe<Scalars["uuid"]>;
  id?: Maybe<Scalars["uuid"]>;
  message_id?: Maybe<Scalars["uuid"]>;
  status_id?: Maybe<Scalars["uuid"]>;
};

/** order by min() on columns of table "message_statuses" */
export type Message_Statuses_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  delivery_method_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  message_id?: InputMaybe<Order_By>;
  status_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "message_statuses" */
export type Message_Statuses_Mutation_Response = {
  __typename?: "message_statuses_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Message_Statuses>;
};

/** on_conflict condition type for table "message_statuses" */
export type Message_Statuses_On_Conflict = {
  constraint: Message_Statuses_Constraint;
  update_columns?: Array<Message_Statuses_Update_Column>;
  where?: InputMaybe<Message_Statuses_Bool_Exp>;
};

/** Ordering options when selecting data from "message_statuses". */
export type Message_Statuses_Order_By = {
  created_at?: InputMaybe<Order_By>;
  delivery_method?: InputMaybe<Delivery_Methods_Order_By>;
  delivery_method_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  message?: InputMaybe<Messages_Order_By>;
  message_id?: InputMaybe<Order_By>;
  status?: InputMaybe<Statuses_Order_By>;
  status_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: message_statuses */
export type Message_Statuses_Pk_Columns_Input = {
  id: Scalars["uuid"];
};

/** select columns of table "message_statuses" */
export enum Message_Statuses_Select_Column {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  DeliveryMethodId = "delivery_method_id",
  /** column name */
  Id = "id",
  /** column name */
  MessageId = "message_id",
  /** column name */
  StatusId = "status_id",
}

/** input type for updating data in table "message_statuses" */
export type Message_Statuses_Set_Input = {
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  delivery_method_id?: InputMaybe<Scalars["uuid"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  message_id?: InputMaybe<Scalars["uuid"]>;
  status_id?: InputMaybe<Scalars["uuid"]>;
};

/** Streaming cursor of the table "message_statuses" */
export type Message_Statuses_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Message_Statuses_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Message_Statuses_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  delivery_method_id?: InputMaybe<Scalars["uuid"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  message_id?: InputMaybe<Scalars["uuid"]>;
  status_id?: InputMaybe<Scalars["uuid"]>;
};

/** update columns of table "message_statuses" */
export enum Message_Statuses_Update_Column {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  DeliveryMethodId = "delivery_method_id",
  /** column name */
  Id = "id",
  /** column name */
  MessageId = "message_id",
  /** column name */
  StatusId = "status_id",
}

export type Message_Statuses_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Message_Statuses_Set_Input>;
  /** filter the rows which have to be updated */
  where: Message_Statuses_Bool_Exp;
};

/** columns and relationships of "messages" */
export type Messages = {
  __typename?: "messages";
  body: Scalars["String"];
  /** An object relationship */
  channel: Channels;
  channel_id: Scalars["uuid"];
  created_at: Scalars["timestamptz"];
  id: Scalars["uuid"];
  /** An array relationship */
  message_delivery_methods: Array<Message_Delivery_Methods>;
  /** An aggregate relationship */
  message_delivery_methods_aggregate: Message_Delivery_Methods_Aggregate;
  /** An array relationship */
  message_delivery_responses: Array<Message_Delivery_Responses>;
  /** An aggregate relationship */
  message_delivery_responses_aggregate: Message_Delivery_Responses_Aggregate;
  /** An array relationship */
  message_flags: Array<Message_Flags>;
  /** An aggregate relationship */
  message_flags_aggregate: Message_Flags_Aggregate;
  /** An array relationship */
  message_statuses: Array<Message_Statuses>;
  /** An aggregate relationship */
  message_statuses_aggregate: Message_Statuses_Aggregate;
  metadata?: Maybe<Scalars["jsonb"]>;
  /** An object relationship */
  party_by_sender?: Maybe<Parties>;
  sender?: Maybe<Scalars["uuid"]>;
  subject?: Maybe<Scalars["String"]>;
  /** An object relationship */
  type: Types;
  type_id: Scalars["uuid"];
  updated_at: Scalars["timestamptz"];
};

/** columns and relationships of "messages" */
export type MessagesMessage_Delivery_MethodsArgs = {
  distinct_on?: InputMaybe<Array<Message_Delivery_Methods_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Message_Delivery_Methods_Order_By>>;
  where?: InputMaybe<Message_Delivery_Methods_Bool_Exp>;
};

/** columns and relationships of "messages" */
export type MessagesMessage_Delivery_Methods_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Message_Delivery_Methods_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Message_Delivery_Methods_Order_By>>;
  where?: InputMaybe<Message_Delivery_Methods_Bool_Exp>;
};

/** columns and relationships of "messages" */
export type MessagesMessage_Delivery_ResponsesArgs = {
  distinct_on?: InputMaybe<Array<Message_Delivery_Responses_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Message_Delivery_Responses_Order_By>>;
  where?: InputMaybe<Message_Delivery_Responses_Bool_Exp>;
};

/** columns and relationships of "messages" */
export type MessagesMessage_Delivery_Responses_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Message_Delivery_Responses_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Message_Delivery_Responses_Order_By>>;
  where?: InputMaybe<Message_Delivery_Responses_Bool_Exp>;
};

/** columns and relationships of "messages" */
export type MessagesMessage_FlagsArgs = {
  distinct_on?: InputMaybe<Array<Message_Flags_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Message_Flags_Order_By>>;
  where?: InputMaybe<Message_Flags_Bool_Exp>;
};

/** columns and relationships of "messages" */
export type MessagesMessage_Flags_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Message_Flags_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Message_Flags_Order_By>>;
  where?: InputMaybe<Message_Flags_Bool_Exp>;
};

/** columns and relationships of "messages" */
export type MessagesMessage_StatusesArgs = {
  distinct_on?: InputMaybe<Array<Message_Statuses_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Message_Statuses_Order_By>>;
  where?: InputMaybe<Message_Statuses_Bool_Exp>;
};

/** columns and relationships of "messages" */
export type MessagesMessage_Statuses_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Message_Statuses_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Message_Statuses_Order_By>>;
  where?: InputMaybe<Message_Statuses_Bool_Exp>;
};

/** columns and relationships of "messages" */
export type MessagesMetadataArgs = {
  path?: InputMaybe<Scalars["String"]>;
};

/** aggregated selection of "messages" */
export type Messages_Aggregate = {
  __typename?: "messages_aggregate";
  aggregate?: Maybe<Messages_Aggregate_Fields>;
  nodes: Array<Messages>;
};

export type Messages_Aggregate_Bool_Exp = {
  count?: InputMaybe<Messages_Aggregate_Bool_Exp_Count>;
};

export type Messages_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Messages_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<Messages_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "messages" */
export type Messages_Aggregate_Fields = {
  __typename?: "messages_aggregate_fields";
  count: Scalars["Int"];
  max?: Maybe<Messages_Max_Fields>;
  min?: Maybe<Messages_Min_Fields>;
};

/** aggregate fields of "messages" */
export type Messages_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Messages_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "messages" */
export type Messages_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Messages_Max_Order_By>;
  min?: InputMaybe<Messages_Min_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Messages_Append_Input = {
  metadata?: InputMaybe<Scalars["jsonb"]>;
};

/** input type for inserting array relation for remote table "messages" */
export type Messages_Arr_Rel_Insert_Input = {
  data: Array<Messages_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Messages_On_Conflict>;
};

/** Boolean expression to filter rows from the table "messages". All fields are combined with a logical 'AND'. */
export type Messages_Bool_Exp = {
  _and?: InputMaybe<Array<Messages_Bool_Exp>>;
  _not?: InputMaybe<Messages_Bool_Exp>;
  _or?: InputMaybe<Array<Messages_Bool_Exp>>;
  body?: InputMaybe<String_Comparison_Exp>;
  channel?: InputMaybe<Channels_Bool_Exp>;
  channel_id?: InputMaybe<Uuid_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  message_delivery_methods?: InputMaybe<Message_Delivery_Methods_Bool_Exp>;
  message_delivery_methods_aggregate?: InputMaybe<Message_Delivery_Methods_Aggregate_Bool_Exp>;
  message_delivery_responses?: InputMaybe<Message_Delivery_Responses_Bool_Exp>;
  message_delivery_responses_aggregate?: InputMaybe<Message_Delivery_Responses_Aggregate_Bool_Exp>;
  message_flags?: InputMaybe<Message_Flags_Bool_Exp>;
  message_flags_aggregate?: InputMaybe<Message_Flags_Aggregate_Bool_Exp>;
  message_statuses?: InputMaybe<Message_Statuses_Bool_Exp>;
  message_statuses_aggregate?: InputMaybe<Message_Statuses_Aggregate_Bool_Exp>;
  metadata?: InputMaybe<Jsonb_Comparison_Exp>;
  party_by_sender?: InputMaybe<Parties_Bool_Exp>;
  sender?: InputMaybe<Uuid_Comparison_Exp>;
  subject?: InputMaybe<String_Comparison_Exp>;
  type?: InputMaybe<Types_Bool_Exp>;
  type_id?: InputMaybe<Uuid_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "messages" */
export enum Messages_Constraint {
  /** unique or primary key constraint on columns "id" */
  MessagesPkey = "messages_pkey",
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Messages_Delete_At_Path_Input = {
  metadata?: InputMaybe<Array<Scalars["String"]>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Messages_Delete_Elem_Input = {
  metadata?: InputMaybe<Scalars["Int"]>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Messages_Delete_Key_Input = {
  metadata?: InputMaybe<Scalars["String"]>;
};

/** input type for inserting data into table "messages" */
export type Messages_Insert_Input = {
  body?: InputMaybe<Scalars["String"]>;
  channel?: InputMaybe<Channels_Obj_Rel_Insert_Input>;
  channel_id?: InputMaybe<Scalars["uuid"]>;
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  message_delivery_methods?: InputMaybe<Message_Delivery_Methods_Arr_Rel_Insert_Input>;
  message_delivery_responses?: InputMaybe<Message_Delivery_Responses_Arr_Rel_Insert_Input>;
  message_flags?: InputMaybe<Message_Flags_Arr_Rel_Insert_Input>;
  message_statuses?: InputMaybe<Message_Statuses_Arr_Rel_Insert_Input>;
  metadata?: InputMaybe<Scalars["jsonb"]>;
  party_by_sender?: InputMaybe<Parties_Obj_Rel_Insert_Input>;
  sender?: InputMaybe<Scalars["uuid"]>;
  subject?: InputMaybe<Scalars["String"]>;
  type?: InputMaybe<Types_Obj_Rel_Insert_Input>;
  type_id?: InputMaybe<Scalars["uuid"]>;
  updated_at?: InputMaybe<Scalars["timestamptz"]>;
};

/** aggregate max on columns */
export type Messages_Max_Fields = {
  __typename?: "messages_max_fields";
  body?: Maybe<Scalars["String"]>;
  channel_id?: Maybe<Scalars["uuid"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["uuid"]>;
  sender?: Maybe<Scalars["uuid"]>;
  subject?: Maybe<Scalars["String"]>;
  type_id?: Maybe<Scalars["uuid"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
};

/** order by max() on columns of table "messages" */
export type Messages_Max_Order_By = {
  body?: InputMaybe<Order_By>;
  channel_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  sender?: InputMaybe<Order_By>;
  subject?: InputMaybe<Order_By>;
  type_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Messages_Min_Fields = {
  __typename?: "messages_min_fields";
  body?: Maybe<Scalars["String"]>;
  channel_id?: Maybe<Scalars["uuid"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["uuid"]>;
  sender?: Maybe<Scalars["uuid"]>;
  subject?: Maybe<Scalars["String"]>;
  type_id?: Maybe<Scalars["uuid"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
};

/** order by min() on columns of table "messages" */
export type Messages_Min_Order_By = {
  body?: InputMaybe<Order_By>;
  channel_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  sender?: InputMaybe<Order_By>;
  subject?: InputMaybe<Order_By>;
  type_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "messages" */
export type Messages_Mutation_Response = {
  __typename?: "messages_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Messages>;
};

/** input type for inserting object relation for remote table "messages" */
export type Messages_Obj_Rel_Insert_Input = {
  data: Messages_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Messages_On_Conflict>;
};

/** on_conflict condition type for table "messages" */
export type Messages_On_Conflict = {
  constraint: Messages_Constraint;
  update_columns?: Array<Messages_Update_Column>;
  where?: InputMaybe<Messages_Bool_Exp>;
};

/** Ordering options when selecting data from "messages". */
export type Messages_Order_By = {
  body?: InputMaybe<Order_By>;
  channel?: InputMaybe<Channels_Order_By>;
  channel_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  message_delivery_methods_aggregate?: InputMaybe<Message_Delivery_Methods_Aggregate_Order_By>;
  message_delivery_responses_aggregate?: InputMaybe<Message_Delivery_Responses_Aggregate_Order_By>;
  message_flags_aggregate?: InputMaybe<Message_Flags_Aggregate_Order_By>;
  message_statuses_aggregate?: InputMaybe<Message_Statuses_Aggregate_Order_By>;
  metadata?: InputMaybe<Order_By>;
  party_by_sender?: InputMaybe<Parties_Order_By>;
  sender?: InputMaybe<Order_By>;
  subject?: InputMaybe<Order_By>;
  type?: InputMaybe<Types_Order_By>;
  type_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: messages */
export type Messages_Pk_Columns_Input = {
  id: Scalars["uuid"];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Messages_Prepend_Input = {
  metadata?: InputMaybe<Scalars["jsonb"]>;
};

/** select columns of table "messages" */
export enum Messages_Select_Column {
  /** column name */
  Body = "body",
  /** column name */
  ChannelId = "channel_id",
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  Id = "id",
  /** column name */
  Metadata = "metadata",
  /** column name */
  Sender = "sender",
  /** column name */
  Subject = "subject",
  /** column name */
  TypeId = "type_id",
  /** column name */
  UpdatedAt = "updated_at",
}

/** input type for updating data in table "messages" */
export type Messages_Set_Input = {
  body?: InputMaybe<Scalars["String"]>;
  channel_id?: InputMaybe<Scalars["uuid"]>;
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  metadata?: InputMaybe<Scalars["jsonb"]>;
  sender?: InputMaybe<Scalars["uuid"]>;
  subject?: InputMaybe<Scalars["String"]>;
  type_id?: InputMaybe<Scalars["uuid"]>;
  updated_at?: InputMaybe<Scalars["timestamptz"]>;
};

/** Streaming cursor of the table "messages" */
export type Messages_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Messages_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Messages_Stream_Cursor_Value_Input = {
  body?: InputMaybe<Scalars["String"]>;
  channel_id?: InputMaybe<Scalars["uuid"]>;
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  metadata?: InputMaybe<Scalars["jsonb"]>;
  sender?: InputMaybe<Scalars["uuid"]>;
  subject?: InputMaybe<Scalars["String"]>;
  type_id?: InputMaybe<Scalars["uuid"]>;
  updated_at?: InputMaybe<Scalars["timestamptz"]>;
};

/** update columns of table "messages" */
export enum Messages_Update_Column {
  /** column name */
  Body = "body",
  /** column name */
  ChannelId = "channel_id",
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  Id = "id",
  /** column name */
  Metadata = "metadata",
  /** column name */
  Sender = "sender",
  /** column name */
  Subject = "subject",
  /** column name */
  TypeId = "type_id",
  /** column name */
  UpdatedAt = "updated_at",
}

export type Messages_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Messages_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Messages_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Messages_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Messages_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Messages_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Messages_Set_Input>;
  /** filter the rows which have to be updated */
  where: Messages_Bool_Exp;
};

/** mutation root */
export type Mutation_Root = {
  __typename?: "mutation_root";
  addMFAMethod?: Maybe<AddMfaMethodOutput>;
  approveTask?: Maybe<TaskApprovalGrantedOutput>;
  /** Cancel subscription */
  cancelSubscription?: Maybe<CancelSubscriptionOutput>;
  changePassword?: Maybe<ChangePasswordOutput>;
  completeLogin?: Maybe<CompleteLoginOutput>;
  /** Initiate member login generating OTP */
  completeMemberLogin?: Maybe<CompleteMemberLoginOutput>;
  createAndSubscribeToChannel?: Maybe<CreateAndSubscribeToChannelOutput>;
  createFromTemplate?: Maybe<CreateFromTemplateOutput>;
  createTableTrigger?: Maybe<CreateTableTriggerOutput>;
  createTemplate?: Maybe<CreateTemplateOutput>;
  create_ledger: LedgerMutationOutput;
  create_ledger_account: LedgerAccountMutationOutput;
  create_payment_intent: CreatePaymentIntentOutput;
  /** delete data from the table: "activity_logs" */
  delete_activity_logs?: Maybe<Activity_Logs_Mutation_Response>;
  /** delete single row from the table: "activity_logs" */
  delete_activity_logs_by_pk?: Maybe<Activity_Logs>;
  /** delete data from the table: "audit_logs" */
  delete_audit_logs?: Maybe<Audit_Logs_Mutation_Response>;
  /** delete single row from the table: "audit_logs" */
  delete_audit_logs_by_pk?: Maybe<Audit_Logs>;
  /** delete data from the table: "campaign_contacts" */
  delete_campaign_contacts?: Maybe<Campaign_Contacts_Mutation_Response>;
  /** delete single row from the table: "campaign_contacts" */
  delete_campaign_contacts_by_pk?: Maybe<Campaign_Contacts>;
  /** delete data from the table: "campaign_performance_indicators" */
  delete_campaign_performance_indicators?: Maybe<Campaign_Performance_Indicators_Mutation_Response>;
  /** delete single row from the table: "campaign_performance_indicators" */
  delete_campaign_performance_indicators_by_pk?: Maybe<Campaign_Performance_Indicators>;
  /** delete data from the table: "campaign_rewards" */
  delete_campaign_rewards?: Maybe<Campaign_Rewards_Mutation_Response>;
  /** delete single row from the table: "campaign_rewards" */
  delete_campaign_rewards_by_pk?: Maybe<Campaign_Rewards>;
  /** delete data from the table: "campaign_rules" */
  delete_campaign_rules?: Maybe<Campaign_Rules_Mutation_Response>;
  /** delete single row from the table: "campaign_rules" */
  delete_campaign_rules_by_pk?: Maybe<Campaign_Rules>;
  /** delete data from the table: "campaign_tasks" */
  delete_campaign_tasks?: Maybe<Campaign_Tasks_Mutation_Response>;
  /** delete single row from the table: "campaign_tasks" */
  delete_campaign_tasks_by_pk?: Maybe<Campaign_Tasks>;
  /** delete data from the table: "campaign_workflows" */
  delete_campaign_workflows?: Maybe<Campaign_Workflows_Mutation_Response>;
  /** delete single row from the table: "campaign_workflows" */
  delete_campaign_workflows_by_pk?: Maybe<Campaign_Workflows>;
  /** delete data from the table: "campaigns" */
  delete_campaigns?: Maybe<Campaigns_Mutation_Response>;
  /** delete single row from the table: "campaigns" */
  delete_campaigns_by_pk?: Maybe<Campaigns>;
  /** delete data from the table: "case_assignments" */
  delete_case_assignments?: Maybe<Case_Assignments_Mutation_Response>;
  /** delete single row from the table: "case_assignments" */
  delete_case_assignments_by_pk?: Maybe<Case_Assignments>;
  /** delete data from the table: "case_details" */
  delete_case_details?: Maybe<Case_Details_Mutation_Response>;
  /** delete single row from the table: "case_details" */
  delete_case_details_by_pk?: Maybe<Case_Details>;
  /** delete data from the table: "case_documents" */
  delete_case_documents?: Maybe<Case_Documents_Mutation_Response>;
  /** delete single row from the table: "case_documents" */
  delete_case_documents_by_pk?: Maybe<Case_Documents>;
  /** delete data from the table: "case_flags" */
  delete_case_flags?: Maybe<Case_Flags_Mutation_Response>;
  /** delete single row from the table: "case_flags" */
  delete_case_flags_by_pk?: Maybe<Case_Flags>;
  /** delete data from the table: "case_interactions" */
  delete_case_interactions?: Maybe<Case_Interactions_Mutation_Response>;
  /** delete single row from the table: "case_interactions" */
  delete_case_interactions_by_pk?: Maybe<Case_Interactions>;
  /** delete data from the table: "case_mentions" */
  delete_case_mentions?: Maybe<Case_Mentions_Mutation_Response>;
  /** delete single row from the table: "case_mentions" */
  delete_case_mentions_by_pk?: Maybe<Case_Mentions>;
  /** delete data from the table: "case_statuses" */
  delete_case_statuses?: Maybe<Case_Statuses_Mutation_Response>;
  /** delete single row from the table: "case_statuses" */
  delete_case_statuses_by_pk?: Maybe<Case_Statuses>;
  /** delete data from the table: "cases" */
  delete_cases?: Maybe<Cases_Mutation_Response>;
  /** delete single row from the table: "cases" */
  delete_cases_by_pk?: Maybe<Cases>;
  /** delete data from the table: "channel_flags" */
  delete_channel_flags?: Maybe<Channel_Flags_Mutation_Response>;
  /** delete single row from the table: "channel_flags" */
  delete_channel_flags_by_pk?: Maybe<Channel_Flags>;
  /** delete data from the table: "channel_parties" */
  delete_channel_parties?: Maybe<Channel_Parties_Mutation_Response>;
  /** delete single row from the table: "channel_parties" */
  delete_channel_parties_by_pk?: Maybe<Channel_Parties>;
  /** delete data from the table: "channel_statuses" */
  delete_channel_statuses?: Maybe<Channel_Statuses_Mutation_Response>;
  /** delete single row from the table: "channel_statuses" */
  delete_channel_statuses_by_pk?: Maybe<Channel_Statuses>;
  /** delete data from the table: "channel_subscriptions" */
  delete_channel_subscriptions?: Maybe<Channel_Subscriptions_Mutation_Response>;
  /** delete single row from the table: "channel_subscriptions" */
  delete_channel_subscriptions_by_pk?: Maybe<Channel_Subscriptions>;
  /** delete data from the table: "channels" */
  delete_channels?: Maybe<Channels_Mutation_Response>;
  /** delete single row from the table: "channels" */
  delete_channels_by_pk?: Maybe<Channels>;
  /** delete data from the table: "conditions" */
  delete_conditions?: Maybe<Conditions_Mutation_Response>;
  /** delete single row from the table: "conditions" */
  delete_conditions_by_pk?: Maybe<Conditions>;
  /** delete data from the table: "contact_details" */
  delete_contact_details?: Maybe<Contact_Details_Mutation_Response>;
  /** delete single row from the table: "contact_details" */
  delete_contact_details_by_pk?: Maybe<Contact_Details>;
  /** delete data from the table: "contact_interactions" */
  delete_contact_interactions?: Maybe<Contact_Interactions_Mutation_Response>;
  /** delete single row from the table: "contact_interactions" */
  delete_contact_interactions_by_pk?: Maybe<Contact_Interactions>;
  /** delete data from the table: "contact_relationships" */
  delete_contact_relationships?: Maybe<Contact_Relationships_Mutation_Response>;
  /** delete single row from the table: "contact_relationships" */
  delete_contact_relationships_by_pk?: Maybe<Contact_Relationships>;
  /** delete data from the table: "contacts" */
  delete_contacts?: Maybe<Contacts_Mutation_Response>;
  /** delete single row from the table: "contacts" */
  delete_contacts_by_pk?: Maybe<Contacts>;
  /** delete data from the table: "credit_profile_flags" */
  delete_credit_profile_flags?: Maybe<Credit_Profile_Flags_Mutation_Response>;
  /** delete single row from the table: "credit_profile_flags" */
  delete_credit_profile_flags_by_pk?: Maybe<Credit_Profile_Flags>;
  /** delete data from the table: "credit_profile_statuses" */
  delete_credit_profile_statuses?: Maybe<Credit_Profile_Statuses_Mutation_Response>;
  /** delete single row from the table: "credit_profile_statuses" */
  delete_credit_profile_statuses_by_pk?: Maybe<Credit_Profile_Statuses>;
  /** delete data from the table: "credit_profiles" */
  delete_credit_profiles?: Maybe<Credit_Profiles_Mutation_Response>;
  /** delete single row from the table: "credit_profiles" */
  delete_credit_profiles_by_pk?: Maybe<Credit_Profiles>;
  /** delete data from the table: "delivery_methods" */
  delete_delivery_methods?: Maybe<Delivery_Methods_Mutation_Response>;
  /** delete single row from the table: "delivery_methods" */
  delete_delivery_methods_by_pk?: Maybe<Delivery_Methods>;
  /** delete data from the table: "document_interactions" */
  delete_document_interactions?: Maybe<Document_Interactions_Mutation_Response>;
  /** delete single row from the table: "document_interactions" */
  delete_document_interactions_by_pk?: Maybe<Document_Interactions>;
  /** delete data from the table: "documents" */
  delete_documents?: Maybe<Documents_Mutation_Response>;
  /** delete single row from the table: "documents" */
  delete_documents_by_pk?: Maybe<Documents>;
  /** delete data from the table: "flags" */
  delete_flags?: Maybe<Flags_Mutation_Response>;
  /** delete single row from the table: "flags" */
  delete_flags_by_pk?: Maybe<Flags>;
  /** delete data from the table: "form_fields" */
  delete_form_fields?: Maybe<Form_Fields_Mutation_Response>;
  /** delete single row from the table: "form_fields" */
  delete_form_fields_by_pk?: Maybe<Form_Fields>;
  /** delete data from the table: "form_rules" */
  delete_form_rules?: Maybe<Form_Rules_Mutation_Response>;
  /** delete single row from the table: "form_rules" */
  delete_form_rules_by_pk?: Maybe<Form_Rules>;
  /** delete data from the table: "forms" */
  delete_forms?: Maybe<Forms_Mutation_Response>;
  /** delete single row from the table: "forms" */
  delete_forms_by_pk?: Maybe<Forms>;
  /** delete data from the table: "interaction_details" */
  delete_interaction_details?: Maybe<Interaction_Details_Mutation_Response>;
  /** delete single row from the table: "interaction_details" */
  delete_interaction_details_by_pk?: Maybe<Interaction_Details>;
  /** delete data from the table: "interactions" */
  delete_interactions?: Maybe<Interactions_Mutation_Response>;
  /** delete single row from the table: "interactions" */
  delete_interactions_by_pk?: Maybe<Interactions>;
  /** delete data from the table: "invoice_interactions" */
  delete_invoice_interactions?: Maybe<Invoice_Interactions_Mutation_Response>;
  /** delete single row from the table: "invoice_interactions" */
  delete_invoice_interactions_by_pk?: Maybe<Invoice_Interactions>;
  /** delete data from the table: "invoice_items" */
  delete_invoice_items?: Maybe<Invoice_Items_Mutation_Response>;
  /** delete single row from the table: "invoice_items" */
  delete_invoice_items_by_pk?: Maybe<Invoice_Items>;
  /** delete data from the table: "invoices" */
  delete_invoices?: Maybe<Invoices_Mutation_Response>;
  /** delete single row from the table: "invoices" */
  delete_invoices_by_pk?: Maybe<Invoices>;
  /** delete data from the table: "message_delivery_methods" */
  delete_message_delivery_methods?: Maybe<Message_Delivery_Methods_Mutation_Response>;
  /** delete single row from the table: "message_delivery_methods" */
  delete_message_delivery_methods_by_pk?: Maybe<Message_Delivery_Methods>;
  /** delete data from the table: "message_delivery_responses" */
  delete_message_delivery_responses?: Maybe<Message_Delivery_Responses_Mutation_Response>;
  /** delete single row from the table: "message_delivery_responses" */
  delete_message_delivery_responses_by_pk?: Maybe<Message_Delivery_Responses>;
  /** delete data from the table: "message_flags" */
  delete_message_flags?: Maybe<Message_Flags_Mutation_Response>;
  /** delete single row from the table: "message_flags" */
  delete_message_flags_by_pk?: Maybe<Message_Flags>;
  /** delete data from the table: "message_statuses" */
  delete_message_statuses?: Maybe<Message_Statuses_Mutation_Response>;
  /** delete single row from the table: "message_statuses" */
  delete_message_statuses_by_pk?: Maybe<Message_Statuses>;
  /** delete data from the table: "messages" */
  delete_messages?: Maybe<Messages_Mutation_Response>;
  /** delete single row from the table: "messages" */
  delete_messages_by_pk?: Maybe<Messages>;
  /** delete data from the table: "organisation_credentials" */
  delete_organisation_credentials?: Maybe<Organisation_Credentials_Mutation_Response>;
  /** delete single row from the table: "organisation_credentials" */
  delete_organisation_credentials_by_pk?: Maybe<Organisation_Credentials>;
  /** delete data from the table: "organisation_flags" */
  delete_organisation_flags?: Maybe<Organisation_Flags_Mutation_Response>;
  /** delete single row from the table: "organisation_flags" */
  delete_organisation_flags_by_pk?: Maybe<Organisation_Flags>;
  /** delete data from the table: "organisation_invites" */
  delete_organisation_invites?: Maybe<Organisation_Invites_Mutation_Response>;
  /** delete single row from the table: "organisation_invites" */
  delete_organisation_invites_by_pk?: Maybe<Organisation_Invites>;
  /** delete data from the table: "organisation_rules" */
  delete_organisation_rules?: Maybe<Organisation_Rules_Mutation_Response>;
  /** delete single row from the table: "organisation_rules" */
  delete_organisation_rules_by_pk?: Maybe<Organisation_Rules>;
  /** delete data from the table: "organisation_settings" */
  delete_organisation_settings?: Maybe<Organisation_Settings_Mutation_Response>;
  /** delete single row from the table: "organisation_settings" */
  delete_organisation_settings_by_pk?: Maybe<Organisation_Settings>;
  /** delete data from the table: "organisation_statuses" */
  delete_organisation_statuses?: Maybe<Organisation_Statuses_Mutation_Response>;
  /** delete single row from the table: "organisation_statuses" */
  delete_organisation_statuses_by_pk?: Maybe<Organisation_Statuses>;
  /** delete data from the table: "organisations" */
  delete_organisations?: Maybe<Organisations_Mutation_Response>;
  /** delete single row from the table: "organisations" */
  delete_organisations_by_pk?: Maybe<Organisations>;
  /** delete data from the table: "parties" */
  delete_parties?: Maybe<Parties_Mutation_Response>;
  /** delete single row from the table: "parties" */
  delete_parties_by_pk?: Maybe<Parties>;
  /** delete data from the table: "party_contacts" */
  delete_party_contacts?: Maybe<Party_Contacts_Mutation_Response>;
  /** delete single row from the table: "party_contacts" */
  delete_party_contacts_by_pk?: Maybe<Party_Contacts>;
  /** delete data from the table: "party_documents" */
  delete_party_documents?: Maybe<Party_Documents_Mutation_Response>;
  /** delete single row from the table: "party_documents" */
  delete_party_documents_by_pk?: Maybe<Party_Documents>;
  /** delete data from the table: "party_flags" */
  delete_party_flags?: Maybe<Party_Flags_Mutation_Response>;
  /** delete single row from the table: "party_flags" */
  delete_party_flags_by_pk?: Maybe<Party_Flags>;
  /** delete data from the table: "party_interactions" */
  delete_party_interactions?: Maybe<Party_Interactions_Mutation_Response>;
  /** delete single row from the table: "party_interactions" */
  delete_party_interactions_by_pk?: Maybe<Party_Interactions>;
  /** delete data from the table: "party_ledger_accounts" */
  delete_party_ledger_accounts?: Maybe<Party_Ledger_Accounts_Mutation_Response>;
  /** delete single row from the table: "party_ledger_accounts" */
  delete_party_ledger_accounts_by_pk?: Maybe<Party_Ledger_Accounts>;
  /** delete data from the table: "party_relationship_statuses" */
  delete_party_relationship_statuses?: Maybe<Party_Relationship_Statuses_Mutation_Response>;
  /** delete single row from the table: "party_relationship_statuses" */
  delete_party_relationship_statuses_by_pk?: Maybe<Party_Relationship_Statuses>;
  /** delete data from the table: "party_relationships" */
  delete_party_relationships?: Maybe<Party_Relationships_Mutation_Response>;
  /** delete single row from the table: "party_relationships" */
  delete_party_relationships_by_pk?: Maybe<Party_Relationships>;
  /** delete data from the table: "party_settings" */
  delete_party_settings?: Maybe<Party_Settings_Mutation_Response>;
  /** delete single row from the table: "party_settings" */
  delete_party_settings_by_pk?: Maybe<Party_Settings>;
  /** delete data from the table: "party_statuses" */
  delete_party_statuses?: Maybe<Party_Statuses_Mutation_Response>;
  /** delete single row from the table: "party_statuses" */
  delete_party_statuses_by_pk?: Maybe<Party_Statuses>;
  /** delete data from the table: "performance_indicators" */
  delete_performance_indicators?: Maybe<Performance_Indicators_Mutation_Response>;
  /** delete single row from the table: "performance_indicators" */
  delete_performance_indicators_by_pk?: Maybe<Performance_Indicators>;
  /** delete data from the table: "performance_targets" */
  delete_performance_targets?: Maybe<Performance_Targets_Mutation_Response>;
  /** delete single row from the table: "performance_targets" */
  delete_performance_targets_by_pk?: Maybe<Performance_Targets>;
  /** delete data from the table: "product_documents" */
  delete_product_documents?: Maybe<Product_Documents_Mutation_Response>;
  /** delete single row from the table: "product_documents" */
  delete_product_documents_by_pk?: Maybe<Product_Documents>;
  /** delete data from the table: "product_interactions" */
  delete_product_interactions?: Maybe<Product_Interactions_Mutation_Response>;
  /** delete single row from the table: "product_interactions" */
  delete_product_interactions_by_pk?: Maybe<Product_Interactions>;
  /** delete data from the table: "product_items" */
  delete_product_items?: Maybe<Product_Items_Mutation_Response>;
  /** delete single row from the table: "product_items" */
  delete_product_items_by_pk?: Maybe<Product_Items>;
  /** delete data from the table: "product_rules" */
  delete_product_rules?: Maybe<Product_Rules_Mutation_Response>;
  /** delete single row from the table: "product_rules" */
  delete_product_rules_by_pk?: Maybe<Product_Rules>;
  /** delete data from the table: "product_settings" */
  delete_product_settings?: Maybe<Product_Settings_Mutation_Response>;
  /** delete single row from the table: "product_settings" */
  delete_product_settings_by_pk?: Maybe<Product_Settings>;
  /** delete data from the table: "product_statuses" */
  delete_product_statuses?: Maybe<Product_Statuses_Mutation_Response>;
  /** delete single row from the table: "product_statuses" */
  delete_product_statuses_by_pk?: Maybe<Product_Statuses>;
  /** delete data from the table: "product_templates" */
  delete_product_templates?: Maybe<Product_Templates_Mutation_Response>;
  /** delete single row from the table: "product_templates" */
  delete_product_templates_by_pk?: Maybe<Product_Templates>;
  /** delete data from the table: "products" */
  delete_products?: Maybe<Products_Mutation_Response>;
  /** delete single row from the table: "products" */
  delete_products_by_pk?: Maybe<Products>;
  /** delete data from the table: "reward_redemptions" */
  delete_reward_redemptions?: Maybe<Reward_Redemptions_Mutation_Response>;
  /** delete single row from the table: "reward_redemptions" */
  delete_reward_redemptions_by_pk?: Maybe<Reward_Redemptions>;
  /** delete data from the table: "rewards" */
  delete_rewards?: Maybe<Rewards_Mutation_Response>;
  /** delete single row from the table: "rewards" */
  delete_rewards_by_pk?: Maybe<Rewards>;
  /** delete data from the table: "rules" */
  delete_rules?: Maybe<Rules_Mutation_Response>;
  /** delete single row from the table: "rules" */
  delete_rules_by_pk?: Maybe<Rules>;
  /** delete data from the table: "statuses" */
  delete_statuses?: Maybe<Statuses_Mutation_Response>;
  /** delete single row from the table: "statuses" */
  delete_statuses_by_pk?: Maybe<Statuses>;
  /** delete data from the table: "subscription_cases" */
  delete_subscription_cases?: Maybe<Subscription_Cases_Mutation_Response>;
  /** delete single row from the table: "subscription_cases" */
  delete_subscription_cases_by_pk?: Maybe<Subscription_Cases>;
  /** delete data from the table: "subscription_details" */
  delete_subscription_details?: Maybe<Subscription_Details_Mutation_Response>;
  /** delete single row from the table: "subscription_details" */
  delete_subscription_details_by_pk?: Maybe<Subscription_Details>;
  /** delete data from the table: "subscription_documents" */
  delete_subscription_documents?: Maybe<Subscription_Documents_Mutation_Response>;
  /** delete single row from the table: "subscription_documents" */
  delete_subscription_documents_by_pk?: Maybe<Subscription_Documents>;
  /** delete data from the table: "subscription_flags" */
  delete_subscription_flags?: Maybe<Subscription_Flags_Mutation_Response>;
  /** delete single row from the table: "subscription_flags" */
  delete_subscription_flags_by_pk?: Maybe<Subscription_Flags>;
  /** delete data from the table: "subscription_interactions" */
  delete_subscription_interactions?: Maybe<Subscription_Interactions_Mutation_Response>;
  /** delete single row from the table: "subscription_interactions" */
  delete_subscription_interactions_by_pk?: Maybe<Subscription_Interactions>;
  /** delete data from the table: "subscription_items" */
  delete_subscription_items?: Maybe<Subscription_Items_Mutation_Response>;
  /** delete single row from the table: "subscription_items" */
  delete_subscription_items_by_pk?: Maybe<Subscription_Items>;
  /** delete data from the table: "subscription_payment_transactions" */
  delete_subscription_payment_transactions?: Maybe<Subscription_Payment_Transactions_Mutation_Response>;
  /** delete single row from the table: "subscription_payment_transactions" */
  delete_subscription_payment_transactions_by_pk?: Maybe<Subscription_Payment_Transactions>;
  /** delete data from the table: "subscription_payments" */
  delete_subscription_payments?: Maybe<Subscription_Payments_Mutation_Response>;
  /** delete single row from the table: "subscription_payments" */
  delete_subscription_payments_by_pk?: Maybe<Subscription_Payments>;
  /** delete data from the table: "subscription_rules" */
  delete_subscription_rules?: Maybe<Subscription_Rules_Mutation_Response>;
  /** delete single row from the table: "subscription_rules" */
  delete_subscription_rules_by_pk?: Maybe<Subscription_Rules>;
  /** delete data from the table: "subscription_schedules" */
  delete_subscription_schedules?: Maybe<Subscription_Schedules_Mutation_Response>;
  /** delete single row from the table: "subscription_schedules" */
  delete_subscription_schedules_by_pk?: Maybe<Subscription_Schedules>;
  /** delete data from the table: "subscription_statuses" */
  delete_subscription_statuses?: Maybe<Subscription_Statuses_Mutation_Response>;
  /** delete single row from the table: "subscription_statuses" */
  delete_subscription_statuses_by_pk?: Maybe<Subscription_Statuses>;
  /** delete data from the table: "subscription_tasks" */
  delete_subscription_tasks?: Maybe<Subscription_Tasks_Mutation_Response>;
  /** delete single row from the table: "subscription_tasks" */
  delete_subscription_tasks_by_pk?: Maybe<Subscription_Tasks>;
  /** delete data from the table: "subscriptions" */
  delete_subscriptions?: Maybe<Subscriptions_Mutation_Response>;
  /** delete single row from the table: "subscriptions" */
  delete_subscriptions_by_pk?: Maybe<Subscriptions>;
  /** delete data from the table: "tariff_histories" */
  delete_tariff_histories?: Maybe<Tariff_Histories_Mutation_Response>;
  /** delete single row from the table: "tariff_histories" */
  delete_tariff_histories_by_pk?: Maybe<Tariff_Histories>;
  /** delete data from the table: "tariffs" */
  delete_tariffs?: Maybe<Tariffs_Mutation_Response>;
  /** delete single row from the table: "tariffs" */
  delete_tariffs_by_pk?: Maybe<Tariffs>;
  /** delete data from the table: "task_assignments" */
  delete_task_assignments?: Maybe<Task_Assignments_Mutation_Response>;
  /** delete single row from the table: "task_assignments" */
  delete_task_assignments_by_pk?: Maybe<Task_Assignments>;
  /** delete data from the table: "task_documents" */
  delete_task_documents?: Maybe<Task_Documents_Mutation_Response>;
  /** delete single row from the table: "task_documents" */
  delete_task_documents_by_pk?: Maybe<Task_Documents>;
  /** delete data from the table: "task_flags" */
  delete_task_flags?: Maybe<Task_Flags_Mutation_Response>;
  /** delete single row from the table: "task_flags" */
  delete_task_flags_by_pk?: Maybe<Task_Flags>;
  /** delete data from the table: "task_interactions" */
  delete_task_interactions?: Maybe<Task_Interactions_Mutation_Response>;
  /** delete single row from the table: "task_interactions" */
  delete_task_interactions_by_pk?: Maybe<Task_Interactions>;
  /** delete data from the table: "task_mentions" */
  delete_task_mentions?: Maybe<Task_Mentions_Mutation_Response>;
  /** delete single row from the table: "task_mentions" */
  delete_task_mentions_by_pk?: Maybe<Task_Mentions>;
  /** delete data from the table: "task_reminders" */
  delete_task_reminders?: Maybe<Task_Reminders_Mutation_Response>;
  /** delete single row from the table: "task_reminders" */
  delete_task_reminders_by_pk?: Maybe<Task_Reminders>;
  /** delete data from the table: "task_rules" */
  delete_task_rules?: Maybe<Task_Rules_Mutation_Response>;
  /** delete single row from the table: "task_rules" */
  delete_task_rules_by_pk?: Maybe<Task_Rules>;
  /** delete data from the table: "task_statuses" */
  delete_task_statuses?: Maybe<Task_Statuses_Mutation_Response>;
  /** delete single row from the table: "task_statuses" */
  delete_task_statuses_by_pk?: Maybe<Task_Statuses>;
  /** delete data from the table: "tasks" */
  delete_tasks?: Maybe<Tasks_Mutation_Response>;
  /** delete single row from the table: "tasks" */
  delete_tasks_by_pk?: Maybe<Tasks>;
  /** delete data from the table: "territories" */
  delete_territories?: Maybe<Territories_Mutation_Response>;
  /** delete single row from the table: "territories" */
  delete_territories_by_pk?: Maybe<Territories>;
  /** delete data from the table: "territory_assignments" */
  delete_territory_assignments?: Maybe<Territory_Assignments_Mutation_Response>;
  /** delete single row from the table: "territory_assignments" */
  delete_territory_assignments_by_pk?: Maybe<Territory_Assignments>;
  /** delete data from the table: "territory_flags" */
  delete_territory_flags?: Maybe<Territory_Flags_Mutation_Response>;
  /** delete single row from the table: "territory_flags" */
  delete_territory_flags_by_pk?: Maybe<Territory_Flags>;
  /** delete data from the table: "territory_interactions" */
  delete_territory_interactions?: Maybe<Territory_Interactions_Mutation_Response>;
  /** delete single row from the table: "territory_interactions" */
  delete_territory_interactions_by_pk?: Maybe<Territory_Interactions>;
  /** delete data from the table: "territory_members" */
  delete_territory_members?: Maybe<Territory_Members_Mutation_Response>;
  /** delete single row from the table: "territory_members" */
  delete_territory_members_by_pk?: Maybe<Territory_Members>;
  /** delete data from the table: "territory_rules" */
  delete_territory_rules?: Maybe<Territory_Rules_Mutation_Response>;
  /** delete single row from the table: "territory_rules" */
  delete_territory_rules_by_pk?: Maybe<Territory_Rules>;
  /** delete data from the table: "territory_statuses" */
  delete_territory_statuses?: Maybe<Territory_Statuses_Mutation_Response>;
  /** delete single row from the table: "territory_statuses" */
  delete_territory_statuses_by_pk?: Maybe<Territory_Statuses>;
  /** delete data from the table: "types" */
  delete_types?: Maybe<Types_Mutation_Response>;
  /** delete single row from the table: "types" */
  delete_types_by_pk?: Maybe<Types>;
  /** delete data from the table: "user_flags" */
  delete_user_flags?: Maybe<User_Flags_Mutation_Response>;
  /** delete single row from the table: "user_flags" */
  delete_user_flags_by_pk?: Maybe<User_Flags>;
  /** delete data from the table: "user_mfa_factors" */
  delete_user_mfa_factors?: Maybe<User_Mfa_Factors_Mutation_Response>;
  /** delete single row from the table: "user_mfa_factors" */
  delete_user_mfa_factors_by_pk?: Maybe<User_Mfa_Factors>;
  /** delete data from the table: "user_statuses" */
  delete_user_statuses?: Maybe<User_Statuses_Mutation_Response>;
  /** delete single row from the table: "user_statuses" */
  delete_user_statuses_by_pk?: Maybe<User_Statuses>;
  /** delete data from the table: "users" */
  delete_users?: Maybe<Users_Mutation_Response>;
  /** delete single row from the table: "users" */
  delete_users_by_pk?: Maybe<Users>;
  /** delete data from the table: "webhooks" */
  delete_webhooks?: Maybe<Webhooks_Mutation_Response>;
  /** delete single row from the table: "webhooks" */
  delete_webhooks_by_pk?: Maybe<Webhooks>;
  /** delete data from the table: "workflow_templates" */
  delete_workflow_templates?: Maybe<Workflow_Templates_Mutation_Response>;
  /** delete single row from the table: "workflow_templates" */
  delete_workflow_templates_by_pk?: Maybe<Workflow_Templates>;
  duplicateForm?: Maybe<DuplicateFormOutput>;
  duplicateTask?: Maybe<DuplicateTaskOutput>;
  /** Handles editing a workflow submission */
  editWorkflowSubmission?: Maybe<EditWorkflowSubmissionOutput>;
  generateMagicLoginLink?: Maybe<GenerateMagicLoginLinkOutput>;
  initiatePasswordReset?: Maybe<IntiatePasswordResetOutput>;
  /** insert data into the table: "activity_logs" */
  insert_activity_logs?: Maybe<Activity_Logs_Mutation_Response>;
  /** insert a single row into the table: "activity_logs" */
  insert_activity_logs_one?: Maybe<Activity_Logs>;
  /** insert data into the table: "audit_logs" */
  insert_audit_logs?: Maybe<Audit_Logs_Mutation_Response>;
  /** insert a single row into the table: "audit_logs" */
  insert_audit_logs_one?: Maybe<Audit_Logs>;
  /** insert data into the table: "campaign_contacts" */
  insert_campaign_contacts?: Maybe<Campaign_Contacts_Mutation_Response>;
  /** insert a single row into the table: "campaign_contacts" */
  insert_campaign_contacts_one?: Maybe<Campaign_Contacts>;
  /** insert data into the table: "campaign_performance_indicators" */
  insert_campaign_performance_indicators?: Maybe<Campaign_Performance_Indicators_Mutation_Response>;
  /** insert a single row into the table: "campaign_performance_indicators" */
  insert_campaign_performance_indicators_one?: Maybe<Campaign_Performance_Indicators>;
  /** insert data into the table: "campaign_rewards" */
  insert_campaign_rewards?: Maybe<Campaign_Rewards_Mutation_Response>;
  /** insert a single row into the table: "campaign_rewards" */
  insert_campaign_rewards_one?: Maybe<Campaign_Rewards>;
  /** insert data into the table: "campaign_rules" */
  insert_campaign_rules?: Maybe<Campaign_Rules_Mutation_Response>;
  /** insert a single row into the table: "campaign_rules" */
  insert_campaign_rules_one?: Maybe<Campaign_Rules>;
  /** insert data into the table: "campaign_tasks" */
  insert_campaign_tasks?: Maybe<Campaign_Tasks_Mutation_Response>;
  /** insert a single row into the table: "campaign_tasks" */
  insert_campaign_tasks_one?: Maybe<Campaign_Tasks>;
  /** insert data into the table: "campaign_workflows" */
  insert_campaign_workflows?: Maybe<Campaign_Workflows_Mutation_Response>;
  /** insert a single row into the table: "campaign_workflows" */
  insert_campaign_workflows_one?: Maybe<Campaign_Workflows>;
  /** insert data into the table: "campaigns" */
  insert_campaigns?: Maybe<Campaigns_Mutation_Response>;
  /** insert a single row into the table: "campaigns" */
  insert_campaigns_one?: Maybe<Campaigns>;
  /** insert data into the table: "case_assignments" */
  insert_case_assignments?: Maybe<Case_Assignments_Mutation_Response>;
  /** insert a single row into the table: "case_assignments" */
  insert_case_assignments_one?: Maybe<Case_Assignments>;
  /** insert data into the table: "case_details" */
  insert_case_details?: Maybe<Case_Details_Mutation_Response>;
  /** insert a single row into the table: "case_details" */
  insert_case_details_one?: Maybe<Case_Details>;
  /** insert data into the table: "case_documents" */
  insert_case_documents?: Maybe<Case_Documents_Mutation_Response>;
  /** insert a single row into the table: "case_documents" */
  insert_case_documents_one?: Maybe<Case_Documents>;
  /** insert data into the table: "case_flags" */
  insert_case_flags?: Maybe<Case_Flags_Mutation_Response>;
  /** insert a single row into the table: "case_flags" */
  insert_case_flags_one?: Maybe<Case_Flags>;
  /** insert data into the table: "case_interactions" */
  insert_case_interactions?: Maybe<Case_Interactions_Mutation_Response>;
  /** insert a single row into the table: "case_interactions" */
  insert_case_interactions_one?: Maybe<Case_Interactions>;
  /** insert data into the table: "case_mentions" */
  insert_case_mentions?: Maybe<Case_Mentions_Mutation_Response>;
  /** insert a single row into the table: "case_mentions" */
  insert_case_mentions_one?: Maybe<Case_Mentions>;
  /** insert data into the table: "case_statuses" */
  insert_case_statuses?: Maybe<Case_Statuses_Mutation_Response>;
  /** insert a single row into the table: "case_statuses" */
  insert_case_statuses_one?: Maybe<Case_Statuses>;
  /** insert data into the table: "cases" */
  insert_cases?: Maybe<Cases_Mutation_Response>;
  /** insert a single row into the table: "cases" */
  insert_cases_one?: Maybe<Cases>;
  /** insert data into the table: "channel_flags" */
  insert_channel_flags?: Maybe<Channel_Flags_Mutation_Response>;
  /** insert a single row into the table: "channel_flags" */
  insert_channel_flags_one?: Maybe<Channel_Flags>;
  /** insert data into the table: "channel_parties" */
  insert_channel_parties?: Maybe<Channel_Parties_Mutation_Response>;
  /** insert a single row into the table: "channel_parties" */
  insert_channel_parties_one?: Maybe<Channel_Parties>;
  /** insert data into the table: "channel_statuses" */
  insert_channel_statuses?: Maybe<Channel_Statuses_Mutation_Response>;
  /** insert a single row into the table: "channel_statuses" */
  insert_channel_statuses_one?: Maybe<Channel_Statuses>;
  /** insert data into the table: "channel_subscriptions" */
  insert_channel_subscriptions?: Maybe<Channel_Subscriptions_Mutation_Response>;
  /** insert a single row into the table: "channel_subscriptions" */
  insert_channel_subscriptions_one?: Maybe<Channel_Subscriptions>;
  /** insert data into the table: "channels" */
  insert_channels?: Maybe<Channels_Mutation_Response>;
  /** insert a single row into the table: "channels" */
  insert_channels_one?: Maybe<Channels>;
  /** insert data into the table: "conditions" */
  insert_conditions?: Maybe<Conditions_Mutation_Response>;
  /** insert a single row into the table: "conditions" */
  insert_conditions_one?: Maybe<Conditions>;
  /** insert data into the table: "contact_details" */
  insert_contact_details?: Maybe<Contact_Details_Mutation_Response>;
  /** insert a single row into the table: "contact_details" */
  insert_contact_details_one?: Maybe<Contact_Details>;
  /** insert data into the table: "contact_interactions" */
  insert_contact_interactions?: Maybe<Contact_Interactions_Mutation_Response>;
  /** insert a single row into the table: "contact_interactions" */
  insert_contact_interactions_one?: Maybe<Contact_Interactions>;
  /** insert data into the table: "contact_relationships" */
  insert_contact_relationships?: Maybe<Contact_Relationships_Mutation_Response>;
  /** insert a single row into the table: "contact_relationships" */
  insert_contact_relationships_one?: Maybe<Contact_Relationships>;
  /** insert data into the table: "contacts" */
  insert_contacts?: Maybe<Contacts_Mutation_Response>;
  /** insert a single row into the table: "contacts" */
  insert_contacts_one?: Maybe<Contacts>;
  /** insert data into the table: "credit_profile_flags" */
  insert_credit_profile_flags?: Maybe<Credit_Profile_Flags_Mutation_Response>;
  /** insert a single row into the table: "credit_profile_flags" */
  insert_credit_profile_flags_one?: Maybe<Credit_Profile_Flags>;
  /** insert data into the table: "credit_profile_statuses" */
  insert_credit_profile_statuses?: Maybe<Credit_Profile_Statuses_Mutation_Response>;
  /** insert a single row into the table: "credit_profile_statuses" */
  insert_credit_profile_statuses_one?: Maybe<Credit_Profile_Statuses>;
  /** insert data into the table: "credit_profiles" */
  insert_credit_profiles?: Maybe<Credit_Profiles_Mutation_Response>;
  /** insert a single row into the table: "credit_profiles" */
  insert_credit_profiles_one?: Maybe<Credit_Profiles>;
  /** insert data into the table: "delivery_methods" */
  insert_delivery_methods?: Maybe<Delivery_Methods_Mutation_Response>;
  /** insert a single row into the table: "delivery_methods" */
  insert_delivery_methods_one?: Maybe<Delivery_Methods>;
  /** insert data into the table: "document_interactions" */
  insert_document_interactions?: Maybe<Document_Interactions_Mutation_Response>;
  /** insert a single row into the table: "document_interactions" */
  insert_document_interactions_one?: Maybe<Document_Interactions>;
  /** insert data into the table: "documents" */
  insert_documents?: Maybe<Documents_Mutation_Response>;
  /** insert a single row into the table: "documents" */
  insert_documents_one?: Maybe<Documents>;
  /** insert data into the table: "flags" */
  insert_flags?: Maybe<Flags_Mutation_Response>;
  /** insert a single row into the table: "flags" */
  insert_flags_one?: Maybe<Flags>;
  /** insert data into the table: "form_fields" */
  insert_form_fields?: Maybe<Form_Fields_Mutation_Response>;
  /** insert a single row into the table: "form_fields" */
  insert_form_fields_one?: Maybe<Form_Fields>;
  /** insert data into the table: "form_rules" */
  insert_form_rules?: Maybe<Form_Rules_Mutation_Response>;
  /** insert a single row into the table: "form_rules" */
  insert_form_rules_one?: Maybe<Form_Rules>;
  /** insert data into the table: "forms" */
  insert_forms?: Maybe<Forms_Mutation_Response>;
  /** insert a single row into the table: "forms" */
  insert_forms_one?: Maybe<Forms>;
  /** insert data into the table: "interaction_details" */
  insert_interaction_details?: Maybe<Interaction_Details_Mutation_Response>;
  /** insert a single row into the table: "interaction_details" */
  insert_interaction_details_one?: Maybe<Interaction_Details>;
  /** insert data into the table: "interactions" */
  insert_interactions?: Maybe<Interactions_Mutation_Response>;
  /** insert a single row into the table: "interactions" */
  insert_interactions_one?: Maybe<Interactions>;
  /** insert data into the table: "invoice_interactions" */
  insert_invoice_interactions?: Maybe<Invoice_Interactions_Mutation_Response>;
  /** insert a single row into the table: "invoice_interactions" */
  insert_invoice_interactions_one?: Maybe<Invoice_Interactions>;
  /** insert data into the table: "invoice_items" */
  insert_invoice_items?: Maybe<Invoice_Items_Mutation_Response>;
  /** insert a single row into the table: "invoice_items" */
  insert_invoice_items_one?: Maybe<Invoice_Items>;
  /** insert data into the table: "invoices" */
  insert_invoices?: Maybe<Invoices_Mutation_Response>;
  /** insert a single row into the table: "invoices" */
  insert_invoices_one?: Maybe<Invoices>;
  /** insert data into the table: "message_delivery_methods" */
  insert_message_delivery_methods?: Maybe<Message_Delivery_Methods_Mutation_Response>;
  /** insert a single row into the table: "message_delivery_methods" */
  insert_message_delivery_methods_one?: Maybe<Message_Delivery_Methods>;
  /** insert data into the table: "message_delivery_responses" */
  insert_message_delivery_responses?: Maybe<Message_Delivery_Responses_Mutation_Response>;
  /** insert a single row into the table: "message_delivery_responses" */
  insert_message_delivery_responses_one?: Maybe<Message_Delivery_Responses>;
  /** insert data into the table: "message_flags" */
  insert_message_flags?: Maybe<Message_Flags_Mutation_Response>;
  /** insert a single row into the table: "message_flags" */
  insert_message_flags_one?: Maybe<Message_Flags>;
  /** insert data into the table: "message_statuses" */
  insert_message_statuses?: Maybe<Message_Statuses_Mutation_Response>;
  /** insert a single row into the table: "message_statuses" */
  insert_message_statuses_one?: Maybe<Message_Statuses>;
  /** insert data into the table: "messages" */
  insert_messages?: Maybe<Messages_Mutation_Response>;
  /** insert a single row into the table: "messages" */
  insert_messages_one?: Maybe<Messages>;
  /** insert data into the table: "organisation_credentials" */
  insert_organisation_credentials?: Maybe<Organisation_Credentials_Mutation_Response>;
  /** insert a single row into the table: "organisation_credentials" */
  insert_organisation_credentials_one?: Maybe<Organisation_Credentials>;
  /** insert data into the table: "organisation_flags" */
  insert_organisation_flags?: Maybe<Organisation_Flags_Mutation_Response>;
  /** insert a single row into the table: "organisation_flags" */
  insert_organisation_flags_one?: Maybe<Organisation_Flags>;
  /** insert data into the table: "organisation_invites" */
  insert_organisation_invites?: Maybe<Organisation_Invites_Mutation_Response>;
  /** insert a single row into the table: "organisation_invites" */
  insert_organisation_invites_one?: Maybe<Organisation_Invites>;
  /** insert data into the table: "organisation_rules" */
  insert_organisation_rules?: Maybe<Organisation_Rules_Mutation_Response>;
  /** insert a single row into the table: "organisation_rules" */
  insert_organisation_rules_one?: Maybe<Organisation_Rules>;
  /** insert data into the table: "organisation_settings" */
  insert_organisation_settings?: Maybe<Organisation_Settings_Mutation_Response>;
  /** insert a single row into the table: "organisation_settings" */
  insert_organisation_settings_one?: Maybe<Organisation_Settings>;
  /** insert data into the table: "organisation_statuses" */
  insert_organisation_statuses?: Maybe<Organisation_Statuses_Mutation_Response>;
  /** insert a single row into the table: "organisation_statuses" */
  insert_organisation_statuses_one?: Maybe<Organisation_Statuses>;
  /** insert data into the table: "organisations" */
  insert_organisations?: Maybe<Organisations_Mutation_Response>;
  /** insert a single row into the table: "organisations" */
  insert_organisations_one?: Maybe<Organisations>;
  /** insert data into the table: "parties" */
  insert_parties?: Maybe<Parties_Mutation_Response>;
  /** insert a single row into the table: "parties" */
  insert_parties_one?: Maybe<Parties>;
  /** insert data into the table: "party_contacts" */
  insert_party_contacts?: Maybe<Party_Contacts_Mutation_Response>;
  /** insert a single row into the table: "party_contacts" */
  insert_party_contacts_one?: Maybe<Party_Contacts>;
  /** insert data into the table: "party_documents" */
  insert_party_documents?: Maybe<Party_Documents_Mutation_Response>;
  /** insert a single row into the table: "party_documents" */
  insert_party_documents_one?: Maybe<Party_Documents>;
  /** insert data into the table: "party_flags" */
  insert_party_flags?: Maybe<Party_Flags_Mutation_Response>;
  /** insert a single row into the table: "party_flags" */
  insert_party_flags_one?: Maybe<Party_Flags>;
  /** insert data into the table: "party_interactions" */
  insert_party_interactions?: Maybe<Party_Interactions_Mutation_Response>;
  /** insert a single row into the table: "party_interactions" */
  insert_party_interactions_one?: Maybe<Party_Interactions>;
  /** insert data into the table: "party_ledger_accounts" */
  insert_party_ledger_accounts?: Maybe<Party_Ledger_Accounts_Mutation_Response>;
  /** insert a single row into the table: "party_ledger_accounts" */
  insert_party_ledger_accounts_one?: Maybe<Party_Ledger_Accounts>;
  /** insert data into the table: "party_relationship_statuses" */
  insert_party_relationship_statuses?: Maybe<Party_Relationship_Statuses_Mutation_Response>;
  /** insert a single row into the table: "party_relationship_statuses" */
  insert_party_relationship_statuses_one?: Maybe<Party_Relationship_Statuses>;
  /** insert data into the table: "party_relationships" */
  insert_party_relationships?: Maybe<Party_Relationships_Mutation_Response>;
  /** insert a single row into the table: "party_relationships" */
  insert_party_relationships_one?: Maybe<Party_Relationships>;
  /** insert data into the table: "party_settings" */
  insert_party_settings?: Maybe<Party_Settings_Mutation_Response>;
  /** insert a single row into the table: "party_settings" */
  insert_party_settings_one?: Maybe<Party_Settings>;
  /** insert data into the table: "party_statuses" */
  insert_party_statuses?: Maybe<Party_Statuses_Mutation_Response>;
  /** insert a single row into the table: "party_statuses" */
  insert_party_statuses_one?: Maybe<Party_Statuses>;
  /** insert data into the table: "performance_indicators" */
  insert_performance_indicators?: Maybe<Performance_Indicators_Mutation_Response>;
  /** insert a single row into the table: "performance_indicators" */
  insert_performance_indicators_one?: Maybe<Performance_Indicators>;
  /** insert data into the table: "performance_targets" */
  insert_performance_targets?: Maybe<Performance_Targets_Mutation_Response>;
  /** insert a single row into the table: "performance_targets" */
  insert_performance_targets_one?: Maybe<Performance_Targets>;
  /** insert data into the table: "product_documents" */
  insert_product_documents?: Maybe<Product_Documents_Mutation_Response>;
  /** insert a single row into the table: "product_documents" */
  insert_product_documents_one?: Maybe<Product_Documents>;
  /** insert data into the table: "product_interactions" */
  insert_product_interactions?: Maybe<Product_Interactions_Mutation_Response>;
  /** insert a single row into the table: "product_interactions" */
  insert_product_interactions_one?: Maybe<Product_Interactions>;
  /** insert data into the table: "product_items" */
  insert_product_items?: Maybe<Product_Items_Mutation_Response>;
  /** insert a single row into the table: "product_items" */
  insert_product_items_one?: Maybe<Product_Items>;
  /** insert data into the table: "product_rules" */
  insert_product_rules?: Maybe<Product_Rules_Mutation_Response>;
  /** insert a single row into the table: "product_rules" */
  insert_product_rules_one?: Maybe<Product_Rules>;
  /** insert data into the table: "product_settings" */
  insert_product_settings?: Maybe<Product_Settings_Mutation_Response>;
  /** insert a single row into the table: "product_settings" */
  insert_product_settings_one?: Maybe<Product_Settings>;
  /** insert data into the table: "product_statuses" */
  insert_product_statuses?: Maybe<Product_Statuses_Mutation_Response>;
  /** insert a single row into the table: "product_statuses" */
  insert_product_statuses_one?: Maybe<Product_Statuses>;
  /** insert data into the table: "product_templates" */
  insert_product_templates?: Maybe<Product_Templates_Mutation_Response>;
  /** insert a single row into the table: "product_templates" */
  insert_product_templates_one?: Maybe<Product_Templates>;
  /** insert data into the table: "products" */
  insert_products?: Maybe<Products_Mutation_Response>;
  /** insert a single row into the table: "products" */
  insert_products_one?: Maybe<Products>;
  /** insert data into the table: "reward_redemptions" */
  insert_reward_redemptions?: Maybe<Reward_Redemptions_Mutation_Response>;
  /** insert a single row into the table: "reward_redemptions" */
  insert_reward_redemptions_one?: Maybe<Reward_Redemptions>;
  /** insert data into the table: "rewards" */
  insert_rewards?: Maybe<Rewards_Mutation_Response>;
  /** insert a single row into the table: "rewards" */
  insert_rewards_one?: Maybe<Rewards>;
  /** insert data into the table: "rules" */
  insert_rules?: Maybe<Rules_Mutation_Response>;
  /** insert a single row into the table: "rules" */
  insert_rules_one?: Maybe<Rules>;
  /** insert data into the table: "statuses" */
  insert_statuses?: Maybe<Statuses_Mutation_Response>;
  /** insert a single row into the table: "statuses" */
  insert_statuses_one?: Maybe<Statuses>;
  /** insert data into the table: "subscription_cases" */
  insert_subscription_cases?: Maybe<Subscription_Cases_Mutation_Response>;
  /** insert a single row into the table: "subscription_cases" */
  insert_subscription_cases_one?: Maybe<Subscription_Cases>;
  /** insert data into the table: "subscription_details" */
  insert_subscription_details?: Maybe<Subscription_Details_Mutation_Response>;
  /** insert a single row into the table: "subscription_details" */
  insert_subscription_details_one?: Maybe<Subscription_Details>;
  /** insert data into the table: "subscription_documents" */
  insert_subscription_documents?: Maybe<Subscription_Documents_Mutation_Response>;
  /** insert a single row into the table: "subscription_documents" */
  insert_subscription_documents_one?: Maybe<Subscription_Documents>;
  /** insert data into the table: "subscription_flags" */
  insert_subscription_flags?: Maybe<Subscription_Flags_Mutation_Response>;
  /** insert a single row into the table: "subscription_flags" */
  insert_subscription_flags_one?: Maybe<Subscription_Flags>;
  /** insert data into the table: "subscription_interactions" */
  insert_subscription_interactions?: Maybe<Subscription_Interactions_Mutation_Response>;
  /** insert a single row into the table: "subscription_interactions" */
  insert_subscription_interactions_one?: Maybe<Subscription_Interactions>;
  /** insert data into the table: "subscription_items" */
  insert_subscription_items?: Maybe<Subscription_Items_Mutation_Response>;
  /** insert a single row into the table: "subscription_items" */
  insert_subscription_items_one?: Maybe<Subscription_Items>;
  /** insert data into the table: "subscription_payment_transactions" */
  insert_subscription_payment_transactions?: Maybe<Subscription_Payment_Transactions_Mutation_Response>;
  /** insert a single row into the table: "subscription_payment_transactions" */
  insert_subscription_payment_transactions_one?: Maybe<Subscription_Payment_Transactions>;
  /** insert data into the table: "subscription_payments" */
  insert_subscription_payments?: Maybe<Subscription_Payments_Mutation_Response>;
  /** insert a single row into the table: "subscription_payments" */
  insert_subscription_payments_one?: Maybe<Subscription_Payments>;
  /** insert data into the table: "subscription_rules" */
  insert_subscription_rules?: Maybe<Subscription_Rules_Mutation_Response>;
  /** insert a single row into the table: "subscription_rules" */
  insert_subscription_rules_one?: Maybe<Subscription_Rules>;
  /** insert data into the table: "subscription_schedules" */
  insert_subscription_schedules?: Maybe<Subscription_Schedules_Mutation_Response>;
  /** insert a single row into the table: "subscription_schedules" */
  insert_subscription_schedules_one?: Maybe<Subscription_Schedules>;
  /** insert data into the table: "subscription_statuses" */
  insert_subscription_statuses?: Maybe<Subscription_Statuses_Mutation_Response>;
  /** insert a single row into the table: "subscription_statuses" */
  insert_subscription_statuses_one?: Maybe<Subscription_Statuses>;
  /** insert data into the table: "subscription_tasks" */
  insert_subscription_tasks?: Maybe<Subscription_Tasks_Mutation_Response>;
  /** insert a single row into the table: "subscription_tasks" */
  insert_subscription_tasks_one?: Maybe<Subscription_Tasks>;
  /** insert data into the table: "subscriptions" */
  insert_subscriptions?: Maybe<Subscriptions_Mutation_Response>;
  /** insert a single row into the table: "subscriptions" */
  insert_subscriptions_one?: Maybe<Subscriptions>;
  /** insert data into the table: "tariff_histories" */
  insert_tariff_histories?: Maybe<Tariff_Histories_Mutation_Response>;
  /** insert a single row into the table: "tariff_histories" */
  insert_tariff_histories_one?: Maybe<Tariff_Histories>;
  /** insert data into the table: "tariffs" */
  insert_tariffs?: Maybe<Tariffs_Mutation_Response>;
  /** insert a single row into the table: "tariffs" */
  insert_tariffs_one?: Maybe<Tariffs>;
  /** insert data into the table: "task_assignments" */
  insert_task_assignments?: Maybe<Task_Assignments_Mutation_Response>;
  /** insert a single row into the table: "task_assignments" */
  insert_task_assignments_one?: Maybe<Task_Assignments>;
  /** insert data into the table: "task_documents" */
  insert_task_documents?: Maybe<Task_Documents_Mutation_Response>;
  /** insert a single row into the table: "task_documents" */
  insert_task_documents_one?: Maybe<Task_Documents>;
  /** insert data into the table: "task_flags" */
  insert_task_flags?: Maybe<Task_Flags_Mutation_Response>;
  /** insert a single row into the table: "task_flags" */
  insert_task_flags_one?: Maybe<Task_Flags>;
  /** insert data into the table: "task_interactions" */
  insert_task_interactions?: Maybe<Task_Interactions_Mutation_Response>;
  /** insert a single row into the table: "task_interactions" */
  insert_task_interactions_one?: Maybe<Task_Interactions>;
  /** insert data into the table: "task_mentions" */
  insert_task_mentions?: Maybe<Task_Mentions_Mutation_Response>;
  /** insert a single row into the table: "task_mentions" */
  insert_task_mentions_one?: Maybe<Task_Mentions>;
  /** insert data into the table: "task_reminders" */
  insert_task_reminders?: Maybe<Task_Reminders_Mutation_Response>;
  /** insert a single row into the table: "task_reminders" */
  insert_task_reminders_one?: Maybe<Task_Reminders>;
  /** insert data into the table: "task_rules" */
  insert_task_rules?: Maybe<Task_Rules_Mutation_Response>;
  /** insert a single row into the table: "task_rules" */
  insert_task_rules_one?: Maybe<Task_Rules>;
  /** insert data into the table: "task_statuses" */
  insert_task_statuses?: Maybe<Task_Statuses_Mutation_Response>;
  /** insert a single row into the table: "task_statuses" */
  insert_task_statuses_one?: Maybe<Task_Statuses>;
  /** insert data into the table: "tasks" */
  insert_tasks?: Maybe<Tasks_Mutation_Response>;
  /** insert a single row into the table: "tasks" */
  insert_tasks_one?: Maybe<Tasks>;
  /** insert data into the table: "territories" */
  insert_territories?: Maybe<Territories_Mutation_Response>;
  /** insert a single row into the table: "territories" */
  insert_territories_one?: Maybe<Territories>;
  /** insert data into the table: "territory_assignments" */
  insert_territory_assignments?: Maybe<Territory_Assignments_Mutation_Response>;
  /** insert a single row into the table: "territory_assignments" */
  insert_territory_assignments_one?: Maybe<Territory_Assignments>;
  /** insert data into the table: "territory_flags" */
  insert_territory_flags?: Maybe<Territory_Flags_Mutation_Response>;
  /** insert a single row into the table: "territory_flags" */
  insert_territory_flags_one?: Maybe<Territory_Flags>;
  /** insert data into the table: "territory_interactions" */
  insert_territory_interactions?: Maybe<Territory_Interactions_Mutation_Response>;
  /** insert a single row into the table: "territory_interactions" */
  insert_territory_interactions_one?: Maybe<Territory_Interactions>;
  /** insert data into the table: "territory_members" */
  insert_territory_members?: Maybe<Territory_Members_Mutation_Response>;
  /** insert a single row into the table: "territory_members" */
  insert_territory_members_one?: Maybe<Territory_Members>;
  /** insert data into the table: "territory_rules" */
  insert_territory_rules?: Maybe<Territory_Rules_Mutation_Response>;
  /** insert a single row into the table: "territory_rules" */
  insert_territory_rules_one?: Maybe<Territory_Rules>;
  /** insert data into the table: "territory_statuses" */
  insert_territory_statuses?: Maybe<Territory_Statuses_Mutation_Response>;
  /** insert a single row into the table: "territory_statuses" */
  insert_territory_statuses_one?: Maybe<Territory_Statuses>;
  /** insert data into the table: "types" */
  insert_types?: Maybe<Types_Mutation_Response>;
  /** insert a single row into the table: "types" */
  insert_types_one?: Maybe<Types>;
  /** insert data into the table: "user_flags" */
  insert_user_flags?: Maybe<User_Flags_Mutation_Response>;
  /** insert a single row into the table: "user_flags" */
  insert_user_flags_one?: Maybe<User_Flags>;
  /** insert data into the table: "user_mfa_factors" */
  insert_user_mfa_factors?: Maybe<User_Mfa_Factors_Mutation_Response>;
  /** insert a single row into the table: "user_mfa_factors" */
  insert_user_mfa_factors_one?: Maybe<User_Mfa_Factors>;
  /** insert data into the table: "user_statuses" */
  insert_user_statuses?: Maybe<User_Statuses_Mutation_Response>;
  /** insert a single row into the table: "user_statuses" */
  insert_user_statuses_one?: Maybe<User_Statuses>;
  /** insert data into the table: "users" */
  insert_users?: Maybe<Users_Mutation_Response>;
  /** insert a single row into the table: "users" */
  insert_users_one?: Maybe<Users>;
  /** insert data into the table: "webhooks" */
  insert_webhooks?: Maybe<Webhooks_Mutation_Response>;
  /** insert a single row into the table: "webhooks" */
  insert_webhooks_one?: Maybe<Webhooks>;
  /** insert data into the table: "workflow_templates" */
  insert_workflow_templates?: Maybe<Workflow_Templates_Mutation_Response>;
  /** insert a single row into the table: "workflow_templates" */
  insert_workflow_templates_one?: Maybe<Workflow_Templates>;
  /** If MFA Enabled, accessToken is temporary */
  login?: Maybe<LoginOutput>;
  magicLinkLogin?: Maybe<MagicLinkLoginOutput>;
  /** Make invoice payment */
  makeInvoicePayment?: Maybe<MakeInvoicePaymentOutput>;
  /** Make subscription payment */
  makeSubscriptionPayment?: Maybe<MakeSubscriptionPaymentOutput>;
  markMessageAsRead?: Maybe<MarkMessageAsReadOutput>;
  /** Handles a workflow submission */
  processWorkflowSubmission?: Maybe<ProcessWorkflowSubmissionOutput>;
  record_payment: RecordPaymentOutput;
  record_transaction: RecordTransactionOutput;
  rejectTaskApproval?: Maybe<RejectTaskApprovalOutput>;
  /** Remove invoice items */
  removeInvoiceItems?: Maybe<UpdateInvoiceOutput>;
  requestTaskApproval?: Maybe<TaskApprovalOutput>;
  rescheduleInvoice?: Maybe<RescheduleInvoiceOutput>;
  resetPassword?: Maybe<ResetPasswordOutput>;
  /** Allows retry sending of messages */
  retrySendingMessages?: Maybe<RetrySendingMessagesOutput>;
  scheduleMessage?: Maybe<ScheduleMessageOutput>;
  sendAdHocMessage?: Maybe<SendMessageOutput>;
  /** message is either 'Message queued'  or  'Message queing failed' */
  sendMessage?: Maybe<SendMessageOutput>;
  signup?: Maybe<SignupOutput>;
  subscribeToChannel?: Maybe<SubscribeToChannelOutput>;
  /** Toggles subscription delivery method status */
  toggleSubscriptionDeliveryMethodStatus?: Maybe<ToggleSubscriptionDeliveryMethodStatusOutput>;
  transfer_funds: TransferFundsOutput;
  /** Update invoice */
  updateInvoice?: Maybe<UpdateInvoiceOutput>;
  updateSubscriptionDeliveryMethodAddress?: Maybe<UpdateSubscriptionDeliveryMethodAddressOutput>;
  /** update data of the table: "activity_logs" */
  update_activity_logs?: Maybe<Activity_Logs_Mutation_Response>;
  /** update single row of the table: "activity_logs" */
  update_activity_logs_by_pk?: Maybe<Activity_Logs>;
  /** update multiples rows of table: "activity_logs" */
  update_activity_logs_many?: Maybe<
    Array<Maybe<Activity_Logs_Mutation_Response>>
  >;
  /** update data of the table: "audit_logs" */
  update_audit_logs?: Maybe<Audit_Logs_Mutation_Response>;
  /** update single row of the table: "audit_logs" */
  update_audit_logs_by_pk?: Maybe<Audit_Logs>;
  /** update multiples rows of table: "audit_logs" */
  update_audit_logs_many?: Maybe<Array<Maybe<Audit_Logs_Mutation_Response>>>;
  /** update data of the table: "campaign_contacts" */
  update_campaign_contacts?: Maybe<Campaign_Contacts_Mutation_Response>;
  /** update single row of the table: "campaign_contacts" */
  update_campaign_contacts_by_pk?: Maybe<Campaign_Contacts>;
  /** update multiples rows of table: "campaign_contacts" */
  update_campaign_contacts_many?: Maybe<
    Array<Maybe<Campaign_Contacts_Mutation_Response>>
  >;
  /** update data of the table: "campaign_performance_indicators" */
  update_campaign_performance_indicators?: Maybe<Campaign_Performance_Indicators_Mutation_Response>;
  /** update single row of the table: "campaign_performance_indicators" */
  update_campaign_performance_indicators_by_pk?: Maybe<Campaign_Performance_Indicators>;
  /** update multiples rows of table: "campaign_performance_indicators" */
  update_campaign_performance_indicators_many?: Maybe<
    Array<Maybe<Campaign_Performance_Indicators_Mutation_Response>>
  >;
  /** update data of the table: "campaign_rewards" */
  update_campaign_rewards?: Maybe<Campaign_Rewards_Mutation_Response>;
  /** update single row of the table: "campaign_rewards" */
  update_campaign_rewards_by_pk?: Maybe<Campaign_Rewards>;
  /** update multiples rows of table: "campaign_rewards" */
  update_campaign_rewards_many?: Maybe<
    Array<Maybe<Campaign_Rewards_Mutation_Response>>
  >;
  /** update data of the table: "campaign_rules" */
  update_campaign_rules?: Maybe<Campaign_Rules_Mutation_Response>;
  /** update single row of the table: "campaign_rules" */
  update_campaign_rules_by_pk?: Maybe<Campaign_Rules>;
  /** update multiples rows of table: "campaign_rules" */
  update_campaign_rules_many?: Maybe<
    Array<Maybe<Campaign_Rules_Mutation_Response>>
  >;
  /** update data of the table: "campaign_tasks" */
  update_campaign_tasks?: Maybe<Campaign_Tasks_Mutation_Response>;
  /** update single row of the table: "campaign_tasks" */
  update_campaign_tasks_by_pk?: Maybe<Campaign_Tasks>;
  /** update multiples rows of table: "campaign_tasks" */
  update_campaign_tasks_many?: Maybe<
    Array<Maybe<Campaign_Tasks_Mutation_Response>>
  >;
  /** update data of the table: "campaign_workflows" */
  update_campaign_workflows?: Maybe<Campaign_Workflows_Mutation_Response>;
  /** update single row of the table: "campaign_workflows" */
  update_campaign_workflows_by_pk?: Maybe<Campaign_Workflows>;
  /** update multiples rows of table: "campaign_workflows" */
  update_campaign_workflows_many?: Maybe<
    Array<Maybe<Campaign_Workflows_Mutation_Response>>
  >;
  /** update data of the table: "campaigns" */
  update_campaigns?: Maybe<Campaigns_Mutation_Response>;
  /** update single row of the table: "campaigns" */
  update_campaigns_by_pk?: Maybe<Campaigns>;
  /** update multiples rows of table: "campaigns" */
  update_campaigns_many?: Maybe<Array<Maybe<Campaigns_Mutation_Response>>>;
  /** update data of the table: "case_assignments" */
  update_case_assignments?: Maybe<Case_Assignments_Mutation_Response>;
  /** update single row of the table: "case_assignments" */
  update_case_assignments_by_pk?: Maybe<Case_Assignments>;
  /** update multiples rows of table: "case_assignments" */
  update_case_assignments_many?: Maybe<
    Array<Maybe<Case_Assignments_Mutation_Response>>
  >;
  /** update data of the table: "case_details" */
  update_case_details?: Maybe<Case_Details_Mutation_Response>;
  /** update single row of the table: "case_details" */
  update_case_details_by_pk?: Maybe<Case_Details>;
  /** update multiples rows of table: "case_details" */
  update_case_details_many?: Maybe<
    Array<Maybe<Case_Details_Mutation_Response>>
  >;
  /** update data of the table: "case_documents" */
  update_case_documents?: Maybe<Case_Documents_Mutation_Response>;
  /** update single row of the table: "case_documents" */
  update_case_documents_by_pk?: Maybe<Case_Documents>;
  /** update multiples rows of table: "case_documents" */
  update_case_documents_many?: Maybe<
    Array<Maybe<Case_Documents_Mutation_Response>>
  >;
  /** update data of the table: "case_flags" */
  update_case_flags?: Maybe<Case_Flags_Mutation_Response>;
  /** update single row of the table: "case_flags" */
  update_case_flags_by_pk?: Maybe<Case_Flags>;
  /** update multiples rows of table: "case_flags" */
  update_case_flags_many?: Maybe<Array<Maybe<Case_Flags_Mutation_Response>>>;
  /** update data of the table: "case_interactions" */
  update_case_interactions?: Maybe<Case_Interactions_Mutation_Response>;
  /** update single row of the table: "case_interactions" */
  update_case_interactions_by_pk?: Maybe<Case_Interactions>;
  /** update multiples rows of table: "case_interactions" */
  update_case_interactions_many?: Maybe<
    Array<Maybe<Case_Interactions_Mutation_Response>>
  >;
  /** update data of the table: "case_mentions" */
  update_case_mentions?: Maybe<Case_Mentions_Mutation_Response>;
  /** update single row of the table: "case_mentions" */
  update_case_mentions_by_pk?: Maybe<Case_Mentions>;
  /** update multiples rows of table: "case_mentions" */
  update_case_mentions_many?: Maybe<
    Array<Maybe<Case_Mentions_Mutation_Response>>
  >;
  /** update data of the table: "case_statuses" */
  update_case_statuses?: Maybe<Case_Statuses_Mutation_Response>;
  /** update single row of the table: "case_statuses" */
  update_case_statuses_by_pk?: Maybe<Case_Statuses>;
  /** update multiples rows of table: "case_statuses" */
  update_case_statuses_many?: Maybe<
    Array<Maybe<Case_Statuses_Mutation_Response>>
  >;
  /** update data of the table: "cases" */
  update_cases?: Maybe<Cases_Mutation_Response>;
  /** update single row of the table: "cases" */
  update_cases_by_pk?: Maybe<Cases>;
  /** update multiples rows of table: "cases" */
  update_cases_many?: Maybe<Array<Maybe<Cases_Mutation_Response>>>;
  /** update data of the table: "channel_flags" */
  update_channel_flags?: Maybe<Channel_Flags_Mutation_Response>;
  /** update single row of the table: "channel_flags" */
  update_channel_flags_by_pk?: Maybe<Channel_Flags>;
  /** update multiples rows of table: "channel_flags" */
  update_channel_flags_many?: Maybe<
    Array<Maybe<Channel_Flags_Mutation_Response>>
  >;
  /** update data of the table: "channel_parties" */
  update_channel_parties?: Maybe<Channel_Parties_Mutation_Response>;
  /** update single row of the table: "channel_parties" */
  update_channel_parties_by_pk?: Maybe<Channel_Parties>;
  /** update multiples rows of table: "channel_parties" */
  update_channel_parties_many?: Maybe<
    Array<Maybe<Channel_Parties_Mutation_Response>>
  >;
  /** update data of the table: "channel_statuses" */
  update_channel_statuses?: Maybe<Channel_Statuses_Mutation_Response>;
  /** update single row of the table: "channel_statuses" */
  update_channel_statuses_by_pk?: Maybe<Channel_Statuses>;
  /** update multiples rows of table: "channel_statuses" */
  update_channel_statuses_many?: Maybe<
    Array<Maybe<Channel_Statuses_Mutation_Response>>
  >;
  /** update data of the table: "channel_subscriptions" */
  update_channel_subscriptions?: Maybe<Channel_Subscriptions_Mutation_Response>;
  /** update single row of the table: "channel_subscriptions" */
  update_channel_subscriptions_by_pk?: Maybe<Channel_Subscriptions>;
  /** update multiples rows of table: "channel_subscriptions" */
  update_channel_subscriptions_many?: Maybe<
    Array<Maybe<Channel_Subscriptions_Mutation_Response>>
  >;
  /** update data of the table: "channels" */
  update_channels?: Maybe<Channels_Mutation_Response>;
  /** update single row of the table: "channels" */
  update_channels_by_pk?: Maybe<Channels>;
  /** update multiples rows of table: "channels" */
  update_channels_many?: Maybe<Array<Maybe<Channels_Mutation_Response>>>;
  /** update data of the table: "conditions" */
  update_conditions?: Maybe<Conditions_Mutation_Response>;
  /** update single row of the table: "conditions" */
  update_conditions_by_pk?: Maybe<Conditions>;
  /** update multiples rows of table: "conditions" */
  update_conditions_many?: Maybe<Array<Maybe<Conditions_Mutation_Response>>>;
  /** update data of the table: "contact_details" */
  update_contact_details?: Maybe<Contact_Details_Mutation_Response>;
  /** update single row of the table: "contact_details" */
  update_contact_details_by_pk?: Maybe<Contact_Details>;
  /** update multiples rows of table: "contact_details" */
  update_contact_details_many?: Maybe<
    Array<Maybe<Contact_Details_Mutation_Response>>
  >;
  /** update data of the table: "contact_interactions" */
  update_contact_interactions?: Maybe<Contact_Interactions_Mutation_Response>;
  /** update single row of the table: "contact_interactions" */
  update_contact_interactions_by_pk?: Maybe<Contact_Interactions>;
  /** update multiples rows of table: "contact_interactions" */
  update_contact_interactions_many?: Maybe<
    Array<Maybe<Contact_Interactions_Mutation_Response>>
  >;
  /** update data of the table: "contact_relationships" */
  update_contact_relationships?: Maybe<Contact_Relationships_Mutation_Response>;
  /** update single row of the table: "contact_relationships" */
  update_contact_relationships_by_pk?: Maybe<Contact_Relationships>;
  /** update multiples rows of table: "contact_relationships" */
  update_contact_relationships_many?: Maybe<
    Array<Maybe<Contact_Relationships_Mutation_Response>>
  >;
  /** update data of the table: "contacts" */
  update_contacts?: Maybe<Contacts_Mutation_Response>;
  /** update single row of the table: "contacts" */
  update_contacts_by_pk?: Maybe<Contacts>;
  /** update multiples rows of table: "contacts" */
  update_contacts_many?: Maybe<Array<Maybe<Contacts_Mutation_Response>>>;
  /** update data of the table: "credit_profile_flags" */
  update_credit_profile_flags?: Maybe<Credit_Profile_Flags_Mutation_Response>;
  /** update single row of the table: "credit_profile_flags" */
  update_credit_profile_flags_by_pk?: Maybe<Credit_Profile_Flags>;
  /** update multiples rows of table: "credit_profile_flags" */
  update_credit_profile_flags_many?: Maybe<
    Array<Maybe<Credit_Profile_Flags_Mutation_Response>>
  >;
  /** update data of the table: "credit_profile_statuses" */
  update_credit_profile_statuses?: Maybe<Credit_Profile_Statuses_Mutation_Response>;
  /** update single row of the table: "credit_profile_statuses" */
  update_credit_profile_statuses_by_pk?: Maybe<Credit_Profile_Statuses>;
  /** update multiples rows of table: "credit_profile_statuses" */
  update_credit_profile_statuses_many?: Maybe<
    Array<Maybe<Credit_Profile_Statuses_Mutation_Response>>
  >;
  /** update data of the table: "credit_profiles" */
  update_credit_profiles?: Maybe<Credit_Profiles_Mutation_Response>;
  /** update single row of the table: "credit_profiles" */
  update_credit_profiles_by_pk?: Maybe<Credit_Profiles>;
  /** update multiples rows of table: "credit_profiles" */
  update_credit_profiles_many?: Maybe<
    Array<Maybe<Credit_Profiles_Mutation_Response>>
  >;
  /** update data of the table: "delivery_methods" */
  update_delivery_methods?: Maybe<Delivery_Methods_Mutation_Response>;
  /** update single row of the table: "delivery_methods" */
  update_delivery_methods_by_pk?: Maybe<Delivery_Methods>;
  /** update multiples rows of table: "delivery_methods" */
  update_delivery_methods_many?: Maybe<
    Array<Maybe<Delivery_Methods_Mutation_Response>>
  >;
  /** update data of the table: "document_interactions" */
  update_document_interactions?: Maybe<Document_Interactions_Mutation_Response>;
  /** update single row of the table: "document_interactions" */
  update_document_interactions_by_pk?: Maybe<Document_Interactions>;
  /** update multiples rows of table: "document_interactions" */
  update_document_interactions_many?: Maybe<
    Array<Maybe<Document_Interactions_Mutation_Response>>
  >;
  /** update data of the table: "documents" */
  update_documents?: Maybe<Documents_Mutation_Response>;
  /** update single row of the table: "documents" */
  update_documents_by_pk?: Maybe<Documents>;
  /** update multiples rows of table: "documents" */
  update_documents_many?: Maybe<Array<Maybe<Documents_Mutation_Response>>>;
  /** update data of the table: "flags" */
  update_flags?: Maybe<Flags_Mutation_Response>;
  /** update single row of the table: "flags" */
  update_flags_by_pk?: Maybe<Flags>;
  /** update multiples rows of table: "flags" */
  update_flags_many?: Maybe<Array<Maybe<Flags_Mutation_Response>>>;
  /** update data of the table: "form_fields" */
  update_form_fields?: Maybe<Form_Fields_Mutation_Response>;
  /** update single row of the table: "form_fields" */
  update_form_fields_by_pk?: Maybe<Form_Fields>;
  /** update multiples rows of table: "form_fields" */
  update_form_fields_many?: Maybe<Array<Maybe<Form_Fields_Mutation_Response>>>;
  /** update data of the table: "form_rules" */
  update_form_rules?: Maybe<Form_Rules_Mutation_Response>;
  /** update single row of the table: "form_rules" */
  update_form_rules_by_pk?: Maybe<Form_Rules>;
  /** update multiples rows of table: "form_rules" */
  update_form_rules_many?: Maybe<Array<Maybe<Form_Rules_Mutation_Response>>>;
  /** update data of the table: "forms" */
  update_forms?: Maybe<Forms_Mutation_Response>;
  /** update single row of the table: "forms" */
  update_forms_by_pk?: Maybe<Forms>;
  /** update multiples rows of table: "forms" */
  update_forms_many?: Maybe<Array<Maybe<Forms_Mutation_Response>>>;
  /** update data of the table: "interaction_details" */
  update_interaction_details?: Maybe<Interaction_Details_Mutation_Response>;
  /** update single row of the table: "interaction_details" */
  update_interaction_details_by_pk?: Maybe<Interaction_Details>;
  /** update multiples rows of table: "interaction_details" */
  update_interaction_details_many?: Maybe<
    Array<Maybe<Interaction_Details_Mutation_Response>>
  >;
  /** update data of the table: "interactions" */
  update_interactions?: Maybe<Interactions_Mutation_Response>;
  /** update single row of the table: "interactions" */
  update_interactions_by_pk?: Maybe<Interactions>;
  /** update multiples rows of table: "interactions" */
  update_interactions_many?: Maybe<
    Array<Maybe<Interactions_Mutation_Response>>
  >;
  /** update data of the table: "invoice_interactions" */
  update_invoice_interactions?: Maybe<Invoice_Interactions_Mutation_Response>;
  /** update single row of the table: "invoice_interactions" */
  update_invoice_interactions_by_pk?: Maybe<Invoice_Interactions>;
  /** update multiples rows of table: "invoice_interactions" */
  update_invoice_interactions_many?: Maybe<
    Array<Maybe<Invoice_Interactions_Mutation_Response>>
  >;
  /** update data of the table: "invoice_items" */
  update_invoice_items?: Maybe<Invoice_Items_Mutation_Response>;
  /** update single row of the table: "invoice_items" */
  update_invoice_items_by_pk?: Maybe<Invoice_Items>;
  /** update multiples rows of table: "invoice_items" */
  update_invoice_items_many?: Maybe<
    Array<Maybe<Invoice_Items_Mutation_Response>>
  >;
  /** update data of the table: "invoices" */
  update_invoices?: Maybe<Invoices_Mutation_Response>;
  /** update single row of the table: "invoices" */
  update_invoices_by_pk?: Maybe<Invoices>;
  /** update multiples rows of table: "invoices" */
  update_invoices_many?: Maybe<Array<Maybe<Invoices_Mutation_Response>>>;
  update_ledger: LedgerMutationOutput;
  update_ledger_account: LedgerAccountMutationOutput;
  /** update data of the table: "message_delivery_methods" */
  update_message_delivery_methods?: Maybe<Message_Delivery_Methods_Mutation_Response>;
  /** update single row of the table: "message_delivery_methods" */
  update_message_delivery_methods_by_pk?: Maybe<Message_Delivery_Methods>;
  /** update multiples rows of table: "message_delivery_methods" */
  update_message_delivery_methods_many?: Maybe<
    Array<Maybe<Message_Delivery_Methods_Mutation_Response>>
  >;
  /** update data of the table: "message_delivery_responses" */
  update_message_delivery_responses?: Maybe<Message_Delivery_Responses_Mutation_Response>;
  /** update single row of the table: "message_delivery_responses" */
  update_message_delivery_responses_by_pk?: Maybe<Message_Delivery_Responses>;
  /** update multiples rows of table: "message_delivery_responses" */
  update_message_delivery_responses_many?: Maybe<
    Array<Maybe<Message_Delivery_Responses_Mutation_Response>>
  >;
  /** update data of the table: "message_flags" */
  update_message_flags?: Maybe<Message_Flags_Mutation_Response>;
  /** update single row of the table: "message_flags" */
  update_message_flags_by_pk?: Maybe<Message_Flags>;
  /** update multiples rows of table: "message_flags" */
  update_message_flags_many?: Maybe<
    Array<Maybe<Message_Flags_Mutation_Response>>
  >;
  /** update data of the table: "message_statuses" */
  update_message_statuses?: Maybe<Message_Statuses_Mutation_Response>;
  /** update single row of the table: "message_statuses" */
  update_message_statuses_by_pk?: Maybe<Message_Statuses>;
  /** update multiples rows of table: "message_statuses" */
  update_message_statuses_many?: Maybe<
    Array<Maybe<Message_Statuses_Mutation_Response>>
  >;
  /** update data of the table: "messages" */
  update_messages?: Maybe<Messages_Mutation_Response>;
  /** update single row of the table: "messages" */
  update_messages_by_pk?: Maybe<Messages>;
  /** update multiples rows of table: "messages" */
  update_messages_many?: Maybe<Array<Maybe<Messages_Mutation_Response>>>;
  /** update data of the table: "organisation_credentials" */
  update_organisation_credentials?: Maybe<Organisation_Credentials_Mutation_Response>;
  /** update single row of the table: "organisation_credentials" */
  update_organisation_credentials_by_pk?: Maybe<Organisation_Credentials>;
  /** update multiples rows of table: "organisation_credentials" */
  update_organisation_credentials_many?: Maybe<
    Array<Maybe<Organisation_Credentials_Mutation_Response>>
  >;
  /** update data of the table: "organisation_flags" */
  update_organisation_flags?: Maybe<Organisation_Flags_Mutation_Response>;
  /** update single row of the table: "organisation_flags" */
  update_organisation_flags_by_pk?: Maybe<Organisation_Flags>;
  /** update multiples rows of table: "organisation_flags" */
  update_organisation_flags_many?: Maybe<
    Array<Maybe<Organisation_Flags_Mutation_Response>>
  >;
  /** update data of the table: "organisation_invites" */
  update_organisation_invites?: Maybe<Organisation_Invites_Mutation_Response>;
  /** update single row of the table: "organisation_invites" */
  update_organisation_invites_by_pk?: Maybe<Organisation_Invites>;
  /** update multiples rows of table: "organisation_invites" */
  update_organisation_invites_many?: Maybe<
    Array<Maybe<Organisation_Invites_Mutation_Response>>
  >;
  /** update data of the table: "organisation_rules" */
  update_organisation_rules?: Maybe<Organisation_Rules_Mutation_Response>;
  /** update single row of the table: "organisation_rules" */
  update_organisation_rules_by_pk?: Maybe<Organisation_Rules>;
  /** update multiples rows of table: "organisation_rules" */
  update_organisation_rules_many?: Maybe<
    Array<Maybe<Organisation_Rules_Mutation_Response>>
  >;
  /** update data of the table: "organisation_settings" */
  update_organisation_settings?: Maybe<Organisation_Settings_Mutation_Response>;
  /** update single row of the table: "organisation_settings" */
  update_organisation_settings_by_pk?: Maybe<Organisation_Settings>;
  /** update multiples rows of table: "organisation_settings" */
  update_organisation_settings_many?: Maybe<
    Array<Maybe<Organisation_Settings_Mutation_Response>>
  >;
  /** update data of the table: "organisation_statuses" */
  update_organisation_statuses?: Maybe<Organisation_Statuses_Mutation_Response>;
  /** update single row of the table: "organisation_statuses" */
  update_organisation_statuses_by_pk?: Maybe<Organisation_Statuses>;
  /** update multiples rows of table: "organisation_statuses" */
  update_organisation_statuses_many?: Maybe<
    Array<Maybe<Organisation_Statuses_Mutation_Response>>
  >;
  /** update data of the table: "organisations" */
  update_organisations?: Maybe<Organisations_Mutation_Response>;
  /** update single row of the table: "organisations" */
  update_organisations_by_pk?: Maybe<Organisations>;
  /** update multiples rows of table: "organisations" */
  update_organisations_many?: Maybe<
    Array<Maybe<Organisations_Mutation_Response>>
  >;
  /** update data of the table: "parties" */
  update_parties?: Maybe<Parties_Mutation_Response>;
  /** update single row of the table: "parties" */
  update_parties_by_pk?: Maybe<Parties>;
  /** update multiples rows of table: "parties" */
  update_parties_many?: Maybe<Array<Maybe<Parties_Mutation_Response>>>;
  /** update data of the table: "party_contacts" */
  update_party_contacts?: Maybe<Party_Contacts_Mutation_Response>;
  /** update single row of the table: "party_contacts" */
  update_party_contacts_by_pk?: Maybe<Party_Contacts>;
  /** update multiples rows of table: "party_contacts" */
  update_party_contacts_many?: Maybe<
    Array<Maybe<Party_Contacts_Mutation_Response>>
  >;
  /** update data of the table: "party_documents" */
  update_party_documents?: Maybe<Party_Documents_Mutation_Response>;
  /** update single row of the table: "party_documents" */
  update_party_documents_by_pk?: Maybe<Party_Documents>;
  /** update multiples rows of table: "party_documents" */
  update_party_documents_many?: Maybe<
    Array<Maybe<Party_Documents_Mutation_Response>>
  >;
  /** update data of the table: "party_flags" */
  update_party_flags?: Maybe<Party_Flags_Mutation_Response>;
  /** update single row of the table: "party_flags" */
  update_party_flags_by_pk?: Maybe<Party_Flags>;
  /** update multiples rows of table: "party_flags" */
  update_party_flags_many?: Maybe<Array<Maybe<Party_Flags_Mutation_Response>>>;
  /** update data of the table: "party_interactions" */
  update_party_interactions?: Maybe<Party_Interactions_Mutation_Response>;
  /** update single row of the table: "party_interactions" */
  update_party_interactions_by_pk?: Maybe<Party_Interactions>;
  /** update multiples rows of table: "party_interactions" */
  update_party_interactions_many?: Maybe<
    Array<Maybe<Party_Interactions_Mutation_Response>>
  >;
  /** update data of the table: "party_ledger_accounts" */
  update_party_ledger_accounts?: Maybe<Party_Ledger_Accounts_Mutation_Response>;
  /** update single row of the table: "party_ledger_accounts" */
  update_party_ledger_accounts_by_pk?: Maybe<Party_Ledger_Accounts>;
  /** update multiples rows of table: "party_ledger_accounts" */
  update_party_ledger_accounts_many?: Maybe<
    Array<Maybe<Party_Ledger_Accounts_Mutation_Response>>
  >;
  /** update data of the table: "party_relationship_statuses" */
  update_party_relationship_statuses?: Maybe<Party_Relationship_Statuses_Mutation_Response>;
  /** update single row of the table: "party_relationship_statuses" */
  update_party_relationship_statuses_by_pk?: Maybe<Party_Relationship_Statuses>;
  /** update multiples rows of table: "party_relationship_statuses" */
  update_party_relationship_statuses_many?: Maybe<
    Array<Maybe<Party_Relationship_Statuses_Mutation_Response>>
  >;
  /** update data of the table: "party_relationships" */
  update_party_relationships?: Maybe<Party_Relationships_Mutation_Response>;
  /** update single row of the table: "party_relationships" */
  update_party_relationships_by_pk?: Maybe<Party_Relationships>;
  /** update multiples rows of table: "party_relationships" */
  update_party_relationships_many?: Maybe<
    Array<Maybe<Party_Relationships_Mutation_Response>>
  >;
  /** update data of the table: "party_settings" */
  update_party_settings?: Maybe<Party_Settings_Mutation_Response>;
  /** update single row of the table: "party_settings" */
  update_party_settings_by_pk?: Maybe<Party_Settings>;
  /** update multiples rows of table: "party_settings" */
  update_party_settings_many?: Maybe<
    Array<Maybe<Party_Settings_Mutation_Response>>
  >;
  /** update data of the table: "party_statuses" */
  update_party_statuses?: Maybe<Party_Statuses_Mutation_Response>;
  /** update single row of the table: "party_statuses" */
  update_party_statuses_by_pk?: Maybe<Party_Statuses>;
  /** update multiples rows of table: "party_statuses" */
  update_party_statuses_many?: Maybe<
    Array<Maybe<Party_Statuses_Mutation_Response>>
  >;
  update_payment: RecordPaymentOutput;
  update_payment_intent: RecordPaymentOutput;
  /** update data of the table: "performance_indicators" */
  update_performance_indicators?: Maybe<Performance_Indicators_Mutation_Response>;
  /** update single row of the table: "performance_indicators" */
  update_performance_indicators_by_pk?: Maybe<Performance_Indicators>;
  /** update multiples rows of table: "performance_indicators" */
  update_performance_indicators_many?: Maybe<
    Array<Maybe<Performance_Indicators_Mutation_Response>>
  >;
  /** update data of the table: "performance_targets" */
  update_performance_targets?: Maybe<Performance_Targets_Mutation_Response>;
  /** update single row of the table: "performance_targets" */
  update_performance_targets_by_pk?: Maybe<Performance_Targets>;
  /** update multiples rows of table: "performance_targets" */
  update_performance_targets_many?: Maybe<
    Array<Maybe<Performance_Targets_Mutation_Response>>
  >;
  /** update data of the table: "product_documents" */
  update_product_documents?: Maybe<Product_Documents_Mutation_Response>;
  /** update single row of the table: "product_documents" */
  update_product_documents_by_pk?: Maybe<Product_Documents>;
  /** update multiples rows of table: "product_documents" */
  update_product_documents_many?: Maybe<
    Array<Maybe<Product_Documents_Mutation_Response>>
  >;
  /** update data of the table: "product_interactions" */
  update_product_interactions?: Maybe<Product_Interactions_Mutation_Response>;
  /** update single row of the table: "product_interactions" */
  update_product_interactions_by_pk?: Maybe<Product_Interactions>;
  /** update multiples rows of table: "product_interactions" */
  update_product_interactions_many?: Maybe<
    Array<Maybe<Product_Interactions_Mutation_Response>>
  >;
  /** update data of the table: "product_items" */
  update_product_items?: Maybe<Product_Items_Mutation_Response>;
  /** update single row of the table: "product_items" */
  update_product_items_by_pk?: Maybe<Product_Items>;
  /** update multiples rows of table: "product_items" */
  update_product_items_many?: Maybe<
    Array<Maybe<Product_Items_Mutation_Response>>
  >;
  /** update data of the table: "product_rules" */
  update_product_rules?: Maybe<Product_Rules_Mutation_Response>;
  /** update single row of the table: "product_rules" */
  update_product_rules_by_pk?: Maybe<Product_Rules>;
  /** update multiples rows of table: "product_rules" */
  update_product_rules_many?: Maybe<
    Array<Maybe<Product_Rules_Mutation_Response>>
  >;
  /** update data of the table: "product_settings" */
  update_product_settings?: Maybe<Product_Settings_Mutation_Response>;
  /** update single row of the table: "product_settings" */
  update_product_settings_by_pk?: Maybe<Product_Settings>;
  /** update multiples rows of table: "product_settings" */
  update_product_settings_many?: Maybe<
    Array<Maybe<Product_Settings_Mutation_Response>>
  >;
  /** update data of the table: "product_statuses" */
  update_product_statuses?: Maybe<Product_Statuses_Mutation_Response>;
  /** update single row of the table: "product_statuses" */
  update_product_statuses_by_pk?: Maybe<Product_Statuses>;
  /** update multiples rows of table: "product_statuses" */
  update_product_statuses_many?: Maybe<
    Array<Maybe<Product_Statuses_Mutation_Response>>
  >;
  /** update data of the table: "product_templates" */
  update_product_templates?: Maybe<Product_Templates_Mutation_Response>;
  /** update single row of the table: "product_templates" */
  update_product_templates_by_pk?: Maybe<Product_Templates>;
  /** update multiples rows of table: "product_templates" */
  update_product_templates_many?: Maybe<
    Array<Maybe<Product_Templates_Mutation_Response>>
  >;
  /** update data of the table: "products" */
  update_products?: Maybe<Products_Mutation_Response>;
  /** update single row of the table: "products" */
  update_products_by_pk?: Maybe<Products>;
  /** update multiples rows of table: "products" */
  update_products_many?: Maybe<Array<Maybe<Products_Mutation_Response>>>;
  /** update data of the table: "reward_redemptions" */
  update_reward_redemptions?: Maybe<Reward_Redemptions_Mutation_Response>;
  /** update single row of the table: "reward_redemptions" */
  update_reward_redemptions_by_pk?: Maybe<Reward_Redemptions>;
  /** update multiples rows of table: "reward_redemptions" */
  update_reward_redemptions_many?: Maybe<
    Array<Maybe<Reward_Redemptions_Mutation_Response>>
  >;
  /** update data of the table: "rewards" */
  update_rewards?: Maybe<Rewards_Mutation_Response>;
  /** update single row of the table: "rewards" */
  update_rewards_by_pk?: Maybe<Rewards>;
  /** update multiples rows of table: "rewards" */
  update_rewards_many?: Maybe<Array<Maybe<Rewards_Mutation_Response>>>;
  /** update data of the table: "rules" */
  update_rules?: Maybe<Rules_Mutation_Response>;
  /** update single row of the table: "rules" */
  update_rules_by_pk?: Maybe<Rules>;
  /** update multiples rows of table: "rules" */
  update_rules_many?: Maybe<Array<Maybe<Rules_Mutation_Response>>>;
  /** update data of the table: "statuses" */
  update_statuses?: Maybe<Statuses_Mutation_Response>;
  /** update single row of the table: "statuses" */
  update_statuses_by_pk?: Maybe<Statuses>;
  /** update multiples rows of table: "statuses" */
  update_statuses_many?: Maybe<Array<Maybe<Statuses_Mutation_Response>>>;
  /** update data of the table: "subscription_cases" */
  update_subscription_cases?: Maybe<Subscription_Cases_Mutation_Response>;
  /** update single row of the table: "subscription_cases" */
  update_subscription_cases_by_pk?: Maybe<Subscription_Cases>;
  /** update multiples rows of table: "subscription_cases" */
  update_subscription_cases_many?: Maybe<
    Array<Maybe<Subscription_Cases_Mutation_Response>>
  >;
  /** update data of the table: "subscription_details" */
  update_subscription_details?: Maybe<Subscription_Details_Mutation_Response>;
  /** update single row of the table: "subscription_details" */
  update_subscription_details_by_pk?: Maybe<Subscription_Details>;
  /** update multiples rows of table: "subscription_details" */
  update_subscription_details_many?: Maybe<
    Array<Maybe<Subscription_Details_Mutation_Response>>
  >;
  /** update data of the table: "subscription_documents" */
  update_subscription_documents?: Maybe<Subscription_Documents_Mutation_Response>;
  /** update single row of the table: "subscription_documents" */
  update_subscription_documents_by_pk?: Maybe<Subscription_Documents>;
  /** update multiples rows of table: "subscription_documents" */
  update_subscription_documents_many?: Maybe<
    Array<Maybe<Subscription_Documents_Mutation_Response>>
  >;
  /** update data of the table: "subscription_flags" */
  update_subscription_flags?: Maybe<Subscription_Flags_Mutation_Response>;
  /** update single row of the table: "subscription_flags" */
  update_subscription_flags_by_pk?: Maybe<Subscription_Flags>;
  /** update multiples rows of table: "subscription_flags" */
  update_subscription_flags_many?: Maybe<
    Array<Maybe<Subscription_Flags_Mutation_Response>>
  >;
  /** update data of the table: "subscription_interactions" */
  update_subscription_interactions?: Maybe<Subscription_Interactions_Mutation_Response>;
  /** update single row of the table: "subscription_interactions" */
  update_subscription_interactions_by_pk?: Maybe<Subscription_Interactions>;
  /** update multiples rows of table: "subscription_interactions" */
  update_subscription_interactions_many?: Maybe<
    Array<Maybe<Subscription_Interactions_Mutation_Response>>
  >;
  /** update data of the table: "subscription_items" */
  update_subscription_items?: Maybe<Subscription_Items_Mutation_Response>;
  /** update single row of the table: "subscription_items" */
  update_subscription_items_by_pk?: Maybe<Subscription_Items>;
  /** update multiples rows of table: "subscription_items" */
  update_subscription_items_many?: Maybe<
    Array<Maybe<Subscription_Items_Mutation_Response>>
  >;
  /** update data of the table: "subscription_payment_transactions" */
  update_subscription_payment_transactions?: Maybe<Subscription_Payment_Transactions_Mutation_Response>;
  /** update single row of the table: "subscription_payment_transactions" */
  update_subscription_payment_transactions_by_pk?: Maybe<Subscription_Payment_Transactions>;
  /** update multiples rows of table: "subscription_payment_transactions" */
  update_subscription_payment_transactions_many?: Maybe<
    Array<Maybe<Subscription_Payment_Transactions_Mutation_Response>>
  >;
  /** update data of the table: "subscription_payments" */
  update_subscription_payments?: Maybe<Subscription_Payments_Mutation_Response>;
  /** update single row of the table: "subscription_payments" */
  update_subscription_payments_by_pk?: Maybe<Subscription_Payments>;
  /** update multiples rows of table: "subscription_payments" */
  update_subscription_payments_many?: Maybe<
    Array<Maybe<Subscription_Payments_Mutation_Response>>
  >;
  /** update data of the table: "subscription_rules" */
  update_subscription_rules?: Maybe<Subscription_Rules_Mutation_Response>;
  /** update single row of the table: "subscription_rules" */
  update_subscription_rules_by_pk?: Maybe<Subscription_Rules>;
  /** update multiples rows of table: "subscription_rules" */
  update_subscription_rules_many?: Maybe<
    Array<Maybe<Subscription_Rules_Mutation_Response>>
  >;
  /** update data of the table: "subscription_schedules" */
  update_subscription_schedules?: Maybe<Subscription_Schedules_Mutation_Response>;
  /** update single row of the table: "subscription_schedules" */
  update_subscription_schedules_by_pk?: Maybe<Subscription_Schedules>;
  /** update multiples rows of table: "subscription_schedules" */
  update_subscription_schedules_many?: Maybe<
    Array<Maybe<Subscription_Schedules_Mutation_Response>>
  >;
  /** update data of the table: "subscription_statuses" */
  update_subscription_statuses?: Maybe<Subscription_Statuses_Mutation_Response>;
  /** update single row of the table: "subscription_statuses" */
  update_subscription_statuses_by_pk?: Maybe<Subscription_Statuses>;
  /** update multiples rows of table: "subscription_statuses" */
  update_subscription_statuses_many?: Maybe<
    Array<Maybe<Subscription_Statuses_Mutation_Response>>
  >;
  /** update data of the table: "subscription_tasks" */
  update_subscription_tasks?: Maybe<Subscription_Tasks_Mutation_Response>;
  /** update single row of the table: "subscription_tasks" */
  update_subscription_tasks_by_pk?: Maybe<Subscription_Tasks>;
  /** update multiples rows of table: "subscription_tasks" */
  update_subscription_tasks_many?: Maybe<
    Array<Maybe<Subscription_Tasks_Mutation_Response>>
  >;
  /** update data of the table: "subscriptions" */
  update_subscriptions?: Maybe<Subscriptions_Mutation_Response>;
  /** update single row of the table: "subscriptions" */
  update_subscriptions_by_pk?: Maybe<Subscriptions>;
  /** update multiples rows of table: "subscriptions" */
  update_subscriptions_many?: Maybe<
    Array<Maybe<Subscriptions_Mutation_Response>>
  >;
  /** update data of the table: "tariff_histories" */
  update_tariff_histories?: Maybe<Tariff_Histories_Mutation_Response>;
  /** update single row of the table: "tariff_histories" */
  update_tariff_histories_by_pk?: Maybe<Tariff_Histories>;
  /** update multiples rows of table: "tariff_histories" */
  update_tariff_histories_many?: Maybe<
    Array<Maybe<Tariff_Histories_Mutation_Response>>
  >;
  /** update data of the table: "tariffs" */
  update_tariffs?: Maybe<Tariffs_Mutation_Response>;
  /** update single row of the table: "tariffs" */
  update_tariffs_by_pk?: Maybe<Tariffs>;
  /** update multiples rows of table: "tariffs" */
  update_tariffs_many?: Maybe<Array<Maybe<Tariffs_Mutation_Response>>>;
  /** update data of the table: "task_assignments" */
  update_task_assignments?: Maybe<Task_Assignments_Mutation_Response>;
  /** update single row of the table: "task_assignments" */
  update_task_assignments_by_pk?: Maybe<Task_Assignments>;
  /** update multiples rows of table: "task_assignments" */
  update_task_assignments_many?: Maybe<
    Array<Maybe<Task_Assignments_Mutation_Response>>
  >;
  /** update data of the table: "task_documents" */
  update_task_documents?: Maybe<Task_Documents_Mutation_Response>;
  /** update single row of the table: "task_documents" */
  update_task_documents_by_pk?: Maybe<Task_Documents>;
  /** update multiples rows of table: "task_documents" */
  update_task_documents_many?: Maybe<
    Array<Maybe<Task_Documents_Mutation_Response>>
  >;
  /** update data of the table: "task_flags" */
  update_task_flags?: Maybe<Task_Flags_Mutation_Response>;
  /** update single row of the table: "task_flags" */
  update_task_flags_by_pk?: Maybe<Task_Flags>;
  /** update multiples rows of table: "task_flags" */
  update_task_flags_many?: Maybe<Array<Maybe<Task_Flags_Mutation_Response>>>;
  /** update data of the table: "task_interactions" */
  update_task_interactions?: Maybe<Task_Interactions_Mutation_Response>;
  /** update single row of the table: "task_interactions" */
  update_task_interactions_by_pk?: Maybe<Task_Interactions>;
  /** update multiples rows of table: "task_interactions" */
  update_task_interactions_many?: Maybe<
    Array<Maybe<Task_Interactions_Mutation_Response>>
  >;
  /** update data of the table: "task_mentions" */
  update_task_mentions?: Maybe<Task_Mentions_Mutation_Response>;
  /** update single row of the table: "task_mentions" */
  update_task_mentions_by_pk?: Maybe<Task_Mentions>;
  /** update multiples rows of table: "task_mentions" */
  update_task_mentions_many?: Maybe<
    Array<Maybe<Task_Mentions_Mutation_Response>>
  >;
  /** update data of the table: "task_reminders" */
  update_task_reminders?: Maybe<Task_Reminders_Mutation_Response>;
  /** update single row of the table: "task_reminders" */
  update_task_reminders_by_pk?: Maybe<Task_Reminders>;
  /** update multiples rows of table: "task_reminders" */
  update_task_reminders_many?: Maybe<
    Array<Maybe<Task_Reminders_Mutation_Response>>
  >;
  /** update data of the table: "task_rules" */
  update_task_rules?: Maybe<Task_Rules_Mutation_Response>;
  /** update single row of the table: "task_rules" */
  update_task_rules_by_pk?: Maybe<Task_Rules>;
  /** update multiples rows of table: "task_rules" */
  update_task_rules_many?: Maybe<Array<Maybe<Task_Rules_Mutation_Response>>>;
  /** update data of the table: "task_statuses" */
  update_task_statuses?: Maybe<Task_Statuses_Mutation_Response>;
  /** update single row of the table: "task_statuses" */
  update_task_statuses_by_pk?: Maybe<Task_Statuses>;
  /** update multiples rows of table: "task_statuses" */
  update_task_statuses_many?: Maybe<
    Array<Maybe<Task_Statuses_Mutation_Response>>
  >;
  /** update data of the table: "tasks" */
  update_tasks?: Maybe<Tasks_Mutation_Response>;
  /** update single row of the table: "tasks" */
  update_tasks_by_pk?: Maybe<Tasks>;
  /** update multiples rows of table: "tasks" */
  update_tasks_many?: Maybe<Array<Maybe<Tasks_Mutation_Response>>>;
  /** update data of the table: "territories" */
  update_territories?: Maybe<Territories_Mutation_Response>;
  /** update single row of the table: "territories" */
  update_territories_by_pk?: Maybe<Territories>;
  /** update multiples rows of table: "territories" */
  update_territories_many?: Maybe<Array<Maybe<Territories_Mutation_Response>>>;
  /** update data of the table: "territory_assignments" */
  update_territory_assignments?: Maybe<Territory_Assignments_Mutation_Response>;
  /** update single row of the table: "territory_assignments" */
  update_territory_assignments_by_pk?: Maybe<Territory_Assignments>;
  /** update multiples rows of table: "territory_assignments" */
  update_territory_assignments_many?: Maybe<
    Array<Maybe<Territory_Assignments_Mutation_Response>>
  >;
  /** update data of the table: "territory_flags" */
  update_territory_flags?: Maybe<Territory_Flags_Mutation_Response>;
  /** update single row of the table: "territory_flags" */
  update_territory_flags_by_pk?: Maybe<Territory_Flags>;
  /** update multiples rows of table: "territory_flags" */
  update_territory_flags_many?: Maybe<
    Array<Maybe<Territory_Flags_Mutation_Response>>
  >;
  /** update data of the table: "territory_interactions" */
  update_territory_interactions?: Maybe<Territory_Interactions_Mutation_Response>;
  /** update single row of the table: "territory_interactions" */
  update_territory_interactions_by_pk?: Maybe<Territory_Interactions>;
  /** update multiples rows of table: "territory_interactions" */
  update_territory_interactions_many?: Maybe<
    Array<Maybe<Territory_Interactions_Mutation_Response>>
  >;
  /** update data of the table: "territory_members" */
  update_territory_members?: Maybe<Territory_Members_Mutation_Response>;
  /** update single row of the table: "territory_members" */
  update_territory_members_by_pk?: Maybe<Territory_Members>;
  /** update multiples rows of table: "territory_members" */
  update_territory_members_many?: Maybe<
    Array<Maybe<Territory_Members_Mutation_Response>>
  >;
  /** update data of the table: "territory_rules" */
  update_territory_rules?: Maybe<Territory_Rules_Mutation_Response>;
  /** update single row of the table: "territory_rules" */
  update_territory_rules_by_pk?: Maybe<Territory_Rules>;
  /** update multiples rows of table: "territory_rules" */
  update_territory_rules_many?: Maybe<
    Array<Maybe<Territory_Rules_Mutation_Response>>
  >;
  /** update data of the table: "territory_statuses" */
  update_territory_statuses?: Maybe<Territory_Statuses_Mutation_Response>;
  /** update single row of the table: "territory_statuses" */
  update_territory_statuses_by_pk?: Maybe<Territory_Statuses>;
  /** update multiples rows of table: "territory_statuses" */
  update_territory_statuses_many?: Maybe<
    Array<Maybe<Territory_Statuses_Mutation_Response>>
  >;
  update_transaction: UpdateTransactionOutput;
  /** update data of the table: "types" */
  update_types?: Maybe<Types_Mutation_Response>;
  /** update single row of the table: "types" */
  update_types_by_pk?: Maybe<Types>;
  /** update multiples rows of table: "types" */
  update_types_many?: Maybe<Array<Maybe<Types_Mutation_Response>>>;
  /** update data of the table: "user_flags" */
  update_user_flags?: Maybe<User_Flags_Mutation_Response>;
  /** update single row of the table: "user_flags" */
  update_user_flags_by_pk?: Maybe<User_Flags>;
  /** update multiples rows of table: "user_flags" */
  update_user_flags_many?: Maybe<Array<Maybe<User_Flags_Mutation_Response>>>;
  /** update data of the table: "user_mfa_factors" */
  update_user_mfa_factors?: Maybe<User_Mfa_Factors_Mutation_Response>;
  /** update single row of the table: "user_mfa_factors" */
  update_user_mfa_factors_by_pk?: Maybe<User_Mfa_Factors>;
  /** update multiples rows of table: "user_mfa_factors" */
  update_user_mfa_factors_many?: Maybe<
    Array<Maybe<User_Mfa_Factors_Mutation_Response>>
  >;
  /** update data of the table: "user_statuses" */
  update_user_statuses?: Maybe<User_Statuses_Mutation_Response>;
  /** update single row of the table: "user_statuses" */
  update_user_statuses_by_pk?: Maybe<User_Statuses>;
  /** update multiples rows of table: "user_statuses" */
  update_user_statuses_many?: Maybe<
    Array<Maybe<User_Statuses_Mutation_Response>>
  >;
  /** update data of the table: "users" */
  update_users?: Maybe<Users_Mutation_Response>;
  /** update single row of the table: "users" */
  update_users_by_pk?: Maybe<Users>;
  /** update multiples rows of table: "users" */
  update_users_many?: Maybe<Array<Maybe<Users_Mutation_Response>>>;
  /** update data of the table: "webhooks" */
  update_webhooks?: Maybe<Webhooks_Mutation_Response>;
  /** update single row of the table: "webhooks" */
  update_webhooks_by_pk?: Maybe<Webhooks>;
  /** update multiples rows of table: "webhooks" */
  update_webhooks_many?: Maybe<Array<Maybe<Webhooks_Mutation_Response>>>;
  /** update data of the table: "workflow_templates" */
  update_workflow_templates?: Maybe<Workflow_Templates_Mutation_Response>;
  /** update single row of the table: "workflow_templates" */
  update_workflow_templates_by_pk?: Maybe<Workflow_Templates>;
  /** update multiples rows of table: "workflow_templates" */
  update_workflow_templates_many?: Maybe<
    Array<Maybe<Workflow_Templates_Mutation_Response>>
  >;
};

/** mutation root */
export type Mutation_RootAddMfaMethodArgs = {
  params: AddMfaMethodInput;
};

/** mutation root */
export type Mutation_RootApproveTaskArgs = {
  params: TaskApprovalGrantedInput;
};

/** mutation root */
export type Mutation_RootCancelSubscriptionArgs = {
  params: CancelSubscriptionInput;
};

/** mutation root */
export type Mutation_RootChangePasswordArgs = {
  params: ChangePasswordInput;
};

/** mutation root */
export type Mutation_RootCompleteLoginArgs = {
  params: CompleteLoginInput;
};

/** mutation root */
export type Mutation_RootCompleteMemberLoginArgs = {
  params: CompleteMemberLoginInput;
};

/** mutation root */
export type Mutation_RootCreateAndSubscribeToChannelArgs = {
  params: CreateAndSubscribeToChannelInput;
};

/** mutation root */
export type Mutation_RootCreateFromTemplateArgs = {
  params: CreateFromTemplateInput;
};

/** mutation root */
export type Mutation_RootCreateTableTriggerArgs = {
  params: CreateTableTriggerInput;
};

/** mutation root */
export type Mutation_RootCreateTemplateArgs = {
  params: CreateTemplateInput;
};

/** mutation root */
export type Mutation_RootCreate_LedgerArgs = {
  input: CreateLedgerInput;
};

/** mutation root */
export type Mutation_RootCreate_Ledger_AccountArgs = {
  input: CreateLedgerAccountInput;
};

/** mutation root */
export type Mutation_RootCreate_Payment_IntentArgs = {
  input: CreatePaymentIntentInput;
};

/** mutation root */
export type Mutation_RootDelete_Activity_LogsArgs = {
  where: Activity_Logs_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Activity_Logs_By_PkArgs = {
  id: Scalars["uuid"];
};

/** mutation root */
export type Mutation_RootDelete_Audit_LogsArgs = {
  where: Audit_Logs_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Audit_Logs_By_PkArgs = {
  id: Scalars["uuid"];
};

/** mutation root */
export type Mutation_RootDelete_Campaign_ContactsArgs = {
  where: Campaign_Contacts_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Campaign_Contacts_By_PkArgs = {
  id: Scalars["uuid"];
};

/** mutation root */
export type Mutation_RootDelete_Campaign_Performance_IndicatorsArgs = {
  where: Campaign_Performance_Indicators_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Campaign_Performance_Indicators_By_PkArgs = {
  id: Scalars["uuid"];
};

/** mutation root */
export type Mutation_RootDelete_Campaign_RewardsArgs = {
  where: Campaign_Rewards_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Campaign_Rewards_By_PkArgs = {
  id: Scalars["uuid"];
};

/** mutation root */
export type Mutation_RootDelete_Campaign_RulesArgs = {
  where: Campaign_Rules_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Campaign_Rules_By_PkArgs = {
  id: Scalars["uuid"];
};

/** mutation root */
export type Mutation_RootDelete_Campaign_TasksArgs = {
  where: Campaign_Tasks_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Campaign_Tasks_By_PkArgs = {
  id: Scalars["uuid"];
};

/** mutation root */
export type Mutation_RootDelete_Campaign_WorkflowsArgs = {
  where: Campaign_Workflows_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Campaign_Workflows_By_PkArgs = {
  id: Scalars["uuid"];
};

/** mutation root */
export type Mutation_RootDelete_CampaignsArgs = {
  where: Campaigns_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Campaigns_By_PkArgs = {
  id: Scalars["uuid"];
};

/** mutation root */
export type Mutation_RootDelete_Case_AssignmentsArgs = {
  where: Case_Assignments_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Case_Assignments_By_PkArgs = {
  id: Scalars["uuid"];
};

/** mutation root */
export type Mutation_RootDelete_Case_DetailsArgs = {
  where: Case_Details_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Case_Details_By_PkArgs = {
  id: Scalars["uuid"];
};

/** mutation root */
export type Mutation_RootDelete_Case_DocumentsArgs = {
  where: Case_Documents_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Case_Documents_By_PkArgs = {
  id: Scalars["uuid"];
};

/** mutation root */
export type Mutation_RootDelete_Case_FlagsArgs = {
  where: Case_Flags_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Case_Flags_By_PkArgs = {
  id: Scalars["uuid"];
};

/** mutation root */
export type Mutation_RootDelete_Case_InteractionsArgs = {
  where: Case_Interactions_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Case_Interactions_By_PkArgs = {
  id: Scalars["uuid"];
};

/** mutation root */
export type Mutation_RootDelete_Case_MentionsArgs = {
  where: Case_Mentions_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Case_Mentions_By_PkArgs = {
  id: Scalars["uuid"];
};

/** mutation root */
export type Mutation_RootDelete_Case_StatusesArgs = {
  where: Case_Statuses_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Case_Statuses_By_PkArgs = {
  id: Scalars["uuid"];
};

/** mutation root */
export type Mutation_RootDelete_CasesArgs = {
  where: Cases_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Cases_By_PkArgs = {
  id: Scalars["uuid"];
};

/** mutation root */
export type Mutation_RootDelete_Channel_FlagsArgs = {
  where: Channel_Flags_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Channel_Flags_By_PkArgs = {
  id: Scalars["uuid"];
};

/** mutation root */
export type Mutation_RootDelete_Channel_PartiesArgs = {
  where: Channel_Parties_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Channel_Parties_By_PkArgs = {
  id: Scalars["uuid"];
};

/** mutation root */
export type Mutation_RootDelete_Channel_StatusesArgs = {
  where: Channel_Statuses_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Channel_Statuses_By_PkArgs = {
  id: Scalars["uuid"];
};

/** mutation root */
export type Mutation_RootDelete_Channel_SubscriptionsArgs = {
  where: Channel_Subscriptions_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Channel_Subscriptions_By_PkArgs = {
  id: Scalars["uuid"];
};

/** mutation root */
export type Mutation_RootDelete_ChannelsArgs = {
  where: Channels_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Channels_By_PkArgs = {
  id: Scalars["uuid"];
};

/** mutation root */
export type Mutation_RootDelete_ConditionsArgs = {
  where: Conditions_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Conditions_By_PkArgs = {
  id: Scalars["uuid"];
};

/** mutation root */
export type Mutation_RootDelete_Contact_DetailsArgs = {
  where: Contact_Details_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Contact_Details_By_PkArgs = {
  id: Scalars["uuid"];
};

/** mutation root */
export type Mutation_RootDelete_Contact_InteractionsArgs = {
  where: Contact_Interactions_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Contact_Interactions_By_PkArgs = {
  id: Scalars["uuid"];
};

/** mutation root */
export type Mutation_RootDelete_Contact_RelationshipsArgs = {
  where: Contact_Relationships_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Contact_Relationships_By_PkArgs = {
  id: Scalars["uuid"];
};

/** mutation root */
export type Mutation_RootDelete_ContactsArgs = {
  where: Contacts_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Contacts_By_PkArgs = {
  id: Scalars["uuid"];
};

/** mutation root */
export type Mutation_RootDelete_Credit_Profile_FlagsArgs = {
  where: Credit_Profile_Flags_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Credit_Profile_Flags_By_PkArgs = {
  id: Scalars["uuid"];
};

/** mutation root */
export type Mutation_RootDelete_Credit_Profile_StatusesArgs = {
  where: Credit_Profile_Statuses_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Credit_Profile_Statuses_By_PkArgs = {
  id: Scalars["uuid"];
};

/** mutation root */
export type Mutation_RootDelete_Credit_ProfilesArgs = {
  where: Credit_Profiles_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Credit_Profiles_By_PkArgs = {
  id: Scalars["uuid"];
};

/** mutation root */
export type Mutation_RootDelete_Delivery_MethodsArgs = {
  where: Delivery_Methods_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Delivery_Methods_By_PkArgs = {
  id: Scalars["uuid"];
};

/** mutation root */
export type Mutation_RootDelete_Document_InteractionsArgs = {
  where: Document_Interactions_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Document_Interactions_By_PkArgs = {
  id: Scalars["uuid"];
};

/** mutation root */
export type Mutation_RootDelete_DocumentsArgs = {
  where: Documents_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Documents_By_PkArgs = {
  id: Scalars["uuid"];
};

/** mutation root */
export type Mutation_RootDelete_FlagsArgs = {
  where: Flags_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Flags_By_PkArgs = {
  id: Scalars["uuid"];
};

/** mutation root */
export type Mutation_RootDelete_Form_FieldsArgs = {
  where: Form_Fields_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Form_Fields_By_PkArgs = {
  id: Scalars["uuid"];
};

/** mutation root */
export type Mutation_RootDelete_Form_RulesArgs = {
  where: Form_Rules_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Form_Rules_By_PkArgs = {
  id: Scalars["uuid"];
};

/** mutation root */
export type Mutation_RootDelete_FormsArgs = {
  where: Forms_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Forms_By_PkArgs = {
  id: Scalars["uuid"];
};

/** mutation root */
export type Mutation_RootDelete_Interaction_DetailsArgs = {
  where: Interaction_Details_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Interaction_Details_By_PkArgs = {
  id: Scalars["uuid"];
};

/** mutation root */
export type Mutation_RootDelete_InteractionsArgs = {
  where: Interactions_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Interactions_By_PkArgs = {
  id: Scalars["uuid"];
};

/** mutation root */
export type Mutation_RootDelete_Invoice_InteractionsArgs = {
  where: Invoice_Interactions_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Invoice_Interactions_By_PkArgs = {
  id: Scalars["uuid"];
};

/** mutation root */
export type Mutation_RootDelete_Invoice_ItemsArgs = {
  where: Invoice_Items_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Invoice_Items_By_PkArgs = {
  id: Scalars["uuid"];
};

/** mutation root */
export type Mutation_RootDelete_InvoicesArgs = {
  where: Invoices_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Invoices_By_PkArgs = {
  id: Scalars["uuid"];
};

/** mutation root */
export type Mutation_RootDelete_Message_Delivery_MethodsArgs = {
  where: Message_Delivery_Methods_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Message_Delivery_Methods_By_PkArgs = {
  id: Scalars["uuid"];
};

/** mutation root */
export type Mutation_RootDelete_Message_Delivery_ResponsesArgs = {
  where: Message_Delivery_Responses_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Message_Delivery_Responses_By_PkArgs = {
  id: Scalars["uuid"];
};

/** mutation root */
export type Mutation_RootDelete_Message_FlagsArgs = {
  where: Message_Flags_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Message_Flags_By_PkArgs = {
  id: Scalars["uuid"];
};

/** mutation root */
export type Mutation_RootDelete_Message_StatusesArgs = {
  where: Message_Statuses_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Message_Statuses_By_PkArgs = {
  id: Scalars["uuid"];
};

/** mutation root */
export type Mutation_RootDelete_MessagesArgs = {
  where: Messages_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Messages_By_PkArgs = {
  id: Scalars["uuid"];
};

/** mutation root */
export type Mutation_RootDelete_Organisation_CredentialsArgs = {
  where: Organisation_Credentials_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Organisation_Credentials_By_PkArgs = {
  id: Scalars["uuid"];
};

/** mutation root */
export type Mutation_RootDelete_Organisation_FlagsArgs = {
  where: Organisation_Flags_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Organisation_Flags_By_PkArgs = {
  id: Scalars["uuid"];
};

/** mutation root */
export type Mutation_RootDelete_Organisation_InvitesArgs = {
  where: Organisation_Invites_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Organisation_Invites_By_PkArgs = {
  id: Scalars["uuid"];
};

/** mutation root */
export type Mutation_RootDelete_Organisation_RulesArgs = {
  where: Organisation_Rules_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Organisation_Rules_By_PkArgs = {
  id: Scalars["uuid"];
};

/** mutation root */
export type Mutation_RootDelete_Organisation_SettingsArgs = {
  where: Organisation_Settings_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Organisation_Settings_By_PkArgs = {
  id: Scalars["uuid"];
};

/** mutation root */
export type Mutation_RootDelete_Organisation_StatusesArgs = {
  where: Organisation_Statuses_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Organisation_Statuses_By_PkArgs = {
  id: Scalars["uuid"];
};

/** mutation root */
export type Mutation_RootDelete_OrganisationsArgs = {
  where: Organisations_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Organisations_By_PkArgs = {
  id: Scalars["uuid"];
};

/** mutation root */
export type Mutation_RootDelete_PartiesArgs = {
  where: Parties_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Parties_By_PkArgs = {
  id: Scalars["uuid"];
};

/** mutation root */
export type Mutation_RootDelete_Party_ContactsArgs = {
  where: Party_Contacts_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Party_Contacts_By_PkArgs = {
  id: Scalars["uuid"];
};

/** mutation root */
export type Mutation_RootDelete_Party_DocumentsArgs = {
  where: Party_Documents_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Party_Documents_By_PkArgs = {
  id: Scalars["uuid"];
};

/** mutation root */
export type Mutation_RootDelete_Party_FlagsArgs = {
  where: Party_Flags_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Party_Flags_By_PkArgs = {
  id: Scalars["uuid"];
};

/** mutation root */
export type Mutation_RootDelete_Party_InteractionsArgs = {
  where: Party_Interactions_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Party_Interactions_By_PkArgs = {
  id: Scalars["uuid"];
};

/** mutation root */
export type Mutation_RootDelete_Party_Ledger_AccountsArgs = {
  where: Party_Ledger_Accounts_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Party_Ledger_Accounts_By_PkArgs = {
  id: Scalars["uuid"];
};

/** mutation root */
export type Mutation_RootDelete_Party_Relationship_StatusesArgs = {
  where: Party_Relationship_Statuses_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Party_Relationship_Statuses_By_PkArgs = {
  id: Scalars["uuid"];
};

/** mutation root */
export type Mutation_RootDelete_Party_RelationshipsArgs = {
  where: Party_Relationships_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Party_Relationships_By_PkArgs = {
  id: Scalars["uuid"];
};

/** mutation root */
export type Mutation_RootDelete_Party_SettingsArgs = {
  where: Party_Settings_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Party_Settings_By_PkArgs = {
  id: Scalars["uuid"];
};

/** mutation root */
export type Mutation_RootDelete_Party_StatusesArgs = {
  where: Party_Statuses_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Party_Statuses_By_PkArgs = {
  id: Scalars["uuid"];
};

/** mutation root */
export type Mutation_RootDelete_Performance_IndicatorsArgs = {
  where: Performance_Indicators_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Performance_Indicators_By_PkArgs = {
  id: Scalars["uuid"];
};

/** mutation root */
export type Mutation_RootDelete_Performance_TargetsArgs = {
  where: Performance_Targets_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Performance_Targets_By_PkArgs = {
  id: Scalars["uuid"];
};

/** mutation root */
export type Mutation_RootDelete_Product_DocumentsArgs = {
  where: Product_Documents_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Product_Documents_By_PkArgs = {
  id: Scalars["uuid"];
};

/** mutation root */
export type Mutation_RootDelete_Product_InteractionsArgs = {
  where: Product_Interactions_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Product_Interactions_By_PkArgs = {
  id: Scalars["uuid"];
};

/** mutation root */
export type Mutation_RootDelete_Product_ItemsArgs = {
  where: Product_Items_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Product_Items_By_PkArgs = {
  id: Scalars["uuid"];
};

/** mutation root */
export type Mutation_RootDelete_Product_RulesArgs = {
  where: Product_Rules_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Product_Rules_By_PkArgs = {
  id: Scalars["uuid"];
};

/** mutation root */
export type Mutation_RootDelete_Product_SettingsArgs = {
  where: Product_Settings_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Product_Settings_By_PkArgs = {
  id: Scalars["uuid"];
};

/** mutation root */
export type Mutation_RootDelete_Product_StatusesArgs = {
  where: Product_Statuses_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Product_Statuses_By_PkArgs = {
  id: Scalars["uuid"];
};

/** mutation root */
export type Mutation_RootDelete_Product_TemplatesArgs = {
  where: Product_Templates_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Product_Templates_By_PkArgs = {
  id: Scalars["uuid"];
};

/** mutation root */
export type Mutation_RootDelete_ProductsArgs = {
  where: Products_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Products_By_PkArgs = {
  id: Scalars["uuid"];
};

/** mutation root */
export type Mutation_RootDelete_Reward_RedemptionsArgs = {
  where: Reward_Redemptions_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Reward_Redemptions_By_PkArgs = {
  id: Scalars["uuid"];
};

/** mutation root */
export type Mutation_RootDelete_RewardsArgs = {
  where: Rewards_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Rewards_By_PkArgs = {
  id: Scalars["uuid"];
};

/** mutation root */
export type Mutation_RootDelete_RulesArgs = {
  where: Rules_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Rules_By_PkArgs = {
  id: Scalars["uuid"];
};

/** mutation root */
export type Mutation_RootDelete_StatusesArgs = {
  where: Statuses_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Statuses_By_PkArgs = {
  id: Scalars["uuid"];
};

/** mutation root */
export type Mutation_RootDelete_Subscription_CasesArgs = {
  where: Subscription_Cases_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Subscription_Cases_By_PkArgs = {
  id: Scalars["uuid"];
};

/** mutation root */
export type Mutation_RootDelete_Subscription_DetailsArgs = {
  where: Subscription_Details_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Subscription_Details_By_PkArgs = {
  id: Scalars["uuid"];
};

/** mutation root */
export type Mutation_RootDelete_Subscription_DocumentsArgs = {
  where: Subscription_Documents_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Subscription_Documents_By_PkArgs = {
  id: Scalars["uuid"];
};

/** mutation root */
export type Mutation_RootDelete_Subscription_FlagsArgs = {
  where: Subscription_Flags_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Subscription_Flags_By_PkArgs = {
  id: Scalars["uuid"];
};

/** mutation root */
export type Mutation_RootDelete_Subscription_InteractionsArgs = {
  where: Subscription_Interactions_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Subscription_Interactions_By_PkArgs = {
  id: Scalars["uuid"];
};

/** mutation root */
export type Mutation_RootDelete_Subscription_ItemsArgs = {
  where: Subscription_Items_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Subscription_Items_By_PkArgs = {
  id: Scalars["uuid"];
};

/** mutation root */
export type Mutation_RootDelete_Subscription_Payment_TransactionsArgs = {
  where: Subscription_Payment_Transactions_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Subscription_Payment_Transactions_By_PkArgs = {
  id: Scalars["uuid"];
};

/** mutation root */
export type Mutation_RootDelete_Subscription_PaymentsArgs = {
  where: Subscription_Payments_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Subscription_Payments_By_PkArgs = {
  id: Scalars["uuid"];
};

/** mutation root */
export type Mutation_RootDelete_Subscription_RulesArgs = {
  where: Subscription_Rules_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Subscription_Rules_By_PkArgs = {
  id: Scalars["uuid"];
};

/** mutation root */
export type Mutation_RootDelete_Subscription_SchedulesArgs = {
  where: Subscription_Schedules_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Subscription_Schedules_By_PkArgs = {
  id: Scalars["uuid"];
};

/** mutation root */
export type Mutation_RootDelete_Subscription_StatusesArgs = {
  where: Subscription_Statuses_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Subscription_Statuses_By_PkArgs = {
  id: Scalars["uuid"];
};

/** mutation root */
export type Mutation_RootDelete_Subscription_TasksArgs = {
  where: Subscription_Tasks_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Subscription_Tasks_By_PkArgs = {
  id: Scalars["uuid"];
};

/** mutation root */
export type Mutation_RootDelete_SubscriptionsArgs = {
  where: Subscriptions_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Subscriptions_By_PkArgs = {
  id: Scalars["uuid"];
};

/** mutation root */
export type Mutation_RootDelete_Tariff_HistoriesArgs = {
  where: Tariff_Histories_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Tariff_Histories_By_PkArgs = {
  id: Scalars["uuid"];
};

/** mutation root */
export type Mutation_RootDelete_TariffsArgs = {
  where: Tariffs_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Tariffs_By_PkArgs = {
  id: Scalars["uuid"];
};

/** mutation root */
export type Mutation_RootDelete_Task_AssignmentsArgs = {
  where: Task_Assignments_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Task_Assignments_By_PkArgs = {
  id: Scalars["uuid"];
};

/** mutation root */
export type Mutation_RootDelete_Task_DocumentsArgs = {
  where: Task_Documents_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Task_Documents_By_PkArgs = {
  id: Scalars["uuid"];
};

/** mutation root */
export type Mutation_RootDelete_Task_FlagsArgs = {
  where: Task_Flags_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Task_Flags_By_PkArgs = {
  id: Scalars["uuid"];
};

/** mutation root */
export type Mutation_RootDelete_Task_InteractionsArgs = {
  where: Task_Interactions_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Task_Interactions_By_PkArgs = {
  id: Scalars["uuid"];
};

/** mutation root */
export type Mutation_RootDelete_Task_MentionsArgs = {
  where: Task_Mentions_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Task_Mentions_By_PkArgs = {
  id: Scalars["uuid"];
};

/** mutation root */
export type Mutation_RootDelete_Task_RemindersArgs = {
  where: Task_Reminders_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Task_Reminders_By_PkArgs = {
  id: Scalars["uuid"];
};

/** mutation root */
export type Mutation_RootDelete_Task_RulesArgs = {
  where: Task_Rules_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Task_Rules_By_PkArgs = {
  id: Scalars["uuid"];
};

/** mutation root */
export type Mutation_RootDelete_Task_StatusesArgs = {
  where: Task_Statuses_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Task_Statuses_By_PkArgs = {
  id: Scalars["uuid"];
};

/** mutation root */
export type Mutation_RootDelete_TasksArgs = {
  where: Tasks_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Tasks_By_PkArgs = {
  id: Scalars["uuid"];
};

/** mutation root */
export type Mutation_RootDelete_TerritoriesArgs = {
  where: Territories_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Territories_By_PkArgs = {
  id: Scalars["uuid"];
};

/** mutation root */
export type Mutation_RootDelete_Territory_AssignmentsArgs = {
  where: Territory_Assignments_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Territory_Assignments_By_PkArgs = {
  id: Scalars["uuid"];
};

/** mutation root */
export type Mutation_RootDelete_Territory_FlagsArgs = {
  where: Territory_Flags_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Territory_Flags_By_PkArgs = {
  id: Scalars["uuid"];
};

/** mutation root */
export type Mutation_RootDelete_Territory_InteractionsArgs = {
  where: Territory_Interactions_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Territory_Interactions_By_PkArgs = {
  id: Scalars["uuid"];
};

/** mutation root */
export type Mutation_RootDelete_Territory_MembersArgs = {
  where: Territory_Members_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Territory_Members_By_PkArgs = {
  id: Scalars["uuid"];
};

/** mutation root */
export type Mutation_RootDelete_Territory_RulesArgs = {
  where: Territory_Rules_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Territory_Rules_By_PkArgs = {
  id: Scalars["uuid"];
};

/** mutation root */
export type Mutation_RootDelete_Territory_StatusesArgs = {
  where: Territory_Statuses_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Territory_Statuses_By_PkArgs = {
  id: Scalars["uuid"];
};

/** mutation root */
export type Mutation_RootDelete_TypesArgs = {
  where: Types_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Types_By_PkArgs = {
  id: Scalars["uuid"];
};

/** mutation root */
export type Mutation_RootDelete_User_FlagsArgs = {
  where: User_Flags_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_User_Flags_By_PkArgs = {
  id: Scalars["uuid"];
};

/** mutation root */
export type Mutation_RootDelete_User_Mfa_FactorsArgs = {
  where: User_Mfa_Factors_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_User_Mfa_Factors_By_PkArgs = {
  id: Scalars["uuid"];
};

/** mutation root */
export type Mutation_RootDelete_User_StatusesArgs = {
  where: User_Statuses_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_User_Statuses_By_PkArgs = {
  id: Scalars["uuid"];
};

/** mutation root */
export type Mutation_RootDelete_UsersArgs = {
  where: Users_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Users_By_PkArgs = {
  id: Scalars["uuid"];
};

/** mutation root */
export type Mutation_RootDelete_WebhooksArgs = {
  where: Webhooks_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Webhooks_By_PkArgs = {
  id: Scalars["uuid"];
};

/** mutation root */
export type Mutation_RootDelete_Workflow_TemplatesArgs = {
  where: Workflow_Templates_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Workflow_Templates_By_PkArgs = {
  id: Scalars["uuid"];
};

/** mutation root */
export type Mutation_RootDuplicateFormArgs = {
  params: DuplicateFormInput;
};

/** mutation root */
export type Mutation_RootDuplicateTaskArgs = {
  params: DuplicateTaskInput;
};

/** mutation root */
export type Mutation_RootEditWorkflowSubmissionArgs = {
  params: ProcessWorkflowSubmissionInput;
};

/** mutation root */
export type Mutation_RootGenerateMagicLoginLinkArgs = {
  params: GenerateMagicLoginLinkInput;
};

/** mutation root */
export type Mutation_RootInitiatePasswordResetArgs = {
  params: IntiatePasswordResetInput;
};

/** mutation root */
export type Mutation_RootInsert_Activity_LogsArgs = {
  objects: Array<Activity_Logs_Insert_Input>;
  on_conflict?: InputMaybe<Activity_Logs_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Activity_Logs_OneArgs = {
  object: Activity_Logs_Insert_Input;
  on_conflict?: InputMaybe<Activity_Logs_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Audit_LogsArgs = {
  objects: Array<Audit_Logs_Insert_Input>;
  on_conflict?: InputMaybe<Audit_Logs_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Audit_Logs_OneArgs = {
  object: Audit_Logs_Insert_Input;
  on_conflict?: InputMaybe<Audit_Logs_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Campaign_ContactsArgs = {
  objects: Array<Campaign_Contacts_Insert_Input>;
  on_conflict?: InputMaybe<Campaign_Contacts_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Campaign_Contacts_OneArgs = {
  object: Campaign_Contacts_Insert_Input;
  on_conflict?: InputMaybe<Campaign_Contacts_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Campaign_Performance_IndicatorsArgs = {
  objects: Array<Campaign_Performance_Indicators_Insert_Input>;
  on_conflict?: InputMaybe<Campaign_Performance_Indicators_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Campaign_Performance_Indicators_OneArgs = {
  object: Campaign_Performance_Indicators_Insert_Input;
  on_conflict?: InputMaybe<Campaign_Performance_Indicators_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Campaign_RewardsArgs = {
  objects: Array<Campaign_Rewards_Insert_Input>;
  on_conflict?: InputMaybe<Campaign_Rewards_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Campaign_Rewards_OneArgs = {
  object: Campaign_Rewards_Insert_Input;
  on_conflict?: InputMaybe<Campaign_Rewards_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Campaign_RulesArgs = {
  objects: Array<Campaign_Rules_Insert_Input>;
  on_conflict?: InputMaybe<Campaign_Rules_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Campaign_Rules_OneArgs = {
  object: Campaign_Rules_Insert_Input;
  on_conflict?: InputMaybe<Campaign_Rules_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Campaign_TasksArgs = {
  objects: Array<Campaign_Tasks_Insert_Input>;
  on_conflict?: InputMaybe<Campaign_Tasks_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Campaign_Tasks_OneArgs = {
  object: Campaign_Tasks_Insert_Input;
  on_conflict?: InputMaybe<Campaign_Tasks_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Campaign_WorkflowsArgs = {
  objects: Array<Campaign_Workflows_Insert_Input>;
  on_conflict?: InputMaybe<Campaign_Workflows_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Campaign_Workflows_OneArgs = {
  object: Campaign_Workflows_Insert_Input;
  on_conflict?: InputMaybe<Campaign_Workflows_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_CampaignsArgs = {
  objects: Array<Campaigns_Insert_Input>;
  on_conflict?: InputMaybe<Campaigns_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Campaigns_OneArgs = {
  object: Campaigns_Insert_Input;
  on_conflict?: InputMaybe<Campaigns_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Case_AssignmentsArgs = {
  objects: Array<Case_Assignments_Insert_Input>;
  on_conflict?: InputMaybe<Case_Assignments_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Case_Assignments_OneArgs = {
  object: Case_Assignments_Insert_Input;
  on_conflict?: InputMaybe<Case_Assignments_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Case_DetailsArgs = {
  objects: Array<Case_Details_Insert_Input>;
  on_conflict?: InputMaybe<Case_Details_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Case_Details_OneArgs = {
  object: Case_Details_Insert_Input;
  on_conflict?: InputMaybe<Case_Details_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Case_DocumentsArgs = {
  objects: Array<Case_Documents_Insert_Input>;
  on_conflict?: InputMaybe<Case_Documents_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Case_Documents_OneArgs = {
  object: Case_Documents_Insert_Input;
  on_conflict?: InputMaybe<Case_Documents_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Case_FlagsArgs = {
  objects: Array<Case_Flags_Insert_Input>;
  on_conflict?: InputMaybe<Case_Flags_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Case_Flags_OneArgs = {
  object: Case_Flags_Insert_Input;
  on_conflict?: InputMaybe<Case_Flags_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Case_InteractionsArgs = {
  objects: Array<Case_Interactions_Insert_Input>;
  on_conflict?: InputMaybe<Case_Interactions_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Case_Interactions_OneArgs = {
  object: Case_Interactions_Insert_Input;
  on_conflict?: InputMaybe<Case_Interactions_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Case_MentionsArgs = {
  objects: Array<Case_Mentions_Insert_Input>;
  on_conflict?: InputMaybe<Case_Mentions_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Case_Mentions_OneArgs = {
  object: Case_Mentions_Insert_Input;
  on_conflict?: InputMaybe<Case_Mentions_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Case_StatusesArgs = {
  objects: Array<Case_Statuses_Insert_Input>;
  on_conflict?: InputMaybe<Case_Statuses_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Case_Statuses_OneArgs = {
  object: Case_Statuses_Insert_Input;
  on_conflict?: InputMaybe<Case_Statuses_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_CasesArgs = {
  objects: Array<Cases_Insert_Input>;
  on_conflict?: InputMaybe<Cases_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Cases_OneArgs = {
  object: Cases_Insert_Input;
  on_conflict?: InputMaybe<Cases_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Channel_FlagsArgs = {
  objects: Array<Channel_Flags_Insert_Input>;
  on_conflict?: InputMaybe<Channel_Flags_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Channel_Flags_OneArgs = {
  object: Channel_Flags_Insert_Input;
  on_conflict?: InputMaybe<Channel_Flags_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Channel_PartiesArgs = {
  objects: Array<Channel_Parties_Insert_Input>;
  on_conflict?: InputMaybe<Channel_Parties_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Channel_Parties_OneArgs = {
  object: Channel_Parties_Insert_Input;
  on_conflict?: InputMaybe<Channel_Parties_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Channel_StatusesArgs = {
  objects: Array<Channel_Statuses_Insert_Input>;
  on_conflict?: InputMaybe<Channel_Statuses_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Channel_Statuses_OneArgs = {
  object: Channel_Statuses_Insert_Input;
  on_conflict?: InputMaybe<Channel_Statuses_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Channel_SubscriptionsArgs = {
  objects: Array<Channel_Subscriptions_Insert_Input>;
  on_conflict?: InputMaybe<Channel_Subscriptions_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Channel_Subscriptions_OneArgs = {
  object: Channel_Subscriptions_Insert_Input;
  on_conflict?: InputMaybe<Channel_Subscriptions_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_ChannelsArgs = {
  objects: Array<Channels_Insert_Input>;
  on_conflict?: InputMaybe<Channels_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Channels_OneArgs = {
  object: Channels_Insert_Input;
  on_conflict?: InputMaybe<Channels_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_ConditionsArgs = {
  objects: Array<Conditions_Insert_Input>;
  on_conflict?: InputMaybe<Conditions_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Conditions_OneArgs = {
  object: Conditions_Insert_Input;
  on_conflict?: InputMaybe<Conditions_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Contact_DetailsArgs = {
  objects: Array<Contact_Details_Insert_Input>;
  on_conflict?: InputMaybe<Contact_Details_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Contact_Details_OneArgs = {
  object: Contact_Details_Insert_Input;
  on_conflict?: InputMaybe<Contact_Details_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Contact_InteractionsArgs = {
  objects: Array<Contact_Interactions_Insert_Input>;
  on_conflict?: InputMaybe<Contact_Interactions_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Contact_Interactions_OneArgs = {
  object: Contact_Interactions_Insert_Input;
  on_conflict?: InputMaybe<Contact_Interactions_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Contact_RelationshipsArgs = {
  objects: Array<Contact_Relationships_Insert_Input>;
  on_conflict?: InputMaybe<Contact_Relationships_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Contact_Relationships_OneArgs = {
  object: Contact_Relationships_Insert_Input;
  on_conflict?: InputMaybe<Contact_Relationships_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_ContactsArgs = {
  objects: Array<Contacts_Insert_Input>;
  on_conflict?: InputMaybe<Contacts_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Contacts_OneArgs = {
  object: Contacts_Insert_Input;
  on_conflict?: InputMaybe<Contacts_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Credit_Profile_FlagsArgs = {
  objects: Array<Credit_Profile_Flags_Insert_Input>;
  on_conflict?: InputMaybe<Credit_Profile_Flags_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Credit_Profile_Flags_OneArgs = {
  object: Credit_Profile_Flags_Insert_Input;
  on_conflict?: InputMaybe<Credit_Profile_Flags_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Credit_Profile_StatusesArgs = {
  objects: Array<Credit_Profile_Statuses_Insert_Input>;
  on_conflict?: InputMaybe<Credit_Profile_Statuses_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Credit_Profile_Statuses_OneArgs = {
  object: Credit_Profile_Statuses_Insert_Input;
  on_conflict?: InputMaybe<Credit_Profile_Statuses_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Credit_ProfilesArgs = {
  objects: Array<Credit_Profiles_Insert_Input>;
  on_conflict?: InputMaybe<Credit_Profiles_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Credit_Profiles_OneArgs = {
  object: Credit_Profiles_Insert_Input;
  on_conflict?: InputMaybe<Credit_Profiles_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Delivery_MethodsArgs = {
  objects: Array<Delivery_Methods_Insert_Input>;
  on_conflict?: InputMaybe<Delivery_Methods_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Delivery_Methods_OneArgs = {
  object: Delivery_Methods_Insert_Input;
  on_conflict?: InputMaybe<Delivery_Methods_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Document_InteractionsArgs = {
  objects: Array<Document_Interactions_Insert_Input>;
  on_conflict?: InputMaybe<Document_Interactions_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Document_Interactions_OneArgs = {
  object: Document_Interactions_Insert_Input;
  on_conflict?: InputMaybe<Document_Interactions_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_DocumentsArgs = {
  objects: Array<Documents_Insert_Input>;
  on_conflict?: InputMaybe<Documents_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Documents_OneArgs = {
  object: Documents_Insert_Input;
  on_conflict?: InputMaybe<Documents_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_FlagsArgs = {
  objects: Array<Flags_Insert_Input>;
  on_conflict?: InputMaybe<Flags_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Flags_OneArgs = {
  object: Flags_Insert_Input;
  on_conflict?: InputMaybe<Flags_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Form_FieldsArgs = {
  objects: Array<Form_Fields_Insert_Input>;
  on_conflict?: InputMaybe<Form_Fields_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Form_Fields_OneArgs = {
  object: Form_Fields_Insert_Input;
  on_conflict?: InputMaybe<Form_Fields_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Form_RulesArgs = {
  objects: Array<Form_Rules_Insert_Input>;
  on_conflict?: InputMaybe<Form_Rules_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Form_Rules_OneArgs = {
  object: Form_Rules_Insert_Input;
  on_conflict?: InputMaybe<Form_Rules_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_FormsArgs = {
  objects: Array<Forms_Insert_Input>;
  on_conflict?: InputMaybe<Forms_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Forms_OneArgs = {
  object: Forms_Insert_Input;
  on_conflict?: InputMaybe<Forms_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Interaction_DetailsArgs = {
  objects: Array<Interaction_Details_Insert_Input>;
  on_conflict?: InputMaybe<Interaction_Details_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Interaction_Details_OneArgs = {
  object: Interaction_Details_Insert_Input;
  on_conflict?: InputMaybe<Interaction_Details_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_InteractionsArgs = {
  objects: Array<Interactions_Insert_Input>;
  on_conflict?: InputMaybe<Interactions_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Interactions_OneArgs = {
  object: Interactions_Insert_Input;
  on_conflict?: InputMaybe<Interactions_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Invoice_InteractionsArgs = {
  objects: Array<Invoice_Interactions_Insert_Input>;
  on_conflict?: InputMaybe<Invoice_Interactions_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Invoice_Interactions_OneArgs = {
  object: Invoice_Interactions_Insert_Input;
  on_conflict?: InputMaybe<Invoice_Interactions_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Invoice_ItemsArgs = {
  objects: Array<Invoice_Items_Insert_Input>;
  on_conflict?: InputMaybe<Invoice_Items_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Invoice_Items_OneArgs = {
  object: Invoice_Items_Insert_Input;
  on_conflict?: InputMaybe<Invoice_Items_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_InvoicesArgs = {
  objects: Array<Invoices_Insert_Input>;
  on_conflict?: InputMaybe<Invoices_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Invoices_OneArgs = {
  object: Invoices_Insert_Input;
  on_conflict?: InputMaybe<Invoices_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Message_Delivery_MethodsArgs = {
  objects: Array<Message_Delivery_Methods_Insert_Input>;
  on_conflict?: InputMaybe<Message_Delivery_Methods_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Message_Delivery_Methods_OneArgs = {
  object: Message_Delivery_Methods_Insert_Input;
  on_conflict?: InputMaybe<Message_Delivery_Methods_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Message_Delivery_ResponsesArgs = {
  objects: Array<Message_Delivery_Responses_Insert_Input>;
  on_conflict?: InputMaybe<Message_Delivery_Responses_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Message_Delivery_Responses_OneArgs = {
  object: Message_Delivery_Responses_Insert_Input;
  on_conflict?: InputMaybe<Message_Delivery_Responses_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Message_FlagsArgs = {
  objects: Array<Message_Flags_Insert_Input>;
  on_conflict?: InputMaybe<Message_Flags_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Message_Flags_OneArgs = {
  object: Message_Flags_Insert_Input;
  on_conflict?: InputMaybe<Message_Flags_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Message_StatusesArgs = {
  objects: Array<Message_Statuses_Insert_Input>;
  on_conflict?: InputMaybe<Message_Statuses_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Message_Statuses_OneArgs = {
  object: Message_Statuses_Insert_Input;
  on_conflict?: InputMaybe<Message_Statuses_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_MessagesArgs = {
  objects: Array<Messages_Insert_Input>;
  on_conflict?: InputMaybe<Messages_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Messages_OneArgs = {
  object: Messages_Insert_Input;
  on_conflict?: InputMaybe<Messages_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Organisation_CredentialsArgs = {
  objects: Array<Organisation_Credentials_Insert_Input>;
  on_conflict?: InputMaybe<Organisation_Credentials_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Organisation_Credentials_OneArgs = {
  object: Organisation_Credentials_Insert_Input;
  on_conflict?: InputMaybe<Organisation_Credentials_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Organisation_FlagsArgs = {
  objects: Array<Organisation_Flags_Insert_Input>;
  on_conflict?: InputMaybe<Organisation_Flags_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Organisation_Flags_OneArgs = {
  object: Organisation_Flags_Insert_Input;
  on_conflict?: InputMaybe<Organisation_Flags_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Organisation_InvitesArgs = {
  objects: Array<Organisation_Invites_Insert_Input>;
  on_conflict?: InputMaybe<Organisation_Invites_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Organisation_Invites_OneArgs = {
  object: Organisation_Invites_Insert_Input;
  on_conflict?: InputMaybe<Organisation_Invites_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Organisation_RulesArgs = {
  objects: Array<Organisation_Rules_Insert_Input>;
  on_conflict?: InputMaybe<Organisation_Rules_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Organisation_Rules_OneArgs = {
  object: Organisation_Rules_Insert_Input;
  on_conflict?: InputMaybe<Organisation_Rules_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Organisation_SettingsArgs = {
  objects: Array<Organisation_Settings_Insert_Input>;
  on_conflict?: InputMaybe<Organisation_Settings_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Organisation_Settings_OneArgs = {
  object: Organisation_Settings_Insert_Input;
  on_conflict?: InputMaybe<Organisation_Settings_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Organisation_StatusesArgs = {
  objects: Array<Organisation_Statuses_Insert_Input>;
  on_conflict?: InputMaybe<Organisation_Statuses_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Organisation_Statuses_OneArgs = {
  object: Organisation_Statuses_Insert_Input;
  on_conflict?: InputMaybe<Organisation_Statuses_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_OrganisationsArgs = {
  objects: Array<Organisations_Insert_Input>;
  on_conflict?: InputMaybe<Organisations_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Organisations_OneArgs = {
  object: Organisations_Insert_Input;
  on_conflict?: InputMaybe<Organisations_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_PartiesArgs = {
  objects: Array<Parties_Insert_Input>;
  on_conflict?: InputMaybe<Parties_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Parties_OneArgs = {
  object: Parties_Insert_Input;
  on_conflict?: InputMaybe<Parties_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Party_ContactsArgs = {
  objects: Array<Party_Contacts_Insert_Input>;
  on_conflict?: InputMaybe<Party_Contacts_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Party_Contacts_OneArgs = {
  object: Party_Contacts_Insert_Input;
  on_conflict?: InputMaybe<Party_Contacts_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Party_DocumentsArgs = {
  objects: Array<Party_Documents_Insert_Input>;
  on_conflict?: InputMaybe<Party_Documents_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Party_Documents_OneArgs = {
  object: Party_Documents_Insert_Input;
  on_conflict?: InputMaybe<Party_Documents_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Party_FlagsArgs = {
  objects: Array<Party_Flags_Insert_Input>;
  on_conflict?: InputMaybe<Party_Flags_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Party_Flags_OneArgs = {
  object: Party_Flags_Insert_Input;
  on_conflict?: InputMaybe<Party_Flags_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Party_InteractionsArgs = {
  objects: Array<Party_Interactions_Insert_Input>;
  on_conflict?: InputMaybe<Party_Interactions_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Party_Interactions_OneArgs = {
  object: Party_Interactions_Insert_Input;
  on_conflict?: InputMaybe<Party_Interactions_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Party_Ledger_AccountsArgs = {
  objects: Array<Party_Ledger_Accounts_Insert_Input>;
  on_conflict?: InputMaybe<Party_Ledger_Accounts_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Party_Ledger_Accounts_OneArgs = {
  object: Party_Ledger_Accounts_Insert_Input;
  on_conflict?: InputMaybe<Party_Ledger_Accounts_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Party_Relationship_StatusesArgs = {
  objects: Array<Party_Relationship_Statuses_Insert_Input>;
  on_conflict?: InputMaybe<Party_Relationship_Statuses_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Party_Relationship_Statuses_OneArgs = {
  object: Party_Relationship_Statuses_Insert_Input;
  on_conflict?: InputMaybe<Party_Relationship_Statuses_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Party_RelationshipsArgs = {
  objects: Array<Party_Relationships_Insert_Input>;
  on_conflict?: InputMaybe<Party_Relationships_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Party_Relationships_OneArgs = {
  object: Party_Relationships_Insert_Input;
  on_conflict?: InputMaybe<Party_Relationships_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Party_SettingsArgs = {
  objects: Array<Party_Settings_Insert_Input>;
  on_conflict?: InputMaybe<Party_Settings_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Party_Settings_OneArgs = {
  object: Party_Settings_Insert_Input;
  on_conflict?: InputMaybe<Party_Settings_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Party_StatusesArgs = {
  objects: Array<Party_Statuses_Insert_Input>;
  on_conflict?: InputMaybe<Party_Statuses_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Party_Statuses_OneArgs = {
  object: Party_Statuses_Insert_Input;
  on_conflict?: InputMaybe<Party_Statuses_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Performance_IndicatorsArgs = {
  objects: Array<Performance_Indicators_Insert_Input>;
  on_conflict?: InputMaybe<Performance_Indicators_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Performance_Indicators_OneArgs = {
  object: Performance_Indicators_Insert_Input;
  on_conflict?: InputMaybe<Performance_Indicators_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Performance_TargetsArgs = {
  objects: Array<Performance_Targets_Insert_Input>;
  on_conflict?: InputMaybe<Performance_Targets_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Performance_Targets_OneArgs = {
  object: Performance_Targets_Insert_Input;
  on_conflict?: InputMaybe<Performance_Targets_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Product_DocumentsArgs = {
  objects: Array<Product_Documents_Insert_Input>;
  on_conflict?: InputMaybe<Product_Documents_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Product_Documents_OneArgs = {
  object: Product_Documents_Insert_Input;
  on_conflict?: InputMaybe<Product_Documents_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Product_InteractionsArgs = {
  objects: Array<Product_Interactions_Insert_Input>;
  on_conflict?: InputMaybe<Product_Interactions_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Product_Interactions_OneArgs = {
  object: Product_Interactions_Insert_Input;
  on_conflict?: InputMaybe<Product_Interactions_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Product_ItemsArgs = {
  objects: Array<Product_Items_Insert_Input>;
  on_conflict?: InputMaybe<Product_Items_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Product_Items_OneArgs = {
  object: Product_Items_Insert_Input;
  on_conflict?: InputMaybe<Product_Items_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Product_RulesArgs = {
  objects: Array<Product_Rules_Insert_Input>;
  on_conflict?: InputMaybe<Product_Rules_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Product_Rules_OneArgs = {
  object: Product_Rules_Insert_Input;
  on_conflict?: InputMaybe<Product_Rules_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Product_SettingsArgs = {
  objects: Array<Product_Settings_Insert_Input>;
  on_conflict?: InputMaybe<Product_Settings_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Product_Settings_OneArgs = {
  object: Product_Settings_Insert_Input;
  on_conflict?: InputMaybe<Product_Settings_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Product_StatusesArgs = {
  objects: Array<Product_Statuses_Insert_Input>;
  on_conflict?: InputMaybe<Product_Statuses_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Product_Statuses_OneArgs = {
  object: Product_Statuses_Insert_Input;
  on_conflict?: InputMaybe<Product_Statuses_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Product_TemplatesArgs = {
  objects: Array<Product_Templates_Insert_Input>;
  on_conflict?: InputMaybe<Product_Templates_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Product_Templates_OneArgs = {
  object: Product_Templates_Insert_Input;
  on_conflict?: InputMaybe<Product_Templates_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_ProductsArgs = {
  objects: Array<Products_Insert_Input>;
  on_conflict?: InputMaybe<Products_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Products_OneArgs = {
  object: Products_Insert_Input;
  on_conflict?: InputMaybe<Products_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Reward_RedemptionsArgs = {
  objects: Array<Reward_Redemptions_Insert_Input>;
  on_conflict?: InputMaybe<Reward_Redemptions_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Reward_Redemptions_OneArgs = {
  object: Reward_Redemptions_Insert_Input;
  on_conflict?: InputMaybe<Reward_Redemptions_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_RewardsArgs = {
  objects: Array<Rewards_Insert_Input>;
  on_conflict?: InputMaybe<Rewards_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Rewards_OneArgs = {
  object: Rewards_Insert_Input;
  on_conflict?: InputMaybe<Rewards_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_RulesArgs = {
  objects: Array<Rules_Insert_Input>;
  on_conflict?: InputMaybe<Rules_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Rules_OneArgs = {
  object: Rules_Insert_Input;
  on_conflict?: InputMaybe<Rules_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_StatusesArgs = {
  objects: Array<Statuses_Insert_Input>;
  on_conflict?: InputMaybe<Statuses_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Statuses_OneArgs = {
  object: Statuses_Insert_Input;
  on_conflict?: InputMaybe<Statuses_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Subscription_CasesArgs = {
  objects: Array<Subscription_Cases_Insert_Input>;
  on_conflict?: InputMaybe<Subscription_Cases_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Subscription_Cases_OneArgs = {
  object: Subscription_Cases_Insert_Input;
  on_conflict?: InputMaybe<Subscription_Cases_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Subscription_DetailsArgs = {
  objects: Array<Subscription_Details_Insert_Input>;
  on_conflict?: InputMaybe<Subscription_Details_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Subscription_Details_OneArgs = {
  object: Subscription_Details_Insert_Input;
  on_conflict?: InputMaybe<Subscription_Details_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Subscription_DocumentsArgs = {
  objects: Array<Subscription_Documents_Insert_Input>;
  on_conflict?: InputMaybe<Subscription_Documents_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Subscription_Documents_OneArgs = {
  object: Subscription_Documents_Insert_Input;
  on_conflict?: InputMaybe<Subscription_Documents_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Subscription_FlagsArgs = {
  objects: Array<Subscription_Flags_Insert_Input>;
  on_conflict?: InputMaybe<Subscription_Flags_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Subscription_Flags_OneArgs = {
  object: Subscription_Flags_Insert_Input;
  on_conflict?: InputMaybe<Subscription_Flags_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Subscription_InteractionsArgs = {
  objects: Array<Subscription_Interactions_Insert_Input>;
  on_conflict?: InputMaybe<Subscription_Interactions_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Subscription_Interactions_OneArgs = {
  object: Subscription_Interactions_Insert_Input;
  on_conflict?: InputMaybe<Subscription_Interactions_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Subscription_ItemsArgs = {
  objects: Array<Subscription_Items_Insert_Input>;
  on_conflict?: InputMaybe<Subscription_Items_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Subscription_Items_OneArgs = {
  object: Subscription_Items_Insert_Input;
  on_conflict?: InputMaybe<Subscription_Items_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Subscription_Payment_TransactionsArgs = {
  objects: Array<Subscription_Payment_Transactions_Insert_Input>;
  on_conflict?: InputMaybe<Subscription_Payment_Transactions_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Subscription_Payment_Transactions_OneArgs = {
  object: Subscription_Payment_Transactions_Insert_Input;
  on_conflict?: InputMaybe<Subscription_Payment_Transactions_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Subscription_PaymentsArgs = {
  objects: Array<Subscription_Payments_Insert_Input>;
  on_conflict?: InputMaybe<Subscription_Payments_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Subscription_Payments_OneArgs = {
  object: Subscription_Payments_Insert_Input;
  on_conflict?: InputMaybe<Subscription_Payments_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Subscription_RulesArgs = {
  objects: Array<Subscription_Rules_Insert_Input>;
  on_conflict?: InputMaybe<Subscription_Rules_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Subscription_Rules_OneArgs = {
  object: Subscription_Rules_Insert_Input;
  on_conflict?: InputMaybe<Subscription_Rules_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Subscription_SchedulesArgs = {
  objects: Array<Subscription_Schedules_Insert_Input>;
  on_conflict?: InputMaybe<Subscription_Schedules_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Subscription_Schedules_OneArgs = {
  object: Subscription_Schedules_Insert_Input;
  on_conflict?: InputMaybe<Subscription_Schedules_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Subscription_StatusesArgs = {
  objects: Array<Subscription_Statuses_Insert_Input>;
  on_conflict?: InputMaybe<Subscription_Statuses_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Subscription_Statuses_OneArgs = {
  object: Subscription_Statuses_Insert_Input;
  on_conflict?: InputMaybe<Subscription_Statuses_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Subscription_TasksArgs = {
  objects: Array<Subscription_Tasks_Insert_Input>;
  on_conflict?: InputMaybe<Subscription_Tasks_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Subscription_Tasks_OneArgs = {
  object: Subscription_Tasks_Insert_Input;
  on_conflict?: InputMaybe<Subscription_Tasks_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_SubscriptionsArgs = {
  objects: Array<Subscriptions_Insert_Input>;
  on_conflict?: InputMaybe<Subscriptions_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Subscriptions_OneArgs = {
  object: Subscriptions_Insert_Input;
  on_conflict?: InputMaybe<Subscriptions_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Tariff_HistoriesArgs = {
  objects: Array<Tariff_Histories_Insert_Input>;
  on_conflict?: InputMaybe<Tariff_Histories_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Tariff_Histories_OneArgs = {
  object: Tariff_Histories_Insert_Input;
  on_conflict?: InputMaybe<Tariff_Histories_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_TariffsArgs = {
  objects: Array<Tariffs_Insert_Input>;
  on_conflict?: InputMaybe<Tariffs_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Tariffs_OneArgs = {
  object: Tariffs_Insert_Input;
  on_conflict?: InputMaybe<Tariffs_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Task_AssignmentsArgs = {
  objects: Array<Task_Assignments_Insert_Input>;
  on_conflict?: InputMaybe<Task_Assignments_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Task_Assignments_OneArgs = {
  object: Task_Assignments_Insert_Input;
  on_conflict?: InputMaybe<Task_Assignments_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Task_DocumentsArgs = {
  objects: Array<Task_Documents_Insert_Input>;
  on_conflict?: InputMaybe<Task_Documents_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Task_Documents_OneArgs = {
  object: Task_Documents_Insert_Input;
  on_conflict?: InputMaybe<Task_Documents_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Task_FlagsArgs = {
  objects: Array<Task_Flags_Insert_Input>;
  on_conflict?: InputMaybe<Task_Flags_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Task_Flags_OneArgs = {
  object: Task_Flags_Insert_Input;
  on_conflict?: InputMaybe<Task_Flags_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Task_InteractionsArgs = {
  objects: Array<Task_Interactions_Insert_Input>;
  on_conflict?: InputMaybe<Task_Interactions_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Task_Interactions_OneArgs = {
  object: Task_Interactions_Insert_Input;
  on_conflict?: InputMaybe<Task_Interactions_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Task_MentionsArgs = {
  objects: Array<Task_Mentions_Insert_Input>;
  on_conflict?: InputMaybe<Task_Mentions_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Task_Mentions_OneArgs = {
  object: Task_Mentions_Insert_Input;
  on_conflict?: InputMaybe<Task_Mentions_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Task_RemindersArgs = {
  objects: Array<Task_Reminders_Insert_Input>;
  on_conflict?: InputMaybe<Task_Reminders_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Task_Reminders_OneArgs = {
  object: Task_Reminders_Insert_Input;
  on_conflict?: InputMaybe<Task_Reminders_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Task_RulesArgs = {
  objects: Array<Task_Rules_Insert_Input>;
  on_conflict?: InputMaybe<Task_Rules_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Task_Rules_OneArgs = {
  object: Task_Rules_Insert_Input;
  on_conflict?: InputMaybe<Task_Rules_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Task_StatusesArgs = {
  objects: Array<Task_Statuses_Insert_Input>;
  on_conflict?: InputMaybe<Task_Statuses_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Task_Statuses_OneArgs = {
  object: Task_Statuses_Insert_Input;
  on_conflict?: InputMaybe<Task_Statuses_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_TasksArgs = {
  objects: Array<Tasks_Insert_Input>;
  on_conflict?: InputMaybe<Tasks_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Tasks_OneArgs = {
  object: Tasks_Insert_Input;
  on_conflict?: InputMaybe<Tasks_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_TerritoriesArgs = {
  objects: Array<Territories_Insert_Input>;
  on_conflict?: InputMaybe<Territories_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Territories_OneArgs = {
  object: Territories_Insert_Input;
  on_conflict?: InputMaybe<Territories_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Territory_AssignmentsArgs = {
  objects: Array<Territory_Assignments_Insert_Input>;
  on_conflict?: InputMaybe<Territory_Assignments_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Territory_Assignments_OneArgs = {
  object: Territory_Assignments_Insert_Input;
  on_conflict?: InputMaybe<Territory_Assignments_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Territory_FlagsArgs = {
  objects: Array<Territory_Flags_Insert_Input>;
  on_conflict?: InputMaybe<Territory_Flags_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Territory_Flags_OneArgs = {
  object: Territory_Flags_Insert_Input;
  on_conflict?: InputMaybe<Territory_Flags_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Territory_InteractionsArgs = {
  objects: Array<Territory_Interactions_Insert_Input>;
  on_conflict?: InputMaybe<Territory_Interactions_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Territory_Interactions_OneArgs = {
  object: Territory_Interactions_Insert_Input;
  on_conflict?: InputMaybe<Territory_Interactions_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Territory_MembersArgs = {
  objects: Array<Territory_Members_Insert_Input>;
  on_conflict?: InputMaybe<Territory_Members_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Territory_Members_OneArgs = {
  object: Territory_Members_Insert_Input;
  on_conflict?: InputMaybe<Territory_Members_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Territory_RulesArgs = {
  objects: Array<Territory_Rules_Insert_Input>;
  on_conflict?: InputMaybe<Territory_Rules_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Territory_Rules_OneArgs = {
  object: Territory_Rules_Insert_Input;
  on_conflict?: InputMaybe<Territory_Rules_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Territory_StatusesArgs = {
  objects: Array<Territory_Statuses_Insert_Input>;
  on_conflict?: InputMaybe<Territory_Statuses_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Territory_Statuses_OneArgs = {
  object: Territory_Statuses_Insert_Input;
  on_conflict?: InputMaybe<Territory_Statuses_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_TypesArgs = {
  objects: Array<Types_Insert_Input>;
  on_conflict?: InputMaybe<Types_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Types_OneArgs = {
  object: Types_Insert_Input;
  on_conflict?: InputMaybe<Types_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_User_FlagsArgs = {
  objects: Array<User_Flags_Insert_Input>;
  on_conflict?: InputMaybe<User_Flags_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_User_Flags_OneArgs = {
  object: User_Flags_Insert_Input;
  on_conflict?: InputMaybe<User_Flags_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_User_Mfa_FactorsArgs = {
  objects: Array<User_Mfa_Factors_Insert_Input>;
  on_conflict?: InputMaybe<User_Mfa_Factors_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_User_Mfa_Factors_OneArgs = {
  object: User_Mfa_Factors_Insert_Input;
  on_conflict?: InputMaybe<User_Mfa_Factors_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_User_StatusesArgs = {
  objects: Array<User_Statuses_Insert_Input>;
  on_conflict?: InputMaybe<User_Statuses_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_User_Statuses_OneArgs = {
  object: User_Statuses_Insert_Input;
  on_conflict?: InputMaybe<User_Statuses_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_UsersArgs = {
  objects: Array<Users_Insert_Input>;
  on_conflict?: InputMaybe<Users_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Users_OneArgs = {
  object: Users_Insert_Input;
  on_conflict?: InputMaybe<Users_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_WebhooksArgs = {
  objects: Array<Webhooks_Insert_Input>;
  on_conflict?: InputMaybe<Webhooks_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Webhooks_OneArgs = {
  object: Webhooks_Insert_Input;
  on_conflict?: InputMaybe<Webhooks_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Workflow_TemplatesArgs = {
  objects: Array<Workflow_Templates_Insert_Input>;
  on_conflict?: InputMaybe<Workflow_Templates_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Workflow_Templates_OneArgs = {
  object: Workflow_Templates_Insert_Input;
  on_conflict?: InputMaybe<Workflow_Templates_On_Conflict>;
};

/** mutation root */
export type Mutation_RootLoginArgs = {
  params: LoginInput;
};

/** mutation root */
export type Mutation_RootMagicLinkLoginArgs = {
  params: MagicLinkLoginInput;
};

/** mutation root */
export type Mutation_RootMakeInvoicePaymentArgs = {
  params: MakeInvoicePaymentInput;
};

/** mutation root */
export type Mutation_RootMakeSubscriptionPaymentArgs = {
  params: MakeSubscriptionPaymentInput;
};

/** mutation root */
export type Mutation_RootMarkMessageAsReadArgs = {
  params: MarkMessageAsReadInput;
};

/** mutation root */
export type Mutation_RootProcessWorkflowSubmissionArgs = {
  params: ProcessWorkflowSubmissionInput;
};

/** mutation root */
export type Mutation_RootRecord_PaymentArgs = {
  input: RecordPaymentInput;
};

/** mutation root */
export type Mutation_RootRecord_TransactionArgs = {
  input: Array<RecordTransactionInput>;
};

/** mutation root */
export type Mutation_RootRejectTaskApprovalArgs = {
  params: RejectTaskApprovalInput;
};

/** mutation root */
export type Mutation_RootRemoveInvoiceItemsArgs = {
  params: RemoveInvoiceItemsInput;
};

/** mutation root */
export type Mutation_RootRequestTaskApprovalArgs = {
  params: TaskApprovalInput;
};

/** mutation root */
export type Mutation_RootRescheduleInvoiceArgs = {
  params: RescheduleInvoiceInput;
};

/** mutation root */
export type Mutation_RootResetPasswordArgs = {
  params: ResetPasswordInput;
};

/** mutation root */
export type Mutation_RootRetrySendingMessagesArgs = {
  params: RetrySendingMessagesInput;
};

/** mutation root */
export type Mutation_RootScheduleMessageArgs = {
  params: ScheduleMessageInput;
};

/** mutation root */
export type Mutation_RootSendAdHocMessageArgs = {
  params: SendAdHocMessageInput;
};

/** mutation root */
export type Mutation_RootSendMessageArgs = {
  params: SendMessageInput;
};

/** mutation root */
export type Mutation_RootSignupArgs = {
  params: SignupInput;
};

/** mutation root */
export type Mutation_RootSubscribeToChannelArgs = {
  params: SubscribeToChannelInput;
};

/** mutation root */
export type Mutation_RootToggleSubscriptionDeliveryMethodStatusArgs = {
  params: ToggleSubscriptionDeliveryMethodStatusInput;
};

/** mutation root */
export type Mutation_RootTransfer_FundsArgs = {
  input: TransferFundsInput;
};

/** mutation root */
export type Mutation_RootUpdateInvoiceArgs = {
  params: UpdateInvoiceInput;
};

/** mutation root */
export type Mutation_RootUpdateSubscriptionDeliveryMethodAddressArgs = {
  params: UpdateSubscriptionDeliveryMethodAddressInput;
};

/** mutation root */
export type Mutation_RootUpdate_Activity_LogsArgs = {
  _append?: InputMaybe<Activity_Logs_Append_Input>;
  _delete_at_path?: InputMaybe<Activity_Logs_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Activity_Logs_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Activity_Logs_Delete_Key_Input>;
  _prepend?: InputMaybe<Activity_Logs_Prepend_Input>;
  _set?: InputMaybe<Activity_Logs_Set_Input>;
  where: Activity_Logs_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Activity_Logs_By_PkArgs = {
  _append?: InputMaybe<Activity_Logs_Append_Input>;
  _delete_at_path?: InputMaybe<Activity_Logs_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Activity_Logs_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Activity_Logs_Delete_Key_Input>;
  _prepend?: InputMaybe<Activity_Logs_Prepend_Input>;
  _set?: InputMaybe<Activity_Logs_Set_Input>;
  pk_columns: Activity_Logs_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Activity_Logs_ManyArgs = {
  updates: Array<Activity_Logs_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Audit_LogsArgs = {
  _append?: InputMaybe<Audit_Logs_Append_Input>;
  _delete_at_path?: InputMaybe<Audit_Logs_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Audit_Logs_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Audit_Logs_Delete_Key_Input>;
  _prepend?: InputMaybe<Audit_Logs_Prepend_Input>;
  _set?: InputMaybe<Audit_Logs_Set_Input>;
  where: Audit_Logs_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Audit_Logs_By_PkArgs = {
  _append?: InputMaybe<Audit_Logs_Append_Input>;
  _delete_at_path?: InputMaybe<Audit_Logs_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Audit_Logs_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Audit_Logs_Delete_Key_Input>;
  _prepend?: InputMaybe<Audit_Logs_Prepend_Input>;
  _set?: InputMaybe<Audit_Logs_Set_Input>;
  pk_columns: Audit_Logs_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Audit_Logs_ManyArgs = {
  updates: Array<Audit_Logs_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Campaign_ContactsArgs = {
  _set?: InputMaybe<Campaign_Contacts_Set_Input>;
  where: Campaign_Contacts_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Campaign_Contacts_By_PkArgs = {
  _set?: InputMaybe<Campaign_Contacts_Set_Input>;
  pk_columns: Campaign_Contacts_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Campaign_Contacts_ManyArgs = {
  updates: Array<Campaign_Contacts_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Campaign_Performance_IndicatorsArgs = {
  _set?: InputMaybe<Campaign_Performance_Indicators_Set_Input>;
  where: Campaign_Performance_Indicators_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Campaign_Performance_Indicators_By_PkArgs = {
  _set?: InputMaybe<Campaign_Performance_Indicators_Set_Input>;
  pk_columns: Campaign_Performance_Indicators_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Campaign_Performance_Indicators_ManyArgs = {
  updates: Array<Campaign_Performance_Indicators_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Campaign_RewardsArgs = {
  _set?: InputMaybe<Campaign_Rewards_Set_Input>;
  where: Campaign_Rewards_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Campaign_Rewards_By_PkArgs = {
  _set?: InputMaybe<Campaign_Rewards_Set_Input>;
  pk_columns: Campaign_Rewards_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Campaign_Rewards_ManyArgs = {
  updates: Array<Campaign_Rewards_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Campaign_RulesArgs = {
  _set?: InputMaybe<Campaign_Rules_Set_Input>;
  where: Campaign_Rules_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Campaign_Rules_By_PkArgs = {
  _set?: InputMaybe<Campaign_Rules_Set_Input>;
  pk_columns: Campaign_Rules_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Campaign_Rules_ManyArgs = {
  updates: Array<Campaign_Rules_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Campaign_TasksArgs = {
  _set?: InputMaybe<Campaign_Tasks_Set_Input>;
  where: Campaign_Tasks_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Campaign_Tasks_By_PkArgs = {
  _set?: InputMaybe<Campaign_Tasks_Set_Input>;
  pk_columns: Campaign_Tasks_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Campaign_Tasks_ManyArgs = {
  updates: Array<Campaign_Tasks_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Campaign_WorkflowsArgs = {
  _set?: InputMaybe<Campaign_Workflows_Set_Input>;
  where: Campaign_Workflows_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Campaign_Workflows_By_PkArgs = {
  _set?: InputMaybe<Campaign_Workflows_Set_Input>;
  pk_columns: Campaign_Workflows_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Campaign_Workflows_ManyArgs = {
  updates: Array<Campaign_Workflows_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_CampaignsArgs = {
  _set?: InputMaybe<Campaigns_Set_Input>;
  where: Campaigns_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Campaigns_By_PkArgs = {
  _set?: InputMaybe<Campaigns_Set_Input>;
  pk_columns: Campaigns_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Campaigns_ManyArgs = {
  updates: Array<Campaigns_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Case_AssignmentsArgs = {
  _set?: InputMaybe<Case_Assignments_Set_Input>;
  where: Case_Assignments_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Case_Assignments_By_PkArgs = {
  _set?: InputMaybe<Case_Assignments_Set_Input>;
  pk_columns: Case_Assignments_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Case_Assignments_ManyArgs = {
  updates: Array<Case_Assignments_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Case_DetailsArgs = {
  _set?: InputMaybe<Case_Details_Set_Input>;
  where: Case_Details_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Case_Details_By_PkArgs = {
  _set?: InputMaybe<Case_Details_Set_Input>;
  pk_columns: Case_Details_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Case_Details_ManyArgs = {
  updates: Array<Case_Details_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Case_DocumentsArgs = {
  _set?: InputMaybe<Case_Documents_Set_Input>;
  where: Case_Documents_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Case_Documents_By_PkArgs = {
  _set?: InputMaybe<Case_Documents_Set_Input>;
  pk_columns: Case_Documents_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Case_Documents_ManyArgs = {
  updates: Array<Case_Documents_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Case_FlagsArgs = {
  _set?: InputMaybe<Case_Flags_Set_Input>;
  where: Case_Flags_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Case_Flags_By_PkArgs = {
  _set?: InputMaybe<Case_Flags_Set_Input>;
  pk_columns: Case_Flags_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Case_Flags_ManyArgs = {
  updates: Array<Case_Flags_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Case_InteractionsArgs = {
  _set?: InputMaybe<Case_Interactions_Set_Input>;
  where: Case_Interactions_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Case_Interactions_By_PkArgs = {
  _set?: InputMaybe<Case_Interactions_Set_Input>;
  pk_columns: Case_Interactions_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Case_Interactions_ManyArgs = {
  updates: Array<Case_Interactions_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Case_MentionsArgs = {
  _set?: InputMaybe<Case_Mentions_Set_Input>;
  where: Case_Mentions_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Case_Mentions_By_PkArgs = {
  _set?: InputMaybe<Case_Mentions_Set_Input>;
  pk_columns: Case_Mentions_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Case_Mentions_ManyArgs = {
  updates: Array<Case_Mentions_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Case_StatusesArgs = {
  _set?: InputMaybe<Case_Statuses_Set_Input>;
  where: Case_Statuses_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Case_Statuses_By_PkArgs = {
  _set?: InputMaybe<Case_Statuses_Set_Input>;
  pk_columns: Case_Statuses_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Case_Statuses_ManyArgs = {
  updates: Array<Case_Statuses_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_CasesArgs = {
  _set?: InputMaybe<Cases_Set_Input>;
  where: Cases_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Cases_By_PkArgs = {
  _set?: InputMaybe<Cases_Set_Input>;
  pk_columns: Cases_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Cases_ManyArgs = {
  updates: Array<Cases_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Channel_FlagsArgs = {
  _set?: InputMaybe<Channel_Flags_Set_Input>;
  where: Channel_Flags_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Channel_Flags_By_PkArgs = {
  _set?: InputMaybe<Channel_Flags_Set_Input>;
  pk_columns: Channel_Flags_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Channel_Flags_ManyArgs = {
  updates: Array<Channel_Flags_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Channel_PartiesArgs = {
  _set?: InputMaybe<Channel_Parties_Set_Input>;
  where: Channel_Parties_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Channel_Parties_By_PkArgs = {
  _set?: InputMaybe<Channel_Parties_Set_Input>;
  pk_columns: Channel_Parties_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Channel_Parties_ManyArgs = {
  updates: Array<Channel_Parties_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Channel_StatusesArgs = {
  _set?: InputMaybe<Channel_Statuses_Set_Input>;
  where: Channel_Statuses_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Channel_Statuses_By_PkArgs = {
  _set?: InputMaybe<Channel_Statuses_Set_Input>;
  pk_columns: Channel_Statuses_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Channel_Statuses_ManyArgs = {
  updates: Array<Channel_Statuses_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Channel_SubscriptionsArgs = {
  _append?: InputMaybe<Channel_Subscriptions_Append_Input>;
  _delete_at_path?: InputMaybe<Channel_Subscriptions_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Channel_Subscriptions_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Channel_Subscriptions_Delete_Key_Input>;
  _prepend?: InputMaybe<Channel_Subscriptions_Prepend_Input>;
  _set?: InputMaybe<Channel_Subscriptions_Set_Input>;
  where: Channel_Subscriptions_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Channel_Subscriptions_By_PkArgs = {
  _append?: InputMaybe<Channel_Subscriptions_Append_Input>;
  _delete_at_path?: InputMaybe<Channel_Subscriptions_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Channel_Subscriptions_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Channel_Subscriptions_Delete_Key_Input>;
  _prepend?: InputMaybe<Channel_Subscriptions_Prepend_Input>;
  _set?: InputMaybe<Channel_Subscriptions_Set_Input>;
  pk_columns: Channel_Subscriptions_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Channel_Subscriptions_ManyArgs = {
  updates: Array<Channel_Subscriptions_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_ChannelsArgs = {
  _set?: InputMaybe<Channels_Set_Input>;
  where: Channels_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Channels_By_PkArgs = {
  _set?: InputMaybe<Channels_Set_Input>;
  pk_columns: Channels_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Channels_ManyArgs = {
  updates: Array<Channels_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_ConditionsArgs = {
  _append?: InputMaybe<Conditions_Append_Input>;
  _delete_at_path?: InputMaybe<Conditions_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Conditions_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Conditions_Delete_Key_Input>;
  _inc?: InputMaybe<Conditions_Inc_Input>;
  _prepend?: InputMaybe<Conditions_Prepend_Input>;
  _set?: InputMaybe<Conditions_Set_Input>;
  where: Conditions_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Conditions_By_PkArgs = {
  _append?: InputMaybe<Conditions_Append_Input>;
  _delete_at_path?: InputMaybe<Conditions_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Conditions_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Conditions_Delete_Key_Input>;
  _inc?: InputMaybe<Conditions_Inc_Input>;
  _prepend?: InputMaybe<Conditions_Prepend_Input>;
  _set?: InputMaybe<Conditions_Set_Input>;
  pk_columns: Conditions_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Conditions_ManyArgs = {
  updates: Array<Conditions_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Contact_DetailsArgs = {
  _set?: InputMaybe<Contact_Details_Set_Input>;
  where: Contact_Details_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Contact_Details_By_PkArgs = {
  _set?: InputMaybe<Contact_Details_Set_Input>;
  pk_columns: Contact_Details_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Contact_Details_ManyArgs = {
  updates: Array<Contact_Details_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Contact_InteractionsArgs = {
  _set?: InputMaybe<Contact_Interactions_Set_Input>;
  where: Contact_Interactions_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Contact_Interactions_By_PkArgs = {
  _set?: InputMaybe<Contact_Interactions_Set_Input>;
  pk_columns: Contact_Interactions_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Contact_Interactions_ManyArgs = {
  updates: Array<Contact_Interactions_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Contact_RelationshipsArgs = {
  _set?: InputMaybe<Contact_Relationships_Set_Input>;
  where: Contact_Relationships_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Contact_Relationships_By_PkArgs = {
  _set?: InputMaybe<Contact_Relationships_Set_Input>;
  pk_columns: Contact_Relationships_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Contact_Relationships_ManyArgs = {
  updates: Array<Contact_Relationships_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_ContactsArgs = {
  _set?: InputMaybe<Contacts_Set_Input>;
  where: Contacts_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Contacts_By_PkArgs = {
  _set?: InputMaybe<Contacts_Set_Input>;
  pk_columns: Contacts_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Contacts_ManyArgs = {
  updates: Array<Contacts_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Credit_Profile_FlagsArgs = {
  _set?: InputMaybe<Credit_Profile_Flags_Set_Input>;
  where: Credit_Profile_Flags_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Credit_Profile_Flags_By_PkArgs = {
  _set?: InputMaybe<Credit_Profile_Flags_Set_Input>;
  pk_columns: Credit_Profile_Flags_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Credit_Profile_Flags_ManyArgs = {
  updates: Array<Credit_Profile_Flags_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Credit_Profile_StatusesArgs = {
  _set?: InputMaybe<Credit_Profile_Statuses_Set_Input>;
  where: Credit_Profile_Statuses_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Credit_Profile_Statuses_By_PkArgs = {
  _set?: InputMaybe<Credit_Profile_Statuses_Set_Input>;
  pk_columns: Credit_Profile_Statuses_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Credit_Profile_Statuses_ManyArgs = {
  updates: Array<Credit_Profile_Statuses_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Credit_ProfilesArgs = {
  _inc?: InputMaybe<Credit_Profiles_Inc_Input>;
  _set?: InputMaybe<Credit_Profiles_Set_Input>;
  where: Credit_Profiles_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Credit_Profiles_By_PkArgs = {
  _inc?: InputMaybe<Credit_Profiles_Inc_Input>;
  _set?: InputMaybe<Credit_Profiles_Set_Input>;
  pk_columns: Credit_Profiles_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Credit_Profiles_ManyArgs = {
  updates: Array<Credit_Profiles_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Delivery_MethodsArgs = {
  _append?: InputMaybe<Delivery_Methods_Append_Input>;
  _delete_at_path?: InputMaybe<Delivery_Methods_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Delivery_Methods_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Delivery_Methods_Delete_Key_Input>;
  _prepend?: InputMaybe<Delivery_Methods_Prepend_Input>;
  _set?: InputMaybe<Delivery_Methods_Set_Input>;
  where: Delivery_Methods_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Delivery_Methods_By_PkArgs = {
  _append?: InputMaybe<Delivery_Methods_Append_Input>;
  _delete_at_path?: InputMaybe<Delivery_Methods_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Delivery_Methods_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Delivery_Methods_Delete_Key_Input>;
  _prepend?: InputMaybe<Delivery_Methods_Prepend_Input>;
  _set?: InputMaybe<Delivery_Methods_Set_Input>;
  pk_columns: Delivery_Methods_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Delivery_Methods_ManyArgs = {
  updates: Array<Delivery_Methods_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Document_InteractionsArgs = {
  _set?: InputMaybe<Document_Interactions_Set_Input>;
  where: Document_Interactions_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Document_Interactions_By_PkArgs = {
  _set?: InputMaybe<Document_Interactions_Set_Input>;
  pk_columns: Document_Interactions_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Document_Interactions_ManyArgs = {
  updates: Array<Document_Interactions_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_DocumentsArgs = {
  _inc?: InputMaybe<Documents_Inc_Input>;
  _set?: InputMaybe<Documents_Set_Input>;
  where: Documents_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Documents_By_PkArgs = {
  _inc?: InputMaybe<Documents_Inc_Input>;
  _set?: InputMaybe<Documents_Set_Input>;
  pk_columns: Documents_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Documents_ManyArgs = {
  updates: Array<Documents_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_FlagsArgs = {
  _set?: InputMaybe<Flags_Set_Input>;
  where: Flags_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Flags_By_PkArgs = {
  _set?: InputMaybe<Flags_Set_Input>;
  pk_columns: Flags_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Flags_ManyArgs = {
  updates: Array<Flags_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Form_FieldsArgs = {
  _append?: InputMaybe<Form_Fields_Append_Input>;
  _delete_at_path?: InputMaybe<Form_Fields_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Form_Fields_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Form_Fields_Delete_Key_Input>;
  _inc?: InputMaybe<Form_Fields_Inc_Input>;
  _prepend?: InputMaybe<Form_Fields_Prepend_Input>;
  _set?: InputMaybe<Form_Fields_Set_Input>;
  where: Form_Fields_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Form_Fields_By_PkArgs = {
  _append?: InputMaybe<Form_Fields_Append_Input>;
  _delete_at_path?: InputMaybe<Form_Fields_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Form_Fields_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Form_Fields_Delete_Key_Input>;
  _inc?: InputMaybe<Form_Fields_Inc_Input>;
  _prepend?: InputMaybe<Form_Fields_Prepend_Input>;
  _set?: InputMaybe<Form_Fields_Set_Input>;
  pk_columns: Form_Fields_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Form_Fields_ManyArgs = {
  updates: Array<Form_Fields_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Form_RulesArgs = {
  _set?: InputMaybe<Form_Rules_Set_Input>;
  where: Form_Rules_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Form_Rules_By_PkArgs = {
  _set?: InputMaybe<Form_Rules_Set_Input>;
  pk_columns: Form_Rules_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Form_Rules_ManyArgs = {
  updates: Array<Form_Rules_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_FormsArgs = {
  _append?: InputMaybe<Forms_Append_Input>;
  _delete_at_path?: InputMaybe<Forms_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Forms_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Forms_Delete_Key_Input>;
  _prepend?: InputMaybe<Forms_Prepend_Input>;
  _set?: InputMaybe<Forms_Set_Input>;
  where: Forms_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Forms_By_PkArgs = {
  _append?: InputMaybe<Forms_Append_Input>;
  _delete_at_path?: InputMaybe<Forms_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Forms_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Forms_Delete_Key_Input>;
  _prepend?: InputMaybe<Forms_Prepend_Input>;
  _set?: InputMaybe<Forms_Set_Input>;
  pk_columns: Forms_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Forms_ManyArgs = {
  updates: Array<Forms_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Interaction_DetailsArgs = {
  _set?: InputMaybe<Interaction_Details_Set_Input>;
  where: Interaction_Details_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Interaction_Details_By_PkArgs = {
  _set?: InputMaybe<Interaction_Details_Set_Input>;
  pk_columns: Interaction_Details_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Interaction_Details_ManyArgs = {
  updates: Array<Interaction_Details_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_InteractionsArgs = {
  _set?: InputMaybe<Interactions_Set_Input>;
  where: Interactions_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Interactions_By_PkArgs = {
  _set?: InputMaybe<Interactions_Set_Input>;
  pk_columns: Interactions_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Interactions_ManyArgs = {
  updates: Array<Interactions_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Invoice_InteractionsArgs = {
  _set?: InputMaybe<Invoice_Interactions_Set_Input>;
  where: Invoice_Interactions_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Invoice_Interactions_By_PkArgs = {
  _set?: InputMaybe<Invoice_Interactions_Set_Input>;
  pk_columns: Invoice_Interactions_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Invoice_Interactions_ManyArgs = {
  updates: Array<Invoice_Interactions_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Invoice_ItemsArgs = {
  _inc?: InputMaybe<Invoice_Items_Inc_Input>;
  _set?: InputMaybe<Invoice_Items_Set_Input>;
  where: Invoice_Items_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Invoice_Items_By_PkArgs = {
  _inc?: InputMaybe<Invoice_Items_Inc_Input>;
  _set?: InputMaybe<Invoice_Items_Set_Input>;
  pk_columns: Invoice_Items_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Invoice_Items_ManyArgs = {
  updates: Array<Invoice_Items_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_InvoicesArgs = {
  _append?: InputMaybe<Invoices_Append_Input>;
  _delete_at_path?: InputMaybe<Invoices_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Invoices_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Invoices_Delete_Key_Input>;
  _inc?: InputMaybe<Invoices_Inc_Input>;
  _prepend?: InputMaybe<Invoices_Prepend_Input>;
  _set?: InputMaybe<Invoices_Set_Input>;
  where: Invoices_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Invoices_By_PkArgs = {
  _append?: InputMaybe<Invoices_Append_Input>;
  _delete_at_path?: InputMaybe<Invoices_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Invoices_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Invoices_Delete_Key_Input>;
  _inc?: InputMaybe<Invoices_Inc_Input>;
  _prepend?: InputMaybe<Invoices_Prepend_Input>;
  _set?: InputMaybe<Invoices_Set_Input>;
  pk_columns: Invoices_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Invoices_ManyArgs = {
  updates: Array<Invoices_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_LedgerArgs = {
  input: UpdateLedgerInput;
};

/** mutation root */
export type Mutation_RootUpdate_Ledger_AccountArgs = {
  input: UpdateLedgerAccountInput;
};

/** mutation root */
export type Mutation_RootUpdate_Message_Delivery_MethodsArgs = {
  _set?: InputMaybe<Message_Delivery_Methods_Set_Input>;
  where: Message_Delivery_Methods_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Message_Delivery_Methods_By_PkArgs = {
  _set?: InputMaybe<Message_Delivery_Methods_Set_Input>;
  pk_columns: Message_Delivery_Methods_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Message_Delivery_Methods_ManyArgs = {
  updates: Array<Message_Delivery_Methods_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Message_Delivery_ResponsesArgs = {
  _append?: InputMaybe<Message_Delivery_Responses_Append_Input>;
  _delete_at_path?: InputMaybe<Message_Delivery_Responses_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Message_Delivery_Responses_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Message_Delivery_Responses_Delete_Key_Input>;
  _prepend?: InputMaybe<Message_Delivery_Responses_Prepend_Input>;
  _set?: InputMaybe<Message_Delivery_Responses_Set_Input>;
  where: Message_Delivery_Responses_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Message_Delivery_Responses_By_PkArgs = {
  _append?: InputMaybe<Message_Delivery_Responses_Append_Input>;
  _delete_at_path?: InputMaybe<Message_Delivery_Responses_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Message_Delivery_Responses_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Message_Delivery_Responses_Delete_Key_Input>;
  _prepend?: InputMaybe<Message_Delivery_Responses_Prepend_Input>;
  _set?: InputMaybe<Message_Delivery_Responses_Set_Input>;
  pk_columns: Message_Delivery_Responses_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Message_Delivery_Responses_ManyArgs = {
  updates: Array<Message_Delivery_Responses_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Message_FlagsArgs = {
  _set?: InputMaybe<Message_Flags_Set_Input>;
  where: Message_Flags_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Message_Flags_By_PkArgs = {
  _set?: InputMaybe<Message_Flags_Set_Input>;
  pk_columns: Message_Flags_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Message_Flags_ManyArgs = {
  updates: Array<Message_Flags_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Message_StatusesArgs = {
  _set?: InputMaybe<Message_Statuses_Set_Input>;
  where: Message_Statuses_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Message_Statuses_By_PkArgs = {
  _set?: InputMaybe<Message_Statuses_Set_Input>;
  pk_columns: Message_Statuses_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Message_Statuses_ManyArgs = {
  updates: Array<Message_Statuses_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_MessagesArgs = {
  _append?: InputMaybe<Messages_Append_Input>;
  _delete_at_path?: InputMaybe<Messages_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Messages_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Messages_Delete_Key_Input>;
  _prepend?: InputMaybe<Messages_Prepend_Input>;
  _set?: InputMaybe<Messages_Set_Input>;
  where: Messages_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Messages_By_PkArgs = {
  _append?: InputMaybe<Messages_Append_Input>;
  _delete_at_path?: InputMaybe<Messages_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Messages_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Messages_Delete_Key_Input>;
  _prepend?: InputMaybe<Messages_Prepend_Input>;
  _set?: InputMaybe<Messages_Set_Input>;
  pk_columns: Messages_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Messages_ManyArgs = {
  updates: Array<Messages_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Organisation_CredentialsArgs = {
  _set?: InputMaybe<Organisation_Credentials_Set_Input>;
  where: Organisation_Credentials_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Organisation_Credentials_By_PkArgs = {
  _set?: InputMaybe<Organisation_Credentials_Set_Input>;
  pk_columns: Organisation_Credentials_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Organisation_Credentials_ManyArgs = {
  updates: Array<Organisation_Credentials_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Organisation_FlagsArgs = {
  _set?: InputMaybe<Organisation_Flags_Set_Input>;
  where: Organisation_Flags_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Organisation_Flags_By_PkArgs = {
  _set?: InputMaybe<Organisation_Flags_Set_Input>;
  pk_columns: Organisation_Flags_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Organisation_Flags_ManyArgs = {
  updates: Array<Organisation_Flags_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Organisation_InvitesArgs = {
  _set?: InputMaybe<Organisation_Invites_Set_Input>;
  where: Organisation_Invites_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Organisation_Invites_By_PkArgs = {
  _set?: InputMaybe<Organisation_Invites_Set_Input>;
  pk_columns: Organisation_Invites_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Organisation_Invites_ManyArgs = {
  updates: Array<Organisation_Invites_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Organisation_RulesArgs = {
  _set?: InputMaybe<Organisation_Rules_Set_Input>;
  where: Organisation_Rules_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Organisation_Rules_By_PkArgs = {
  _set?: InputMaybe<Organisation_Rules_Set_Input>;
  pk_columns: Organisation_Rules_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Organisation_Rules_ManyArgs = {
  updates: Array<Organisation_Rules_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Organisation_SettingsArgs = {
  _set?: InputMaybe<Organisation_Settings_Set_Input>;
  where: Organisation_Settings_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Organisation_Settings_By_PkArgs = {
  _set?: InputMaybe<Organisation_Settings_Set_Input>;
  pk_columns: Organisation_Settings_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Organisation_Settings_ManyArgs = {
  updates: Array<Organisation_Settings_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Organisation_StatusesArgs = {
  _set?: InputMaybe<Organisation_Statuses_Set_Input>;
  where: Organisation_Statuses_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Organisation_Statuses_By_PkArgs = {
  _set?: InputMaybe<Organisation_Statuses_Set_Input>;
  pk_columns: Organisation_Statuses_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Organisation_Statuses_ManyArgs = {
  updates: Array<Organisation_Statuses_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_OrganisationsArgs = {
  _set?: InputMaybe<Organisations_Set_Input>;
  where: Organisations_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Organisations_By_PkArgs = {
  _set?: InputMaybe<Organisations_Set_Input>;
  pk_columns: Organisations_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Organisations_ManyArgs = {
  updates: Array<Organisations_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_PartiesArgs = {
  _append?: InputMaybe<Parties_Append_Input>;
  _delete_at_path?: InputMaybe<Parties_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Parties_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Parties_Delete_Key_Input>;
  _prepend?: InputMaybe<Parties_Prepend_Input>;
  _set?: InputMaybe<Parties_Set_Input>;
  where: Parties_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Parties_By_PkArgs = {
  _append?: InputMaybe<Parties_Append_Input>;
  _delete_at_path?: InputMaybe<Parties_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Parties_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Parties_Delete_Key_Input>;
  _prepend?: InputMaybe<Parties_Prepend_Input>;
  _set?: InputMaybe<Parties_Set_Input>;
  pk_columns: Parties_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Parties_ManyArgs = {
  updates: Array<Parties_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Party_ContactsArgs = {
  _set?: InputMaybe<Party_Contacts_Set_Input>;
  where: Party_Contacts_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Party_Contacts_By_PkArgs = {
  _set?: InputMaybe<Party_Contacts_Set_Input>;
  pk_columns: Party_Contacts_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Party_Contacts_ManyArgs = {
  updates: Array<Party_Contacts_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Party_DocumentsArgs = {
  _set?: InputMaybe<Party_Documents_Set_Input>;
  where: Party_Documents_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Party_Documents_By_PkArgs = {
  _set?: InputMaybe<Party_Documents_Set_Input>;
  pk_columns: Party_Documents_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Party_Documents_ManyArgs = {
  updates: Array<Party_Documents_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Party_FlagsArgs = {
  _set?: InputMaybe<Party_Flags_Set_Input>;
  where: Party_Flags_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Party_Flags_By_PkArgs = {
  _set?: InputMaybe<Party_Flags_Set_Input>;
  pk_columns: Party_Flags_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Party_Flags_ManyArgs = {
  updates: Array<Party_Flags_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Party_InteractionsArgs = {
  _set?: InputMaybe<Party_Interactions_Set_Input>;
  where: Party_Interactions_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Party_Interactions_By_PkArgs = {
  _set?: InputMaybe<Party_Interactions_Set_Input>;
  pk_columns: Party_Interactions_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Party_Interactions_ManyArgs = {
  updates: Array<Party_Interactions_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Party_Ledger_AccountsArgs = {
  _set?: InputMaybe<Party_Ledger_Accounts_Set_Input>;
  where: Party_Ledger_Accounts_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Party_Ledger_Accounts_By_PkArgs = {
  _set?: InputMaybe<Party_Ledger_Accounts_Set_Input>;
  pk_columns: Party_Ledger_Accounts_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Party_Ledger_Accounts_ManyArgs = {
  updates: Array<Party_Ledger_Accounts_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Party_Relationship_StatusesArgs = {
  _set?: InputMaybe<Party_Relationship_Statuses_Set_Input>;
  where: Party_Relationship_Statuses_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Party_Relationship_Statuses_By_PkArgs = {
  _set?: InputMaybe<Party_Relationship_Statuses_Set_Input>;
  pk_columns: Party_Relationship_Statuses_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Party_Relationship_Statuses_ManyArgs = {
  updates: Array<Party_Relationship_Statuses_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Party_RelationshipsArgs = {
  _set?: InputMaybe<Party_Relationships_Set_Input>;
  where: Party_Relationships_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Party_Relationships_By_PkArgs = {
  _set?: InputMaybe<Party_Relationships_Set_Input>;
  pk_columns: Party_Relationships_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Party_Relationships_ManyArgs = {
  updates: Array<Party_Relationships_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Party_SettingsArgs = {
  _set?: InputMaybe<Party_Settings_Set_Input>;
  where: Party_Settings_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Party_Settings_By_PkArgs = {
  _set?: InputMaybe<Party_Settings_Set_Input>;
  pk_columns: Party_Settings_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Party_Settings_ManyArgs = {
  updates: Array<Party_Settings_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Party_StatusesArgs = {
  _set?: InputMaybe<Party_Statuses_Set_Input>;
  where: Party_Statuses_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Party_Statuses_By_PkArgs = {
  _set?: InputMaybe<Party_Statuses_Set_Input>;
  pk_columns: Party_Statuses_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Party_Statuses_ManyArgs = {
  updates: Array<Party_Statuses_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_PaymentArgs = {
  input: UpdatePaymentInput;
};

/** mutation root */
export type Mutation_RootUpdate_Payment_IntentArgs = {
  input: UpdatePaymentIntentInput;
};

/** mutation root */
export type Mutation_RootUpdate_Performance_IndicatorsArgs = {
  _set?: InputMaybe<Performance_Indicators_Set_Input>;
  where: Performance_Indicators_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Performance_Indicators_By_PkArgs = {
  _set?: InputMaybe<Performance_Indicators_Set_Input>;
  pk_columns: Performance_Indicators_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Performance_Indicators_ManyArgs = {
  updates: Array<Performance_Indicators_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Performance_TargetsArgs = {
  _append?: InputMaybe<Performance_Targets_Append_Input>;
  _delete_at_path?: InputMaybe<Performance_Targets_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Performance_Targets_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Performance_Targets_Delete_Key_Input>;
  _inc?: InputMaybe<Performance_Targets_Inc_Input>;
  _prepend?: InputMaybe<Performance_Targets_Prepend_Input>;
  _set?: InputMaybe<Performance_Targets_Set_Input>;
  where: Performance_Targets_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Performance_Targets_By_PkArgs = {
  _append?: InputMaybe<Performance_Targets_Append_Input>;
  _delete_at_path?: InputMaybe<Performance_Targets_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Performance_Targets_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Performance_Targets_Delete_Key_Input>;
  _inc?: InputMaybe<Performance_Targets_Inc_Input>;
  _prepend?: InputMaybe<Performance_Targets_Prepend_Input>;
  _set?: InputMaybe<Performance_Targets_Set_Input>;
  pk_columns: Performance_Targets_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Performance_Targets_ManyArgs = {
  updates: Array<Performance_Targets_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Product_DocumentsArgs = {
  _set?: InputMaybe<Product_Documents_Set_Input>;
  where: Product_Documents_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Product_Documents_By_PkArgs = {
  _set?: InputMaybe<Product_Documents_Set_Input>;
  pk_columns: Product_Documents_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Product_Documents_ManyArgs = {
  updates: Array<Product_Documents_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Product_InteractionsArgs = {
  _set?: InputMaybe<Product_Interactions_Set_Input>;
  where: Product_Interactions_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Product_Interactions_By_PkArgs = {
  _set?: InputMaybe<Product_Interactions_Set_Input>;
  pk_columns: Product_Interactions_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Product_Interactions_ManyArgs = {
  updates: Array<Product_Interactions_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Product_ItemsArgs = {
  _append?: InputMaybe<Product_Items_Append_Input>;
  _delete_at_path?: InputMaybe<Product_Items_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Product_Items_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Product_Items_Delete_Key_Input>;
  _prepend?: InputMaybe<Product_Items_Prepend_Input>;
  _set?: InputMaybe<Product_Items_Set_Input>;
  where: Product_Items_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Product_Items_By_PkArgs = {
  _append?: InputMaybe<Product_Items_Append_Input>;
  _delete_at_path?: InputMaybe<Product_Items_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Product_Items_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Product_Items_Delete_Key_Input>;
  _prepend?: InputMaybe<Product_Items_Prepend_Input>;
  _set?: InputMaybe<Product_Items_Set_Input>;
  pk_columns: Product_Items_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Product_Items_ManyArgs = {
  updates: Array<Product_Items_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Product_RulesArgs = {
  _set?: InputMaybe<Product_Rules_Set_Input>;
  where: Product_Rules_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Product_Rules_By_PkArgs = {
  _set?: InputMaybe<Product_Rules_Set_Input>;
  pk_columns: Product_Rules_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Product_Rules_ManyArgs = {
  updates: Array<Product_Rules_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Product_SettingsArgs = {
  _set?: InputMaybe<Product_Settings_Set_Input>;
  where: Product_Settings_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Product_Settings_By_PkArgs = {
  _set?: InputMaybe<Product_Settings_Set_Input>;
  pk_columns: Product_Settings_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Product_Settings_ManyArgs = {
  updates: Array<Product_Settings_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Product_StatusesArgs = {
  _set?: InputMaybe<Product_Statuses_Set_Input>;
  where: Product_Statuses_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Product_Statuses_By_PkArgs = {
  _set?: InputMaybe<Product_Statuses_Set_Input>;
  pk_columns: Product_Statuses_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Product_Statuses_ManyArgs = {
  updates: Array<Product_Statuses_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Product_TemplatesArgs = {
  _append?: InputMaybe<Product_Templates_Append_Input>;
  _delete_at_path?: InputMaybe<Product_Templates_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Product_Templates_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Product_Templates_Delete_Key_Input>;
  _prepend?: InputMaybe<Product_Templates_Prepend_Input>;
  _set?: InputMaybe<Product_Templates_Set_Input>;
  where: Product_Templates_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Product_Templates_By_PkArgs = {
  _append?: InputMaybe<Product_Templates_Append_Input>;
  _delete_at_path?: InputMaybe<Product_Templates_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Product_Templates_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Product_Templates_Delete_Key_Input>;
  _prepend?: InputMaybe<Product_Templates_Prepend_Input>;
  _set?: InputMaybe<Product_Templates_Set_Input>;
  pk_columns: Product_Templates_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Product_Templates_ManyArgs = {
  updates: Array<Product_Templates_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_ProductsArgs = {
  _set?: InputMaybe<Products_Set_Input>;
  where: Products_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Products_By_PkArgs = {
  _set?: InputMaybe<Products_Set_Input>;
  pk_columns: Products_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Products_ManyArgs = {
  updates: Array<Products_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Reward_RedemptionsArgs = {
  _set?: InputMaybe<Reward_Redemptions_Set_Input>;
  where: Reward_Redemptions_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Reward_Redemptions_By_PkArgs = {
  _set?: InputMaybe<Reward_Redemptions_Set_Input>;
  pk_columns: Reward_Redemptions_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Reward_Redemptions_ManyArgs = {
  updates: Array<Reward_Redemptions_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_RewardsArgs = {
  _append?: InputMaybe<Rewards_Append_Input>;
  _delete_at_path?: InputMaybe<Rewards_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Rewards_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Rewards_Delete_Key_Input>;
  _prepend?: InputMaybe<Rewards_Prepend_Input>;
  _set?: InputMaybe<Rewards_Set_Input>;
  where: Rewards_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Rewards_By_PkArgs = {
  _append?: InputMaybe<Rewards_Append_Input>;
  _delete_at_path?: InputMaybe<Rewards_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Rewards_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Rewards_Delete_Key_Input>;
  _prepend?: InputMaybe<Rewards_Prepend_Input>;
  _set?: InputMaybe<Rewards_Set_Input>;
  pk_columns: Rewards_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Rewards_ManyArgs = {
  updates: Array<Rewards_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_RulesArgs = {
  _append?: InputMaybe<Rules_Append_Input>;
  _delete_at_path?: InputMaybe<Rules_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Rules_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Rules_Delete_Key_Input>;
  _prepend?: InputMaybe<Rules_Prepend_Input>;
  _set?: InputMaybe<Rules_Set_Input>;
  where: Rules_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Rules_By_PkArgs = {
  _append?: InputMaybe<Rules_Append_Input>;
  _delete_at_path?: InputMaybe<Rules_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Rules_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Rules_Delete_Key_Input>;
  _prepend?: InputMaybe<Rules_Prepend_Input>;
  _set?: InputMaybe<Rules_Set_Input>;
  pk_columns: Rules_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Rules_ManyArgs = {
  updates: Array<Rules_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_StatusesArgs = {
  _set?: InputMaybe<Statuses_Set_Input>;
  where: Statuses_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Statuses_By_PkArgs = {
  _set?: InputMaybe<Statuses_Set_Input>;
  pk_columns: Statuses_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Statuses_ManyArgs = {
  updates: Array<Statuses_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Subscription_CasesArgs = {
  _set?: InputMaybe<Subscription_Cases_Set_Input>;
  where: Subscription_Cases_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Subscription_Cases_By_PkArgs = {
  _set?: InputMaybe<Subscription_Cases_Set_Input>;
  pk_columns: Subscription_Cases_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Subscription_Cases_ManyArgs = {
  updates: Array<Subscription_Cases_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Subscription_DetailsArgs = {
  _set?: InputMaybe<Subscription_Details_Set_Input>;
  where: Subscription_Details_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Subscription_Details_By_PkArgs = {
  _set?: InputMaybe<Subscription_Details_Set_Input>;
  pk_columns: Subscription_Details_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Subscription_Details_ManyArgs = {
  updates: Array<Subscription_Details_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Subscription_DocumentsArgs = {
  _set?: InputMaybe<Subscription_Documents_Set_Input>;
  where: Subscription_Documents_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Subscription_Documents_By_PkArgs = {
  _set?: InputMaybe<Subscription_Documents_Set_Input>;
  pk_columns: Subscription_Documents_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Subscription_Documents_ManyArgs = {
  updates: Array<Subscription_Documents_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Subscription_FlagsArgs = {
  _set?: InputMaybe<Subscription_Flags_Set_Input>;
  where: Subscription_Flags_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Subscription_Flags_By_PkArgs = {
  _set?: InputMaybe<Subscription_Flags_Set_Input>;
  pk_columns: Subscription_Flags_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Subscription_Flags_ManyArgs = {
  updates: Array<Subscription_Flags_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Subscription_InteractionsArgs = {
  _set?: InputMaybe<Subscription_Interactions_Set_Input>;
  where: Subscription_Interactions_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Subscription_Interactions_By_PkArgs = {
  _set?: InputMaybe<Subscription_Interactions_Set_Input>;
  pk_columns: Subscription_Interactions_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Subscription_Interactions_ManyArgs = {
  updates: Array<Subscription_Interactions_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Subscription_ItemsArgs = {
  _inc?: InputMaybe<Subscription_Items_Inc_Input>;
  _set?: InputMaybe<Subscription_Items_Set_Input>;
  where: Subscription_Items_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Subscription_Items_By_PkArgs = {
  _inc?: InputMaybe<Subscription_Items_Inc_Input>;
  _set?: InputMaybe<Subscription_Items_Set_Input>;
  pk_columns: Subscription_Items_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Subscription_Items_ManyArgs = {
  updates: Array<Subscription_Items_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Subscription_Payment_TransactionsArgs = {
  _append?: InputMaybe<Subscription_Payment_Transactions_Append_Input>;
  _delete_at_path?: InputMaybe<Subscription_Payment_Transactions_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Subscription_Payment_Transactions_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Subscription_Payment_Transactions_Delete_Key_Input>;
  _inc?: InputMaybe<Subscription_Payment_Transactions_Inc_Input>;
  _prepend?: InputMaybe<Subscription_Payment_Transactions_Prepend_Input>;
  _set?: InputMaybe<Subscription_Payment_Transactions_Set_Input>;
  where: Subscription_Payment_Transactions_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Subscription_Payment_Transactions_By_PkArgs = {
  _append?: InputMaybe<Subscription_Payment_Transactions_Append_Input>;
  _delete_at_path?: InputMaybe<Subscription_Payment_Transactions_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Subscription_Payment_Transactions_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Subscription_Payment_Transactions_Delete_Key_Input>;
  _inc?: InputMaybe<Subscription_Payment_Transactions_Inc_Input>;
  _prepend?: InputMaybe<Subscription_Payment_Transactions_Prepend_Input>;
  _set?: InputMaybe<Subscription_Payment_Transactions_Set_Input>;
  pk_columns: Subscription_Payment_Transactions_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Subscription_Payment_Transactions_ManyArgs = {
  updates: Array<Subscription_Payment_Transactions_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Subscription_PaymentsArgs = {
  _append?: InputMaybe<Subscription_Payments_Append_Input>;
  _delete_at_path?: InputMaybe<Subscription_Payments_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Subscription_Payments_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Subscription_Payments_Delete_Key_Input>;
  _inc?: InputMaybe<Subscription_Payments_Inc_Input>;
  _prepend?: InputMaybe<Subscription_Payments_Prepend_Input>;
  _set?: InputMaybe<Subscription_Payments_Set_Input>;
  where: Subscription_Payments_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Subscription_Payments_By_PkArgs = {
  _append?: InputMaybe<Subscription_Payments_Append_Input>;
  _delete_at_path?: InputMaybe<Subscription_Payments_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Subscription_Payments_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Subscription_Payments_Delete_Key_Input>;
  _inc?: InputMaybe<Subscription_Payments_Inc_Input>;
  _prepend?: InputMaybe<Subscription_Payments_Prepend_Input>;
  _set?: InputMaybe<Subscription_Payments_Set_Input>;
  pk_columns: Subscription_Payments_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Subscription_Payments_ManyArgs = {
  updates: Array<Subscription_Payments_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Subscription_RulesArgs = {
  _set?: InputMaybe<Subscription_Rules_Set_Input>;
  where: Subscription_Rules_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Subscription_Rules_By_PkArgs = {
  _set?: InputMaybe<Subscription_Rules_Set_Input>;
  pk_columns: Subscription_Rules_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Subscription_Rules_ManyArgs = {
  updates: Array<Subscription_Rules_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Subscription_SchedulesArgs = {
  _append?: InputMaybe<Subscription_Schedules_Append_Input>;
  _delete_at_path?: InputMaybe<Subscription_Schedules_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Subscription_Schedules_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Subscription_Schedules_Delete_Key_Input>;
  _inc?: InputMaybe<Subscription_Schedules_Inc_Input>;
  _prepend?: InputMaybe<Subscription_Schedules_Prepend_Input>;
  _set?: InputMaybe<Subscription_Schedules_Set_Input>;
  where: Subscription_Schedules_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Subscription_Schedules_By_PkArgs = {
  _append?: InputMaybe<Subscription_Schedules_Append_Input>;
  _delete_at_path?: InputMaybe<Subscription_Schedules_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Subscription_Schedules_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Subscription_Schedules_Delete_Key_Input>;
  _inc?: InputMaybe<Subscription_Schedules_Inc_Input>;
  _prepend?: InputMaybe<Subscription_Schedules_Prepend_Input>;
  _set?: InputMaybe<Subscription_Schedules_Set_Input>;
  pk_columns: Subscription_Schedules_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Subscription_Schedules_ManyArgs = {
  updates: Array<Subscription_Schedules_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Subscription_StatusesArgs = {
  _append?: InputMaybe<Subscription_Statuses_Append_Input>;
  _delete_at_path?: InputMaybe<Subscription_Statuses_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Subscription_Statuses_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Subscription_Statuses_Delete_Key_Input>;
  _prepend?: InputMaybe<Subscription_Statuses_Prepend_Input>;
  _set?: InputMaybe<Subscription_Statuses_Set_Input>;
  where: Subscription_Statuses_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Subscription_Statuses_By_PkArgs = {
  _append?: InputMaybe<Subscription_Statuses_Append_Input>;
  _delete_at_path?: InputMaybe<Subscription_Statuses_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Subscription_Statuses_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Subscription_Statuses_Delete_Key_Input>;
  _prepend?: InputMaybe<Subscription_Statuses_Prepend_Input>;
  _set?: InputMaybe<Subscription_Statuses_Set_Input>;
  pk_columns: Subscription_Statuses_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Subscription_Statuses_ManyArgs = {
  updates: Array<Subscription_Statuses_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Subscription_TasksArgs = {
  _set?: InputMaybe<Subscription_Tasks_Set_Input>;
  where: Subscription_Tasks_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Subscription_Tasks_By_PkArgs = {
  _set?: InputMaybe<Subscription_Tasks_Set_Input>;
  pk_columns: Subscription_Tasks_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Subscription_Tasks_ManyArgs = {
  updates: Array<Subscription_Tasks_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_SubscriptionsArgs = {
  _append?: InputMaybe<Subscriptions_Append_Input>;
  _delete_at_path?: InputMaybe<Subscriptions_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Subscriptions_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Subscriptions_Delete_Key_Input>;
  _prepend?: InputMaybe<Subscriptions_Prepend_Input>;
  _set?: InputMaybe<Subscriptions_Set_Input>;
  where: Subscriptions_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Subscriptions_By_PkArgs = {
  _append?: InputMaybe<Subscriptions_Append_Input>;
  _delete_at_path?: InputMaybe<Subscriptions_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Subscriptions_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Subscriptions_Delete_Key_Input>;
  _prepend?: InputMaybe<Subscriptions_Prepend_Input>;
  _set?: InputMaybe<Subscriptions_Set_Input>;
  pk_columns: Subscriptions_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Subscriptions_ManyArgs = {
  updates: Array<Subscriptions_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Tariff_HistoriesArgs = {
  _append?: InputMaybe<Tariff_Histories_Append_Input>;
  _delete_at_path?: InputMaybe<Tariff_Histories_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Tariff_Histories_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Tariff_Histories_Delete_Key_Input>;
  _inc?: InputMaybe<Tariff_Histories_Inc_Input>;
  _prepend?: InputMaybe<Tariff_Histories_Prepend_Input>;
  _set?: InputMaybe<Tariff_Histories_Set_Input>;
  where: Tariff_Histories_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Tariff_Histories_By_PkArgs = {
  _append?: InputMaybe<Tariff_Histories_Append_Input>;
  _delete_at_path?: InputMaybe<Tariff_Histories_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Tariff_Histories_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Tariff_Histories_Delete_Key_Input>;
  _inc?: InputMaybe<Tariff_Histories_Inc_Input>;
  _prepend?: InputMaybe<Tariff_Histories_Prepend_Input>;
  _set?: InputMaybe<Tariff_Histories_Set_Input>;
  pk_columns: Tariff_Histories_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Tariff_Histories_ManyArgs = {
  updates: Array<Tariff_Histories_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_TariffsArgs = {
  _append?: InputMaybe<Tariffs_Append_Input>;
  _delete_at_path?: InputMaybe<Tariffs_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Tariffs_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Tariffs_Delete_Key_Input>;
  _inc?: InputMaybe<Tariffs_Inc_Input>;
  _prepend?: InputMaybe<Tariffs_Prepend_Input>;
  _set?: InputMaybe<Tariffs_Set_Input>;
  where: Tariffs_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Tariffs_By_PkArgs = {
  _append?: InputMaybe<Tariffs_Append_Input>;
  _delete_at_path?: InputMaybe<Tariffs_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Tariffs_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Tariffs_Delete_Key_Input>;
  _inc?: InputMaybe<Tariffs_Inc_Input>;
  _prepend?: InputMaybe<Tariffs_Prepend_Input>;
  _set?: InputMaybe<Tariffs_Set_Input>;
  pk_columns: Tariffs_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Tariffs_ManyArgs = {
  updates: Array<Tariffs_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Task_AssignmentsArgs = {
  _set?: InputMaybe<Task_Assignments_Set_Input>;
  where: Task_Assignments_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Task_Assignments_By_PkArgs = {
  _set?: InputMaybe<Task_Assignments_Set_Input>;
  pk_columns: Task_Assignments_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Task_Assignments_ManyArgs = {
  updates: Array<Task_Assignments_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Task_DocumentsArgs = {
  _set?: InputMaybe<Task_Documents_Set_Input>;
  where: Task_Documents_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Task_Documents_By_PkArgs = {
  _set?: InputMaybe<Task_Documents_Set_Input>;
  pk_columns: Task_Documents_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Task_Documents_ManyArgs = {
  updates: Array<Task_Documents_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Task_FlagsArgs = {
  _set?: InputMaybe<Task_Flags_Set_Input>;
  where: Task_Flags_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Task_Flags_By_PkArgs = {
  _set?: InputMaybe<Task_Flags_Set_Input>;
  pk_columns: Task_Flags_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Task_Flags_ManyArgs = {
  updates: Array<Task_Flags_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Task_InteractionsArgs = {
  _set?: InputMaybe<Task_Interactions_Set_Input>;
  where: Task_Interactions_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Task_Interactions_By_PkArgs = {
  _set?: InputMaybe<Task_Interactions_Set_Input>;
  pk_columns: Task_Interactions_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Task_Interactions_ManyArgs = {
  updates: Array<Task_Interactions_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Task_MentionsArgs = {
  _set?: InputMaybe<Task_Mentions_Set_Input>;
  where: Task_Mentions_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Task_Mentions_By_PkArgs = {
  _set?: InputMaybe<Task_Mentions_Set_Input>;
  pk_columns: Task_Mentions_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Task_Mentions_ManyArgs = {
  updates: Array<Task_Mentions_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Task_RemindersArgs = {
  _inc?: InputMaybe<Task_Reminders_Inc_Input>;
  _set?: InputMaybe<Task_Reminders_Set_Input>;
  where: Task_Reminders_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Task_Reminders_By_PkArgs = {
  _inc?: InputMaybe<Task_Reminders_Inc_Input>;
  _set?: InputMaybe<Task_Reminders_Set_Input>;
  pk_columns: Task_Reminders_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Task_Reminders_ManyArgs = {
  updates: Array<Task_Reminders_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Task_RulesArgs = {
  _set?: InputMaybe<Task_Rules_Set_Input>;
  where: Task_Rules_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Task_Rules_By_PkArgs = {
  _set?: InputMaybe<Task_Rules_Set_Input>;
  pk_columns: Task_Rules_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Task_Rules_ManyArgs = {
  updates: Array<Task_Rules_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Task_StatusesArgs = {
  _set?: InputMaybe<Task_Statuses_Set_Input>;
  where: Task_Statuses_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Task_Statuses_By_PkArgs = {
  _set?: InputMaybe<Task_Statuses_Set_Input>;
  pk_columns: Task_Statuses_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Task_Statuses_ManyArgs = {
  updates: Array<Task_Statuses_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_TasksArgs = {
  _set?: InputMaybe<Tasks_Set_Input>;
  where: Tasks_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Tasks_By_PkArgs = {
  _set?: InputMaybe<Tasks_Set_Input>;
  pk_columns: Tasks_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Tasks_ManyArgs = {
  updates: Array<Tasks_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_TerritoriesArgs = {
  _set?: InputMaybe<Territories_Set_Input>;
  where: Territories_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Territories_By_PkArgs = {
  _set?: InputMaybe<Territories_Set_Input>;
  pk_columns: Territories_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Territories_ManyArgs = {
  updates: Array<Territories_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Territory_AssignmentsArgs = {
  _set?: InputMaybe<Territory_Assignments_Set_Input>;
  where: Territory_Assignments_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Territory_Assignments_By_PkArgs = {
  _set?: InputMaybe<Territory_Assignments_Set_Input>;
  pk_columns: Territory_Assignments_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Territory_Assignments_ManyArgs = {
  updates: Array<Territory_Assignments_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Territory_FlagsArgs = {
  _set?: InputMaybe<Territory_Flags_Set_Input>;
  where: Territory_Flags_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Territory_Flags_By_PkArgs = {
  _set?: InputMaybe<Territory_Flags_Set_Input>;
  pk_columns: Territory_Flags_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Territory_Flags_ManyArgs = {
  updates: Array<Territory_Flags_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Territory_InteractionsArgs = {
  _set?: InputMaybe<Territory_Interactions_Set_Input>;
  where: Territory_Interactions_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Territory_Interactions_By_PkArgs = {
  _set?: InputMaybe<Territory_Interactions_Set_Input>;
  pk_columns: Territory_Interactions_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Territory_Interactions_ManyArgs = {
  updates: Array<Territory_Interactions_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Territory_MembersArgs = {
  _set?: InputMaybe<Territory_Members_Set_Input>;
  where: Territory_Members_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Territory_Members_By_PkArgs = {
  _set?: InputMaybe<Territory_Members_Set_Input>;
  pk_columns: Territory_Members_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Territory_Members_ManyArgs = {
  updates: Array<Territory_Members_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Territory_RulesArgs = {
  _set?: InputMaybe<Territory_Rules_Set_Input>;
  where: Territory_Rules_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Territory_Rules_By_PkArgs = {
  _set?: InputMaybe<Territory_Rules_Set_Input>;
  pk_columns: Territory_Rules_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Territory_Rules_ManyArgs = {
  updates: Array<Territory_Rules_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Territory_StatusesArgs = {
  _set?: InputMaybe<Territory_Statuses_Set_Input>;
  where: Territory_Statuses_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Territory_Statuses_By_PkArgs = {
  _set?: InputMaybe<Territory_Statuses_Set_Input>;
  pk_columns: Territory_Statuses_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Territory_Statuses_ManyArgs = {
  updates: Array<Territory_Statuses_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_TransactionArgs = {
  input?: InputMaybe<UpdateTransactionInput>;
};

/** mutation root */
export type Mutation_RootUpdate_TypesArgs = {
  _set?: InputMaybe<Types_Set_Input>;
  where: Types_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Types_By_PkArgs = {
  _set?: InputMaybe<Types_Set_Input>;
  pk_columns: Types_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Types_ManyArgs = {
  updates: Array<Types_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_User_FlagsArgs = {
  _set?: InputMaybe<User_Flags_Set_Input>;
  where: User_Flags_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_User_Flags_By_PkArgs = {
  _set?: InputMaybe<User_Flags_Set_Input>;
  pk_columns: User_Flags_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_User_Flags_ManyArgs = {
  updates: Array<User_Flags_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_User_Mfa_FactorsArgs = {
  _set?: InputMaybe<User_Mfa_Factors_Set_Input>;
  where: User_Mfa_Factors_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_User_Mfa_Factors_By_PkArgs = {
  _set?: InputMaybe<User_Mfa_Factors_Set_Input>;
  pk_columns: User_Mfa_Factors_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_User_Mfa_Factors_ManyArgs = {
  updates: Array<User_Mfa_Factors_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_User_StatusesArgs = {
  _set?: InputMaybe<User_Statuses_Set_Input>;
  where: User_Statuses_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_User_Statuses_By_PkArgs = {
  _set?: InputMaybe<User_Statuses_Set_Input>;
  pk_columns: User_Statuses_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_User_Statuses_ManyArgs = {
  updates: Array<User_Statuses_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_UsersArgs = {
  _set?: InputMaybe<Users_Set_Input>;
  where: Users_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Users_By_PkArgs = {
  _set?: InputMaybe<Users_Set_Input>;
  pk_columns: Users_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Users_ManyArgs = {
  updates: Array<Users_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_WebhooksArgs = {
  _append?: InputMaybe<Webhooks_Append_Input>;
  _delete_at_path?: InputMaybe<Webhooks_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Webhooks_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Webhooks_Delete_Key_Input>;
  _prepend?: InputMaybe<Webhooks_Prepend_Input>;
  _set?: InputMaybe<Webhooks_Set_Input>;
  where: Webhooks_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Webhooks_By_PkArgs = {
  _append?: InputMaybe<Webhooks_Append_Input>;
  _delete_at_path?: InputMaybe<Webhooks_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Webhooks_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Webhooks_Delete_Key_Input>;
  _prepend?: InputMaybe<Webhooks_Prepend_Input>;
  _set?: InputMaybe<Webhooks_Set_Input>;
  pk_columns: Webhooks_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Webhooks_ManyArgs = {
  updates: Array<Webhooks_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Workflow_TemplatesArgs = {
  _append?: InputMaybe<Workflow_Templates_Append_Input>;
  _delete_at_path?: InputMaybe<Workflow_Templates_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Workflow_Templates_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Workflow_Templates_Delete_Key_Input>;
  _prepend?: InputMaybe<Workflow_Templates_Prepend_Input>;
  _set?: InputMaybe<Workflow_Templates_Set_Input>;
  where: Workflow_Templates_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Workflow_Templates_By_PkArgs = {
  _append?: InputMaybe<Workflow_Templates_Append_Input>;
  _delete_at_path?: InputMaybe<Workflow_Templates_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Workflow_Templates_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Workflow_Templates_Delete_Key_Input>;
  _prepend?: InputMaybe<Workflow_Templates_Prepend_Input>;
  _set?: InputMaybe<Workflow_Templates_Set_Input>;
  pk_columns: Workflow_Templates_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Workflow_Templates_ManyArgs = {
  updates: Array<Workflow_Templates_Updates>;
};

export type New_Cases_Daily_Growth_Args = {
  start_date?: InputMaybe<Scalars["date"]>;
  stop_date?: InputMaybe<Scalars["date"]>;
};

/** Boolean expression to compare columns of type "numeric". All fields are combined with logical 'AND'. */
export type Numeric_Comparison_Exp = {
  _eq?: InputMaybe<Scalars["numeric"]>;
  _gt?: InputMaybe<Scalars["numeric"]>;
  _gte?: InputMaybe<Scalars["numeric"]>;
  _in?: InputMaybe<Array<Scalars["numeric"]>>;
  _is_null?: InputMaybe<Scalars["Boolean"]>;
  _lt?: InputMaybe<Scalars["numeric"]>;
  _lte?: InputMaybe<Scalars["numeric"]>;
  _neq?: InputMaybe<Scalars["numeric"]>;
  _nin?: InputMaybe<Array<Scalars["numeric"]>>;
};

/** column ordering options */
export enum Order_By {
  /** in ascending order, nulls last */
  Asc = "asc",
  /** in ascending order, nulls first */
  AscNullsFirst = "asc_nulls_first",
  /** in ascending order, nulls last */
  AscNullsLast = "asc_nulls_last",
  /** in descending order, nulls first */
  Desc = "desc",
  /** in descending order, nulls first */
  DescNullsFirst = "desc_nulls_first",
  /** in descending order, nulls last */
  DescNullsLast = "desc_nulls_last",
}

/** columns and relationships of "organisation_credentials" */
export type Organisation_Credentials = {
  __typename?: "organisation_credentials";
  created_at: Scalars["timestamptz"];
  id: Scalars["uuid"];
  is_active: Scalars["Boolean"];
  name: Scalars["String"];
  organisation_id: Scalars["uuid"];
  service: Scalars["String"];
  updated_at: Scalars["timestamptz"];
  value?: Maybe<Scalars["String"]>;
};

/** aggregated selection of "organisation_credentials" */
export type Organisation_Credentials_Aggregate = {
  __typename?: "organisation_credentials_aggregate";
  aggregate?: Maybe<Organisation_Credentials_Aggregate_Fields>;
  nodes: Array<Organisation_Credentials>;
};

/** aggregate fields of "organisation_credentials" */
export type Organisation_Credentials_Aggregate_Fields = {
  __typename?: "organisation_credentials_aggregate_fields";
  count: Scalars["Int"];
  max?: Maybe<Organisation_Credentials_Max_Fields>;
  min?: Maybe<Organisation_Credentials_Min_Fields>;
};

/** aggregate fields of "organisation_credentials" */
export type Organisation_Credentials_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Organisation_Credentials_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** Boolean expression to filter rows from the table "organisation_credentials". All fields are combined with a logical 'AND'. */
export type Organisation_Credentials_Bool_Exp = {
  _and?: InputMaybe<Array<Organisation_Credentials_Bool_Exp>>;
  _not?: InputMaybe<Organisation_Credentials_Bool_Exp>;
  _or?: InputMaybe<Array<Organisation_Credentials_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  is_active?: InputMaybe<Boolean_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  organisation_id?: InputMaybe<Uuid_Comparison_Exp>;
  service?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "organisation_credentials" */
export enum Organisation_Credentials_Constraint {
  /** unique or primary key constraint on columns "id" */
  OrganisationCredentialsPkey = "organisation_credentials_pkey",
  /** unique or primary key constraint on columns "is_active", "service", "name" */
  UniqueOrgCredentials = "unique_org_credentials",
}

/** input type for inserting data into table "organisation_credentials" */
export type Organisation_Credentials_Insert_Input = {
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  is_active?: InputMaybe<Scalars["Boolean"]>;
  name?: InputMaybe<Scalars["String"]>;
  organisation_id?: InputMaybe<Scalars["uuid"]>;
  service?: InputMaybe<Scalars["String"]>;
  updated_at?: InputMaybe<Scalars["timestamptz"]>;
  value?: InputMaybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Organisation_Credentials_Max_Fields = {
  __typename?: "organisation_credentials_max_fields";
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["uuid"]>;
  name?: Maybe<Scalars["String"]>;
  organisation_id?: Maybe<Scalars["uuid"]>;
  service?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
  value?: Maybe<Scalars["String"]>;
};

/** aggregate min on columns */
export type Organisation_Credentials_Min_Fields = {
  __typename?: "organisation_credentials_min_fields";
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["uuid"]>;
  name?: Maybe<Scalars["String"]>;
  organisation_id?: Maybe<Scalars["uuid"]>;
  service?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
  value?: Maybe<Scalars["String"]>;
};

/** response of any mutation on the table "organisation_credentials" */
export type Organisation_Credentials_Mutation_Response = {
  __typename?: "organisation_credentials_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Organisation_Credentials>;
};

/** on_conflict condition type for table "organisation_credentials" */
export type Organisation_Credentials_On_Conflict = {
  constraint: Organisation_Credentials_Constraint;
  update_columns?: Array<Organisation_Credentials_Update_Column>;
  where?: InputMaybe<Organisation_Credentials_Bool_Exp>;
};

/** Ordering options when selecting data from "organisation_credentials". */
export type Organisation_Credentials_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  is_active?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  organisation_id?: InputMaybe<Order_By>;
  service?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: organisation_credentials */
export type Organisation_Credentials_Pk_Columns_Input = {
  id: Scalars["uuid"];
};

/** select columns of table "organisation_credentials" */
export enum Organisation_Credentials_Select_Column {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  Id = "id",
  /** column name */
  IsActive = "is_active",
  /** column name */
  Name = "name",
  /** column name */
  OrganisationId = "organisation_id",
  /** column name */
  Service = "service",
  /** column name */
  UpdatedAt = "updated_at",
  /** column name */
  Value = "value",
}

/** input type for updating data in table "organisation_credentials" */
export type Organisation_Credentials_Set_Input = {
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  is_active?: InputMaybe<Scalars["Boolean"]>;
  name?: InputMaybe<Scalars["String"]>;
  organisation_id?: InputMaybe<Scalars["uuid"]>;
  service?: InputMaybe<Scalars["String"]>;
  updated_at?: InputMaybe<Scalars["timestamptz"]>;
  value?: InputMaybe<Scalars["String"]>;
};

/** Streaming cursor of the table "organisation_credentials" */
export type Organisation_Credentials_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Organisation_Credentials_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Organisation_Credentials_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  is_active?: InputMaybe<Scalars["Boolean"]>;
  name?: InputMaybe<Scalars["String"]>;
  organisation_id?: InputMaybe<Scalars["uuid"]>;
  service?: InputMaybe<Scalars["String"]>;
  updated_at?: InputMaybe<Scalars["timestamptz"]>;
  value?: InputMaybe<Scalars["String"]>;
};

/** update columns of table "organisation_credentials" */
export enum Organisation_Credentials_Update_Column {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  Id = "id",
  /** column name */
  IsActive = "is_active",
  /** column name */
  Name = "name",
  /** column name */
  OrganisationId = "organisation_id",
  /** column name */
  Service = "service",
  /** column name */
  UpdatedAt = "updated_at",
  /** column name */
  Value = "value",
}

export type Organisation_Credentials_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Organisation_Credentials_Set_Input>;
  /** filter the rows which have to be updated */
  where: Organisation_Credentials_Bool_Exp;
};

/** columns and relationships of "organisation_flags" */
export type Organisation_Flags = {
  __typename?: "organisation_flags";
  created_at?: Maybe<Scalars["timestamptz"]>;
  /** An object relationship */
  flag: Flags;
  flag_id: Scalars["uuid"];
  id: Scalars["uuid"];
  organisation_id: Scalars["uuid"];
};

/** aggregated selection of "organisation_flags" */
export type Organisation_Flags_Aggregate = {
  __typename?: "organisation_flags_aggregate";
  aggregate?: Maybe<Organisation_Flags_Aggregate_Fields>;
  nodes: Array<Organisation_Flags>;
};

/** aggregate fields of "organisation_flags" */
export type Organisation_Flags_Aggregate_Fields = {
  __typename?: "organisation_flags_aggregate_fields";
  count: Scalars["Int"];
  max?: Maybe<Organisation_Flags_Max_Fields>;
  min?: Maybe<Organisation_Flags_Min_Fields>;
};

/** aggregate fields of "organisation_flags" */
export type Organisation_Flags_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Organisation_Flags_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** Boolean expression to filter rows from the table "organisation_flags". All fields are combined with a logical 'AND'. */
export type Organisation_Flags_Bool_Exp = {
  _and?: InputMaybe<Array<Organisation_Flags_Bool_Exp>>;
  _not?: InputMaybe<Organisation_Flags_Bool_Exp>;
  _or?: InputMaybe<Array<Organisation_Flags_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  flag?: InputMaybe<Flags_Bool_Exp>;
  flag_id?: InputMaybe<Uuid_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  organisation_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "organisation_flags" */
export enum Organisation_Flags_Constraint {
  /** unique or primary key constraint on columns "id" */
  OrganisationFlagsPkey = "organisation_flags_pkey",
}

/** input type for inserting data into table "organisation_flags" */
export type Organisation_Flags_Insert_Input = {
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  flag?: InputMaybe<Flags_Obj_Rel_Insert_Input>;
  flag_id?: InputMaybe<Scalars["uuid"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  organisation_id?: InputMaybe<Scalars["uuid"]>;
};

/** aggregate max on columns */
export type Organisation_Flags_Max_Fields = {
  __typename?: "organisation_flags_max_fields";
  created_at?: Maybe<Scalars["timestamptz"]>;
  flag_id?: Maybe<Scalars["uuid"]>;
  id?: Maybe<Scalars["uuid"]>;
  organisation_id?: Maybe<Scalars["uuid"]>;
};

/** aggregate min on columns */
export type Organisation_Flags_Min_Fields = {
  __typename?: "organisation_flags_min_fields";
  created_at?: Maybe<Scalars["timestamptz"]>;
  flag_id?: Maybe<Scalars["uuid"]>;
  id?: Maybe<Scalars["uuid"]>;
  organisation_id?: Maybe<Scalars["uuid"]>;
};

/** response of any mutation on the table "organisation_flags" */
export type Organisation_Flags_Mutation_Response = {
  __typename?: "organisation_flags_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Organisation_Flags>;
};

/** on_conflict condition type for table "organisation_flags" */
export type Organisation_Flags_On_Conflict = {
  constraint: Organisation_Flags_Constraint;
  update_columns?: Array<Organisation_Flags_Update_Column>;
  where?: InputMaybe<Organisation_Flags_Bool_Exp>;
};

/** Ordering options when selecting data from "organisation_flags". */
export type Organisation_Flags_Order_By = {
  created_at?: InputMaybe<Order_By>;
  flag?: InputMaybe<Flags_Order_By>;
  flag_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  organisation_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: organisation_flags */
export type Organisation_Flags_Pk_Columns_Input = {
  id: Scalars["uuid"];
};

/** select columns of table "organisation_flags" */
export enum Organisation_Flags_Select_Column {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  FlagId = "flag_id",
  /** column name */
  Id = "id",
  /** column name */
  OrganisationId = "organisation_id",
}

/** input type for updating data in table "organisation_flags" */
export type Organisation_Flags_Set_Input = {
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  flag_id?: InputMaybe<Scalars["uuid"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  organisation_id?: InputMaybe<Scalars["uuid"]>;
};

/** Streaming cursor of the table "organisation_flags" */
export type Organisation_Flags_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Organisation_Flags_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Organisation_Flags_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  flag_id?: InputMaybe<Scalars["uuid"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  organisation_id?: InputMaybe<Scalars["uuid"]>;
};

/** update columns of table "organisation_flags" */
export enum Organisation_Flags_Update_Column {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  FlagId = "flag_id",
  /** column name */
  Id = "id",
  /** column name */
  OrganisationId = "organisation_id",
}

export type Organisation_Flags_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Organisation_Flags_Set_Input>;
  /** filter the rows which have to be updated */
  where: Organisation_Flags_Bool_Exp;
};

/** columns and relationships of "organisation_invites" */
export type Organisation_Invites = {
  __typename?: "organisation_invites";
  created_at: Scalars["timestamptz"];
  has_accepted: Scalars["Boolean"];
  id: Scalars["uuid"];
  invited_by: Scalars["uuid"];
  invitee_email: Scalars["String"];
  organisation_id: Scalars["uuid"];
  token?: Maybe<Scalars["String"]>;
  updated_at: Scalars["timestamptz"];
};

/** aggregated selection of "organisation_invites" */
export type Organisation_Invites_Aggregate = {
  __typename?: "organisation_invites_aggregate";
  aggregate?: Maybe<Organisation_Invites_Aggregate_Fields>;
  nodes: Array<Organisation_Invites>;
};

/** aggregate fields of "organisation_invites" */
export type Organisation_Invites_Aggregate_Fields = {
  __typename?: "organisation_invites_aggregate_fields";
  count: Scalars["Int"];
  max?: Maybe<Organisation_Invites_Max_Fields>;
  min?: Maybe<Organisation_Invites_Min_Fields>;
};

/** aggregate fields of "organisation_invites" */
export type Organisation_Invites_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Organisation_Invites_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** Boolean expression to filter rows from the table "organisation_invites". All fields are combined with a logical 'AND'. */
export type Organisation_Invites_Bool_Exp = {
  _and?: InputMaybe<Array<Organisation_Invites_Bool_Exp>>;
  _not?: InputMaybe<Organisation_Invites_Bool_Exp>;
  _or?: InputMaybe<Array<Organisation_Invites_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  has_accepted?: InputMaybe<Boolean_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  invited_by?: InputMaybe<Uuid_Comparison_Exp>;
  invitee_email?: InputMaybe<String_Comparison_Exp>;
  organisation_id?: InputMaybe<Uuid_Comparison_Exp>;
  token?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "organisation_invites" */
export enum Organisation_Invites_Constraint {
  /** unique or primary key constraint on columns "invitee_email" */
  OrganisationInvitesInviteeEmailUnique = "organisation_invites_invitee_email_unique",
  /** unique or primary key constraint on columns "id" */
  OrganisationInvitesPkey = "organisation_invites_pkey",
  /** unique or primary key constraint on columns "token" */
  OrganisationInvitesTokenUnique = "organisation_invites_token_unique",
}

/** input type for inserting data into table "organisation_invites" */
export type Organisation_Invites_Insert_Input = {
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  has_accepted?: InputMaybe<Scalars["Boolean"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  invited_by?: InputMaybe<Scalars["uuid"]>;
  invitee_email?: InputMaybe<Scalars["String"]>;
  organisation_id?: InputMaybe<Scalars["uuid"]>;
  token?: InputMaybe<Scalars["String"]>;
  updated_at?: InputMaybe<Scalars["timestamptz"]>;
};

/** aggregate max on columns */
export type Organisation_Invites_Max_Fields = {
  __typename?: "organisation_invites_max_fields";
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["uuid"]>;
  invited_by?: Maybe<Scalars["uuid"]>;
  invitee_email?: Maybe<Scalars["String"]>;
  organisation_id?: Maybe<Scalars["uuid"]>;
  token?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
};

/** aggregate min on columns */
export type Organisation_Invites_Min_Fields = {
  __typename?: "organisation_invites_min_fields";
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["uuid"]>;
  invited_by?: Maybe<Scalars["uuid"]>;
  invitee_email?: Maybe<Scalars["String"]>;
  organisation_id?: Maybe<Scalars["uuid"]>;
  token?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
};

/** response of any mutation on the table "organisation_invites" */
export type Organisation_Invites_Mutation_Response = {
  __typename?: "organisation_invites_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Organisation_Invites>;
};

/** on_conflict condition type for table "organisation_invites" */
export type Organisation_Invites_On_Conflict = {
  constraint: Organisation_Invites_Constraint;
  update_columns?: Array<Organisation_Invites_Update_Column>;
  where?: InputMaybe<Organisation_Invites_Bool_Exp>;
};

/** Ordering options when selecting data from "organisation_invites". */
export type Organisation_Invites_Order_By = {
  created_at?: InputMaybe<Order_By>;
  has_accepted?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  invited_by?: InputMaybe<Order_By>;
  invitee_email?: InputMaybe<Order_By>;
  organisation_id?: InputMaybe<Order_By>;
  token?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: organisation_invites */
export type Organisation_Invites_Pk_Columns_Input = {
  id: Scalars["uuid"];
};

/** select columns of table "organisation_invites" */
export enum Organisation_Invites_Select_Column {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  HasAccepted = "has_accepted",
  /** column name */
  Id = "id",
  /** column name */
  InvitedBy = "invited_by",
  /** column name */
  InviteeEmail = "invitee_email",
  /** column name */
  OrganisationId = "organisation_id",
  /** column name */
  Token = "token",
  /** column name */
  UpdatedAt = "updated_at",
}

/** input type for updating data in table "organisation_invites" */
export type Organisation_Invites_Set_Input = {
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  has_accepted?: InputMaybe<Scalars["Boolean"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  invited_by?: InputMaybe<Scalars["uuid"]>;
  invitee_email?: InputMaybe<Scalars["String"]>;
  organisation_id?: InputMaybe<Scalars["uuid"]>;
  token?: InputMaybe<Scalars["String"]>;
  updated_at?: InputMaybe<Scalars["timestamptz"]>;
};

/** Streaming cursor of the table "organisation_invites" */
export type Organisation_Invites_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Organisation_Invites_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Organisation_Invites_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  has_accepted?: InputMaybe<Scalars["Boolean"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  invited_by?: InputMaybe<Scalars["uuid"]>;
  invitee_email?: InputMaybe<Scalars["String"]>;
  organisation_id?: InputMaybe<Scalars["uuid"]>;
  token?: InputMaybe<Scalars["String"]>;
  updated_at?: InputMaybe<Scalars["timestamptz"]>;
};

/** update columns of table "organisation_invites" */
export enum Organisation_Invites_Update_Column {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  HasAccepted = "has_accepted",
  /** column name */
  Id = "id",
  /** column name */
  InvitedBy = "invited_by",
  /** column name */
  InviteeEmail = "invitee_email",
  /** column name */
  OrganisationId = "organisation_id",
  /** column name */
  Token = "token",
  /** column name */
  UpdatedAt = "updated_at",
}

export type Organisation_Invites_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Organisation_Invites_Set_Input>;
  /** filter the rows which have to be updated */
  where: Organisation_Invites_Bool_Exp;
};

/** columns and relationships of "organisation_rules" */
export type Organisation_Rules = {
  __typename?: "organisation_rules";
  created_at?: Maybe<Scalars["timestamp"]>;
  id: Scalars["uuid"];
  organisation_id: Scalars["uuid"];
  rule_id: Scalars["uuid"];
};

/** aggregated selection of "organisation_rules" */
export type Organisation_Rules_Aggregate = {
  __typename?: "organisation_rules_aggregate";
  aggregate?: Maybe<Organisation_Rules_Aggregate_Fields>;
  nodes: Array<Organisation_Rules>;
};

/** aggregate fields of "organisation_rules" */
export type Organisation_Rules_Aggregate_Fields = {
  __typename?: "organisation_rules_aggregate_fields";
  count: Scalars["Int"];
  max?: Maybe<Organisation_Rules_Max_Fields>;
  min?: Maybe<Organisation_Rules_Min_Fields>;
};

/** aggregate fields of "organisation_rules" */
export type Organisation_Rules_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Organisation_Rules_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** Boolean expression to filter rows from the table "organisation_rules". All fields are combined with a logical 'AND'. */
export type Organisation_Rules_Bool_Exp = {
  _and?: InputMaybe<Array<Organisation_Rules_Bool_Exp>>;
  _not?: InputMaybe<Organisation_Rules_Bool_Exp>;
  _or?: InputMaybe<Array<Organisation_Rules_Bool_Exp>>;
  created_at?: InputMaybe<Timestamp_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  organisation_id?: InputMaybe<Uuid_Comparison_Exp>;
  rule_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "organisation_rules" */
export enum Organisation_Rules_Constraint {
  /** unique or primary key constraint on columns "id" */
  OrganisationRulesPkey = "organisation_rules_pkey",
  /** unique or primary key constraint on columns "rule_id", "organisation_id" */
  UniqueOrgRules = "unique_org_rules",
}

/** input type for inserting data into table "organisation_rules" */
export type Organisation_Rules_Insert_Input = {
  created_at?: InputMaybe<Scalars["timestamp"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  organisation_id?: InputMaybe<Scalars["uuid"]>;
  rule_id?: InputMaybe<Scalars["uuid"]>;
};

/** aggregate max on columns */
export type Organisation_Rules_Max_Fields = {
  __typename?: "organisation_rules_max_fields";
  created_at?: Maybe<Scalars["timestamp"]>;
  id?: Maybe<Scalars["uuid"]>;
  organisation_id?: Maybe<Scalars["uuid"]>;
  rule_id?: Maybe<Scalars["uuid"]>;
};

/** aggregate min on columns */
export type Organisation_Rules_Min_Fields = {
  __typename?: "organisation_rules_min_fields";
  created_at?: Maybe<Scalars["timestamp"]>;
  id?: Maybe<Scalars["uuid"]>;
  organisation_id?: Maybe<Scalars["uuid"]>;
  rule_id?: Maybe<Scalars["uuid"]>;
};

/** response of any mutation on the table "organisation_rules" */
export type Organisation_Rules_Mutation_Response = {
  __typename?: "organisation_rules_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Organisation_Rules>;
};

/** on_conflict condition type for table "organisation_rules" */
export type Organisation_Rules_On_Conflict = {
  constraint: Organisation_Rules_Constraint;
  update_columns?: Array<Organisation_Rules_Update_Column>;
  where?: InputMaybe<Organisation_Rules_Bool_Exp>;
};

/** Ordering options when selecting data from "organisation_rules". */
export type Organisation_Rules_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  organisation_id?: InputMaybe<Order_By>;
  rule_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: organisation_rules */
export type Organisation_Rules_Pk_Columns_Input = {
  id: Scalars["uuid"];
};

/** select columns of table "organisation_rules" */
export enum Organisation_Rules_Select_Column {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  Id = "id",
  /** column name */
  OrganisationId = "organisation_id",
  /** column name */
  RuleId = "rule_id",
}

/** input type for updating data in table "organisation_rules" */
export type Organisation_Rules_Set_Input = {
  created_at?: InputMaybe<Scalars["timestamp"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  organisation_id?: InputMaybe<Scalars["uuid"]>;
  rule_id?: InputMaybe<Scalars["uuid"]>;
};

/** Streaming cursor of the table "organisation_rules" */
export type Organisation_Rules_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Organisation_Rules_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Organisation_Rules_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars["timestamp"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  organisation_id?: InputMaybe<Scalars["uuid"]>;
  rule_id?: InputMaybe<Scalars["uuid"]>;
};

/** update columns of table "organisation_rules" */
export enum Organisation_Rules_Update_Column {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  Id = "id",
  /** column name */
  OrganisationId = "organisation_id",
  /** column name */
  RuleId = "rule_id",
}

export type Organisation_Rules_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Organisation_Rules_Set_Input>;
  /** filter the rows which have to be updated */
  where: Organisation_Rules_Bool_Exp;
};

/** columns and relationships of "organisation_settings" */
export type Organisation_Settings = {
  __typename?: "organisation_settings";
  created_at: Scalars["timestamptz"];
  id: Scalars["uuid"];
  is_active: Scalars["Boolean"];
  name: Scalars["String"];
  organisation_id: Scalars["uuid"];
  updated_at: Scalars["timestamptz"];
  value?: Maybe<Scalars["String"]>;
};

/** aggregated selection of "organisation_settings" */
export type Organisation_Settings_Aggregate = {
  __typename?: "organisation_settings_aggregate";
  aggregate?: Maybe<Organisation_Settings_Aggregate_Fields>;
  nodes: Array<Organisation_Settings>;
};

export type Organisation_Settings_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Organisation_Settings_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Organisation_Settings_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Organisation_Settings_Aggregate_Bool_Exp_Count>;
};

export type Organisation_Settings_Aggregate_Bool_Exp_Bool_And = {
  arguments: Organisation_Settings_Select_Column_Organisation_Settings_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<Organisation_Settings_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Organisation_Settings_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Organisation_Settings_Select_Column_Organisation_Settings_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<Organisation_Settings_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Organisation_Settings_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Organisation_Settings_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<Organisation_Settings_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "organisation_settings" */
export type Organisation_Settings_Aggregate_Fields = {
  __typename?: "organisation_settings_aggregate_fields";
  count: Scalars["Int"];
  max?: Maybe<Organisation_Settings_Max_Fields>;
  min?: Maybe<Organisation_Settings_Min_Fields>;
};

/** aggregate fields of "organisation_settings" */
export type Organisation_Settings_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Organisation_Settings_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "organisation_settings" */
export type Organisation_Settings_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Organisation_Settings_Max_Order_By>;
  min?: InputMaybe<Organisation_Settings_Min_Order_By>;
};

/** input type for inserting array relation for remote table "organisation_settings" */
export type Organisation_Settings_Arr_Rel_Insert_Input = {
  data: Array<Organisation_Settings_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Organisation_Settings_On_Conflict>;
};

/** Boolean expression to filter rows from the table "organisation_settings". All fields are combined with a logical 'AND'. */
export type Organisation_Settings_Bool_Exp = {
  _and?: InputMaybe<Array<Organisation_Settings_Bool_Exp>>;
  _not?: InputMaybe<Organisation_Settings_Bool_Exp>;
  _or?: InputMaybe<Array<Organisation_Settings_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  is_active?: InputMaybe<Boolean_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  organisation_id?: InputMaybe<Uuid_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "organisation_settings" */
export enum Organisation_Settings_Constraint {
  /** unique or primary key constraint on columns "id" */
  OrganisationSettingsPkey = "organisation_settings_pkey",
  /** unique or primary key constraint on columns "organisation_id", "is_active", "name" */
  UniqueOrgSetting = "unique_org_setting",
}

/** input type for inserting data into table "organisation_settings" */
export type Organisation_Settings_Insert_Input = {
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  is_active?: InputMaybe<Scalars["Boolean"]>;
  name?: InputMaybe<Scalars["String"]>;
  organisation_id?: InputMaybe<Scalars["uuid"]>;
  updated_at?: InputMaybe<Scalars["timestamptz"]>;
  value?: InputMaybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Organisation_Settings_Max_Fields = {
  __typename?: "organisation_settings_max_fields";
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["uuid"]>;
  name?: Maybe<Scalars["String"]>;
  organisation_id?: Maybe<Scalars["uuid"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
  value?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "organisation_settings" */
export type Organisation_Settings_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  organisation_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Organisation_Settings_Min_Fields = {
  __typename?: "organisation_settings_min_fields";
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["uuid"]>;
  name?: Maybe<Scalars["String"]>;
  organisation_id?: Maybe<Scalars["uuid"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
  value?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "organisation_settings" */
export type Organisation_Settings_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  organisation_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "organisation_settings" */
export type Organisation_Settings_Mutation_Response = {
  __typename?: "organisation_settings_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Organisation_Settings>;
};

/** on_conflict condition type for table "organisation_settings" */
export type Organisation_Settings_On_Conflict = {
  constraint: Organisation_Settings_Constraint;
  update_columns?: Array<Organisation_Settings_Update_Column>;
  where?: InputMaybe<Organisation_Settings_Bool_Exp>;
};

/** Ordering options when selecting data from "organisation_settings". */
export type Organisation_Settings_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  is_active?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  organisation_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: organisation_settings */
export type Organisation_Settings_Pk_Columns_Input = {
  id: Scalars["uuid"];
};

/** select columns of table "organisation_settings" */
export enum Organisation_Settings_Select_Column {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  Id = "id",
  /** column name */
  IsActive = "is_active",
  /** column name */
  Name = "name",
  /** column name */
  OrganisationId = "organisation_id",
  /** column name */
  UpdatedAt = "updated_at",
  /** column name */
  Value = "value",
}

/** select "organisation_settings_aggregate_bool_exp_bool_and_arguments_columns" columns of table "organisation_settings" */
export enum Organisation_Settings_Select_Column_Organisation_Settings_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  IsActive = "is_active",
}

/** select "organisation_settings_aggregate_bool_exp_bool_or_arguments_columns" columns of table "organisation_settings" */
export enum Organisation_Settings_Select_Column_Organisation_Settings_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  IsActive = "is_active",
}

/** input type for updating data in table "organisation_settings" */
export type Organisation_Settings_Set_Input = {
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  is_active?: InputMaybe<Scalars["Boolean"]>;
  name?: InputMaybe<Scalars["String"]>;
  organisation_id?: InputMaybe<Scalars["uuid"]>;
  updated_at?: InputMaybe<Scalars["timestamptz"]>;
  value?: InputMaybe<Scalars["String"]>;
};

/** Streaming cursor of the table "organisation_settings" */
export type Organisation_Settings_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Organisation_Settings_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Organisation_Settings_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  is_active?: InputMaybe<Scalars["Boolean"]>;
  name?: InputMaybe<Scalars["String"]>;
  organisation_id?: InputMaybe<Scalars["uuid"]>;
  updated_at?: InputMaybe<Scalars["timestamptz"]>;
  value?: InputMaybe<Scalars["String"]>;
};

/** update columns of table "organisation_settings" */
export enum Organisation_Settings_Update_Column {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  Id = "id",
  /** column name */
  IsActive = "is_active",
  /** column name */
  Name = "name",
  /** column name */
  OrganisationId = "organisation_id",
  /** column name */
  UpdatedAt = "updated_at",
  /** column name */
  Value = "value",
}

export type Organisation_Settings_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Organisation_Settings_Set_Input>;
  /** filter the rows which have to be updated */
  where: Organisation_Settings_Bool_Exp;
};

/** columns and relationships of "organisation_statuses" */
export type Organisation_Statuses = {
  __typename?: "organisation_statuses";
  created_at?: Maybe<Scalars["timestamptz"]>;
  id: Scalars["uuid"];
  organisation_id: Scalars["uuid"];
  status_id: Scalars["uuid"];
};

/** aggregated selection of "organisation_statuses" */
export type Organisation_Statuses_Aggregate = {
  __typename?: "organisation_statuses_aggregate";
  aggregate?: Maybe<Organisation_Statuses_Aggregate_Fields>;
  nodes: Array<Organisation_Statuses>;
};

/** aggregate fields of "organisation_statuses" */
export type Organisation_Statuses_Aggregate_Fields = {
  __typename?: "organisation_statuses_aggregate_fields";
  count: Scalars["Int"];
  max?: Maybe<Organisation_Statuses_Max_Fields>;
  min?: Maybe<Organisation_Statuses_Min_Fields>;
};

/** aggregate fields of "organisation_statuses" */
export type Organisation_Statuses_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Organisation_Statuses_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** Boolean expression to filter rows from the table "organisation_statuses". All fields are combined with a logical 'AND'. */
export type Organisation_Statuses_Bool_Exp = {
  _and?: InputMaybe<Array<Organisation_Statuses_Bool_Exp>>;
  _not?: InputMaybe<Organisation_Statuses_Bool_Exp>;
  _or?: InputMaybe<Array<Organisation_Statuses_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  organisation_id?: InputMaybe<Uuid_Comparison_Exp>;
  status_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "organisation_statuses" */
export enum Organisation_Statuses_Constraint {
  /** unique or primary key constraint on columns "id" */
  OrganisationStatusesPkey = "organisation_statuses_pkey",
}

/** input type for inserting data into table "organisation_statuses" */
export type Organisation_Statuses_Insert_Input = {
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  organisation_id?: InputMaybe<Scalars["uuid"]>;
  status_id?: InputMaybe<Scalars["uuid"]>;
};

/** aggregate max on columns */
export type Organisation_Statuses_Max_Fields = {
  __typename?: "organisation_statuses_max_fields";
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["uuid"]>;
  organisation_id?: Maybe<Scalars["uuid"]>;
  status_id?: Maybe<Scalars["uuid"]>;
};

/** aggregate min on columns */
export type Organisation_Statuses_Min_Fields = {
  __typename?: "organisation_statuses_min_fields";
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["uuid"]>;
  organisation_id?: Maybe<Scalars["uuid"]>;
  status_id?: Maybe<Scalars["uuid"]>;
};

/** response of any mutation on the table "organisation_statuses" */
export type Organisation_Statuses_Mutation_Response = {
  __typename?: "organisation_statuses_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Organisation_Statuses>;
};

/** on_conflict condition type for table "organisation_statuses" */
export type Organisation_Statuses_On_Conflict = {
  constraint: Organisation_Statuses_Constraint;
  update_columns?: Array<Organisation_Statuses_Update_Column>;
  where?: InputMaybe<Organisation_Statuses_Bool_Exp>;
};

/** Ordering options when selecting data from "organisation_statuses". */
export type Organisation_Statuses_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  organisation_id?: InputMaybe<Order_By>;
  status_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: organisation_statuses */
export type Organisation_Statuses_Pk_Columns_Input = {
  id: Scalars["uuid"];
};

/** select columns of table "organisation_statuses" */
export enum Organisation_Statuses_Select_Column {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  Id = "id",
  /** column name */
  OrganisationId = "organisation_id",
  /** column name */
  StatusId = "status_id",
}

/** input type for updating data in table "organisation_statuses" */
export type Organisation_Statuses_Set_Input = {
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  organisation_id?: InputMaybe<Scalars["uuid"]>;
  status_id?: InputMaybe<Scalars["uuid"]>;
};

/** Streaming cursor of the table "organisation_statuses" */
export type Organisation_Statuses_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Organisation_Statuses_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Organisation_Statuses_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  organisation_id?: InputMaybe<Scalars["uuid"]>;
  status_id?: InputMaybe<Scalars["uuid"]>;
};

/** update columns of table "organisation_statuses" */
export enum Organisation_Statuses_Update_Column {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  Id = "id",
  /** column name */
  OrganisationId = "organisation_id",
  /** column name */
  StatusId = "status_id",
}

export type Organisation_Statuses_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Organisation_Statuses_Set_Input>;
  /** filter the rows which have to be updated */
  where: Organisation_Statuses_Bool_Exp;
};

/** columns and relationships of "organisations" */
export type Organisations = {
  __typename?: "organisations";
  /** An array relationship */
  activity_logs: Array<Activity_Logs>;
  /** An aggregate relationship */
  activity_logs_aggregate: Activity_Logs_Aggregate;
  /** An array relationship */
  audit_logs: Array<Audit_Logs>;
  /** An aggregate relationship */
  audit_logs_aggregate: Audit_Logs_Aggregate;
  created_at: Scalars["timestamptz"];
  email: Scalars["String"];
  id: Scalars["uuid"];
  is_active: Scalars["Boolean"];
  is_verified: Scalars["Boolean"];
  locale?: Maybe<Scalars["String"]>;
  name: Scalars["String"];
  /** An array relationship */
  organisation_settings: Array<Organisation_Settings>;
  /** An aggregate relationship */
  organisation_settings_aggregate: Organisation_Settings_Aggregate;
  phone_number: Scalars["String"];
  require_mfa: Scalars["Boolean"];
  updated_at: Scalars["timestamptz"];
  /** An array relationship */
  webhooks: Array<Webhooks>;
  /** An aggregate relationship */
  webhooks_aggregate: Webhooks_Aggregate;
  /** An array relationship */
  workflow_templates: Array<Workflow_Templates>;
  /** An aggregate relationship */
  workflow_templates_aggregate: Workflow_Templates_Aggregate;
};

/** columns and relationships of "organisations" */
export type OrganisationsActivity_LogsArgs = {
  distinct_on?: InputMaybe<Array<Activity_Logs_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Activity_Logs_Order_By>>;
  where?: InputMaybe<Activity_Logs_Bool_Exp>;
};

/** columns and relationships of "organisations" */
export type OrganisationsActivity_Logs_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Activity_Logs_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Activity_Logs_Order_By>>;
  where?: InputMaybe<Activity_Logs_Bool_Exp>;
};

/** columns and relationships of "organisations" */
export type OrganisationsAudit_LogsArgs = {
  distinct_on?: InputMaybe<Array<Audit_Logs_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Audit_Logs_Order_By>>;
  where?: InputMaybe<Audit_Logs_Bool_Exp>;
};

/** columns and relationships of "organisations" */
export type OrganisationsAudit_Logs_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Audit_Logs_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Audit_Logs_Order_By>>;
  where?: InputMaybe<Audit_Logs_Bool_Exp>;
};

/** columns and relationships of "organisations" */
export type OrganisationsOrganisation_SettingsArgs = {
  distinct_on?: InputMaybe<Array<Organisation_Settings_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Organisation_Settings_Order_By>>;
  where?: InputMaybe<Organisation_Settings_Bool_Exp>;
};

/** columns and relationships of "organisations" */
export type OrganisationsOrganisation_Settings_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Organisation_Settings_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Organisation_Settings_Order_By>>;
  where?: InputMaybe<Organisation_Settings_Bool_Exp>;
};

/** columns and relationships of "organisations" */
export type OrganisationsWebhooksArgs = {
  distinct_on?: InputMaybe<Array<Webhooks_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Webhooks_Order_By>>;
  where?: InputMaybe<Webhooks_Bool_Exp>;
};

/** columns and relationships of "organisations" */
export type OrganisationsWebhooks_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Webhooks_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Webhooks_Order_By>>;
  where?: InputMaybe<Webhooks_Bool_Exp>;
};

/** columns and relationships of "organisations" */
export type OrganisationsWorkflow_TemplatesArgs = {
  distinct_on?: InputMaybe<Array<Workflow_Templates_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Workflow_Templates_Order_By>>;
  where?: InputMaybe<Workflow_Templates_Bool_Exp>;
};

/** columns and relationships of "organisations" */
export type OrganisationsWorkflow_Templates_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Workflow_Templates_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Workflow_Templates_Order_By>>;
  where?: InputMaybe<Workflow_Templates_Bool_Exp>;
};

/** aggregated selection of "organisations" */
export type Organisations_Aggregate = {
  __typename?: "organisations_aggregate";
  aggregate?: Maybe<Organisations_Aggregate_Fields>;
  nodes: Array<Organisations>;
};

/** aggregate fields of "organisations" */
export type Organisations_Aggregate_Fields = {
  __typename?: "organisations_aggregate_fields";
  count: Scalars["Int"];
  max?: Maybe<Organisations_Max_Fields>;
  min?: Maybe<Organisations_Min_Fields>;
};

/** aggregate fields of "organisations" */
export type Organisations_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Organisations_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** Boolean expression to filter rows from the table "organisations". All fields are combined with a logical 'AND'. */
export type Organisations_Bool_Exp = {
  _and?: InputMaybe<Array<Organisations_Bool_Exp>>;
  _not?: InputMaybe<Organisations_Bool_Exp>;
  _or?: InputMaybe<Array<Organisations_Bool_Exp>>;
  activity_logs?: InputMaybe<Activity_Logs_Bool_Exp>;
  activity_logs_aggregate?: InputMaybe<Activity_Logs_Aggregate_Bool_Exp>;
  audit_logs?: InputMaybe<Audit_Logs_Bool_Exp>;
  audit_logs_aggregate?: InputMaybe<Audit_Logs_Aggregate_Bool_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  email?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  is_active?: InputMaybe<Boolean_Comparison_Exp>;
  is_verified?: InputMaybe<Boolean_Comparison_Exp>;
  locale?: InputMaybe<String_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  organisation_settings?: InputMaybe<Organisation_Settings_Bool_Exp>;
  organisation_settings_aggregate?: InputMaybe<Organisation_Settings_Aggregate_Bool_Exp>;
  phone_number?: InputMaybe<String_Comparison_Exp>;
  require_mfa?: InputMaybe<Boolean_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  webhooks?: InputMaybe<Webhooks_Bool_Exp>;
  webhooks_aggregate?: InputMaybe<Webhooks_Aggregate_Bool_Exp>;
  workflow_templates?: InputMaybe<Workflow_Templates_Bool_Exp>;
  workflow_templates_aggregate?: InputMaybe<Workflow_Templates_Aggregate_Bool_Exp>;
};

/** unique or primary key constraints on table "organisations" */
export enum Organisations_Constraint {
  /** unique or primary key constraint on columns "email" */
  OrganisationsEmailUnique = "organisations_email_unique",
  /** unique or primary key constraint on columns "phone_number" */
  OrganisationsPhoneNumberUnique = "organisations_phone_number_unique",
  /** unique or primary key constraint on columns "id" */
  OrganisationsPkey = "organisations_pkey",
}

/** input type for inserting data into table "organisations" */
export type Organisations_Insert_Input = {
  activity_logs?: InputMaybe<Activity_Logs_Arr_Rel_Insert_Input>;
  audit_logs?: InputMaybe<Audit_Logs_Arr_Rel_Insert_Input>;
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  email?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  is_active?: InputMaybe<Scalars["Boolean"]>;
  is_verified?: InputMaybe<Scalars["Boolean"]>;
  locale?: InputMaybe<Scalars["String"]>;
  name?: InputMaybe<Scalars["String"]>;
  organisation_settings?: InputMaybe<Organisation_Settings_Arr_Rel_Insert_Input>;
  phone_number?: InputMaybe<Scalars["String"]>;
  require_mfa?: InputMaybe<Scalars["Boolean"]>;
  updated_at?: InputMaybe<Scalars["timestamptz"]>;
  webhooks?: InputMaybe<Webhooks_Arr_Rel_Insert_Input>;
  workflow_templates?: InputMaybe<Workflow_Templates_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Organisations_Max_Fields = {
  __typename?: "organisations_max_fields";
  created_at?: Maybe<Scalars["timestamptz"]>;
  email?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["uuid"]>;
  locale?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  phone_number?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
};

/** aggregate min on columns */
export type Organisations_Min_Fields = {
  __typename?: "organisations_min_fields";
  created_at?: Maybe<Scalars["timestamptz"]>;
  email?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["uuid"]>;
  locale?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  phone_number?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
};

/** response of any mutation on the table "organisations" */
export type Organisations_Mutation_Response = {
  __typename?: "organisations_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Organisations>;
};

/** input type for inserting object relation for remote table "organisations" */
export type Organisations_Obj_Rel_Insert_Input = {
  data: Organisations_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Organisations_On_Conflict>;
};

/** on_conflict condition type for table "organisations" */
export type Organisations_On_Conflict = {
  constraint: Organisations_Constraint;
  update_columns?: Array<Organisations_Update_Column>;
  where?: InputMaybe<Organisations_Bool_Exp>;
};

/** Ordering options when selecting data from "organisations". */
export type Organisations_Order_By = {
  activity_logs_aggregate?: InputMaybe<Activity_Logs_Aggregate_Order_By>;
  audit_logs_aggregate?: InputMaybe<Audit_Logs_Aggregate_Order_By>;
  created_at?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  is_active?: InputMaybe<Order_By>;
  is_verified?: InputMaybe<Order_By>;
  locale?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  organisation_settings_aggregate?: InputMaybe<Organisation_Settings_Aggregate_Order_By>;
  phone_number?: InputMaybe<Order_By>;
  require_mfa?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  webhooks_aggregate?: InputMaybe<Webhooks_Aggregate_Order_By>;
  workflow_templates_aggregate?: InputMaybe<Workflow_Templates_Aggregate_Order_By>;
};

/** primary key columns input for table: organisations */
export type Organisations_Pk_Columns_Input = {
  id: Scalars["uuid"];
};

/** select columns of table "organisations" */
export enum Organisations_Select_Column {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  Email = "email",
  /** column name */
  Id = "id",
  /** column name */
  IsActive = "is_active",
  /** column name */
  IsVerified = "is_verified",
  /** column name */
  Locale = "locale",
  /** column name */
  Name = "name",
  /** column name */
  PhoneNumber = "phone_number",
  /** column name */
  RequireMfa = "require_mfa",
  /** column name */
  UpdatedAt = "updated_at",
}

/** input type for updating data in table "organisations" */
export type Organisations_Set_Input = {
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  email?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  is_active?: InputMaybe<Scalars["Boolean"]>;
  is_verified?: InputMaybe<Scalars["Boolean"]>;
  locale?: InputMaybe<Scalars["String"]>;
  name?: InputMaybe<Scalars["String"]>;
  phone_number?: InputMaybe<Scalars["String"]>;
  require_mfa?: InputMaybe<Scalars["Boolean"]>;
  updated_at?: InputMaybe<Scalars["timestamptz"]>;
};

/** Streaming cursor of the table "organisations" */
export type Organisations_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Organisations_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Organisations_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  email?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  is_active?: InputMaybe<Scalars["Boolean"]>;
  is_verified?: InputMaybe<Scalars["Boolean"]>;
  locale?: InputMaybe<Scalars["String"]>;
  name?: InputMaybe<Scalars["String"]>;
  phone_number?: InputMaybe<Scalars["String"]>;
  require_mfa?: InputMaybe<Scalars["Boolean"]>;
  updated_at?: InputMaybe<Scalars["timestamptz"]>;
};

/** update columns of table "organisations" */
export enum Organisations_Update_Column {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  Email = "email",
  /** column name */
  Id = "id",
  /** column name */
  IsActive = "is_active",
  /** column name */
  IsVerified = "is_verified",
  /** column name */
  Locale = "locale",
  /** column name */
  Name = "name",
  /** column name */
  PhoneNumber = "phone_number",
  /** column name */
  RequireMfa = "require_mfa",
  /** column name */
  UpdatedAt = "updated_at",
}

export type Organisations_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Organisations_Set_Input>;
  /** filter the rows which have to be updated */
  where: Organisations_Bool_Exp;
};

/** columns and relationships of "parties" */
export type Parties = {
  __typename?: "parties";
  avatar?: Maybe<Scalars["String"]>;
  /** An array relationship */
  campaigns: Array<Campaigns>;
  /** An aggregate relationship */
  campaigns_aggregate: Campaigns_Aggregate;
  /** An array relationship */
  caseAssignmentsByAssignedTo: Array<Case_Assignments>;
  /** An aggregate relationship */
  caseAssignmentsByAssignedTo_aggregate: Case_Assignments_Aggregate;
  /** An array relationship */
  caseMentionsByMentionedParty: Array<Case_Mentions>;
  /** An aggregate relationship */
  caseMentionsByMentionedParty_aggregate: Case_Mentions_Aggregate;
  /** An array relationship */
  case_assignments: Array<Case_Assignments>;
  /** An aggregate relationship */
  case_assignments_aggregate: Case_Assignments_Aggregate;
  /** An array relationship */
  case_mentions: Array<Case_Mentions>;
  /** An aggregate relationship */
  case_mentions_aggregate: Case_Mentions_Aggregate;
  /** An array relationship */
  cases: Array<Cases>;
  /** An array relationship */
  casesByPartyId: Array<Cases>;
  /** An aggregate relationship */
  casesByPartyId_aggregate: Cases_Aggregate;
  /** An aggregate relationship */
  cases_aggregate: Cases_Aggregate;
  /** An array relationship */
  channel_parties: Array<Channel_Parties>;
  /** An aggregate relationship */
  channel_parties_aggregate: Channel_Parties_Aggregate;
  /** An array relationship */
  channel_subscriptions: Array<Channel_Subscriptions>;
  /** An aggregate relationship */
  channel_subscriptions_aggregate: Channel_Subscriptions_Aggregate;
  created_at: Scalars["timestamptz"];
  /** An array relationship */
  credit_profiles: Array<Credit_Profiles>;
  /** An aggregate relationship */
  credit_profiles_aggregate: Credit_Profiles_Aggregate;
  /** An array relationship */
  documents: Array<Documents>;
  /** An aggregate relationship */
  documents_aggregate: Documents_Aggregate;
  email: Scalars["String"];
  /** An object relationship */
  form?: Maybe<Forms>;
  form_id?: Maybe<Scalars["uuid"]>;
  id: Scalars["uuid"];
  /** An array relationship */
  interactions: Array<Interactions>;
  /** An aggregate relationship */
  interactions_aggregate: Interactions_Aggregate;
  /** An array relationship */
  invoices: Array<Invoices>;
  /** An aggregate relationship */
  invoices_aggregate: Invoices_Aggregate;
  locale?: Maybe<Scalars["String"]>;
  location?: Maybe<Scalars["String"]>;
  /** An array relationship */
  messages: Array<Messages>;
  /** An aggregate relationship */
  messages_aggregate: Messages_Aggregate;
  metadata?: Maybe<Scalars["jsonb"]>;
  name: Scalars["String"];
  nickname?: Maybe<Scalars["String"]>;
  organisation_id: Scalars["uuid"];
  /** An array relationship */
  partyRelationshipsByParticipant: Array<Party_Relationships>;
  /** An aggregate relationship */
  partyRelationshipsByParticipant_aggregate: Party_Relationships_Aggregate;
  /** An array relationship */
  party_contacts: Array<Party_Contacts>;
  /** An aggregate relationship */
  party_contacts_aggregate: Party_Contacts_Aggregate;
  /** An array relationship */
  party_documents: Array<Party_Documents>;
  /** An aggregate relationship */
  party_documents_aggregate: Party_Documents_Aggregate;
  /** An array relationship */
  party_flags: Array<Party_Flags>;
  /** An aggregate relationship */
  party_flags_aggregate: Party_Flags_Aggregate;
  /** An array relationship */
  party_interactions: Array<Party_Interactions>;
  /** An aggregate relationship */
  party_interactions_aggregate: Party_Interactions_Aggregate;
  /** An array relationship */
  party_ledger_accounts: Array<Party_Ledger_Accounts>;
  /** An aggregate relationship */
  party_ledger_accounts_aggregate: Party_Ledger_Accounts_Aggregate;
  party_number?: Maybe<Scalars["String"]>;
  party_number_schema?: Maybe<Scalars["String"]>;
  /** An array relationship */
  party_relationships: Array<Party_Relationships>;
  /** An aggregate relationship */
  party_relationships_aggregate: Party_Relationships_Aggregate;
  /** An array relationship */
  party_settings: Array<Party_Settings>;
  /** An aggregate relationship */
  party_settings_aggregate: Party_Settings_Aggregate;
  /** An array relationship */
  party_statuses: Array<Party_Statuses>;
  /** An aggregate relationship */
  party_statuses_aggregate: Party_Statuses_Aggregate;
  /** An array relationship */
  performanceTargetsByAssignedTo: Array<Performance_Targets>;
  /** An aggregate relationship */
  performanceTargetsByAssignedTo_aggregate: Performance_Targets_Aggregate;
  /** An array relationship */
  performance_targets: Array<Performance_Targets>;
  /** An aggregate relationship */
  performance_targets_aggregate: Performance_Targets_Aggregate;
  phone_number: Scalars["String"];
  /** An array relationship */
  reward_redemptions: Array<Reward_Redemptions>;
  /** An aggregate relationship */
  reward_redemptions_aggregate: Reward_Redemptions_Aggregate;
  /** An array relationship */
  subscriptionPaymentsByRecordedBy: Array<Subscription_Payments>;
  /** An aggregate relationship */
  subscriptionPaymentsByRecordedBy_aggregate: Subscription_Payments_Aggregate;
  /** An array relationship */
  subscriptionPaymentsByUpdatedBy: Array<Subscription_Payments>;
  /** An aggregate relationship */
  subscriptionPaymentsByUpdatedBy_aggregate: Subscription_Payments_Aggregate;
  /** An array relationship */
  subscription_payment_transactions: Array<Subscription_Payment_Transactions>;
  /** An aggregate relationship */
  subscription_payment_transactions_aggregate: Subscription_Payment_Transactions_Aggregate;
  /** An array relationship */
  subscription_payments: Array<Subscription_Payments>;
  /** An aggregate relationship */
  subscription_payments_aggregate: Subscription_Payments_Aggregate;
  /** An array relationship */
  subscriptions: Array<Subscriptions>;
  /** An aggregate relationship */
  subscriptions_aggregate: Subscriptions_Aggregate;
  /** An array relationship */
  taskAssignmentsByAssignedTo: Array<Task_Assignments>;
  /** An aggregate relationship */
  taskAssignmentsByAssignedTo_aggregate: Task_Assignments_Aggregate;
  /** An array relationship */
  task_assignments: Array<Task_Assignments>;
  /** An aggregate relationship */
  task_assignments_aggregate: Task_Assignments_Aggregate;
  /** An array relationship */
  task_mentions: Array<Task_Mentions>;
  /** An aggregate relationship */
  task_mentions_aggregate: Task_Mentions_Aggregate;
  /** An array relationship */
  tasks: Array<Tasks>;
  /** An array relationship */
  tasksByAssignedTo: Array<Tasks>;
  /** An aggregate relationship */
  tasksByAssignedTo_aggregate: Tasks_Aggregate;
  /** An aggregate relationship */
  tasks_aggregate: Tasks_Aggregate;
  /** An array relationship */
  territories: Array<Territories>;
  /** An aggregate relationship */
  territories_aggregate: Territories_Aggregate;
  /** An array relationship */
  territoryAssignmentsByAssignedTo: Array<Territory_Assignments>;
  /** An aggregate relationship */
  territoryAssignmentsByAssignedTo_aggregate: Territory_Assignments_Aggregate;
  /** An array relationship */
  territory_assignments: Array<Territory_Assignments>;
  /** An aggregate relationship */
  territory_assignments_aggregate: Territory_Assignments_Aggregate;
  /** An array relationship */
  territory_members: Array<Territory_Members>;
  /** An aggregate relationship */
  territory_members_aggregate: Territory_Members_Aggregate;
  /** An object relationship */
  type: Types;
  type_id: Scalars["uuid"];
  updated_at: Scalars["timestamptz"];
};

/** columns and relationships of "parties" */
export type PartiesCampaignsArgs = {
  distinct_on?: InputMaybe<Array<Campaigns_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Campaigns_Order_By>>;
  where?: InputMaybe<Campaigns_Bool_Exp>;
};

/** columns and relationships of "parties" */
export type PartiesCampaigns_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Campaigns_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Campaigns_Order_By>>;
  where?: InputMaybe<Campaigns_Bool_Exp>;
};

/** columns and relationships of "parties" */
export type PartiesCaseAssignmentsByAssignedToArgs = {
  distinct_on?: InputMaybe<Array<Case_Assignments_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Case_Assignments_Order_By>>;
  where?: InputMaybe<Case_Assignments_Bool_Exp>;
};

/** columns and relationships of "parties" */
export type PartiesCaseAssignmentsByAssignedTo_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Case_Assignments_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Case_Assignments_Order_By>>;
  where?: InputMaybe<Case_Assignments_Bool_Exp>;
};

/** columns and relationships of "parties" */
export type PartiesCaseMentionsByMentionedPartyArgs = {
  distinct_on?: InputMaybe<Array<Case_Mentions_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Case_Mentions_Order_By>>;
  where?: InputMaybe<Case_Mentions_Bool_Exp>;
};

/** columns and relationships of "parties" */
export type PartiesCaseMentionsByMentionedParty_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Case_Mentions_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Case_Mentions_Order_By>>;
  where?: InputMaybe<Case_Mentions_Bool_Exp>;
};

/** columns and relationships of "parties" */
export type PartiesCase_AssignmentsArgs = {
  distinct_on?: InputMaybe<Array<Case_Assignments_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Case_Assignments_Order_By>>;
  where?: InputMaybe<Case_Assignments_Bool_Exp>;
};

/** columns and relationships of "parties" */
export type PartiesCase_Assignments_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Case_Assignments_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Case_Assignments_Order_By>>;
  where?: InputMaybe<Case_Assignments_Bool_Exp>;
};

/** columns and relationships of "parties" */
export type PartiesCase_MentionsArgs = {
  distinct_on?: InputMaybe<Array<Case_Mentions_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Case_Mentions_Order_By>>;
  where?: InputMaybe<Case_Mentions_Bool_Exp>;
};

/** columns and relationships of "parties" */
export type PartiesCase_Mentions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Case_Mentions_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Case_Mentions_Order_By>>;
  where?: InputMaybe<Case_Mentions_Bool_Exp>;
};

/** columns and relationships of "parties" */
export type PartiesCasesArgs = {
  distinct_on?: InputMaybe<Array<Cases_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Cases_Order_By>>;
  where?: InputMaybe<Cases_Bool_Exp>;
};

/** columns and relationships of "parties" */
export type PartiesCasesByPartyIdArgs = {
  distinct_on?: InputMaybe<Array<Cases_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Cases_Order_By>>;
  where?: InputMaybe<Cases_Bool_Exp>;
};

/** columns and relationships of "parties" */
export type PartiesCasesByPartyId_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Cases_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Cases_Order_By>>;
  where?: InputMaybe<Cases_Bool_Exp>;
};

/** columns and relationships of "parties" */
export type PartiesCases_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Cases_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Cases_Order_By>>;
  where?: InputMaybe<Cases_Bool_Exp>;
};

/** columns and relationships of "parties" */
export type PartiesChannel_PartiesArgs = {
  distinct_on?: InputMaybe<Array<Channel_Parties_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Channel_Parties_Order_By>>;
  where?: InputMaybe<Channel_Parties_Bool_Exp>;
};

/** columns and relationships of "parties" */
export type PartiesChannel_Parties_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Channel_Parties_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Channel_Parties_Order_By>>;
  where?: InputMaybe<Channel_Parties_Bool_Exp>;
};

/** columns and relationships of "parties" */
export type PartiesChannel_SubscriptionsArgs = {
  distinct_on?: InputMaybe<Array<Channel_Subscriptions_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Channel_Subscriptions_Order_By>>;
  where?: InputMaybe<Channel_Subscriptions_Bool_Exp>;
};

/** columns and relationships of "parties" */
export type PartiesChannel_Subscriptions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Channel_Subscriptions_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Channel_Subscriptions_Order_By>>;
  where?: InputMaybe<Channel_Subscriptions_Bool_Exp>;
};

/** columns and relationships of "parties" */
export type PartiesCredit_ProfilesArgs = {
  distinct_on?: InputMaybe<Array<Credit_Profiles_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Credit_Profiles_Order_By>>;
  where?: InputMaybe<Credit_Profiles_Bool_Exp>;
};

/** columns and relationships of "parties" */
export type PartiesCredit_Profiles_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Credit_Profiles_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Credit_Profiles_Order_By>>;
  where?: InputMaybe<Credit_Profiles_Bool_Exp>;
};

/** columns and relationships of "parties" */
export type PartiesDocumentsArgs = {
  distinct_on?: InputMaybe<Array<Documents_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Documents_Order_By>>;
  where?: InputMaybe<Documents_Bool_Exp>;
};

/** columns and relationships of "parties" */
export type PartiesDocuments_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Documents_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Documents_Order_By>>;
  where?: InputMaybe<Documents_Bool_Exp>;
};

/** columns and relationships of "parties" */
export type PartiesInteractionsArgs = {
  distinct_on?: InputMaybe<Array<Interactions_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Interactions_Order_By>>;
  where?: InputMaybe<Interactions_Bool_Exp>;
};

/** columns and relationships of "parties" */
export type PartiesInteractions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Interactions_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Interactions_Order_By>>;
  where?: InputMaybe<Interactions_Bool_Exp>;
};

/** columns and relationships of "parties" */
export type PartiesInvoicesArgs = {
  distinct_on?: InputMaybe<Array<Invoices_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Invoices_Order_By>>;
  where?: InputMaybe<Invoices_Bool_Exp>;
};

/** columns and relationships of "parties" */
export type PartiesInvoices_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Invoices_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Invoices_Order_By>>;
  where?: InputMaybe<Invoices_Bool_Exp>;
};

/** columns and relationships of "parties" */
export type PartiesMessagesArgs = {
  distinct_on?: InputMaybe<Array<Messages_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Messages_Order_By>>;
  where?: InputMaybe<Messages_Bool_Exp>;
};

/** columns and relationships of "parties" */
export type PartiesMessages_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Messages_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Messages_Order_By>>;
  where?: InputMaybe<Messages_Bool_Exp>;
};

/** columns and relationships of "parties" */
export type PartiesMetadataArgs = {
  path?: InputMaybe<Scalars["String"]>;
};

/** columns and relationships of "parties" */
export type PartiesPartyRelationshipsByParticipantArgs = {
  distinct_on?: InputMaybe<Array<Party_Relationships_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Party_Relationships_Order_By>>;
  where?: InputMaybe<Party_Relationships_Bool_Exp>;
};

/** columns and relationships of "parties" */
export type PartiesPartyRelationshipsByParticipant_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Party_Relationships_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Party_Relationships_Order_By>>;
  where?: InputMaybe<Party_Relationships_Bool_Exp>;
};

/** columns and relationships of "parties" */
export type PartiesParty_ContactsArgs = {
  distinct_on?: InputMaybe<Array<Party_Contacts_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Party_Contacts_Order_By>>;
  where?: InputMaybe<Party_Contacts_Bool_Exp>;
};

/** columns and relationships of "parties" */
export type PartiesParty_Contacts_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Party_Contacts_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Party_Contacts_Order_By>>;
  where?: InputMaybe<Party_Contacts_Bool_Exp>;
};

/** columns and relationships of "parties" */
export type PartiesParty_DocumentsArgs = {
  distinct_on?: InputMaybe<Array<Party_Documents_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Party_Documents_Order_By>>;
  where?: InputMaybe<Party_Documents_Bool_Exp>;
};

/** columns and relationships of "parties" */
export type PartiesParty_Documents_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Party_Documents_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Party_Documents_Order_By>>;
  where?: InputMaybe<Party_Documents_Bool_Exp>;
};

/** columns and relationships of "parties" */
export type PartiesParty_FlagsArgs = {
  distinct_on?: InputMaybe<Array<Party_Flags_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Party_Flags_Order_By>>;
  where?: InputMaybe<Party_Flags_Bool_Exp>;
};

/** columns and relationships of "parties" */
export type PartiesParty_Flags_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Party_Flags_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Party_Flags_Order_By>>;
  where?: InputMaybe<Party_Flags_Bool_Exp>;
};

/** columns and relationships of "parties" */
export type PartiesParty_InteractionsArgs = {
  distinct_on?: InputMaybe<Array<Party_Interactions_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Party_Interactions_Order_By>>;
  where?: InputMaybe<Party_Interactions_Bool_Exp>;
};

/** columns and relationships of "parties" */
export type PartiesParty_Interactions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Party_Interactions_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Party_Interactions_Order_By>>;
  where?: InputMaybe<Party_Interactions_Bool_Exp>;
};

/** columns and relationships of "parties" */
export type PartiesParty_Ledger_AccountsArgs = {
  distinct_on?: InputMaybe<Array<Party_Ledger_Accounts_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Party_Ledger_Accounts_Order_By>>;
  where?: InputMaybe<Party_Ledger_Accounts_Bool_Exp>;
};

/** columns and relationships of "parties" */
export type PartiesParty_Ledger_Accounts_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Party_Ledger_Accounts_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Party_Ledger_Accounts_Order_By>>;
  where?: InputMaybe<Party_Ledger_Accounts_Bool_Exp>;
};

/** columns and relationships of "parties" */
export type PartiesParty_RelationshipsArgs = {
  distinct_on?: InputMaybe<Array<Party_Relationships_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Party_Relationships_Order_By>>;
  where?: InputMaybe<Party_Relationships_Bool_Exp>;
};

/** columns and relationships of "parties" */
export type PartiesParty_Relationships_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Party_Relationships_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Party_Relationships_Order_By>>;
  where?: InputMaybe<Party_Relationships_Bool_Exp>;
};

/** columns and relationships of "parties" */
export type PartiesParty_SettingsArgs = {
  distinct_on?: InputMaybe<Array<Party_Settings_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Party_Settings_Order_By>>;
  where?: InputMaybe<Party_Settings_Bool_Exp>;
};

/** columns and relationships of "parties" */
export type PartiesParty_Settings_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Party_Settings_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Party_Settings_Order_By>>;
  where?: InputMaybe<Party_Settings_Bool_Exp>;
};

/** columns and relationships of "parties" */
export type PartiesParty_StatusesArgs = {
  distinct_on?: InputMaybe<Array<Party_Statuses_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Party_Statuses_Order_By>>;
  where?: InputMaybe<Party_Statuses_Bool_Exp>;
};

/** columns and relationships of "parties" */
export type PartiesParty_Statuses_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Party_Statuses_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Party_Statuses_Order_By>>;
  where?: InputMaybe<Party_Statuses_Bool_Exp>;
};

/** columns and relationships of "parties" */
export type PartiesPerformanceTargetsByAssignedToArgs = {
  distinct_on?: InputMaybe<Array<Performance_Targets_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Performance_Targets_Order_By>>;
  where?: InputMaybe<Performance_Targets_Bool_Exp>;
};

/** columns and relationships of "parties" */
export type PartiesPerformanceTargetsByAssignedTo_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Performance_Targets_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Performance_Targets_Order_By>>;
  where?: InputMaybe<Performance_Targets_Bool_Exp>;
};

/** columns and relationships of "parties" */
export type PartiesPerformance_TargetsArgs = {
  distinct_on?: InputMaybe<Array<Performance_Targets_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Performance_Targets_Order_By>>;
  where?: InputMaybe<Performance_Targets_Bool_Exp>;
};

/** columns and relationships of "parties" */
export type PartiesPerformance_Targets_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Performance_Targets_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Performance_Targets_Order_By>>;
  where?: InputMaybe<Performance_Targets_Bool_Exp>;
};

/** columns and relationships of "parties" */
export type PartiesReward_RedemptionsArgs = {
  distinct_on?: InputMaybe<Array<Reward_Redemptions_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Reward_Redemptions_Order_By>>;
  where?: InputMaybe<Reward_Redemptions_Bool_Exp>;
};

/** columns and relationships of "parties" */
export type PartiesReward_Redemptions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Reward_Redemptions_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Reward_Redemptions_Order_By>>;
  where?: InputMaybe<Reward_Redemptions_Bool_Exp>;
};

/** columns and relationships of "parties" */
export type PartiesSubscriptionPaymentsByRecordedByArgs = {
  distinct_on?: InputMaybe<Array<Subscription_Payments_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Subscription_Payments_Order_By>>;
  where?: InputMaybe<Subscription_Payments_Bool_Exp>;
};

/** columns and relationships of "parties" */
export type PartiesSubscriptionPaymentsByRecordedBy_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Subscription_Payments_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Subscription_Payments_Order_By>>;
  where?: InputMaybe<Subscription_Payments_Bool_Exp>;
};

/** columns and relationships of "parties" */
export type PartiesSubscriptionPaymentsByUpdatedByArgs = {
  distinct_on?: InputMaybe<Array<Subscription_Payments_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Subscription_Payments_Order_By>>;
  where?: InputMaybe<Subscription_Payments_Bool_Exp>;
};

/** columns and relationships of "parties" */
export type PartiesSubscriptionPaymentsByUpdatedBy_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Subscription_Payments_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Subscription_Payments_Order_By>>;
  where?: InputMaybe<Subscription_Payments_Bool_Exp>;
};

/** columns and relationships of "parties" */
export type PartiesSubscription_Payment_TransactionsArgs = {
  distinct_on?: InputMaybe<
    Array<Subscription_Payment_Transactions_Select_Column>
  >;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Subscription_Payment_Transactions_Order_By>>;
  where?: InputMaybe<Subscription_Payment_Transactions_Bool_Exp>;
};

/** columns and relationships of "parties" */
export type PartiesSubscription_Payment_Transactions_AggregateArgs = {
  distinct_on?: InputMaybe<
    Array<Subscription_Payment_Transactions_Select_Column>
  >;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Subscription_Payment_Transactions_Order_By>>;
  where?: InputMaybe<Subscription_Payment_Transactions_Bool_Exp>;
};

/** columns and relationships of "parties" */
export type PartiesSubscription_PaymentsArgs = {
  distinct_on?: InputMaybe<Array<Subscription_Payments_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Subscription_Payments_Order_By>>;
  where?: InputMaybe<Subscription_Payments_Bool_Exp>;
};

/** columns and relationships of "parties" */
export type PartiesSubscription_Payments_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Subscription_Payments_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Subscription_Payments_Order_By>>;
  where?: InputMaybe<Subscription_Payments_Bool_Exp>;
};

/** columns and relationships of "parties" */
export type PartiesSubscriptionsArgs = {
  distinct_on?: InputMaybe<Array<Subscriptions_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Subscriptions_Order_By>>;
  where?: InputMaybe<Subscriptions_Bool_Exp>;
};

/** columns and relationships of "parties" */
export type PartiesSubscriptions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Subscriptions_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Subscriptions_Order_By>>;
  where?: InputMaybe<Subscriptions_Bool_Exp>;
};

/** columns and relationships of "parties" */
export type PartiesTaskAssignmentsByAssignedToArgs = {
  distinct_on?: InputMaybe<Array<Task_Assignments_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Task_Assignments_Order_By>>;
  where?: InputMaybe<Task_Assignments_Bool_Exp>;
};

/** columns and relationships of "parties" */
export type PartiesTaskAssignmentsByAssignedTo_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Task_Assignments_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Task_Assignments_Order_By>>;
  where?: InputMaybe<Task_Assignments_Bool_Exp>;
};

/** columns and relationships of "parties" */
export type PartiesTask_AssignmentsArgs = {
  distinct_on?: InputMaybe<Array<Task_Assignments_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Task_Assignments_Order_By>>;
  where?: InputMaybe<Task_Assignments_Bool_Exp>;
};

/** columns and relationships of "parties" */
export type PartiesTask_Assignments_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Task_Assignments_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Task_Assignments_Order_By>>;
  where?: InputMaybe<Task_Assignments_Bool_Exp>;
};

/** columns and relationships of "parties" */
export type PartiesTask_MentionsArgs = {
  distinct_on?: InputMaybe<Array<Task_Mentions_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Task_Mentions_Order_By>>;
  where?: InputMaybe<Task_Mentions_Bool_Exp>;
};

/** columns and relationships of "parties" */
export type PartiesTask_Mentions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Task_Mentions_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Task_Mentions_Order_By>>;
  where?: InputMaybe<Task_Mentions_Bool_Exp>;
};

/** columns and relationships of "parties" */
export type PartiesTasksArgs = {
  distinct_on?: InputMaybe<Array<Tasks_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Tasks_Order_By>>;
  where?: InputMaybe<Tasks_Bool_Exp>;
};

/** columns and relationships of "parties" */
export type PartiesTasksByAssignedToArgs = {
  distinct_on?: InputMaybe<Array<Tasks_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Tasks_Order_By>>;
  where?: InputMaybe<Tasks_Bool_Exp>;
};

/** columns and relationships of "parties" */
export type PartiesTasksByAssignedTo_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tasks_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Tasks_Order_By>>;
  where?: InputMaybe<Tasks_Bool_Exp>;
};

/** columns and relationships of "parties" */
export type PartiesTasks_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tasks_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Tasks_Order_By>>;
  where?: InputMaybe<Tasks_Bool_Exp>;
};

/** columns and relationships of "parties" */
export type PartiesTerritoriesArgs = {
  distinct_on?: InputMaybe<Array<Territories_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Territories_Order_By>>;
  where?: InputMaybe<Territories_Bool_Exp>;
};

/** columns and relationships of "parties" */
export type PartiesTerritories_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Territories_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Territories_Order_By>>;
  where?: InputMaybe<Territories_Bool_Exp>;
};

/** columns and relationships of "parties" */
export type PartiesTerritoryAssignmentsByAssignedToArgs = {
  distinct_on?: InputMaybe<Array<Territory_Assignments_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Territory_Assignments_Order_By>>;
  where?: InputMaybe<Territory_Assignments_Bool_Exp>;
};

/** columns and relationships of "parties" */
export type PartiesTerritoryAssignmentsByAssignedTo_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Territory_Assignments_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Territory_Assignments_Order_By>>;
  where?: InputMaybe<Territory_Assignments_Bool_Exp>;
};

/** columns and relationships of "parties" */
export type PartiesTerritory_AssignmentsArgs = {
  distinct_on?: InputMaybe<Array<Territory_Assignments_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Territory_Assignments_Order_By>>;
  where?: InputMaybe<Territory_Assignments_Bool_Exp>;
};

/** columns and relationships of "parties" */
export type PartiesTerritory_Assignments_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Territory_Assignments_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Territory_Assignments_Order_By>>;
  where?: InputMaybe<Territory_Assignments_Bool_Exp>;
};

/** columns and relationships of "parties" */
export type PartiesTerritory_MembersArgs = {
  distinct_on?: InputMaybe<Array<Territory_Members_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Territory_Members_Order_By>>;
  where?: InputMaybe<Territory_Members_Bool_Exp>;
};

/** columns and relationships of "parties" */
export type PartiesTerritory_Members_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Territory_Members_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Territory_Members_Order_By>>;
  where?: InputMaybe<Territory_Members_Bool_Exp>;
};

/** aggregated selection of "parties" */
export type Parties_Aggregate = {
  __typename?: "parties_aggregate";
  aggregate?: Maybe<Parties_Aggregate_Fields>;
  nodes: Array<Parties>;
};

export type Parties_Aggregate_Bool_Exp = {
  count?: InputMaybe<Parties_Aggregate_Bool_Exp_Count>;
};

export type Parties_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Parties_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<Parties_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "parties" */
export type Parties_Aggregate_Fields = {
  __typename?: "parties_aggregate_fields";
  count: Scalars["Int"];
  max?: Maybe<Parties_Max_Fields>;
  min?: Maybe<Parties_Min_Fields>;
};

/** aggregate fields of "parties" */
export type Parties_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Parties_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "parties" */
export type Parties_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Parties_Max_Order_By>;
  min?: InputMaybe<Parties_Min_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Parties_Append_Input = {
  metadata?: InputMaybe<Scalars["jsonb"]>;
};

/** input type for inserting array relation for remote table "parties" */
export type Parties_Arr_Rel_Insert_Input = {
  data: Array<Parties_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Parties_On_Conflict>;
};

/** Boolean expression to filter rows from the table "parties". All fields are combined with a logical 'AND'. */
export type Parties_Bool_Exp = {
  _and?: InputMaybe<Array<Parties_Bool_Exp>>;
  _not?: InputMaybe<Parties_Bool_Exp>;
  _or?: InputMaybe<Array<Parties_Bool_Exp>>;
  avatar?: InputMaybe<String_Comparison_Exp>;
  campaigns?: InputMaybe<Campaigns_Bool_Exp>;
  campaigns_aggregate?: InputMaybe<Campaigns_Aggregate_Bool_Exp>;
  caseAssignmentsByAssignedTo?: InputMaybe<Case_Assignments_Bool_Exp>;
  caseAssignmentsByAssignedTo_aggregate?: InputMaybe<Case_Assignments_Aggregate_Bool_Exp>;
  caseMentionsByMentionedParty?: InputMaybe<Case_Mentions_Bool_Exp>;
  caseMentionsByMentionedParty_aggregate?: InputMaybe<Case_Mentions_Aggregate_Bool_Exp>;
  case_assignments?: InputMaybe<Case_Assignments_Bool_Exp>;
  case_assignments_aggregate?: InputMaybe<Case_Assignments_Aggregate_Bool_Exp>;
  case_mentions?: InputMaybe<Case_Mentions_Bool_Exp>;
  case_mentions_aggregate?: InputMaybe<Case_Mentions_Aggregate_Bool_Exp>;
  cases?: InputMaybe<Cases_Bool_Exp>;
  casesByPartyId?: InputMaybe<Cases_Bool_Exp>;
  casesByPartyId_aggregate?: InputMaybe<Cases_Aggregate_Bool_Exp>;
  cases_aggregate?: InputMaybe<Cases_Aggregate_Bool_Exp>;
  channel_parties?: InputMaybe<Channel_Parties_Bool_Exp>;
  channel_parties_aggregate?: InputMaybe<Channel_Parties_Aggregate_Bool_Exp>;
  channel_subscriptions?: InputMaybe<Channel_Subscriptions_Bool_Exp>;
  channel_subscriptions_aggregate?: InputMaybe<Channel_Subscriptions_Aggregate_Bool_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  credit_profiles?: InputMaybe<Credit_Profiles_Bool_Exp>;
  credit_profiles_aggregate?: InputMaybe<Credit_Profiles_Aggregate_Bool_Exp>;
  documents?: InputMaybe<Documents_Bool_Exp>;
  documents_aggregate?: InputMaybe<Documents_Aggregate_Bool_Exp>;
  email?: InputMaybe<String_Comparison_Exp>;
  form?: InputMaybe<Forms_Bool_Exp>;
  form_id?: InputMaybe<Uuid_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  interactions?: InputMaybe<Interactions_Bool_Exp>;
  interactions_aggregate?: InputMaybe<Interactions_Aggregate_Bool_Exp>;
  invoices?: InputMaybe<Invoices_Bool_Exp>;
  invoices_aggregate?: InputMaybe<Invoices_Aggregate_Bool_Exp>;
  locale?: InputMaybe<String_Comparison_Exp>;
  location?: InputMaybe<String_Comparison_Exp>;
  messages?: InputMaybe<Messages_Bool_Exp>;
  messages_aggregate?: InputMaybe<Messages_Aggregate_Bool_Exp>;
  metadata?: InputMaybe<Jsonb_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  nickname?: InputMaybe<String_Comparison_Exp>;
  organisation_id?: InputMaybe<Uuid_Comparison_Exp>;
  partyRelationshipsByParticipant?: InputMaybe<Party_Relationships_Bool_Exp>;
  partyRelationshipsByParticipant_aggregate?: InputMaybe<Party_Relationships_Aggregate_Bool_Exp>;
  party_contacts?: InputMaybe<Party_Contacts_Bool_Exp>;
  party_contacts_aggregate?: InputMaybe<Party_Contacts_Aggregate_Bool_Exp>;
  party_documents?: InputMaybe<Party_Documents_Bool_Exp>;
  party_documents_aggregate?: InputMaybe<Party_Documents_Aggregate_Bool_Exp>;
  party_flags?: InputMaybe<Party_Flags_Bool_Exp>;
  party_flags_aggregate?: InputMaybe<Party_Flags_Aggregate_Bool_Exp>;
  party_interactions?: InputMaybe<Party_Interactions_Bool_Exp>;
  party_interactions_aggregate?: InputMaybe<Party_Interactions_Aggregate_Bool_Exp>;
  party_ledger_accounts?: InputMaybe<Party_Ledger_Accounts_Bool_Exp>;
  party_ledger_accounts_aggregate?: InputMaybe<Party_Ledger_Accounts_Aggregate_Bool_Exp>;
  party_number?: InputMaybe<String_Comparison_Exp>;
  party_number_schema?: InputMaybe<String_Comparison_Exp>;
  party_relationships?: InputMaybe<Party_Relationships_Bool_Exp>;
  party_relationships_aggregate?: InputMaybe<Party_Relationships_Aggregate_Bool_Exp>;
  party_settings?: InputMaybe<Party_Settings_Bool_Exp>;
  party_settings_aggregate?: InputMaybe<Party_Settings_Aggregate_Bool_Exp>;
  party_statuses?: InputMaybe<Party_Statuses_Bool_Exp>;
  party_statuses_aggregate?: InputMaybe<Party_Statuses_Aggregate_Bool_Exp>;
  performanceTargetsByAssignedTo?: InputMaybe<Performance_Targets_Bool_Exp>;
  performanceTargetsByAssignedTo_aggregate?: InputMaybe<Performance_Targets_Aggregate_Bool_Exp>;
  performance_targets?: InputMaybe<Performance_Targets_Bool_Exp>;
  performance_targets_aggregate?: InputMaybe<Performance_Targets_Aggregate_Bool_Exp>;
  phone_number?: InputMaybe<String_Comparison_Exp>;
  reward_redemptions?: InputMaybe<Reward_Redemptions_Bool_Exp>;
  reward_redemptions_aggregate?: InputMaybe<Reward_Redemptions_Aggregate_Bool_Exp>;
  subscriptionPaymentsByRecordedBy?: InputMaybe<Subscription_Payments_Bool_Exp>;
  subscriptionPaymentsByRecordedBy_aggregate?: InputMaybe<Subscription_Payments_Aggregate_Bool_Exp>;
  subscriptionPaymentsByUpdatedBy?: InputMaybe<Subscription_Payments_Bool_Exp>;
  subscriptionPaymentsByUpdatedBy_aggregate?: InputMaybe<Subscription_Payments_Aggregate_Bool_Exp>;
  subscription_payment_transactions?: InputMaybe<Subscription_Payment_Transactions_Bool_Exp>;
  subscription_payment_transactions_aggregate?: InputMaybe<Subscription_Payment_Transactions_Aggregate_Bool_Exp>;
  subscription_payments?: InputMaybe<Subscription_Payments_Bool_Exp>;
  subscription_payments_aggregate?: InputMaybe<Subscription_Payments_Aggregate_Bool_Exp>;
  subscriptions?: InputMaybe<Subscriptions_Bool_Exp>;
  subscriptions_aggregate?: InputMaybe<Subscriptions_Aggregate_Bool_Exp>;
  taskAssignmentsByAssignedTo?: InputMaybe<Task_Assignments_Bool_Exp>;
  taskAssignmentsByAssignedTo_aggregate?: InputMaybe<Task_Assignments_Aggregate_Bool_Exp>;
  task_assignments?: InputMaybe<Task_Assignments_Bool_Exp>;
  task_assignments_aggregate?: InputMaybe<Task_Assignments_Aggregate_Bool_Exp>;
  task_mentions?: InputMaybe<Task_Mentions_Bool_Exp>;
  task_mentions_aggregate?: InputMaybe<Task_Mentions_Aggregate_Bool_Exp>;
  tasks?: InputMaybe<Tasks_Bool_Exp>;
  tasksByAssignedTo?: InputMaybe<Tasks_Bool_Exp>;
  tasksByAssignedTo_aggregate?: InputMaybe<Tasks_Aggregate_Bool_Exp>;
  tasks_aggregate?: InputMaybe<Tasks_Aggregate_Bool_Exp>;
  territories?: InputMaybe<Territories_Bool_Exp>;
  territories_aggregate?: InputMaybe<Territories_Aggregate_Bool_Exp>;
  territoryAssignmentsByAssignedTo?: InputMaybe<Territory_Assignments_Bool_Exp>;
  territoryAssignmentsByAssignedTo_aggregate?: InputMaybe<Territory_Assignments_Aggregate_Bool_Exp>;
  territory_assignments?: InputMaybe<Territory_Assignments_Bool_Exp>;
  territory_assignments_aggregate?: InputMaybe<Territory_Assignments_Aggregate_Bool_Exp>;
  territory_members?: InputMaybe<Territory_Members_Bool_Exp>;
  territory_members_aggregate?: InputMaybe<Territory_Members_Aggregate_Bool_Exp>;
  type?: InputMaybe<Types_Bool_Exp>;
  type_id?: InputMaybe<Uuid_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "parties" */
export enum Parties_Constraint {
  /** unique or primary key constraint on columns "id" */
  PartiesPkey = "parties_pkey",
  /** unique or primary key constraint on columns "phone_number", "organisation_id", "name" */
  UniqueOrganisationParty = "unique_organisation_party",
  /** unique or primary key constraint on columns "organisation_id", "email" */
  UniqueOrganisationPartyByEmail = "unique_organisation_party_by_email",
  /** unique or primary key constraint on columns "organisation_id", "party_number" */
  UniquePartyNumber = "unique_party_number",
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Parties_Delete_At_Path_Input = {
  metadata?: InputMaybe<Array<Scalars["String"]>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Parties_Delete_Elem_Input = {
  metadata?: InputMaybe<Scalars["Int"]>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Parties_Delete_Key_Input = {
  metadata?: InputMaybe<Scalars["String"]>;
};

/** input type for inserting data into table "parties" */
export type Parties_Insert_Input = {
  avatar?: InputMaybe<Scalars["String"]>;
  campaigns?: InputMaybe<Campaigns_Arr_Rel_Insert_Input>;
  caseAssignmentsByAssignedTo?: InputMaybe<Case_Assignments_Arr_Rel_Insert_Input>;
  caseMentionsByMentionedParty?: InputMaybe<Case_Mentions_Arr_Rel_Insert_Input>;
  case_assignments?: InputMaybe<Case_Assignments_Arr_Rel_Insert_Input>;
  case_mentions?: InputMaybe<Case_Mentions_Arr_Rel_Insert_Input>;
  cases?: InputMaybe<Cases_Arr_Rel_Insert_Input>;
  casesByPartyId?: InputMaybe<Cases_Arr_Rel_Insert_Input>;
  channel_parties?: InputMaybe<Channel_Parties_Arr_Rel_Insert_Input>;
  channel_subscriptions?: InputMaybe<Channel_Subscriptions_Arr_Rel_Insert_Input>;
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  credit_profiles?: InputMaybe<Credit_Profiles_Arr_Rel_Insert_Input>;
  documents?: InputMaybe<Documents_Arr_Rel_Insert_Input>;
  email?: InputMaybe<Scalars["String"]>;
  form?: InputMaybe<Forms_Obj_Rel_Insert_Input>;
  form_id?: InputMaybe<Scalars["uuid"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  interactions?: InputMaybe<Interactions_Arr_Rel_Insert_Input>;
  invoices?: InputMaybe<Invoices_Arr_Rel_Insert_Input>;
  locale?: InputMaybe<Scalars["String"]>;
  location?: InputMaybe<Scalars["String"]>;
  messages?: InputMaybe<Messages_Arr_Rel_Insert_Input>;
  metadata?: InputMaybe<Scalars["jsonb"]>;
  name?: InputMaybe<Scalars["String"]>;
  nickname?: InputMaybe<Scalars["String"]>;
  organisation_id?: InputMaybe<Scalars["uuid"]>;
  partyRelationshipsByParticipant?: InputMaybe<Party_Relationships_Arr_Rel_Insert_Input>;
  party_contacts?: InputMaybe<Party_Contacts_Arr_Rel_Insert_Input>;
  party_documents?: InputMaybe<Party_Documents_Arr_Rel_Insert_Input>;
  party_flags?: InputMaybe<Party_Flags_Arr_Rel_Insert_Input>;
  party_interactions?: InputMaybe<Party_Interactions_Arr_Rel_Insert_Input>;
  party_ledger_accounts?: InputMaybe<Party_Ledger_Accounts_Arr_Rel_Insert_Input>;
  party_number?: InputMaybe<Scalars["String"]>;
  party_number_schema?: InputMaybe<Scalars["String"]>;
  party_relationships?: InputMaybe<Party_Relationships_Arr_Rel_Insert_Input>;
  party_settings?: InputMaybe<Party_Settings_Arr_Rel_Insert_Input>;
  party_statuses?: InputMaybe<Party_Statuses_Arr_Rel_Insert_Input>;
  performanceTargetsByAssignedTo?: InputMaybe<Performance_Targets_Arr_Rel_Insert_Input>;
  performance_targets?: InputMaybe<Performance_Targets_Arr_Rel_Insert_Input>;
  phone_number?: InputMaybe<Scalars["String"]>;
  reward_redemptions?: InputMaybe<Reward_Redemptions_Arr_Rel_Insert_Input>;
  subscriptionPaymentsByRecordedBy?: InputMaybe<Subscription_Payments_Arr_Rel_Insert_Input>;
  subscriptionPaymentsByUpdatedBy?: InputMaybe<Subscription_Payments_Arr_Rel_Insert_Input>;
  subscription_payment_transactions?: InputMaybe<Subscription_Payment_Transactions_Arr_Rel_Insert_Input>;
  subscription_payments?: InputMaybe<Subscription_Payments_Arr_Rel_Insert_Input>;
  subscriptions?: InputMaybe<Subscriptions_Arr_Rel_Insert_Input>;
  taskAssignmentsByAssignedTo?: InputMaybe<Task_Assignments_Arr_Rel_Insert_Input>;
  task_assignments?: InputMaybe<Task_Assignments_Arr_Rel_Insert_Input>;
  task_mentions?: InputMaybe<Task_Mentions_Arr_Rel_Insert_Input>;
  tasks?: InputMaybe<Tasks_Arr_Rel_Insert_Input>;
  tasksByAssignedTo?: InputMaybe<Tasks_Arr_Rel_Insert_Input>;
  territories?: InputMaybe<Territories_Arr_Rel_Insert_Input>;
  territoryAssignmentsByAssignedTo?: InputMaybe<Territory_Assignments_Arr_Rel_Insert_Input>;
  territory_assignments?: InputMaybe<Territory_Assignments_Arr_Rel_Insert_Input>;
  territory_members?: InputMaybe<Territory_Members_Arr_Rel_Insert_Input>;
  type?: InputMaybe<Types_Obj_Rel_Insert_Input>;
  type_id?: InputMaybe<Scalars["uuid"]>;
  updated_at?: InputMaybe<Scalars["timestamptz"]>;
};

/** aggregate max on columns */
export type Parties_Max_Fields = {
  __typename?: "parties_max_fields";
  avatar?: Maybe<Scalars["String"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  email?: Maybe<Scalars["String"]>;
  form_id?: Maybe<Scalars["uuid"]>;
  id?: Maybe<Scalars["uuid"]>;
  locale?: Maybe<Scalars["String"]>;
  location?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  nickname?: Maybe<Scalars["String"]>;
  organisation_id?: Maybe<Scalars["uuid"]>;
  party_number?: Maybe<Scalars["String"]>;
  party_number_schema?: Maybe<Scalars["String"]>;
  phone_number?: Maybe<Scalars["String"]>;
  type_id?: Maybe<Scalars["uuid"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
};

/** order by max() on columns of table "parties" */
export type Parties_Max_Order_By = {
  avatar?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  form_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  locale?: InputMaybe<Order_By>;
  location?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  nickname?: InputMaybe<Order_By>;
  organisation_id?: InputMaybe<Order_By>;
  party_number?: InputMaybe<Order_By>;
  party_number_schema?: InputMaybe<Order_By>;
  phone_number?: InputMaybe<Order_By>;
  type_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Parties_Min_Fields = {
  __typename?: "parties_min_fields";
  avatar?: Maybe<Scalars["String"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  email?: Maybe<Scalars["String"]>;
  form_id?: Maybe<Scalars["uuid"]>;
  id?: Maybe<Scalars["uuid"]>;
  locale?: Maybe<Scalars["String"]>;
  location?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  nickname?: Maybe<Scalars["String"]>;
  organisation_id?: Maybe<Scalars["uuid"]>;
  party_number?: Maybe<Scalars["String"]>;
  party_number_schema?: Maybe<Scalars["String"]>;
  phone_number?: Maybe<Scalars["String"]>;
  type_id?: Maybe<Scalars["uuid"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
};

/** order by min() on columns of table "parties" */
export type Parties_Min_Order_By = {
  avatar?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  form_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  locale?: InputMaybe<Order_By>;
  location?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  nickname?: InputMaybe<Order_By>;
  organisation_id?: InputMaybe<Order_By>;
  party_number?: InputMaybe<Order_By>;
  party_number_schema?: InputMaybe<Order_By>;
  phone_number?: InputMaybe<Order_By>;
  type_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "parties" */
export type Parties_Mutation_Response = {
  __typename?: "parties_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Parties>;
};

/** input type for inserting object relation for remote table "parties" */
export type Parties_Obj_Rel_Insert_Input = {
  data: Parties_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Parties_On_Conflict>;
};

/** on_conflict condition type for table "parties" */
export type Parties_On_Conflict = {
  constraint: Parties_Constraint;
  update_columns?: Array<Parties_Update_Column>;
  where?: InputMaybe<Parties_Bool_Exp>;
};

/** Ordering options when selecting data from "parties". */
export type Parties_Order_By = {
  avatar?: InputMaybe<Order_By>;
  campaigns_aggregate?: InputMaybe<Campaigns_Aggregate_Order_By>;
  caseAssignmentsByAssignedTo_aggregate?: InputMaybe<Case_Assignments_Aggregate_Order_By>;
  caseMentionsByMentionedParty_aggregate?: InputMaybe<Case_Mentions_Aggregate_Order_By>;
  case_assignments_aggregate?: InputMaybe<Case_Assignments_Aggregate_Order_By>;
  case_mentions_aggregate?: InputMaybe<Case_Mentions_Aggregate_Order_By>;
  casesByPartyId_aggregate?: InputMaybe<Cases_Aggregate_Order_By>;
  cases_aggregate?: InputMaybe<Cases_Aggregate_Order_By>;
  channel_parties_aggregate?: InputMaybe<Channel_Parties_Aggregate_Order_By>;
  channel_subscriptions_aggregate?: InputMaybe<Channel_Subscriptions_Aggregate_Order_By>;
  created_at?: InputMaybe<Order_By>;
  credit_profiles_aggregate?: InputMaybe<Credit_Profiles_Aggregate_Order_By>;
  documents_aggregate?: InputMaybe<Documents_Aggregate_Order_By>;
  email?: InputMaybe<Order_By>;
  form?: InputMaybe<Forms_Order_By>;
  form_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  interactions_aggregate?: InputMaybe<Interactions_Aggregate_Order_By>;
  invoices_aggregate?: InputMaybe<Invoices_Aggregate_Order_By>;
  locale?: InputMaybe<Order_By>;
  location?: InputMaybe<Order_By>;
  messages_aggregate?: InputMaybe<Messages_Aggregate_Order_By>;
  metadata?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  nickname?: InputMaybe<Order_By>;
  organisation_id?: InputMaybe<Order_By>;
  partyRelationshipsByParticipant_aggregate?: InputMaybe<Party_Relationships_Aggregate_Order_By>;
  party_contacts_aggregate?: InputMaybe<Party_Contacts_Aggregate_Order_By>;
  party_documents_aggregate?: InputMaybe<Party_Documents_Aggregate_Order_By>;
  party_flags_aggregate?: InputMaybe<Party_Flags_Aggregate_Order_By>;
  party_interactions_aggregate?: InputMaybe<Party_Interactions_Aggregate_Order_By>;
  party_ledger_accounts_aggregate?: InputMaybe<Party_Ledger_Accounts_Aggregate_Order_By>;
  party_number?: InputMaybe<Order_By>;
  party_number_schema?: InputMaybe<Order_By>;
  party_relationships_aggregate?: InputMaybe<Party_Relationships_Aggregate_Order_By>;
  party_settings_aggregate?: InputMaybe<Party_Settings_Aggregate_Order_By>;
  party_statuses_aggregate?: InputMaybe<Party_Statuses_Aggregate_Order_By>;
  performanceTargetsByAssignedTo_aggregate?: InputMaybe<Performance_Targets_Aggregate_Order_By>;
  performance_targets_aggregate?: InputMaybe<Performance_Targets_Aggregate_Order_By>;
  phone_number?: InputMaybe<Order_By>;
  reward_redemptions_aggregate?: InputMaybe<Reward_Redemptions_Aggregate_Order_By>;
  subscriptionPaymentsByRecordedBy_aggregate?: InputMaybe<Subscription_Payments_Aggregate_Order_By>;
  subscriptionPaymentsByUpdatedBy_aggregate?: InputMaybe<Subscription_Payments_Aggregate_Order_By>;
  subscription_payment_transactions_aggregate?: InputMaybe<Subscription_Payment_Transactions_Aggregate_Order_By>;
  subscription_payments_aggregate?: InputMaybe<Subscription_Payments_Aggregate_Order_By>;
  subscriptions_aggregate?: InputMaybe<Subscriptions_Aggregate_Order_By>;
  taskAssignmentsByAssignedTo_aggregate?: InputMaybe<Task_Assignments_Aggregate_Order_By>;
  task_assignments_aggregate?: InputMaybe<Task_Assignments_Aggregate_Order_By>;
  task_mentions_aggregate?: InputMaybe<Task_Mentions_Aggregate_Order_By>;
  tasksByAssignedTo_aggregate?: InputMaybe<Tasks_Aggregate_Order_By>;
  tasks_aggregate?: InputMaybe<Tasks_Aggregate_Order_By>;
  territories_aggregate?: InputMaybe<Territories_Aggregate_Order_By>;
  territoryAssignmentsByAssignedTo_aggregate?: InputMaybe<Territory_Assignments_Aggregate_Order_By>;
  territory_assignments_aggregate?: InputMaybe<Territory_Assignments_Aggregate_Order_By>;
  territory_members_aggregate?: InputMaybe<Territory_Members_Aggregate_Order_By>;
  type?: InputMaybe<Types_Order_By>;
  type_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: parties */
export type Parties_Pk_Columns_Input = {
  id: Scalars["uuid"];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Parties_Prepend_Input = {
  metadata?: InputMaybe<Scalars["jsonb"]>;
};

/** select columns of table "parties" */
export enum Parties_Select_Column {
  /** column name */
  Avatar = "avatar",
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  Email = "email",
  /** column name */
  FormId = "form_id",
  /** column name */
  Id = "id",
  /** column name */
  Locale = "locale",
  /** column name */
  Location = "location",
  /** column name */
  Metadata = "metadata",
  /** column name */
  Name = "name",
  /** column name */
  Nickname = "nickname",
  /** column name */
  OrganisationId = "organisation_id",
  /** column name */
  PartyNumber = "party_number",
  /** column name */
  PartyNumberSchema = "party_number_schema",
  /** column name */
  PhoneNumber = "phone_number",
  /** column name */
  TypeId = "type_id",
  /** column name */
  UpdatedAt = "updated_at",
}

/** input type for updating data in table "parties" */
export type Parties_Set_Input = {
  avatar?: InputMaybe<Scalars["String"]>;
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  email?: InputMaybe<Scalars["String"]>;
  form_id?: InputMaybe<Scalars["uuid"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  locale?: InputMaybe<Scalars["String"]>;
  location?: InputMaybe<Scalars["String"]>;
  metadata?: InputMaybe<Scalars["jsonb"]>;
  name?: InputMaybe<Scalars["String"]>;
  nickname?: InputMaybe<Scalars["String"]>;
  organisation_id?: InputMaybe<Scalars["uuid"]>;
  party_number?: InputMaybe<Scalars["String"]>;
  party_number_schema?: InputMaybe<Scalars["String"]>;
  phone_number?: InputMaybe<Scalars["String"]>;
  type_id?: InputMaybe<Scalars["uuid"]>;
  updated_at?: InputMaybe<Scalars["timestamptz"]>;
};

/** Streaming cursor of the table "parties" */
export type Parties_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Parties_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Parties_Stream_Cursor_Value_Input = {
  avatar?: InputMaybe<Scalars["String"]>;
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  email?: InputMaybe<Scalars["String"]>;
  form_id?: InputMaybe<Scalars["uuid"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  locale?: InputMaybe<Scalars["String"]>;
  location?: InputMaybe<Scalars["String"]>;
  metadata?: InputMaybe<Scalars["jsonb"]>;
  name?: InputMaybe<Scalars["String"]>;
  nickname?: InputMaybe<Scalars["String"]>;
  organisation_id?: InputMaybe<Scalars["uuid"]>;
  party_number?: InputMaybe<Scalars["String"]>;
  party_number_schema?: InputMaybe<Scalars["String"]>;
  phone_number?: InputMaybe<Scalars["String"]>;
  type_id?: InputMaybe<Scalars["uuid"]>;
  updated_at?: InputMaybe<Scalars["timestamptz"]>;
};

/** update columns of table "parties" */
export enum Parties_Update_Column {
  /** column name */
  Avatar = "avatar",
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  Email = "email",
  /** column name */
  FormId = "form_id",
  /** column name */
  Id = "id",
  /** column name */
  Locale = "locale",
  /** column name */
  Location = "location",
  /** column name */
  Metadata = "metadata",
  /** column name */
  Name = "name",
  /** column name */
  Nickname = "nickname",
  /** column name */
  OrganisationId = "organisation_id",
  /** column name */
  PartyNumber = "party_number",
  /** column name */
  PartyNumberSchema = "party_number_schema",
  /** column name */
  PhoneNumber = "phone_number",
  /** column name */
  TypeId = "type_id",
  /** column name */
  UpdatedAt = "updated_at",
}

export type Parties_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Parties_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Parties_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Parties_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Parties_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Parties_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Parties_Set_Input>;
  /** filter the rows which have to be updated */
  where: Parties_Bool_Exp;
};

/** columns and relationships of "party_contacts" */
export type Party_Contacts = {
  __typename?: "party_contacts";
  /** An object relationship */
  contact: Contacts;
  contact_id: Scalars["uuid"];
  created_at?: Maybe<Scalars["timestamptz"]>;
  id: Scalars["uuid"];
  /** An object relationship */
  party: Parties;
  party_id: Scalars["uuid"];
};

/** aggregated selection of "party_contacts" */
export type Party_Contacts_Aggregate = {
  __typename?: "party_contacts_aggregate";
  aggregate?: Maybe<Party_Contacts_Aggregate_Fields>;
  nodes: Array<Party_Contacts>;
};

export type Party_Contacts_Aggregate_Bool_Exp = {
  count?: InputMaybe<Party_Contacts_Aggregate_Bool_Exp_Count>;
};

export type Party_Contacts_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Party_Contacts_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<Party_Contacts_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "party_contacts" */
export type Party_Contacts_Aggregate_Fields = {
  __typename?: "party_contacts_aggregate_fields";
  count: Scalars["Int"];
  max?: Maybe<Party_Contacts_Max_Fields>;
  min?: Maybe<Party_Contacts_Min_Fields>;
};

/** aggregate fields of "party_contacts" */
export type Party_Contacts_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Party_Contacts_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "party_contacts" */
export type Party_Contacts_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Party_Contacts_Max_Order_By>;
  min?: InputMaybe<Party_Contacts_Min_Order_By>;
};

/** input type for inserting array relation for remote table "party_contacts" */
export type Party_Contacts_Arr_Rel_Insert_Input = {
  data: Array<Party_Contacts_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Party_Contacts_On_Conflict>;
};

/** Boolean expression to filter rows from the table "party_contacts". All fields are combined with a logical 'AND'. */
export type Party_Contacts_Bool_Exp = {
  _and?: InputMaybe<Array<Party_Contacts_Bool_Exp>>;
  _not?: InputMaybe<Party_Contacts_Bool_Exp>;
  _or?: InputMaybe<Array<Party_Contacts_Bool_Exp>>;
  contact?: InputMaybe<Contacts_Bool_Exp>;
  contact_id?: InputMaybe<Uuid_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  party?: InputMaybe<Parties_Bool_Exp>;
  party_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "party_contacts" */
export enum Party_Contacts_Constraint {
  /** unique or primary key constraint on columns "id" */
  PartyContactsPkey = "party_contacts_pkey",
}

/** input type for inserting data into table "party_contacts" */
export type Party_Contacts_Insert_Input = {
  contact?: InputMaybe<Contacts_Obj_Rel_Insert_Input>;
  contact_id?: InputMaybe<Scalars["uuid"]>;
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  party?: InputMaybe<Parties_Obj_Rel_Insert_Input>;
  party_id?: InputMaybe<Scalars["uuid"]>;
};

/** aggregate max on columns */
export type Party_Contacts_Max_Fields = {
  __typename?: "party_contacts_max_fields";
  contact_id?: Maybe<Scalars["uuid"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["uuid"]>;
  party_id?: Maybe<Scalars["uuid"]>;
};

/** order by max() on columns of table "party_contacts" */
export type Party_Contacts_Max_Order_By = {
  contact_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  party_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Party_Contacts_Min_Fields = {
  __typename?: "party_contacts_min_fields";
  contact_id?: Maybe<Scalars["uuid"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["uuid"]>;
  party_id?: Maybe<Scalars["uuid"]>;
};

/** order by min() on columns of table "party_contacts" */
export type Party_Contacts_Min_Order_By = {
  contact_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  party_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "party_contacts" */
export type Party_Contacts_Mutation_Response = {
  __typename?: "party_contacts_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Party_Contacts>;
};

/** on_conflict condition type for table "party_contacts" */
export type Party_Contacts_On_Conflict = {
  constraint: Party_Contacts_Constraint;
  update_columns?: Array<Party_Contacts_Update_Column>;
  where?: InputMaybe<Party_Contacts_Bool_Exp>;
};

/** Ordering options when selecting data from "party_contacts". */
export type Party_Contacts_Order_By = {
  contact?: InputMaybe<Contacts_Order_By>;
  contact_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  party?: InputMaybe<Parties_Order_By>;
  party_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: party_contacts */
export type Party_Contacts_Pk_Columns_Input = {
  id: Scalars["uuid"];
};

/** select columns of table "party_contacts" */
export enum Party_Contacts_Select_Column {
  /** column name */
  ContactId = "contact_id",
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  Id = "id",
  /** column name */
  PartyId = "party_id",
}

/** input type for updating data in table "party_contacts" */
export type Party_Contacts_Set_Input = {
  contact_id?: InputMaybe<Scalars["uuid"]>;
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  party_id?: InputMaybe<Scalars["uuid"]>;
};

/** Streaming cursor of the table "party_contacts" */
export type Party_Contacts_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Party_Contacts_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Party_Contacts_Stream_Cursor_Value_Input = {
  contact_id?: InputMaybe<Scalars["uuid"]>;
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  party_id?: InputMaybe<Scalars["uuid"]>;
};

/** update columns of table "party_contacts" */
export enum Party_Contacts_Update_Column {
  /** column name */
  ContactId = "contact_id",
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  Id = "id",
  /** column name */
  PartyId = "party_id",
}

export type Party_Contacts_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Party_Contacts_Set_Input>;
  /** filter the rows which have to be updated */
  where: Party_Contacts_Bool_Exp;
};

/** columns and relationships of "party_documents" */
export type Party_Documents = {
  __typename?: "party_documents";
  created_at?: Maybe<Scalars["timestamptz"]>;
  /** An object relationship */
  document: Documents;
  document_id: Scalars["uuid"];
  id: Scalars["uuid"];
  /** An object relationship */
  party: Parties;
  party_id: Scalars["uuid"];
};

/** aggregated selection of "party_documents" */
export type Party_Documents_Aggregate = {
  __typename?: "party_documents_aggregate";
  aggregate?: Maybe<Party_Documents_Aggregate_Fields>;
  nodes: Array<Party_Documents>;
};

export type Party_Documents_Aggregate_Bool_Exp = {
  count?: InputMaybe<Party_Documents_Aggregate_Bool_Exp_Count>;
};

export type Party_Documents_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Party_Documents_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<Party_Documents_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "party_documents" */
export type Party_Documents_Aggregate_Fields = {
  __typename?: "party_documents_aggregate_fields";
  count: Scalars["Int"];
  max?: Maybe<Party_Documents_Max_Fields>;
  min?: Maybe<Party_Documents_Min_Fields>;
};

/** aggregate fields of "party_documents" */
export type Party_Documents_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Party_Documents_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "party_documents" */
export type Party_Documents_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Party_Documents_Max_Order_By>;
  min?: InputMaybe<Party_Documents_Min_Order_By>;
};

/** input type for inserting array relation for remote table "party_documents" */
export type Party_Documents_Arr_Rel_Insert_Input = {
  data: Array<Party_Documents_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Party_Documents_On_Conflict>;
};

/** Boolean expression to filter rows from the table "party_documents". All fields are combined with a logical 'AND'. */
export type Party_Documents_Bool_Exp = {
  _and?: InputMaybe<Array<Party_Documents_Bool_Exp>>;
  _not?: InputMaybe<Party_Documents_Bool_Exp>;
  _or?: InputMaybe<Array<Party_Documents_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  document?: InputMaybe<Documents_Bool_Exp>;
  document_id?: InputMaybe<Uuid_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  party?: InputMaybe<Parties_Bool_Exp>;
  party_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "party_documents" */
export enum Party_Documents_Constraint {
  /** unique or primary key constraint on columns "id" */
  PartyDocumentsPkey = "party_documents_pkey",
}

/** input type for inserting data into table "party_documents" */
export type Party_Documents_Insert_Input = {
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  document?: InputMaybe<Documents_Obj_Rel_Insert_Input>;
  document_id?: InputMaybe<Scalars["uuid"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  party?: InputMaybe<Parties_Obj_Rel_Insert_Input>;
  party_id?: InputMaybe<Scalars["uuid"]>;
};

/** aggregate max on columns */
export type Party_Documents_Max_Fields = {
  __typename?: "party_documents_max_fields";
  created_at?: Maybe<Scalars["timestamptz"]>;
  document_id?: Maybe<Scalars["uuid"]>;
  id?: Maybe<Scalars["uuid"]>;
  party_id?: Maybe<Scalars["uuid"]>;
};

/** order by max() on columns of table "party_documents" */
export type Party_Documents_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  document_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  party_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Party_Documents_Min_Fields = {
  __typename?: "party_documents_min_fields";
  created_at?: Maybe<Scalars["timestamptz"]>;
  document_id?: Maybe<Scalars["uuid"]>;
  id?: Maybe<Scalars["uuid"]>;
  party_id?: Maybe<Scalars["uuid"]>;
};

/** order by min() on columns of table "party_documents" */
export type Party_Documents_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  document_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  party_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "party_documents" */
export type Party_Documents_Mutation_Response = {
  __typename?: "party_documents_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Party_Documents>;
};

/** on_conflict condition type for table "party_documents" */
export type Party_Documents_On_Conflict = {
  constraint: Party_Documents_Constraint;
  update_columns?: Array<Party_Documents_Update_Column>;
  where?: InputMaybe<Party_Documents_Bool_Exp>;
};

/** Ordering options when selecting data from "party_documents". */
export type Party_Documents_Order_By = {
  created_at?: InputMaybe<Order_By>;
  document?: InputMaybe<Documents_Order_By>;
  document_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  party?: InputMaybe<Parties_Order_By>;
  party_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: party_documents */
export type Party_Documents_Pk_Columns_Input = {
  id: Scalars["uuid"];
};

/** select columns of table "party_documents" */
export enum Party_Documents_Select_Column {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  DocumentId = "document_id",
  /** column name */
  Id = "id",
  /** column name */
  PartyId = "party_id",
}

/** input type for updating data in table "party_documents" */
export type Party_Documents_Set_Input = {
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  document_id?: InputMaybe<Scalars["uuid"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  party_id?: InputMaybe<Scalars["uuid"]>;
};

/** Streaming cursor of the table "party_documents" */
export type Party_Documents_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Party_Documents_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Party_Documents_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  document_id?: InputMaybe<Scalars["uuid"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  party_id?: InputMaybe<Scalars["uuid"]>;
};

/** update columns of table "party_documents" */
export enum Party_Documents_Update_Column {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  DocumentId = "document_id",
  /** column name */
  Id = "id",
  /** column name */
  PartyId = "party_id",
}

export type Party_Documents_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Party_Documents_Set_Input>;
  /** filter the rows which have to be updated */
  where: Party_Documents_Bool_Exp;
};

/** columns and relationships of "party_flags" */
export type Party_Flags = {
  __typename?: "party_flags";
  created_at?: Maybe<Scalars["timestamptz"]>;
  /** An object relationship */
  flag: Flags;
  flag_id: Scalars["uuid"];
  id: Scalars["uuid"];
  /** An object relationship */
  party: Parties;
  party_id: Scalars["uuid"];
};

/** aggregated selection of "party_flags" */
export type Party_Flags_Aggregate = {
  __typename?: "party_flags_aggregate";
  aggregate?: Maybe<Party_Flags_Aggregate_Fields>;
  nodes: Array<Party_Flags>;
};

export type Party_Flags_Aggregate_Bool_Exp = {
  count?: InputMaybe<Party_Flags_Aggregate_Bool_Exp_Count>;
};

export type Party_Flags_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Party_Flags_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<Party_Flags_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "party_flags" */
export type Party_Flags_Aggregate_Fields = {
  __typename?: "party_flags_aggregate_fields";
  count: Scalars["Int"];
  max?: Maybe<Party_Flags_Max_Fields>;
  min?: Maybe<Party_Flags_Min_Fields>;
};

/** aggregate fields of "party_flags" */
export type Party_Flags_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Party_Flags_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "party_flags" */
export type Party_Flags_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Party_Flags_Max_Order_By>;
  min?: InputMaybe<Party_Flags_Min_Order_By>;
};

/** input type for inserting array relation for remote table "party_flags" */
export type Party_Flags_Arr_Rel_Insert_Input = {
  data: Array<Party_Flags_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Party_Flags_On_Conflict>;
};

/** Boolean expression to filter rows from the table "party_flags". All fields are combined with a logical 'AND'. */
export type Party_Flags_Bool_Exp = {
  _and?: InputMaybe<Array<Party_Flags_Bool_Exp>>;
  _not?: InputMaybe<Party_Flags_Bool_Exp>;
  _or?: InputMaybe<Array<Party_Flags_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  flag?: InputMaybe<Flags_Bool_Exp>;
  flag_id?: InputMaybe<Uuid_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  party?: InputMaybe<Parties_Bool_Exp>;
  party_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "party_flags" */
export enum Party_Flags_Constraint {
  /** unique or primary key constraint on columns "id" */
  PartyFlagsPkey = "party_flags_pkey",
}

/** input type for inserting data into table "party_flags" */
export type Party_Flags_Insert_Input = {
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  flag?: InputMaybe<Flags_Obj_Rel_Insert_Input>;
  flag_id?: InputMaybe<Scalars["uuid"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  party?: InputMaybe<Parties_Obj_Rel_Insert_Input>;
  party_id?: InputMaybe<Scalars["uuid"]>;
};

/** aggregate max on columns */
export type Party_Flags_Max_Fields = {
  __typename?: "party_flags_max_fields";
  created_at?: Maybe<Scalars["timestamptz"]>;
  flag_id?: Maybe<Scalars["uuid"]>;
  id?: Maybe<Scalars["uuid"]>;
  party_id?: Maybe<Scalars["uuid"]>;
};

/** order by max() on columns of table "party_flags" */
export type Party_Flags_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  flag_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  party_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Party_Flags_Min_Fields = {
  __typename?: "party_flags_min_fields";
  created_at?: Maybe<Scalars["timestamptz"]>;
  flag_id?: Maybe<Scalars["uuid"]>;
  id?: Maybe<Scalars["uuid"]>;
  party_id?: Maybe<Scalars["uuid"]>;
};

/** order by min() on columns of table "party_flags" */
export type Party_Flags_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  flag_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  party_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "party_flags" */
export type Party_Flags_Mutation_Response = {
  __typename?: "party_flags_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Party_Flags>;
};

/** on_conflict condition type for table "party_flags" */
export type Party_Flags_On_Conflict = {
  constraint: Party_Flags_Constraint;
  update_columns?: Array<Party_Flags_Update_Column>;
  where?: InputMaybe<Party_Flags_Bool_Exp>;
};

/** Ordering options when selecting data from "party_flags". */
export type Party_Flags_Order_By = {
  created_at?: InputMaybe<Order_By>;
  flag?: InputMaybe<Flags_Order_By>;
  flag_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  party?: InputMaybe<Parties_Order_By>;
  party_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: party_flags */
export type Party_Flags_Pk_Columns_Input = {
  id: Scalars["uuid"];
};

/** select columns of table "party_flags" */
export enum Party_Flags_Select_Column {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  FlagId = "flag_id",
  /** column name */
  Id = "id",
  /** column name */
  PartyId = "party_id",
}

/** input type for updating data in table "party_flags" */
export type Party_Flags_Set_Input = {
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  flag_id?: InputMaybe<Scalars["uuid"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  party_id?: InputMaybe<Scalars["uuid"]>;
};

/** Streaming cursor of the table "party_flags" */
export type Party_Flags_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Party_Flags_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Party_Flags_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  flag_id?: InputMaybe<Scalars["uuid"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  party_id?: InputMaybe<Scalars["uuid"]>;
};

/** update columns of table "party_flags" */
export enum Party_Flags_Update_Column {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  FlagId = "flag_id",
  /** column name */
  Id = "id",
  /** column name */
  PartyId = "party_id",
}

export type Party_Flags_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Party_Flags_Set_Input>;
  /** filter the rows which have to be updated */
  where: Party_Flags_Bool_Exp;
};

/** columns and relationships of "party_interactions" */
export type Party_Interactions = {
  __typename?: "party_interactions";
  created_at?: Maybe<Scalars["timestamptz"]>;
  id: Scalars["uuid"];
  /** An object relationship */
  interaction: Interactions;
  interaction_id: Scalars["uuid"];
  /** An object relationship */
  party: Parties;
  party_id: Scalars["uuid"];
};

/** aggregated selection of "party_interactions" */
export type Party_Interactions_Aggregate = {
  __typename?: "party_interactions_aggregate";
  aggregate?: Maybe<Party_Interactions_Aggregate_Fields>;
  nodes: Array<Party_Interactions>;
};

export type Party_Interactions_Aggregate_Bool_Exp = {
  count?: InputMaybe<Party_Interactions_Aggregate_Bool_Exp_Count>;
};

export type Party_Interactions_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Party_Interactions_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<Party_Interactions_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "party_interactions" */
export type Party_Interactions_Aggregate_Fields = {
  __typename?: "party_interactions_aggregate_fields";
  count: Scalars["Int"];
  max?: Maybe<Party_Interactions_Max_Fields>;
  min?: Maybe<Party_Interactions_Min_Fields>;
};

/** aggregate fields of "party_interactions" */
export type Party_Interactions_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Party_Interactions_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "party_interactions" */
export type Party_Interactions_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Party_Interactions_Max_Order_By>;
  min?: InputMaybe<Party_Interactions_Min_Order_By>;
};

/** input type for inserting array relation for remote table "party_interactions" */
export type Party_Interactions_Arr_Rel_Insert_Input = {
  data: Array<Party_Interactions_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Party_Interactions_On_Conflict>;
};

/** Boolean expression to filter rows from the table "party_interactions". All fields are combined with a logical 'AND'. */
export type Party_Interactions_Bool_Exp = {
  _and?: InputMaybe<Array<Party_Interactions_Bool_Exp>>;
  _not?: InputMaybe<Party_Interactions_Bool_Exp>;
  _or?: InputMaybe<Array<Party_Interactions_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  interaction?: InputMaybe<Interactions_Bool_Exp>;
  interaction_id?: InputMaybe<Uuid_Comparison_Exp>;
  party?: InputMaybe<Parties_Bool_Exp>;
  party_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "party_interactions" */
export enum Party_Interactions_Constraint {
  /** unique or primary key constraint on columns "id" */
  PartyInteractionsPkey = "party_interactions_pkey",
}

/** input type for inserting data into table "party_interactions" */
export type Party_Interactions_Insert_Input = {
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  interaction?: InputMaybe<Interactions_Obj_Rel_Insert_Input>;
  interaction_id?: InputMaybe<Scalars["uuid"]>;
  party?: InputMaybe<Parties_Obj_Rel_Insert_Input>;
  party_id?: InputMaybe<Scalars["uuid"]>;
};

/** aggregate max on columns */
export type Party_Interactions_Max_Fields = {
  __typename?: "party_interactions_max_fields";
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["uuid"]>;
  interaction_id?: Maybe<Scalars["uuid"]>;
  party_id?: Maybe<Scalars["uuid"]>;
};

/** order by max() on columns of table "party_interactions" */
export type Party_Interactions_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  interaction_id?: InputMaybe<Order_By>;
  party_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Party_Interactions_Min_Fields = {
  __typename?: "party_interactions_min_fields";
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["uuid"]>;
  interaction_id?: Maybe<Scalars["uuid"]>;
  party_id?: Maybe<Scalars["uuid"]>;
};

/** order by min() on columns of table "party_interactions" */
export type Party_Interactions_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  interaction_id?: InputMaybe<Order_By>;
  party_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "party_interactions" */
export type Party_Interactions_Mutation_Response = {
  __typename?: "party_interactions_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Party_Interactions>;
};

/** on_conflict condition type for table "party_interactions" */
export type Party_Interactions_On_Conflict = {
  constraint: Party_Interactions_Constraint;
  update_columns?: Array<Party_Interactions_Update_Column>;
  where?: InputMaybe<Party_Interactions_Bool_Exp>;
};

/** Ordering options when selecting data from "party_interactions". */
export type Party_Interactions_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  interaction?: InputMaybe<Interactions_Order_By>;
  interaction_id?: InputMaybe<Order_By>;
  party?: InputMaybe<Parties_Order_By>;
  party_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: party_interactions */
export type Party_Interactions_Pk_Columns_Input = {
  id: Scalars["uuid"];
};

/** select columns of table "party_interactions" */
export enum Party_Interactions_Select_Column {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  Id = "id",
  /** column name */
  InteractionId = "interaction_id",
  /** column name */
  PartyId = "party_id",
}

/** input type for updating data in table "party_interactions" */
export type Party_Interactions_Set_Input = {
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  interaction_id?: InputMaybe<Scalars["uuid"]>;
  party_id?: InputMaybe<Scalars["uuid"]>;
};

/** Streaming cursor of the table "party_interactions" */
export type Party_Interactions_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Party_Interactions_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Party_Interactions_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  interaction_id?: InputMaybe<Scalars["uuid"]>;
  party_id?: InputMaybe<Scalars["uuid"]>;
};

/** update columns of table "party_interactions" */
export enum Party_Interactions_Update_Column {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  Id = "id",
  /** column name */
  InteractionId = "interaction_id",
  /** column name */
  PartyId = "party_id",
}

export type Party_Interactions_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Party_Interactions_Set_Input>;
  /** filter the rows which have to be updated */
  where: Party_Interactions_Bool_Exp;
};

/** columns and relationships of "party_ledger_accounts" */
export type Party_Ledger_Accounts = {
  __typename?: "party_ledger_accounts";
  created_at?: Maybe<Scalars["timestamptz"]>;
  id: Scalars["uuid"];
  ledger_account_id: Scalars["uuid"];
  ledger_id: Scalars["uuid"];
  party_id: Scalars["uuid"];
};

/** aggregated selection of "party_ledger_accounts" */
export type Party_Ledger_Accounts_Aggregate = {
  __typename?: "party_ledger_accounts_aggregate";
  aggregate?: Maybe<Party_Ledger_Accounts_Aggregate_Fields>;
  nodes: Array<Party_Ledger_Accounts>;
};

export type Party_Ledger_Accounts_Aggregate_Bool_Exp = {
  count?: InputMaybe<Party_Ledger_Accounts_Aggregate_Bool_Exp_Count>;
};

export type Party_Ledger_Accounts_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Party_Ledger_Accounts_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<Party_Ledger_Accounts_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "party_ledger_accounts" */
export type Party_Ledger_Accounts_Aggregate_Fields = {
  __typename?: "party_ledger_accounts_aggregate_fields";
  count: Scalars["Int"];
  max?: Maybe<Party_Ledger_Accounts_Max_Fields>;
  min?: Maybe<Party_Ledger_Accounts_Min_Fields>;
};

/** aggregate fields of "party_ledger_accounts" */
export type Party_Ledger_Accounts_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Party_Ledger_Accounts_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "party_ledger_accounts" */
export type Party_Ledger_Accounts_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Party_Ledger_Accounts_Max_Order_By>;
  min?: InputMaybe<Party_Ledger_Accounts_Min_Order_By>;
};

/** input type for inserting array relation for remote table "party_ledger_accounts" */
export type Party_Ledger_Accounts_Arr_Rel_Insert_Input = {
  data: Array<Party_Ledger_Accounts_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Party_Ledger_Accounts_On_Conflict>;
};

/** Boolean expression to filter rows from the table "party_ledger_accounts". All fields are combined with a logical 'AND'. */
export type Party_Ledger_Accounts_Bool_Exp = {
  _and?: InputMaybe<Array<Party_Ledger_Accounts_Bool_Exp>>;
  _not?: InputMaybe<Party_Ledger_Accounts_Bool_Exp>;
  _or?: InputMaybe<Array<Party_Ledger_Accounts_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  ledger_account_id?: InputMaybe<Uuid_Comparison_Exp>;
  ledger_id?: InputMaybe<Uuid_Comparison_Exp>;
  party_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "party_ledger_accounts" */
export enum Party_Ledger_Accounts_Constraint {
  /** unique or primary key constraint on columns "id" */
  PartyLedgerAccountsPkey = "party_ledger_accounts_pkey",
  /** unique or primary key constraint on columns "ledger_id", "ledger_account_id", "party_id" */
  UniquePartyLedgerAccount = "unique_party_ledger_account",
}

/** input type for inserting data into table "party_ledger_accounts" */
export type Party_Ledger_Accounts_Insert_Input = {
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  ledger_account_id?: InputMaybe<Scalars["uuid"]>;
  ledger_id?: InputMaybe<Scalars["uuid"]>;
  party_id?: InputMaybe<Scalars["uuid"]>;
};

/** aggregate max on columns */
export type Party_Ledger_Accounts_Max_Fields = {
  __typename?: "party_ledger_accounts_max_fields";
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["uuid"]>;
  ledger_account_id?: Maybe<Scalars["uuid"]>;
  ledger_id?: Maybe<Scalars["uuid"]>;
  party_id?: Maybe<Scalars["uuid"]>;
};

/** order by max() on columns of table "party_ledger_accounts" */
export type Party_Ledger_Accounts_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  ledger_account_id?: InputMaybe<Order_By>;
  ledger_id?: InputMaybe<Order_By>;
  party_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Party_Ledger_Accounts_Min_Fields = {
  __typename?: "party_ledger_accounts_min_fields";
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["uuid"]>;
  ledger_account_id?: Maybe<Scalars["uuid"]>;
  ledger_id?: Maybe<Scalars["uuid"]>;
  party_id?: Maybe<Scalars["uuid"]>;
};

/** order by min() on columns of table "party_ledger_accounts" */
export type Party_Ledger_Accounts_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  ledger_account_id?: InputMaybe<Order_By>;
  ledger_id?: InputMaybe<Order_By>;
  party_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "party_ledger_accounts" */
export type Party_Ledger_Accounts_Mutation_Response = {
  __typename?: "party_ledger_accounts_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Party_Ledger_Accounts>;
};

/** on_conflict condition type for table "party_ledger_accounts" */
export type Party_Ledger_Accounts_On_Conflict = {
  constraint: Party_Ledger_Accounts_Constraint;
  update_columns?: Array<Party_Ledger_Accounts_Update_Column>;
  where?: InputMaybe<Party_Ledger_Accounts_Bool_Exp>;
};

/** Ordering options when selecting data from "party_ledger_accounts". */
export type Party_Ledger_Accounts_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  ledger_account_id?: InputMaybe<Order_By>;
  ledger_id?: InputMaybe<Order_By>;
  party_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: party_ledger_accounts */
export type Party_Ledger_Accounts_Pk_Columns_Input = {
  id: Scalars["uuid"];
};

/** select columns of table "party_ledger_accounts" */
export enum Party_Ledger_Accounts_Select_Column {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  Id = "id",
  /** column name */
  LedgerAccountId = "ledger_account_id",
  /** column name */
  LedgerId = "ledger_id",
  /** column name */
  PartyId = "party_id",
}

/** input type for updating data in table "party_ledger_accounts" */
export type Party_Ledger_Accounts_Set_Input = {
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  ledger_account_id?: InputMaybe<Scalars["uuid"]>;
  ledger_id?: InputMaybe<Scalars["uuid"]>;
  party_id?: InputMaybe<Scalars["uuid"]>;
};

/** Streaming cursor of the table "party_ledger_accounts" */
export type Party_Ledger_Accounts_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Party_Ledger_Accounts_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Party_Ledger_Accounts_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  ledger_account_id?: InputMaybe<Scalars["uuid"]>;
  ledger_id?: InputMaybe<Scalars["uuid"]>;
  party_id?: InputMaybe<Scalars["uuid"]>;
};

/** update columns of table "party_ledger_accounts" */
export enum Party_Ledger_Accounts_Update_Column {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  Id = "id",
  /** column name */
  LedgerAccountId = "ledger_account_id",
  /** column name */
  LedgerId = "ledger_id",
  /** column name */
  PartyId = "party_id",
}

export type Party_Ledger_Accounts_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Party_Ledger_Accounts_Set_Input>;
  /** filter the rows which have to be updated */
  where: Party_Ledger_Accounts_Bool_Exp;
};

/** columns and relationships of "party_relationship_statuses" */
export type Party_Relationship_Statuses = {
  __typename?: "party_relationship_statuses";
  created_at?: Maybe<Scalars["timestamptz"]>;
  id: Scalars["uuid"];
  /** An object relationship */
  party_relationship: Party_Relationships;
  party_relationship_id: Scalars["uuid"];
  /** An object relationship */
  status: Statuses;
  status_id: Scalars["uuid"];
};

/** aggregated selection of "party_relationship_statuses" */
export type Party_Relationship_Statuses_Aggregate = {
  __typename?: "party_relationship_statuses_aggregate";
  aggregate?: Maybe<Party_Relationship_Statuses_Aggregate_Fields>;
  nodes: Array<Party_Relationship_Statuses>;
};

export type Party_Relationship_Statuses_Aggregate_Bool_Exp = {
  count?: InputMaybe<Party_Relationship_Statuses_Aggregate_Bool_Exp_Count>;
};

export type Party_Relationship_Statuses_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Party_Relationship_Statuses_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<Party_Relationship_Statuses_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "party_relationship_statuses" */
export type Party_Relationship_Statuses_Aggregate_Fields = {
  __typename?: "party_relationship_statuses_aggregate_fields";
  count: Scalars["Int"];
  max?: Maybe<Party_Relationship_Statuses_Max_Fields>;
  min?: Maybe<Party_Relationship_Statuses_Min_Fields>;
};

/** aggregate fields of "party_relationship_statuses" */
export type Party_Relationship_Statuses_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Party_Relationship_Statuses_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "party_relationship_statuses" */
export type Party_Relationship_Statuses_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Party_Relationship_Statuses_Max_Order_By>;
  min?: InputMaybe<Party_Relationship_Statuses_Min_Order_By>;
};

/** input type for inserting array relation for remote table "party_relationship_statuses" */
export type Party_Relationship_Statuses_Arr_Rel_Insert_Input = {
  data: Array<Party_Relationship_Statuses_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Party_Relationship_Statuses_On_Conflict>;
};

/** Boolean expression to filter rows from the table "party_relationship_statuses". All fields are combined with a logical 'AND'. */
export type Party_Relationship_Statuses_Bool_Exp = {
  _and?: InputMaybe<Array<Party_Relationship_Statuses_Bool_Exp>>;
  _not?: InputMaybe<Party_Relationship_Statuses_Bool_Exp>;
  _or?: InputMaybe<Array<Party_Relationship_Statuses_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  party_relationship?: InputMaybe<Party_Relationships_Bool_Exp>;
  party_relationship_id?: InputMaybe<Uuid_Comparison_Exp>;
  status?: InputMaybe<Statuses_Bool_Exp>;
  status_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "party_relationship_statuses" */
export enum Party_Relationship_Statuses_Constraint {
  /** unique or primary key constraint on columns "id" */
  PartyRelationshipStatusesPkey = "party_relationship_statuses_pkey",
}

/** input type for inserting data into table "party_relationship_statuses" */
export type Party_Relationship_Statuses_Insert_Input = {
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  party_relationship?: InputMaybe<Party_Relationships_Obj_Rel_Insert_Input>;
  party_relationship_id?: InputMaybe<Scalars["uuid"]>;
  status?: InputMaybe<Statuses_Obj_Rel_Insert_Input>;
  status_id?: InputMaybe<Scalars["uuid"]>;
};

/** aggregate max on columns */
export type Party_Relationship_Statuses_Max_Fields = {
  __typename?: "party_relationship_statuses_max_fields";
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["uuid"]>;
  party_relationship_id?: Maybe<Scalars["uuid"]>;
  status_id?: Maybe<Scalars["uuid"]>;
};

/** order by max() on columns of table "party_relationship_statuses" */
export type Party_Relationship_Statuses_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  party_relationship_id?: InputMaybe<Order_By>;
  status_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Party_Relationship_Statuses_Min_Fields = {
  __typename?: "party_relationship_statuses_min_fields";
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["uuid"]>;
  party_relationship_id?: Maybe<Scalars["uuid"]>;
  status_id?: Maybe<Scalars["uuid"]>;
};

/** order by min() on columns of table "party_relationship_statuses" */
export type Party_Relationship_Statuses_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  party_relationship_id?: InputMaybe<Order_By>;
  status_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "party_relationship_statuses" */
export type Party_Relationship_Statuses_Mutation_Response = {
  __typename?: "party_relationship_statuses_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Party_Relationship_Statuses>;
};

/** on_conflict condition type for table "party_relationship_statuses" */
export type Party_Relationship_Statuses_On_Conflict = {
  constraint: Party_Relationship_Statuses_Constraint;
  update_columns?: Array<Party_Relationship_Statuses_Update_Column>;
  where?: InputMaybe<Party_Relationship_Statuses_Bool_Exp>;
};

/** Ordering options when selecting data from "party_relationship_statuses". */
export type Party_Relationship_Statuses_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  party_relationship?: InputMaybe<Party_Relationships_Order_By>;
  party_relationship_id?: InputMaybe<Order_By>;
  status?: InputMaybe<Statuses_Order_By>;
  status_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: party_relationship_statuses */
export type Party_Relationship_Statuses_Pk_Columns_Input = {
  id: Scalars["uuid"];
};

/** select columns of table "party_relationship_statuses" */
export enum Party_Relationship_Statuses_Select_Column {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  Id = "id",
  /** column name */
  PartyRelationshipId = "party_relationship_id",
  /** column name */
  StatusId = "status_id",
}

/** input type for updating data in table "party_relationship_statuses" */
export type Party_Relationship_Statuses_Set_Input = {
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  party_relationship_id?: InputMaybe<Scalars["uuid"]>;
  status_id?: InputMaybe<Scalars["uuid"]>;
};

/** Streaming cursor of the table "party_relationship_statuses" */
export type Party_Relationship_Statuses_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Party_Relationship_Statuses_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Party_Relationship_Statuses_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  party_relationship_id?: InputMaybe<Scalars["uuid"]>;
  status_id?: InputMaybe<Scalars["uuid"]>;
};

/** update columns of table "party_relationship_statuses" */
export enum Party_Relationship_Statuses_Update_Column {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  Id = "id",
  /** column name */
  PartyRelationshipId = "party_relationship_id",
  /** column name */
  StatusId = "status_id",
}

export type Party_Relationship_Statuses_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Party_Relationship_Statuses_Set_Input>;
  /** filter the rows which have to be updated */
  where: Party_Relationship_Statuses_Bool_Exp;
};

/** columns and relationships of "party_relationships" */
export type Party_Relationships = {
  __typename?: "party_relationships";
  created_at: Scalars["timestamptz"];
  id: Scalars["uuid"];
  name: Scalars["String"];
  owner: Scalars["uuid"];
  participant: Scalars["uuid"];
  /** An object relationship */
  party: Parties;
  /** An object relationship */
  partyByParticipant: Parties;
  /** An array relationship */
  party_relationship_statuses: Array<Party_Relationship_Statuses>;
  /** An aggregate relationship */
  party_relationship_statuses_aggregate: Party_Relationship_Statuses_Aggregate;
  /** An object relationship */
  type: Types;
  type_id: Scalars["uuid"];
  updated_at: Scalars["timestamptz"];
};

/** columns and relationships of "party_relationships" */
export type Party_RelationshipsParty_Relationship_StatusesArgs = {
  distinct_on?: InputMaybe<Array<Party_Relationship_Statuses_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Party_Relationship_Statuses_Order_By>>;
  where?: InputMaybe<Party_Relationship_Statuses_Bool_Exp>;
};

/** columns and relationships of "party_relationships" */
export type Party_RelationshipsParty_Relationship_Statuses_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Party_Relationship_Statuses_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Party_Relationship_Statuses_Order_By>>;
  where?: InputMaybe<Party_Relationship_Statuses_Bool_Exp>;
};

/** aggregated selection of "party_relationships" */
export type Party_Relationships_Aggregate = {
  __typename?: "party_relationships_aggregate";
  aggregate?: Maybe<Party_Relationships_Aggregate_Fields>;
  nodes: Array<Party_Relationships>;
};

export type Party_Relationships_Aggregate_Bool_Exp = {
  count?: InputMaybe<Party_Relationships_Aggregate_Bool_Exp_Count>;
};

export type Party_Relationships_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Party_Relationships_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<Party_Relationships_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "party_relationships" */
export type Party_Relationships_Aggregate_Fields = {
  __typename?: "party_relationships_aggregate_fields";
  count: Scalars["Int"];
  max?: Maybe<Party_Relationships_Max_Fields>;
  min?: Maybe<Party_Relationships_Min_Fields>;
};

/** aggregate fields of "party_relationships" */
export type Party_Relationships_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Party_Relationships_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "party_relationships" */
export type Party_Relationships_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Party_Relationships_Max_Order_By>;
  min?: InputMaybe<Party_Relationships_Min_Order_By>;
};

/** input type for inserting array relation for remote table "party_relationships" */
export type Party_Relationships_Arr_Rel_Insert_Input = {
  data: Array<Party_Relationships_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Party_Relationships_On_Conflict>;
};

/** Boolean expression to filter rows from the table "party_relationships". All fields are combined with a logical 'AND'. */
export type Party_Relationships_Bool_Exp = {
  _and?: InputMaybe<Array<Party_Relationships_Bool_Exp>>;
  _not?: InputMaybe<Party_Relationships_Bool_Exp>;
  _or?: InputMaybe<Array<Party_Relationships_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  owner?: InputMaybe<Uuid_Comparison_Exp>;
  participant?: InputMaybe<Uuid_Comparison_Exp>;
  party?: InputMaybe<Parties_Bool_Exp>;
  partyByParticipant?: InputMaybe<Parties_Bool_Exp>;
  party_relationship_statuses?: InputMaybe<Party_Relationship_Statuses_Bool_Exp>;
  party_relationship_statuses_aggregate?: InputMaybe<Party_Relationship_Statuses_Aggregate_Bool_Exp>;
  type?: InputMaybe<Types_Bool_Exp>;
  type_id?: InputMaybe<Uuid_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "party_relationships" */
export enum Party_Relationships_Constraint {
  /** unique or primary key constraint on columns "id" */
  PartyRelationshipsPkey = "party_relationships_pkey",
}

/** input type for inserting data into table "party_relationships" */
export type Party_Relationships_Insert_Input = {
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  name?: InputMaybe<Scalars["String"]>;
  owner?: InputMaybe<Scalars["uuid"]>;
  participant?: InputMaybe<Scalars["uuid"]>;
  party?: InputMaybe<Parties_Obj_Rel_Insert_Input>;
  partyByParticipant?: InputMaybe<Parties_Obj_Rel_Insert_Input>;
  party_relationship_statuses?: InputMaybe<Party_Relationship_Statuses_Arr_Rel_Insert_Input>;
  type?: InputMaybe<Types_Obj_Rel_Insert_Input>;
  type_id?: InputMaybe<Scalars["uuid"]>;
  updated_at?: InputMaybe<Scalars["timestamptz"]>;
};

/** aggregate max on columns */
export type Party_Relationships_Max_Fields = {
  __typename?: "party_relationships_max_fields";
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["uuid"]>;
  name?: Maybe<Scalars["String"]>;
  owner?: Maybe<Scalars["uuid"]>;
  participant?: Maybe<Scalars["uuid"]>;
  type_id?: Maybe<Scalars["uuid"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
};

/** order by max() on columns of table "party_relationships" */
export type Party_Relationships_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  owner?: InputMaybe<Order_By>;
  participant?: InputMaybe<Order_By>;
  type_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Party_Relationships_Min_Fields = {
  __typename?: "party_relationships_min_fields";
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["uuid"]>;
  name?: Maybe<Scalars["String"]>;
  owner?: Maybe<Scalars["uuid"]>;
  participant?: Maybe<Scalars["uuid"]>;
  type_id?: Maybe<Scalars["uuid"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
};

/** order by min() on columns of table "party_relationships" */
export type Party_Relationships_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  owner?: InputMaybe<Order_By>;
  participant?: InputMaybe<Order_By>;
  type_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "party_relationships" */
export type Party_Relationships_Mutation_Response = {
  __typename?: "party_relationships_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Party_Relationships>;
};

/** input type for inserting object relation for remote table "party_relationships" */
export type Party_Relationships_Obj_Rel_Insert_Input = {
  data: Party_Relationships_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Party_Relationships_On_Conflict>;
};

/** on_conflict condition type for table "party_relationships" */
export type Party_Relationships_On_Conflict = {
  constraint: Party_Relationships_Constraint;
  update_columns?: Array<Party_Relationships_Update_Column>;
  where?: InputMaybe<Party_Relationships_Bool_Exp>;
};

/** Ordering options when selecting data from "party_relationships". */
export type Party_Relationships_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  owner?: InputMaybe<Order_By>;
  participant?: InputMaybe<Order_By>;
  party?: InputMaybe<Parties_Order_By>;
  partyByParticipant?: InputMaybe<Parties_Order_By>;
  party_relationship_statuses_aggregate?: InputMaybe<Party_Relationship_Statuses_Aggregate_Order_By>;
  type?: InputMaybe<Types_Order_By>;
  type_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: party_relationships */
export type Party_Relationships_Pk_Columns_Input = {
  id: Scalars["uuid"];
};

/** select columns of table "party_relationships" */
export enum Party_Relationships_Select_Column {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  Id = "id",
  /** column name */
  Name = "name",
  /** column name */
  Owner = "owner",
  /** column name */
  Participant = "participant",
  /** column name */
  TypeId = "type_id",
  /** column name */
  UpdatedAt = "updated_at",
}

/** input type for updating data in table "party_relationships" */
export type Party_Relationships_Set_Input = {
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  name?: InputMaybe<Scalars["String"]>;
  owner?: InputMaybe<Scalars["uuid"]>;
  participant?: InputMaybe<Scalars["uuid"]>;
  type_id?: InputMaybe<Scalars["uuid"]>;
  updated_at?: InputMaybe<Scalars["timestamptz"]>;
};

/** Streaming cursor of the table "party_relationships" */
export type Party_Relationships_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Party_Relationships_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Party_Relationships_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  name?: InputMaybe<Scalars["String"]>;
  owner?: InputMaybe<Scalars["uuid"]>;
  participant?: InputMaybe<Scalars["uuid"]>;
  type_id?: InputMaybe<Scalars["uuid"]>;
  updated_at?: InputMaybe<Scalars["timestamptz"]>;
};

/** update columns of table "party_relationships" */
export enum Party_Relationships_Update_Column {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  Id = "id",
  /** column name */
  Name = "name",
  /** column name */
  Owner = "owner",
  /** column name */
  Participant = "participant",
  /** column name */
  TypeId = "type_id",
  /** column name */
  UpdatedAt = "updated_at",
}

export type Party_Relationships_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Party_Relationships_Set_Input>;
  /** filter the rows which have to be updated */
  where: Party_Relationships_Bool_Exp;
};

/** columns and relationships of "party_settings" */
export type Party_Settings = {
  __typename?: "party_settings";
  created_at: Scalars["timestamptz"];
  id: Scalars["uuid"];
  is_active: Scalars["Boolean"];
  name: Scalars["String"];
  /** An object relationship */
  party: Parties;
  party_id: Scalars["uuid"];
  /** An object relationship */
  type: Types;
  type_id: Scalars["uuid"];
  updated_at: Scalars["timestamptz"];
  value?: Maybe<Scalars["String"]>;
};

/** aggregated selection of "party_settings" */
export type Party_Settings_Aggregate = {
  __typename?: "party_settings_aggregate";
  aggregate?: Maybe<Party_Settings_Aggregate_Fields>;
  nodes: Array<Party_Settings>;
};

export type Party_Settings_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Party_Settings_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Party_Settings_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Party_Settings_Aggregate_Bool_Exp_Count>;
};

export type Party_Settings_Aggregate_Bool_Exp_Bool_And = {
  arguments: Party_Settings_Select_Column_Party_Settings_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<Party_Settings_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Party_Settings_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Party_Settings_Select_Column_Party_Settings_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<Party_Settings_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Party_Settings_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Party_Settings_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<Party_Settings_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "party_settings" */
export type Party_Settings_Aggregate_Fields = {
  __typename?: "party_settings_aggregate_fields";
  count: Scalars["Int"];
  max?: Maybe<Party_Settings_Max_Fields>;
  min?: Maybe<Party_Settings_Min_Fields>;
};

/** aggregate fields of "party_settings" */
export type Party_Settings_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Party_Settings_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "party_settings" */
export type Party_Settings_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Party_Settings_Max_Order_By>;
  min?: InputMaybe<Party_Settings_Min_Order_By>;
};

/** input type for inserting array relation for remote table "party_settings" */
export type Party_Settings_Arr_Rel_Insert_Input = {
  data: Array<Party_Settings_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Party_Settings_On_Conflict>;
};

/** Boolean expression to filter rows from the table "party_settings". All fields are combined with a logical 'AND'. */
export type Party_Settings_Bool_Exp = {
  _and?: InputMaybe<Array<Party_Settings_Bool_Exp>>;
  _not?: InputMaybe<Party_Settings_Bool_Exp>;
  _or?: InputMaybe<Array<Party_Settings_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  is_active?: InputMaybe<Boolean_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  party?: InputMaybe<Parties_Bool_Exp>;
  party_id?: InputMaybe<Uuid_Comparison_Exp>;
  type?: InputMaybe<Types_Bool_Exp>;
  type_id?: InputMaybe<Uuid_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "party_settings" */
export enum Party_Settings_Constraint {
  /** unique or primary key constraint on columns "id" */
  PartySettingsPkey = "party_settings_pkey",
  /** unique or primary key constraint on columns "is_active", "name" */
  UniquePartySetting = "unique_party_setting",
}

/** input type for inserting data into table "party_settings" */
export type Party_Settings_Insert_Input = {
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  is_active?: InputMaybe<Scalars["Boolean"]>;
  name?: InputMaybe<Scalars["String"]>;
  party?: InputMaybe<Parties_Obj_Rel_Insert_Input>;
  party_id?: InputMaybe<Scalars["uuid"]>;
  type?: InputMaybe<Types_Obj_Rel_Insert_Input>;
  type_id?: InputMaybe<Scalars["uuid"]>;
  updated_at?: InputMaybe<Scalars["timestamptz"]>;
  value?: InputMaybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Party_Settings_Max_Fields = {
  __typename?: "party_settings_max_fields";
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["uuid"]>;
  name?: Maybe<Scalars["String"]>;
  party_id?: Maybe<Scalars["uuid"]>;
  type_id?: Maybe<Scalars["uuid"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
  value?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "party_settings" */
export type Party_Settings_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  party_id?: InputMaybe<Order_By>;
  type_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Party_Settings_Min_Fields = {
  __typename?: "party_settings_min_fields";
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["uuid"]>;
  name?: Maybe<Scalars["String"]>;
  party_id?: Maybe<Scalars["uuid"]>;
  type_id?: Maybe<Scalars["uuid"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
  value?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "party_settings" */
export type Party_Settings_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  party_id?: InputMaybe<Order_By>;
  type_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "party_settings" */
export type Party_Settings_Mutation_Response = {
  __typename?: "party_settings_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Party_Settings>;
};

/** on_conflict condition type for table "party_settings" */
export type Party_Settings_On_Conflict = {
  constraint: Party_Settings_Constraint;
  update_columns?: Array<Party_Settings_Update_Column>;
  where?: InputMaybe<Party_Settings_Bool_Exp>;
};

/** Ordering options when selecting data from "party_settings". */
export type Party_Settings_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  is_active?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  party?: InputMaybe<Parties_Order_By>;
  party_id?: InputMaybe<Order_By>;
  type?: InputMaybe<Types_Order_By>;
  type_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: party_settings */
export type Party_Settings_Pk_Columns_Input = {
  id: Scalars["uuid"];
};

/** select columns of table "party_settings" */
export enum Party_Settings_Select_Column {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  Id = "id",
  /** column name */
  IsActive = "is_active",
  /** column name */
  Name = "name",
  /** column name */
  PartyId = "party_id",
  /** column name */
  TypeId = "type_id",
  /** column name */
  UpdatedAt = "updated_at",
  /** column name */
  Value = "value",
}

/** select "party_settings_aggregate_bool_exp_bool_and_arguments_columns" columns of table "party_settings" */
export enum Party_Settings_Select_Column_Party_Settings_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  IsActive = "is_active",
}

/** select "party_settings_aggregate_bool_exp_bool_or_arguments_columns" columns of table "party_settings" */
export enum Party_Settings_Select_Column_Party_Settings_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  IsActive = "is_active",
}

/** input type for updating data in table "party_settings" */
export type Party_Settings_Set_Input = {
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  is_active?: InputMaybe<Scalars["Boolean"]>;
  name?: InputMaybe<Scalars["String"]>;
  party_id?: InputMaybe<Scalars["uuid"]>;
  type_id?: InputMaybe<Scalars["uuid"]>;
  updated_at?: InputMaybe<Scalars["timestamptz"]>;
  value?: InputMaybe<Scalars["String"]>;
};

/** Streaming cursor of the table "party_settings" */
export type Party_Settings_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Party_Settings_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Party_Settings_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  is_active?: InputMaybe<Scalars["Boolean"]>;
  name?: InputMaybe<Scalars["String"]>;
  party_id?: InputMaybe<Scalars["uuid"]>;
  type_id?: InputMaybe<Scalars["uuid"]>;
  updated_at?: InputMaybe<Scalars["timestamptz"]>;
  value?: InputMaybe<Scalars["String"]>;
};

/** update columns of table "party_settings" */
export enum Party_Settings_Update_Column {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  Id = "id",
  /** column name */
  IsActive = "is_active",
  /** column name */
  Name = "name",
  /** column name */
  PartyId = "party_id",
  /** column name */
  TypeId = "type_id",
  /** column name */
  UpdatedAt = "updated_at",
  /** column name */
  Value = "value",
}

export type Party_Settings_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Party_Settings_Set_Input>;
  /** filter the rows which have to be updated */
  where: Party_Settings_Bool_Exp;
};

/** columns and relationships of "party_statuses" */
export type Party_Statuses = {
  __typename?: "party_statuses";
  created_at?: Maybe<Scalars["timestamptz"]>;
  id: Scalars["uuid"];
  /** An object relationship */
  party: Parties;
  party_id: Scalars["uuid"];
  /** An object relationship */
  status: Statuses;
  status_id: Scalars["uuid"];
};

/** aggregated selection of "party_statuses" */
export type Party_Statuses_Aggregate = {
  __typename?: "party_statuses_aggregate";
  aggregate?: Maybe<Party_Statuses_Aggregate_Fields>;
  nodes: Array<Party_Statuses>;
};

export type Party_Statuses_Aggregate_Bool_Exp = {
  count?: InputMaybe<Party_Statuses_Aggregate_Bool_Exp_Count>;
};

export type Party_Statuses_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Party_Statuses_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<Party_Statuses_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "party_statuses" */
export type Party_Statuses_Aggregate_Fields = {
  __typename?: "party_statuses_aggregate_fields";
  count: Scalars["Int"];
  max?: Maybe<Party_Statuses_Max_Fields>;
  min?: Maybe<Party_Statuses_Min_Fields>;
};

/** aggregate fields of "party_statuses" */
export type Party_Statuses_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Party_Statuses_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "party_statuses" */
export type Party_Statuses_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Party_Statuses_Max_Order_By>;
  min?: InputMaybe<Party_Statuses_Min_Order_By>;
};

/** input type for inserting array relation for remote table "party_statuses" */
export type Party_Statuses_Arr_Rel_Insert_Input = {
  data: Array<Party_Statuses_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Party_Statuses_On_Conflict>;
};

/** Boolean expression to filter rows from the table "party_statuses". All fields are combined with a logical 'AND'. */
export type Party_Statuses_Bool_Exp = {
  _and?: InputMaybe<Array<Party_Statuses_Bool_Exp>>;
  _not?: InputMaybe<Party_Statuses_Bool_Exp>;
  _or?: InputMaybe<Array<Party_Statuses_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  party?: InputMaybe<Parties_Bool_Exp>;
  party_id?: InputMaybe<Uuid_Comparison_Exp>;
  status?: InputMaybe<Statuses_Bool_Exp>;
  status_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "party_statuses" */
export enum Party_Statuses_Constraint {
  /** unique or primary key constraint on columns "id" */
  PartyStatusesPkey = "party_statuses_pkey",
}

/** input type for inserting data into table "party_statuses" */
export type Party_Statuses_Insert_Input = {
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  party?: InputMaybe<Parties_Obj_Rel_Insert_Input>;
  party_id?: InputMaybe<Scalars["uuid"]>;
  status?: InputMaybe<Statuses_Obj_Rel_Insert_Input>;
  status_id?: InputMaybe<Scalars["uuid"]>;
};

/** aggregate max on columns */
export type Party_Statuses_Max_Fields = {
  __typename?: "party_statuses_max_fields";
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["uuid"]>;
  party_id?: Maybe<Scalars["uuid"]>;
  status_id?: Maybe<Scalars["uuid"]>;
};

/** order by max() on columns of table "party_statuses" */
export type Party_Statuses_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  party_id?: InputMaybe<Order_By>;
  status_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Party_Statuses_Min_Fields = {
  __typename?: "party_statuses_min_fields";
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["uuid"]>;
  party_id?: Maybe<Scalars["uuid"]>;
  status_id?: Maybe<Scalars["uuid"]>;
};

/** order by min() on columns of table "party_statuses" */
export type Party_Statuses_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  party_id?: InputMaybe<Order_By>;
  status_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "party_statuses" */
export type Party_Statuses_Mutation_Response = {
  __typename?: "party_statuses_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Party_Statuses>;
};

/** on_conflict condition type for table "party_statuses" */
export type Party_Statuses_On_Conflict = {
  constraint: Party_Statuses_Constraint;
  update_columns?: Array<Party_Statuses_Update_Column>;
  where?: InputMaybe<Party_Statuses_Bool_Exp>;
};

/** Ordering options when selecting data from "party_statuses". */
export type Party_Statuses_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  party?: InputMaybe<Parties_Order_By>;
  party_id?: InputMaybe<Order_By>;
  status?: InputMaybe<Statuses_Order_By>;
  status_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: party_statuses */
export type Party_Statuses_Pk_Columns_Input = {
  id: Scalars["uuid"];
};

/** select columns of table "party_statuses" */
export enum Party_Statuses_Select_Column {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  Id = "id",
  /** column name */
  PartyId = "party_id",
  /** column name */
  StatusId = "status_id",
}

/** input type for updating data in table "party_statuses" */
export type Party_Statuses_Set_Input = {
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  party_id?: InputMaybe<Scalars["uuid"]>;
  status_id?: InputMaybe<Scalars["uuid"]>;
};

/** Streaming cursor of the table "party_statuses" */
export type Party_Statuses_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Party_Statuses_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Party_Statuses_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  party_id?: InputMaybe<Scalars["uuid"]>;
  status_id?: InputMaybe<Scalars["uuid"]>;
};

/** update columns of table "party_statuses" */
export enum Party_Statuses_Update_Column {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  Id = "id",
  /** column name */
  PartyId = "party_id",
  /** column name */
  StatusId = "status_id",
}

export type Party_Statuses_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Party_Statuses_Set_Input>;
  /** filter the rows which have to be updated */
  where: Party_Statuses_Bool_Exp;
};

export type Payment_Bool_Exp = {
  amount?: InputMaybe<Int_Comparison_Exp>;
  created_at?: InputMaybe<Time_Comparison_Exp>;
  currency?: InputMaybe<String_Comparison_Exp>;
  effective_date?: InputMaybe<Time_Comparison_Exp>;
  external_reference?: InputMaybe<String_Comparison_Exp>;
  ledger_id?: InputMaybe<Uuid_Comparison_Exp>;
  originating_ledger_account_id?: InputMaybe<Uuid_Comparison_Exp>;
  originating_party_id?: InputMaybe<Uuid_Comparison_Exp>;
  receiving_ledger_account_id?: InputMaybe<Uuid_Comparison_Exp>;
  receiving_party_id?: InputMaybe<Uuid_Comparison_Exp>;
  recording_party_id?: InputMaybe<Uuid_Comparison_Exp>;
  status_id?: InputMaybe<Uuid_Comparison_Exp>;
  transaction_id?: InputMaybe<Uuid_Comparison_Exp>;
  type_id?: InputMaybe<Uuid_Comparison_Exp>;
  updated_at?: InputMaybe<Time_Comparison_Exp>;
};

export type Payment_Intent_Bool_Exp = {
  amount?: InputMaybe<Int_Comparison_Exp>;
  cancellation_reason?: InputMaybe<String_Comparison_Exp>;
  cancelled_at?: InputMaybe<Time_Comparison_Exp>;
  created_at?: InputMaybe<Time_Comparison_Exp>;
  currency?: InputMaybe<String_Comparison_Exp>;
  external_id?: InputMaybe<String_Comparison_Exp>;
  next_action?: InputMaybe<String_Comparison_Exp>;
  organisation_id?: InputMaybe<Uuid_Comparison_Exp>;
  party_to_pay_id?: InputMaybe<Uuid_Comparison_Exp>;
  recording_party_id?: InputMaybe<Uuid_Comparison_Exp>;
  status_id?: InputMaybe<Uuid_Comparison_Exp>;
  transaction_id?: InputMaybe<Uuid_Comparison_Exp>;
  updated_at?: InputMaybe<Time_Comparison_Exp>;
};

/** columns and relationships of "performance_indicators" */
export type Performance_Indicators = {
  __typename?: "performance_indicators";
  /** An array relationship */
  campaign_performance_indicators: Array<Campaign_Performance_Indicators>;
  /** An aggregate relationship */
  campaign_performance_indicators_aggregate: Campaign_Performance_Indicators_Aggregate;
  created_at: Scalars["timestamptz"];
  description: Scalars["String"];
  id: Scalars["uuid"];
  is_active: Scalars["Boolean"];
  name: Scalars["String"];
  /** An array relationship */
  performance_targets: Array<Performance_Targets>;
  /** An aggregate relationship */
  performance_targets_aggregate: Performance_Targets_Aggregate;
  /** An object relationship */
  type?: Maybe<Types>;
  type_id?: Maybe<Scalars["uuid"]>;
  updated_at: Scalars["timestamptz"];
};

/** columns and relationships of "performance_indicators" */
export type Performance_IndicatorsCampaign_Performance_IndicatorsArgs = {
  distinct_on?: InputMaybe<
    Array<Campaign_Performance_Indicators_Select_Column>
  >;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Campaign_Performance_Indicators_Order_By>>;
  where?: InputMaybe<Campaign_Performance_Indicators_Bool_Exp>;
};

/** columns and relationships of "performance_indicators" */
export type Performance_IndicatorsCampaign_Performance_Indicators_AggregateArgs =
  {
    distinct_on?: InputMaybe<
      Array<Campaign_Performance_Indicators_Select_Column>
    >;
    limit?: InputMaybe<Scalars["Int"]>;
    offset?: InputMaybe<Scalars["Int"]>;
    order_by?: InputMaybe<Array<Campaign_Performance_Indicators_Order_By>>;
    where?: InputMaybe<Campaign_Performance_Indicators_Bool_Exp>;
  };

/** columns and relationships of "performance_indicators" */
export type Performance_IndicatorsPerformance_TargetsArgs = {
  distinct_on?: InputMaybe<Array<Performance_Targets_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Performance_Targets_Order_By>>;
  where?: InputMaybe<Performance_Targets_Bool_Exp>;
};

/** columns and relationships of "performance_indicators" */
export type Performance_IndicatorsPerformance_Targets_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Performance_Targets_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Performance_Targets_Order_By>>;
  where?: InputMaybe<Performance_Targets_Bool_Exp>;
};

/** aggregated selection of "performance_indicators" */
export type Performance_Indicators_Aggregate = {
  __typename?: "performance_indicators_aggregate";
  aggregate?: Maybe<Performance_Indicators_Aggregate_Fields>;
  nodes: Array<Performance_Indicators>;
};

export type Performance_Indicators_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Performance_Indicators_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Performance_Indicators_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Performance_Indicators_Aggregate_Bool_Exp_Count>;
};

export type Performance_Indicators_Aggregate_Bool_Exp_Bool_And = {
  arguments: Performance_Indicators_Select_Column_Performance_Indicators_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<Performance_Indicators_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Performance_Indicators_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Performance_Indicators_Select_Column_Performance_Indicators_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<Performance_Indicators_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Performance_Indicators_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Performance_Indicators_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<Performance_Indicators_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "performance_indicators" */
export type Performance_Indicators_Aggregate_Fields = {
  __typename?: "performance_indicators_aggregate_fields";
  count: Scalars["Int"];
  max?: Maybe<Performance_Indicators_Max_Fields>;
  min?: Maybe<Performance_Indicators_Min_Fields>;
};

/** aggregate fields of "performance_indicators" */
export type Performance_Indicators_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Performance_Indicators_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "performance_indicators" */
export type Performance_Indicators_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Performance_Indicators_Max_Order_By>;
  min?: InputMaybe<Performance_Indicators_Min_Order_By>;
};

/** input type for inserting array relation for remote table "performance_indicators" */
export type Performance_Indicators_Arr_Rel_Insert_Input = {
  data: Array<Performance_Indicators_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Performance_Indicators_On_Conflict>;
};

/** Boolean expression to filter rows from the table "performance_indicators". All fields are combined with a logical 'AND'. */
export type Performance_Indicators_Bool_Exp = {
  _and?: InputMaybe<Array<Performance_Indicators_Bool_Exp>>;
  _not?: InputMaybe<Performance_Indicators_Bool_Exp>;
  _or?: InputMaybe<Array<Performance_Indicators_Bool_Exp>>;
  campaign_performance_indicators?: InputMaybe<Campaign_Performance_Indicators_Bool_Exp>;
  campaign_performance_indicators_aggregate?: InputMaybe<Campaign_Performance_Indicators_Aggregate_Bool_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  is_active?: InputMaybe<Boolean_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  performance_targets?: InputMaybe<Performance_Targets_Bool_Exp>;
  performance_targets_aggregate?: InputMaybe<Performance_Targets_Aggregate_Bool_Exp>;
  type?: InputMaybe<Types_Bool_Exp>;
  type_id?: InputMaybe<Uuid_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "performance_indicators" */
export enum Performance_Indicators_Constraint {
  /** unique or primary key constraint on columns "id" */
  PerformanceIndicatorsPkey = "performance_indicators_pkey",
}

/** input type for inserting data into table "performance_indicators" */
export type Performance_Indicators_Insert_Input = {
  campaign_performance_indicators?: InputMaybe<Campaign_Performance_Indicators_Arr_Rel_Insert_Input>;
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  description?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  is_active?: InputMaybe<Scalars["Boolean"]>;
  name?: InputMaybe<Scalars["String"]>;
  performance_targets?: InputMaybe<Performance_Targets_Arr_Rel_Insert_Input>;
  type?: InputMaybe<Types_Obj_Rel_Insert_Input>;
  type_id?: InputMaybe<Scalars["uuid"]>;
  updated_at?: InputMaybe<Scalars["timestamptz"]>;
};

/** aggregate max on columns */
export type Performance_Indicators_Max_Fields = {
  __typename?: "performance_indicators_max_fields";
  created_at?: Maybe<Scalars["timestamptz"]>;
  description?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["uuid"]>;
  name?: Maybe<Scalars["String"]>;
  type_id?: Maybe<Scalars["uuid"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
};

/** order by max() on columns of table "performance_indicators" */
export type Performance_Indicators_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  type_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Performance_Indicators_Min_Fields = {
  __typename?: "performance_indicators_min_fields";
  created_at?: Maybe<Scalars["timestamptz"]>;
  description?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["uuid"]>;
  name?: Maybe<Scalars["String"]>;
  type_id?: Maybe<Scalars["uuid"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
};

/** order by min() on columns of table "performance_indicators" */
export type Performance_Indicators_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  type_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "performance_indicators" */
export type Performance_Indicators_Mutation_Response = {
  __typename?: "performance_indicators_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Performance_Indicators>;
};

/** input type for inserting object relation for remote table "performance_indicators" */
export type Performance_Indicators_Obj_Rel_Insert_Input = {
  data: Performance_Indicators_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Performance_Indicators_On_Conflict>;
};

/** on_conflict condition type for table "performance_indicators" */
export type Performance_Indicators_On_Conflict = {
  constraint: Performance_Indicators_Constraint;
  update_columns?: Array<Performance_Indicators_Update_Column>;
  where?: InputMaybe<Performance_Indicators_Bool_Exp>;
};

/** Ordering options when selecting data from "performance_indicators". */
export type Performance_Indicators_Order_By = {
  campaign_performance_indicators_aggregate?: InputMaybe<Campaign_Performance_Indicators_Aggregate_Order_By>;
  created_at?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  is_active?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  performance_targets_aggregate?: InputMaybe<Performance_Targets_Aggregate_Order_By>;
  type?: InputMaybe<Types_Order_By>;
  type_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: performance_indicators */
export type Performance_Indicators_Pk_Columns_Input = {
  id: Scalars["uuid"];
};

/** select columns of table "performance_indicators" */
export enum Performance_Indicators_Select_Column {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  Description = "description",
  /** column name */
  Id = "id",
  /** column name */
  IsActive = "is_active",
  /** column name */
  Name = "name",
  /** column name */
  TypeId = "type_id",
  /** column name */
  UpdatedAt = "updated_at",
}

/** select "performance_indicators_aggregate_bool_exp_bool_and_arguments_columns" columns of table "performance_indicators" */
export enum Performance_Indicators_Select_Column_Performance_Indicators_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  IsActive = "is_active",
}

/** select "performance_indicators_aggregate_bool_exp_bool_or_arguments_columns" columns of table "performance_indicators" */
export enum Performance_Indicators_Select_Column_Performance_Indicators_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  IsActive = "is_active",
}

/** input type for updating data in table "performance_indicators" */
export type Performance_Indicators_Set_Input = {
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  description?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  is_active?: InputMaybe<Scalars["Boolean"]>;
  name?: InputMaybe<Scalars["String"]>;
  type_id?: InputMaybe<Scalars["uuid"]>;
  updated_at?: InputMaybe<Scalars["timestamptz"]>;
};

/** Streaming cursor of the table "performance_indicators" */
export type Performance_Indicators_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Performance_Indicators_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Performance_Indicators_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  description?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  is_active?: InputMaybe<Scalars["Boolean"]>;
  name?: InputMaybe<Scalars["String"]>;
  type_id?: InputMaybe<Scalars["uuid"]>;
  updated_at?: InputMaybe<Scalars["timestamptz"]>;
};

/** update columns of table "performance_indicators" */
export enum Performance_Indicators_Update_Column {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  Description = "description",
  /** column name */
  Id = "id",
  /** column name */
  IsActive = "is_active",
  /** column name */
  Name = "name",
  /** column name */
  TypeId = "type_id",
  /** column name */
  UpdatedAt = "updated_at",
}

export type Performance_Indicators_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Performance_Indicators_Set_Input>;
  /** filter the rows which have to be updated */
  where: Performance_Indicators_Bool_Exp;
};

/** columns and relationships of "performance_targets" */
export type Performance_Targets = {
  __typename?: "performance_targets";
  assigned_by: Scalars["uuid"];
  assigned_to: Scalars["uuid"];
  created_at: Scalars["timestamptz"];
  id: Scalars["uuid"];
  is_active: Scalars["Boolean"];
  organisation_id?: Maybe<Scalars["uuid"]>;
  /** An object relationship */
  party: Parties;
  /** An object relationship */
  partyByAssignedTo: Parties;
  /** An object relationship */
  performance_indicator: Performance_Indicators;
  performance_indicator_id: Scalars["uuid"];
  period: Scalars["jsonb"];
  target: Scalars["Float"];
  /** An object relationship */
  type?: Maybe<Types>;
  type_id?: Maybe<Scalars["uuid"]>;
  updated_at: Scalars["timestamptz"];
  weight: Scalars["Float"];
};

/** columns and relationships of "performance_targets" */
export type Performance_TargetsPeriodArgs = {
  path?: InputMaybe<Scalars["String"]>;
};

/** aggregated selection of "performance_targets" */
export type Performance_Targets_Aggregate = {
  __typename?: "performance_targets_aggregate";
  aggregate?: Maybe<Performance_Targets_Aggregate_Fields>;
  nodes: Array<Performance_Targets>;
};

export type Performance_Targets_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Performance_Targets_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Performance_Targets_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Performance_Targets_Aggregate_Bool_Exp_Count>;
};

export type Performance_Targets_Aggregate_Bool_Exp_Bool_And = {
  arguments: Performance_Targets_Select_Column_Performance_Targets_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<Performance_Targets_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Performance_Targets_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Performance_Targets_Select_Column_Performance_Targets_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<Performance_Targets_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Performance_Targets_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Performance_Targets_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<Performance_Targets_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "performance_targets" */
export type Performance_Targets_Aggregate_Fields = {
  __typename?: "performance_targets_aggregate_fields";
  avg?: Maybe<Performance_Targets_Avg_Fields>;
  count: Scalars["Int"];
  max?: Maybe<Performance_Targets_Max_Fields>;
  min?: Maybe<Performance_Targets_Min_Fields>;
  stddev?: Maybe<Performance_Targets_Stddev_Fields>;
  stddev_pop?: Maybe<Performance_Targets_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Performance_Targets_Stddev_Samp_Fields>;
  sum?: Maybe<Performance_Targets_Sum_Fields>;
  var_pop?: Maybe<Performance_Targets_Var_Pop_Fields>;
  var_samp?: Maybe<Performance_Targets_Var_Samp_Fields>;
  variance?: Maybe<Performance_Targets_Variance_Fields>;
};

/** aggregate fields of "performance_targets" */
export type Performance_Targets_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Performance_Targets_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "performance_targets" */
export type Performance_Targets_Aggregate_Order_By = {
  avg?: InputMaybe<Performance_Targets_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Performance_Targets_Max_Order_By>;
  min?: InputMaybe<Performance_Targets_Min_Order_By>;
  stddev?: InputMaybe<Performance_Targets_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Performance_Targets_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Performance_Targets_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Performance_Targets_Sum_Order_By>;
  var_pop?: InputMaybe<Performance_Targets_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Performance_Targets_Var_Samp_Order_By>;
  variance?: InputMaybe<Performance_Targets_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Performance_Targets_Append_Input = {
  period?: InputMaybe<Scalars["jsonb"]>;
};

/** input type for inserting array relation for remote table "performance_targets" */
export type Performance_Targets_Arr_Rel_Insert_Input = {
  data: Array<Performance_Targets_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Performance_Targets_On_Conflict>;
};

/** aggregate avg on columns */
export type Performance_Targets_Avg_Fields = {
  __typename?: "performance_targets_avg_fields";
  target?: Maybe<Scalars["Float"]>;
  weight?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "performance_targets" */
export type Performance_Targets_Avg_Order_By = {
  target?: InputMaybe<Order_By>;
  weight?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "performance_targets". All fields are combined with a logical 'AND'. */
export type Performance_Targets_Bool_Exp = {
  _and?: InputMaybe<Array<Performance_Targets_Bool_Exp>>;
  _not?: InputMaybe<Performance_Targets_Bool_Exp>;
  _or?: InputMaybe<Array<Performance_Targets_Bool_Exp>>;
  assigned_by?: InputMaybe<Uuid_Comparison_Exp>;
  assigned_to?: InputMaybe<Uuid_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  is_active?: InputMaybe<Boolean_Comparison_Exp>;
  organisation_id?: InputMaybe<Uuid_Comparison_Exp>;
  party?: InputMaybe<Parties_Bool_Exp>;
  partyByAssignedTo?: InputMaybe<Parties_Bool_Exp>;
  performance_indicator?: InputMaybe<Performance_Indicators_Bool_Exp>;
  performance_indicator_id?: InputMaybe<Uuid_Comparison_Exp>;
  period?: InputMaybe<Jsonb_Comparison_Exp>;
  target?: InputMaybe<Float_Comparison_Exp>;
  type?: InputMaybe<Types_Bool_Exp>;
  type_id?: InputMaybe<Uuid_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  weight?: InputMaybe<Float_Comparison_Exp>;
};

/** unique or primary key constraints on table "performance_targets" */
export enum Performance_Targets_Constraint {
  /** unique or primary key constraint on columns "id" */
  PerformanceTargetsPkey = "performance_targets_pkey",
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Performance_Targets_Delete_At_Path_Input = {
  period?: InputMaybe<Array<Scalars["String"]>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Performance_Targets_Delete_Elem_Input = {
  period?: InputMaybe<Scalars["Int"]>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Performance_Targets_Delete_Key_Input = {
  period?: InputMaybe<Scalars["String"]>;
};

/** input type for incrementing numeric columns in table "performance_targets" */
export type Performance_Targets_Inc_Input = {
  target?: InputMaybe<Scalars["Float"]>;
  weight?: InputMaybe<Scalars["Float"]>;
};

/** input type for inserting data into table "performance_targets" */
export type Performance_Targets_Insert_Input = {
  assigned_by?: InputMaybe<Scalars["uuid"]>;
  assigned_to?: InputMaybe<Scalars["uuid"]>;
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  is_active?: InputMaybe<Scalars["Boolean"]>;
  organisation_id?: InputMaybe<Scalars["uuid"]>;
  party?: InputMaybe<Parties_Obj_Rel_Insert_Input>;
  partyByAssignedTo?: InputMaybe<Parties_Obj_Rel_Insert_Input>;
  performance_indicator?: InputMaybe<Performance_Indicators_Obj_Rel_Insert_Input>;
  performance_indicator_id?: InputMaybe<Scalars["uuid"]>;
  period?: InputMaybe<Scalars["jsonb"]>;
  target?: InputMaybe<Scalars["Float"]>;
  type?: InputMaybe<Types_Obj_Rel_Insert_Input>;
  type_id?: InputMaybe<Scalars["uuid"]>;
  updated_at?: InputMaybe<Scalars["timestamptz"]>;
  weight?: InputMaybe<Scalars["Float"]>;
};

/** aggregate max on columns */
export type Performance_Targets_Max_Fields = {
  __typename?: "performance_targets_max_fields";
  assigned_by?: Maybe<Scalars["uuid"]>;
  assigned_to?: Maybe<Scalars["uuid"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["uuid"]>;
  organisation_id?: Maybe<Scalars["uuid"]>;
  performance_indicator_id?: Maybe<Scalars["uuid"]>;
  target?: Maybe<Scalars["Float"]>;
  type_id?: Maybe<Scalars["uuid"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
  weight?: Maybe<Scalars["Float"]>;
};

/** order by max() on columns of table "performance_targets" */
export type Performance_Targets_Max_Order_By = {
  assigned_by?: InputMaybe<Order_By>;
  assigned_to?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  organisation_id?: InputMaybe<Order_By>;
  performance_indicator_id?: InputMaybe<Order_By>;
  target?: InputMaybe<Order_By>;
  type_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  weight?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Performance_Targets_Min_Fields = {
  __typename?: "performance_targets_min_fields";
  assigned_by?: Maybe<Scalars["uuid"]>;
  assigned_to?: Maybe<Scalars["uuid"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["uuid"]>;
  organisation_id?: Maybe<Scalars["uuid"]>;
  performance_indicator_id?: Maybe<Scalars["uuid"]>;
  target?: Maybe<Scalars["Float"]>;
  type_id?: Maybe<Scalars["uuid"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
  weight?: Maybe<Scalars["Float"]>;
};

/** order by min() on columns of table "performance_targets" */
export type Performance_Targets_Min_Order_By = {
  assigned_by?: InputMaybe<Order_By>;
  assigned_to?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  organisation_id?: InputMaybe<Order_By>;
  performance_indicator_id?: InputMaybe<Order_By>;
  target?: InputMaybe<Order_By>;
  type_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  weight?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "performance_targets" */
export type Performance_Targets_Mutation_Response = {
  __typename?: "performance_targets_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Performance_Targets>;
};

/** on_conflict condition type for table "performance_targets" */
export type Performance_Targets_On_Conflict = {
  constraint: Performance_Targets_Constraint;
  update_columns?: Array<Performance_Targets_Update_Column>;
  where?: InputMaybe<Performance_Targets_Bool_Exp>;
};

/** Ordering options when selecting data from "performance_targets". */
export type Performance_Targets_Order_By = {
  assigned_by?: InputMaybe<Order_By>;
  assigned_to?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  is_active?: InputMaybe<Order_By>;
  organisation_id?: InputMaybe<Order_By>;
  party?: InputMaybe<Parties_Order_By>;
  partyByAssignedTo?: InputMaybe<Parties_Order_By>;
  performance_indicator?: InputMaybe<Performance_Indicators_Order_By>;
  performance_indicator_id?: InputMaybe<Order_By>;
  period?: InputMaybe<Order_By>;
  target?: InputMaybe<Order_By>;
  type?: InputMaybe<Types_Order_By>;
  type_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  weight?: InputMaybe<Order_By>;
};

/** primary key columns input for table: performance_targets */
export type Performance_Targets_Pk_Columns_Input = {
  id: Scalars["uuid"];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Performance_Targets_Prepend_Input = {
  period?: InputMaybe<Scalars["jsonb"]>;
};

/** select columns of table "performance_targets" */
export enum Performance_Targets_Select_Column {
  /** column name */
  AssignedBy = "assigned_by",
  /** column name */
  AssignedTo = "assigned_to",
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  Id = "id",
  /** column name */
  IsActive = "is_active",
  /** column name */
  OrganisationId = "organisation_id",
  /** column name */
  PerformanceIndicatorId = "performance_indicator_id",
  /** column name */
  Period = "period",
  /** column name */
  Target = "target",
  /** column name */
  TypeId = "type_id",
  /** column name */
  UpdatedAt = "updated_at",
  /** column name */
  Weight = "weight",
}

/** select "performance_targets_aggregate_bool_exp_bool_and_arguments_columns" columns of table "performance_targets" */
export enum Performance_Targets_Select_Column_Performance_Targets_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  IsActive = "is_active",
}

/** select "performance_targets_aggregate_bool_exp_bool_or_arguments_columns" columns of table "performance_targets" */
export enum Performance_Targets_Select_Column_Performance_Targets_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  IsActive = "is_active",
}

/** input type for updating data in table "performance_targets" */
export type Performance_Targets_Set_Input = {
  assigned_by?: InputMaybe<Scalars["uuid"]>;
  assigned_to?: InputMaybe<Scalars["uuid"]>;
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  is_active?: InputMaybe<Scalars["Boolean"]>;
  organisation_id?: InputMaybe<Scalars["uuid"]>;
  performance_indicator_id?: InputMaybe<Scalars["uuid"]>;
  period?: InputMaybe<Scalars["jsonb"]>;
  target?: InputMaybe<Scalars["Float"]>;
  type_id?: InputMaybe<Scalars["uuid"]>;
  updated_at?: InputMaybe<Scalars["timestamptz"]>;
  weight?: InputMaybe<Scalars["Float"]>;
};

/** aggregate stddev on columns */
export type Performance_Targets_Stddev_Fields = {
  __typename?: "performance_targets_stddev_fields";
  target?: Maybe<Scalars["Float"]>;
  weight?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "performance_targets" */
export type Performance_Targets_Stddev_Order_By = {
  target?: InputMaybe<Order_By>;
  weight?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Performance_Targets_Stddev_Pop_Fields = {
  __typename?: "performance_targets_stddev_pop_fields";
  target?: Maybe<Scalars["Float"]>;
  weight?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "performance_targets" */
export type Performance_Targets_Stddev_Pop_Order_By = {
  target?: InputMaybe<Order_By>;
  weight?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Performance_Targets_Stddev_Samp_Fields = {
  __typename?: "performance_targets_stddev_samp_fields";
  target?: Maybe<Scalars["Float"]>;
  weight?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "performance_targets" */
export type Performance_Targets_Stddev_Samp_Order_By = {
  target?: InputMaybe<Order_By>;
  weight?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "performance_targets" */
export type Performance_Targets_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Performance_Targets_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Performance_Targets_Stream_Cursor_Value_Input = {
  assigned_by?: InputMaybe<Scalars["uuid"]>;
  assigned_to?: InputMaybe<Scalars["uuid"]>;
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  is_active?: InputMaybe<Scalars["Boolean"]>;
  organisation_id?: InputMaybe<Scalars["uuid"]>;
  performance_indicator_id?: InputMaybe<Scalars["uuid"]>;
  period?: InputMaybe<Scalars["jsonb"]>;
  target?: InputMaybe<Scalars["Float"]>;
  type_id?: InputMaybe<Scalars["uuid"]>;
  updated_at?: InputMaybe<Scalars["timestamptz"]>;
  weight?: InputMaybe<Scalars["Float"]>;
};

/** aggregate sum on columns */
export type Performance_Targets_Sum_Fields = {
  __typename?: "performance_targets_sum_fields";
  target?: Maybe<Scalars["Float"]>;
  weight?: Maybe<Scalars["Float"]>;
};

/** order by sum() on columns of table "performance_targets" */
export type Performance_Targets_Sum_Order_By = {
  target?: InputMaybe<Order_By>;
  weight?: InputMaybe<Order_By>;
};

/** update columns of table "performance_targets" */
export enum Performance_Targets_Update_Column {
  /** column name */
  AssignedBy = "assigned_by",
  /** column name */
  AssignedTo = "assigned_to",
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  Id = "id",
  /** column name */
  IsActive = "is_active",
  /** column name */
  OrganisationId = "organisation_id",
  /** column name */
  PerformanceIndicatorId = "performance_indicator_id",
  /** column name */
  Period = "period",
  /** column name */
  Target = "target",
  /** column name */
  TypeId = "type_id",
  /** column name */
  UpdatedAt = "updated_at",
  /** column name */
  Weight = "weight",
}

export type Performance_Targets_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Performance_Targets_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Performance_Targets_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Performance_Targets_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Performance_Targets_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Performance_Targets_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Performance_Targets_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Performance_Targets_Set_Input>;
  /** filter the rows which have to be updated */
  where: Performance_Targets_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Performance_Targets_Var_Pop_Fields = {
  __typename?: "performance_targets_var_pop_fields";
  target?: Maybe<Scalars["Float"]>;
  weight?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "performance_targets" */
export type Performance_Targets_Var_Pop_Order_By = {
  target?: InputMaybe<Order_By>;
  weight?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Performance_Targets_Var_Samp_Fields = {
  __typename?: "performance_targets_var_samp_fields";
  target?: Maybe<Scalars["Float"]>;
  weight?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "performance_targets" */
export type Performance_Targets_Var_Samp_Order_By = {
  target?: InputMaybe<Order_By>;
  weight?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Performance_Targets_Variance_Fields = {
  __typename?: "performance_targets_variance_fields";
  target?: Maybe<Scalars["Float"]>;
  weight?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "performance_targets" */
export type Performance_Targets_Variance_Order_By = {
  target?: InputMaybe<Order_By>;
  weight?: InputMaybe<Order_By>;
};

/** columns and relationships of "product_documents" */
export type Product_Documents = {
  __typename?: "product_documents";
  created_at?: Maybe<Scalars["timestamptz"]>;
  /** An object relationship */
  document: Documents;
  document_id: Scalars["uuid"];
  id: Scalars["uuid"];
  /** An object relationship */
  product: Products;
  product_id: Scalars["uuid"];
};

/** aggregated selection of "product_documents" */
export type Product_Documents_Aggregate = {
  __typename?: "product_documents_aggregate";
  aggregate?: Maybe<Product_Documents_Aggregate_Fields>;
  nodes: Array<Product_Documents>;
};

export type Product_Documents_Aggregate_Bool_Exp = {
  count?: InputMaybe<Product_Documents_Aggregate_Bool_Exp_Count>;
};

export type Product_Documents_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Product_Documents_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<Product_Documents_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "product_documents" */
export type Product_Documents_Aggregate_Fields = {
  __typename?: "product_documents_aggregate_fields";
  count: Scalars["Int"];
  max?: Maybe<Product_Documents_Max_Fields>;
  min?: Maybe<Product_Documents_Min_Fields>;
};

/** aggregate fields of "product_documents" */
export type Product_Documents_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Product_Documents_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "product_documents" */
export type Product_Documents_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Product_Documents_Max_Order_By>;
  min?: InputMaybe<Product_Documents_Min_Order_By>;
};

/** input type for inserting array relation for remote table "product_documents" */
export type Product_Documents_Arr_Rel_Insert_Input = {
  data: Array<Product_Documents_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Product_Documents_On_Conflict>;
};

/** Boolean expression to filter rows from the table "product_documents". All fields are combined with a logical 'AND'. */
export type Product_Documents_Bool_Exp = {
  _and?: InputMaybe<Array<Product_Documents_Bool_Exp>>;
  _not?: InputMaybe<Product_Documents_Bool_Exp>;
  _or?: InputMaybe<Array<Product_Documents_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  document?: InputMaybe<Documents_Bool_Exp>;
  document_id?: InputMaybe<Uuid_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  product?: InputMaybe<Products_Bool_Exp>;
  product_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "product_documents" */
export enum Product_Documents_Constraint {
  /** unique or primary key constraint on columns "id" */
  ProductDocumentsPkey = "product_documents_pkey",
  /** unique or primary key constraint on columns "document_id", "product_id" */
  UniqueProductDocuments = "unique_product_documents",
}

/** input type for inserting data into table "product_documents" */
export type Product_Documents_Insert_Input = {
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  document?: InputMaybe<Documents_Obj_Rel_Insert_Input>;
  document_id?: InputMaybe<Scalars["uuid"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  product?: InputMaybe<Products_Obj_Rel_Insert_Input>;
  product_id?: InputMaybe<Scalars["uuid"]>;
};

/** aggregate max on columns */
export type Product_Documents_Max_Fields = {
  __typename?: "product_documents_max_fields";
  created_at?: Maybe<Scalars["timestamptz"]>;
  document_id?: Maybe<Scalars["uuid"]>;
  id?: Maybe<Scalars["uuid"]>;
  product_id?: Maybe<Scalars["uuid"]>;
};

/** order by max() on columns of table "product_documents" */
export type Product_Documents_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  document_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  product_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Product_Documents_Min_Fields = {
  __typename?: "product_documents_min_fields";
  created_at?: Maybe<Scalars["timestamptz"]>;
  document_id?: Maybe<Scalars["uuid"]>;
  id?: Maybe<Scalars["uuid"]>;
  product_id?: Maybe<Scalars["uuid"]>;
};

/** order by min() on columns of table "product_documents" */
export type Product_Documents_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  document_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  product_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "product_documents" */
export type Product_Documents_Mutation_Response = {
  __typename?: "product_documents_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Product_Documents>;
};

/** on_conflict condition type for table "product_documents" */
export type Product_Documents_On_Conflict = {
  constraint: Product_Documents_Constraint;
  update_columns?: Array<Product_Documents_Update_Column>;
  where?: InputMaybe<Product_Documents_Bool_Exp>;
};

/** Ordering options when selecting data from "product_documents". */
export type Product_Documents_Order_By = {
  created_at?: InputMaybe<Order_By>;
  document?: InputMaybe<Documents_Order_By>;
  document_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  product?: InputMaybe<Products_Order_By>;
  product_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: product_documents */
export type Product_Documents_Pk_Columns_Input = {
  id: Scalars["uuid"];
};

/** select columns of table "product_documents" */
export enum Product_Documents_Select_Column {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  DocumentId = "document_id",
  /** column name */
  Id = "id",
  /** column name */
  ProductId = "product_id",
}

/** input type for updating data in table "product_documents" */
export type Product_Documents_Set_Input = {
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  document_id?: InputMaybe<Scalars["uuid"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  product_id?: InputMaybe<Scalars["uuid"]>;
};

/** Streaming cursor of the table "product_documents" */
export type Product_Documents_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Product_Documents_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Product_Documents_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  document_id?: InputMaybe<Scalars["uuid"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  product_id?: InputMaybe<Scalars["uuid"]>;
};

/** update columns of table "product_documents" */
export enum Product_Documents_Update_Column {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  DocumentId = "document_id",
  /** column name */
  Id = "id",
  /** column name */
  ProductId = "product_id",
}

export type Product_Documents_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Product_Documents_Set_Input>;
  /** filter the rows which have to be updated */
  where: Product_Documents_Bool_Exp;
};

/** columns and relationships of "product_interactions" */
export type Product_Interactions = {
  __typename?: "product_interactions";
  created_at?: Maybe<Scalars["timestamptz"]>;
  id: Scalars["uuid"];
  /** An object relationship */
  interaction: Interactions;
  interaction_id: Scalars["uuid"];
  /** An object relationship */
  product: Products;
  product_id: Scalars["uuid"];
};

/** aggregated selection of "product_interactions" */
export type Product_Interactions_Aggregate = {
  __typename?: "product_interactions_aggregate";
  aggregate?: Maybe<Product_Interactions_Aggregate_Fields>;
  nodes: Array<Product_Interactions>;
};

export type Product_Interactions_Aggregate_Bool_Exp = {
  count?: InputMaybe<Product_Interactions_Aggregate_Bool_Exp_Count>;
};

export type Product_Interactions_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Product_Interactions_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<Product_Interactions_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "product_interactions" */
export type Product_Interactions_Aggregate_Fields = {
  __typename?: "product_interactions_aggregate_fields";
  count: Scalars["Int"];
  max?: Maybe<Product_Interactions_Max_Fields>;
  min?: Maybe<Product_Interactions_Min_Fields>;
};

/** aggregate fields of "product_interactions" */
export type Product_Interactions_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Product_Interactions_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "product_interactions" */
export type Product_Interactions_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Product_Interactions_Max_Order_By>;
  min?: InputMaybe<Product_Interactions_Min_Order_By>;
};

/** input type for inserting array relation for remote table "product_interactions" */
export type Product_Interactions_Arr_Rel_Insert_Input = {
  data: Array<Product_Interactions_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Product_Interactions_On_Conflict>;
};

/** Boolean expression to filter rows from the table "product_interactions". All fields are combined with a logical 'AND'. */
export type Product_Interactions_Bool_Exp = {
  _and?: InputMaybe<Array<Product_Interactions_Bool_Exp>>;
  _not?: InputMaybe<Product_Interactions_Bool_Exp>;
  _or?: InputMaybe<Array<Product_Interactions_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  interaction?: InputMaybe<Interactions_Bool_Exp>;
  interaction_id?: InputMaybe<Uuid_Comparison_Exp>;
  product?: InputMaybe<Products_Bool_Exp>;
  product_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "product_interactions" */
export enum Product_Interactions_Constraint {
  /** unique or primary key constraint on columns "id" */
  ProductInteractionsPkey = "product_interactions_pkey",
}

/** input type for inserting data into table "product_interactions" */
export type Product_Interactions_Insert_Input = {
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  interaction?: InputMaybe<Interactions_Obj_Rel_Insert_Input>;
  interaction_id?: InputMaybe<Scalars["uuid"]>;
  product?: InputMaybe<Products_Obj_Rel_Insert_Input>;
  product_id?: InputMaybe<Scalars["uuid"]>;
};

/** aggregate max on columns */
export type Product_Interactions_Max_Fields = {
  __typename?: "product_interactions_max_fields";
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["uuid"]>;
  interaction_id?: Maybe<Scalars["uuid"]>;
  product_id?: Maybe<Scalars["uuid"]>;
};

/** order by max() on columns of table "product_interactions" */
export type Product_Interactions_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  interaction_id?: InputMaybe<Order_By>;
  product_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Product_Interactions_Min_Fields = {
  __typename?: "product_interactions_min_fields";
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["uuid"]>;
  interaction_id?: Maybe<Scalars["uuid"]>;
  product_id?: Maybe<Scalars["uuid"]>;
};

/** order by min() on columns of table "product_interactions" */
export type Product_Interactions_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  interaction_id?: InputMaybe<Order_By>;
  product_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "product_interactions" */
export type Product_Interactions_Mutation_Response = {
  __typename?: "product_interactions_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Product_Interactions>;
};

/** on_conflict condition type for table "product_interactions" */
export type Product_Interactions_On_Conflict = {
  constraint: Product_Interactions_Constraint;
  update_columns?: Array<Product_Interactions_Update_Column>;
  where?: InputMaybe<Product_Interactions_Bool_Exp>;
};

/** Ordering options when selecting data from "product_interactions". */
export type Product_Interactions_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  interaction?: InputMaybe<Interactions_Order_By>;
  interaction_id?: InputMaybe<Order_By>;
  product?: InputMaybe<Products_Order_By>;
  product_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: product_interactions */
export type Product_Interactions_Pk_Columns_Input = {
  id: Scalars["uuid"];
};

/** select columns of table "product_interactions" */
export enum Product_Interactions_Select_Column {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  Id = "id",
  /** column name */
  InteractionId = "interaction_id",
  /** column name */
  ProductId = "product_id",
}

/** input type for updating data in table "product_interactions" */
export type Product_Interactions_Set_Input = {
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  interaction_id?: InputMaybe<Scalars["uuid"]>;
  product_id?: InputMaybe<Scalars["uuid"]>;
};

/** Streaming cursor of the table "product_interactions" */
export type Product_Interactions_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Product_Interactions_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Product_Interactions_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  interaction_id?: InputMaybe<Scalars["uuid"]>;
  product_id?: InputMaybe<Scalars["uuid"]>;
};

/** update columns of table "product_interactions" */
export enum Product_Interactions_Update_Column {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  Id = "id",
  /** column name */
  InteractionId = "interaction_id",
  /** column name */
  ProductId = "product_id",
}

export type Product_Interactions_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Product_Interactions_Set_Input>;
  /** filter the rows which have to be updated */
  where: Product_Interactions_Bool_Exp;
};

/** columns and relationships of "product_items" */
export type Product_Items = {
  __typename?: "product_items";
  created_at: Scalars["timestamptz"];
  description: Scalars["String"];
  id: Scalars["uuid"];
  /** An array relationship */
  invoice_items: Array<Invoice_Items>;
  /** An aggregate relationship */
  invoice_items_aggregate: Invoice_Items_Aggregate;
  is_active: Scalars["Boolean"];
  ledger_account: LedgerAccount;
  ledger_account_id?: Maybe<Scalars["uuid"]>;
  metadata?: Maybe<Scalars["jsonb"]>;
  name: Scalars["String"];
  organisation_id: Scalars["uuid"];
  /** An object relationship */
  product: Products;
  product_id: Scalars["uuid"];
  /** An object relationship */
  sub_type?: Maybe<Types>;
  sub_type_id?: Maybe<Scalars["uuid"]>;
  subscription_items: Array<Subscription_Items>;
  subscription_items_aggregate: Subscription_Items_Aggregate;
  /** An array relationship */
  subscription_payment_transactions: Array<Subscription_Payment_Transactions>;
  /** An aggregate relationship */
  subscription_payment_transactions_aggregate: Subscription_Payment_Transactions_Aggregate;
  /** An object relationship */
  tariff: Tariffs;
  tariff_id: Scalars["uuid"];
  transaction_entries: Array<TransactionLedgerEntry>;
  /** An object relationship */
  type: Types;
  type_id: Scalars["uuid"];
  updated_at: Scalars["timestamptz"];
};

/** columns and relationships of "product_items" */
export type Product_ItemsInvoice_ItemsArgs = {
  distinct_on?: InputMaybe<Array<Invoice_Items_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Invoice_Items_Order_By>>;
  where?: InputMaybe<Invoice_Items_Bool_Exp>;
};

/** columns and relationships of "product_items" */
export type Product_ItemsInvoice_Items_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Invoice_Items_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Invoice_Items_Order_By>>;
  where?: InputMaybe<Invoice_Items_Bool_Exp>;
};

/** columns and relationships of "product_items" */
export type Product_ItemsMetadataArgs = {
  path?: InputMaybe<Scalars["String"]>;
};

/** columns and relationships of "product_items" */
export type Product_ItemsSubscription_ItemsArgs = {
  distinct_on?: InputMaybe<Array<Subscription_Items_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Subscription_Items_Order_By>>;
  where?: InputMaybe<Subscription_Items_Bool_Exp>;
};

/** columns and relationships of "product_items" */
export type Product_ItemsSubscription_Items_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Subscription_Items_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Subscription_Items_Order_By>>;
  where?: InputMaybe<Subscription_Items_Bool_Exp>;
};

/** columns and relationships of "product_items" */
export type Product_ItemsSubscription_Payment_TransactionsArgs = {
  distinct_on?: InputMaybe<
    Array<Subscription_Payment_Transactions_Select_Column>
  >;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Subscription_Payment_Transactions_Order_By>>;
  where?: InputMaybe<Subscription_Payment_Transactions_Bool_Exp>;
};

/** columns and relationships of "product_items" */
export type Product_ItemsSubscription_Payment_Transactions_AggregateArgs = {
  distinct_on?: InputMaybe<
    Array<Subscription_Payment_Transactions_Select_Column>
  >;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Subscription_Payment_Transactions_Order_By>>;
  where?: InputMaybe<Subscription_Payment_Transactions_Bool_Exp>;
};

/** columns and relationships of "product_items" */
export type Product_ItemsTransaction_EntriesArgs = {
  offset?: InputMaybe<Scalars["Int"]>;
};

/** aggregated selection of "product_items" */
export type Product_Items_Aggregate = {
  __typename?: "product_items_aggregate";
  aggregate?: Maybe<Product_Items_Aggregate_Fields>;
  nodes: Array<Product_Items>;
};

export type Product_Items_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Product_Items_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Product_Items_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Product_Items_Aggregate_Bool_Exp_Count>;
};

export type Product_Items_Aggregate_Bool_Exp_Bool_And = {
  arguments: Product_Items_Select_Column_Product_Items_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<Product_Items_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Product_Items_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Product_Items_Select_Column_Product_Items_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<Product_Items_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Product_Items_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Product_Items_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<Product_Items_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "product_items" */
export type Product_Items_Aggregate_Fields = {
  __typename?: "product_items_aggregate_fields";
  count: Scalars["Int"];
  max?: Maybe<Product_Items_Max_Fields>;
  min?: Maybe<Product_Items_Min_Fields>;
};

/** aggregate fields of "product_items" */
export type Product_Items_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Product_Items_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "product_items" */
export type Product_Items_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Product_Items_Max_Order_By>;
  min?: InputMaybe<Product_Items_Min_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Product_Items_Append_Input = {
  metadata?: InputMaybe<Scalars["jsonb"]>;
};

/** input type for inserting array relation for remote table "product_items" */
export type Product_Items_Arr_Rel_Insert_Input = {
  data: Array<Product_Items_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Product_Items_On_Conflict>;
};

/** Boolean expression to filter rows from the table "product_items". All fields are combined with a logical 'AND'. */
export type Product_Items_Bool_Exp = {
  _and?: InputMaybe<Array<Product_Items_Bool_Exp>>;
  _not?: InputMaybe<Product_Items_Bool_Exp>;
  _or?: InputMaybe<Array<Product_Items_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  invoice_items?: InputMaybe<Invoice_Items_Bool_Exp>;
  invoice_items_aggregate?: InputMaybe<Invoice_Items_Aggregate_Bool_Exp>;
  is_active?: InputMaybe<Boolean_Comparison_Exp>;
  ledger_account_id?: InputMaybe<Uuid_Comparison_Exp>;
  metadata?: InputMaybe<Jsonb_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  organisation_id?: InputMaybe<Uuid_Comparison_Exp>;
  product?: InputMaybe<Products_Bool_Exp>;
  product_id?: InputMaybe<Uuid_Comparison_Exp>;
  sub_type?: InputMaybe<Types_Bool_Exp>;
  sub_type_id?: InputMaybe<Uuid_Comparison_Exp>;
  subscription_items?: InputMaybe<Subscription_Items_Bool_Exp>;
  subscription_items_aggregate?: InputMaybe<Subscription_Items_Aggregate_Bool_Exp>;
  subscription_payment_transactions?: InputMaybe<Subscription_Payment_Transactions_Bool_Exp>;
  subscription_payment_transactions_aggregate?: InputMaybe<Subscription_Payment_Transactions_Aggregate_Bool_Exp>;
  tariff?: InputMaybe<Tariffs_Bool_Exp>;
  tariff_id?: InputMaybe<Uuid_Comparison_Exp>;
  type?: InputMaybe<Types_Bool_Exp>;
  type_id?: InputMaybe<Uuid_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "product_items" */
export enum Product_Items_Constraint {
  /** unique or primary key constraint on columns "id" */
  ProductItemsPkey = "product_items_pkey",
  /** unique or primary key constraint on columns "ledger_account_id" */
  UniqueProductItemLedgerAccount = "unique_product_item_ledger_account",
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Product_Items_Delete_At_Path_Input = {
  metadata?: InputMaybe<Array<Scalars["String"]>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Product_Items_Delete_Elem_Input = {
  metadata?: InputMaybe<Scalars["Int"]>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Product_Items_Delete_Key_Input = {
  metadata?: InputMaybe<Scalars["String"]>;
};

/** input type for inserting data into table "product_items" */
export type Product_Items_Insert_Input = {
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  description?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  invoice_items?: InputMaybe<Invoice_Items_Arr_Rel_Insert_Input>;
  is_active?: InputMaybe<Scalars["Boolean"]>;
  ledger_account_id?: InputMaybe<Scalars["uuid"]>;
  metadata?: InputMaybe<Scalars["jsonb"]>;
  name?: InputMaybe<Scalars["String"]>;
  organisation_id?: InputMaybe<Scalars["uuid"]>;
  product?: InputMaybe<Products_Obj_Rel_Insert_Input>;
  product_id?: InputMaybe<Scalars["uuid"]>;
  sub_type?: InputMaybe<Types_Obj_Rel_Insert_Input>;
  sub_type_id?: InputMaybe<Scalars["uuid"]>;
  subscription_items?: InputMaybe<Subscription_Items_Arr_Rel_Insert_Input>;
  subscription_payment_transactions?: InputMaybe<Subscription_Payment_Transactions_Arr_Rel_Insert_Input>;
  tariff?: InputMaybe<Tariffs_Obj_Rel_Insert_Input>;
  tariff_id?: InputMaybe<Scalars["uuid"]>;
  type?: InputMaybe<Types_Obj_Rel_Insert_Input>;
  type_id?: InputMaybe<Scalars["uuid"]>;
  updated_at?: InputMaybe<Scalars["timestamptz"]>;
};

/** aggregate max on columns */
export type Product_Items_Max_Fields = {
  __typename?: "product_items_max_fields";
  created_at?: Maybe<Scalars["timestamptz"]>;
  description?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["uuid"]>;
  ledger_account_id?: Maybe<Scalars["uuid"]>;
  name?: Maybe<Scalars["String"]>;
  organisation_id?: Maybe<Scalars["uuid"]>;
  product_id?: Maybe<Scalars["uuid"]>;
  sub_type_id?: Maybe<Scalars["uuid"]>;
  tariff_id?: Maybe<Scalars["uuid"]>;
  type_id?: Maybe<Scalars["uuid"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
};

/** order by max() on columns of table "product_items" */
export type Product_Items_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  ledger_account_id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  organisation_id?: InputMaybe<Order_By>;
  product_id?: InputMaybe<Order_By>;
  sub_type_id?: InputMaybe<Order_By>;
  tariff_id?: InputMaybe<Order_By>;
  type_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Product_Items_Min_Fields = {
  __typename?: "product_items_min_fields";
  created_at?: Maybe<Scalars["timestamptz"]>;
  description?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["uuid"]>;
  ledger_account_id?: Maybe<Scalars["uuid"]>;
  name?: Maybe<Scalars["String"]>;
  organisation_id?: Maybe<Scalars["uuid"]>;
  product_id?: Maybe<Scalars["uuid"]>;
  sub_type_id?: Maybe<Scalars["uuid"]>;
  tariff_id?: Maybe<Scalars["uuid"]>;
  type_id?: Maybe<Scalars["uuid"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
};

/** order by min() on columns of table "product_items" */
export type Product_Items_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  ledger_account_id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  organisation_id?: InputMaybe<Order_By>;
  product_id?: InputMaybe<Order_By>;
  sub_type_id?: InputMaybe<Order_By>;
  tariff_id?: InputMaybe<Order_By>;
  type_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "product_items" */
export type Product_Items_Mutation_Response = {
  __typename?: "product_items_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Product_Items>;
};

/** input type for inserting object relation for remote table "product_items" */
export type Product_Items_Obj_Rel_Insert_Input = {
  data: Product_Items_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Product_Items_On_Conflict>;
};

/** on_conflict condition type for table "product_items" */
export type Product_Items_On_Conflict = {
  constraint: Product_Items_Constraint;
  update_columns?: Array<Product_Items_Update_Column>;
  where?: InputMaybe<Product_Items_Bool_Exp>;
};

/** Ordering options when selecting data from "product_items". */
export type Product_Items_Order_By = {
  created_at?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  invoice_items_aggregate?: InputMaybe<Invoice_Items_Aggregate_Order_By>;
  is_active?: InputMaybe<Order_By>;
  ledger_account_id?: InputMaybe<Order_By>;
  metadata?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  organisation_id?: InputMaybe<Order_By>;
  product?: InputMaybe<Products_Order_By>;
  product_id?: InputMaybe<Order_By>;
  sub_type?: InputMaybe<Types_Order_By>;
  sub_type_id?: InputMaybe<Order_By>;
  subscription_items_aggregate?: InputMaybe<Subscription_Items_Aggregate_Order_By>;
  subscription_payment_transactions_aggregate?: InputMaybe<Subscription_Payment_Transactions_Aggregate_Order_By>;
  tariff?: InputMaybe<Tariffs_Order_By>;
  tariff_id?: InputMaybe<Order_By>;
  type?: InputMaybe<Types_Order_By>;
  type_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: product_items */
export type Product_Items_Pk_Columns_Input = {
  id: Scalars["uuid"];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Product_Items_Prepend_Input = {
  metadata?: InputMaybe<Scalars["jsonb"]>;
};

/** select columns of table "product_items" */
export enum Product_Items_Select_Column {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  Description = "description",
  /** column name */
  Id = "id",
  /** column name */
  IsActive = "is_active",
  /** column name */
  LedgerAccountId = "ledger_account_id",
  /** column name */
  Metadata = "metadata",
  /** column name */
  Name = "name",
  /** column name */
  OrganisationId = "organisation_id",
  /** column name */
  ProductId = "product_id",
  /** column name */
  SubTypeId = "sub_type_id",
  /** column name */
  TariffId = "tariff_id",
  /** column name */
  TypeId = "type_id",
  /** column name */
  UpdatedAt = "updated_at",
}

/** select "product_items_aggregate_bool_exp_bool_and_arguments_columns" columns of table "product_items" */
export enum Product_Items_Select_Column_Product_Items_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  IsActive = "is_active",
}

/** select "product_items_aggregate_bool_exp_bool_or_arguments_columns" columns of table "product_items" */
export enum Product_Items_Select_Column_Product_Items_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  IsActive = "is_active",
}

/** input type for updating data in table "product_items" */
export type Product_Items_Set_Input = {
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  description?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  is_active?: InputMaybe<Scalars["Boolean"]>;
  ledger_account_id?: InputMaybe<Scalars["uuid"]>;
  metadata?: InputMaybe<Scalars["jsonb"]>;
  name?: InputMaybe<Scalars["String"]>;
  organisation_id?: InputMaybe<Scalars["uuid"]>;
  product_id?: InputMaybe<Scalars["uuid"]>;
  sub_type_id?: InputMaybe<Scalars["uuid"]>;
  tariff_id?: InputMaybe<Scalars["uuid"]>;
  type_id?: InputMaybe<Scalars["uuid"]>;
  updated_at?: InputMaybe<Scalars["timestamptz"]>;
};

/** Streaming cursor of the table "product_items" */
export type Product_Items_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Product_Items_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Product_Items_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  description?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  is_active?: InputMaybe<Scalars["Boolean"]>;
  ledger_account_id?: InputMaybe<Scalars["uuid"]>;
  metadata?: InputMaybe<Scalars["jsonb"]>;
  name?: InputMaybe<Scalars["String"]>;
  organisation_id?: InputMaybe<Scalars["uuid"]>;
  product_id?: InputMaybe<Scalars["uuid"]>;
  sub_type_id?: InputMaybe<Scalars["uuid"]>;
  tariff_id?: InputMaybe<Scalars["uuid"]>;
  type_id?: InputMaybe<Scalars["uuid"]>;
  updated_at?: InputMaybe<Scalars["timestamptz"]>;
};

/** update columns of table "product_items" */
export enum Product_Items_Update_Column {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  Description = "description",
  /** column name */
  Id = "id",
  /** column name */
  IsActive = "is_active",
  /** column name */
  LedgerAccountId = "ledger_account_id",
  /** column name */
  Metadata = "metadata",
  /** column name */
  Name = "name",
  /** column name */
  OrganisationId = "organisation_id",
  /** column name */
  ProductId = "product_id",
  /** column name */
  SubTypeId = "sub_type_id",
  /** column name */
  TariffId = "tariff_id",
  /** column name */
  TypeId = "type_id",
  /** column name */
  UpdatedAt = "updated_at",
}

export type Product_Items_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Product_Items_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Product_Items_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Product_Items_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Product_Items_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Product_Items_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Product_Items_Set_Input>;
  /** filter the rows which have to be updated */
  where: Product_Items_Bool_Exp;
};

/** columns and relationships of "product_rules" */
export type Product_Rules = {
  __typename?: "product_rules";
  created_at?: Maybe<Scalars["timestamptz"]>;
  id: Scalars["uuid"];
  /** An object relationship */
  product: Products;
  product_id: Scalars["uuid"];
  /** An object relationship */
  rule: Rules;
  rule_id: Scalars["uuid"];
};

/** aggregated selection of "product_rules" */
export type Product_Rules_Aggregate = {
  __typename?: "product_rules_aggregate";
  aggregate?: Maybe<Product_Rules_Aggregate_Fields>;
  nodes: Array<Product_Rules>;
};

export type Product_Rules_Aggregate_Bool_Exp = {
  count?: InputMaybe<Product_Rules_Aggregate_Bool_Exp_Count>;
};

export type Product_Rules_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Product_Rules_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<Product_Rules_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "product_rules" */
export type Product_Rules_Aggregate_Fields = {
  __typename?: "product_rules_aggregate_fields";
  count: Scalars["Int"];
  max?: Maybe<Product_Rules_Max_Fields>;
  min?: Maybe<Product_Rules_Min_Fields>;
};

/** aggregate fields of "product_rules" */
export type Product_Rules_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Product_Rules_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "product_rules" */
export type Product_Rules_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Product_Rules_Max_Order_By>;
  min?: InputMaybe<Product_Rules_Min_Order_By>;
};

/** input type for inserting array relation for remote table "product_rules" */
export type Product_Rules_Arr_Rel_Insert_Input = {
  data: Array<Product_Rules_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Product_Rules_On_Conflict>;
};

/** Boolean expression to filter rows from the table "product_rules". All fields are combined with a logical 'AND'. */
export type Product_Rules_Bool_Exp = {
  _and?: InputMaybe<Array<Product_Rules_Bool_Exp>>;
  _not?: InputMaybe<Product_Rules_Bool_Exp>;
  _or?: InputMaybe<Array<Product_Rules_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  product?: InputMaybe<Products_Bool_Exp>;
  product_id?: InputMaybe<Uuid_Comparison_Exp>;
  rule?: InputMaybe<Rules_Bool_Exp>;
  rule_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "product_rules" */
export enum Product_Rules_Constraint {
  /** unique or primary key constraint on columns "id" */
  ProductRulesPkey = "product_rules_pkey",
  /** unique or primary key constraint on columns "rule_id", "product_id" */
  UniqueProductRules = "unique_product_rules",
}

/** input type for inserting data into table "product_rules" */
export type Product_Rules_Insert_Input = {
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  product?: InputMaybe<Products_Obj_Rel_Insert_Input>;
  product_id?: InputMaybe<Scalars["uuid"]>;
  rule?: InputMaybe<Rules_Obj_Rel_Insert_Input>;
  rule_id?: InputMaybe<Scalars["uuid"]>;
};

/** aggregate max on columns */
export type Product_Rules_Max_Fields = {
  __typename?: "product_rules_max_fields";
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["uuid"]>;
  product_id?: Maybe<Scalars["uuid"]>;
  rule_id?: Maybe<Scalars["uuid"]>;
};

/** order by max() on columns of table "product_rules" */
export type Product_Rules_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  product_id?: InputMaybe<Order_By>;
  rule_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Product_Rules_Min_Fields = {
  __typename?: "product_rules_min_fields";
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["uuid"]>;
  product_id?: Maybe<Scalars["uuid"]>;
  rule_id?: Maybe<Scalars["uuid"]>;
};

/** order by min() on columns of table "product_rules" */
export type Product_Rules_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  product_id?: InputMaybe<Order_By>;
  rule_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "product_rules" */
export type Product_Rules_Mutation_Response = {
  __typename?: "product_rules_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Product_Rules>;
};

/** on_conflict condition type for table "product_rules" */
export type Product_Rules_On_Conflict = {
  constraint: Product_Rules_Constraint;
  update_columns?: Array<Product_Rules_Update_Column>;
  where?: InputMaybe<Product_Rules_Bool_Exp>;
};

/** Ordering options when selecting data from "product_rules". */
export type Product_Rules_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  product?: InputMaybe<Products_Order_By>;
  product_id?: InputMaybe<Order_By>;
  rule?: InputMaybe<Rules_Order_By>;
  rule_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: product_rules */
export type Product_Rules_Pk_Columns_Input = {
  id: Scalars["uuid"];
};

/** select columns of table "product_rules" */
export enum Product_Rules_Select_Column {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  Id = "id",
  /** column name */
  ProductId = "product_id",
  /** column name */
  RuleId = "rule_id",
}

/** input type for updating data in table "product_rules" */
export type Product_Rules_Set_Input = {
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  product_id?: InputMaybe<Scalars["uuid"]>;
  rule_id?: InputMaybe<Scalars["uuid"]>;
};

/** Streaming cursor of the table "product_rules" */
export type Product_Rules_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Product_Rules_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Product_Rules_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  product_id?: InputMaybe<Scalars["uuid"]>;
  rule_id?: InputMaybe<Scalars["uuid"]>;
};

/** update columns of table "product_rules" */
export enum Product_Rules_Update_Column {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  Id = "id",
  /** column name */
  ProductId = "product_id",
  /** column name */
  RuleId = "rule_id",
}

export type Product_Rules_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Product_Rules_Set_Input>;
  /** filter the rows which have to be updated */
  where: Product_Rules_Bool_Exp;
};

/** columns and relationships of "product_settings" */
export type Product_Settings = {
  __typename?: "product_settings";
  created_at: Scalars["timestamptz"];
  id: Scalars["uuid"];
  is_active: Scalars["Boolean"];
  name: Scalars["String"];
  /** An object relationship */
  product: Products;
  product_id: Scalars["uuid"];
  /** An object relationship */
  type: Types;
  type_id: Scalars["uuid"];
  updated_at: Scalars["timestamptz"];
  value?: Maybe<Scalars["String"]>;
};

/** aggregated selection of "product_settings" */
export type Product_Settings_Aggregate = {
  __typename?: "product_settings_aggregate";
  aggregate?: Maybe<Product_Settings_Aggregate_Fields>;
  nodes: Array<Product_Settings>;
};

export type Product_Settings_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Product_Settings_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Product_Settings_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Product_Settings_Aggregate_Bool_Exp_Count>;
};

export type Product_Settings_Aggregate_Bool_Exp_Bool_And = {
  arguments: Product_Settings_Select_Column_Product_Settings_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<Product_Settings_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Product_Settings_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Product_Settings_Select_Column_Product_Settings_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<Product_Settings_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Product_Settings_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Product_Settings_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<Product_Settings_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "product_settings" */
export type Product_Settings_Aggregate_Fields = {
  __typename?: "product_settings_aggregate_fields";
  count: Scalars["Int"];
  max?: Maybe<Product_Settings_Max_Fields>;
  min?: Maybe<Product_Settings_Min_Fields>;
};

/** aggregate fields of "product_settings" */
export type Product_Settings_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Product_Settings_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "product_settings" */
export type Product_Settings_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Product_Settings_Max_Order_By>;
  min?: InputMaybe<Product_Settings_Min_Order_By>;
};

/** input type for inserting array relation for remote table "product_settings" */
export type Product_Settings_Arr_Rel_Insert_Input = {
  data: Array<Product_Settings_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Product_Settings_On_Conflict>;
};

/** Boolean expression to filter rows from the table "product_settings". All fields are combined with a logical 'AND'. */
export type Product_Settings_Bool_Exp = {
  _and?: InputMaybe<Array<Product_Settings_Bool_Exp>>;
  _not?: InputMaybe<Product_Settings_Bool_Exp>;
  _or?: InputMaybe<Array<Product_Settings_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  is_active?: InputMaybe<Boolean_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  product?: InputMaybe<Products_Bool_Exp>;
  product_id?: InputMaybe<Uuid_Comparison_Exp>;
  type?: InputMaybe<Types_Bool_Exp>;
  type_id?: InputMaybe<Uuid_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "product_settings" */
export enum Product_Settings_Constraint {
  /** unique or primary key constraint on columns "id" */
  ProductSettingsPkey = "product_settings_pkey",
  /** unique or primary key constraint on columns "product_id", "name" */
  ProductSettingsProductIdNameKey = "product_settings_product_id_name_key",
}

/** input type for inserting data into table "product_settings" */
export type Product_Settings_Insert_Input = {
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  is_active?: InputMaybe<Scalars["Boolean"]>;
  name?: InputMaybe<Scalars["String"]>;
  product?: InputMaybe<Products_Obj_Rel_Insert_Input>;
  product_id?: InputMaybe<Scalars["uuid"]>;
  type?: InputMaybe<Types_Obj_Rel_Insert_Input>;
  type_id?: InputMaybe<Scalars["uuid"]>;
  updated_at?: InputMaybe<Scalars["timestamptz"]>;
  value?: InputMaybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Product_Settings_Max_Fields = {
  __typename?: "product_settings_max_fields";
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["uuid"]>;
  name?: Maybe<Scalars["String"]>;
  product_id?: Maybe<Scalars["uuid"]>;
  type_id?: Maybe<Scalars["uuid"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
  value?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "product_settings" */
export type Product_Settings_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  product_id?: InputMaybe<Order_By>;
  type_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Product_Settings_Min_Fields = {
  __typename?: "product_settings_min_fields";
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["uuid"]>;
  name?: Maybe<Scalars["String"]>;
  product_id?: Maybe<Scalars["uuid"]>;
  type_id?: Maybe<Scalars["uuid"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
  value?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "product_settings" */
export type Product_Settings_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  product_id?: InputMaybe<Order_By>;
  type_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "product_settings" */
export type Product_Settings_Mutation_Response = {
  __typename?: "product_settings_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Product_Settings>;
};

/** on_conflict condition type for table "product_settings" */
export type Product_Settings_On_Conflict = {
  constraint: Product_Settings_Constraint;
  update_columns?: Array<Product_Settings_Update_Column>;
  where?: InputMaybe<Product_Settings_Bool_Exp>;
};

/** Ordering options when selecting data from "product_settings". */
export type Product_Settings_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  is_active?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  product?: InputMaybe<Products_Order_By>;
  product_id?: InputMaybe<Order_By>;
  type?: InputMaybe<Types_Order_By>;
  type_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: product_settings */
export type Product_Settings_Pk_Columns_Input = {
  id: Scalars["uuid"];
};

/** select columns of table "product_settings" */
export enum Product_Settings_Select_Column {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  Id = "id",
  /** column name */
  IsActive = "is_active",
  /** column name */
  Name = "name",
  /** column name */
  ProductId = "product_id",
  /** column name */
  TypeId = "type_id",
  /** column name */
  UpdatedAt = "updated_at",
  /** column name */
  Value = "value",
}

/** select "product_settings_aggregate_bool_exp_bool_and_arguments_columns" columns of table "product_settings" */
export enum Product_Settings_Select_Column_Product_Settings_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  IsActive = "is_active",
}

/** select "product_settings_aggregate_bool_exp_bool_or_arguments_columns" columns of table "product_settings" */
export enum Product_Settings_Select_Column_Product_Settings_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  IsActive = "is_active",
}

/** input type for updating data in table "product_settings" */
export type Product_Settings_Set_Input = {
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  is_active?: InputMaybe<Scalars["Boolean"]>;
  name?: InputMaybe<Scalars["String"]>;
  product_id?: InputMaybe<Scalars["uuid"]>;
  type_id?: InputMaybe<Scalars["uuid"]>;
  updated_at?: InputMaybe<Scalars["timestamptz"]>;
  value?: InputMaybe<Scalars["String"]>;
};

/** Streaming cursor of the table "product_settings" */
export type Product_Settings_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Product_Settings_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Product_Settings_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  is_active?: InputMaybe<Scalars["Boolean"]>;
  name?: InputMaybe<Scalars["String"]>;
  product_id?: InputMaybe<Scalars["uuid"]>;
  type_id?: InputMaybe<Scalars["uuid"]>;
  updated_at?: InputMaybe<Scalars["timestamptz"]>;
  value?: InputMaybe<Scalars["String"]>;
};

/** update columns of table "product_settings" */
export enum Product_Settings_Update_Column {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  Id = "id",
  /** column name */
  IsActive = "is_active",
  /** column name */
  Name = "name",
  /** column name */
  ProductId = "product_id",
  /** column name */
  TypeId = "type_id",
  /** column name */
  UpdatedAt = "updated_at",
  /** column name */
  Value = "value",
}

export type Product_Settings_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Product_Settings_Set_Input>;
  /** filter the rows which have to be updated */
  where: Product_Settings_Bool_Exp;
};

/** columns and relationships of "product_statuses" */
export type Product_Statuses = {
  __typename?: "product_statuses";
  created_at?: Maybe<Scalars["timestamptz"]>;
  id: Scalars["uuid"];
  /** An object relationship */
  product: Products;
  product_id: Scalars["uuid"];
  /** An object relationship */
  status: Statuses;
  status_id: Scalars["uuid"];
};

/** aggregated selection of "product_statuses" */
export type Product_Statuses_Aggregate = {
  __typename?: "product_statuses_aggregate";
  aggregate?: Maybe<Product_Statuses_Aggregate_Fields>;
  nodes: Array<Product_Statuses>;
};

export type Product_Statuses_Aggregate_Bool_Exp = {
  count?: InputMaybe<Product_Statuses_Aggregate_Bool_Exp_Count>;
};

export type Product_Statuses_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Product_Statuses_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<Product_Statuses_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "product_statuses" */
export type Product_Statuses_Aggregate_Fields = {
  __typename?: "product_statuses_aggregate_fields";
  count: Scalars["Int"];
  max?: Maybe<Product_Statuses_Max_Fields>;
  min?: Maybe<Product_Statuses_Min_Fields>;
};

/** aggregate fields of "product_statuses" */
export type Product_Statuses_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Product_Statuses_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "product_statuses" */
export type Product_Statuses_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Product_Statuses_Max_Order_By>;
  min?: InputMaybe<Product_Statuses_Min_Order_By>;
};

/** input type for inserting array relation for remote table "product_statuses" */
export type Product_Statuses_Arr_Rel_Insert_Input = {
  data: Array<Product_Statuses_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Product_Statuses_On_Conflict>;
};

/** Boolean expression to filter rows from the table "product_statuses". All fields are combined with a logical 'AND'. */
export type Product_Statuses_Bool_Exp = {
  _and?: InputMaybe<Array<Product_Statuses_Bool_Exp>>;
  _not?: InputMaybe<Product_Statuses_Bool_Exp>;
  _or?: InputMaybe<Array<Product_Statuses_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  product?: InputMaybe<Products_Bool_Exp>;
  product_id?: InputMaybe<Uuid_Comparison_Exp>;
  status?: InputMaybe<Statuses_Bool_Exp>;
  status_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "product_statuses" */
export enum Product_Statuses_Constraint {
  /** unique or primary key constraint on columns "id" */
  ProductStatusesPkey = "product_statuses_pkey",
}

/** input type for inserting data into table "product_statuses" */
export type Product_Statuses_Insert_Input = {
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  product?: InputMaybe<Products_Obj_Rel_Insert_Input>;
  product_id?: InputMaybe<Scalars["uuid"]>;
  status?: InputMaybe<Statuses_Obj_Rel_Insert_Input>;
  status_id?: InputMaybe<Scalars["uuid"]>;
};

/** aggregate max on columns */
export type Product_Statuses_Max_Fields = {
  __typename?: "product_statuses_max_fields";
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["uuid"]>;
  product_id?: Maybe<Scalars["uuid"]>;
  status_id?: Maybe<Scalars["uuid"]>;
};

/** order by max() on columns of table "product_statuses" */
export type Product_Statuses_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  product_id?: InputMaybe<Order_By>;
  status_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Product_Statuses_Min_Fields = {
  __typename?: "product_statuses_min_fields";
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["uuid"]>;
  product_id?: Maybe<Scalars["uuid"]>;
  status_id?: Maybe<Scalars["uuid"]>;
};

/** order by min() on columns of table "product_statuses" */
export type Product_Statuses_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  product_id?: InputMaybe<Order_By>;
  status_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "product_statuses" */
export type Product_Statuses_Mutation_Response = {
  __typename?: "product_statuses_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Product_Statuses>;
};

/** on_conflict condition type for table "product_statuses" */
export type Product_Statuses_On_Conflict = {
  constraint: Product_Statuses_Constraint;
  update_columns?: Array<Product_Statuses_Update_Column>;
  where?: InputMaybe<Product_Statuses_Bool_Exp>;
};

/** Ordering options when selecting data from "product_statuses". */
export type Product_Statuses_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  product?: InputMaybe<Products_Order_By>;
  product_id?: InputMaybe<Order_By>;
  status?: InputMaybe<Statuses_Order_By>;
  status_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: product_statuses */
export type Product_Statuses_Pk_Columns_Input = {
  id: Scalars["uuid"];
};

/** select columns of table "product_statuses" */
export enum Product_Statuses_Select_Column {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  Id = "id",
  /** column name */
  ProductId = "product_id",
  /** column name */
  StatusId = "status_id",
}

/** input type for updating data in table "product_statuses" */
export type Product_Statuses_Set_Input = {
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  product_id?: InputMaybe<Scalars["uuid"]>;
  status_id?: InputMaybe<Scalars["uuid"]>;
};

/** Streaming cursor of the table "product_statuses" */
export type Product_Statuses_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Product_Statuses_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Product_Statuses_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  product_id?: InputMaybe<Scalars["uuid"]>;
  status_id?: InputMaybe<Scalars["uuid"]>;
};

/** update columns of table "product_statuses" */
export enum Product_Statuses_Update_Column {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  Id = "id",
  /** column name */
  ProductId = "product_id",
  /** column name */
  StatusId = "status_id",
}

export type Product_Statuses_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Product_Statuses_Set_Input>;
  /** filter the rows which have to be updated */
  where: Product_Statuses_Bool_Exp;
};

/** columns and relationships of "product_templates" */
export type Product_Templates = {
  __typename?: "product_templates";
  created_at: Scalars["timestamptz"];
  id: Scalars["uuid"];
  product_id: Scalars["uuid"];
  template: Scalars["jsonb"];
  /** An object relationship */
  type: Types;
  type_id: Scalars["uuid"];
  updated_at: Scalars["timestamptz"];
};

/** columns and relationships of "product_templates" */
export type Product_TemplatesTemplateArgs = {
  path?: InputMaybe<Scalars["String"]>;
};

/** aggregated selection of "product_templates" */
export type Product_Templates_Aggregate = {
  __typename?: "product_templates_aggregate";
  aggregate?: Maybe<Product_Templates_Aggregate_Fields>;
  nodes: Array<Product_Templates>;
};

/** aggregate fields of "product_templates" */
export type Product_Templates_Aggregate_Fields = {
  __typename?: "product_templates_aggregate_fields";
  count: Scalars["Int"];
  max?: Maybe<Product_Templates_Max_Fields>;
  min?: Maybe<Product_Templates_Min_Fields>;
};

/** aggregate fields of "product_templates" */
export type Product_Templates_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Product_Templates_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Product_Templates_Append_Input = {
  template?: InputMaybe<Scalars["jsonb"]>;
};

/** Boolean expression to filter rows from the table "product_templates". All fields are combined with a logical 'AND'. */
export type Product_Templates_Bool_Exp = {
  _and?: InputMaybe<Array<Product_Templates_Bool_Exp>>;
  _not?: InputMaybe<Product_Templates_Bool_Exp>;
  _or?: InputMaybe<Array<Product_Templates_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  product_id?: InputMaybe<Uuid_Comparison_Exp>;
  template?: InputMaybe<Jsonb_Comparison_Exp>;
  type?: InputMaybe<Types_Bool_Exp>;
  type_id?: InputMaybe<Uuid_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "product_templates" */
export enum Product_Templates_Constraint {
  /** unique or primary key constraint on columns "id" */
  ProductTemplatesPkey = "product_templates_pkey",
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Product_Templates_Delete_At_Path_Input = {
  template?: InputMaybe<Array<Scalars["String"]>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Product_Templates_Delete_Elem_Input = {
  template?: InputMaybe<Scalars["Int"]>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Product_Templates_Delete_Key_Input = {
  template?: InputMaybe<Scalars["String"]>;
};

/** input type for inserting data into table "product_templates" */
export type Product_Templates_Insert_Input = {
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  product_id?: InputMaybe<Scalars["uuid"]>;
  template?: InputMaybe<Scalars["jsonb"]>;
  type?: InputMaybe<Types_Obj_Rel_Insert_Input>;
  type_id?: InputMaybe<Scalars["uuid"]>;
  updated_at?: InputMaybe<Scalars["timestamptz"]>;
};

/** aggregate max on columns */
export type Product_Templates_Max_Fields = {
  __typename?: "product_templates_max_fields";
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["uuid"]>;
  product_id?: Maybe<Scalars["uuid"]>;
  type_id?: Maybe<Scalars["uuid"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
};

/** aggregate min on columns */
export type Product_Templates_Min_Fields = {
  __typename?: "product_templates_min_fields";
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["uuid"]>;
  product_id?: Maybe<Scalars["uuid"]>;
  type_id?: Maybe<Scalars["uuid"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
};

/** response of any mutation on the table "product_templates" */
export type Product_Templates_Mutation_Response = {
  __typename?: "product_templates_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Product_Templates>;
};

/** on_conflict condition type for table "product_templates" */
export type Product_Templates_On_Conflict = {
  constraint: Product_Templates_Constraint;
  update_columns?: Array<Product_Templates_Update_Column>;
  where?: InputMaybe<Product_Templates_Bool_Exp>;
};

/** Ordering options when selecting data from "product_templates". */
export type Product_Templates_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  product_id?: InputMaybe<Order_By>;
  template?: InputMaybe<Order_By>;
  type?: InputMaybe<Types_Order_By>;
  type_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: product_templates */
export type Product_Templates_Pk_Columns_Input = {
  id: Scalars["uuid"];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Product_Templates_Prepend_Input = {
  template?: InputMaybe<Scalars["jsonb"]>;
};

/** select columns of table "product_templates" */
export enum Product_Templates_Select_Column {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  Id = "id",
  /** column name */
  ProductId = "product_id",
  /** column name */
  Template = "template",
  /** column name */
  TypeId = "type_id",
  /** column name */
  UpdatedAt = "updated_at",
}

/** input type for updating data in table "product_templates" */
export type Product_Templates_Set_Input = {
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  product_id?: InputMaybe<Scalars["uuid"]>;
  template?: InputMaybe<Scalars["jsonb"]>;
  type_id?: InputMaybe<Scalars["uuid"]>;
  updated_at?: InputMaybe<Scalars["timestamptz"]>;
};

/** Streaming cursor of the table "product_templates" */
export type Product_Templates_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Product_Templates_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Product_Templates_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  product_id?: InputMaybe<Scalars["uuid"]>;
  template?: InputMaybe<Scalars["jsonb"]>;
  type_id?: InputMaybe<Scalars["uuid"]>;
  updated_at?: InputMaybe<Scalars["timestamptz"]>;
};

/** update columns of table "product_templates" */
export enum Product_Templates_Update_Column {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  Id = "id",
  /** column name */
  ProductId = "product_id",
  /** column name */
  Template = "template",
  /** column name */
  TypeId = "type_id",
  /** column name */
  UpdatedAt = "updated_at",
}

export type Product_Templates_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Product_Templates_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Product_Templates_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Product_Templates_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Product_Templates_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Product_Templates_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Product_Templates_Set_Input>;
  /** filter the rows which have to be updated */
  where: Product_Templates_Bool_Exp;
};

/** columns and relationships of "products" */
export type Products = {
  __typename?: "products";
  /** An object relationship */
  active_subscriptions?: Maybe<Active_Product_Subscriptions>;
  created_at: Scalars["timestamptz"];
  description: Scalars["String"];
  id: Scalars["uuid"];
  /** An array relationship */
  invoices: Array<Invoices>;
  /** An aggregate relationship */
  invoices_aggregate: Invoices_Aggregate;
  is_active: Scalars["Boolean"];
  ledger_id?: Maybe<Scalars["uuid"]>;
  mom_growth_report: MoMGrowthReport;
  name: Scalars["String"];
  organisation_id: Scalars["uuid"];
  /** An array relationship */
  product_documents: Array<Product_Documents>;
  /** An aggregate relationship */
  product_documents_aggregate: Product_Documents_Aggregate;
  /** An array relationship */
  product_interactions: Array<Product_Interactions>;
  /** An aggregate relationship */
  product_interactions_aggregate: Product_Interactions_Aggregate;
  /** An array relationship */
  product_items: Array<Product_Items>;
  /** An aggregate relationship */
  product_items_aggregate: Product_Items_Aggregate;
  /** An array relationship */
  product_rules: Array<Product_Rules>;
  /** An aggregate relationship */
  product_rules_aggregate: Product_Rules_Aggregate;
  /** An array relationship */
  product_settings: Array<Product_Settings>;
  /** An aggregate relationship */
  product_settings_aggregate: Product_Settings_Aggregate;
  /** An array relationship */
  product_statuses: Array<Product_Statuses>;
  /** An aggregate relationship */
  product_statuses_aggregate: Product_Statuses_Aggregate;
  /** An object relationship */
  sub_type?: Maybe<Types>;
  sub_type_id?: Maybe<Scalars["uuid"]>;
  subscription_items: Array<Subscription_Items>;
  subscription_items_aggregate: Subscription_Items_Aggregate;
  /** An array relationship */
  subscription_payments: Array<Subscription_Payments>;
  /** An aggregate relationship */
  subscription_payments_aggregate: Subscription_Payments_Aggregate;
  /** An array relationship */
  subscriptions: Array<Subscriptions>;
  /** An aggregate relationship */
  subscriptions_aggregate: Subscriptions_Aggregate;
  /** An object relationship */
  type: Types;
  type_id: Scalars["uuid"];
  updated_at: Scalars["timestamptz"];
};

/** columns and relationships of "products" */
export type ProductsInvoicesArgs = {
  distinct_on?: InputMaybe<Array<Invoices_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Invoices_Order_By>>;
  where?: InputMaybe<Invoices_Bool_Exp>;
};

/** columns and relationships of "products" */
export type ProductsInvoices_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Invoices_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Invoices_Order_By>>;
  where?: InputMaybe<Invoices_Bool_Exp>;
};

/** columns and relationships of "products" */
export type ProductsMom_Growth_ReportArgs = {
  period?: InputMaybe<Array<Scalars["String"]>>;
};

/** columns and relationships of "products" */
export type ProductsProduct_DocumentsArgs = {
  distinct_on?: InputMaybe<Array<Product_Documents_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Product_Documents_Order_By>>;
  where?: InputMaybe<Product_Documents_Bool_Exp>;
};

/** columns and relationships of "products" */
export type ProductsProduct_Documents_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Product_Documents_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Product_Documents_Order_By>>;
  where?: InputMaybe<Product_Documents_Bool_Exp>;
};

/** columns and relationships of "products" */
export type ProductsProduct_InteractionsArgs = {
  distinct_on?: InputMaybe<Array<Product_Interactions_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Product_Interactions_Order_By>>;
  where?: InputMaybe<Product_Interactions_Bool_Exp>;
};

/** columns and relationships of "products" */
export type ProductsProduct_Interactions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Product_Interactions_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Product_Interactions_Order_By>>;
  where?: InputMaybe<Product_Interactions_Bool_Exp>;
};

/** columns and relationships of "products" */
export type ProductsProduct_ItemsArgs = {
  distinct_on?: InputMaybe<Array<Product_Items_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Product_Items_Order_By>>;
  where?: InputMaybe<Product_Items_Bool_Exp>;
};

/** columns and relationships of "products" */
export type ProductsProduct_Items_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Product_Items_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Product_Items_Order_By>>;
  where?: InputMaybe<Product_Items_Bool_Exp>;
};

/** columns and relationships of "products" */
export type ProductsProduct_RulesArgs = {
  distinct_on?: InputMaybe<Array<Product_Rules_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Product_Rules_Order_By>>;
  where?: InputMaybe<Product_Rules_Bool_Exp>;
};

/** columns and relationships of "products" */
export type ProductsProduct_Rules_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Product_Rules_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Product_Rules_Order_By>>;
  where?: InputMaybe<Product_Rules_Bool_Exp>;
};

/** columns and relationships of "products" */
export type ProductsProduct_SettingsArgs = {
  distinct_on?: InputMaybe<Array<Product_Settings_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Product_Settings_Order_By>>;
  where?: InputMaybe<Product_Settings_Bool_Exp>;
};

/** columns and relationships of "products" */
export type ProductsProduct_Settings_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Product_Settings_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Product_Settings_Order_By>>;
  where?: InputMaybe<Product_Settings_Bool_Exp>;
};

/** columns and relationships of "products" */
export type ProductsProduct_StatusesArgs = {
  distinct_on?: InputMaybe<Array<Product_Statuses_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Product_Statuses_Order_By>>;
  where?: InputMaybe<Product_Statuses_Bool_Exp>;
};

/** columns and relationships of "products" */
export type ProductsProduct_Statuses_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Product_Statuses_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Product_Statuses_Order_By>>;
  where?: InputMaybe<Product_Statuses_Bool_Exp>;
};

/** columns and relationships of "products" */
export type ProductsSubscription_ItemsArgs = {
  distinct_on?: InputMaybe<Array<Subscription_Items_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Subscription_Items_Order_By>>;
  where?: InputMaybe<Subscription_Items_Bool_Exp>;
};

/** columns and relationships of "products" */
export type ProductsSubscription_Items_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Subscription_Items_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Subscription_Items_Order_By>>;
  where?: InputMaybe<Subscription_Items_Bool_Exp>;
};

/** columns and relationships of "products" */
export type ProductsSubscription_PaymentsArgs = {
  distinct_on?: InputMaybe<Array<Subscription_Payments_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Subscription_Payments_Order_By>>;
  where?: InputMaybe<Subscription_Payments_Bool_Exp>;
};

/** columns and relationships of "products" */
export type ProductsSubscription_Payments_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Subscription_Payments_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Subscription_Payments_Order_By>>;
  where?: InputMaybe<Subscription_Payments_Bool_Exp>;
};

/** columns and relationships of "products" */
export type ProductsSubscriptionsArgs = {
  distinct_on?: InputMaybe<Array<Subscriptions_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Subscriptions_Order_By>>;
  where?: InputMaybe<Subscriptions_Bool_Exp>;
};

/** columns and relationships of "products" */
export type ProductsSubscriptions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Subscriptions_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Subscriptions_Order_By>>;
  where?: InputMaybe<Subscriptions_Bool_Exp>;
};

/** aggregated selection of "products" */
export type Products_Aggregate = {
  __typename?: "products_aggregate";
  aggregate?: Maybe<Products_Aggregate_Fields>;
  nodes: Array<Products>;
};

export type Products_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Products_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Products_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Products_Aggregate_Bool_Exp_Count>;
};

export type Products_Aggregate_Bool_Exp_Bool_And = {
  arguments: Products_Select_Column_Products_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<Products_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Products_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Products_Select_Column_Products_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<Products_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Products_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Products_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<Products_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "products" */
export type Products_Aggregate_Fields = {
  __typename?: "products_aggregate_fields";
  count: Scalars["Int"];
  max?: Maybe<Products_Max_Fields>;
  min?: Maybe<Products_Min_Fields>;
};

/** aggregate fields of "products" */
export type Products_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Products_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "products" */
export type Products_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Products_Max_Order_By>;
  min?: InputMaybe<Products_Min_Order_By>;
};

/** input type for inserting array relation for remote table "products" */
export type Products_Arr_Rel_Insert_Input = {
  data: Array<Products_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Products_On_Conflict>;
};

/** Boolean expression to filter rows from the table "products". All fields are combined with a logical 'AND'. */
export type Products_Bool_Exp = {
  _and?: InputMaybe<Array<Products_Bool_Exp>>;
  _not?: InputMaybe<Products_Bool_Exp>;
  _or?: InputMaybe<Array<Products_Bool_Exp>>;
  active_subscriptions?: InputMaybe<Active_Product_Subscriptions_Bool_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  invoices?: InputMaybe<Invoices_Bool_Exp>;
  invoices_aggregate?: InputMaybe<Invoices_Aggregate_Bool_Exp>;
  is_active?: InputMaybe<Boolean_Comparison_Exp>;
  ledger_id?: InputMaybe<Uuid_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  organisation_id?: InputMaybe<Uuid_Comparison_Exp>;
  product_documents?: InputMaybe<Product_Documents_Bool_Exp>;
  product_documents_aggregate?: InputMaybe<Product_Documents_Aggregate_Bool_Exp>;
  product_interactions?: InputMaybe<Product_Interactions_Bool_Exp>;
  product_interactions_aggregate?: InputMaybe<Product_Interactions_Aggregate_Bool_Exp>;
  product_items?: InputMaybe<Product_Items_Bool_Exp>;
  product_items_aggregate?: InputMaybe<Product_Items_Aggregate_Bool_Exp>;
  product_rules?: InputMaybe<Product_Rules_Bool_Exp>;
  product_rules_aggregate?: InputMaybe<Product_Rules_Aggregate_Bool_Exp>;
  product_settings?: InputMaybe<Product_Settings_Bool_Exp>;
  product_settings_aggregate?: InputMaybe<Product_Settings_Aggregate_Bool_Exp>;
  product_statuses?: InputMaybe<Product_Statuses_Bool_Exp>;
  product_statuses_aggregate?: InputMaybe<Product_Statuses_Aggregate_Bool_Exp>;
  sub_type?: InputMaybe<Types_Bool_Exp>;
  sub_type_id?: InputMaybe<Uuid_Comparison_Exp>;
  subscription_items?: InputMaybe<Subscription_Items_Bool_Exp>;
  subscription_items_aggregate?: InputMaybe<Subscription_Items_Aggregate_Bool_Exp>;
  subscription_payments?: InputMaybe<Subscription_Payments_Bool_Exp>;
  subscription_payments_aggregate?: InputMaybe<Subscription_Payments_Aggregate_Bool_Exp>;
  subscriptions?: InputMaybe<Subscriptions_Bool_Exp>;
  subscriptions_aggregate?: InputMaybe<Subscriptions_Aggregate_Bool_Exp>;
  type?: InputMaybe<Types_Bool_Exp>;
  type_id?: InputMaybe<Uuid_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "products" */
export enum Products_Constraint {
  /** unique or primary key constraint on columns "id" */
  ProductsPkey = "products_pkey",
  /** unique or primary key constraint on columns "ledger_id" */
  UniqueProductLedger = "unique_product_ledger",
}

/** input type for inserting data into table "products" */
export type Products_Insert_Input = {
  active_subscriptions?: InputMaybe<Active_Product_Subscriptions_Obj_Rel_Insert_Input>;
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  description?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  invoices?: InputMaybe<Invoices_Arr_Rel_Insert_Input>;
  is_active?: InputMaybe<Scalars["Boolean"]>;
  ledger_id?: InputMaybe<Scalars["uuid"]>;
  name?: InputMaybe<Scalars["String"]>;
  organisation_id?: InputMaybe<Scalars["uuid"]>;
  product_documents?: InputMaybe<Product_Documents_Arr_Rel_Insert_Input>;
  product_interactions?: InputMaybe<Product_Interactions_Arr_Rel_Insert_Input>;
  product_items?: InputMaybe<Product_Items_Arr_Rel_Insert_Input>;
  product_rules?: InputMaybe<Product_Rules_Arr_Rel_Insert_Input>;
  product_settings?: InputMaybe<Product_Settings_Arr_Rel_Insert_Input>;
  product_statuses?: InputMaybe<Product_Statuses_Arr_Rel_Insert_Input>;
  sub_type?: InputMaybe<Types_Obj_Rel_Insert_Input>;
  sub_type_id?: InputMaybe<Scalars["uuid"]>;
  subscription_items?: InputMaybe<Subscription_Items_Arr_Rel_Insert_Input>;
  subscription_payments?: InputMaybe<Subscription_Payments_Arr_Rel_Insert_Input>;
  subscriptions?: InputMaybe<Subscriptions_Arr_Rel_Insert_Input>;
  type?: InputMaybe<Types_Obj_Rel_Insert_Input>;
  type_id?: InputMaybe<Scalars["uuid"]>;
  updated_at?: InputMaybe<Scalars["timestamptz"]>;
};

/** aggregate max on columns */
export type Products_Max_Fields = {
  __typename?: "products_max_fields";
  created_at?: Maybe<Scalars["timestamptz"]>;
  description?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["uuid"]>;
  ledger_id?: Maybe<Scalars["uuid"]>;
  name?: Maybe<Scalars["String"]>;
  organisation_id?: Maybe<Scalars["uuid"]>;
  sub_type_id?: Maybe<Scalars["uuid"]>;
  type_id?: Maybe<Scalars["uuid"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
};

/** order by max() on columns of table "products" */
export type Products_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  ledger_id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  organisation_id?: InputMaybe<Order_By>;
  sub_type_id?: InputMaybe<Order_By>;
  type_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Products_Min_Fields = {
  __typename?: "products_min_fields";
  created_at?: Maybe<Scalars["timestamptz"]>;
  description?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["uuid"]>;
  ledger_id?: Maybe<Scalars["uuid"]>;
  name?: Maybe<Scalars["String"]>;
  organisation_id?: Maybe<Scalars["uuid"]>;
  sub_type_id?: Maybe<Scalars["uuid"]>;
  type_id?: Maybe<Scalars["uuid"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
};

/** order by min() on columns of table "products" */
export type Products_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  ledger_id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  organisation_id?: InputMaybe<Order_By>;
  sub_type_id?: InputMaybe<Order_By>;
  type_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "products" */
export type Products_Mutation_Response = {
  __typename?: "products_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Products>;
};

/** input type for inserting object relation for remote table "products" */
export type Products_Obj_Rel_Insert_Input = {
  data: Products_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Products_On_Conflict>;
};

/** on_conflict condition type for table "products" */
export type Products_On_Conflict = {
  constraint: Products_Constraint;
  update_columns?: Array<Products_Update_Column>;
  where?: InputMaybe<Products_Bool_Exp>;
};

/** Ordering options when selecting data from "products". */
export type Products_Order_By = {
  active_subscriptions?: InputMaybe<Active_Product_Subscriptions_Order_By>;
  created_at?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  invoices_aggregate?: InputMaybe<Invoices_Aggregate_Order_By>;
  is_active?: InputMaybe<Order_By>;
  ledger_id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  organisation_id?: InputMaybe<Order_By>;
  product_documents_aggregate?: InputMaybe<Product_Documents_Aggregate_Order_By>;
  product_interactions_aggregate?: InputMaybe<Product_Interactions_Aggregate_Order_By>;
  product_items_aggregate?: InputMaybe<Product_Items_Aggregate_Order_By>;
  product_rules_aggregate?: InputMaybe<Product_Rules_Aggregate_Order_By>;
  product_settings_aggregate?: InputMaybe<Product_Settings_Aggregate_Order_By>;
  product_statuses_aggregate?: InputMaybe<Product_Statuses_Aggregate_Order_By>;
  sub_type?: InputMaybe<Types_Order_By>;
  sub_type_id?: InputMaybe<Order_By>;
  subscription_items_aggregate?: InputMaybe<Subscription_Items_Aggregate_Order_By>;
  subscription_payments_aggregate?: InputMaybe<Subscription_Payments_Aggregate_Order_By>;
  subscriptions_aggregate?: InputMaybe<Subscriptions_Aggregate_Order_By>;
  type?: InputMaybe<Types_Order_By>;
  type_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: products */
export type Products_Pk_Columns_Input = {
  id: Scalars["uuid"];
};

/** select columns of table "products" */
export enum Products_Select_Column {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  Description = "description",
  /** column name */
  Id = "id",
  /** column name */
  IsActive = "is_active",
  /** column name */
  LedgerId = "ledger_id",
  /** column name */
  Name = "name",
  /** column name */
  OrganisationId = "organisation_id",
  /** column name */
  SubTypeId = "sub_type_id",
  /** column name */
  TypeId = "type_id",
  /** column name */
  UpdatedAt = "updated_at",
}

/** select "products_aggregate_bool_exp_bool_and_arguments_columns" columns of table "products" */
export enum Products_Select_Column_Products_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  IsActive = "is_active",
}

/** select "products_aggregate_bool_exp_bool_or_arguments_columns" columns of table "products" */
export enum Products_Select_Column_Products_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  IsActive = "is_active",
}

/** input type for updating data in table "products" */
export type Products_Set_Input = {
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  description?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  is_active?: InputMaybe<Scalars["Boolean"]>;
  ledger_id?: InputMaybe<Scalars["uuid"]>;
  name?: InputMaybe<Scalars["String"]>;
  organisation_id?: InputMaybe<Scalars["uuid"]>;
  sub_type_id?: InputMaybe<Scalars["uuid"]>;
  type_id?: InputMaybe<Scalars["uuid"]>;
  updated_at?: InputMaybe<Scalars["timestamptz"]>;
};

/** Streaming cursor of the table "products" */
export type Products_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Products_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Products_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  description?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  is_active?: InputMaybe<Scalars["Boolean"]>;
  ledger_id?: InputMaybe<Scalars["uuid"]>;
  name?: InputMaybe<Scalars["String"]>;
  organisation_id?: InputMaybe<Scalars["uuid"]>;
  sub_type_id?: InputMaybe<Scalars["uuid"]>;
  type_id?: InputMaybe<Scalars["uuid"]>;
  updated_at?: InputMaybe<Scalars["timestamptz"]>;
};

/** update columns of table "products" */
export enum Products_Update_Column {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  Description = "description",
  /** column name */
  Id = "id",
  /** column name */
  IsActive = "is_active",
  /** column name */
  LedgerId = "ledger_id",
  /** column name */
  Name = "name",
  /** column name */
  OrganisationId = "organisation_id",
  /** column name */
  SubTypeId = "sub_type_id",
  /** column name */
  TypeId = "type_id",
  /** column name */
  UpdatedAt = "updated_at",
}

export type Products_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Products_Set_Input>;
  /** filter the rows which have to be updated */
  where: Products_Bool_Exp;
};

export type Query_Root = {
  __typename?: "query_root";
  /** fetch data from the table: "_case_dod_data" */
  _case_dod_data: Array<_Case_Dod_Data>;
  /** fetch aggregated fields from the table: "_case_dod_data" */
  _case_dod_data_aggregate: _Case_Dod_Data_Aggregate;
  /** fetch data from the table: "_closed_cases_dod_data" */
  _closed_cases_dod_data: Array<_Closed_Cases_Dod_Data>;
  /** fetch aggregated fields from the table: "_closed_cases_dod_data" */
  _closed_cases_dod_data_aggregate: _Closed_Cases_Dod_Data_Aggregate;
  /** fetch data from the table: "_cus_growth_data" */
  _cus_growth_data: Array<_Cus_Growth_Data>;
  /** fetch aggregated fields from the table: "_cus_growth_data" */
  _cus_growth_data_aggregate: _Cus_Growth_Data_Aggregate;
  /** fetch data from the table: "active_product_subscriptions" */
  active_product_subscriptions: Array<Active_Product_Subscriptions>;
  /** fetch aggregated fields from the table: "active_product_subscriptions" */
  active_product_subscriptions_aggregate: Active_Product_Subscriptions_Aggregate;
  /** An array relationship */
  activity_logs: Array<Activity_Logs>;
  /** An aggregate relationship */
  activity_logs_aggregate: Activity_Logs_Aggregate;
  /** fetch data from the table: "activity_logs" using primary key columns */
  activity_logs_by_pk?: Maybe<Activity_Logs>;
  /** An array relationship */
  audit_logs: Array<Audit_Logs>;
  /** An aggregate relationship */
  audit_logs_aggregate: Audit_Logs_Aggregate;
  /** fetch data from the table: "audit_logs" using primary key columns */
  audit_logs_by_pk?: Maybe<Audit_Logs>;
  /** An array relationship */
  campaign_contacts: Array<Campaign_Contacts>;
  /** An aggregate relationship */
  campaign_contacts_aggregate: Campaign_Contacts_Aggregate;
  /** fetch data from the table: "campaign_contacts" using primary key columns */
  campaign_contacts_by_pk?: Maybe<Campaign_Contacts>;
  /** An array relationship */
  campaign_performance_indicators: Array<Campaign_Performance_Indicators>;
  /** An aggregate relationship */
  campaign_performance_indicators_aggregate: Campaign_Performance_Indicators_Aggregate;
  /** fetch data from the table: "campaign_performance_indicators" using primary key columns */
  campaign_performance_indicators_by_pk?: Maybe<Campaign_Performance_Indicators>;
  /** An array relationship */
  campaign_rewards: Array<Campaign_Rewards>;
  /** An aggregate relationship */
  campaign_rewards_aggregate: Campaign_Rewards_Aggregate;
  /** fetch data from the table: "campaign_rewards" using primary key columns */
  campaign_rewards_by_pk?: Maybe<Campaign_Rewards>;
  /** An array relationship */
  campaign_rules: Array<Campaign_Rules>;
  /** An aggregate relationship */
  campaign_rules_aggregate: Campaign_Rules_Aggregate;
  /** fetch data from the table: "campaign_rules" using primary key columns */
  campaign_rules_by_pk?: Maybe<Campaign_Rules>;
  /** An array relationship */
  campaign_tasks: Array<Campaign_Tasks>;
  /** An aggregate relationship */
  campaign_tasks_aggregate: Campaign_Tasks_Aggregate;
  /** fetch data from the table: "campaign_tasks" using primary key columns */
  campaign_tasks_by_pk?: Maybe<Campaign_Tasks>;
  /** An array relationship */
  campaign_workflows: Array<Campaign_Workflows>;
  /** An aggregate relationship */
  campaign_workflows_aggregate: Campaign_Workflows_Aggregate;
  /** fetch data from the table: "campaign_workflows" using primary key columns */
  campaign_workflows_by_pk?: Maybe<Campaign_Workflows>;
  /** An array relationship */
  campaigns: Array<Campaigns>;
  /** An aggregate relationship */
  campaigns_aggregate: Campaigns_Aggregate;
  /** fetch data from the table: "campaigns" using primary key columns */
  campaigns_by_pk?: Maybe<Campaigns>;
  /** Verify if payment can be made for this product */
  canMakeProductPayment?: Maybe<CanMakeProductPaymentOutput>;
  /** An array relationship */
  case_assignments: Array<Case_Assignments>;
  /** An aggregate relationship */
  case_assignments_aggregate: Case_Assignments_Aggregate;
  /** fetch data from the table: "case_assignments" using primary key columns */
  case_assignments_by_pk?: Maybe<Case_Assignments>;
  /** An array relationship */
  case_details: Array<Case_Details>;
  /** An aggregate relationship */
  case_details_aggregate: Case_Details_Aggregate;
  /** fetch data from the table: "case_details" using primary key columns */
  case_details_by_pk?: Maybe<Case_Details>;
  /** An array relationship */
  case_documents: Array<Case_Documents>;
  /** An aggregate relationship */
  case_documents_aggregate: Case_Documents_Aggregate;
  /** fetch data from the table: "case_documents" using primary key columns */
  case_documents_by_pk?: Maybe<Case_Documents>;
  /** An array relationship */
  case_flags: Array<Case_Flags>;
  /** An aggregate relationship */
  case_flags_aggregate: Case_Flags_Aggregate;
  /** fetch data from the table: "case_flags" using primary key columns */
  case_flags_by_pk?: Maybe<Case_Flags>;
  /** An array relationship */
  case_interactions: Array<Case_Interactions>;
  /** An aggregate relationship */
  case_interactions_aggregate: Case_Interactions_Aggregate;
  /** fetch data from the table: "case_interactions" using primary key columns */
  case_interactions_by_pk?: Maybe<Case_Interactions>;
  /** An array relationship */
  case_mentions: Array<Case_Mentions>;
  /** An aggregate relationship */
  case_mentions_aggregate: Case_Mentions_Aggregate;
  /** fetch data from the table: "case_mentions" using primary key columns */
  case_mentions_by_pk?: Maybe<Case_Mentions>;
  /** An array relationship */
  case_statuses: Array<Case_Statuses>;
  /** An aggregate relationship */
  case_statuses_aggregate: Case_Statuses_Aggregate;
  /** fetch data from the table: "case_statuses" using primary key columns */
  case_statuses_by_pk?: Maybe<Case_Statuses>;
  /** An array relationship */
  cases: Array<Cases>;
  /** An aggregate relationship */
  cases_aggregate: Cases_Aggregate;
  /** fetch data from the table: "cases" using primary key columns */
  cases_by_pk?: Maybe<Cases>;
  /** An array relationship */
  channel_flags: Array<Channel_Flags>;
  /** An aggregate relationship */
  channel_flags_aggregate: Channel_Flags_Aggregate;
  /** fetch data from the table: "channel_flags" using primary key columns */
  channel_flags_by_pk?: Maybe<Channel_Flags>;
  /** An array relationship */
  channel_parties: Array<Channel_Parties>;
  /** An aggregate relationship */
  channel_parties_aggregate: Channel_Parties_Aggregate;
  /** fetch data from the table: "channel_parties" using primary key columns */
  channel_parties_by_pk?: Maybe<Channel_Parties>;
  /** An array relationship */
  channel_statuses: Array<Channel_Statuses>;
  /** An aggregate relationship */
  channel_statuses_aggregate: Channel_Statuses_Aggregate;
  /** fetch data from the table: "channel_statuses" using primary key columns */
  channel_statuses_by_pk?: Maybe<Channel_Statuses>;
  /** An array relationship */
  channel_subscriptions: Array<Channel_Subscriptions>;
  /** An aggregate relationship */
  channel_subscriptions_aggregate: Channel_Subscriptions_Aggregate;
  /** fetch data from the table: "channel_subscriptions" using primary key columns */
  channel_subscriptions_by_pk?: Maybe<Channel_Subscriptions>;
  /** fetch data from the table: "channels" */
  channels: Array<Channels>;
  /** fetch aggregated fields from the table: "channels" */
  channels_aggregate: Channels_Aggregate;
  /** fetch data from the table: "channels" using primary key columns */
  channels_by_pk?: Maybe<Channels>;
  /** execute function "closed_cases_daily" which returns "_closed_cases_dod_data" */
  closed_cases_daily: Array<_Closed_Cases_Dod_Data>;
  /** execute function "closed_cases_daily" and query aggregates on result of table type "_closed_cases_dod_data" */
  closed_cases_daily_aggregate: _Closed_Cases_Dod_Data_Aggregate;
  /** An array relationship */
  conditions: Array<Conditions>;
  /** An aggregate relationship */
  conditions_aggregate: Conditions_Aggregate;
  /** fetch data from the table: "conditions" using primary key columns */
  conditions_by_pk?: Maybe<Conditions>;
  /** An array relationship */
  contact_details: Array<Contact_Details>;
  /** An aggregate relationship */
  contact_details_aggregate: Contact_Details_Aggregate;
  /** fetch data from the table: "contact_details" using primary key columns */
  contact_details_by_pk?: Maybe<Contact_Details>;
  /** An array relationship */
  contact_interactions: Array<Contact_Interactions>;
  /** An aggregate relationship */
  contact_interactions_aggregate: Contact_Interactions_Aggregate;
  /** fetch data from the table: "contact_interactions" using primary key columns */
  contact_interactions_by_pk?: Maybe<Contact_Interactions>;
  /** An array relationship */
  contact_relationships: Array<Contact_Relationships>;
  /** An aggregate relationship */
  contact_relationships_aggregate: Contact_Relationships_Aggregate;
  /** fetch data from the table: "contact_relationships" using primary key columns */
  contact_relationships_by_pk?: Maybe<Contact_Relationships>;
  /** An array relationship */
  contacts: Array<Contacts>;
  /** An aggregate relationship */
  contacts_aggregate: Contacts_Aggregate;
  /** fetch data from the table: "contacts" using primary key columns */
  contacts_by_pk?: Maybe<Contacts>;
  /** An array relationship */
  credit_profile_flags: Array<Credit_Profile_Flags>;
  /** An aggregate relationship */
  credit_profile_flags_aggregate: Credit_Profile_Flags_Aggregate;
  /** fetch data from the table: "credit_profile_flags" using primary key columns */
  credit_profile_flags_by_pk?: Maybe<Credit_Profile_Flags>;
  /** An array relationship */
  credit_profile_statuses: Array<Credit_Profile_Statuses>;
  /** An aggregate relationship */
  credit_profile_statuses_aggregate: Credit_Profile_Statuses_Aggregate;
  /** fetch data from the table: "credit_profile_statuses" using primary key columns */
  credit_profile_statuses_by_pk?: Maybe<Credit_Profile_Statuses>;
  /** An array relationship */
  credit_profiles: Array<Credit_Profiles>;
  /** An aggregate relationship */
  credit_profiles_aggregate: Credit_Profiles_Aggregate;
  /** fetch data from the table: "credit_profiles" using primary key columns */
  credit_profiles_by_pk?: Maybe<Credit_Profiles>;
  /** execute function "customer_daily_growth" which returns "_cus_growth_data" */
  customer_daily_growth: Array<_Cus_Growth_Data>;
  /** execute function "customer_daily_growth" and query aggregates on result of table type "_cus_growth_data" */
  customer_daily_growth_aggregate: _Cus_Growth_Data_Aggregate;
  /** fetch data from the table: "delivery_methods" */
  delivery_methods: Array<Delivery_Methods>;
  /** fetch aggregated fields from the table: "delivery_methods" */
  delivery_methods_aggregate: Delivery_Methods_Aggregate;
  /** fetch data from the table: "delivery_methods" using primary key columns */
  delivery_methods_by_pk?: Maybe<Delivery_Methods>;
  /** An array relationship */
  document_interactions: Array<Document_Interactions>;
  /** An aggregate relationship */
  document_interactions_aggregate: Document_Interactions_Aggregate;
  /** fetch data from the table: "document_interactions" using primary key columns */
  document_interactions_by_pk?: Maybe<Document_Interactions>;
  /** An array relationship */
  documents: Array<Documents>;
  /** An aggregate relationship */
  documents_aggregate: Documents_Aggregate;
  /** fetch data from the table: "documents" using primary key columns */
  documents_by_pk?: Maybe<Documents>;
  /** fetch data from the table: "flags" */
  flags: Array<Flags>;
  /** fetch aggregated fields from the table: "flags" */
  flags_aggregate: Flags_Aggregate;
  /** fetch data from the table: "flags" using primary key columns */
  flags_by_pk?: Maybe<Flags>;
  /** An array relationship */
  form_fields: Array<Form_Fields>;
  /** An aggregate relationship */
  form_fields_aggregate: Form_Fields_Aggregate;
  /** fetch data from the table: "form_fields" using primary key columns */
  form_fields_by_pk?: Maybe<Form_Fields>;
  /** An array relationship */
  form_rules: Array<Form_Rules>;
  /** An aggregate relationship */
  form_rules_aggregate: Form_Rules_Aggregate;
  /** fetch data from the table: "form_rules" using primary key columns */
  form_rules_by_pk?: Maybe<Form_Rules>;
  /** An array relationship */
  forms: Array<Forms>;
  /** An aggregate relationship */
  forms_aggregate: Forms_Aggregate;
  /** fetch data from the table: "forms" using primary key columns */
  forms_by_pk?: Maybe<Forms>;
  generateOTPToken: GenerateOtpOutput;
  getAuthenticatorQRCodeURL?: Maybe<QrAuthenticatorOutput>;
  /** Returns last read messages for given channels */
  getLastReadMessages?: Maybe<GetLastReadMessagesOutput>;
  getReadMessages?: Maybe<GetReadMessagesOutput>;
  getScheduledJobs?: Maybe<GetScheduledJobsOutput>;
  /** execute function "get_party_channel" which returns "channel_parties" */
  get_party_channel: Array<Channel_Parties>;
  /** execute function "get_party_channel" and query aggregates on result of table type "channel_parties" */
  get_party_channel_aggregate: Channel_Parties_Aggregate;
  /** Initiate member login generating OTP */
  initiateMemberLogin?: Maybe<InitiateMemberLoginOutput>;
  /** fetch data from the table: "interaction_details" */
  interaction_details: Array<Interaction_Details>;
  /** fetch aggregated fields from the table: "interaction_details" */
  interaction_details_aggregate: Interaction_Details_Aggregate;
  /** fetch data from the table: "interaction_details" using primary key columns */
  interaction_details_by_pk?: Maybe<Interaction_Details>;
  /** An array relationship */
  interactions: Array<Interactions>;
  /** An aggregate relationship */
  interactions_aggregate: Interactions_Aggregate;
  /** fetch data from the table: "interactions" using primary key columns */
  interactions_by_pk?: Maybe<Interactions>;
  /** An array relationship */
  invoice_interactions: Array<Invoice_Interactions>;
  /** An aggregate relationship */
  invoice_interactions_aggregate: Invoice_Interactions_Aggregate;
  /** fetch data from the table: "invoice_interactions" using primary key columns */
  invoice_interactions_by_pk?: Maybe<Invoice_Interactions>;
  /** An array relationship */
  invoice_items: Array<Invoice_Items>;
  /** An aggregate relationship */
  invoice_items_aggregate: Invoice_Items_Aggregate;
  /** fetch data from the table: "invoice_items" using primary key columns */
  invoice_items_by_pk?: Maybe<Invoice_Items>;
  /** An array relationship */
  invoices: Array<Invoices>;
  /** An aggregate relationship */
  invoices_aggregate: Invoices_Aggregate;
  /** fetch data from the table: "invoices" using primary key columns */
  invoices_by_pk?: Maybe<Invoices>;
  ledger_accounts: Array<LedgerAccount>;
  ledger_accounts_by_pk: LedgerAccount;
  ledger_entries_by_ledger_account_id: Array<TransactionLedgerEntry>;
  ledger_entries_by_ledger_account_ids: TransactionLedgerEntryConnection;
  ledgers: Array<Ledger>;
  ledgers_by_pk: Ledger;
  /** Returns a loan product summary */
  loanProductSummary?: Maybe<LoanProductSummaryOutput>;
  luca_statuses: Array<Status>;
  luca_types: Array<Type>;
  me?: Maybe<MeOutput>;
  /** An array relationship */
  message_delivery_methods: Array<Message_Delivery_Methods>;
  /** An aggregate relationship */
  message_delivery_methods_aggregate: Message_Delivery_Methods_Aggregate;
  /** fetch data from the table: "message_delivery_methods" using primary key columns */
  message_delivery_methods_by_pk?: Maybe<Message_Delivery_Methods>;
  /** An array relationship */
  message_delivery_responses: Array<Message_Delivery_Responses>;
  /** An aggregate relationship */
  message_delivery_responses_aggregate: Message_Delivery_Responses_Aggregate;
  /** fetch data from the table: "message_delivery_responses" using primary key columns */
  message_delivery_responses_by_pk?: Maybe<Message_Delivery_Responses>;
  /** An array relationship */
  message_flags: Array<Message_Flags>;
  /** An aggregate relationship */
  message_flags_aggregate: Message_Flags_Aggregate;
  /** fetch data from the table: "message_flags" using primary key columns */
  message_flags_by_pk?: Maybe<Message_Flags>;
  /** An array relationship */
  message_statuses: Array<Message_Statuses>;
  /** An aggregate relationship */
  message_statuses_aggregate: Message_Statuses_Aggregate;
  /** fetch data from the table: "message_statuses" using primary key columns */
  message_statuses_by_pk?: Maybe<Message_Statuses>;
  /** An array relationship */
  messages: Array<Messages>;
  /** An aggregate relationship */
  messages_aggregate: Messages_Aggregate;
  /** fetch data from the table: "messages" using primary key columns */
  messages_by_pk?: Maybe<Messages>;
  mom_ledger_account_growth_report: MoMGrowthReport;
  mom_ledger_growth_report: MoMGrowthReport;
  /** execute function "new_cases_daily_growth" which returns "_case_dod_data" */
  new_cases_daily_growth: Array<_Case_Dod_Data>;
  /** execute function "new_cases_daily_growth" and query aggregates on result of table type "_case_dod_data" */
  new_cases_daily_growth_aggregate: _Case_Dod_Data_Aggregate;
  /** fetch data from the table: "organisation_credentials" */
  organisation_credentials: Array<Organisation_Credentials>;
  /** fetch aggregated fields from the table: "organisation_credentials" */
  organisation_credentials_aggregate: Organisation_Credentials_Aggregate;
  /** fetch data from the table: "organisation_credentials" using primary key columns */
  organisation_credentials_by_pk?: Maybe<Organisation_Credentials>;
  /** fetch data from the table: "organisation_flags" */
  organisation_flags: Array<Organisation_Flags>;
  /** fetch aggregated fields from the table: "organisation_flags" */
  organisation_flags_aggregate: Organisation_Flags_Aggregate;
  /** fetch data from the table: "organisation_flags" using primary key columns */
  organisation_flags_by_pk?: Maybe<Organisation_Flags>;
  /** fetch data from the table: "organisation_invites" */
  organisation_invites: Array<Organisation_Invites>;
  /** fetch aggregated fields from the table: "organisation_invites" */
  organisation_invites_aggregate: Organisation_Invites_Aggregate;
  /** fetch data from the table: "organisation_invites" using primary key columns */
  organisation_invites_by_pk?: Maybe<Organisation_Invites>;
  /** fetch data from the table: "organisation_rules" */
  organisation_rules: Array<Organisation_Rules>;
  /** fetch aggregated fields from the table: "organisation_rules" */
  organisation_rules_aggregate: Organisation_Rules_Aggregate;
  /** fetch data from the table: "organisation_rules" using primary key columns */
  organisation_rules_by_pk?: Maybe<Organisation_Rules>;
  /** An array relationship */
  organisation_settings: Array<Organisation_Settings>;
  /** An aggregate relationship */
  organisation_settings_aggregate: Organisation_Settings_Aggregate;
  /** fetch data from the table: "organisation_settings" using primary key columns */
  organisation_settings_by_pk?: Maybe<Organisation_Settings>;
  /** fetch data from the table: "organisation_statuses" */
  organisation_statuses: Array<Organisation_Statuses>;
  /** fetch aggregated fields from the table: "organisation_statuses" */
  organisation_statuses_aggregate: Organisation_Statuses_Aggregate;
  /** fetch data from the table: "organisation_statuses" using primary key columns */
  organisation_statuses_by_pk?: Maybe<Organisation_Statuses>;
  /** fetch data from the table: "organisations" */
  organisations: Array<Organisations>;
  /** fetch aggregated fields from the table: "organisations" */
  organisations_aggregate: Organisations_Aggregate;
  /** fetch data from the table: "organisations" using primary key columns */
  organisations_by_pk?: Maybe<Organisations>;
  /** An array relationship */
  parties: Array<Parties>;
  /** An aggregate relationship */
  parties_aggregate: Parties_Aggregate;
  /** fetch data from the table: "parties" using primary key columns */
  parties_by_pk?: Maybe<Parties>;
  /** An array relationship */
  party_contacts: Array<Party_Contacts>;
  /** An aggregate relationship */
  party_contacts_aggregate: Party_Contacts_Aggregate;
  /** fetch data from the table: "party_contacts" using primary key columns */
  party_contacts_by_pk?: Maybe<Party_Contacts>;
  /** An array relationship */
  party_documents: Array<Party_Documents>;
  /** An aggregate relationship */
  party_documents_aggregate: Party_Documents_Aggregate;
  /** fetch data from the table: "party_documents" using primary key columns */
  party_documents_by_pk?: Maybe<Party_Documents>;
  /** An array relationship */
  party_flags: Array<Party_Flags>;
  /** An aggregate relationship */
  party_flags_aggregate: Party_Flags_Aggregate;
  /** fetch data from the table: "party_flags" using primary key columns */
  party_flags_by_pk?: Maybe<Party_Flags>;
  /** An array relationship */
  party_interactions: Array<Party_Interactions>;
  /** An aggregate relationship */
  party_interactions_aggregate: Party_Interactions_Aggregate;
  /** fetch data from the table: "party_interactions" using primary key columns */
  party_interactions_by_pk?: Maybe<Party_Interactions>;
  /** An array relationship */
  party_ledger_accounts: Array<Party_Ledger_Accounts>;
  /** An aggregate relationship */
  party_ledger_accounts_aggregate: Party_Ledger_Accounts_Aggregate;
  /** fetch data from the table: "party_ledger_accounts" using primary key columns */
  party_ledger_accounts_by_pk?: Maybe<Party_Ledger_Accounts>;
  /** An array relationship */
  party_relationship_statuses: Array<Party_Relationship_Statuses>;
  /** An aggregate relationship */
  party_relationship_statuses_aggregate: Party_Relationship_Statuses_Aggregate;
  /** fetch data from the table: "party_relationship_statuses" using primary key columns */
  party_relationship_statuses_by_pk?: Maybe<Party_Relationship_Statuses>;
  /** An array relationship */
  party_relationships: Array<Party_Relationships>;
  /** An aggregate relationship */
  party_relationships_aggregate: Party_Relationships_Aggregate;
  /** fetch data from the table: "party_relationships" using primary key columns */
  party_relationships_by_pk?: Maybe<Party_Relationships>;
  /** An array relationship */
  party_settings: Array<Party_Settings>;
  /** An aggregate relationship */
  party_settings_aggregate: Party_Settings_Aggregate;
  /** fetch data from the table: "party_settings" using primary key columns */
  party_settings_by_pk?: Maybe<Party_Settings>;
  /** An array relationship */
  party_statuses: Array<Party_Statuses>;
  /** An aggregate relationship */
  party_statuses_aggregate: Party_Statuses_Aggregate;
  /** fetch data from the table: "party_statuses" using primary key columns */
  party_statuses_by_pk?: Maybe<Party_Statuses>;
  payment_intents: Array<PaymentIntent>;
  payment_intents_by_pk: PaymentIntent;
  payments: Array<Payment>;
  payments_by_pk: Payment;
  /** An array relationship */
  performance_indicators: Array<Performance_Indicators>;
  /** An aggregate relationship */
  performance_indicators_aggregate: Performance_Indicators_Aggregate;
  /** fetch data from the table: "performance_indicators" using primary key columns */
  performance_indicators_by_pk?: Maybe<Performance_Indicators>;
  /** An array relationship */
  performance_targets: Array<Performance_Targets>;
  /** An aggregate relationship */
  performance_targets_aggregate: Performance_Targets_Aggregate;
  /** fetch data from the table: "performance_targets" using primary key columns */
  performance_targets_by_pk?: Maybe<Performance_Targets>;
  /** An array relationship */
  product_documents: Array<Product_Documents>;
  /** An aggregate relationship */
  product_documents_aggregate: Product_Documents_Aggregate;
  /** fetch data from the table: "product_documents" using primary key columns */
  product_documents_by_pk?: Maybe<Product_Documents>;
  /** An array relationship */
  product_interactions: Array<Product_Interactions>;
  /** An aggregate relationship */
  product_interactions_aggregate: Product_Interactions_Aggregate;
  /** fetch data from the table: "product_interactions" using primary key columns */
  product_interactions_by_pk?: Maybe<Product_Interactions>;
  /** An array relationship */
  product_items: Array<Product_Items>;
  /** An aggregate relationship */
  product_items_aggregate: Product_Items_Aggregate;
  /** fetch data from the table: "product_items" using primary key columns */
  product_items_by_pk?: Maybe<Product_Items>;
  /** An array relationship */
  product_rules: Array<Product_Rules>;
  /** An aggregate relationship */
  product_rules_aggregate: Product_Rules_Aggregate;
  /** fetch data from the table: "product_rules" using primary key columns */
  product_rules_by_pk?: Maybe<Product_Rules>;
  /** An array relationship */
  product_settings: Array<Product_Settings>;
  /** An aggregate relationship */
  product_settings_aggregate: Product_Settings_Aggregate;
  /** fetch data from the table: "product_settings" using primary key columns */
  product_settings_by_pk?: Maybe<Product_Settings>;
  /** An array relationship */
  product_statuses: Array<Product_Statuses>;
  /** An aggregate relationship */
  product_statuses_aggregate: Product_Statuses_Aggregate;
  /** fetch data from the table: "product_statuses" using primary key columns */
  product_statuses_by_pk?: Maybe<Product_Statuses>;
  /** fetch data from the table: "product_templates" */
  product_templates: Array<Product_Templates>;
  /** fetch aggregated fields from the table: "product_templates" */
  product_templates_aggregate: Product_Templates_Aggregate;
  /** fetch data from the table: "product_templates" using primary key columns */
  product_templates_by_pk?: Maybe<Product_Templates>;
  /** An array relationship */
  products: Array<Products>;
  /** An aggregate relationship */
  products_aggregate: Products_Aggregate;
  /** fetch data from the table: "products" using primary key columns */
  products_by_pk?: Maybe<Products>;
  /** An array relationship */
  reward_redemptions: Array<Reward_Redemptions>;
  /** An aggregate relationship */
  reward_redemptions_aggregate: Reward_Redemptions_Aggregate;
  /** fetch data from the table: "reward_redemptions" using primary key columns */
  reward_redemptions_by_pk?: Maybe<Reward_Redemptions>;
  /** fetch data from the table: "rewards" */
  rewards: Array<Rewards>;
  /** fetch aggregated fields from the table: "rewards" */
  rewards_aggregate: Rewards_Aggregate;
  /** fetch data from the table: "rewards" using primary key columns */
  rewards_by_pk?: Maybe<Rewards>;
  /** fetch data from the table: "rules" */
  rules: Array<Rules>;
  /** fetch aggregated fields from the table: "rules" */
  rules_aggregate: Rules_Aggregate;
  /** fetch data from the table: "rules" using primary key columns */
  rules_by_pk?: Maybe<Rules>;
  /** fetch data from the table: "statuses" */
  statuses: Array<Statuses>;
  /** fetch aggregated fields from the table: "statuses" */
  statuses_aggregate: Statuses_Aggregate;
  /** fetch data from the table: "statuses" using primary key columns */
  statuses_by_pk?: Maybe<Statuses>;
  /** An array relationship */
  subscription_cases: Array<Subscription_Cases>;
  /** An aggregate relationship */
  subscription_cases_aggregate: Subscription_Cases_Aggregate;
  /** fetch data from the table: "subscription_cases" using primary key columns */
  subscription_cases_by_pk?: Maybe<Subscription_Cases>;
  /** An array relationship */
  subscription_details: Array<Subscription_Details>;
  /** An aggregate relationship */
  subscription_details_aggregate: Subscription_Details_Aggregate;
  /** fetch data from the table: "subscription_details" using primary key columns */
  subscription_details_by_pk?: Maybe<Subscription_Details>;
  /** An array relationship */
  subscription_documents: Array<Subscription_Documents>;
  /** An aggregate relationship */
  subscription_documents_aggregate: Subscription_Documents_Aggregate;
  /** fetch data from the table: "subscription_documents" using primary key columns */
  subscription_documents_by_pk?: Maybe<Subscription_Documents>;
  /** An array relationship */
  subscription_flags: Array<Subscription_Flags>;
  /** An aggregate relationship */
  subscription_flags_aggregate: Subscription_Flags_Aggregate;
  /** fetch data from the table: "subscription_flags" using primary key columns */
  subscription_flags_by_pk?: Maybe<Subscription_Flags>;
  /** An array relationship */
  subscription_interactions: Array<Subscription_Interactions>;
  /** An aggregate relationship */
  subscription_interactions_aggregate: Subscription_Interactions_Aggregate;
  /** fetch data from the table: "subscription_interactions" using primary key columns */
  subscription_interactions_by_pk?: Maybe<Subscription_Interactions>;
  subscription_items: Array<Subscription_Items>;
  subscription_items_aggregate: Subscription_Items_Aggregate;
  /** fetch data from the table: "subscription_items" using primary key columns */
  subscription_items_by_pk?: Maybe<Subscription_Items>;
  /** An array relationship */
  subscription_payment_transactions: Array<Subscription_Payment_Transactions>;
  /** An aggregate relationship */
  subscription_payment_transactions_aggregate: Subscription_Payment_Transactions_Aggregate;
  /** fetch data from the table: "subscription_payment_transactions" using primary key columns */
  subscription_payment_transactions_by_pk?: Maybe<Subscription_Payment_Transactions>;
  /** An array relationship */
  subscription_payments: Array<Subscription_Payments>;
  /** An aggregate relationship */
  subscription_payments_aggregate: Subscription_Payments_Aggregate;
  /** fetch data from the table: "subscription_payments" using primary key columns */
  subscription_payments_by_pk?: Maybe<Subscription_Payments>;
  /** An array relationship */
  subscription_rules: Array<Subscription_Rules>;
  /** An aggregate relationship */
  subscription_rules_aggregate: Subscription_Rules_Aggregate;
  /** fetch data from the table: "subscription_rules" using primary key columns */
  subscription_rules_by_pk?: Maybe<Subscription_Rules>;
  /** An array relationship */
  subscription_schedules: Array<Subscription_Schedules>;
  /** An aggregate relationship */
  subscription_schedules_aggregate: Subscription_Schedules_Aggregate;
  /** fetch data from the table: "subscription_schedules" using primary key columns */
  subscription_schedules_by_pk?: Maybe<Subscription_Schedules>;
  /** An array relationship */
  subscription_statuses: Array<Subscription_Statuses>;
  /** An aggregate relationship */
  subscription_statuses_aggregate: Subscription_Statuses_Aggregate;
  /** fetch data from the table: "subscription_statuses" using primary key columns */
  subscription_statuses_by_pk?: Maybe<Subscription_Statuses>;
  /** An array relationship */
  subscription_tasks: Array<Subscription_Tasks>;
  /** An aggregate relationship */
  subscription_tasks_aggregate: Subscription_Tasks_Aggregate;
  /** fetch data from the table: "subscription_tasks" using primary key columns */
  subscription_tasks_by_pk?: Maybe<Subscription_Tasks>;
  /** An array relationship */
  subscriptions: Array<Subscriptions>;
  /** An aggregate relationship */
  subscriptions_aggregate: Subscriptions_Aggregate;
  /** fetch data from the table: "subscriptions" using primary key columns */
  subscriptions_by_pk?: Maybe<Subscriptions>;
  /** An array relationship */
  tariff_histories: Array<Tariff_Histories>;
  /** An aggregate relationship */
  tariff_histories_aggregate: Tariff_Histories_Aggregate;
  /** fetch data from the table: "tariff_histories" using primary key columns */
  tariff_histories_by_pk?: Maybe<Tariff_Histories>;
  /** An array relationship */
  tariffs: Array<Tariffs>;
  /** An aggregate relationship */
  tariffs_aggregate: Tariffs_Aggregate;
  /** fetch data from the table: "tariffs" using primary key columns */
  tariffs_by_pk?: Maybe<Tariffs>;
  /** An array relationship */
  task_assignments: Array<Task_Assignments>;
  /** An aggregate relationship */
  task_assignments_aggregate: Task_Assignments_Aggregate;
  /** fetch data from the table: "task_assignments" using primary key columns */
  task_assignments_by_pk?: Maybe<Task_Assignments>;
  /** An array relationship */
  task_documents: Array<Task_Documents>;
  /** An aggregate relationship */
  task_documents_aggregate: Task_Documents_Aggregate;
  /** fetch data from the table: "task_documents" using primary key columns */
  task_documents_by_pk?: Maybe<Task_Documents>;
  /** An array relationship */
  task_flags: Array<Task_Flags>;
  /** An aggregate relationship */
  task_flags_aggregate: Task_Flags_Aggregate;
  /** fetch data from the table: "task_flags" using primary key columns */
  task_flags_by_pk?: Maybe<Task_Flags>;
  /** An array relationship */
  task_interactions: Array<Task_Interactions>;
  /** An aggregate relationship */
  task_interactions_aggregate: Task_Interactions_Aggregate;
  /** fetch data from the table: "task_interactions" using primary key columns */
  task_interactions_by_pk?: Maybe<Task_Interactions>;
  /** An array relationship */
  task_mentions: Array<Task_Mentions>;
  /** An aggregate relationship */
  task_mentions_aggregate: Task_Mentions_Aggregate;
  /** fetch data from the table: "task_mentions" using primary key columns */
  task_mentions_by_pk?: Maybe<Task_Mentions>;
  /** An array relationship */
  task_reminders: Array<Task_Reminders>;
  /** An aggregate relationship */
  task_reminders_aggregate: Task_Reminders_Aggregate;
  /** fetch data from the table: "task_reminders" using primary key columns */
  task_reminders_by_pk?: Maybe<Task_Reminders>;
  /** An array relationship */
  task_rules: Array<Task_Rules>;
  /** An aggregate relationship */
  task_rules_aggregate: Task_Rules_Aggregate;
  /** fetch data from the table: "task_rules" using primary key columns */
  task_rules_by_pk?: Maybe<Task_Rules>;
  /** An array relationship */
  task_statuses: Array<Task_Statuses>;
  /** An aggregate relationship */
  task_statuses_aggregate: Task_Statuses_Aggregate;
  /** fetch data from the table: "task_statuses" using primary key columns */
  task_statuses_by_pk?: Maybe<Task_Statuses>;
  /** An array relationship */
  tasks: Array<Tasks>;
  /** An aggregate relationship */
  tasks_aggregate: Tasks_Aggregate;
  /** fetch data from the table: "tasks" using primary key columns */
  tasks_by_pk?: Maybe<Tasks>;
  /** An array relationship */
  territories: Array<Territories>;
  /** An aggregate relationship */
  territories_aggregate: Territories_Aggregate;
  /** fetch data from the table: "territories" using primary key columns */
  territories_by_pk?: Maybe<Territories>;
  /** An array relationship */
  territory_assignments: Array<Territory_Assignments>;
  /** An aggregate relationship */
  territory_assignments_aggregate: Territory_Assignments_Aggregate;
  /** fetch data from the table: "territory_assignments" using primary key columns */
  territory_assignments_by_pk?: Maybe<Territory_Assignments>;
  /** An array relationship */
  territory_flags: Array<Territory_Flags>;
  /** An aggregate relationship */
  territory_flags_aggregate: Territory_Flags_Aggregate;
  /** fetch data from the table: "territory_flags" using primary key columns */
  territory_flags_by_pk?: Maybe<Territory_Flags>;
  /** An array relationship */
  territory_interactions: Array<Territory_Interactions>;
  /** An aggregate relationship */
  territory_interactions_aggregate: Territory_Interactions_Aggregate;
  /** fetch data from the table: "territory_interactions" using primary key columns */
  territory_interactions_by_pk?: Maybe<Territory_Interactions>;
  /** An array relationship */
  territory_members: Array<Territory_Members>;
  /** An aggregate relationship */
  territory_members_aggregate: Territory_Members_Aggregate;
  /** fetch data from the table: "territory_members" using primary key columns */
  territory_members_by_pk?: Maybe<Territory_Members>;
  /** An array relationship */
  territory_rules: Array<Territory_Rules>;
  /** An aggregate relationship */
  territory_rules_aggregate: Territory_Rules_Aggregate;
  /** fetch data from the table: "territory_rules" using primary key columns */
  territory_rules_by_pk?: Maybe<Territory_Rules>;
  /** An array relationship */
  territory_statuses: Array<Territory_Statuses>;
  /** An aggregate relationship */
  territory_statuses_aggregate: Territory_Statuses_Aggregate;
  /** fetch data from the table: "territory_statuses" using primary key columns */
  territory_statuses_by_pk?: Maybe<Territory_Statuses>;
  transactions: Array<Transaction>;
  transactions_by_pk: Transaction;
  trial_balance_report: TrialBalanceReport;
  /** fetch data from the table: "types" */
  types: Array<Types>;
  /** fetch aggregated fields from the table: "types" */
  types_aggregate: Types_Aggregate;
  /** fetch data from the table: "types" using primary key columns */
  types_by_pk?: Maybe<Types>;
  /** An array relationship */
  user_flags: Array<User_Flags>;
  /** An aggregate relationship */
  user_flags_aggregate: User_Flags_Aggregate;
  /** fetch data from the table: "user_flags" using primary key columns */
  user_flags_by_pk?: Maybe<User_Flags>;
  /** fetch data from the table: "user_mfa_factors" */
  user_mfa_factors: Array<User_Mfa_Factors>;
  /** fetch aggregated fields from the table: "user_mfa_factors" */
  user_mfa_factors_aggregate: User_Mfa_Factors_Aggregate;
  /** fetch data from the table: "user_mfa_factors" using primary key columns */
  user_mfa_factors_by_pk?: Maybe<User_Mfa_Factors>;
  /** An array relationship */
  user_statuses: Array<User_Statuses>;
  /** An aggregate relationship */
  user_statuses_aggregate: User_Statuses_Aggregate;
  /** fetch data from the table: "user_statuses" using primary key columns */
  user_statuses_by_pk?: Maybe<User_Statuses>;
  /** fetch data from the table: "users" */
  users: Array<Users>;
  /** fetch aggregated fields from the table: "users" */
  users_aggregate: Users_Aggregate;
  /** fetch data from the table: "users" using primary key columns */
  users_by_pk?: Maybe<Users>;
  verifyAuthFactor?: Maybe<VerifyAuthFactorOutput>;
  /** An array relationship */
  webhooks: Array<Webhooks>;
  /** An aggregate relationship */
  webhooks_aggregate: Webhooks_Aggregate;
  /** fetch data from the table: "webhooks" using primary key columns */
  webhooks_by_pk?: Maybe<Webhooks>;
  /** An array relationship */
  workflow_templates: Array<Workflow_Templates>;
  /** An aggregate relationship */
  workflow_templates_aggregate: Workflow_Templates_Aggregate;
  /** fetch data from the table: "workflow_templates" using primary key columns */
  workflow_templates_by_pk?: Maybe<Workflow_Templates>;
};

export type Query_Root_Case_Dod_DataArgs = {
  distinct_on?: InputMaybe<Array<_Case_Dod_Data_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<_Case_Dod_Data_Order_By>>;
  where?: InputMaybe<_Case_Dod_Data_Bool_Exp>;
};

export type Query_Root_Case_Dod_Data_AggregateArgs = {
  distinct_on?: InputMaybe<Array<_Case_Dod_Data_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<_Case_Dod_Data_Order_By>>;
  where?: InputMaybe<_Case_Dod_Data_Bool_Exp>;
};

export type Query_Root_Closed_Cases_Dod_DataArgs = {
  distinct_on?: InputMaybe<Array<_Closed_Cases_Dod_Data_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<_Closed_Cases_Dod_Data_Order_By>>;
  where?: InputMaybe<_Closed_Cases_Dod_Data_Bool_Exp>;
};

export type Query_Root_Closed_Cases_Dod_Data_AggregateArgs = {
  distinct_on?: InputMaybe<Array<_Closed_Cases_Dod_Data_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<_Closed_Cases_Dod_Data_Order_By>>;
  where?: InputMaybe<_Closed_Cases_Dod_Data_Bool_Exp>;
};

export type Query_Root_Cus_Growth_DataArgs = {
  distinct_on?: InputMaybe<Array<_Cus_Growth_Data_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<_Cus_Growth_Data_Order_By>>;
  where?: InputMaybe<_Cus_Growth_Data_Bool_Exp>;
};

export type Query_Root_Cus_Growth_Data_AggregateArgs = {
  distinct_on?: InputMaybe<Array<_Cus_Growth_Data_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<_Cus_Growth_Data_Order_By>>;
  where?: InputMaybe<_Cus_Growth_Data_Bool_Exp>;
};

export type Query_RootActive_Product_SubscriptionsArgs = {
  distinct_on?: InputMaybe<Array<Active_Product_Subscriptions_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Active_Product_Subscriptions_Order_By>>;
  where?: InputMaybe<Active_Product_Subscriptions_Bool_Exp>;
};

export type Query_RootActive_Product_Subscriptions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Active_Product_Subscriptions_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Active_Product_Subscriptions_Order_By>>;
  where?: InputMaybe<Active_Product_Subscriptions_Bool_Exp>;
};

export type Query_RootActivity_LogsArgs = {
  distinct_on?: InputMaybe<Array<Activity_Logs_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Activity_Logs_Order_By>>;
  where?: InputMaybe<Activity_Logs_Bool_Exp>;
};

export type Query_RootActivity_Logs_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Activity_Logs_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Activity_Logs_Order_By>>;
  where?: InputMaybe<Activity_Logs_Bool_Exp>;
};

export type Query_RootActivity_Logs_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Query_RootAudit_LogsArgs = {
  distinct_on?: InputMaybe<Array<Audit_Logs_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Audit_Logs_Order_By>>;
  where?: InputMaybe<Audit_Logs_Bool_Exp>;
};

export type Query_RootAudit_Logs_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Audit_Logs_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Audit_Logs_Order_By>>;
  where?: InputMaybe<Audit_Logs_Bool_Exp>;
};

export type Query_RootAudit_Logs_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Query_RootCampaign_ContactsArgs = {
  distinct_on?: InputMaybe<Array<Campaign_Contacts_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Campaign_Contacts_Order_By>>;
  where?: InputMaybe<Campaign_Contacts_Bool_Exp>;
};

export type Query_RootCampaign_Contacts_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Campaign_Contacts_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Campaign_Contacts_Order_By>>;
  where?: InputMaybe<Campaign_Contacts_Bool_Exp>;
};

export type Query_RootCampaign_Contacts_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Query_RootCampaign_Performance_IndicatorsArgs = {
  distinct_on?: InputMaybe<
    Array<Campaign_Performance_Indicators_Select_Column>
  >;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Campaign_Performance_Indicators_Order_By>>;
  where?: InputMaybe<Campaign_Performance_Indicators_Bool_Exp>;
};

export type Query_RootCampaign_Performance_Indicators_AggregateArgs = {
  distinct_on?: InputMaybe<
    Array<Campaign_Performance_Indicators_Select_Column>
  >;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Campaign_Performance_Indicators_Order_By>>;
  where?: InputMaybe<Campaign_Performance_Indicators_Bool_Exp>;
};

export type Query_RootCampaign_Performance_Indicators_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Query_RootCampaign_RewardsArgs = {
  distinct_on?: InputMaybe<Array<Campaign_Rewards_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Campaign_Rewards_Order_By>>;
  where?: InputMaybe<Campaign_Rewards_Bool_Exp>;
};

export type Query_RootCampaign_Rewards_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Campaign_Rewards_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Campaign_Rewards_Order_By>>;
  where?: InputMaybe<Campaign_Rewards_Bool_Exp>;
};

export type Query_RootCampaign_Rewards_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Query_RootCampaign_RulesArgs = {
  distinct_on?: InputMaybe<Array<Campaign_Rules_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Campaign_Rules_Order_By>>;
  where?: InputMaybe<Campaign_Rules_Bool_Exp>;
};

export type Query_RootCampaign_Rules_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Campaign_Rules_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Campaign_Rules_Order_By>>;
  where?: InputMaybe<Campaign_Rules_Bool_Exp>;
};

export type Query_RootCampaign_Rules_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Query_RootCampaign_TasksArgs = {
  distinct_on?: InputMaybe<Array<Campaign_Tasks_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Campaign_Tasks_Order_By>>;
  where?: InputMaybe<Campaign_Tasks_Bool_Exp>;
};

export type Query_RootCampaign_Tasks_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Campaign_Tasks_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Campaign_Tasks_Order_By>>;
  where?: InputMaybe<Campaign_Tasks_Bool_Exp>;
};

export type Query_RootCampaign_Tasks_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Query_RootCampaign_WorkflowsArgs = {
  distinct_on?: InputMaybe<Array<Campaign_Workflows_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Campaign_Workflows_Order_By>>;
  where?: InputMaybe<Campaign_Workflows_Bool_Exp>;
};

export type Query_RootCampaign_Workflows_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Campaign_Workflows_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Campaign_Workflows_Order_By>>;
  where?: InputMaybe<Campaign_Workflows_Bool_Exp>;
};

export type Query_RootCampaign_Workflows_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Query_RootCampaignsArgs = {
  distinct_on?: InputMaybe<Array<Campaigns_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Campaigns_Order_By>>;
  where?: InputMaybe<Campaigns_Bool_Exp>;
};

export type Query_RootCampaigns_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Campaigns_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Campaigns_Order_By>>;
  where?: InputMaybe<Campaigns_Bool_Exp>;
};

export type Query_RootCampaigns_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Query_RootCanMakeProductPaymentArgs = {
  params: CanMakeProductPaymentInput;
};

export type Query_RootCase_AssignmentsArgs = {
  distinct_on?: InputMaybe<Array<Case_Assignments_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Case_Assignments_Order_By>>;
  where?: InputMaybe<Case_Assignments_Bool_Exp>;
};

export type Query_RootCase_Assignments_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Case_Assignments_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Case_Assignments_Order_By>>;
  where?: InputMaybe<Case_Assignments_Bool_Exp>;
};

export type Query_RootCase_Assignments_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Query_RootCase_DetailsArgs = {
  distinct_on?: InputMaybe<Array<Case_Details_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Case_Details_Order_By>>;
  where?: InputMaybe<Case_Details_Bool_Exp>;
};

export type Query_RootCase_Details_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Case_Details_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Case_Details_Order_By>>;
  where?: InputMaybe<Case_Details_Bool_Exp>;
};

export type Query_RootCase_Details_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Query_RootCase_DocumentsArgs = {
  distinct_on?: InputMaybe<Array<Case_Documents_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Case_Documents_Order_By>>;
  where?: InputMaybe<Case_Documents_Bool_Exp>;
};

export type Query_RootCase_Documents_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Case_Documents_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Case_Documents_Order_By>>;
  where?: InputMaybe<Case_Documents_Bool_Exp>;
};

export type Query_RootCase_Documents_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Query_RootCase_FlagsArgs = {
  distinct_on?: InputMaybe<Array<Case_Flags_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Case_Flags_Order_By>>;
  where?: InputMaybe<Case_Flags_Bool_Exp>;
};

export type Query_RootCase_Flags_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Case_Flags_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Case_Flags_Order_By>>;
  where?: InputMaybe<Case_Flags_Bool_Exp>;
};

export type Query_RootCase_Flags_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Query_RootCase_InteractionsArgs = {
  distinct_on?: InputMaybe<Array<Case_Interactions_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Case_Interactions_Order_By>>;
  where?: InputMaybe<Case_Interactions_Bool_Exp>;
};

export type Query_RootCase_Interactions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Case_Interactions_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Case_Interactions_Order_By>>;
  where?: InputMaybe<Case_Interactions_Bool_Exp>;
};

export type Query_RootCase_Interactions_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Query_RootCase_MentionsArgs = {
  distinct_on?: InputMaybe<Array<Case_Mentions_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Case_Mentions_Order_By>>;
  where?: InputMaybe<Case_Mentions_Bool_Exp>;
};

export type Query_RootCase_Mentions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Case_Mentions_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Case_Mentions_Order_By>>;
  where?: InputMaybe<Case_Mentions_Bool_Exp>;
};

export type Query_RootCase_Mentions_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Query_RootCase_StatusesArgs = {
  distinct_on?: InputMaybe<Array<Case_Statuses_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Case_Statuses_Order_By>>;
  where?: InputMaybe<Case_Statuses_Bool_Exp>;
};

export type Query_RootCase_Statuses_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Case_Statuses_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Case_Statuses_Order_By>>;
  where?: InputMaybe<Case_Statuses_Bool_Exp>;
};

export type Query_RootCase_Statuses_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Query_RootCasesArgs = {
  distinct_on?: InputMaybe<Array<Cases_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Cases_Order_By>>;
  where?: InputMaybe<Cases_Bool_Exp>;
};

export type Query_RootCases_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Cases_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Cases_Order_By>>;
  where?: InputMaybe<Cases_Bool_Exp>;
};

export type Query_RootCases_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Query_RootChannel_FlagsArgs = {
  distinct_on?: InputMaybe<Array<Channel_Flags_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Channel_Flags_Order_By>>;
  where?: InputMaybe<Channel_Flags_Bool_Exp>;
};

export type Query_RootChannel_Flags_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Channel_Flags_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Channel_Flags_Order_By>>;
  where?: InputMaybe<Channel_Flags_Bool_Exp>;
};

export type Query_RootChannel_Flags_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Query_RootChannel_PartiesArgs = {
  distinct_on?: InputMaybe<Array<Channel_Parties_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Channel_Parties_Order_By>>;
  where?: InputMaybe<Channel_Parties_Bool_Exp>;
};

export type Query_RootChannel_Parties_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Channel_Parties_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Channel_Parties_Order_By>>;
  where?: InputMaybe<Channel_Parties_Bool_Exp>;
};

export type Query_RootChannel_Parties_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Query_RootChannel_StatusesArgs = {
  distinct_on?: InputMaybe<Array<Channel_Statuses_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Channel_Statuses_Order_By>>;
  where?: InputMaybe<Channel_Statuses_Bool_Exp>;
};

export type Query_RootChannel_Statuses_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Channel_Statuses_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Channel_Statuses_Order_By>>;
  where?: InputMaybe<Channel_Statuses_Bool_Exp>;
};

export type Query_RootChannel_Statuses_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Query_RootChannel_SubscriptionsArgs = {
  distinct_on?: InputMaybe<Array<Channel_Subscriptions_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Channel_Subscriptions_Order_By>>;
  where?: InputMaybe<Channel_Subscriptions_Bool_Exp>;
};

export type Query_RootChannel_Subscriptions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Channel_Subscriptions_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Channel_Subscriptions_Order_By>>;
  where?: InputMaybe<Channel_Subscriptions_Bool_Exp>;
};

export type Query_RootChannel_Subscriptions_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Query_RootChannelsArgs = {
  distinct_on?: InputMaybe<Array<Channels_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Channels_Order_By>>;
  where?: InputMaybe<Channels_Bool_Exp>;
};

export type Query_RootChannels_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Channels_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Channels_Order_By>>;
  where?: InputMaybe<Channels_Bool_Exp>;
};

export type Query_RootChannels_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Query_RootClosed_Cases_DailyArgs = {
  args: Closed_Cases_Daily_Args;
  distinct_on?: InputMaybe<Array<_Closed_Cases_Dod_Data_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<_Closed_Cases_Dod_Data_Order_By>>;
  where?: InputMaybe<_Closed_Cases_Dod_Data_Bool_Exp>;
};

export type Query_RootClosed_Cases_Daily_AggregateArgs = {
  args: Closed_Cases_Daily_Args;
  distinct_on?: InputMaybe<Array<_Closed_Cases_Dod_Data_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<_Closed_Cases_Dod_Data_Order_By>>;
  where?: InputMaybe<_Closed_Cases_Dod_Data_Bool_Exp>;
};

export type Query_RootConditionsArgs = {
  distinct_on?: InputMaybe<Array<Conditions_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Conditions_Order_By>>;
  where?: InputMaybe<Conditions_Bool_Exp>;
};

export type Query_RootConditions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Conditions_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Conditions_Order_By>>;
  where?: InputMaybe<Conditions_Bool_Exp>;
};

export type Query_RootConditions_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Query_RootContact_DetailsArgs = {
  distinct_on?: InputMaybe<Array<Contact_Details_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Contact_Details_Order_By>>;
  where?: InputMaybe<Contact_Details_Bool_Exp>;
};

export type Query_RootContact_Details_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Contact_Details_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Contact_Details_Order_By>>;
  where?: InputMaybe<Contact_Details_Bool_Exp>;
};

export type Query_RootContact_Details_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Query_RootContact_InteractionsArgs = {
  distinct_on?: InputMaybe<Array<Contact_Interactions_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Contact_Interactions_Order_By>>;
  where?: InputMaybe<Contact_Interactions_Bool_Exp>;
};

export type Query_RootContact_Interactions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Contact_Interactions_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Contact_Interactions_Order_By>>;
  where?: InputMaybe<Contact_Interactions_Bool_Exp>;
};

export type Query_RootContact_Interactions_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Query_RootContact_RelationshipsArgs = {
  distinct_on?: InputMaybe<Array<Contact_Relationships_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Contact_Relationships_Order_By>>;
  where?: InputMaybe<Contact_Relationships_Bool_Exp>;
};

export type Query_RootContact_Relationships_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Contact_Relationships_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Contact_Relationships_Order_By>>;
  where?: InputMaybe<Contact_Relationships_Bool_Exp>;
};

export type Query_RootContact_Relationships_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Query_RootContactsArgs = {
  distinct_on?: InputMaybe<Array<Contacts_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Contacts_Order_By>>;
  where?: InputMaybe<Contacts_Bool_Exp>;
};

export type Query_RootContacts_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Contacts_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Contacts_Order_By>>;
  where?: InputMaybe<Contacts_Bool_Exp>;
};

export type Query_RootContacts_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Query_RootCredit_Profile_FlagsArgs = {
  distinct_on?: InputMaybe<Array<Credit_Profile_Flags_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Credit_Profile_Flags_Order_By>>;
  where?: InputMaybe<Credit_Profile_Flags_Bool_Exp>;
};

export type Query_RootCredit_Profile_Flags_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Credit_Profile_Flags_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Credit_Profile_Flags_Order_By>>;
  where?: InputMaybe<Credit_Profile_Flags_Bool_Exp>;
};

export type Query_RootCredit_Profile_Flags_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Query_RootCredit_Profile_StatusesArgs = {
  distinct_on?: InputMaybe<Array<Credit_Profile_Statuses_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Credit_Profile_Statuses_Order_By>>;
  where?: InputMaybe<Credit_Profile_Statuses_Bool_Exp>;
};

export type Query_RootCredit_Profile_Statuses_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Credit_Profile_Statuses_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Credit_Profile_Statuses_Order_By>>;
  where?: InputMaybe<Credit_Profile_Statuses_Bool_Exp>;
};

export type Query_RootCredit_Profile_Statuses_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Query_RootCredit_ProfilesArgs = {
  distinct_on?: InputMaybe<Array<Credit_Profiles_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Credit_Profiles_Order_By>>;
  where?: InputMaybe<Credit_Profiles_Bool_Exp>;
};

export type Query_RootCredit_Profiles_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Credit_Profiles_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Credit_Profiles_Order_By>>;
  where?: InputMaybe<Credit_Profiles_Bool_Exp>;
};

export type Query_RootCredit_Profiles_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Query_RootCustomer_Daily_GrowthArgs = {
  args: Customer_Daily_Growth_Args;
  distinct_on?: InputMaybe<Array<_Cus_Growth_Data_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<_Cus_Growth_Data_Order_By>>;
  where?: InputMaybe<_Cus_Growth_Data_Bool_Exp>;
};

export type Query_RootCustomer_Daily_Growth_AggregateArgs = {
  args: Customer_Daily_Growth_Args;
  distinct_on?: InputMaybe<Array<_Cus_Growth_Data_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<_Cus_Growth_Data_Order_By>>;
  where?: InputMaybe<_Cus_Growth_Data_Bool_Exp>;
};

export type Query_RootDelivery_MethodsArgs = {
  distinct_on?: InputMaybe<Array<Delivery_Methods_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Delivery_Methods_Order_By>>;
  where?: InputMaybe<Delivery_Methods_Bool_Exp>;
};

export type Query_RootDelivery_Methods_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Delivery_Methods_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Delivery_Methods_Order_By>>;
  where?: InputMaybe<Delivery_Methods_Bool_Exp>;
};

export type Query_RootDelivery_Methods_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Query_RootDocument_InteractionsArgs = {
  distinct_on?: InputMaybe<Array<Document_Interactions_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Document_Interactions_Order_By>>;
  where?: InputMaybe<Document_Interactions_Bool_Exp>;
};

export type Query_RootDocument_Interactions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Document_Interactions_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Document_Interactions_Order_By>>;
  where?: InputMaybe<Document_Interactions_Bool_Exp>;
};

export type Query_RootDocument_Interactions_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Query_RootDocumentsArgs = {
  distinct_on?: InputMaybe<Array<Documents_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Documents_Order_By>>;
  where?: InputMaybe<Documents_Bool_Exp>;
};

export type Query_RootDocuments_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Documents_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Documents_Order_By>>;
  where?: InputMaybe<Documents_Bool_Exp>;
};

export type Query_RootDocuments_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Query_RootFlagsArgs = {
  distinct_on?: InputMaybe<Array<Flags_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Flags_Order_By>>;
  where?: InputMaybe<Flags_Bool_Exp>;
};

export type Query_RootFlags_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Flags_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Flags_Order_By>>;
  where?: InputMaybe<Flags_Bool_Exp>;
};

export type Query_RootFlags_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Query_RootForm_FieldsArgs = {
  distinct_on?: InputMaybe<Array<Form_Fields_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Form_Fields_Order_By>>;
  where?: InputMaybe<Form_Fields_Bool_Exp>;
};

export type Query_RootForm_Fields_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Form_Fields_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Form_Fields_Order_By>>;
  where?: InputMaybe<Form_Fields_Bool_Exp>;
};

export type Query_RootForm_Fields_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Query_RootForm_RulesArgs = {
  distinct_on?: InputMaybe<Array<Form_Rules_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Form_Rules_Order_By>>;
  where?: InputMaybe<Form_Rules_Bool_Exp>;
};

export type Query_RootForm_Rules_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Form_Rules_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Form_Rules_Order_By>>;
  where?: InputMaybe<Form_Rules_Bool_Exp>;
};

export type Query_RootForm_Rules_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Query_RootFormsArgs = {
  distinct_on?: InputMaybe<Array<Forms_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Forms_Order_By>>;
  where?: InputMaybe<Forms_Bool_Exp>;
};

export type Query_RootForms_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Forms_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Forms_Order_By>>;
  where?: InputMaybe<Forms_Bool_Exp>;
};

export type Query_RootForms_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Query_RootGetLastReadMessagesArgs = {
  params: GetLastReadMessagesInput;
};

export type Query_RootGetReadMessagesArgs = {
  params: GetReadMessagesInput;
};

export type Query_RootGetScheduledJobsArgs = {
  params: GetScheduledJobsInput;
};

export type Query_RootGet_Party_ChannelArgs = {
  args: Get_Party_Channel_Args;
  distinct_on?: InputMaybe<Array<Channel_Parties_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Channel_Parties_Order_By>>;
  where?: InputMaybe<Channel_Parties_Bool_Exp>;
};

export type Query_RootGet_Party_Channel_AggregateArgs = {
  args: Get_Party_Channel_Args;
  distinct_on?: InputMaybe<Array<Channel_Parties_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Channel_Parties_Order_By>>;
  where?: InputMaybe<Channel_Parties_Bool_Exp>;
};

export type Query_RootInitiateMemberLoginArgs = {
  phoneNumber: Scalars["String"];
};

export type Query_RootInteraction_DetailsArgs = {
  distinct_on?: InputMaybe<Array<Interaction_Details_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Interaction_Details_Order_By>>;
  where?: InputMaybe<Interaction_Details_Bool_Exp>;
};

export type Query_RootInteraction_Details_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Interaction_Details_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Interaction_Details_Order_By>>;
  where?: InputMaybe<Interaction_Details_Bool_Exp>;
};

export type Query_RootInteraction_Details_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Query_RootInteractionsArgs = {
  distinct_on?: InputMaybe<Array<Interactions_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Interactions_Order_By>>;
  where?: InputMaybe<Interactions_Bool_Exp>;
};

export type Query_RootInteractions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Interactions_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Interactions_Order_By>>;
  where?: InputMaybe<Interactions_Bool_Exp>;
};

export type Query_RootInteractions_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Query_RootInvoice_InteractionsArgs = {
  distinct_on?: InputMaybe<Array<Invoice_Interactions_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Invoice_Interactions_Order_By>>;
  where?: InputMaybe<Invoice_Interactions_Bool_Exp>;
};

export type Query_RootInvoice_Interactions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Invoice_Interactions_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Invoice_Interactions_Order_By>>;
  where?: InputMaybe<Invoice_Interactions_Bool_Exp>;
};

export type Query_RootInvoice_Interactions_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Query_RootInvoice_ItemsArgs = {
  distinct_on?: InputMaybe<Array<Invoice_Items_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Invoice_Items_Order_By>>;
  where?: InputMaybe<Invoice_Items_Bool_Exp>;
};

export type Query_RootInvoice_Items_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Invoice_Items_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Invoice_Items_Order_By>>;
  where?: InputMaybe<Invoice_Items_Bool_Exp>;
};

export type Query_RootInvoice_Items_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Query_RootInvoicesArgs = {
  distinct_on?: InputMaybe<Array<Invoices_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Invoices_Order_By>>;
  where?: InputMaybe<Invoices_Bool_Exp>;
};

export type Query_RootInvoices_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Invoices_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Invoices_Order_By>>;
  where?: InputMaybe<Invoices_Bool_Exp>;
};

export type Query_RootInvoices_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Query_RootLedger_AccountsArgs = {
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  organisation_id: Scalars["uuid"];
  where?: InputMaybe<Ledger_Accounts_Bool_Exp>;
};

export type Query_RootLedger_Accounts_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Query_RootLedger_Entries_By_Ledger_Account_IdArgs = {
  ledger_account_id: Scalars["uuid"];
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type Query_RootLedger_Entries_By_Ledger_Account_IdsArgs = {
  ledger_account_ids?: InputMaybe<Array<Scalars["uuid"]>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<Ledger_Entries_Bool_Exp>;
};

export type Query_RootLedgersArgs = {
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  organisation_id: Scalars["uuid"];
};

export type Query_RootLedgers_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Query_RootLoanProductSummaryArgs = {
  productId: Scalars["uuid"];
};

export type Query_RootMessage_Delivery_MethodsArgs = {
  distinct_on?: InputMaybe<Array<Message_Delivery_Methods_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Message_Delivery_Methods_Order_By>>;
  where?: InputMaybe<Message_Delivery_Methods_Bool_Exp>;
};

export type Query_RootMessage_Delivery_Methods_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Message_Delivery_Methods_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Message_Delivery_Methods_Order_By>>;
  where?: InputMaybe<Message_Delivery_Methods_Bool_Exp>;
};

export type Query_RootMessage_Delivery_Methods_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Query_RootMessage_Delivery_ResponsesArgs = {
  distinct_on?: InputMaybe<Array<Message_Delivery_Responses_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Message_Delivery_Responses_Order_By>>;
  where?: InputMaybe<Message_Delivery_Responses_Bool_Exp>;
};

export type Query_RootMessage_Delivery_Responses_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Message_Delivery_Responses_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Message_Delivery_Responses_Order_By>>;
  where?: InputMaybe<Message_Delivery_Responses_Bool_Exp>;
};

export type Query_RootMessage_Delivery_Responses_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Query_RootMessage_FlagsArgs = {
  distinct_on?: InputMaybe<Array<Message_Flags_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Message_Flags_Order_By>>;
  where?: InputMaybe<Message_Flags_Bool_Exp>;
};

export type Query_RootMessage_Flags_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Message_Flags_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Message_Flags_Order_By>>;
  where?: InputMaybe<Message_Flags_Bool_Exp>;
};

export type Query_RootMessage_Flags_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Query_RootMessage_StatusesArgs = {
  distinct_on?: InputMaybe<Array<Message_Statuses_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Message_Statuses_Order_By>>;
  where?: InputMaybe<Message_Statuses_Bool_Exp>;
};

export type Query_RootMessage_Statuses_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Message_Statuses_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Message_Statuses_Order_By>>;
  where?: InputMaybe<Message_Statuses_Bool_Exp>;
};

export type Query_RootMessage_Statuses_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Query_RootMessagesArgs = {
  distinct_on?: InputMaybe<Array<Messages_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Messages_Order_By>>;
  where?: InputMaybe<Messages_Bool_Exp>;
};

export type Query_RootMessages_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Messages_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Messages_Order_By>>;
  where?: InputMaybe<Messages_Bool_Exp>;
};

export type Query_RootMessages_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Query_RootMom_Ledger_Account_Growth_ReportArgs = {
  ledger_account_ids?: InputMaybe<Array<Scalars["uuid"]>>;
  organisation_id: Scalars["uuid"];
  period?: InputMaybe<Array<Scalars["String"]>>;
};

export type Query_RootMom_Ledger_Growth_ReportArgs = {
  ledger_id: Scalars["uuid"];
  organisation_id: Scalars["uuid"];
  period?: InputMaybe<Array<Scalars["String"]>>;
};

export type Query_RootNew_Cases_Daily_GrowthArgs = {
  args: New_Cases_Daily_Growth_Args;
  distinct_on?: InputMaybe<Array<_Case_Dod_Data_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<_Case_Dod_Data_Order_By>>;
  where?: InputMaybe<_Case_Dod_Data_Bool_Exp>;
};

export type Query_RootNew_Cases_Daily_Growth_AggregateArgs = {
  args: New_Cases_Daily_Growth_Args;
  distinct_on?: InputMaybe<Array<_Case_Dod_Data_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<_Case_Dod_Data_Order_By>>;
  where?: InputMaybe<_Case_Dod_Data_Bool_Exp>;
};

export type Query_RootOrganisation_CredentialsArgs = {
  distinct_on?: InputMaybe<Array<Organisation_Credentials_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Organisation_Credentials_Order_By>>;
  where?: InputMaybe<Organisation_Credentials_Bool_Exp>;
};

export type Query_RootOrganisation_Credentials_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Organisation_Credentials_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Organisation_Credentials_Order_By>>;
  where?: InputMaybe<Organisation_Credentials_Bool_Exp>;
};

export type Query_RootOrganisation_Credentials_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Query_RootOrganisation_FlagsArgs = {
  distinct_on?: InputMaybe<Array<Organisation_Flags_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Organisation_Flags_Order_By>>;
  where?: InputMaybe<Organisation_Flags_Bool_Exp>;
};

export type Query_RootOrganisation_Flags_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Organisation_Flags_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Organisation_Flags_Order_By>>;
  where?: InputMaybe<Organisation_Flags_Bool_Exp>;
};

export type Query_RootOrganisation_Flags_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Query_RootOrganisation_InvitesArgs = {
  distinct_on?: InputMaybe<Array<Organisation_Invites_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Organisation_Invites_Order_By>>;
  where?: InputMaybe<Organisation_Invites_Bool_Exp>;
};

export type Query_RootOrganisation_Invites_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Organisation_Invites_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Organisation_Invites_Order_By>>;
  where?: InputMaybe<Organisation_Invites_Bool_Exp>;
};

export type Query_RootOrganisation_Invites_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Query_RootOrganisation_RulesArgs = {
  distinct_on?: InputMaybe<Array<Organisation_Rules_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Organisation_Rules_Order_By>>;
  where?: InputMaybe<Organisation_Rules_Bool_Exp>;
};

export type Query_RootOrganisation_Rules_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Organisation_Rules_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Organisation_Rules_Order_By>>;
  where?: InputMaybe<Organisation_Rules_Bool_Exp>;
};

export type Query_RootOrganisation_Rules_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Query_RootOrganisation_SettingsArgs = {
  distinct_on?: InputMaybe<Array<Organisation_Settings_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Organisation_Settings_Order_By>>;
  where?: InputMaybe<Organisation_Settings_Bool_Exp>;
};

export type Query_RootOrganisation_Settings_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Organisation_Settings_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Organisation_Settings_Order_By>>;
  where?: InputMaybe<Organisation_Settings_Bool_Exp>;
};

export type Query_RootOrganisation_Settings_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Query_RootOrganisation_StatusesArgs = {
  distinct_on?: InputMaybe<Array<Organisation_Statuses_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Organisation_Statuses_Order_By>>;
  where?: InputMaybe<Organisation_Statuses_Bool_Exp>;
};

export type Query_RootOrganisation_Statuses_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Organisation_Statuses_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Organisation_Statuses_Order_By>>;
  where?: InputMaybe<Organisation_Statuses_Bool_Exp>;
};

export type Query_RootOrganisation_Statuses_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Query_RootOrganisationsArgs = {
  distinct_on?: InputMaybe<Array<Organisations_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Organisations_Order_By>>;
  where?: InputMaybe<Organisations_Bool_Exp>;
};

export type Query_RootOrganisations_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Organisations_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Organisations_Order_By>>;
  where?: InputMaybe<Organisations_Bool_Exp>;
};

export type Query_RootOrganisations_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Query_RootPartiesArgs = {
  distinct_on?: InputMaybe<Array<Parties_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Parties_Order_By>>;
  where?: InputMaybe<Parties_Bool_Exp>;
};

export type Query_RootParties_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Parties_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Parties_Order_By>>;
  where?: InputMaybe<Parties_Bool_Exp>;
};

export type Query_RootParties_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Query_RootParty_ContactsArgs = {
  distinct_on?: InputMaybe<Array<Party_Contacts_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Party_Contacts_Order_By>>;
  where?: InputMaybe<Party_Contacts_Bool_Exp>;
};

export type Query_RootParty_Contacts_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Party_Contacts_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Party_Contacts_Order_By>>;
  where?: InputMaybe<Party_Contacts_Bool_Exp>;
};

export type Query_RootParty_Contacts_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Query_RootParty_DocumentsArgs = {
  distinct_on?: InputMaybe<Array<Party_Documents_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Party_Documents_Order_By>>;
  where?: InputMaybe<Party_Documents_Bool_Exp>;
};

export type Query_RootParty_Documents_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Party_Documents_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Party_Documents_Order_By>>;
  where?: InputMaybe<Party_Documents_Bool_Exp>;
};

export type Query_RootParty_Documents_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Query_RootParty_FlagsArgs = {
  distinct_on?: InputMaybe<Array<Party_Flags_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Party_Flags_Order_By>>;
  where?: InputMaybe<Party_Flags_Bool_Exp>;
};

export type Query_RootParty_Flags_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Party_Flags_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Party_Flags_Order_By>>;
  where?: InputMaybe<Party_Flags_Bool_Exp>;
};

export type Query_RootParty_Flags_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Query_RootParty_InteractionsArgs = {
  distinct_on?: InputMaybe<Array<Party_Interactions_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Party_Interactions_Order_By>>;
  where?: InputMaybe<Party_Interactions_Bool_Exp>;
};

export type Query_RootParty_Interactions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Party_Interactions_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Party_Interactions_Order_By>>;
  where?: InputMaybe<Party_Interactions_Bool_Exp>;
};

export type Query_RootParty_Interactions_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Query_RootParty_Ledger_AccountsArgs = {
  distinct_on?: InputMaybe<Array<Party_Ledger_Accounts_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Party_Ledger_Accounts_Order_By>>;
  where?: InputMaybe<Party_Ledger_Accounts_Bool_Exp>;
};

export type Query_RootParty_Ledger_Accounts_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Party_Ledger_Accounts_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Party_Ledger_Accounts_Order_By>>;
  where?: InputMaybe<Party_Ledger_Accounts_Bool_Exp>;
};

export type Query_RootParty_Ledger_Accounts_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Query_RootParty_Relationship_StatusesArgs = {
  distinct_on?: InputMaybe<Array<Party_Relationship_Statuses_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Party_Relationship_Statuses_Order_By>>;
  where?: InputMaybe<Party_Relationship_Statuses_Bool_Exp>;
};

export type Query_RootParty_Relationship_Statuses_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Party_Relationship_Statuses_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Party_Relationship_Statuses_Order_By>>;
  where?: InputMaybe<Party_Relationship_Statuses_Bool_Exp>;
};

export type Query_RootParty_Relationship_Statuses_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Query_RootParty_RelationshipsArgs = {
  distinct_on?: InputMaybe<Array<Party_Relationships_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Party_Relationships_Order_By>>;
  where?: InputMaybe<Party_Relationships_Bool_Exp>;
};

export type Query_RootParty_Relationships_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Party_Relationships_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Party_Relationships_Order_By>>;
  where?: InputMaybe<Party_Relationships_Bool_Exp>;
};

export type Query_RootParty_Relationships_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Query_RootParty_SettingsArgs = {
  distinct_on?: InputMaybe<Array<Party_Settings_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Party_Settings_Order_By>>;
  where?: InputMaybe<Party_Settings_Bool_Exp>;
};

export type Query_RootParty_Settings_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Party_Settings_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Party_Settings_Order_By>>;
  where?: InputMaybe<Party_Settings_Bool_Exp>;
};

export type Query_RootParty_Settings_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Query_RootParty_StatusesArgs = {
  distinct_on?: InputMaybe<Array<Party_Statuses_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Party_Statuses_Order_By>>;
  where?: InputMaybe<Party_Statuses_Bool_Exp>;
};

export type Query_RootParty_Statuses_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Party_Statuses_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Party_Statuses_Order_By>>;
  where?: InputMaybe<Party_Statuses_Bool_Exp>;
};

export type Query_RootParty_Statuses_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Query_RootPayment_IntentsArgs = {
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  organisation_id: Scalars["uuid"];
  where?: InputMaybe<Payment_Intent_Bool_Exp>;
};

export type Query_RootPayment_Intents_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Query_RootPaymentsArgs = {
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  organisation_id: Scalars["uuid"];
  where?: InputMaybe<Payment_Bool_Exp>;
};

export type Query_RootPayments_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Query_RootPerformance_IndicatorsArgs = {
  distinct_on?: InputMaybe<Array<Performance_Indicators_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Performance_Indicators_Order_By>>;
  where?: InputMaybe<Performance_Indicators_Bool_Exp>;
};

export type Query_RootPerformance_Indicators_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Performance_Indicators_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Performance_Indicators_Order_By>>;
  where?: InputMaybe<Performance_Indicators_Bool_Exp>;
};

export type Query_RootPerformance_Indicators_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Query_RootPerformance_TargetsArgs = {
  distinct_on?: InputMaybe<Array<Performance_Targets_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Performance_Targets_Order_By>>;
  where?: InputMaybe<Performance_Targets_Bool_Exp>;
};

export type Query_RootPerformance_Targets_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Performance_Targets_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Performance_Targets_Order_By>>;
  where?: InputMaybe<Performance_Targets_Bool_Exp>;
};

export type Query_RootPerformance_Targets_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Query_RootProduct_DocumentsArgs = {
  distinct_on?: InputMaybe<Array<Product_Documents_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Product_Documents_Order_By>>;
  where?: InputMaybe<Product_Documents_Bool_Exp>;
};

export type Query_RootProduct_Documents_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Product_Documents_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Product_Documents_Order_By>>;
  where?: InputMaybe<Product_Documents_Bool_Exp>;
};

export type Query_RootProduct_Documents_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Query_RootProduct_InteractionsArgs = {
  distinct_on?: InputMaybe<Array<Product_Interactions_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Product_Interactions_Order_By>>;
  where?: InputMaybe<Product_Interactions_Bool_Exp>;
};

export type Query_RootProduct_Interactions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Product_Interactions_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Product_Interactions_Order_By>>;
  where?: InputMaybe<Product_Interactions_Bool_Exp>;
};

export type Query_RootProduct_Interactions_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Query_RootProduct_ItemsArgs = {
  distinct_on?: InputMaybe<Array<Product_Items_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Product_Items_Order_By>>;
  where?: InputMaybe<Product_Items_Bool_Exp>;
};

export type Query_RootProduct_Items_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Product_Items_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Product_Items_Order_By>>;
  where?: InputMaybe<Product_Items_Bool_Exp>;
};

export type Query_RootProduct_Items_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Query_RootProduct_RulesArgs = {
  distinct_on?: InputMaybe<Array<Product_Rules_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Product_Rules_Order_By>>;
  where?: InputMaybe<Product_Rules_Bool_Exp>;
};

export type Query_RootProduct_Rules_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Product_Rules_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Product_Rules_Order_By>>;
  where?: InputMaybe<Product_Rules_Bool_Exp>;
};

export type Query_RootProduct_Rules_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Query_RootProduct_SettingsArgs = {
  distinct_on?: InputMaybe<Array<Product_Settings_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Product_Settings_Order_By>>;
  where?: InputMaybe<Product_Settings_Bool_Exp>;
};

export type Query_RootProduct_Settings_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Product_Settings_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Product_Settings_Order_By>>;
  where?: InputMaybe<Product_Settings_Bool_Exp>;
};

export type Query_RootProduct_Settings_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Query_RootProduct_StatusesArgs = {
  distinct_on?: InputMaybe<Array<Product_Statuses_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Product_Statuses_Order_By>>;
  where?: InputMaybe<Product_Statuses_Bool_Exp>;
};

export type Query_RootProduct_Statuses_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Product_Statuses_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Product_Statuses_Order_By>>;
  where?: InputMaybe<Product_Statuses_Bool_Exp>;
};

export type Query_RootProduct_Statuses_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Query_RootProduct_TemplatesArgs = {
  distinct_on?: InputMaybe<Array<Product_Templates_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Product_Templates_Order_By>>;
  where?: InputMaybe<Product_Templates_Bool_Exp>;
};

export type Query_RootProduct_Templates_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Product_Templates_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Product_Templates_Order_By>>;
  where?: InputMaybe<Product_Templates_Bool_Exp>;
};

export type Query_RootProduct_Templates_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Query_RootProductsArgs = {
  distinct_on?: InputMaybe<Array<Products_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Products_Order_By>>;
  where?: InputMaybe<Products_Bool_Exp>;
};

export type Query_RootProducts_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Products_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Products_Order_By>>;
  where?: InputMaybe<Products_Bool_Exp>;
};

export type Query_RootProducts_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Query_RootReward_RedemptionsArgs = {
  distinct_on?: InputMaybe<Array<Reward_Redemptions_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Reward_Redemptions_Order_By>>;
  where?: InputMaybe<Reward_Redemptions_Bool_Exp>;
};

export type Query_RootReward_Redemptions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Reward_Redemptions_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Reward_Redemptions_Order_By>>;
  where?: InputMaybe<Reward_Redemptions_Bool_Exp>;
};

export type Query_RootReward_Redemptions_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Query_RootRewardsArgs = {
  distinct_on?: InputMaybe<Array<Rewards_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Rewards_Order_By>>;
  where?: InputMaybe<Rewards_Bool_Exp>;
};

export type Query_RootRewards_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Rewards_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Rewards_Order_By>>;
  where?: InputMaybe<Rewards_Bool_Exp>;
};

export type Query_RootRewards_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Query_RootRulesArgs = {
  distinct_on?: InputMaybe<Array<Rules_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Rules_Order_By>>;
  where?: InputMaybe<Rules_Bool_Exp>;
};

export type Query_RootRules_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Rules_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Rules_Order_By>>;
  where?: InputMaybe<Rules_Bool_Exp>;
};

export type Query_RootRules_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Query_RootStatusesArgs = {
  distinct_on?: InputMaybe<Array<Statuses_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Statuses_Order_By>>;
  where?: InputMaybe<Statuses_Bool_Exp>;
};

export type Query_RootStatuses_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Statuses_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Statuses_Order_By>>;
  where?: InputMaybe<Statuses_Bool_Exp>;
};

export type Query_RootStatuses_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Query_RootSubscription_CasesArgs = {
  distinct_on?: InputMaybe<Array<Subscription_Cases_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Subscription_Cases_Order_By>>;
  where?: InputMaybe<Subscription_Cases_Bool_Exp>;
};

export type Query_RootSubscription_Cases_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Subscription_Cases_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Subscription_Cases_Order_By>>;
  where?: InputMaybe<Subscription_Cases_Bool_Exp>;
};

export type Query_RootSubscription_Cases_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Query_RootSubscription_DetailsArgs = {
  distinct_on?: InputMaybe<Array<Subscription_Details_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Subscription_Details_Order_By>>;
  where?: InputMaybe<Subscription_Details_Bool_Exp>;
};

export type Query_RootSubscription_Details_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Subscription_Details_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Subscription_Details_Order_By>>;
  where?: InputMaybe<Subscription_Details_Bool_Exp>;
};

export type Query_RootSubscription_Details_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Query_RootSubscription_DocumentsArgs = {
  distinct_on?: InputMaybe<Array<Subscription_Documents_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Subscription_Documents_Order_By>>;
  where?: InputMaybe<Subscription_Documents_Bool_Exp>;
};

export type Query_RootSubscription_Documents_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Subscription_Documents_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Subscription_Documents_Order_By>>;
  where?: InputMaybe<Subscription_Documents_Bool_Exp>;
};

export type Query_RootSubscription_Documents_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Query_RootSubscription_FlagsArgs = {
  distinct_on?: InputMaybe<Array<Subscription_Flags_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Subscription_Flags_Order_By>>;
  where?: InputMaybe<Subscription_Flags_Bool_Exp>;
};

export type Query_RootSubscription_Flags_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Subscription_Flags_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Subscription_Flags_Order_By>>;
  where?: InputMaybe<Subscription_Flags_Bool_Exp>;
};

export type Query_RootSubscription_Flags_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Query_RootSubscription_InteractionsArgs = {
  distinct_on?: InputMaybe<Array<Subscription_Interactions_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Subscription_Interactions_Order_By>>;
  where?: InputMaybe<Subscription_Interactions_Bool_Exp>;
};

export type Query_RootSubscription_Interactions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Subscription_Interactions_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Subscription_Interactions_Order_By>>;
  where?: InputMaybe<Subscription_Interactions_Bool_Exp>;
};

export type Query_RootSubscription_Interactions_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Query_RootSubscription_ItemsArgs = {
  distinct_on?: InputMaybe<Array<Subscription_Items_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Subscription_Items_Order_By>>;
  where?: InputMaybe<Subscription_Items_Bool_Exp>;
};

export type Query_RootSubscription_Items_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Subscription_Items_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Subscription_Items_Order_By>>;
  where?: InputMaybe<Subscription_Items_Bool_Exp>;
};

export type Query_RootSubscription_Items_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Query_RootSubscription_Payment_TransactionsArgs = {
  distinct_on?: InputMaybe<
    Array<Subscription_Payment_Transactions_Select_Column>
  >;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Subscription_Payment_Transactions_Order_By>>;
  where?: InputMaybe<Subscription_Payment_Transactions_Bool_Exp>;
};

export type Query_RootSubscription_Payment_Transactions_AggregateArgs = {
  distinct_on?: InputMaybe<
    Array<Subscription_Payment_Transactions_Select_Column>
  >;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Subscription_Payment_Transactions_Order_By>>;
  where?: InputMaybe<Subscription_Payment_Transactions_Bool_Exp>;
};

export type Query_RootSubscription_Payment_Transactions_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Query_RootSubscription_PaymentsArgs = {
  distinct_on?: InputMaybe<Array<Subscription_Payments_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Subscription_Payments_Order_By>>;
  where?: InputMaybe<Subscription_Payments_Bool_Exp>;
};

export type Query_RootSubscription_Payments_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Subscription_Payments_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Subscription_Payments_Order_By>>;
  where?: InputMaybe<Subscription_Payments_Bool_Exp>;
};

export type Query_RootSubscription_Payments_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Query_RootSubscription_RulesArgs = {
  distinct_on?: InputMaybe<Array<Subscription_Rules_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Subscription_Rules_Order_By>>;
  where?: InputMaybe<Subscription_Rules_Bool_Exp>;
};

export type Query_RootSubscription_Rules_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Subscription_Rules_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Subscription_Rules_Order_By>>;
  where?: InputMaybe<Subscription_Rules_Bool_Exp>;
};

export type Query_RootSubscription_Rules_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Query_RootSubscription_SchedulesArgs = {
  distinct_on?: InputMaybe<Array<Subscription_Schedules_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Subscription_Schedules_Order_By>>;
  where?: InputMaybe<Subscription_Schedules_Bool_Exp>;
};

export type Query_RootSubscription_Schedules_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Subscription_Schedules_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Subscription_Schedules_Order_By>>;
  where?: InputMaybe<Subscription_Schedules_Bool_Exp>;
};

export type Query_RootSubscription_Schedules_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Query_RootSubscription_StatusesArgs = {
  distinct_on?: InputMaybe<Array<Subscription_Statuses_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Subscription_Statuses_Order_By>>;
  where?: InputMaybe<Subscription_Statuses_Bool_Exp>;
};

export type Query_RootSubscription_Statuses_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Subscription_Statuses_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Subscription_Statuses_Order_By>>;
  where?: InputMaybe<Subscription_Statuses_Bool_Exp>;
};

export type Query_RootSubscription_Statuses_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Query_RootSubscription_TasksArgs = {
  distinct_on?: InputMaybe<Array<Subscription_Tasks_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Subscription_Tasks_Order_By>>;
  where?: InputMaybe<Subscription_Tasks_Bool_Exp>;
};

export type Query_RootSubscription_Tasks_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Subscription_Tasks_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Subscription_Tasks_Order_By>>;
  where?: InputMaybe<Subscription_Tasks_Bool_Exp>;
};

export type Query_RootSubscription_Tasks_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Query_RootSubscriptionsArgs = {
  distinct_on?: InputMaybe<Array<Subscriptions_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Subscriptions_Order_By>>;
  where?: InputMaybe<Subscriptions_Bool_Exp>;
};

export type Query_RootSubscriptions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Subscriptions_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Subscriptions_Order_By>>;
  where?: InputMaybe<Subscriptions_Bool_Exp>;
};

export type Query_RootSubscriptions_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Query_RootTariff_HistoriesArgs = {
  distinct_on?: InputMaybe<Array<Tariff_Histories_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Tariff_Histories_Order_By>>;
  where?: InputMaybe<Tariff_Histories_Bool_Exp>;
};

export type Query_RootTariff_Histories_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tariff_Histories_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Tariff_Histories_Order_By>>;
  where?: InputMaybe<Tariff_Histories_Bool_Exp>;
};

export type Query_RootTariff_Histories_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Query_RootTariffsArgs = {
  distinct_on?: InputMaybe<Array<Tariffs_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Tariffs_Order_By>>;
  where?: InputMaybe<Tariffs_Bool_Exp>;
};

export type Query_RootTariffs_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tariffs_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Tariffs_Order_By>>;
  where?: InputMaybe<Tariffs_Bool_Exp>;
};

export type Query_RootTariffs_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Query_RootTask_AssignmentsArgs = {
  distinct_on?: InputMaybe<Array<Task_Assignments_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Task_Assignments_Order_By>>;
  where?: InputMaybe<Task_Assignments_Bool_Exp>;
};

export type Query_RootTask_Assignments_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Task_Assignments_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Task_Assignments_Order_By>>;
  where?: InputMaybe<Task_Assignments_Bool_Exp>;
};

export type Query_RootTask_Assignments_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Query_RootTask_DocumentsArgs = {
  distinct_on?: InputMaybe<Array<Task_Documents_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Task_Documents_Order_By>>;
  where?: InputMaybe<Task_Documents_Bool_Exp>;
};

export type Query_RootTask_Documents_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Task_Documents_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Task_Documents_Order_By>>;
  where?: InputMaybe<Task_Documents_Bool_Exp>;
};

export type Query_RootTask_Documents_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Query_RootTask_FlagsArgs = {
  distinct_on?: InputMaybe<Array<Task_Flags_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Task_Flags_Order_By>>;
  where?: InputMaybe<Task_Flags_Bool_Exp>;
};

export type Query_RootTask_Flags_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Task_Flags_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Task_Flags_Order_By>>;
  where?: InputMaybe<Task_Flags_Bool_Exp>;
};

export type Query_RootTask_Flags_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Query_RootTask_InteractionsArgs = {
  distinct_on?: InputMaybe<Array<Task_Interactions_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Task_Interactions_Order_By>>;
  where?: InputMaybe<Task_Interactions_Bool_Exp>;
};

export type Query_RootTask_Interactions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Task_Interactions_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Task_Interactions_Order_By>>;
  where?: InputMaybe<Task_Interactions_Bool_Exp>;
};

export type Query_RootTask_Interactions_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Query_RootTask_MentionsArgs = {
  distinct_on?: InputMaybe<Array<Task_Mentions_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Task_Mentions_Order_By>>;
  where?: InputMaybe<Task_Mentions_Bool_Exp>;
};

export type Query_RootTask_Mentions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Task_Mentions_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Task_Mentions_Order_By>>;
  where?: InputMaybe<Task_Mentions_Bool_Exp>;
};

export type Query_RootTask_Mentions_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Query_RootTask_RemindersArgs = {
  distinct_on?: InputMaybe<Array<Task_Reminders_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Task_Reminders_Order_By>>;
  where?: InputMaybe<Task_Reminders_Bool_Exp>;
};

export type Query_RootTask_Reminders_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Task_Reminders_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Task_Reminders_Order_By>>;
  where?: InputMaybe<Task_Reminders_Bool_Exp>;
};

export type Query_RootTask_Reminders_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Query_RootTask_RulesArgs = {
  distinct_on?: InputMaybe<Array<Task_Rules_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Task_Rules_Order_By>>;
  where?: InputMaybe<Task_Rules_Bool_Exp>;
};

export type Query_RootTask_Rules_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Task_Rules_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Task_Rules_Order_By>>;
  where?: InputMaybe<Task_Rules_Bool_Exp>;
};

export type Query_RootTask_Rules_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Query_RootTask_StatusesArgs = {
  distinct_on?: InputMaybe<Array<Task_Statuses_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Task_Statuses_Order_By>>;
  where?: InputMaybe<Task_Statuses_Bool_Exp>;
};

export type Query_RootTask_Statuses_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Task_Statuses_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Task_Statuses_Order_By>>;
  where?: InputMaybe<Task_Statuses_Bool_Exp>;
};

export type Query_RootTask_Statuses_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Query_RootTasksArgs = {
  distinct_on?: InputMaybe<Array<Tasks_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Tasks_Order_By>>;
  where?: InputMaybe<Tasks_Bool_Exp>;
};

export type Query_RootTasks_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tasks_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Tasks_Order_By>>;
  where?: InputMaybe<Tasks_Bool_Exp>;
};

export type Query_RootTasks_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Query_RootTerritoriesArgs = {
  distinct_on?: InputMaybe<Array<Territories_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Territories_Order_By>>;
  where?: InputMaybe<Territories_Bool_Exp>;
};

export type Query_RootTerritories_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Territories_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Territories_Order_By>>;
  where?: InputMaybe<Territories_Bool_Exp>;
};

export type Query_RootTerritories_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Query_RootTerritory_AssignmentsArgs = {
  distinct_on?: InputMaybe<Array<Territory_Assignments_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Territory_Assignments_Order_By>>;
  where?: InputMaybe<Territory_Assignments_Bool_Exp>;
};

export type Query_RootTerritory_Assignments_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Territory_Assignments_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Territory_Assignments_Order_By>>;
  where?: InputMaybe<Territory_Assignments_Bool_Exp>;
};

export type Query_RootTerritory_Assignments_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Query_RootTerritory_FlagsArgs = {
  distinct_on?: InputMaybe<Array<Territory_Flags_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Territory_Flags_Order_By>>;
  where?: InputMaybe<Territory_Flags_Bool_Exp>;
};

export type Query_RootTerritory_Flags_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Territory_Flags_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Territory_Flags_Order_By>>;
  where?: InputMaybe<Territory_Flags_Bool_Exp>;
};

export type Query_RootTerritory_Flags_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Query_RootTerritory_InteractionsArgs = {
  distinct_on?: InputMaybe<Array<Territory_Interactions_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Territory_Interactions_Order_By>>;
  where?: InputMaybe<Territory_Interactions_Bool_Exp>;
};

export type Query_RootTerritory_Interactions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Territory_Interactions_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Territory_Interactions_Order_By>>;
  where?: InputMaybe<Territory_Interactions_Bool_Exp>;
};

export type Query_RootTerritory_Interactions_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Query_RootTerritory_MembersArgs = {
  distinct_on?: InputMaybe<Array<Territory_Members_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Territory_Members_Order_By>>;
  where?: InputMaybe<Territory_Members_Bool_Exp>;
};

export type Query_RootTerritory_Members_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Territory_Members_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Territory_Members_Order_By>>;
  where?: InputMaybe<Territory_Members_Bool_Exp>;
};

export type Query_RootTerritory_Members_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Query_RootTerritory_RulesArgs = {
  distinct_on?: InputMaybe<Array<Territory_Rules_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Territory_Rules_Order_By>>;
  where?: InputMaybe<Territory_Rules_Bool_Exp>;
};

export type Query_RootTerritory_Rules_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Territory_Rules_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Territory_Rules_Order_By>>;
  where?: InputMaybe<Territory_Rules_Bool_Exp>;
};

export type Query_RootTerritory_Rules_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Query_RootTerritory_StatusesArgs = {
  distinct_on?: InputMaybe<Array<Territory_Statuses_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Territory_Statuses_Order_By>>;
  where?: InputMaybe<Territory_Statuses_Bool_Exp>;
};

export type Query_RootTerritory_Statuses_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Territory_Statuses_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Territory_Statuses_Order_By>>;
  where?: InputMaybe<Territory_Statuses_Bool_Exp>;
};

export type Query_RootTerritory_Statuses_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Query_RootTransactionsArgs = {
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  organisation_id: Scalars["uuid"];
  where?: InputMaybe<Transaction_Bool_Exp>;
};

export type Query_RootTransactions_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Query_RootTrial_Balance_ReportArgs = {
  from_date: Scalars["String"];
  ledger_id: Scalars["uuid"];
  to_date: Scalars["String"];
};

export type Query_RootTypesArgs = {
  distinct_on?: InputMaybe<Array<Types_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Types_Order_By>>;
  where?: InputMaybe<Types_Bool_Exp>;
};

export type Query_RootTypes_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Types_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Types_Order_By>>;
  where?: InputMaybe<Types_Bool_Exp>;
};

export type Query_RootTypes_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Query_RootUser_FlagsArgs = {
  distinct_on?: InputMaybe<Array<User_Flags_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<User_Flags_Order_By>>;
  where?: InputMaybe<User_Flags_Bool_Exp>;
};

export type Query_RootUser_Flags_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Flags_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<User_Flags_Order_By>>;
  where?: InputMaybe<User_Flags_Bool_Exp>;
};

export type Query_RootUser_Flags_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Query_RootUser_Mfa_FactorsArgs = {
  distinct_on?: InputMaybe<Array<User_Mfa_Factors_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<User_Mfa_Factors_Order_By>>;
  where?: InputMaybe<User_Mfa_Factors_Bool_Exp>;
};

export type Query_RootUser_Mfa_Factors_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Mfa_Factors_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<User_Mfa_Factors_Order_By>>;
  where?: InputMaybe<User_Mfa_Factors_Bool_Exp>;
};

export type Query_RootUser_Mfa_Factors_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Query_RootUser_StatusesArgs = {
  distinct_on?: InputMaybe<Array<User_Statuses_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<User_Statuses_Order_By>>;
  where?: InputMaybe<User_Statuses_Bool_Exp>;
};

export type Query_RootUser_Statuses_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Statuses_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<User_Statuses_Order_By>>;
  where?: InputMaybe<User_Statuses_Bool_Exp>;
};

export type Query_RootUser_Statuses_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Query_RootUsersArgs = {
  distinct_on?: InputMaybe<Array<Users_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Users_Order_By>>;
  where?: InputMaybe<Users_Bool_Exp>;
};

export type Query_RootUsers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Users_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Users_Order_By>>;
  where?: InputMaybe<Users_Bool_Exp>;
};

export type Query_RootUsers_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Query_RootVerifyAuthFactorArgs = {
  params: VerifyAuthFactorInput;
};

export type Query_RootWebhooksArgs = {
  distinct_on?: InputMaybe<Array<Webhooks_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Webhooks_Order_By>>;
  where?: InputMaybe<Webhooks_Bool_Exp>;
};

export type Query_RootWebhooks_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Webhooks_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Webhooks_Order_By>>;
  where?: InputMaybe<Webhooks_Bool_Exp>;
};

export type Query_RootWebhooks_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Query_RootWorkflow_TemplatesArgs = {
  distinct_on?: InputMaybe<Array<Workflow_Templates_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Workflow_Templates_Order_By>>;
  where?: InputMaybe<Workflow_Templates_Bool_Exp>;
};

export type Query_RootWorkflow_Templates_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Workflow_Templates_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Workflow_Templates_Order_By>>;
  where?: InputMaybe<Workflow_Templates_Bool_Exp>;
};

export type Query_RootWorkflow_Templates_By_PkArgs = {
  id: Scalars["uuid"];
};

/** columns and relationships of "reward_redemptions" */
export type Reward_Redemptions = {
  __typename?: "reward_redemptions";
  created_at: Scalars["timestamptz"];
  id: Scalars["uuid"];
  is_voided: Scalars["Boolean"];
  organisation_id?: Maybe<Scalars["uuid"]>;
  /** An object relationship */
  party: Parties;
  party_id: Scalars["uuid"];
  /** An object relationship */
  reward: Rewards;
  reward_id: Scalars["uuid"];
  updated_at: Scalars["timestamptz"];
  void_reason: Scalars["String"];
};

/** aggregated selection of "reward_redemptions" */
export type Reward_Redemptions_Aggregate = {
  __typename?: "reward_redemptions_aggregate";
  aggregate?: Maybe<Reward_Redemptions_Aggregate_Fields>;
  nodes: Array<Reward_Redemptions>;
};

export type Reward_Redemptions_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Reward_Redemptions_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Reward_Redemptions_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Reward_Redemptions_Aggregate_Bool_Exp_Count>;
};

export type Reward_Redemptions_Aggregate_Bool_Exp_Bool_And = {
  arguments: Reward_Redemptions_Select_Column_Reward_Redemptions_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<Reward_Redemptions_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Reward_Redemptions_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Reward_Redemptions_Select_Column_Reward_Redemptions_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<Reward_Redemptions_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Reward_Redemptions_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Reward_Redemptions_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<Reward_Redemptions_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "reward_redemptions" */
export type Reward_Redemptions_Aggregate_Fields = {
  __typename?: "reward_redemptions_aggregate_fields";
  count: Scalars["Int"];
  max?: Maybe<Reward_Redemptions_Max_Fields>;
  min?: Maybe<Reward_Redemptions_Min_Fields>;
};

/** aggregate fields of "reward_redemptions" */
export type Reward_Redemptions_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Reward_Redemptions_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "reward_redemptions" */
export type Reward_Redemptions_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Reward_Redemptions_Max_Order_By>;
  min?: InputMaybe<Reward_Redemptions_Min_Order_By>;
};

/** input type for inserting array relation for remote table "reward_redemptions" */
export type Reward_Redemptions_Arr_Rel_Insert_Input = {
  data: Array<Reward_Redemptions_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Reward_Redemptions_On_Conflict>;
};

/** Boolean expression to filter rows from the table "reward_redemptions". All fields are combined with a logical 'AND'. */
export type Reward_Redemptions_Bool_Exp = {
  _and?: InputMaybe<Array<Reward_Redemptions_Bool_Exp>>;
  _not?: InputMaybe<Reward_Redemptions_Bool_Exp>;
  _or?: InputMaybe<Array<Reward_Redemptions_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  is_voided?: InputMaybe<Boolean_Comparison_Exp>;
  organisation_id?: InputMaybe<Uuid_Comparison_Exp>;
  party?: InputMaybe<Parties_Bool_Exp>;
  party_id?: InputMaybe<Uuid_Comparison_Exp>;
  reward?: InputMaybe<Rewards_Bool_Exp>;
  reward_id?: InputMaybe<Uuid_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  void_reason?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "reward_redemptions" */
export enum Reward_Redemptions_Constraint {
  /** unique or primary key constraint on columns "id" */
  RewardRedemptionsPkey = "reward_redemptions_pkey",
}

/** input type for inserting data into table "reward_redemptions" */
export type Reward_Redemptions_Insert_Input = {
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  is_voided?: InputMaybe<Scalars["Boolean"]>;
  organisation_id?: InputMaybe<Scalars["uuid"]>;
  party?: InputMaybe<Parties_Obj_Rel_Insert_Input>;
  party_id?: InputMaybe<Scalars["uuid"]>;
  reward?: InputMaybe<Rewards_Obj_Rel_Insert_Input>;
  reward_id?: InputMaybe<Scalars["uuid"]>;
  updated_at?: InputMaybe<Scalars["timestamptz"]>;
  void_reason?: InputMaybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Reward_Redemptions_Max_Fields = {
  __typename?: "reward_redemptions_max_fields";
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["uuid"]>;
  organisation_id?: Maybe<Scalars["uuid"]>;
  party_id?: Maybe<Scalars["uuid"]>;
  reward_id?: Maybe<Scalars["uuid"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
  void_reason?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "reward_redemptions" */
export type Reward_Redemptions_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  organisation_id?: InputMaybe<Order_By>;
  party_id?: InputMaybe<Order_By>;
  reward_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  void_reason?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Reward_Redemptions_Min_Fields = {
  __typename?: "reward_redemptions_min_fields";
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["uuid"]>;
  organisation_id?: Maybe<Scalars["uuid"]>;
  party_id?: Maybe<Scalars["uuid"]>;
  reward_id?: Maybe<Scalars["uuid"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
  void_reason?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "reward_redemptions" */
export type Reward_Redemptions_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  organisation_id?: InputMaybe<Order_By>;
  party_id?: InputMaybe<Order_By>;
  reward_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  void_reason?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "reward_redemptions" */
export type Reward_Redemptions_Mutation_Response = {
  __typename?: "reward_redemptions_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Reward_Redemptions>;
};

/** on_conflict condition type for table "reward_redemptions" */
export type Reward_Redemptions_On_Conflict = {
  constraint: Reward_Redemptions_Constraint;
  update_columns?: Array<Reward_Redemptions_Update_Column>;
  where?: InputMaybe<Reward_Redemptions_Bool_Exp>;
};

/** Ordering options when selecting data from "reward_redemptions". */
export type Reward_Redemptions_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  is_voided?: InputMaybe<Order_By>;
  organisation_id?: InputMaybe<Order_By>;
  party?: InputMaybe<Parties_Order_By>;
  party_id?: InputMaybe<Order_By>;
  reward?: InputMaybe<Rewards_Order_By>;
  reward_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  void_reason?: InputMaybe<Order_By>;
};

/** primary key columns input for table: reward_redemptions */
export type Reward_Redemptions_Pk_Columns_Input = {
  id: Scalars["uuid"];
};

/** select columns of table "reward_redemptions" */
export enum Reward_Redemptions_Select_Column {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  Id = "id",
  /** column name */
  IsVoided = "is_voided",
  /** column name */
  OrganisationId = "organisation_id",
  /** column name */
  PartyId = "party_id",
  /** column name */
  RewardId = "reward_id",
  /** column name */
  UpdatedAt = "updated_at",
  /** column name */
  VoidReason = "void_reason",
}

/** select "reward_redemptions_aggregate_bool_exp_bool_and_arguments_columns" columns of table "reward_redemptions" */
export enum Reward_Redemptions_Select_Column_Reward_Redemptions_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  IsVoided = "is_voided",
}

/** select "reward_redemptions_aggregate_bool_exp_bool_or_arguments_columns" columns of table "reward_redemptions" */
export enum Reward_Redemptions_Select_Column_Reward_Redemptions_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  IsVoided = "is_voided",
}

/** input type for updating data in table "reward_redemptions" */
export type Reward_Redemptions_Set_Input = {
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  is_voided?: InputMaybe<Scalars["Boolean"]>;
  organisation_id?: InputMaybe<Scalars["uuid"]>;
  party_id?: InputMaybe<Scalars["uuid"]>;
  reward_id?: InputMaybe<Scalars["uuid"]>;
  updated_at?: InputMaybe<Scalars["timestamptz"]>;
  void_reason?: InputMaybe<Scalars["String"]>;
};

/** Streaming cursor of the table "reward_redemptions" */
export type Reward_Redemptions_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Reward_Redemptions_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Reward_Redemptions_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  is_voided?: InputMaybe<Scalars["Boolean"]>;
  organisation_id?: InputMaybe<Scalars["uuid"]>;
  party_id?: InputMaybe<Scalars["uuid"]>;
  reward_id?: InputMaybe<Scalars["uuid"]>;
  updated_at?: InputMaybe<Scalars["timestamptz"]>;
  void_reason?: InputMaybe<Scalars["String"]>;
};

/** update columns of table "reward_redemptions" */
export enum Reward_Redemptions_Update_Column {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  Id = "id",
  /** column name */
  IsVoided = "is_voided",
  /** column name */
  OrganisationId = "organisation_id",
  /** column name */
  PartyId = "party_id",
  /** column name */
  RewardId = "reward_id",
  /** column name */
  UpdatedAt = "updated_at",
  /** column name */
  VoidReason = "void_reason",
}

export type Reward_Redemptions_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Reward_Redemptions_Set_Input>;
  /** filter the rows which have to be updated */
  where: Reward_Redemptions_Bool_Exp;
};

/** columns and relationships of "rewards" */
export type Rewards = {
  __typename?: "rewards";
  artwork_url: Scalars["String"];
  /** An array relationship */
  campaign_rewards: Array<Campaign_Rewards>;
  /** An aggregate relationship */
  campaign_rewards_aggregate: Campaign_Rewards_Aggregate;
  created_at: Scalars["timestamptz"];
  description?: Maybe<Scalars["String"]>;
  id: Scalars["uuid"];
  is_active: Scalars["Boolean"];
  multiple_redemptions: Scalars["Boolean"];
  name: Scalars["String"];
  organisation_id?: Maybe<Scalars["uuid"]>;
  redemption_criteria: Scalars["jsonb"];
  /** An array relationship */
  reward_redemptions: Array<Reward_Redemptions>;
  /** An aggregate relationship */
  reward_redemptions_aggregate: Reward_Redemptions_Aggregate;
  updated_at: Scalars["timestamptz"];
};

/** columns and relationships of "rewards" */
export type RewardsCampaign_RewardsArgs = {
  distinct_on?: InputMaybe<Array<Campaign_Rewards_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Campaign_Rewards_Order_By>>;
  where?: InputMaybe<Campaign_Rewards_Bool_Exp>;
};

/** columns and relationships of "rewards" */
export type RewardsCampaign_Rewards_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Campaign_Rewards_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Campaign_Rewards_Order_By>>;
  where?: InputMaybe<Campaign_Rewards_Bool_Exp>;
};

/** columns and relationships of "rewards" */
export type RewardsRedemption_CriteriaArgs = {
  path?: InputMaybe<Scalars["String"]>;
};

/** columns and relationships of "rewards" */
export type RewardsReward_RedemptionsArgs = {
  distinct_on?: InputMaybe<Array<Reward_Redemptions_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Reward_Redemptions_Order_By>>;
  where?: InputMaybe<Reward_Redemptions_Bool_Exp>;
};

/** columns and relationships of "rewards" */
export type RewardsReward_Redemptions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Reward_Redemptions_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Reward_Redemptions_Order_By>>;
  where?: InputMaybe<Reward_Redemptions_Bool_Exp>;
};

/** aggregated selection of "rewards" */
export type Rewards_Aggregate = {
  __typename?: "rewards_aggregate";
  aggregate?: Maybe<Rewards_Aggregate_Fields>;
  nodes: Array<Rewards>;
};

/** aggregate fields of "rewards" */
export type Rewards_Aggregate_Fields = {
  __typename?: "rewards_aggregate_fields";
  count: Scalars["Int"];
  max?: Maybe<Rewards_Max_Fields>;
  min?: Maybe<Rewards_Min_Fields>;
};

/** aggregate fields of "rewards" */
export type Rewards_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Rewards_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Rewards_Append_Input = {
  redemption_criteria?: InputMaybe<Scalars["jsonb"]>;
};

/** Boolean expression to filter rows from the table "rewards". All fields are combined with a logical 'AND'. */
export type Rewards_Bool_Exp = {
  _and?: InputMaybe<Array<Rewards_Bool_Exp>>;
  _not?: InputMaybe<Rewards_Bool_Exp>;
  _or?: InputMaybe<Array<Rewards_Bool_Exp>>;
  artwork_url?: InputMaybe<String_Comparison_Exp>;
  campaign_rewards?: InputMaybe<Campaign_Rewards_Bool_Exp>;
  campaign_rewards_aggregate?: InputMaybe<Campaign_Rewards_Aggregate_Bool_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  is_active?: InputMaybe<Boolean_Comparison_Exp>;
  multiple_redemptions?: InputMaybe<Boolean_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  organisation_id?: InputMaybe<Uuid_Comparison_Exp>;
  redemption_criteria?: InputMaybe<Jsonb_Comparison_Exp>;
  reward_redemptions?: InputMaybe<Reward_Redemptions_Bool_Exp>;
  reward_redemptions_aggregate?: InputMaybe<Reward_Redemptions_Aggregate_Bool_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "rewards" */
export enum Rewards_Constraint {
  /** unique or primary key constraint on columns "id" */
  RewardsPkey = "rewards_pkey",
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Rewards_Delete_At_Path_Input = {
  redemption_criteria?: InputMaybe<Array<Scalars["String"]>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Rewards_Delete_Elem_Input = {
  redemption_criteria?: InputMaybe<Scalars["Int"]>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Rewards_Delete_Key_Input = {
  redemption_criteria?: InputMaybe<Scalars["String"]>;
};

/** input type for inserting data into table "rewards" */
export type Rewards_Insert_Input = {
  artwork_url?: InputMaybe<Scalars["String"]>;
  campaign_rewards?: InputMaybe<Campaign_Rewards_Arr_Rel_Insert_Input>;
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  description?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  is_active?: InputMaybe<Scalars["Boolean"]>;
  multiple_redemptions?: InputMaybe<Scalars["Boolean"]>;
  name?: InputMaybe<Scalars["String"]>;
  organisation_id?: InputMaybe<Scalars["uuid"]>;
  redemption_criteria?: InputMaybe<Scalars["jsonb"]>;
  reward_redemptions?: InputMaybe<Reward_Redemptions_Arr_Rel_Insert_Input>;
  updated_at?: InputMaybe<Scalars["timestamptz"]>;
};

/** aggregate max on columns */
export type Rewards_Max_Fields = {
  __typename?: "rewards_max_fields";
  artwork_url?: Maybe<Scalars["String"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  description?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["uuid"]>;
  name?: Maybe<Scalars["String"]>;
  organisation_id?: Maybe<Scalars["uuid"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
};

/** aggregate min on columns */
export type Rewards_Min_Fields = {
  __typename?: "rewards_min_fields";
  artwork_url?: Maybe<Scalars["String"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  description?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["uuid"]>;
  name?: Maybe<Scalars["String"]>;
  organisation_id?: Maybe<Scalars["uuid"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
};

/** response of any mutation on the table "rewards" */
export type Rewards_Mutation_Response = {
  __typename?: "rewards_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Rewards>;
};

/** input type for inserting object relation for remote table "rewards" */
export type Rewards_Obj_Rel_Insert_Input = {
  data: Rewards_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Rewards_On_Conflict>;
};

/** on_conflict condition type for table "rewards" */
export type Rewards_On_Conflict = {
  constraint: Rewards_Constraint;
  update_columns?: Array<Rewards_Update_Column>;
  where?: InputMaybe<Rewards_Bool_Exp>;
};

/** Ordering options when selecting data from "rewards". */
export type Rewards_Order_By = {
  artwork_url?: InputMaybe<Order_By>;
  campaign_rewards_aggregate?: InputMaybe<Campaign_Rewards_Aggregate_Order_By>;
  created_at?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  is_active?: InputMaybe<Order_By>;
  multiple_redemptions?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  organisation_id?: InputMaybe<Order_By>;
  redemption_criteria?: InputMaybe<Order_By>;
  reward_redemptions_aggregate?: InputMaybe<Reward_Redemptions_Aggregate_Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: rewards */
export type Rewards_Pk_Columns_Input = {
  id: Scalars["uuid"];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Rewards_Prepend_Input = {
  redemption_criteria?: InputMaybe<Scalars["jsonb"]>;
};

/** select columns of table "rewards" */
export enum Rewards_Select_Column {
  /** column name */
  ArtworkUrl = "artwork_url",
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  Description = "description",
  /** column name */
  Id = "id",
  /** column name */
  IsActive = "is_active",
  /** column name */
  MultipleRedemptions = "multiple_redemptions",
  /** column name */
  Name = "name",
  /** column name */
  OrganisationId = "organisation_id",
  /** column name */
  RedemptionCriteria = "redemption_criteria",
  /** column name */
  UpdatedAt = "updated_at",
}

/** input type for updating data in table "rewards" */
export type Rewards_Set_Input = {
  artwork_url?: InputMaybe<Scalars["String"]>;
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  description?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  is_active?: InputMaybe<Scalars["Boolean"]>;
  multiple_redemptions?: InputMaybe<Scalars["Boolean"]>;
  name?: InputMaybe<Scalars["String"]>;
  organisation_id?: InputMaybe<Scalars["uuid"]>;
  redemption_criteria?: InputMaybe<Scalars["jsonb"]>;
  updated_at?: InputMaybe<Scalars["timestamptz"]>;
};

/** Streaming cursor of the table "rewards" */
export type Rewards_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Rewards_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Rewards_Stream_Cursor_Value_Input = {
  artwork_url?: InputMaybe<Scalars["String"]>;
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  description?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  is_active?: InputMaybe<Scalars["Boolean"]>;
  multiple_redemptions?: InputMaybe<Scalars["Boolean"]>;
  name?: InputMaybe<Scalars["String"]>;
  organisation_id?: InputMaybe<Scalars["uuid"]>;
  redemption_criteria?: InputMaybe<Scalars["jsonb"]>;
  updated_at?: InputMaybe<Scalars["timestamptz"]>;
};

/** update columns of table "rewards" */
export enum Rewards_Update_Column {
  /** column name */
  ArtworkUrl = "artwork_url",
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  Description = "description",
  /** column name */
  Id = "id",
  /** column name */
  IsActive = "is_active",
  /** column name */
  MultipleRedemptions = "multiple_redemptions",
  /** column name */
  Name = "name",
  /** column name */
  OrganisationId = "organisation_id",
  /** column name */
  RedemptionCriteria = "redemption_criteria",
  /** column name */
  UpdatedAt = "updated_at",
}

export type Rewards_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Rewards_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Rewards_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Rewards_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Rewards_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Rewards_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Rewards_Set_Input>;
  /** filter the rows which have to be updated */
  where: Rewards_Bool_Exp;
};

/** columns and relationships of "rules" */
export type Rules = {
  __typename?: "rules";
  /** An array relationship */
  campaign_rules: Array<Campaign_Rules>;
  /** An aggregate relationship */
  campaign_rules_aggregate: Campaign_Rules_Aggregate;
  /** An array relationship */
  conditions: Array<Conditions>;
  /** An aggregate relationship */
  conditions_aggregate: Conditions_Aggregate;
  created_at: Scalars["timestamptz"];
  description?: Maybe<Scalars["String"]>;
  /** An array relationship */
  form_rules: Array<Form_Rules>;
  /** An aggregate relationship */
  form_rules_aggregate: Form_Rules_Aggregate;
  id: Scalars["uuid"];
  is_active: Scalars["Boolean"];
  metadata: Scalars["jsonb"];
  name: Scalars["String"];
  organisation_id?: Maybe<Scalars["uuid"]>;
  /** When to run the rule */
  run_on: Array<Scalars["String"]>;
  success_event?: Maybe<Scalars["jsonb"]>;
  /** An array relationship */
  task_rules: Array<Task_Rules>;
  /** An aggregate relationship */
  task_rules_aggregate: Task_Rules_Aggregate;
  /** An array relationship */
  territory_rules: Array<Territory_Rules>;
  /** An aggregate relationship */
  territory_rules_aggregate: Territory_Rules_Aggregate;
  updated_at: Scalars["timestamptz"];
};

/** columns and relationships of "rules" */
export type RulesCampaign_RulesArgs = {
  distinct_on?: InputMaybe<Array<Campaign_Rules_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Campaign_Rules_Order_By>>;
  where?: InputMaybe<Campaign_Rules_Bool_Exp>;
};

/** columns and relationships of "rules" */
export type RulesCampaign_Rules_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Campaign_Rules_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Campaign_Rules_Order_By>>;
  where?: InputMaybe<Campaign_Rules_Bool_Exp>;
};

/** columns and relationships of "rules" */
export type RulesConditionsArgs = {
  distinct_on?: InputMaybe<Array<Conditions_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Conditions_Order_By>>;
  where?: InputMaybe<Conditions_Bool_Exp>;
};

/** columns and relationships of "rules" */
export type RulesConditions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Conditions_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Conditions_Order_By>>;
  where?: InputMaybe<Conditions_Bool_Exp>;
};

/** columns and relationships of "rules" */
export type RulesForm_RulesArgs = {
  distinct_on?: InputMaybe<Array<Form_Rules_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Form_Rules_Order_By>>;
  where?: InputMaybe<Form_Rules_Bool_Exp>;
};

/** columns and relationships of "rules" */
export type RulesForm_Rules_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Form_Rules_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Form_Rules_Order_By>>;
  where?: InputMaybe<Form_Rules_Bool_Exp>;
};

/** columns and relationships of "rules" */
export type RulesMetadataArgs = {
  path?: InputMaybe<Scalars["String"]>;
};

/** columns and relationships of "rules" */
export type RulesSuccess_EventArgs = {
  path?: InputMaybe<Scalars["String"]>;
};

/** columns and relationships of "rules" */
export type RulesTask_RulesArgs = {
  distinct_on?: InputMaybe<Array<Task_Rules_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Task_Rules_Order_By>>;
  where?: InputMaybe<Task_Rules_Bool_Exp>;
};

/** columns and relationships of "rules" */
export type RulesTask_Rules_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Task_Rules_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Task_Rules_Order_By>>;
  where?: InputMaybe<Task_Rules_Bool_Exp>;
};

/** columns and relationships of "rules" */
export type RulesTerritory_RulesArgs = {
  distinct_on?: InputMaybe<Array<Territory_Rules_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Territory_Rules_Order_By>>;
  where?: InputMaybe<Territory_Rules_Bool_Exp>;
};

/** columns and relationships of "rules" */
export type RulesTerritory_Rules_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Territory_Rules_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Territory_Rules_Order_By>>;
  where?: InputMaybe<Territory_Rules_Bool_Exp>;
};

/** aggregated selection of "rules" */
export type Rules_Aggregate = {
  __typename?: "rules_aggregate";
  aggregate?: Maybe<Rules_Aggregate_Fields>;
  nodes: Array<Rules>;
};

/** aggregate fields of "rules" */
export type Rules_Aggregate_Fields = {
  __typename?: "rules_aggregate_fields";
  count: Scalars["Int"];
  max?: Maybe<Rules_Max_Fields>;
  min?: Maybe<Rules_Min_Fields>;
};

/** aggregate fields of "rules" */
export type Rules_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Rules_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Rules_Append_Input = {
  metadata?: InputMaybe<Scalars["jsonb"]>;
  success_event?: InputMaybe<Scalars["jsonb"]>;
};

/** Boolean expression to filter rows from the table "rules". All fields are combined with a logical 'AND'. */
export type Rules_Bool_Exp = {
  _and?: InputMaybe<Array<Rules_Bool_Exp>>;
  _not?: InputMaybe<Rules_Bool_Exp>;
  _or?: InputMaybe<Array<Rules_Bool_Exp>>;
  campaign_rules?: InputMaybe<Campaign_Rules_Bool_Exp>;
  campaign_rules_aggregate?: InputMaybe<Campaign_Rules_Aggregate_Bool_Exp>;
  conditions?: InputMaybe<Conditions_Bool_Exp>;
  conditions_aggregate?: InputMaybe<Conditions_Aggregate_Bool_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  form_rules?: InputMaybe<Form_Rules_Bool_Exp>;
  form_rules_aggregate?: InputMaybe<Form_Rules_Aggregate_Bool_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  is_active?: InputMaybe<Boolean_Comparison_Exp>;
  metadata?: InputMaybe<Jsonb_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  organisation_id?: InputMaybe<Uuid_Comparison_Exp>;
  run_on?: InputMaybe<String_Array_Comparison_Exp>;
  success_event?: InputMaybe<Jsonb_Comparison_Exp>;
  task_rules?: InputMaybe<Task_Rules_Bool_Exp>;
  task_rules_aggregate?: InputMaybe<Task_Rules_Aggregate_Bool_Exp>;
  territory_rules?: InputMaybe<Territory_Rules_Bool_Exp>;
  territory_rules_aggregate?: InputMaybe<Territory_Rules_Aggregate_Bool_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "rules" */
export enum Rules_Constraint {
  /** unique or primary key constraint on columns "id" */
  RulesPkey = "rules_pkey",
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Rules_Delete_At_Path_Input = {
  metadata?: InputMaybe<Array<Scalars["String"]>>;
  success_event?: InputMaybe<Array<Scalars["String"]>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Rules_Delete_Elem_Input = {
  metadata?: InputMaybe<Scalars["Int"]>;
  success_event?: InputMaybe<Scalars["Int"]>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Rules_Delete_Key_Input = {
  metadata?: InputMaybe<Scalars["String"]>;
  success_event?: InputMaybe<Scalars["String"]>;
};

/** input type for inserting data into table "rules" */
export type Rules_Insert_Input = {
  campaign_rules?: InputMaybe<Campaign_Rules_Arr_Rel_Insert_Input>;
  conditions?: InputMaybe<Conditions_Arr_Rel_Insert_Input>;
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  description?: InputMaybe<Scalars["String"]>;
  form_rules?: InputMaybe<Form_Rules_Arr_Rel_Insert_Input>;
  id?: InputMaybe<Scalars["uuid"]>;
  is_active?: InputMaybe<Scalars["Boolean"]>;
  metadata?: InputMaybe<Scalars["jsonb"]>;
  name?: InputMaybe<Scalars["String"]>;
  organisation_id?: InputMaybe<Scalars["uuid"]>;
  /** When to run the rule */
  run_on?: InputMaybe<Array<Scalars["String"]>>;
  success_event?: InputMaybe<Scalars["jsonb"]>;
  task_rules?: InputMaybe<Task_Rules_Arr_Rel_Insert_Input>;
  territory_rules?: InputMaybe<Territory_Rules_Arr_Rel_Insert_Input>;
  updated_at?: InputMaybe<Scalars["timestamptz"]>;
};

/** aggregate max on columns */
export type Rules_Max_Fields = {
  __typename?: "rules_max_fields";
  created_at?: Maybe<Scalars["timestamptz"]>;
  description?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["uuid"]>;
  name?: Maybe<Scalars["String"]>;
  organisation_id?: Maybe<Scalars["uuid"]>;
  /** When to run the rule */
  run_on?: Maybe<Array<Scalars["String"]>>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
};

/** aggregate min on columns */
export type Rules_Min_Fields = {
  __typename?: "rules_min_fields";
  created_at?: Maybe<Scalars["timestamptz"]>;
  description?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["uuid"]>;
  name?: Maybe<Scalars["String"]>;
  organisation_id?: Maybe<Scalars["uuid"]>;
  /** When to run the rule */
  run_on?: Maybe<Array<Scalars["String"]>>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
};

/** response of any mutation on the table "rules" */
export type Rules_Mutation_Response = {
  __typename?: "rules_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Rules>;
};

/** input type for inserting object relation for remote table "rules" */
export type Rules_Obj_Rel_Insert_Input = {
  data: Rules_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Rules_On_Conflict>;
};

/** on_conflict condition type for table "rules" */
export type Rules_On_Conflict = {
  constraint: Rules_Constraint;
  update_columns?: Array<Rules_Update_Column>;
  where?: InputMaybe<Rules_Bool_Exp>;
};

/** Ordering options when selecting data from "rules". */
export type Rules_Order_By = {
  campaign_rules_aggregate?: InputMaybe<Campaign_Rules_Aggregate_Order_By>;
  conditions_aggregate?: InputMaybe<Conditions_Aggregate_Order_By>;
  created_at?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  form_rules_aggregate?: InputMaybe<Form_Rules_Aggregate_Order_By>;
  id?: InputMaybe<Order_By>;
  is_active?: InputMaybe<Order_By>;
  metadata?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  organisation_id?: InputMaybe<Order_By>;
  run_on?: InputMaybe<Order_By>;
  success_event?: InputMaybe<Order_By>;
  task_rules_aggregate?: InputMaybe<Task_Rules_Aggregate_Order_By>;
  territory_rules_aggregate?: InputMaybe<Territory_Rules_Aggregate_Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: rules */
export type Rules_Pk_Columns_Input = {
  id: Scalars["uuid"];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Rules_Prepend_Input = {
  metadata?: InputMaybe<Scalars["jsonb"]>;
  success_event?: InputMaybe<Scalars["jsonb"]>;
};

/** select columns of table "rules" */
export enum Rules_Select_Column {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  Description = "description",
  /** column name */
  Id = "id",
  /** column name */
  IsActive = "is_active",
  /** column name */
  Metadata = "metadata",
  /** column name */
  Name = "name",
  /** column name */
  OrganisationId = "organisation_id",
  /** column name */
  RunOn = "run_on",
  /** column name */
  SuccessEvent = "success_event",
  /** column name */
  UpdatedAt = "updated_at",
}

/** input type for updating data in table "rules" */
export type Rules_Set_Input = {
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  description?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  is_active?: InputMaybe<Scalars["Boolean"]>;
  metadata?: InputMaybe<Scalars["jsonb"]>;
  name?: InputMaybe<Scalars["String"]>;
  organisation_id?: InputMaybe<Scalars["uuid"]>;
  /** When to run the rule */
  run_on?: InputMaybe<Array<Scalars["String"]>>;
  success_event?: InputMaybe<Scalars["jsonb"]>;
  updated_at?: InputMaybe<Scalars["timestamptz"]>;
};

/** Streaming cursor of the table "rules" */
export type Rules_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Rules_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Rules_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  description?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  is_active?: InputMaybe<Scalars["Boolean"]>;
  metadata?: InputMaybe<Scalars["jsonb"]>;
  name?: InputMaybe<Scalars["String"]>;
  organisation_id?: InputMaybe<Scalars["uuid"]>;
  /** When to run the rule */
  run_on?: InputMaybe<Array<Scalars["String"]>>;
  success_event?: InputMaybe<Scalars["jsonb"]>;
  updated_at?: InputMaybe<Scalars["timestamptz"]>;
};

/** update columns of table "rules" */
export enum Rules_Update_Column {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  Description = "description",
  /** column name */
  Id = "id",
  /** column name */
  IsActive = "is_active",
  /** column name */
  Metadata = "metadata",
  /** column name */
  Name = "name",
  /** column name */
  OrganisationId = "organisation_id",
  /** column name */
  RunOn = "run_on",
  /** column name */
  SuccessEvent = "success_event",
  /** column name */
  UpdatedAt = "updated_at",
}

export type Rules_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Rules_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Rules_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Rules_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Rules_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Rules_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Rules_Set_Input>;
  /** filter the rows which have to be updated */
  where: Rules_Bool_Exp;
};

/** columns and relationships of "statuses" */
export type Statuses = {
  __typename?: "statuses";
  /** An array relationship */
  campaign_contacts: Array<Campaign_Contacts>;
  /** An aggregate relationship */
  campaign_contacts_aggregate: Campaign_Contacts_Aggregate;
  /** An array relationship */
  campaign_rewards: Array<Campaign_Rewards>;
  /** An aggregate relationship */
  campaign_rewards_aggregate: Campaign_Rewards_Aggregate;
  /** An array relationship */
  case_statuses: Array<Case_Statuses>;
  /** An aggregate relationship */
  case_statuses_aggregate: Case_Statuses_Aggregate;
  /** An array relationship */
  channel_statuses: Array<Channel_Statuses>;
  /** An aggregate relationship */
  channel_statuses_aggregate: Channel_Statuses_Aggregate;
  /** An array relationship */
  channel_subscriptions: Array<Channel_Subscriptions>;
  /** An aggregate relationship */
  channel_subscriptions_aggregate: Channel_Subscriptions_Aggregate;
  /** An array relationship */
  contacts: Array<Contacts>;
  /** An aggregate relationship */
  contacts_aggregate: Contacts_Aggregate;
  created_at: Scalars["timestamptz"];
  description?: Maybe<Scalars["String"]>;
  id: Scalars["uuid"];
  /** An array relationship */
  message_statuses: Array<Message_Statuses>;
  /** An aggregate relationship */
  message_statuses_aggregate: Message_Statuses_Aggregate;
  name: Scalars["String"];
  /** An array relationship */
  party_relationship_statuses: Array<Party_Relationship_Statuses>;
  /** An aggregate relationship */
  party_relationship_statuses_aggregate: Party_Relationship_Statuses_Aggregate;
  /** An array relationship */
  party_statuses: Array<Party_Statuses>;
  /** An aggregate relationship */
  party_statuses_aggregate: Party_Statuses_Aggregate;
  /** An array relationship */
  task_statuses: Array<Task_Statuses>;
  /** An aggregate relationship */
  task_statuses_aggregate: Task_Statuses_Aggregate;
  /** An array relationship */
  territory_statuses: Array<Territory_Statuses>;
  /** An aggregate relationship */
  territory_statuses_aggregate: Territory_Statuses_Aggregate;
  updated_at: Scalars["timestamptz"];
  /** An array relationship */
  user_statuses: Array<User_Statuses>;
  /** An aggregate relationship */
  user_statuses_aggregate: User_Statuses_Aggregate;
  value: Scalars["String"];
};

/** columns and relationships of "statuses" */
export type StatusesCampaign_ContactsArgs = {
  distinct_on?: InputMaybe<Array<Campaign_Contacts_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Campaign_Contacts_Order_By>>;
  where?: InputMaybe<Campaign_Contacts_Bool_Exp>;
};

/** columns and relationships of "statuses" */
export type StatusesCampaign_Contacts_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Campaign_Contacts_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Campaign_Contacts_Order_By>>;
  where?: InputMaybe<Campaign_Contacts_Bool_Exp>;
};

/** columns and relationships of "statuses" */
export type StatusesCampaign_RewardsArgs = {
  distinct_on?: InputMaybe<Array<Campaign_Rewards_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Campaign_Rewards_Order_By>>;
  where?: InputMaybe<Campaign_Rewards_Bool_Exp>;
};

/** columns and relationships of "statuses" */
export type StatusesCampaign_Rewards_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Campaign_Rewards_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Campaign_Rewards_Order_By>>;
  where?: InputMaybe<Campaign_Rewards_Bool_Exp>;
};

/** columns and relationships of "statuses" */
export type StatusesCase_StatusesArgs = {
  distinct_on?: InputMaybe<Array<Case_Statuses_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Case_Statuses_Order_By>>;
  where?: InputMaybe<Case_Statuses_Bool_Exp>;
};

/** columns and relationships of "statuses" */
export type StatusesCase_Statuses_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Case_Statuses_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Case_Statuses_Order_By>>;
  where?: InputMaybe<Case_Statuses_Bool_Exp>;
};

/** columns and relationships of "statuses" */
export type StatusesChannel_StatusesArgs = {
  distinct_on?: InputMaybe<Array<Channel_Statuses_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Channel_Statuses_Order_By>>;
  where?: InputMaybe<Channel_Statuses_Bool_Exp>;
};

/** columns and relationships of "statuses" */
export type StatusesChannel_Statuses_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Channel_Statuses_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Channel_Statuses_Order_By>>;
  where?: InputMaybe<Channel_Statuses_Bool_Exp>;
};

/** columns and relationships of "statuses" */
export type StatusesChannel_SubscriptionsArgs = {
  distinct_on?: InputMaybe<Array<Channel_Subscriptions_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Channel_Subscriptions_Order_By>>;
  where?: InputMaybe<Channel_Subscriptions_Bool_Exp>;
};

/** columns and relationships of "statuses" */
export type StatusesChannel_Subscriptions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Channel_Subscriptions_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Channel_Subscriptions_Order_By>>;
  where?: InputMaybe<Channel_Subscriptions_Bool_Exp>;
};

/** columns and relationships of "statuses" */
export type StatusesContactsArgs = {
  distinct_on?: InputMaybe<Array<Contacts_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Contacts_Order_By>>;
  where?: InputMaybe<Contacts_Bool_Exp>;
};

/** columns and relationships of "statuses" */
export type StatusesContacts_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Contacts_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Contacts_Order_By>>;
  where?: InputMaybe<Contacts_Bool_Exp>;
};

/** columns and relationships of "statuses" */
export type StatusesMessage_StatusesArgs = {
  distinct_on?: InputMaybe<Array<Message_Statuses_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Message_Statuses_Order_By>>;
  where?: InputMaybe<Message_Statuses_Bool_Exp>;
};

/** columns and relationships of "statuses" */
export type StatusesMessage_Statuses_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Message_Statuses_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Message_Statuses_Order_By>>;
  where?: InputMaybe<Message_Statuses_Bool_Exp>;
};

/** columns and relationships of "statuses" */
export type StatusesParty_Relationship_StatusesArgs = {
  distinct_on?: InputMaybe<Array<Party_Relationship_Statuses_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Party_Relationship_Statuses_Order_By>>;
  where?: InputMaybe<Party_Relationship_Statuses_Bool_Exp>;
};

/** columns and relationships of "statuses" */
export type StatusesParty_Relationship_Statuses_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Party_Relationship_Statuses_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Party_Relationship_Statuses_Order_By>>;
  where?: InputMaybe<Party_Relationship_Statuses_Bool_Exp>;
};

/** columns and relationships of "statuses" */
export type StatusesParty_StatusesArgs = {
  distinct_on?: InputMaybe<Array<Party_Statuses_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Party_Statuses_Order_By>>;
  where?: InputMaybe<Party_Statuses_Bool_Exp>;
};

/** columns and relationships of "statuses" */
export type StatusesParty_Statuses_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Party_Statuses_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Party_Statuses_Order_By>>;
  where?: InputMaybe<Party_Statuses_Bool_Exp>;
};

/** columns and relationships of "statuses" */
export type StatusesTask_StatusesArgs = {
  distinct_on?: InputMaybe<Array<Task_Statuses_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Task_Statuses_Order_By>>;
  where?: InputMaybe<Task_Statuses_Bool_Exp>;
};

/** columns and relationships of "statuses" */
export type StatusesTask_Statuses_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Task_Statuses_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Task_Statuses_Order_By>>;
  where?: InputMaybe<Task_Statuses_Bool_Exp>;
};

/** columns and relationships of "statuses" */
export type StatusesTerritory_StatusesArgs = {
  distinct_on?: InputMaybe<Array<Territory_Statuses_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Territory_Statuses_Order_By>>;
  where?: InputMaybe<Territory_Statuses_Bool_Exp>;
};

/** columns and relationships of "statuses" */
export type StatusesTerritory_Statuses_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Territory_Statuses_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Territory_Statuses_Order_By>>;
  where?: InputMaybe<Territory_Statuses_Bool_Exp>;
};

/** columns and relationships of "statuses" */
export type StatusesUser_StatusesArgs = {
  distinct_on?: InputMaybe<Array<User_Statuses_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<User_Statuses_Order_By>>;
  where?: InputMaybe<User_Statuses_Bool_Exp>;
};

/** columns and relationships of "statuses" */
export type StatusesUser_Statuses_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Statuses_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<User_Statuses_Order_By>>;
  where?: InputMaybe<User_Statuses_Bool_Exp>;
};

/** aggregated selection of "statuses" */
export type Statuses_Aggregate = {
  __typename?: "statuses_aggregate";
  aggregate?: Maybe<Statuses_Aggregate_Fields>;
  nodes: Array<Statuses>;
};

/** aggregate fields of "statuses" */
export type Statuses_Aggregate_Fields = {
  __typename?: "statuses_aggregate_fields";
  count: Scalars["Int"];
  max?: Maybe<Statuses_Max_Fields>;
  min?: Maybe<Statuses_Min_Fields>;
};

/** aggregate fields of "statuses" */
export type Statuses_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Statuses_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** Boolean expression to filter rows from the table "statuses". All fields are combined with a logical 'AND'. */
export type Statuses_Bool_Exp = {
  _and?: InputMaybe<Array<Statuses_Bool_Exp>>;
  _not?: InputMaybe<Statuses_Bool_Exp>;
  _or?: InputMaybe<Array<Statuses_Bool_Exp>>;
  campaign_contacts?: InputMaybe<Campaign_Contacts_Bool_Exp>;
  campaign_contacts_aggregate?: InputMaybe<Campaign_Contacts_Aggregate_Bool_Exp>;
  campaign_rewards?: InputMaybe<Campaign_Rewards_Bool_Exp>;
  campaign_rewards_aggregate?: InputMaybe<Campaign_Rewards_Aggregate_Bool_Exp>;
  case_statuses?: InputMaybe<Case_Statuses_Bool_Exp>;
  case_statuses_aggregate?: InputMaybe<Case_Statuses_Aggregate_Bool_Exp>;
  channel_statuses?: InputMaybe<Channel_Statuses_Bool_Exp>;
  channel_statuses_aggregate?: InputMaybe<Channel_Statuses_Aggregate_Bool_Exp>;
  channel_subscriptions?: InputMaybe<Channel_Subscriptions_Bool_Exp>;
  channel_subscriptions_aggregate?: InputMaybe<Channel_Subscriptions_Aggregate_Bool_Exp>;
  contacts?: InputMaybe<Contacts_Bool_Exp>;
  contacts_aggregate?: InputMaybe<Contacts_Aggregate_Bool_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  message_statuses?: InputMaybe<Message_Statuses_Bool_Exp>;
  message_statuses_aggregate?: InputMaybe<Message_Statuses_Aggregate_Bool_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  party_relationship_statuses?: InputMaybe<Party_Relationship_Statuses_Bool_Exp>;
  party_relationship_statuses_aggregate?: InputMaybe<Party_Relationship_Statuses_Aggregate_Bool_Exp>;
  party_statuses?: InputMaybe<Party_Statuses_Bool_Exp>;
  party_statuses_aggregate?: InputMaybe<Party_Statuses_Aggregate_Bool_Exp>;
  task_statuses?: InputMaybe<Task_Statuses_Bool_Exp>;
  task_statuses_aggregate?: InputMaybe<Task_Statuses_Aggregate_Bool_Exp>;
  territory_statuses?: InputMaybe<Territory_Statuses_Bool_Exp>;
  territory_statuses_aggregate?: InputMaybe<Territory_Statuses_Aggregate_Bool_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user_statuses?: InputMaybe<User_Statuses_Bool_Exp>;
  user_statuses_aggregate?: InputMaybe<User_Statuses_Aggregate_Bool_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "statuses" */
export enum Statuses_Constraint {
  /** unique or primary key constraint on columns "id" */
  StatusesPkey = "statuses_pkey",
  /** unique or primary key constraint on columns "value" */
  StatusesValueUnique = "statuses_value_unique",
}

/** input type for inserting data into table "statuses" */
export type Statuses_Insert_Input = {
  campaign_contacts?: InputMaybe<Campaign_Contacts_Arr_Rel_Insert_Input>;
  campaign_rewards?: InputMaybe<Campaign_Rewards_Arr_Rel_Insert_Input>;
  case_statuses?: InputMaybe<Case_Statuses_Arr_Rel_Insert_Input>;
  channel_statuses?: InputMaybe<Channel_Statuses_Arr_Rel_Insert_Input>;
  channel_subscriptions?: InputMaybe<Channel_Subscriptions_Arr_Rel_Insert_Input>;
  contacts?: InputMaybe<Contacts_Arr_Rel_Insert_Input>;
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  description?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  message_statuses?: InputMaybe<Message_Statuses_Arr_Rel_Insert_Input>;
  name?: InputMaybe<Scalars["String"]>;
  party_relationship_statuses?: InputMaybe<Party_Relationship_Statuses_Arr_Rel_Insert_Input>;
  party_statuses?: InputMaybe<Party_Statuses_Arr_Rel_Insert_Input>;
  task_statuses?: InputMaybe<Task_Statuses_Arr_Rel_Insert_Input>;
  territory_statuses?: InputMaybe<Territory_Statuses_Arr_Rel_Insert_Input>;
  updated_at?: InputMaybe<Scalars["timestamptz"]>;
  user_statuses?: InputMaybe<User_Statuses_Arr_Rel_Insert_Input>;
  value?: InputMaybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Statuses_Max_Fields = {
  __typename?: "statuses_max_fields";
  created_at?: Maybe<Scalars["timestamptz"]>;
  description?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["uuid"]>;
  name?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
  value?: Maybe<Scalars["String"]>;
};

/** aggregate min on columns */
export type Statuses_Min_Fields = {
  __typename?: "statuses_min_fields";
  created_at?: Maybe<Scalars["timestamptz"]>;
  description?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["uuid"]>;
  name?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
  value?: Maybe<Scalars["String"]>;
};

/** response of any mutation on the table "statuses" */
export type Statuses_Mutation_Response = {
  __typename?: "statuses_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Statuses>;
};

/** input type for inserting object relation for remote table "statuses" */
export type Statuses_Obj_Rel_Insert_Input = {
  data: Statuses_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Statuses_On_Conflict>;
};

/** on_conflict condition type for table "statuses" */
export type Statuses_On_Conflict = {
  constraint: Statuses_Constraint;
  update_columns?: Array<Statuses_Update_Column>;
  where?: InputMaybe<Statuses_Bool_Exp>;
};

/** Ordering options when selecting data from "statuses". */
export type Statuses_Order_By = {
  campaign_contacts_aggregate?: InputMaybe<Campaign_Contacts_Aggregate_Order_By>;
  campaign_rewards_aggregate?: InputMaybe<Campaign_Rewards_Aggregate_Order_By>;
  case_statuses_aggregate?: InputMaybe<Case_Statuses_Aggregate_Order_By>;
  channel_statuses_aggregate?: InputMaybe<Channel_Statuses_Aggregate_Order_By>;
  channel_subscriptions_aggregate?: InputMaybe<Channel_Subscriptions_Aggregate_Order_By>;
  contacts_aggregate?: InputMaybe<Contacts_Aggregate_Order_By>;
  created_at?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  message_statuses_aggregate?: InputMaybe<Message_Statuses_Aggregate_Order_By>;
  name?: InputMaybe<Order_By>;
  party_relationship_statuses_aggregate?: InputMaybe<Party_Relationship_Statuses_Aggregate_Order_By>;
  party_statuses_aggregate?: InputMaybe<Party_Statuses_Aggregate_Order_By>;
  task_statuses_aggregate?: InputMaybe<Task_Statuses_Aggregate_Order_By>;
  territory_statuses_aggregate?: InputMaybe<Territory_Statuses_Aggregate_Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_statuses_aggregate?: InputMaybe<User_Statuses_Aggregate_Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: statuses */
export type Statuses_Pk_Columns_Input = {
  id: Scalars["uuid"];
};

/** select columns of table "statuses" */
export enum Statuses_Select_Column {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  Description = "description",
  /** column name */
  Id = "id",
  /** column name */
  Name = "name",
  /** column name */
  UpdatedAt = "updated_at",
  /** column name */
  Value = "value",
}

/** input type for updating data in table "statuses" */
export type Statuses_Set_Input = {
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  description?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  name?: InputMaybe<Scalars["String"]>;
  updated_at?: InputMaybe<Scalars["timestamptz"]>;
  value?: InputMaybe<Scalars["String"]>;
};

/** Streaming cursor of the table "statuses" */
export type Statuses_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Statuses_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Statuses_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  description?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  name?: InputMaybe<Scalars["String"]>;
  updated_at?: InputMaybe<Scalars["timestamptz"]>;
  value?: InputMaybe<Scalars["String"]>;
};

/** update columns of table "statuses" */
export enum Statuses_Update_Column {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  Description = "description",
  /** column name */
  Id = "id",
  /** column name */
  Name = "name",
  /** column name */
  UpdatedAt = "updated_at",
  /** column name */
  Value = "value",
}

export type Statuses_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Statuses_Set_Input>;
  /** filter the rows which have to be updated */
  where: Statuses_Bool_Exp;
};

/** columns and relationships of "subscription_cases" */
export type Subscription_Cases = {
  __typename?: "subscription_cases";
  /** An object relationship */
  case: Cases;
  case_id: Scalars["uuid"];
  created_at?: Maybe<Scalars["timestamptz"]>;
  id: Scalars["uuid"];
  /** An object relationship */
  subscription: Subscriptions;
  subscription_id: Scalars["uuid"];
};

/** aggregated selection of "subscription_cases" */
export type Subscription_Cases_Aggregate = {
  __typename?: "subscription_cases_aggregate";
  aggregate?: Maybe<Subscription_Cases_Aggregate_Fields>;
  nodes: Array<Subscription_Cases>;
};

export type Subscription_Cases_Aggregate_Bool_Exp = {
  count?: InputMaybe<Subscription_Cases_Aggregate_Bool_Exp_Count>;
};

export type Subscription_Cases_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Subscription_Cases_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<Subscription_Cases_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "subscription_cases" */
export type Subscription_Cases_Aggregate_Fields = {
  __typename?: "subscription_cases_aggregate_fields";
  count: Scalars["Int"];
  max?: Maybe<Subscription_Cases_Max_Fields>;
  min?: Maybe<Subscription_Cases_Min_Fields>;
};

/** aggregate fields of "subscription_cases" */
export type Subscription_Cases_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Subscription_Cases_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "subscription_cases" */
export type Subscription_Cases_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Subscription_Cases_Max_Order_By>;
  min?: InputMaybe<Subscription_Cases_Min_Order_By>;
};

/** input type for inserting array relation for remote table "subscription_cases" */
export type Subscription_Cases_Arr_Rel_Insert_Input = {
  data: Array<Subscription_Cases_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Subscription_Cases_On_Conflict>;
};

/** Boolean expression to filter rows from the table "subscription_cases". All fields are combined with a logical 'AND'. */
export type Subscription_Cases_Bool_Exp = {
  _and?: InputMaybe<Array<Subscription_Cases_Bool_Exp>>;
  _not?: InputMaybe<Subscription_Cases_Bool_Exp>;
  _or?: InputMaybe<Array<Subscription_Cases_Bool_Exp>>;
  case?: InputMaybe<Cases_Bool_Exp>;
  case_id?: InputMaybe<Uuid_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  subscription?: InputMaybe<Subscriptions_Bool_Exp>;
  subscription_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "subscription_cases" */
export enum Subscription_Cases_Constraint {
  /** unique or primary key constraint on columns "id" */
  SubscriptionCasesPkey = "subscription_cases_pkey",
}

/** input type for inserting data into table "subscription_cases" */
export type Subscription_Cases_Insert_Input = {
  case?: InputMaybe<Cases_Obj_Rel_Insert_Input>;
  case_id?: InputMaybe<Scalars["uuid"]>;
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  subscription?: InputMaybe<Subscriptions_Obj_Rel_Insert_Input>;
  subscription_id?: InputMaybe<Scalars["uuid"]>;
};

/** aggregate max on columns */
export type Subscription_Cases_Max_Fields = {
  __typename?: "subscription_cases_max_fields";
  case_id?: Maybe<Scalars["uuid"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["uuid"]>;
  subscription_id?: Maybe<Scalars["uuid"]>;
};

/** order by max() on columns of table "subscription_cases" */
export type Subscription_Cases_Max_Order_By = {
  case_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  subscription_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Subscription_Cases_Min_Fields = {
  __typename?: "subscription_cases_min_fields";
  case_id?: Maybe<Scalars["uuid"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["uuid"]>;
  subscription_id?: Maybe<Scalars["uuid"]>;
};

/** order by min() on columns of table "subscription_cases" */
export type Subscription_Cases_Min_Order_By = {
  case_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  subscription_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "subscription_cases" */
export type Subscription_Cases_Mutation_Response = {
  __typename?: "subscription_cases_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Subscription_Cases>;
};

/** on_conflict condition type for table "subscription_cases" */
export type Subscription_Cases_On_Conflict = {
  constraint: Subscription_Cases_Constraint;
  update_columns?: Array<Subscription_Cases_Update_Column>;
  where?: InputMaybe<Subscription_Cases_Bool_Exp>;
};

/** Ordering options when selecting data from "subscription_cases". */
export type Subscription_Cases_Order_By = {
  case?: InputMaybe<Cases_Order_By>;
  case_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  subscription?: InputMaybe<Subscriptions_Order_By>;
  subscription_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: subscription_cases */
export type Subscription_Cases_Pk_Columns_Input = {
  id: Scalars["uuid"];
};

/** select columns of table "subscription_cases" */
export enum Subscription_Cases_Select_Column {
  /** column name */
  CaseId = "case_id",
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  Id = "id",
  /** column name */
  SubscriptionId = "subscription_id",
}

/** input type for updating data in table "subscription_cases" */
export type Subscription_Cases_Set_Input = {
  case_id?: InputMaybe<Scalars["uuid"]>;
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  subscription_id?: InputMaybe<Scalars["uuid"]>;
};

/** Streaming cursor of the table "subscription_cases" */
export type Subscription_Cases_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Subscription_Cases_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Subscription_Cases_Stream_Cursor_Value_Input = {
  case_id?: InputMaybe<Scalars["uuid"]>;
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  subscription_id?: InputMaybe<Scalars["uuid"]>;
};

/** update columns of table "subscription_cases" */
export enum Subscription_Cases_Update_Column {
  /** column name */
  CaseId = "case_id",
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  Id = "id",
  /** column name */
  SubscriptionId = "subscription_id",
}

export type Subscription_Cases_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Subscription_Cases_Set_Input>;
  /** filter the rows which have to be updated */
  where: Subscription_Cases_Bool_Exp;
};

/** columns and relationships of "subscription_details" */
export type Subscription_Details = {
  __typename?: "subscription_details";
  created_at: Scalars["timestamptz"];
  created_by: Scalars["uuid"];
  /** An object relationship */
  form: Forms;
  /** An object relationship */
  form_field: Form_Fields;
  form_field_id: Scalars["uuid"];
  form_id: Scalars["uuid"];
  id: Scalars["uuid"];
  /** An object relationship */
  party: Parties;
  /** An object relationship */
  partyByUpdatedBy: Parties;
  /** An object relationship */
  subscription: Subscriptions;
  subscription_id: Scalars["uuid"];
  updated_at: Scalars["timestamptz"];
  updated_by: Scalars["uuid"];
  value: Scalars["String"];
};

/** aggregated selection of "subscription_details" */
export type Subscription_Details_Aggregate = {
  __typename?: "subscription_details_aggregate";
  aggregate?: Maybe<Subscription_Details_Aggregate_Fields>;
  nodes: Array<Subscription_Details>;
};

export type Subscription_Details_Aggregate_Bool_Exp = {
  count?: InputMaybe<Subscription_Details_Aggregate_Bool_Exp_Count>;
};

export type Subscription_Details_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Subscription_Details_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<Subscription_Details_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "subscription_details" */
export type Subscription_Details_Aggregate_Fields = {
  __typename?: "subscription_details_aggregate_fields";
  count: Scalars["Int"];
  max?: Maybe<Subscription_Details_Max_Fields>;
  min?: Maybe<Subscription_Details_Min_Fields>;
};

/** aggregate fields of "subscription_details" */
export type Subscription_Details_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Subscription_Details_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "subscription_details" */
export type Subscription_Details_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Subscription_Details_Max_Order_By>;
  min?: InputMaybe<Subscription_Details_Min_Order_By>;
};

/** input type for inserting array relation for remote table "subscription_details" */
export type Subscription_Details_Arr_Rel_Insert_Input = {
  data: Array<Subscription_Details_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Subscription_Details_On_Conflict>;
};

/** Boolean expression to filter rows from the table "subscription_details". All fields are combined with a logical 'AND'. */
export type Subscription_Details_Bool_Exp = {
  _and?: InputMaybe<Array<Subscription_Details_Bool_Exp>>;
  _not?: InputMaybe<Subscription_Details_Bool_Exp>;
  _or?: InputMaybe<Array<Subscription_Details_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  created_by?: InputMaybe<Uuid_Comparison_Exp>;
  form?: InputMaybe<Forms_Bool_Exp>;
  form_field?: InputMaybe<Form_Fields_Bool_Exp>;
  form_field_id?: InputMaybe<Uuid_Comparison_Exp>;
  form_id?: InputMaybe<Uuid_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  party?: InputMaybe<Parties_Bool_Exp>;
  partyByUpdatedBy?: InputMaybe<Parties_Bool_Exp>;
  subscription?: InputMaybe<Subscriptions_Bool_Exp>;
  subscription_id?: InputMaybe<Uuid_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  updated_by?: InputMaybe<Uuid_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "subscription_details" */
export enum Subscription_Details_Constraint {
  /** unique or primary key constraint on columns "id" */
  SubscriptionDetailsPkey = "subscription_details_pkey",
}

/** input type for inserting data into table "subscription_details" */
export type Subscription_Details_Insert_Input = {
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  created_by?: InputMaybe<Scalars["uuid"]>;
  form?: InputMaybe<Forms_Obj_Rel_Insert_Input>;
  form_field?: InputMaybe<Form_Fields_Obj_Rel_Insert_Input>;
  form_field_id?: InputMaybe<Scalars["uuid"]>;
  form_id?: InputMaybe<Scalars["uuid"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  party?: InputMaybe<Parties_Obj_Rel_Insert_Input>;
  partyByUpdatedBy?: InputMaybe<Parties_Obj_Rel_Insert_Input>;
  subscription?: InputMaybe<Subscriptions_Obj_Rel_Insert_Input>;
  subscription_id?: InputMaybe<Scalars["uuid"]>;
  updated_at?: InputMaybe<Scalars["timestamptz"]>;
  updated_by?: InputMaybe<Scalars["uuid"]>;
  value?: InputMaybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Subscription_Details_Max_Fields = {
  __typename?: "subscription_details_max_fields";
  created_at?: Maybe<Scalars["timestamptz"]>;
  created_by?: Maybe<Scalars["uuid"]>;
  form_field_id?: Maybe<Scalars["uuid"]>;
  form_id?: Maybe<Scalars["uuid"]>;
  id?: Maybe<Scalars["uuid"]>;
  subscription_id?: Maybe<Scalars["uuid"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
  updated_by?: Maybe<Scalars["uuid"]>;
  value?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "subscription_details" */
export type Subscription_Details_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  form_field_id?: InputMaybe<Order_By>;
  form_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  subscription_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Subscription_Details_Min_Fields = {
  __typename?: "subscription_details_min_fields";
  created_at?: Maybe<Scalars["timestamptz"]>;
  created_by?: Maybe<Scalars["uuid"]>;
  form_field_id?: Maybe<Scalars["uuid"]>;
  form_id?: Maybe<Scalars["uuid"]>;
  id?: Maybe<Scalars["uuid"]>;
  subscription_id?: Maybe<Scalars["uuid"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
  updated_by?: Maybe<Scalars["uuid"]>;
  value?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "subscription_details" */
export type Subscription_Details_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  form_field_id?: InputMaybe<Order_By>;
  form_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  subscription_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "subscription_details" */
export type Subscription_Details_Mutation_Response = {
  __typename?: "subscription_details_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Subscription_Details>;
};

/** on_conflict condition type for table "subscription_details" */
export type Subscription_Details_On_Conflict = {
  constraint: Subscription_Details_Constraint;
  update_columns?: Array<Subscription_Details_Update_Column>;
  where?: InputMaybe<Subscription_Details_Bool_Exp>;
};

/** Ordering options when selecting data from "subscription_details". */
export type Subscription_Details_Order_By = {
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  form?: InputMaybe<Forms_Order_By>;
  form_field?: InputMaybe<Form_Fields_Order_By>;
  form_field_id?: InputMaybe<Order_By>;
  form_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  party?: InputMaybe<Parties_Order_By>;
  partyByUpdatedBy?: InputMaybe<Parties_Order_By>;
  subscription?: InputMaybe<Subscriptions_Order_By>;
  subscription_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: subscription_details */
export type Subscription_Details_Pk_Columns_Input = {
  id: Scalars["uuid"];
};

/** select columns of table "subscription_details" */
export enum Subscription_Details_Select_Column {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  CreatedBy = "created_by",
  /** column name */
  FormFieldId = "form_field_id",
  /** column name */
  FormId = "form_id",
  /** column name */
  Id = "id",
  /** column name */
  SubscriptionId = "subscription_id",
  /** column name */
  UpdatedAt = "updated_at",
  /** column name */
  UpdatedBy = "updated_by",
  /** column name */
  Value = "value",
}

/** input type for updating data in table "subscription_details" */
export type Subscription_Details_Set_Input = {
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  created_by?: InputMaybe<Scalars["uuid"]>;
  form_field_id?: InputMaybe<Scalars["uuid"]>;
  form_id?: InputMaybe<Scalars["uuid"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  subscription_id?: InputMaybe<Scalars["uuid"]>;
  updated_at?: InputMaybe<Scalars["timestamptz"]>;
  updated_by?: InputMaybe<Scalars["uuid"]>;
  value?: InputMaybe<Scalars["String"]>;
};

/** Streaming cursor of the table "subscription_details" */
export type Subscription_Details_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Subscription_Details_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Subscription_Details_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  created_by?: InputMaybe<Scalars["uuid"]>;
  form_field_id?: InputMaybe<Scalars["uuid"]>;
  form_id?: InputMaybe<Scalars["uuid"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  subscription_id?: InputMaybe<Scalars["uuid"]>;
  updated_at?: InputMaybe<Scalars["timestamptz"]>;
  updated_by?: InputMaybe<Scalars["uuid"]>;
  value?: InputMaybe<Scalars["String"]>;
};

/** update columns of table "subscription_details" */
export enum Subscription_Details_Update_Column {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  CreatedBy = "created_by",
  /** column name */
  FormFieldId = "form_field_id",
  /** column name */
  FormId = "form_id",
  /** column name */
  Id = "id",
  /** column name */
  SubscriptionId = "subscription_id",
  /** column name */
  UpdatedAt = "updated_at",
  /** column name */
  UpdatedBy = "updated_by",
  /** column name */
  Value = "value",
}

export type Subscription_Details_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Subscription_Details_Set_Input>;
  /** filter the rows which have to be updated */
  where: Subscription_Details_Bool_Exp;
};

/** columns and relationships of "subscription_documents" */
export type Subscription_Documents = {
  __typename?: "subscription_documents";
  created_at?: Maybe<Scalars["timestamptz"]>;
  /** An object relationship */
  document: Documents;
  document_id: Scalars["uuid"];
  id: Scalars["uuid"];
  /** An object relationship */
  subscription: Subscriptions;
  subscription_id: Scalars["uuid"];
};

/** aggregated selection of "subscription_documents" */
export type Subscription_Documents_Aggregate = {
  __typename?: "subscription_documents_aggregate";
  aggregate?: Maybe<Subscription_Documents_Aggregate_Fields>;
  nodes: Array<Subscription_Documents>;
};

export type Subscription_Documents_Aggregate_Bool_Exp = {
  count?: InputMaybe<Subscription_Documents_Aggregate_Bool_Exp_Count>;
};

export type Subscription_Documents_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Subscription_Documents_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<Subscription_Documents_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "subscription_documents" */
export type Subscription_Documents_Aggregate_Fields = {
  __typename?: "subscription_documents_aggregate_fields";
  count: Scalars["Int"];
  max?: Maybe<Subscription_Documents_Max_Fields>;
  min?: Maybe<Subscription_Documents_Min_Fields>;
};

/** aggregate fields of "subscription_documents" */
export type Subscription_Documents_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Subscription_Documents_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "subscription_documents" */
export type Subscription_Documents_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Subscription_Documents_Max_Order_By>;
  min?: InputMaybe<Subscription_Documents_Min_Order_By>;
};

/** input type for inserting array relation for remote table "subscription_documents" */
export type Subscription_Documents_Arr_Rel_Insert_Input = {
  data: Array<Subscription_Documents_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Subscription_Documents_On_Conflict>;
};

/** Boolean expression to filter rows from the table "subscription_documents". All fields are combined with a logical 'AND'. */
export type Subscription_Documents_Bool_Exp = {
  _and?: InputMaybe<Array<Subscription_Documents_Bool_Exp>>;
  _not?: InputMaybe<Subscription_Documents_Bool_Exp>;
  _or?: InputMaybe<Array<Subscription_Documents_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  document?: InputMaybe<Documents_Bool_Exp>;
  document_id?: InputMaybe<Uuid_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  subscription?: InputMaybe<Subscriptions_Bool_Exp>;
  subscription_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "subscription_documents" */
export enum Subscription_Documents_Constraint {
  /** unique or primary key constraint on columns "id" */
  SubscriptionDocumentsPkey = "subscription_documents_pkey",
  /** unique or primary key constraint on columns "subscription_id", "document_id" */
  UniqueSubscriptionDocuments = "unique_subscription_documents",
}

/** input type for inserting data into table "subscription_documents" */
export type Subscription_Documents_Insert_Input = {
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  document?: InputMaybe<Documents_Obj_Rel_Insert_Input>;
  document_id?: InputMaybe<Scalars["uuid"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  subscription?: InputMaybe<Subscriptions_Obj_Rel_Insert_Input>;
  subscription_id?: InputMaybe<Scalars["uuid"]>;
};

/** aggregate max on columns */
export type Subscription_Documents_Max_Fields = {
  __typename?: "subscription_documents_max_fields";
  created_at?: Maybe<Scalars["timestamptz"]>;
  document_id?: Maybe<Scalars["uuid"]>;
  id?: Maybe<Scalars["uuid"]>;
  subscription_id?: Maybe<Scalars["uuid"]>;
};

/** order by max() on columns of table "subscription_documents" */
export type Subscription_Documents_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  document_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  subscription_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Subscription_Documents_Min_Fields = {
  __typename?: "subscription_documents_min_fields";
  created_at?: Maybe<Scalars["timestamptz"]>;
  document_id?: Maybe<Scalars["uuid"]>;
  id?: Maybe<Scalars["uuid"]>;
  subscription_id?: Maybe<Scalars["uuid"]>;
};

/** order by min() on columns of table "subscription_documents" */
export type Subscription_Documents_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  document_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  subscription_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "subscription_documents" */
export type Subscription_Documents_Mutation_Response = {
  __typename?: "subscription_documents_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Subscription_Documents>;
};

/** on_conflict condition type for table "subscription_documents" */
export type Subscription_Documents_On_Conflict = {
  constraint: Subscription_Documents_Constraint;
  update_columns?: Array<Subscription_Documents_Update_Column>;
  where?: InputMaybe<Subscription_Documents_Bool_Exp>;
};

/** Ordering options when selecting data from "subscription_documents". */
export type Subscription_Documents_Order_By = {
  created_at?: InputMaybe<Order_By>;
  document?: InputMaybe<Documents_Order_By>;
  document_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  subscription?: InputMaybe<Subscriptions_Order_By>;
  subscription_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: subscription_documents */
export type Subscription_Documents_Pk_Columns_Input = {
  id: Scalars["uuid"];
};

/** select columns of table "subscription_documents" */
export enum Subscription_Documents_Select_Column {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  DocumentId = "document_id",
  /** column name */
  Id = "id",
  /** column name */
  SubscriptionId = "subscription_id",
}

/** input type for updating data in table "subscription_documents" */
export type Subscription_Documents_Set_Input = {
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  document_id?: InputMaybe<Scalars["uuid"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  subscription_id?: InputMaybe<Scalars["uuid"]>;
};

/** Streaming cursor of the table "subscription_documents" */
export type Subscription_Documents_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Subscription_Documents_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Subscription_Documents_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  document_id?: InputMaybe<Scalars["uuid"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  subscription_id?: InputMaybe<Scalars["uuid"]>;
};

/** update columns of table "subscription_documents" */
export enum Subscription_Documents_Update_Column {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  DocumentId = "document_id",
  /** column name */
  Id = "id",
  /** column name */
  SubscriptionId = "subscription_id",
}

export type Subscription_Documents_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Subscription_Documents_Set_Input>;
  /** filter the rows which have to be updated */
  where: Subscription_Documents_Bool_Exp;
};

/** columns and relationships of "subscription_flags" */
export type Subscription_Flags = {
  __typename?: "subscription_flags";
  created_at?: Maybe<Scalars["timestamptz"]>;
  /** An object relationship */
  flag: Flags;
  flag_id: Scalars["uuid"];
  id: Scalars["uuid"];
  /** An object relationship */
  subscription: Subscriptions;
  subscription_id: Scalars["uuid"];
};

/** aggregated selection of "subscription_flags" */
export type Subscription_Flags_Aggregate = {
  __typename?: "subscription_flags_aggregate";
  aggregate?: Maybe<Subscription_Flags_Aggregate_Fields>;
  nodes: Array<Subscription_Flags>;
};

export type Subscription_Flags_Aggregate_Bool_Exp = {
  count?: InputMaybe<Subscription_Flags_Aggregate_Bool_Exp_Count>;
};

export type Subscription_Flags_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Subscription_Flags_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<Subscription_Flags_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "subscription_flags" */
export type Subscription_Flags_Aggregate_Fields = {
  __typename?: "subscription_flags_aggregate_fields";
  count: Scalars["Int"];
  max?: Maybe<Subscription_Flags_Max_Fields>;
  min?: Maybe<Subscription_Flags_Min_Fields>;
};

/** aggregate fields of "subscription_flags" */
export type Subscription_Flags_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Subscription_Flags_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "subscription_flags" */
export type Subscription_Flags_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Subscription_Flags_Max_Order_By>;
  min?: InputMaybe<Subscription_Flags_Min_Order_By>;
};

/** input type for inserting array relation for remote table "subscription_flags" */
export type Subscription_Flags_Arr_Rel_Insert_Input = {
  data: Array<Subscription_Flags_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Subscription_Flags_On_Conflict>;
};

/** Boolean expression to filter rows from the table "subscription_flags". All fields are combined with a logical 'AND'. */
export type Subscription_Flags_Bool_Exp = {
  _and?: InputMaybe<Array<Subscription_Flags_Bool_Exp>>;
  _not?: InputMaybe<Subscription_Flags_Bool_Exp>;
  _or?: InputMaybe<Array<Subscription_Flags_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  flag?: InputMaybe<Flags_Bool_Exp>;
  flag_id?: InputMaybe<Uuid_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  subscription?: InputMaybe<Subscriptions_Bool_Exp>;
  subscription_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "subscription_flags" */
export enum Subscription_Flags_Constraint {
  /** unique or primary key constraint on columns "id" */
  SubscriptionFlagsPkey = "subscription_flags_pkey",
}

/** input type for inserting data into table "subscription_flags" */
export type Subscription_Flags_Insert_Input = {
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  flag?: InputMaybe<Flags_Obj_Rel_Insert_Input>;
  flag_id?: InputMaybe<Scalars["uuid"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  subscription?: InputMaybe<Subscriptions_Obj_Rel_Insert_Input>;
  subscription_id?: InputMaybe<Scalars["uuid"]>;
};

/** aggregate max on columns */
export type Subscription_Flags_Max_Fields = {
  __typename?: "subscription_flags_max_fields";
  created_at?: Maybe<Scalars["timestamptz"]>;
  flag_id?: Maybe<Scalars["uuid"]>;
  id?: Maybe<Scalars["uuid"]>;
  subscription_id?: Maybe<Scalars["uuid"]>;
};

/** order by max() on columns of table "subscription_flags" */
export type Subscription_Flags_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  flag_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  subscription_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Subscription_Flags_Min_Fields = {
  __typename?: "subscription_flags_min_fields";
  created_at?: Maybe<Scalars["timestamptz"]>;
  flag_id?: Maybe<Scalars["uuid"]>;
  id?: Maybe<Scalars["uuid"]>;
  subscription_id?: Maybe<Scalars["uuid"]>;
};

/** order by min() on columns of table "subscription_flags" */
export type Subscription_Flags_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  flag_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  subscription_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "subscription_flags" */
export type Subscription_Flags_Mutation_Response = {
  __typename?: "subscription_flags_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Subscription_Flags>;
};

/** on_conflict condition type for table "subscription_flags" */
export type Subscription_Flags_On_Conflict = {
  constraint: Subscription_Flags_Constraint;
  update_columns?: Array<Subscription_Flags_Update_Column>;
  where?: InputMaybe<Subscription_Flags_Bool_Exp>;
};

/** Ordering options when selecting data from "subscription_flags". */
export type Subscription_Flags_Order_By = {
  created_at?: InputMaybe<Order_By>;
  flag?: InputMaybe<Flags_Order_By>;
  flag_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  subscription?: InputMaybe<Subscriptions_Order_By>;
  subscription_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: subscription_flags */
export type Subscription_Flags_Pk_Columns_Input = {
  id: Scalars["uuid"];
};

/** select columns of table "subscription_flags" */
export enum Subscription_Flags_Select_Column {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  FlagId = "flag_id",
  /** column name */
  Id = "id",
  /** column name */
  SubscriptionId = "subscription_id",
}

/** input type for updating data in table "subscription_flags" */
export type Subscription_Flags_Set_Input = {
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  flag_id?: InputMaybe<Scalars["uuid"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  subscription_id?: InputMaybe<Scalars["uuid"]>;
};

/** Streaming cursor of the table "subscription_flags" */
export type Subscription_Flags_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Subscription_Flags_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Subscription_Flags_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  flag_id?: InputMaybe<Scalars["uuid"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  subscription_id?: InputMaybe<Scalars["uuid"]>;
};

/** update columns of table "subscription_flags" */
export enum Subscription_Flags_Update_Column {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  FlagId = "flag_id",
  /** column name */
  Id = "id",
  /** column name */
  SubscriptionId = "subscription_id",
}

export type Subscription_Flags_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Subscription_Flags_Set_Input>;
  /** filter the rows which have to be updated */
  where: Subscription_Flags_Bool_Exp;
};

/** columns and relationships of "subscription_interactions" */
export type Subscription_Interactions = {
  __typename?: "subscription_interactions";
  created_at?: Maybe<Scalars["timestamptz"]>;
  id: Scalars["uuid"];
  /** An object relationship */
  interaction: Interactions;
  interaction_id: Scalars["uuid"];
  /** An object relationship */
  subscription: Subscriptions;
  subscription_id: Scalars["uuid"];
};

/** aggregated selection of "subscription_interactions" */
export type Subscription_Interactions_Aggregate = {
  __typename?: "subscription_interactions_aggregate";
  aggregate?: Maybe<Subscription_Interactions_Aggregate_Fields>;
  nodes: Array<Subscription_Interactions>;
};

export type Subscription_Interactions_Aggregate_Bool_Exp = {
  count?: InputMaybe<Subscription_Interactions_Aggregate_Bool_Exp_Count>;
};

export type Subscription_Interactions_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Subscription_Interactions_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<Subscription_Interactions_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "subscription_interactions" */
export type Subscription_Interactions_Aggregate_Fields = {
  __typename?: "subscription_interactions_aggregate_fields";
  count: Scalars["Int"];
  max?: Maybe<Subscription_Interactions_Max_Fields>;
  min?: Maybe<Subscription_Interactions_Min_Fields>;
};

/** aggregate fields of "subscription_interactions" */
export type Subscription_Interactions_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Subscription_Interactions_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "subscription_interactions" */
export type Subscription_Interactions_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Subscription_Interactions_Max_Order_By>;
  min?: InputMaybe<Subscription_Interactions_Min_Order_By>;
};

/** input type for inserting array relation for remote table "subscription_interactions" */
export type Subscription_Interactions_Arr_Rel_Insert_Input = {
  data: Array<Subscription_Interactions_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Subscription_Interactions_On_Conflict>;
};

/** Boolean expression to filter rows from the table "subscription_interactions". All fields are combined with a logical 'AND'. */
export type Subscription_Interactions_Bool_Exp = {
  _and?: InputMaybe<Array<Subscription_Interactions_Bool_Exp>>;
  _not?: InputMaybe<Subscription_Interactions_Bool_Exp>;
  _or?: InputMaybe<Array<Subscription_Interactions_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  interaction?: InputMaybe<Interactions_Bool_Exp>;
  interaction_id?: InputMaybe<Uuid_Comparison_Exp>;
  subscription?: InputMaybe<Subscriptions_Bool_Exp>;
  subscription_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "subscription_interactions" */
export enum Subscription_Interactions_Constraint {
  /** unique or primary key constraint on columns "id" */
  SubscriptionInteractionsPkey = "subscription_interactions_pkey",
}

/** input type for inserting data into table "subscription_interactions" */
export type Subscription_Interactions_Insert_Input = {
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  interaction?: InputMaybe<Interactions_Obj_Rel_Insert_Input>;
  interaction_id?: InputMaybe<Scalars["uuid"]>;
  subscription?: InputMaybe<Subscriptions_Obj_Rel_Insert_Input>;
  subscription_id?: InputMaybe<Scalars["uuid"]>;
};

/** aggregate max on columns */
export type Subscription_Interactions_Max_Fields = {
  __typename?: "subscription_interactions_max_fields";
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["uuid"]>;
  interaction_id?: Maybe<Scalars["uuid"]>;
  subscription_id?: Maybe<Scalars["uuid"]>;
};

/** order by max() on columns of table "subscription_interactions" */
export type Subscription_Interactions_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  interaction_id?: InputMaybe<Order_By>;
  subscription_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Subscription_Interactions_Min_Fields = {
  __typename?: "subscription_interactions_min_fields";
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["uuid"]>;
  interaction_id?: Maybe<Scalars["uuid"]>;
  subscription_id?: Maybe<Scalars["uuid"]>;
};

/** order by min() on columns of table "subscription_interactions" */
export type Subscription_Interactions_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  interaction_id?: InputMaybe<Order_By>;
  subscription_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "subscription_interactions" */
export type Subscription_Interactions_Mutation_Response = {
  __typename?: "subscription_interactions_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Subscription_Interactions>;
};

/** on_conflict condition type for table "subscription_interactions" */
export type Subscription_Interactions_On_Conflict = {
  constraint: Subscription_Interactions_Constraint;
  update_columns?: Array<Subscription_Interactions_Update_Column>;
  where?: InputMaybe<Subscription_Interactions_Bool_Exp>;
};

/** Ordering options when selecting data from "subscription_interactions". */
export type Subscription_Interactions_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  interaction?: InputMaybe<Interactions_Order_By>;
  interaction_id?: InputMaybe<Order_By>;
  subscription?: InputMaybe<Subscriptions_Order_By>;
  subscription_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: subscription_interactions */
export type Subscription_Interactions_Pk_Columns_Input = {
  id: Scalars["uuid"];
};

/** select columns of table "subscription_interactions" */
export enum Subscription_Interactions_Select_Column {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  Id = "id",
  /** column name */
  InteractionId = "interaction_id",
  /** column name */
  SubscriptionId = "subscription_id",
}

/** input type for updating data in table "subscription_interactions" */
export type Subscription_Interactions_Set_Input = {
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  interaction_id?: InputMaybe<Scalars["uuid"]>;
  subscription_id?: InputMaybe<Scalars["uuid"]>;
};

/** Streaming cursor of the table "subscription_interactions" */
export type Subscription_Interactions_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Subscription_Interactions_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Subscription_Interactions_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  interaction_id?: InputMaybe<Scalars["uuid"]>;
  subscription_id?: InputMaybe<Scalars["uuid"]>;
};

/** update columns of table "subscription_interactions" */
export enum Subscription_Interactions_Update_Column {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  Id = "id",
  /** column name */
  InteractionId = "interaction_id",
  /** column name */
  SubscriptionId = "subscription_id",
}

export type Subscription_Interactions_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Subscription_Interactions_Set_Input>;
  /** filter the rows which have to be updated */
  where: Subscription_Interactions_Bool_Exp;
};

/** columns and relationships of "subscription_items" */
export type Subscription_Items = {
  __typename?: "subscription_items";
  created_at: Scalars["timestamptz"];
  id: Scalars["uuid"];
  /** An array relationship */
  invoice_items: Array<Invoice_Items>;
  /** An aggregate relationship */
  invoice_items_aggregate: Invoice_Items_Aggregate;
  is_voided: Scalars["Boolean"];
  ledger_account: LedgerAccount;
  ledger_account_id?: Maybe<Scalars["uuid"]>;
  /** An object relationship */
  product: Products;
  product_id: Scalars["uuid"];
  /** An object relationship */
  product_item: Product_Items;
  product_item_id: Scalars["uuid"];
  quantity: Scalars["float8"];
  /** An object relationship */
  subscription: Subscriptions;
  subscription_id: Scalars["uuid"];
  /** An array relationship */
  subscription_payment_transactions: Array<Subscription_Payment_Transactions>;
  /** An aggregate relationship */
  subscription_payment_transactions_aggregate: Subscription_Payment_Transactions_Aggregate;
  transaction_entries: Array<TransactionLedgerEntry>;
  transaction_summary: TransactionLedgerEntryConnection;
  updated_at: Scalars["timestamptz"];
  void_reason?: Maybe<Scalars["String"]>;
};

/** columns and relationships of "subscription_items" */
export type Subscription_ItemsInvoice_ItemsArgs = {
  distinct_on?: InputMaybe<Array<Invoice_Items_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Invoice_Items_Order_By>>;
  where?: InputMaybe<Invoice_Items_Bool_Exp>;
};

/** columns and relationships of "subscription_items" */
export type Subscription_ItemsInvoice_Items_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Invoice_Items_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Invoice_Items_Order_By>>;
  where?: InputMaybe<Invoice_Items_Bool_Exp>;
};

/** columns and relationships of "subscription_items" */
export type Subscription_ItemsSubscription_Payment_TransactionsArgs = {
  distinct_on?: InputMaybe<
    Array<Subscription_Payment_Transactions_Select_Column>
  >;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Subscription_Payment_Transactions_Order_By>>;
  where?: InputMaybe<Subscription_Payment_Transactions_Bool_Exp>;
};

/** columns and relationships of "subscription_items" */
export type Subscription_ItemsSubscription_Payment_Transactions_AggregateArgs =
  {
    distinct_on?: InputMaybe<
      Array<Subscription_Payment_Transactions_Select_Column>
    >;
    limit?: InputMaybe<Scalars["Int"]>;
    offset?: InputMaybe<Scalars["Int"]>;
    order_by?: InputMaybe<Array<Subscription_Payment_Transactions_Order_By>>;
    where?: InputMaybe<Subscription_Payment_Transactions_Bool_Exp>;
  };

/** columns and relationships of "subscription_items" */
export type Subscription_ItemsTransaction_EntriesArgs = {
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

/** columns and relationships of "subscription_items" */
export type Subscription_ItemsTransaction_SummaryArgs = {
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<Ledger_Entries_Bool_Exp>;
};

/** aggregated selection of "subscription_items" */
export type Subscription_Items_Aggregate = {
  __typename?: "subscription_items_aggregate";
  aggregate?: Maybe<Subscription_Items_Aggregate_Fields>;
  nodes: Array<Subscription_Items>;
};

export type Subscription_Items_Aggregate_Bool_Exp = {
  avg?: InputMaybe<Subscription_Items_Aggregate_Bool_Exp_Avg>;
  bool_and?: InputMaybe<Subscription_Items_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Subscription_Items_Aggregate_Bool_Exp_Bool_Or>;
  corr?: InputMaybe<Subscription_Items_Aggregate_Bool_Exp_Corr>;
  count?: InputMaybe<Subscription_Items_Aggregate_Bool_Exp_Count>;
  covar_samp?: InputMaybe<Subscription_Items_Aggregate_Bool_Exp_Covar_Samp>;
  max?: InputMaybe<Subscription_Items_Aggregate_Bool_Exp_Max>;
  min?: InputMaybe<Subscription_Items_Aggregate_Bool_Exp_Min>;
  stddev_samp?: InputMaybe<Subscription_Items_Aggregate_Bool_Exp_Stddev_Samp>;
  sum?: InputMaybe<Subscription_Items_Aggregate_Bool_Exp_Sum>;
  var_samp?: InputMaybe<Subscription_Items_Aggregate_Bool_Exp_Var_Samp>;
};

export type Subscription_Items_Aggregate_Bool_Exp_Avg = {
  arguments: Subscription_Items_Select_Column_Subscription_Items_Aggregate_Bool_Exp_Avg_Arguments_Columns;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<Subscription_Items_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Subscription_Items_Aggregate_Bool_Exp_Bool_And = {
  arguments: Subscription_Items_Select_Column_Subscription_Items_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<Subscription_Items_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Subscription_Items_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Subscription_Items_Select_Column_Subscription_Items_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<Subscription_Items_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Subscription_Items_Aggregate_Bool_Exp_Corr = {
  arguments: Subscription_Items_Aggregate_Bool_Exp_Corr_Arguments;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<Subscription_Items_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Subscription_Items_Aggregate_Bool_Exp_Corr_Arguments = {
  X: Subscription_Items_Select_Column_Subscription_Items_Aggregate_Bool_Exp_Corr_Arguments_Columns;
  Y: Subscription_Items_Select_Column_Subscription_Items_Aggregate_Bool_Exp_Corr_Arguments_Columns;
};

export type Subscription_Items_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Subscription_Items_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<Subscription_Items_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

export type Subscription_Items_Aggregate_Bool_Exp_Covar_Samp = {
  arguments: Subscription_Items_Aggregate_Bool_Exp_Covar_Samp_Arguments;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<Subscription_Items_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Subscription_Items_Aggregate_Bool_Exp_Covar_Samp_Arguments = {
  X: Subscription_Items_Select_Column_Subscription_Items_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns;
  Y: Subscription_Items_Select_Column_Subscription_Items_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns;
};

export type Subscription_Items_Aggregate_Bool_Exp_Max = {
  arguments: Subscription_Items_Select_Column_Subscription_Items_Aggregate_Bool_Exp_Max_Arguments_Columns;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<Subscription_Items_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Subscription_Items_Aggregate_Bool_Exp_Min = {
  arguments: Subscription_Items_Select_Column_Subscription_Items_Aggregate_Bool_Exp_Min_Arguments_Columns;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<Subscription_Items_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Subscription_Items_Aggregate_Bool_Exp_Stddev_Samp = {
  arguments: Subscription_Items_Select_Column_Subscription_Items_Aggregate_Bool_Exp_Stddev_Samp_Arguments_Columns;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<Subscription_Items_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Subscription_Items_Aggregate_Bool_Exp_Sum = {
  arguments: Subscription_Items_Select_Column_Subscription_Items_Aggregate_Bool_Exp_Sum_Arguments_Columns;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<Subscription_Items_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Subscription_Items_Aggregate_Bool_Exp_Var_Samp = {
  arguments: Subscription_Items_Select_Column_Subscription_Items_Aggregate_Bool_Exp_Var_Samp_Arguments_Columns;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<Subscription_Items_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

/** aggregate fields of "subscription_items" */
export type Subscription_Items_Aggregate_Fields = {
  __typename?: "subscription_items_aggregate_fields";
  avg?: Maybe<Subscription_Items_Avg_Fields>;
  count: Scalars["Int"];
  max?: Maybe<Subscription_Items_Max_Fields>;
  min?: Maybe<Subscription_Items_Min_Fields>;
  stddev?: Maybe<Subscription_Items_Stddev_Fields>;
  stddev_pop?: Maybe<Subscription_Items_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Subscription_Items_Stddev_Samp_Fields>;
  sum?: Maybe<Subscription_Items_Sum_Fields>;
  var_pop?: Maybe<Subscription_Items_Var_Pop_Fields>;
  var_samp?: Maybe<Subscription_Items_Var_Samp_Fields>;
  variance?: Maybe<Subscription_Items_Variance_Fields>;
};

/** aggregate fields of "subscription_items" */
export type Subscription_Items_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Subscription_Items_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "subscription_items" */
export type Subscription_Items_Aggregate_Order_By = {
  avg?: InputMaybe<Subscription_Items_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Subscription_Items_Max_Order_By>;
  min?: InputMaybe<Subscription_Items_Min_Order_By>;
  stddev?: InputMaybe<Subscription_Items_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Subscription_Items_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Subscription_Items_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Subscription_Items_Sum_Order_By>;
  var_pop?: InputMaybe<Subscription_Items_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Subscription_Items_Var_Samp_Order_By>;
  variance?: InputMaybe<Subscription_Items_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "subscription_items" */
export type Subscription_Items_Arr_Rel_Insert_Input = {
  data: Array<Subscription_Items_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Subscription_Items_On_Conflict>;
};

/** aggregate avg on columns */
export type Subscription_Items_Avg_Fields = {
  __typename?: "subscription_items_avg_fields";
  quantity?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "subscription_items" */
export type Subscription_Items_Avg_Order_By = {
  quantity?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "subscription_items". All fields are combined with a logical 'AND'. */
export type Subscription_Items_Bool_Exp = {
  _and?: InputMaybe<Array<Subscription_Items_Bool_Exp>>;
  _not?: InputMaybe<Subscription_Items_Bool_Exp>;
  _or?: InputMaybe<Array<Subscription_Items_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  invoice_items?: InputMaybe<Invoice_Items_Bool_Exp>;
  invoice_items_aggregate?: InputMaybe<Invoice_Items_Aggregate_Bool_Exp>;
  is_voided?: InputMaybe<Boolean_Comparison_Exp>;
  ledger_account_id?: InputMaybe<Uuid_Comparison_Exp>;
  product?: InputMaybe<Products_Bool_Exp>;
  product_id?: InputMaybe<Uuid_Comparison_Exp>;
  product_item?: InputMaybe<Product_Items_Bool_Exp>;
  product_item_id?: InputMaybe<Uuid_Comparison_Exp>;
  quantity?: InputMaybe<Float8_Comparison_Exp>;
  subscription?: InputMaybe<Subscriptions_Bool_Exp>;
  subscription_id?: InputMaybe<Uuid_Comparison_Exp>;
  subscription_payment_transactions?: InputMaybe<Subscription_Payment_Transactions_Bool_Exp>;
  subscription_payment_transactions_aggregate?: InputMaybe<Subscription_Payment_Transactions_Aggregate_Bool_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  void_reason?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "subscription_items" */
export enum Subscription_Items_Constraint {
  /** unique or primary key constraint on columns "id" */
  SubscriptionItemsPkey = "subscription_items_pkey",
  /** unique or primary key constraint on columns "subscription_id", "product_item_id" */
  SubscriptionItemsSubscriptionIdProductItemIdKey = "subscription_items_subscription_id_product_item_id_key",
  /** unique or primary key constraint on columns "ledger_account_id" */
  UniqueSubscriptionItemLedgerAccount = "unique_subscription_item_ledger_account",
}

/** input type for incrementing numeric columns in table "subscription_items" */
export type Subscription_Items_Inc_Input = {
  quantity?: InputMaybe<Scalars["float8"]>;
};

/** input type for inserting data into table "subscription_items" */
export type Subscription_Items_Insert_Input = {
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  invoice_items?: InputMaybe<Invoice_Items_Arr_Rel_Insert_Input>;
  is_voided?: InputMaybe<Scalars["Boolean"]>;
  ledger_account_id?: InputMaybe<Scalars["uuid"]>;
  product?: InputMaybe<Products_Obj_Rel_Insert_Input>;
  product_id?: InputMaybe<Scalars["uuid"]>;
  product_item?: InputMaybe<Product_Items_Obj_Rel_Insert_Input>;
  product_item_id?: InputMaybe<Scalars["uuid"]>;
  quantity?: InputMaybe<Scalars["float8"]>;
  subscription?: InputMaybe<Subscriptions_Obj_Rel_Insert_Input>;
  subscription_id?: InputMaybe<Scalars["uuid"]>;
  subscription_payment_transactions?: InputMaybe<Subscription_Payment_Transactions_Arr_Rel_Insert_Input>;
  updated_at?: InputMaybe<Scalars["timestamptz"]>;
  void_reason?: InputMaybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Subscription_Items_Max_Fields = {
  __typename?: "subscription_items_max_fields";
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["uuid"]>;
  ledger_account_id?: Maybe<Scalars["uuid"]>;
  product_id?: Maybe<Scalars["uuid"]>;
  product_item_id?: Maybe<Scalars["uuid"]>;
  quantity?: Maybe<Scalars["float8"]>;
  subscription_id?: Maybe<Scalars["uuid"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
  void_reason?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "subscription_items" */
export type Subscription_Items_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  ledger_account_id?: InputMaybe<Order_By>;
  product_id?: InputMaybe<Order_By>;
  product_item_id?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  subscription_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  void_reason?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Subscription_Items_Min_Fields = {
  __typename?: "subscription_items_min_fields";
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["uuid"]>;
  ledger_account_id?: Maybe<Scalars["uuid"]>;
  product_id?: Maybe<Scalars["uuid"]>;
  product_item_id?: Maybe<Scalars["uuid"]>;
  quantity?: Maybe<Scalars["float8"]>;
  subscription_id?: Maybe<Scalars["uuid"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
  void_reason?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "subscription_items" */
export type Subscription_Items_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  ledger_account_id?: InputMaybe<Order_By>;
  product_id?: InputMaybe<Order_By>;
  product_item_id?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  subscription_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  void_reason?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "subscription_items" */
export type Subscription_Items_Mutation_Response = {
  __typename?: "subscription_items_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Subscription_Items>;
};

/** input type for inserting object relation for remote table "subscription_items" */
export type Subscription_Items_Obj_Rel_Insert_Input = {
  data: Subscription_Items_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Subscription_Items_On_Conflict>;
};

/** on_conflict condition type for table "subscription_items" */
export type Subscription_Items_On_Conflict = {
  constraint: Subscription_Items_Constraint;
  update_columns?: Array<Subscription_Items_Update_Column>;
  where?: InputMaybe<Subscription_Items_Bool_Exp>;
};

/** Ordering options when selecting data from "subscription_items". */
export type Subscription_Items_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  invoice_items_aggregate?: InputMaybe<Invoice_Items_Aggregate_Order_By>;
  is_voided?: InputMaybe<Order_By>;
  ledger_account_id?: InputMaybe<Order_By>;
  product?: InputMaybe<Products_Order_By>;
  product_id?: InputMaybe<Order_By>;
  product_item?: InputMaybe<Product_Items_Order_By>;
  product_item_id?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  subscription?: InputMaybe<Subscriptions_Order_By>;
  subscription_id?: InputMaybe<Order_By>;
  subscription_payment_transactions_aggregate?: InputMaybe<Subscription_Payment_Transactions_Aggregate_Order_By>;
  updated_at?: InputMaybe<Order_By>;
  void_reason?: InputMaybe<Order_By>;
};

/** primary key columns input for table: subscription_items */
export type Subscription_Items_Pk_Columns_Input = {
  id: Scalars["uuid"];
};

/** select columns of table "subscription_items" */
export enum Subscription_Items_Select_Column {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  Id = "id",
  /** column name */
  IsVoided = "is_voided",
  /** column name */
  LedgerAccountId = "ledger_account_id",
  /** column name */
  ProductId = "product_id",
  /** column name */
  ProductItemId = "product_item_id",
  /** column name */
  Quantity = "quantity",
  /** column name */
  SubscriptionId = "subscription_id",
  /** column name */
  UpdatedAt = "updated_at",
  /** column name */
  VoidReason = "void_reason",
}

/** select "subscription_items_aggregate_bool_exp_avg_arguments_columns" columns of table "subscription_items" */
export enum Subscription_Items_Select_Column_Subscription_Items_Aggregate_Bool_Exp_Avg_Arguments_Columns {
  /** column name */
  Quantity = "quantity",
}

/** select "subscription_items_aggregate_bool_exp_bool_and_arguments_columns" columns of table "subscription_items" */
export enum Subscription_Items_Select_Column_Subscription_Items_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  IsVoided = "is_voided",
}

/** select "subscription_items_aggregate_bool_exp_bool_or_arguments_columns" columns of table "subscription_items" */
export enum Subscription_Items_Select_Column_Subscription_Items_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  IsVoided = "is_voided",
}

/** select "subscription_items_aggregate_bool_exp_corr_arguments_columns" columns of table "subscription_items" */
export enum Subscription_Items_Select_Column_Subscription_Items_Aggregate_Bool_Exp_Corr_Arguments_Columns {
  /** column name */
  Quantity = "quantity",
}

/** select "subscription_items_aggregate_bool_exp_covar_samp_arguments_columns" columns of table "subscription_items" */
export enum Subscription_Items_Select_Column_Subscription_Items_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns {
  /** column name */
  Quantity = "quantity",
}

/** select "subscription_items_aggregate_bool_exp_max_arguments_columns" columns of table "subscription_items" */
export enum Subscription_Items_Select_Column_Subscription_Items_Aggregate_Bool_Exp_Max_Arguments_Columns {
  /** column name */
  Quantity = "quantity",
}

/** select "subscription_items_aggregate_bool_exp_min_arguments_columns" columns of table "subscription_items" */
export enum Subscription_Items_Select_Column_Subscription_Items_Aggregate_Bool_Exp_Min_Arguments_Columns {
  /** column name */
  Quantity = "quantity",
}

/** select "subscription_items_aggregate_bool_exp_stddev_samp_arguments_columns" columns of table "subscription_items" */
export enum Subscription_Items_Select_Column_Subscription_Items_Aggregate_Bool_Exp_Stddev_Samp_Arguments_Columns {
  /** column name */
  Quantity = "quantity",
}

/** select "subscription_items_aggregate_bool_exp_sum_arguments_columns" columns of table "subscription_items" */
export enum Subscription_Items_Select_Column_Subscription_Items_Aggregate_Bool_Exp_Sum_Arguments_Columns {
  /** column name */
  Quantity = "quantity",
}

/** select "subscription_items_aggregate_bool_exp_var_samp_arguments_columns" columns of table "subscription_items" */
export enum Subscription_Items_Select_Column_Subscription_Items_Aggregate_Bool_Exp_Var_Samp_Arguments_Columns {
  /** column name */
  Quantity = "quantity",
}

/** input type for updating data in table "subscription_items" */
export type Subscription_Items_Set_Input = {
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  is_voided?: InputMaybe<Scalars["Boolean"]>;
  ledger_account_id?: InputMaybe<Scalars["uuid"]>;
  product_id?: InputMaybe<Scalars["uuid"]>;
  product_item_id?: InputMaybe<Scalars["uuid"]>;
  quantity?: InputMaybe<Scalars["float8"]>;
  subscription_id?: InputMaybe<Scalars["uuid"]>;
  updated_at?: InputMaybe<Scalars["timestamptz"]>;
  void_reason?: InputMaybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type Subscription_Items_Stddev_Fields = {
  __typename?: "subscription_items_stddev_fields";
  quantity?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "subscription_items" */
export type Subscription_Items_Stddev_Order_By = {
  quantity?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Subscription_Items_Stddev_Pop_Fields = {
  __typename?: "subscription_items_stddev_pop_fields";
  quantity?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "subscription_items" */
export type Subscription_Items_Stddev_Pop_Order_By = {
  quantity?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Subscription_Items_Stddev_Samp_Fields = {
  __typename?: "subscription_items_stddev_samp_fields";
  quantity?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "subscription_items" */
export type Subscription_Items_Stddev_Samp_Order_By = {
  quantity?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "subscription_items" */
export type Subscription_Items_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Subscription_Items_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Subscription_Items_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  is_voided?: InputMaybe<Scalars["Boolean"]>;
  ledger_account_id?: InputMaybe<Scalars["uuid"]>;
  product_id?: InputMaybe<Scalars["uuid"]>;
  product_item_id?: InputMaybe<Scalars["uuid"]>;
  quantity?: InputMaybe<Scalars["float8"]>;
  subscription_id?: InputMaybe<Scalars["uuid"]>;
  updated_at?: InputMaybe<Scalars["timestamptz"]>;
  void_reason?: InputMaybe<Scalars["String"]>;
};

/** aggregate sum on columns */
export type Subscription_Items_Sum_Fields = {
  __typename?: "subscription_items_sum_fields";
  quantity?: Maybe<Scalars["float8"]>;
};

/** order by sum() on columns of table "subscription_items" */
export type Subscription_Items_Sum_Order_By = {
  quantity?: InputMaybe<Order_By>;
};

/** update columns of table "subscription_items" */
export enum Subscription_Items_Update_Column {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  Id = "id",
  /** column name */
  IsVoided = "is_voided",
  /** column name */
  LedgerAccountId = "ledger_account_id",
  /** column name */
  ProductId = "product_id",
  /** column name */
  ProductItemId = "product_item_id",
  /** column name */
  Quantity = "quantity",
  /** column name */
  SubscriptionId = "subscription_id",
  /** column name */
  UpdatedAt = "updated_at",
  /** column name */
  VoidReason = "void_reason",
}

export type Subscription_Items_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Subscription_Items_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Subscription_Items_Set_Input>;
  /** filter the rows which have to be updated */
  where: Subscription_Items_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Subscription_Items_Var_Pop_Fields = {
  __typename?: "subscription_items_var_pop_fields";
  quantity?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "subscription_items" */
export type Subscription_Items_Var_Pop_Order_By = {
  quantity?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Subscription_Items_Var_Samp_Fields = {
  __typename?: "subscription_items_var_samp_fields";
  quantity?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "subscription_items" */
export type Subscription_Items_Var_Samp_Order_By = {
  quantity?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Subscription_Items_Variance_Fields = {
  __typename?: "subscription_items_variance_fields";
  quantity?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "subscription_items" */
export type Subscription_Items_Variance_Order_By = {
  quantity?: InputMaybe<Order_By>;
};

/** columns and relationships of "subscription_payment_transactions" */
export type Subscription_Payment_Transactions = {
  __typename?: "subscription_payment_transactions";
  amount: Scalars["numeric"];
  created_at?: Maybe<Scalars["timestamptz"]>;
  id: Scalars["uuid"];
  /** An object relationship */
  invoice?: Maybe<Invoices>;
  invoice_id?: Maybe<Scalars["uuid"]>;
  /** An object relationship */
  invoice_item?: Maybe<Invoice_Items>;
  invoice_item_id?: Maybe<Scalars["uuid"]>;
  ledger_account_id: Scalars["uuid"];
  ledger_id: Scalars["uuid"];
  ledger_transaction: Transaction;
  ledger_transaction_id: Scalars["uuid"];
  metadata?: Maybe<Scalars["jsonb"]>;
  organisation_id: Scalars["uuid"];
  /** An object relationship */
  party: Parties;
  party_id: Scalars["uuid"];
  /** An object relationship */
  product: Products;
  product_id: Scalars["uuid"];
  /** An object relationship */
  product_item: Product_Items;
  product_item_id: Scalars["uuid"];
  /** An object relationship */
  subscription: Subscriptions;
  subscription_id: Scalars["uuid"];
  /** An object relationship */
  subscription_item: Subscription_Items;
  subscription_item_id: Scalars["uuid"];
  /** An object relationship */
  subscription_payment: Subscription_Payments;
  subscription_payment_id: Scalars["uuid"];
};

/** columns and relationships of "subscription_payment_transactions" */
export type Subscription_Payment_TransactionsMetadataArgs = {
  path?: InputMaybe<Scalars["String"]>;
};

/** aggregated selection of "subscription_payment_transactions" */
export type Subscription_Payment_Transactions_Aggregate = {
  __typename?: "subscription_payment_transactions_aggregate";
  aggregate?: Maybe<Subscription_Payment_Transactions_Aggregate_Fields>;
  nodes: Array<Subscription_Payment_Transactions>;
};

export type Subscription_Payment_Transactions_Aggregate_Bool_Exp = {
  count?: InputMaybe<Subscription_Payment_Transactions_Aggregate_Bool_Exp_Count>;
};

export type Subscription_Payment_Transactions_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<
    Array<Subscription_Payment_Transactions_Select_Column>
  >;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<Subscription_Payment_Transactions_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "subscription_payment_transactions" */
export type Subscription_Payment_Transactions_Aggregate_Fields = {
  __typename?: "subscription_payment_transactions_aggregate_fields";
  avg?: Maybe<Subscription_Payment_Transactions_Avg_Fields>;
  count: Scalars["Int"];
  max?: Maybe<Subscription_Payment_Transactions_Max_Fields>;
  min?: Maybe<Subscription_Payment_Transactions_Min_Fields>;
  stddev?: Maybe<Subscription_Payment_Transactions_Stddev_Fields>;
  stddev_pop?: Maybe<Subscription_Payment_Transactions_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Subscription_Payment_Transactions_Stddev_Samp_Fields>;
  sum?: Maybe<Subscription_Payment_Transactions_Sum_Fields>;
  var_pop?: Maybe<Subscription_Payment_Transactions_Var_Pop_Fields>;
  var_samp?: Maybe<Subscription_Payment_Transactions_Var_Samp_Fields>;
  variance?: Maybe<Subscription_Payment_Transactions_Variance_Fields>;
};

/** aggregate fields of "subscription_payment_transactions" */
export type Subscription_Payment_Transactions_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Subscription_Payment_Transactions_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "subscription_payment_transactions" */
export type Subscription_Payment_Transactions_Aggregate_Order_By = {
  avg?: InputMaybe<Subscription_Payment_Transactions_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Subscription_Payment_Transactions_Max_Order_By>;
  min?: InputMaybe<Subscription_Payment_Transactions_Min_Order_By>;
  stddev?: InputMaybe<Subscription_Payment_Transactions_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Subscription_Payment_Transactions_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Subscription_Payment_Transactions_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Subscription_Payment_Transactions_Sum_Order_By>;
  var_pop?: InputMaybe<Subscription_Payment_Transactions_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Subscription_Payment_Transactions_Var_Samp_Order_By>;
  variance?: InputMaybe<Subscription_Payment_Transactions_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Subscription_Payment_Transactions_Append_Input = {
  metadata?: InputMaybe<Scalars["jsonb"]>;
};

/** input type for inserting array relation for remote table "subscription_payment_transactions" */
export type Subscription_Payment_Transactions_Arr_Rel_Insert_Input = {
  data: Array<Subscription_Payment_Transactions_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Subscription_Payment_Transactions_On_Conflict>;
};

/** aggregate avg on columns */
export type Subscription_Payment_Transactions_Avg_Fields = {
  __typename?: "subscription_payment_transactions_avg_fields";
  amount?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "subscription_payment_transactions" */
export type Subscription_Payment_Transactions_Avg_Order_By = {
  amount?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "subscription_payment_transactions". All fields are combined with a logical 'AND'. */
export type Subscription_Payment_Transactions_Bool_Exp = {
  _and?: InputMaybe<Array<Subscription_Payment_Transactions_Bool_Exp>>;
  _not?: InputMaybe<Subscription_Payment_Transactions_Bool_Exp>;
  _or?: InputMaybe<Array<Subscription_Payment_Transactions_Bool_Exp>>;
  amount?: InputMaybe<Numeric_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  invoice?: InputMaybe<Invoices_Bool_Exp>;
  invoice_id?: InputMaybe<Uuid_Comparison_Exp>;
  invoice_item?: InputMaybe<Invoice_Items_Bool_Exp>;
  invoice_item_id?: InputMaybe<Uuid_Comparison_Exp>;
  ledger_account_id?: InputMaybe<Uuid_Comparison_Exp>;
  ledger_id?: InputMaybe<Uuid_Comparison_Exp>;
  ledger_transaction_id?: InputMaybe<Uuid_Comparison_Exp>;
  metadata?: InputMaybe<Jsonb_Comparison_Exp>;
  organisation_id?: InputMaybe<Uuid_Comparison_Exp>;
  party?: InputMaybe<Parties_Bool_Exp>;
  party_id?: InputMaybe<Uuid_Comparison_Exp>;
  product?: InputMaybe<Products_Bool_Exp>;
  product_id?: InputMaybe<Uuid_Comparison_Exp>;
  product_item?: InputMaybe<Product_Items_Bool_Exp>;
  product_item_id?: InputMaybe<Uuid_Comparison_Exp>;
  subscription?: InputMaybe<Subscriptions_Bool_Exp>;
  subscription_id?: InputMaybe<Uuid_Comparison_Exp>;
  subscription_item?: InputMaybe<Subscription_Items_Bool_Exp>;
  subscription_item_id?: InputMaybe<Uuid_Comparison_Exp>;
  subscription_payment?: InputMaybe<Subscription_Payments_Bool_Exp>;
  subscription_payment_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "subscription_payment_transactions" */
export enum Subscription_Payment_Transactions_Constraint {
  /** unique or primary key constraint on columns "id" */
  SubscriptionPaymentTransactionsPkey = "subscription_payment_transactions_pkey",
  /** unique or primary key constraint on columns "subscription_payment_id", "subscription_item_id" */
  SubscriptionPaymentTransactionsSubscriptionItemIdSubscrip = "subscription_payment_transactions_subscription_item_id_subscrip",
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Subscription_Payment_Transactions_Delete_At_Path_Input = {
  metadata?: InputMaybe<Array<Scalars["String"]>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Subscription_Payment_Transactions_Delete_Elem_Input = {
  metadata?: InputMaybe<Scalars["Int"]>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Subscription_Payment_Transactions_Delete_Key_Input = {
  metadata?: InputMaybe<Scalars["String"]>;
};

/** input type for incrementing numeric columns in table "subscription_payment_transactions" */
export type Subscription_Payment_Transactions_Inc_Input = {
  amount?: InputMaybe<Scalars["numeric"]>;
};

/** input type for inserting data into table "subscription_payment_transactions" */
export type Subscription_Payment_Transactions_Insert_Input = {
  amount?: InputMaybe<Scalars["numeric"]>;
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  invoice?: InputMaybe<Invoices_Obj_Rel_Insert_Input>;
  invoice_id?: InputMaybe<Scalars["uuid"]>;
  invoice_item?: InputMaybe<Invoice_Items_Obj_Rel_Insert_Input>;
  invoice_item_id?: InputMaybe<Scalars["uuid"]>;
  ledger_account_id?: InputMaybe<Scalars["uuid"]>;
  ledger_id?: InputMaybe<Scalars["uuid"]>;
  ledger_transaction_id?: InputMaybe<Scalars["uuid"]>;
  metadata?: InputMaybe<Scalars["jsonb"]>;
  organisation_id?: InputMaybe<Scalars["uuid"]>;
  party?: InputMaybe<Parties_Obj_Rel_Insert_Input>;
  party_id?: InputMaybe<Scalars["uuid"]>;
  product?: InputMaybe<Products_Obj_Rel_Insert_Input>;
  product_id?: InputMaybe<Scalars["uuid"]>;
  product_item?: InputMaybe<Product_Items_Obj_Rel_Insert_Input>;
  product_item_id?: InputMaybe<Scalars["uuid"]>;
  subscription?: InputMaybe<Subscriptions_Obj_Rel_Insert_Input>;
  subscription_id?: InputMaybe<Scalars["uuid"]>;
  subscription_item?: InputMaybe<Subscription_Items_Obj_Rel_Insert_Input>;
  subscription_item_id?: InputMaybe<Scalars["uuid"]>;
  subscription_payment?: InputMaybe<Subscription_Payments_Obj_Rel_Insert_Input>;
  subscription_payment_id?: InputMaybe<Scalars["uuid"]>;
};

/** aggregate max on columns */
export type Subscription_Payment_Transactions_Max_Fields = {
  __typename?: "subscription_payment_transactions_max_fields";
  amount?: Maybe<Scalars["numeric"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["uuid"]>;
  invoice_id?: Maybe<Scalars["uuid"]>;
  invoice_item_id?: Maybe<Scalars["uuid"]>;
  ledger_account_id?: Maybe<Scalars["uuid"]>;
  ledger_id?: Maybe<Scalars["uuid"]>;
  ledger_transaction_id?: Maybe<Scalars["uuid"]>;
  organisation_id?: Maybe<Scalars["uuid"]>;
  party_id?: Maybe<Scalars["uuid"]>;
  product_id?: Maybe<Scalars["uuid"]>;
  product_item_id?: Maybe<Scalars["uuid"]>;
  subscription_id?: Maybe<Scalars["uuid"]>;
  subscription_item_id?: Maybe<Scalars["uuid"]>;
  subscription_payment_id?: Maybe<Scalars["uuid"]>;
};

/** order by max() on columns of table "subscription_payment_transactions" */
export type Subscription_Payment_Transactions_Max_Order_By = {
  amount?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  invoice_id?: InputMaybe<Order_By>;
  invoice_item_id?: InputMaybe<Order_By>;
  ledger_account_id?: InputMaybe<Order_By>;
  ledger_id?: InputMaybe<Order_By>;
  ledger_transaction_id?: InputMaybe<Order_By>;
  organisation_id?: InputMaybe<Order_By>;
  party_id?: InputMaybe<Order_By>;
  product_id?: InputMaybe<Order_By>;
  product_item_id?: InputMaybe<Order_By>;
  subscription_id?: InputMaybe<Order_By>;
  subscription_item_id?: InputMaybe<Order_By>;
  subscription_payment_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Subscription_Payment_Transactions_Min_Fields = {
  __typename?: "subscription_payment_transactions_min_fields";
  amount?: Maybe<Scalars["numeric"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["uuid"]>;
  invoice_id?: Maybe<Scalars["uuid"]>;
  invoice_item_id?: Maybe<Scalars["uuid"]>;
  ledger_account_id?: Maybe<Scalars["uuid"]>;
  ledger_id?: Maybe<Scalars["uuid"]>;
  ledger_transaction_id?: Maybe<Scalars["uuid"]>;
  organisation_id?: Maybe<Scalars["uuid"]>;
  party_id?: Maybe<Scalars["uuid"]>;
  product_id?: Maybe<Scalars["uuid"]>;
  product_item_id?: Maybe<Scalars["uuid"]>;
  subscription_id?: Maybe<Scalars["uuid"]>;
  subscription_item_id?: Maybe<Scalars["uuid"]>;
  subscription_payment_id?: Maybe<Scalars["uuid"]>;
};

/** order by min() on columns of table "subscription_payment_transactions" */
export type Subscription_Payment_Transactions_Min_Order_By = {
  amount?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  invoice_id?: InputMaybe<Order_By>;
  invoice_item_id?: InputMaybe<Order_By>;
  ledger_account_id?: InputMaybe<Order_By>;
  ledger_id?: InputMaybe<Order_By>;
  ledger_transaction_id?: InputMaybe<Order_By>;
  organisation_id?: InputMaybe<Order_By>;
  party_id?: InputMaybe<Order_By>;
  product_id?: InputMaybe<Order_By>;
  product_item_id?: InputMaybe<Order_By>;
  subscription_id?: InputMaybe<Order_By>;
  subscription_item_id?: InputMaybe<Order_By>;
  subscription_payment_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "subscription_payment_transactions" */
export type Subscription_Payment_Transactions_Mutation_Response = {
  __typename?: "subscription_payment_transactions_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Subscription_Payment_Transactions>;
};

/** on_conflict condition type for table "subscription_payment_transactions" */
export type Subscription_Payment_Transactions_On_Conflict = {
  constraint: Subscription_Payment_Transactions_Constraint;
  update_columns?: Array<Subscription_Payment_Transactions_Update_Column>;
  where?: InputMaybe<Subscription_Payment_Transactions_Bool_Exp>;
};

/** Ordering options when selecting data from "subscription_payment_transactions". */
export type Subscription_Payment_Transactions_Order_By = {
  amount?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  invoice?: InputMaybe<Invoices_Order_By>;
  invoice_id?: InputMaybe<Order_By>;
  invoice_item?: InputMaybe<Invoice_Items_Order_By>;
  invoice_item_id?: InputMaybe<Order_By>;
  ledger_account_id?: InputMaybe<Order_By>;
  ledger_id?: InputMaybe<Order_By>;
  ledger_transaction_id?: InputMaybe<Order_By>;
  metadata?: InputMaybe<Order_By>;
  organisation_id?: InputMaybe<Order_By>;
  party?: InputMaybe<Parties_Order_By>;
  party_id?: InputMaybe<Order_By>;
  product?: InputMaybe<Products_Order_By>;
  product_id?: InputMaybe<Order_By>;
  product_item?: InputMaybe<Product_Items_Order_By>;
  product_item_id?: InputMaybe<Order_By>;
  subscription?: InputMaybe<Subscriptions_Order_By>;
  subscription_id?: InputMaybe<Order_By>;
  subscription_item?: InputMaybe<Subscription_Items_Order_By>;
  subscription_item_id?: InputMaybe<Order_By>;
  subscription_payment?: InputMaybe<Subscription_Payments_Order_By>;
  subscription_payment_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: subscription_payment_transactions */
export type Subscription_Payment_Transactions_Pk_Columns_Input = {
  id: Scalars["uuid"];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Subscription_Payment_Transactions_Prepend_Input = {
  metadata?: InputMaybe<Scalars["jsonb"]>;
};

/** select columns of table "subscription_payment_transactions" */
export enum Subscription_Payment_Transactions_Select_Column {
  /** column name */
  Amount = "amount",
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  Id = "id",
  /** column name */
  InvoiceId = "invoice_id",
  /** column name */
  InvoiceItemId = "invoice_item_id",
  /** column name */
  LedgerAccountId = "ledger_account_id",
  /** column name */
  LedgerId = "ledger_id",
  /** column name */
  LedgerTransactionId = "ledger_transaction_id",
  /** column name */
  Metadata = "metadata",
  /** column name */
  OrganisationId = "organisation_id",
  /** column name */
  PartyId = "party_id",
  /** column name */
  ProductId = "product_id",
  /** column name */
  ProductItemId = "product_item_id",
  /** column name */
  SubscriptionId = "subscription_id",
  /** column name */
  SubscriptionItemId = "subscription_item_id",
  /** column name */
  SubscriptionPaymentId = "subscription_payment_id",
}

/** input type for updating data in table "subscription_payment_transactions" */
export type Subscription_Payment_Transactions_Set_Input = {
  amount?: InputMaybe<Scalars["numeric"]>;
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  invoice_id?: InputMaybe<Scalars["uuid"]>;
  invoice_item_id?: InputMaybe<Scalars["uuid"]>;
  ledger_account_id?: InputMaybe<Scalars["uuid"]>;
  ledger_id?: InputMaybe<Scalars["uuid"]>;
  ledger_transaction_id?: InputMaybe<Scalars["uuid"]>;
  metadata?: InputMaybe<Scalars["jsonb"]>;
  organisation_id?: InputMaybe<Scalars["uuid"]>;
  party_id?: InputMaybe<Scalars["uuid"]>;
  product_id?: InputMaybe<Scalars["uuid"]>;
  product_item_id?: InputMaybe<Scalars["uuid"]>;
  subscription_id?: InputMaybe<Scalars["uuid"]>;
  subscription_item_id?: InputMaybe<Scalars["uuid"]>;
  subscription_payment_id?: InputMaybe<Scalars["uuid"]>;
};

/** aggregate stddev on columns */
export type Subscription_Payment_Transactions_Stddev_Fields = {
  __typename?: "subscription_payment_transactions_stddev_fields";
  amount?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "subscription_payment_transactions" */
export type Subscription_Payment_Transactions_Stddev_Order_By = {
  amount?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Subscription_Payment_Transactions_Stddev_Pop_Fields = {
  __typename?: "subscription_payment_transactions_stddev_pop_fields";
  amount?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "subscription_payment_transactions" */
export type Subscription_Payment_Transactions_Stddev_Pop_Order_By = {
  amount?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Subscription_Payment_Transactions_Stddev_Samp_Fields = {
  __typename?: "subscription_payment_transactions_stddev_samp_fields";
  amount?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "subscription_payment_transactions" */
export type Subscription_Payment_Transactions_Stddev_Samp_Order_By = {
  amount?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "subscription_payment_transactions" */
export type Subscription_Payment_Transactions_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Subscription_Payment_Transactions_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Subscription_Payment_Transactions_Stream_Cursor_Value_Input = {
  amount?: InputMaybe<Scalars["numeric"]>;
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  invoice_id?: InputMaybe<Scalars["uuid"]>;
  invoice_item_id?: InputMaybe<Scalars["uuid"]>;
  ledger_account_id?: InputMaybe<Scalars["uuid"]>;
  ledger_id?: InputMaybe<Scalars["uuid"]>;
  ledger_transaction_id?: InputMaybe<Scalars["uuid"]>;
  metadata?: InputMaybe<Scalars["jsonb"]>;
  organisation_id?: InputMaybe<Scalars["uuid"]>;
  party_id?: InputMaybe<Scalars["uuid"]>;
  product_id?: InputMaybe<Scalars["uuid"]>;
  product_item_id?: InputMaybe<Scalars["uuid"]>;
  subscription_id?: InputMaybe<Scalars["uuid"]>;
  subscription_item_id?: InputMaybe<Scalars["uuid"]>;
  subscription_payment_id?: InputMaybe<Scalars["uuid"]>;
};

/** aggregate sum on columns */
export type Subscription_Payment_Transactions_Sum_Fields = {
  __typename?: "subscription_payment_transactions_sum_fields";
  amount?: Maybe<Scalars["numeric"]>;
};

/** order by sum() on columns of table "subscription_payment_transactions" */
export type Subscription_Payment_Transactions_Sum_Order_By = {
  amount?: InputMaybe<Order_By>;
};

/** update columns of table "subscription_payment_transactions" */
export enum Subscription_Payment_Transactions_Update_Column {
  /** column name */
  Amount = "amount",
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  Id = "id",
  /** column name */
  InvoiceId = "invoice_id",
  /** column name */
  InvoiceItemId = "invoice_item_id",
  /** column name */
  LedgerAccountId = "ledger_account_id",
  /** column name */
  LedgerId = "ledger_id",
  /** column name */
  LedgerTransactionId = "ledger_transaction_id",
  /** column name */
  Metadata = "metadata",
  /** column name */
  OrganisationId = "organisation_id",
  /** column name */
  PartyId = "party_id",
  /** column name */
  ProductId = "product_id",
  /** column name */
  ProductItemId = "product_item_id",
  /** column name */
  SubscriptionId = "subscription_id",
  /** column name */
  SubscriptionItemId = "subscription_item_id",
  /** column name */
  SubscriptionPaymentId = "subscription_payment_id",
}

export type Subscription_Payment_Transactions_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Subscription_Payment_Transactions_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Subscription_Payment_Transactions_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Subscription_Payment_Transactions_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Subscription_Payment_Transactions_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Subscription_Payment_Transactions_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Subscription_Payment_Transactions_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Subscription_Payment_Transactions_Set_Input>;
  /** filter the rows which have to be updated */
  where: Subscription_Payment_Transactions_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Subscription_Payment_Transactions_Var_Pop_Fields = {
  __typename?: "subscription_payment_transactions_var_pop_fields";
  amount?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "subscription_payment_transactions" */
export type Subscription_Payment_Transactions_Var_Pop_Order_By = {
  amount?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Subscription_Payment_Transactions_Var_Samp_Fields = {
  __typename?: "subscription_payment_transactions_var_samp_fields";
  amount?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "subscription_payment_transactions" */
export type Subscription_Payment_Transactions_Var_Samp_Order_By = {
  amount?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Subscription_Payment_Transactions_Variance_Fields = {
  __typename?: "subscription_payment_transactions_variance_fields";
  amount?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "subscription_payment_transactions" */
export type Subscription_Payment_Transactions_Variance_Order_By = {
  amount?: InputMaybe<Order_By>;
};

/** columns and relationships of "subscription_payments" */
export type Subscription_Payments = {
  __typename?: "subscription_payments";
  amount: Scalars["float8"];
  created_at: Scalars["timestamptz"];
  id: Scalars["uuid"];
  /** An object relationship */
  invoice?: Maybe<Invoices>;
  invoice_id?: Maybe<Scalars["uuid"]>;
  ledger_id: Scalars["uuid"];
  metadata?: Maybe<Scalars["jsonb"]>;
  /** An object relationship */
  party: Parties;
  /** An object relationship */
  partyByRecordedBy: Parties;
  /** An object relationship */
  partyByUpdatedBy: Parties;
  party_id: Scalars["uuid"];
  payment_date: Scalars["date"];
  payment_service_id?: Maybe<Scalars["String"]>;
  product_id: Scalars["uuid"];
  recorded_by: Scalars["uuid"];
  /** An object relationship */
  subscription: Subscriptions;
  subscription_balance: Scalars["float8"];
  subscription_id: Scalars["uuid"];
  /** An array relationship */
  subscription_payment_transactions: Array<Subscription_Payment_Transactions>;
  /** An aggregate relationship */
  subscription_payment_transactions_aggregate: Subscription_Payment_Transactions_Aggregate;
  updated_at: Scalars["timestamptz"];
  updated_by: Scalars["uuid"];
};

/** columns and relationships of "subscription_payments" */
export type Subscription_PaymentsMetadataArgs = {
  path?: InputMaybe<Scalars["String"]>;
};

/** columns and relationships of "subscription_payments" */
export type Subscription_PaymentsSubscription_Payment_TransactionsArgs = {
  distinct_on?: InputMaybe<
    Array<Subscription_Payment_Transactions_Select_Column>
  >;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Subscription_Payment_Transactions_Order_By>>;
  where?: InputMaybe<Subscription_Payment_Transactions_Bool_Exp>;
};

/** columns and relationships of "subscription_payments" */
export type Subscription_PaymentsSubscription_Payment_Transactions_AggregateArgs =
  {
    distinct_on?: InputMaybe<
      Array<Subscription_Payment_Transactions_Select_Column>
    >;
    limit?: InputMaybe<Scalars["Int"]>;
    offset?: InputMaybe<Scalars["Int"]>;
    order_by?: InputMaybe<Array<Subscription_Payment_Transactions_Order_By>>;
    where?: InputMaybe<Subscription_Payment_Transactions_Bool_Exp>;
  };

/** aggregated selection of "subscription_payments" */
export type Subscription_Payments_Aggregate = {
  __typename?: "subscription_payments_aggregate";
  aggregate?: Maybe<Subscription_Payments_Aggregate_Fields>;
  nodes: Array<Subscription_Payments>;
};

export type Subscription_Payments_Aggregate_Bool_Exp = {
  avg?: InputMaybe<Subscription_Payments_Aggregate_Bool_Exp_Avg>;
  corr?: InputMaybe<Subscription_Payments_Aggregate_Bool_Exp_Corr>;
  count?: InputMaybe<Subscription_Payments_Aggregate_Bool_Exp_Count>;
  covar_samp?: InputMaybe<Subscription_Payments_Aggregate_Bool_Exp_Covar_Samp>;
  max?: InputMaybe<Subscription_Payments_Aggregate_Bool_Exp_Max>;
  min?: InputMaybe<Subscription_Payments_Aggregate_Bool_Exp_Min>;
  stddev_samp?: InputMaybe<Subscription_Payments_Aggregate_Bool_Exp_Stddev_Samp>;
  sum?: InputMaybe<Subscription_Payments_Aggregate_Bool_Exp_Sum>;
  var_samp?: InputMaybe<Subscription_Payments_Aggregate_Bool_Exp_Var_Samp>;
};

export type Subscription_Payments_Aggregate_Bool_Exp_Avg = {
  arguments: Subscription_Payments_Select_Column_Subscription_Payments_Aggregate_Bool_Exp_Avg_Arguments_Columns;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<Subscription_Payments_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Subscription_Payments_Aggregate_Bool_Exp_Corr = {
  arguments: Subscription_Payments_Aggregate_Bool_Exp_Corr_Arguments;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<Subscription_Payments_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Subscription_Payments_Aggregate_Bool_Exp_Corr_Arguments = {
  X: Subscription_Payments_Select_Column_Subscription_Payments_Aggregate_Bool_Exp_Corr_Arguments_Columns;
  Y: Subscription_Payments_Select_Column_Subscription_Payments_Aggregate_Bool_Exp_Corr_Arguments_Columns;
};

export type Subscription_Payments_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Subscription_Payments_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<Subscription_Payments_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

export type Subscription_Payments_Aggregate_Bool_Exp_Covar_Samp = {
  arguments: Subscription_Payments_Aggregate_Bool_Exp_Covar_Samp_Arguments;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<Subscription_Payments_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Subscription_Payments_Aggregate_Bool_Exp_Covar_Samp_Arguments = {
  X: Subscription_Payments_Select_Column_Subscription_Payments_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns;
  Y: Subscription_Payments_Select_Column_Subscription_Payments_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns;
};

export type Subscription_Payments_Aggregate_Bool_Exp_Max = {
  arguments: Subscription_Payments_Select_Column_Subscription_Payments_Aggregate_Bool_Exp_Max_Arguments_Columns;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<Subscription_Payments_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Subscription_Payments_Aggregate_Bool_Exp_Min = {
  arguments: Subscription_Payments_Select_Column_Subscription_Payments_Aggregate_Bool_Exp_Min_Arguments_Columns;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<Subscription_Payments_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Subscription_Payments_Aggregate_Bool_Exp_Stddev_Samp = {
  arguments: Subscription_Payments_Select_Column_Subscription_Payments_Aggregate_Bool_Exp_Stddev_Samp_Arguments_Columns;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<Subscription_Payments_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Subscription_Payments_Aggregate_Bool_Exp_Sum = {
  arguments: Subscription_Payments_Select_Column_Subscription_Payments_Aggregate_Bool_Exp_Sum_Arguments_Columns;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<Subscription_Payments_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Subscription_Payments_Aggregate_Bool_Exp_Var_Samp = {
  arguments: Subscription_Payments_Select_Column_Subscription_Payments_Aggregate_Bool_Exp_Var_Samp_Arguments_Columns;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<Subscription_Payments_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

/** aggregate fields of "subscription_payments" */
export type Subscription_Payments_Aggregate_Fields = {
  __typename?: "subscription_payments_aggregate_fields";
  avg?: Maybe<Subscription_Payments_Avg_Fields>;
  count: Scalars["Int"];
  max?: Maybe<Subscription_Payments_Max_Fields>;
  min?: Maybe<Subscription_Payments_Min_Fields>;
  stddev?: Maybe<Subscription_Payments_Stddev_Fields>;
  stddev_pop?: Maybe<Subscription_Payments_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Subscription_Payments_Stddev_Samp_Fields>;
  sum?: Maybe<Subscription_Payments_Sum_Fields>;
  var_pop?: Maybe<Subscription_Payments_Var_Pop_Fields>;
  var_samp?: Maybe<Subscription_Payments_Var_Samp_Fields>;
  variance?: Maybe<Subscription_Payments_Variance_Fields>;
};

/** aggregate fields of "subscription_payments" */
export type Subscription_Payments_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Subscription_Payments_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "subscription_payments" */
export type Subscription_Payments_Aggregate_Order_By = {
  avg?: InputMaybe<Subscription_Payments_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Subscription_Payments_Max_Order_By>;
  min?: InputMaybe<Subscription_Payments_Min_Order_By>;
  stddev?: InputMaybe<Subscription_Payments_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Subscription_Payments_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Subscription_Payments_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Subscription_Payments_Sum_Order_By>;
  var_pop?: InputMaybe<Subscription_Payments_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Subscription_Payments_Var_Samp_Order_By>;
  variance?: InputMaybe<Subscription_Payments_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Subscription_Payments_Append_Input = {
  metadata?: InputMaybe<Scalars["jsonb"]>;
};

/** input type for inserting array relation for remote table "subscription_payments" */
export type Subscription_Payments_Arr_Rel_Insert_Input = {
  data: Array<Subscription_Payments_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Subscription_Payments_On_Conflict>;
};

/** aggregate avg on columns */
export type Subscription_Payments_Avg_Fields = {
  __typename?: "subscription_payments_avg_fields";
  amount?: Maybe<Scalars["Float"]>;
  subscription_balance?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "subscription_payments" */
export type Subscription_Payments_Avg_Order_By = {
  amount?: InputMaybe<Order_By>;
  subscription_balance?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "subscription_payments". All fields are combined with a logical 'AND'. */
export type Subscription_Payments_Bool_Exp = {
  _and?: InputMaybe<Array<Subscription_Payments_Bool_Exp>>;
  _not?: InputMaybe<Subscription_Payments_Bool_Exp>;
  _or?: InputMaybe<Array<Subscription_Payments_Bool_Exp>>;
  amount?: InputMaybe<Float8_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  invoice?: InputMaybe<Invoices_Bool_Exp>;
  invoice_id?: InputMaybe<Uuid_Comparison_Exp>;
  ledger_id?: InputMaybe<Uuid_Comparison_Exp>;
  metadata?: InputMaybe<Jsonb_Comparison_Exp>;
  party?: InputMaybe<Parties_Bool_Exp>;
  partyByRecordedBy?: InputMaybe<Parties_Bool_Exp>;
  partyByUpdatedBy?: InputMaybe<Parties_Bool_Exp>;
  party_id?: InputMaybe<Uuid_Comparison_Exp>;
  payment_date?: InputMaybe<Date_Comparison_Exp>;
  payment_service_id?: InputMaybe<String_Comparison_Exp>;
  product_id?: InputMaybe<Uuid_Comparison_Exp>;
  recorded_by?: InputMaybe<Uuid_Comparison_Exp>;
  subscription?: InputMaybe<Subscriptions_Bool_Exp>;
  subscription_balance?: InputMaybe<Float8_Comparison_Exp>;
  subscription_id?: InputMaybe<Uuid_Comparison_Exp>;
  subscription_payment_transactions?: InputMaybe<Subscription_Payment_Transactions_Bool_Exp>;
  subscription_payment_transactions_aggregate?: InputMaybe<Subscription_Payment_Transactions_Aggregate_Bool_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  updated_by?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "subscription_payments" */
export enum Subscription_Payments_Constraint {
  /** unique or primary key constraint on columns "id" */
  SubscriptionPaymentsPkey = "subscription_payments_pkey",
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Subscription_Payments_Delete_At_Path_Input = {
  metadata?: InputMaybe<Array<Scalars["String"]>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Subscription_Payments_Delete_Elem_Input = {
  metadata?: InputMaybe<Scalars["Int"]>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Subscription_Payments_Delete_Key_Input = {
  metadata?: InputMaybe<Scalars["String"]>;
};

/** input type for incrementing numeric columns in table "subscription_payments" */
export type Subscription_Payments_Inc_Input = {
  amount?: InputMaybe<Scalars["float8"]>;
  subscription_balance?: InputMaybe<Scalars["float8"]>;
};

/** input type for inserting data into table "subscription_payments" */
export type Subscription_Payments_Insert_Input = {
  amount?: InputMaybe<Scalars["float8"]>;
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  invoice?: InputMaybe<Invoices_Obj_Rel_Insert_Input>;
  invoice_id?: InputMaybe<Scalars["uuid"]>;
  ledger_id?: InputMaybe<Scalars["uuid"]>;
  metadata?: InputMaybe<Scalars["jsonb"]>;
  party?: InputMaybe<Parties_Obj_Rel_Insert_Input>;
  partyByRecordedBy?: InputMaybe<Parties_Obj_Rel_Insert_Input>;
  partyByUpdatedBy?: InputMaybe<Parties_Obj_Rel_Insert_Input>;
  party_id?: InputMaybe<Scalars["uuid"]>;
  payment_date?: InputMaybe<Scalars["date"]>;
  payment_service_id?: InputMaybe<Scalars["String"]>;
  product_id?: InputMaybe<Scalars["uuid"]>;
  recorded_by?: InputMaybe<Scalars["uuid"]>;
  subscription?: InputMaybe<Subscriptions_Obj_Rel_Insert_Input>;
  subscription_balance?: InputMaybe<Scalars["float8"]>;
  subscription_id?: InputMaybe<Scalars["uuid"]>;
  subscription_payment_transactions?: InputMaybe<Subscription_Payment_Transactions_Arr_Rel_Insert_Input>;
  updated_at?: InputMaybe<Scalars["timestamptz"]>;
  updated_by?: InputMaybe<Scalars["uuid"]>;
};

/** aggregate max on columns */
export type Subscription_Payments_Max_Fields = {
  __typename?: "subscription_payments_max_fields";
  amount?: Maybe<Scalars["float8"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["uuid"]>;
  invoice_id?: Maybe<Scalars["uuid"]>;
  ledger_id?: Maybe<Scalars["uuid"]>;
  party_id?: Maybe<Scalars["uuid"]>;
  payment_date?: Maybe<Scalars["date"]>;
  payment_service_id?: Maybe<Scalars["String"]>;
  product_id?: Maybe<Scalars["uuid"]>;
  recorded_by?: Maybe<Scalars["uuid"]>;
  subscription_balance?: Maybe<Scalars["float8"]>;
  subscription_id?: Maybe<Scalars["uuid"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
  updated_by?: Maybe<Scalars["uuid"]>;
};

/** order by max() on columns of table "subscription_payments" */
export type Subscription_Payments_Max_Order_By = {
  amount?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  invoice_id?: InputMaybe<Order_By>;
  ledger_id?: InputMaybe<Order_By>;
  party_id?: InputMaybe<Order_By>;
  payment_date?: InputMaybe<Order_By>;
  payment_service_id?: InputMaybe<Order_By>;
  product_id?: InputMaybe<Order_By>;
  recorded_by?: InputMaybe<Order_By>;
  subscription_balance?: InputMaybe<Order_By>;
  subscription_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Subscription_Payments_Min_Fields = {
  __typename?: "subscription_payments_min_fields";
  amount?: Maybe<Scalars["float8"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["uuid"]>;
  invoice_id?: Maybe<Scalars["uuid"]>;
  ledger_id?: Maybe<Scalars["uuid"]>;
  party_id?: Maybe<Scalars["uuid"]>;
  payment_date?: Maybe<Scalars["date"]>;
  payment_service_id?: Maybe<Scalars["String"]>;
  product_id?: Maybe<Scalars["uuid"]>;
  recorded_by?: Maybe<Scalars["uuid"]>;
  subscription_balance?: Maybe<Scalars["float8"]>;
  subscription_id?: Maybe<Scalars["uuid"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
  updated_by?: Maybe<Scalars["uuid"]>;
};

/** order by min() on columns of table "subscription_payments" */
export type Subscription_Payments_Min_Order_By = {
  amount?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  invoice_id?: InputMaybe<Order_By>;
  ledger_id?: InputMaybe<Order_By>;
  party_id?: InputMaybe<Order_By>;
  payment_date?: InputMaybe<Order_By>;
  payment_service_id?: InputMaybe<Order_By>;
  product_id?: InputMaybe<Order_By>;
  recorded_by?: InputMaybe<Order_By>;
  subscription_balance?: InputMaybe<Order_By>;
  subscription_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "subscription_payments" */
export type Subscription_Payments_Mutation_Response = {
  __typename?: "subscription_payments_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Subscription_Payments>;
};

/** input type for inserting object relation for remote table "subscription_payments" */
export type Subscription_Payments_Obj_Rel_Insert_Input = {
  data: Subscription_Payments_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Subscription_Payments_On_Conflict>;
};

/** on_conflict condition type for table "subscription_payments" */
export type Subscription_Payments_On_Conflict = {
  constraint: Subscription_Payments_Constraint;
  update_columns?: Array<Subscription_Payments_Update_Column>;
  where?: InputMaybe<Subscription_Payments_Bool_Exp>;
};

/** Ordering options when selecting data from "subscription_payments". */
export type Subscription_Payments_Order_By = {
  amount?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  invoice?: InputMaybe<Invoices_Order_By>;
  invoice_id?: InputMaybe<Order_By>;
  ledger_id?: InputMaybe<Order_By>;
  metadata?: InputMaybe<Order_By>;
  party?: InputMaybe<Parties_Order_By>;
  partyByRecordedBy?: InputMaybe<Parties_Order_By>;
  partyByUpdatedBy?: InputMaybe<Parties_Order_By>;
  party_id?: InputMaybe<Order_By>;
  payment_date?: InputMaybe<Order_By>;
  payment_service_id?: InputMaybe<Order_By>;
  product_id?: InputMaybe<Order_By>;
  recorded_by?: InputMaybe<Order_By>;
  subscription?: InputMaybe<Subscriptions_Order_By>;
  subscription_balance?: InputMaybe<Order_By>;
  subscription_id?: InputMaybe<Order_By>;
  subscription_payment_transactions_aggregate?: InputMaybe<Subscription_Payment_Transactions_Aggregate_Order_By>;
  updated_at?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** primary key columns input for table: subscription_payments */
export type Subscription_Payments_Pk_Columns_Input = {
  id: Scalars["uuid"];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Subscription_Payments_Prepend_Input = {
  metadata?: InputMaybe<Scalars["jsonb"]>;
};

/** select columns of table "subscription_payments" */
export enum Subscription_Payments_Select_Column {
  /** column name */
  Amount = "amount",
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  Id = "id",
  /** column name */
  InvoiceId = "invoice_id",
  /** column name */
  LedgerId = "ledger_id",
  /** column name */
  Metadata = "metadata",
  /** column name */
  PartyId = "party_id",
  /** column name */
  PaymentDate = "payment_date",
  /** column name */
  PaymentServiceId = "payment_service_id",
  /** column name */
  ProductId = "product_id",
  /** column name */
  RecordedBy = "recorded_by",
  /** column name */
  SubscriptionBalance = "subscription_balance",
  /** column name */
  SubscriptionId = "subscription_id",
  /** column name */
  UpdatedAt = "updated_at",
  /** column name */
  UpdatedBy = "updated_by",
}

/** select "subscription_payments_aggregate_bool_exp_avg_arguments_columns" columns of table "subscription_payments" */
export enum Subscription_Payments_Select_Column_Subscription_Payments_Aggregate_Bool_Exp_Avg_Arguments_Columns {
  /** column name */
  Amount = "amount",
  /** column name */
  SubscriptionBalance = "subscription_balance",
}

/** select "subscription_payments_aggregate_bool_exp_corr_arguments_columns" columns of table "subscription_payments" */
export enum Subscription_Payments_Select_Column_Subscription_Payments_Aggregate_Bool_Exp_Corr_Arguments_Columns {
  /** column name */
  Amount = "amount",
  /** column name */
  SubscriptionBalance = "subscription_balance",
}

/** select "subscription_payments_aggregate_bool_exp_covar_samp_arguments_columns" columns of table "subscription_payments" */
export enum Subscription_Payments_Select_Column_Subscription_Payments_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns {
  /** column name */
  Amount = "amount",
  /** column name */
  SubscriptionBalance = "subscription_balance",
}

/** select "subscription_payments_aggregate_bool_exp_max_arguments_columns" columns of table "subscription_payments" */
export enum Subscription_Payments_Select_Column_Subscription_Payments_Aggregate_Bool_Exp_Max_Arguments_Columns {
  /** column name */
  Amount = "amount",
  /** column name */
  SubscriptionBalance = "subscription_balance",
}

/** select "subscription_payments_aggregate_bool_exp_min_arguments_columns" columns of table "subscription_payments" */
export enum Subscription_Payments_Select_Column_Subscription_Payments_Aggregate_Bool_Exp_Min_Arguments_Columns {
  /** column name */
  Amount = "amount",
  /** column name */
  SubscriptionBalance = "subscription_balance",
}

/** select "subscription_payments_aggregate_bool_exp_stddev_samp_arguments_columns" columns of table "subscription_payments" */
export enum Subscription_Payments_Select_Column_Subscription_Payments_Aggregate_Bool_Exp_Stddev_Samp_Arguments_Columns {
  /** column name */
  Amount = "amount",
  /** column name */
  SubscriptionBalance = "subscription_balance",
}

/** select "subscription_payments_aggregate_bool_exp_sum_arguments_columns" columns of table "subscription_payments" */
export enum Subscription_Payments_Select_Column_Subscription_Payments_Aggregate_Bool_Exp_Sum_Arguments_Columns {
  /** column name */
  Amount = "amount",
  /** column name */
  SubscriptionBalance = "subscription_balance",
}

/** select "subscription_payments_aggregate_bool_exp_var_samp_arguments_columns" columns of table "subscription_payments" */
export enum Subscription_Payments_Select_Column_Subscription_Payments_Aggregate_Bool_Exp_Var_Samp_Arguments_Columns {
  /** column name */
  Amount = "amount",
  /** column name */
  SubscriptionBalance = "subscription_balance",
}

/** input type for updating data in table "subscription_payments" */
export type Subscription_Payments_Set_Input = {
  amount?: InputMaybe<Scalars["float8"]>;
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  invoice_id?: InputMaybe<Scalars["uuid"]>;
  ledger_id?: InputMaybe<Scalars["uuid"]>;
  metadata?: InputMaybe<Scalars["jsonb"]>;
  party_id?: InputMaybe<Scalars["uuid"]>;
  payment_date?: InputMaybe<Scalars["date"]>;
  payment_service_id?: InputMaybe<Scalars["String"]>;
  product_id?: InputMaybe<Scalars["uuid"]>;
  recorded_by?: InputMaybe<Scalars["uuid"]>;
  subscription_balance?: InputMaybe<Scalars["float8"]>;
  subscription_id?: InputMaybe<Scalars["uuid"]>;
  updated_at?: InputMaybe<Scalars["timestamptz"]>;
  updated_by?: InputMaybe<Scalars["uuid"]>;
};

/** aggregate stddev on columns */
export type Subscription_Payments_Stddev_Fields = {
  __typename?: "subscription_payments_stddev_fields";
  amount?: Maybe<Scalars["Float"]>;
  subscription_balance?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "subscription_payments" */
export type Subscription_Payments_Stddev_Order_By = {
  amount?: InputMaybe<Order_By>;
  subscription_balance?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Subscription_Payments_Stddev_Pop_Fields = {
  __typename?: "subscription_payments_stddev_pop_fields";
  amount?: Maybe<Scalars["Float"]>;
  subscription_balance?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "subscription_payments" */
export type Subscription_Payments_Stddev_Pop_Order_By = {
  amount?: InputMaybe<Order_By>;
  subscription_balance?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Subscription_Payments_Stddev_Samp_Fields = {
  __typename?: "subscription_payments_stddev_samp_fields";
  amount?: Maybe<Scalars["Float"]>;
  subscription_balance?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "subscription_payments" */
export type Subscription_Payments_Stddev_Samp_Order_By = {
  amount?: InputMaybe<Order_By>;
  subscription_balance?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "subscription_payments" */
export type Subscription_Payments_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Subscription_Payments_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Subscription_Payments_Stream_Cursor_Value_Input = {
  amount?: InputMaybe<Scalars["float8"]>;
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  invoice_id?: InputMaybe<Scalars["uuid"]>;
  ledger_id?: InputMaybe<Scalars["uuid"]>;
  metadata?: InputMaybe<Scalars["jsonb"]>;
  party_id?: InputMaybe<Scalars["uuid"]>;
  payment_date?: InputMaybe<Scalars["date"]>;
  payment_service_id?: InputMaybe<Scalars["String"]>;
  product_id?: InputMaybe<Scalars["uuid"]>;
  recorded_by?: InputMaybe<Scalars["uuid"]>;
  subscription_balance?: InputMaybe<Scalars["float8"]>;
  subscription_id?: InputMaybe<Scalars["uuid"]>;
  updated_at?: InputMaybe<Scalars["timestamptz"]>;
  updated_by?: InputMaybe<Scalars["uuid"]>;
};

/** aggregate sum on columns */
export type Subscription_Payments_Sum_Fields = {
  __typename?: "subscription_payments_sum_fields";
  amount?: Maybe<Scalars["float8"]>;
  subscription_balance?: Maybe<Scalars["float8"]>;
};

/** order by sum() on columns of table "subscription_payments" */
export type Subscription_Payments_Sum_Order_By = {
  amount?: InputMaybe<Order_By>;
  subscription_balance?: InputMaybe<Order_By>;
};

/** update columns of table "subscription_payments" */
export enum Subscription_Payments_Update_Column {
  /** column name */
  Amount = "amount",
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  Id = "id",
  /** column name */
  InvoiceId = "invoice_id",
  /** column name */
  LedgerId = "ledger_id",
  /** column name */
  Metadata = "metadata",
  /** column name */
  PartyId = "party_id",
  /** column name */
  PaymentDate = "payment_date",
  /** column name */
  PaymentServiceId = "payment_service_id",
  /** column name */
  ProductId = "product_id",
  /** column name */
  RecordedBy = "recorded_by",
  /** column name */
  SubscriptionBalance = "subscription_balance",
  /** column name */
  SubscriptionId = "subscription_id",
  /** column name */
  UpdatedAt = "updated_at",
  /** column name */
  UpdatedBy = "updated_by",
}

export type Subscription_Payments_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Subscription_Payments_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Subscription_Payments_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Subscription_Payments_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Subscription_Payments_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Subscription_Payments_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Subscription_Payments_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Subscription_Payments_Set_Input>;
  /** filter the rows which have to be updated */
  where: Subscription_Payments_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Subscription_Payments_Var_Pop_Fields = {
  __typename?: "subscription_payments_var_pop_fields";
  amount?: Maybe<Scalars["Float"]>;
  subscription_balance?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "subscription_payments" */
export type Subscription_Payments_Var_Pop_Order_By = {
  amount?: InputMaybe<Order_By>;
  subscription_balance?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Subscription_Payments_Var_Samp_Fields = {
  __typename?: "subscription_payments_var_samp_fields";
  amount?: Maybe<Scalars["Float"]>;
  subscription_balance?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "subscription_payments" */
export type Subscription_Payments_Var_Samp_Order_By = {
  amount?: InputMaybe<Order_By>;
  subscription_balance?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Subscription_Payments_Variance_Fields = {
  __typename?: "subscription_payments_variance_fields";
  amount?: Maybe<Scalars["Float"]>;
  subscription_balance?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "subscription_payments" */
export type Subscription_Payments_Variance_Order_By = {
  amount?: InputMaybe<Order_By>;
  subscription_balance?: InputMaybe<Order_By>;
};

export type Subscription_Root = {
  __typename?: "subscription_root";
  /** fetch data from the table: "_case_dod_data" */
  _case_dod_data: Array<_Case_Dod_Data>;
  /** fetch aggregated fields from the table: "_case_dod_data" */
  _case_dod_data_aggregate: _Case_Dod_Data_Aggregate;
  /** fetch data from the table in a streaming manner: "_case_dod_data" */
  _case_dod_data_stream: Array<_Case_Dod_Data>;
  /** fetch data from the table: "_closed_cases_dod_data" */
  _closed_cases_dod_data: Array<_Closed_Cases_Dod_Data>;
  /** fetch aggregated fields from the table: "_closed_cases_dod_data" */
  _closed_cases_dod_data_aggregate: _Closed_Cases_Dod_Data_Aggregate;
  /** fetch data from the table in a streaming manner: "_closed_cases_dod_data" */
  _closed_cases_dod_data_stream: Array<_Closed_Cases_Dod_Data>;
  /** fetch data from the table: "_cus_growth_data" */
  _cus_growth_data: Array<_Cus_Growth_Data>;
  /** fetch aggregated fields from the table: "_cus_growth_data" */
  _cus_growth_data_aggregate: _Cus_Growth_Data_Aggregate;
  /** fetch data from the table in a streaming manner: "_cus_growth_data" */
  _cus_growth_data_stream: Array<_Cus_Growth_Data>;
  /** fetch data from the table: "active_product_subscriptions" */
  active_product_subscriptions: Array<Active_Product_Subscriptions>;
  /** fetch aggregated fields from the table: "active_product_subscriptions" */
  active_product_subscriptions_aggregate: Active_Product_Subscriptions_Aggregate;
  /** fetch data from the table in a streaming manner: "active_product_subscriptions" */
  active_product_subscriptions_stream: Array<Active_Product_Subscriptions>;
  /** An array relationship */
  activity_logs: Array<Activity_Logs>;
  /** An aggregate relationship */
  activity_logs_aggregate: Activity_Logs_Aggregate;
  /** fetch data from the table: "activity_logs" using primary key columns */
  activity_logs_by_pk?: Maybe<Activity_Logs>;
  /** fetch data from the table in a streaming manner: "activity_logs" */
  activity_logs_stream: Array<Activity_Logs>;
  /** An array relationship */
  audit_logs: Array<Audit_Logs>;
  /** An aggregate relationship */
  audit_logs_aggregate: Audit_Logs_Aggregate;
  /** fetch data from the table: "audit_logs" using primary key columns */
  audit_logs_by_pk?: Maybe<Audit_Logs>;
  /** fetch data from the table in a streaming manner: "audit_logs" */
  audit_logs_stream: Array<Audit_Logs>;
  /** An array relationship */
  campaign_contacts: Array<Campaign_Contacts>;
  /** An aggregate relationship */
  campaign_contacts_aggregate: Campaign_Contacts_Aggregate;
  /** fetch data from the table: "campaign_contacts" using primary key columns */
  campaign_contacts_by_pk?: Maybe<Campaign_Contacts>;
  /** fetch data from the table in a streaming manner: "campaign_contacts" */
  campaign_contacts_stream: Array<Campaign_Contacts>;
  /** An array relationship */
  campaign_performance_indicators: Array<Campaign_Performance_Indicators>;
  /** An aggregate relationship */
  campaign_performance_indicators_aggregate: Campaign_Performance_Indicators_Aggregate;
  /** fetch data from the table: "campaign_performance_indicators" using primary key columns */
  campaign_performance_indicators_by_pk?: Maybe<Campaign_Performance_Indicators>;
  /** fetch data from the table in a streaming manner: "campaign_performance_indicators" */
  campaign_performance_indicators_stream: Array<Campaign_Performance_Indicators>;
  /** An array relationship */
  campaign_rewards: Array<Campaign_Rewards>;
  /** An aggregate relationship */
  campaign_rewards_aggregate: Campaign_Rewards_Aggregate;
  /** fetch data from the table: "campaign_rewards" using primary key columns */
  campaign_rewards_by_pk?: Maybe<Campaign_Rewards>;
  /** fetch data from the table in a streaming manner: "campaign_rewards" */
  campaign_rewards_stream: Array<Campaign_Rewards>;
  /** An array relationship */
  campaign_rules: Array<Campaign_Rules>;
  /** An aggregate relationship */
  campaign_rules_aggregate: Campaign_Rules_Aggregate;
  /** fetch data from the table: "campaign_rules" using primary key columns */
  campaign_rules_by_pk?: Maybe<Campaign_Rules>;
  /** fetch data from the table in a streaming manner: "campaign_rules" */
  campaign_rules_stream: Array<Campaign_Rules>;
  /** An array relationship */
  campaign_tasks: Array<Campaign_Tasks>;
  /** An aggregate relationship */
  campaign_tasks_aggregate: Campaign_Tasks_Aggregate;
  /** fetch data from the table: "campaign_tasks" using primary key columns */
  campaign_tasks_by_pk?: Maybe<Campaign_Tasks>;
  /** fetch data from the table in a streaming manner: "campaign_tasks" */
  campaign_tasks_stream: Array<Campaign_Tasks>;
  /** An array relationship */
  campaign_workflows: Array<Campaign_Workflows>;
  /** An aggregate relationship */
  campaign_workflows_aggregate: Campaign_Workflows_Aggregate;
  /** fetch data from the table: "campaign_workflows" using primary key columns */
  campaign_workflows_by_pk?: Maybe<Campaign_Workflows>;
  /** fetch data from the table in a streaming manner: "campaign_workflows" */
  campaign_workflows_stream: Array<Campaign_Workflows>;
  /** An array relationship */
  campaigns: Array<Campaigns>;
  /** An aggregate relationship */
  campaigns_aggregate: Campaigns_Aggregate;
  /** fetch data from the table: "campaigns" using primary key columns */
  campaigns_by_pk?: Maybe<Campaigns>;
  /** fetch data from the table in a streaming manner: "campaigns" */
  campaigns_stream: Array<Campaigns>;
  /** An array relationship */
  case_assignments: Array<Case_Assignments>;
  /** An aggregate relationship */
  case_assignments_aggregate: Case_Assignments_Aggregate;
  /** fetch data from the table: "case_assignments" using primary key columns */
  case_assignments_by_pk?: Maybe<Case_Assignments>;
  /** fetch data from the table in a streaming manner: "case_assignments" */
  case_assignments_stream: Array<Case_Assignments>;
  /** An array relationship */
  case_details: Array<Case_Details>;
  /** An aggregate relationship */
  case_details_aggregate: Case_Details_Aggregate;
  /** fetch data from the table: "case_details" using primary key columns */
  case_details_by_pk?: Maybe<Case_Details>;
  /** fetch data from the table in a streaming manner: "case_details" */
  case_details_stream: Array<Case_Details>;
  /** An array relationship */
  case_documents: Array<Case_Documents>;
  /** An aggregate relationship */
  case_documents_aggregate: Case_Documents_Aggregate;
  /** fetch data from the table: "case_documents" using primary key columns */
  case_documents_by_pk?: Maybe<Case_Documents>;
  /** fetch data from the table in a streaming manner: "case_documents" */
  case_documents_stream: Array<Case_Documents>;
  /** An array relationship */
  case_flags: Array<Case_Flags>;
  /** An aggregate relationship */
  case_flags_aggregate: Case_Flags_Aggregate;
  /** fetch data from the table: "case_flags" using primary key columns */
  case_flags_by_pk?: Maybe<Case_Flags>;
  /** fetch data from the table in a streaming manner: "case_flags" */
  case_flags_stream: Array<Case_Flags>;
  /** An array relationship */
  case_interactions: Array<Case_Interactions>;
  /** An aggregate relationship */
  case_interactions_aggregate: Case_Interactions_Aggregate;
  /** fetch data from the table: "case_interactions" using primary key columns */
  case_interactions_by_pk?: Maybe<Case_Interactions>;
  /** fetch data from the table in a streaming manner: "case_interactions" */
  case_interactions_stream: Array<Case_Interactions>;
  /** An array relationship */
  case_mentions: Array<Case_Mentions>;
  /** An aggregate relationship */
  case_mentions_aggregate: Case_Mentions_Aggregate;
  /** fetch data from the table: "case_mentions" using primary key columns */
  case_mentions_by_pk?: Maybe<Case_Mentions>;
  /** fetch data from the table in a streaming manner: "case_mentions" */
  case_mentions_stream: Array<Case_Mentions>;
  /** An array relationship */
  case_statuses: Array<Case_Statuses>;
  /** An aggregate relationship */
  case_statuses_aggregate: Case_Statuses_Aggregate;
  /** fetch data from the table: "case_statuses" using primary key columns */
  case_statuses_by_pk?: Maybe<Case_Statuses>;
  /** fetch data from the table in a streaming manner: "case_statuses" */
  case_statuses_stream: Array<Case_Statuses>;
  /** An array relationship */
  cases: Array<Cases>;
  /** An aggregate relationship */
  cases_aggregate: Cases_Aggregate;
  /** fetch data from the table: "cases" using primary key columns */
  cases_by_pk?: Maybe<Cases>;
  /** fetch data from the table in a streaming manner: "cases" */
  cases_stream: Array<Cases>;
  /** An array relationship */
  channel_flags: Array<Channel_Flags>;
  /** An aggregate relationship */
  channel_flags_aggregate: Channel_Flags_Aggregate;
  /** fetch data from the table: "channel_flags" using primary key columns */
  channel_flags_by_pk?: Maybe<Channel_Flags>;
  /** fetch data from the table in a streaming manner: "channel_flags" */
  channel_flags_stream: Array<Channel_Flags>;
  /** An array relationship */
  channel_parties: Array<Channel_Parties>;
  /** An aggregate relationship */
  channel_parties_aggregate: Channel_Parties_Aggregate;
  /** fetch data from the table: "channel_parties" using primary key columns */
  channel_parties_by_pk?: Maybe<Channel_Parties>;
  /** fetch data from the table in a streaming manner: "channel_parties" */
  channel_parties_stream: Array<Channel_Parties>;
  /** An array relationship */
  channel_statuses: Array<Channel_Statuses>;
  /** An aggregate relationship */
  channel_statuses_aggregate: Channel_Statuses_Aggregate;
  /** fetch data from the table: "channel_statuses" using primary key columns */
  channel_statuses_by_pk?: Maybe<Channel_Statuses>;
  /** fetch data from the table in a streaming manner: "channel_statuses" */
  channel_statuses_stream: Array<Channel_Statuses>;
  /** An array relationship */
  channel_subscriptions: Array<Channel_Subscriptions>;
  /** An aggregate relationship */
  channel_subscriptions_aggregate: Channel_Subscriptions_Aggregate;
  /** fetch data from the table: "channel_subscriptions" using primary key columns */
  channel_subscriptions_by_pk?: Maybe<Channel_Subscriptions>;
  /** fetch data from the table in a streaming manner: "channel_subscriptions" */
  channel_subscriptions_stream: Array<Channel_Subscriptions>;
  /** fetch data from the table: "channels" */
  channels: Array<Channels>;
  /** fetch aggregated fields from the table: "channels" */
  channels_aggregate: Channels_Aggregate;
  /** fetch data from the table: "channels" using primary key columns */
  channels_by_pk?: Maybe<Channels>;
  /** fetch data from the table in a streaming manner: "channels" */
  channels_stream: Array<Channels>;
  /** execute function "closed_cases_daily" which returns "_closed_cases_dod_data" */
  closed_cases_daily: Array<_Closed_Cases_Dod_Data>;
  /** execute function "closed_cases_daily" and query aggregates on result of table type "_closed_cases_dod_data" */
  closed_cases_daily_aggregate: _Closed_Cases_Dod_Data_Aggregate;
  /** An array relationship */
  conditions: Array<Conditions>;
  /** An aggregate relationship */
  conditions_aggregate: Conditions_Aggregate;
  /** fetch data from the table: "conditions" using primary key columns */
  conditions_by_pk?: Maybe<Conditions>;
  /** fetch data from the table in a streaming manner: "conditions" */
  conditions_stream: Array<Conditions>;
  /** An array relationship */
  contact_details: Array<Contact_Details>;
  /** An aggregate relationship */
  contact_details_aggregate: Contact_Details_Aggregate;
  /** fetch data from the table: "contact_details" using primary key columns */
  contact_details_by_pk?: Maybe<Contact_Details>;
  /** fetch data from the table in a streaming manner: "contact_details" */
  contact_details_stream: Array<Contact_Details>;
  /** An array relationship */
  contact_interactions: Array<Contact_Interactions>;
  /** An aggregate relationship */
  contact_interactions_aggregate: Contact_Interactions_Aggregate;
  /** fetch data from the table: "contact_interactions" using primary key columns */
  contact_interactions_by_pk?: Maybe<Contact_Interactions>;
  /** fetch data from the table in a streaming manner: "contact_interactions" */
  contact_interactions_stream: Array<Contact_Interactions>;
  /** An array relationship */
  contact_relationships: Array<Contact_Relationships>;
  /** An aggregate relationship */
  contact_relationships_aggregate: Contact_Relationships_Aggregate;
  /** fetch data from the table: "contact_relationships" using primary key columns */
  contact_relationships_by_pk?: Maybe<Contact_Relationships>;
  /** fetch data from the table in a streaming manner: "contact_relationships" */
  contact_relationships_stream: Array<Contact_Relationships>;
  /** An array relationship */
  contacts: Array<Contacts>;
  /** An aggregate relationship */
  contacts_aggregate: Contacts_Aggregate;
  /** fetch data from the table: "contacts" using primary key columns */
  contacts_by_pk?: Maybe<Contacts>;
  /** fetch data from the table in a streaming manner: "contacts" */
  contacts_stream: Array<Contacts>;
  /** An array relationship */
  credit_profile_flags: Array<Credit_Profile_Flags>;
  /** An aggregate relationship */
  credit_profile_flags_aggregate: Credit_Profile_Flags_Aggregate;
  /** fetch data from the table: "credit_profile_flags" using primary key columns */
  credit_profile_flags_by_pk?: Maybe<Credit_Profile_Flags>;
  /** fetch data from the table in a streaming manner: "credit_profile_flags" */
  credit_profile_flags_stream: Array<Credit_Profile_Flags>;
  /** An array relationship */
  credit_profile_statuses: Array<Credit_Profile_Statuses>;
  /** An aggregate relationship */
  credit_profile_statuses_aggregate: Credit_Profile_Statuses_Aggregate;
  /** fetch data from the table: "credit_profile_statuses" using primary key columns */
  credit_profile_statuses_by_pk?: Maybe<Credit_Profile_Statuses>;
  /** fetch data from the table in a streaming manner: "credit_profile_statuses" */
  credit_profile_statuses_stream: Array<Credit_Profile_Statuses>;
  /** An array relationship */
  credit_profiles: Array<Credit_Profiles>;
  /** An aggregate relationship */
  credit_profiles_aggregate: Credit_Profiles_Aggregate;
  /** fetch data from the table: "credit_profiles" using primary key columns */
  credit_profiles_by_pk?: Maybe<Credit_Profiles>;
  /** fetch data from the table in a streaming manner: "credit_profiles" */
  credit_profiles_stream: Array<Credit_Profiles>;
  /** execute function "customer_daily_growth" which returns "_cus_growth_data" */
  customer_daily_growth: Array<_Cus_Growth_Data>;
  /** execute function "customer_daily_growth" and query aggregates on result of table type "_cus_growth_data" */
  customer_daily_growth_aggregate: _Cus_Growth_Data_Aggregate;
  /** fetch data from the table: "delivery_methods" */
  delivery_methods: Array<Delivery_Methods>;
  /** fetch aggregated fields from the table: "delivery_methods" */
  delivery_methods_aggregate: Delivery_Methods_Aggregate;
  /** fetch data from the table: "delivery_methods" using primary key columns */
  delivery_methods_by_pk?: Maybe<Delivery_Methods>;
  /** fetch data from the table in a streaming manner: "delivery_methods" */
  delivery_methods_stream: Array<Delivery_Methods>;
  /** An array relationship */
  document_interactions: Array<Document_Interactions>;
  /** An aggregate relationship */
  document_interactions_aggregate: Document_Interactions_Aggregate;
  /** fetch data from the table: "document_interactions" using primary key columns */
  document_interactions_by_pk?: Maybe<Document_Interactions>;
  /** fetch data from the table in a streaming manner: "document_interactions" */
  document_interactions_stream: Array<Document_Interactions>;
  /** An array relationship */
  documents: Array<Documents>;
  /** An aggregate relationship */
  documents_aggregate: Documents_Aggregate;
  /** fetch data from the table: "documents" using primary key columns */
  documents_by_pk?: Maybe<Documents>;
  /** fetch data from the table in a streaming manner: "documents" */
  documents_stream: Array<Documents>;
  /** fetch data from the table: "flags" */
  flags: Array<Flags>;
  /** fetch aggregated fields from the table: "flags" */
  flags_aggregate: Flags_Aggregate;
  /** fetch data from the table: "flags" using primary key columns */
  flags_by_pk?: Maybe<Flags>;
  /** fetch data from the table in a streaming manner: "flags" */
  flags_stream: Array<Flags>;
  /** An array relationship */
  form_fields: Array<Form_Fields>;
  /** An aggregate relationship */
  form_fields_aggregate: Form_Fields_Aggregate;
  /** fetch data from the table: "form_fields" using primary key columns */
  form_fields_by_pk?: Maybe<Form_Fields>;
  /** fetch data from the table in a streaming manner: "form_fields" */
  form_fields_stream: Array<Form_Fields>;
  /** An array relationship */
  form_rules: Array<Form_Rules>;
  /** An aggregate relationship */
  form_rules_aggregate: Form_Rules_Aggregate;
  /** fetch data from the table: "form_rules" using primary key columns */
  form_rules_by_pk?: Maybe<Form_Rules>;
  /** fetch data from the table in a streaming manner: "form_rules" */
  form_rules_stream: Array<Form_Rules>;
  /** An array relationship */
  forms: Array<Forms>;
  /** An aggregate relationship */
  forms_aggregate: Forms_Aggregate;
  /** fetch data from the table: "forms" using primary key columns */
  forms_by_pk?: Maybe<Forms>;
  /** fetch data from the table in a streaming manner: "forms" */
  forms_stream: Array<Forms>;
  /** execute function "get_party_channel" which returns "channel_parties" */
  get_party_channel: Array<Channel_Parties>;
  /** execute function "get_party_channel" and query aggregates on result of table type "channel_parties" */
  get_party_channel_aggregate: Channel_Parties_Aggregate;
  /** fetch data from the table: "interaction_details" */
  interaction_details: Array<Interaction_Details>;
  /** fetch aggregated fields from the table: "interaction_details" */
  interaction_details_aggregate: Interaction_Details_Aggregate;
  /** fetch data from the table: "interaction_details" using primary key columns */
  interaction_details_by_pk?: Maybe<Interaction_Details>;
  /** fetch data from the table in a streaming manner: "interaction_details" */
  interaction_details_stream: Array<Interaction_Details>;
  /** An array relationship */
  interactions: Array<Interactions>;
  /** An aggregate relationship */
  interactions_aggregate: Interactions_Aggregate;
  /** fetch data from the table: "interactions" using primary key columns */
  interactions_by_pk?: Maybe<Interactions>;
  /** fetch data from the table in a streaming manner: "interactions" */
  interactions_stream: Array<Interactions>;
  /** An array relationship */
  invoice_interactions: Array<Invoice_Interactions>;
  /** An aggregate relationship */
  invoice_interactions_aggregate: Invoice_Interactions_Aggregate;
  /** fetch data from the table: "invoice_interactions" using primary key columns */
  invoice_interactions_by_pk?: Maybe<Invoice_Interactions>;
  /** fetch data from the table in a streaming manner: "invoice_interactions" */
  invoice_interactions_stream: Array<Invoice_Interactions>;
  /** An array relationship */
  invoice_items: Array<Invoice_Items>;
  /** An aggregate relationship */
  invoice_items_aggregate: Invoice_Items_Aggregate;
  /** fetch data from the table: "invoice_items" using primary key columns */
  invoice_items_by_pk?: Maybe<Invoice_Items>;
  /** fetch data from the table in a streaming manner: "invoice_items" */
  invoice_items_stream: Array<Invoice_Items>;
  /** An array relationship */
  invoices: Array<Invoices>;
  /** An aggregate relationship */
  invoices_aggregate: Invoices_Aggregate;
  /** fetch data from the table: "invoices" using primary key columns */
  invoices_by_pk?: Maybe<Invoices>;
  /** fetch data from the table in a streaming manner: "invoices" */
  invoices_stream: Array<Invoices>;
  /** An array relationship */
  message_delivery_methods: Array<Message_Delivery_Methods>;
  /** An aggregate relationship */
  message_delivery_methods_aggregate: Message_Delivery_Methods_Aggregate;
  /** fetch data from the table: "message_delivery_methods" using primary key columns */
  message_delivery_methods_by_pk?: Maybe<Message_Delivery_Methods>;
  /** fetch data from the table in a streaming manner: "message_delivery_methods" */
  message_delivery_methods_stream: Array<Message_Delivery_Methods>;
  /** An array relationship */
  message_delivery_responses: Array<Message_Delivery_Responses>;
  /** An aggregate relationship */
  message_delivery_responses_aggregate: Message_Delivery_Responses_Aggregate;
  /** fetch data from the table: "message_delivery_responses" using primary key columns */
  message_delivery_responses_by_pk?: Maybe<Message_Delivery_Responses>;
  /** fetch data from the table in a streaming manner: "message_delivery_responses" */
  message_delivery_responses_stream: Array<Message_Delivery_Responses>;
  /** An array relationship */
  message_flags: Array<Message_Flags>;
  /** An aggregate relationship */
  message_flags_aggregate: Message_Flags_Aggregate;
  /** fetch data from the table: "message_flags" using primary key columns */
  message_flags_by_pk?: Maybe<Message_Flags>;
  /** fetch data from the table in a streaming manner: "message_flags" */
  message_flags_stream: Array<Message_Flags>;
  /** An array relationship */
  message_statuses: Array<Message_Statuses>;
  /** An aggregate relationship */
  message_statuses_aggregate: Message_Statuses_Aggregate;
  /** fetch data from the table: "message_statuses" using primary key columns */
  message_statuses_by_pk?: Maybe<Message_Statuses>;
  /** fetch data from the table in a streaming manner: "message_statuses" */
  message_statuses_stream: Array<Message_Statuses>;
  /** An array relationship */
  messages: Array<Messages>;
  /** An aggregate relationship */
  messages_aggregate: Messages_Aggregate;
  /** fetch data from the table: "messages" using primary key columns */
  messages_by_pk?: Maybe<Messages>;
  /** fetch data from the table in a streaming manner: "messages" */
  messages_stream: Array<Messages>;
  /** execute function "new_cases_daily_growth" which returns "_case_dod_data" */
  new_cases_daily_growth: Array<_Case_Dod_Data>;
  /** execute function "new_cases_daily_growth" and query aggregates on result of table type "_case_dod_data" */
  new_cases_daily_growth_aggregate: _Case_Dod_Data_Aggregate;
  /** fetch data from the table: "organisation_credentials" */
  organisation_credentials: Array<Organisation_Credentials>;
  /** fetch aggregated fields from the table: "organisation_credentials" */
  organisation_credentials_aggregate: Organisation_Credentials_Aggregate;
  /** fetch data from the table: "organisation_credentials" using primary key columns */
  organisation_credentials_by_pk?: Maybe<Organisation_Credentials>;
  /** fetch data from the table in a streaming manner: "organisation_credentials" */
  organisation_credentials_stream: Array<Organisation_Credentials>;
  /** fetch data from the table: "organisation_flags" */
  organisation_flags: Array<Organisation_Flags>;
  /** fetch aggregated fields from the table: "organisation_flags" */
  organisation_flags_aggregate: Organisation_Flags_Aggregate;
  /** fetch data from the table: "organisation_flags" using primary key columns */
  organisation_flags_by_pk?: Maybe<Organisation_Flags>;
  /** fetch data from the table in a streaming manner: "organisation_flags" */
  organisation_flags_stream: Array<Organisation_Flags>;
  /** fetch data from the table: "organisation_invites" */
  organisation_invites: Array<Organisation_Invites>;
  /** fetch aggregated fields from the table: "organisation_invites" */
  organisation_invites_aggregate: Organisation_Invites_Aggregate;
  /** fetch data from the table: "organisation_invites" using primary key columns */
  organisation_invites_by_pk?: Maybe<Organisation_Invites>;
  /** fetch data from the table in a streaming manner: "organisation_invites" */
  organisation_invites_stream: Array<Organisation_Invites>;
  /** fetch data from the table: "organisation_rules" */
  organisation_rules: Array<Organisation_Rules>;
  /** fetch aggregated fields from the table: "organisation_rules" */
  organisation_rules_aggregate: Organisation_Rules_Aggregate;
  /** fetch data from the table: "organisation_rules" using primary key columns */
  organisation_rules_by_pk?: Maybe<Organisation_Rules>;
  /** fetch data from the table in a streaming manner: "organisation_rules" */
  organisation_rules_stream: Array<Organisation_Rules>;
  /** An array relationship */
  organisation_settings: Array<Organisation_Settings>;
  /** An aggregate relationship */
  organisation_settings_aggregate: Organisation_Settings_Aggregate;
  /** fetch data from the table: "organisation_settings" using primary key columns */
  organisation_settings_by_pk?: Maybe<Organisation_Settings>;
  /** fetch data from the table in a streaming manner: "organisation_settings" */
  organisation_settings_stream: Array<Organisation_Settings>;
  /** fetch data from the table: "organisation_statuses" */
  organisation_statuses: Array<Organisation_Statuses>;
  /** fetch aggregated fields from the table: "organisation_statuses" */
  organisation_statuses_aggregate: Organisation_Statuses_Aggregate;
  /** fetch data from the table: "organisation_statuses" using primary key columns */
  organisation_statuses_by_pk?: Maybe<Organisation_Statuses>;
  /** fetch data from the table in a streaming manner: "organisation_statuses" */
  organisation_statuses_stream: Array<Organisation_Statuses>;
  /** fetch data from the table: "organisations" */
  organisations: Array<Organisations>;
  /** fetch aggregated fields from the table: "organisations" */
  organisations_aggregate: Organisations_Aggregate;
  /** fetch data from the table: "organisations" using primary key columns */
  organisations_by_pk?: Maybe<Organisations>;
  /** fetch data from the table in a streaming manner: "organisations" */
  organisations_stream: Array<Organisations>;
  /** An array relationship */
  parties: Array<Parties>;
  /** An aggregate relationship */
  parties_aggregate: Parties_Aggregate;
  /** fetch data from the table: "parties" using primary key columns */
  parties_by_pk?: Maybe<Parties>;
  /** fetch data from the table in a streaming manner: "parties" */
  parties_stream: Array<Parties>;
  /** An array relationship */
  party_contacts: Array<Party_Contacts>;
  /** An aggregate relationship */
  party_contacts_aggregate: Party_Contacts_Aggregate;
  /** fetch data from the table: "party_contacts" using primary key columns */
  party_contacts_by_pk?: Maybe<Party_Contacts>;
  /** fetch data from the table in a streaming manner: "party_contacts" */
  party_contacts_stream: Array<Party_Contacts>;
  /** An array relationship */
  party_documents: Array<Party_Documents>;
  /** An aggregate relationship */
  party_documents_aggregate: Party_Documents_Aggregate;
  /** fetch data from the table: "party_documents" using primary key columns */
  party_documents_by_pk?: Maybe<Party_Documents>;
  /** fetch data from the table in a streaming manner: "party_documents" */
  party_documents_stream: Array<Party_Documents>;
  /** An array relationship */
  party_flags: Array<Party_Flags>;
  /** An aggregate relationship */
  party_flags_aggregate: Party_Flags_Aggregate;
  /** fetch data from the table: "party_flags" using primary key columns */
  party_flags_by_pk?: Maybe<Party_Flags>;
  /** fetch data from the table in a streaming manner: "party_flags" */
  party_flags_stream: Array<Party_Flags>;
  /** An array relationship */
  party_interactions: Array<Party_Interactions>;
  /** An aggregate relationship */
  party_interactions_aggregate: Party_Interactions_Aggregate;
  /** fetch data from the table: "party_interactions" using primary key columns */
  party_interactions_by_pk?: Maybe<Party_Interactions>;
  /** fetch data from the table in a streaming manner: "party_interactions" */
  party_interactions_stream: Array<Party_Interactions>;
  /** An array relationship */
  party_ledger_accounts: Array<Party_Ledger_Accounts>;
  /** An aggregate relationship */
  party_ledger_accounts_aggregate: Party_Ledger_Accounts_Aggregate;
  /** fetch data from the table: "party_ledger_accounts" using primary key columns */
  party_ledger_accounts_by_pk?: Maybe<Party_Ledger_Accounts>;
  /** fetch data from the table in a streaming manner: "party_ledger_accounts" */
  party_ledger_accounts_stream: Array<Party_Ledger_Accounts>;
  /** An array relationship */
  party_relationship_statuses: Array<Party_Relationship_Statuses>;
  /** An aggregate relationship */
  party_relationship_statuses_aggregate: Party_Relationship_Statuses_Aggregate;
  /** fetch data from the table: "party_relationship_statuses" using primary key columns */
  party_relationship_statuses_by_pk?: Maybe<Party_Relationship_Statuses>;
  /** fetch data from the table in a streaming manner: "party_relationship_statuses" */
  party_relationship_statuses_stream: Array<Party_Relationship_Statuses>;
  /** An array relationship */
  party_relationships: Array<Party_Relationships>;
  /** An aggregate relationship */
  party_relationships_aggregate: Party_Relationships_Aggregate;
  /** fetch data from the table: "party_relationships" using primary key columns */
  party_relationships_by_pk?: Maybe<Party_Relationships>;
  /** fetch data from the table in a streaming manner: "party_relationships" */
  party_relationships_stream: Array<Party_Relationships>;
  /** An array relationship */
  party_settings: Array<Party_Settings>;
  /** An aggregate relationship */
  party_settings_aggregate: Party_Settings_Aggregate;
  /** fetch data from the table: "party_settings" using primary key columns */
  party_settings_by_pk?: Maybe<Party_Settings>;
  /** fetch data from the table in a streaming manner: "party_settings" */
  party_settings_stream: Array<Party_Settings>;
  /** An array relationship */
  party_statuses: Array<Party_Statuses>;
  /** An aggregate relationship */
  party_statuses_aggregate: Party_Statuses_Aggregate;
  /** fetch data from the table: "party_statuses" using primary key columns */
  party_statuses_by_pk?: Maybe<Party_Statuses>;
  /** fetch data from the table in a streaming manner: "party_statuses" */
  party_statuses_stream: Array<Party_Statuses>;
  /** An array relationship */
  performance_indicators: Array<Performance_Indicators>;
  /** An aggregate relationship */
  performance_indicators_aggregate: Performance_Indicators_Aggregate;
  /** fetch data from the table: "performance_indicators" using primary key columns */
  performance_indicators_by_pk?: Maybe<Performance_Indicators>;
  /** fetch data from the table in a streaming manner: "performance_indicators" */
  performance_indicators_stream: Array<Performance_Indicators>;
  /** An array relationship */
  performance_targets: Array<Performance_Targets>;
  /** An aggregate relationship */
  performance_targets_aggregate: Performance_Targets_Aggregate;
  /** fetch data from the table: "performance_targets" using primary key columns */
  performance_targets_by_pk?: Maybe<Performance_Targets>;
  /** fetch data from the table in a streaming manner: "performance_targets" */
  performance_targets_stream: Array<Performance_Targets>;
  /** An array relationship */
  product_documents: Array<Product_Documents>;
  /** An aggregate relationship */
  product_documents_aggregate: Product_Documents_Aggregate;
  /** fetch data from the table: "product_documents" using primary key columns */
  product_documents_by_pk?: Maybe<Product_Documents>;
  /** fetch data from the table in a streaming manner: "product_documents" */
  product_documents_stream: Array<Product_Documents>;
  /** An array relationship */
  product_interactions: Array<Product_Interactions>;
  /** An aggregate relationship */
  product_interactions_aggregate: Product_Interactions_Aggregate;
  /** fetch data from the table: "product_interactions" using primary key columns */
  product_interactions_by_pk?: Maybe<Product_Interactions>;
  /** fetch data from the table in a streaming manner: "product_interactions" */
  product_interactions_stream: Array<Product_Interactions>;
  /** An array relationship */
  product_items: Array<Product_Items>;
  /** An aggregate relationship */
  product_items_aggregate: Product_Items_Aggregate;
  /** fetch data from the table: "product_items" using primary key columns */
  product_items_by_pk?: Maybe<Product_Items>;
  /** fetch data from the table in a streaming manner: "product_items" */
  product_items_stream: Array<Product_Items>;
  /** An array relationship */
  product_rules: Array<Product_Rules>;
  /** An aggregate relationship */
  product_rules_aggregate: Product_Rules_Aggregate;
  /** fetch data from the table: "product_rules" using primary key columns */
  product_rules_by_pk?: Maybe<Product_Rules>;
  /** fetch data from the table in a streaming manner: "product_rules" */
  product_rules_stream: Array<Product_Rules>;
  /** An array relationship */
  product_settings: Array<Product_Settings>;
  /** An aggregate relationship */
  product_settings_aggregate: Product_Settings_Aggregate;
  /** fetch data from the table: "product_settings" using primary key columns */
  product_settings_by_pk?: Maybe<Product_Settings>;
  /** fetch data from the table in a streaming manner: "product_settings" */
  product_settings_stream: Array<Product_Settings>;
  /** An array relationship */
  product_statuses: Array<Product_Statuses>;
  /** An aggregate relationship */
  product_statuses_aggregate: Product_Statuses_Aggregate;
  /** fetch data from the table: "product_statuses" using primary key columns */
  product_statuses_by_pk?: Maybe<Product_Statuses>;
  /** fetch data from the table in a streaming manner: "product_statuses" */
  product_statuses_stream: Array<Product_Statuses>;
  /** fetch data from the table: "product_templates" */
  product_templates: Array<Product_Templates>;
  /** fetch aggregated fields from the table: "product_templates" */
  product_templates_aggregate: Product_Templates_Aggregate;
  /** fetch data from the table: "product_templates" using primary key columns */
  product_templates_by_pk?: Maybe<Product_Templates>;
  /** fetch data from the table in a streaming manner: "product_templates" */
  product_templates_stream: Array<Product_Templates>;
  /** An array relationship */
  products: Array<Products>;
  /** An aggregate relationship */
  products_aggregate: Products_Aggregate;
  /** fetch data from the table: "products" using primary key columns */
  products_by_pk?: Maybe<Products>;
  /** fetch data from the table in a streaming manner: "products" */
  products_stream: Array<Products>;
  /** An array relationship */
  reward_redemptions: Array<Reward_Redemptions>;
  /** An aggregate relationship */
  reward_redemptions_aggregate: Reward_Redemptions_Aggregate;
  /** fetch data from the table: "reward_redemptions" using primary key columns */
  reward_redemptions_by_pk?: Maybe<Reward_Redemptions>;
  /** fetch data from the table in a streaming manner: "reward_redemptions" */
  reward_redemptions_stream: Array<Reward_Redemptions>;
  /** fetch data from the table: "rewards" */
  rewards: Array<Rewards>;
  /** fetch aggregated fields from the table: "rewards" */
  rewards_aggregate: Rewards_Aggregate;
  /** fetch data from the table: "rewards" using primary key columns */
  rewards_by_pk?: Maybe<Rewards>;
  /** fetch data from the table in a streaming manner: "rewards" */
  rewards_stream: Array<Rewards>;
  /** fetch data from the table: "rules" */
  rules: Array<Rules>;
  /** fetch aggregated fields from the table: "rules" */
  rules_aggregate: Rules_Aggregate;
  /** fetch data from the table: "rules" using primary key columns */
  rules_by_pk?: Maybe<Rules>;
  /** fetch data from the table in a streaming manner: "rules" */
  rules_stream: Array<Rules>;
  /** fetch data from the table: "statuses" */
  statuses: Array<Statuses>;
  /** fetch aggregated fields from the table: "statuses" */
  statuses_aggregate: Statuses_Aggregate;
  /** fetch data from the table: "statuses" using primary key columns */
  statuses_by_pk?: Maybe<Statuses>;
  /** fetch data from the table in a streaming manner: "statuses" */
  statuses_stream: Array<Statuses>;
  /** An array relationship */
  subscription_cases: Array<Subscription_Cases>;
  /** An aggregate relationship */
  subscription_cases_aggregate: Subscription_Cases_Aggregate;
  /** fetch data from the table: "subscription_cases" using primary key columns */
  subscription_cases_by_pk?: Maybe<Subscription_Cases>;
  /** fetch data from the table in a streaming manner: "subscription_cases" */
  subscription_cases_stream: Array<Subscription_Cases>;
  /** An array relationship */
  subscription_details: Array<Subscription_Details>;
  /** An aggregate relationship */
  subscription_details_aggregate: Subscription_Details_Aggregate;
  /** fetch data from the table: "subscription_details" using primary key columns */
  subscription_details_by_pk?: Maybe<Subscription_Details>;
  /** fetch data from the table in a streaming manner: "subscription_details" */
  subscription_details_stream: Array<Subscription_Details>;
  /** An array relationship */
  subscription_documents: Array<Subscription_Documents>;
  /** An aggregate relationship */
  subscription_documents_aggregate: Subscription_Documents_Aggregate;
  /** fetch data from the table: "subscription_documents" using primary key columns */
  subscription_documents_by_pk?: Maybe<Subscription_Documents>;
  /** fetch data from the table in a streaming manner: "subscription_documents" */
  subscription_documents_stream: Array<Subscription_Documents>;
  /** An array relationship */
  subscription_flags: Array<Subscription_Flags>;
  /** An aggregate relationship */
  subscription_flags_aggregate: Subscription_Flags_Aggregate;
  /** fetch data from the table: "subscription_flags" using primary key columns */
  subscription_flags_by_pk?: Maybe<Subscription_Flags>;
  /** fetch data from the table in a streaming manner: "subscription_flags" */
  subscription_flags_stream: Array<Subscription_Flags>;
  /** An array relationship */
  subscription_interactions: Array<Subscription_Interactions>;
  /** An aggregate relationship */
  subscription_interactions_aggregate: Subscription_Interactions_Aggregate;
  /** fetch data from the table: "subscription_interactions" using primary key columns */
  subscription_interactions_by_pk?: Maybe<Subscription_Interactions>;
  /** fetch data from the table in a streaming manner: "subscription_interactions" */
  subscription_interactions_stream: Array<Subscription_Interactions>;
  subscription_items: Array<Subscription_Items>;
  subscription_items_aggregate: Subscription_Items_Aggregate;
  /** fetch data from the table: "subscription_items" using primary key columns */
  subscription_items_by_pk?: Maybe<Subscription_Items>;
  /** fetch data from the table in a streaming manner: "subscription_items" */
  subscription_items_stream: Array<Subscription_Items>;
  /** An array relationship */
  subscription_payment_transactions: Array<Subscription_Payment_Transactions>;
  /** An aggregate relationship */
  subscription_payment_transactions_aggregate: Subscription_Payment_Transactions_Aggregate;
  /** fetch data from the table: "subscription_payment_transactions" using primary key columns */
  subscription_payment_transactions_by_pk?: Maybe<Subscription_Payment_Transactions>;
  /** fetch data from the table in a streaming manner: "subscription_payment_transactions" */
  subscription_payment_transactions_stream: Array<Subscription_Payment_Transactions>;
  /** An array relationship */
  subscription_payments: Array<Subscription_Payments>;
  /** An aggregate relationship */
  subscription_payments_aggregate: Subscription_Payments_Aggregate;
  /** fetch data from the table: "subscription_payments" using primary key columns */
  subscription_payments_by_pk?: Maybe<Subscription_Payments>;
  /** fetch data from the table in a streaming manner: "subscription_payments" */
  subscription_payments_stream: Array<Subscription_Payments>;
  /** An array relationship */
  subscription_rules: Array<Subscription_Rules>;
  /** An aggregate relationship */
  subscription_rules_aggregate: Subscription_Rules_Aggregate;
  /** fetch data from the table: "subscription_rules" using primary key columns */
  subscription_rules_by_pk?: Maybe<Subscription_Rules>;
  /** fetch data from the table in a streaming manner: "subscription_rules" */
  subscription_rules_stream: Array<Subscription_Rules>;
  /** An array relationship */
  subscription_schedules: Array<Subscription_Schedules>;
  /** An aggregate relationship */
  subscription_schedules_aggregate: Subscription_Schedules_Aggregate;
  /** fetch data from the table: "subscription_schedules" using primary key columns */
  subscription_schedules_by_pk?: Maybe<Subscription_Schedules>;
  /** fetch data from the table in a streaming manner: "subscription_schedules" */
  subscription_schedules_stream: Array<Subscription_Schedules>;
  /** An array relationship */
  subscription_statuses: Array<Subscription_Statuses>;
  /** An aggregate relationship */
  subscription_statuses_aggregate: Subscription_Statuses_Aggregate;
  /** fetch data from the table: "subscription_statuses" using primary key columns */
  subscription_statuses_by_pk?: Maybe<Subscription_Statuses>;
  /** fetch data from the table in a streaming manner: "subscription_statuses" */
  subscription_statuses_stream: Array<Subscription_Statuses>;
  /** An array relationship */
  subscription_tasks: Array<Subscription_Tasks>;
  /** An aggregate relationship */
  subscription_tasks_aggregate: Subscription_Tasks_Aggregate;
  /** fetch data from the table: "subscription_tasks" using primary key columns */
  subscription_tasks_by_pk?: Maybe<Subscription_Tasks>;
  /** fetch data from the table in a streaming manner: "subscription_tasks" */
  subscription_tasks_stream: Array<Subscription_Tasks>;
  /** An array relationship */
  subscriptions: Array<Subscriptions>;
  /** An aggregate relationship */
  subscriptions_aggregate: Subscriptions_Aggregate;
  /** fetch data from the table: "subscriptions" using primary key columns */
  subscriptions_by_pk?: Maybe<Subscriptions>;
  /** fetch data from the table in a streaming manner: "subscriptions" */
  subscriptions_stream: Array<Subscriptions>;
  /** An array relationship */
  tariff_histories: Array<Tariff_Histories>;
  /** An aggregate relationship */
  tariff_histories_aggregate: Tariff_Histories_Aggregate;
  /** fetch data from the table: "tariff_histories" using primary key columns */
  tariff_histories_by_pk?: Maybe<Tariff_Histories>;
  /** fetch data from the table in a streaming manner: "tariff_histories" */
  tariff_histories_stream: Array<Tariff_Histories>;
  /** An array relationship */
  tariffs: Array<Tariffs>;
  /** An aggregate relationship */
  tariffs_aggregate: Tariffs_Aggregate;
  /** fetch data from the table: "tariffs" using primary key columns */
  tariffs_by_pk?: Maybe<Tariffs>;
  /** fetch data from the table in a streaming manner: "tariffs" */
  tariffs_stream: Array<Tariffs>;
  /** An array relationship */
  task_assignments: Array<Task_Assignments>;
  /** An aggregate relationship */
  task_assignments_aggregate: Task_Assignments_Aggregate;
  /** fetch data from the table: "task_assignments" using primary key columns */
  task_assignments_by_pk?: Maybe<Task_Assignments>;
  /** fetch data from the table in a streaming manner: "task_assignments" */
  task_assignments_stream: Array<Task_Assignments>;
  /** An array relationship */
  task_documents: Array<Task_Documents>;
  /** An aggregate relationship */
  task_documents_aggregate: Task_Documents_Aggregate;
  /** fetch data from the table: "task_documents" using primary key columns */
  task_documents_by_pk?: Maybe<Task_Documents>;
  /** fetch data from the table in a streaming manner: "task_documents" */
  task_documents_stream: Array<Task_Documents>;
  /** An array relationship */
  task_flags: Array<Task_Flags>;
  /** An aggregate relationship */
  task_flags_aggregate: Task_Flags_Aggregate;
  /** fetch data from the table: "task_flags" using primary key columns */
  task_flags_by_pk?: Maybe<Task_Flags>;
  /** fetch data from the table in a streaming manner: "task_flags" */
  task_flags_stream: Array<Task_Flags>;
  /** An array relationship */
  task_interactions: Array<Task_Interactions>;
  /** An aggregate relationship */
  task_interactions_aggregate: Task_Interactions_Aggregate;
  /** fetch data from the table: "task_interactions" using primary key columns */
  task_interactions_by_pk?: Maybe<Task_Interactions>;
  /** fetch data from the table in a streaming manner: "task_interactions" */
  task_interactions_stream: Array<Task_Interactions>;
  /** An array relationship */
  task_mentions: Array<Task_Mentions>;
  /** An aggregate relationship */
  task_mentions_aggregate: Task_Mentions_Aggregate;
  /** fetch data from the table: "task_mentions" using primary key columns */
  task_mentions_by_pk?: Maybe<Task_Mentions>;
  /** fetch data from the table in a streaming manner: "task_mentions" */
  task_mentions_stream: Array<Task_Mentions>;
  /** An array relationship */
  task_reminders: Array<Task_Reminders>;
  /** An aggregate relationship */
  task_reminders_aggregate: Task_Reminders_Aggregate;
  /** fetch data from the table: "task_reminders" using primary key columns */
  task_reminders_by_pk?: Maybe<Task_Reminders>;
  /** fetch data from the table in a streaming manner: "task_reminders" */
  task_reminders_stream: Array<Task_Reminders>;
  /** An array relationship */
  task_rules: Array<Task_Rules>;
  /** An aggregate relationship */
  task_rules_aggregate: Task_Rules_Aggregate;
  /** fetch data from the table: "task_rules" using primary key columns */
  task_rules_by_pk?: Maybe<Task_Rules>;
  /** fetch data from the table in a streaming manner: "task_rules" */
  task_rules_stream: Array<Task_Rules>;
  /** An array relationship */
  task_statuses: Array<Task_Statuses>;
  /** An aggregate relationship */
  task_statuses_aggregate: Task_Statuses_Aggregate;
  /** fetch data from the table: "task_statuses" using primary key columns */
  task_statuses_by_pk?: Maybe<Task_Statuses>;
  /** fetch data from the table in a streaming manner: "task_statuses" */
  task_statuses_stream: Array<Task_Statuses>;
  /** An array relationship */
  tasks: Array<Tasks>;
  /** An aggregate relationship */
  tasks_aggregate: Tasks_Aggregate;
  /** fetch data from the table: "tasks" using primary key columns */
  tasks_by_pk?: Maybe<Tasks>;
  /** fetch data from the table in a streaming manner: "tasks" */
  tasks_stream: Array<Tasks>;
  /** An array relationship */
  territories: Array<Territories>;
  /** An aggregate relationship */
  territories_aggregate: Territories_Aggregate;
  /** fetch data from the table: "territories" using primary key columns */
  territories_by_pk?: Maybe<Territories>;
  /** fetch data from the table in a streaming manner: "territories" */
  territories_stream: Array<Territories>;
  /** An array relationship */
  territory_assignments: Array<Territory_Assignments>;
  /** An aggregate relationship */
  territory_assignments_aggregate: Territory_Assignments_Aggregate;
  /** fetch data from the table: "territory_assignments" using primary key columns */
  territory_assignments_by_pk?: Maybe<Territory_Assignments>;
  /** fetch data from the table in a streaming manner: "territory_assignments" */
  territory_assignments_stream: Array<Territory_Assignments>;
  /** An array relationship */
  territory_flags: Array<Territory_Flags>;
  /** An aggregate relationship */
  territory_flags_aggregate: Territory_Flags_Aggregate;
  /** fetch data from the table: "territory_flags" using primary key columns */
  territory_flags_by_pk?: Maybe<Territory_Flags>;
  /** fetch data from the table in a streaming manner: "territory_flags" */
  territory_flags_stream: Array<Territory_Flags>;
  /** An array relationship */
  territory_interactions: Array<Territory_Interactions>;
  /** An aggregate relationship */
  territory_interactions_aggregate: Territory_Interactions_Aggregate;
  /** fetch data from the table: "territory_interactions" using primary key columns */
  territory_interactions_by_pk?: Maybe<Territory_Interactions>;
  /** fetch data from the table in a streaming manner: "territory_interactions" */
  territory_interactions_stream: Array<Territory_Interactions>;
  /** An array relationship */
  territory_members: Array<Territory_Members>;
  /** An aggregate relationship */
  territory_members_aggregate: Territory_Members_Aggregate;
  /** fetch data from the table: "territory_members" using primary key columns */
  territory_members_by_pk?: Maybe<Territory_Members>;
  /** fetch data from the table in a streaming manner: "territory_members" */
  territory_members_stream: Array<Territory_Members>;
  /** An array relationship */
  territory_rules: Array<Territory_Rules>;
  /** An aggregate relationship */
  territory_rules_aggregate: Territory_Rules_Aggregate;
  /** fetch data from the table: "territory_rules" using primary key columns */
  territory_rules_by_pk?: Maybe<Territory_Rules>;
  /** fetch data from the table in a streaming manner: "territory_rules" */
  territory_rules_stream: Array<Territory_Rules>;
  /** An array relationship */
  territory_statuses: Array<Territory_Statuses>;
  /** An aggregate relationship */
  territory_statuses_aggregate: Territory_Statuses_Aggregate;
  /** fetch data from the table: "territory_statuses" using primary key columns */
  territory_statuses_by_pk?: Maybe<Territory_Statuses>;
  /** fetch data from the table in a streaming manner: "territory_statuses" */
  territory_statuses_stream: Array<Territory_Statuses>;
  /** fetch data from the table: "types" */
  types: Array<Types>;
  /** fetch aggregated fields from the table: "types" */
  types_aggregate: Types_Aggregate;
  /** fetch data from the table: "types" using primary key columns */
  types_by_pk?: Maybe<Types>;
  /** fetch data from the table in a streaming manner: "types" */
  types_stream: Array<Types>;
  /** An array relationship */
  user_flags: Array<User_Flags>;
  /** An aggregate relationship */
  user_flags_aggregate: User_Flags_Aggregate;
  /** fetch data from the table: "user_flags" using primary key columns */
  user_flags_by_pk?: Maybe<User_Flags>;
  /** fetch data from the table in a streaming manner: "user_flags" */
  user_flags_stream: Array<User_Flags>;
  /** fetch data from the table: "user_mfa_factors" */
  user_mfa_factors: Array<User_Mfa_Factors>;
  /** fetch aggregated fields from the table: "user_mfa_factors" */
  user_mfa_factors_aggregate: User_Mfa_Factors_Aggregate;
  /** fetch data from the table: "user_mfa_factors" using primary key columns */
  user_mfa_factors_by_pk?: Maybe<User_Mfa_Factors>;
  /** fetch data from the table in a streaming manner: "user_mfa_factors" */
  user_mfa_factors_stream: Array<User_Mfa_Factors>;
  /** An array relationship */
  user_statuses: Array<User_Statuses>;
  /** An aggregate relationship */
  user_statuses_aggregate: User_Statuses_Aggregate;
  /** fetch data from the table: "user_statuses" using primary key columns */
  user_statuses_by_pk?: Maybe<User_Statuses>;
  /** fetch data from the table in a streaming manner: "user_statuses" */
  user_statuses_stream: Array<User_Statuses>;
  /** fetch data from the table: "users" */
  users: Array<Users>;
  /** fetch aggregated fields from the table: "users" */
  users_aggregate: Users_Aggregate;
  /** fetch data from the table: "users" using primary key columns */
  users_by_pk?: Maybe<Users>;
  /** fetch data from the table in a streaming manner: "users" */
  users_stream: Array<Users>;
  /** An array relationship */
  webhooks: Array<Webhooks>;
  /** An aggregate relationship */
  webhooks_aggregate: Webhooks_Aggregate;
  /** fetch data from the table: "webhooks" using primary key columns */
  webhooks_by_pk?: Maybe<Webhooks>;
  /** fetch data from the table in a streaming manner: "webhooks" */
  webhooks_stream: Array<Webhooks>;
  /** An array relationship */
  workflow_templates: Array<Workflow_Templates>;
  /** An aggregate relationship */
  workflow_templates_aggregate: Workflow_Templates_Aggregate;
  /** fetch data from the table: "workflow_templates" using primary key columns */
  workflow_templates_by_pk?: Maybe<Workflow_Templates>;
  /** fetch data from the table in a streaming manner: "workflow_templates" */
  workflow_templates_stream: Array<Workflow_Templates>;
};

export type Subscription_Root_Case_Dod_DataArgs = {
  distinct_on?: InputMaybe<Array<_Case_Dod_Data_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<_Case_Dod_Data_Order_By>>;
  where?: InputMaybe<_Case_Dod_Data_Bool_Exp>;
};

export type Subscription_Root_Case_Dod_Data_AggregateArgs = {
  distinct_on?: InputMaybe<Array<_Case_Dod_Data_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<_Case_Dod_Data_Order_By>>;
  where?: InputMaybe<_Case_Dod_Data_Bool_Exp>;
};

export type Subscription_Root_Case_Dod_Data_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<InputMaybe<_Case_Dod_Data_Stream_Cursor_Input>>;
  where?: InputMaybe<_Case_Dod_Data_Bool_Exp>;
};

export type Subscription_Root_Closed_Cases_Dod_DataArgs = {
  distinct_on?: InputMaybe<Array<_Closed_Cases_Dod_Data_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<_Closed_Cases_Dod_Data_Order_By>>;
  where?: InputMaybe<_Closed_Cases_Dod_Data_Bool_Exp>;
};

export type Subscription_Root_Closed_Cases_Dod_Data_AggregateArgs = {
  distinct_on?: InputMaybe<Array<_Closed_Cases_Dod_Data_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<_Closed_Cases_Dod_Data_Order_By>>;
  where?: InputMaybe<_Closed_Cases_Dod_Data_Bool_Exp>;
};

export type Subscription_Root_Closed_Cases_Dod_Data_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<InputMaybe<_Closed_Cases_Dod_Data_Stream_Cursor_Input>>;
  where?: InputMaybe<_Closed_Cases_Dod_Data_Bool_Exp>;
};

export type Subscription_Root_Cus_Growth_DataArgs = {
  distinct_on?: InputMaybe<Array<_Cus_Growth_Data_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<_Cus_Growth_Data_Order_By>>;
  where?: InputMaybe<_Cus_Growth_Data_Bool_Exp>;
};

export type Subscription_Root_Cus_Growth_Data_AggregateArgs = {
  distinct_on?: InputMaybe<Array<_Cus_Growth_Data_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<_Cus_Growth_Data_Order_By>>;
  where?: InputMaybe<_Cus_Growth_Data_Bool_Exp>;
};

export type Subscription_Root_Cus_Growth_Data_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<InputMaybe<_Cus_Growth_Data_Stream_Cursor_Input>>;
  where?: InputMaybe<_Cus_Growth_Data_Bool_Exp>;
};

export type Subscription_RootActive_Product_SubscriptionsArgs = {
  distinct_on?: InputMaybe<Array<Active_Product_Subscriptions_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Active_Product_Subscriptions_Order_By>>;
  where?: InputMaybe<Active_Product_Subscriptions_Bool_Exp>;
};

export type Subscription_RootActive_Product_Subscriptions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Active_Product_Subscriptions_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Active_Product_Subscriptions_Order_By>>;
  where?: InputMaybe<Active_Product_Subscriptions_Bool_Exp>;
};

export type Subscription_RootActive_Product_Subscriptions_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<InputMaybe<Active_Product_Subscriptions_Stream_Cursor_Input>>;
  where?: InputMaybe<Active_Product_Subscriptions_Bool_Exp>;
};

export type Subscription_RootActivity_LogsArgs = {
  distinct_on?: InputMaybe<Array<Activity_Logs_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Activity_Logs_Order_By>>;
  where?: InputMaybe<Activity_Logs_Bool_Exp>;
};

export type Subscription_RootActivity_Logs_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Activity_Logs_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Activity_Logs_Order_By>>;
  where?: InputMaybe<Activity_Logs_Bool_Exp>;
};

export type Subscription_RootActivity_Logs_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Subscription_RootActivity_Logs_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<InputMaybe<Activity_Logs_Stream_Cursor_Input>>;
  where?: InputMaybe<Activity_Logs_Bool_Exp>;
};

export type Subscription_RootAudit_LogsArgs = {
  distinct_on?: InputMaybe<Array<Audit_Logs_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Audit_Logs_Order_By>>;
  where?: InputMaybe<Audit_Logs_Bool_Exp>;
};

export type Subscription_RootAudit_Logs_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Audit_Logs_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Audit_Logs_Order_By>>;
  where?: InputMaybe<Audit_Logs_Bool_Exp>;
};

export type Subscription_RootAudit_Logs_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Subscription_RootAudit_Logs_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<InputMaybe<Audit_Logs_Stream_Cursor_Input>>;
  where?: InputMaybe<Audit_Logs_Bool_Exp>;
};

export type Subscription_RootCampaign_ContactsArgs = {
  distinct_on?: InputMaybe<Array<Campaign_Contacts_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Campaign_Contacts_Order_By>>;
  where?: InputMaybe<Campaign_Contacts_Bool_Exp>;
};

export type Subscription_RootCampaign_Contacts_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Campaign_Contacts_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Campaign_Contacts_Order_By>>;
  where?: InputMaybe<Campaign_Contacts_Bool_Exp>;
};

export type Subscription_RootCampaign_Contacts_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Subscription_RootCampaign_Contacts_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<InputMaybe<Campaign_Contacts_Stream_Cursor_Input>>;
  where?: InputMaybe<Campaign_Contacts_Bool_Exp>;
};

export type Subscription_RootCampaign_Performance_IndicatorsArgs = {
  distinct_on?: InputMaybe<
    Array<Campaign_Performance_Indicators_Select_Column>
  >;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Campaign_Performance_Indicators_Order_By>>;
  where?: InputMaybe<Campaign_Performance_Indicators_Bool_Exp>;
};

export type Subscription_RootCampaign_Performance_Indicators_AggregateArgs = {
  distinct_on?: InputMaybe<
    Array<Campaign_Performance_Indicators_Select_Column>
  >;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Campaign_Performance_Indicators_Order_By>>;
  where?: InputMaybe<Campaign_Performance_Indicators_Bool_Exp>;
};

export type Subscription_RootCampaign_Performance_Indicators_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Subscription_RootCampaign_Performance_Indicators_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<
    InputMaybe<Campaign_Performance_Indicators_Stream_Cursor_Input>
  >;
  where?: InputMaybe<Campaign_Performance_Indicators_Bool_Exp>;
};

export type Subscription_RootCampaign_RewardsArgs = {
  distinct_on?: InputMaybe<Array<Campaign_Rewards_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Campaign_Rewards_Order_By>>;
  where?: InputMaybe<Campaign_Rewards_Bool_Exp>;
};

export type Subscription_RootCampaign_Rewards_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Campaign_Rewards_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Campaign_Rewards_Order_By>>;
  where?: InputMaybe<Campaign_Rewards_Bool_Exp>;
};

export type Subscription_RootCampaign_Rewards_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Subscription_RootCampaign_Rewards_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<InputMaybe<Campaign_Rewards_Stream_Cursor_Input>>;
  where?: InputMaybe<Campaign_Rewards_Bool_Exp>;
};

export type Subscription_RootCampaign_RulesArgs = {
  distinct_on?: InputMaybe<Array<Campaign_Rules_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Campaign_Rules_Order_By>>;
  where?: InputMaybe<Campaign_Rules_Bool_Exp>;
};

export type Subscription_RootCampaign_Rules_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Campaign_Rules_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Campaign_Rules_Order_By>>;
  where?: InputMaybe<Campaign_Rules_Bool_Exp>;
};

export type Subscription_RootCampaign_Rules_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Subscription_RootCampaign_Rules_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<InputMaybe<Campaign_Rules_Stream_Cursor_Input>>;
  where?: InputMaybe<Campaign_Rules_Bool_Exp>;
};

export type Subscription_RootCampaign_TasksArgs = {
  distinct_on?: InputMaybe<Array<Campaign_Tasks_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Campaign_Tasks_Order_By>>;
  where?: InputMaybe<Campaign_Tasks_Bool_Exp>;
};

export type Subscription_RootCampaign_Tasks_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Campaign_Tasks_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Campaign_Tasks_Order_By>>;
  where?: InputMaybe<Campaign_Tasks_Bool_Exp>;
};

export type Subscription_RootCampaign_Tasks_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Subscription_RootCampaign_Tasks_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<InputMaybe<Campaign_Tasks_Stream_Cursor_Input>>;
  where?: InputMaybe<Campaign_Tasks_Bool_Exp>;
};

export type Subscription_RootCampaign_WorkflowsArgs = {
  distinct_on?: InputMaybe<Array<Campaign_Workflows_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Campaign_Workflows_Order_By>>;
  where?: InputMaybe<Campaign_Workflows_Bool_Exp>;
};

export type Subscription_RootCampaign_Workflows_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Campaign_Workflows_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Campaign_Workflows_Order_By>>;
  where?: InputMaybe<Campaign_Workflows_Bool_Exp>;
};

export type Subscription_RootCampaign_Workflows_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Subscription_RootCampaign_Workflows_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<InputMaybe<Campaign_Workflows_Stream_Cursor_Input>>;
  where?: InputMaybe<Campaign_Workflows_Bool_Exp>;
};

export type Subscription_RootCampaignsArgs = {
  distinct_on?: InputMaybe<Array<Campaigns_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Campaigns_Order_By>>;
  where?: InputMaybe<Campaigns_Bool_Exp>;
};

export type Subscription_RootCampaigns_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Campaigns_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Campaigns_Order_By>>;
  where?: InputMaybe<Campaigns_Bool_Exp>;
};

export type Subscription_RootCampaigns_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Subscription_RootCampaigns_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<InputMaybe<Campaigns_Stream_Cursor_Input>>;
  where?: InputMaybe<Campaigns_Bool_Exp>;
};

export type Subscription_RootCase_AssignmentsArgs = {
  distinct_on?: InputMaybe<Array<Case_Assignments_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Case_Assignments_Order_By>>;
  where?: InputMaybe<Case_Assignments_Bool_Exp>;
};

export type Subscription_RootCase_Assignments_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Case_Assignments_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Case_Assignments_Order_By>>;
  where?: InputMaybe<Case_Assignments_Bool_Exp>;
};

export type Subscription_RootCase_Assignments_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Subscription_RootCase_Assignments_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<InputMaybe<Case_Assignments_Stream_Cursor_Input>>;
  where?: InputMaybe<Case_Assignments_Bool_Exp>;
};

export type Subscription_RootCase_DetailsArgs = {
  distinct_on?: InputMaybe<Array<Case_Details_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Case_Details_Order_By>>;
  where?: InputMaybe<Case_Details_Bool_Exp>;
};

export type Subscription_RootCase_Details_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Case_Details_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Case_Details_Order_By>>;
  where?: InputMaybe<Case_Details_Bool_Exp>;
};

export type Subscription_RootCase_Details_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Subscription_RootCase_Details_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<InputMaybe<Case_Details_Stream_Cursor_Input>>;
  where?: InputMaybe<Case_Details_Bool_Exp>;
};

export type Subscription_RootCase_DocumentsArgs = {
  distinct_on?: InputMaybe<Array<Case_Documents_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Case_Documents_Order_By>>;
  where?: InputMaybe<Case_Documents_Bool_Exp>;
};

export type Subscription_RootCase_Documents_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Case_Documents_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Case_Documents_Order_By>>;
  where?: InputMaybe<Case_Documents_Bool_Exp>;
};

export type Subscription_RootCase_Documents_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Subscription_RootCase_Documents_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<InputMaybe<Case_Documents_Stream_Cursor_Input>>;
  where?: InputMaybe<Case_Documents_Bool_Exp>;
};

export type Subscription_RootCase_FlagsArgs = {
  distinct_on?: InputMaybe<Array<Case_Flags_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Case_Flags_Order_By>>;
  where?: InputMaybe<Case_Flags_Bool_Exp>;
};

export type Subscription_RootCase_Flags_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Case_Flags_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Case_Flags_Order_By>>;
  where?: InputMaybe<Case_Flags_Bool_Exp>;
};

export type Subscription_RootCase_Flags_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Subscription_RootCase_Flags_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<InputMaybe<Case_Flags_Stream_Cursor_Input>>;
  where?: InputMaybe<Case_Flags_Bool_Exp>;
};

export type Subscription_RootCase_InteractionsArgs = {
  distinct_on?: InputMaybe<Array<Case_Interactions_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Case_Interactions_Order_By>>;
  where?: InputMaybe<Case_Interactions_Bool_Exp>;
};

export type Subscription_RootCase_Interactions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Case_Interactions_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Case_Interactions_Order_By>>;
  where?: InputMaybe<Case_Interactions_Bool_Exp>;
};

export type Subscription_RootCase_Interactions_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Subscription_RootCase_Interactions_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<InputMaybe<Case_Interactions_Stream_Cursor_Input>>;
  where?: InputMaybe<Case_Interactions_Bool_Exp>;
};

export type Subscription_RootCase_MentionsArgs = {
  distinct_on?: InputMaybe<Array<Case_Mentions_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Case_Mentions_Order_By>>;
  where?: InputMaybe<Case_Mentions_Bool_Exp>;
};

export type Subscription_RootCase_Mentions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Case_Mentions_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Case_Mentions_Order_By>>;
  where?: InputMaybe<Case_Mentions_Bool_Exp>;
};

export type Subscription_RootCase_Mentions_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Subscription_RootCase_Mentions_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<InputMaybe<Case_Mentions_Stream_Cursor_Input>>;
  where?: InputMaybe<Case_Mentions_Bool_Exp>;
};

export type Subscription_RootCase_StatusesArgs = {
  distinct_on?: InputMaybe<Array<Case_Statuses_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Case_Statuses_Order_By>>;
  where?: InputMaybe<Case_Statuses_Bool_Exp>;
};

export type Subscription_RootCase_Statuses_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Case_Statuses_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Case_Statuses_Order_By>>;
  where?: InputMaybe<Case_Statuses_Bool_Exp>;
};

export type Subscription_RootCase_Statuses_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Subscription_RootCase_Statuses_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<InputMaybe<Case_Statuses_Stream_Cursor_Input>>;
  where?: InputMaybe<Case_Statuses_Bool_Exp>;
};

export type Subscription_RootCasesArgs = {
  distinct_on?: InputMaybe<Array<Cases_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Cases_Order_By>>;
  where?: InputMaybe<Cases_Bool_Exp>;
};

export type Subscription_RootCases_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Cases_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Cases_Order_By>>;
  where?: InputMaybe<Cases_Bool_Exp>;
};

export type Subscription_RootCases_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Subscription_RootCases_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<InputMaybe<Cases_Stream_Cursor_Input>>;
  where?: InputMaybe<Cases_Bool_Exp>;
};

export type Subscription_RootChannel_FlagsArgs = {
  distinct_on?: InputMaybe<Array<Channel_Flags_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Channel_Flags_Order_By>>;
  where?: InputMaybe<Channel_Flags_Bool_Exp>;
};

export type Subscription_RootChannel_Flags_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Channel_Flags_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Channel_Flags_Order_By>>;
  where?: InputMaybe<Channel_Flags_Bool_Exp>;
};

export type Subscription_RootChannel_Flags_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Subscription_RootChannel_Flags_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<InputMaybe<Channel_Flags_Stream_Cursor_Input>>;
  where?: InputMaybe<Channel_Flags_Bool_Exp>;
};

export type Subscription_RootChannel_PartiesArgs = {
  distinct_on?: InputMaybe<Array<Channel_Parties_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Channel_Parties_Order_By>>;
  where?: InputMaybe<Channel_Parties_Bool_Exp>;
};

export type Subscription_RootChannel_Parties_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Channel_Parties_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Channel_Parties_Order_By>>;
  where?: InputMaybe<Channel_Parties_Bool_Exp>;
};

export type Subscription_RootChannel_Parties_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Subscription_RootChannel_Parties_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<InputMaybe<Channel_Parties_Stream_Cursor_Input>>;
  where?: InputMaybe<Channel_Parties_Bool_Exp>;
};

export type Subscription_RootChannel_StatusesArgs = {
  distinct_on?: InputMaybe<Array<Channel_Statuses_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Channel_Statuses_Order_By>>;
  where?: InputMaybe<Channel_Statuses_Bool_Exp>;
};

export type Subscription_RootChannel_Statuses_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Channel_Statuses_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Channel_Statuses_Order_By>>;
  where?: InputMaybe<Channel_Statuses_Bool_Exp>;
};

export type Subscription_RootChannel_Statuses_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Subscription_RootChannel_Statuses_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<InputMaybe<Channel_Statuses_Stream_Cursor_Input>>;
  where?: InputMaybe<Channel_Statuses_Bool_Exp>;
};

export type Subscription_RootChannel_SubscriptionsArgs = {
  distinct_on?: InputMaybe<Array<Channel_Subscriptions_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Channel_Subscriptions_Order_By>>;
  where?: InputMaybe<Channel_Subscriptions_Bool_Exp>;
};

export type Subscription_RootChannel_Subscriptions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Channel_Subscriptions_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Channel_Subscriptions_Order_By>>;
  where?: InputMaybe<Channel_Subscriptions_Bool_Exp>;
};

export type Subscription_RootChannel_Subscriptions_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Subscription_RootChannel_Subscriptions_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<InputMaybe<Channel_Subscriptions_Stream_Cursor_Input>>;
  where?: InputMaybe<Channel_Subscriptions_Bool_Exp>;
};

export type Subscription_RootChannelsArgs = {
  distinct_on?: InputMaybe<Array<Channels_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Channels_Order_By>>;
  where?: InputMaybe<Channels_Bool_Exp>;
};

export type Subscription_RootChannels_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Channels_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Channels_Order_By>>;
  where?: InputMaybe<Channels_Bool_Exp>;
};

export type Subscription_RootChannels_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Subscription_RootChannels_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<InputMaybe<Channels_Stream_Cursor_Input>>;
  where?: InputMaybe<Channels_Bool_Exp>;
};

export type Subscription_RootClosed_Cases_DailyArgs = {
  args: Closed_Cases_Daily_Args;
  distinct_on?: InputMaybe<Array<_Closed_Cases_Dod_Data_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<_Closed_Cases_Dod_Data_Order_By>>;
  where?: InputMaybe<_Closed_Cases_Dod_Data_Bool_Exp>;
};

export type Subscription_RootClosed_Cases_Daily_AggregateArgs = {
  args: Closed_Cases_Daily_Args;
  distinct_on?: InputMaybe<Array<_Closed_Cases_Dod_Data_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<_Closed_Cases_Dod_Data_Order_By>>;
  where?: InputMaybe<_Closed_Cases_Dod_Data_Bool_Exp>;
};

export type Subscription_RootConditionsArgs = {
  distinct_on?: InputMaybe<Array<Conditions_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Conditions_Order_By>>;
  where?: InputMaybe<Conditions_Bool_Exp>;
};

export type Subscription_RootConditions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Conditions_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Conditions_Order_By>>;
  where?: InputMaybe<Conditions_Bool_Exp>;
};

export type Subscription_RootConditions_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Subscription_RootConditions_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<InputMaybe<Conditions_Stream_Cursor_Input>>;
  where?: InputMaybe<Conditions_Bool_Exp>;
};

export type Subscription_RootContact_DetailsArgs = {
  distinct_on?: InputMaybe<Array<Contact_Details_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Contact_Details_Order_By>>;
  where?: InputMaybe<Contact_Details_Bool_Exp>;
};

export type Subscription_RootContact_Details_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Contact_Details_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Contact_Details_Order_By>>;
  where?: InputMaybe<Contact_Details_Bool_Exp>;
};

export type Subscription_RootContact_Details_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Subscription_RootContact_Details_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<InputMaybe<Contact_Details_Stream_Cursor_Input>>;
  where?: InputMaybe<Contact_Details_Bool_Exp>;
};

export type Subscription_RootContact_InteractionsArgs = {
  distinct_on?: InputMaybe<Array<Contact_Interactions_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Contact_Interactions_Order_By>>;
  where?: InputMaybe<Contact_Interactions_Bool_Exp>;
};

export type Subscription_RootContact_Interactions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Contact_Interactions_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Contact_Interactions_Order_By>>;
  where?: InputMaybe<Contact_Interactions_Bool_Exp>;
};

export type Subscription_RootContact_Interactions_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Subscription_RootContact_Interactions_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<InputMaybe<Contact_Interactions_Stream_Cursor_Input>>;
  where?: InputMaybe<Contact_Interactions_Bool_Exp>;
};

export type Subscription_RootContact_RelationshipsArgs = {
  distinct_on?: InputMaybe<Array<Contact_Relationships_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Contact_Relationships_Order_By>>;
  where?: InputMaybe<Contact_Relationships_Bool_Exp>;
};

export type Subscription_RootContact_Relationships_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Contact_Relationships_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Contact_Relationships_Order_By>>;
  where?: InputMaybe<Contact_Relationships_Bool_Exp>;
};

export type Subscription_RootContact_Relationships_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Subscription_RootContact_Relationships_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<InputMaybe<Contact_Relationships_Stream_Cursor_Input>>;
  where?: InputMaybe<Contact_Relationships_Bool_Exp>;
};

export type Subscription_RootContactsArgs = {
  distinct_on?: InputMaybe<Array<Contacts_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Contacts_Order_By>>;
  where?: InputMaybe<Contacts_Bool_Exp>;
};

export type Subscription_RootContacts_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Contacts_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Contacts_Order_By>>;
  where?: InputMaybe<Contacts_Bool_Exp>;
};

export type Subscription_RootContacts_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Subscription_RootContacts_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<InputMaybe<Contacts_Stream_Cursor_Input>>;
  where?: InputMaybe<Contacts_Bool_Exp>;
};

export type Subscription_RootCredit_Profile_FlagsArgs = {
  distinct_on?: InputMaybe<Array<Credit_Profile_Flags_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Credit_Profile_Flags_Order_By>>;
  where?: InputMaybe<Credit_Profile_Flags_Bool_Exp>;
};

export type Subscription_RootCredit_Profile_Flags_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Credit_Profile_Flags_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Credit_Profile_Flags_Order_By>>;
  where?: InputMaybe<Credit_Profile_Flags_Bool_Exp>;
};

export type Subscription_RootCredit_Profile_Flags_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Subscription_RootCredit_Profile_Flags_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<InputMaybe<Credit_Profile_Flags_Stream_Cursor_Input>>;
  where?: InputMaybe<Credit_Profile_Flags_Bool_Exp>;
};

export type Subscription_RootCredit_Profile_StatusesArgs = {
  distinct_on?: InputMaybe<Array<Credit_Profile_Statuses_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Credit_Profile_Statuses_Order_By>>;
  where?: InputMaybe<Credit_Profile_Statuses_Bool_Exp>;
};

export type Subscription_RootCredit_Profile_Statuses_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Credit_Profile_Statuses_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Credit_Profile_Statuses_Order_By>>;
  where?: InputMaybe<Credit_Profile_Statuses_Bool_Exp>;
};

export type Subscription_RootCredit_Profile_Statuses_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Subscription_RootCredit_Profile_Statuses_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<InputMaybe<Credit_Profile_Statuses_Stream_Cursor_Input>>;
  where?: InputMaybe<Credit_Profile_Statuses_Bool_Exp>;
};

export type Subscription_RootCredit_ProfilesArgs = {
  distinct_on?: InputMaybe<Array<Credit_Profiles_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Credit_Profiles_Order_By>>;
  where?: InputMaybe<Credit_Profiles_Bool_Exp>;
};

export type Subscription_RootCredit_Profiles_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Credit_Profiles_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Credit_Profiles_Order_By>>;
  where?: InputMaybe<Credit_Profiles_Bool_Exp>;
};

export type Subscription_RootCredit_Profiles_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Subscription_RootCredit_Profiles_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<InputMaybe<Credit_Profiles_Stream_Cursor_Input>>;
  where?: InputMaybe<Credit_Profiles_Bool_Exp>;
};

export type Subscription_RootCustomer_Daily_GrowthArgs = {
  args: Customer_Daily_Growth_Args;
  distinct_on?: InputMaybe<Array<_Cus_Growth_Data_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<_Cus_Growth_Data_Order_By>>;
  where?: InputMaybe<_Cus_Growth_Data_Bool_Exp>;
};

export type Subscription_RootCustomer_Daily_Growth_AggregateArgs = {
  args: Customer_Daily_Growth_Args;
  distinct_on?: InputMaybe<Array<_Cus_Growth_Data_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<_Cus_Growth_Data_Order_By>>;
  where?: InputMaybe<_Cus_Growth_Data_Bool_Exp>;
};

export type Subscription_RootDelivery_MethodsArgs = {
  distinct_on?: InputMaybe<Array<Delivery_Methods_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Delivery_Methods_Order_By>>;
  where?: InputMaybe<Delivery_Methods_Bool_Exp>;
};

export type Subscription_RootDelivery_Methods_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Delivery_Methods_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Delivery_Methods_Order_By>>;
  where?: InputMaybe<Delivery_Methods_Bool_Exp>;
};

export type Subscription_RootDelivery_Methods_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Subscription_RootDelivery_Methods_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<InputMaybe<Delivery_Methods_Stream_Cursor_Input>>;
  where?: InputMaybe<Delivery_Methods_Bool_Exp>;
};

export type Subscription_RootDocument_InteractionsArgs = {
  distinct_on?: InputMaybe<Array<Document_Interactions_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Document_Interactions_Order_By>>;
  where?: InputMaybe<Document_Interactions_Bool_Exp>;
};

export type Subscription_RootDocument_Interactions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Document_Interactions_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Document_Interactions_Order_By>>;
  where?: InputMaybe<Document_Interactions_Bool_Exp>;
};

export type Subscription_RootDocument_Interactions_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Subscription_RootDocument_Interactions_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<InputMaybe<Document_Interactions_Stream_Cursor_Input>>;
  where?: InputMaybe<Document_Interactions_Bool_Exp>;
};

export type Subscription_RootDocumentsArgs = {
  distinct_on?: InputMaybe<Array<Documents_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Documents_Order_By>>;
  where?: InputMaybe<Documents_Bool_Exp>;
};

export type Subscription_RootDocuments_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Documents_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Documents_Order_By>>;
  where?: InputMaybe<Documents_Bool_Exp>;
};

export type Subscription_RootDocuments_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Subscription_RootDocuments_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<InputMaybe<Documents_Stream_Cursor_Input>>;
  where?: InputMaybe<Documents_Bool_Exp>;
};

export type Subscription_RootFlagsArgs = {
  distinct_on?: InputMaybe<Array<Flags_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Flags_Order_By>>;
  where?: InputMaybe<Flags_Bool_Exp>;
};

export type Subscription_RootFlags_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Flags_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Flags_Order_By>>;
  where?: InputMaybe<Flags_Bool_Exp>;
};

export type Subscription_RootFlags_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Subscription_RootFlags_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<InputMaybe<Flags_Stream_Cursor_Input>>;
  where?: InputMaybe<Flags_Bool_Exp>;
};

export type Subscription_RootForm_FieldsArgs = {
  distinct_on?: InputMaybe<Array<Form_Fields_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Form_Fields_Order_By>>;
  where?: InputMaybe<Form_Fields_Bool_Exp>;
};

export type Subscription_RootForm_Fields_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Form_Fields_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Form_Fields_Order_By>>;
  where?: InputMaybe<Form_Fields_Bool_Exp>;
};

export type Subscription_RootForm_Fields_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Subscription_RootForm_Fields_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<InputMaybe<Form_Fields_Stream_Cursor_Input>>;
  where?: InputMaybe<Form_Fields_Bool_Exp>;
};

export type Subscription_RootForm_RulesArgs = {
  distinct_on?: InputMaybe<Array<Form_Rules_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Form_Rules_Order_By>>;
  where?: InputMaybe<Form_Rules_Bool_Exp>;
};

export type Subscription_RootForm_Rules_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Form_Rules_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Form_Rules_Order_By>>;
  where?: InputMaybe<Form_Rules_Bool_Exp>;
};

export type Subscription_RootForm_Rules_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Subscription_RootForm_Rules_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<InputMaybe<Form_Rules_Stream_Cursor_Input>>;
  where?: InputMaybe<Form_Rules_Bool_Exp>;
};

export type Subscription_RootFormsArgs = {
  distinct_on?: InputMaybe<Array<Forms_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Forms_Order_By>>;
  where?: InputMaybe<Forms_Bool_Exp>;
};

export type Subscription_RootForms_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Forms_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Forms_Order_By>>;
  where?: InputMaybe<Forms_Bool_Exp>;
};

export type Subscription_RootForms_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Subscription_RootForms_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<InputMaybe<Forms_Stream_Cursor_Input>>;
  where?: InputMaybe<Forms_Bool_Exp>;
};

export type Subscription_RootGet_Party_ChannelArgs = {
  args: Get_Party_Channel_Args;
  distinct_on?: InputMaybe<Array<Channel_Parties_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Channel_Parties_Order_By>>;
  where?: InputMaybe<Channel_Parties_Bool_Exp>;
};

export type Subscription_RootGet_Party_Channel_AggregateArgs = {
  args: Get_Party_Channel_Args;
  distinct_on?: InputMaybe<Array<Channel_Parties_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Channel_Parties_Order_By>>;
  where?: InputMaybe<Channel_Parties_Bool_Exp>;
};

export type Subscription_RootInteraction_DetailsArgs = {
  distinct_on?: InputMaybe<Array<Interaction_Details_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Interaction_Details_Order_By>>;
  where?: InputMaybe<Interaction_Details_Bool_Exp>;
};

export type Subscription_RootInteraction_Details_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Interaction_Details_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Interaction_Details_Order_By>>;
  where?: InputMaybe<Interaction_Details_Bool_Exp>;
};

export type Subscription_RootInteraction_Details_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Subscription_RootInteraction_Details_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<InputMaybe<Interaction_Details_Stream_Cursor_Input>>;
  where?: InputMaybe<Interaction_Details_Bool_Exp>;
};

export type Subscription_RootInteractionsArgs = {
  distinct_on?: InputMaybe<Array<Interactions_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Interactions_Order_By>>;
  where?: InputMaybe<Interactions_Bool_Exp>;
};

export type Subscription_RootInteractions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Interactions_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Interactions_Order_By>>;
  where?: InputMaybe<Interactions_Bool_Exp>;
};

export type Subscription_RootInteractions_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Subscription_RootInteractions_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<InputMaybe<Interactions_Stream_Cursor_Input>>;
  where?: InputMaybe<Interactions_Bool_Exp>;
};

export type Subscription_RootInvoice_InteractionsArgs = {
  distinct_on?: InputMaybe<Array<Invoice_Interactions_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Invoice_Interactions_Order_By>>;
  where?: InputMaybe<Invoice_Interactions_Bool_Exp>;
};

export type Subscription_RootInvoice_Interactions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Invoice_Interactions_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Invoice_Interactions_Order_By>>;
  where?: InputMaybe<Invoice_Interactions_Bool_Exp>;
};

export type Subscription_RootInvoice_Interactions_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Subscription_RootInvoice_Interactions_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<InputMaybe<Invoice_Interactions_Stream_Cursor_Input>>;
  where?: InputMaybe<Invoice_Interactions_Bool_Exp>;
};

export type Subscription_RootInvoice_ItemsArgs = {
  distinct_on?: InputMaybe<Array<Invoice_Items_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Invoice_Items_Order_By>>;
  where?: InputMaybe<Invoice_Items_Bool_Exp>;
};

export type Subscription_RootInvoice_Items_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Invoice_Items_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Invoice_Items_Order_By>>;
  where?: InputMaybe<Invoice_Items_Bool_Exp>;
};

export type Subscription_RootInvoice_Items_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Subscription_RootInvoice_Items_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<InputMaybe<Invoice_Items_Stream_Cursor_Input>>;
  where?: InputMaybe<Invoice_Items_Bool_Exp>;
};

export type Subscription_RootInvoicesArgs = {
  distinct_on?: InputMaybe<Array<Invoices_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Invoices_Order_By>>;
  where?: InputMaybe<Invoices_Bool_Exp>;
};

export type Subscription_RootInvoices_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Invoices_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Invoices_Order_By>>;
  where?: InputMaybe<Invoices_Bool_Exp>;
};

export type Subscription_RootInvoices_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Subscription_RootInvoices_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<InputMaybe<Invoices_Stream_Cursor_Input>>;
  where?: InputMaybe<Invoices_Bool_Exp>;
};

export type Subscription_RootMessage_Delivery_MethodsArgs = {
  distinct_on?: InputMaybe<Array<Message_Delivery_Methods_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Message_Delivery_Methods_Order_By>>;
  where?: InputMaybe<Message_Delivery_Methods_Bool_Exp>;
};

export type Subscription_RootMessage_Delivery_Methods_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Message_Delivery_Methods_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Message_Delivery_Methods_Order_By>>;
  where?: InputMaybe<Message_Delivery_Methods_Bool_Exp>;
};

export type Subscription_RootMessage_Delivery_Methods_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Subscription_RootMessage_Delivery_Methods_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<InputMaybe<Message_Delivery_Methods_Stream_Cursor_Input>>;
  where?: InputMaybe<Message_Delivery_Methods_Bool_Exp>;
};

export type Subscription_RootMessage_Delivery_ResponsesArgs = {
  distinct_on?: InputMaybe<Array<Message_Delivery_Responses_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Message_Delivery_Responses_Order_By>>;
  where?: InputMaybe<Message_Delivery_Responses_Bool_Exp>;
};

export type Subscription_RootMessage_Delivery_Responses_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Message_Delivery_Responses_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Message_Delivery_Responses_Order_By>>;
  where?: InputMaybe<Message_Delivery_Responses_Bool_Exp>;
};

export type Subscription_RootMessage_Delivery_Responses_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Subscription_RootMessage_Delivery_Responses_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<InputMaybe<Message_Delivery_Responses_Stream_Cursor_Input>>;
  where?: InputMaybe<Message_Delivery_Responses_Bool_Exp>;
};

export type Subscription_RootMessage_FlagsArgs = {
  distinct_on?: InputMaybe<Array<Message_Flags_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Message_Flags_Order_By>>;
  where?: InputMaybe<Message_Flags_Bool_Exp>;
};

export type Subscription_RootMessage_Flags_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Message_Flags_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Message_Flags_Order_By>>;
  where?: InputMaybe<Message_Flags_Bool_Exp>;
};

export type Subscription_RootMessage_Flags_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Subscription_RootMessage_Flags_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<InputMaybe<Message_Flags_Stream_Cursor_Input>>;
  where?: InputMaybe<Message_Flags_Bool_Exp>;
};

export type Subscription_RootMessage_StatusesArgs = {
  distinct_on?: InputMaybe<Array<Message_Statuses_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Message_Statuses_Order_By>>;
  where?: InputMaybe<Message_Statuses_Bool_Exp>;
};

export type Subscription_RootMessage_Statuses_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Message_Statuses_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Message_Statuses_Order_By>>;
  where?: InputMaybe<Message_Statuses_Bool_Exp>;
};

export type Subscription_RootMessage_Statuses_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Subscription_RootMessage_Statuses_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<InputMaybe<Message_Statuses_Stream_Cursor_Input>>;
  where?: InputMaybe<Message_Statuses_Bool_Exp>;
};

export type Subscription_RootMessagesArgs = {
  distinct_on?: InputMaybe<Array<Messages_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Messages_Order_By>>;
  where?: InputMaybe<Messages_Bool_Exp>;
};

export type Subscription_RootMessages_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Messages_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Messages_Order_By>>;
  where?: InputMaybe<Messages_Bool_Exp>;
};

export type Subscription_RootMessages_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Subscription_RootMessages_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<InputMaybe<Messages_Stream_Cursor_Input>>;
  where?: InputMaybe<Messages_Bool_Exp>;
};

export type Subscription_RootNew_Cases_Daily_GrowthArgs = {
  args: New_Cases_Daily_Growth_Args;
  distinct_on?: InputMaybe<Array<_Case_Dod_Data_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<_Case_Dod_Data_Order_By>>;
  where?: InputMaybe<_Case_Dod_Data_Bool_Exp>;
};

export type Subscription_RootNew_Cases_Daily_Growth_AggregateArgs = {
  args: New_Cases_Daily_Growth_Args;
  distinct_on?: InputMaybe<Array<_Case_Dod_Data_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<_Case_Dod_Data_Order_By>>;
  where?: InputMaybe<_Case_Dod_Data_Bool_Exp>;
};

export type Subscription_RootOrganisation_CredentialsArgs = {
  distinct_on?: InputMaybe<Array<Organisation_Credentials_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Organisation_Credentials_Order_By>>;
  where?: InputMaybe<Organisation_Credentials_Bool_Exp>;
};

export type Subscription_RootOrganisation_Credentials_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Organisation_Credentials_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Organisation_Credentials_Order_By>>;
  where?: InputMaybe<Organisation_Credentials_Bool_Exp>;
};

export type Subscription_RootOrganisation_Credentials_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Subscription_RootOrganisation_Credentials_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<InputMaybe<Organisation_Credentials_Stream_Cursor_Input>>;
  where?: InputMaybe<Organisation_Credentials_Bool_Exp>;
};

export type Subscription_RootOrganisation_FlagsArgs = {
  distinct_on?: InputMaybe<Array<Organisation_Flags_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Organisation_Flags_Order_By>>;
  where?: InputMaybe<Organisation_Flags_Bool_Exp>;
};

export type Subscription_RootOrganisation_Flags_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Organisation_Flags_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Organisation_Flags_Order_By>>;
  where?: InputMaybe<Organisation_Flags_Bool_Exp>;
};

export type Subscription_RootOrganisation_Flags_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Subscription_RootOrganisation_Flags_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<InputMaybe<Organisation_Flags_Stream_Cursor_Input>>;
  where?: InputMaybe<Organisation_Flags_Bool_Exp>;
};

export type Subscription_RootOrganisation_InvitesArgs = {
  distinct_on?: InputMaybe<Array<Organisation_Invites_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Organisation_Invites_Order_By>>;
  where?: InputMaybe<Organisation_Invites_Bool_Exp>;
};

export type Subscription_RootOrganisation_Invites_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Organisation_Invites_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Organisation_Invites_Order_By>>;
  where?: InputMaybe<Organisation_Invites_Bool_Exp>;
};

export type Subscription_RootOrganisation_Invites_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Subscription_RootOrganisation_Invites_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<InputMaybe<Organisation_Invites_Stream_Cursor_Input>>;
  where?: InputMaybe<Organisation_Invites_Bool_Exp>;
};

export type Subscription_RootOrganisation_RulesArgs = {
  distinct_on?: InputMaybe<Array<Organisation_Rules_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Organisation_Rules_Order_By>>;
  where?: InputMaybe<Organisation_Rules_Bool_Exp>;
};

export type Subscription_RootOrganisation_Rules_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Organisation_Rules_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Organisation_Rules_Order_By>>;
  where?: InputMaybe<Organisation_Rules_Bool_Exp>;
};

export type Subscription_RootOrganisation_Rules_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Subscription_RootOrganisation_Rules_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<InputMaybe<Organisation_Rules_Stream_Cursor_Input>>;
  where?: InputMaybe<Organisation_Rules_Bool_Exp>;
};

export type Subscription_RootOrganisation_SettingsArgs = {
  distinct_on?: InputMaybe<Array<Organisation_Settings_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Organisation_Settings_Order_By>>;
  where?: InputMaybe<Organisation_Settings_Bool_Exp>;
};

export type Subscription_RootOrganisation_Settings_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Organisation_Settings_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Organisation_Settings_Order_By>>;
  where?: InputMaybe<Organisation_Settings_Bool_Exp>;
};

export type Subscription_RootOrganisation_Settings_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Subscription_RootOrganisation_Settings_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<InputMaybe<Organisation_Settings_Stream_Cursor_Input>>;
  where?: InputMaybe<Organisation_Settings_Bool_Exp>;
};

export type Subscription_RootOrganisation_StatusesArgs = {
  distinct_on?: InputMaybe<Array<Organisation_Statuses_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Organisation_Statuses_Order_By>>;
  where?: InputMaybe<Organisation_Statuses_Bool_Exp>;
};

export type Subscription_RootOrganisation_Statuses_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Organisation_Statuses_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Organisation_Statuses_Order_By>>;
  where?: InputMaybe<Organisation_Statuses_Bool_Exp>;
};

export type Subscription_RootOrganisation_Statuses_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Subscription_RootOrganisation_Statuses_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<InputMaybe<Organisation_Statuses_Stream_Cursor_Input>>;
  where?: InputMaybe<Organisation_Statuses_Bool_Exp>;
};

export type Subscription_RootOrganisationsArgs = {
  distinct_on?: InputMaybe<Array<Organisations_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Organisations_Order_By>>;
  where?: InputMaybe<Organisations_Bool_Exp>;
};

export type Subscription_RootOrganisations_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Organisations_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Organisations_Order_By>>;
  where?: InputMaybe<Organisations_Bool_Exp>;
};

export type Subscription_RootOrganisations_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Subscription_RootOrganisations_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<InputMaybe<Organisations_Stream_Cursor_Input>>;
  where?: InputMaybe<Organisations_Bool_Exp>;
};

export type Subscription_RootPartiesArgs = {
  distinct_on?: InputMaybe<Array<Parties_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Parties_Order_By>>;
  where?: InputMaybe<Parties_Bool_Exp>;
};

export type Subscription_RootParties_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Parties_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Parties_Order_By>>;
  where?: InputMaybe<Parties_Bool_Exp>;
};

export type Subscription_RootParties_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Subscription_RootParties_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<InputMaybe<Parties_Stream_Cursor_Input>>;
  where?: InputMaybe<Parties_Bool_Exp>;
};

export type Subscription_RootParty_ContactsArgs = {
  distinct_on?: InputMaybe<Array<Party_Contacts_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Party_Contacts_Order_By>>;
  where?: InputMaybe<Party_Contacts_Bool_Exp>;
};

export type Subscription_RootParty_Contacts_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Party_Contacts_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Party_Contacts_Order_By>>;
  where?: InputMaybe<Party_Contacts_Bool_Exp>;
};

export type Subscription_RootParty_Contacts_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Subscription_RootParty_Contacts_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<InputMaybe<Party_Contacts_Stream_Cursor_Input>>;
  where?: InputMaybe<Party_Contacts_Bool_Exp>;
};

export type Subscription_RootParty_DocumentsArgs = {
  distinct_on?: InputMaybe<Array<Party_Documents_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Party_Documents_Order_By>>;
  where?: InputMaybe<Party_Documents_Bool_Exp>;
};

export type Subscription_RootParty_Documents_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Party_Documents_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Party_Documents_Order_By>>;
  where?: InputMaybe<Party_Documents_Bool_Exp>;
};

export type Subscription_RootParty_Documents_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Subscription_RootParty_Documents_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<InputMaybe<Party_Documents_Stream_Cursor_Input>>;
  where?: InputMaybe<Party_Documents_Bool_Exp>;
};

export type Subscription_RootParty_FlagsArgs = {
  distinct_on?: InputMaybe<Array<Party_Flags_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Party_Flags_Order_By>>;
  where?: InputMaybe<Party_Flags_Bool_Exp>;
};

export type Subscription_RootParty_Flags_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Party_Flags_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Party_Flags_Order_By>>;
  where?: InputMaybe<Party_Flags_Bool_Exp>;
};

export type Subscription_RootParty_Flags_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Subscription_RootParty_Flags_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<InputMaybe<Party_Flags_Stream_Cursor_Input>>;
  where?: InputMaybe<Party_Flags_Bool_Exp>;
};

export type Subscription_RootParty_InteractionsArgs = {
  distinct_on?: InputMaybe<Array<Party_Interactions_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Party_Interactions_Order_By>>;
  where?: InputMaybe<Party_Interactions_Bool_Exp>;
};

export type Subscription_RootParty_Interactions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Party_Interactions_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Party_Interactions_Order_By>>;
  where?: InputMaybe<Party_Interactions_Bool_Exp>;
};

export type Subscription_RootParty_Interactions_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Subscription_RootParty_Interactions_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<InputMaybe<Party_Interactions_Stream_Cursor_Input>>;
  where?: InputMaybe<Party_Interactions_Bool_Exp>;
};

export type Subscription_RootParty_Ledger_AccountsArgs = {
  distinct_on?: InputMaybe<Array<Party_Ledger_Accounts_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Party_Ledger_Accounts_Order_By>>;
  where?: InputMaybe<Party_Ledger_Accounts_Bool_Exp>;
};

export type Subscription_RootParty_Ledger_Accounts_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Party_Ledger_Accounts_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Party_Ledger_Accounts_Order_By>>;
  where?: InputMaybe<Party_Ledger_Accounts_Bool_Exp>;
};

export type Subscription_RootParty_Ledger_Accounts_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Subscription_RootParty_Ledger_Accounts_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<InputMaybe<Party_Ledger_Accounts_Stream_Cursor_Input>>;
  where?: InputMaybe<Party_Ledger_Accounts_Bool_Exp>;
};

export type Subscription_RootParty_Relationship_StatusesArgs = {
  distinct_on?: InputMaybe<Array<Party_Relationship_Statuses_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Party_Relationship_Statuses_Order_By>>;
  where?: InputMaybe<Party_Relationship_Statuses_Bool_Exp>;
};

export type Subscription_RootParty_Relationship_Statuses_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Party_Relationship_Statuses_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Party_Relationship_Statuses_Order_By>>;
  where?: InputMaybe<Party_Relationship_Statuses_Bool_Exp>;
};

export type Subscription_RootParty_Relationship_Statuses_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Subscription_RootParty_Relationship_Statuses_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<InputMaybe<Party_Relationship_Statuses_Stream_Cursor_Input>>;
  where?: InputMaybe<Party_Relationship_Statuses_Bool_Exp>;
};

export type Subscription_RootParty_RelationshipsArgs = {
  distinct_on?: InputMaybe<Array<Party_Relationships_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Party_Relationships_Order_By>>;
  where?: InputMaybe<Party_Relationships_Bool_Exp>;
};

export type Subscription_RootParty_Relationships_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Party_Relationships_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Party_Relationships_Order_By>>;
  where?: InputMaybe<Party_Relationships_Bool_Exp>;
};

export type Subscription_RootParty_Relationships_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Subscription_RootParty_Relationships_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<InputMaybe<Party_Relationships_Stream_Cursor_Input>>;
  where?: InputMaybe<Party_Relationships_Bool_Exp>;
};

export type Subscription_RootParty_SettingsArgs = {
  distinct_on?: InputMaybe<Array<Party_Settings_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Party_Settings_Order_By>>;
  where?: InputMaybe<Party_Settings_Bool_Exp>;
};

export type Subscription_RootParty_Settings_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Party_Settings_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Party_Settings_Order_By>>;
  where?: InputMaybe<Party_Settings_Bool_Exp>;
};

export type Subscription_RootParty_Settings_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Subscription_RootParty_Settings_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<InputMaybe<Party_Settings_Stream_Cursor_Input>>;
  where?: InputMaybe<Party_Settings_Bool_Exp>;
};

export type Subscription_RootParty_StatusesArgs = {
  distinct_on?: InputMaybe<Array<Party_Statuses_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Party_Statuses_Order_By>>;
  where?: InputMaybe<Party_Statuses_Bool_Exp>;
};

export type Subscription_RootParty_Statuses_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Party_Statuses_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Party_Statuses_Order_By>>;
  where?: InputMaybe<Party_Statuses_Bool_Exp>;
};

export type Subscription_RootParty_Statuses_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Subscription_RootParty_Statuses_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<InputMaybe<Party_Statuses_Stream_Cursor_Input>>;
  where?: InputMaybe<Party_Statuses_Bool_Exp>;
};

export type Subscription_RootPerformance_IndicatorsArgs = {
  distinct_on?: InputMaybe<Array<Performance_Indicators_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Performance_Indicators_Order_By>>;
  where?: InputMaybe<Performance_Indicators_Bool_Exp>;
};

export type Subscription_RootPerformance_Indicators_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Performance_Indicators_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Performance_Indicators_Order_By>>;
  where?: InputMaybe<Performance_Indicators_Bool_Exp>;
};

export type Subscription_RootPerformance_Indicators_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Subscription_RootPerformance_Indicators_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<InputMaybe<Performance_Indicators_Stream_Cursor_Input>>;
  where?: InputMaybe<Performance_Indicators_Bool_Exp>;
};

export type Subscription_RootPerformance_TargetsArgs = {
  distinct_on?: InputMaybe<Array<Performance_Targets_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Performance_Targets_Order_By>>;
  where?: InputMaybe<Performance_Targets_Bool_Exp>;
};

export type Subscription_RootPerformance_Targets_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Performance_Targets_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Performance_Targets_Order_By>>;
  where?: InputMaybe<Performance_Targets_Bool_Exp>;
};

export type Subscription_RootPerformance_Targets_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Subscription_RootPerformance_Targets_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<InputMaybe<Performance_Targets_Stream_Cursor_Input>>;
  where?: InputMaybe<Performance_Targets_Bool_Exp>;
};

export type Subscription_RootProduct_DocumentsArgs = {
  distinct_on?: InputMaybe<Array<Product_Documents_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Product_Documents_Order_By>>;
  where?: InputMaybe<Product_Documents_Bool_Exp>;
};

export type Subscription_RootProduct_Documents_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Product_Documents_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Product_Documents_Order_By>>;
  where?: InputMaybe<Product_Documents_Bool_Exp>;
};

export type Subscription_RootProduct_Documents_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Subscription_RootProduct_Documents_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<InputMaybe<Product_Documents_Stream_Cursor_Input>>;
  where?: InputMaybe<Product_Documents_Bool_Exp>;
};

export type Subscription_RootProduct_InteractionsArgs = {
  distinct_on?: InputMaybe<Array<Product_Interactions_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Product_Interactions_Order_By>>;
  where?: InputMaybe<Product_Interactions_Bool_Exp>;
};

export type Subscription_RootProduct_Interactions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Product_Interactions_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Product_Interactions_Order_By>>;
  where?: InputMaybe<Product_Interactions_Bool_Exp>;
};

export type Subscription_RootProduct_Interactions_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Subscription_RootProduct_Interactions_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<InputMaybe<Product_Interactions_Stream_Cursor_Input>>;
  where?: InputMaybe<Product_Interactions_Bool_Exp>;
};

export type Subscription_RootProduct_ItemsArgs = {
  distinct_on?: InputMaybe<Array<Product_Items_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Product_Items_Order_By>>;
  where?: InputMaybe<Product_Items_Bool_Exp>;
};

export type Subscription_RootProduct_Items_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Product_Items_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Product_Items_Order_By>>;
  where?: InputMaybe<Product_Items_Bool_Exp>;
};

export type Subscription_RootProduct_Items_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Subscription_RootProduct_Items_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<InputMaybe<Product_Items_Stream_Cursor_Input>>;
  where?: InputMaybe<Product_Items_Bool_Exp>;
};

export type Subscription_RootProduct_RulesArgs = {
  distinct_on?: InputMaybe<Array<Product_Rules_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Product_Rules_Order_By>>;
  where?: InputMaybe<Product_Rules_Bool_Exp>;
};

export type Subscription_RootProduct_Rules_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Product_Rules_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Product_Rules_Order_By>>;
  where?: InputMaybe<Product_Rules_Bool_Exp>;
};

export type Subscription_RootProduct_Rules_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Subscription_RootProduct_Rules_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<InputMaybe<Product_Rules_Stream_Cursor_Input>>;
  where?: InputMaybe<Product_Rules_Bool_Exp>;
};

export type Subscription_RootProduct_SettingsArgs = {
  distinct_on?: InputMaybe<Array<Product_Settings_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Product_Settings_Order_By>>;
  where?: InputMaybe<Product_Settings_Bool_Exp>;
};

export type Subscription_RootProduct_Settings_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Product_Settings_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Product_Settings_Order_By>>;
  where?: InputMaybe<Product_Settings_Bool_Exp>;
};

export type Subscription_RootProduct_Settings_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Subscription_RootProduct_Settings_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<InputMaybe<Product_Settings_Stream_Cursor_Input>>;
  where?: InputMaybe<Product_Settings_Bool_Exp>;
};

export type Subscription_RootProduct_StatusesArgs = {
  distinct_on?: InputMaybe<Array<Product_Statuses_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Product_Statuses_Order_By>>;
  where?: InputMaybe<Product_Statuses_Bool_Exp>;
};

export type Subscription_RootProduct_Statuses_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Product_Statuses_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Product_Statuses_Order_By>>;
  where?: InputMaybe<Product_Statuses_Bool_Exp>;
};

export type Subscription_RootProduct_Statuses_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Subscription_RootProduct_Statuses_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<InputMaybe<Product_Statuses_Stream_Cursor_Input>>;
  where?: InputMaybe<Product_Statuses_Bool_Exp>;
};

export type Subscription_RootProduct_TemplatesArgs = {
  distinct_on?: InputMaybe<Array<Product_Templates_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Product_Templates_Order_By>>;
  where?: InputMaybe<Product_Templates_Bool_Exp>;
};

export type Subscription_RootProduct_Templates_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Product_Templates_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Product_Templates_Order_By>>;
  where?: InputMaybe<Product_Templates_Bool_Exp>;
};

export type Subscription_RootProduct_Templates_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Subscription_RootProduct_Templates_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<InputMaybe<Product_Templates_Stream_Cursor_Input>>;
  where?: InputMaybe<Product_Templates_Bool_Exp>;
};

export type Subscription_RootProductsArgs = {
  distinct_on?: InputMaybe<Array<Products_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Products_Order_By>>;
  where?: InputMaybe<Products_Bool_Exp>;
};

export type Subscription_RootProducts_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Products_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Products_Order_By>>;
  where?: InputMaybe<Products_Bool_Exp>;
};

export type Subscription_RootProducts_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Subscription_RootProducts_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<InputMaybe<Products_Stream_Cursor_Input>>;
  where?: InputMaybe<Products_Bool_Exp>;
};

export type Subscription_RootReward_RedemptionsArgs = {
  distinct_on?: InputMaybe<Array<Reward_Redemptions_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Reward_Redemptions_Order_By>>;
  where?: InputMaybe<Reward_Redemptions_Bool_Exp>;
};

export type Subscription_RootReward_Redemptions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Reward_Redemptions_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Reward_Redemptions_Order_By>>;
  where?: InputMaybe<Reward_Redemptions_Bool_Exp>;
};

export type Subscription_RootReward_Redemptions_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Subscription_RootReward_Redemptions_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<InputMaybe<Reward_Redemptions_Stream_Cursor_Input>>;
  where?: InputMaybe<Reward_Redemptions_Bool_Exp>;
};

export type Subscription_RootRewardsArgs = {
  distinct_on?: InputMaybe<Array<Rewards_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Rewards_Order_By>>;
  where?: InputMaybe<Rewards_Bool_Exp>;
};

export type Subscription_RootRewards_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Rewards_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Rewards_Order_By>>;
  where?: InputMaybe<Rewards_Bool_Exp>;
};

export type Subscription_RootRewards_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Subscription_RootRewards_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<InputMaybe<Rewards_Stream_Cursor_Input>>;
  where?: InputMaybe<Rewards_Bool_Exp>;
};

export type Subscription_RootRulesArgs = {
  distinct_on?: InputMaybe<Array<Rules_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Rules_Order_By>>;
  where?: InputMaybe<Rules_Bool_Exp>;
};

export type Subscription_RootRules_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Rules_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Rules_Order_By>>;
  where?: InputMaybe<Rules_Bool_Exp>;
};

export type Subscription_RootRules_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Subscription_RootRules_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<InputMaybe<Rules_Stream_Cursor_Input>>;
  where?: InputMaybe<Rules_Bool_Exp>;
};

export type Subscription_RootStatusesArgs = {
  distinct_on?: InputMaybe<Array<Statuses_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Statuses_Order_By>>;
  where?: InputMaybe<Statuses_Bool_Exp>;
};

export type Subscription_RootStatuses_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Statuses_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Statuses_Order_By>>;
  where?: InputMaybe<Statuses_Bool_Exp>;
};

export type Subscription_RootStatuses_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Subscription_RootStatuses_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<InputMaybe<Statuses_Stream_Cursor_Input>>;
  where?: InputMaybe<Statuses_Bool_Exp>;
};

export type Subscription_RootSubscription_CasesArgs = {
  distinct_on?: InputMaybe<Array<Subscription_Cases_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Subscription_Cases_Order_By>>;
  where?: InputMaybe<Subscription_Cases_Bool_Exp>;
};

export type Subscription_RootSubscription_Cases_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Subscription_Cases_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Subscription_Cases_Order_By>>;
  where?: InputMaybe<Subscription_Cases_Bool_Exp>;
};

export type Subscription_RootSubscription_Cases_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Subscription_RootSubscription_Cases_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<InputMaybe<Subscription_Cases_Stream_Cursor_Input>>;
  where?: InputMaybe<Subscription_Cases_Bool_Exp>;
};

export type Subscription_RootSubscription_DetailsArgs = {
  distinct_on?: InputMaybe<Array<Subscription_Details_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Subscription_Details_Order_By>>;
  where?: InputMaybe<Subscription_Details_Bool_Exp>;
};

export type Subscription_RootSubscription_Details_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Subscription_Details_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Subscription_Details_Order_By>>;
  where?: InputMaybe<Subscription_Details_Bool_Exp>;
};

export type Subscription_RootSubscription_Details_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Subscription_RootSubscription_Details_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<InputMaybe<Subscription_Details_Stream_Cursor_Input>>;
  where?: InputMaybe<Subscription_Details_Bool_Exp>;
};

export type Subscription_RootSubscription_DocumentsArgs = {
  distinct_on?: InputMaybe<Array<Subscription_Documents_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Subscription_Documents_Order_By>>;
  where?: InputMaybe<Subscription_Documents_Bool_Exp>;
};

export type Subscription_RootSubscription_Documents_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Subscription_Documents_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Subscription_Documents_Order_By>>;
  where?: InputMaybe<Subscription_Documents_Bool_Exp>;
};

export type Subscription_RootSubscription_Documents_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Subscription_RootSubscription_Documents_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<InputMaybe<Subscription_Documents_Stream_Cursor_Input>>;
  where?: InputMaybe<Subscription_Documents_Bool_Exp>;
};

export type Subscription_RootSubscription_FlagsArgs = {
  distinct_on?: InputMaybe<Array<Subscription_Flags_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Subscription_Flags_Order_By>>;
  where?: InputMaybe<Subscription_Flags_Bool_Exp>;
};

export type Subscription_RootSubscription_Flags_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Subscription_Flags_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Subscription_Flags_Order_By>>;
  where?: InputMaybe<Subscription_Flags_Bool_Exp>;
};

export type Subscription_RootSubscription_Flags_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Subscription_RootSubscription_Flags_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<InputMaybe<Subscription_Flags_Stream_Cursor_Input>>;
  where?: InputMaybe<Subscription_Flags_Bool_Exp>;
};

export type Subscription_RootSubscription_InteractionsArgs = {
  distinct_on?: InputMaybe<Array<Subscription_Interactions_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Subscription_Interactions_Order_By>>;
  where?: InputMaybe<Subscription_Interactions_Bool_Exp>;
};

export type Subscription_RootSubscription_Interactions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Subscription_Interactions_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Subscription_Interactions_Order_By>>;
  where?: InputMaybe<Subscription_Interactions_Bool_Exp>;
};

export type Subscription_RootSubscription_Interactions_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Subscription_RootSubscription_Interactions_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<InputMaybe<Subscription_Interactions_Stream_Cursor_Input>>;
  where?: InputMaybe<Subscription_Interactions_Bool_Exp>;
};

export type Subscription_RootSubscription_ItemsArgs = {
  distinct_on?: InputMaybe<Array<Subscription_Items_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Subscription_Items_Order_By>>;
  where?: InputMaybe<Subscription_Items_Bool_Exp>;
};

export type Subscription_RootSubscription_Items_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Subscription_Items_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Subscription_Items_Order_By>>;
  where?: InputMaybe<Subscription_Items_Bool_Exp>;
};

export type Subscription_RootSubscription_Items_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Subscription_RootSubscription_Items_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<InputMaybe<Subscription_Items_Stream_Cursor_Input>>;
  where?: InputMaybe<Subscription_Items_Bool_Exp>;
};

export type Subscription_RootSubscription_Payment_TransactionsArgs = {
  distinct_on?: InputMaybe<
    Array<Subscription_Payment_Transactions_Select_Column>
  >;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Subscription_Payment_Transactions_Order_By>>;
  where?: InputMaybe<Subscription_Payment_Transactions_Bool_Exp>;
};

export type Subscription_RootSubscription_Payment_Transactions_AggregateArgs = {
  distinct_on?: InputMaybe<
    Array<Subscription_Payment_Transactions_Select_Column>
  >;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Subscription_Payment_Transactions_Order_By>>;
  where?: InputMaybe<Subscription_Payment_Transactions_Bool_Exp>;
};

export type Subscription_RootSubscription_Payment_Transactions_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Subscription_RootSubscription_Payment_Transactions_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<
    InputMaybe<Subscription_Payment_Transactions_Stream_Cursor_Input>
  >;
  where?: InputMaybe<Subscription_Payment_Transactions_Bool_Exp>;
};

export type Subscription_RootSubscription_PaymentsArgs = {
  distinct_on?: InputMaybe<Array<Subscription_Payments_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Subscription_Payments_Order_By>>;
  where?: InputMaybe<Subscription_Payments_Bool_Exp>;
};

export type Subscription_RootSubscription_Payments_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Subscription_Payments_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Subscription_Payments_Order_By>>;
  where?: InputMaybe<Subscription_Payments_Bool_Exp>;
};

export type Subscription_RootSubscription_Payments_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Subscription_RootSubscription_Payments_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<InputMaybe<Subscription_Payments_Stream_Cursor_Input>>;
  where?: InputMaybe<Subscription_Payments_Bool_Exp>;
};

export type Subscription_RootSubscription_RulesArgs = {
  distinct_on?: InputMaybe<Array<Subscription_Rules_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Subscription_Rules_Order_By>>;
  where?: InputMaybe<Subscription_Rules_Bool_Exp>;
};

export type Subscription_RootSubscription_Rules_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Subscription_Rules_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Subscription_Rules_Order_By>>;
  where?: InputMaybe<Subscription_Rules_Bool_Exp>;
};

export type Subscription_RootSubscription_Rules_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Subscription_RootSubscription_Rules_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<InputMaybe<Subscription_Rules_Stream_Cursor_Input>>;
  where?: InputMaybe<Subscription_Rules_Bool_Exp>;
};

export type Subscription_RootSubscription_SchedulesArgs = {
  distinct_on?: InputMaybe<Array<Subscription_Schedules_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Subscription_Schedules_Order_By>>;
  where?: InputMaybe<Subscription_Schedules_Bool_Exp>;
};

export type Subscription_RootSubscription_Schedules_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Subscription_Schedules_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Subscription_Schedules_Order_By>>;
  where?: InputMaybe<Subscription_Schedules_Bool_Exp>;
};

export type Subscription_RootSubscription_Schedules_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Subscription_RootSubscription_Schedules_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<InputMaybe<Subscription_Schedules_Stream_Cursor_Input>>;
  where?: InputMaybe<Subscription_Schedules_Bool_Exp>;
};

export type Subscription_RootSubscription_StatusesArgs = {
  distinct_on?: InputMaybe<Array<Subscription_Statuses_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Subscription_Statuses_Order_By>>;
  where?: InputMaybe<Subscription_Statuses_Bool_Exp>;
};

export type Subscription_RootSubscription_Statuses_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Subscription_Statuses_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Subscription_Statuses_Order_By>>;
  where?: InputMaybe<Subscription_Statuses_Bool_Exp>;
};

export type Subscription_RootSubscription_Statuses_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Subscription_RootSubscription_Statuses_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<InputMaybe<Subscription_Statuses_Stream_Cursor_Input>>;
  where?: InputMaybe<Subscription_Statuses_Bool_Exp>;
};

export type Subscription_RootSubscription_TasksArgs = {
  distinct_on?: InputMaybe<Array<Subscription_Tasks_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Subscription_Tasks_Order_By>>;
  where?: InputMaybe<Subscription_Tasks_Bool_Exp>;
};

export type Subscription_RootSubscription_Tasks_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Subscription_Tasks_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Subscription_Tasks_Order_By>>;
  where?: InputMaybe<Subscription_Tasks_Bool_Exp>;
};

export type Subscription_RootSubscription_Tasks_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Subscription_RootSubscription_Tasks_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<InputMaybe<Subscription_Tasks_Stream_Cursor_Input>>;
  where?: InputMaybe<Subscription_Tasks_Bool_Exp>;
};

export type Subscription_RootSubscriptionsArgs = {
  distinct_on?: InputMaybe<Array<Subscriptions_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Subscriptions_Order_By>>;
  where?: InputMaybe<Subscriptions_Bool_Exp>;
};

export type Subscription_RootSubscriptions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Subscriptions_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Subscriptions_Order_By>>;
  where?: InputMaybe<Subscriptions_Bool_Exp>;
};

export type Subscription_RootSubscriptions_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Subscription_RootSubscriptions_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<InputMaybe<Subscriptions_Stream_Cursor_Input>>;
  where?: InputMaybe<Subscriptions_Bool_Exp>;
};

export type Subscription_RootTariff_HistoriesArgs = {
  distinct_on?: InputMaybe<Array<Tariff_Histories_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Tariff_Histories_Order_By>>;
  where?: InputMaybe<Tariff_Histories_Bool_Exp>;
};

export type Subscription_RootTariff_Histories_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tariff_Histories_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Tariff_Histories_Order_By>>;
  where?: InputMaybe<Tariff_Histories_Bool_Exp>;
};

export type Subscription_RootTariff_Histories_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Subscription_RootTariff_Histories_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<InputMaybe<Tariff_Histories_Stream_Cursor_Input>>;
  where?: InputMaybe<Tariff_Histories_Bool_Exp>;
};

export type Subscription_RootTariffsArgs = {
  distinct_on?: InputMaybe<Array<Tariffs_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Tariffs_Order_By>>;
  where?: InputMaybe<Tariffs_Bool_Exp>;
};

export type Subscription_RootTariffs_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tariffs_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Tariffs_Order_By>>;
  where?: InputMaybe<Tariffs_Bool_Exp>;
};

export type Subscription_RootTariffs_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Subscription_RootTariffs_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<InputMaybe<Tariffs_Stream_Cursor_Input>>;
  where?: InputMaybe<Tariffs_Bool_Exp>;
};

export type Subscription_RootTask_AssignmentsArgs = {
  distinct_on?: InputMaybe<Array<Task_Assignments_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Task_Assignments_Order_By>>;
  where?: InputMaybe<Task_Assignments_Bool_Exp>;
};

export type Subscription_RootTask_Assignments_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Task_Assignments_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Task_Assignments_Order_By>>;
  where?: InputMaybe<Task_Assignments_Bool_Exp>;
};

export type Subscription_RootTask_Assignments_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Subscription_RootTask_Assignments_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<InputMaybe<Task_Assignments_Stream_Cursor_Input>>;
  where?: InputMaybe<Task_Assignments_Bool_Exp>;
};

export type Subscription_RootTask_DocumentsArgs = {
  distinct_on?: InputMaybe<Array<Task_Documents_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Task_Documents_Order_By>>;
  where?: InputMaybe<Task_Documents_Bool_Exp>;
};

export type Subscription_RootTask_Documents_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Task_Documents_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Task_Documents_Order_By>>;
  where?: InputMaybe<Task_Documents_Bool_Exp>;
};

export type Subscription_RootTask_Documents_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Subscription_RootTask_Documents_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<InputMaybe<Task_Documents_Stream_Cursor_Input>>;
  where?: InputMaybe<Task_Documents_Bool_Exp>;
};

export type Subscription_RootTask_FlagsArgs = {
  distinct_on?: InputMaybe<Array<Task_Flags_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Task_Flags_Order_By>>;
  where?: InputMaybe<Task_Flags_Bool_Exp>;
};

export type Subscription_RootTask_Flags_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Task_Flags_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Task_Flags_Order_By>>;
  where?: InputMaybe<Task_Flags_Bool_Exp>;
};

export type Subscription_RootTask_Flags_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Subscription_RootTask_Flags_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<InputMaybe<Task_Flags_Stream_Cursor_Input>>;
  where?: InputMaybe<Task_Flags_Bool_Exp>;
};

export type Subscription_RootTask_InteractionsArgs = {
  distinct_on?: InputMaybe<Array<Task_Interactions_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Task_Interactions_Order_By>>;
  where?: InputMaybe<Task_Interactions_Bool_Exp>;
};

export type Subscription_RootTask_Interactions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Task_Interactions_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Task_Interactions_Order_By>>;
  where?: InputMaybe<Task_Interactions_Bool_Exp>;
};

export type Subscription_RootTask_Interactions_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Subscription_RootTask_Interactions_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<InputMaybe<Task_Interactions_Stream_Cursor_Input>>;
  where?: InputMaybe<Task_Interactions_Bool_Exp>;
};

export type Subscription_RootTask_MentionsArgs = {
  distinct_on?: InputMaybe<Array<Task_Mentions_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Task_Mentions_Order_By>>;
  where?: InputMaybe<Task_Mentions_Bool_Exp>;
};

export type Subscription_RootTask_Mentions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Task_Mentions_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Task_Mentions_Order_By>>;
  where?: InputMaybe<Task_Mentions_Bool_Exp>;
};

export type Subscription_RootTask_Mentions_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Subscription_RootTask_Mentions_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<InputMaybe<Task_Mentions_Stream_Cursor_Input>>;
  where?: InputMaybe<Task_Mentions_Bool_Exp>;
};

export type Subscription_RootTask_RemindersArgs = {
  distinct_on?: InputMaybe<Array<Task_Reminders_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Task_Reminders_Order_By>>;
  where?: InputMaybe<Task_Reminders_Bool_Exp>;
};

export type Subscription_RootTask_Reminders_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Task_Reminders_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Task_Reminders_Order_By>>;
  where?: InputMaybe<Task_Reminders_Bool_Exp>;
};

export type Subscription_RootTask_Reminders_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Subscription_RootTask_Reminders_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<InputMaybe<Task_Reminders_Stream_Cursor_Input>>;
  where?: InputMaybe<Task_Reminders_Bool_Exp>;
};

export type Subscription_RootTask_RulesArgs = {
  distinct_on?: InputMaybe<Array<Task_Rules_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Task_Rules_Order_By>>;
  where?: InputMaybe<Task_Rules_Bool_Exp>;
};

export type Subscription_RootTask_Rules_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Task_Rules_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Task_Rules_Order_By>>;
  where?: InputMaybe<Task_Rules_Bool_Exp>;
};

export type Subscription_RootTask_Rules_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Subscription_RootTask_Rules_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<InputMaybe<Task_Rules_Stream_Cursor_Input>>;
  where?: InputMaybe<Task_Rules_Bool_Exp>;
};

export type Subscription_RootTask_StatusesArgs = {
  distinct_on?: InputMaybe<Array<Task_Statuses_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Task_Statuses_Order_By>>;
  where?: InputMaybe<Task_Statuses_Bool_Exp>;
};

export type Subscription_RootTask_Statuses_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Task_Statuses_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Task_Statuses_Order_By>>;
  where?: InputMaybe<Task_Statuses_Bool_Exp>;
};

export type Subscription_RootTask_Statuses_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Subscription_RootTask_Statuses_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<InputMaybe<Task_Statuses_Stream_Cursor_Input>>;
  where?: InputMaybe<Task_Statuses_Bool_Exp>;
};

export type Subscription_RootTasksArgs = {
  distinct_on?: InputMaybe<Array<Tasks_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Tasks_Order_By>>;
  where?: InputMaybe<Tasks_Bool_Exp>;
};

export type Subscription_RootTasks_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tasks_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Tasks_Order_By>>;
  where?: InputMaybe<Tasks_Bool_Exp>;
};

export type Subscription_RootTasks_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Subscription_RootTasks_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<InputMaybe<Tasks_Stream_Cursor_Input>>;
  where?: InputMaybe<Tasks_Bool_Exp>;
};

export type Subscription_RootTerritoriesArgs = {
  distinct_on?: InputMaybe<Array<Territories_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Territories_Order_By>>;
  where?: InputMaybe<Territories_Bool_Exp>;
};

export type Subscription_RootTerritories_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Territories_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Territories_Order_By>>;
  where?: InputMaybe<Territories_Bool_Exp>;
};

export type Subscription_RootTerritories_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Subscription_RootTerritories_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<InputMaybe<Territories_Stream_Cursor_Input>>;
  where?: InputMaybe<Territories_Bool_Exp>;
};

export type Subscription_RootTerritory_AssignmentsArgs = {
  distinct_on?: InputMaybe<Array<Territory_Assignments_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Territory_Assignments_Order_By>>;
  where?: InputMaybe<Territory_Assignments_Bool_Exp>;
};

export type Subscription_RootTerritory_Assignments_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Territory_Assignments_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Territory_Assignments_Order_By>>;
  where?: InputMaybe<Territory_Assignments_Bool_Exp>;
};

export type Subscription_RootTerritory_Assignments_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Subscription_RootTerritory_Assignments_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<InputMaybe<Territory_Assignments_Stream_Cursor_Input>>;
  where?: InputMaybe<Territory_Assignments_Bool_Exp>;
};

export type Subscription_RootTerritory_FlagsArgs = {
  distinct_on?: InputMaybe<Array<Territory_Flags_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Territory_Flags_Order_By>>;
  where?: InputMaybe<Territory_Flags_Bool_Exp>;
};

export type Subscription_RootTerritory_Flags_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Territory_Flags_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Territory_Flags_Order_By>>;
  where?: InputMaybe<Territory_Flags_Bool_Exp>;
};

export type Subscription_RootTerritory_Flags_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Subscription_RootTerritory_Flags_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<InputMaybe<Territory_Flags_Stream_Cursor_Input>>;
  where?: InputMaybe<Territory_Flags_Bool_Exp>;
};

export type Subscription_RootTerritory_InteractionsArgs = {
  distinct_on?: InputMaybe<Array<Territory_Interactions_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Territory_Interactions_Order_By>>;
  where?: InputMaybe<Territory_Interactions_Bool_Exp>;
};

export type Subscription_RootTerritory_Interactions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Territory_Interactions_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Territory_Interactions_Order_By>>;
  where?: InputMaybe<Territory_Interactions_Bool_Exp>;
};

export type Subscription_RootTerritory_Interactions_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Subscription_RootTerritory_Interactions_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<InputMaybe<Territory_Interactions_Stream_Cursor_Input>>;
  where?: InputMaybe<Territory_Interactions_Bool_Exp>;
};

export type Subscription_RootTerritory_MembersArgs = {
  distinct_on?: InputMaybe<Array<Territory_Members_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Territory_Members_Order_By>>;
  where?: InputMaybe<Territory_Members_Bool_Exp>;
};

export type Subscription_RootTerritory_Members_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Territory_Members_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Territory_Members_Order_By>>;
  where?: InputMaybe<Territory_Members_Bool_Exp>;
};

export type Subscription_RootTerritory_Members_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Subscription_RootTerritory_Members_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<InputMaybe<Territory_Members_Stream_Cursor_Input>>;
  where?: InputMaybe<Territory_Members_Bool_Exp>;
};

export type Subscription_RootTerritory_RulesArgs = {
  distinct_on?: InputMaybe<Array<Territory_Rules_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Territory_Rules_Order_By>>;
  where?: InputMaybe<Territory_Rules_Bool_Exp>;
};

export type Subscription_RootTerritory_Rules_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Territory_Rules_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Territory_Rules_Order_By>>;
  where?: InputMaybe<Territory_Rules_Bool_Exp>;
};

export type Subscription_RootTerritory_Rules_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Subscription_RootTerritory_Rules_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<InputMaybe<Territory_Rules_Stream_Cursor_Input>>;
  where?: InputMaybe<Territory_Rules_Bool_Exp>;
};

export type Subscription_RootTerritory_StatusesArgs = {
  distinct_on?: InputMaybe<Array<Territory_Statuses_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Territory_Statuses_Order_By>>;
  where?: InputMaybe<Territory_Statuses_Bool_Exp>;
};

export type Subscription_RootTerritory_Statuses_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Territory_Statuses_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Territory_Statuses_Order_By>>;
  where?: InputMaybe<Territory_Statuses_Bool_Exp>;
};

export type Subscription_RootTerritory_Statuses_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Subscription_RootTerritory_Statuses_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<InputMaybe<Territory_Statuses_Stream_Cursor_Input>>;
  where?: InputMaybe<Territory_Statuses_Bool_Exp>;
};

export type Subscription_RootTypesArgs = {
  distinct_on?: InputMaybe<Array<Types_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Types_Order_By>>;
  where?: InputMaybe<Types_Bool_Exp>;
};

export type Subscription_RootTypes_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Types_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Types_Order_By>>;
  where?: InputMaybe<Types_Bool_Exp>;
};

export type Subscription_RootTypes_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Subscription_RootTypes_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<InputMaybe<Types_Stream_Cursor_Input>>;
  where?: InputMaybe<Types_Bool_Exp>;
};

export type Subscription_RootUser_FlagsArgs = {
  distinct_on?: InputMaybe<Array<User_Flags_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<User_Flags_Order_By>>;
  where?: InputMaybe<User_Flags_Bool_Exp>;
};

export type Subscription_RootUser_Flags_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Flags_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<User_Flags_Order_By>>;
  where?: InputMaybe<User_Flags_Bool_Exp>;
};

export type Subscription_RootUser_Flags_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Subscription_RootUser_Flags_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<InputMaybe<User_Flags_Stream_Cursor_Input>>;
  where?: InputMaybe<User_Flags_Bool_Exp>;
};

export type Subscription_RootUser_Mfa_FactorsArgs = {
  distinct_on?: InputMaybe<Array<User_Mfa_Factors_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<User_Mfa_Factors_Order_By>>;
  where?: InputMaybe<User_Mfa_Factors_Bool_Exp>;
};

export type Subscription_RootUser_Mfa_Factors_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Mfa_Factors_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<User_Mfa_Factors_Order_By>>;
  where?: InputMaybe<User_Mfa_Factors_Bool_Exp>;
};

export type Subscription_RootUser_Mfa_Factors_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Subscription_RootUser_Mfa_Factors_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<InputMaybe<User_Mfa_Factors_Stream_Cursor_Input>>;
  where?: InputMaybe<User_Mfa_Factors_Bool_Exp>;
};

export type Subscription_RootUser_StatusesArgs = {
  distinct_on?: InputMaybe<Array<User_Statuses_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<User_Statuses_Order_By>>;
  where?: InputMaybe<User_Statuses_Bool_Exp>;
};

export type Subscription_RootUser_Statuses_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Statuses_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<User_Statuses_Order_By>>;
  where?: InputMaybe<User_Statuses_Bool_Exp>;
};

export type Subscription_RootUser_Statuses_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Subscription_RootUser_Statuses_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<InputMaybe<User_Statuses_Stream_Cursor_Input>>;
  where?: InputMaybe<User_Statuses_Bool_Exp>;
};

export type Subscription_RootUsersArgs = {
  distinct_on?: InputMaybe<Array<Users_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Users_Order_By>>;
  where?: InputMaybe<Users_Bool_Exp>;
};

export type Subscription_RootUsers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Users_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Users_Order_By>>;
  where?: InputMaybe<Users_Bool_Exp>;
};

export type Subscription_RootUsers_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Subscription_RootUsers_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<InputMaybe<Users_Stream_Cursor_Input>>;
  where?: InputMaybe<Users_Bool_Exp>;
};

export type Subscription_RootWebhooksArgs = {
  distinct_on?: InputMaybe<Array<Webhooks_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Webhooks_Order_By>>;
  where?: InputMaybe<Webhooks_Bool_Exp>;
};

export type Subscription_RootWebhooks_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Webhooks_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Webhooks_Order_By>>;
  where?: InputMaybe<Webhooks_Bool_Exp>;
};

export type Subscription_RootWebhooks_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Subscription_RootWebhooks_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<InputMaybe<Webhooks_Stream_Cursor_Input>>;
  where?: InputMaybe<Webhooks_Bool_Exp>;
};

export type Subscription_RootWorkflow_TemplatesArgs = {
  distinct_on?: InputMaybe<Array<Workflow_Templates_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Workflow_Templates_Order_By>>;
  where?: InputMaybe<Workflow_Templates_Bool_Exp>;
};

export type Subscription_RootWorkflow_Templates_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Workflow_Templates_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Workflow_Templates_Order_By>>;
  where?: InputMaybe<Workflow_Templates_Bool_Exp>;
};

export type Subscription_RootWorkflow_Templates_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Subscription_RootWorkflow_Templates_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<InputMaybe<Workflow_Templates_Stream_Cursor_Input>>;
  where?: InputMaybe<Workflow_Templates_Bool_Exp>;
};

/** columns and relationships of "subscription_rules" */
export type Subscription_Rules = {
  __typename?: "subscription_rules";
  created_at?: Maybe<Scalars["timestamptz"]>;
  id: Scalars["uuid"];
  /** An object relationship */
  rule: Rules;
  rule_id: Scalars["uuid"];
  /** An object relationship */
  subscription: Subscriptions;
  subscription_id: Scalars["uuid"];
};

/** aggregated selection of "subscription_rules" */
export type Subscription_Rules_Aggregate = {
  __typename?: "subscription_rules_aggregate";
  aggregate?: Maybe<Subscription_Rules_Aggregate_Fields>;
  nodes: Array<Subscription_Rules>;
};

export type Subscription_Rules_Aggregate_Bool_Exp = {
  count?: InputMaybe<Subscription_Rules_Aggregate_Bool_Exp_Count>;
};

export type Subscription_Rules_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Subscription_Rules_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<Subscription_Rules_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "subscription_rules" */
export type Subscription_Rules_Aggregate_Fields = {
  __typename?: "subscription_rules_aggregate_fields";
  count: Scalars["Int"];
  max?: Maybe<Subscription_Rules_Max_Fields>;
  min?: Maybe<Subscription_Rules_Min_Fields>;
};

/** aggregate fields of "subscription_rules" */
export type Subscription_Rules_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Subscription_Rules_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "subscription_rules" */
export type Subscription_Rules_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Subscription_Rules_Max_Order_By>;
  min?: InputMaybe<Subscription_Rules_Min_Order_By>;
};

/** input type for inserting array relation for remote table "subscription_rules" */
export type Subscription_Rules_Arr_Rel_Insert_Input = {
  data: Array<Subscription_Rules_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Subscription_Rules_On_Conflict>;
};

/** Boolean expression to filter rows from the table "subscription_rules". All fields are combined with a logical 'AND'. */
export type Subscription_Rules_Bool_Exp = {
  _and?: InputMaybe<Array<Subscription_Rules_Bool_Exp>>;
  _not?: InputMaybe<Subscription_Rules_Bool_Exp>;
  _or?: InputMaybe<Array<Subscription_Rules_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  rule?: InputMaybe<Rules_Bool_Exp>;
  rule_id?: InputMaybe<Uuid_Comparison_Exp>;
  subscription?: InputMaybe<Subscriptions_Bool_Exp>;
  subscription_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "subscription_rules" */
export enum Subscription_Rules_Constraint {
  /** unique or primary key constraint on columns "id" */
  SubscriptionRulesPkey = "subscription_rules_pkey",
  /** unique or primary key constraint on columns "rule_id", "subscription_id" */
  UniqueSubscriptionRules = "unique_subscription_rules",
}

/** input type for inserting data into table "subscription_rules" */
export type Subscription_Rules_Insert_Input = {
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  rule?: InputMaybe<Rules_Obj_Rel_Insert_Input>;
  rule_id?: InputMaybe<Scalars["uuid"]>;
  subscription?: InputMaybe<Subscriptions_Obj_Rel_Insert_Input>;
  subscription_id?: InputMaybe<Scalars["uuid"]>;
};

/** aggregate max on columns */
export type Subscription_Rules_Max_Fields = {
  __typename?: "subscription_rules_max_fields";
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["uuid"]>;
  rule_id?: Maybe<Scalars["uuid"]>;
  subscription_id?: Maybe<Scalars["uuid"]>;
};

/** order by max() on columns of table "subscription_rules" */
export type Subscription_Rules_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  rule_id?: InputMaybe<Order_By>;
  subscription_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Subscription_Rules_Min_Fields = {
  __typename?: "subscription_rules_min_fields";
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["uuid"]>;
  rule_id?: Maybe<Scalars["uuid"]>;
  subscription_id?: Maybe<Scalars["uuid"]>;
};

/** order by min() on columns of table "subscription_rules" */
export type Subscription_Rules_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  rule_id?: InputMaybe<Order_By>;
  subscription_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "subscription_rules" */
export type Subscription_Rules_Mutation_Response = {
  __typename?: "subscription_rules_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Subscription_Rules>;
};

/** on_conflict condition type for table "subscription_rules" */
export type Subscription_Rules_On_Conflict = {
  constraint: Subscription_Rules_Constraint;
  update_columns?: Array<Subscription_Rules_Update_Column>;
  where?: InputMaybe<Subscription_Rules_Bool_Exp>;
};

/** Ordering options when selecting data from "subscription_rules". */
export type Subscription_Rules_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  rule?: InputMaybe<Rules_Order_By>;
  rule_id?: InputMaybe<Order_By>;
  subscription?: InputMaybe<Subscriptions_Order_By>;
  subscription_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: subscription_rules */
export type Subscription_Rules_Pk_Columns_Input = {
  id: Scalars["uuid"];
};

/** select columns of table "subscription_rules" */
export enum Subscription_Rules_Select_Column {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  Id = "id",
  /** column name */
  RuleId = "rule_id",
  /** column name */
  SubscriptionId = "subscription_id",
}

/** input type for updating data in table "subscription_rules" */
export type Subscription_Rules_Set_Input = {
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  rule_id?: InputMaybe<Scalars["uuid"]>;
  subscription_id?: InputMaybe<Scalars["uuid"]>;
};

/** Streaming cursor of the table "subscription_rules" */
export type Subscription_Rules_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Subscription_Rules_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Subscription_Rules_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  rule_id?: InputMaybe<Scalars["uuid"]>;
  subscription_id?: InputMaybe<Scalars["uuid"]>;
};

/** update columns of table "subscription_rules" */
export enum Subscription_Rules_Update_Column {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  Id = "id",
  /** column name */
  RuleId = "rule_id",
  /** column name */
  SubscriptionId = "subscription_id",
}

export type Subscription_Rules_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Subscription_Rules_Set_Input>;
  /** filter the rows which have to be updated */
  where: Subscription_Rules_Bool_Exp;
};

/** columns and relationships of "subscription_schedules" */
export type Subscription_Schedules = {
  __typename?: "subscription_schedules";
  actual_payment: Scalars["numeric"];
  beginning_balance: Scalars["numeric"];
  charges: Scalars["numeric"];
  created_at: Scalars["timestamptz"];
  due_date: Scalars["date"];
  ending_balance: Scalars["numeric"];
  extra_payment: Scalars["numeric"];
  id: Scalars["uuid"];
  interest: Scalars["numeric"];
  last_updated_by?: Maybe<Scalars["uuid"]>;
  metadata?: Maybe<Scalars["jsonb"]>;
  /** An object relationship */
  organisation: Organisations;
  organisation_id: Scalars["uuid"];
  /** An object relationship */
  party: Parties;
  /** An object relationship */
  partyByLastUpdatedBy?: Maybe<Parties>;
  party_id: Scalars["uuid"];
  penalties: Scalars["numeric"];
  principal: Scalars["numeric"];
  /** An object relationship */
  product: Products;
  product_id: Scalars["uuid"];
  scheduled_payment: Scalars["numeric"];
  /** An object relationship */
  subscription: Subscriptions;
  subscription_id: Scalars["uuid"];
  total_expected_payment?: Maybe<Scalars["numeric"]>;
  updated_at: Scalars["timestamptz"];
};

/** columns and relationships of "subscription_schedules" */
export type Subscription_SchedulesMetadataArgs = {
  path?: InputMaybe<Scalars["String"]>;
};

/** aggregated selection of "subscription_schedules" */
export type Subscription_Schedules_Aggregate = {
  __typename?: "subscription_schedules_aggregate";
  aggregate?: Maybe<Subscription_Schedules_Aggregate_Fields>;
  nodes: Array<Subscription_Schedules>;
};

export type Subscription_Schedules_Aggregate_Bool_Exp = {
  count?: InputMaybe<Subscription_Schedules_Aggregate_Bool_Exp_Count>;
};

export type Subscription_Schedules_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Subscription_Schedules_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<Subscription_Schedules_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "subscription_schedules" */
export type Subscription_Schedules_Aggregate_Fields = {
  __typename?: "subscription_schedules_aggregate_fields";
  avg?: Maybe<Subscription_Schedules_Avg_Fields>;
  count: Scalars["Int"];
  max?: Maybe<Subscription_Schedules_Max_Fields>;
  min?: Maybe<Subscription_Schedules_Min_Fields>;
  stddev?: Maybe<Subscription_Schedules_Stddev_Fields>;
  stddev_pop?: Maybe<Subscription_Schedules_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Subscription_Schedules_Stddev_Samp_Fields>;
  sum?: Maybe<Subscription_Schedules_Sum_Fields>;
  var_pop?: Maybe<Subscription_Schedules_Var_Pop_Fields>;
  var_samp?: Maybe<Subscription_Schedules_Var_Samp_Fields>;
  variance?: Maybe<Subscription_Schedules_Variance_Fields>;
};

/** aggregate fields of "subscription_schedules" */
export type Subscription_Schedules_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Subscription_Schedules_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "subscription_schedules" */
export type Subscription_Schedules_Aggregate_Order_By = {
  avg?: InputMaybe<Subscription_Schedules_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Subscription_Schedules_Max_Order_By>;
  min?: InputMaybe<Subscription_Schedules_Min_Order_By>;
  stddev?: InputMaybe<Subscription_Schedules_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Subscription_Schedules_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Subscription_Schedules_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Subscription_Schedules_Sum_Order_By>;
  var_pop?: InputMaybe<Subscription_Schedules_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Subscription_Schedules_Var_Samp_Order_By>;
  variance?: InputMaybe<Subscription_Schedules_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Subscription_Schedules_Append_Input = {
  metadata?: InputMaybe<Scalars["jsonb"]>;
};

/** input type for inserting array relation for remote table "subscription_schedules" */
export type Subscription_Schedules_Arr_Rel_Insert_Input = {
  data: Array<Subscription_Schedules_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Subscription_Schedules_On_Conflict>;
};

/** aggregate avg on columns */
export type Subscription_Schedules_Avg_Fields = {
  __typename?: "subscription_schedules_avg_fields";
  actual_payment?: Maybe<Scalars["Float"]>;
  beginning_balance?: Maybe<Scalars["Float"]>;
  charges?: Maybe<Scalars["Float"]>;
  ending_balance?: Maybe<Scalars["Float"]>;
  extra_payment?: Maybe<Scalars["Float"]>;
  interest?: Maybe<Scalars["Float"]>;
  penalties?: Maybe<Scalars["Float"]>;
  principal?: Maybe<Scalars["Float"]>;
  scheduled_payment?: Maybe<Scalars["Float"]>;
  total_expected_payment?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "subscription_schedules" */
export type Subscription_Schedules_Avg_Order_By = {
  actual_payment?: InputMaybe<Order_By>;
  beginning_balance?: InputMaybe<Order_By>;
  charges?: InputMaybe<Order_By>;
  ending_balance?: InputMaybe<Order_By>;
  extra_payment?: InputMaybe<Order_By>;
  interest?: InputMaybe<Order_By>;
  penalties?: InputMaybe<Order_By>;
  principal?: InputMaybe<Order_By>;
  scheduled_payment?: InputMaybe<Order_By>;
  total_expected_payment?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "subscription_schedules". All fields are combined with a logical 'AND'. */
export type Subscription_Schedules_Bool_Exp = {
  _and?: InputMaybe<Array<Subscription_Schedules_Bool_Exp>>;
  _not?: InputMaybe<Subscription_Schedules_Bool_Exp>;
  _or?: InputMaybe<Array<Subscription_Schedules_Bool_Exp>>;
  actual_payment?: InputMaybe<Numeric_Comparison_Exp>;
  beginning_balance?: InputMaybe<Numeric_Comparison_Exp>;
  charges?: InputMaybe<Numeric_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  due_date?: InputMaybe<Date_Comparison_Exp>;
  ending_balance?: InputMaybe<Numeric_Comparison_Exp>;
  extra_payment?: InputMaybe<Numeric_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  interest?: InputMaybe<Numeric_Comparison_Exp>;
  last_updated_by?: InputMaybe<Uuid_Comparison_Exp>;
  metadata?: InputMaybe<Jsonb_Comparison_Exp>;
  organisation?: InputMaybe<Organisations_Bool_Exp>;
  organisation_id?: InputMaybe<Uuid_Comparison_Exp>;
  party?: InputMaybe<Parties_Bool_Exp>;
  partyByLastUpdatedBy?: InputMaybe<Parties_Bool_Exp>;
  party_id?: InputMaybe<Uuid_Comparison_Exp>;
  penalties?: InputMaybe<Numeric_Comparison_Exp>;
  principal?: InputMaybe<Numeric_Comparison_Exp>;
  product?: InputMaybe<Products_Bool_Exp>;
  product_id?: InputMaybe<Uuid_Comparison_Exp>;
  scheduled_payment?: InputMaybe<Numeric_Comparison_Exp>;
  subscription?: InputMaybe<Subscriptions_Bool_Exp>;
  subscription_id?: InputMaybe<Uuid_Comparison_Exp>;
  total_expected_payment?: InputMaybe<Numeric_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "subscription_schedules" */
export enum Subscription_Schedules_Constraint {
  /** unique or primary key constraint on columns "id" */
  SubscriptionSchedulesPkey = "subscription_schedules_pkey",
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Subscription_Schedules_Delete_At_Path_Input = {
  metadata?: InputMaybe<Array<Scalars["String"]>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Subscription_Schedules_Delete_Elem_Input = {
  metadata?: InputMaybe<Scalars["Int"]>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Subscription_Schedules_Delete_Key_Input = {
  metadata?: InputMaybe<Scalars["String"]>;
};

/** input type for incrementing numeric columns in table "subscription_schedules" */
export type Subscription_Schedules_Inc_Input = {
  actual_payment?: InputMaybe<Scalars["numeric"]>;
  beginning_balance?: InputMaybe<Scalars["numeric"]>;
  charges?: InputMaybe<Scalars["numeric"]>;
  ending_balance?: InputMaybe<Scalars["numeric"]>;
  extra_payment?: InputMaybe<Scalars["numeric"]>;
  interest?: InputMaybe<Scalars["numeric"]>;
  penalties?: InputMaybe<Scalars["numeric"]>;
  principal?: InputMaybe<Scalars["numeric"]>;
  scheduled_payment?: InputMaybe<Scalars["numeric"]>;
};

/** input type for inserting data into table "subscription_schedules" */
export type Subscription_Schedules_Insert_Input = {
  actual_payment?: InputMaybe<Scalars["numeric"]>;
  beginning_balance?: InputMaybe<Scalars["numeric"]>;
  charges?: InputMaybe<Scalars["numeric"]>;
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  due_date?: InputMaybe<Scalars["date"]>;
  ending_balance?: InputMaybe<Scalars["numeric"]>;
  extra_payment?: InputMaybe<Scalars["numeric"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  interest?: InputMaybe<Scalars["numeric"]>;
  last_updated_by?: InputMaybe<Scalars["uuid"]>;
  metadata?: InputMaybe<Scalars["jsonb"]>;
  organisation?: InputMaybe<Organisations_Obj_Rel_Insert_Input>;
  organisation_id?: InputMaybe<Scalars["uuid"]>;
  party?: InputMaybe<Parties_Obj_Rel_Insert_Input>;
  partyByLastUpdatedBy?: InputMaybe<Parties_Obj_Rel_Insert_Input>;
  party_id?: InputMaybe<Scalars["uuid"]>;
  penalties?: InputMaybe<Scalars["numeric"]>;
  principal?: InputMaybe<Scalars["numeric"]>;
  product?: InputMaybe<Products_Obj_Rel_Insert_Input>;
  product_id?: InputMaybe<Scalars["uuid"]>;
  scheduled_payment?: InputMaybe<Scalars["numeric"]>;
  subscription?: InputMaybe<Subscriptions_Obj_Rel_Insert_Input>;
  subscription_id?: InputMaybe<Scalars["uuid"]>;
  updated_at?: InputMaybe<Scalars["timestamptz"]>;
};

/** aggregate max on columns */
export type Subscription_Schedules_Max_Fields = {
  __typename?: "subscription_schedules_max_fields";
  actual_payment?: Maybe<Scalars["numeric"]>;
  beginning_balance?: Maybe<Scalars["numeric"]>;
  charges?: Maybe<Scalars["numeric"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  due_date?: Maybe<Scalars["date"]>;
  ending_balance?: Maybe<Scalars["numeric"]>;
  extra_payment?: Maybe<Scalars["numeric"]>;
  id?: Maybe<Scalars["uuid"]>;
  interest?: Maybe<Scalars["numeric"]>;
  last_updated_by?: Maybe<Scalars["uuid"]>;
  organisation_id?: Maybe<Scalars["uuid"]>;
  party_id?: Maybe<Scalars["uuid"]>;
  penalties?: Maybe<Scalars["numeric"]>;
  principal?: Maybe<Scalars["numeric"]>;
  product_id?: Maybe<Scalars["uuid"]>;
  scheduled_payment?: Maybe<Scalars["numeric"]>;
  subscription_id?: Maybe<Scalars["uuid"]>;
  total_expected_payment?: Maybe<Scalars["numeric"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
};

/** order by max() on columns of table "subscription_schedules" */
export type Subscription_Schedules_Max_Order_By = {
  actual_payment?: InputMaybe<Order_By>;
  beginning_balance?: InputMaybe<Order_By>;
  charges?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  due_date?: InputMaybe<Order_By>;
  ending_balance?: InputMaybe<Order_By>;
  extra_payment?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  interest?: InputMaybe<Order_By>;
  last_updated_by?: InputMaybe<Order_By>;
  organisation_id?: InputMaybe<Order_By>;
  party_id?: InputMaybe<Order_By>;
  penalties?: InputMaybe<Order_By>;
  principal?: InputMaybe<Order_By>;
  product_id?: InputMaybe<Order_By>;
  scheduled_payment?: InputMaybe<Order_By>;
  subscription_id?: InputMaybe<Order_By>;
  total_expected_payment?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Subscription_Schedules_Min_Fields = {
  __typename?: "subscription_schedules_min_fields";
  actual_payment?: Maybe<Scalars["numeric"]>;
  beginning_balance?: Maybe<Scalars["numeric"]>;
  charges?: Maybe<Scalars["numeric"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  due_date?: Maybe<Scalars["date"]>;
  ending_balance?: Maybe<Scalars["numeric"]>;
  extra_payment?: Maybe<Scalars["numeric"]>;
  id?: Maybe<Scalars["uuid"]>;
  interest?: Maybe<Scalars["numeric"]>;
  last_updated_by?: Maybe<Scalars["uuid"]>;
  organisation_id?: Maybe<Scalars["uuid"]>;
  party_id?: Maybe<Scalars["uuid"]>;
  penalties?: Maybe<Scalars["numeric"]>;
  principal?: Maybe<Scalars["numeric"]>;
  product_id?: Maybe<Scalars["uuid"]>;
  scheduled_payment?: Maybe<Scalars["numeric"]>;
  subscription_id?: Maybe<Scalars["uuid"]>;
  total_expected_payment?: Maybe<Scalars["numeric"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
};

/** order by min() on columns of table "subscription_schedules" */
export type Subscription_Schedules_Min_Order_By = {
  actual_payment?: InputMaybe<Order_By>;
  beginning_balance?: InputMaybe<Order_By>;
  charges?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  due_date?: InputMaybe<Order_By>;
  ending_balance?: InputMaybe<Order_By>;
  extra_payment?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  interest?: InputMaybe<Order_By>;
  last_updated_by?: InputMaybe<Order_By>;
  organisation_id?: InputMaybe<Order_By>;
  party_id?: InputMaybe<Order_By>;
  penalties?: InputMaybe<Order_By>;
  principal?: InputMaybe<Order_By>;
  product_id?: InputMaybe<Order_By>;
  scheduled_payment?: InputMaybe<Order_By>;
  subscription_id?: InputMaybe<Order_By>;
  total_expected_payment?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "subscription_schedules" */
export type Subscription_Schedules_Mutation_Response = {
  __typename?: "subscription_schedules_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Subscription_Schedules>;
};

/** on_conflict condition type for table "subscription_schedules" */
export type Subscription_Schedules_On_Conflict = {
  constraint: Subscription_Schedules_Constraint;
  update_columns?: Array<Subscription_Schedules_Update_Column>;
  where?: InputMaybe<Subscription_Schedules_Bool_Exp>;
};

/** Ordering options when selecting data from "subscription_schedules". */
export type Subscription_Schedules_Order_By = {
  actual_payment?: InputMaybe<Order_By>;
  beginning_balance?: InputMaybe<Order_By>;
  charges?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  due_date?: InputMaybe<Order_By>;
  ending_balance?: InputMaybe<Order_By>;
  extra_payment?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  interest?: InputMaybe<Order_By>;
  last_updated_by?: InputMaybe<Order_By>;
  metadata?: InputMaybe<Order_By>;
  organisation?: InputMaybe<Organisations_Order_By>;
  organisation_id?: InputMaybe<Order_By>;
  party?: InputMaybe<Parties_Order_By>;
  partyByLastUpdatedBy?: InputMaybe<Parties_Order_By>;
  party_id?: InputMaybe<Order_By>;
  penalties?: InputMaybe<Order_By>;
  principal?: InputMaybe<Order_By>;
  product?: InputMaybe<Products_Order_By>;
  product_id?: InputMaybe<Order_By>;
  scheduled_payment?: InputMaybe<Order_By>;
  subscription?: InputMaybe<Subscriptions_Order_By>;
  subscription_id?: InputMaybe<Order_By>;
  total_expected_payment?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: subscription_schedules */
export type Subscription_Schedules_Pk_Columns_Input = {
  id: Scalars["uuid"];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Subscription_Schedules_Prepend_Input = {
  metadata?: InputMaybe<Scalars["jsonb"]>;
};

/** select columns of table "subscription_schedules" */
export enum Subscription_Schedules_Select_Column {
  /** column name */
  ActualPayment = "actual_payment",
  /** column name */
  BeginningBalance = "beginning_balance",
  /** column name */
  Charges = "charges",
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  DueDate = "due_date",
  /** column name */
  EndingBalance = "ending_balance",
  /** column name */
  ExtraPayment = "extra_payment",
  /** column name */
  Id = "id",
  /** column name */
  Interest = "interest",
  /** column name */
  LastUpdatedBy = "last_updated_by",
  /** column name */
  Metadata = "metadata",
  /** column name */
  OrganisationId = "organisation_id",
  /** column name */
  PartyId = "party_id",
  /** column name */
  Penalties = "penalties",
  /** column name */
  Principal = "principal",
  /** column name */
  ProductId = "product_id",
  /** column name */
  ScheduledPayment = "scheduled_payment",
  /** column name */
  SubscriptionId = "subscription_id",
  /** column name */
  TotalExpectedPayment = "total_expected_payment",
  /** column name */
  UpdatedAt = "updated_at",
}

/** input type for updating data in table "subscription_schedules" */
export type Subscription_Schedules_Set_Input = {
  actual_payment?: InputMaybe<Scalars["numeric"]>;
  beginning_balance?: InputMaybe<Scalars["numeric"]>;
  charges?: InputMaybe<Scalars["numeric"]>;
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  due_date?: InputMaybe<Scalars["date"]>;
  ending_balance?: InputMaybe<Scalars["numeric"]>;
  extra_payment?: InputMaybe<Scalars["numeric"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  interest?: InputMaybe<Scalars["numeric"]>;
  last_updated_by?: InputMaybe<Scalars["uuid"]>;
  metadata?: InputMaybe<Scalars["jsonb"]>;
  organisation_id?: InputMaybe<Scalars["uuid"]>;
  party_id?: InputMaybe<Scalars["uuid"]>;
  penalties?: InputMaybe<Scalars["numeric"]>;
  principal?: InputMaybe<Scalars["numeric"]>;
  product_id?: InputMaybe<Scalars["uuid"]>;
  scheduled_payment?: InputMaybe<Scalars["numeric"]>;
  subscription_id?: InputMaybe<Scalars["uuid"]>;
  updated_at?: InputMaybe<Scalars["timestamptz"]>;
};

/** aggregate stddev on columns */
export type Subscription_Schedules_Stddev_Fields = {
  __typename?: "subscription_schedules_stddev_fields";
  actual_payment?: Maybe<Scalars["Float"]>;
  beginning_balance?: Maybe<Scalars["Float"]>;
  charges?: Maybe<Scalars["Float"]>;
  ending_balance?: Maybe<Scalars["Float"]>;
  extra_payment?: Maybe<Scalars["Float"]>;
  interest?: Maybe<Scalars["Float"]>;
  penalties?: Maybe<Scalars["Float"]>;
  principal?: Maybe<Scalars["Float"]>;
  scheduled_payment?: Maybe<Scalars["Float"]>;
  total_expected_payment?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "subscription_schedules" */
export type Subscription_Schedules_Stddev_Order_By = {
  actual_payment?: InputMaybe<Order_By>;
  beginning_balance?: InputMaybe<Order_By>;
  charges?: InputMaybe<Order_By>;
  ending_balance?: InputMaybe<Order_By>;
  extra_payment?: InputMaybe<Order_By>;
  interest?: InputMaybe<Order_By>;
  penalties?: InputMaybe<Order_By>;
  principal?: InputMaybe<Order_By>;
  scheduled_payment?: InputMaybe<Order_By>;
  total_expected_payment?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Subscription_Schedules_Stddev_Pop_Fields = {
  __typename?: "subscription_schedules_stddev_pop_fields";
  actual_payment?: Maybe<Scalars["Float"]>;
  beginning_balance?: Maybe<Scalars["Float"]>;
  charges?: Maybe<Scalars["Float"]>;
  ending_balance?: Maybe<Scalars["Float"]>;
  extra_payment?: Maybe<Scalars["Float"]>;
  interest?: Maybe<Scalars["Float"]>;
  penalties?: Maybe<Scalars["Float"]>;
  principal?: Maybe<Scalars["Float"]>;
  scheduled_payment?: Maybe<Scalars["Float"]>;
  total_expected_payment?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "subscription_schedules" */
export type Subscription_Schedules_Stddev_Pop_Order_By = {
  actual_payment?: InputMaybe<Order_By>;
  beginning_balance?: InputMaybe<Order_By>;
  charges?: InputMaybe<Order_By>;
  ending_balance?: InputMaybe<Order_By>;
  extra_payment?: InputMaybe<Order_By>;
  interest?: InputMaybe<Order_By>;
  penalties?: InputMaybe<Order_By>;
  principal?: InputMaybe<Order_By>;
  scheduled_payment?: InputMaybe<Order_By>;
  total_expected_payment?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Subscription_Schedules_Stddev_Samp_Fields = {
  __typename?: "subscription_schedules_stddev_samp_fields";
  actual_payment?: Maybe<Scalars["Float"]>;
  beginning_balance?: Maybe<Scalars["Float"]>;
  charges?: Maybe<Scalars["Float"]>;
  ending_balance?: Maybe<Scalars["Float"]>;
  extra_payment?: Maybe<Scalars["Float"]>;
  interest?: Maybe<Scalars["Float"]>;
  penalties?: Maybe<Scalars["Float"]>;
  principal?: Maybe<Scalars["Float"]>;
  scheduled_payment?: Maybe<Scalars["Float"]>;
  total_expected_payment?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "subscription_schedules" */
export type Subscription_Schedules_Stddev_Samp_Order_By = {
  actual_payment?: InputMaybe<Order_By>;
  beginning_balance?: InputMaybe<Order_By>;
  charges?: InputMaybe<Order_By>;
  ending_balance?: InputMaybe<Order_By>;
  extra_payment?: InputMaybe<Order_By>;
  interest?: InputMaybe<Order_By>;
  penalties?: InputMaybe<Order_By>;
  principal?: InputMaybe<Order_By>;
  scheduled_payment?: InputMaybe<Order_By>;
  total_expected_payment?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "subscription_schedules" */
export type Subscription_Schedules_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Subscription_Schedules_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Subscription_Schedules_Stream_Cursor_Value_Input = {
  actual_payment?: InputMaybe<Scalars["numeric"]>;
  beginning_balance?: InputMaybe<Scalars["numeric"]>;
  charges?: InputMaybe<Scalars["numeric"]>;
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  due_date?: InputMaybe<Scalars["date"]>;
  ending_balance?: InputMaybe<Scalars["numeric"]>;
  extra_payment?: InputMaybe<Scalars["numeric"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  interest?: InputMaybe<Scalars["numeric"]>;
  last_updated_by?: InputMaybe<Scalars["uuid"]>;
  metadata?: InputMaybe<Scalars["jsonb"]>;
  organisation_id?: InputMaybe<Scalars["uuid"]>;
  party_id?: InputMaybe<Scalars["uuid"]>;
  penalties?: InputMaybe<Scalars["numeric"]>;
  principal?: InputMaybe<Scalars["numeric"]>;
  product_id?: InputMaybe<Scalars["uuid"]>;
  scheduled_payment?: InputMaybe<Scalars["numeric"]>;
  subscription_id?: InputMaybe<Scalars["uuid"]>;
  total_expected_payment?: InputMaybe<Scalars["numeric"]>;
  updated_at?: InputMaybe<Scalars["timestamptz"]>;
};

/** aggregate sum on columns */
export type Subscription_Schedules_Sum_Fields = {
  __typename?: "subscription_schedules_sum_fields";
  actual_payment?: Maybe<Scalars["numeric"]>;
  beginning_balance?: Maybe<Scalars["numeric"]>;
  charges?: Maybe<Scalars["numeric"]>;
  ending_balance?: Maybe<Scalars["numeric"]>;
  extra_payment?: Maybe<Scalars["numeric"]>;
  interest?: Maybe<Scalars["numeric"]>;
  penalties?: Maybe<Scalars["numeric"]>;
  principal?: Maybe<Scalars["numeric"]>;
  scheduled_payment?: Maybe<Scalars["numeric"]>;
  total_expected_payment?: Maybe<Scalars["numeric"]>;
};

/** order by sum() on columns of table "subscription_schedules" */
export type Subscription_Schedules_Sum_Order_By = {
  actual_payment?: InputMaybe<Order_By>;
  beginning_balance?: InputMaybe<Order_By>;
  charges?: InputMaybe<Order_By>;
  ending_balance?: InputMaybe<Order_By>;
  extra_payment?: InputMaybe<Order_By>;
  interest?: InputMaybe<Order_By>;
  penalties?: InputMaybe<Order_By>;
  principal?: InputMaybe<Order_By>;
  scheduled_payment?: InputMaybe<Order_By>;
  total_expected_payment?: InputMaybe<Order_By>;
};

/** update columns of table "subscription_schedules" */
export enum Subscription_Schedules_Update_Column {
  /** column name */
  ActualPayment = "actual_payment",
  /** column name */
  BeginningBalance = "beginning_balance",
  /** column name */
  Charges = "charges",
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  DueDate = "due_date",
  /** column name */
  EndingBalance = "ending_balance",
  /** column name */
  ExtraPayment = "extra_payment",
  /** column name */
  Id = "id",
  /** column name */
  Interest = "interest",
  /** column name */
  LastUpdatedBy = "last_updated_by",
  /** column name */
  Metadata = "metadata",
  /** column name */
  OrganisationId = "organisation_id",
  /** column name */
  PartyId = "party_id",
  /** column name */
  Penalties = "penalties",
  /** column name */
  Principal = "principal",
  /** column name */
  ProductId = "product_id",
  /** column name */
  ScheduledPayment = "scheduled_payment",
  /** column name */
  SubscriptionId = "subscription_id",
  /** column name */
  UpdatedAt = "updated_at",
}

export type Subscription_Schedules_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Subscription_Schedules_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Subscription_Schedules_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Subscription_Schedules_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Subscription_Schedules_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Subscription_Schedules_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Subscription_Schedules_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Subscription_Schedules_Set_Input>;
  /** filter the rows which have to be updated */
  where: Subscription_Schedules_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Subscription_Schedules_Var_Pop_Fields = {
  __typename?: "subscription_schedules_var_pop_fields";
  actual_payment?: Maybe<Scalars["Float"]>;
  beginning_balance?: Maybe<Scalars["Float"]>;
  charges?: Maybe<Scalars["Float"]>;
  ending_balance?: Maybe<Scalars["Float"]>;
  extra_payment?: Maybe<Scalars["Float"]>;
  interest?: Maybe<Scalars["Float"]>;
  penalties?: Maybe<Scalars["Float"]>;
  principal?: Maybe<Scalars["Float"]>;
  scheduled_payment?: Maybe<Scalars["Float"]>;
  total_expected_payment?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "subscription_schedules" */
export type Subscription_Schedules_Var_Pop_Order_By = {
  actual_payment?: InputMaybe<Order_By>;
  beginning_balance?: InputMaybe<Order_By>;
  charges?: InputMaybe<Order_By>;
  ending_balance?: InputMaybe<Order_By>;
  extra_payment?: InputMaybe<Order_By>;
  interest?: InputMaybe<Order_By>;
  penalties?: InputMaybe<Order_By>;
  principal?: InputMaybe<Order_By>;
  scheduled_payment?: InputMaybe<Order_By>;
  total_expected_payment?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Subscription_Schedules_Var_Samp_Fields = {
  __typename?: "subscription_schedules_var_samp_fields";
  actual_payment?: Maybe<Scalars["Float"]>;
  beginning_balance?: Maybe<Scalars["Float"]>;
  charges?: Maybe<Scalars["Float"]>;
  ending_balance?: Maybe<Scalars["Float"]>;
  extra_payment?: Maybe<Scalars["Float"]>;
  interest?: Maybe<Scalars["Float"]>;
  penalties?: Maybe<Scalars["Float"]>;
  principal?: Maybe<Scalars["Float"]>;
  scheduled_payment?: Maybe<Scalars["Float"]>;
  total_expected_payment?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "subscription_schedules" */
export type Subscription_Schedules_Var_Samp_Order_By = {
  actual_payment?: InputMaybe<Order_By>;
  beginning_balance?: InputMaybe<Order_By>;
  charges?: InputMaybe<Order_By>;
  ending_balance?: InputMaybe<Order_By>;
  extra_payment?: InputMaybe<Order_By>;
  interest?: InputMaybe<Order_By>;
  penalties?: InputMaybe<Order_By>;
  principal?: InputMaybe<Order_By>;
  scheduled_payment?: InputMaybe<Order_By>;
  total_expected_payment?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Subscription_Schedules_Variance_Fields = {
  __typename?: "subscription_schedules_variance_fields";
  actual_payment?: Maybe<Scalars["Float"]>;
  beginning_balance?: Maybe<Scalars["Float"]>;
  charges?: Maybe<Scalars["Float"]>;
  ending_balance?: Maybe<Scalars["Float"]>;
  extra_payment?: Maybe<Scalars["Float"]>;
  interest?: Maybe<Scalars["Float"]>;
  penalties?: Maybe<Scalars["Float"]>;
  principal?: Maybe<Scalars["Float"]>;
  scheduled_payment?: Maybe<Scalars["Float"]>;
  total_expected_payment?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "subscription_schedules" */
export type Subscription_Schedules_Variance_Order_By = {
  actual_payment?: InputMaybe<Order_By>;
  beginning_balance?: InputMaybe<Order_By>;
  charges?: InputMaybe<Order_By>;
  ending_balance?: InputMaybe<Order_By>;
  extra_payment?: InputMaybe<Order_By>;
  interest?: InputMaybe<Order_By>;
  penalties?: InputMaybe<Order_By>;
  principal?: InputMaybe<Order_By>;
  scheduled_payment?: InputMaybe<Order_By>;
  total_expected_payment?: InputMaybe<Order_By>;
};

/** columns and relationships of "subscription_statuses" */
export type Subscription_Statuses = {
  __typename?: "subscription_statuses";
  created_at?: Maybe<Scalars["timestamptz"]>;
  id: Scalars["uuid"];
  metadata?: Maybe<Scalars["jsonb"]>;
  /** An object relationship */
  status: Statuses;
  status_id: Scalars["uuid"];
  /** An object relationship */
  subscription: Subscriptions;
  subscription_id: Scalars["uuid"];
};

/** columns and relationships of "subscription_statuses" */
export type Subscription_StatusesMetadataArgs = {
  path?: InputMaybe<Scalars["String"]>;
};

/** aggregated selection of "subscription_statuses" */
export type Subscription_Statuses_Aggregate = {
  __typename?: "subscription_statuses_aggregate";
  aggregate?: Maybe<Subscription_Statuses_Aggregate_Fields>;
  nodes: Array<Subscription_Statuses>;
};

export type Subscription_Statuses_Aggregate_Bool_Exp = {
  count?: InputMaybe<Subscription_Statuses_Aggregate_Bool_Exp_Count>;
};

export type Subscription_Statuses_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Subscription_Statuses_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<Subscription_Statuses_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "subscription_statuses" */
export type Subscription_Statuses_Aggregate_Fields = {
  __typename?: "subscription_statuses_aggregate_fields";
  count: Scalars["Int"];
  max?: Maybe<Subscription_Statuses_Max_Fields>;
  min?: Maybe<Subscription_Statuses_Min_Fields>;
};

/** aggregate fields of "subscription_statuses" */
export type Subscription_Statuses_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Subscription_Statuses_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "subscription_statuses" */
export type Subscription_Statuses_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Subscription_Statuses_Max_Order_By>;
  min?: InputMaybe<Subscription_Statuses_Min_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Subscription_Statuses_Append_Input = {
  metadata?: InputMaybe<Scalars["jsonb"]>;
};

/** input type for inserting array relation for remote table "subscription_statuses" */
export type Subscription_Statuses_Arr_Rel_Insert_Input = {
  data: Array<Subscription_Statuses_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Subscription_Statuses_On_Conflict>;
};

/** Boolean expression to filter rows from the table "subscription_statuses". All fields are combined with a logical 'AND'. */
export type Subscription_Statuses_Bool_Exp = {
  _and?: InputMaybe<Array<Subscription_Statuses_Bool_Exp>>;
  _not?: InputMaybe<Subscription_Statuses_Bool_Exp>;
  _or?: InputMaybe<Array<Subscription_Statuses_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  metadata?: InputMaybe<Jsonb_Comparison_Exp>;
  status?: InputMaybe<Statuses_Bool_Exp>;
  status_id?: InputMaybe<Uuid_Comparison_Exp>;
  subscription?: InputMaybe<Subscriptions_Bool_Exp>;
  subscription_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "subscription_statuses" */
export enum Subscription_Statuses_Constraint {
  /** unique or primary key constraint on columns "id" */
  SubscriptionStatusesPkey = "subscription_statuses_pkey",
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Subscription_Statuses_Delete_At_Path_Input = {
  metadata?: InputMaybe<Array<Scalars["String"]>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Subscription_Statuses_Delete_Elem_Input = {
  metadata?: InputMaybe<Scalars["Int"]>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Subscription_Statuses_Delete_Key_Input = {
  metadata?: InputMaybe<Scalars["String"]>;
};

/** input type for inserting data into table "subscription_statuses" */
export type Subscription_Statuses_Insert_Input = {
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  metadata?: InputMaybe<Scalars["jsonb"]>;
  status?: InputMaybe<Statuses_Obj_Rel_Insert_Input>;
  status_id?: InputMaybe<Scalars["uuid"]>;
  subscription?: InputMaybe<Subscriptions_Obj_Rel_Insert_Input>;
  subscription_id?: InputMaybe<Scalars["uuid"]>;
};

/** aggregate max on columns */
export type Subscription_Statuses_Max_Fields = {
  __typename?: "subscription_statuses_max_fields";
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["uuid"]>;
  status_id?: Maybe<Scalars["uuid"]>;
  subscription_id?: Maybe<Scalars["uuid"]>;
};

/** order by max() on columns of table "subscription_statuses" */
export type Subscription_Statuses_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  status_id?: InputMaybe<Order_By>;
  subscription_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Subscription_Statuses_Min_Fields = {
  __typename?: "subscription_statuses_min_fields";
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["uuid"]>;
  status_id?: Maybe<Scalars["uuid"]>;
  subscription_id?: Maybe<Scalars["uuid"]>;
};

/** order by min() on columns of table "subscription_statuses" */
export type Subscription_Statuses_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  status_id?: InputMaybe<Order_By>;
  subscription_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "subscription_statuses" */
export type Subscription_Statuses_Mutation_Response = {
  __typename?: "subscription_statuses_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Subscription_Statuses>;
};

/** on_conflict condition type for table "subscription_statuses" */
export type Subscription_Statuses_On_Conflict = {
  constraint: Subscription_Statuses_Constraint;
  update_columns?: Array<Subscription_Statuses_Update_Column>;
  where?: InputMaybe<Subscription_Statuses_Bool_Exp>;
};

/** Ordering options when selecting data from "subscription_statuses". */
export type Subscription_Statuses_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  metadata?: InputMaybe<Order_By>;
  status?: InputMaybe<Statuses_Order_By>;
  status_id?: InputMaybe<Order_By>;
  subscription?: InputMaybe<Subscriptions_Order_By>;
  subscription_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: subscription_statuses */
export type Subscription_Statuses_Pk_Columns_Input = {
  id: Scalars["uuid"];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Subscription_Statuses_Prepend_Input = {
  metadata?: InputMaybe<Scalars["jsonb"]>;
};

/** select columns of table "subscription_statuses" */
export enum Subscription_Statuses_Select_Column {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  Id = "id",
  /** column name */
  Metadata = "metadata",
  /** column name */
  StatusId = "status_id",
  /** column name */
  SubscriptionId = "subscription_id",
}

/** input type for updating data in table "subscription_statuses" */
export type Subscription_Statuses_Set_Input = {
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  metadata?: InputMaybe<Scalars["jsonb"]>;
  status_id?: InputMaybe<Scalars["uuid"]>;
  subscription_id?: InputMaybe<Scalars["uuid"]>;
};

/** Streaming cursor of the table "subscription_statuses" */
export type Subscription_Statuses_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Subscription_Statuses_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Subscription_Statuses_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  metadata?: InputMaybe<Scalars["jsonb"]>;
  status_id?: InputMaybe<Scalars["uuid"]>;
  subscription_id?: InputMaybe<Scalars["uuid"]>;
};

/** update columns of table "subscription_statuses" */
export enum Subscription_Statuses_Update_Column {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  Id = "id",
  /** column name */
  Metadata = "metadata",
  /** column name */
  StatusId = "status_id",
  /** column name */
  SubscriptionId = "subscription_id",
}

export type Subscription_Statuses_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Subscription_Statuses_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Subscription_Statuses_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Subscription_Statuses_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Subscription_Statuses_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Subscription_Statuses_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Subscription_Statuses_Set_Input>;
  /** filter the rows which have to be updated */
  where: Subscription_Statuses_Bool_Exp;
};

/** columns and relationships of "subscription_tasks" */
export type Subscription_Tasks = {
  __typename?: "subscription_tasks";
  created_at?: Maybe<Scalars["timestamptz"]>;
  id: Scalars["uuid"];
  /** An object relationship */
  product: Products;
  product_id: Scalars["uuid"];
  /** An object relationship */
  subscription: Subscriptions;
  subscription_id: Scalars["uuid"];
  /** An object relationship */
  task: Tasks;
  task_id: Scalars["uuid"];
};

/** aggregated selection of "subscription_tasks" */
export type Subscription_Tasks_Aggregate = {
  __typename?: "subscription_tasks_aggregate";
  aggregate?: Maybe<Subscription_Tasks_Aggregate_Fields>;
  nodes: Array<Subscription_Tasks>;
};

export type Subscription_Tasks_Aggregate_Bool_Exp = {
  count?: InputMaybe<Subscription_Tasks_Aggregate_Bool_Exp_Count>;
};

export type Subscription_Tasks_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Subscription_Tasks_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<Subscription_Tasks_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "subscription_tasks" */
export type Subscription_Tasks_Aggregate_Fields = {
  __typename?: "subscription_tasks_aggregate_fields";
  count: Scalars["Int"];
  max?: Maybe<Subscription_Tasks_Max_Fields>;
  min?: Maybe<Subscription_Tasks_Min_Fields>;
};

/** aggregate fields of "subscription_tasks" */
export type Subscription_Tasks_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Subscription_Tasks_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "subscription_tasks" */
export type Subscription_Tasks_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Subscription_Tasks_Max_Order_By>;
  min?: InputMaybe<Subscription_Tasks_Min_Order_By>;
};

/** input type for inserting array relation for remote table "subscription_tasks" */
export type Subscription_Tasks_Arr_Rel_Insert_Input = {
  data: Array<Subscription_Tasks_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Subscription_Tasks_On_Conflict>;
};

/** Boolean expression to filter rows from the table "subscription_tasks". All fields are combined with a logical 'AND'. */
export type Subscription_Tasks_Bool_Exp = {
  _and?: InputMaybe<Array<Subscription_Tasks_Bool_Exp>>;
  _not?: InputMaybe<Subscription_Tasks_Bool_Exp>;
  _or?: InputMaybe<Array<Subscription_Tasks_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  product?: InputMaybe<Products_Bool_Exp>;
  product_id?: InputMaybe<Uuid_Comparison_Exp>;
  subscription?: InputMaybe<Subscriptions_Bool_Exp>;
  subscription_id?: InputMaybe<Uuid_Comparison_Exp>;
  task?: InputMaybe<Tasks_Bool_Exp>;
  task_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "subscription_tasks" */
export enum Subscription_Tasks_Constraint {
  /** unique or primary key constraint on columns "id" */
  SubscriptionTasksPkey = "subscription_tasks_pkey",
  /** unique or primary key constraint on columns "subscription_id", "task_id" */
  UniqueSubscriptionTask = "unique_subscription_task",
}

/** input type for inserting data into table "subscription_tasks" */
export type Subscription_Tasks_Insert_Input = {
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  product?: InputMaybe<Products_Obj_Rel_Insert_Input>;
  product_id?: InputMaybe<Scalars["uuid"]>;
  subscription?: InputMaybe<Subscriptions_Obj_Rel_Insert_Input>;
  subscription_id?: InputMaybe<Scalars["uuid"]>;
  task?: InputMaybe<Tasks_Obj_Rel_Insert_Input>;
  task_id?: InputMaybe<Scalars["uuid"]>;
};

/** aggregate max on columns */
export type Subscription_Tasks_Max_Fields = {
  __typename?: "subscription_tasks_max_fields";
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["uuid"]>;
  product_id?: Maybe<Scalars["uuid"]>;
  subscription_id?: Maybe<Scalars["uuid"]>;
  task_id?: Maybe<Scalars["uuid"]>;
};

/** order by max() on columns of table "subscription_tasks" */
export type Subscription_Tasks_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  product_id?: InputMaybe<Order_By>;
  subscription_id?: InputMaybe<Order_By>;
  task_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Subscription_Tasks_Min_Fields = {
  __typename?: "subscription_tasks_min_fields";
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["uuid"]>;
  product_id?: Maybe<Scalars["uuid"]>;
  subscription_id?: Maybe<Scalars["uuid"]>;
  task_id?: Maybe<Scalars["uuid"]>;
};

/** order by min() on columns of table "subscription_tasks" */
export type Subscription_Tasks_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  product_id?: InputMaybe<Order_By>;
  subscription_id?: InputMaybe<Order_By>;
  task_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "subscription_tasks" */
export type Subscription_Tasks_Mutation_Response = {
  __typename?: "subscription_tasks_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Subscription_Tasks>;
};

/** on_conflict condition type for table "subscription_tasks" */
export type Subscription_Tasks_On_Conflict = {
  constraint: Subscription_Tasks_Constraint;
  update_columns?: Array<Subscription_Tasks_Update_Column>;
  where?: InputMaybe<Subscription_Tasks_Bool_Exp>;
};

/** Ordering options when selecting data from "subscription_tasks". */
export type Subscription_Tasks_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  product?: InputMaybe<Products_Order_By>;
  product_id?: InputMaybe<Order_By>;
  subscription?: InputMaybe<Subscriptions_Order_By>;
  subscription_id?: InputMaybe<Order_By>;
  task?: InputMaybe<Tasks_Order_By>;
  task_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: subscription_tasks */
export type Subscription_Tasks_Pk_Columns_Input = {
  id: Scalars["uuid"];
};

/** select columns of table "subscription_tasks" */
export enum Subscription_Tasks_Select_Column {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  Id = "id",
  /** column name */
  ProductId = "product_id",
  /** column name */
  SubscriptionId = "subscription_id",
  /** column name */
  TaskId = "task_id",
}

/** input type for updating data in table "subscription_tasks" */
export type Subscription_Tasks_Set_Input = {
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  product_id?: InputMaybe<Scalars["uuid"]>;
  subscription_id?: InputMaybe<Scalars["uuid"]>;
  task_id?: InputMaybe<Scalars["uuid"]>;
};

/** Streaming cursor of the table "subscription_tasks" */
export type Subscription_Tasks_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Subscription_Tasks_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Subscription_Tasks_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  product_id?: InputMaybe<Scalars["uuid"]>;
  subscription_id?: InputMaybe<Scalars["uuid"]>;
  task_id?: InputMaybe<Scalars["uuid"]>;
};

/** update columns of table "subscription_tasks" */
export enum Subscription_Tasks_Update_Column {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  Id = "id",
  /** column name */
  ProductId = "product_id",
  /** column name */
  SubscriptionId = "subscription_id",
  /** column name */
  TaskId = "task_id",
}

export type Subscription_Tasks_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Subscription_Tasks_Set_Input>;
  /** filter the rows which have to be updated */
  where: Subscription_Tasks_Bool_Exp;
};

/** columns and relationships of "subscriptions" */
export type Subscriptions = {
  __typename?: "subscriptions";
  created_at: Scalars["timestamptz"];
  id: Scalars["uuid"];
  invoice_interval?: Maybe<Scalars["String"]>;
  /** An array relationship */
  invoice_items: Array<Invoice_Items>;
  /** An aggregate relationship */
  invoice_items_aggregate: Invoice_Items_Aggregate;
  /** An array relationship */
  invoices: Array<Invoices>;
  /** An aggregate relationship */
  invoices_aggregate: Invoices_Aggregate;
  metadata?: Maybe<Scalars["jsonb"]>;
  organisation_id: Scalars["uuid"];
  /** An object relationship */
  party: Parties;
  party_id: Scalars["uuid"];
  /** An object relationship */
  product: Products;
  product_id: Scalars["uuid"];
  /** An array relationship */
  subscription_cases: Array<Subscription_Cases>;
  /** An aggregate relationship */
  subscription_cases_aggregate: Subscription_Cases_Aggregate;
  /** An array relationship */
  subscription_details: Array<Subscription_Details>;
  /** An aggregate relationship */
  subscription_details_aggregate: Subscription_Details_Aggregate;
  /** An array relationship */
  subscription_documents: Array<Subscription_Documents>;
  /** An aggregate relationship */
  subscription_documents_aggregate: Subscription_Documents_Aggregate;
  subscription_end?: Maybe<Scalars["timestamptz"]>;
  /** An array relationship */
  subscription_flags: Array<Subscription_Flags>;
  /** An aggregate relationship */
  subscription_flags_aggregate: Subscription_Flags_Aggregate;
  /** An array relationship */
  subscription_interactions: Array<Subscription_Interactions>;
  /** An aggregate relationship */
  subscription_interactions_aggregate: Subscription_Interactions_Aggregate;
  subscription_items: Array<Subscription_Items>;
  subscription_items_aggregate: Subscription_Items_Aggregate;
  /** An array relationship */
  subscription_payment_transactions: Array<Subscription_Payment_Transactions>;
  /** An aggregate relationship */
  subscription_payment_transactions_aggregate: Subscription_Payment_Transactions_Aggregate;
  /** An array relationship */
  subscription_payments: Array<Subscription_Payments>;
  /** An aggregate relationship */
  subscription_payments_aggregate: Subscription_Payments_Aggregate;
  /** An array relationship */
  subscription_rules: Array<Subscription_Rules>;
  /** An aggregate relationship */
  subscription_rules_aggregate: Subscription_Rules_Aggregate;
  /** An array relationship */
  subscription_schedules: Array<Subscription_Schedules>;
  /** An aggregate relationship */
  subscription_schedules_aggregate: Subscription_Schedules_Aggregate;
  subscription_start: Scalars["timestamptz"];
  /** An array relationship */
  subscription_statuses: Array<Subscription_Statuses>;
  /** An aggregate relationship */
  subscription_statuses_aggregate: Subscription_Statuses_Aggregate;
  /** An array relationship */
  subscription_tasks: Array<Subscription_Tasks>;
  /** An aggregate relationship */
  subscription_tasks_aggregate: Subscription_Tasks_Aggregate;
  /** An object relationship */
  type: Types;
  type_id: Scalars["uuid"];
  updated_at: Scalars["timestamptz"];
};

/** columns and relationships of "subscriptions" */
export type SubscriptionsInvoice_ItemsArgs = {
  distinct_on?: InputMaybe<Array<Invoice_Items_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Invoice_Items_Order_By>>;
  where?: InputMaybe<Invoice_Items_Bool_Exp>;
};

/** columns and relationships of "subscriptions" */
export type SubscriptionsInvoice_Items_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Invoice_Items_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Invoice_Items_Order_By>>;
  where?: InputMaybe<Invoice_Items_Bool_Exp>;
};

/** columns and relationships of "subscriptions" */
export type SubscriptionsInvoicesArgs = {
  distinct_on?: InputMaybe<Array<Invoices_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Invoices_Order_By>>;
  where?: InputMaybe<Invoices_Bool_Exp>;
};

/** columns and relationships of "subscriptions" */
export type SubscriptionsInvoices_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Invoices_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Invoices_Order_By>>;
  where?: InputMaybe<Invoices_Bool_Exp>;
};

/** columns and relationships of "subscriptions" */
export type SubscriptionsMetadataArgs = {
  path?: InputMaybe<Scalars["String"]>;
};

/** columns and relationships of "subscriptions" */
export type SubscriptionsSubscription_CasesArgs = {
  distinct_on?: InputMaybe<Array<Subscription_Cases_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Subscription_Cases_Order_By>>;
  where?: InputMaybe<Subscription_Cases_Bool_Exp>;
};

/** columns and relationships of "subscriptions" */
export type SubscriptionsSubscription_Cases_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Subscription_Cases_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Subscription_Cases_Order_By>>;
  where?: InputMaybe<Subscription_Cases_Bool_Exp>;
};

/** columns and relationships of "subscriptions" */
export type SubscriptionsSubscription_DetailsArgs = {
  distinct_on?: InputMaybe<Array<Subscription_Details_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Subscription_Details_Order_By>>;
  where?: InputMaybe<Subscription_Details_Bool_Exp>;
};

/** columns and relationships of "subscriptions" */
export type SubscriptionsSubscription_Details_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Subscription_Details_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Subscription_Details_Order_By>>;
  where?: InputMaybe<Subscription_Details_Bool_Exp>;
};

/** columns and relationships of "subscriptions" */
export type SubscriptionsSubscription_DocumentsArgs = {
  distinct_on?: InputMaybe<Array<Subscription_Documents_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Subscription_Documents_Order_By>>;
  where?: InputMaybe<Subscription_Documents_Bool_Exp>;
};

/** columns and relationships of "subscriptions" */
export type SubscriptionsSubscription_Documents_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Subscription_Documents_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Subscription_Documents_Order_By>>;
  where?: InputMaybe<Subscription_Documents_Bool_Exp>;
};

/** columns and relationships of "subscriptions" */
export type SubscriptionsSubscription_FlagsArgs = {
  distinct_on?: InputMaybe<Array<Subscription_Flags_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Subscription_Flags_Order_By>>;
  where?: InputMaybe<Subscription_Flags_Bool_Exp>;
};

/** columns and relationships of "subscriptions" */
export type SubscriptionsSubscription_Flags_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Subscription_Flags_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Subscription_Flags_Order_By>>;
  where?: InputMaybe<Subscription_Flags_Bool_Exp>;
};

/** columns and relationships of "subscriptions" */
export type SubscriptionsSubscription_InteractionsArgs = {
  distinct_on?: InputMaybe<Array<Subscription_Interactions_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Subscription_Interactions_Order_By>>;
  where?: InputMaybe<Subscription_Interactions_Bool_Exp>;
};

/** columns and relationships of "subscriptions" */
export type SubscriptionsSubscription_Interactions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Subscription_Interactions_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Subscription_Interactions_Order_By>>;
  where?: InputMaybe<Subscription_Interactions_Bool_Exp>;
};

/** columns and relationships of "subscriptions" */
export type SubscriptionsSubscription_ItemsArgs = {
  distinct_on?: InputMaybe<Array<Subscription_Items_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Subscription_Items_Order_By>>;
  where?: InputMaybe<Subscription_Items_Bool_Exp>;
};

/** columns and relationships of "subscriptions" */
export type SubscriptionsSubscription_Items_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Subscription_Items_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Subscription_Items_Order_By>>;
  where?: InputMaybe<Subscription_Items_Bool_Exp>;
};

/** columns and relationships of "subscriptions" */
export type SubscriptionsSubscription_Payment_TransactionsArgs = {
  distinct_on?: InputMaybe<
    Array<Subscription_Payment_Transactions_Select_Column>
  >;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Subscription_Payment_Transactions_Order_By>>;
  where?: InputMaybe<Subscription_Payment_Transactions_Bool_Exp>;
};

/** columns and relationships of "subscriptions" */
export type SubscriptionsSubscription_Payment_Transactions_AggregateArgs = {
  distinct_on?: InputMaybe<
    Array<Subscription_Payment_Transactions_Select_Column>
  >;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Subscription_Payment_Transactions_Order_By>>;
  where?: InputMaybe<Subscription_Payment_Transactions_Bool_Exp>;
};

/** columns and relationships of "subscriptions" */
export type SubscriptionsSubscription_PaymentsArgs = {
  distinct_on?: InputMaybe<Array<Subscription_Payments_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Subscription_Payments_Order_By>>;
  where?: InputMaybe<Subscription_Payments_Bool_Exp>;
};

/** columns and relationships of "subscriptions" */
export type SubscriptionsSubscription_Payments_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Subscription_Payments_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Subscription_Payments_Order_By>>;
  where?: InputMaybe<Subscription_Payments_Bool_Exp>;
};

/** columns and relationships of "subscriptions" */
export type SubscriptionsSubscription_RulesArgs = {
  distinct_on?: InputMaybe<Array<Subscription_Rules_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Subscription_Rules_Order_By>>;
  where?: InputMaybe<Subscription_Rules_Bool_Exp>;
};

/** columns and relationships of "subscriptions" */
export type SubscriptionsSubscription_Rules_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Subscription_Rules_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Subscription_Rules_Order_By>>;
  where?: InputMaybe<Subscription_Rules_Bool_Exp>;
};

/** columns and relationships of "subscriptions" */
export type SubscriptionsSubscription_SchedulesArgs = {
  distinct_on?: InputMaybe<Array<Subscription_Schedules_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Subscription_Schedules_Order_By>>;
  where?: InputMaybe<Subscription_Schedules_Bool_Exp>;
};

/** columns and relationships of "subscriptions" */
export type SubscriptionsSubscription_Schedules_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Subscription_Schedules_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Subscription_Schedules_Order_By>>;
  where?: InputMaybe<Subscription_Schedules_Bool_Exp>;
};

/** columns and relationships of "subscriptions" */
export type SubscriptionsSubscription_StatusesArgs = {
  distinct_on?: InputMaybe<Array<Subscription_Statuses_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Subscription_Statuses_Order_By>>;
  where?: InputMaybe<Subscription_Statuses_Bool_Exp>;
};

/** columns and relationships of "subscriptions" */
export type SubscriptionsSubscription_Statuses_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Subscription_Statuses_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Subscription_Statuses_Order_By>>;
  where?: InputMaybe<Subscription_Statuses_Bool_Exp>;
};

/** columns and relationships of "subscriptions" */
export type SubscriptionsSubscription_TasksArgs = {
  distinct_on?: InputMaybe<Array<Subscription_Tasks_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Subscription_Tasks_Order_By>>;
  where?: InputMaybe<Subscription_Tasks_Bool_Exp>;
};

/** columns and relationships of "subscriptions" */
export type SubscriptionsSubscription_Tasks_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Subscription_Tasks_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Subscription_Tasks_Order_By>>;
  where?: InputMaybe<Subscription_Tasks_Bool_Exp>;
};

/** aggregated selection of "subscriptions" */
export type Subscriptions_Aggregate = {
  __typename?: "subscriptions_aggregate";
  aggregate?: Maybe<Subscriptions_Aggregate_Fields>;
  nodes: Array<Subscriptions>;
};

export type Subscriptions_Aggregate_Bool_Exp = {
  count?: InputMaybe<Subscriptions_Aggregate_Bool_Exp_Count>;
};

export type Subscriptions_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Subscriptions_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<Subscriptions_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "subscriptions" */
export type Subscriptions_Aggregate_Fields = {
  __typename?: "subscriptions_aggregate_fields";
  count: Scalars["Int"];
  max?: Maybe<Subscriptions_Max_Fields>;
  min?: Maybe<Subscriptions_Min_Fields>;
};

/** aggregate fields of "subscriptions" */
export type Subscriptions_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Subscriptions_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "subscriptions" */
export type Subscriptions_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Subscriptions_Max_Order_By>;
  min?: InputMaybe<Subscriptions_Min_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Subscriptions_Append_Input = {
  metadata?: InputMaybe<Scalars["jsonb"]>;
};

/** input type for inserting array relation for remote table "subscriptions" */
export type Subscriptions_Arr_Rel_Insert_Input = {
  data: Array<Subscriptions_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Subscriptions_On_Conflict>;
};

/** Boolean expression to filter rows from the table "subscriptions". All fields are combined with a logical 'AND'. */
export type Subscriptions_Bool_Exp = {
  _and?: InputMaybe<Array<Subscriptions_Bool_Exp>>;
  _not?: InputMaybe<Subscriptions_Bool_Exp>;
  _or?: InputMaybe<Array<Subscriptions_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  invoice_interval?: InputMaybe<String_Comparison_Exp>;
  invoice_items?: InputMaybe<Invoice_Items_Bool_Exp>;
  invoice_items_aggregate?: InputMaybe<Invoice_Items_Aggregate_Bool_Exp>;
  invoices?: InputMaybe<Invoices_Bool_Exp>;
  invoices_aggregate?: InputMaybe<Invoices_Aggregate_Bool_Exp>;
  metadata?: InputMaybe<Jsonb_Comparison_Exp>;
  organisation_id?: InputMaybe<Uuid_Comparison_Exp>;
  party?: InputMaybe<Parties_Bool_Exp>;
  party_id?: InputMaybe<Uuid_Comparison_Exp>;
  product?: InputMaybe<Products_Bool_Exp>;
  product_id?: InputMaybe<Uuid_Comparison_Exp>;
  subscription_cases?: InputMaybe<Subscription_Cases_Bool_Exp>;
  subscription_cases_aggregate?: InputMaybe<Subscription_Cases_Aggregate_Bool_Exp>;
  subscription_details?: InputMaybe<Subscription_Details_Bool_Exp>;
  subscription_details_aggregate?: InputMaybe<Subscription_Details_Aggregate_Bool_Exp>;
  subscription_documents?: InputMaybe<Subscription_Documents_Bool_Exp>;
  subscription_documents_aggregate?: InputMaybe<Subscription_Documents_Aggregate_Bool_Exp>;
  subscription_end?: InputMaybe<Timestamptz_Comparison_Exp>;
  subscription_flags?: InputMaybe<Subscription_Flags_Bool_Exp>;
  subscription_flags_aggregate?: InputMaybe<Subscription_Flags_Aggregate_Bool_Exp>;
  subscription_interactions?: InputMaybe<Subscription_Interactions_Bool_Exp>;
  subscription_interactions_aggregate?: InputMaybe<Subscription_Interactions_Aggregate_Bool_Exp>;
  subscription_items?: InputMaybe<Subscription_Items_Bool_Exp>;
  subscription_items_aggregate?: InputMaybe<Subscription_Items_Aggregate_Bool_Exp>;
  subscription_payment_transactions?: InputMaybe<Subscription_Payment_Transactions_Bool_Exp>;
  subscription_payment_transactions_aggregate?: InputMaybe<Subscription_Payment_Transactions_Aggregate_Bool_Exp>;
  subscription_payments?: InputMaybe<Subscription_Payments_Bool_Exp>;
  subscription_payments_aggregate?: InputMaybe<Subscription_Payments_Aggregate_Bool_Exp>;
  subscription_rules?: InputMaybe<Subscription_Rules_Bool_Exp>;
  subscription_rules_aggregate?: InputMaybe<Subscription_Rules_Aggregate_Bool_Exp>;
  subscription_schedules?: InputMaybe<Subscription_Schedules_Bool_Exp>;
  subscription_schedules_aggregate?: InputMaybe<Subscription_Schedules_Aggregate_Bool_Exp>;
  subscription_start?: InputMaybe<Timestamptz_Comparison_Exp>;
  subscription_statuses?: InputMaybe<Subscription_Statuses_Bool_Exp>;
  subscription_statuses_aggregate?: InputMaybe<Subscription_Statuses_Aggregate_Bool_Exp>;
  subscription_tasks?: InputMaybe<Subscription_Tasks_Bool_Exp>;
  subscription_tasks_aggregate?: InputMaybe<Subscription_Tasks_Aggregate_Bool_Exp>;
  type?: InputMaybe<Types_Bool_Exp>;
  type_id?: InputMaybe<Uuid_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "subscriptions" */
export enum Subscriptions_Constraint {
  /** unique or primary key constraint on columns "id" */
  SubscriptionsPkey = "subscriptions_pkey",
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Subscriptions_Delete_At_Path_Input = {
  metadata?: InputMaybe<Array<Scalars["String"]>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Subscriptions_Delete_Elem_Input = {
  metadata?: InputMaybe<Scalars["Int"]>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Subscriptions_Delete_Key_Input = {
  metadata?: InputMaybe<Scalars["String"]>;
};

/** input type for inserting data into table "subscriptions" */
export type Subscriptions_Insert_Input = {
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  invoice_interval?: InputMaybe<Scalars["String"]>;
  invoice_items?: InputMaybe<Invoice_Items_Arr_Rel_Insert_Input>;
  invoices?: InputMaybe<Invoices_Arr_Rel_Insert_Input>;
  metadata?: InputMaybe<Scalars["jsonb"]>;
  organisation_id?: InputMaybe<Scalars["uuid"]>;
  party?: InputMaybe<Parties_Obj_Rel_Insert_Input>;
  party_id?: InputMaybe<Scalars["uuid"]>;
  product?: InputMaybe<Products_Obj_Rel_Insert_Input>;
  product_id?: InputMaybe<Scalars["uuid"]>;
  subscription_cases?: InputMaybe<Subscription_Cases_Arr_Rel_Insert_Input>;
  subscription_details?: InputMaybe<Subscription_Details_Arr_Rel_Insert_Input>;
  subscription_documents?: InputMaybe<Subscription_Documents_Arr_Rel_Insert_Input>;
  subscription_end?: InputMaybe<Scalars["timestamptz"]>;
  subscription_flags?: InputMaybe<Subscription_Flags_Arr_Rel_Insert_Input>;
  subscription_interactions?: InputMaybe<Subscription_Interactions_Arr_Rel_Insert_Input>;
  subscription_items?: InputMaybe<Subscription_Items_Arr_Rel_Insert_Input>;
  subscription_payment_transactions?: InputMaybe<Subscription_Payment_Transactions_Arr_Rel_Insert_Input>;
  subscription_payments?: InputMaybe<Subscription_Payments_Arr_Rel_Insert_Input>;
  subscription_rules?: InputMaybe<Subscription_Rules_Arr_Rel_Insert_Input>;
  subscription_schedules?: InputMaybe<Subscription_Schedules_Arr_Rel_Insert_Input>;
  subscription_start?: InputMaybe<Scalars["timestamptz"]>;
  subscription_statuses?: InputMaybe<Subscription_Statuses_Arr_Rel_Insert_Input>;
  subscription_tasks?: InputMaybe<Subscription_Tasks_Arr_Rel_Insert_Input>;
  type?: InputMaybe<Types_Obj_Rel_Insert_Input>;
  type_id?: InputMaybe<Scalars["uuid"]>;
  updated_at?: InputMaybe<Scalars["timestamptz"]>;
};

/** aggregate max on columns */
export type Subscriptions_Max_Fields = {
  __typename?: "subscriptions_max_fields";
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["uuid"]>;
  invoice_interval?: Maybe<Scalars["String"]>;
  organisation_id?: Maybe<Scalars["uuid"]>;
  party_id?: Maybe<Scalars["uuid"]>;
  product_id?: Maybe<Scalars["uuid"]>;
  subscription_end?: Maybe<Scalars["timestamptz"]>;
  subscription_start?: Maybe<Scalars["timestamptz"]>;
  type_id?: Maybe<Scalars["uuid"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
};

/** order by max() on columns of table "subscriptions" */
export type Subscriptions_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  invoice_interval?: InputMaybe<Order_By>;
  organisation_id?: InputMaybe<Order_By>;
  party_id?: InputMaybe<Order_By>;
  product_id?: InputMaybe<Order_By>;
  subscription_end?: InputMaybe<Order_By>;
  subscription_start?: InputMaybe<Order_By>;
  type_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Subscriptions_Min_Fields = {
  __typename?: "subscriptions_min_fields";
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["uuid"]>;
  invoice_interval?: Maybe<Scalars["String"]>;
  organisation_id?: Maybe<Scalars["uuid"]>;
  party_id?: Maybe<Scalars["uuid"]>;
  product_id?: Maybe<Scalars["uuid"]>;
  subscription_end?: Maybe<Scalars["timestamptz"]>;
  subscription_start?: Maybe<Scalars["timestamptz"]>;
  type_id?: Maybe<Scalars["uuid"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
};

/** order by min() on columns of table "subscriptions" */
export type Subscriptions_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  invoice_interval?: InputMaybe<Order_By>;
  organisation_id?: InputMaybe<Order_By>;
  party_id?: InputMaybe<Order_By>;
  product_id?: InputMaybe<Order_By>;
  subscription_end?: InputMaybe<Order_By>;
  subscription_start?: InputMaybe<Order_By>;
  type_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "subscriptions" */
export type Subscriptions_Mutation_Response = {
  __typename?: "subscriptions_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Subscriptions>;
};

/** input type for inserting object relation for remote table "subscriptions" */
export type Subscriptions_Obj_Rel_Insert_Input = {
  data: Subscriptions_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Subscriptions_On_Conflict>;
};

/** on_conflict condition type for table "subscriptions" */
export type Subscriptions_On_Conflict = {
  constraint: Subscriptions_Constraint;
  update_columns?: Array<Subscriptions_Update_Column>;
  where?: InputMaybe<Subscriptions_Bool_Exp>;
};

/** Ordering options when selecting data from "subscriptions". */
export type Subscriptions_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  invoice_interval?: InputMaybe<Order_By>;
  invoice_items_aggregate?: InputMaybe<Invoice_Items_Aggregate_Order_By>;
  invoices_aggregate?: InputMaybe<Invoices_Aggregate_Order_By>;
  metadata?: InputMaybe<Order_By>;
  organisation_id?: InputMaybe<Order_By>;
  party?: InputMaybe<Parties_Order_By>;
  party_id?: InputMaybe<Order_By>;
  product?: InputMaybe<Products_Order_By>;
  product_id?: InputMaybe<Order_By>;
  subscription_cases_aggregate?: InputMaybe<Subscription_Cases_Aggregate_Order_By>;
  subscription_details_aggregate?: InputMaybe<Subscription_Details_Aggregate_Order_By>;
  subscription_documents_aggregate?: InputMaybe<Subscription_Documents_Aggregate_Order_By>;
  subscription_end?: InputMaybe<Order_By>;
  subscription_flags_aggregate?: InputMaybe<Subscription_Flags_Aggregate_Order_By>;
  subscription_interactions_aggregate?: InputMaybe<Subscription_Interactions_Aggregate_Order_By>;
  subscription_items_aggregate?: InputMaybe<Subscription_Items_Aggregate_Order_By>;
  subscription_payment_transactions_aggregate?: InputMaybe<Subscription_Payment_Transactions_Aggregate_Order_By>;
  subscription_payments_aggregate?: InputMaybe<Subscription_Payments_Aggregate_Order_By>;
  subscription_rules_aggregate?: InputMaybe<Subscription_Rules_Aggregate_Order_By>;
  subscription_schedules_aggregate?: InputMaybe<Subscription_Schedules_Aggregate_Order_By>;
  subscription_start?: InputMaybe<Order_By>;
  subscription_statuses_aggregate?: InputMaybe<Subscription_Statuses_Aggregate_Order_By>;
  subscription_tasks_aggregate?: InputMaybe<Subscription_Tasks_Aggregate_Order_By>;
  type?: InputMaybe<Types_Order_By>;
  type_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: subscriptions */
export type Subscriptions_Pk_Columns_Input = {
  id: Scalars["uuid"];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Subscriptions_Prepend_Input = {
  metadata?: InputMaybe<Scalars["jsonb"]>;
};

/** select columns of table "subscriptions" */
export enum Subscriptions_Select_Column {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  Id = "id",
  /** column name */
  InvoiceInterval = "invoice_interval",
  /** column name */
  Metadata = "metadata",
  /** column name */
  OrganisationId = "organisation_id",
  /** column name */
  PartyId = "party_id",
  /** column name */
  ProductId = "product_id",
  /** column name */
  SubscriptionEnd = "subscription_end",
  /** column name */
  SubscriptionStart = "subscription_start",
  /** column name */
  TypeId = "type_id",
  /** column name */
  UpdatedAt = "updated_at",
}

/** input type for updating data in table "subscriptions" */
export type Subscriptions_Set_Input = {
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  invoice_interval?: InputMaybe<Scalars["String"]>;
  metadata?: InputMaybe<Scalars["jsonb"]>;
  organisation_id?: InputMaybe<Scalars["uuid"]>;
  party_id?: InputMaybe<Scalars["uuid"]>;
  product_id?: InputMaybe<Scalars["uuid"]>;
  subscription_end?: InputMaybe<Scalars["timestamptz"]>;
  subscription_start?: InputMaybe<Scalars["timestamptz"]>;
  type_id?: InputMaybe<Scalars["uuid"]>;
  updated_at?: InputMaybe<Scalars["timestamptz"]>;
};

/** Streaming cursor of the table "subscriptions" */
export type Subscriptions_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Subscriptions_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Subscriptions_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  invoice_interval?: InputMaybe<Scalars["String"]>;
  metadata?: InputMaybe<Scalars["jsonb"]>;
  organisation_id?: InputMaybe<Scalars["uuid"]>;
  party_id?: InputMaybe<Scalars["uuid"]>;
  product_id?: InputMaybe<Scalars["uuid"]>;
  subscription_end?: InputMaybe<Scalars["timestamptz"]>;
  subscription_start?: InputMaybe<Scalars["timestamptz"]>;
  type_id?: InputMaybe<Scalars["uuid"]>;
  updated_at?: InputMaybe<Scalars["timestamptz"]>;
};

/** update columns of table "subscriptions" */
export enum Subscriptions_Update_Column {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  Id = "id",
  /** column name */
  InvoiceInterval = "invoice_interval",
  /** column name */
  Metadata = "metadata",
  /** column name */
  OrganisationId = "organisation_id",
  /** column name */
  PartyId = "party_id",
  /** column name */
  ProductId = "product_id",
  /** column name */
  SubscriptionEnd = "subscription_end",
  /** column name */
  SubscriptionStart = "subscription_start",
  /** column name */
  TypeId = "type_id",
  /** column name */
  UpdatedAt = "updated_at",
}

export type Subscriptions_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Subscriptions_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Subscriptions_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Subscriptions_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Subscriptions_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Subscriptions_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Subscriptions_Set_Input>;
  /** filter the rows which have to be updated */
  where: Subscriptions_Bool_Exp;
};

/** columns and relationships of "tariff_histories" */
export type Tariff_Histories = {
  __typename?: "tariff_histories";
  created_at: Scalars["timestamptz"];
  id: Scalars["uuid"];
  is_percentage: Scalars["Boolean"];
  max_value?: Maybe<Scalars["bigint"]>;
  metadata?: Maybe<Scalars["jsonb"]>;
  min_value: Scalars["bigint"];
  of_field_value: Scalars["Boolean"];
  of_total: Scalars["Boolean"];
  organisation_id: Scalars["uuid"];
  /** An object relationship */
  tariff: Tariffs;
  tariff_id: Scalars["uuid"];
  /** An object relationship */
  type: Types;
  type_id: Scalars["uuid"];
  valid_from: Scalars["timestamptz"];
  valid_to: Scalars["timestamptz"];
  value: Scalars["bigint"];
};

/** columns and relationships of "tariff_histories" */
export type Tariff_HistoriesMetadataArgs = {
  path?: InputMaybe<Scalars["String"]>;
};

/** aggregated selection of "tariff_histories" */
export type Tariff_Histories_Aggregate = {
  __typename?: "tariff_histories_aggregate";
  aggregate?: Maybe<Tariff_Histories_Aggregate_Fields>;
  nodes: Array<Tariff_Histories>;
};

export type Tariff_Histories_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Tariff_Histories_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Tariff_Histories_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Tariff_Histories_Aggregate_Bool_Exp_Count>;
};

export type Tariff_Histories_Aggregate_Bool_Exp_Bool_And = {
  arguments: Tariff_Histories_Select_Column_Tariff_Histories_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<Tariff_Histories_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Tariff_Histories_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Tariff_Histories_Select_Column_Tariff_Histories_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<Tariff_Histories_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Tariff_Histories_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Tariff_Histories_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<Tariff_Histories_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "tariff_histories" */
export type Tariff_Histories_Aggregate_Fields = {
  __typename?: "tariff_histories_aggregate_fields";
  avg?: Maybe<Tariff_Histories_Avg_Fields>;
  count: Scalars["Int"];
  max?: Maybe<Tariff_Histories_Max_Fields>;
  min?: Maybe<Tariff_Histories_Min_Fields>;
  stddev?: Maybe<Tariff_Histories_Stddev_Fields>;
  stddev_pop?: Maybe<Tariff_Histories_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Tariff_Histories_Stddev_Samp_Fields>;
  sum?: Maybe<Tariff_Histories_Sum_Fields>;
  var_pop?: Maybe<Tariff_Histories_Var_Pop_Fields>;
  var_samp?: Maybe<Tariff_Histories_Var_Samp_Fields>;
  variance?: Maybe<Tariff_Histories_Variance_Fields>;
};

/** aggregate fields of "tariff_histories" */
export type Tariff_Histories_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Tariff_Histories_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "tariff_histories" */
export type Tariff_Histories_Aggregate_Order_By = {
  avg?: InputMaybe<Tariff_Histories_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Tariff_Histories_Max_Order_By>;
  min?: InputMaybe<Tariff_Histories_Min_Order_By>;
  stddev?: InputMaybe<Tariff_Histories_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Tariff_Histories_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Tariff_Histories_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Tariff_Histories_Sum_Order_By>;
  var_pop?: InputMaybe<Tariff_Histories_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Tariff_Histories_Var_Samp_Order_By>;
  variance?: InputMaybe<Tariff_Histories_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Tariff_Histories_Append_Input = {
  metadata?: InputMaybe<Scalars["jsonb"]>;
};

/** input type for inserting array relation for remote table "tariff_histories" */
export type Tariff_Histories_Arr_Rel_Insert_Input = {
  data: Array<Tariff_Histories_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Tariff_Histories_On_Conflict>;
};

/** aggregate avg on columns */
export type Tariff_Histories_Avg_Fields = {
  __typename?: "tariff_histories_avg_fields";
  max_value?: Maybe<Scalars["Float"]>;
  min_value?: Maybe<Scalars["Float"]>;
  value?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "tariff_histories" */
export type Tariff_Histories_Avg_Order_By = {
  max_value?: InputMaybe<Order_By>;
  min_value?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "tariff_histories". All fields are combined with a logical 'AND'. */
export type Tariff_Histories_Bool_Exp = {
  _and?: InputMaybe<Array<Tariff_Histories_Bool_Exp>>;
  _not?: InputMaybe<Tariff_Histories_Bool_Exp>;
  _or?: InputMaybe<Array<Tariff_Histories_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  is_percentage?: InputMaybe<Boolean_Comparison_Exp>;
  max_value?: InputMaybe<Bigint_Comparison_Exp>;
  metadata?: InputMaybe<Jsonb_Comparison_Exp>;
  min_value?: InputMaybe<Bigint_Comparison_Exp>;
  of_field_value?: InputMaybe<Boolean_Comparison_Exp>;
  of_total?: InputMaybe<Boolean_Comparison_Exp>;
  organisation_id?: InputMaybe<Uuid_Comparison_Exp>;
  tariff?: InputMaybe<Tariffs_Bool_Exp>;
  tariff_id?: InputMaybe<Uuid_Comparison_Exp>;
  type?: InputMaybe<Types_Bool_Exp>;
  type_id?: InputMaybe<Uuid_Comparison_Exp>;
  valid_from?: InputMaybe<Timestamptz_Comparison_Exp>;
  valid_to?: InputMaybe<Timestamptz_Comparison_Exp>;
  value?: InputMaybe<Bigint_Comparison_Exp>;
};

/** unique or primary key constraints on table "tariff_histories" */
export enum Tariff_Histories_Constraint {
  /** unique or primary key constraint on columns "id" */
  TariffHistoriesPkey = "tariff_histories_pkey",
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Tariff_Histories_Delete_At_Path_Input = {
  metadata?: InputMaybe<Array<Scalars["String"]>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Tariff_Histories_Delete_Elem_Input = {
  metadata?: InputMaybe<Scalars["Int"]>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Tariff_Histories_Delete_Key_Input = {
  metadata?: InputMaybe<Scalars["String"]>;
};

/** input type for incrementing numeric columns in table "tariff_histories" */
export type Tariff_Histories_Inc_Input = {
  max_value?: InputMaybe<Scalars["bigint"]>;
  min_value?: InputMaybe<Scalars["bigint"]>;
  value?: InputMaybe<Scalars["bigint"]>;
};

/** input type for inserting data into table "tariff_histories" */
export type Tariff_Histories_Insert_Input = {
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  is_percentage?: InputMaybe<Scalars["Boolean"]>;
  max_value?: InputMaybe<Scalars["bigint"]>;
  metadata?: InputMaybe<Scalars["jsonb"]>;
  min_value?: InputMaybe<Scalars["bigint"]>;
  of_field_value?: InputMaybe<Scalars["Boolean"]>;
  of_total?: InputMaybe<Scalars["Boolean"]>;
  organisation_id?: InputMaybe<Scalars["uuid"]>;
  tariff?: InputMaybe<Tariffs_Obj_Rel_Insert_Input>;
  tariff_id?: InputMaybe<Scalars["uuid"]>;
  type?: InputMaybe<Types_Obj_Rel_Insert_Input>;
  type_id?: InputMaybe<Scalars["uuid"]>;
  valid_from?: InputMaybe<Scalars["timestamptz"]>;
  valid_to?: InputMaybe<Scalars["timestamptz"]>;
  value?: InputMaybe<Scalars["bigint"]>;
};

/** aggregate max on columns */
export type Tariff_Histories_Max_Fields = {
  __typename?: "tariff_histories_max_fields";
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["uuid"]>;
  max_value?: Maybe<Scalars["bigint"]>;
  min_value?: Maybe<Scalars["bigint"]>;
  organisation_id?: Maybe<Scalars["uuid"]>;
  tariff_id?: Maybe<Scalars["uuid"]>;
  type_id?: Maybe<Scalars["uuid"]>;
  valid_from?: Maybe<Scalars["timestamptz"]>;
  valid_to?: Maybe<Scalars["timestamptz"]>;
  value?: Maybe<Scalars["bigint"]>;
};

/** order by max() on columns of table "tariff_histories" */
export type Tariff_Histories_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  max_value?: InputMaybe<Order_By>;
  min_value?: InputMaybe<Order_By>;
  organisation_id?: InputMaybe<Order_By>;
  tariff_id?: InputMaybe<Order_By>;
  type_id?: InputMaybe<Order_By>;
  valid_from?: InputMaybe<Order_By>;
  valid_to?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Tariff_Histories_Min_Fields = {
  __typename?: "tariff_histories_min_fields";
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["uuid"]>;
  max_value?: Maybe<Scalars["bigint"]>;
  min_value?: Maybe<Scalars["bigint"]>;
  organisation_id?: Maybe<Scalars["uuid"]>;
  tariff_id?: Maybe<Scalars["uuid"]>;
  type_id?: Maybe<Scalars["uuid"]>;
  valid_from?: Maybe<Scalars["timestamptz"]>;
  valid_to?: Maybe<Scalars["timestamptz"]>;
  value?: Maybe<Scalars["bigint"]>;
};

/** order by min() on columns of table "tariff_histories" */
export type Tariff_Histories_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  max_value?: InputMaybe<Order_By>;
  min_value?: InputMaybe<Order_By>;
  organisation_id?: InputMaybe<Order_By>;
  tariff_id?: InputMaybe<Order_By>;
  type_id?: InputMaybe<Order_By>;
  valid_from?: InputMaybe<Order_By>;
  valid_to?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "tariff_histories" */
export type Tariff_Histories_Mutation_Response = {
  __typename?: "tariff_histories_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Tariff_Histories>;
};

/** on_conflict condition type for table "tariff_histories" */
export type Tariff_Histories_On_Conflict = {
  constraint: Tariff_Histories_Constraint;
  update_columns?: Array<Tariff_Histories_Update_Column>;
  where?: InputMaybe<Tariff_Histories_Bool_Exp>;
};

/** Ordering options when selecting data from "tariff_histories". */
export type Tariff_Histories_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  is_percentage?: InputMaybe<Order_By>;
  max_value?: InputMaybe<Order_By>;
  metadata?: InputMaybe<Order_By>;
  min_value?: InputMaybe<Order_By>;
  of_field_value?: InputMaybe<Order_By>;
  of_total?: InputMaybe<Order_By>;
  organisation_id?: InputMaybe<Order_By>;
  tariff?: InputMaybe<Tariffs_Order_By>;
  tariff_id?: InputMaybe<Order_By>;
  type?: InputMaybe<Types_Order_By>;
  type_id?: InputMaybe<Order_By>;
  valid_from?: InputMaybe<Order_By>;
  valid_to?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: tariff_histories */
export type Tariff_Histories_Pk_Columns_Input = {
  id: Scalars["uuid"];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Tariff_Histories_Prepend_Input = {
  metadata?: InputMaybe<Scalars["jsonb"]>;
};

/** select columns of table "tariff_histories" */
export enum Tariff_Histories_Select_Column {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  Id = "id",
  /** column name */
  IsPercentage = "is_percentage",
  /** column name */
  MaxValue = "max_value",
  /** column name */
  Metadata = "metadata",
  /** column name */
  MinValue = "min_value",
  /** column name */
  OfFieldValue = "of_field_value",
  /** column name */
  OfTotal = "of_total",
  /** column name */
  OrganisationId = "organisation_id",
  /** column name */
  TariffId = "tariff_id",
  /** column name */
  TypeId = "type_id",
  /** column name */
  ValidFrom = "valid_from",
  /** column name */
  ValidTo = "valid_to",
  /** column name */
  Value = "value",
}

/** select "tariff_histories_aggregate_bool_exp_bool_and_arguments_columns" columns of table "tariff_histories" */
export enum Tariff_Histories_Select_Column_Tariff_Histories_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  IsPercentage = "is_percentage",
  /** column name */
  OfFieldValue = "of_field_value",
  /** column name */
  OfTotal = "of_total",
}

/** select "tariff_histories_aggregate_bool_exp_bool_or_arguments_columns" columns of table "tariff_histories" */
export enum Tariff_Histories_Select_Column_Tariff_Histories_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  IsPercentage = "is_percentage",
  /** column name */
  OfFieldValue = "of_field_value",
  /** column name */
  OfTotal = "of_total",
}

/** input type for updating data in table "tariff_histories" */
export type Tariff_Histories_Set_Input = {
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  is_percentage?: InputMaybe<Scalars["Boolean"]>;
  max_value?: InputMaybe<Scalars["bigint"]>;
  metadata?: InputMaybe<Scalars["jsonb"]>;
  min_value?: InputMaybe<Scalars["bigint"]>;
  of_field_value?: InputMaybe<Scalars["Boolean"]>;
  of_total?: InputMaybe<Scalars["Boolean"]>;
  organisation_id?: InputMaybe<Scalars["uuid"]>;
  tariff_id?: InputMaybe<Scalars["uuid"]>;
  type_id?: InputMaybe<Scalars["uuid"]>;
  valid_from?: InputMaybe<Scalars["timestamptz"]>;
  valid_to?: InputMaybe<Scalars["timestamptz"]>;
  value?: InputMaybe<Scalars["bigint"]>;
};

/** aggregate stddev on columns */
export type Tariff_Histories_Stddev_Fields = {
  __typename?: "tariff_histories_stddev_fields";
  max_value?: Maybe<Scalars["Float"]>;
  min_value?: Maybe<Scalars["Float"]>;
  value?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "tariff_histories" */
export type Tariff_Histories_Stddev_Order_By = {
  max_value?: InputMaybe<Order_By>;
  min_value?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Tariff_Histories_Stddev_Pop_Fields = {
  __typename?: "tariff_histories_stddev_pop_fields";
  max_value?: Maybe<Scalars["Float"]>;
  min_value?: Maybe<Scalars["Float"]>;
  value?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "tariff_histories" */
export type Tariff_Histories_Stddev_Pop_Order_By = {
  max_value?: InputMaybe<Order_By>;
  min_value?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Tariff_Histories_Stddev_Samp_Fields = {
  __typename?: "tariff_histories_stddev_samp_fields";
  max_value?: Maybe<Scalars["Float"]>;
  min_value?: Maybe<Scalars["Float"]>;
  value?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "tariff_histories" */
export type Tariff_Histories_Stddev_Samp_Order_By = {
  max_value?: InputMaybe<Order_By>;
  min_value?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "tariff_histories" */
export type Tariff_Histories_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Tariff_Histories_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Tariff_Histories_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  is_percentage?: InputMaybe<Scalars["Boolean"]>;
  max_value?: InputMaybe<Scalars["bigint"]>;
  metadata?: InputMaybe<Scalars["jsonb"]>;
  min_value?: InputMaybe<Scalars["bigint"]>;
  of_field_value?: InputMaybe<Scalars["Boolean"]>;
  of_total?: InputMaybe<Scalars["Boolean"]>;
  organisation_id?: InputMaybe<Scalars["uuid"]>;
  tariff_id?: InputMaybe<Scalars["uuid"]>;
  type_id?: InputMaybe<Scalars["uuid"]>;
  valid_from?: InputMaybe<Scalars["timestamptz"]>;
  valid_to?: InputMaybe<Scalars["timestamptz"]>;
  value?: InputMaybe<Scalars["bigint"]>;
};

/** aggregate sum on columns */
export type Tariff_Histories_Sum_Fields = {
  __typename?: "tariff_histories_sum_fields";
  max_value?: Maybe<Scalars["bigint"]>;
  min_value?: Maybe<Scalars["bigint"]>;
  value?: Maybe<Scalars["bigint"]>;
};

/** order by sum() on columns of table "tariff_histories" */
export type Tariff_Histories_Sum_Order_By = {
  max_value?: InputMaybe<Order_By>;
  min_value?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** update columns of table "tariff_histories" */
export enum Tariff_Histories_Update_Column {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  Id = "id",
  /** column name */
  IsPercentage = "is_percentage",
  /** column name */
  MaxValue = "max_value",
  /** column name */
  Metadata = "metadata",
  /** column name */
  MinValue = "min_value",
  /** column name */
  OfFieldValue = "of_field_value",
  /** column name */
  OfTotal = "of_total",
  /** column name */
  OrganisationId = "organisation_id",
  /** column name */
  TariffId = "tariff_id",
  /** column name */
  TypeId = "type_id",
  /** column name */
  ValidFrom = "valid_from",
  /** column name */
  ValidTo = "valid_to",
  /** column name */
  Value = "value",
}

export type Tariff_Histories_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Tariff_Histories_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Tariff_Histories_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Tariff_Histories_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Tariff_Histories_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Tariff_Histories_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Tariff_Histories_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Tariff_Histories_Set_Input>;
  /** filter the rows which have to be updated */
  where: Tariff_Histories_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Tariff_Histories_Var_Pop_Fields = {
  __typename?: "tariff_histories_var_pop_fields";
  max_value?: Maybe<Scalars["Float"]>;
  min_value?: Maybe<Scalars["Float"]>;
  value?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "tariff_histories" */
export type Tariff_Histories_Var_Pop_Order_By = {
  max_value?: InputMaybe<Order_By>;
  min_value?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Tariff_Histories_Var_Samp_Fields = {
  __typename?: "tariff_histories_var_samp_fields";
  max_value?: Maybe<Scalars["Float"]>;
  min_value?: Maybe<Scalars["Float"]>;
  value?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "tariff_histories" */
export type Tariff_Histories_Var_Samp_Order_By = {
  max_value?: InputMaybe<Order_By>;
  min_value?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Tariff_Histories_Variance_Fields = {
  __typename?: "tariff_histories_variance_fields";
  max_value?: Maybe<Scalars["Float"]>;
  min_value?: Maybe<Scalars["Float"]>;
  value?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "tariff_histories" */
export type Tariff_Histories_Variance_Order_By = {
  max_value?: InputMaybe<Order_By>;
  min_value?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** columns and relationships of "tariffs" */
export type Tariffs = {
  __typename?: "tariffs";
  created_at: Scalars["timestamptz"];
  description: Scalars["String"];
  id: Scalars["uuid"];
  is_active: Scalars["Boolean"];
  is_percentage: Scalars["Boolean"];
  max_value?: Maybe<Scalars["bigint"]>;
  metadata?: Maybe<Scalars["jsonb"]>;
  min_value: Scalars["bigint"];
  name: Scalars["String"];
  of_field_value: Scalars["Boolean"];
  of_total: Scalars["Boolean"];
  /** An object relationship */
  organisation: Organisations;
  organisation_id: Scalars["uuid"];
  parent_id?: Maybe<Scalars["uuid"]>;
  /** An array relationship */
  product_items: Array<Product_Items>;
  /** An aggregate relationship */
  product_items_aggregate: Product_Items_Aggregate;
  /** An object relationship */
  tariff?: Maybe<Tariffs>;
  /** An array relationship */
  tariff_histories: Array<Tariff_Histories>;
  /** An aggregate relationship */
  tariff_histories_aggregate: Tariff_Histories_Aggregate;
  /** An array relationship */
  tariffs: Array<Tariffs>;
  /** An aggregate relationship */
  tariffs_aggregate: Tariffs_Aggregate;
  /** An object relationship */
  type: Types;
  type_id: Scalars["uuid"];
  updated_at: Scalars["timestamptz"];
  value: Scalars["float8"];
};

/** columns and relationships of "tariffs" */
export type TariffsMetadataArgs = {
  path?: InputMaybe<Scalars["String"]>;
};

/** columns and relationships of "tariffs" */
export type TariffsProduct_ItemsArgs = {
  distinct_on?: InputMaybe<Array<Product_Items_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Product_Items_Order_By>>;
  where?: InputMaybe<Product_Items_Bool_Exp>;
};

/** columns and relationships of "tariffs" */
export type TariffsProduct_Items_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Product_Items_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Product_Items_Order_By>>;
  where?: InputMaybe<Product_Items_Bool_Exp>;
};

/** columns and relationships of "tariffs" */
export type TariffsTariff_HistoriesArgs = {
  distinct_on?: InputMaybe<Array<Tariff_Histories_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Tariff_Histories_Order_By>>;
  where?: InputMaybe<Tariff_Histories_Bool_Exp>;
};

/** columns and relationships of "tariffs" */
export type TariffsTariff_Histories_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tariff_Histories_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Tariff_Histories_Order_By>>;
  where?: InputMaybe<Tariff_Histories_Bool_Exp>;
};

/** columns and relationships of "tariffs" */
export type TariffsTariffsArgs = {
  distinct_on?: InputMaybe<Array<Tariffs_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Tariffs_Order_By>>;
  where?: InputMaybe<Tariffs_Bool_Exp>;
};

/** columns and relationships of "tariffs" */
export type TariffsTariffs_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tariffs_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Tariffs_Order_By>>;
  where?: InputMaybe<Tariffs_Bool_Exp>;
};

/** aggregated selection of "tariffs" */
export type Tariffs_Aggregate = {
  __typename?: "tariffs_aggregate";
  aggregate?: Maybe<Tariffs_Aggregate_Fields>;
  nodes: Array<Tariffs>;
};

export type Tariffs_Aggregate_Bool_Exp = {
  avg?: InputMaybe<Tariffs_Aggregate_Bool_Exp_Avg>;
  bool_and?: InputMaybe<Tariffs_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Tariffs_Aggregate_Bool_Exp_Bool_Or>;
  corr?: InputMaybe<Tariffs_Aggregate_Bool_Exp_Corr>;
  count?: InputMaybe<Tariffs_Aggregate_Bool_Exp_Count>;
  covar_samp?: InputMaybe<Tariffs_Aggregate_Bool_Exp_Covar_Samp>;
  max?: InputMaybe<Tariffs_Aggregate_Bool_Exp_Max>;
  min?: InputMaybe<Tariffs_Aggregate_Bool_Exp_Min>;
  stddev_samp?: InputMaybe<Tariffs_Aggregate_Bool_Exp_Stddev_Samp>;
  sum?: InputMaybe<Tariffs_Aggregate_Bool_Exp_Sum>;
  var_samp?: InputMaybe<Tariffs_Aggregate_Bool_Exp_Var_Samp>;
};

export type Tariffs_Aggregate_Bool_Exp_Avg = {
  arguments: Tariffs_Select_Column_Tariffs_Aggregate_Bool_Exp_Avg_Arguments_Columns;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<Tariffs_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Tariffs_Aggregate_Bool_Exp_Bool_And = {
  arguments: Tariffs_Select_Column_Tariffs_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<Tariffs_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Tariffs_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Tariffs_Select_Column_Tariffs_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<Tariffs_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Tariffs_Aggregate_Bool_Exp_Corr = {
  arguments: Tariffs_Aggregate_Bool_Exp_Corr_Arguments;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<Tariffs_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Tariffs_Aggregate_Bool_Exp_Corr_Arguments = {
  X: Tariffs_Select_Column_Tariffs_Aggregate_Bool_Exp_Corr_Arguments_Columns;
  Y: Tariffs_Select_Column_Tariffs_Aggregate_Bool_Exp_Corr_Arguments_Columns;
};

export type Tariffs_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Tariffs_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<Tariffs_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

export type Tariffs_Aggregate_Bool_Exp_Covar_Samp = {
  arguments: Tariffs_Aggregate_Bool_Exp_Covar_Samp_Arguments;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<Tariffs_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Tariffs_Aggregate_Bool_Exp_Covar_Samp_Arguments = {
  X: Tariffs_Select_Column_Tariffs_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns;
  Y: Tariffs_Select_Column_Tariffs_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns;
};

export type Tariffs_Aggregate_Bool_Exp_Max = {
  arguments: Tariffs_Select_Column_Tariffs_Aggregate_Bool_Exp_Max_Arguments_Columns;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<Tariffs_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Tariffs_Aggregate_Bool_Exp_Min = {
  arguments: Tariffs_Select_Column_Tariffs_Aggregate_Bool_Exp_Min_Arguments_Columns;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<Tariffs_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Tariffs_Aggregate_Bool_Exp_Stddev_Samp = {
  arguments: Tariffs_Select_Column_Tariffs_Aggregate_Bool_Exp_Stddev_Samp_Arguments_Columns;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<Tariffs_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Tariffs_Aggregate_Bool_Exp_Sum = {
  arguments: Tariffs_Select_Column_Tariffs_Aggregate_Bool_Exp_Sum_Arguments_Columns;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<Tariffs_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Tariffs_Aggregate_Bool_Exp_Var_Samp = {
  arguments: Tariffs_Select_Column_Tariffs_Aggregate_Bool_Exp_Var_Samp_Arguments_Columns;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<Tariffs_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

/** aggregate fields of "tariffs" */
export type Tariffs_Aggregate_Fields = {
  __typename?: "tariffs_aggregate_fields";
  avg?: Maybe<Tariffs_Avg_Fields>;
  count: Scalars["Int"];
  max?: Maybe<Tariffs_Max_Fields>;
  min?: Maybe<Tariffs_Min_Fields>;
  stddev?: Maybe<Tariffs_Stddev_Fields>;
  stddev_pop?: Maybe<Tariffs_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Tariffs_Stddev_Samp_Fields>;
  sum?: Maybe<Tariffs_Sum_Fields>;
  var_pop?: Maybe<Tariffs_Var_Pop_Fields>;
  var_samp?: Maybe<Tariffs_Var_Samp_Fields>;
  variance?: Maybe<Tariffs_Variance_Fields>;
};

/** aggregate fields of "tariffs" */
export type Tariffs_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Tariffs_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "tariffs" */
export type Tariffs_Aggregate_Order_By = {
  avg?: InputMaybe<Tariffs_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Tariffs_Max_Order_By>;
  min?: InputMaybe<Tariffs_Min_Order_By>;
  stddev?: InputMaybe<Tariffs_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Tariffs_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Tariffs_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Tariffs_Sum_Order_By>;
  var_pop?: InputMaybe<Tariffs_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Tariffs_Var_Samp_Order_By>;
  variance?: InputMaybe<Tariffs_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Tariffs_Append_Input = {
  metadata?: InputMaybe<Scalars["jsonb"]>;
};

/** input type for inserting array relation for remote table "tariffs" */
export type Tariffs_Arr_Rel_Insert_Input = {
  data: Array<Tariffs_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Tariffs_On_Conflict>;
};

/** aggregate avg on columns */
export type Tariffs_Avg_Fields = {
  __typename?: "tariffs_avg_fields";
  max_value?: Maybe<Scalars["Float"]>;
  min_value?: Maybe<Scalars["Float"]>;
  value?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "tariffs" */
export type Tariffs_Avg_Order_By = {
  max_value?: InputMaybe<Order_By>;
  min_value?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "tariffs". All fields are combined with a logical 'AND'. */
export type Tariffs_Bool_Exp = {
  _and?: InputMaybe<Array<Tariffs_Bool_Exp>>;
  _not?: InputMaybe<Tariffs_Bool_Exp>;
  _or?: InputMaybe<Array<Tariffs_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  is_active?: InputMaybe<Boolean_Comparison_Exp>;
  is_percentage?: InputMaybe<Boolean_Comparison_Exp>;
  max_value?: InputMaybe<Bigint_Comparison_Exp>;
  metadata?: InputMaybe<Jsonb_Comparison_Exp>;
  min_value?: InputMaybe<Bigint_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  of_field_value?: InputMaybe<Boolean_Comparison_Exp>;
  of_total?: InputMaybe<Boolean_Comparison_Exp>;
  organisation?: InputMaybe<Organisations_Bool_Exp>;
  organisation_id?: InputMaybe<Uuid_Comparison_Exp>;
  parent_id?: InputMaybe<Uuid_Comparison_Exp>;
  product_items?: InputMaybe<Product_Items_Bool_Exp>;
  product_items_aggregate?: InputMaybe<Product_Items_Aggregate_Bool_Exp>;
  tariff?: InputMaybe<Tariffs_Bool_Exp>;
  tariff_histories?: InputMaybe<Tariff_Histories_Bool_Exp>;
  tariff_histories_aggregate?: InputMaybe<Tariff_Histories_Aggregate_Bool_Exp>;
  tariffs?: InputMaybe<Tariffs_Bool_Exp>;
  tariffs_aggregate?: InputMaybe<Tariffs_Aggregate_Bool_Exp>;
  type?: InputMaybe<Types_Bool_Exp>;
  type_id?: InputMaybe<Uuid_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  value?: InputMaybe<Float8_Comparison_Exp>;
};

/** unique or primary key constraints on table "tariffs" */
export enum Tariffs_Constraint {
  /** unique or primary key constraint on columns "id" */
  TariffsPkey = "tariffs_pkey",
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Tariffs_Delete_At_Path_Input = {
  metadata?: InputMaybe<Array<Scalars["String"]>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Tariffs_Delete_Elem_Input = {
  metadata?: InputMaybe<Scalars["Int"]>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Tariffs_Delete_Key_Input = {
  metadata?: InputMaybe<Scalars["String"]>;
};

/** input type for incrementing numeric columns in table "tariffs" */
export type Tariffs_Inc_Input = {
  max_value?: InputMaybe<Scalars["bigint"]>;
  min_value?: InputMaybe<Scalars["bigint"]>;
  value?: InputMaybe<Scalars["float8"]>;
};

/** input type for inserting data into table "tariffs" */
export type Tariffs_Insert_Input = {
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  description?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  is_active?: InputMaybe<Scalars["Boolean"]>;
  is_percentage?: InputMaybe<Scalars["Boolean"]>;
  max_value?: InputMaybe<Scalars["bigint"]>;
  metadata?: InputMaybe<Scalars["jsonb"]>;
  min_value?: InputMaybe<Scalars["bigint"]>;
  name?: InputMaybe<Scalars["String"]>;
  of_field_value?: InputMaybe<Scalars["Boolean"]>;
  of_total?: InputMaybe<Scalars["Boolean"]>;
  organisation?: InputMaybe<Organisations_Obj_Rel_Insert_Input>;
  organisation_id?: InputMaybe<Scalars["uuid"]>;
  parent_id?: InputMaybe<Scalars["uuid"]>;
  product_items?: InputMaybe<Product_Items_Arr_Rel_Insert_Input>;
  tariff?: InputMaybe<Tariffs_Obj_Rel_Insert_Input>;
  tariff_histories?: InputMaybe<Tariff_Histories_Arr_Rel_Insert_Input>;
  tariffs?: InputMaybe<Tariffs_Arr_Rel_Insert_Input>;
  type?: InputMaybe<Types_Obj_Rel_Insert_Input>;
  type_id?: InputMaybe<Scalars["uuid"]>;
  updated_at?: InputMaybe<Scalars["timestamptz"]>;
  value?: InputMaybe<Scalars["float8"]>;
};

/** aggregate max on columns */
export type Tariffs_Max_Fields = {
  __typename?: "tariffs_max_fields";
  created_at?: Maybe<Scalars["timestamptz"]>;
  description?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["uuid"]>;
  max_value?: Maybe<Scalars["bigint"]>;
  min_value?: Maybe<Scalars["bigint"]>;
  name?: Maybe<Scalars["String"]>;
  organisation_id?: Maybe<Scalars["uuid"]>;
  parent_id?: Maybe<Scalars["uuid"]>;
  type_id?: Maybe<Scalars["uuid"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
  value?: Maybe<Scalars["float8"]>;
};

/** order by max() on columns of table "tariffs" */
export type Tariffs_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  max_value?: InputMaybe<Order_By>;
  min_value?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  organisation_id?: InputMaybe<Order_By>;
  parent_id?: InputMaybe<Order_By>;
  type_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Tariffs_Min_Fields = {
  __typename?: "tariffs_min_fields";
  created_at?: Maybe<Scalars["timestamptz"]>;
  description?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["uuid"]>;
  max_value?: Maybe<Scalars["bigint"]>;
  min_value?: Maybe<Scalars["bigint"]>;
  name?: Maybe<Scalars["String"]>;
  organisation_id?: Maybe<Scalars["uuid"]>;
  parent_id?: Maybe<Scalars["uuid"]>;
  type_id?: Maybe<Scalars["uuid"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
  value?: Maybe<Scalars["float8"]>;
};

/** order by min() on columns of table "tariffs" */
export type Tariffs_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  max_value?: InputMaybe<Order_By>;
  min_value?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  organisation_id?: InputMaybe<Order_By>;
  parent_id?: InputMaybe<Order_By>;
  type_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "tariffs" */
export type Tariffs_Mutation_Response = {
  __typename?: "tariffs_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Tariffs>;
};

/** input type for inserting object relation for remote table "tariffs" */
export type Tariffs_Obj_Rel_Insert_Input = {
  data: Tariffs_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Tariffs_On_Conflict>;
};

/** on_conflict condition type for table "tariffs" */
export type Tariffs_On_Conflict = {
  constraint: Tariffs_Constraint;
  update_columns?: Array<Tariffs_Update_Column>;
  where?: InputMaybe<Tariffs_Bool_Exp>;
};

/** Ordering options when selecting data from "tariffs". */
export type Tariffs_Order_By = {
  created_at?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  is_active?: InputMaybe<Order_By>;
  is_percentage?: InputMaybe<Order_By>;
  max_value?: InputMaybe<Order_By>;
  metadata?: InputMaybe<Order_By>;
  min_value?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  of_field_value?: InputMaybe<Order_By>;
  of_total?: InputMaybe<Order_By>;
  organisation?: InputMaybe<Organisations_Order_By>;
  organisation_id?: InputMaybe<Order_By>;
  parent_id?: InputMaybe<Order_By>;
  product_items_aggregate?: InputMaybe<Product_Items_Aggregate_Order_By>;
  tariff?: InputMaybe<Tariffs_Order_By>;
  tariff_histories_aggregate?: InputMaybe<Tariff_Histories_Aggregate_Order_By>;
  tariffs_aggregate?: InputMaybe<Tariffs_Aggregate_Order_By>;
  type?: InputMaybe<Types_Order_By>;
  type_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: tariffs */
export type Tariffs_Pk_Columns_Input = {
  id: Scalars["uuid"];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Tariffs_Prepend_Input = {
  metadata?: InputMaybe<Scalars["jsonb"]>;
};

/** select columns of table "tariffs" */
export enum Tariffs_Select_Column {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  Description = "description",
  /** column name */
  Id = "id",
  /** column name */
  IsActive = "is_active",
  /** column name */
  IsPercentage = "is_percentage",
  /** column name */
  MaxValue = "max_value",
  /** column name */
  Metadata = "metadata",
  /** column name */
  MinValue = "min_value",
  /** column name */
  Name = "name",
  /** column name */
  OfFieldValue = "of_field_value",
  /** column name */
  OfTotal = "of_total",
  /** column name */
  OrganisationId = "organisation_id",
  /** column name */
  ParentId = "parent_id",
  /** column name */
  TypeId = "type_id",
  /** column name */
  UpdatedAt = "updated_at",
  /** column name */
  Value = "value",
}

/** select "tariffs_aggregate_bool_exp_avg_arguments_columns" columns of table "tariffs" */
export enum Tariffs_Select_Column_Tariffs_Aggregate_Bool_Exp_Avg_Arguments_Columns {
  /** column name */
  Value = "value",
}

/** select "tariffs_aggregate_bool_exp_bool_and_arguments_columns" columns of table "tariffs" */
export enum Tariffs_Select_Column_Tariffs_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  IsActive = "is_active",
  /** column name */
  IsPercentage = "is_percentage",
  /** column name */
  OfFieldValue = "of_field_value",
  /** column name */
  OfTotal = "of_total",
}

/** select "tariffs_aggregate_bool_exp_bool_or_arguments_columns" columns of table "tariffs" */
export enum Tariffs_Select_Column_Tariffs_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  IsActive = "is_active",
  /** column name */
  IsPercentage = "is_percentage",
  /** column name */
  OfFieldValue = "of_field_value",
  /** column name */
  OfTotal = "of_total",
}

/** select "tariffs_aggregate_bool_exp_corr_arguments_columns" columns of table "tariffs" */
export enum Tariffs_Select_Column_Tariffs_Aggregate_Bool_Exp_Corr_Arguments_Columns {
  /** column name */
  Value = "value",
}

/** select "tariffs_aggregate_bool_exp_covar_samp_arguments_columns" columns of table "tariffs" */
export enum Tariffs_Select_Column_Tariffs_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns {
  /** column name */
  Value = "value",
}

/** select "tariffs_aggregate_bool_exp_max_arguments_columns" columns of table "tariffs" */
export enum Tariffs_Select_Column_Tariffs_Aggregate_Bool_Exp_Max_Arguments_Columns {
  /** column name */
  Value = "value",
}

/** select "tariffs_aggregate_bool_exp_min_arguments_columns" columns of table "tariffs" */
export enum Tariffs_Select_Column_Tariffs_Aggregate_Bool_Exp_Min_Arguments_Columns {
  /** column name */
  Value = "value",
}

/** select "tariffs_aggregate_bool_exp_stddev_samp_arguments_columns" columns of table "tariffs" */
export enum Tariffs_Select_Column_Tariffs_Aggregate_Bool_Exp_Stddev_Samp_Arguments_Columns {
  /** column name */
  Value = "value",
}

/** select "tariffs_aggregate_bool_exp_sum_arguments_columns" columns of table "tariffs" */
export enum Tariffs_Select_Column_Tariffs_Aggregate_Bool_Exp_Sum_Arguments_Columns {
  /** column name */
  Value = "value",
}

/** select "tariffs_aggregate_bool_exp_var_samp_arguments_columns" columns of table "tariffs" */
export enum Tariffs_Select_Column_Tariffs_Aggregate_Bool_Exp_Var_Samp_Arguments_Columns {
  /** column name */
  Value = "value",
}

/** input type for updating data in table "tariffs" */
export type Tariffs_Set_Input = {
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  description?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  is_active?: InputMaybe<Scalars["Boolean"]>;
  is_percentage?: InputMaybe<Scalars["Boolean"]>;
  max_value?: InputMaybe<Scalars["bigint"]>;
  metadata?: InputMaybe<Scalars["jsonb"]>;
  min_value?: InputMaybe<Scalars["bigint"]>;
  name?: InputMaybe<Scalars["String"]>;
  of_field_value?: InputMaybe<Scalars["Boolean"]>;
  of_total?: InputMaybe<Scalars["Boolean"]>;
  organisation_id?: InputMaybe<Scalars["uuid"]>;
  parent_id?: InputMaybe<Scalars["uuid"]>;
  type_id?: InputMaybe<Scalars["uuid"]>;
  updated_at?: InputMaybe<Scalars["timestamptz"]>;
  value?: InputMaybe<Scalars["float8"]>;
};

/** aggregate stddev on columns */
export type Tariffs_Stddev_Fields = {
  __typename?: "tariffs_stddev_fields";
  max_value?: Maybe<Scalars["Float"]>;
  min_value?: Maybe<Scalars["Float"]>;
  value?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "tariffs" */
export type Tariffs_Stddev_Order_By = {
  max_value?: InputMaybe<Order_By>;
  min_value?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Tariffs_Stddev_Pop_Fields = {
  __typename?: "tariffs_stddev_pop_fields";
  max_value?: Maybe<Scalars["Float"]>;
  min_value?: Maybe<Scalars["Float"]>;
  value?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "tariffs" */
export type Tariffs_Stddev_Pop_Order_By = {
  max_value?: InputMaybe<Order_By>;
  min_value?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Tariffs_Stddev_Samp_Fields = {
  __typename?: "tariffs_stddev_samp_fields";
  max_value?: Maybe<Scalars["Float"]>;
  min_value?: Maybe<Scalars["Float"]>;
  value?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "tariffs" */
export type Tariffs_Stddev_Samp_Order_By = {
  max_value?: InputMaybe<Order_By>;
  min_value?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "tariffs" */
export type Tariffs_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Tariffs_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Tariffs_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  description?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  is_active?: InputMaybe<Scalars["Boolean"]>;
  is_percentage?: InputMaybe<Scalars["Boolean"]>;
  max_value?: InputMaybe<Scalars["bigint"]>;
  metadata?: InputMaybe<Scalars["jsonb"]>;
  min_value?: InputMaybe<Scalars["bigint"]>;
  name?: InputMaybe<Scalars["String"]>;
  of_field_value?: InputMaybe<Scalars["Boolean"]>;
  of_total?: InputMaybe<Scalars["Boolean"]>;
  organisation_id?: InputMaybe<Scalars["uuid"]>;
  parent_id?: InputMaybe<Scalars["uuid"]>;
  type_id?: InputMaybe<Scalars["uuid"]>;
  updated_at?: InputMaybe<Scalars["timestamptz"]>;
  value?: InputMaybe<Scalars["float8"]>;
};

/** aggregate sum on columns */
export type Tariffs_Sum_Fields = {
  __typename?: "tariffs_sum_fields";
  max_value?: Maybe<Scalars["bigint"]>;
  min_value?: Maybe<Scalars["bigint"]>;
  value?: Maybe<Scalars["float8"]>;
};

/** order by sum() on columns of table "tariffs" */
export type Tariffs_Sum_Order_By = {
  max_value?: InputMaybe<Order_By>;
  min_value?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** update columns of table "tariffs" */
export enum Tariffs_Update_Column {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  Description = "description",
  /** column name */
  Id = "id",
  /** column name */
  IsActive = "is_active",
  /** column name */
  IsPercentage = "is_percentage",
  /** column name */
  MaxValue = "max_value",
  /** column name */
  Metadata = "metadata",
  /** column name */
  MinValue = "min_value",
  /** column name */
  Name = "name",
  /** column name */
  OfFieldValue = "of_field_value",
  /** column name */
  OfTotal = "of_total",
  /** column name */
  OrganisationId = "organisation_id",
  /** column name */
  ParentId = "parent_id",
  /** column name */
  TypeId = "type_id",
  /** column name */
  UpdatedAt = "updated_at",
  /** column name */
  Value = "value",
}

export type Tariffs_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Tariffs_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Tariffs_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Tariffs_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Tariffs_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Tariffs_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Tariffs_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Tariffs_Set_Input>;
  /** filter the rows which have to be updated */
  where: Tariffs_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Tariffs_Var_Pop_Fields = {
  __typename?: "tariffs_var_pop_fields";
  max_value?: Maybe<Scalars["Float"]>;
  min_value?: Maybe<Scalars["Float"]>;
  value?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "tariffs" */
export type Tariffs_Var_Pop_Order_By = {
  max_value?: InputMaybe<Order_By>;
  min_value?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Tariffs_Var_Samp_Fields = {
  __typename?: "tariffs_var_samp_fields";
  max_value?: Maybe<Scalars["Float"]>;
  min_value?: Maybe<Scalars["Float"]>;
  value?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "tariffs" */
export type Tariffs_Var_Samp_Order_By = {
  max_value?: InputMaybe<Order_By>;
  min_value?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Tariffs_Variance_Fields = {
  __typename?: "tariffs_variance_fields";
  max_value?: Maybe<Scalars["Float"]>;
  min_value?: Maybe<Scalars["Float"]>;
  value?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "tariffs" */
export type Tariffs_Variance_Order_By = {
  max_value?: InputMaybe<Order_By>;
  min_value?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** columns and relationships of "task_assignments" */
export type Task_Assignments = {
  __typename?: "task_assignments";
  assigned_by: Scalars["uuid"];
  assigned_to: Scalars["uuid"];
  created_at: Scalars["timestamptz"];
  id: Scalars["uuid"];
  is_active?: Maybe<Scalars["Boolean"]>;
  /** An object relationship */
  party: Parties;
  /** An object relationship */
  partyByAssignedTo: Parties;
  /** An object relationship */
  task: Tasks;
  task_id: Scalars["uuid"];
  updated_at: Scalars["timestamptz"];
};

/** aggregated selection of "task_assignments" */
export type Task_Assignments_Aggregate = {
  __typename?: "task_assignments_aggregate";
  aggregate?: Maybe<Task_Assignments_Aggregate_Fields>;
  nodes: Array<Task_Assignments>;
};

export type Task_Assignments_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Task_Assignments_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Task_Assignments_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Task_Assignments_Aggregate_Bool_Exp_Count>;
};

export type Task_Assignments_Aggregate_Bool_Exp_Bool_And = {
  arguments: Task_Assignments_Select_Column_Task_Assignments_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<Task_Assignments_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Task_Assignments_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Task_Assignments_Select_Column_Task_Assignments_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<Task_Assignments_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Task_Assignments_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Task_Assignments_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<Task_Assignments_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "task_assignments" */
export type Task_Assignments_Aggregate_Fields = {
  __typename?: "task_assignments_aggregate_fields";
  count: Scalars["Int"];
  max?: Maybe<Task_Assignments_Max_Fields>;
  min?: Maybe<Task_Assignments_Min_Fields>;
};

/** aggregate fields of "task_assignments" */
export type Task_Assignments_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Task_Assignments_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "task_assignments" */
export type Task_Assignments_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Task_Assignments_Max_Order_By>;
  min?: InputMaybe<Task_Assignments_Min_Order_By>;
};

/** input type for inserting array relation for remote table "task_assignments" */
export type Task_Assignments_Arr_Rel_Insert_Input = {
  data: Array<Task_Assignments_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Task_Assignments_On_Conflict>;
};

/** Boolean expression to filter rows from the table "task_assignments". All fields are combined with a logical 'AND'. */
export type Task_Assignments_Bool_Exp = {
  _and?: InputMaybe<Array<Task_Assignments_Bool_Exp>>;
  _not?: InputMaybe<Task_Assignments_Bool_Exp>;
  _or?: InputMaybe<Array<Task_Assignments_Bool_Exp>>;
  assigned_by?: InputMaybe<Uuid_Comparison_Exp>;
  assigned_to?: InputMaybe<Uuid_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  is_active?: InputMaybe<Boolean_Comparison_Exp>;
  party?: InputMaybe<Parties_Bool_Exp>;
  partyByAssignedTo?: InputMaybe<Parties_Bool_Exp>;
  task?: InputMaybe<Tasks_Bool_Exp>;
  task_id?: InputMaybe<Uuid_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "task_assignments" */
export enum Task_Assignments_Constraint {
  /** unique or primary key constraint on columns "id" */
  TaskAssignmentsPkey = "task_assignments_pkey",
}

/** input type for inserting data into table "task_assignments" */
export type Task_Assignments_Insert_Input = {
  assigned_by?: InputMaybe<Scalars["uuid"]>;
  assigned_to?: InputMaybe<Scalars["uuid"]>;
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  is_active?: InputMaybe<Scalars["Boolean"]>;
  party?: InputMaybe<Parties_Obj_Rel_Insert_Input>;
  partyByAssignedTo?: InputMaybe<Parties_Obj_Rel_Insert_Input>;
  task?: InputMaybe<Tasks_Obj_Rel_Insert_Input>;
  task_id?: InputMaybe<Scalars["uuid"]>;
  updated_at?: InputMaybe<Scalars["timestamptz"]>;
};

/** aggregate max on columns */
export type Task_Assignments_Max_Fields = {
  __typename?: "task_assignments_max_fields";
  assigned_by?: Maybe<Scalars["uuid"]>;
  assigned_to?: Maybe<Scalars["uuid"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["uuid"]>;
  task_id?: Maybe<Scalars["uuid"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
};

/** order by max() on columns of table "task_assignments" */
export type Task_Assignments_Max_Order_By = {
  assigned_by?: InputMaybe<Order_By>;
  assigned_to?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  task_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Task_Assignments_Min_Fields = {
  __typename?: "task_assignments_min_fields";
  assigned_by?: Maybe<Scalars["uuid"]>;
  assigned_to?: Maybe<Scalars["uuid"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["uuid"]>;
  task_id?: Maybe<Scalars["uuid"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
};

/** order by min() on columns of table "task_assignments" */
export type Task_Assignments_Min_Order_By = {
  assigned_by?: InputMaybe<Order_By>;
  assigned_to?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  task_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "task_assignments" */
export type Task_Assignments_Mutation_Response = {
  __typename?: "task_assignments_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Task_Assignments>;
};

/** on_conflict condition type for table "task_assignments" */
export type Task_Assignments_On_Conflict = {
  constraint: Task_Assignments_Constraint;
  update_columns?: Array<Task_Assignments_Update_Column>;
  where?: InputMaybe<Task_Assignments_Bool_Exp>;
};

/** Ordering options when selecting data from "task_assignments". */
export type Task_Assignments_Order_By = {
  assigned_by?: InputMaybe<Order_By>;
  assigned_to?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  is_active?: InputMaybe<Order_By>;
  party?: InputMaybe<Parties_Order_By>;
  partyByAssignedTo?: InputMaybe<Parties_Order_By>;
  task?: InputMaybe<Tasks_Order_By>;
  task_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: task_assignments */
export type Task_Assignments_Pk_Columns_Input = {
  id: Scalars["uuid"];
};

/** select columns of table "task_assignments" */
export enum Task_Assignments_Select_Column {
  /** column name */
  AssignedBy = "assigned_by",
  /** column name */
  AssignedTo = "assigned_to",
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  Id = "id",
  /** column name */
  IsActive = "is_active",
  /** column name */
  TaskId = "task_id",
  /** column name */
  UpdatedAt = "updated_at",
}

/** select "task_assignments_aggregate_bool_exp_bool_and_arguments_columns" columns of table "task_assignments" */
export enum Task_Assignments_Select_Column_Task_Assignments_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  IsActive = "is_active",
}

/** select "task_assignments_aggregate_bool_exp_bool_or_arguments_columns" columns of table "task_assignments" */
export enum Task_Assignments_Select_Column_Task_Assignments_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  IsActive = "is_active",
}

/** input type for updating data in table "task_assignments" */
export type Task_Assignments_Set_Input = {
  assigned_by?: InputMaybe<Scalars["uuid"]>;
  assigned_to?: InputMaybe<Scalars["uuid"]>;
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  is_active?: InputMaybe<Scalars["Boolean"]>;
  task_id?: InputMaybe<Scalars["uuid"]>;
  updated_at?: InputMaybe<Scalars["timestamptz"]>;
};

/** Streaming cursor of the table "task_assignments" */
export type Task_Assignments_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Task_Assignments_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Task_Assignments_Stream_Cursor_Value_Input = {
  assigned_by?: InputMaybe<Scalars["uuid"]>;
  assigned_to?: InputMaybe<Scalars["uuid"]>;
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  is_active?: InputMaybe<Scalars["Boolean"]>;
  task_id?: InputMaybe<Scalars["uuid"]>;
  updated_at?: InputMaybe<Scalars["timestamptz"]>;
};

/** update columns of table "task_assignments" */
export enum Task_Assignments_Update_Column {
  /** column name */
  AssignedBy = "assigned_by",
  /** column name */
  AssignedTo = "assigned_to",
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  Id = "id",
  /** column name */
  IsActive = "is_active",
  /** column name */
  TaskId = "task_id",
  /** column name */
  UpdatedAt = "updated_at",
}

export type Task_Assignments_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Task_Assignments_Set_Input>;
  /** filter the rows which have to be updated */
  where: Task_Assignments_Bool_Exp;
};

/** columns and relationships of "task_documents" */
export type Task_Documents = {
  __typename?: "task_documents";
  created_at?: Maybe<Scalars["timestamptz"]>;
  /** An object relationship */
  document: Documents;
  document_id: Scalars["uuid"];
  id: Scalars["uuid"];
  /** An object relationship */
  task: Tasks;
  task_id: Scalars["uuid"];
};

/** aggregated selection of "task_documents" */
export type Task_Documents_Aggregate = {
  __typename?: "task_documents_aggregate";
  aggregate?: Maybe<Task_Documents_Aggregate_Fields>;
  nodes: Array<Task_Documents>;
};

export type Task_Documents_Aggregate_Bool_Exp = {
  count?: InputMaybe<Task_Documents_Aggregate_Bool_Exp_Count>;
};

export type Task_Documents_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Task_Documents_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<Task_Documents_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "task_documents" */
export type Task_Documents_Aggregate_Fields = {
  __typename?: "task_documents_aggregate_fields";
  count: Scalars["Int"];
  max?: Maybe<Task_Documents_Max_Fields>;
  min?: Maybe<Task_Documents_Min_Fields>;
};

/** aggregate fields of "task_documents" */
export type Task_Documents_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Task_Documents_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "task_documents" */
export type Task_Documents_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Task_Documents_Max_Order_By>;
  min?: InputMaybe<Task_Documents_Min_Order_By>;
};

/** input type for inserting array relation for remote table "task_documents" */
export type Task_Documents_Arr_Rel_Insert_Input = {
  data: Array<Task_Documents_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Task_Documents_On_Conflict>;
};

/** Boolean expression to filter rows from the table "task_documents". All fields are combined with a logical 'AND'. */
export type Task_Documents_Bool_Exp = {
  _and?: InputMaybe<Array<Task_Documents_Bool_Exp>>;
  _not?: InputMaybe<Task_Documents_Bool_Exp>;
  _or?: InputMaybe<Array<Task_Documents_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  document?: InputMaybe<Documents_Bool_Exp>;
  document_id?: InputMaybe<Uuid_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  task?: InputMaybe<Tasks_Bool_Exp>;
  task_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "task_documents" */
export enum Task_Documents_Constraint {
  /** unique or primary key constraint on columns "id" */
  TaskDocumentsPkey = "task_documents_pkey",
}

/** input type for inserting data into table "task_documents" */
export type Task_Documents_Insert_Input = {
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  document?: InputMaybe<Documents_Obj_Rel_Insert_Input>;
  document_id?: InputMaybe<Scalars["uuid"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  task?: InputMaybe<Tasks_Obj_Rel_Insert_Input>;
  task_id?: InputMaybe<Scalars["uuid"]>;
};

/** aggregate max on columns */
export type Task_Documents_Max_Fields = {
  __typename?: "task_documents_max_fields";
  created_at?: Maybe<Scalars["timestamptz"]>;
  document_id?: Maybe<Scalars["uuid"]>;
  id?: Maybe<Scalars["uuid"]>;
  task_id?: Maybe<Scalars["uuid"]>;
};

/** order by max() on columns of table "task_documents" */
export type Task_Documents_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  document_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  task_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Task_Documents_Min_Fields = {
  __typename?: "task_documents_min_fields";
  created_at?: Maybe<Scalars["timestamptz"]>;
  document_id?: Maybe<Scalars["uuid"]>;
  id?: Maybe<Scalars["uuid"]>;
  task_id?: Maybe<Scalars["uuid"]>;
};

/** order by min() on columns of table "task_documents" */
export type Task_Documents_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  document_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  task_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "task_documents" */
export type Task_Documents_Mutation_Response = {
  __typename?: "task_documents_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Task_Documents>;
};

/** on_conflict condition type for table "task_documents" */
export type Task_Documents_On_Conflict = {
  constraint: Task_Documents_Constraint;
  update_columns?: Array<Task_Documents_Update_Column>;
  where?: InputMaybe<Task_Documents_Bool_Exp>;
};

/** Ordering options when selecting data from "task_documents". */
export type Task_Documents_Order_By = {
  created_at?: InputMaybe<Order_By>;
  document?: InputMaybe<Documents_Order_By>;
  document_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  task?: InputMaybe<Tasks_Order_By>;
  task_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: task_documents */
export type Task_Documents_Pk_Columns_Input = {
  id: Scalars["uuid"];
};

/** select columns of table "task_documents" */
export enum Task_Documents_Select_Column {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  DocumentId = "document_id",
  /** column name */
  Id = "id",
  /** column name */
  TaskId = "task_id",
}

/** input type for updating data in table "task_documents" */
export type Task_Documents_Set_Input = {
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  document_id?: InputMaybe<Scalars["uuid"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  task_id?: InputMaybe<Scalars["uuid"]>;
};

/** Streaming cursor of the table "task_documents" */
export type Task_Documents_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Task_Documents_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Task_Documents_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  document_id?: InputMaybe<Scalars["uuid"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  task_id?: InputMaybe<Scalars["uuid"]>;
};

/** update columns of table "task_documents" */
export enum Task_Documents_Update_Column {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  DocumentId = "document_id",
  /** column name */
  Id = "id",
  /** column name */
  TaskId = "task_id",
}

export type Task_Documents_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Task_Documents_Set_Input>;
  /** filter the rows which have to be updated */
  where: Task_Documents_Bool_Exp;
};

/** columns and relationships of "task_flags" */
export type Task_Flags = {
  __typename?: "task_flags";
  created_at?: Maybe<Scalars["timestamptz"]>;
  /** An object relationship */
  flag: Flags;
  flag_id: Scalars["uuid"];
  id: Scalars["uuid"];
  /** An object relationship */
  task: Tasks;
  task_id: Scalars["uuid"];
};

/** aggregated selection of "task_flags" */
export type Task_Flags_Aggregate = {
  __typename?: "task_flags_aggregate";
  aggregate?: Maybe<Task_Flags_Aggregate_Fields>;
  nodes: Array<Task_Flags>;
};

export type Task_Flags_Aggregate_Bool_Exp = {
  count?: InputMaybe<Task_Flags_Aggregate_Bool_Exp_Count>;
};

export type Task_Flags_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Task_Flags_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<Task_Flags_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "task_flags" */
export type Task_Flags_Aggregate_Fields = {
  __typename?: "task_flags_aggregate_fields";
  count: Scalars["Int"];
  max?: Maybe<Task_Flags_Max_Fields>;
  min?: Maybe<Task_Flags_Min_Fields>;
};

/** aggregate fields of "task_flags" */
export type Task_Flags_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Task_Flags_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "task_flags" */
export type Task_Flags_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Task_Flags_Max_Order_By>;
  min?: InputMaybe<Task_Flags_Min_Order_By>;
};

/** input type for inserting array relation for remote table "task_flags" */
export type Task_Flags_Arr_Rel_Insert_Input = {
  data: Array<Task_Flags_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Task_Flags_On_Conflict>;
};

/** Boolean expression to filter rows from the table "task_flags". All fields are combined with a logical 'AND'. */
export type Task_Flags_Bool_Exp = {
  _and?: InputMaybe<Array<Task_Flags_Bool_Exp>>;
  _not?: InputMaybe<Task_Flags_Bool_Exp>;
  _or?: InputMaybe<Array<Task_Flags_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  flag?: InputMaybe<Flags_Bool_Exp>;
  flag_id?: InputMaybe<Uuid_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  task?: InputMaybe<Tasks_Bool_Exp>;
  task_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "task_flags" */
export enum Task_Flags_Constraint {
  /** unique or primary key constraint on columns "id" */
  TaskFlagsPkey = "task_flags_pkey",
}

/** input type for inserting data into table "task_flags" */
export type Task_Flags_Insert_Input = {
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  flag?: InputMaybe<Flags_Obj_Rel_Insert_Input>;
  flag_id?: InputMaybe<Scalars["uuid"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  task?: InputMaybe<Tasks_Obj_Rel_Insert_Input>;
  task_id?: InputMaybe<Scalars["uuid"]>;
};

/** aggregate max on columns */
export type Task_Flags_Max_Fields = {
  __typename?: "task_flags_max_fields";
  created_at?: Maybe<Scalars["timestamptz"]>;
  flag_id?: Maybe<Scalars["uuid"]>;
  id?: Maybe<Scalars["uuid"]>;
  task_id?: Maybe<Scalars["uuid"]>;
};

/** order by max() on columns of table "task_flags" */
export type Task_Flags_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  flag_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  task_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Task_Flags_Min_Fields = {
  __typename?: "task_flags_min_fields";
  created_at?: Maybe<Scalars["timestamptz"]>;
  flag_id?: Maybe<Scalars["uuid"]>;
  id?: Maybe<Scalars["uuid"]>;
  task_id?: Maybe<Scalars["uuid"]>;
};

/** order by min() on columns of table "task_flags" */
export type Task_Flags_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  flag_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  task_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "task_flags" */
export type Task_Flags_Mutation_Response = {
  __typename?: "task_flags_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Task_Flags>;
};

/** on_conflict condition type for table "task_flags" */
export type Task_Flags_On_Conflict = {
  constraint: Task_Flags_Constraint;
  update_columns?: Array<Task_Flags_Update_Column>;
  where?: InputMaybe<Task_Flags_Bool_Exp>;
};

/** Ordering options when selecting data from "task_flags". */
export type Task_Flags_Order_By = {
  created_at?: InputMaybe<Order_By>;
  flag?: InputMaybe<Flags_Order_By>;
  flag_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  task?: InputMaybe<Tasks_Order_By>;
  task_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: task_flags */
export type Task_Flags_Pk_Columns_Input = {
  id: Scalars["uuid"];
};

/** select columns of table "task_flags" */
export enum Task_Flags_Select_Column {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  FlagId = "flag_id",
  /** column name */
  Id = "id",
  /** column name */
  TaskId = "task_id",
}

/** input type for updating data in table "task_flags" */
export type Task_Flags_Set_Input = {
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  flag_id?: InputMaybe<Scalars["uuid"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  task_id?: InputMaybe<Scalars["uuid"]>;
};

/** Streaming cursor of the table "task_flags" */
export type Task_Flags_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Task_Flags_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Task_Flags_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  flag_id?: InputMaybe<Scalars["uuid"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  task_id?: InputMaybe<Scalars["uuid"]>;
};

/** update columns of table "task_flags" */
export enum Task_Flags_Update_Column {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  FlagId = "flag_id",
  /** column name */
  Id = "id",
  /** column name */
  TaskId = "task_id",
}

export type Task_Flags_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Task_Flags_Set_Input>;
  /** filter the rows which have to be updated */
  where: Task_Flags_Bool_Exp;
};

/** columns and relationships of "task_interactions" */
export type Task_Interactions = {
  __typename?: "task_interactions";
  created_at?: Maybe<Scalars["timestamptz"]>;
  id: Scalars["uuid"];
  /** An object relationship */
  interaction: Interactions;
  interaction_id: Scalars["uuid"];
  /** An object relationship */
  task: Tasks;
  task_id: Scalars["uuid"];
};

/** aggregated selection of "task_interactions" */
export type Task_Interactions_Aggregate = {
  __typename?: "task_interactions_aggregate";
  aggregate?: Maybe<Task_Interactions_Aggregate_Fields>;
  nodes: Array<Task_Interactions>;
};

export type Task_Interactions_Aggregate_Bool_Exp = {
  count?: InputMaybe<Task_Interactions_Aggregate_Bool_Exp_Count>;
};

export type Task_Interactions_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Task_Interactions_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<Task_Interactions_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "task_interactions" */
export type Task_Interactions_Aggregate_Fields = {
  __typename?: "task_interactions_aggregate_fields";
  count: Scalars["Int"];
  max?: Maybe<Task_Interactions_Max_Fields>;
  min?: Maybe<Task_Interactions_Min_Fields>;
};

/** aggregate fields of "task_interactions" */
export type Task_Interactions_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Task_Interactions_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "task_interactions" */
export type Task_Interactions_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Task_Interactions_Max_Order_By>;
  min?: InputMaybe<Task_Interactions_Min_Order_By>;
};

/** input type for inserting array relation for remote table "task_interactions" */
export type Task_Interactions_Arr_Rel_Insert_Input = {
  data: Array<Task_Interactions_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Task_Interactions_On_Conflict>;
};

/** Boolean expression to filter rows from the table "task_interactions". All fields are combined with a logical 'AND'. */
export type Task_Interactions_Bool_Exp = {
  _and?: InputMaybe<Array<Task_Interactions_Bool_Exp>>;
  _not?: InputMaybe<Task_Interactions_Bool_Exp>;
  _or?: InputMaybe<Array<Task_Interactions_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  interaction?: InputMaybe<Interactions_Bool_Exp>;
  interaction_id?: InputMaybe<Uuid_Comparison_Exp>;
  task?: InputMaybe<Tasks_Bool_Exp>;
  task_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "task_interactions" */
export enum Task_Interactions_Constraint {
  /** unique or primary key constraint on columns "id" */
  TaskInteractionsPkey = "task_interactions_pkey",
}

/** input type for inserting data into table "task_interactions" */
export type Task_Interactions_Insert_Input = {
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  interaction?: InputMaybe<Interactions_Obj_Rel_Insert_Input>;
  interaction_id?: InputMaybe<Scalars["uuid"]>;
  task?: InputMaybe<Tasks_Obj_Rel_Insert_Input>;
  task_id?: InputMaybe<Scalars["uuid"]>;
};

/** aggregate max on columns */
export type Task_Interactions_Max_Fields = {
  __typename?: "task_interactions_max_fields";
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["uuid"]>;
  interaction_id?: Maybe<Scalars["uuid"]>;
  task_id?: Maybe<Scalars["uuid"]>;
};

/** order by max() on columns of table "task_interactions" */
export type Task_Interactions_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  interaction_id?: InputMaybe<Order_By>;
  task_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Task_Interactions_Min_Fields = {
  __typename?: "task_interactions_min_fields";
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["uuid"]>;
  interaction_id?: Maybe<Scalars["uuid"]>;
  task_id?: Maybe<Scalars["uuid"]>;
};

/** order by min() on columns of table "task_interactions" */
export type Task_Interactions_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  interaction_id?: InputMaybe<Order_By>;
  task_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "task_interactions" */
export type Task_Interactions_Mutation_Response = {
  __typename?: "task_interactions_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Task_Interactions>;
};

/** on_conflict condition type for table "task_interactions" */
export type Task_Interactions_On_Conflict = {
  constraint: Task_Interactions_Constraint;
  update_columns?: Array<Task_Interactions_Update_Column>;
  where?: InputMaybe<Task_Interactions_Bool_Exp>;
};

/** Ordering options when selecting data from "task_interactions". */
export type Task_Interactions_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  interaction?: InputMaybe<Interactions_Order_By>;
  interaction_id?: InputMaybe<Order_By>;
  task?: InputMaybe<Tasks_Order_By>;
  task_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: task_interactions */
export type Task_Interactions_Pk_Columns_Input = {
  id: Scalars["uuid"];
};

/** select columns of table "task_interactions" */
export enum Task_Interactions_Select_Column {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  Id = "id",
  /** column name */
  InteractionId = "interaction_id",
  /** column name */
  TaskId = "task_id",
}

/** input type for updating data in table "task_interactions" */
export type Task_Interactions_Set_Input = {
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  interaction_id?: InputMaybe<Scalars["uuid"]>;
  task_id?: InputMaybe<Scalars["uuid"]>;
};

/** Streaming cursor of the table "task_interactions" */
export type Task_Interactions_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Task_Interactions_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Task_Interactions_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  interaction_id?: InputMaybe<Scalars["uuid"]>;
  task_id?: InputMaybe<Scalars["uuid"]>;
};

/** update columns of table "task_interactions" */
export enum Task_Interactions_Update_Column {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  Id = "id",
  /** column name */
  InteractionId = "interaction_id",
  /** column name */
  TaskId = "task_id",
}

export type Task_Interactions_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Task_Interactions_Set_Input>;
  /** filter the rows which have to be updated */
  where: Task_Interactions_Bool_Exp;
};

/** columns and relationships of "task_mentions" */
export type Task_Mentions = {
  __typename?: "task_mentions";
  created_at?: Maybe<Scalars["timestamptz"]>;
  id: Scalars["uuid"];
  /** An object relationship */
  party: Parties;
  party_id: Scalars["uuid"];
  /** An object relationship */
  task: Tasks;
  task_id: Scalars["uuid"];
};

/** aggregated selection of "task_mentions" */
export type Task_Mentions_Aggregate = {
  __typename?: "task_mentions_aggregate";
  aggregate?: Maybe<Task_Mentions_Aggregate_Fields>;
  nodes: Array<Task_Mentions>;
};

export type Task_Mentions_Aggregate_Bool_Exp = {
  count?: InputMaybe<Task_Mentions_Aggregate_Bool_Exp_Count>;
};

export type Task_Mentions_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Task_Mentions_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<Task_Mentions_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "task_mentions" */
export type Task_Mentions_Aggregate_Fields = {
  __typename?: "task_mentions_aggregate_fields";
  count: Scalars["Int"];
  max?: Maybe<Task_Mentions_Max_Fields>;
  min?: Maybe<Task_Mentions_Min_Fields>;
};

/** aggregate fields of "task_mentions" */
export type Task_Mentions_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Task_Mentions_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "task_mentions" */
export type Task_Mentions_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Task_Mentions_Max_Order_By>;
  min?: InputMaybe<Task_Mentions_Min_Order_By>;
};

/** input type for inserting array relation for remote table "task_mentions" */
export type Task_Mentions_Arr_Rel_Insert_Input = {
  data: Array<Task_Mentions_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Task_Mentions_On_Conflict>;
};

/** Boolean expression to filter rows from the table "task_mentions". All fields are combined with a logical 'AND'. */
export type Task_Mentions_Bool_Exp = {
  _and?: InputMaybe<Array<Task_Mentions_Bool_Exp>>;
  _not?: InputMaybe<Task_Mentions_Bool_Exp>;
  _or?: InputMaybe<Array<Task_Mentions_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  party?: InputMaybe<Parties_Bool_Exp>;
  party_id?: InputMaybe<Uuid_Comparison_Exp>;
  task?: InputMaybe<Tasks_Bool_Exp>;
  task_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "task_mentions" */
export enum Task_Mentions_Constraint {
  /** unique or primary key constraint on columns "id" */
  TaskMentionsPkey = "task_mentions_pkey",
}

/** input type for inserting data into table "task_mentions" */
export type Task_Mentions_Insert_Input = {
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  party?: InputMaybe<Parties_Obj_Rel_Insert_Input>;
  party_id?: InputMaybe<Scalars["uuid"]>;
  task?: InputMaybe<Tasks_Obj_Rel_Insert_Input>;
  task_id?: InputMaybe<Scalars["uuid"]>;
};

/** aggregate max on columns */
export type Task_Mentions_Max_Fields = {
  __typename?: "task_mentions_max_fields";
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["uuid"]>;
  party_id?: Maybe<Scalars["uuid"]>;
  task_id?: Maybe<Scalars["uuid"]>;
};

/** order by max() on columns of table "task_mentions" */
export type Task_Mentions_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  party_id?: InputMaybe<Order_By>;
  task_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Task_Mentions_Min_Fields = {
  __typename?: "task_mentions_min_fields";
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["uuid"]>;
  party_id?: Maybe<Scalars["uuid"]>;
  task_id?: Maybe<Scalars["uuid"]>;
};

/** order by min() on columns of table "task_mentions" */
export type Task_Mentions_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  party_id?: InputMaybe<Order_By>;
  task_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "task_mentions" */
export type Task_Mentions_Mutation_Response = {
  __typename?: "task_mentions_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Task_Mentions>;
};

/** on_conflict condition type for table "task_mentions" */
export type Task_Mentions_On_Conflict = {
  constraint: Task_Mentions_Constraint;
  update_columns?: Array<Task_Mentions_Update_Column>;
  where?: InputMaybe<Task_Mentions_Bool_Exp>;
};

/** Ordering options when selecting data from "task_mentions". */
export type Task_Mentions_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  party?: InputMaybe<Parties_Order_By>;
  party_id?: InputMaybe<Order_By>;
  task?: InputMaybe<Tasks_Order_By>;
  task_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: task_mentions */
export type Task_Mentions_Pk_Columns_Input = {
  id: Scalars["uuid"];
};

/** select columns of table "task_mentions" */
export enum Task_Mentions_Select_Column {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  Id = "id",
  /** column name */
  PartyId = "party_id",
  /** column name */
  TaskId = "task_id",
}

/** input type for updating data in table "task_mentions" */
export type Task_Mentions_Set_Input = {
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  party_id?: InputMaybe<Scalars["uuid"]>;
  task_id?: InputMaybe<Scalars["uuid"]>;
};

/** Streaming cursor of the table "task_mentions" */
export type Task_Mentions_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Task_Mentions_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Task_Mentions_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  party_id?: InputMaybe<Scalars["uuid"]>;
  task_id?: InputMaybe<Scalars["uuid"]>;
};

/** update columns of table "task_mentions" */
export enum Task_Mentions_Update_Column {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  Id = "id",
  /** column name */
  PartyId = "party_id",
  /** column name */
  TaskId = "task_id",
}

export type Task_Mentions_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Task_Mentions_Set_Input>;
  /** filter the rows which have to be updated */
  where: Task_Mentions_Bool_Exp;
};

/** columns and relationships of "task_reminders" */
export type Task_Reminders = {
  __typename?: "task_reminders";
  created_at: Scalars["timestamptz"];
  id: Scalars["uuid"];
  reminder_interval: Scalars["Int"];
  reminder_start: Scalars["Int"];
  /** An object relationship */
  task: Tasks;
  task_id: Scalars["uuid"];
  updated_at: Scalars["timestamptz"];
};

/** aggregated selection of "task_reminders" */
export type Task_Reminders_Aggregate = {
  __typename?: "task_reminders_aggregate";
  aggregate?: Maybe<Task_Reminders_Aggregate_Fields>;
  nodes: Array<Task_Reminders>;
};

export type Task_Reminders_Aggregate_Bool_Exp = {
  count?: InputMaybe<Task_Reminders_Aggregate_Bool_Exp_Count>;
};

export type Task_Reminders_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Task_Reminders_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<Task_Reminders_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "task_reminders" */
export type Task_Reminders_Aggregate_Fields = {
  __typename?: "task_reminders_aggregate_fields";
  avg?: Maybe<Task_Reminders_Avg_Fields>;
  count: Scalars["Int"];
  max?: Maybe<Task_Reminders_Max_Fields>;
  min?: Maybe<Task_Reminders_Min_Fields>;
  stddev?: Maybe<Task_Reminders_Stddev_Fields>;
  stddev_pop?: Maybe<Task_Reminders_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Task_Reminders_Stddev_Samp_Fields>;
  sum?: Maybe<Task_Reminders_Sum_Fields>;
  var_pop?: Maybe<Task_Reminders_Var_Pop_Fields>;
  var_samp?: Maybe<Task_Reminders_Var_Samp_Fields>;
  variance?: Maybe<Task_Reminders_Variance_Fields>;
};

/** aggregate fields of "task_reminders" */
export type Task_Reminders_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Task_Reminders_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "task_reminders" */
export type Task_Reminders_Aggregate_Order_By = {
  avg?: InputMaybe<Task_Reminders_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Task_Reminders_Max_Order_By>;
  min?: InputMaybe<Task_Reminders_Min_Order_By>;
  stddev?: InputMaybe<Task_Reminders_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Task_Reminders_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Task_Reminders_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Task_Reminders_Sum_Order_By>;
  var_pop?: InputMaybe<Task_Reminders_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Task_Reminders_Var_Samp_Order_By>;
  variance?: InputMaybe<Task_Reminders_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "task_reminders" */
export type Task_Reminders_Arr_Rel_Insert_Input = {
  data: Array<Task_Reminders_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Task_Reminders_On_Conflict>;
};

/** aggregate avg on columns */
export type Task_Reminders_Avg_Fields = {
  __typename?: "task_reminders_avg_fields";
  reminder_interval?: Maybe<Scalars["Float"]>;
  reminder_start?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "task_reminders" */
export type Task_Reminders_Avg_Order_By = {
  reminder_interval?: InputMaybe<Order_By>;
  reminder_start?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "task_reminders". All fields are combined with a logical 'AND'. */
export type Task_Reminders_Bool_Exp = {
  _and?: InputMaybe<Array<Task_Reminders_Bool_Exp>>;
  _not?: InputMaybe<Task_Reminders_Bool_Exp>;
  _or?: InputMaybe<Array<Task_Reminders_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  reminder_interval?: InputMaybe<Int_Comparison_Exp>;
  reminder_start?: InputMaybe<Int_Comparison_Exp>;
  task?: InputMaybe<Tasks_Bool_Exp>;
  task_id?: InputMaybe<Uuid_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "task_reminders" */
export enum Task_Reminders_Constraint {
  /** unique or primary key constraint on columns "id" */
  TaskRemindersPkey = "task_reminders_pkey",
}

/** input type for incrementing numeric columns in table "task_reminders" */
export type Task_Reminders_Inc_Input = {
  reminder_interval?: InputMaybe<Scalars["Int"]>;
  reminder_start?: InputMaybe<Scalars["Int"]>;
};

/** input type for inserting data into table "task_reminders" */
export type Task_Reminders_Insert_Input = {
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  reminder_interval?: InputMaybe<Scalars["Int"]>;
  reminder_start?: InputMaybe<Scalars["Int"]>;
  task?: InputMaybe<Tasks_Obj_Rel_Insert_Input>;
  task_id?: InputMaybe<Scalars["uuid"]>;
  updated_at?: InputMaybe<Scalars["timestamptz"]>;
};

/** aggregate max on columns */
export type Task_Reminders_Max_Fields = {
  __typename?: "task_reminders_max_fields";
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["uuid"]>;
  reminder_interval?: Maybe<Scalars["Int"]>;
  reminder_start?: Maybe<Scalars["Int"]>;
  task_id?: Maybe<Scalars["uuid"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
};

/** order by max() on columns of table "task_reminders" */
export type Task_Reminders_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  reminder_interval?: InputMaybe<Order_By>;
  reminder_start?: InputMaybe<Order_By>;
  task_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Task_Reminders_Min_Fields = {
  __typename?: "task_reminders_min_fields";
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["uuid"]>;
  reminder_interval?: Maybe<Scalars["Int"]>;
  reminder_start?: Maybe<Scalars["Int"]>;
  task_id?: Maybe<Scalars["uuid"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
};

/** order by min() on columns of table "task_reminders" */
export type Task_Reminders_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  reminder_interval?: InputMaybe<Order_By>;
  reminder_start?: InputMaybe<Order_By>;
  task_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "task_reminders" */
export type Task_Reminders_Mutation_Response = {
  __typename?: "task_reminders_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Task_Reminders>;
};

/** on_conflict condition type for table "task_reminders" */
export type Task_Reminders_On_Conflict = {
  constraint: Task_Reminders_Constraint;
  update_columns?: Array<Task_Reminders_Update_Column>;
  where?: InputMaybe<Task_Reminders_Bool_Exp>;
};

/** Ordering options when selecting data from "task_reminders". */
export type Task_Reminders_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  reminder_interval?: InputMaybe<Order_By>;
  reminder_start?: InputMaybe<Order_By>;
  task?: InputMaybe<Tasks_Order_By>;
  task_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: task_reminders */
export type Task_Reminders_Pk_Columns_Input = {
  id: Scalars["uuid"];
};

/** select columns of table "task_reminders" */
export enum Task_Reminders_Select_Column {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  Id = "id",
  /** column name */
  ReminderInterval = "reminder_interval",
  /** column name */
  ReminderStart = "reminder_start",
  /** column name */
  TaskId = "task_id",
  /** column name */
  UpdatedAt = "updated_at",
}

/** input type for updating data in table "task_reminders" */
export type Task_Reminders_Set_Input = {
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  reminder_interval?: InputMaybe<Scalars["Int"]>;
  reminder_start?: InputMaybe<Scalars["Int"]>;
  task_id?: InputMaybe<Scalars["uuid"]>;
  updated_at?: InputMaybe<Scalars["timestamptz"]>;
};

/** aggregate stddev on columns */
export type Task_Reminders_Stddev_Fields = {
  __typename?: "task_reminders_stddev_fields";
  reminder_interval?: Maybe<Scalars["Float"]>;
  reminder_start?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "task_reminders" */
export type Task_Reminders_Stddev_Order_By = {
  reminder_interval?: InputMaybe<Order_By>;
  reminder_start?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Task_Reminders_Stddev_Pop_Fields = {
  __typename?: "task_reminders_stddev_pop_fields";
  reminder_interval?: Maybe<Scalars["Float"]>;
  reminder_start?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "task_reminders" */
export type Task_Reminders_Stddev_Pop_Order_By = {
  reminder_interval?: InputMaybe<Order_By>;
  reminder_start?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Task_Reminders_Stddev_Samp_Fields = {
  __typename?: "task_reminders_stddev_samp_fields";
  reminder_interval?: Maybe<Scalars["Float"]>;
  reminder_start?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "task_reminders" */
export type Task_Reminders_Stddev_Samp_Order_By = {
  reminder_interval?: InputMaybe<Order_By>;
  reminder_start?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "task_reminders" */
export type Task_Reminders_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Task_Reminders_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Task_Reminders_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  reminder_interval?: InputMaybe<Scalars["Int"]>;
  reminder_start?: InputMaybe<Scalars["Int"]>;
  task_id?: InputMaybe<Scalars["uuid"]>;
  updated_at?: InputMaybe<Scalars["timestamptz"]>;
};

/** aggregate sum on columns */
export type Task_Reminders_Sum_Fields = {
  __typename?: "task_reminders_sum_fields";
  reminder_interval?: Maybe<Scalars["Int"]>;
  reminder_start?: Maybe<Scalars["Int"]>;
};

/** order by sum() on columns of table "task_reminders" */
export type Task_Reminders_Sum_Order_By = {
  reminder_interval?: InputMaybe<Order_By>;
  reminder_start?: InputMaybe<Order_By>;
};

/** update columns of table "task_reminders" */
export enum Task_Reminders_Update_Column {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  Id = "id",
  /** column name */
  ReminderInterval = "reminder_interval",
  /** column name */
  ReminderStart = "reminder_start",
  /** column name */
  TaskId = "task_id",
  /** column name */
  UpdatedAt = "updated_at",
}

export type Task_Reminders_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Task_Reminders_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Task_Reminders_Set_Input>;
  /** filter the rows which have to be updated */
  where: Task_Reminders_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Task_Reminders_Var_Pop_Fields = {
  __typename?: "task_reminders_var_pop_fields";
  reminder_interval?: Maybe<Scalars["Float"]>;
  reminder_start?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "task_reminders" */
export type Task_Reminders_Var_Pop_Order_By = {
  reminder_interval?: InputMaybe<Order_By>;
  reminder_start?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Task_Reminders_Var_Samp_Fields = {
  __typename?: "task_reminders_var_samp_fields";
  reminder_interval?: Maybe<Scalars["Float"]>;
  reminder_start?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "task_reminders" */
export type Task_Reminders_Var_Samp_Order_By = {
  reminder_interval?: InputMaybe<Order_By>;
  reminder_start?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Task_Reminders_Variance_Fields = {
  __typename?: "task_reminders_variance_fields";
  reminder_interval?: Maybe<Scalars["Float"]>;
  reminder_start?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "task_reminders" */
export type Task_Reminders_Variance_Order_By = {
  reminder_interval?: InputMaybe<Order_By>;
  reminder_start?: InputMaybe<Order_By>;
};

/** columns and relationships of "task_rules" */
export type Task_Rules = {
  __typename?: "task_rules";
  created_at: Scalars["timestamptz"];
  id: Scalars["uuid"];
  is_active: Scalars["Boolean"];
  /** An object relationship */
  rule: Rules;
  rule_id: Scalars["uuid"];
  /** An object relationship */
  task: Tasks;
  task_id: Scalars["uuid"];
  updated_at: Scalars["timestamptz"];
};

/** aggregated selection of "task_rules" */
export type Task_Rules_Aggregate = {
  __typename?: "task_rules_aggregate";
  aggregate?: Maybe<Task_Rules_Aggregate_Fields>;
  nodes: Array<Task_Rules>;
};

export type Task_Rules_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Task_Rules_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Task_Rules_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Task_Rules_Aggregate_Bool_Exp_Count>;
};

export type Task_Rules_Aggregate_Bool_Exp_Bool_And = {
  arguments: Task_Rules_Select_Column_Task_Rules_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<Task_Rules_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Task_Rules_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Task_Rules_Select_Column_Task_Rules_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<Task_Rules_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Task_Rules_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Task_Rules_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<Task_Rules_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "task_rules" */
export type Task_Rules_Aggregate_Fields = {
  __typename?: "task_rules_aggregate_fields";
  count: Scalars["Int"];
  max?: Maybe<Task_Rules_Max_Fields>;
  min?: Maybe<Task_Rules_Min_Fields>;
};

/** aggregate fields of "task_rules" */
export type Task_Rules_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Task_Rules_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "task_rules" */
export type Task_Rules_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Task_Rules_Max_Order_By>;
  min?: InputMaybe<Task_Rules_Min_Order_By>;
};

/** input type for inserting array relation for remote table "task_rules" */
export type Task_Rules_Arr_Rel_Insert_Input = {
  data: Array<Task_Rules_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Task_Rules_On_Conflict>;
};

/** Boolean expression to filter rows from the table "task_rules". All fields are combined with a logical 'AND'. */
export type Task_Rules_Bool_Exp = {
  _and?: InputMaybe<Array<Task_Rules_Bool_Exp>>;
  _not?: InputMaybe<Task_Rules_Bool_Exp>;
  _or?: InputMaybe<Array<Task_Rules_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  is_active?: InputMaybe<Boolean_Comparison_Exp>;
  rule?: InputMaybe<Rules_Bool_Exp>;
  rule_id?: InputMaybe<Uuid_Comparison_Exp>;
  task?: InputMaybe<Tasks_Bool_Exp>;
  task_id?: InputMaybe<Uuid_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "task_rules" */
export enum Task_Rules_Constraint {
  /** unique or primary key constraint on columns "id" */
  TaskRulesPkey = "task_rules_pkey",
}

/** input type for inserting data into table "task_rules" */
export type Task_Rules_Insert_Input = {
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  is_active?: InputMaybe<Scalars["Boolean"]>;
  rule?: InputMaybe<Rules_Obj_Rel_Insert_Input>;
  rule_id?: InputMaybe<Scalars["uuid"]>;
  task?: InputMaybe<Tasks_Obj_Rel_Insert_Input>;
  task_id?: InputMaybe<Scalars["uuid"]>;
  updated_at?: InputMaybe<Scalars["timestamptz"]>;
};

/** aggregate max on columns */
export type Task_Rules_Max_Fields = {
  __typename?: "task_rules_max_fields";
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["uuid"]>;
  rule_id?: Maybe<Scalars["uuid"]>;
  task_id?: Maybe<Scalars["uuid"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
};

/** order by max() on columns of table "task_rules" */
export type Task_Rules_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  rule_id?: InputMaybe<Order_By>;
  task_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Task_Rules_Min_Fields = {
  __typename?: "task_rules_min_fields";
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["uuid"]>;
  rule_id?: Maybe<Scalars["uuid"]>;
  task_id?: Maybe<Scalars["uuid"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
};

/** order by min() on columns of table "task_rules" */
export type Task_Rules_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  rule_id?: InputMaybe<Order_By>;
  task_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "task_rules" */
export type Task_Rules_Mutation_Response = {
  __typename?: "task_rules_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Task_Rules>;
};

/** on_conflict condition type for table "task_rules" */
export type Task_Rules_On_Conflict = {
  constraint: Task_Rules_Constraint;
  update_columns?: Array<Task_Rules_Update_Column>;
  where?: InputMaybe<Task_Rules_Bool_Exp>;
};

/** Ordering options when selecting data from "task_rules". */
export type Task_Rules_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  is_active?: InputMaybe<Order_By>;
  rule?: InputMaybe<Rules_Order_By>;
  rule_id?: InputMaybe<Order_By>;
  task?: InputMaybe<Tasks_Order_By>;
  task_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: task_rules */
export type Task_Rules_Pk_Columns_Input = {
  id: Scalars["uuid"];
};

/** select columns of table "task_rules" */
export enum Task_Rules_Select_Column {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  Id = "id",
  /** column name */
  IsActive = "is_active",
  /** column name */
  RuleId = "rule_id",
  /** column name */
  TaskId = "task_id",
  /** column name */
  UpdatedAt = "updated_at",
}

/** select "task_rules_aggregate_bool_exp_bool_and_arguments_columns" columns of table "task_rules" */
export enum Task_Rules_Select_Column_Task_Rules_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  IsActive = "is_active",
}

/** select "task_rules_aggregate_bool_exp_bool_or_arguments_columns" columns of table "task_rules" */
export enum Task_Rules_Select_Column_Task_Rules_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  IsActive = "is_active",
}

/** input type for updating data in table "task_rules" */
export type Task_Rules_Set_Input = {
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  is_active?: InputMaybe<Scalars["Boolean"]>;
  rule_id?: InputMaybe<Scalars["uuid"]>;
  task_id?: InputMaybe<Scalars["uuid"]>;
  updated_at?: InputMaybe<Scalars["timestamptz"]>;
};

/** Streaming cursor of the table "task_rules" */
export type Task_Rules_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Task_Rules_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Task_Rules_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  is_active?: InputMaybe<Scalars["Boolean"]>;
  rule_id?: InputMaybe<Scalars["uuid"]>;
  task_id?: InputMaybe<Scalars["uuid"]>;
  updated_at?: InputMaybe<Scalars["timestamptz"]>;
};

/** update columns of table "task_rules" */
export enum Task_Rules_Update_Column {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  Id = "id",
  /** column name */
  IsActive = "is_active",
  /** column name */
  RuleId = "rule_id",
  /** column name */
  TaskId = "task_id",
  /** column name */
  UpdatedAt = "updated_at",
}

export type Task_Rules_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Task_Rules_Set_Input>;
  /** filter the rows which have to be updated */
  where: Task_Rules_Bool_Exp;
};

/** columns and relationships of "task_statuses" */
export type Task_Statuses = {
  __typename?: "task_statuses";
  created_at?: Maybe<Scalars["timestamptz"]>;
  id: Scalars["uuid"];
  /** An object relationship */
  status: Statuses;
  status_id: Scalars["uuid"];
  /** An object relationship */
  task: Tasks;
  task_id: Scalars["uuid"];
};

/** aggregated selection of "task_statuses" */
export type Task_Statuses_Aggregate = {
  __typename?: "task_statuses_aggregate";
  aggregate?: Maybe<Task_Statuses_Aggregate_Fields>;
  nodes: Array<Task_Statuses>;
};

export type Task_Statuses_Aggregate_Bool_Exp = {
  count?: InputMaybe<Task_Statuses_Aggregate_Bool_Exp_Count>;
};

export type Task_Statuses_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Task_Statuses_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<Task_Statuses_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "task_statuses" */
export type Task_Statuses_Aggregate_Fields = {
  __typename?: "task_statuses_aggregate_fields";
  count: Scalars["Int"];
  max?: Maybe<Task_Statuses_Max_Fields>;
  min?: Maybe<Task_Statuses_Min_Fields>;
};

/** aggregate fields of "task_statuses" */
export type Task_Statuses_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Task_Statuses_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "task_statuses" */
export type Task_Statuses_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Task_Statuses_Max_Order_By>;
  min?: InputMaybe<Task_Statuses_Min_Order_By>;
};

/** input type for inserting array relation for remote table "task_statuses" */
export type Task_Statuses_Arr_Rel_Insert_Input = {
  data: Array<Task_Statuses_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Task_Statuses_On_Conflict>;
};

/** Boolean expression to filter rows from the table "task_statuses". All fields are combined with a logical 'AND'. */
export type Task_Statuses_Bool_Exp = {
  _and?: InputMaybe<Array<Task_Statuses_Bool_Exp>>;
  _not?: InputMaybe<Task_Statuses_Bool_Exp>;
  _or?: InputMaybe<Array<Task_Statuses_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  status?: InputMaybe<Statuses_Bool_Exp>;
  status_id?: InputMaybe<Uuid_Comparison_Exp>;
  task?: InputMaybe<Tasks_Bool_Exp>;
  task_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "task_statuses" */
export enum Task_Statuses_Constraint {
  /** unique or primary key constraint on columns "id" */
  TaskStatusesPkey = "task_statuses_pkey",
}

/** input type for inserting data into table "task_statuses" */
export type Task_Statuses_Insert_Input = {
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  status?: InputMaybe<Statuses_Obj_Rel_Insert_Input>;
  status_id?: InputMaybe<Scalars["uuid"]>;
  task?: InputMaybe<Tasks_Obj_Rel_Insert_Input>;
  task_id?: InputMaybe<Scalars["uuid"]>;
};

/** aggregate max on columns */
export type Task_Statuses_Max_Fields = {
  __typename?: "task_statuses_max_fields";
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["uuid"]>;
  status_id?: Maybe<Scalars["uuid"]>;
  task_id?: Maybe<Scalars["uuid"]>;
};

/** order by max() on columns of table "task_statuses" */
export type Task_Statuses_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  status_id?: InputMaybe<Order_By>;
  task_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Task_Statuses_Min_Fields = {
  __typename?: "task_statuses_min_fields";
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["uuid"]>;
  status_id?: Maybe<Scalars["uuid"]>;
  task_id?: Maybe<Scalars["uuid"]>;
};

/** order by min() on columns of table "task_statuses" */
export type Task_Statuses_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  status_id?: InputMaybe<Order_By>;
  task_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "task_statuses" */
export type Task_Statuses_Mutation_Response = {
  __typename?: "task_statuses_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Task_Statuses>;
};

/** on_conflict condition type for table "task_statuses" */
export type Task_Statuses_On_Conflict = {
  constraint: Task_Statuses_Constraint;
  update_columns?: Array<Task_Statuses_Update_Column>;
  where?: InputMaybe<Task_Statuses_Bool_Exp>;
};

/** Ordering options when selecting data from "task_statuses". */
export type Task_Statuses_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  status?: InputMaybe<Statuses_Order_By>;
  status_id?: InputMaybe<Order_By>;
  task?: InputMaybe<Tasks_Order_By>;
  task_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: task_statuses */
export type Task_Statuses_Pk_Columns_Input = {
  id: Scalars["uuid"];
};

/** select columns of table "task_statuses" */
export enum Task_Statuses_Select_Column {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  Id = "id",
  /** column name */
  StatusId = "status_id",
  /** column name */
  TaskId = "task_id",
}

/** input type for updating data in table "task_statuses" */
export type Task_Statuses_Set_Input = {
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  status_id?: InputMaybe<Scalars["uuid"]>;
  task_id?: InputMaybe<Scalars["uuid"]>;
};

/** Streaming cursor of the table "task_statuses" */
export type Task_Statuses_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Task_Statuses_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Task_Statuses_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  status_id?: InputMaybe<Scalars["uuid"]>;
  task_id?: InputMaybe<Scalars["uuid"]>;
};

/** update columns of table "task_statuses" */
export enum Task_Statuses_Update_Column {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  Id = "id",
  /** column name */
  StatusId = "status_id",
  /** column name */
  TaskId = "task_id",
}

export type Task_Statuses_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Task_Statuses_Set_Input>;
  /** filter the rows which have to be updated */
  where: Task_Statuses_Bool_Exp;
};

/** columns and relationships of "tasks" */
export type Tasks = {
  __typename?: "tasks";
  assigned_by: Scalars["uuid"];
  assigned_to: Scalars["uuid"];
  /** An array relationship */
  campaign_tasks: Array<Campaign_Tasks>;
  /** An aggregate relationship */
  campaign_tasks_aggregate: Campaign_Tasks_Aggregate;
  /** An array relationship */
  childTasks: Array<Tasks>;
  /** An aggregate relationship */
  childTasks_aggregate: Tasks_Aggregate;
  created_at: Scalars["timestamptz"];
  description: Scalars["String"];
  id: Scalars["uuid"];
  organisation_id?: Maybe<Scalars["uuid"]>;
  /** An object relationship */
  parentTask?: Maybe<Tasks>;
  parent_task_id?: Maybe<Scalars["uuid"]>;
  /** An object relationship */
  party: Parties;
  /** An object relationship */
  partyByAssignedTo: Parties;
  /** An array relationship */
  subscription_tasks: Array<Subscription_Tasks>;
  /** An aggregate relationship */
  subscription_tasks_aggregate: Subscription_Tasks_Aggregate;
  /** An array relationship */
  task_assignments: Array<Task_Assignments>;
  /** An aggregate relationship */
  task_assignments_aggregate: Task_Assignments_Aggregate;
  /** An array relationship */
  task_documents: Array<Task_Documents>;
  /** An aggregate relationship */
  task_documents_aggregate: Task_Documents_Aggregate;
  /** An array relationship */
  task_flags: Array<Task_Flags>;
  /** An aggregate relationship */
  task_flags_aggregate: Task_Flags_Aggregate;
  /** An array relationship */
  task_interactions: Array<Task_Interactions>;
  /** An aggregate relationship */
  task_interactions_aggregate: Task_Interactions_Aggregate;
  /** An array relationship */
  task_mentions: Array<Task_Mentions>;
  /** An aggregate relationship */
  task_mentions_aggregate: Task_Mentions_Aggregate;
  /** An array relationship */
  task_reminders: Array<Task_Reminders>;
  /** An aggregate relationship */
  task_reminders_aggregate: Task_Reminders_Aggregate;
  /** An array relationship */
  task_rules: Array<Task_Rules>;
  /** An aggregate relationship */
  task_rules_aggregate: Task_Rules_Aggregate;
  /** An array relationship */
  task_statuses: Array<Task_Statuses>;
  /** An aggregate relationship */
  task_statuses_aggregate: Task_Statuses_Aggregate;
  title: Scalars["String"];
  /** An object relationship */
  type: Types;
  type_id: Scalars["uuid"];
  updated_at: Scalars["timestamptz"];
};

/** columns and relationships of "tasks" */
export type TasksCampaign_TasksArgs = {
  distinct_on?: InputMaybe<Array<Campaign_Tasks_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Campaign_Tasks_Order_By>>;
  where?: InputMaybe<Campaign_Tasks_Bool_Exp>;
};

/** columns and relationships of "tasks" */
export type TasksCampaign_Tasks_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Campaign_Tasks_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Campaign_Tasks_Order_By>>;
  where?: InputMaybe<Campaign_Tasks_Bool_Exp>;
};

/** columns and relationships of "tasks" */
export type TasksChildTasksArgs = {
  distinct_on?: InputMaybe<Array<Tasks_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Tasks_Order_By>>;
  where?: InputMaybe<Tasks_Bool_Exp>;
};

/** columns and relationships of "tasks" */
export type TasksChildTasks_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tasks_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Tasks_Order_By>>;
  where?: InputMaybe<Tasks_Bool_Exp>;
};

/** columns and relationships of "tasks" */
export type TasksSubscription_TasksArgs = {
  distinct_on?: InputMaybe<Array<Subscription_Tasks_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Subscription_Tasks_Order_By>>;
  where?: InputMaybe<Subscription_Tasks_Bool_Exp>;
};

/** columns and relationships of "tasks" */
export type TasksSubscription_Tasks_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Subscription_Tasks_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Subscription_Tasks_Order_By>>;
  where?: InputMaybe<Subscription_Tasks_Bool_Exp>;
};

/** columns and relationships of "tasks" */
export type TasksTask_AssignmentsArgs = {
  distinct_on?: InputMaybe<Array<Task_Assignments_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Task_Assignments_Order_By>>;
  where?: InputMaybe<Task_Assignments_Bool_Exp>;
};

/** columns and relationships of "tasks" */
export type TasksTask_Assignments_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Task_Assignments_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Task_Assignments_Order_By>>;
  where?: InputMaybe<Task_Assignments_Bool_Exp>;
};

/** columns and relationships of "tasks" */
export type TasksTask_DocumentsArgs = {
  distinct_on?: InputMaybe<Array<Task_Documents_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Task_Documents_Order_By>>;
  where?: InputMaybe<Task_Documents_Bool_Exp>;
};

/** columns and relationships of "tasks" */
export type TasksTask_Documents_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Task_Documents_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Task_Documents_Order_By>>;
  where?: InputMaybe<Task_Documents_Bool_Exp>;
};

/** columns and relationships of "tasks" */
export type TasksTask_FlagsArgs = {
  distinct_on?: InputMaybe<Array<Task_Flags_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Task_Flags_Order_By>>;
  where?: InputMaybe<Task_Flags_Bool_Exp>;
};

/** columns and relationships of "tasks" */
export type TasksTask_Flags_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Task_Flags_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Task_Flags_Order_By>>;
  where?: InputMaybe<Task_Flags_Bool_Exp>;
};

/** columns and relationships of "tasks" */
export type TasksTask_InteractionsArgs = {
  distinct_on?: InputMaybe<Array<Task_Interactions_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Task_Interactions_Order_By>>;
  where?: InputMaybe<Task_Interactions_Bool_Exp>;
};

/** columns and relationships of "tasks" */
export type TasksTask_Interactions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Task_Interactions_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Task_Interactions_Order_By>>;
  where?: InputMaybe<Task_Interactions_Bool_Exp>;
};

/** columns and relationships of "tasks" */
export type TasksTask_MentionsArgs = {
  distinct_on?: InputMaybe<Array<Task_Mentions_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Task_Mentions_Order_By>>;
  where?: InputMaybe<Task_Mentions_Bool_Exp>;
};

/** columns and relationships of "tasks" */
export type TasksTask_Mentions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Task_Mentions_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Task_Mentions_Order_By>>;
  where?: InputMaybe<Task_Mentions_Bool_Exp>;
};

/** columns and relationships of "tasks" */
export type TasksTask_RemindersArgs = {
  distinct_on?: InputMaybe<Array<Task_Reminders_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Task_Reminders_Order_By>>;
  where?: InputMaybe<Task_Reminders_Bool_Exp>;
};

/** columns and relationships of "tasks" */
export type TasksTask_Reminders_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Task_Reminders_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Task_Reminders_Order_By>>;
  where?: InputMaybe<Task_Reminders_Bool_Exp>;
};

/** columns and relationships of "tasks" */
export type TasksTask_RulesArgs = {
  distinct_on?: InputMaybe<Array<Task_Rules_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Task_Rules_Order_By>>;
  where?: InputMaybe<Task_Rules_Bool_Exp>;
};

/** columns and relationships of "tasks" */
export type TasksTask_Rules_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Task_Rules_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Task_Rules_Order_By>>;
  where?: InputMaybe<Task_Rules_Bool_Exp>;
};

/** columns and relationships of "tasks" */
export type TasksTask_StatusesArgs = {
  distinct_on?: InputMaybe<Array<Task_Statuses_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Task_Statuses_Order_By>>;
  where?: InputMaybe<Task_Statuses_Bool_Exp>;
};

/** columns and relationships of "tasks" */
export type TasksTask_Statuses_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Task_Statuses_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Task_Statuses_Order_By>>;
  where?: InputMaybe<Task_Statuses_Bool_Exp>;
};

/** aggregated selection of "tasks" */
export type Tasks_Aggregate = {
  __typename?: "tasks_aggregate";
  aggregate?: Maybe<Tasks_Aggregate_Fields>;
  nodes: Array<Tasks>;
};

export type Tasks_Aggregate_Bool_Exp = {
  count?: InputMaybe<Tasks_Aggregate_Bool_Exp_Count>;
};

export type Tasks_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Tasks_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<Tasks_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "tasks" */
export type Tasks_Aggregate_Fields = {
  __typename?: "tasks_aggregate_fields";
  count: Scalars["Int"];
  max?: Maybe<Tasks_Max_Fields>;
  min?: Maybe<Tasks_Min_Fields>;
};

/** aggregate fields of "tasks" */
export type Tasks_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Tasks_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "tasks" */
export type Tasks_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Tasks_Max_Order_By>;
  min?: InputMaybe<Tasks_Min_Order_By>;
};

/** input type for inserting array relation for remote table "tasks" */
export type Tasks_Arr_Rel_Insert_Input = {
  data: Array<Tasks_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Tasks_On_Conflict>;
};

/** Boolean expression to filter rows from the table "tasks". All fields are combined with a logical 'AND'. */
export type Tasks_Bool_Exp = {
  _and?: InputMaybe<Array<Tasks_Bool_Exp>>;
  _not?: InputMaybe<Tasks_Bool_Exp>;
  _or?: InputMaybe<Array<Tasks_Bool_Exp>>;
  assigned_by?: InputMaybe<Uuid_Comparison_Exp>;
  assigned_to?: InputMaybe<Uuid_Comparison_Exp>;
  campaign_tasks?: InputMaybe<Campaign_Tasks_Bool_Exp>;
  campaign_tasks_aggregate?: InputMaybe<Campaign_Tasks_Aggregate_Bool_Exp>;
  childTasks?: InputMaybe<Tasks_Bool_Exp>;
  childTasks_aggregate?: InputMaybe<Tasks_Aggregate_Bool_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  organisation_id?: InputMaybe<Uuid_Comparison_Exp>;
  parentTask?: InputMaybe<Tasks_Bool_Exp>;
  parent_task_id?: InputMaybe<Uuid_Comparison_Exp>;
  party?: InputMaybe<Parties_Bool_Exp>;
  partyByAssignedTo?: InputMaybe<Parties_Bool_Exp>;
  subscription_tasks?: InputMaybe<Subscription_Tasks_Bool_Exp>;
  subscription_tasks_aggregate?: InputMaybe<Subscription_Tasks_Aggregate_Bool_Exp>;
  task_assignments?: InputMaybe<Task_Assignments_Bool_Exp>;
  task_assignments_aggregate?: InputMaybe<Task_Assignments_Aggregate_Bool_Exp>;
  task_documents?: InputMaybe<Task_Documents_Bool_Exp>;
  task_documents_aggregate?: InputMaybe<Task_Documents_Aggregate_Bool_Exp>;
  task_flags?: InputMaybe<Task_Flags_Bool_Exp>;
  task_flags_aggregate?: InputMaybe<Task_Flags_Aggregate_Bool_Exp>;
  task_interactions?: InputMaybe<Task_Interactions_Bool_Exp>;
  task_interactions_aggregate?: InputMaybe<Task_Interactions_Aggregate_Bool_Exp>;
  task_mentions?: InputMaybe<Task_Mentions_Bool_Exp>;
  task_mentions_aggregate?: InputMaybe<Task_Mentions_Aggregate_Bool_Exp>;
  task_reminders?: InputMaybe<Task_Reminders_Bool_Exp>;
  task_reminders_aggregate?: InputMaybe<Task_Reminders_Aggregate_Bool_Exp>;
  task_rules?: InputMaybe<Task_Rules_Bool_Exp>;
  task_rules_aggregate?: InputMaybe<Task_Rules_Aggregate_Bool_Exp>;
  task_statuses?: InputMaybe<Task_Statuses_Bool_Exp>;
  task_statuses_aggregate?: InputMaybe<Task_Statuses_Aggregate_Bool_Exp>;
  title?: InputMaybe<String_Comparison_Exp>;
  type?: InputMaybe<Types_Bool_Exp>;
  type_id?: InputMaybe<Uuid_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "tasks" */
export enum Tasks_Constraint {
  /** unique or primary key constraint on columns "id" */
  TasksPkey = "tasks_pkey",
}

/** input type for inserting data into table "tasks" */
export type Tasks_Insert_Input = {
  assigned_by?: InputMaybe<Scalars["uuid"]>;
  assigned_to?: InputMaybe<Scalars["uuid"]>;
  campaign_tasks?: InputMaybe<Campaign_Tasks_Arr_Rel_Insert_Input>;
  childTasks?: InputMaybe<Tasks_Arr_Rel_Insert_Input>;
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  description?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  organisation_id?: InputMaybe<Scalars["uuid"]>;
  parentTask?: InputMaybe<Tasks_Obj_Rel_Insert_Input>;
  parent_task_id?: InputMaybe<Scalars["uuid"]>;
  party?: InputMaybe<Parties_Obj_Rel_Insert_Input>;
  partyByAssignedTo?: InputMaybe<Parties_Obj_Rel_Insert_Input>;
  subscription_tasks?: InputMaybe<Subscription_Tasks_Arr_Rel_Insert_Input>;
  task_assignments?: InputMaybe<Task_Assignments_Arr_Rel_Insert_Input>;
  task_documents?: InputMaybe<Task_Documents_Arr_Rel_Insert_Input>;
  task_flags?: InputMaybe<Task_Flags_Arr_Rel_Insert_Input>;
  task_interactions?: InputMaybe<Task_Interactions_Arr_Rel_Insert_Input>;
  task_mentions?: InputMaybe<Task_Mentions_Arr_Rel_Insert_Input>;
  task_reminders?: InputMaybe<Task_Reminders_Arr_Rel_Insert_Input>;
  task_rules?: InputMaybe<Task_Rules_Arr_Rel_Insert_Input>;
  task_statuses?: InputMaybe<Task_Statuses_Arr_Rel_Insert_Input>;
  title?: InputMaybe<Scalars["String"]>;
  type?: InputMaybe<Types_Obj_Rel_Insert_Input>;
  type_id?: InputMaybe<Scalars["uuid"]>;
  updated_at?: InputMaybe<Scalars["timestamptz"]>;
};

/** aggregate max on columns */
export type Tasks_Max_Fields = {
  __typename?: "tasks_max_fields";
  assigned_by?: Maybe<Scalars["uuid"]>;
  assigned_to?: Maybe<Scalars["uuid"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  description?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["uuid"]>;
  organisation_id?: Maybe<Scalars["uuid"]>;
  parent_task_id?: Maybe<Scalars["uuid"]>;
  title?: Maybe<Scalars["String"]>;
  type_id?: Maybe<Scalars["uuid"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
};

/** order by max() on columns of table "tasks" */
export type Tasks_Max_Order_By = {
  assigned_by?: InputMaybe<Order_By>;
  assigned_to?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  organisation_id?: InputMaybe<Order_By>;
  parent_task_id?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
  type_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Tasks_Min_Fields = {
  __typename?: "tasks_min_fields";
  assigned_by?: Maybe<Scalars["uuid"]>;
  assigned_to?: Maybe<Scalars["uuid"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  description?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["uuid"]>;
  organisation_id?: Maybe<Scalars["uuid"]>;
  parent_task_id?: Maybe<Scalars["uuid"]>;
  title?: Maybe<Scalars["String"]>;
  type_id?: Maybe<Scalars["uuid"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
};

/** order by min() on columns of table "tasks" */
export type Tasks_Min_Order_By = {
  assigned_by?: InputMaybe<Order_By>;
  assigned_to?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  organisation_id?: InputMaybe<Order_By>;
  parent_task_id?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
  type_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "tasks" */
export type Tasks_Mutation_Response = {
  __typename?: "tasks_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Tasks>;
};

/** input type for inserting object relation for remote table "tasks" */
export type Tasks_Obj_Rel_Insert_Input = {
  data: Tasks_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Tasks_On_Conflict>;
};

/** on_conflict condition type for table "tasks" */
export type Tasks_On_Conflict = {
  constraint: Tasks_Constraint;
  update_columns?: Array<Tasks_Update_Column>;
  where?: InputMaybe<Tasks_Bool_Exp>;
};

/** Ordering options when selecting data from "tasks". */
export type Tasks_Order_By = {
  assigned_by?: InputMaybe<Order_By>;
  assigned_to?: InputMaybe<Order_By>;
  campaign_tasks_aggregate?: InputMaybe<Campaign_Tasks_Aggregate_Order_By>;
  childTasks_aggregate?: InputMaybe<Tasks_Aggregate_Order_By>;
  created_at?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  organisation_id?: InputMaybe<Order_By>;
  parentTask?: InputMaybe<Tasks_Order_By>;
  parent_task_id?: InputMaybe<Order_By>;
  party?: InputMaybe<Parties_Order_By>;
  partyByAssignedTo?: InputMaybe<Parties_Order_By>;
  subscription_tasks_aggregate?: InputMaybe<Subscription_Tasks_Aggregate_Order_By>;
  task_assignments_aggregate?: InputMaybe<Task_Assignments_Aggregate_Order_By>;
  task_documents_aggregate?: InputMaybe<Task_Documents_Aggregate_Order_By>;
  task_flags_aggregate?: InputMaybe<Task_Flags_Aggregate_Order_By>;
  task_interactions_aggregate?: InputMaybe<Task_Interactions_Aggregate_Order_By>;
  task_mentions_aggregate?: InputMaybe<Task_Mentions_Aggregate_Order_By>;
  task_reminders_aggregate?: InputMaybe<Task_Reminders_Aggregate_Order_By>;
  task_rules_aggregate?: InputMaybe<Task_Rules_Aggregate_Order_By>;
  task_statuses_aggregate?: InputMaybe<Task_Statuses_Aggregate_Order_By>;
  title?: InputMaybe<Order_By>;
  type?: InputMaybe<Types_Order_By>;
  type_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: tasks */
export type Tasks_Pk_Columns_Input = {
  id: Scalars["uuid"];
};

/** select columns of table "tasks" */
export enum Tasks_Select_Column {
  /** column name */
  AssignedBy = "assigned_by",
  /** column name */
  AssignedTo = "assigned_to",
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  Description = "description",
  /** column name */
  Id = "id",
  /** column name */
  OrganisationId = "organisation_id",
  /** column name */
  ParentTaskId = "parent_task_id",
  /** column name */
  Title = "title",
  /** column name */
  TypeId = "type_id",
  /** column name */
  UpdatedAt = "updated_at",
}

/** input type for updating data in table "tasks" */
export type Tasks_Set_Input = {
  assigned_by?: InputMaybe<Scalars["uuid"]>;
  assigned_to?: InputMaybe<Scalars["uuid"]>;
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  description?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  organisation_id?: InputMaybe<Scalars["uuid"]>;
  parent_task_id?: InputMaybe<Scalars["uuid"]>;
  title?: InputMaybe<Scalars["String"]>;
  type_id?: InputMaybe<Scalars["uuid"]>;
  updated_at?: InputMaybe<Scalars["timestamptz"]>;
};

/** Streaming cursor of the table "tasks" */
export type Tasks_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Tasks_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Tasks_Stream_Cursor_Value_Input = {
  assigned_by?: InputMaybe<Scalars["uuid"]>;
  assigned_to?: InputMaybe<Scalars["uuid"]>;
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  description?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  organisation_id?: InputMaybe<Scalars["uuid"]>;
  parent_task_id?: InputMaybe<Scalars["uuid"]>;
  title?: InputMaybe<Scalars["String"]>;
  type_id?: InputMaybe<Scalars["uuid"]>;
  updated_at?: InputMaybe<Scalars["timestamptz"]>;
};

/** update columns of table "tasks" */
export enum Tasks_Update_Column {
  /** column name */
  AssignedBy = "assigned_by",
  /** column name */
  AssignedTo = "assigned_to",
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  Description = "description",
  /** column name */
  Id = "id",
  /** column name */
  OrganisationId = "organisation_id",
  /** column name */
  ParentTaskId = "parent_task_id",
  /** column name */
  Title = "title",
  /** column name */
  TypeId = "type_id",
  /** column name */
  UpdatedAt = "updated_at",
}

export type Tasks_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Tasks_Set_Input>;
  /** filter the rows which have to be updated */
  where: Tasks_Bool_Exp;
};

/** columns and relationships of "territories" */
export type Territories = {
  __typename?: "territories";
  created_at: Scalars["timestamptz"];
  id: Scalars["uuid"];
  name?: Maybe<Scalars["String"]>;
  organisation_id?: Maybe<Scalars["uuid"]>;
  parent_territory?: Maybe<Scalars["uuid"]>;
  /** An object relationship */
  party: Parties;
  party_id: Scalars["uuid"];
  /** An array relationship */
  territories: Array<Territories>;
  /** An aggregate relationship */
  territories_aggregate: Territories_Aggregate;
  /** An object relationship */
  territory?: Maybe<Territories>;
  /** An array relationship */
  territory_assignments: Array<Territory_Assignments>;
  /** An aggregate relationship */
  territory_assignments_aggregate: Territory_Assignments_Aggregate;
  /** An array relationship */
  territory_flags: Array<Territory_Flags>;
  /** An aggregate relationship */
  territory_flags_aggregate: Territory_Flags_Aggregate;
  /** An array relationship */
  territory_interactions: Array<Territory_Interactions>;
  /** An aggregate relationship */
  territory_interactions_aggregate: Territory_Interactions_Aggregate;
  /** An array relationship */
  territory_members: Array<Territory_Members>;
  /** An aggregate relationship */
  territory_members_aggregate: Territory_Members_Aggregate;
  /** An array relationship */
  territory_rules: Array<Territory_Rules>;
  /** An aggregate relationship */
  territory_rules_aggregate: Territory_Rules_Aggregate;
  /** An array relationship */
  territory_statuses: Array<Territory_Statuses>;
  /** An aggregate relationship */
  territory_statuses_aggregate: Territory_Statuses_Aggregate;
  /** An object relationship */
  type: Types;
  type_id: Scalars["uuid"];
  updated_at: Scalars["timestamptz"];
};

/** columns and relationships of "territories" */
export type TerritoriesTerritoriesArgs = {
  distinct_on?: InputMaybe<Array<Territories_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Territories_Order_By>>;
  where?: InputMaybe<Territories_Bool_Exp>;
};

/** columns and relationships of "territories" */
export type TerritoriesTerritories_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Territories_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Territories_Order_By>>;
  where?: InputMaybe<Territories_Bool_Exp>;
};

/** columns and relationships of "territories" */
export type TerritoriesTerritory_AssignmentsArgs = {
  distinct_on?: InputMaybe<Array<Territory_Assignments_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Territory_Assignments_Order_By>>;
  where?: InputMaybe<Territory_Assignments_Bool_Exp>;
};

/** columns and relationships of "territories" */
export type TerritoriesTerritory_Assignments_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Territory_Assignments_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Territory_Assignments_Order_By>>;
  where?: InputMaybe<Territory_Assignments_Bool_Exp>;
};

/** columns and relationships of "territories" */
export type TerritoriesTerritory_FlagsArgs = {
  distinct_on?: InputMaybe<Array<Territory_Flags_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Territory_Flags_Order_By>>;
  where?: InputMaybe<Territory_Flags_Bool_Exp>;
};

/** columns and relationships of "territories" */
export type TerritoriesTerritory_Flags_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Territory_Flags_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Territory_Flags_Order_By>>;
  where?: InputMaybe<Territory_Flags_Bool_Exp>;
};

/** columns and relationships of "territories" */
export type TerritoriesTerritory_InteractionsArgs = {
  distinct_on?: InputMaybe<Array<Territory_Interactions_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Territory_Interactions_Order_By>>;
  where?: InputMaybe<Territory_Interactions_Bool_Exp>;
};

/** columns and relationships of "territories" */
export type TerritoriesTerritory_Interactions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Territory_Interactions_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Territory_Interactions_Order_By>>;
  where?: InputMaybe<Territory_Interactions_Bool_Exp>;
};

/** columns and relationships of "territories" */
export type TerritoriesTerritory_MembersArgs = {
  distinct_on?: InputMaybe<Array<Territory_Members_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Territory_Members_Order_By>>;
  where?: InputMaybe<Territory_Members_Bool_Exp>;
};

/** columns and relationships of "territories" */
export type TerritoriesTerritory_Members_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Territory_Members_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Territory_Members_Order_By>>;
  where?: InputMaybe<Territory_Members_Bool_Exp>;
};

/** columns and relationships of "territories" */
export type TerritoriesTerritory_RulesArgs = {
  distinct_on?: InputMaybe<Array<Territory_Rules_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Territory_Rules_Order_By>>;
  where?: InputMaybe<Territory_Rules_Bool_Exp>;
};

/** columns and relationships of "territories" */
export type TerritoriesTerritory_Rules_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Territory_Rules_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Territory_Rules_Order_By>>;
  where?: InputMaybe<Territory_Rules_Bool_Exp>;
};

/** columns and relationships of "territories" */
export type TerritoriesTerritory_StatusesArgs = {
  distinct_on?: InputMaybe<Array<Territory_Statuses_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Territory_Statuses_Order_By>>;
  where?: InputMaybe<Territory_Statuses_Bool_Exp>;
};

/** columns and relationships of "territories" */
export type TerritoriesTerritory_Statuses_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Territory_Statuses_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Territory_Statuses_Order_By>>;
  where?: InputMaybe<Territory_Statuses_Bool_Exp>;
};

/** aggregated selection of "territories" */
export type Territories_Aggregate = {
  __typename?: "territories_aggregate";
  aggregate?: Maybe<Territories_Aggregate_Fields>;
  nodes: Array<Territories>;
};

export type Territories_Aggregate_Bool_Exp = {
  count?: InputMaybe<Territories_Aggregate_Bool_Exp_Count>;
};

export type Territories_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Territories_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<Territories_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "territories" */
export type Territories_Aggregate_Fields = {
  __typename?: "territories_aggregate_fields";
  count: Scalars["Int"];
  max?: Maybe<Territories_Max_Fields>;
  min?: Maybe<Territories_Min_Fields>;
};

/** aggregate fields of "territories" */
export type Territories_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Territories_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "territories" */
export type Territories_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Territories_Max_Order_By>;
  min?: InputMaybe<Territories_Min_Order_By>;
};

/** input type for inserting array relation for remote table "territories" */
export type Territories_Arr_Rel_Insert_Input = {
  data: Array<Territories_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Territories_On_Conflict>;
};

/** Boolean expression to filter rows from the table "territories". All fields are combined with a logical 'AND'. */
export type Territories_Bool_Exp = {
  _and?: InputMaybe<Array<Territories_Bool_Exp>>;
  _not?: InputMaybe<Territories_Bool_Exp>;
  _or?: InputMaybe<Array<Territories_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  organisation_id?: InputMaybe<Uuid_Comparison_Exp>;
  parent_territory?: InputMaybe<Uuid_Comparison_Exp>;
  party?: InputMaybe<Parties_Bool_Exp>;
  party_id?: InputMaybe<Uuid_Comparison_Exp>;
  territories?: InputMaybe<Territories_Bool_Exp>;
  territories_aggregate?: InputMaybe<Territories_Aggregate_Bool_Exp>;
  territory?: InputMaybe<Territories_Bool_Exp>;
  territory_assignments?: InputMaybe<Territory_Assignments_Bool_Exp>;
  territory_assignments_aggregate?: InputMaybe<Territory_Assignments_Aggregate_Bool_Exp>;
  territory_flags?: InputMaybe<Territory_Flags_Bool_Exp>;
  territory_flags_aggregate?: InputMaybe<Territory_Flags_Aggregate_Bool_Exp>;
  territory_interactions?: InputMaybe<Territory_Interactions_Bool_Exp>;
  territory_interactions_aggregate?: InputMaybe<Territory_Interactions_Aggregate_Bool_Exp>;
  territory_members?: InputMaybe<Territory_Members_Bool_Exp>;
  territory_members_aggregate?: InputMaybe<Territory_Members_Aggregate_Bool_Exp>;
  territory_rules?: InputMaybe<Territory_Rules_Bool_Exp>;
  territory_rules_aggregate?: InputMaybe<Territory_Rules_Aggregate_Bool_Exp>;
  territory_statuses?: InputMaybe<Territory_Statuses_Bool_Exp>;
  territory_statuses_aggregate?: InputMaybe<Territory_Statuses_Aggregate_Bool_Exp>;
  type?: InputMaybe<Types_Bool_Exp>;
  type_id?: InputMaybe<Uuid_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "territories" */
export enum Territories_Constraint {
  /** unique or primary key constraint on columns "id" */
  TerritoriesPkey = "territories_pkey",
}

/** input type for inserting data into table "territories" */
export type Territories_Insert_Input = {
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  name?: InputMaybe<Scalars["String"]>;
  organisation_id?: InputMaybe<Scalars["uuid"]>;
  parent_territory?: InputMaybe<Scalars["uuid"]>;
  party?: InputMaybe<Parties_Obj_Rel_Insert_Input>;
  party_id?: InputMaybe<Scalars["uuid"]>;
  territories?: InputMaybe<Territories_Arr_Rel_Insert_Input>;
  territory?: InputMaybe<Territories_Obj_Rel_Insert_Input>;
  territory_assignments?: InputMaybe<Territory_Assignments_Arr_Rel_Insert_Input>;
  territory_flags?: InputMaybe<Territory_Flags_Arr_Rel_Insert_Input>;
  territory_interactions?: InputMaybe<Territory_Interactions_Arr_Rel_Insert_Input>;
  territory_members?: InputMaybe<Territory_Members_Arr_Rel_Insert_Input>;
  territory_rules?: InputMaybe<Territory_Rules_Arr_Rel_Insert_Input>;
  territory_statuses?: InputMaybe<Territory_Statuses_Arr_Rel_Insert_Input>;
  type?: InputMaybe<Types_Obj_Rel_Insert_Input>;
  type_id?: InputMaybe<Scalars["uuid"]>;
  updated_at?: InputMaybe<Scalars["timestamptz"]>;
};

/** aggregate max on columns */
export type Territories_Max_Fields = {
  __typename?: "territories_max_fields";
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["uuid"]>;
  name?: Maybe<Scalars["String"]>;
  organisation_id?: Maybe<Scalars["uuid"]>;
  parent_territory?: Maybe<Scalars["uuid"]>;
  party_id?: Maybe<Scalars["uuid"]>;
  type_id?: Maybe<Scalars["uuid"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
};

/** order by max() on columns of table "territories" */
export type Territories_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  organisation_id?: InputMaybe<Order_By>;
  parent_territory?: InputMaybe<Order_By>;
  party_id?: InputMaybe<Order_By>;
  type_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Territories_Min_Fields = {
  __typename?: "territories_min_fields";
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["uuid"]>;
  name?: Maybe<Scalars["String"]>;
  organisation_id?: Maybe<Scalars["uuid"]>;
  parent_territory?: Maybe<Scalars["uuid"]>;
  party_id?: Maybe<Scalars["uuid"]>;
  type_id?: Maybe<Scalars["uuid"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
};

/** order by min() on columns of table "territories" */
export type Territories_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  organisation_id?: InputMaybe<Order_By>;
  parent_territory?: InputMaybe<Order_By>;
  party_id?: InputMaybe<Order_By>;
  type_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "territories" */
export type Territories_Mutation_Response = {
  __typename?: "territories_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Territories>;
};

/** input type for inserting object relation for remote table "territories" */
export type Territories_Obj_Rel_Insert_Input = {
  data: Territories_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Territories_On_Conflict>;
};

/** on_conflict condition type for table "territories" */
export type Territories_On_Conflict = {
  constraint: Territories_Constraint;
  update_columns?: Array<Territories_Update_Column>;
  where?: InputMaybe<Territories_Bool_Exp>;
};

/** Ordering options when selecting data from "territories". */
export type Territories_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  organisation_id?: InputMaybe<Order_By>;
  parent_territory?: InputMaybe<Order_By>;
  party?: InputMaybe<Parties_Order_By>;
  party_id?: InputMaybe<Order_By>;
  territories_aggregate?: InputMaybe<Territories_Aggregate_Order_By>;
  territory?: InputMaybe<Territories_Order_By>;
  territory_assignments_aggregate?: InputMaybe<Territory_Assignments_Aggregate_Order_By>;
  territory_flags_aggregate?: InputMaybe<Territory_Flags_Aggregate_Order_By>;
  territory_interactions_aggregate?: InputMaybe<Territory_Interactions_Aggregate_Order_By>;
  territory_members_aggregate?: InputMaybe<Territory_Members_Aggregate_Order_By>;
  territory_rules_aggregate?: InputMaybe<Territory_Rules_Aggregate_Order_By>;
  territory_statuses_aggregate?: InputMaybe<Territory_Statuses_Aggregate_Order_By>;
  type?: InputMaybe<Types_Order_By>;
  type_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: territories */
export type Territories_Pk_Columns_Input = {
  id: Scalars["uuid"];
};

/** select columns of table "territories" */
export enum Territories_Select_Column {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  Id = "id",
  /** column name */
  Name = "name",
  /** column name */
  OrganisationId = "organisation_id",
  /** column name */
  ParentTerritory = "parent_territory",
  /** column name */
  PartyId = "party_id",
  /** column name */
  TypeId = "type_id",
  /** column name */
  UpdatedAt = "updated_at",
}

/** input type for updating data in table "territories" */
export type Territories_Set_Input = {
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  name?: InputMaybe<Scalars["String"]>;
  organisation_id?: InputMaybe<Scalars["uuid"]>;
  parent_territory?: InputMaybe<Scalars["uuid"]>;
  party_id?: InputMaybe<Scalars["uuid"]>;
  type_id?: InputMaybe<Scalars["uuid"]>;
  updated_at?: InputMaybe<Scalars["timestamptz"]>;
};

/** Streaming cursor of the table "territories" */
export type Territories_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Territories_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Territories_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  name?: InputMaybe<Scalars["String"]>;
  organisation_id?: InputMaybe<Scalars["uuid"]>;
  parent_territory?: InputMaybe<Scalars["uuid"]>;
  party_id?: InputMaybe<Scalars["uuid"]>;
  type_id?: InputMaybe<Scalars["uuid"]>;
  updated_at?: InputMaybe<Scalars["timestamptz"]>;
};

/** update columns of table "territories" */
export enum Territories_Update_Column {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  Id = "id",
  /** column name */
  Name = "name",
  /** column name */
  OrganisationId = "organisation_id",
  /** column name */
  ParentTerritory = "parent_territory",
  /** column name */
  PartyId = "party_id",
  /** column name */
  TypeId = "type_id",
  /** column name */
  UpdatedAt = "updated_at",
}

export type Territories_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Territories_Set_Input>;
  /** filter the rows which have to be updated */
  where: Territories_Bool_Exp;
};

/** columns and relationships of "territory_assignments" */
export type Territory_Assignments = {
  __typename?: "territory_assignments";
  assigned_by: Scalars["uuid"];
  assigned_to: Scalars["uuid"];
  created_at: Scalars["timestamptz"];
  id: Scalars["uuid"];
  is_active: Scalars["Boolean"];
  /** An object relationship */
  party: Parties;
  /** An object relationship */
  partyByAssignedTo: Parties;
  /** An object relationship */
  territory: Territories;
  territory_id: Scalars["uuid"];
  updated_at: Scalars["timestamptz"];
};

/** aggregated selection of "territory_assignments" */
export type Territory_Assignments_Aggregate = {
  __typename?: "territory_assignments_aggregate";
  aggregate?: Maybe<Territory_Assignments_Aggregate_Fields>;
  nodes: Array<Territory_Assignments>;
};

export type Territory_Assignments_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Territory_Assignments_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Territory_Assignments_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Territory_Assignments_Aggregate_Bool_Exp_Count>;
};

export type Territory_Assignments_Aggregate_Bool_Exp_Bool_And = {
  arguments: Territory_Assignments_Select_Column_Territory_Assignments_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<Territory_Assignments_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Territory_Assignments_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Territory_Assignments_Select_Column_Territory_Assignments_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<Territory_Assignments_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Territory_Assignments_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Territory_Assignments_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<Territory_Assignments_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "territory_assignments" */
export type Territory_Assignments_Aggregate_Fields = {
  __typename?: "territory_assignments_aggregate_fields";
  count: Scalars["Int"];
  max?: Maybe<Territory_Assignments_Max_Fields>;
  min?: Maybe<Territory_Assignments_Min_Fields>;
};

/** aggregate fields of "territory_assignments" */
export type Territory_Assignments_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Territory_Assignments_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "territory_assignments" */
export type Territory_Assignments_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Territory_Assignments_Max_Order_By>;
  min?: InputMaybe<Territory_Assignments_Min_Order_By>;
};

/** input type for inserting array relation for remote table "territory_assignments" */
export type Territory_Assignments_Arr_Rel_Insert_Input = {
  data: Array<Territory_Assignments_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Territory_Assignments_On_Conflict>;
};

/** Boolean expression to filter rows from the table "territory_assignments". All fields are combined with a logical 'AND'. */
export type Territory_Assignments_Bool_Exp = {
  _and?: InputMaybe<Array<Territory_Assignments_Bool_Exp>>;
  _not?: InputMaybe<Territory_Assignments_Bool_Exp>;
  _or?: InputMaybe<Array<Territory_Assignments_Bool_Exp>>;
  assigned_by?: InputMaybe<Uuid_Comparison_Exp>;
  assigned_to?: InputMaybe<Uuid_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  is_active?: InputMaybe<Boolean_Comparison_Exp>;
  party?: InputMaybe<Parties_Bool_Exp>;
  partyByAssignedTo?: InputMaybe<Parties_Bool_Exp>;
  territory?: InputMaybe<Territories_Bool_Exp>;
  territory_id?: InputMaybe<Uuid_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "territory_assignments" */
export enum Territory_Assignments_Constraint {
  /** unique or primary key constraint on columns "id" */
  TerritoryAssignmentsPkey = "territory_assignments_pkey",
}

/** input type for inserting data into table "territory_assignments" */
export type Territory_Assignments_Insert_Input = {
  assigned_by?: InputMaybe<Scalars["uuid"]>;
  assigned_to?: InputMaybe<Scalars["uuid"]>;
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  is_active?: InputMaybe<Scalars["Boolean"]>;
  party?: InputMaybe<Parties_Obj_Rel_Insert_Input>;
  partyByAssignedTo?: InputMaybe<Parties_Obj_Rel_Insert_Input>;
  territory?: InputMaybe<Territories_Obj_Rel_Insert_Input>;
  territory_id?: InputMaybe<Scalars["uuid"]>;
  updated_at?: InputMaybe<Scalars["timestamptz"]>;
};

/** aggregate max on columns */
export type Territory_Assignments_Max_Fields = {
  __typename?: "territory_assignments_max_fields";
  assigned_by?: Maybe<Scalars["uuid"]>;
  assigned_to?: Maybe<Scalars["uuid"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["uuid"]>;
  territory_id?: Maybe<Scalars["uuid"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
};

/** order by max() on columns of table "territory_assignments" */
export type Territory_Assignments_Max_Order_By = {
  assigned_by?: InputMaybe<Order_By>;
  assigned_to?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  territory_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Territory_Assignments_Min_Fields = {
  __typename?: "territory_assignments_min_fields";
  assigned_by?: Maybe<Scalars["uuid"]>;
  assigned_to?: Maybe<Scalars["uuid"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["uuid"]>;
  territory_id?: Maybe<Scalars["uuid"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
};

/** order by min() on columns of table "territory_assignments" */
export type Territory_Assignments_Min_Order_By = {
  assigned_by?: InputMaybe<Order_By>;
  assigned_to?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  territory_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "territory_assignments" */
export type Territory_Assignments_Mutation_Response = {
  __typename?: "territory_assignments_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Territory_Assignments>;
};

/** on_conflict condition type for table "territory_assignments" */
export type Territory_Assignments_On_Conflict = {
  constraint: Territory_Assignments_Constraint;
  update_columns?: Array<Territory_Assignments_Update_Column>;
  where?: InputMaybe<Territory_Assignments_Bool_Exp>;
};

/** Ordering options when selecting data from "territory_assignments". */
export type Territory_Assignments_Order_By = {
  assigned_by?: InputMaybe<Order_By>;
  assigned_to?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  is_active?: InputMaybe<Order_By>;
  party?: InputMaybe<Parties_Order_By>;
  partyByAssignedTo?: InputMaybe<Parties_Order_By>;
  territory?: InputMaybe<Territories_Order_By>;
  territory_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: territory_assignments */
export type Territory_Assignments_Pk_Columns_Input = {
  id: Scalars["uuid"];
};

/** select columns of table "territory_assignments" */
export enum Territory_Assignments_Select_Column {
  /** column name */
  AssignedBy = "assigned_by",
  /** column name */
  AssignedTo = "assigned_to",
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  Id = "id",
  /** column name */
  IsActive = "is_active",
  /** column name */
  TerritoryId = "territory_id",
  /** column name */
  UpdatedAt = "updated_at",
}

/** select "territory_assignments_aggregate_bool_exp_bool_and_arguments_columns" columns of table "territory_assignments" */
export enum Territory_Assignments_Select_Column_Territory_Assignments_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  IsActive = "is_active",
}

/** select "territory_assignments_aggregate_bool_exp_bool_or_arguments_columns" columns of table "territory_assignments" */
export enum Territory_Assignments_Select_Column_Territory_Assignments_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  IsActive = "is_active",
}

/** input type for updating data in table "territory_assignments" */
export type Territory_Assignments_Set_Input = {
  assigned_by?: InputMaybe<Scalars["uuid"]>;
  assigned_to?: InputMaybe<Scalars["uuid"]>;
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  is_active?: InputMaybe<Scalars["Boolean"]>;
  territory_id?: InputMaybe<Scalars["uuid"]>;
  updated_at?: InputMaybe<Scalars["timestamptz"]>;
};

/** Streaming cursor of the table "territory_assignments" */
export type Territory_Assignments_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Territory_Assignments_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Territory_Assignments_Stream_Cursor_Value_Input = {
  assigned_by?: InputMaybe<Scalars["uuid"]>;
  assigned_to?: InputMaybe<Scalars["uuid"]>;
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  is_active?: InputMaybe<Scalars["Boolean"]>;
  territory_id?: InputMaybe<Scalars["uuid"]>;
  updated_at?: InputMaybe<Scalars["timestamptz"]>;
};

/** update columns of table "territory_assignments" */
export enum Territory_Assignments_Update_Column {
  /** column name */
  AssignedBy = "assigned_by",
  /** column name */
  AssignedTo = "assigned_to",
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  Id = "id",
  /** column name */
  IsActive = "is_active",
  /** column name */
  TerritoryId = "territory_id",
  /** column name */
  UpdatedAt = "updated_at",
}

export type Territory_Assignments_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Territory_Assignments_Set_Input>;
  /** filter the rows which have to be updated */
  where: Territory_Assignments_Bool_Exp;
};

/** columns and relationships of "territory_flags" */
export type Territory_Flags = {
  __typename?: "territory_flags";
  created_at?: Maybe<Scalars["timestamptz"]>;
  /** An object relationship */
  flag: Flags;
  flag_id: Scalars["uuid"];
  id: Scalars["uuid"];
  /** An object relationship */
  territory: Territories;
  territory_id: Scalars["uuid"];
};

/** aggregated selection of "territory_flags" */
export type Territory_Flags_Aggregate = {
  __typename?: "territory_flags_aggregate";
  aggregate?: Maybe<Territory_Flags_Aggregate_Fields>;
  nodes: Array<Territory_Flags>;
};

export type Territory_Flags_Aggregate_Bool_Exp = {
  count?: InputMaybe<Territory_Flags_Aggregate_Bool_Exp_Count>;
};

export type Territory_Flags_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Territory_Flags_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<Territory_Flags_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "territory_flags" */
export type Territory_Flags_Aggregate_Fields = {
  __typename?: "territory_flags_aggregate_fields";
  count: Scalars["Int"];
  max?: Maybe<Territory_Flags_Max_Fields>;
  min?: Maybe<Territory_Flags_Min_Fields>;
};

/** aggregate fields of "territory_flags" */
export type Territory_Flags_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Territory_Flags_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "territory_flags" */
export type Territory_Flags_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Territory_Flags_Max_Order_By>;
  min?: InputMaybe<Territory_Flags_Min_Order_By>;
};

/** input type for inserting array relation for remote table "territory_flags" */
export type Territory_Flags_Arr_Rel_Insert_Input = {
  data: Array<Territory_Flags_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Territory_Flags_On_Conflict>;
};

/** Boolean expression to filter rows from the table "territory_flags". All fields are combined with a logical 'AND'. */
export type Territory_Flags_Bool_Exp = {
  _and?: InputMaybe<Array<Territory_Flags_Bool_Exp>>;
  _not?: InputMaybe<Territory_Flags_Bool_Exp>;
  _or?: InputMaybe<Array<Territory_Flags_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  flag?: InputMaybe<Flags_Bool_Exp>;
  flag_id?: InputMaybe<Uuid_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  territory?: InputMaybe<Territories_Bool_Exp>;
  territory_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "territory_flags" */
export enum Territory_Flags_Constraint {
  /** unique or primary key constraint on columns "id" */
  TerritoryFlagsPkey = "territory_flags_pkey",
}

/** input type for inserting data into table "territory_flags" */
export type Territory_Flags_Insert_Input = {
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  flag?: InputMaybe<Flags_Obj_Rel_Insert_Input>;
  flag_id?: InputMaybe<Scalars["uuid"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  territory?: InputMaybe<Territories_Obj_Rel_Insert_Input>;
  territory_id?: InputMaybe<Scalars["uuid"]>;
};

/** aggregate max on columns */
export type Territory_Flags_Max_Fields = {
  __typename?: "territory_flags_max_fields";
  created_at?: Maybe<Scalars["timestamptz"]>;
  flag_id?: Maybe<Scalars["uuid"]>;
  id?: Maybe<Scalars["uuid"]>;
  territory_id?: Maybe<Scalars["uuid"]>;
};

/** order by max() on columns of table "territory_flags" */
export type Territory_Flags_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  flag_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  territory_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Territory_Flags_Min_Fields = {
  __typename?: "territory_flags_min_fields";
  created_at?: Maybe<Scalars["timestamptz"]>;
  flag_id?: Maybe<Scalars["uuid"]>;
  id?: Maybe<Scalars["uuid"]>;
  territory_id?: Maybe<Scalars["uuid"]>;
};

/** order by min() on columns of table "territory_flags" */
export type Territory_Flags_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  flag_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  territory_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "territory_flags" */
export type Territory_Flags_Mutation_Response = {
  __typename?: "territory_flags_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Territory_Flags>;
};

/** on_conflict condition type for table "territory_flags" */
export type Territory_Flags_On_Conflict = {
  constraint: Territory_Flags_Constraint;
  update_columns?: Array<Territory_Flags_Update_Column>;
  where?: InputMaybe<Territory_Flags_Bool_Exp>;
};

/** Ordering options when selecting data from "territory_flags". */
export type Territory_Flags_Order_By = {
  created_at?: InputMaybe<Order_By>;
  flag?: InputMaybe<Flags_Order_By>;
  flag_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  territory?: InputMaybe<Territories_Order_By>;
  territory_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: territory_flags */
export type Territory_Flags_Pk_Columns_Input = {
  id: Scalars["uuid"];
};

/** select columns of table "territory_flags" */
export enum Territory_Flags_Select_Column {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  FlagId = "flag_id",
  /** column name */
  Id = "id",
  /** column name */
  TerritoryId = "territory_id",
}

/** input type for updating data in table "territory_flags" */
export type Territory_Flags_Set_Input = {
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  flag_id?: InputMaybe<Scalars["uuid"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  territory_id?: InputMaybe<Scalars["uuid"]>;
};

/** Streaming cursor of the table "territory_flags" */
export type Territory_Flags_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Territory_Flags_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Territory_Flags_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  flag_id?: InputMaybe<Scalars["uuid"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  territory_id?: InputMaybe<Scalars["uuid"]>;
};

/** update columns of table "territory_flags" */
export enum Territory_Flags_Update_Column {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  FlagId = "flag_id",
  /** column name */
  Id = "id",
  /** column name */
  TerritoryId = "territory_id",
}

export type Territory_Flags_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Territory_Flags_Set_Input>;
  /** filter the rows which have to be updated */
  where: Territory_Flags_Bool_Exp;
};

/** columns and relationships of "territory_interactions" */
export type Territory_Interactions = {
  __typename?: "territory_interactions";
  created_at?: Maybe<Scalars["timestamptz"]>;
  id: Scalars["uuid"];
  /** An object relationship */
  interaction: Interactions;
  interaction_id: Scalars["uuid"];
  /** An object relationship */
  territory: Territories;
  territory_id: Scalars["uuid"];
};

/** aggregated selection of "territory_interactions" */
export type Territory_Interactions_Aggregate = {
  __typename?: "territory_interactions_aggregate";
  aggregate?: Maybe<Territory_Interactions_Aggregate_Fields>;
  nodes: Array<Territory_Interactions>;
};

export type Territory_Interactions_Aggregate_Bool_Exp = {
  count?: InputMaybe<Territory_Interactions_Aggregate_Bool_Exp_Count>;
};

export type Territory_Interactions_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Territory_Interactions_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<Territory_Interactions_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "territory_interactions" */
export type Territory_Interactions_Aggregate_Fields = {
  __typename?: "territory_interactions_aggregate_fields";
  count: Scalars["Int"];
  max?: Maybe<Territory_Interactions_Max_Fields>;
  min?: Maybe<Territory_Interactions_Min_Fields>;
};

/** aggregate fields of "territory_interactions" */
export type Territory_Interactions_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Territory_Interactions_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "territory_interactions" */
export type Territory_Interactions_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Territory_Interactions_Max_Order_By>;
  min?: InputMaybe<Territory_Interactions_Min_Order_By>;
};

/** input type for inserting array relation for remote table "territory_interactions" */
export type Territory_Interactions_Arr_Rel_Insert_Input = {
  data: Array<Territory_Interactions_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Territory_Interactions_On_Conflict>;
};

/** Boolean expression to filter rows from the table "territory_interactions". All fields are combined with a logical 'AND'. */
export type Territory_Interactions_Bool_Exp = {
  _and?: InputMaybe<Array<Territory_Interactions_Bool_Exp>>;
  _not?: InputMaybe<Territory_Interactions_Bool_Exp>;
  _or?: InputMaybe<Array<Territory_Interactions_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  interaction?: InputMaybe<Interactions_Bool_Exp>;
  interaction_id?: InputMaybe<Uuid_Comparison_Exp>;
  territory?: InputMaybe<Territories_Bool_Exp>;
  territory_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "territory_interactions" */
export enum Territory_Interactions_Constraint {
  /** unique or primary key constraint on columns "id" */
  TerritoryInteractionsPkey = "territory_interactions_pkey",
}

/** input type for inserting data into table "territory_interactions" */
export type Territory_Interactions_Insert_Input = {
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  interaction?: InputMaybe<Interactions_Obj_Rel_Insert_Input>;
  interaction_id?: InputMaybe<Scalars["uuid"]>;
  territory?: InputMaybe<Territories_Obj_Rel_Insert_Input>;
  territory_id?: InputMaybe<Scalars["uuid"]>;
};

/** aggregate max on columns */
export type Territory_Interactions_Max_Fields = {
  __typename?: "territory_interactions_max_fields";
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["uuid"]>;
  interaction_id?: Maybe<Scalars["uuid"]>;
  territory_id?: Maybe<Scalars["uuid"]>;
};

/** order by max() on columns of table "territory_interactions" */
export type Territory_Interactions_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  interaction_id?: InputMaybe<Order_By>;
  territory_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Territory_Interactions_Min_Fields = {
  __typename?: "territory_interactions_min_fields";
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["uuid"]>;
  interaction_id?: Maybe<Scalars["uuid"]>;
  territory_id?: Maybe<Scalars["uuid"]>;
};

/** order by min() on columns of table "territory_interactions" */
export type Territory_Interactions_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  interaction_id?: InputMaybe<Order_By>;
  territory_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "territory_interactions" */
export type Territory_Interactions_Mutation_Response = {
  __typename?: "territory_interactions_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Territory_Interactions>;
};

/** on_conflict condition type for table "territory_interactions" */
export type Territory_Interactions_On_Conflict = {
  constraint: Territory_Interactions_Constraint;
  update_columns?: Array<Territory_Interactions_Update_Column>;
  where?: InputMaybe<Territory_Interactions_Bool_Exp>;
};

/** Ordering options when selecting data from "territory_interactions". */
export type Territory_Interactions_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  interaction?: InputMaybe<Interactions_Order_By>;
  interaction_id?: InputMaybe<Order_By>;
  territory?: InputMaybe<Territories_Order_By>;
  territory_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: territory_interactions */
export type Territory_Interactions_Pk_Columns_Input = {
  id: Scalars["uuid"];
};

/** select columns of table "territory_interactions" */
export enum Territory_Interactions_Select_Column {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  Id = "id",
  /** column name */
  InteractionId = "interaction_id",
  /** column name */
  TerritoryId = "territory_id",
}

/** input type for updating data in table "territory_interactions" */
export type Territory_Interactions_Set_Input = {
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  interaction_id?: InputMaybe<Scalars["uuid"]>;
  territory_id?: InputMaybe<Scalars["uuid"]>;
};

/** Streaming cursor of the table "territory_interactions" */
export type Territory_Interactions_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Territory_Interactions_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Territory_Interactions_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  interaction_id?: InputMaybe<Scalars["uuid"]>;
  territory_id?: InputMaybe<Scalars["uuid"]>;
};

/** update columns of table "territory_interactions" */
export enum Territory_Interactions_Update_Column {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  Id = "id",
  /** column name */
  InteractionId = "interaction_id",
  /** column name */
  TerritoryId = "territory_id",
}

export type Territory_Interactions_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Territory_Interactions_Set_Input>;
  /** filter the rows which have to be updated */
  where: Territory_Interactions_Bool_Exp;
};

/** columns and relationships of "territory_members" */
export type Territory_Members = {
  __typename?: "territory_members";
  created_at: Scalars["timestamptz"];
  id: Scalars["uuid"];
  /** An object relationship */
  party: Parties;
  party_id: Scalars["uuid"];
  /** An object relationship */
  territory: Territories;
  territory_id: Scalars["uuid"];
  updated_at: Scalars["timestamptz"];
};

/** aggregated selection of "territory_members" */
export type Territory_Members_Aggregate = {
  __typename?: "territory_members_aggregate";
  aggregate?: Maybe<Territory_Members_Aggregate_Fields>;
  nodes: Array<Territory_Members>;
};

export type Territory_Members_Aggregate_Bool_Exp = {
  count?: InputMaybe<Territory_Members_Aggregate_Bool_Exp_Count>;
};

export type Territory_Members_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Territory_Members_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<Territory_Members_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "territory_members" */
export type Territory_Members_Aggregate_Fields = {
  __typename?: "territory_members_aggregate_fields";
  count: Scalars["Int"];
  max?: Maybe<Territory_Members_Max_Fields>;
  min?: Maybe<Territory_Members_Min_Fields>;
};

/** aggregate fields of "territory_members" */
export type Territory_Members_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Territory_Members_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "territory_members" */
export type Territory_Members_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Territory_Members_Max_Order_By>;
  min?: InputMaybe<Territory_Members_Min_Order_By>;
};

/** input type for inserting array relation for remote table "territory_members" */
export type Territory_Members_Arr_Rel_Insert_Input = {
  data: Array<Territory_Members_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Territory_Members_On_Conflict>;
};

/** Boolean expression to filter rows from the table "territory_members". All fields are combined with a logical 'AND'. */
export type Territory_Members_Bool_Exp = {
  _and?: InputMaybe<Array<Territory_Members_Bool_Exp>>;
  _not?: InputMaybe<Territory_Members_Bool_Exp>;
  _or?: InputMaybe<Array<Territory_Members_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  party?: InputMaybe<Parties_Bool_Exp>;
  party_id?: InputMaybe<Uuid_Comparison_Exp>;
  territory?: InputMaybe<Territories_Bool_Exp>;
  territory_id?: InputMaybe<Uuid_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "territory_members" */
export enum Territory_Members_Constraint {
  /** unique or primary key constraint on columns "id" */
  TerritoryMembersPkey = "territory_members_pkey",
}

/** input type for inserting data into table "territory_members" */
export type Territory_Members_Insert_Input = {
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  party?: InputMaybe<Parties_Obj_Rel_Insert_Input>;
  party_id?: InputMaybe<Scalars["uuid"]>;
  territory?: InputMaybe<Territories_Obj_Rel_Insert_Input>;
  territory_id?: InputMaybe<Scalars["uuid"]>;
  updated_at?: InputMaybe<Scalars["timestamptz"]>;
};

/** aggregate max on columns */
export type Territory_Members_Max_Fields = {
  __typename?: "territory_members_max_fields";
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["uuid"]>;
  party_id?: Maybe<Scalars["uuid"]>;
  territory_id?: Maybe<Scalars["uuid"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
};

/** order by max() on columns of table "territory_members" */
export type Territory_Members_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  party_id?: InputMaybe<Order_By>;
  territory_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Territory_Members_Min_Fields = {
  __typename?: "territory_members_min_fields";
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["uuid"]>;
  party_id?: Maybe<Scalars["uuid"]>;
  territory_id?: Maybe<Scalars["uuid"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
};

/** order by min() on columns of table "territory_members" */
export type Territory_Members_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  party_id?: InputMaybe<Order_By>;
  territory_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "territory_members" */
export type Territory_Members_Mutation_Response = {
  __typename?: "territory_members_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Territory_Members>;
};

/** on_conflict condition type for table "territory_members" */
export type Territory_Members_On_Conflict = {
  constraint: Territory_Members_Constraint;
  update_columns?: Array<Territory_Members_Update_Column>;
  where?: InputMaybe<Territory_Members_Bool_Exp>;
};

/** Ordering options when selecting data from "territory_members". */
export type Territory_Members_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  party?: InputMaybe<Parties_Order_By>;
  party_id?: InputMaybe<Order_By>;
  territory?: InputMaybe<Territories_Order_By>;
  territory_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: territory_members */
export type Territory_Members_Pk_Columns_Input = {
  id: Scalars["uuid"];
};

/** select columns of table "territory_members" */
export enum Territory_Members_Select_Column {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  Id = "id",
  /** column name */
  PartyId = "party_id",
  /** column name */
  TerritoryId = "territory_id",
  /** column name */
  UpdatedAt = "updated_at",
}

/** input type for updating data in table "territory_members" */
export type Territory_Members_Set_Input = {
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  party_id?: InputMaybe<Scalars["uuid"]>;
  territory_id?: InputMaybe<Scalars["uuid"]>;
  updated_at?: InputMaybe<Scalars["timestamptz"]>;
};

/** Streaming cursor of the table "territory_members" */
export type Territory_Members_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Territory_Members_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Territory_Members_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  party_id?: InputMaybe<Scalars["uuid"]>;
  territory_id?: InputMaybe<Scalars["uuid"]>;
  updated_at?: InputMaybe<Scalars["timestamptz"]>;
};

/** update columns of table "territory_members" */
export enum Territory_Members_Update_Column {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  Id = "id",
  /** column name */
  PartyId = "party_id",
  /** column name */
  TerritoryId = "territory_id",
  /** column name */
  UpdatedAt = "updated_at",
}

export type Territory_Members_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Territory_Members_Set_Input>;
  /** filter the rows which have to be updated */
  where: Territory_Members_Bool_Exp;
};

/** columns and relationships of "territory_rules" */
export type Territory_Rules = {
  __typename?: "territory_rules";
  created_at: Scalars["timestamptz"];
  id: Scalars["uuid"];
  is_active: Scalars["Boolean"];
  /** An object relationship */
  rule: Rules;
  rule_id: Scalars["uuid"];
  /** An object relationship */
  territory: Territories;
  territory_id: Scalars["uuid"];
  updated_at: Scalars["timestamptz"];
};

/** aggregated selection of "territory_rules" */
export type Territory_Rules_Aggregate = {
  __typename?: "territory_rules_aggregate";
  aggregate?: Maybe<Territory_Rules_Aggregate_Fields>;
  nodes: Array<Territory_Rules>;
};

export type Territory_Rules_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Territory_Rules_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Territory_Rules_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Territory_Rules_Aggregate_Bool_Exp_Count>;
};

export type Territory_Rules_Aggregate_Bool_Exp_Bool_And = {
  arguments: Territory_Rules_Select_Column_Territory_Rules_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<Territory_Rules_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Territory_Rules_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Territory_Rules_Select_Column_Territory_Rules_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<Territory_Rules_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Territory_Rules_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Territory_Rules_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<Territory_Rules_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "territory_rules" */
export type Territory_Rules_Aggregate_Fields = {
  __typename?: "territory_rules_aggregate_fields";
  count: Scalars["Int"];
  max?: Maybe<Territory_Rules_Max_Fields>;
  min?: Maybe<Territory_Rules_Min_Fields>;
};

/** aggregate fields of "territory_rules" */
export type Territory_Rules_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Territory_Rules_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "territory_rules" */
export type Territory_Rules_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Territory_Rules_Max_Order_By>;
  min?: InputMaybe<Territory_Rules_Min_Order_By>;
};

/** input type for inserting array relation for remote table "territory_rules" */
export type Territory_Rules_Arr_Rel_Insert_Input = {
  data: Array<Territory_Rules_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Territory_Rules_On_Conflict>;
};

/** Boolean expression to filter rows from the table "territory_rules". All fields are combined with a logical 'AND'. */
export type Territory_Rules_Bool_Exp = {
  _and?: InputMaybe<Array<Territory_Rules_Bool_Exp>>;
  _not?: InputMaybe<Territory_Rules_Bool_Exp>;
  _or?: InputMaybe<Array<Territory_Rules_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  is_active?: InputMaybe<Boolean_Comparison_Exp>;
  rule?: InputMaybe<Rules_Bool_Exp>;
  rule_id?: InputMaybe<Uuid_Comparison_Exp>;
  territory?: InputMaybe<Territories_Bool_Exp>;
  territory_id?: InputMaybe<Uuid_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "territory_rules" */
export enum Territory_Rules_Constraint {
  /** unique or primary key constraint on columns "id" */
  TerritoryRulesPkey = "territory_rules_pkey",
}

/** input type for inserting data into table "territory_rules" */
export type Territory_Rules_Insert_Input = {
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  is_active?: InputMaybe<Scalars["Boolean"]>;
  rule?: InputMaybe<Rules_Obj_Rel_Insert_Input>;
  rule_id?: InputMaybe<Scalars["uuid"]>;
  territory?: InputMaybe<Territories_Obj_Rel_Insert_Input>;
  territory_id?: InputMaybe<Scalars["uuid"]>;
  updated_at?: InputMaybe<Scalars["timestamptz"]>;
};

/** aggregate max on columns */
export type Territory_Rules_Max_Fields = {
  __typename?: "territory_rules_max_fields";
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["uuid"]>;
  rule_id?: Maybe<Scalars["uuid"]>;
  territory_id?: Maybe<Scalars["uuid"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
};

/** order by max() on columns of table "territory_rules" */
export type Territory_Rules_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  rule_id?: InputMaybe<Order_By>;
  territory_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Territory_Rules_Min_Fields = {
  __typename?: "territory_rules_min_fields";
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["uuid"]>;
  rule_id?: Maybe<Scalars["uuid"]>;
  territory_id?: Maybe<Scalars["uuid"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
};

/** order by min() on columns of table "territory_rules" */
export type Territory_Rules_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  rule_id?: InputMaybe<Order_By>;
  territory_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "territory_rules" */
export type Territory_Rules_Mutation_Response = {
  __typename?: "territory_rules_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Territory_Rules>;
};

/** on_conflict condition type for table "territory_rules" */
export type Territory_Rules_On_Conflict = {
  constraint: Territory_Rules_Constraint;
  update_columns?: Array<Territory_Rules_Update_Column>;
  where?: InputMaybe<Territory_Rules_Bool_Exp>;
};

/** Ordering options when selecting data from "territory_rules". */
export type Territory_Rules_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  is_active?: InputMaybe<Order_By>;
  rule?: InputMaybe<Rules_Order_By>;
  rule_id?: InputMaybe<Order_By>;
  territory?: InputMaybe<Territories_Order_By>;
  territory_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: territory_rules */
export type Territory_Rules_Pk_Columns_Input = {
  id: Scalars["uuid"];
};

/** select columns of table "territory_rules" */
export enum Territory_Rules_Select_Column {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  Id = "id",
  /** column name */
  IsActive = "is_active",
  /** column name */
  RuleId = "rule_id",
  /** column name */
  TerritoryId = "territory_id",
  /** column name */
  UpdatedAt = "updated_at",
}

/** select "territory_rules_aggregate_bool_exp_bool_and_arguments_columns" columns of table "territory_rules" */
export enum Territory_Rules_Select_Column_Territory_Rules_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  IsActive = "is_active",
}

/** select "territory_rules_aggregate_bool_exp_bool_or_arguments_columns" columns of table "territory_rules" */
export enum Territory_Rules_Select_Column_Territory_Rules_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  IsActive = "is_active",
}

/** input type for updating data in table "territory_rules" */
export type Territory_Rules_Set_Input = {
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  is_active?: InputMaybe<Scalars["Boolean"]>;
  rule_id?: InputMaybe<Scalars["uuid"]>;
  territory_id?: InputMaybe<Scalars["uuid"]>;
  updated_at?: InputMaybe<Scalars["timestamptz"]>;
};

/** Streaming cursor of the table "territory_rules" */
export type Territory_Rules_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Territory_Rules_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Territory_Rules_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  is_active?: InputMaybe<Scalars["Boolean"]>;
  rule_id?: InputMaybe<Scalars["uuid"]>;
  territory_id?: InputMaybe<Scalars["uuid"]>;
  updated_at?: InputMaybe<Scalars["timestamptz"]>;
};

/** update columns of table "territory_rules" */
export enum Territory_Rules_Update_Column {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  Id = "id",
  /** column name */
  IsActive = "is_active",
  /** column name */
  RuleId = "rule_id",
  /** column name */
  TerritoryId = "territory_id",
  /** column name */
  UpdatedAt = "updated_at",
}

export type Territory_Rules_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Territory_Rules_Set_Input>;
  /** filter the rows which have to be updated */
  where: Territory_Rules_Bool_Exp;
};

/** columns and relationships of "territory_statuses" */
export type Territory_Statuses = {
  __typename?: "territory_statuses";
  id: Scalars["uuid"];
  /** An object relationship */
  status: Statuses;
  status_id: Scalars["uuid"];
  /** An object relationship */
  territory: Territories;
  territory_id: Scalars["uuid"];
};

/** aggregated selection of "territory_statuses" */
export type Territory_Statuses_Aggregate = {
  __typename?: "territory_statuses_aggregate";
  aggregate?: Maybe<Territory_Statuses_Aggregate_Fields>;
  nodes: Array<Territory_Statuses>;
};

export type Territory_Statuses_Aggregate_Bool_Exp = {
  count?: InputMaybe<Territory_Statuses_Aggregate_Bool_Exp_Count>;
};

export type Territory_Statuses_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Territory_Statuses_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<Territory_Statuses_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "territory_statuses" */
export type Territory_Statuses_Aggregate_Fields = {
  __typename?: "territory_statuses_aggregate_fields";
  count: Scalars["Int"];
  max?: Maybe<Territory_Statuses_Max_Fields>;
  min?: Maybe<Territory_Statuses_Min_Fields>;
};

/** aggregate fields of "territory_statuses" */
export type Territory_Statuses_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Territory_Statuses_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "territory_statuses" */
export type Territory_Statuses_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Territory_Statuses_Max_Order_By>;
  min?: InputMaybe<Territory_Statuses_Min_Order_By>;
};

/** input type for inserting array relation for remote table "territory_statuses" */
export type Territory_Statuses_Arr_Rel_Insert_Input = {
  data: Array<Territory_Statuses_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Territory_Statuses_On_Conflict>;
};

/** Boolean expression to filter rows from the table "territory_statuses". All fields are combined with a logical 'AND'. */
export type Territory_Statuses_Bool_Exp = {
  _and?: InputMaybe<Array<Territory_Statuses_Bool_Exp>>;
  _not?: InputMaybe<Territory_Statuses_Bool_Exp>;
  _or?: InputMaybe<Array<Territory_Statuses_Bool_Exp>>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  status?: InputMaybe<Statuses_Bool_Exp>;
  status_id?: InputMaybe<Uuid_Comparison_Exp>;
  territory?: InputMaybe<Territories_Bool_Exp>;
  territory_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "territory_statuses" */
export enum Territory_Statuses_Constraint {
  /** unique or primary key constraint on columns "id" */
  TerritoryStatusesPkey = "territory_statuses_pkey",
}

/** input type for inserting data into table "territory_statuses" */
export type Territory_Statuses_Insert_Input = {
  id?: InputMaybe<Scalars["uuid"]>;
  status?: InputMaybe<Statuses_Obj_Rel_Insert_Input>;
  status_id?: InputMaybe<Scalars["uuid"]>;
  territory?: InputMaybe<Territories_Obj_Rel_Insert_Input>;
  territory_id?: InputMaybe<Scalars["uuid"]>;
};

/** aggregate max on columns */
export type Territory_Statuses_Max_Fields = {
  __typename?: "territory_statuses_max_fields";
  id?: Maybe<Scalars["uuid"]>;
  status_id?: Maybe<Scalars["uuid"]>;
  territory_id?: Maybe<Scalars["uuid"]>;
};

/** order by max() on columns of table "territory_statuses" */
export type Territory_Statuses_Max_Order_By = {
  id?: InputMaybe<Order_By>;
  status_id?: InputMaybe<Order_By>;
  territory_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Territory_Statuses_Min_Fields = {
  __typename?: "territory_statuses_min_fields";
  id?: Maybe<Scalars["uuid"]>;
  status_id?: Maybe<Scalars["uuid"]>;
  territory_id?: Maybe<Scalars["uuid"]>;
};

/** order by min() on columns of table "territory_statuses" */
export type Territory_Statuses_Min_Order_By = {
  id?: InputMaybe<Order_By>;
  status_id?: InputMaybe<Order_By>;
  territory_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "territory_statuses" */
export type Territory_Statuses_Mutation_Response = {
  __typename?: "territory_statuses_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Territory_Statuses>;
};

/** on_conflict condition type for table "territory_statuses" */
export type Territory_Statuses_On_Conflict = {
  constraint: Territory_Statuses_Constraint;
  update_columns?: Array<Territory_Statuses_Update_Column>;
  where?: InputMaybe<Territory_Statuses_Bool_Exp>;
};

/** Ordering options when selecting data from "territory_statuses". */
export type Territory_Statuses_Order_By = {
  id?: InputMaybe<Order_By>;
  status?: InputMaybe<Statuses_Order_By>;
  status_id?: InputMaybe<Order_By>;
  territory?: InputMaybe<Territories_Order_By>;
  territory_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: territory_statuses */
export type Territory_Statuses_Pk_Columns_Input = {
  id: Scalars["uuid"];
};

/** select columns of table "territory_statuses" */
export enum Territory_Statuses_Select_Column {
  /** column name */
  Id = "id",
  /** column name */
  StatusId = "status_id",
  /** column name */
  TerritoryId = "territory_id",
}

/** input type for updating data in table "territory_statuses" */
export type Territory_Statuses_Set_Input = {
  id?: InputMaybe<Scalars["uuid"]>;
  status_id?: InputMaybe<Scalars["uuid"]>;
  territory_id?: InputMaybe<Scalars["uuid"]>;
};

/** Streaming cursor of the table "territory_statuses" */
export type Territory_Statuses_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Territory_Statuses_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Territory_Statuses_Stream_Cursor_Value_Input = {
  id?: InputMaybe<Scalars["uuid"]>;
  status_id?: InputMaybe<Scalars["uuid"]>;
  territory_id?: InputMaybe<Scalars["uuid"]>;
};

/** update columns of table "territory_statuses" */
export enum Territory_Statuses_Update_Column {
  /** column name */
  Id = "id",
  /** column name */
  StatusId = "status_id",
  /** column name */
  TerritoryId = "territory_id",
}

export type Territory_Statuses_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Territory_Statuses_Set_Input>;
  /** filter the rows which have to be updated */
  where: Territory_Statuses_Bool_Exp;
};

/** Boolean expression to compare columns of type "timestamp". All fields are combined with logical 'AND'. */
export type Timestamp_Comparison_Exp = {
  _eq?: InputMaybe<Scalars["timestamp"]>;
  _gt?: InputMaybe<Scalars["timestamp"]>;
  _gte?: InputMaybe<Scalars["timestamp"]>;
  _in?: InputMaybe<Array<Scalars["timestamp"]>>;
  _is_null?: InputMaybe<Scalars["Boolean"]>;
  _lt?: InputMaybe<Scalars["timestamp"]>;
  _lte?: InputMaybe<Scalars["timestamp"]>;
  _neq?: InputMaybe<Scalars["timestamp"]>;
  _nin?: InputMaybe<Array<Scalars["timestamp"]>>;
};

/** Boolean expression to compare columns of type "timestamptz". All fields are combined with logical 'AND'. */
export type Timestamptz_Comparison_Exp = {
  _eq?: InputMaybe<Scalars["timestamptz"]>;
  _gt?: InputMaybe<Scalars["timestamptz"]>;
  _gte?: InputMaybe<Scalars["timestamptz"]>;
  _in?: InputMaybe<Array<Scalars["timestamptz"]>>;
  _is_null?: InputMaybe<Scalars["Boolean"]>;
  _lt?: InputMaybe<Scalars["timestamptz"]>;
  _lte?: InputMaybe<Scalars["timestamptz"]>;
  _neq?: InputMaybe<Scalars["timestamptz"]>;
  _nin?: InputMaybe<Array<Scalars["timestamptz"]>>;
};

export type Transaction_Bool_Exp = {
  created_at?: InputMaybe<Time_Comparison_Exp>;
  effective_date?: InputMaybe<Time_Comparison_Exp>;
  external_reference?: InputMaybe<String_Comparison_Exp>;
  ledger_id?: InputMaybe<Uuid_Comparison_Exp>;
  payment_id?: InputMaybe<Uuid_Comparison_Exp>;
  posted_at?: InputMaybe<Time_Comparison_Exp>;
  status_id?: InputMaybe<Uuid_Comparison_Exp>;
  type_id?: InputMaybe<Uuid_Comparison_Exp>;
  updated_at?: InputMaybe<Time_Comparison_Exp>;
};

/** columns and relationships of "types" */
export type Types = {
  __typename?: "types";
  /** An array relationship */
  campaigns: Array<Campaigns>;
  /** An aggregate relationship */
  campaigns_aggregate: Campaigns_Aggregate;
  /** An array relationship */
  cases: Array<Cases>;
  /** An aggregate relationship */
  cases_aggregate: Cases_Aggregate;
  /** An array relationship */
  contact_relationships: Array<Contact_Relationships>;
  /** An aggregate relationship */
  contact_relationships_aggregate: Contact_Relationships_Aggregate;
  /** An array relationship */
  contacts: Array<Contacts>;
  /** An aggregate relationship */
  contacts_aggregate: Contacts_Aggregate;
  created_at: Scalars["timestamptz"];
  description?: Maybe<Scalars["String"]>;
  /** An array relationship */
  documents: Array<Documents>;
  /** An aggregate relationship */
  documents_aggregate: Documents_Aggregate;
  /** An array relationship */
  form_fields: Array<Form_Fields>;
  /** An aggregate relationship */
  form_fields_aggregate: Form_Fields_Aggregate;
  /** An array relationship */
  forms: Array<Forms>;
  /** An aggregate relationship */
  forms_aggregate: Forms_Aggregate;
  id: Scalars["uuid"];
  /** An array relationship */
  interactions: Array<Interactions>;
  /** An aggregate relationship */
  interactions_aggregate: Interactions_Aggregate;
  /** An array relationship */
  invoices: Array<Invoices>;
  /** An aggregate relationship */
  invoices_aggregate: Invoices_Aggregate;
  /** An array relationship */
  messages: Array<Messages>;
  /** An aggregate relationship */
  messages_aggregate: Messages_Aggregate;
  name: Scalars["String"];
  /** An array relationship */
  parties: Array<Parties>;
  /** An aggregate relationship */
  parties_aggregate: Parties_Aggregate;
  /** An array relationship */
  party_relationships: Array<Party_Relationships>;
  /** An aggregate relationship */
  party_relationships_aggregate: Party_Relationships_Aggregate;
  /** An array relationship */
  performance_indicators: Array<Performance_Indicators>;
  /** An aggregate relationship */
  performance_indicators_aggregate: Performance_Indicators_Aggregate;
  /** An array relationship */
  performance_targets: Array<Performance_Targets>;
  /** An aggregate relationship */
  performance_targets_aggregate: Performance_Targets_Aggregate;
  /** An array relationship */
  product_items: Array<Product_Items>;
  /** An aggregate relationship */
  product_items_aggregate: Product_Items_Aggregate;
  /** An array relationship */
  products: Array<Products>;
  /** An aggregate relationship */
  products_aggregate: Products_Aggregate;
  /** An array relationship */
  subscriptions: Array<Subscriptions>;
  /** An aggregate relationship */
  subscriptions_aggregate: Subscriptions_Aggregate;
  /** An array relationship */
  tariffs: Array<Tariffs>;
  /** An aggregate relationship */
  tariffs_aggregate: Tariffs_Aggregate;
  /** An array relationship */
  tasks: Array<Tasks>;
  /** An aggregate relationship */
  tasks_aggregate: Tasks_Aggregate;
  /** An array relationship */
  territories: Array<Territories>;
  /** An aggregate relationship */
  territories_aggregate: Territories_Aggregate;
  updated_at: Scalars["timestamptz"];
  value: Scalars["String"];
  /** An array relationship */
  workflow_templates: Array<Workflow_Templates>;
  /** An aggregate relationship */
  workflow_templates_aggregate: Workflow_Templates_Aggregate;
};

/** columns and relationships of "types" */
export type TypesCampaignsArgs = {
  distinct_on?: InputMaybe<Array<Campaigns_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Campaigns_Order_By>>;
  where?: InputMaybe<Campaigns_Bool_Exp>;
};

/** columns and relationships of "types" */
export type TypesCampaigns_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Campaigns_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Campaigns_Order_By>>;
  where?: InputMaybe<Campaigns_Bool_Exp>;
};

/** columns and relationships of "types" */
export type TypesCasesArgs = {
  distinct_on?: InputMaybe<Array<Cases_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Cases_Order_By>>;
  where?: InputMaybe<Cases_Bool_Exp>;
};

/** columns and relationships of "types" */
export type TypesCases_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Cases_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Cases_Order_By>>;
  where?: InputMaybe<Cases_Bool_Exp>;
};

/** columns and relationships of "types" */
export type TypesContact_RelationshipsArgs = {
  distinct_on?: InputMaybe<Array<Contact_Relationships_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Contact_Relationships_Order_By>>;
  where?: InputMaybe<Contact_Relationships_Bool_Exp>;
};

/** columns and relationships of "types" */
export type TypesContact_Relationships_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Contact_Relationships_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Contact_Relationships_Order_By>>;
  where?: InputMaybe<Contact_Relationships_Bool_Exp>;
};

/** columns and relationships of "types" */
export type TypesContactsArgs = {
  distinct_on?: InputMaybe<Array<Contacts_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Contacts_Order_By>>;
  where?: InputMaybe<Contacts_Bool_Exp>;
};

/** columns and relationships of "types" */
export type TypesContacts_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Contacts_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Contacts_Order_By>>;
  where?: InputMaybe<Contacts_Bool_Exp>;
};

/** columns and relationships of "types" */
export type TypesDocumentsArgs = {
  distinct_on?: InputMaybe<Array<Documents_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Documents_Order_By>>;
  where?: InputMaybe<Documents_Bool_Exp>;
};

/** columns and relationships of "types" */
export type TypesDocuments_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Documents_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Documents_Order_By>>;
  where?: InputMaybe<Documents_Bool_Exp>;
};

/** columns and relationships of "types" */
export type TypesForm_FieldsArgs = {
  distinct_on?: InputMaybe<Array<Form_Fields_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Form_Fields_Order_By>>;
  where?: InputMaybe<Form_Fields_Bool_Exp>;
};

/** columns and relationships of "types" */
export type TypesForm_Fields_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Form_Fields_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Form_Fields_Order_By>>;
  where?: InputMaybe<Form_Fields_Bool_Exp>;
};

/** columns and relationships of "types" */
export type TypesFormsArgs = {
  distinct_on?: InputMaybe<Array<Forms_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Forms_Order_By>>;
  where?: InputMaybe<Forms_Bool_Exp>;
};

/** columns and relationships of "types" */
export type TypesForms_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Forms_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Forms_Order_By>>;
  where?: InputMaybe<Forms_Bool_Exp>;
};

/** columns and relationships of "types" */
export type TypesInteractionsArgs = {
  distinct_on?: InputMaybe<Array<Interactions_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Interactions_Order_By>>;
  where?: InputMaybe<Interactions_Bool_Exp>;
};

/** columns and relationships of "types" */
export type TypesInteractions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Interactions_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Interactions_Order_By>>;
  where?: InputMaybe<Interactions_Bool_Exp>;
};

/** columns and relationships of "types" */
export type TypesInvoicesArgs = {
  distinct_on?: InputMaybe<Array<Invoices_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Invoices_Order_By>>;
  where?: InputMaybe<Invoices_Bool_Exp>;
};

/** columns and relationships of "types" */
export type TypesInvoices_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Invoices_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Invoices_Order_By>>;
  where?: InputMaybe<Invoices_Bool_Exp>;
};

/** columns and relationships of "types" */
export type TypesMessagesArgs = {
  distinct_on?: InputMaybe<Array<Messages_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Messages_Order_By>>;
  where?: InputMaybe<Messages_Bool_Exp>;
};

/** columns and relationships of "types" */
export type TypesMessages_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Messages_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Messages_Order_By>>;
  where?: InputMaybe<Messages_Bool_Exp>;
};

/** columns and relationships of "types" */
export type TypesPartiesArgs = {
  distinct_on?: InputMaybe<Array<Parties_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Parties_Order_By>>;
  where?: InputMaybe<Parties_Bool_Exp>;
};

/** columns and relationships of "types" */
export type TypesParties_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Parties_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Parties_Order_By>>;
  where?: InputMaybe<Parties_Bool_Exp>;
};

/** columns and relationships of "types" */
export type TypesParty_RelationshipsArgs = {
  distinct_on?: InputMaybe<Array<Party_Relationships_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Party_Relationships_Order_By>>;
  where?: InputMaybe<Party_Relationships_Bool_Exp>;
};

/** columns and relationships of "types" */
export type TypesParty_Relationships_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Party_Relationships_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Party_Relationships_Order_By>>;
  where?: InputMaybe<Party_Relationships_Bool_Exp>;
};

/** columns and relationships of "types" */
export type TypesPerformance_IndicatorsArgs = {
  distinct_on?: InputMaybe<Array<Performance_Indicators_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Performance_Indicators_Order_By>>;
  where?: InputMaybe<Performance_Indicators_Bool_Exp>;
};

/** columns and relationships of "types" */
export type TypesPerformance_Indicators_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Performance_Indicators_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Performance_Indicators_Order_By>>;
  where?: InputMaybe<Performance_Indicators_Bool_Exp>;
};

/** columns and relationships of "types" */
export type TypesPerformance_TargetsArgs = {
  distinct_on?: InputMaybe<Array<Performance_Targets_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Performance_Targets_Order_By>>;
  where?: InputMaybe<Performance_Targets_Bool_Exp>;
};

/** columns and relationships of "types" */
export type TypesPerformance_Targets_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Performance_Targets_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Performance_Targets_Order_By>>;
  where?: InputMaybe<Performance_Targets_Bool_Exp>;
};

/** columns and relationships of "types" */
export type TypesProduct_ItemsArgs = {
  distinct_on?: InputMaybe<Array<Product_Items_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Product_Items_Order_By>>;
  where?: InputMaybe<Product_Items_Bool_Exp>;
};

/** columns and relationships of "types" */
export type TypesProduct_Items_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Product_Items_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Product_Items_Order_By>>;
  where?: InputMaybe<Product_Items_Bool_Exp>;
};

/** columns and relationships of "types" */
export type TypesProductsArgs = {
  distinct_on?: InputMaybe<Array<Products_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Products_Order_By>>;
  where?: InputMaybe<Products_Bool_Exp>;
};

/** columns and relationships of "types" */
export type TypesProducts_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Products_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Products_Order_By>>;
  where?: InputMaybe<Products_Bool_Exp>;
};

/** columns and relationships of "types" */
export type TypesSubscriptionsArgs = {
  distinct_on?: InputMaybe<Array<Subscriptions_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Subscriptions_Order_By>>;
  where?: InputMaybe<Subscriptions_Bool_Exp>;
};

/** columns and relationships of "types" */
export type TypesSubscriptions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Subscriptions_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Subscriptions_Order_By>>;
  where?: InputMaybe<Subscriptions_Bool_Exp>;
};

/** columns and relationships of "types" */
export type TypesTariffsArgs = {
  distinct_on?: InputMaybe<Array<Tariffs_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Tariffs_Order_By>>;
  where?: InputMaybe<Tariffs_Bool_Exp>;
};

/** columns and relationships of "types" */
export type TypesTariffs_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tariffs_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Tariffs_Order_By>>;
  where?: InputMaybe<Tariffs_Bool_Exp>;
};

/** columns and relationships of "types" */
export type TypesTasksArgs = {
  distinct_on?: InputMaybe<Array<Tasks_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Tasks_Order_By>>;
  where?: InputMaybe<Tasks_Bool_Exp>;
};

/** columns and relationships of "types" */
export type TypesTasks_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tasks_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Tasks_Order_By>>;
  where?: InputMaybe<Tasks_Bool_Exp>;
};

/** columns and relationships of "types" */
export type TypesTerritoriesArgs = {
  distinct_on?: InputMaybe<Array<Territories_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Territories_Order_By>>;
  where?: InputMaybe<Territories_Bool_Exp>;
};

/** columns and relationships of "types" */
export type TypesTerritories_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Territories_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Territories_Order_By>>;
  where?: InputMaybe<Territories_Bool_Exp>;
};

/** columns and relationships of "types" */
export type TypesWorkflow_TemplatesArgs = {
  distinct_on?: InputMaybe<Array<Workflow_Templates_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Workflow_Templates_Order_By>>;
  where?: InputMaybe<Workflow_Templates_Bool_Exp>;
};

/** columns and relationships of "types" */
export type TypesWorkflow_Templates_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Workflow_Templates_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Workflow_Templates_Order_By>>;
  where?: InputMaybe<Workflow_Templates_Bool_Exp>;
};

/** aggregated selection of "types" */
export type Types_Aggregate = {
  __typename?: "types_aggregate";
  aggregate?: Maybe<Types_Aggregate_Fields>;
  nodes: Array<Types>;
};

/** aggregate fields of "types" */
export type Types_Aggregate_Fields = {
  __typename?: "types_aggregate_fields";
  count: Scalars["Int"];
  max?: Maybe<Types_Max_Fields>;
  min?: Maybe<Types_Min_Fields>;
};

/** aggregate fields of "types" */
export type Types_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Types_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** Boolean expression to filter rows from the table "types". All fields are combined with a logical 'AND'. */
export type Types_Bool_Exp = {
  _and?: InputMaybe<Array<Types_Bool_Exp>>;
  _not?: InputMaybe<Types_Bool_Exp>;
  _or?: InputMaybe<Array<Types_Bool_Exp>>;
  campaigns?: InputMaybe<Campaigns_Bool_Exp>;
  campaigns_aggregate?: InputMaybe<Campaigns_Aggregate_Bool_Exp>;
  cases?: InputMaybe<Cases_Bool_Exp>;
  cases_aggregate?: InputMaybe<Cases_Aggregate_Bool_Exp>;
  contact_relationships?: InputMaybe<Contact_Relationships_Bool_Exp>;
  contact_relationships_aggregate?: InputMaybe<Contact_Relationships_Aggregate_Bool_Exp>;
  contacts?: InputMaybe<Contacts_Bool_Exp>;
  contacts_aggregate?: InputMaybe<Contacts_Aggregate_Bool_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  documents?: InputMaybe<Documents_Bool_Exp>;
  documents_aggregate?: InputMaybe<Documents_Aggregate_Bool_Exp>;
  form_fields?: InputMaybe<Form_Fields_Bool_Exp>;
  form_fields_aggregate?: InputMaybe<Form_Fields_Aggregate_Bool_Exp>;
  forms?: InputMaybe<Forms_Bool_Exp>;
  forms_aggregate?: InputMaybe<Forms_Aggregate_Bool_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  interactions?: InputMaybe<Interactions_Bool_Exp>;
  interactions_aggregate?: InputMaybe<Interactions_Aggregate_Bool_Exp>;
  invoices?: InputMaybe<Invoices_Bool_Exp>;
  invoices_aggregate?: InputMaybe<Invoices_Aggregate_Bool_Exp>;
  messages?: InputMaybe<Messages_Bool_Exp>;
  messages_aggregate?: InputMaybe<Messages_Aggregate_Bool_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  parties?: InputMaybe<Parties_Bool_Exp>;
  parties_aggregate?: InputMaybe<Parties_Aggregate_Bool_Exp>;
  party_relationships?: InputMaybe<Party_Relationships_Bool_Exp>;
  party_relationships_aggregate?: InputMaybe<Party_Relationships_Aggregate_Bool_Exp>;
  performance_indicators?: InputMaybe<Performance_Indicators_Bool_Exp>;
  performance_indicators_aggregate?: InputMaybe<Performance_Indicators_Aggregate_Bool_Exp>;
  performance_targets?: InputMaybe<Performance_Targets_Bool_Exp>;
  performance_targets_aggregate?: InputMaybe<Performance_Targets_Aggregate_Bool_Exp>;
  product_items?: InputMaybe<Product_Items_Bool_Exp>;
  product_items_aggregate?: InputMaybe<Product_Items_Aggregate_Bool_Exp>;
  products?: InputMaybe<Products_Bool_Exp>;
  products_aggregate?: InputMaybe<Products_Aggregate_Bool_Exp>;
  subscriptions?: InputMaybe<Subscriptions_Bool_Exp>;
  subscriptions_aggregate?: InputMaybe<Subscriptions_Aggregate_Bool_Exp>;
  tariffs?: InputMaybe<Tariffs_Bool_Exp>;
  tariffs_aggregate?: InputMaybe<Tariffs_Aggregate_Bool_Exp>;
  tasks?: InputMaybe<Tasks_Bool_Exp>;
  tasks_aggregate?: InputMaybe<Tasks_Aggregate_Bool_Exp>;
  territories?: InputMaybe<Territories_Bool_Exp>;
  territories_aggregate?: InputMaybe<Territories_Aggregate_Bool_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
  workflow_templates?: InputMaybe<Workflow_Templates_Bool_Exp>;
  workflow_templates_aggregate?: InputMaybe<Workflow_Templates_Aggregate_Bool_Exp>;
};

/** unique or primary key constraints on table "types" */
export enum Types_Constraint {
  /** unique or primary key constraint on columns "id" */
  TypesPkey = "types_pkey",
  /** unique or primary key constraint on columns "value" */
  TypesValueUnique = "types_value_unique",
}

/** input type for inserting data into table "types" */
export type Types_Insert_Input = {
  campaigns?: InputMaybe<Campaigns_Arr_Rel_Insert_Input>;
  cases?: InputMaybe<Cases_Arr_Rel_Insert_Input>;
  contact_relationships?: InputMaybe<Contact_Relationships_Arr_Rel_Insert_Input>;
  contacts?: InputMaybe<Contacts_Arr_Rel_Insert_Input>;
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  description?: InputMaybe<Scalars["String"]>;
  documents?: InputMaybe<Documents_Arr_Rel_Insert_Input>;
  form_fields?: InputMaybe<Form_Fields_Arr_Rel_Insert_Input>;
  forms?: InputMaybe<Forms_Arr_Rel_Insert_Input>;
  id?: InputMaybe<Scalars["uuid"]>;
  interactions?: InputMaybe<Interactions_Arr_Rel_Insert_Input>;
  invoices?: InputMaybe<Invoices_Arr_Rel_Insert_Input>;
  messages?: InputMaybe<Messages_Arr_Rel_Insert_Input>;
  name?: InputMaybe<Scalars["String"]>;
  parties?: InputMaybe<Parties_Arr_Rel_Insert_Input>;
  party_relationships?: InputMaybe<Party_Relationships_Arr_Rel_Insert_Input>;
  performance_indicators?: InputMaybe<Performance_Indicators_Arr_Rel_Insert_Input>;
  performance_targets?: InputMaybe<Performance_Targets_Arr_Rel_Insert_Input>;
  product_items?: InputMaybe<Product_Items_Arr_Rel_Insert_Input>;
  products?: InputMaybe<Products_Arr_Rel_Insert_Input>;
  subscriptions?: InputMaybe<Subscriptions_Arr_Rel_Insert_Input>;
  tariffs?: InputMaybe<Tariffs_Arr_Rel_Insert_Input>;
  tasks?: InputMaybe<Tasks_Arr_Rel_Insert_Input>;
  territories?: InputMaybe<Territories_Arr_Rel_Insert_Input>;
  updated_at?: InputMaybe<Scalars["timestamptz"]>;
  value?: InputMaybe<Scalars["String"]>;
  workflow_templates?: InputMaybe<Workflow_Templates_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Types_Max_Fields = {
  __typename?: "types_max_fields";
  created_at?: Maybe<Scalars["timestamptz"]>;
  description?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["uuid"]>;
  name?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
  value?: Maybe<Scalars["String"]>;
};

/** aggregate min on columns */
export type Types_Min_Fields = {
  __typename?: "types_min_fields";
  created_at?: Maybe<Scalars["timestamptz"]>;
  description?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["uuid"]>;
  name?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
  value?: Maybe<Scalars["String"]>;
};

/** response of any mutation on the table "types" */
export type Types_Mutation_Response = {
  __typename?: "types_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Types>;
};

/** input type for inserting object relation for remote table "types" */
export type Types_Obj_Rel_Insert_Input = {
  data: Types_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Types_On_Conflict>;
};

/** on_conflict condition type for table "types" */
export type Types_On_Conflict = {
  constraint: Types_Constraint;
  update_columns?: Array<Types_Update_Column>;
  where?: InputMaybe<Types_Bool_Exp>;
};

/** Ordering options when selecting data from "types". */
export type Types_Order_By = {
  campaigns_aggregate?: InputMaybe<Campaigns_Aggregate_Order_By>;
  cases_aggregate?: InputMaybe<Cases_Aggregate_Order_By>;
  contact_relationships_aggregate?: InputMaybe<Contact_Relationships_Aggregate_Order_By>;
  contacts_aggregate?: InputMaybe<Contacts_Aggregate_Order_By>;
  created_at?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  documents_aggregate?: InputMaybe<Documents_Aggregate_Order_By>;
  form_fields_aggregate?: InputMaybe<Form_Fields_Aggregate_Order_By>;
  forms_aggregate?: InputMaybe<Forms_Aggregate_Order_By>;
  id?: InputMaybe<Order_By>;
  interactions_aggregate?: InputMaybe<Interactions_Aggregate_Order_By>;
  invoices_aggregate?: InputMaybe<Invoices_Aggregate_Order_By>;
  messages_aggregate?: InputMaybe<Messages_Aggregate_Order_By>;
  name?: InputMaybe<Order_By>;
  parties_aggregate?: InputMaybe<Parties_Aggregate_Order_By>;
  party_relationships_aggregate?: InputMaybe<Party_Relationships_Aggregate_Order_By>;
  performance_indicators_aggregate?: InputMaybe<Performance_Indicators_Aggregate_Order_By>;
  performance_targets_aggregate?: InputMaybe<Performance_Targets_Aggregate_Order_By>;
  product_items_aggregate?: InputMaybe<Product_Items_Aggregate_Order_By>;
  products_aggregate?: InputMaybe<Products_Aggregate_Order_By>;
  subscriptions_aggregate?: InputMaybe<Subscriptions_Aggregate_Order_By>;
  tariffs_aggregate?: InputMaybe<Tariffs_Aggregate_Order_By>;
  tasks_aggregate?: InputMaybe<Tasks_Aggregate_Order_By>;
  territories_aggregate?: InputMaybe<Territories_Aggregate_Order_By>;
  updated_at?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
  workflow_templates_aggregate?: InputMaybe<Workflow_Templates_Aggregate_Order_By>;
};

/** primary key columns input for table: types */
export type Types_Pk_Columns_Input = {
  id: Scalars["uuid"];
};

/** select columns of table "types" */
export enum Types_Select_Column {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  Description = "description",
  /** column name */
  Id = "id",
  /** column name */
  Name = "name",
  /** column name */
  UpdatedAt = "updated_at",
  /** column name */
  Value = "value",
}

/** input type for updating data in table "types" */
export type Types_Set_Input = {
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  description?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  name?: InputMaybe<Scalars["String"]>;
  updated_at?: InputMaybe<Scalars["timestamptz"]>;
  value?: InputMaybe<Scalars["String"]>;
};

/** Streaming cursor of the table "types" */
export type Types_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Types_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Types_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  description?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  name?: InputMaybe<Scalars["String"]>;
  updated_at?: InputMaybe<Scalars["timestamptz"]>;
  value?: InputMaybe<Scalars["String"]>;
};

/** update columns of table "types" */
export enum Types_Update_Column {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  Description = "description",
  /** column name */
  Id = "id",
  /** column name */
  Name = "name",
  /** column name */
  UpdatedAt = "updated_at",
  /** column name */
  Value = "value",
}

export type Types_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Types_Set_Input>;
  /** filter the rows which have to be updated */
  where: Types_Bool_Exp;
};

/** columns and relationships of "user_flags" */
export type User_Flags = {
  __typename?: "user_flags";
  created_at?: Maybe<Scalars["timestamptz"]>;
  /** An object relationship */
  flag: Flags;
  flag_id: Scalars["uuid"];
  id: Scalars["uuid"];
  user_id: Scalars["uuid"];
};

/** aggregated selection of "user_flags" */
export type User_Flags_Aggregate = {
  __typename?: "user_flags_aggregate";
  aggregate?: Maybe<User_Flags_Aggregate_Fields>;
  nodes: Array<User_Flags>;
};

export type User_Flags_Aggregate_Bool_Exp = {
  count?: InputMaybe<User_Flags_Aggregate_Bool_Exp_Count>;
};

export type User_Flags_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<User_Flags_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<User_Flags_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "user_flags" */
export type User_Flags_Aggregate_Fields = {
  __typename?: "user_flags_aggregate_fields";
  count: Scalars["Int"];
  max?: Maybe<User_Flags_Max_Fields>;
  min?: Maybe<User_Flags_Min_Fields>;
};

/** aggregate fields of "user_flags" */
export type User_Flags_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<User_Flags_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "user_flags" */
export type User_Flags_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<User_Flags_Max_Order_By>;
  min?: InputMaybe<User_Flags_Min_Order_By>;
};

/** input type for inserting array relation for remote table "user_flags" */
export type User_Flags_Arr_Rel_Insert_Input = {
  data: Array<User_Flags_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<User_Flags_On_Conflict>;
};

/** Boolean expression to filter rows from the table "user_flags". All fields are combined with a logical 'AND'. */
export type User_Flags_Bool_Exp = {
  _and?: InputMaybe<Array<User_Flags_Bool_Exp>>;
  _not?: InputMaybe<User_Flags_Bool_Exp>;
  _or?: InputMaybe<Array<User_Flags_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  flag?: InputMaybe<Flags_Bool_Exp>;
  flag_id?: InputMaybe<Uuid_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "user_flags" */
export enum User_Flags_Constraint {
  /** unique or primary key constraint on columns "id" */
  UserFlagsPkey = "user_flags_pkey",
}

/** input type for inserting data into table "user_flags" */
export type User_Flags_Insert_Input = {
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  flag?: InputMaybe<Flags_Obj_Rel_Insert_Input>;
  flag_id?: InputMaybe<Scalars["uuid"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  user_id?: InputMaybe<Scalars["uuid"]>;
};

/** aggregate max on columns */
export type User_Flags_Max_Fields = {
  __typename?: "user_flags_max_fields";
  created_at?: Maybe<Scalars["timestamptz"]>;
  flag_id?: Maybe<Scalars["uuid"]>;
  id?: Maybe<Scalars["uuid"]>;
  user_id?: Maybe<Scalars["uuid"]>;
};

/** order by max() on columns of table "user_flags" */
export type User_Flags_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  flag_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type User_Flags_Min_Fields = {
  __typename?: "user_flags_min_fields";
  created_at?: Maybe<Scalars["timestamptz"]>;
  flag_id?: Maybe<Scalars["uuid"]>;
  id?: Maybe<Scalars["uuid"]>;
  user_id?: Maybe<Scalars["uuid"]>;
};

/** order by min() on columns of table "user_flags" */
export type User_Flags_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  flag_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "user_flags" */
export type User_Flags_Mutation_Response = {
  __typename?: "user_flags_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<User_Flags>;
};

/** on_conflict condition type for table "user_flags" */
export type User_Flags_On_Conflict = {
  constraint: User_Flags_Constraint;
  update_columns?: Array<User_Flags_Update_Column>;
  where?: InputMaybe<User_Flags_Bool_Exp>;
};

/** Ordering options when selecting data from "user_flags". */
export type User_Flags_Order_By = {
  created_at?: InputMaybe<Order_By>;
  flag?: InputMaybe<Flags_Order_By>;
  flag_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: user_flags */
export type User_Flags_Pk_Columns_Input = {
  id: Scalars["uuid"];
};

/** select columns of table "user_flags" */
export enum User_Flags_Select_Column {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  FlagId = "flag_id",
  /** column name */
  Id = "id",
  /** column name */
  UserId = "user_id",
}

/** input type for updating data in table "user_flags" */
export type User_Flags_Set_Input = {
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  flag_id?: InputMaybe<Scalars["uuid"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  user_id?: InputMaybe<Scalars["uuid"]>;
};

/** Streaming cursor of the table "user_flags" */
export type User_Flags_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: User_Flags_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type User_Flags_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  flag_id?: InputMaybe<Scalars["uuid"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  user_id?: InputMaybe<Scalars["uuid"]>;
};

/** update columns of table "user_flags" */
export enum User_Flags_Update_Column {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  FlagId = "flag_id",
  /** column name */
  Id = "id",
  /** column name */
  UserId = "user_id",
}

export type User_Flags_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<User_Flags_Set_Input>;
  /** filter the rows which have to be updated */
  where: User_Flags_Bool_Exp;
};

/** columns and relationships of "user_mfa_factors" */
export type User_Mfa_Factors = {
  __typename?: "user_mfa_factors";
  created_at: Scalars["timestamptz"];
  expires_on?: Maybe<Scalars["timestamptz"]>;
  id: Scalars["uuid"];
  is_active?: Maybe<Scalars["Boolean"]>;
  mfa_type: Scalars["String"];
  secret: Scalars["String"];
  updated_at: Scalars["timestamptz"];
  user_id?: Maybe<Scalars["uuid"]>;
};

/** aggregated selection of "user_mfa_factors" */
export type User_Mfa_Factors_Aggregate = {
  __typename?: "user_mfa_factors_aggregate";
  aggregate?: Maybe<User_Mfa_Factors_Aggregate_Fields>;
  nodes: Array<User_Mfa_Factors>;
};

/** aggregate fields of "user_mfa_factors" */
export type User_Mfa_Factors_Aggregate_Fields = {
  __typename?: "user_mfa_factors_aggregate_fields";
  count: Scalars["Int"];
  max?: Maybe<User_Mfa_Factors_Max_Fields>;
  min?: Maybe<User_Mfa_Factors_Min_Fields>;
};

/** aggregate fields of "user_mfa_factors" */
export type User_Mfa_Factors_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<User_Mfa_Factors_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** Boolean expression to filter rows from the table "user_mfa_factors". All fields are combined with a logical 'AND'. */
export type User_Mfa_Factors_Bool_Exp = {
  _and?: InputMaybe<Array<User_Mfa_Factors_Bool_Exp>>;
  _not?: InputMaybe<User_Mfa_Factors_Bool_Exp>;
  _or?: InputMaybe<Array<User_Mfa_Factors_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  expires_on?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  is_active?: InputMaybe<Boolean_Comparison_Exp>;
  mfa_type?: InputMaybe<String_Comparison_Exp>;
  secret?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "user_mfa_factors" */
export enum User_Mfa_Factors_Constraint {
  /** unique or primary key constraint on columns "id" */
  UserMfaFactorsPkey = "user_mfa_factors_pkey",
}

/** input type for inserting data into table "user_mfa_factors" */
export type User_Mfa_Factors_Insert_Input = {
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  expires_on?: InputMaybe<Scalars["timestamptz"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  is_active?: InputMaybe<Scalars["Boolean"]>;
  mfa_type?: InputMaybe<Scalars["String"]>;
  secret?: InputMaybe<Scalars["String"]>;
  updated_at?: InputMaybe<Scalars["timestamptz"]>;
  user_id?: InputMaybe<Scalars["uuid"]>;
};

/** aggregate max on columns */
export type User_Mfa_Factors_Max_Fields = {
  __typename?: "user_mfa_factors_max_fields";
  created_at?: Maybe<Scalars["timestamptz"]>;
  expires_on?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["uuid"]>;
  mfa_type?: Maybe<Scalars["String"]>;
  secret?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
  user_id?: Maybe<Scalars["uuid"]>;
};

/** aggregate min on columns */
export type User_Mfa_Factors_Min_Fields = {
  __typename?: "user_mfa_factors_min_fields";
  created_at?: Maybe<Scalars["timestamptz"]>;
  expires_on?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["uuid"]>;
  mfa_type?: Maybe<Scalars["String"]>;
  secret?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
  user_id?: Maybe<Scalars["uuid"]>;
};

/** response of any mutation on the table "user_mfa_factors" */
export type User_Mfa_Factors_Mutation_Response = {
  __typename?: "user_mfa_factors_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<User_Mfa_Factors>;
};

/** on_conflict condition type for table "user_mfa_factors" */
export type User_Mfa_Factors_On_Conflict = {
  constraint: User_Mfa_Factors_Constraint;
  update_columns?: Array<User_Mfa_Factors_Update_Column>;
  where?: InputMaybe<User_Mfa_Factors_Bool_Exp>;
};

/** Ordering options when selecting data from "user_mfa_factors". */
export type User_Mfa_Factors_Order_By = {
  created_at?: InputMaybe<Order_By>;
  expires_on?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  is_active?: InputMaybe<Order_By>;
  mfa_type?: InputMaybe<Order_By>;
  secret?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: user_mfa_factors */
export type User_Mfa_Factors_Pk_Columns_Input = {
  id: Scalars["uuid"];
};

/** select columns of table "user_mfa_factors" */
export enum User_Mfa_Factors_Select_Column {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  ExpiresOn = "expires_on",
  /** column name */
  Id = "id",
  /** column name */
  IsActive = "is_active",
  /** column name */
  MfaType = "mfa_type",
  /** column name */
  Secret = "secret",
  /** column name */
  UpdatedAt = "updated_at",
  /** column name */
  UserId = "user_id",
}

/** input type for updating data in table "user_mfa_factors" */
export type User_Mfa_Factors_Set_Input = {
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  expires_on?: InputMaybe<Scalars["timestamptz"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  is_active?: InputMaybe<Scalars["Boolean"]>;
  mfa_type?: InputMaybe<Scalars["String"]>;
  secret?: InputMaybe<Scalars["String"]>;
  updated_at?: InputMaybe<Scalars["timestamptz"]>;
  user_id?: InputMaybe<Scalars["uuid"]>;
};

/** Streaming cursor of the table "user_mfa_factors" */
export type User_Mfa_Factors_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: User_Mfa_Factors_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type User_Mfa_Factors_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  expires_on?: InputMaybe<Scalars["timestamptz"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  is_active?: InputMaybe<Scalars["Boolean"]>;
  mfa_type?: InputMaybe<Scalars["String"]>;
  secret?: InputMaybe<Scalars["String"]>;
  updated_at?: InputMaybe<Scalars["timestamptz"]>;
  user_id?: InputMaybe<Scalars["uuid"]>;
};

/** update columns of table "user_mfa_factors" */
export enum User_Mfa_Factors_Update_Column {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  ExpiresOn = "expires_on",
  /** column name */
  Id = "id",
  /** column name */
  IsActive = "is_active",
  /** column name */
  MfaType = "mfa_type",
  /** column name */
  Secret = "secret",
  /** column name */
  UpdatedAt = "updated_at",
  /** column name */
  UserId = "user_id",
}

export type User_Mfa_Factors_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<User_Mfa_Factors_Set_Input>;
  /** filter the rows which have to be updated */
  where: User_Mfa_Factors_Bool_Exp;
};

/** columns and relationships of "user_statuses" */
export type User_Statuses = {
  __typename?: "user_statuses";
  created_at?: Maybe<Scalars["timestamptz"]>;
  id: Scalars["uuid"];
  /** An object relationship */
  status: Statuses;
  status_id: Scalars["uuid"];
  user_id: Scalars["uuid"];
};

/** aggregated selection of "user_statuses" */
export type User_Statuses_Aggregate = {
  __typename?: "user_statuses_aggregate";
  aggregate?: Maybe<User_Statuses_Aggregate_Fields>;
  nodes: Array<User_Statuses>;
};

export type User_Statuses_Aggregate_Bool_Exp = {
  count?: InputMaybe<User_Statuses_Aggregate_Bool_Exp_Count>;
};

export type User_Statuses_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<User_Statuses_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<User_Statuses_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "user_statuses" */
export type User_Statuses_Aggregate_Fields = {
  __typename?: "user_statuses_aggregate_fields";
  count: Scalars["Int"];
  max?: Maybe<User_Statuses_Max_Fields>;
  min?: Maybe<User_Statuses_Min_Fields>;
};

/** aggregate fields of "user_statuses" */
export type User_Statuses_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<User_Statuses_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "user_statuses" */
export type User_Statuses_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<User_Statuses_Max_Order_By>;
  min?: InputMaybe<User_Statuses_Min_Order_By>;
};

/** input type for inserting array relation for remote table "user_statuses" */
export type User_Statuses_Arr_Rel_Insert_Input = {
  data: Array<User_Statuses_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<User_Statuses_On_Conflict>;
};

/** Boolean expression to filter rows from the table "user_statuses". All fields are combined with a logical 'AND'. */
export type User_Statuses_Bool_Exp = {
  _and?: InputMaybe<Array<User_Statuses_Bool_Exp>>;
  _not?: InputMaybe<User_Statuses_Bool_Exp>;
  _or?: InputMaybe<Array<User_Statuses_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  status?: InputMaybe<Statuses_Bool_Exp>;
  status_id?: InputMaybe<Uuid_Comparison_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "user_statuses" */
export enum User_Statuses_Constraint {
  /** unique or primary key constraint on columns "id" */
  UserStatusesPkey = "user_statuses_pkey",
}

/** input type for inserting data into table "user_statuses" */
export type User_Statuses_Insert_Input = {
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  status?: InputMaybe<Statuses_Obj_Rel_Insert_Input>;
  status_id?: InputMaybe<Scalars["uuid"]>;
  user_id?: InputMaybe<Scalars["uuid"]>;
};

/** aggregate max on columns */
export type User_Statuses_Max_Fields = {
  __typename?: "user_statuses_max_fields";
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["uuid"]>;
  status_id?: Maybe<Scalars["uuid"]>;
  user_id?: Maybe<Scalars["uuid"]>;
};

/** order by max() on columns of table "user_statuses" */
export type User_Statuses_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  status_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type User_Statuses_Min_Fields = {
  __typename?: "user_statuses_min_fields";
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["uuid"]>;
  status_id?: Maybe<Scalars["uuid"]>;
  user_id?: Maybe<Scalars["uuid"]>;
};

/** order by min() on columns of table "user_statuses" */
export type User_Statuses_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  status_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "user_statuses" */
export type User_Statuses_Mutation_Response = {
  __typename?: "user_statuses_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<User_Statuses>;
};

/** on_conflict condition type for table "user_statuses" */
export type User_Statuses_On_Conflict = {
  constraint: User_Statuses_Constraint;
  update_columns?: Array<User_Statuses_Update_Column>;
  where?: InputMaybe<User_Statuses_Bool_Exp>;
};

/** Ordering options when selecting data from "user_statuses". */
export type User_Statuses_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  status?: InputMaybe<Statuses_Order_By>;
  status_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: user_statuses */
export type User_Statuses_Pk_Columns_Input = {
  id: Scalars["uuid"];
};

/** select columns of table "user_statuses" */
export enum User_Statuses_Select_Column {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  Id = "id",
  /** column name */
  StatusId = "status_id",
  /** column name */
  UserId = "user_id",
}

/** input type for updating data in table "user_statuses" */
export type User_Statuses_Set_Input = {
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  status_id?: InputMaybe<Scalars["uuid"]>;
  user_id?: InputMaybe<Scalars["uuid"]>;
};

/** Streaming cursor of the table "user_statuses" */
export type User_Statuses_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: User_Statuses_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type User_Statuses_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  status_id?: InputMaybe<Scalars["uuid"]>;
  user_id?: InputMaybe<Scalars["uuid"]>;
};

/** update columns of table "user_statuses" */
export enum User_Statuses_Update_Column {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  Id = "id",
  /** column name */
  StatusId = "status_id",
  /** column name */
  UserId = "user_id",
}

export type User_Statuses_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<User_Statuses_Set_Input>;
  /** filter the rows which have to be updated */
  where: User_Statuses_Bool_Exp;
};

/** columns and relationships of "users" */
export type Users = {
  __typename?: "users";
  created_at: Scalars["timestamptz"];
  email: Scalars["String"];
  email_verified_at?: Maybe<Scalars["timestamptz"]>;
  id: Scalars["uuid"];
  is_active: Scalars["Boolean"];
  last_sign_in_at?: Maybe<Scalars["timestamptz"]>;
  locale?: Maybe<Scalars["String"]>;
  mfa_enabled: Scalars["Boolean"];
  name: Scalars["String"];
  organisation_id?: Maybe<Scalars["uuid"]>;
  party_id?: Maybe<Scalars["uuid"]>;
  password: Scalars["String"];
  phone_number: Scalars["String"];
  salt: Scalars["String"];
  updated_at: Scalars["timestamptz"];
};

/** aggregated selection of "users" */
export type Users_Aggregate = {
  __typename?: "users_aggregate";
  aggregate?: Maybe<Users_Aggregate_Fields>;
  nodes: Array<Users>;
};

/** aggregate fields of "users" */
export type Users_Aggregate_Fields = {
  __typename?: "users_aggregate_fields";
  count: Scalars["Int"];
  max?: Maybe<Users_Max_Fields>;
  min?: Maybe<Users_Min_Fields>;
};

/** aggregate fields of "users" */
export type Users_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Users_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** Boolean expression to filter rows from the table "users". All fields are combined with a logical 'AND'. */
export type Users_Bool_Exp = {
  _and?: InputMaybe<Array<Users_Bool_Exp>>;
  _not?: InputMaybe<Users_Bool_Exp>;
  _or?: InputMaybe<Array<Users_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  email?: InputMaybe<String_Comparison_Exp>;
  email_verified_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  is_active?: InputMaybe<Boolean_Comparison_Exp>;
  last_sign_in_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  locale?: InputMaybe<String_Comparison_Exp>;
  mfa_enabled?: InputMaybe<Boolean_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  organisation_id?: InputMaybe<Uuid_Comparison_Exp>;
  party_id?: InputMaybe<Uuid_Comparison_Exp>;
  password?: InputMaybe<String_Comparison_Exp>;
  phone_number?: InputMaybe<String_Comparison_Exp>;
  salt?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "users" */
export enum Users_Constraint {
  /** unique or primary key constraint on columns "email" */
  UsersEmailUnique = "users_email_unique",
  /** unique or primary key constraint on columns "phone_number" */
  UsersPhoneNumberUnique = "users_phone_number_unique",
  /** unique or primary key constraint on columns "id" */
  UsersPkey = "users_pkey",
}

/** input type for inserting data into table "users" */
export type Users_Insert_Input = {
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  email?: InputMaybe<Scalars["String"]>;
  email_verified_at?: InputMaybe<Scalars["timestamptz"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  is_active?: InputMaybe<Scalars["Boolean"]>;
  last_sign_in_at?: InputMaybe<Scalars["timestamptz"]>;
  locale?: InputMaybe<Scalars["String"]>;
  mfa_enabled?: InputMaybe<Scalars["Boolean"]>;
  name?: InputMaybe<Scalars["String"]>;
  organisation_id?: InputMaybe<Scalars["uuid"]>;
  party_id?: InputMaybe<Scalars["uuid"]>;
  password?: InputMaybe<Scalars["String"]>;
  phone_number?: InputMaybe<Scalars["String"]>;
  salt?: InputMaybe<Scalars["String"]>;
  updated_at?: InputMaybe<Scalars["timestamptz"]>;
};

/** aggregate max on columns */
export type Users_Max_Fields = {
  __typename?: "users_max_fields";
  created_at?: Maybe<Scalars["timestamptz"]>;
  email?: Maybe<Scalars["String"]>;
  email_verified_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["uuid"]>;
  last_sign_in_at?: Maybe<Scalars["timestamptz"]>;
  locale?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  organisation_id?: Maybe<Scalars["uuid"]>;
  party_id?: Maybe<Scalars["uuid"]>;
  password?: Maybe<Scalars["String"]>;
  phone_number?: Maybe<Scalars["String"]>;
  salt?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
};

/** aggregate min on columns */
export type Users_Min_Fields = {
  __typename?: "users_min_fields";
  created_at?: Maybe<Scalars["timestamptz"]>;
  email?: Maybe<Scalars["String"]>;
  email_verified_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["uuid"]>;
  last_sign_in_at?: Maybe<Scalars["timestamptz"]>;
  locale?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  organisation_id?: Maybe<Scalars["uuid"]>;
  party_id?: Maybe<Scalars["uuid"]>;
  password?: Maybe<Scalars["String"]>;
  phone_number?: Maybe<Scalars["String"]>;
  salt?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
};

/** response of any mutation on the table "users" */
export type Users_Mutation_Response = {
  __typename?: "users_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Users>;
};

/** on_conflict condition type for table "users" */
export type Users_On_Conflict = {
  constraint: Users_Constraint;
  update_columns?: Array<Users_Update_Column>;
  where?: InputMaybe<Users_Bool_Exp>;
};

/** Ordering options when selecting data from "users". */
export type Users_Order_By = {
  created_at?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  email_verified_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  is_active?: InputMaybe<Order_By>;
  last_sign_in_at?: InputMaybe<Order_By>;
  locale?: InputMaybe<Order_By>;
  mfa_enabled?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  organisation_id?: InputMaybe<Order_By>;
  party_id?: InputMaybe<Order_By>;
  password?: InputMaybe<Order_By>;
  phone_number?: InputMaybe<Order_By>;
  salt?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: users */
export type Users_Pk_Columns_Input = {
  id: Scalars["uuid"];
};

/** select columns of table "users" */
export enum Users_Select_Column {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  Email = "email",
  /** column name */
  EmailVerifiedAt = "email_verified_at",
  /** column name */
  Id = "id",
  /** column name */
  IsActive = "is_active",
  /** column name */
  LastSignInAt = "last_sign_in_at",
  /** column name */
  Locale = "locale",
  /** column name */
  MfaEnabled = "mfa_enabled",
  /** column name */
  Name = "name",
  /** column name */
  OrganisationId = "organisation_id",
  /** column name */
  PartyId = "party_id",
  /** column name */
  Password = "password",
  /** column name */
  PhoneNumber = "phone_number",
  /** column name */
  Salt = "salt",
  /** column name */
  UpdatedAt = "updated_at",
}

/** input type for updating data in table "users" */
export type Users_Set_Input = {
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  email?: InputMaybe<Scalars["String"]>;
  email_verified_at?: InputMaybe<Scalars["timestamptz"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  is_active?: InputMaybe<Scalars["Boolean"]>;
  last_sign_in_at?: InputMaybe<Scalars["timestamptz"]>;
  locale?: InputMaybe<Scalars["String"]>;
  mfa_enabled?: InputMaybe<Scalars["Boolean"]>;
  name?: InputMaybe<Scalars["String"]>;
  organisation_id?: InputMaybe<Scalars["uuid"]>;
  party_id?: InputMaybe<Scalars["uuid"]>;
  password?: InputMaybe<Scalars["String"]>;
  phone_number?: InputMaybe<Scalars["String"]>;
  salt?: InputMaybe<Scalars["String"]>;
  updated_at?: InputMaybe<Scalars["timestamptz"]>;
};

/** Streaming cursor of the table "users" */
export type Users_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Users_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Users_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  email?: InputMaybe<Scalars["String"]>;
  email_verified_at?: InputMaybe<Scalars["timestamptz"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  is_active?: InputMaybe<Scalars["Boolean"]>;
  last_sign_in_at?: InputMaybe<Scalars["timestamptz"]>;
  locale?: InputMaybe<Scalars["String"]>;
  mfa_enabled?: InputMaybe<Scalars["Boolean"]>;
  name?: InputMaybe<Scalars["String"]>;
  organisation_id?: InputMaybe<Scalars["uuid"]>;
  party_id?: InputMaybe<Scalars["uuid"]>;
  password?: InputMaybe<Scalars["String"]>;
  phone_number?: InputMaybe<Scalars["String"]>;
  salt?: InputMaybe<Scalars["String"]>;
  updated_at?: InputMaybe<Scalars["timestamptz"]>;
};

/** update columns of table "users" */
export enum Users_Update_Column {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  Email = "email",
  /** column name */
  EmailVerifiedAt = "email_verified_at",
  /** column name */
  Id = "id",
  /** column name */
  IsActive = "is_active",
  /** column name */
  LastSignInAt = "last_sign_in_at",
  /** column name */
  Locale = "locale",
  /** column name */
  MfaEnabled = "mfa_enabled",
  /** column name */
  Name = "name",
  /** column name */
  OrganisationId = "organisation_id",
  /** column name */
  PartyId = "party_id",
  /** column name */
  Password = "password",
  /** column name */
  PhoneNumber = "phone_number",
  /** column name */
  Salt = "salt",
  /** column name */
  UpdatedAt = "updated_at",
}

export type Users_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Users_Set_Input>;
  /** filter the rows which have to be updated */
  where: Users_Bool_Exp;
};

/** Boolean expression to compare columns of type "uuid". All fields are combined with logical 'AND'. */
export type Uuid_Comparison_Exp = {
  _eq?: InputMaybe<Scalars["uuid"]>;
  _gt?: InputMaybe<Scalars["uuid"]>;
  _gte?: InputMaybe<Scalars["uuid"]>;
  _in?: InputMaybe<Array<Scalars["uuid"]>>;
  _is_null?: InputMaybe<Scalars["Boolean"]>;
  _lt?: InputMaybe<Scalars["uuid"]>;
  _lte?: InputMaybe<Scalars["uuid"]>;
  _neq?: InputMaybe<Scalars["uuid"]>;
  _nin?: InputMaybe<Array<Scalars["uuid"]>>;
};

/** columns and relationships of "webhooks" */
export type Webhooks = {
  __typename?: "webhooks";
  created_at: Scalars["timestamptz"];
  headers?: Maybe<Scalars["jsonb"]>;
  id: Scalars["uuid"];
  is_active: Scalars["Boolean"];
  method: Scalars["String"];
  name: Scalars["String"];
  organisation_id?: Maybe<Scalars["uuid"]>;
  updated_at: Scalars["timestamptz"];
  url: Scalars["String"];
};

/** columns and relationships of "webhooks" */
export type WebhooksHeadersArgs = {
  path?: InputMaybe<Scalars["String"]>;
};

/** aggregated selection of "webhooks" */
export type Webhooks_Aggregate = {
  __typename?: "webhooks_aggregate";
  aggregate?: Maybe<Webhooks_Aggregate_Fields>;
  nodes: Array<Webhooks>;
};

export type Webhooks_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Webhooks_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Webhooks_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Webhooks_Aggregate_Bool_Exp_Count>;
};

export type Webhooks_Aggregate_Bool_Exp_Bool_And = {
  arguments: Webhooks_Select_Column_Webhooks_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<Webhooks_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Webhooks_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Webhooks_Select_Column_Webhooks_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<Webhooks_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Webhooks_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Webhooks_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<Webhooks_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "webhooks" */
export type Webhooks_Aggregate_Fields = {
  __typename?: "webhooks_aggregate_fields";
  count: Scalars["Int"];
  max?: Maybe<Webhooks_Max_Fields>;
  min?: Maybe<Webhooks_Min_Fields>;
};

/** aggregate fields of "webhooks" */
export type Webhooks_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Webhooks_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "webhooks" */
export type Webhooks_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Webhooks_Max_Order_By>;
  min?: InputMaybe<Webhooks_Min_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Webhooks_Append_Input = {
  headers?: InputMaybe<Scalars["jsonb"]>;
};

/** input type for inserting array relation for remote table "webhooks" */
export type Webhooks_Arr_Rel_Insert_Input = {
  data: Array<Webhooks_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Webhooks_On_Conflict>;
};

/** Boolean expression to filter rows from the table "webhooks". All fields are combined with a logical 'AND'. */
export type Webhooks_Bool_Exp = {
  _and?: InputMaybe<Array<Webhooks_Bool_Exp>>;
  _not?: InputMaybe<Webhooks_Bool_Exp>;
  _or?: InputMaybe<Array<Webhooks_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  headers?: InputMaybe<Jsonb_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  is_active?: InputMaybe<Boolean_Comparison_Exp>;
  method?: InputMaybe<String_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  organisation_id?: InputMaybe<Uuid_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  url?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "webhooks" */
export enum Webhooks_Constraint {
  /** unique or primary key constraint on columns "id" */
  WebhooksPkey = "webhooks_pkey",
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Webhooks_Delete_At_Path_Input = {
  headers?: InputMaybe<Array<Scalars["String"]>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Webhooks_Delete_Elem_Input = {
  headers?: InputMaybe<Scalars["Int"]>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Webhooks_Delete_Key_Input = {
  headers?: InputMaybe<Scalars["String"]>;
};

/** input type for inserting data into table "webhooks" */
export type Webhooks_Insert_Input = {
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  headers?: InputMaybe<Scalars["jsonb"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  is_active?: InputMaybe<Scalars["Boolean"]>;
  method?: InputMaybe<Scalars["String"]>;
  name?: InputMaybe<Scalars["String"]>;
  organisation_id?: InputMaybe<Scalars["uuid"]>;
  updated_at?: InputMaybe<Scalars["timestamptz"]>;
  url?: InputMaybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Webhooks_Max_Fields = {
  __typename?: "webhooks_max_fields";
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["uuid"]>;
  method?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  organisation_id?: Maybe<Scalars["uuid"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
  url?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "webhooks" */
export type Webhooks_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  method?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  organisation_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  url?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Webhooks_Min_Fields = {
  __typename?: "webhooks_min_fields";
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["uuid"]>;
  method?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  organisation_id?: Maybe<Scalars["uuid"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
  url?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "webhooks" */
export type Webhooks_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  method?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  organisation_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  url?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "webhooks" */
export type Webhooks_Mutation_Response = {
  __typename?: "webhooks_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Webhooks>;
};

/** on_conflict condition type for table "webhooks" */
export type Webhooks_On_Conflict = {
  constraint: Webhooks_Constraint;
  update_columns?: Array<Webhooks_Update_Column>;
  where?: InputMaybe<Webhooks_Bool_Exp>;
};

/** Ordering options when selecting data from "webhooks". */
export type Webhooks_Order_By = {
  created_at?: InputMaybe<Order_By>;
  headers?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  is_active?: InputMaybe<Order_By>;
  method?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  organisation_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  url?: InputMaybe<Order_By>;
};

/** primary key columns input for table: webhooks */
export type Webhooks_Pk_Columns_Input = {
  id: Scalars["uuid"];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Webhooks_Prepend_Input = {
  headers?: InputMaybe<Scalars["jsonb"]>;
};

/** select columns of table "webhooks" */
export enum Webhooks_Select_Column {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  Headers = "headers",
  /** column name */
  Id = "id",
  /** column name */
  IsActive = "is_active",
  /** column name */
  Method = "method",
  /** column name */
  Name = "name",
  /** column name */
  OrganisationId = "organisation_id",
  /** column name */
  UpdatedAt = "updated_at",
  /** column name */
  Url = "url",
}

/** select "webhooks_aggregate_bool_exp_bool_and_arguments_columns" columns of table "webhooks" */
export enum Webhooks_Select_Column_Webhooks_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  IsActive = "is_active",
}

/** select "webhooks_aggregate_bool_exp_bool_or_arguments_columns" columns of table "webhooks" */
export enum Webhooks_Select_Column_Webhooks_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  IsActive = "is_active",
}

/** input type for updating data in table "webhooks" */
export type Webhooks_Set_Input = {
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  headers?: InputMaybe<Scalars["jsonb"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  is_active?: InputMaybe<Scalars["Boolean"]>;
  method?: InputMaybe<Scalars["String"]>;
  name?: InputMaybe<Scalars["String"]>;
  organisation_id?: InputMaybe<Scalars["uuid"]>;
  updated_at?: InputMaybe<Scalars["timestamptz"]>;
  url?: InputMaybe<Scalars["String"]>;
};

/** Streaming cursor of the table "webhooks" */
export type Webhooks_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Webhooks_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Webhooks_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  headers?: InputMaybe<Scalars["jsonb"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  is_active?: InputMaybe<Scalars["Boolean"]>;
  method?: InputMaybe<Scalars["String"]>;
  name?: InputMaybe<Scalars["String"]>;
  organisation_id?: InputMaybe<Scalars["uuid"]>;
  updated_at?: InputMaybe<Scalars["timestamptz"]>;
  url?: InputMaybe<Scalars["String"]>;
};

/** update columns of table "webhooks" */
export enum Webhooks_Update_Column {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  Headers = "headers",
  /** column name */
  Id = "id",
  /** column name */
  IsActive = "is_active",
  /** column name */
  Method = "method",
  /** column name */
  Name = "name",
  /** column name */
  OrganisationId = "organisation_id",
  /** column name */
  UpdatedAt = "updated_at",
  /** column name */
  Url = "url",
}

export type Webhooks_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Webhooks_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Webhooks_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Webhooks_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Webhooks_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Webhooks_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Webhooks_Set_Input>;
  /** filter the rows which have to be updated */
  where: Webhooks_Bool_Exp;
};

/** columns and relationships of "workflow_templates" */
export type Workflow_Templates = {
  __typename?: "workflow_templates";
  created_at: Scalars["timestamptz"];
  id: Scalars["uuid"];
  name: Scalars["String"];
  organisation_id?: Maybe<Scalars["uuid"]>;
  template: Scalars["jsonb"];
  /** An object relationship */
  type: Types;
  type_id: Scalars["uuid"];
  updated_at: Scalars["timestamptz"];
};

/** columns and relationships of "workflow_templates" */
export type Workflow_TemplatesTemplateArgs = {
  path?: InputMaybe<Scalars["String"]>;
};

/** aggregated selection of "workflow_templates" */
export type Workflow_Templates_Aggregate = {
  __typename?: "workflow_templates_aggregate";
  aggregate?: Maybe<Workflow_Templates_Aggregate_Fields>;
  nodes: Array<Workflow_Templates>;
};

export type Workflow_Templates_Aggregate_Bool_Exp = {
  count?: InputMaybe<Workflow_Templates_Aggregate_Bool_Exp_Count>;
};

export type Workflow_Templates_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Workflow_Templates_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<Workflow_Templates_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "workflow_templates" */
export type Workflow_Templates_Aggregate_Fields = {
  __typename?: "workflow_templates_aggregate_fields";
  count: Scalars["Int"];
  max?: Maybe<Workflow_Templates_Max_Fields>;
  min?: Maybe<Workflow_Templates_Min_Fields>;
};

/** aggregate fields of "workflow_templates" */
export type Workflow_Templates_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Workflow_Templates_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "workflow_templates" */
export type Workflow_Templates_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Workflow_Templates_Max_Order_By>;
  min?: InputMaybe<Workflow_Templates_Min_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Workflow_Templates_Append_Input = {
  template?: InputMaybe<Scalars["jsonb"]>;
};

/** input type for inserting array relation for remote table "workflow_templates" */
export type Workflow_Templates_Arr_Rel_Insert_Input = {
  data: Array<Workflow_Templates_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Workflow_Templates_On_Conflict>;
};

/** Boolean expression to filter rows from the table "workflow_templates". All fields are combined with a logical 'AND'. */
export type Workflow_Templates_Bool_Exp = {
  _and?: InputMaybe<Array<Workflow_Templates_Bool_Exp>>;
  _not?: InputMaybe<Workflow_Templates_Bool_Exp>;
  _or?: InputMaybe<Array<Workflow_Templates_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  organisation_id?: InputMaybe<Uuid_Comparison_Exp>;
  template?: InputMaybe<Jsonb_Comparison_Exp>;
  type?: InputMaybe<Types_Bool_Exp>;
  type_id?: InputMaybe<Uuid_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "workflow_templates" */
export enum Workflow_Templates_Constraint {
  /** unique or primary key constraint on columns "id" */
  WorkflowTemplatesPkey = "workflow_templates_pkey",
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Workflow_Templates_Delete_At_Path_Input = {
  template?: InputMaybe<Array<Scalars["String"]>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Workflow_Templates_Delete_Elem_Input = {
  template?: InputMaybe<Scalars["Int"]>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Workflow_Templates_Delete_Key_Input = {
  template?: InputMaybe<Scalars["String"]>;
};

/** input type for inserting data into table "workflow_templates" */
export type Workflow_Templates_Insert_Input = {
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  name?: InputMaybe<Scalars["String"]>;
  organisation_id?: InputMaybe<Scalars["uuid"]>;
  template?: InputMaybe<Scalars["jsonb"]>;
  type?: InputMaybe<Types_Obj_Rel_Insert_Input>;
  type_id?: InputMaybe<Scalars["uuid"]>;
  updated_at?: InputMaybe<Scalars["timestamptz"]>;
};

/** aggregate max on columns */
export type Workflow_Templates_Max_Fields = {
  __typename?: "workflow_templates_max_fields";
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["uuid"]>;
  name?: Maybe<Scalars["String"]>;
  organisation_id?: Maybe<Scalars["uuid"]>;
  type_id?: Maybe<Scalars["uuid"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
};

/** order by max() on columns of table "workflow_templates" */
export type Workflow_Templates_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  organisation_id?: InputMaybe<Order_By>;
  type_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Workflow_Templates_Min_Fields = {
  __typename?: "workflow_templates_min_fields";
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["uuid"]>;
  name?: Maybe<Scalars["String"]>;
  organisation_id?: Maybe<Scalars["uuid"]>;
  type_id?: Maybe<Scalars["uuid"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
};

/** order by min() on columns of table "workflow_templates" */
export type Workflow_Templates_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  organisation_id?: InputMaybe<Order_By>;
  type_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "workflow_templates" */
export type Workflow_Templates_Mutation_Response = {
  __typename?: "workflow_templates_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Workflow_Templates>;
};

/** on_conflict condition type for table "workflow_templates" */
export type Workflow_Templates_On_Conflict = {
  constraint: Workflow_Templates_Constraint;
  update_columns?: Array<Workflow_Templates_Update_Column>;
  where?: InputMaybe<Workflow_Templates_Bool_Exp>;
};

/** Ordering options when selecting data from "workflow_templates". */
export type Workflow_Templates_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  organisation_id?: InputMaybe<Order_By>;
  template?: InputMaybe<Order_By>;
  type?: InputMaybe<Types_Order_By>;
  type_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: workflow_templates */
export type Workflow_Templates_Pk_Columns_Input = {
  id: Scalars["uuid"];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Workflow_Templates_Prepend_Input = {
  template?: InputMaybe<Scalars["jsonb"]>;
};

/** select columns of table "workflow_templates" */
export enum Workflow_Templates_Select_Column {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  Id = "id",
  /** column name */
  Name = "name",
  /** column name */
  OrganisationId = "organisation_id",
  /** column name */
  Template = "template",
  /** column name */
  TypeId = "type_id",
  /** column name */
  UpdatedAt = "updated_at",
}

/** input type for updating data in table "workflow_templates" */
export type Workflow_Templates_Set_Input = {
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  name?: InputMaybe<Scalars["String"]>;
  organisation_id?: InputMaybe<Scalars["uuid"]>;
  template?: InputMaybe<Scalars["jsonb"]>;
  type_id?: InputMaybe<Scalars["uuid"]>;
  updated_at?: InputMaybe<Scalars["timestamptz"]>;
};

/** Streaming cursor of the table "workflow_templates" */
export type Workflow_Templates_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Workflow_Templates_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Workflow_Templates_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  name?: InputMaybe<Scalars["String"]>;
  organisation_id?: InputMaybe<Scalars["uuid"]>;
  template?: InputMaybe<Scalars["jsonb"]>;
  type_id?: InputMaybe<Scalars["uuid"]>;
  updated_at?: InputMaybe<Scalars["timestamptz"]>;
};

/** update columns of table "workflow_templates" */
export enum Workflow_Templates_Update_Column {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  Id = "id",
  /** column name */
  Name = "name",
  /** column name */
  OrganisationId = "organisation_id",
  /** column name */
  Template = "template",
  /** column name */
  TypeId = "type_id",
  /** column name */
  UpdatedAt = "updated_at",
}

export type Workflow_Templates_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Workflow_Templates_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Workflow_Templates_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Workflow_Templates_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Workflow_Templates_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Workflow_Templates_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Workflow_Templates_Set_Input>;
  /** filter the rows which have to be updated */
  where: Workflow_Templates_Bool_Exp;
};

export type FormQueryOptionsFragment = {
  __typename?: "forms";
  id: any;
  name: string;
  description?: string | null;
  display_if?: any | null;
  workflow_type?: string | null;
  parent_form_id?: any | null;
  created_at: any;
  updated_at: any;
  form_fields: Array<{
    __typename?: "form_fields";
    id: any;
    field_name?: string | null;
    label: string;
    is_required: boolean;
    options?: any | null;
    units?: string | null;
    position: number;
    step?: string | null;
    min?: any | null;
    max?: any | null;
    default_value?: string | null;
    placeholder?: string | null;
    validation_regex?: string | null;
    display_if?: any | null;
    type_id: any;
  }>;
};

export type LoanAccountBaseFieldsFragment = {
  __typename?: "subscriptions";
  id: any;
  invoice_interval?: string | null;
  metadata?: any | null;
  subscription_start: any;
  subscription_end?: any | null;
  created_at: any;
  updated_at: any;
  product_id: any;
  subscription_items: Array<{
    __typename?: "subscription_items";
    id: any;
    is_voided: boolean;
    void_reason?: string | null;
    quantity: any;
    ledger_account_id?: any | null;
    product_item: {
      __typename?: "product_items";
      id: any;
      name: string;
      description: string;
      is_active: boolean;
      ledger_account_id?: any | null;
      product_id: any;
      metadata?: any | null;
      tariff: {
        __typename?: "tariffs";
        id: any;
        is_active: boolean;
        is_percentage: boolean;
        max_value?: any | null;
        min_value: any;
        name: string;
        of_total: boolean;
        of_field_value: boolean;
        metadata?: any | null;
        created_at: any;
        type: { __typename?: "types"; id: any; name: string; value: string };
      };
      type: { __typename?: "types"; id: any; name: string; value: string };
    };
  }>;
  subscription_schedules: Array<{
    __typename?: "subscription_schedules";
    id: any;
    due_date: any;
    beginning_balance: any;
    scheduled_payment: any;
    extra_payment: any;
    actual_payment: any;
    principal: any;
    interest: any;
    penalties: any;
    charges: any;
    ending_balance: any;
    total_expected_payment?: any | null;
    metadata?: any | null;
    created_at: any;
    updated_at: any;
    partyByLastUpdatedBy?: {
      __typename?: "parties";
      id: any;
      name: string;
    } | null;
  }>;
  subscription_payments: Array<{
    __typename?: "subscription_payments";
    id: any;
    amount: any;
    subscription_balance: any;
    payment_date: any;
    created_at: any;
    subscription_payment_transactions: Array<{
      __typename?: "subscription_payment_transactions";
      id: any;
      amount: any;
    }>;
  }>;
  subscription_rules: Array<{
    __typename?: "subscription_rules";
    id: any;
    rule: {
      __typename?: "rules";
      id: any;
      name: string;
      is_active: boolean;
      run_on: Array<string>;
      success_event?: any | null;
      metadata: any;
      created_at: any;
      updated_at: any;
      conditions: Array<{
        __typename?: "conditions";
        id: any;
        hierarchy_resolver?: string | null;
        fact: string;
        value: string;
        operator: string;
        params?: any | null;
        path: string;
        root_operator: string;
        priority: number;
        is_active: boolean;
      }>;
    };
  }>;
  subscription_statuses: Array<{
    __typename?: "subscription_statuses";
    id: any;
    created_at?: any | null;
    status: { __typename?: "statuses"; id: any; name: string; value: string };
  }>;
  penalty: Array<{
    __typename?: "subscription_items";
    id: any;
    invoice_items: Array<{
      __typename?: "invoice_items";
      id: any;
      quantity: any;
      amount: any;
    }>;
  }>;
  latest_invoice: Array<{
    __typename?: "invoices";
    id: any;
    amount_due: any;
    created_at: any;
    invoice_items: Array<{
      __typename?: "invoice_items";
      id: any;
      quantity: any;
      amount: any;
      created_at?: any | null;
      product_item: { __typename?: "product_items"; id: any; name: string };
    }>;
  }>;
  invoices: Array<{
    __typename?: "invoices";
    id: any;
    amount_due: any;
    due_at: any;
    created_at: any;
    status: { __typename?: "statuses"; id: any; name: string; value: string };
    invoice_items: Array<{
      __typename?: "invoice_items";
      id: any;
      quantity: any;
      amount: any;
      product_item: {
        __typename?: "product_items";
        name: string;
        type: { __typename?: "types"; value: string };
      };
    }>;
  }>;
  current_status: Array<{
    __typename?: "subscription_statuses";
    id: any;
    status: { __typename?: "statuses"; id: any; name: string; value: string };
  }>;
  disbursed: Array<{
    __typename?: "subscription_statuses";
    id: any;
    created_at?: any | null;
  }>;
  type: { __typename?: "types"; id: any; name: string; value: string };
  product: {
    __typename?: "products";
    id: any;
    name: string;
    ledger_id?: any | null;
    product_settings: Array<{
      __typename?: "product_settings";
      id: any;
      name: string;
      value?: string | null;
    }>;
  };
  party: { __typename?: "parties"; id: any; name: string; type_id: any };
};

export type TransactionEntriesPaginationFragment = {
  __typename?: "TransactionLedgerEntryConnection";
  aggregate: { __typename?: "Aggergate"; totalCount: number };
  pageInfo: {
    __typename?: "PageInfo";
    endCursor: string;
    hasNextPage: boolean;
  };
};

export type PartyBaseFieldsFragment = {
  __typename?: "parties";
  id: any;
  name: string;
  nickname?: string | null;
  avatar?: string | null;
  email: string;
  location?: string | null;
  party_number?: string | null;
  party_number_schema?: string | null;
  locale?: string | null;
  type_id: any;
  form_id?: any | null;
  metadata?: any | null;
  created_at: any;
  phoneNumber: string;
};

export type OrderedPartyStatusesFragment = {
  __typename?: "parties";
  party_statuses: Array<{
    __typename?: "party_statuses";
    status: { __typename?: "statuses"; name: string; value: string };
  }>;
};

export type PartyContactsFieldsFragment = {
  __typename?: "parties";
  party_contacts: Array<{
    __typename?: "party_contacts";
    contact: {
      __typename?: "contacts";
      id: any;
      name: string;
      contact_details: Array<{
        __typename?: "contact_details";
        id: any;
        value?: string | null;
        form_field: {
          __typename?: "form_fields";
          id: any;
          field_name?: string | null;
        };
      }>;
    };
  }>;
};

export type ProductBaseFieldsFragment = {
  __typename?: "products";
  id: any;
  name: string;
  description: string;
  created_at: any;
  updated_at: any;
  type: { __typename?: "types"; id: any; name: string; value: string };
  sub_type?: {
    __typename?: "types";
    id: any;
    name: string;
    value: string;
  } | null;
  product_items: Array<{
    __typename?: "product_items";
    id: any;
    name: string;
    description: string;
    is_active: boolean;
    ledger_account_id?: any | null;
    metadata?: any | null;
    created_at: any;
    updated_at: any;
    tariff: {
      __typename?: "tariffs";
      id: any;
      is_active: boolean;
      is_percentage: boolean;
      value: any;
      max_value?: any | null;
      min_value: any;
      name: string;
      of_total: boolean;
      of_field_value: boolean;
      metadata?: any | null;
      created_at: any;
      type: { __typename?: "types"; id: any; name: string; value: string };
    };
    type: { __typename?: "types"; id: any; name: string; value: string };
    sub_type?: {
      __typename?: "types";
      id: any;
      name: string;
      value: string;
    } | null;
  }>;
  product_settings: Array<{
    __typename?: "product_settings";
    id: any;
    name: string;
    value?: string | null;
    type: { __typename?: "types"; id: any; name: string };
  }>;
  product_statuses: Array<{
    __typename?: "product_statuses";
    id: any;
    status: { __typename?: "statuses"; id: any; name: string; value: string };
  }>;
  active_subscriptions?: {
    __typename?: "active_product_subscriptions";
    count?: any | null;
  } | null;
  mom_growth_report: {
    __typename?: "MoMGrowthReport";
    report_name: string;
    report_data: Array<{
      __typename?: "MoMData";
      month: string;
      previous_month_value: number;
      current_month_value: number;
      percentage_growth: string;
    }>;
  };
};

export type LoanProductBaseFieldsFragment = {
  __typename?: "products";
  id: any;
  name: string;
  description: string;
  created_at: any;
  updated_at: any;
  type: { __typename?: "types"; id: any; name: string; value: string };
  sub_type?: {
    __typename?: "types";
    id: any;
    name: string;
    value: string;
  } | null;
  product_items: Array<{
    __typename?: "product_items";
    id: any;
    name: string;
    description: string;
    is_active: boolean;
    ledger_account_id?: any | null;
    metadata?: any | null;
    created_at: any;
    updated_at: any;
    tariff: {
      __typename?: "tariffs";
      id: any;
      is_active: boolean;
      is_percentage: boolean;
      value: any;
      max_value?: any | null;
      min_value: any;
      name: string;
      of_total: boolean;
      of_field_value: boolean;
      metadata?: any | null;
      created_at: any;
      type: { __typename?: "types"; id: any; name: string; value: string };
    };
    type: { __typename?: "types"; id: any; name: string; value: string };
    sub_type?: {
      __typename?: "types";
      id: any;
      name: string;
      value: string;
    } | null;
  }>;
  product_settings: Array<{
    __typename?: "product_settings";
    id: any;
    name: string;
    value?: string | null;
    type: { __typename?: "types"; id: any; name: string };
  }>;
  product_statuses: Array<{
    __typename?: "product_statuses";
    id: any;
    status: { __typename?: "statuses"; id: any; name: string; value: string };
  }>;
  subscriptions: Array<{
    __typename?: "subscriptions";
    id: any;
    created_at: any;
    party: { __typename?: "parties"; id: any; name: string; type_id: any };
    subscription_tasks: Array<{
      __typename?: "subscription_tasks";
      id: any;
      task_id: any;
    }>;
    subscription_statuses: Array<{
      __typename?: "subscription_statuses";
      id: any;
      status_id: any;
    }>;
  }>;
};

export type RuleFieldsFragment = {
  __typename?: "rules";
  id: any;
  name: string;
  is_active: boolean;
  run_on: Array<string>;
  success_event?: any | null;
  metadata: any;
  created_at: any;
  updated_at: any;
  conditions: Array<{
    __typename?: "conditions";
    id: any;
    hierarchy_resolver?: string | null;
    fact: string;
    value: string;
    operator: string;
    params?: any | null;
    path: string;
    root_operator: string;
    priority: number;
    is_active: boolean;
  }>;
};

export type SubscriptionBaseFieldsFragment = {
  __typename?: "subscriptions";
  id: any;
  invoice_interval?: string | null;
  created_at: any;
  updated_at: any;
  metadata?: any | null;
  subscription_start: any;
  subscription_end?: any | null;
  product_id: any;
  subscription_items: Array<{
    __typename?: "subscription_items";
    id: any;
    is_voided: boolean;
    void_reason?: string | null;
    quantity: any;
    ledger_account_id?: any | null;
    product_item: {
      __typename?: "product_items";
      id: any;
      name: string;
      description: string;
      is_active: boolean;
      ledger_account_id?: any | null;
      product_id: any;
      metadata?: any | null;
      tariff: {
        __typename?: "tariffs";
        id: any;
        is_active: boolean;
        is_percentage: boolean;
        max_value?: any | null;
        min_value: any;
        name: string;
        of_total: boolean;
        of_field_value: boolean;
        metadata?: any | null;
        created_at: any;
        type: { __typename?: "types"; id: any; name: string; value: string };
      };
      type: { __typename?: "types"; id: any; name: string; value: string };
    };
  }>;
  subscription_rules: Array<{
    __typename?: "subscription_rules";
    id: any;
    rule: {
      __typename?: "rules";
      id: any;
      name: string;
      is_active: boolean;
      run_on: Array<string>;
      success_event?: any | null;
      metadata: any;
      created_at: any;
      updated_at: any;
      conditions: Array<{
        __typename?: "conditions";
        id: any;
        hierarchy_resolver?: string | null;
        fact: string;
        value: string;
        operator: string;
        params?: any | null;
        path: string;
        root_operator: string;
        priority: number;
        is_active: boolean;
      }>;
    };
  }>;
  subscription_details: Array<{
    __typename?: "subscription_details";
    id: any;
    value: string;
    form_field: {
      __typename?: "form_fields";
      id: any;
      field_name?: string | null;
    };
  }>;
  subscription_statuses: Array<{
    __typename?: "subscription_statuses";
    id: any;
    status: { __typename?: "statuses"; id: any; name: string; value: string };
  }>;
  invoices: Array<{
    __typename?: "invoices";
    id: any;
    created_at: any;
    updated_at: any;
  }>;
  type: { __typename?: "types"; id: any; name: string; value: string };
  product: {
    __typename?: "products";
    id: any;
    name: string;
    ledger_id?: any | null;
    product_settings: Array<{
      __typename?: "product_settings";
      id: any;
      name: string;
      value?: string | null;
    }>;
  };
  party: { __typename?: "parties"; id: any; name: string; type_id: any };
};

export type SubscriptionItemFieldsFragment = {
  __typename?: "subscription_items";
  id: any;
  is_voided: boolean;
  void_reason?: string | null;
  quantity: any;
  ledger_account_id?: any | null;
  product_item: {
    __typename?: "product_items";
    id: any;
    name: string;
    description: string;
    is_active: boolean;
    ledger_account_id?: any | null;
    product_id: any;
    metadata?: any | null;
    tariff: {
      __typename?: "tariffs";
      id: any;
      is_active: boolean;
      is_percentage: boolean;
      max_value?: any | null;
      min_value: any;
      name: string;
      of_total: boolean;
      of_field_value: boolean;
      metadata?: any | null;
      created_at: any;
      type: { __typename?: "types"; id: any; name: string; value: string };
    };
    type: { __typename?: "types"; id: any; name: string; value: string };
  };
};

export type AddDocumentNoteMutationVariables = Exact<{
  typeId: Scalars["uuid"];
  interactionData: Interaction_Details_Arr_Rel_Insert_Input;
  interactedId: Scalars["uuid"];
}>;

export type AddDocumentNoteMutation = {
  __typename?: "mutation_root";
  insert_interactions?: {
    __typename?: "interactions_mutation_response";
    returning: Array<{
      __typename?: "interactions";
      id: any;
      interaction_data: Array<{ __typename?: "interaction_details"; id: any }>;
    }>;
  } | null;
};

export type AddInvoiceNoteMutationVariables = Exact<{
  typeId: Scalars["uuid"];
  interactionData: Interaction_Details_Arr_Rel_Insert_Input;
  interactedId: Scalars["uuid"];
}>;

export type AddInvoiceNoteMutation = {
  __typename?: "mutation_root";
  insert_interactions?: {
    __typename?: "interactions_mutation_response";
    returning: Array<{
      __typename?: "interactions";
      id: any;
      interaction_data: Array<{ __typename?: "interaction_details"; id: any }>;
    }>;
  } | null;
};

export type AddMfaMethodMutationVariables = Exact<{
  mfaMethod: Scalars["String"];
}>;

export type AddMfaMethodMutation = {
  __typename?: "mutation_root";
  addMFAMethod?: {
    __typename?: "AddMFAMethodOutput";
    qrCodeURL?: string | null;
  } | null;
};

export type AddPartyDocumentsMutationVariables = Exact<{
  documentData:
    | Array<Party_Documents_Insert_Input>
    | Party_Documents_Insert_Input;
}>;

export type AddPartyDocumentsMutation = {
  __typename?: "mutation_root";
  insert_party_documents?: {
    __typename?: "party_documents_mutation_response";
    returning: Array<{
      __typename?: "party_documents";
      id: any;
      document_id: any;
    }>;
  } | null;
};

export type AddPartyNoteMutationVariables = Exact<{
  typeId: Scalars["uuid"];
  interactionData: Interaction_Details_Arr_Rel_Insert_Input;
  interactedId: Scalars["uuid"];
}>;

export type AddPartyNoteMutation = {
  __typename?: "mutation_root";
  insert_interactions?: {
    __typename?: "interactions_mutation_response";
    returning: Array<{
      __typename?: "interactions";
      id: any;
      interaction_data: Array<{ __typename?: "interaction_details"; id: any }>;
    }>;
  } | null;
};

export type AddPartyStatusMutationVariables = Exact<{
  partyId: Scalars["uuid"];
  statusId: Scalars["uuid"];
}>;

export type AddPartyStatusMutation = {
  __typename?: "mutation_root";
  insert_party_statuses_one?: { __typename?: "party_statuses"; id: any } | null;
};

export type AddProductItemMutationVariables = Exact<{
  input: Array<Product_Items_Insert_Input> | Product_Items_Insert_Input;
}>;

export type AddProductItemMutation = {
  __typename?: "mutation_root";
  insert_product_items?: {
    __typename?: "product_items_mutation_response";
    affected_rows: number;
  } | null;
};

export type AddProductRuleMutationVariables = Exact<{
  input: Product_Rules_Insert_Input;
}>;

export type AddProductRuleMutation = {
  __typename?: "mutation_root";
  insert_product_rules_one?: {
    __typename?: "product_rules";
    id: any;
    rule_id: any;
  } | null;
};

export type AddProductSettingMutationVariables = Exact<{
  input: Array<Product_Settings_Insert_Input> | Product_Settings_Insert_Input;
}>;

export type AddProductSettingMutation = {
  __typename?: "mutation_root";
  insert_product_settings?: {
    __typename?: "product_settings_mutation_response";
    affected_rows: number;
  } | null;
};

export type AddProductStatusMutationVariables = Exact<{
  productId: Scalars["uuid"];
  statusId: Scalars["uuid"];
}>;

export type AddProductStatusMutation = {
  __typename?: "mutation_root";
  insert_product_statuses_one?: {
    __typename?: "product_statuses";
    id: any;
  } | null;
};

export type AddSubscriptionNoteMutationVariables = Exact<{
  typeId: Scalars["uuid"];
  interactionData: Interaction_Details_Arr_Rel_Insert_Input;
  interactedId: Scalars["uuid"];
}>;

export type AddSubscriptionNoteMutation = {
  __typename?: "mutation_root";
  insert_interactions?: {
    __typename?: "interactions_mutation_response";
    returning: Array<{
      __typename?: "interactions";
      id: any;
      interaction_data: Array<{ __typename?: "interaction_details"; id: any }>;
    }>;
  } | null;
};

export type AddSubscriptionRuleMutationVariables = Exact<{
  input: Subscription_Rules_Insert_Input;
}>;

export type AddSubscriptionRuleMutation = {
  __typename?: "mutation_root";
  insert_subscription_rules_one?: {
    __typename?: "subscription_rules";
    id: any;
    rule_id: any;
  } | null;
};

export type AddSubscriptionStatusMutationVariables = Exact<{
  subscriptionId: Scalars["uuid"];
  statusId: Scalars["uuid"];
}>;

export type AddSubscriptionStatusMutation = {
  __typename?: "mutation_root";
  insert_subscription_statuses_one?: {
    __typename?: "subscription_statuses";
    id: any;
  } | null;
};

export type SetPartiesStatusMutationVariables = Exact<{
  object: Array<Party_Statuses_Insert_Input> | Party_Statuses_Insert_Input;
}>;

export type SetPartiesStatusMutation = {
  __typename?: "mutation_root";
  insert_party_statuses?: {
    __typename?: "party_statuses_mutation_response";
    affected_rows: number;
  } | null;
};

export type SetSubscriptionsStatusMutationVariables = Exact<{
  object:
    | Array<Subscription_Statuses_Insert_Input>
    | Subscription_Statuses_Insert_Input;
}>;

export type SetSubscriptionsStatusMutation = {
  __typename?: "mutation_root";
  insert_subscription_statuses?: {
    __typename?: "subscription_statuses_mutation_response";
    affected_rows: number;
  } | null;
};

export type CancelSubscriptionMutationVariables = Exact<{
  input: CancelSubscriptionInput;
}>;

export type CancelSubscriptionMutation = {
  __typename?: "mutation_root";
  cancelSubscription?: {
    __typename?: "CancelSubscriptionOutput";
    message: string;
  } | null;
};

export type ChangePasswordMutationVariables = Exact<{
  email: Scalars["String"];
  newPassword: Scalars["String"];
  oldPassword: Scalars["String"];
}>;

export type ChangePasswordMutation = {
  __typename?: "mutation_root";
  changePassword?: {
    __typename?: "ChangePasswordOutput";
    message?: string | null;
  } | null;
};

export type CompleteLoginMutationVariables = Exact<{
  params: CompleteLoginInput;
}>;

export type CompleteLoginMutation = {
  __typename?: "mutation_root";
  completeLogin?: {
    __typename?: "CompleteLoginOutput";
    partyId?: string | null;
    accessToken: string;
    message?: string | null;
    type?: string | null;
  } | null;
};

export type CreateAndSubscribeToChannelMutationVariables = Exact<{
  channelName: Scalars["String"];
  channelDescription?: InputMaybe<Scalars["String"]>;
  partyIds: Array<InputMaybe<Scalars["uuid"]>> | InputMaybe<Scalars["uuid"]>;
  deliveryAddresses?: InputMaybe<Scalars["jsonObject"]>;
  deliveryMethods?: InputMaybe<
    Array<InputMaybe<Scalars["String"]>> | InputMaybe<Scalars["String"]>
  >;
  metadata?: InputMaybe<Scalars["jsonObject"]>;
}>;

export type CreateAndSubscribeToChannelMutation = {
  __typename?: "mutation_root";
  createAndSubscribeToChannel?: {
    __typename?: "CreateAndSubscribeToChannelOutput";
    body?: any | null;
    message?: string | null;
  } | null;
};

export type CreateProductMutationVariables = Exact<{
  name: Scalars["String"];
  type_id: Scalars["uuid"];
  description: Scalars["String"];
  product_items: Product_Items_Arr_Rel_Insert_Input;
  product_settings: Product_Settings_Arr_Rel_Insert_Input;
  product_statuses: Product_Statuses_Arr_Rel_Insert_Input;
  product_rules?: InputMaybe<Product_Rules_Arr_Rel_Insert_Input>;
}>;

export type CreateProductMutation = {
  __typename?: "mutation_root";
  insert_products?: {
    __typename?: "products_mutation_response";
    affected_rows: number;
    returning: Array<{ __typename?: "products"; id: any }>;
  } | null;
};

export type CreateSubscriptionMutationVariables = Exact<{
  input: Subscriptions_Insert_Input;
}>;

export type CreateSubscriptionMutation = {
  __typename?: "mutation_root";
  insert_subscriptions_one?: { __typename?: "subscriptions"; id: any } | null;
};

export type AddNewWorkflowMutationVariables = Exact<{
  description: Scalars["String"];
  displayIf?: InputMaybe<Scalars["jsonb"]>;
  name: Scalars["String"];
  parentFormId?: InputMaybe<Scalars["uuid"]>;
  workflowType: Scalars["String"];
  typeId: Scalars["uuid"];
  formFields: Form_Fields_Arr_Rel_Insert_Input;
  childForms?: InputMaybe<Forms_Arr_Rel_Insert_Input>;
}>;

export type AddNewWorkflowMutation = {
  __typename?: "mutation_root";
  insert_forms_one?: { __typename?: "forms"; id: any } | null;
};

export type EditWorkflowSubmissionMutationVariables = Exact<{
  params: ProcessWorkflowSubmissionInput;
}>;

export type EditWorkflowSubmissionMutation = {
  __typename?: "mutation_root";
  editWorkflowSubmission?: {
    __typename?: "EditWorkflowSubmissionOutput";
    message: string;
  } | null;
};

export type LoginMutationVariables = Exact<{
  params: LoginInput;
}>;

export type LoginMutation = {
  __typename?: "mutation_root";
  login?: {
    __typename?: "LoginOutput";
    partyId?: string | null;
    accessToken: string;
    message?: string | null;
    type?: string | null;
    requiresMFA: boolean;
    availableMFAMethods?: Array<string | null> | null;
  } | null;
};

export type MakeInvoicePaymentMutationVariables = Exact<{
  paymentData: MakeInvoicePaymentInput;
}>;

export type MakeInvoicePaymentMutation = {
  __typename?: "mutation_root";
  makeInvoicePayment?: {
    __typename?: "MakeInvoicePaymentOutput";
    message: string;
    subscriptionPaymentId: any;
  } | null;
};

export type MakeSubscriptionPaymentMutationVariables = Exact<{
  paymentData: MakeSubscriptionPaymentInput;
}>;

export type MakeSubscriptionPaymentMutation = {
  __typename?: "mutation_root";
  makeSubscriptionPayment?: {
    __typename?: "MakeSubscriptionPaymentOutput";
    message: string;
    subscriptionPaymentId: any;
  } | null;
};

export type MarkMessageAsReadMutationVariables = Exact<{
  params: MarkMessageAsReadInput;
}>;

export type MarkMessageAsReadMutation = {
  __typename?: "mutation_root";
  markMessageAsRead?: {
    __typename?: "MarkMessageAsReadOutput";
    message?: string | null;
  } | null;
};

export type RecordTransactionMutationVariables = Exact<{
  input: Array<RecordTransactionInput> | RecordTransactionInput;
}>;

export type RecordTransactionMutation = {
  __typename?: "mutation_root";
  record_transaction: {
    __typename?: "RecordTransactionOutput";
    affected_rows: number;
    returning?: {
      __typename?: "RecordTransactionOutputReturning";
      transaction_ids: Array<any>;
      transactions_map: Array<{
        __typename?: "TransactionMap";
        ledger_account_id: any;
        transaction_id: any;
      }>;
    } | null;
  };
};

export type RemoveInvoiceItemsMutationVariables = Exact<{
  params: RemoveInvoiceItemsInput;
}>;

export type RemoveInvoiceItemsMutation = {
  __typename?: "mutation_root";
  removeInvoiceItems?: {
    __typename?: "UpdateInvoiceOutput";
    message: string;
  } | null;
};

export type RescheduleSubscriptionInvoiceMutationVariables = Exact<{
  params: RescheduleInvoiceInput;
}>;

export type RescheduleSubscriptionInvoiceMutation = {
  __typename?: "mutation_root";
  rescheduleInvoice?: {
    __typename?: "RescheduleInvoiceOutput";
    message: string;
  } | null;
};

export type SendAdHocMessageMutationVariables = Exact<{
  params: SendAdHocMessageInput;
}>;

export type SendAdHocMessageMutation = {
  __typename?: "mutation_root";
  sendAdHocMessage?: {
    __typename?: "SendMessageOutput";
    message: string;
  } | null;
};

export type SendMessageMutationVariables = Exact<{
  params: SendMessageInput;
}>;

export type SendMessageMutation = {
  __typename?: "mutation_root";
  sendMessage?: { __typename?: "SendMessageOutput"; message: string } | null;
};

export type SignupMutationVariables = Exact<{
  params: SignupInput;
}>;

export type SignupMutation = {
  __typename?: "mutation_root";
  signup?: {
    __typename?: "SignupOutput";
    data?: string | null;
    message?: string | null;
    type?: string | null;
  } | null;
};

export type SubmitWorkflowMutationVariables = Exact<{
  params: ProcessWorkflowSubmissionInput;
}>;

export type SubmitWorkflowMutation = {
  __typename?: "mutation_root";
  processWorkflowSubmission?: {
    __typename?: "ProcessWorkflowSubmissionOutput";
    partyId: string;
  } | null;
};

export type UpdateAccountMutationVariables = Exact<{
  partyId: Scalars["uuid"];
  email: Scalars["String"];
  name: Scalars["String"];
  nickname?: InputMaybe<Scalars["String"]>;
  phone_number: Scalars["String"];
  avatar?: InputMaybe<Scalars["String"]>;
  location: Scalars["String"];
  locale?: InputMaybe<Scalars["String"]>;
}>;

export type UpdateAccountMutation = {
  __typename?: "mutation_root";
  update_parties_by_pk?: {
    __typename?: "parties";
    id: any;
    updated_at: any;
  } | null;
  update_users?: {
    __typename?: "users_mutation_response";
    affected_rows: number;
    returning: Array<{ __typename?: "users"; id: any; updated_at: any }>;
  } | null;
};

export type UpdateInvoiceMutationVariables = Exact<{
  params: UpdateInvoiceInput;
}>;

export type UpdateInvoiceMutation = {
  __typename?: "mutation_root";
  updateInvoice?: {
    __typename?: "UpdateInvoiceOutput";
    message: string;
  } | null;
};

export type UpdateCustomerManagementSettingMutationVariables = Exact<{
  settingId: Scalars["uuid"];
  newValue: Scalars["String"];
}>;

export type UpdateCustomerManagementSettingMutation = {
  __typename?: "mutation_root";
  update_organisation_settings_by_pk?: {
    __typename?: "organisation_settings";
    updated_at: any;
  } | null;
};

export type UpdateLoanProductInfoMutationVariables = Exact<{
  productId: Scalars["uuid"];
  productInfo: Products_Set_Input;
}>;

export type UpdateLoanProductInfoMutation = {
  __typename?: "mutation_root";
  update_products_by_pk?: { __typename?: "products"; updated_at: any } | null;
};

export type UpdateProductItemsMutationVariables = Exact<{
  productItemsData: Array<Product_Items_Updates> | Product_Items_Updates;
  productItemTariffData: Array<Tariffs_Updates> | Tariffs_Updates;
}>;

export type UpdateProductItemsMutation = {
  __typename?: "mutation_root";
  update_product_items_many?: Array<{
    __typename?: "product_items_mutation_response";
    affected_rows: number;
  } | null> | null;
  update_tariffs_many?: Array<{
    __typename?: "tariffs_mutation_response";
    affected_rows: number;
  } | null> | null;
};

export type UpdateProductSettingsMutationVariables = Exact<{
  productSettingsData:
    | Array<Product_Settings_Updates>
    | Product_Settings_Updates;
}>;

export type UpdateProductSettingsMutation = {
  __typename?: "mutation_root";
  update_product_settings_many?: Array<{
    __typename?: "product_settings_mutation_response";
    affected_rows: number;
  } | null> | null;
};

export type UpdateRulesAndConditionsMutationVariables = Exact<{
  rulesData: Array<Rules_Updates> | Rules_Updates;
  conditionsData: Array<Conditions_Updates> | Conditions_Updates;
}>;

export type UpdateRulesAndConditionsMutation = {
  __typename?: "mutation_root";
  update_rules_many?: Array<{
    __typename?: "rules_mutation_response";
    affected_rows: number;
  } | null> | null;
  update_conditions_many?: Array<{
    __typename?: "conditions_mutation_response";
    affected_rows: number;
  } | null> | null;
};

export type GetActivityStreamQueryVariables = Exact<{
  party?: InputMaybe<Scalars["jsonb"]>;
}>;

export type GetActivityStreamQuery = {
  __typename?: "query_root";
  activity_logs: Array<{
    __typename?: "activity_logs";
    actor: any;
    created_at?: any | null;
    event_timestamp?: any | null;
    id: any;
    object: any;
    render_template?: string | null;
    target: any;
    verb: string;
  }>;
};

export type GetCaseDocumentsQueryVariables = Exact<{
  caseId: Scalars["uuid"];
}>;

export type GetCaseDocumentsQuery = {
  __typename?: "query_root";
  case_documents: Array<{
    __typename?: "case_documents";
    id: any;
    created_at?: any | null;
    case_id: any;
    document: {
      __typename?: "documents";
      id: any;
      name?: string | null;
      path: string;
      size: any;
      type_id: any;
      created_at: any;
      party?: { __typename?: "parties"; id: any; name: string } | null;
      type: { __typename?: "types"; id: any; name: string; value: string };
    };
  }>;
};

export type GetChannelPartiesQueryVariables = Exact<{
  channelId: Scalars["uuid"];
}>;

export type GetChannelPartiesQuery = {
  __typename?: "query_root";
  channel_parties: Array<{
    __typename?: "channel_parties";
    id: any;
    party: {
      __typename?: "parties";
      id: any;
      name: string;
      nickname?: string | null;
      avatar?: string | null;
      email: string;
      location?: string | null;
      party_number?: string | null;
      party_number_schema?: string | null;
      locale?: string | null;
      type_id: any;
      form_id?: any | null;
      metadata?: any | null;
      created_at: any;
      phoneNumber: string;
    };
  }>;
};

export type GetChannelsQueryVariables = Exact<{
  where?: InputMaybe<Channels_Bool_Exp>;
}>;

export type GetChannelsQuery = {
  __typename?: "query_root";
  channels: Array<{
    __typename?: "channels";
    id: any;
    name: string;
    channel_parties: Array<{
      __typename?: "channel_parties";
      id: any;
      party: {
        __typename?: "parties";
        id: any;
        name: string;
        avatar?: string | null;
      };
    }>;
    messages: Array<{
      __typename?: "messages";
      id: any;
      body: string;
      created_at: any;
      message_delivery_methods: Array<{
        __typename?: "message_delivery_methods";
        delivery_method: {
          __typename?: "delivery_methods";
          id: any;
          name: string;
        };
      }>;
    }>;
  }>;
};

export type GetLastReadMessagesQueryVariables = Exact<{
  params: GetLastReadMessagesInput;
}>;

export type GetLastReadMessagesQuery = {
  __typename?: "query_root";
  getLastReadMessages?: {
    __typename?: "GetLastReadMessagesOutput";
    lastReadMessages?: Array<any | null> | null;
  } | null;
};

export type GetCustomerManagementSettingsQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetCustomerManagementSettingsQuery = {
  __typename?: "query_root";
  organisation_settings: Array<{
    __typename?: "organisation_settings";
    id: any;
    name: string;
    value?: string | null;
  }>;
};

export type GetDocumentNotesQueryVariables = Exact<{
  documentId: Scalars["uuid"];
}>;

export type GetDocumentNotesQuery = {
  __typename?: "query_root";
  document_interactions: Array<{
    __typename?: "document_interactions";
    id: any;
    created_at?: any | null;
    interaction: {
      __typename?: "interactions";
      id: any;
      created_at?: any | null;
      party: {
        __typename?: "parties";
        id: any;
        avatar?: string | null;
        name: string;
        nickname?: string | null;
      };
      interaction_data: Array<{
        __typename?: "interaction_details";
        id: any;
        value?: string | null;
        form_field: {
          __typename?: "form_fields";
          id: any;
          field_name?: string | null;
        };
      }>;
    };
  }>;
};

export type GetDoDGrowthQueryVariables = Exact<{
  fromDate?: InputMaybe<Scalars["date"]>;
  toDate?: InputMaybe<Scalars["date"]>;
}>;

export type GetDoDGrowthQuery = {
  __typename?: "query_root";
  customer_daily_growth: Array<{
    __typename?: "_cus_growth_data";
    day?: any | null;
    previous_day_count?: any | null;
    current_day_count?: any | null;
    growth?: string | null;
  }>;
  new_cases_daily_growth: Array<{
    __typename?: "_case_dod_data";
    day?: any | null;
    previous_day_count?: any | null;
    current_day_count?: any | null;
    growth?: string | null;
  }>;
};

export type GetFormByIdQueryVariables = Exact<{
  id: Scalars["uuid"];
}>;

export type GetFormByIdQuery = {
  __typename?: "query_root";
  forms_by_pk?: {
    __typename?: "forms";
    id: any;
    name: string;
    description?: string | null;
    display_if?: any | null;
    workflow_type?: string | null;
    parent_form_id?: any | null;
    created_at: any;
    updated_at: any;
    form_fields: Array<{
      __typename?: "form_fields";
      id: any;
      field_name?: string | null;
      label: string;
      is_required: boolean;
      options?: any | null;
      units?: string | null;
      position: number;
      step?: string | null;
      min?: any | null;
      max?: any | null;
      default_value?: string | null;
      placeholder?: string | null;
      validation_regex?: string | null;
      display_if?: any | null;
      type_id: any;
    }>;
  } | null;
};

export type GetFormsByNameQueryVariables = Exact<{
  formNames: Array<Scalars["String"]> | Scalars["String"];
}>;

export type GetFormsByNameQuery = {
  __typename?: "query_root";
  forms: Array<{
    __typename?: "forms";
    id: any;
    name: string;
    description?: string | null;
    display_if?: any | null;
    workflow_type?: string | null;
    parent_form_id?: any | null;
    created_at: any;
    updated_at: any;
    form_fields: Array<{
      __typename?: "form_fields";
      id: any;
      field_name?: string | null;
      label: string;
      is_required: boolean;
      options?: any | null;
      units?: string | null;
      position: number;
      step?: string | null;
      min?: any | null;
      max?: any | null;
      default_value?: string | null;
      placeholder?: string | null;
      validation_regex?: string | null;
      display_if?: any | null;
      type_id: any;
    }>;
  }>;
};

export type GetInvoiceNotesQueryVariables = Exact<{
  invoiceId: Scalars["uuid"];
}>;

export type GetInvoiceNotesQuery = {
  __typename?: "query_root";
  invoice_interactions: Array<{
    __typename?: "invoice_interactions";
    id: any;
    created_at?: any | null;
    interaction: {
      __typename?: "interactions";
      id: any;
      created_at?: any | null;
      party: {
        __typename?: "parties";
        id: any;
        avatar?: string | null;
        name: string;
        nickname?: string | null;
      };
      interaction_data: Array<{
        __typename?: "interaction_details";
        id: any;
        value?: string | null;
        form_field: {
          __typename?: "form_fields";
          id: any;
          field_name?: string | null;
        };
      }>;
    };
  }>;
};

export type GetInvoicesQueryVariables = Exact<{
  where?: InputMaybe<Invoices_Bool_Exp>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
}>;

export type GetInvoicesQuery = {
  __typename?: "query_root";
  invoices: Array<{
    __typename?: "invoices";
    id: any;
    amount_due: any;
    due_at: any;
    created_at: any;
    updated_at: any;
    metadata?: any | null;
    subscription_id: any;
    invoice_items: Array<{
      __typename?: "invoice_items";
      id: any;
      quantity: any;
      amount: any;
      product_id: any;
      product_item: {
        __typename?: "product_items";
        id: any;
        name: string;
        product_id: any;
        tariff: { __typename?: "tariffs"; value: any };
      };
    }>;
    subscription_payments: Array<{
      __typename?: "subscription_payments";
      id: any;
      amount: any;
      payment_date: any;
      subscription_balance: any;
    }>;
    party: { __typename?: "parties"; id: any; name: string; type_id: any };
    product: { __typename?: "products"; id: any; name: string };
  }>;
};

export type GetLedgerAccountsSummaryQueryVariables = Exact<{
  ledgerAccountIds: Array<Scalars["uuid"]> | Scalars["uuid"];
}>;

export type GetLedgerAccountsSummaryQuery = {
  __typename?: "query_root";
  ledger_entries_by_ledger_account_ids: {
    __typename?: "TransactionLedgerEntryConnection";
    summary: {
      __typename?: "TransactionLedgerEntrySummary";
      total_debits: number;
      total_credits: number;
      transaction_count: number;
    };
  };
};

export type GetLedgerAccountTransactionsQueryVariables = Exact<{
  ledgerAccountId: Scalars["uuid"];
}>;

export type GetLedgerAccountTransactionsQuery = {
  __typename?: "query_root";
  ledger_entries_by_ledger_account_id: Array<{
    __typename?: "TransactionLedgerEntry";
    amount: number;
    description: string;
    ledger_entry_id: any;
    ledger_transaction_id: any;
    ledger_account_id: any;
    entry_type: string;
    external_reference: string;
    posted_at: any;
    effective_date: any;
    is_voided: boolean;
    status: { __typename?: "Status"; id: any; name: string };
    type: { __typename?: "Type"; id: any; name: string };
  }>;
};

export type GetLedgerAccountsTransactionsQueryVariables = Exact<{
  ledgerAccountIds: Array<Scalars["uuid"]> | Scalars["uuid"];
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
}>;

export type GetLedgerAccountsTransactionsQuery = {
  __typename?: "query_root";
  ledger_entries_by_ledger_account_ids: {
    __typename?: "TransactionLedgerEntryConnection";
    entries: Array<{
      __typename?: "TransactionLedgerEntry";
      amount: number;
      description: string;
      ledger_entry_id: any;
      ledger_transaction_id: any;
      ledger_account_id: any;
      entry_type: string;
      external_reference: string;
      posted_at: any;
      effective_date: any;
      is_voided: boolean;
      created_at: any;
      updated_at: any;
      status: { __typename?: "Status"; id: any; name: string };
      type: { __typename?: "Type"; id: any; name: string };
    }>;
    summary: {
      __typename?: "TransactionLedgerEntrySummary";
      total_debits: number;
      total_credits: number;
      transaction_count: number;
    };
    aggregate: { __typename?: "Aggergate"; totalCount: number };
    pageInfo: {
      __typename?: "PageInfo";
      endCursor: string;
      hasNextPage: boolean;
    };
  };
};

export type GetLedgerEntriesQueryVariables = Exact<{
  ledgerAccountIds?: InputMaybe<Array<Scalars["uuid"]> | Scalars["uuid"]>;
  where?: InputMaybe<Ledger_Entries_Bool_Exp>;
}>;

export type GetLedgerEntriesQuery = {
  __typename?: "query_root";
  ledger_entries_by_ledger_account_ids: {
    __typename?: "TransactionLedgerEntryConnection";
    entries: Array<{
      __typename?: "TransactionLedgerEntry";
      ledger_account_id: any;
      ledger_entry_id: any;
      amount: number;
      entry_type: string;
      description: string;
      effective_date: any;
      posted_at: any;
      created_at: any;
      external_reference: string;
      status: { __typename?: "Status"; id: any; name: string; value: string };
      subscription_items: Array<{
        __typename?: "subscription_items";
        id: any;
        product_item: { __typename?: "product_items"; id: any; name: string };
      }>;
    }>;
  };
};

export type GetLoanAccountQueryVariables = Exact<{
  id: Scalars["uuid"];
}>;

export type GetLoanAccountQuery = {
  __typename?: "query_root";
  loan_account?: {
    __typename?: "subscriptions";
    id: any;
    invoice_interval?: string | null;
    metadata?: any | null;
    subscription_start: any;
    subscription_end?: any | null;
    created_at: any;
    updated_at: any;
    product_id: any;
    subscription_items: Array<{
      __typename?: "subscription_items";
      id: any;
      is_voided: boolean;
      void_reason?: string | null;
      quantity: any;
      ledger_account_id?: any | null;
      product_item: {
        __typename?: "product_items";
        id: any;
        name: string;
        description: string;
        is_active: boolean;
        ledger_account_id?: any | null;
        product_id: any;
        metadata?: any | null;
        tariff: {
          __typename?: "tariffs";
          id: any;
          is_active: boolean;
          is_percentage: boolean;
          max_value?: any | null;
          min_value: any;
          name: string;
          of_total: boolean;
          of_field_value: boolean;
          metadata?: any | null;
          created_at: any;
          type: { __typename?: "types"; id: any; name: string; value: string };
        };
        type: { __typename?: "types"; id: any; name: string; value: string };
      };
    }>;
    subscription_schedules: Array<{
      __typename?: "subscription_schedules";
      id: any;
      due_date: any;
      beginning_balance: any;
      scheduled_payment: any;
      extra_payment: any;
      actual_payment: any;
      principal: any;
      interest: any;
      penalties: any;
      charges: any;
      ending_balance: any;
      total_expected_payment?: any | null;
      metadata?: any | null;
      created_at: any;
      updated_at: any;
      partyByLastUpdatedBy?: {
        __typename?: "parties";
        id: any;
        name: string;
      } | null;
    }>;
    subscription_payments: Array<{
      __typename?: "subscription_payments";
      id: any;
      amount: any;
      subscription_balance: any;
      payment_date: any;
      created_at: any;
      subscription_payment_transactions: Array<{
        __typename?: "subscription_payment_transactions";
        id: any;
        amount: any;
      }>;
    }>;
    subscription_rules: Array<{
      __typename?: "subscription_rules";
      id: any;
      rule: {
        __typename?: "rules";
        id: any;
        name: string;
        is_active: boolean;
        run_on: Array<string>;
        success_event?: any | null;
        metadata: any;
        created_at: any;
        updated_at: any;
        conditions: Array<{
          __typename?: "conditions";
          id: any;
          hierarchy_resolver?: string | null;
          fact: string;
          value: string;
          operator: string;
          params?: any | null;
          path: string;
          root_operator: string;
          priority: number;
          is_active: boolean;
        }>;
      };
    }>;
    subscription_statuses: Array<{
      __typename?: "subscription_statuses";
      id: any;
      created_at?: any | null;
      status: { __typename?: "statuses"; id: any; name: string; value: string };
    }>;
    penalty: Array<{
      __typename?: "subscription_items";
      id: any;
      invoice_items: Array<{
        __typename?: "invoice_items";
        id: any;
        quantity: any;
        amount: any;
      }>;
    }>;
    latest_invoice: Array<{
      __typename?: "invoices";
      id: any;
      amount_due: any;
      created_at: any;
      invoice_items: Array<{
        __typename?: "invoice_items";
        id: any;
        quantity: any;
        amount: any;
        created_at?: any | null;
        product_item: { __typename?: "product_items"; id: any; name: string };
      }>;
    }>;
    invoices: Array<{
      __typename?: "invoices";
      id: any;
      amount_due: any;
      due_at: any;
      created_at: any;
      status: { __typename?: "statuses"; id: any; name: string; value: string };
      invoice_items: Array<{
        __typename?: "invoice_items";
        id: any;
        quantity: any;
        amount: any;
        product_item: {
          __typename?: "product_items";
          name: string;
          type: { __typename?: "types"; value: string };
        };
      }>;
    }>;
    current_status: Array<{
      __typename?: "subscription_statuses";
      id: any;
      status: { __typename?: "statuses"; id: any; name: string; value: string };
    }>;
    disbursed: Array<{
      __typename?: "subscription_statuses";
      id: any;
      created_at?: any | null;
    }>;
    type: { __typename?: "types"; id: any; name: string; value: string };
    product: {
      __typename?: "products";
      id: any;
      name: string;
      ledger_id?: any | null;
      product_settings: Array<{
        __typename?: "product_settings";
        id: any;
        name: string;
        value?: string | null;
      }>;
    };
    party: { __typename?: "parties"; id: any; name: string; type_id: any };
  } | null;
};

export type GetLoanBookQueryVariables = Exact<{
  productId: Scalars["uuid"];
}>;

export type GetLoanBookQuery = {
  __typename?: "query_root";
  loan_book: Array<{
    __typename?: "subscriptions";
    id: any;
    created_at: any;
    due_date?: any | null;
    party: { __typename?: "parties"; id: any; name: string; type_id: any };
    principal: Array<{
      __typename?: "subscription_items";
      id: any;
      quantity: any;
      product_item: {
        __typename?: "product_items";
        id: any;
        type: { __typename?: "types"; id: any; name: string; value: string };
      };
    }>;
    interest: {
      __typename?: "subscription_schedules_aggregate";
      aggregate?: {
        __typename?: "subscription_schedules_aggregate_fields";
        sum?: {
          __typename?: "subscription_schedules_sum_fields";
          interest?: any | null;
        } | null;
      } | null;
    };
    penalty: Array<{
      __typename?: "subscription_items";
      id: any;
      invoice_items: Array<{
        __typename?: "invoice_items";
        id: any;
        quantity: any;
        amount: any;
      }>;
    }>;
    current_status: Array<{
      __typename?: "subscription_statuses";
      id: any;
      status: { __typename?: "statuses"; id: any; name: string; value: string };
    }>;
    disbursed: Array<{
      __typename?: "subscription_statuses";
      id: any;
      created_at?: any | null;
    }>;
    subscription_payments: Array<{
      __typename?: "subscription_payments";
      id: any;
      subscription_balance: any;
    }>;
    amount_received: {
      __typename?: "subscription_payments_aggregate";
      aggregate?: {
        __typename?: "subscription_payments_aggregate_fields";
        sum?: {
          __typename?: "subscription_payments_sum_fields";
          amount?: any | null;
        } | null;
      } | null;
    };
    loan_items: Array<{
      __typename?: "subscription_items";
      id: any;
      is_voided: boolean;
      void_reason?: string | null;
      quantity: any;
      ledger_account_id?: any | null;
      product_item: {
        __typename?: "product_items";
        id: any;
        name: string;
        description: string;
        is_active: boolean;
        ledger_account_id?: any | null;
        product_id: any;
        metadata?: any | null;
        tariff: {
          __typename?: "tariffs";
          id: any;
          is_active: boolean;
          is_percentage: boolean;
          max_value?: any | null;
          min_value: any;
          name: string;
          of_total: boolean;
          of_field_value: boolean;
          metadata?: any | null;
          created_at: any;
          type: { __typename?: "types"; id: any; name: string; value: string };
        };
        type: { __typename?: "types"; id: any; name: string; value: string };
      };
    }>;
  }>;
};

export type GetLoanProductByIdQueryVariables = Exact<{
  id: Scalars["uuid"];
}>;

export type GetLoanProductByIdQuery = {
  __typename?: "query_root";
  loan_product?: {
    __typename?: "products";
    id: any;
    name: string;
    description: string;
    created_at: any;
    updated_at: any;
    product_rules: Array<{
      __typename?: "product_rules";
      id: any;
      rule: {
        __typename?: "rules";
        id: any;
        name: string;
        is_active: boolean;
        run_on: Array<string>;
        success_event?: any | null;
        metadata: any;
        created_at: any;
        updated_at: any;
        conditions: Array<{
          __typename?: "conditions";
          id: any;
          hierarchy_resolver?: string | null;
          fact: string;
          value: string;
          operator: string;
          params?: any | null;
          path: string;
          root_operator: string;
          priority: number;
          is_active: boolean;
        }>;
      };
    }>;
    subscriptions: Array<{
      __typename?: "subscriptions";
      id: any;
      created_at: any;
      party: { __typename?: "parties"; id: any; name: string; type_id: any };
      subscription_tasks: Array<{
        __typename?: "subscription_tasks";
        id: any;
        task_id: any;
      }>;
      subscription_statuses: Array<{
        __typename?: "subscription_statuses";
        id: any;
        status_id: any;
      }>;
    }>;
    type: { __typename?: "types"; id: any; name: string; value: string };
    sub_type?: {
      __typename?: "types";
      id: any;
      name: string;
      value: string;
    } | null;
    product_items: Array<{
      __typename?: "product_items";
      id: any;
      name: string;
      description: string;
      is_active: boolean;
      ledger_account_id?: any | null;
      metadata?: any | null;
      created_at: any;
      updated_at: any;
      tariff: {
        __typename?: "tariffs";
        id: any;
        is_active: boolean;
        is_percentage: boolean;
        value: any;
        max_value?: any | null;
        min_value: any;
        name: string;
        of_total: boolean;
        of_field_value: boolean;
        metadata?: any | null;
        created_at: any;
        type: { __typename?: "types"; id: any; name: string; value: string };
      };
      type: { __typename?: "types"; id: any; name: string; value: string };
      sub_type?: {
        __typename?: "types";
        id: any;
        name: string;
        value: string;
      } | null;
    }>;
    product_settings: Array<{
      __typename?: "product_settings";
      id: any;
      name: string;
      value?: string | null;
      type: { __typename?: "types"; id: any; name: string };
    }>;
    product_statuses: Array<{
      __typename?: "product_statuses";
      id: any;
      status: { __typename?: "statuses"; id: any; name: string; value: string };
    }>;
  } | null;
  loanProductSummary?: {
    __typename?: "LoanProductSummaryOutput";
    disbursedCount?: number | null;
    disbursedAmount?: number | null;
    currentLoans?: number | null;
    amountInDefault?: number | null;
    floatBalance?: number | null;
  } | null;
};

export type GetLoanProductForEditQueryVariables = Exact<{
  id: Scalars["uuid"];
}>;

export type GetLoanProductForEditQuery = {
  __typename?: "query_root";
  loan_product?: {
    __typename?: "products";
    id: any;
    name: string;
    description: string;
    created_at: any;
    updated_at: any;
    type: { __typename?: "types"; id: any; name: string; value: string };
    sub_type?: {
      __typename?: "types";
      id: any;
      name: string;
      value: string;
    } | null;
    product_items: Array<{
      __typename?: "product_items";
      id: any;
      name: string;
      description: string;
      is_active: boolean;
      ledger_account_id?: any | null;
      metadata?: any | null;
      created_at: any;
      updated_at: any;
      tariff: {
        __typename?: "tariffs";
        id: any;
        is_active: boolean;
        is_percentage: boolean;
        value: any;
        max_value?: any | null;
        min_value: any;
        name: string;
        of_total: boolean;
        of_field_value: boolean;
        metadata?: any | null;
        created_at: any;
        type: { __typename?: "types"; id: any; name: string; value: string };
      };
      type: { __typename?: "types"; id: any; name: string; value: string };
      sub_type?: {
        __typename?: "types";
        id: any;
        name: string;
        value: string;
      } | null;
    }>;
    product_settings: Array<{
      __typename?: "product_settings";
      id: any;
      name: string;
      value?: string | null;
      type: { __typename?: "types"; id: any; name: string };
    }>;
    product_rules: Array<{
      __typename?: "product_rules";
      id: any;
      rule: {
        __typename?: "rules";
        id: any;
        name: string;
        is_active: boolean;
        run_on: Array<string>;
        success_event?: any | null;
        metadata: any;
        created_at: any;
        updated_at: any;
        conditions: Array<{
          __typename?: "conditions";
          id: any;
          hierarchy_resolver?: string | null;
          fact: string;
          value: string;
          operator: string;
          params?: any | null;
          path: string;
          root_operator: string;
          priority: number;
          is_active: boolean;
        }>;
      };
    }>;
  } | null;
};

export type GetLoanProductOverviewQueryVariables = Exact<{
  id: Scalars["uuid"];
  limit?: InputMaybe<Scalars["Int"]>;
}>;

export type GetLoanProductOverviewQuery = {
  __typename?: "query_root";
  requested: Array<{
    __typename?: "subscription_items";
    id: any;
    quantity: any;
    created_at: any;
    subscription: {
      __typename?: "subscriptions";
      id: any;
      party: { __typename?: "parties"; id: any; name: string };
    };
  }>;
  disbursed: Array<{
    __typename?: "subscription_items";
    id: any;
    quantity: any;
    created_at: any;
    subscription: {
      __typename?: "subscriptions";
      id: any;
      party: { __typename?: "parties"; id: any; name: string };
    };
  }>;
  defaulted: Array<{
    __typename?: "subscription_statuses";
    id: any;
    created_at?: any | null;
    subscription: {
      __typename?: "subscriptions";
      id: any;
      party: { __typename?: "parties"; id: any; name: string };
      subscription_payments: Array<{
        __typename?: "subscription_payments";
        id: any;
        subscription_balance: any;
      }>;
      invoices: Array<{ __typename?: "invoices"; id: any; amount_due: any }>;
    };
  }>;
  subscription_payment_transactions: Array<{
    __typename?: "subscription_payment_transactions";
    id: any;
    amount: any;
    created_at?: any | null;
    subscription: {
      __typename?: "subscriptions";
      id: any;
      party: { __typename?: "parties"; id: any; name: string };
    };
  }>;
};

export type GetLoanProductsQueryVariables = Exact<{ [key: string]: never }>;

export type GetLoanProductsQuery = {
  __typename?: "query_root";
  products: Array<{
    __typename?: "products";
    id: any;
    name: string;
    description: string;
    created_at: any;
    updated_at: any;
    type: { __typename?: "types"; id: any; name: string; value: string };
    sub_type?: {
      __typename?: "types";
      id: any;
      name: string;
      value: string;
    } | null;
    product_items: Array<{
      __typename?: "product_items";
      id: any;
      name: string;
      description: string;
      is_active: boolean;
      ledger_account_id?: any | null;
      metadata?: any | null;
      created_at: any;
      updated_at: any;
      tariff: {
        __typename?: "tariffs";
        id: any;
        is_active: boolean;
        is_percentage: boolean;
        value: any;
        max_value?: any | null;
        min_value: any;
        name: string;
        of_total: boolean;
        of_field_value: boolean;
        metadata?: any | null;
        created_at: any;
        type: { __typename?: "types"; id: any; name: string; value: string };
      };
      type: { __typename?: "types"; id: any; name: string; value: string };
      sub_type?: {
        __typename?: "types";
        id: any;
        name: string;
        value: string;
      } | null;
    }>;
    product_settings: Array<{
      __typename?: "product_settings";
      id: any;
      name: string;
      value?: string | null;
      type: { __typename?: "types"; id: any; name: string };
    }>;
    product_statuses: Array<{
      __typename?: "product_statuses";
      id: any;
      status: { __typename?: "statuses"; id: any; name: string; value: string };
    }>;
    active_subscriptions?: {
      __typename?: "active_product_subscriptions";
      count?: any | null;
    } | null;
    mom_growth_report: {
      __typename?: "MoMGrowthReport";
      report_name: string;
      report_data: Array<{
        __typename?: "MoMData";
        month: string;
        previous_month_value: number;
        current_month_value: number;
        percentage_growth: string;
      }>;
    };
  }>;
};

export type GetLoanRequestListQueryVariables = Exact<{
  productId: Scalars["uuid"];
}>;

export type GetLoanRequestListQuery = {
  __typename?: "query_root";
  approval_list: Array<{
    __typename?: "subscription_statuses";
    id: any;
    subscription: {
      __typename?: "subscriptions";
      id: any;
      party: { __typename?: "parties"; name: string };
      current_status: Array<{
        __typename?: "subscription_statuses";
        id: any;
        status: {
          __typename?: "statuses";
          id: any;
          name: string;
          value: string;
        };
      }>;
      subscription_statuses: Array<{
        __typename?: "subscription_statuses";
        id: any;
        status: {
          __typename?: "statuses";
          id: any;
          name: string;
          value: string;
        };
      }>;
      approval_request: Array<{
        __typename?: "subscription_tasks";
        id: any;
        task: {
          __typename?: "tasks";
          id: any;
          title: string;
          description: string;
          type_id: any;
          created_at: any;
          task_statuses: Array<{
            __typename?: "task_statuses";
            id: any;
            status: {
              __typename?: "statuses";
              id: any;
              name: string;
              value: string;
            };
          }>;
          approver: { __typename?: "parties"; id: any; name: string };
          assignee: { __typename?: "parties"; id: any; name: string };
        };
      }>;
    };
  }>;
};

export type GetMeQueryVariables = Exact<{ [key: string]: never }>;

export type GetMeQuery = {
  __typename?: "query_root";
  me?: {
    __typename?: "MeOutput";
    id: any;
    avatar?: string | null;
    name?: string | null;
    nickname?: string | null;
    email?: string | null;
    phone_number?: string | null;
    locale?: string | null;
    location?: string | null;
  } | null;
};

export type GetOnboardingWorkflowsQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetOnboardingWorkflowsQuery = {
  __typename?: "query_root";
  forms: Array<{
    __typename?: "forms";
    id: any;
    name: string;
    description?: string | null;
    display_if?: any | null;
    workflow_type?: string | null;
    parent_form_id?: any | null;
    created_at: any;
    updated_at: any;
    childForms: Array<{
      __typename?: "forms";
      id: any;
      name: string;
      description?: string | null;
      display_if?: any | null;
      workflow_type?: string | null;
      parent_form_id?: any | null;
      created_at: any;
      updated_at: any;
      form_fields: Array<{
        __typename?: "form_fields";
        id: any;
        field_name?: string | null;
        label: string;
        is_required: boolean;
        options?: any | null;
        units?: string | null;
        position: number;
        step?: string | null;
        min?: any | null;
        max?: any | null;
        default_value?: string | null;
        placeholder?: string | null;
        validation_regex?: string | null;
        display_if?: any | null;
        type_id: any;
      }>;
    }>;
    form_fields: Array<{
      __typename?: "form_fields";
      id: any;
      field_name?: string | null;
      label: string;
      is_required: boolean;
      options?: any | null;
      units?: string | null;
      position: number;
      step?: string | null;
      min?: any | null;
      max?: any | null;
      default_value?: string | null;
      placeholder?: string | null;
      validation_regex?: string | null;
      display_if?: any | null;
      type_id: any;
    }>;
  }>;
};

export type GetOrgActivityStreamQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetOrgActivityStreamQuery = {
  __typename?: "query_root";
  activity_logs: Array<{
    __typename?: "activity_logs";
    actor: any;
    created_at?: any | null;
    event_timestamp?: any | null;
    id: any;
    object: any;
    render_template?: string | null;
    target: any;
    verb: string;
  }>;
};

export type GetOrganisationProductItemsQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetOrganisationProductItemsQuery = {
  __typename?: "query_root";
  product_items: Array<{
    __typename?: "product_items";
    id: any;
    name: string;
    product: { __typename?: "products"; id: any; name: string; type_id: any };
  }>;
};

export type GetOrganisationProductsQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetOrganisationProductsQuery = {
  __typename?: "query_root";
  products: Array<{ __typename?: "products"; id: any; name: string }>;
};

export type GetAllOrganisationSettingsQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetAllOrganisationSettingsQuery = {
  __typename?: "query_root";
  organisation_settings: Array<{
    __typename?: "organisation_settings";
    id: any;
    name: string;
    value?: string | null;
  }>;
};

export type GetPartiesQueryVariables = Exact<{ [key: string]: never }>;

export type GetPartiesQuery = {
  __typename?: "query_root";
  parties: Array<{
    __typename?: "parties";
    id: any;
    name: string;
    nickname?: string | null;
    avatar?: string | null;
    email: string;
    location?: string | null;
    party_number?: string | null;
    party_number_schema?: string | null;
    locale?: string | null;
    type_id: any;
    form_id?: any | null;
    metadata?: any | null;
    created_at: any;
    phoneNumber: string;
    party_statuses: Array<{
      __typename?: "party_statuses";
      status: { __typename?: "statuses"; name: string; value: string };
    }>;
    party_contacts: Array<{
      __typename?: "party_contacts";
      contact: {
        __typename?: "contacts";
        name: string;
        contact_details: Array<{
          __typename?: "contact_details";
          value?: string | null;
          form_field: {
            __typename?: "form_fields";
            field_name?: string | null;
          };
        }>;
      };
    }>;
  }>;
};

export type GetPartiesByTypeQueryVariables = Exact<{
  typeId: Scalars["uuid"];
}>;

export type GetPartiesByTypeQuery = {
  __typename?: "query_root";
  parties: Array<{
    __typename?: "parties";
    created_at: any;
    id: any;
    name: string;
    nickname?: string | null;
    avatar?: string | null;
    email: string;
    location?: string | null;
    party_number?: string | null;
    party_number_schema?: string | null;
    locale?: string | null;
    type_id: any;
    form_id?: any | null;
    metadata?: any | null;
    phoneNumber: string;
    party_contacts: Array<{
      __typename?: "party_contacts";
      contact: {
        __typename?: "contacts";
        id: any;
        name: string;
        contact_details: Array<{
          __typename?: "contact_details";
          id: any;
          value?: string | null;
          form_field: {
            __typename?: "form_fields";
            id: any;
            field_name?: string | null;
          };
        }>;
      };
    }>;
    party_statuses: Array<{
      __typename?: "party_statuses";
      status: { __typename?: "statuses"; name: string; value: string };
    }>;
  }>;
};

export type GetPartiesInPeriodByTypeQueryVariables = Exact<{
  typeIds?: InputMaybe<Array<Scalars["uuid"]> | Scalars["uuid"]>;
  fromDate?: InputMaybe<Scalars["timestamptz"]>;
  toDate?: InputMaybe<Scalars["timestamptz"]>;
}>;

export type GetPartiesInPeriodByTypeQuery = {
  __typename?: "query_root";
  parties: Array<{
    __typename?: "parties";
    created_at: any;
    id: any;
    name: string;
    nickname?: string | null;
    avatar?: string | null;
    email: string;
    location?: string | null;
    party_number?: string | null;
    party_number_schema?: string | null;
    locale?: string | null;
    type_id: any;
    form_id?: any | null;
    metadata?: any | null;
    phoneNumber: string;
    party_contacts: Array<{
      __typename?: "party_contacts";
      contact: {
        __typename?: "contacts";
        id: any;
        name: string;
        contact_details: Array<{
          __typename?: "contact_details";
          id: any;
          value?: string | null;
          form_field: {
            __typename?: "form_fields";
            id: any;
            field_name?: string | null;
          };
        }>;
      };
    }>;
    party_statuses: Array<{
      __typename?: "party_statuses";
      status: { __typename?: "statuses"; name: string; value: string };
    }>;
  }>;
};

export type GetPartyByIdQueryVariables = Exact<{
  id: Scalars["uuid"];
}>;

export type GetPartyByIdQuery = {
  __typename?: "query_root";
  party?: {
    __typename?: "parties";
    id: any;
    name: string;
    nickname?: string | null;
    avatar?: string | null;
    email: string;
    location?: string | null;
    party_number?: string | null;
    party_number_schema?: string | null;
    locale?: string | null;
    type_id: any;
    form_id?: any | null;
    metadata?: any | null;
    created_at: any;
    phoneNumber: string;
    party_contacts: Array<{
      __typename?: "party_contacts";
      contact: {
        __typename?: "contacts";
        name: string;
        contact_details: Array<{
          __typename?: "contact_details";
          value?: string | null;
          form_field: {
            __typename?: "form_fields";
            field_name?: string | null;
          };
        }>;
      };
    }>;
    subscriptions: Array<{
      __typename?: "subscriptions";
      id: any;
      subscription_items: Array<{
        __typename?: "subscription_items";
        id: any;
        ledger_account_id?: any | null;
      }>;
    }>;
    party_statuses: Array<{
      __typename?: "party_statuses";
      status: { __typename?: "statuses"; name: string; value: string };
    }>;
  } | null;
};

export type GetPartyCountsByTypeQueryVariables = Exact<{
  typeIds?: InputMaybe<Array<Scalars["uuid"]> | Scalars["uuid"]>;
}>;

export type GetPartyCountsByTypeQuery = {
  __typename?: "query_root";
  types: Array<{
    __typename?: "types";
    id: any;
    name: string;
    value: string;
    parties_aggregate: {
      __typename?: "parties_aggregate";
      aggregate?: {
        __typename?: "parties_aggregate_fields";
        count: number;
      } | null;
    };
  }>;
};

export type GetPartyDocumentsQueryVariables = Exact<{
  partyId: Scalars["uuid"];
}>;

export type GetPartyDocumentsQuery = {
  __typename?: "query_root";
  party_documents: Array<{
    __typename?: "party_documents";
    id: any;
    created_at?: any | null;
    party_id: any;
    document: {
      __typename?: "documents";
      id: any;
      name?: string | null;
      path: string;
      size: any;
      type_id: any;
      created_at: any;
      party?: { __typename?: "parties"; id: any; name: string } | null;
      type: { __typename?: "types"; id: any; name: string; value: string };
    };
  }>;
};

export type GetEligbilePartiesLongListQueryVariables = Exact<{
  customerTypeIds: Array<Scalars["uuid"]> | Scalars["uuid"];
  productId: Scalars["uuid"];
  eligibleProductItemIds: Array<Scalars["uuid"]> | Scalars["uuid"];
}>;

export type GetEligbilePartiesLongListQuery = {
  __typename?: "query_root";
  parties: Array<{
    __typename?: "parties";
    id: any;
    name: string;
    created_at: any;
    eligible_subscriptions: Array<{
      __typename?: "subscriptions";
      id: any;
      subscription_items: Array<{
        __typename?: "subscription_items";
        id: any;
        transaction_summary: {
          __typename?: "TransactionLedgerEntryConnection";
          summary: {
            __typename?: "TransactionLedgerEntrySummary";
            total_credits: number;
            total_debits: number;
          };
        };
      }>;
    }>;
    last_loan: Array<{
      __typename?: "invoices";
      id: any;
      created_at: any;
      invoice_items: Array<{
        __typename?: "invoice_items";
        id: any;
        quantity: any;
        amount: any;
        product_item: {
          __typename?: "product_items";
          name: string;
          type: { __typename?: "types"; value: string };
        };
      }>;
      subscription_payments: Array<{
        __typename?: "subscription_payments";
        id: any;
        amount: any;
      }>;
      subscription: { __typename?: "subscriptions"; subscription_start: any };
    }>;
    active_loans: Array<{
      __typename?: "subscriptions";
      id: any;
      subscription_statuses: Array<{
        __typename?: "subscription_statuses";
        id: any;
        created_at?: any | null;
        status: { __typename?: "statuses"; id: any; name: string };
      }>;
    }>;
  }>;
};

export type GetPartyNotesQueryVariables = Exact<{
  partyId: Scalars["uuid"];
}>;

export type GetPartyNotesQuery = {
  __typename?: "query_root";
  party_interactions: Array<{
    __typename?: "party_interactions";
    id: any;
    created_at?: any | null;
    party_id: any;
    interaction: {
      __typename?: "interactions";
      id: any;
      created_at?: any | null;
      party: {
        __typename?: "parties";
        id: any;
        avatar?: string | null;
        name: string;
        nickname?: string | null;
      };
      interaction_data: Array<{
        __typename?: "interaction_details";
        id: any;
        value?: string | null;
        form_field: {
          __typename?: "form_fields";
          id: any;
          field_name?: string | null;
        };
      }>;
    };
  }>;
};

export type GetPartyOpenCasesQueryVariables = Exact<{
  partyId: Scalars["uuid"];
}>;

export type GetPartyOpenCasesQuery = {
  __typename?: "query_root";
  cases: Array<{
    __typename?: "cases";
    id: any;
    created_at: any;
    updated_at: any;
    title: string;
    case_interactions: Array<{
      __typename?: "case_interactions";
      id: any;
      created_at?: any | null;
      interaction: {
        __typename?: "interactions";
        id: any;
        interaction_by: { __typename?: "parties"; id: any; name: string };
        type: { __typename?: "types"; id: any; name: string; value: string };
      };
    }>;
    type: { __typename?: "types"; id: any; name: string; value: string };
    case_statuses: Array<{
      __typename?: "case_statuses";
      id: any;
      created_at?: any | null;
      status: { __typename?: "statuses"; id: any; name: string; value: string };
    }>;
    assigned_party: {
      __typename?: "parties";
      avatar?: string | null;
      id: any;
      name: string;
      nickname?: string | null;
    };
  }>;
};

export type GetPartySubscriptionsQueryVariables = Exact<{
  partyId: Scalars["uuid"];
}>;

export type GetPartySubscriptionsQuery = {
  __typename?: "query_root";
  subscriptions: Array<{
    __typename?: "subscriptions";
    id: any;
    invoice_interval?: string | null;
    created_at: any;
    updated_at: any;
    metadata?: any | null;
    subscription_start: any;
    subscription_end?: any | null;
    product_id: any;
    subscription_payments: Array<{
      __typename?: "subscription_payments";
      id: any;
      subscription_balance: any;
      created_at: any;
    }>;
    invoices: Array<{
      __typename?: "invoices";
      id: any;
      amount_due: any;
      created_at: any;
      updated_at: any;
    }>;
    subscription_items: Array<{
      __typename?: "subscription_items";
      id: any;
      is_voided: boolean;
      void_reason?: string | null;
      quantity: any;
      ledger_account_id?: any | null;
      product_item: {
        __typename?: "product_items";
        id: any;
        name: string;
        description: string;
        is_active: boolean;
        ledger_account_id?: any | null;
        product_id: any;
        metadata?: any | null;
        tariff: {
          __typename?: "tariffs";
          id: any;
          is_active: boolean;
          is_percentage: boolean;
          max_value?: any | null;
          min_value: any;
          name: string;
          of_total: boolean;
          of_field_value: boolean;
          metadata?: any | null;
          created_at: any;
          type: { __typename?: "types"; id: any; name: string; value: string };
        };
        type: { __typename?: "types"; id: any; name: string; value: string };
      };
    }>;
    subscription_rules: Array<{
      __typename?: "subscription_rules";
      id: any;
      rule: {
        __typename?: "rules";
        id: any;
        name: string;
        is_active: boolean;
        run_on: Array<string>;
        success_event?: any | null;
        metadata: any;
        created_at: any;
        updated_at: any;
        conditions: Array<{
          __typename?: "conditions";
          id: any;
          hierarchy_resolver?: string | null;
          fact: string;
          value: string;
          operator: string;
          params?: any | null;
          path: string;
          root_operator: string;
          priority: number;
          is_active: boolean;
        }>;
      };
    }>;
    subscription_details: Array<{
      __typename?: "subscription_details";
      id: any;
      value: string;
      form_field: {
        __typename?: "form_fields";
        id: any;
        field_name?: string | null;
      };
    }>;
    subscription_statuses: Array<{
      __typename?: "subscription_statuses";
      id: any;
      status: { __typename?: "statuses"; id: any; name: string; value: string };
    }>;
    type: { __typename?: "types"; id: any; name: string; value: string };
    product: {
      __typename?: "products";
      id: any;
      name: string;
      ledger_id?: any | null;
      product_settings: Array<{
        __typename?: "product_settings";
        id: any;
        name: string;
        value?: string | null;
      }>;
    };
    party: { __typename?: "parties"; id: any; name: string; type_id: any };
  }>;
};

export type GetProductByIdQueryVariables = Exact<{
  id: Scalars["uuid"];
}>;

export type GetProductByIdQuery = {
  __typename?: "query_root";
  product?: {
    __typename?: "products";
    id: any;
    name: string;
    description: string;
    created_at: any;
    updated_at: any;
    product_rules: Array<{
      __typename?: "product_rules";
      id: any;
      rule: {
        __typename?: "rules";
        id: any;
        name: string;
        is_active: boolean;
        run_on: Array<string>;
        success_event?: any | null;
        metadata: any;
        created_at: any;
        updated_at: any;
        conditions: Array<{
          __typename?: "conditions";
          id: any;
          hierarchy_resolver?: string | null;
          fact: string;
          value: string;
          operator: string;
          params?: any | null;
          path: string;
          root_operator: string;
          priority: number;
          is_active: boolean;
        }>;
      };
    }>;
    subscriptions: Array<{
      __typename?: "subscriptions";
      id: any;
      created_at: any;
      party: { __typename?: "parties"; id: any; name: string };
    }>;
    type: { __typename?: "types"; id: any; name: string; value: string };
    sub_type?: {
      __typename?: "types";
      id: any;
      name: string;
      value: string;
    } | null;
    product_items: Array<{
      __typename?: "product_items";
      id: any;
      name: string;
      description: string;
      is_active: boolean;
      ledger_account_id?: any | null;
      metadata?: any | null;
      created_at: any;
      updated_at: any;
      tariff: {
        __typename?: "tariffs";
        id: any;
        is_active: boolean;
        is_percentage: boolean;
        value: any;
        max_value?: any | null;
        min_value: any;
        name: string;
        of_total: boolean;
        of_field_value: boolean;
        metadata?: any | null;
        created_at: any;
        type: { __typename?: "types"; id: any; name: string; value: string };
      };
      type: { __typename?: "types"; id: any; name: string; value: string };
      sub_type?: {
        __typename?: "types";
        id: any;
        name: string;
        value: string;
      } | null;
    }>;
    product_settings: Array<{
      __typename?: "product_settings";
      id: any;
      name: string;
      value?: string | null;
      type: { __typename?: "types"; id: any; name: string };
    }>;
    product_statuses: Array<{
      __typename?: "product_statuses";
      id: any;
      status: { __typename?: "statuses"; id: any; name: string; value: string };
    }>;
    active_subscriptions?: {
      __typename?: "active_product_subscriptions";
      count?: any | null;
    } | null;
    mom_growth_report: {
      __typename?: "MoMGrowthReport";
      report_name: string;
      report_data: Array<{
        __typename?: "MoMData";
        month: string;
        previous_month_value: number;
        current_month_value: number;
        percentage_growth: string;
      }>;
    };
  } | null;
};

export type GetProductDocumentsQueryVariables = Exact<{
  productId: Scalars["uuid"];
}>;

export type GetProductDocumentsQuery = {
  __typename?: "query_root";
  product_documents: Array<{
    __typename?: "product_documents";
    id: any;
    created_at?: any | null;
    product_id: any;
    document: {
      __typename?: "documents";
      id: any;
      name?: string | null;
      path: string;
      size: any;
      type_id: any;
      created_at: any;
      party?: { __typename?: "parties"; id: any; name: string } | null;
      type: { __typename?: "types"; id: any; name: string; value: string };
    };
  }>;
};

export type GetProductNotesQueryVariables = Exact<{
  productId: Scalars["uuid"];
}>;

export type GetProductNotesQuery = {
  __typename?: "query_root";
  product_interactions: Array<{
    __typename?: "product_interactions";
    id: any;
    created_at?: any | null;
    product_id: any;
    interaction: {
      __typename?: "interactions";
      id: any;
      created_at?: any | null;
      party: {
        __typename?: "parties";
        id: any;
        avatar?: string | null;
        name: string;
        nickname?: string | null;
      };
      interaction_data: Array<{
        __typename?: "interaction_details";
        id: any;
        value?: string | null;
        form_field: {
          __typename?: "form_fields";
          id: any;
          field_name?: string | null;
        };
      }>;
    };
  }>;
};

export type GetProductItemsQueryVariables = Exact<{
  productItemIds: Array<Scalars["uuid"]> | Scalars["uuid"];
}>;

export type GetProductItemsQuery = {
  __typename?: "query_root";
  product_items: Array<{ __typename?: "product_items"; id: any; name: string }>;
};

export type GetProductSubscriptionsQueryVariables = Exact<{
  productId: Scalars["uuid"];
}>;

export type GetProductSubscriptionsQuery = {
  __typename?: "query_root";
  subscriptions: Array<{
    __typename?: "subscriptions";
    id: any;
    invoice_interval?: string | null;
    created_at: any;
    updated_at: any;
    metadata?: any | null;
    subscription_start: any;
    subscription_end?: any | null;
    product_id: any;
    subscription_items: Array<{
      __typename?: "subscription_items";
      id: any;
      is_voided: boolean;
      void_reason?: string | null;
      quantity: any;
      ledger_account_id?: any | null;
      product_item: {
        __typename?: "product_items";
        id: any;
        name: string;
        description: string;
        is_active: boolean;
        ledger_account_id?: any | null;
        product_id: any;
        metadata?: any | null;
        tariff: {
          __typename?: "tariffs";
          id: any;
          is_active: boolean;
          is_percentage: boolean;
          max_value?: any | null;
          min_value: any;
          name: string;
          of_total: boolean;
          of_field_value: boolean;
          metadata?: any | null;
          created_at: any;
          type: { __typename?: "types"; id: any; name: string; value: string };
        };
        type: { __typename?: "types"; id: any; name: string; value: string };
      };
    }>;
    subscription_rules: Array<{
      __typename?: "subscription_rules";
      id: any;
      rule: {
        __typename?: "rules";
        id: any;
        name: string;
        is_active: boolean;
        run_on: Array<string>;
        success_event?: any | null;
        metadata: any;
        created_at: any;
        updated_at: any;
        conditions: Array<{
          __typename?: "conditions";
          id: any;
          hierarchy_resolver?: string | null;
          fact: string;
          value: string;
          operator: string;
          params?: any | null;
          path: string;
          root_operator: string;
          priority: number;
          is_active: boolean;
        }>;
      };
    }>;
    subscription_details: Array<{
      __typename?: "subscription_details";
      id: any;
      value: string;
      form_field: {
        __typename?: "form_fields";
        id: any;
        field_name?: string | null;
      };
    }>;
    subscription_statuses: Array<{
      __typename?: "subscription_statuses";
      id: any;
      status: { __typename?: "statuses"; id: any; name: string; value: string };
    }>;
    invoices: Array<{
      __typename?: "invoices";
      id: any;
      created_at: any;
      updated_at: any;
    }>;
    type: { __typename?: "types"; id: any; name: string; value: string };
    product: {
      __typename?: "products";
      id: any;
      name: string;
      ledger_id?: any | null;
      product_settings: Array<{
        __typename?: "product_settings";
        id: any;
        name: string;
        value?: string | null;
      }>;
    };
    party: { __typename?: "parties"; id: any; name: string; type_id: any };
  }>;
};

export type GetProductsQueryVariables = Exact<{ [key: string]: never }>;

export type GetProductsQuery = {
  __typename?: "query_root";
  products: Array<{
    __typename?: "products";
    id: any;
    name: string;
    description: string;
    created_at: any;
    updated_at: any;
    type: { __typename?: "types"; id: any; name: string; value: string };
    sub_type?: {
      __typename?: "types";
      id: any;
      name: string;
      value: string;
    } | null;
    product_items: Array<{
      __typename?: "product_items";
      id: any;
      name: string;
      description: string;
      is_active: boolean;
      ledger_account_id?: any | null;
      metadata?: any | null;
      created_at: any;
      updated_at: any;
      tariff: {
        __typename?: "tariffs";
        id: any;
        is_active: boolean;
        is_percentage: boolean;
        value: any;
        max_value?: any | null;
        min_value: any;
        name: string;
        of_total: boolean;
        of_field_value: boolean;
        metadata?: any | null;
        created_at: any;
        type: { __typename?: "types"; id: any; name: string; value: string };
      };
      type: { __typename?: "types"; id: any; name: string; value: string };
      sub_type?: {
        __typename?: "types";
        id: any;
        name: string;
        value: string;
      } | null;
    }>;
    product_settings: Array<{
      __typename?: "product_settings";
      id: any;
      name: string;
      value?: string | null;
      type: { __typename?: "types"; id: any; name: string };
    }>;
    product_statuses: Array<{
      __typename?: "product_statuses";
      id: any;
      status: { __typename?: "statuses"; id: any; name: string; value: string };
    }>;
    active_subscriptions?: {
      __typename?: "active_product_subscriptions";
      count?: any | null;
    } | null;
    mom_growth_report: {
      __typename?: "MoMGrowthReport";
      report_name: string;
      report_data: Array<{
        __typename?: "MoMData";
        month: string;
        previous_month_value: number;
        current_month_value: number;
        percentage_growth: string;
      }>;
    };
  }>;
};

export type GetProductsSubscribersQueryVariables = Exact<{
  productIds: Array<Scalars["uuid"]> | Scalars["uuid"];
}>;

export type GetProductsSubscribersQuery = {
  __typename?: "query_root";
  subscriptions: Array<{
    __typename?: "subscriptions";
    id: any;
    party: { __typename?: "parties"; id: any; name: string; type_id: any };
    product: {
      __typename?: "products";
      id: any;
      name: string;
      ledger_id?: any | null;
    };
    subscription_items: Array<{
      __typename?: "subscription_items";
      id: any;
      is_voided: boolean;
      void_reason?: string | null;
      quantity: any;
      ledger_account_id?: any | null;
      product_item: {
        __typename?: "product_items";
        id: any;
        name: string;
        description: string;
        is_active: boolean;
        ledger_account_id?: any | null;
        product_id: any;
        metadata?: any | null;
        tariff: {
          __typename?: "tariffs";
          id: any;
          is_active: boolean;
          is_percentage: boolean;
          max_value?: any | null;
          min_value: any;
          name: string;
          of_total: boolean;
          of_field_value: boolean;
          metadata?: any | null;
          created_at: any;
          type: { __typename?: "types"; id: any; name: string; value: string };
        };
        type: { __typename?: "types"; id: any; name: string; value: string };
      };
    }>;
    subscription_payments: Array<{
      __typename?: "subscription_payments";
      id: any;
      subscription_balance: any;
      created_at: any;
    }>;
    invoices: Array<{
      __typename?: "invoices";
      id: any;
      amount_due: any;
      created_at: any;
    }>;
  }>;
};

export type GetReadMessagesQueryVariables = Exact<{
  params: GetReadMessagesInput;
}>;

export type GetReadMessagesQuery = {
  __typename?: "query_root";
  getReadMessages?: {
    __typename?: "GetReadMessagesOutput";
    hasReadMessage: string;
    lastReadMessage?: string | null;
  } | null;
};

export type GetSubscriptionBalancesQueryVariables = Exact<{
  subscriptionIds: Array<Scalars["uuid"]> | Scalars["uuid"];
}>;

export type GetSubscriptionBalancesQuery = {
  __typename?: "query_root";
  subscriptions: Array<{
    __typename?: "subscriptions";
    id: any;
    subscription_payments: Array<{
      __typename?: "subscription_payments";
      id: any;
      amount: any;
    }>;
    invoices: Array<{
      __typename?: "invoices";
      id: any;
      invoice_items: Array<{
        __typename?: "invoice_items";
        id: any;
        quantity: any;
        amount: any;
        created_at?: any | null;
        product_item: { __typename?: "product_items"; id: any; name: string };
      }>;
    }>;
  }>;
};

export type GetSubscriptionByIdQueryVariables = Exact<{
  id: Scalars["uuid"];
}>;

export type GetSubscriptionByIdQuery = {
  __typename?: "query_root";
  subscriptions_by_pk?: {
    __typename?: "subscriptions";
    id: any;
    invoice_interval?: string | null;
    created_at: any;
    updated_at: any;
    metadata?: any | null;
    subscription_start: any;
    subscription_end?: any | null;
    product_id: any;
    subscription_payment_transactions: Array<{
      __typename?: "subscription_payment_transactions";
      id: any;
      amount: any;
      created_at?: any | null;
      invoice?: { __typename?: "invoices"; id: any; due_at: any } | null;
      product_item: { __typename?: "product_items"; id: any; name: string };
    }>;
    subscription_items: Array<{
      __typename?: "subscription_items";
      id: any;
      is_voided: boolean;
      void_reason?: string | null;
      quantity: any;
      ledger_account_id?: any | null;
      product_item: {
        __typename?: "product_items";
        id: any;
        name: string;
        description: string;
        is_active: boolean;
        ledger_account_id?: any | null;
        product_id: any;
        metadata?: any | null;
        tariff: {
          __typename?: "tariffs";
          id: any;
          is_active: boolean;
          is_percentage: boolean;
          max_value?: any | null;
          min_value: any;
          name: string;
          of_total: boolean;
          of_field_value: boolean;
          metadata?: any | null;
          created_at: any;
          type: { __typename?: "types"; id: any; name: string; value: string };
        };
        type: { __typename?: "types"; id: any; name: string; value: string };
      };
    }>;
    subscription_rules: Array<{
      __typename?: "subscription_rules";
      id: any;
      rule: {
        __typename?: "rules";
        id: any;
        name: string;
        is_active: boolean;
        run_on: Array<string>;
        success_event?: any | null;
        metadata: any;
        created_at: any;
        updated_at: any;
        conditions: Array<{
          __typename?: "conditions";
          id: any;
          hierarchy_resolver?: string | null;
          fact: string;
          value: string;
          operator: string;
          params?: any | null;
          path: string;
          root_operator: string;
          priority: number;
          is_active: boolean;
        }>;
      };
    }>;
    subscription_details: Array<{
      __typename?: "subscription_details";
      id: any;
      value: string;
      form_field: {
        __typename?: "form_fields";
        id: any;
        field_name?: string | null;
      };
    }>;
    subscription_statuses: Array<{
      __typename?: "subscription_statuses";
      id: any;
      status: { __typename?: "statuses"; id: any; name: string; value: string };
    }>;
    invoices: Array<{
      __typename?: "invoices";
      id: any;
      created_at: any;
      updated_at: any;
    }>;
    type: { __typename?: "types"; id: any; name: string; value: string };
    product: {
      __typename?: "products";
      id: any;
      name: string;
      ledger_id?: any | null;
      product_settings: Array<{
        __typename?: "product_settings";
        id: any;
        name: string;
        value?: string | null;
      }>;
    };
    party: { __typename?: "parties"; id: any; name: string; type_id: any };
  } | null;
};

export type GetSubscriptionDocumentsQueryVariables = Exact<{
  subscriptionId: Scalars["uuid"];
}>;

export type GetSubscriptionDocumentsQuery = {
  __typename?: "query_root";
  subscription_documents: Array<{
    __typename?: "subscription_documents";
    id: any;
    created_at?: any | null;
    subscription_id: any;
    document: {
      __typename?: "documents";
      id: any;
      name?: string | null;
      path: string;
      size: any;
      type_id: any;
      created_at: any;
      party?: { __typename?: "parties"; id: any; name: string } | null;
      type: { __typename?: "types"; id: any; name: string; value: string };
    };
  }>;
};

export type GetSubscriptionNotesQueryVariables = Exact<{
  subscriptionId: Scalars["uuid"];
}>;

export type GetSubscriptionNotesQuery = {
  __typename?: "query_root";
  subscription_interactions: Array<{
    __typename?: "subscription_interactions";
    id: any;
    created_at?: any | null;
    subscription_id: any;
    interaction: {
      __typename?: "interactions";
      id: any;
      created_at?: any | null;
      party: {
        __typename?: "parties";
        id: any;
        avatar?: string | null;
        name: string;
        nickname?: string | null;
      };
      interaction_data: Array<{
        __typename?: "interaction_details";
        id: any;
        value?: string | null;
        form_field: {
          __typename?: "form_fields";
          id: any;
          field_name?: string | null;
        };
      }>;
    };
  }>;
};

export type GetSubscriptionLedgerAccountsQueryVariables = Exact<{
  id: Scalars["uuid"];
}>;

export type GetSubscriptionLedgerAccountsQuery = {
  __typename?: "query_root";
  subscriptions_by_pk?: {
    __typename?: "subscriptions";
    id: any;
    subscription_items: Array<{
      __typename?: "subscription_items";
      id: any;
      ledger_account_id?: any | null;
    }>;
  } | null;
};

export type GetSubscriptionsQueryVariables = Exact<{
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
}>;

export type GetSubscriptionsQuery = {
  __typename?: "query_root";
  subscriptions: Array<{
    __typename?: "subscriptions";
    id: any;
    invoice_interval?: string | null;
    created_at: any;
    updated_at: any;
    metadata?: any | null;
    subscription_start: any;
    subscription_end?: any | null;
    product_id: any;
    subscription_items: Array<{
      __typename?: "subscription_items";
      id: any;
      is_voided: boolean;
      void_reason?: string | null;
      quantity: any;
      ledger_account_id?: any | null;
      product_item: {
        __typename?: "product_items";
        id: any;
        name: string;
        description: string;
        is_active: boolean;
        ledger_account_id?: any | null;
        product_id: any;
        metadata?: any | null;
        tariff: {
          __typename?: "tariffs";
          id: any;
          is_active: boolean;
          is_percentage: boolean;
          max_value?: any | null;
          min_value: any;
          name: string;
          of_total: boolean;
          of_field_value: boolean;
          metadata?: any | null;
          created_at: any;
          type: { __typename?: "types"; id: any; name: string; value: string };
        };
        type: { __typename?: "types"; id: any; name: string; value: string };
      };
    }>;
    subscription_rules: Array<{
      __typename?: "subscription_rules";
      id: any;
      rule: {
        __typename?: "rules";
        id: any;
        name: string;
        is_active: boolean;
        run_on: Array<string>;
        success_event?: any | null;
        metadata: any;
        created_at: any;
        updated_at: any;
        conditions: Array<{
          __typename?: "conditions";
          id: any;
          hierarchy_resolver?: string | null;
          fact: string;
          value: string;
          operator: string;
          params?: any | null;
          path: string;
          root_operator: string;
          priority: number;
          is_active: boolean;
        }>;
      };
    }>;
    subscription_details: Array<{
      __typename?: "subscription_details";
      id: any;
      value: string;
      form_field: {
        __typename?: "form_fields";
        id: any;
        field_name?: string | null;
      };
    }>;
    subscription_statuses: Array<{
      __typename?: "subscription_statuses";
      id: any;
      status: { __typename?: "statuses"; id: any; name: string; value: string };
    }>;
    invoices: Array<{
      __typename?: "invoices";
      id: any;
      created_at: any;
      updated_at: any;
    }>;
    type: { __typename?: "types"; id: any; name: string; value: string };
    product: {
      __typename?: "products";
      id: any;
      name: string;
      ledger_id?: any | null;
      product_settings: Array<{
        __typename?: "product_settings";
        id: any;
        name: string;
        value?: string | null;
      }>;
    };
    party: { __typename?: "parties"; id: any; name: string; type_id: any };
  }>;
};

export type GetSubscriptionsLedgerAccountsQueryVariables = Exact<{
  ids: Array<Scalars["uuid"]> | Scalars["uuid"];
}>;

export type GetSubscriptionsLedgerAccountsQuery = {
  __typename?: "query_root";
  subscriptions: Array<{
    __typename?: "subscriptions";
    id: any;
    party: { __typename?: "parties"; id: any; name: string };
    subscription_items: Array<{
      __typename?: "subscription_items";
      id: any;
      ledger_account_id?: any | null;
    }>;
  }>;
};

export type GetTaskDocumentsQueryVariables = Exact<{
  taskId: Scalars["uuid"];
}>;

export type GetTaskDocumentsQuery = {
  __typename?: "query_root";
  task_documents: Array<{
    __typename?: "task_documents";
    id: any;
    created_at?: any | null;
    task_id: any;
    document: {
      __typename?: "documents";
      id: any;
      name?: string | null;
      path: string;
      size: any;
      type_id: any;
      created_at: any;
      party?: { __typename?: "parties"; id: any; name: string } | null;
      type: { __typename?: "types"; id: any; name: string; value: string };
    };
  }>;
};

export type GetTypesQueryVariables = Exact<{ [key: string]: never }>;

export type GetTypesQuery = {
  __typename?: "query_root";
  types: Array<{ __typename?: "types"; id: any; name: string; value: string }>;
  statuses: Array<{
    __typename?: "statuses";
    id: any;
    name: string;
    value: string;
  }>;
};

export type GetUserMfaMethodsQueryVariables = Exact<{ [key: string]: never }>;

export type GetUserMfaMethodsQuery = {
  __typename?: "query_root";
  user_mfa_factors: Array<{
    __typename?: "user_mfa_factors";
    id: any;
    mfa_type: string;
    is_active?: boolean | null;
    expires_on?: any | null;
    created_at: any;
    updated_at: any;
  }>;
};

export type GetWorkflowQueryVariables = Exact<{
  formId: Scalars["uuid"];
}>;

export type GetWorkflowQuery = {
  __typename?: "query_root";
  forms_by_pk?: {
    __typename?: "forms";
    id: any;
    name: string;
    description?: string | null;
    display_if?: any | null;
    workflow_type?: string | null;
    parent_form_id?: any | null;
    created_at: any;
    updated_at: any;
    childForms: Array<{
      __typename?: "forms";
      id: any;
      name: string;
      description?: string | null;
      display_if?: any | null;
      workflow_type?: string | null;
      parent_form_id?: any | null;
      created_at: any;
      updated_at: any;
      form_fields: Array<{
        __typename?: "form_fields";
        id: any;
        field_name?: string | null;
        label: string;
        is_required: boolean;
        options?: any | null;
        units?: string | null;
        position: number;
        step?: string | null;
        min?: any | null;
        max?: any | null;
        default_value?: string | null;
        placeholder?: string | null;
        validation_regex?: string | null;
        display_if?: any | null;
        type_id: any;
      }>;
    }>;
    form_fields: Array<{
      __typename?: "form_fields";
      id: any;
      field_name?: string | null;
      label: string;
      is_required: boolean;
      options?: any | null;
      units?: string | null;
      position: number;
      step?: string | null;
      min?: any | null;
      max?: any | null;
      default_value?: string | null;
      placeholder?: string | null;
      validation_regex?: string | null;
      display_if?: any | null;
      type_id: any;
    }>;
  } | null;
};

export type VerifyIfCanMakeProductPaymentQueryVariables = Exact<{
  productId: Scalars["uuid"];
  partyId: Scalars["uuid"];
}>;

export type VerifyIfCanMakeProductPaymentQuery = {
  __typename?: "query_root";
  canMakeProductPayment?: {
    __typename?: "CanMakeProductPaymentOutput";
    canPayProduct: boolean;
    productId?: any | null;
    balance?: any | null;
    product?: { __typename?: "products"; name: string } | null;
  } | null;
};

export type VerifyAuthFactorQueryVariables = Exact<{
  mfaMethod: Scalars["String"];
  otp: Scalars["String"];
}>;

export type VerifyAuthFactorQuery = {
  __typename?: "query_root";
  verifyAuthFactor?: {
    __typename?: "VerifyAuthFactorOutput";
    response: boolean;
  } | null;
};

export type GetChannelMessagesSubscriptionVariables = Exact<{
  channelId: Scalars["uuid"];
}>;

export type GetChannelMessagesSubscription = {
  __typename?: "subscription_root";
  messages: Array<{
    __typename?: "messages";
    id: any;
    body: string;
    created_at: any;
    sender?: any | null;
    message_delivery_methods: Array<{
      __typename?: "message_delivery_methods";
      delivery_method: {
        __typename?: "delivery_methods";
        id: any;
        name: string;
      };
    }>;
  }>;
};

export const FormQueryOptionsFragmentDoc = gql`
  fragment FormQueryOptions on forms {
    id
    name
    description
    display_if
    workflow_type
    parent_form_id
    created_at
    updated_at
    form_fields(
      where: { is_active: { _eq: true } }
      order_by: { position: asc }
    ) {
      id
      field_name
      label
      is_required
      options
      units
      position
      step
      min
      max
      default_value
      placeholder
      validation_regex
      display_if
      type_id
    }
  }
`;
export const SubscriptionItemFieldsFragmentDoc = gql`
  fragment SubscriptionItemFields on subscription_items {
    id
    is_voided
    void_reason
    quantity
    product_item {
      id
      name
      description
      is_active
      ledger_account_id
      product_id
      tariff {
        id
        is_active
        is_percentage
        max_value
        min_value
        name
        of_total
        of_field_value
        type {
          id
          name
          value
        }
        metadata
        created_at
      }
      type {
        id
        name
        value
      }
      metadata
    }
    ledger_account_id
  }
`;
export const RuleFieldsFragmentDoc = gql`
  fragment RuleFields on rules {
    id
    name
    is_active
    run_on
    success_event
    metadata
    conditions {
      id
      hierarchy_resolver
      fact
      value
      operator
      params
      path
      root_operator
      priority
      is_active
    }
    created_at
    updated_at
  }
`;
export const LoanAccountBaseFieldsFragmentDoc = gql`
  fragment LoanAccountBaseFields on subscriptions {
    id
    invoice_interval
    metadata
    subscription_start
    subscription_end
    created_at
    updated_at
    subscription_items {
      ...SubscriptionItemFields
    }
    subscription_schedules {
      id
      due_date
      beginning_balance
      scheduled_payment
      extra_payment
      actual_payment
      principal
      interest
      penalties
      charges
      ending_balance
      total_expected_payment
      partyByLastUpdatedBy {
        id
        name
      }
      metadata
      created_at
      updated_at
    }
    subscription_payments(order_by: { created_at: desc }) {
      id
      amount
      subscription_balance
      payment_date
      subscription_payment_transactions {
        id
        amount
      }
      created_at
    }
    subscription_rules {
      id
      rule {
        ...RuleFields
      }
    }
    subscription_statuses(order_by: { created_at: desc }) {
      id
      created_at
      status {
        id
        name
        value
      }
    }
    penalty: subscription_items(
      where: { product_item: { type: { value: { _eq: "PENALTY" } } } }
    ) {
      id
      invoice_items(limit: 1, order_by: { created_at: desc }) {
        id
        quantity
        amount
      }
    }
    latest_invoice: invoices(limit: 1, order_by: { created_at: desc }) {
      id
      amount_due
      invoice_items {
        id
        quantity
        amount
        product_item {
          id
          name
        }
        created_at
      }
      created_at
    }
    invoices(order_by: { created_at: desc }) {
      id
      amount_due
      due_at
      created_at
      status {
        id
        name
        value
      }
      invoice_items {
        id
        quantity
        amount
        product_item {
          name
          type {
            value
          }
        }
      }
    }
    current_status: subscription_statuses(
      limit: 1
      order_by: { created_at: desc }
    ) {
      id
      status {
        id
        name
        value
      }
    }
    disbursed: subscription_statuses(
      limit: 1
      where: { status: { value: { _eq: "DISBURSED" } } }
    ) {
      id
      created_at
    }
    type {
      id
      name
      value
    }
    product {
      id
      name
      ledger_id
      product_settings {
        id
        name
        value
      }
    }
    party {
      id
      name
      type_id
    }
    product_id
  }
  ${SubscriptionItemFieldsFragmentDoc}
  ${RuleFieldsFragmentDoc}
`;
export const TransactionEntriesPaginationFragmentDoc = gql`
  fragment TransactionEntriesPagination on TransactionLedgerEntryConnection {
    aggregate {
      totalCount
    }
    pageInfo {
      endCursor
      hasNextPage
    }
  }
`;
export const PartyBaseFieldsFragmentDoc = gql`
  fragment PartyBaseFields on parties {
    id
    name
    nickname
    avatar
    phoneNumber: phone_number
    email
    location
    party_number
    party_number_schema
    locale
    type_id
    form_id
    metadata
    created_at
  }
`;
export const OrderedPartyStatusesFragmentDoc = gql`
  fragment OrderedPartyStatuses on parties {
    party_statuses(order_by: { created_at: desc }) {
      status {
        name
        value
      }
    }
  }
`;
export const PartyContactsFieldsFragmentDoc = gql`
  fragment PartyContactsFields on parties {
    party_contacts {
      contact {
        id
        name
        contact_details {
          id
          form_field {
            id
            field_name
          }
          value
        }
      }
    }
  }
`;
export const ProductBaseFieldsFragmentDoc = gql`
  fragment ProductBaseFields on products {
    id
    name
    description
    type {
      id
      name
      value
    }
    sub_type {
      id
      name
      value
    }
    product_items {
      id
      name
      description
      is_active
      tariff {
        id
        is_active
        is_percentage
        value
        max_value
        min_value
        name
        of_total
        of_field_value
        type {
          id
          name
          value
        }
        metadata
        created_at
      }
      type {
        id
        name
        value
      }
      sub_type {
        id
        name
        value
      }
      ledger_account_id
      metadata
      created_at
      updated_at
    }
    product_settings(where: { is_active: { _eq: true } }) {
      id
      name
      value
      type {
        id
        name
      }
    }
    product_statuses(order_by: { created_at: asc }, limit: 1) {
      id
      status {
        id
        name
        value
      }
    }
    active_subscriptions {
      count
    }
    mom_growth_report {
      report_name
      report_data {
        month
        previous_month_value
        current_month_value
        percentage_growth
      }
    }
    created_at
    updated_at
  }
`;
export const LoanProductBaseFieldsFragmentDoc = gql`
  fragment LoanProductBaseFields on products {
    id
    name
    description
    type {
      id
      name
      value
    }
    sub_type {
      id
      name
      value
    }
    product_items {
      id
      name
      description
      is_active
      tariff {
        id
        is_active
        is_percentage
        value
        max_value
        min_value
        name
        of_total
        of_field_value
        type {
          id
          name
          value
        }
        metadata
        created_at
      }
      type {
        id
        name
        value
      }
      sub_type {
        id
        name
        value
      }
      ledger_account_id
      metadata
      created_at
      updated_at
    }
    product_settings(where: { is_active: { _eq: true } }) {
      id
      name
      value
      type {
        id
        name
      }
    }
    product_statuses(order_by: { created_at: asc }, limit: 1) {
      id
      status {
        id
        name
        value
      }
    }
    subscriptions(order_by: { created_at: asc }) {
      id
      party {
        id
        name
        type_id
      }
      subscription_tasks {
        id
        task_id
      }
      subscription_statuses {
        id
        status_id
      }
      created_at
    }
    created_at
    updated_at
  }
`;
export const SubscriptionBaseFieldsFragmentDoc = gql`
  fragment SubscriptionBaseFields on subscriptions {
    id
    invoice_interval
    created_at
    updated_at
    metadata
    subscription_start
    subscription_end
    subscription_items {
      ...SubscriptionItemFields
    }
    subscription_rules {
      id
      rule {
        ...RuleFields
      }
    }
    subscription_details {
      id
      value
      form_field {
        id
        field_name
      }
    }
    subscription_statuses(order_by: { created_at: desc }) {
      id
      status {
        id
        name
        value
      }
    }
    invoices(order_by: { created_at: desc }, limit: 1) {
      id
      created_at
      updated_at
    }
    type {
      id
      name
      value
    }
    product {
      id
      name
      ledger_id
      product_settings {
        id
        name
        value
      }
    }
    party {
      id
      name
      type_id
    }
    product_id
  }
  ${SubscriptionItemFieldsFragmentDoc}
  ${RuleFieldsFragmentDoc}
`;
export const AddDocumentNoteDocument = gql`
  mutation AddDocumentNote(
    $typeId: uuid!
    $interactionData: interaction_details_arr_rel_insert_input!
    $interactedId: uuid!
  ) {
    insert_interactions(
      objects: [
        {
          type_id: $typeId
          interaction_data: $interactionData
          document_interactions: { data: { document_id: $interactedId } }
        }
      ]
    ) {
      returning {
        id
        interaction_data {
          id
        }
      }
    }
  }
`;
export type AddDocumentNoteMutationFn = Apollo.MutationFunction<
  AddDocumentNoteMutation,
  AddDocumentNoteMutationVariables
>;

/**
 * __useAddDocumentNoteMutation__
 *
 * To run a mutation, you first call `useAddDocumentNoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddDocumentNoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addDocumentNoteMutation, { data, loading, error }] = useAddDocumentNoteMutation({
 *   variables: {
 *      typeId: // value for 'typeId'
 *      interactionData: // value for 'interactionData'
 *      interactedId: // value for 'interactedId'
 *   },
 * });
 */
export function useAddDocumentNoteMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddDocumentNoteMutation,
    AddDocumentNoteMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AddDocumentNoteMutation,
    AddDocumentNoteMutationVariables
  >(AddDocumentNoteDocument, options);
}
export type AddDocumentNoteMutationHookResult = ReturnType<
  typeof useAddDocumentNoteMutation
>;
export type AddDocumentNoteMutationResult =
  Apollo.MutationResult<AddDocumentNoteMutation>;
export type AddDocumentNoteMutationOptions = Apollo.BaseMutationOptions<
  AddDocumentNoteMutation,
  AddDocumentNoteMutationVariables
>;
export const AddInvoiceNoteDocument = gql`
  mutation AddInvoiceNote(
    $typeId: uuid!
    $interactionData: interaction_details_arr_rel_insert_input!
    $interactedId: uuid!
  ) {
    insert_interactions(
      objects: [
        {
          type_id: $typeId
          interaction_data: $interactionData
          invoice_interactions: { data: { invoice_id: $interactedId } }
        }
      ]
    ) {
      returning {
        id
        interaction_data {
          id
        }
      }
    }
  }
`;
export type AddInvoiceNoteMutationFn = Apollo.MutationFunction<
  AddInvoiceNoteMutation,
  AddInvoiceNoteMutationVariables
>;

/**
 * __useAddInvoiceNoteMutation__
 *
 * To run a mutation, you first call `useAddInvoiceNoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddInvoiceNoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addInvoiceNoteMutation, { data, loading, error }] = useAddInvoiceNoteMutation({
 *   variables: {
 *      typeId: // value for 'typeId'
 *      interactionData: // value for 'interactionData'
 *      interactedId: // value for 'interactedId'
 *   },
 * });
 */
export function useAddInvoiceNoteMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddInvoiceNoteMutation,
    AddInvoiceNoteMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AddInvoiceNoteMutation,
    AddInvoiceNoteMutationVariables
  >(AddInvoiceNoteDocument, options);
}
export type AddInvoiceNoteMutationHookResult = ReturnType<
  typeof useAddInvoiceNoteMutation
>;
export type AddInvoiceNoteMutationResult =
  Apollo.MutationResult<AddInvoiceNoteMutation>;
export type AddInvoiceNoteMutationOptions = Apollo.BaseMutationOptions<
  AddInvoiceNoteMutation,
  AddInvoiceNoteMutationVariables
>;
export const AddMfaMethodDocument = gql`
  mutation AddMFAMethod($mfaMethod: String!) {
    addMFAMethod(params: { mfaMethod: $mfaMethod }) {
      qrCodeURL
    }
  }
`;
export type AddMfaMethodMutationFn = Apollo.MutationFunction<
  AddMfaMethodMutation,
  AddMfaMethodMutationVariables
>;

/**
 * __useAddMfaMethodMutation__
 *
 * To run a mutation, you first call `useAddMfaMethodMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddMfaMethodMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addMfaMethodMutation, { data, loading, error }] = useAddMfaMethodMutation({
 *   variables: {
 *      mfaMethod: // value for 'mfaMethod'
 *   },
 * });
 */
export function useAddMfaMethodMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddMfaMethodMutation,
    AddMfaMethodMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AddMfaMethodMutation,
    AddMfaMethodMutationVariables
  >(AddMfaMethodDocument, options);
}
export type AddMfaMethodMutationHookResult = ReturnType<
  typeof useAddMfaMethodMutation
>;
export type AddMfaMethodMutationResult =
  Apollo.MutationResult<AddMfaMethodMutation>;
export type AddMfaMethodMutationOptions = Apollo.BaseMutationOptions<
  AddMfaMethodMutation,
  AddMfaMethodMutationVariables
>;
export const AddPartyDocumentsDocument = gql`
  mutation AddPartyDocuments($documentData: [party_documents_insert_input!]!) {
    insert_party_documents(objects: $documentData) {
      returning {
        id
        document_id
      }
    }
  }
`;
export type AddPartyDocumentsMutationFn = Apollo.MutationFunction<
  AddPartyDocumentsMutation,
  AddPartyDocumentsMutationVariables
>;

/**
 * __useAddPartyDocumentsMutation__
 *
 * To run a mutation, you first call `useAddPartyDocumentsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddPartyDocumentsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addPartyDocumentsMutation, { data, loading, error }] = useAddPartyDocumentsMutation({
 *   variables: {
 *      documentData: // value for 'documentData'
 *   },
 * });
 */
export function useAddPartyDocumentsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddPartyDocumentsMutation,
    AddPartyDocumentsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AddPartyDocumentsMutation,
    AddPartyDocumentsMutationVariables
  >(AddPartyDocumentsDocument, options);
}
export type AddPartyDocumentsMutationHookResult = ReturnType<
  typeof useAddPartyDocumentsMutation
>;
export type AddPartyDocumentsMutationResult =
  Apollo.MutationResult<AddPartyDocumentsMutation>;
export type AddPartyDocumentsMutationOptions = Apollo.BaseMutationOptions<
  AddPartyDocumentsMutation,
  AddPartyDocumentsMutationVariables
>;
export const AddPartyNoteDocument = gql`
  mutation AddPartyNote(
    $typeId: uuid!
    $interactionData: interaction_details_arr_rel_insert_input!
    $interactedId: uuid!
  ) {
    insert_interactions(
      objects: [
        {
          type_id: $typeId
          interaction_data: $interactionData
          party_interactions: { data: { party_id: $interactedId } }
        }
      ]
    ) {
      returning {
        id
        interaction_data {
          id
        }
      }
    }
  }
`;
export type AddPartyNoteMutationFn = Apollo.MutationFunction<
  AddPartyNoteMutation,
  AddPartyNoteMutationVariables
>;

/**
 * __useAddPartyNoteMutation__
 *
 * To run a mutation, you first call `useAddPartyNoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddPartyNoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addPartyNoteMutation, { data, loading, error }] = useAddPartyNoteMutation({
 *   variables: {
 *      typeId: // value for 'typeId'
 *      interactionData: // value for 'interactionData'
 *      interactedId: // value for 'interactedId'
 *   },
 * });
 */
export function useAddPartyNoteMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddPartyNoteMutation,
    AddPartyNoteMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AddPartyNoteMutation,
    AddPartyNoteMutationVariables
  >(AddPartyNoteDocument, options);
}
export type AddPartyNoteMutationHookResult = ReturnType<
  typeof useAddPartyNoteMutation
>;
export type AddPartyNoteMutationResult =
  Apollo.MutationResult<AddPartyNoteMutation>;
export type AddPartyNoteMutationOptions = Apollo.BaseMutationOptions<
  AddPartyNoteMutation,
  AddPartyNoteMutationVariables
>;
export const AddPartyStatusDocument = gql`
  mutation AddPartyStatus($partyId: uuid!, $statusId: uuid!) {
    insert_party_statuses_one(
      object: { party_id: $partyId, status_id: $statusId }
    ) {
      id
    }
  }
`;
export type AddPartyStatusMutationFn = Apollo.MutationFunction<
  AddPartyStatusMutation,
  AddPartyStatusMutationVariables
>;

/**
 * __useAddPartyStatusMutation__
 *
 * To run a mutation, you first call `useAddPartyStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddPartyStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addPartyStatusMutation, { data, loading, error }] = useAddPartyStatusMutation({
 *   variables: {
 *      partyId: // value for 'partyId'
 *      statusId: // value for 'statusId'
 *   },
 * });
 */
export function useAddPartyStatusMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddPartyStatusMutation,
    AddPartyStatusMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AddPartyStatusMutation,
    AddPartyStatusMutationVariables
  >(AddPartyStatusDocument, options);
}
export type AddPartyStatusMutationHookResult = ReturnType<
  typeof useAddPartyStatusMutation
>;
export type AddPartyStatusMutationResult =
  Apollo.MutationResult<AddPartyStatusMutation>;
export type AddPartyStatusMutationOptions = Apollo.BaseMutationOptions<
  AddPartyStatusMutation,
  AddPartyStatusMutationVariables
>;
export const AddProductItemDocument = gql`
  mutation AddProductItem($input: [product_items_insert_input!]!) {
    insert_product_items(objects: $input) {
      affected_rows
    }
  }
`;
export type AddProductItemMutationFn = Apollo.MutationFunction<
  AddProductItemMutation,
  AddProductItemMutationVariables
>;

/**
 * __useAddProductItemMutation__
 *
 * To run a mutation, you first call `useAddProductItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddProductItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addProductItemMutation, { data, loading, error }] = useAddProductItemMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddProductItemMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddProductItemMutation,
    AddProductItemMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AddProductItemMutation,
    AddProductItemMutationVariables
  >(AddProductItemDocument, options);
}
export type AddProductItemMutationHookResult = ReturnType<
  typeof useAddProductItemMutation
>;
export type AddProductItemMutationResult =
  Apollo.MutationResult<AddProductItemMutation>;
export type AddProductItemMutationOptions = Apollo.BaseMutationOptions<
  AddProductItemMutation,
  AddProductItemMutationVariables
>;
export const AddProductRuleDocument = gql`
  mutation AddProductRule($input: product_rules_insert_input!) {
    insert_product_rules_one(object: $input) {
      id
      rule_id
    }
  }
`;
export type AddProductRuleMutationFn = Apollo.MutationFunction<
  AddProductRuleMutation,
  AddProductRuleMutationVariables
>;

/**
 * __useAddProductRuleMutation__
 *
 * To run a mutation, you first call `useAddProductRuleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddProductRuleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addProductRuleMutation, { data, loading, error }] = useAddProductRuleMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddProductRuleMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddProductRuleMutation,
    AddProductRuleMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AddProductRuleMutation,
    AddProductRuleMutationVariables
  >(AddProductRuleDocument, options);
}
export type AddProductRuleMutationHookResult = ReturnType<
  typeof useAddProductRuleMutation
>;
export type AddProductRuleMutationResult =
  Apollo.MutationResult<AddProductRuleMutation>;
export type AddProductRuleMutationOptions = Apollo.BaseMutationOptions<
  AddProductRuleMutation,
  AddProductRuleMutationVariables
>;
export const AddProductSettingDocument = gql`
  mutation AddProductSetting($input: [product_settings_insert_input!]!) {
    insert_product_settings(objects: $input) {
      affected_rows
    }
  }
`;
export type AddProductSettingMutationFn = Apollo.MutationFunction<
  AddProductSettingMutation,
  AddProductSettingMutationVariables
>;

/**
 * __useAddProductSettingMutation__
 *
 * To run a mutation, you first call `useAddProductSettingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddProductSettingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addProductSettingMutation, { data, loading, error }] = useAddProductSettingMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddProductSettingMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddProductSettingMutation,
    AddProductSettingMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AddProductSettingMutation,
    AddProductSettingMutationVariables
  >(AddProductSettingDocument, options);
}
export type AddProductSettingMutationHookResult = ReturnType<
  typeof useAddProductSettingMutation
>;
export type AddProductSettingMutationResult =
  Apollo.MutationResult<AddProductSettingMutation>;
export type AddProductSettingMutationOptions = Apollo.BaseMutationOptions<
  AddProductSettingMutation,
  AddProductSettingMutationVariables
>;
export const AddProductStatusDocument = gql`
  mutation AddProductStatus($productId: uuid!, $statusId: uuid!) {
    insert_product_statuses_one(
      object: { product_id: $productId, status_id: $statusId }
    ) {
      id
    }
  }
`;
export type AddProductStatusMutationFn = Apollo.MutationFunction<
  AddProductStatusMutation,
  AddProductStatusMutationVariables
>;

/**
 * __useAddProductStatusMutation__
 *
 * To run a mutation, you first call `useAddProductStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddProductStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addProductStatusMutation, { data, loading, error }] = useAddProductStatusMutation({
 *   variables: {
 *      productId: // value for 'productId'
 *      statusId: // value for 'statusId'
 *   },
 * });
 */
export function useAddProductStatusMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddProductStatusMutation,
    AddProductStatusMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AddProductStatusMutation,
    AddProductStatusMutationVariables
  >(AddProductStatusDocument, options);
}
export type AddProductStatusMutationHookResult = ReturnType<
  typeof useAddProductStatusMutation
>;
export type AddProductStatusMutationResult =
  Apollo.MutationResult<AddProductStatusMutation>;
export type AddProductStatusMutationOptions = Apollo.BaseMutationOptions<
  AddProductStatusMutation,
  AddProductStatusMutationVariables
>;
export const AddSubscriptionNoteDocument = gql`
  mutation AddSubscriptionNote(
    $typeId: uuid!
    $interactionData: interaction_details_arr_rel_insert_input!
    $interactedId: uuid!
  ) {
    insert_interactions(
      objects: [
        {
          type_id: $typeId
          interaction_data: $interactionData
          subscription_interactions: {
            data: { subscription_id: $interactedId }
          }
        }
      ]
    ) {
      returning {
        id
        interaction_data {
          id
        }
      }
    }
  }
`;
export type AddSubscriptionNoteMutationFn = Apollo.MutationFunction<
  AddSubscriptionNoteMutation,
  AddSubscriptionNoteMutationVariables
>;

/**
 * __useAddSubscriptionNoteMutation__
 *
 * To run a mutation, you first call `useAddSubscriptionNoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddSubscriptionNoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addSubscriptionNoteMutation, { data, loading, error }] = useAddSubscriptionNoteMutation({
 *   variables: {
 *      typeId: // value for 'typeId'
 *      interactionData: // value for 'interactionData'
 *      interactedId: // value for 'interactedId'
 *   },
 * });
 */
export function useAddSubscriptionNoteMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddSubscriptionNoteMutation,
    AddSubscriptionNoteMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AddSubscriptionNoteMutation,
    AddSubscriptionNoteMutationVariables
  >(AddSubscriptionNoteDocument, options);
}
export type AddSubscriptionNoteMutationHookResult = ReturnType<
  typeof useAddSubscriptionNoteMutation
>;
export type AddSubscriptionNoteMutationResult =
  Apollo.MutationResult<AddSubscriptionNoteMutation>;
export type AddSubscriptionNoteMutationOptions = Apollo.BaseMutationOptions<
  AddSubscriptionNoteMutation,
  AddSubscriptionNoteMutationVariables
>;
export const AddSubscriptionRuleDocument = gql`
  mutation AddSubscriptionRule($input: subscription_rules_insert_input!) {
    insert_subscription_rules_one(object: $input) {
      id
      rule_id
    }
  }
`;
export type AddSubscriptionRuleMutationFn = Apollo.MutationFunction<
  AddSubscriptionRuleMutation,
  AddSubscriptionRuleMutationVariables
>;

/**
 * __useAddSubscriptionRuleMutation__
 *
 * To run a mutation, you first call `useAddSubscriptionRuleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddSubscriptionRuleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addSubscriptionRuleMutation, { data, loading, error }] = useAddSubscriptionRuleMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddSubscriptionRuleMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddSubscriptionRuleMutation,
    AddSubscriptionRuleMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AddSubscriptionRuleMutation,
    AddSubscriptionRuleMutationVariables
  >(AddSubscriptionRuleDocument, options);
}
export type AddSubscriptionRuleMutationHookResult = ReturnType<
  typeof useAddSubscriptionRuleMutation
>;
export type AddSubscriptionRuleMutationResult =
  Apollo.MutationResult<AddSubscriptionRuleMutation>;
export type AddSubscriptionRuleMutationOptions = Apollo.BaseMutationOptions<
  AddSubscriptionRuleMutation,
  AddSubscriptionRuleMutationVariables
>;
export const AddSubscriptionStatusDocument = gql`
  mutation AddSubscriptionStatus($subscriptionId: uuid!, $statusId: uuid!) {
    insert_subscription_statuses_one(
      object: { subscription_id: $subscriptionId, status_id: $statusId }
    ) {
      id
    }
  }
`;
export type AddSubscriptionStatusMutationFn = Apollo.MutationFunction<
  AddSubscriptionStatusMutation,
  AddSubscriptionStatusMutationVariables
>;

/**
 * __useAddSubscriptionStatusMutation__
 *
 * To run a mutation, you first call `useAddSubscriptionStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddSubscriptionStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addSubscriptionStatusMutation, { data, loading, error }] = useAddSubscriptionStatusMutation({
 *   variables: {
 *      subscriptionId: // value for 'subscriptionId'
 *      statusId: // value for 'statusId'
 *   },
 * });
 */
export function useAddSubscriptionStatusMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddSubscriptionStatusMutation,
    AddSubscriptionStatusMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AddSubscriptionStatusMutation,
    AddSubscriptionStatusMutationVariables
  >(AddSubscriptionStatusDocument, options);
}
export type AddSubscriptionStatusMutationHookResult = ReturnType<
  typeof useAddSubscriptionStatusMutation
>;
export type AddSubscriptionStatusMutationResult =
  Apollo.MutationResult<AddSubscriptionStatusMutation>;
export type AddSubscriptionStatusMutationOptions = Apollo.BaseMutationOptions<
  AddSubscriptionStatusMutation,
  AddSubscriptionStatusMutationVariables
>;
export const SetPartiesStatusDocument = gql`
  mutation SetPartiesStatus($object: [party_statuses_insert_input!]!) {
    insert_party_statuses(objects: $object) {
      affected_rows
    }
  }
`;
export type SetPartiesStatusMutationFn = Apollo.MutationFunction<
  SetPartiesStatusMutation,
  SetPartiesStatusMutationVariables
>;

/**
 * __useSetPartiesStatusMutation__
 *
 * To run a mutation, you first call `useSetPartiesStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetPartiesStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setPartiesStatusMutation, { data, loading, error }] = useSetPartiesStatusMutation({
 *   variables: {
 *      object: // value for 'object'
 *   },
 * });
 */
export function useSetPartiesStatusMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SetPartiesStatusMutation,
    SetPartiesStatusMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SetPartiesStatusMutation,
    SetPartiesStatusMutationVariables
  >(SetPartiesStatusDocument, options);
}
export type SetPartiesStatusMutationHookResult = ReturnType<
  typeof useSetPartiesStatusMutation
>;
export type SetPartiesStatusMutationResult =
  Apollo.MutationResult<SetPartiesStatusMutation>;
export type SetPartiesStatusMutationOptions = Apollo.BaseMutationOptions<
  SetPartiesStatusMutation,
  SetPartiesStatusMutationVariables
>;
export const SetSubscriptionsStatusDocument = gql`
  mutation SetSubscriptionsStatus(
    $object: [subscription_statuses_insert_input!]!
  ) {
    insert_subscription_statuses(objects: $object) {
      affected_rows
    }
  }
`;
export type SetSubscriptionsStatusMutationFn = Apollo.MutationFunction<
  SetSubscriptionsStatusMutation,
  SetSubscriptionsStatusMutationVariables
>;

/**
 * __useSetSubscriptionsStatusMutation__
 *
 * To run a mutation, you first call `useSetSubscriptionsStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetSubscriptionsStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setSubscriptionsStatusMutation, { data, loading, error }] = useSetSubscriptionsStatusMutation({
 *   variables: {
 *      object: // value for 'object'
 *   },
 * });
 */
export function useSetSubscriptionsStatusMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SetSubscriptionsStatusMutation,
    SetSubscriptionsStatusMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SetSubscriptionsStatusMutation,
    SetSubscriptionsStatusMutationVariables
  >(SetSubscriptionsStatusDocument, options);
}
export type SetSubscriptionsStatusMutationHookResult = ReturnType<
  typeof useSetSubscriptionsStatusMutation
>;
export type SetSubscriptionsStatusMutationResult =
  Apollo.MutationResult<SetSubscriptionsStatusMutation>;
export type SetSubscriptionsStatusMutationOptions = Apollo.BaseMutationOptions<
  SetSubscriptionsStatusMutation,
  SetSubscriptionsStatusMutationVariables
>;
export const CancelSubscriptionDocument = gql`
  mutation CancelSubscription($input: CancelSubscriptionInput!) {
    cancelSubscription(params: $input) {
      message
    }
  }
`;
export type CancelSubscriptionMutationFn = Apollo.MutationFunction<
  CancelSubscriptionMutation,
  CancelSubscriptionMutationVariables
>;

/**
 * __useCancelSubscriptionMutation__
 *
 * To run a mutation, you first call `useCancelSubscriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelSubscriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelSubscriptionMutation, { data, loading, error }] = useCancelSubscriptionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCancelSubscriptionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CancelSubscriptionMutation,
    CancelSubscriptionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CancelSubscriptionMutation,
    CancelSubscriptionMutationVariables
  >(CancelSubscriptionDocument, options);
}
export type CancelSubscriptionMutationHookResult = ReturnType<
  typeof useCancelSubscriptionMutation
>;
export type CancelSubscriptionMutationResult =
  Apollo.MutationResult<CancelSubscriptionMutation>;
export type CancelSubscriptionMutationOptions = Apollo.BaseMutationOptions<
  CancelSubscriptionMutation,
  CancelSubscriptionMutationVariables
>;
export const ChangePasswordDocument = gql`
  mutation ChangePassword(
    $email: String!
    $newPassword: String!
    $oldPassword: String!
  ) {
    changePassword(
      params: {
        email: $email
        newPassword: $newPassword
        oldPassword: $oldPassword
      }
    ) {
      message
    }
  }
`;
export type ChangePasswordMutationFn = Apollo.MutationFunction<
  ChangePasswordMutation,
  ChangePasswordMutationVariables
>;

/**
 * __useChangePasswordMutation__
 *
 * To run a mutation, you first call `useChangePasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangePasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changePasswordMutation, { data, loading, error }] = useChangePasswordMutation({
 *   variables: {
 *      email: // value for 'email'
 *      newPassword: // value for 'newPassword'
 *      oldPassword: // value for 'oldPassword'
 *   },
 * });
 */
export function useChangePasswordMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ChangePasswordMutation,
    ChangePasswordMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ChangePasswordMutation,
    ChangePasswordMutationVariables
  >(ChangePasswordDocument, options);
}
export type ChangePasswordMutationHookResult = ReturnType<
  typeof useChangePasswordMutation
>;
export type ChangePasswordMutationResult =
  Apollo.MutationResult<ChangePasswordMutation>;
export type ChangePasswordMutationOptions = Apollo.BaseMutationOptions<
  ChangePasswordMutation,
  ChangePasswordMutationVariables
>;
export const CompleteLoginDocument = gql`
  mutation CompleteLogin($params: CompleteLoginInput!) {
    completeLogin(params: $params) {
      partyId
      accessToken
      message
      type
    }
  }
`;
export type CompleteLoginMutationFn = Apollo.MutationFunction<
  CompleteLoginMutation,
  CompleteLoginMutationVariables
>;

/**
 * __useCompleteLoginMutation__
 *
 * To run a mutation, you first call `useCompleteLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompleteLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [completeLoginMutation, { data, loading, error }] = useCompleteLoginMutation({
 *   variables: {
 *      params: // value for 'params'
 *   },
 * });
 */
export function useCompleteLoginMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CompleteLoginMutation,
    CompleteLoginMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CompleteLoginMutation,
    CompleteLoginMutationVariables
  >(CompleteLoginDocument, options);
}
export type CompleteLoginMutationHookResult = ReturnType<
  typeof useCompleteLoginMutation
>;
export type CompleteLoginMutationResult =
  Apollo.MutationResult<CompleteLoginMutation>;
export type CompleteLoginMutationOptions = Apollo.BaseMutationOptions<
  CompleteLoginMutation,
  CompleteLoginMutationVariables
>;
export const CreateAndSubscribeToChannelDocument = gql`
  mutation CreateAndSubscribeToChannel(
    $channelName: String!
    $channelDescription: String
    $partyIds: [uuid]!
    $deliveryAddresses: jsonObject
    $deliveryMethods: [String]
    $metadata: jsonObject
  ) {
    createAndSubscribeToChannel(
      params: {
        channelName: $channelName
        channelDescription: $channelDescription
        partyIds: $partyIds
        deliveryAddresses: $deliveryAddresses
        deliveryMethods: $deliveryMethods
        metadata: $metadata
      }
    ) {
      body
      message
    }
  }
`;
export type CreateAndSubscribeToChannelMutationFn = Apollo.MutationFunction<
  CreateAndSubscribeToChannelMutation,
  CreateAndSubscribeToChannelMutationVariables
>;

/**
 * __useCreateAndSubscribeToChannelMutation__
 *
 * To run a mutation, you first call `useCreateAndSubscribeToChannelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAndSubscribeToChannelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAndSubscribeToChannelMutation, { data, loading, error }] = useCreateAndSubscribeToChannelMutation({
 *   variables: {
 *      channelName: // value for 'channelName'
 *      channelDescription: // value for 'channelDescription'
 *      partyIds: // value for 'partyIds'
 *      deliveryAddresses: // value for 'deliveryAddresses'
 *      deliveryMethods: // value for 'deliveryMethods'
 *      metadata: // value for 'metadata'
 *   },
 * });
 */
export function useCreateAndSubscribeToChannelMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateAndSubscribeToChannelMutation,
    CreateAndSubscribeToChannelMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateAndSubscribeToChannelMutation,
    CreateAndSubscribeToChannelMutationVariables
  >(CreateAndSubscribeToChannelDocument, options);
}
export type CreateAndSubscribeToChannelMutationHookResult = ReturnType<
  typeof useCreateAndSubscribeToChannelMutation
>;
export type CreateAndSubscribeToChannelMutationResult =
  Apollo.MutationResult<CreateAndSubscribeToChannelMutation>;
export type CreateAndSubscribeToChannelMutationOptions =
  Apollo.BaseMutationOptions<
    CreateAndSubscribeToChannelMutation,
    CreateAndSubscribeToChannelMutationVariables
  >;
export const CreateProductDocument = gql`
  mutation CreateProduct(
    $name: String!
    $type_id: uuid!
    $description: String!
    $product_items: product_items_arr_rel_insert_input!
    $product_settings: product_settings_arr_rel_insert_input!
    $product_statuses: product_statuses_arr_rel_insert_input!
    $product_rules: product_rules_arr_rel_insert_input
  ) {
    insert_products(
      objects: {
        name: $name
        description: $description
        type_id: $type_id
        product_items: $product_items
        product_settings: $product_settings
        product_statuses: $product_statuses
        product_rules: $product_rules
      }
    ) {
      affected_rows
      returning {
        id
      }
    }
  }
`;
export type CreateProductMutationFn = Apollo.MutationFunction<
  CreateProductMutation,
  CreateProductMutationVariables
>;

/**
 * __useCreateProductMutation__
 *
 * To run a mutation, you first call `useCreateProductMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateProductMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createProductMutation, { data, loading, error }] = useCreateProductMutation({
 *   variables: {
 *      name: // value for 'name'
 *      type_id: // value for 'type_id'
 *      description: // value for 'description'
 *      product_items: // value for 'product_items'
 *      product_settings: // value for 'product_settings'
 *      product_statuses: // value for 'product_statuses'
 *      product_rules: // value for 'product_rules'
 *   },
 * });
 */
export function useCreateProductMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateProductMutation,
    CreateProductMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateProductMutation,
    CreateProductMutationVariables
  >(CreateProductDocument, options);
}
export type CreateProductMutationHookResult = ReturnType<
  typeof useCreateProductMutation
>;
export type CreateProductMutationResult =
  Apollo.MutationResult<CreateProductMutation>;
export type CreateProductMutationOptions = Apollo.BaseMutationOptions<
  CreateProductMutation,
  CreateProductMutationVariables
>;
export const CreateSubscriptionDocument = gql`
  mutation CreateSubscription($input: subscriptions_insert_input!) {
    insert_subscriptions_one(object: $input) {
      id
    }
  }
`;
export type CreateSubscriptionMutationFn = Apollo.MutationFunction<
  CreateSubscriptionMutation,
  CreateSubscriptionMutationVariables
>;

/**
 * __useCreateSubscriptionMutation__
 *
 * To run a mutation, you first call `useCreateSubscriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSubscriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSubscriptionMutation, { data, loading, error }] = useCreateSubscriptionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateSubscriptionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateSubscriptionMutation,
    CreateSubscriptionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateSubscriptionMutation,
    CreateSubscriptionMutationVariables
  >(CreateSubscriptionDocument, options);
}
export type CreateSubscriptionMutationHookResult = ReturnType<
  typeof useCreateSubscriptionMutation
>;
export type CreateSubscriptionMutationResult =
  Apollo.MutationResult<CreateSubscriptionMutation>;
export type CreateSubscriptionMutationOptions = Apollo.BaseMutationOptions<
  CreateSubscriptionMutation,
  CreateSubscriptionMutationVariables
>;
export const AddNewWorkflowDocument = gql`
  mutation AddNewWorkflow(
    $description: String!
    $displayIf: jsonb = {}
    $name: String!
    $parentFormId: uuid
    $workflowType: String!
    $typeId: uuid!
    $formFields: form_fields_arr_rel_insert_input!
    $childForms: forms_arr_rel_insert_input
  ) {
    insert_forms_one(
      object: {
        description: $description
        display_if: $displayIf
        name: $name
        parent_form_id: $parentFormId
        workflow_type: $workflowType
        type_id: $typeId
        form_fields: $formFields
        forms: $childForms
      }
    ) {
      id
    }
  }
`;
export type AddNewWorkflowMutationFn = Apollo.MutationFunction<
  AddNewWorkflowMutation,
  AddNewWorkflowMutationVariables
>;

/**
 * __useAddNewWorkflowMutation__
 *
 * To run a mutation, you first call `useAddNewWorkflowMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddNewWorkflowMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addNewWorkflowMutation, { data, loading, error }] = useAddNewWorkflowMutation({
 *   variables: {
 *      description: // value for 'description'
 *      displayIf: // value for 'displayIf'
 *      name: // value for 'name'
 *      parentFormId: // value for 'parentFormId'
 *      workflowType: // value for 'workflowType'
 *      typeId: // value for 'typeId'
 *      formFields: // value for 'formFields'
 *      childForms: // value for 'childForms'
 *   },
 * });
 */
export function useAddNewWorkflowMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddNewWorkflowMutation,
    AddNewWorkflowMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AddNewWorkflowMutation,
    AddNewWorkflowMutationVariables
  >(AddNewWorkflowDocument, options);
}
export type AddNewWorkflowMutationHookResult = ReturnType<
  typeof useAddNewWorkflowMutation
>;
export type AddNewWorkflowMutationResult =
  Apollo.MutationResult<AddNewWorkflowMutation>;
export type AddNewWorkflowMutationOptions = Apollo.BaseMutationOptions<
  AddNewWorkflowMutation,
  AddNewWorkflowMutationVariables
>;
export const EditWorkflowSubmissionDocument = gql`
  mutation EditWorkflowSubmission($params: ProcessWorkflowSubmissionInput!) {
    editWorkflowSubmission(params: $params) {
      message
    }
  }
`;
export type EditWorkflowSubmissionMutationFn = Apollo.MutationFunction<
  EditWorkflowSubmissionMutation,
  EditWorkflowSubmissionMutationVariables
>;

/**
 * __useEditWorkflowSubmissionMutation__
 *
 * To run a mutation, you first call `useEditWorkflowSubmissionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditWorkflowSubmissionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editWorkflowSubmissionMutation, { data, loading, error }] = useEditWorkflowSubmissionMutation({
 *   variables: {
 *      params: // value for 'params'
 *   },
 * });
 */
export function useEditWorkflowSubmissionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    EditWorkflowSubmissionMutation,
    EditWorkflowSubmissionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    EditWorkflowSubmissionMutation,
    EditWorkflowSubmissionMutationVariables
  >(EditWorkflowSubmissionDocument, options);
}
export type EditWorkflowSubmissionMutationHookResult = ReturnType<
  typeof useEditWorkflowSubmissionMutation
>;
export type EditWorkflowSubmissionMutationResult =
  Apollo.MutationResult<EditWorkflowSubmissionMutation>;
export type EditWorkflowSubmissionMutationOptions = Apollo.BaseMutationOptions<
  EditWorkflowSubmissionMutation,
  EditWorkflowSubmissionMutationVariables
>;
export const LoginDocument = gql`
  mutation Login($params: LoginInput!) {
    login(params: $params) {
      partyId
      accessToken
      message
      type
      requiresMFA
      availableMFAMethods
    }
  }
`;
export type LoginMutationFn = Apollo.MutationFunction<
  LoginMutation,
  LoginMutationVariables
>;

/**
 * __useLoginMutation__
 *
 * To run a mutation, you first call `useLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginMutation, { data, loading, error }] = useLoginMutation({
 *   variables: {
 *      params: // value for 'params'
 *   },
 * });
 */
export function useLoginMutation(
  baseOptions?: Apollo.MutationHookOptions<
    LoginMutation,
    LoginMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<LoginMutation, LoginMutationVariables>(
    LoginDocument,
    options
  );
}
export type LoginMutationHookResult = ReturnType<typeof useLoginMutation>;
export type LoginMutationResult = Apollo.MutationResult<LoginMutation>;
export type LoginMutationOptions = Apollo.BaseMutationOptions<
  LoginMutation,
  LoginMutationVariables
>;
export const MakeInvoicePaymentDocument = gql`
  mutation MakeInvoicePayment($paymentData: MakeInvoicePaymentInput!) {
    makeInvoicePayment(params: $paymentData) {
      message
      subscriptionPaymentId
    }
  }
`;
export type MakeInvoicePaymentMutationFn = Apollo.MutationFunction<
  MakeInvoicePaymentMutation,
  MakeInvoicePaymentMutationVariables
>;

/**
 * __useMakeInvoicePaymentMutation__
 *
 * To run a mutation, you first call `useMakeInvoicePaymentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMakeInvoicePaymentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [makeInvoicePaymentMutation, { data, loading, error }] = useMakeInvoicePaymentMutation({
 *   variables: {
 *      paymentData: // value for 'paymentData'
 *   },
 * });
 */
export function useMakeInvoicePaymentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    MakeInvoicePaymentMutation,
    MakeInvoicePaymentMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    MakeInvoicePaymentMutation,
    MakeInvoicePaymentMutationVariables
  >(MakeInvoicePaymentDocument, options);
}
export type MakeInvoicePaymentMutationHookResult = ReturnType<
  typeof useMakeInvoicePaymentMutation
>;
export type MakeInvoicePaymentMutationResult =
  Apollo.MutationResult<MakeInvoicePaymentMutation>;
export type MakeInvoicePaymentMutationOptions = Apollo.BaseMutationOptions<
  MakeInvoicePaymentMutation,
  MakeInvoicePaymentMutationVariables
>;
export const MakeSubscriptionPaymentDocument = gql`
  mutation MakeSubscriptionPayment(
    $paymentData: MakeSubscriptionPaymentInput!
  ) {
    makeSubscriptionPayment(params: $paymentData) {
      message
      subscriptionPaymentId
    }
  }
`;
export type MakeSubscriptionPaymentMutationFn = Apollo.MutationFunction<
  MakeSubscriptionPaymentMutation,
  MakeSubscriptionPaymentMutationVariables
>;

/**
 * __useMakeSubscriptionPaymentMutation__
 *
 * To run a mutation, you first call `useMakeSubscriptionPaymentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMakeSubscriptionPaymentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [makeSubscriptionPaymentMutation, { data, loading, error }] = useMakeSubscriptionPaymentMutation({
 *   variables: {
 *      paymentData: // value for 'paymentData'
 *   },
 * });
 */
export function useMakeSubscriptionPaymentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    MakeSubscriptionPaymentMutation,
    MakeSubscriptionPaymentMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    MakeSubscriptionPaymentMutation,
    MakeSubscriptionPaymentMutationVariables
  >(MakeSubscriptionPaymentDocument, options);
}
export type MakeSubscriptionPaymentMutationHookResult = ReturnType<
  typeof useMakeSubscriptionPaymentMutation
>;
export type MakeSubscriptionPaymentMutationResult =
  Apollo.MutationResult<MakeSubscriptionPaymentMutation>;
export type MakeSubscriptionPaymentMutationOptions = Apollo.BaseMutationOptions<
  MakeSubscriptionPaymentMutation,
  MakeSubscriptionPaymentMutationVariables
>;
export const MarkMessageAsReadDocument = gql`
  mutation MarkMessageAsRead($params: MarkMessageAsReadInput!) {
    markMessageAsRead(params: $params) {
      message
    }
  }
`;
export type MarkMessageAsReadMutationFn = Apollo.MutationFunction<
  MarkMessageAsReadMutation,
  MarkMessageAsReadMutationVariables
>;

/**
 * __useMarkMessageAsReadMutation__
 *
 * To run a mutation, you first call `useMarkMessageAsReadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarkMessageAsReadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [markMessageAsReadMutation, { data, loading, error }] = useMarkMessageAsReadMutation({
 *   variables: {
 *      params: // value for 'params'
 *   },
 * });
 */
export function useMarkMessageAsReadMutation(
  baseOptions?: Apollo.MutationHookOptions<
    MarkMessageAsReadMutation,
    MarkMessageAsReadMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    MarkMessageAsReadMutation,
    MarkMessageAsReadMutationVariables
  >(MarkMessageAsReadDocument, options);
}
export type MarkMessageAsReadMutationHookResult = ReturnType<
  typeof useMarkMessageAsReadMutation
>;
export type MarkMessageAsReadMutationResult =
  Apollo.MutationResult<MarkMessageAsReadMutation>;
export type MarkMessageAsReadMutationOptions = Apollo.BaseMutationOptions<
  MarkMessageAsReadMutation,
  MarkMessageAsReadMutationVariables
>;
export const RecordTransactionDocument = gql`
  mutation RecordTransaction($input: [RecordTransactionInput!]!) {
    record_transaction(input: $input) {
      affected_rows
      returning {
        transaction_ids
        transactions_map {
          ledger_account_id
          transaction_id
        }
      }
    }
  }
`;
export type RecordTransactionMutationFn = Apollo.MutationFunction<
  RecordTransactionMutation,
  RecordTransactionMutationVariables
>;

/**
 * __useRecordTransactionMutation__
 *
 * To run a mutation, you first call `useRecordTransactionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRecordTransactionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [recordTransactionMutation, { data, loading, error }] = useRecordTransactionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRecordTransactionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RecordTransactionMutation,
    RecordTransactionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RecordTransactionMutation,
    RecordTransactionMutationVariables
  >(RecordTransactionDocument, options);
}
export type RecordTransactionMutationHookResult = ReturnType<
  typeof useRecordTransactionMutation
>;
export type RecordTransactionMutationResult =
  Apollo.MutationResult<RecordTransactionMutation>;
export type RecordTransactionMutationOptions = Apollo.BaseMutationOptions<
  RecordTransactionMutation,
  RecordTransactionMutationVariables
>;
export const RemoveInvoiceItemsDocument = gql`
  mutation RemoveInvoiceItems($params: RemoveInvoiceItemsInput!) {
    removeInvoiceItems(params: $params) {
      message
    }
  }
`;
export type RemoveInvoiceItemsMutationFn = Apollo.MutationFunction<
  RemoveInvoiceItemsMutation,
  RemoveInvoiceItemsMutationVariables
>;

/**
 * __useRemoveInvoiceItemsMutation__
 *
 * To run a mutation, you first call `useRemoveInvoiceItemsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveInvoiceItemsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeInvoiceItemsMutation, { data, loading, error }] = useRemoveInvoiceItemsMutation({
 *   variables: {
 *      params: // value for 'params'
 *   },
 * });
 */
export function useRemoveInvoiceItemsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveInvoiceItemsMutation,
    RemoveInvoiceItemsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RemoveInvoiceItemsMutation,
    RemoveInvoiceItemsMutationVariables
  >(RemoveInvoiceItemsDocument, options);
}
export type RemoveInvoiceItemsMutationHookResult = ReturnType<
  typeof useRemoveInvoiceItemsMutation
>;
export type RemoveInvoiceItemsMutationResult =
  Apollo.MutationResult<RemoveInvoiceItemsMutation>;
export type RemoveInvoiceItemsMutationOptions = Apollo.BaseMutationOptions<
  RemoveInvoiceItemsMutation,
  RemoveInvoiceItemsMutationVariables
>;
export const RescheduleSubscriptionInvoiceDocument = gql`
  mutation RescheduleSubscriptionInvoice($params: RescheduleInvoiceInput!) {
    rescheduleInvoice(params: $params) {
      message
    }
  }
`;
export type RescheduleSubscriptionInvoiceMutationFn = Apollo.MutationFunction<
  RescheduleSubscriptionInvoiceMutation,
  RescheduleSubscriptionInvoiceMutationVariables
>;

/**
 * __useRescheduleSubscriptionInvoiceMutation__
 *
 * To run a mutation, you first call `useRescheduleSubscriptionInvoiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRescheduleSubscriptionInvoiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [rescheduleSubscriptionInvoiceMutation, { data, loading, error }] = useRescheduleSubscriptionInvoiceMutation({
 *   variables: {
 *      params: // value for 'params'
 *   },
 * });
 */
export function useRescheduleSubscriptionInvoiceMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RescheduleSubscriptionInvoiceMutation,
    RescheduleSubscriptionInvoiceMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RescheduleSubscriptionInvoiceMutation,
    RescheduleSubscriptionInvoiceMutationVariables
  >(RescheduleSubscriptionInvoiceDocument, options);
}
export type RescheduleSubscriptionInvoiceMutationHookResult = ReturnType<
  typeof useRescheduleSubscriptionInvoiceMutation
>;
export type RescheduleSubscriptionInvoiceMutationResult =
  Apollo.MutationResult<RescheduleSubscriptionInvoiceMutation>;
export type RescheduleSubscriptionInvoiceMutationOptions =
  Apollo.BaseMutationOptions<
    RescheduleSubscriptionInvoiceMutation,
    RescheduleSubscriptionInvoiceMutationVariables
  >;
export const SendAdHocMessageDocument = gql`
  mutation SendAdHocMessage($params: SendAdHocMessageInput!) {
    sendAdHocMessage(params: $params) {
      message
    }
  }
`;
export type SendAdHocMessageMutationFn = Apollo.MutationFunction<
  SendAdHocMessageMutation,
  SendAdHocMessageMutationVariables
>;

/**
 * __useSendAdHocMessageMutation__
 *
 * To run a mutation, you first call `useSendAdHocMessageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendAdHocMessageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendAdHocMessageMutation, { data, loading, error }] = useSendAdHocMessageMutation({
 *   variables: {
 *      params: // value for 'params'
 *   },
 * });
 */
export function useSendAdHocMessageMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SendAdHocMessageMutation,
    SendAdHocMessageMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SendAdHocMessageMutation,
    SendAdHocMessageMutationVariables
  >(SendAdHocMessageDocument, options);
}
export type SendAdHocMessageMutationHookResult = ReturnType<
  typeof useSendAdHocMessageMutation
>;
export type SendAdHocMessageMutationResult =
  Apollo.MutationResult<SendAdHocMessageMutation>;
export type SendAdHocMessageMutationOptions = Apollo.BaseMutationOptions<
  SendAdHocMessageMutation,
  SendAdHocMessageMutationVariables
>;
export const SendMessageDocument = gql`
  mutation SendMessage($params: SendMessageInput!) {
    sendMessage(params: $params) {
      message
    }
  }
`;
export type SendMessageMutationFn = Apollo.MutationFunction<
  SendMessageMutation,
  SendMessageMutationVariables
>;

/**
 * __useSendMessageMutation__
 *
 * To run a mutation, you first call `useSendMessageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendMessageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendMessageMutation, { data, loading, error }] = useSendMessageMutation({
 *   variables: {
 *      params: // value for 'params'
 *   },
 * });
 */
export function useSendMessageMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SendMessageMutation,
    SendMessageMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SendMessageMutation, SendMessageMutationVariables>(
    SendMessageDocument,
    options
  );
}
export type SendMessageMutationHookResult = ReturnType<
  typeof useSendMessageMutation
>;
export type SendMessageMutationResult =
  Apollo.MutationResult<SendMessageMutation>;
export type SendMessageMutationOptions = Apollo.BaseMutationOptions<
  SendMessageMutation,
  SendMessageMutationVariables
>;
export const SignupDocument = gql`
  mutation Signup($params: SignupInput!) {
    signup(params: $params) {
      data
      message
      type
    }
  }
`;
export type SignupMutationFn = Apollo.MutationFunction<
  SignupMutation,
  SignupMutationVariables
>;

/**
 * __useSignupMutation__
 *
 * To run a mutation, you first call `useSignupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signupMutation, { data, loading, error }] = useSignupMutation({
 *   variables: {
 *      params: // value for 'params'
 *   },
 * });
 */
export function useSignupMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SignupMutation,
    SignupMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SignupMutation, SignupMutationVariables>(
    SignupDocument,
    options
  );
}
export type SignupMutationHookResult = ReturnType<typeof useSignupMutation>;
export type SignupMutationResult = Apollo.MutationResult<SignupMutation>;
export type SignupMutationOptions = Apollo.BaseMutationOptions<
  SignupMutation,
  SignupMutationVariables
>;
export const SubmitWorkflowDocument = gql`
  mutation SubmitWorkflow($params: ProcessWorkflowSubmissionInput!) {
    processWorkflowSubmission(params: $params) {
      partyId
    }
  }
`;
export type SubmitWorkflowMutationFn = Apollo.MutationFunction<
  SubmitWorkflowMutation,
  SubmitWorkflowMutationVariables
>;

/**
 * __useSubmitWorkflowMutation__
 *
 * To run a mutation, you first call `useSubmitWorkflowMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubmitWorkflowMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [submitWorkflowMutation, { data, loading, error }] = useSubmitWorkflowMutation({
 *   variables: {
 *      params: // value for 'params'
 *   },
 * });
 */
export function useSubmitWorkflowMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SubmitWorkflowMutation,
    SubmitWorkflowMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SubmitWorkflowMutation,
    SubmitWorkflowMutationVariables
  >(SubmitWorkflowDocument, options);
}
export type SubmitWorkflowMutationHookResult = ReturnType<
  typeof useSubmitWorkflowMutation
>;
export type SubmitWorkflowMutationResult =
  Apollo.MutationResult<SubmitWorkflowMutation>;
export type SubmitWorkflowMutationOptions = Apollo.BaseMutationOptions<
  SubmitWorkflowMutation,
  SubmitWorkflowMutationVariables
>;
export const UpdateAccountDocument = gql`
  mutation UpdateAccount(
    $partyId: uuid!
    $email: String!
    $name: String!
    $nickname: String
    $phone_number: String!
    $avatar: String
    $location: String!
    $locale: String
  ) {
    update_parties_by_pk(
      pk_columns: { id: $partyId }
      _set: {
        avatar: $avatar
        email: $email
        locale: $locale
        location: $location
        name: $name
        nickname: $nickname
        phone_number: $phone_number
      }
    ) {
      id
      updated_at
    }
    update_users(
      _set: { email: $email, name: $name, phone_number: $phone_number }
      where: { party_id: { _eq: $partyId } }
    ) {
      affected_rows
      returning {
        id
        updated_at
      }
    }
  }
`;
export type UpdateAccountMutationFn = Apollo.MutationFunction<
  UpdateAccountMutation,
  UpdateAccountMutationVariables
>;

/**
 * __useUpdateAccountMutation__
 *
 * To run a mutation, you first call `useUpdateAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAccountMutation, { data, loading, error }] = useUpdateAccountMutation({
 *   variables: {
 *      partyId: // value for 'partyId'
 *      email: // value for 'email'
 *      name: // value for 'name'
 *      nickname: // value for 'nickname'
 *      phone_number: // value for 'phone_number'
 *      avatar: // value for 'avatar'
 *      location: // value for 'location'
 *      locale: // value for 'locale'
 *   },
 * });
 */
export function useUpdateAccountMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateAccountMutation,
    UpdateAccountMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateAccountMutation,
    UpdateAccountMutationVariables
  >(UpdateAccountDocument, options);
}
export type UpdateAccountMutationHookResult = ReturnType<
  typeof useUpdateAccountMutation
>;
export type UpdateAccountMutationResult =
  Apollo.MutationResult<UpdateAccountMutation>;
export type UpdateAccountMutationOptions = Apollo.BaseMutationOptions<
  UpdateAccountMutation,
  UpdateAccountMutationVariables
>;
export const UpdateInvoiceDocument = gql`
  mutation UpdateInvoice($params: UpdateInvoiceInput!) {
    updateInvoice(params: $params) {
      message
    }
  }
`;
export type UpdateInvoiceMutationFn = Apollo.MutationFunction<
  UpdateInvoiceMutation,
  UpdateInvoiceMutationVariables
>;

/**
 * __useUpdateInvoiceMutation__
 *
 * To run a mutation, you first call `useUpdateInvoiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateInvoiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateInvoiceMutation, { data, loading, error }] = useUpdateInvoiceMutation({
 *   variables: {
 *      params: // value for 'params'
 *   },
 * });
 */
export function useUpdateInvoiceMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateInvoiceMutation,
    UpdateInvoiceMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateInvoiceMutation,
    UpdateInvoiceMutationVariables
  >(UpdateInvoiceDocument, options);
}
export type UpdateInvoiceMutationHookResult = ReturnType<
  typeof useUpdateInvoiceMutation
>;
export type UpdateInvoiceMutationResult =
  Apollo.MutationResult<UpdateInvoiceMutation>;
export type UpdateInvoiceMutationOptions = Apollo.BaseMutationOptions<
  UpdateInvoiceMutation,
  UpdateInvoiceMutationVariables
>;
export const UpdateCustomerManagementSettingDocument = gql`
  mutation UpdateCustomerManagementSetting(
    $settingId: uuid!
    $newValue: String!
  ) {
    update_organisation_settings_by_pk(
      pk_columns: { id: $settingId }
      _set: { value: $newValue }
    ) {
      updated_at
    }
  }
`;
export type UpdateCustomerManagementSettingMutationFn = Apollo.MutationFunction<
  UpdateCustomerManagementSettingMutation,
  UpdateCustomerManagementSettingMutationVariables
>;

/**
 * __useUpdateCustomerManagementSettingMutation__
 *
 * To run a mutation, you first call `useUpdateCustomerManagementSettingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCustomerManagementSettingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCustomerManagementSettingMutation, { data, loading, error }] = useUpdateCustomerManagementSettingMutation({
 *   variables: {
 *      settingId: // value for 'settingId'
 *      newValue: // value for 'newValue'
 *   },
 * });
 */
export function useUpdateCustomerManagementSettingMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateCustomerManagementSettingMutation,
    UpdateCustomerManagementSettingMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateCustomerManagementSettingMutation,
    UpdateCustomerManagementSettingMutationVariables
  >(UpdateCustomerManagementSettingDocument, options);
}
export type UpdateCustomerManagementSettingMutationHookResult = ReturnType<
  typeof useUpdateCustomerManagementSettingMutation
>;
export type UpdateCustomerManagementSettingMutationResult =
  Apollo.MutationResult<UpdateCustomerManagementSettingMutation>;
export type UpdateCustomerManagementSettingMutationOptions =
  Apollo.BaseMutationOptions<
    UpdateCustomerManagementSettingMutation,
    UpdateCustomerManagementSettingMutationVariables
  >;
export const UpdateLoanProductInfoDocument = gql`
  mutation UpdateLoanProductInfo(
    $productId: uuid!
    $productInfo: products_set_input!
  ) {
    update_products_by_pk(_set: $productInfo, pk_columns: { id: $productId }) {
      updated_at
    }
  }
`;
export type UpdateLoanProductInfoMutationFn = Apollo.MutationFunction<
  UpdateLoanProductInfoMutation,
  UpdateLoanProductInfoMutationVariables
>;

/**
 * __useUpdateLoanProductInfoMutation__
 *
 * To run a mutation, you first call `useUpdateLoanProductInfoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateLoanProductInfoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateLoanProductInfoMutation, { data, loading, error }] = useUpdateLoanProductInfoMutation({
 *   variables: {
 *      productId: // value for 'productId'
 *      productInfo: // value for 'productInfo'
 *   },
 * });
 */
export function useUpdateLoanProductInfoMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateLoanProductInfoMutation,
    UpdateLoanProductInfoMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateLoanProductInfoMutation,
    UpdateLoanProductInfoMutationVariables
  >(UpdateLoanProductInfoDocument, options);
}
export type UpdateLoanProductInfoMutationHookResult = ReturnType<
  typeof useUpdateLoanProductInfoMutation
>;
export type UpdateLoanProductInfoMutationResult =
  Apollo.MutationResult<UpdateLoanProductInfoMutation>;
export type UpdateLoanProductInfoMutationOptions = Apollo.BaseMutationOptions<
  UpdateLoanProductInfoMutation,
  UpdateLoanProductInfoMutationVariables
>;
export const UpdateProductItemsDocument = gql`
  mutation UpdateProductItems(
    $productItemsData: [product_items_updates!]!
    $productItemTariffData: [tariffs_updates!]!
  ) {
    update_product_items_many(updates: $productItemsData) {
      affected_rows
    }
    update_tariffs_many(updates: $productItemTariffData) {
      affected_rows
    }
  }
`;
export type UpdateProductItemsMutationFn = Apollo.MutationFunction<
  UpdateProductItemsMutation,
  UpdateProductItemsMutationVariables
>;

/**
 * __useUpdateProductItemsMutation__
 *
 * To run a mutation, you first call `useUpdateProductItemsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProductItemsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProductItemsMutation, { data, loading, error }] = useUpdateProductItemsMutation({
 *   variables: {
 *      productItemsData: // value for 'productItemsData'
 *      productItemTariffData: // value for 'productItemTariffData'
 *   },
 * });
 */
export function useUpdateProductItemsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateProductItemsMutation,
    UpdateProductItemsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateProductItemsMutation,
    UpdateProductItemsMutationVariables
  >(UpdateProductItemsDocument, options);
}
export type UpdateProductItemsMutationHookResult = ReturnType<
  typeof useUpdateProductItemsMutation
>;
export type UpdateProductItemsMutationResult =
  Apollo.MutationResult<UpdateProductItemsMutation>;
export type UpdateProductItemsMutationOptions = Apollo.BaseMutationOptions<
  UpdateProductItemsMutation,
  UpdateProductItemsMutationVariables
>;
export const UpdateProductSettingsDocument = gql`
  mutation UpdateProductSettings(
    $productSettingsData: [product_settings_updates!]!
  ) {
    update_product_settings_many(updates: $productSettingsData) {
      affected_rows
    }
  }
`;
export type UpdateProductSettingsMutationFn = Apollo.MutationFunction<
  UpdateProductSettingsMutation,
  UpdateProductSettingsMutationVariables
>;

/**
 * __useUpdateProductSettingsMutation__
 *
 * To run a mutation, you first call `useUpdateProductSettingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProductSettingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProductSettingsMutation, { data, loading, error }] = useUpdateProductSettingsMutation({
 *   variables: {
 *      productSettingsData: // value for 'productSettingsData'
 *   },
 * });
 */
export function useUpdateProductSettingsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateProductSettingsMutation,
    UpdateProductSettingsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateProductSettingsMutation,
    UpdateProductSettingsMutationVariables
  >(UpdateProductSettingsDocument, options);
}
export type UpdateProductSettingsMutationHookResult = ReturnType<
  typeof useUpdateProductSettingsMutation
>;
export type UpdateProductSettingsMutationResult =
  Apollo.MutationResult<UpdateProductSettingsMutation>;
export type UpdateProductSettingsMutationOptions = Apollo.BaseMutationOptions<
  UpdateProductSettingsMutation,
  UpdateProductSettingsMutationVariables
>;
export const UpdateRulesAndConditionsDocument = gql`
  mutation UpdateRulesAndConditions(
    $rulesData: [rules_updates!]!
    $conditionsData: [conditions_updates!]!
  ) {
    update_rules_many(updates: $rulesData) {
      affected_rows
    }
    update_conditions_many(updates: $conditionsData) {
      affected_rows
    }
  }
`;
export type UpdateRulesAndConditionsMutationFn = Apollo.MutationFunction<
  UpdateRulesAndConditionsMutation,
  UpdateRulesAndConditionsMutationVariables
>;

/**
 * __useUpdateRulesAndConditionsMutation__
 *
 * To run a mutation, you first call `useUpdateRulesAndConditionsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateRulesAndConditionsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateRulesAndConditionsMutation, { data, loading, error }] = useUpdateRulesAndConditionsMutation({
 *   variables: {
 *      rulesData: // value for 'rulesData'
 *      conditionsData: // value for 'conditionsData'
 *   },
 * });
 */
export function useUpdateRulesAndConditionsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateRulesAndConditionsMutation,
    UpdateRulesAndConditionsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateRulesAndConditionsMutation,
    UpdateRulesAndConditionsMutationVariables
  >(UpdateRulesAndConditionsDocument, options);
}
export type UpdateRulesAndConditionsMutationHookResult = ReturnType<
  typeof useUpdateRulesAndConditionsMutation
>;
export type UpdateRulesAndConditionsMutationResult =
  Apollo.MutationResult<UpdateRulesAndConditionsMutation>;
export type UpdateRulesAndConditionsMutationOptions =
  Apollo.BaseMutationOptions<
    UpdateRulesAndConditionsMutation,
    UpdateRulesAndConditionsMutationVariables
  >;
export const GetActivityStreamDocument = gql`
  query GetActivityStream($party: jsonb) {
    activity_logs(
      limit: 12
      where: { _or: { target: { _contains: $party } } }
      order_by: { event_timestamp: desc }
    ) {
      actor
      created_at
      event_timestamp
      id
      object
      render_template
      target
      verb
    }
  }
`;

/**
 * __useGetActivityStreamQuery__
 *
 * To run a query within a React component, call `useGetActivityStreamQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetActivityStreamQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetActivityStreamQuery({
 *   variables: {
 *      party: // value for 'party'
 *   },
 * });
 */
export function useGetActivityStreamQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetActivityStreamQuery,
    GetActivityStreamQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetActivityStreamQuery,
    GetActivityStreamQueryVariables
  >(GetActivityStreamDocument, options);
}
export function useGetActivityStreamLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetActivityStreamQuery,
    GetActivityStreamQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetActivityStreamQuery,
    GetActivityStreamQueryVariables
  >(GetActivityStreamDocument, options);
}
export type GetActivityStreamQueryHookResult = ReturnType<
  typeof useGetActivityStreamQuery
>;
export type GetActivityStreamLazyQueryHookResult = ReturnType<
  typeof useGetActivityStreamLazyQuery
>;
export type GetActivityStreamQueryResult = Apollo.QueryResult<
  GetActivityStreamQuery,
  GetActivityStreamQueryVariables
>;
export const GetCaseDocumentsDocument = gql`
  query GetCaseDocuments($caseId: uuid!) {
    case_documents(
      where: { case_id: { _eq: $caseId } }
      order_by: { created_at: desc }
    ) {
      id
      document {
        id
        name
        path
        size
        type_id
        party {
          id
          name
        }
        type {
          id
          name
          value
        }
        created_at
      }
      created_at
      case_id
    }
  }
`;

/**
 * __useGetCaseDocumentsQuery__
 *
 * To run a query within a React component, call `useGetCaseDocumentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCaseDocumentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCaseDocumentsQuery({
 *   variables: {
 *      caseId: // value for 'caseId'
 *   },
 * });
 */
export function useGetCaseDocumentsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetCaseDocumentsQuery,
    GetCaseDocumentsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetCaseDocumentsQuery, GetCaseDocumentsQueryVariables>(
    GetCaseDocumentsDocument,
    options
  );
}
export function useGetCaseDocumentsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCaseDocumentsQuery,
    GetCaseDocumentsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetCaseDocumentsQuery,
    GetCaseDocumentsQueryVariables
  >(GetCaseDocumentsDocument, options);
}
export type GetCaseDocumentsQueryHookResult = ReturnType<
  typeof useGetCaseDocumentsQuery
>;
export type GetCaseDocumentsLazyQueryHookResult = ReturnType<
  typeof useGetCaseDocumentsLazyQuery
>;
export type GetCaseDocumentsQueryResult = Apollo.QueryResult<
  GetCaseDocumentsQuery,
  GetCaseDocumentsQueryVariables
>;
export const GetChannelPartiesDocument = gql`
  query GetChannelParties($channelId: uuid!) {
    channel_parties(where: { channel_id: { _eq: $channelId } }) {
      id
      party {
        ...PartyBaseFields
      }
    }
  }
  ${PartyBaseFieldsFragmentDoc}
`;

/**
 * __useGetChannelPartiesQuery__
 *
 * To run a query within a React component, call `useGetChannelPartiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetChannelPartiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetChannelPartiesQuery({
 *   variables: {
 *      channelId: // value for 'channelId'
 *   },
 * });
 */
export function useGetChannelPartiesQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetChannelPartiesQuery,
    GetChannelPartiesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetChannelPartiesQuery,
    GetChannelPartiesQueryVariables
  >(GetChannelPartiesDocument, options);
}
export function useGetChannelPartiesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetChannelPartiesQuery,
    GetChannelPartiesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetChannelPartiesQuery,
    GetChannelPartiesQueryVariables
  >(GetChannelPartiesDocument, options);
}
export type GetChannelPartiesQueryHookResult = ReturnType<
  typeof useGetChannelPartiesQuery
>;
export type GetChannelPartiesLazyQueryHookResult = ReturnType<
  typeof useGetChannelPartiesLazyQuery
>;
export type GetChannelPartiesQueryResult = Apollo.QueryResult<
  GetChannelPartiesQuery,
  GetChannelPartiesQueryVariables
>;
export const GetChannelsDocument = gql`
  query GetChannels($where: channels_bool_exp) {
    channels(
      where: $where
      order_by: { messages_aggregate: { max: { created_at: desc } } }
    ) {
      id
      name
      channel_parties {
        id
        party {
          id
          name
          avatar
        }
      }
      messages(limit: 1, order_by: { created_at: desc }) {
        id
        body
        created_at
        message_delivery_methods {
          delivery_method {
            id
            name
          }
        }
      }
    }
  }
`;

/**
 * __useGetChannelsQuery__
 *
 * To run a query within a React component, call `useGetChannelsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetChannelsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetChannelsQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetChannelsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetChannelsQuery,
    GetChannelsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetChannelsQuery, GetChannelsQueryVariables>(
    GetChannelsDocument,
    options
  );
}
export function useGetChannelsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetChannelsQuery,
    GetChannelsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetChannelsQuery, GetChannelsQueryVariables>(
    GetChannelsDocument,
    options
  );
}
export type GetChannelsQueryHookResult = ReturnType<typeof useGetChannelsQuery>;
export type GetChannelsLazyQueryHookResult = ReturnType<
  typeof useGetChannelsLazyQuery
>;
export type GetChannelsQueryResult = Apollo.QueryResult<
  GetChannelsQuery,
  GetChannelsQueryVariables
>;
export const GetLastReadMessagesDocument = gql`
  query GetLastReadMessages($params: GetLastReadMessagesInput!) {
    getLastReadMessages(params: $params) {
      lastReadMessages
    }
  }
`;

/**
 * __useGetLastReadMessagesQuery__
 *
 * To run a query within a React component, call `useGetLastReadMessagesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLastReadMessagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLastReadMessagesQuery({
 *   variables: {
 *      params: // value for 'params'
 *   },
 * });
 */
export function useGetLastReadMessagesQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetLastReadMessagesQuery,
    GetLastReadMessagesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetLastReadMessagesQuery,
    GetLastReadMessagesQueryVariables
  >(GetLastReadMessagesDocument, options);
}
export function useGetLastReadMessagesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetLastReadMessagesQuery,
    GetLastReadMessagesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetLastReadMessagesQuery,
    GetLastReadMessagesQueryVariables
  >(GetLastReadMessagesDocument, options);
}
export type GetLastReadMessagesQueryHookResult = ReturnType<
  typeof useGetLastReadMessagesQuery
>;
export type GetLastReadMessagesLazyQueryHookResult = ReturnType<
  typeof useGetLastReadMessagesLazyQuery
>;
export type GetLastReadMessagesQueryResult = Apollo.QueryResult<
  GetLastReadMessagesQuery,
  GetLastReadMessagesQueryVariables
>;
export const GetCustomerManagementSettingsDocument = gql`
  query GetCustomerManagementSettings {
    organisation_settings(
      where: { name: { _eq: "CUSTOMER_MANAGEMENT_SETTINGS" } }
    ) {
      id
      name
      value
    }
  }
`;

/**
 * __useGetCustomerManagementSettingsQuery__
 *
 * To run a query within a React component, call `useGetCustomerManagementSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCustomerManagementSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCustomerManagementSettingsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCustomerManagementSettingsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetCustomerManagementSettingsQuery,
    GetCustomerManagementSettingsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetCustomerManagementSettingsQuery,
    GetCustomerManagementSettingsQueryVariables
  >(GetCustomerManagementSettingsDocument, options);
}
export function useGetCustomerManagementSettingsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCustomerManagementSettingsQuery,
    GetCustomerManagementSettingsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetCustomerManagementSettingsQuery,
    GetCustomerManagementSettingsQueryVariables
  >(GetCustomerManagementSettingsDocument, options);
}
export type GetCustomerManagementSettingsQueryHookResult = ReturnType<
  typeof useGetCustomerManagementSettingsQuery
>;
export type GetCustomerManagementSettingsLazyQueryHookResult = ReturnType<
  typeof useGetCustomerManagementSettingsLazyQuery
>;
export type GetCustomerManagementSettingsQueryResult = Apollo.QueryResult<
  GetCustomerManagementSettingsQuery,
  GetCustomerManagementSettingsQueryVariables
>;
export const GetDocumentNotesDocument = gql`
  query GetDocumentNotes($documentId: uuid!) {
    document_interactions(where: { document_id: { _eq: $documentId } }) {
      id
      interaction {
        id
        created_at
        party {
          id
          avatar
          name
          nickname
        }
        interaction_data {
          id
          form_field {
            id
            field_name
          }
          value
        }
      }
      created_at
    }
  }
`;

/**
 * __useGetDocumentNotesQuery__
 *
 * To run a query within a React component, call `useGetDocumentNotesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDocumentNotesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDocumentNotesQuery({
 *   variables: {
 *      documentId: // value for 'documentId'
 *   },
 * });
 */
export function useGetDocumentNotesQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetDocumentNotesQuery,
    GetDocumentNotesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetDocumentNotesQuery, GetDocumentNotesQueryVariables>(
    GetDocumentNotesDocument,
    options
  );
}
export function useGetDocumentNotesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetDocumentNotesQuery,
    GetDocumentNotesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetDocumentNotesQuery,
    GetDocumentNotesQueryVariables
  >(GetDocumentNotesDocument, options);
}
export type GetDocumentNotesQueryHookResult = ReturnType<
  typeof useGetDocumentNotesQuery
>;
export type GetDocumentNotesLazyQueryHookResult = ReturnType<
  typeof useGetDocumentNotesLazyQuery
>;
export type GetDocumentNotesQueryResult = Apollo.QueryResult<
  GetDocumentNotesQuery,
  GetDocumentNotesQueryVariables
>;
export const GetDoDGrowthDocument = gql`
  query GetDoDGrowth($fromDate: date, $toDate: date) {
    customer_daily_growth(args: { start_date: $fromDate, stop_date: $toDate }) {
      day
      previous_day_count
      current_day_count
      growth
    }
    new_cases_daily_growth(
      args: { start_date: $fromDate, stop_date: $toDate }
    ) {
      day
      previous_day_count
      current_day_count
      growth
    }
  }
`;

/**
 * __useGetDoDGrowthQuery__
 *
 * To run a query within a React component, call `useGetDoDGrowthQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDoDGrowthQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDoDGrowthQuery({
 *   variables: {
 *      fromDate: // value for 'fromDate'
 *      toDate: // value for 'toDate'
 *   },
 * });
 */
export function useGetDoDGrowthQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetDoDGrowthQuery,
    GetDoDGrowthQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetDoDGrowthQuery, GetDoDGrowthQueryVariables>(
    GetDoDGrowthDocument,
    options
  );
}
export function useGetDoDGrowthLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetDoDGrowthQuery,
    GetDoDGrowthQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetDoDGrowthQuery, GetDoDGrowthQueryVariables>(
    GetDoDGrowthDocument,
    options
  );
}
export type GetDoDGrowthQueryHookResult = ReturnType<
  typeof useGetDoDGrowthQuery
>;
export type GetDoDGrowthLazyQueryHookResult = ReturnType<
  typeof useGetDoDGrowthLazyQuery
>;
export type GetDoDGrowthQueryResult = Apollo.QueryResult<
  GetDoDGrowthQuery,
  GetDoDGrowthQueryVariables
>;
export const GetFormByIdDocument = gql`
  query GetFormById($id: uuid!) {
    forms_by_pk(id: $id) {
      ...FormQueryOptions
    }
  }
  ${FormQueryOptionsFragmentDoc}
`;

/**
 * __useGetFormByIdQuery__
 *
 * To run a query within a React component, call `useGetFormByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFormByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFormByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetFormByIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetFormByIdQuery,
    GetFormByIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetFormByIdQuery, GetFormByIdQueryVariables>(
    GetFormByIdDocument,
    options
  );
}
export function useGetFormByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetFormByIdQuery,
    GetFormByIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetFormByIdQuery, GetFormByIdQueryVariables>(
    GetFormByIdDocument,
    options
  );
}
export type GetFormByIdQueryHookResult = ReturnType<typeof useGetFormByIdQuery>;
export type GetFormByIdLazyQueryHookResult = ReturnType<
  typeof useGetFormByIdLazyQuery
>;
export type GetFormByIdQueryResult = Apollo.QueryResult<
  GetFormByIdQuery,
  GetFormByIdQueryVariables
>;
export const GetFormsByNameDocument = gql`
  query GetFormsByName($formNames: [String!]!) {
    forms(where: { name: { _in: $formNames } }) {
      ...FormQueryOptions
    }
  }
  ${FormQueryOptionsFragmentDoc}
`;

/**
 * __useGetFormsByNameQuery__
 *
 * To run a query within a React component, call `useGetFormsByNameQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFormsByNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFormsByNameQuery({
 *   variables: {
 *      formNames: // value for 'formNames'
 *   },
 * });
 */
export function useGetFormsByNameQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetFormsByNameQuery,
    GetFormsByNameQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetFormsByNameQuery, GetFormsByNameQueryVariables>(
    GetFormsByNameDocument,
    options
  );
}
export function useGetFormsByNameLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetFormsByNameQuery,
    GetFormsByNameQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetFormsByNameQuery, GetFormsByNameQueryVariables>(
    GetFormsByNameDocument,
    options
  );
}
export type GetFormsByNameQueryHookResult = ReturnType<
  typeof useGetFormsByNameQuery
>;
export type GetFormsByNameLazyQueryHookResult = ReturnType<
  typeof useGetFormsByNameLazyQuery
>;
export type GetFormsByNameQueryResult = Apollo.QueryResult<
  GetFormsByNameQuery,
  GetFormsByNameQueryVariables
>;
export const GetInvoiceNotesDocument = gql`
  query GetInvoiceNotes($invoiceId: uuid!) {
    invoice_interactions(where: { invoice_id: { _eq: $invoiceId } }) {
      id
      interaction {
        id
        created_at
        party {
          id
          avatar
          name
          nickname
        }
        interaction_data {
          id
          form_field {
            id
            field_name
          }
          value
        }
      }
      created_at
    }
  }
`;

/**
 * __useGetInvoiceNotesQuery__
 *
 * To run a query within a React component, call `useGetInvoiceNotesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInvoiceNotesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInvoiceNotesQuery({
 *   variables: {
 *      invoiceId: // value for 'invoiceId'
 *   },
 * });
 */
export function useGetInvoiceNotesQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetInvoiceNotesQuery,
    GetInvoiceNotesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetInvoiceNotesQuery, GetInvoiceNotesQueryVariables>(
    GetInvoiceNotesDocument,
    options
  );
}
export function useGetInvoiceNotesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetInvoiceNotesQuery,
    GetInvoiceNotesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetInvoiceNotesQuery,
    GetInvoiceNotesQueryVariables
  >(GetInvoiceNotesDocument, options);
}
export type GetInvoiceNotesQueryHookResult = ReturnType<
  typeof useGetInvoiceNotesQuery
>;
export type GetInvoiceNotesLazyQueryHookResult = ReturnType<
  typeof useGetInvoiceNotesLazyQuery
>;
export type GetInvoiceNotesQueryResult = Apollo.QueryResult<
  GetInvoiceNotesQuery,
  GetInvoiceNotesQueryVariables
>;
export const GetInvoicesDocument = gql`
  query GetInvoices(
    $where: invoices_bool_exp
    $limit: Int = 100
    $offset: Int = 0
  ) {
    invoices(
      where: $where
      order_by: { due_at: desc }
      limit: $limit
      offset: $offset
    ) {
      id
      amount_due
      due_at
      created_at
      updated_at
      metadata
      subscription_id
      invoice_items {
        id
        quantity
        amount
        product_id
        product_item {
          id
          name
          product_id
          tariff {
            value
          }
        }
      }
      subscription_payments {
        id
        amount
        payment_date
        subscription_balance
      }
      party {
        id
        name
        type_id
      }
      product {
        id
        name
      }
    }
  }
`;

/**
 * __useGetInvoicesQuery__
 *
 * To run a query within a React component, call `useGetInvoicesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInvoicesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInvoicesQuery({
 *   variables: {
 *      where: // value for 'where'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useGetInvoicesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetInvoicesQuery,
    GetInvoicesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetInvoicesQuery, GetInvoicesQueryVariables>(
    GetInvoicesDocument,
    options
  );
}
export function useGetInvoicesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetInvoicesQuery,
    GetInvoicesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetInvoicesQuery, GetInvoicesQueryVariables>(
    GetInvoicesDocument,
    options
  );
}
export type GetInvoicesQueryHookResult = ReturnType<typeof useGetInvoicesQuery>;
export type GetInvoicesLazyQueryHookResult = ReturnType<
  typeof useGetInvoicesLazyQuery
>;
export type GetInvoicesQueryResult = Apollo.QueryResult<
  GetInvoicesQuery,
  GetInvoicesQueryVariables
>;
export const GetLedgerAccountsSummaryDocument = gql`
  query GetLedgerAccountsSummary($ledgerAccountIds: [uuid!]!) {
    ledger_entries_by_ledger_account_ids(
      ledger_account_ids: $ledgerAccountIds
    ) {
      summary {
        total_debits
        total_credits
        transaction_count
      }
    }
  }
`;

/**
 * __useGetLedgerAccountsSummaryQuery__
 *
 * To run a query within a React component, call `useGetLedgerAccountsSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLedgerAccountsSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLedgerAccountsSummaryQuery({
 *   variables: {
 *      ledgerAccountIds: // value for 'ledgerAccountIds'
 *   },
 * });
 */
export function useGetLedgerAccountsSummaryQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetLedgerAccountsSummaryQuery,
    GetLedgerAccountsSummaryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetLedgerAccountsSummaryQuery,
    GetLedgerAccountsSummaryQueryVariables
  >(GetLedgerAccountsSummaryDocument, options);
}
export function useGetLedgerAccountsSummaryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetLedgerAccountsSummaryQuery,
    GetLedgerAccountsSummaryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetLedgerAccountsSummaryQuery,
    GetLedgerAccountsSummaryQueryVariables
  >(GetLedgerAccountsSummaryDocument, options);
}
export type GetLedgerAccountsSummaryQueryHookResult = ReturnType<
  typeof useGetLedgerAccountsSummaryQuery
>;
export type GetLedgerAccountsSummaryLazyQueryHookResult = ReturnType<
  typeof useGetLedgerAccountsSummaryLazyQuery
>;
export type GetLedgerAccountsSummaryQueryResult = Apollo.QueryResult<
  GetLedgerAccountsSummaryQuery,
  GetLedgerAccountsSummaryQueryVariables
>;
export const GetLedgerAccountTransactionsDocument = gql`
  query GetLedgerAccountTransactions($ledgerAccountId: uuid!) {
    ledger_entries_by_ledger_account_id(ledger_account_id: $ledgerAccountId) {
      amount
      description
      ledger_entry_id
      ledger_transaction_id
      ledger_account_id
      entry_type
      external_reference
      posted_at
      effective_date
      is_voided
      status {
        id
        name
      }
      type {
        id
        name
      }
    }
  }
`;

/**
 * __useGetLedgerAccountTransactionsQuery__
 *
 * To run a query within a React component, call `useGetLedgerAccountTransactionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLedgerAccountTransactionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLedgerAccountTransactionsQuery({
 *   variables: {
 *      ledgerAccountId: // value for 'ledgerAccountId'
 *   },
 * });
 */
export function useGetLedgerAccountTransactionsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetLedgerAccountTransactionsQuery,
    GetLedgerAccountTransactionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetLedgerAccountTransactionsQuery,
    GetLedgerAccountTransactionsQueryVariables
  >(GetLedgerAccountTransactionsDocument, options);
}
export function useGetLedgerAccountTransactionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetLedgerAccountTransactionsQuery,
    GetLedgerAccountTransactionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetLedgerAccountTransactionsQuery,
    GetLedgerAccountTransactionsQueryVariables
  >(GetLedgerAccountTransactionsDocument, options);
}
export type GetLedgerAccountTransactionsQueryHookResult = ReturnType<
  typeof useGetLedgerAccountTransactionsQuery
>;
export type GetLedgerAccountTransactionsLazyQueryHookResult = ReturnType<
  typeof useGetLedgerAccountTransactionsLazyQuery
>;
export type GetLedgerAccountTransactionsQueryResult = Apollo.QueryResult<
  GetLedgerAccountTransactionsQuery,
  GetLedgerAccountTransactionsQueryVariables
>;
export const GetLedgerAccountsTransactionsDocument = gql`
  query GetLedgerAccountsTransactions(
    $ledgerAccountIds: [uuid!]!
    $limit: Int = 25
    $offset: Int = 0
  ) {
    ledger_entries_by_ledger_account_ids(
      ledger_account_ids: $ledgerAccountIds
      limit: $limit
      offset: $offset
    ) {
      entries {
        amount
        description
        ledger_entry_id
        ledger_transaction_id
        ledger_account_id
        entry_type
        external_reference
        posted_at
        effective_date
        is_voided
        status {
          id
          name
        }
        type {
          id
          name
        }
        created_at
        updated_at
      }
      summary {
        total_debits
        total_credits
        transaction_count
      }
      ...TransactionEntriesPagination
    }
  }
  ${TransactionEntriesPaginationFragmentDoc}
`;

/**
 * __useGetLedgerAccountsTransactionsQuery__
 *
 * To run a query within a React component, call `useGetLedgerAccountsTransactionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLedgerAccountsTransactionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLedgerAccountsTransactionsQuery({
 *   variables: {
 *      ledgerAccountIds: // value for 'ledgerAccountIds'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useGetLedgerAccountsTransactionsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetLedgerAccountsTransactionsQuery,
    GetLedgerAccountsTransactionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetLedgerAccountsTransactionsQuery,
    GetLedgerAccountsTransactionsQueryVariables
  >(GetLedgerAccountsTransactionsDocument, options);
}
export function useGetLedgerAccountsTransactionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetLedgerAccountsTransactionsQuery,
    GetLedgerAccountsTransactionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetLedgerAccountsTransactionsQuery,
    GetLedgerAccountsTransactionsQueryVariables
  >(GetLedgerAccountsTransactionsDocument, options);
}
export type GetLedgerAccountsTransactionsQueryHookResult = ReturnType<
  typeof useGetLedgerAccountsTransactionsQuery
>;
export type GetLedgerAccountsTransactionsLazyQueryHookResult = ReturnType<
  typeof useGetLedgerAccountsTransactionsLazyQuery
>;
export type GetLedgerAccountsTransactionsQueryResult = Apollo.QueryResult<
  GetLedgerAccountsTransactionsQuery,
  GetLedgerAccountsTransactionsQueryVariables
>;
export const GetLedgerEntriesDocument = gql`
  query GetLedgerEntries(
    $ledgerAccountIds: [uuid!]
    $where: ledger_entries_bool_exp
  ) {
    ledger_entries_by_ledger_account_ids(
      ledger_account_ids: $ledgerAccountIds
      where: $where
      limit: 10000
    ) {
      entries {
        ledger_account_id
        ledger_entry_id
        amount
        entry_type
        description
        effective_date
        posted_at
        created_at
        external_reference
        status {
          id
          name
          value
        }
        subscription_items {
          id
          product_item {
            id
            name
          }
        }
      }
    }
  }
`;

/**
 * __useGetLedgerEntriesQuery__
 *
 * To run a query within a React component, call `useGetLedgerEntriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLedgerEntriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLedgerEntriesQuery({
 *   variables: {
 *      ledgerAccountIds: // value for 'ledgerAccountIds'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetLedgerEntriesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetLedgerEntriesQuery,
    GetLedgerEntriesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetLedgerEntriesQuery, GetLedgerEntriesQueryVariables>(
    GetLedgerEntriesDocument,
    options
  );
}
export function useGetLedgerEntriesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetLedgerEntriesQuery,
    GetLedgerEntriesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetLedgerEntriesQuery,
    GetLedgerEntriesQueryVariables
  >(GetLedgerEntriesDocument, options);
}
export type GetLedgerEntriesQueryHookResult = ReturnType<
  typeof useGetLedgerEntriesQuery
>;
export type GetLedgerEntriesLazyQueryHookResult = ReturnType<
  typeof useGetLedgerEntriesLazyQuery
>;
export type GetLedgerEntriesQueryResult = Apollo.QueryResult<
  GetLedgerEntriesQuery,
  GetLedgerEntriesQueryVariables
>;
export const GetLoanAccountDocument = gql`
  query GetLoanAccount($id: uuid!) {
    loan_account: subscriptions_by_pk(id: $id) {
      ...LoanAccountBaseFields
    }
  }
  ${LoanAccountBaseFieldsFragmentDoc}
`;

/**
 * __useGetLoanAccountQuery__
 *
 * To run a query within a React component, call `useGetLoanAccountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLoanAccountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLoanAccountQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetLoanAccountQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetLoanAccountQuery,
    GetLoanAccountQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetLoanAccountQuery, GetLoanAccountQueryVariables>(
    GetLoanAccountDocument,
    options
  );
}
export function useGetLoanAccountLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetLoanAccountQuery,
    GetLoanAccountQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetLoanAccountQuery, GetLoanAccountQueryVariables>(
    GetLoanAccountDocument,
    options
  );
}
export type GetLoanAccountQueryHookResult = ReturnType<
  typeof useGetLoanAccountQuery
>;
export type GetLoanAccountLazyQueryHookResult = ReturnType<
  typeof useGetLoanAccountLazyQuery
>;
export type GetLoanAccountQueryResult = Apollo.QueryResult<
  GetLoanAccountQuery,
  GetLoanAccountQueryVariables
>;
export const GetLoanBookDocument = gql`
  query GetLoanBook($productId: uuid!) {
    loan_book: subscriptions(where: { product_id: { _eq: $productId } }) {
      id
      party {
        id
        name
        type_id
      }
      created_at
      due_date: subscription_end
      principal: subscription_items(
        where: { product_item: { type: { value: { _eq: "LOAN_PRINCIPAL" } } } }
      ) {
        id
        quantity
        product_item {
          id
          type {
            id
            name
            value
          }
        }
      }
      interest: subscription_schedules_aggregate {
        aggregate {
          sum {
            interest
          }
        }
      }
      penalty: subscription_items(
        where: { product_item: { type: { value: { _eq: "PENALTY" } } } }
      ) {
        id
        invoice_items(limit: 1, order_by: { created_at: desc }) {
          id
          quantity
          amount
        }
      }
      current_status: subscription_statuses(
        limit: 1
        order_by: { created_at: desc }
      ) {
        id
        status {
          id
          name
          value
        }
      }
      disbursed: subscription_statuses(
        limit: 1
        where: { status: { value: { _eq: "DISBURSED" } } }
      ) {
        id
        created_at
      }
      subscription_payments(order_by: { created_at: desc }) {
        id
        subscription_balance
      }
      amount_received: subscription_payments_aggregate {
        aggregate {
          sum {
            amount
          }
        }
      }
      loan_items: subscription_items {
        ...SubscriptionItemFields
      }
    }
  }
  ${SubscriptionItemFieldsFragmentDoc}
`;

/**
 * __useGetLoanBookQuery__
 *
 * To run a query within a React component, call `useGetLoanBookQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLoanBookQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLoanBookQuery({
 *   variables: {
 *      productId: // value for 'productId'
 *   },
 * });
 */
export function useGetLoanBookQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetLoanBookQuery,
    GetLoanBookQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetLoanBookQuery, GetLoanBookQueryVariables>(
    GetLoanBookDocument,
    options
  );
}
export function useGetLoanBookLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetLoanBookQuery,
    GetLoanBookQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetLoanBookQuery, GetLoanBookQueryVariables>(
    GetLoanBookDocument,
    options
  );
}
export type GetLoanBookQueryHookResult = ReturnType<typeof useGetLoanBookQuery>;
export type GetLoanBookLazyQueryHookResult = ReturnType<
  typeof useGetLoanBookLazyQuery
>;
export type GetLoanBookQueryResult = Apollo.QueryResult<
  GetLoanBookQuery,
  GetLoanBookQueryVariables
>;
export const GetLoanProductByIdDocument = gql`
  query GetLoanProductById($id: uuid!) {
    loan_product: products_by_pk(id: $id) {
      ...LoanProductBaseFields
      product_rules {
        id
        rule {
          ...RuleFields
        }
      }
      subscriptions(order_by: { created_at: asc }) {
        id
        party {
          id
          name
        }
        created_at
      }
    }
    loanProductSummary(productId: $id) {
      disbursedCount
      disbursedAmount
      currentLoans
      amountInDefault
      floatBalance
    }
  }
  ${LoanProductBaseFieldsFragmentDoc}
  ${RuleFieldsFragmentDoc}
`;

/**
 * __useGetLoanProductByIdQuery__
 *
 * To run a query within a React component, call `useGetLoanProductByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLoanProductByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLoanProductByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetLoanProductByIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetLoanProductByIdQuery,
    GetLoanProductByIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetLoanProductByIdQuery,
    GetLoanProductByIdQueryVariables
  >(GetLoanProductByIdDocument, options);
}
export function useGetLoanProductByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetLoanProductByIdQuery,
    GetLoanProductByIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetLoanProductByIdQuery,
    GetLoanProductByIdQueryVariables
  >(GetLoanProductByIdDocument, options);
}
export type GetLoanProductByIdQueryHookResult = ReturnType<
  typeof useGetLoanProductByIdQuery
>;
export type GetLoanProductByIdLazyQueryHookResult = ReturnType<
  typeof useGetLoanProductByIdLazyQuery
>;
export type GetLoanProductByIdQueryResult = Apollo.QueryResult<
  GetLoanProductByIdQuery,
  GetLoanProductByIdQueryVariables
>;
export const GetLoanProductForEditDocument = gql`
  query GetLoanProductForEdit($id: uuid!) {
    loan_product: products_by_pk(id: $id) {
      id
      name
      description
      type {
        id
        name
        value
      }
      sub_type {
        id
        name
        value
      }
      product_items {
        id
        name
        description
        is_active
        tariff {
          id
          is_active
          is_percentage
          value
          max_value
          min_value
          name
          of_total
          of_field_value
          type {
            id
            name
            value
          }
          metadata
          created_at
        }
        type {
          id
          name
          value
        }
        sub_type {
          id
          name
          value
        }
        ledger_account_id
        metadata
        created_at
        updated_at
      }
      product_settings {
        id
        name
        value
        type {
          id
          name
        }
      }
      product_rules {
        id
        rule {
          ...RuleFields
        }
      }
      created_at
      updated_at
    }
  }
  ${RuleFieldsFragmentDoc}
`;

/**
 * __useGetLoanProductForEditQuery__
 *
 * To run a query within a React component, call `useGetLoanProductForEditQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLoanProductForEditQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLoanProductForEditQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetLoanProductForEditQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetLoanProductForEditQuery,
    GetLoanProductForEditQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetLoanProductForEditQuery,
    GetLoanProductForEditQueryVariables
  >(GetLoanProductForEditDocument, options);
}
export function useGetLoanProductForEditLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetLoanProductForEditQuery,
    GetLoanProductForEditQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetLoanProductForEditQuery,
    GetLoanProductForEditQueryVariables
  >(GetLoanProductForEditDocument, options);
}
export type GetLoanProductForEditQueryHookResult = ReturnType<
  typeof useGetLoanProductForEditQuery
>;
export type GetLoanProductForEditLazyQueryHookResult = ReturnType<
  typeof useGetLoanProductForEditLazyQuery
>;
export type GetLoanProductForEditQueryResult = Apollo.QueryResult<
  GetLoanProductForEditQuery,
  GetLoanProductForEditQueryVariables
>;
export const GetLoanProductOverviewDocument = gql`
  query GetLoanProductOverview($id: uuid!, $limit: Int = 3) {
    requested: subscription_items(
      where: {
        product_item: {
          type: { value: { _eq: "LOAN_PRINCIPAL" } }
          product_id: { _eq: $id }
        }
        subscription: {
          subscription_statuses: {
            status: { value: { _eq: "LOAN_REQUESTED" } }
          }
        }
      }
      limit: $limit
    ) {
      id
      quantity
      created_at
      subscription {
        id
        party {
          id
          name
        }
      }
    }
    disbursed: subscription_items(
      where: {
        product_item: {
          type: { value: { _eq: "LOAN_PRINCIPAL" } }
          product_id: { _eq: $id }
        }
        subscription: {
          subscription_statuses: { status: { value: { _eq: "DISBURSED" } } }
        }
      }
      limit: $limit
    ) {
      id
      quantity
      created_at
      subscription {
        id
        party {
          id
          name
        }
      }
    }
    defaulted: subscription_statuses(
      where: {
        status: { value: { _eq: "DEFAULTED" } }
        subscription: { product_id: { _eq: $id } }
      }
      limit: $limit
    ) {
      id
      created_at
      subscription {
        id
        party {
          id
          name
        }
        subscription_payments(limit: 1, order_by: { created_at: desc }) {
          id
          subscription_balance
        }
        invoices(limit: 1, order_by: { created_at: desc }) {
          id
          amount_due
        }
      }
    }
    subscription_payment_transactions(
      where: { product_id: { _eq: $id } }
      limit: $limit
    ) {
      id
      amount
      created_at
      subscription {
        id
        party {
          id
          name
        }
      }
    }
  }
`;

/**
 * __useGetLoanProductOverviewQuery__
 *
 * To run a query within a React component, call `useGetLoanProductOverviewQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLoanProductOverviewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLoanProductOverviewQuery({
 *   variables: {
 *      id: // value for 'id'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useGetLoanProductOverviewQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetLoanProductOverviewQuery,
    GetLoanProductOverviewQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetLoanProductOverviewQuery,
    GetLoanProductOverviewQueryVariables
  >(GetLoanProductOverviewDocument, options);
}
export function useGetLoanProductOverviewLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetLoanProductOverviewQuery,
    GetLoanProductOverviewQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetLoanProductOverviewQuery,
    GetLoanProductOverviewQueryVariables
  >(GetLoanProductOverviewDocument, options);
}
export type GetLoanProductOverviewQueryHookResult = ReturnType<
  typeof useGetLoanProductOverviewQuery
>;
export type GetLoanProductOverviewLazyQueryHookResult = ReturnType<
  typeof useGetLoanProductOverviewLazyQuery
>;
export type GetLoanProductOverviewQueryResult = Apollo.QueryResult<
  GetLoanProductOverviewQuery,
  GetLoanProductOverviewQueryVariables
>;
export const GetLoanProductsDocument = gql`
  query GetLoanProducts {
    products(
      where: {
        type: {
          value: {
            _in: [
              "LOAN_PRODUCT"
              "STRAIGHT_LINE_SCHEDULE"
              "REDUCING_BALANCE_SCHEDULE"
              "ORDINARY_ANNUITY_SCHEDULE"
              "ANNUITY_DUE_SCHEDULE"
              "INTEREST_ONLY_SCHEDULE"
              "FIXED_INTEREST_UPFRONT_SCHEDULE"
            ]
          }
        }
      }
    ) {
      ...ProductBaseFields
    }
  }
  ${ProductBaseFieldsFragmentDoc}
`;

/**
 * __useGetLoanProductsQuery__
 *
 * To run a query within a React component, call `useGetLoanProductsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLoanProductsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLoanProductsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetLoanProductsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetLoanProductsQuery,
    GetLoanProductsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetLoanProductsQuery, GetLoanProductsQueryVariables>(
    GetLoanProductsDocument,
    options
  );
}
export function useGetLoanProductsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetLoanProductsQuery,
    GetLoanProductsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetLoanProductsQuery,
    GetLoanProductsQueryVariables
  >(GetLoanProductsDocument, options);
}
export type GetLoanProductsQueryHookResult = ReturnType<
  typeof useGetLoanProductsQuery
>;
export type GetLoanProductsLazyQueryHookResult = ReturnType<
  typeof useGetLoanProductsLazyQuery
>;
export type GetLoanProductsQueryResult = Apollo.QueryResult<
  GetLoanProductsQuery,
  GetLoanProductsQueryVariables
>;
export const GetLoanRequestListDocument = gql`
  query GetLoanRequestList($productId: uuid!) {
    approval_list: subscription_statuses(
      where: {
        status: { value: { _eq: "LOAN_REQUESTED" } }
        subscription: { product_id: { _eq: $productId } }
      }
    ) {
      id
      subscription {
        id
        party {
          name
        }
        current_status: subscription_statuses(
          limit: 1
          order_by: { created_at: desc }
        ) {
          id
          status {
            id
            name
            value
          }
        }
        subscription_statuses(order_by: { created_at: desc }) {
          id
          status {
            id
            name
            value
          }
        }
        approval_request: subscription_tasks(
          where: { task: { type: { value: { _eq: "APPROVAL_REQUEST" } } } }
          limit: 1
          order_by: { created_at: asc }
        ) {
          id
          task {
            id
            title
            description
            type_id
            created_at
            task_statuses(limit: 1, order_by: { created_at: desc }) {
              id
              status {
                id
                name
                value
              }
            }
            approver: partyByAssignedTo {
              id
              name
            }
            assignee: party {
              id
              name
            }
          }
        }
      }
    }
  }
`;

/**
 * __useGetLoanRequestListQuery__
 *
 * To run a query within a React component, call `useGetLoanRequestListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLoanRequestListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLoanRequestListQuery({
 *   variables: {
 *      productId: // value for 'productId'
 *   },
 * });
 */
export function useGetLoanRequestListQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetLoanRequestListQuery,
    GetLoanRequestListQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetLoanRequestListQuery,
    GetLoanRequestListQueryVariables
  >(GetLoanRequestListDocument, options);
}
export function useGetLoanRequestListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetLoanRequestListQuery,
    GetLoanRequestListQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetLoanRequestListQuery,
    GetLoanRequestListQueryVariables
  >(GetLoanRequestListDocument, options);
}
export type GetLoanRequestListQueryHookResult = ReturnType<
  typeof useGetLoanRequestListQuery
>;
export type GetLoanRequestListLazyQueryHookResult = ReturnType<
  typeof useGetLoanRequestListLazyQuery
>;
export type GetLoanRequestListQueryResult = Apollo.QueryResult<
  GetLoanRequestListQuery,
  GetLoanRequestListQueryVariables
>;
export const GetMeDocument = gql`
  query GetMe {
    me {
      id
      avatar
      name
      nickname
      email
      phone_number
      locale
      location
    }
  }
`;

/**
 * __useGetMeQuery__
 *
 * To run a query within a React component, call `useGetMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMeQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMeQuery(
  baseOptions?: Apollo.QueryHookOptions<GetMeQuery, GetMeQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetMeQuery, GetMeQueryVariables>(
    GetMeDocument,
    options
  );
}
export function useGetMeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetMeQuery, GetMeQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetMeQuery, GetMeQueryVariables>(
    GetMeDocument,
    options
  );
}
export type GetMeQueryHookResult = ReturnType<typeof useGetMeQuery>;
export type GetMeLazyQueryHookResult = ReturnType<typeof useGetMeLazyQuery>;
export type GetMeQueryResult = Apollo.QueryResult<
  GetMeQuery,
  GetMeQueryVariables
>;
export const GetOnboardingWorkflowsDocument = gql`
  query GetOnboardingWorkflows {
    forms(
      where: {
        is_active: { _eq: true }
        type: { value: { _eq: "ONBOARDING_WORKFLOW" } }
        parent_form_id: { _is_null: true }
      }
    ) {
      ...FormQueryOptions
      childForms: forms(where: { is_active: { _eq: true } }) {
        ...FormQueryOptions
      }
    }
  }
  ${FormQueryOptionsFragmentDoc}
`;

/**
 * __useGetOnboardingWorkflowsQuery__
 *
 * To run a query within a React component, call `useGetOnboardingWorkflowsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOnboardingWorkflowsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOnboardingWorkflowsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetOnboardingWorkflowsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetOnboardingWorkflowsQuery,
    GetOnboardingWorkflowsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetOnboardingWorkflowsQuery,
    GetOnboardingWorkflowsQueryVariables
  >(GetOnboardingWorkflowsDocument, options);
}
export function useGetOnboardingWorkflowsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetOnboardingWorkflowsQuery,
    GetOnboardingWorkflowsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetOnboardingWorkflowsQuery,
    GetOnboardingWorkflowsQueryVariables
  >(GetOnboardingWorkflowsDocument, options);
}
export type GetOnboardingWorkflowsQueryHookResult = ReturnType<
  typeof useGetOnboardingWorkflowsQuery
>;
export type GetOnboardingWorkflowsLazyQueryHookResult = ReturnType<
  typeof useGetOnboardingWorkflowsLazyQuery
>;
export type GetOnboardingWorkflowsQueryResult = Apollo.QueryResult<
  GetOnboardingWorkflowsQuery,
  GetOnboardingWorkflowsQueryVariables
>;
export const GetOrgActivityStreamDocument = gql`
  query GetOrgActivityStream {
    activity_logs(limit: 7, order_by: { event_timestamp: desc }) {
      actor
      created_at
      event_timestamp
      id
      object
      render_template
      target
      verb
    }
  }
`;

/**
 * __useGetOrgActivityStreamQuery__
 *
 * To run a query within a React component, call `useGetOrgActivityStreamQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrgActivityStreamQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrgActivityStreamQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetOrgActivityStreamQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetOrgActivityStreamQuery,
    GetOrgActivityStreamQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetOrgActivityStreamQuery,
    GetOrgActivityStreamQueryVariables
  >(GetOrgActivityStreamDocument, options);
}
export function useGetOrgActivityStreamLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetOrgActivityStreamQuery,
    GetOrgActivityStreamQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetOrgActivityStreamQuery,
    GetOrgActivityStreamQueryVariables
  >(GetOrgActivityStreamDocument, options);
}
export type GetOrgActivityStreamQueryHookResult = ReturnType<
  typeof useGetOrgActivityStreamQuery
>;
export type GetOrgActivityStreamLazyQueryHookResult = ReturnType<
  typeof useGetOrgActivityStreamLazyQuery
>;
export type GetOrgActivityStreamQueryResult = Apollo.QueryResult<
  GetOrgActivityStreamQuery,
  GetOrgActivityStreamQueryVariables
>;
export const GetOrganisationProductItemsDocument = gql`
  query GetOrganisationProductItems {
    product_items(where: { is_active: { _eq: true } }) {
      id
      name
      product {
        id
        name
        type_id
      }
    }
  }
`;

/**
 * __useGetOrganisationProductItemsQuery__
 *
 * To run a query within a React component, call `useGetOrganisationProductItemsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrganisationProductItemsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrganisationProductItemsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetOrganisationProductItemsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetOrganisationProductItemsQuery,
    GetOrganisationProductItemsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetOrganisationProductItemsQuery,
    GetOrganisationProductItemsQueryVariables
  >(GetOrganisationProductItemsDocument, options);
}
export function useGetOrganisationProductItemsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetOrganisationProductItemsQuery,
    GetOrganisationProductItemsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetOrganisationProductItemsQuery,
    GetOrganisationProductItemsQueryVariables
  >(GetOrganisationProductItemsDocument, options);
}
export type GetOrganisationProductItemsQueryHookResult = ReturnType<
  typeof useGetOrganisationProductItemsQuery
>;
export type GetOrganisationProductItemsLazyQueryHookResult = ReturnType<
  typeof useGetOrganisationProductItemsLazyQuery
>;
export type GetOrganisationProductItemsQueryResult = Apollo.QueryResult<
  GetOrganisationProductItemsQuery,
  GetOrganisationProductItemsQueryVariables
>;
export const GetOrganisationProductsDocument = gql`
  query GetOrganisationProducts {
    products(where: { is_active: { _eq: true } }) {
      id
      name
    }
  }
`;

/**
 * __useGetOrganisationProductsQuery__
 *
 * To run a query within a React component, call `useGetOrganisationProductsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrganisationProductsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrganisationProductsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetOrganisationProductsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetOrganisationProductsQuery,
    GetOrganisationProductsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetOrganisationProductsQuery,
    GetOrganisationProductsQueryVariables
  >(GetOrganisationProductsDocument, options);
}
export function useGetOrganisationProductsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetOrganisationProductsQuery,
    GetOrganisationProductsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetOrganisationProductsQuery,
    GetOrganisationProductsQueryVariables
  >(GetOrganisationProductsDocument, options);
}
export type GetOrganisationProductsQueryHookResult = ReturnType<
  typeof useGetOrganisationProductsQuery
>;
export type GetOrganisationProductsLazyQueryHookResult = ReturnType<
  typeof useGetOrganisationProductsLazyQuery
>;
export type GetOrganisationProductsQueryResult = Apollo.QueryResult<
  GetOrganisationProductsQuery,
  GetOrganisationProductsQueryVariables
>;
export const GetAllOrganisationSettingsDocument = gql`
  query GetAllOrganisationSettings {
    organisation_settings(where: { is_active: { _eq: true } }) {
      id
      name
      value
    }
  }
`;

/**
 * __useGetAllOrganisationSettingsQuery__
 *
 * To run a query within a React component, call `useGetAllOrganisationSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllOrganisationSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllOrganisationSettingsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllOrganisationSettingsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetAllOrganisationSettingsQuery,
    GetAllOrganisationSettingsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetAllOrganisationSettingsQuery,
    GetAllOrganisationSettingsQueryVariables
  >(GetAllOrganisationSettingsDocument, options);
}
export function useGetAllOrganisationSettingsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAllOrganisationSettingsQuery,
    GetAllOrganisationSettingsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetAllOrganisationSettingsQuery,
    GetAllOrganisationSettingsQueryVariables
  >(GetAllOrganisationSettingsDocument, options);
}
export type GetAllOrganisationSettingsQueryHookResult = ReturnType<
  typeof useGetAllOrganisationSettingsQuery
>;
export type GetAllOrganisationSettingsLazyQueryHookResult = ReturnType<
  typeof useGetAllOrganisationSettingsLazyQuery
>;
export type GetAllOrganisationSettingsQueryResult = Apollo.QueryResult<
  GetAllOrganisationSettingsQuery,
  GetAllOrganisationSettingsQueryVariables
>;
export const GetPartiesDocument = gql`
  query GetParties {
    parties(order_by: { created_at: desc }) {
      ...PartyBaseFields
      party_statuses {
        status {
          name
          value
        }
      }
      party_contacts {
        contact {
          name
          contact_details {
            form_field {
              field_name
            }
            value
          }
        }
      }
    }
  }
  ${PartyBaseFieldsFragmentDoc}
`;

/**
 * __useGetPartiesQuery__
 *
 * To run a query within a React component, call `useGetPartiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPartiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPartiesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetPartiesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetPartiesQuery,
    GetPartiesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetPartiesQuery, GetPartiesQueryVariables>(
    GetPartiesDocument,
    options
  );
}
export function useGetPartiesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPartiesQuery,
    GetPartiesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetPartiesQuery, GetPartiesQueryVariables>(
    GetPartiesDocument,
    options
  );
}
export type GetPartiesQueryHookResult = ReturnType<typeof useGetPartiesQuery>;
export type GetPartiesLazyQueryHookResult = ReturnType<
  typeof useGetPartiesLazyQuery
>;
export type GetPartiesQueryResult = Apollo.QueryResult<
  GetPartiesQuery,
  GetPartiesQueryVariables
>;
export const GetPartiesByTypeDocument = gql`
  query GetPartiesByType($typeId: uuid!) {
    parties(
      where: { partyRelationshipsByParticipant: { type_id: { _eq: $typeId } } }
      order_by: { created_at: asc }
    ) {
      ...PartyBaseFields
      ...PartyContactsFields
      created_at
      ...OrderedPartyStatuses
    }
  }
  ${PartyBaseFieldsFragmentDoc}
  ${PartyContactsFieldsFragmentDoc}
  ${OrderedPartyStatusesFragmentDoc}
`;

/**
 * __useGetPartiesByTypeQuery__
 *
 * To run a query within a React component, call `useGetPartiesByTypeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPartiesByTypeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPartiesByTypeQuery({
 *   variables: {
 *      typeId: // value for 'typeId'
 *   },
 * });
 */
export function useGetPartiesByTypeQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetPartiesByTypeQuery,
    GetPartiesByTypeQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetPartiesByTypeQuery, GetPartiesByTypeQueryVariables>(
    GetPartiesByTypeDocument,
    options
  );
}
export function useGetPartiesByTypeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPartiesByTypeQuery,
    GetPartiesByTypeQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetPartiesByTypeQuery,
    GetPartiesByTypeQueryVariables
  >(GetPartiesByTypeDocument, options);
}
export type GetPartiesByTypeQueryHookResult = ReturnType<
  typeof useGetPartiesByTypeQuery
>;
export type GetPartiesByTypeLazyQueryHookResult = ReturnType<
  typeof useGetPartiesByTypeLazyQuery
>;
export type GetPartiesByTypeQueryResult = Apollo.QueryResult<
  GetPartiesByTypeQuery,
  GetPartiesByTypeQueryVariables
>;
export const GetPartiesInPeriodByTypeDocument = gql`
  query GetPartiesInPeriodByType(
    $typeIds: [uuid!]
    $fromDate: timestamptz
    $toDate: timestamptz
  ) {
    parties(
      where: {
        type_id: { _in: $typeIds }
        created_at: { _gte: $fromDate, _lte: $toDate }
      }
      limit: 10
      order_by: { created_at: desc }
    ) {
      ...PartyBaseFields
      ...PartyContactsFields
      ...OrderedPartyStatuses
      created_at
    }
  }
  ${PartyBaseFieldsFragmentDoc}
  ${PartyContactsFieldsFragmentDoc}
  ${OrderedPartyStatusesFragmentDoc}
`;

/**
 * __useGetPartiesInPeriodByTypeQuery__
 *
 * To run a query within a React component, call `useGetPartiesInPeriodByTypeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPartiesInPeriodByTypeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPartiesInPeriodByTypeQuery({
 *   variables: {
 *      typeIds: // value for 'typeIds'
 *      fromDate: // value for 'fromDate'
 *      toDate: // value for 'toDate'
 *   },
 * });
 */
export function useGetPartiesInPeriodByTypeQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetPartiesInPeriodByTypeQuery,
    GetPartiesInPeriodByTypeQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetPartiesInPeriodByTypeQuery,
    GetPartiesInPeriodByTypeQueryVariables
  >(GetPartiesInPeriodByTypeDocument, options);
}
export function useGetPartiesInPeriodByTypeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPartiesInPeriodByTypeQuery,
    GetPartiesInPeriodByTypeQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetPartiesInPeriodByTypeQuery,
    GetPartiesInPeriodByTypeQueryVariables
  >(GetPartiesInPeriodByTypeDocument, options);
}
export type GetPartiesInPeriodByTypeQueryHookResult = ReturnType<
  typeof useGetPartiesInPeriodByTypeQuery
>;
export type GetPartiesInPeriodByTypeLazyQueryHookResult = ReturnType<
  typeof useGetPartiesInPeriodByTypeLazyQuery
>;
export type GetPartiesInPeriodByTypeQueryResult = Apollo.QueryResult<
  GetPartiesInPeriodByTypeQuery,
  GetPartiesInPeriodByTypeQueryVariables
>;
export const GetPartyByIdDocument = gql`
  query GetPartyById($id: uuid!) {
    party: parties_by_pk(id: $id) {
      ...PartyBaseFields
      ...OrderedPartyStatuses
      party_contacts {
        contact {
          name
          contact_details {
            form_field {
              field_name
            }
            value
          }
        }
      }
      subscriptions {
        id
        subscription_items {
          id
          ledger_account_id
        }
      }
    }
  }
  ${PartyBaseFieldsFragmentDoc}
  ${OrderedPartyStatusesFragmentDoc}
`;

/**
 * __useGetPartyByIdQuery__
 *
 * To run a query within a React component, call `useGetPartyByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPartyByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPartyByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetPartyByIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetPartyByIdQuery,
    GetPartyByIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetPartyByIdQuery, GetPartyByIdQueryVariables>(
    GetPartyByIdDocument,
    options
  );
}
export function useGetPartyByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPartyByIdQuery,
    GetPartyByIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetPartyByIdQuery, GetPartyByIdQueryVariables>(
    GetPartyByIdDocument,
    options
  );
}
export type GetPartyByIdQueryHookResult = ReturnType<
  typeof useGetPartyByIdQuery
>;
export type GetPartyByIdLazyQueryHookResult = ReturnType<
  typeof useGetPartyByIdLazyQuery
>;
export type GetPartyByIdQueryResult = Apollo.QueryResult<
  GetPartyByIdQuery,
  GetPartyByIdQueryVariables
>;
export const GetPartyCountsByTypeDocument = gql`
  query GetPartyCountsByType($typeIds: [uuid!]) {
    types(where: { id: { _in: $typeIds } }) {
      id
      name
      value
      parties_aggregate(order_by: { type_id: asc }) {
        aggregate {
          count
        }
      }
    }
  }
`;

/**
 * __useGetPartyCountsByTypeQuery__
 *
 * To run a query within a React component, call `useGetPartyCountsByTypeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPartyCountsByTypeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPartyCountsByTypeQuery({
 *   variables: {
 *      typeIds: // value for 'typeIds'
 *   },
 * });
 */
export function useGetPartyCountsByTypeQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetPartyCountsByTypeQuery,
    GetPartyCountsByTypeQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetPartyCountsByTypeQuery,
    GetPartyCountsByTypeQueryVariables
  >(GetPartyCountsByTypeDocument, options);
}
export function useGetPartyCountsByTypeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPartyCountsByTypeQuery,
    GetPartyCountsByTypeQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetPartyCountsByTypeQuery,
    GetPartyCountsByTypeQueryVariables
  >(GetPartyCountsByTypeDocument, options);
}
export type GetPartyCountsByTypeQueryHookResult = ReturnType<
  typeof useGetPartyCountsByTypeQuery
>;
export type GetPartyCountsByTypeLazyQueryHookResult = ReturnType<
  typeof useGetPartyCountsByTypeLazyQuery
>;
export type GetPartyCountsByTypeQueryResult = Apollo.QueryResult<
  GetPartyCountsByTypeQuery,
  GetPartyCountsByTypeQueryVariables
>;
export const GetPartyDocumentsDocument = gql`
  query GetPartyDocuments($partyId: uuid!) {
    party_documents(
      where: { party_id: { _eq: $partyId } }
      order_by: { created_at: desc }
    ) {
      id
      document {
        id
        name
        path
        size
        type_id
        party {
          id
          name
        }
        type {
          id
          name
          value
        }
        created_at
      }
      created_at
      party_id
    }
  }
`;

/**
 * __useGetPartyDocumentsQuery__
 *
 * To run a query within a React component, call `useGetPartyDocumentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPartyDocumentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPartyDocumentsQuery({
 *   variables: {
 *      partyId: // value for 'partyId'
 *   },
 * });
 */
export function useGetPartyDocumentsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetPartyDocumentsQuery,
    GetPartyDocumentsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetPartyDocumentsQuery,
    GetPartyDocumentsQueryVariables
  >(GetPartyDocumentsDocument, options);
}
export function useGetPartyDocumentsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPartyDocumentsQuery,
    GetPartyDocumentsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetPartyDocumentsQuery,
    GetPartyDocumentsQueryVariables
  >(GetPartyDocumentsDocument, options);
}
export type GetPartyDocumentsQueryHookResult = ReturnType<
  typeof useGetPartyDocumentsQuery
>;
export type GetPartyDocumentsLazyQueryHookResult = ReturnType<
  typeof useGetPartyDocumentsLazyQuery
>;
export type GetPartyDocumentsQueryResult = Apollo.QueryResult<
  GetPartyDocumentsQuery,
  GetPartyDocumentsQueryVariables
>;
export const GetEligbilePartiesLongListDocument = gql`
  query GetEligbilePartiesLongList(
    $customerTypeIds: [uuid!]!
    $productId: uuid!
    $eligibleProductItemIds: [uuid!]!
  ) {
    parties(
      where: {
        partyRelationshipsByParticipant: { type_id: { _in: $customerTypeIds } }
      }
      order_by: { created_at: asc }
    ) {
      id
      name
      created_at
      eligible_subscriptions: subscriptions(
        where: {
          product: { product_items: { id: { _in: $eligibleProductItemIds } } }
        }
      ) {
        id
        subscription_items {
          id
          transaction_summary {
            summary {
              total_credits
              total_debits
            }
          }
        }
      }
      last_loan: invoices(
        where: { product_id: { _eq: $productId } }
        limit: 1
        order_by: { created_at: asc }
      ) {
        id
        created_at
        invoice_items {
          id
          quantity
          amount
          product_item {
            name
            type {
              value
            }
          }
        }
        subscription_payments(order_by: { created_at: asc }, limit: 1) {
          id
          amount
        }
        subscription {
          subscription_start
        }
      }
      active_loans: subscriptions(where: { product_id: { _eq: $productId } }) {
        id
        subscription_statuses(limit: 1, order_by: { created_at: desc }) {
          id
          created_at
          status {
            id
            name
          }
        }
      }
    }
  }
`;

/**
 * __useGetEligbilePartiesLongListQuery__
 *
 * To run a query within a React component, call `useGetEligbilePartiesLongListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEligbilePartiesLongListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEligbilePartiesLongListQuery({
 *   variables: {
 *      customerTypeIds: // value for 'customerTypeIds'
 *      productId: // value for 'productId'
 *      eligibleProductItemIds: // value for 'eligibleProductItemIds'
 *   },
 * });
 */
export function useGetEligbilePartiesLongListQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetEligbilePartiesLongListQuery,
    GetEligbilePartiesLongListQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetEligbilePartiesLongListQuery,
    GetEligbilePartiesLongListQueryVariables
  >(GetEligbilePartiesLongListDocument, options);
}
export function useGetEligbilePartiesLongListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetEligbilePartiesLongListQuery,
    GetEligbilePartiesLongListQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetEligbilePartiesLongListQuery,
    GetEligbilePartiesLongListQueryVariables
  >(GetEligbilePartiesLongListDocument, options);
}
export type GetEligbilePartiesLongListQueryHookResult = ReturnType<
  typeof useGetEligbilePartiesLongListQuery
>;
export type GetEligbilePartiesLongListLazyQueryHookResult = ReturnType<
  typeof useGetEligbilePartiesLongListLazyQuery
>;
export type GetEligbilePartiesLongListQueryResult = Apollo.QueryResult<
  GetEligbilePartiesLongListQuery,
  GetEligbilePartiesLongListQueryVariables
>;
export const GetPartyNotesDocument = gql`
  query GetPartyNotes($partyId: uuid!) {
    party_interactions(where: { party_id: { _eq: $partyId } }) {
      id
      interaction {
        id
        created_at
        party {
          id
          avatar
          name
          nickname
        }
        interaction_data {
          id
          form_field {
            id
            field_name
          }
          value
        }
      }
      created_at
      party_id
    }
  }
`;

/**
 * __useGetPartyNotesQuery__
 *
 * To run a query within a React component, call `useGetPartyNotesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPartyNotesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPartyNotesQuery({
 *   variables: {
 *      partyId: // value for 'partyId'
 *   },
 * });
 */
export function useGetPartyNotesQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetPartyNotesQuery,
    GetPartyNotesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetPartyNotesQuery, GetPartyNotesQueryVariables>(
    GetPartyNotesDocument,
    options
  );
}
export function useGetPartyNotesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPartyNotesQuery,
    GetPartyNotesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetPartyNotesQuery, GetPartyNotesQueryVariables>(
    GetPartyNotesDocument,
    options
  );
}
export type GetPartyNotesQueryHookResult = ReturnType<
  typeof useGetPartyNotesQuery
>;
export type GetPartyNotesLazyQueryHookResult = ReturnType<
  typeof useGetPartyNotesLazyQuery
>;
export type GetPartyNotesQueryResult = Apollo.QueryResult<
  GetPartyNotesQuery,
  GetPartyNotesQueryVariables
>;
export const GetPartyOpenCasesDocument = gql`
  query GetPartyOpenCases($partyId: uuid!) {
    cases(
      where: {
        party_id: { _eq: $partyId }
        _and: [
          {
            _not: {
              case_statuses: { status: { value: { _eq: "SOLVED_CASE" } } }
            }
          }
          {
            _not: {
              case_statuses: { status: { value: { _eq: "CLOSED_CASE" } } }
            }
          }
        ]
      }
      order_by: { created_at: asc }
    ) {
      id
      created_at
      updated_at
      case_interactions {
        id
        interaction {
          id
          interaction_by: party {
            id
            name
          }
          type {
            id
            name
            value
          }
        }
        created_at
      }
      title
      type {
        id
        name
        value
      }
      case_statuses {
        id
        created_at
        status {
          id
          name
          value
        }
      }
      assigned_party {
        avatar
        id
        name
        nickname
      }
    }
  }
`;

/**
 * __useGetPartyOpenCasesQuery__
 *
 * To run a query within a React component, call `useGetPartyOpenCasesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPartyOpenCasesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPartyOpenCasesQuery({
 *   variables: {
 *      partyId: // value for 'partyId'
 *   },
 * });
 */
export function useGetPartyOpenCasesQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetPartyOpenCasesQuery,
    GetPartyOpenCasesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetPartyOpenCasesQuery,
    GetPartyOpenCasesQueryVariables
  >(GetPartyOpenCasesDocument, options);
}
export function useGetPartyOpenCasesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPartyOpenCasesQuery,
    GetPartyOpenCasesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetPartyOpenCasesQuery,
    GetPartyOpenCasesQueryVariables
  >(GetPartyOpenCasesDocument, options);
}
export type GetPartyOpenCasesQueryHookResult = ReturnType<
  typeof useGetPartyOpenCasesQuery
>;
export type GetPartyOpenCasesLazyQueryHookResult = ReturnType<
  typeof useGetPartyOpenCasesLazyQuery
>;
export type GetPartyOpenCasesQueryResult = Apollo.QueryResult<
  GetPartyOpenCasesQuery,
  GetPartyOpenCasesQueryVariables
>;
export const GetPartySubscriptionsDocument = gql`
  query GetPartySubscriptions($partyId: uuid!) {
    subscriptions(
      where: { party_id: { _eq: $partyId } }
      order_by: { created_at: desc }
    ) {
      ...SubscriptionBaseFields
      subscription_payments(limit: 1, order_by: { created_at: desc }) {
        id
        subscription_balance
        created_at
      }
      invoices(limit: 1, order_by: { created_at: desc }) {
        id
        amount_due
        created_at
      }
    }
  }
  ${SubscriptionBaseFieldsFragmentDoc}
`;

/**
 * __useGetPartySubscriptionsQuery__
 *
 * To run a query within a React component, call `useGetPartySubscriptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPartySubscriptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPartySubscriptionsQuery({
 *   variables: {
 *      partyId: // value for 'partyId'
 *   },
 * });
 */
export function useGetPartySubscriptionsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetPartySubscriptionsQuery,
    GetPartySubscriptionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetPartySubscriptionsQuery,
    GetPartySubscriptionsQueryVariables
  >(GetPartySubscriptionsDocument, options);
}
export function useGetPartySubscriptionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPartySubscriptionsQuery,
    GetPartySubscriptionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetPartySubscriptionsQuery,
    GetPartySubscriptionsQueryVariables
  >(GetPartySubscriptionsDocument, options);
}
export type GetPartySubscriptionsQueryHookResult = ReturnType<
  typeof useGetPartySubscriptionsQuery
>;
export type GetPartySubscriptionsLazyQueryHookResult = ReturnType<
  typeof useGetPartySubscriptionsLazyQuery
>;
export type GetPartySubscriptionsQueryResult = Apollo.QueryResult<
  GetPartySubscriptionsQuery,
  GetPartySubscriptionsQueryVariables
>;
export const GetProductByIdDocument = gql`
  query GetProductById($id: uuid!) {
    product: products_by_pk(id: $id) {
      ...ProductBaseFields
      product_rules {
        id
        rule {
          ...RuleFields
        }
      }
      subscriptions(order_by: { created_at: desc }) {
        id
        party {
          id
          name
        }
        created_at
      }
    }
  }
  ${ProductBaseFieldsFragmentDoc}
  ${RuleFieldsFragmentDoc}
`;

/**
 * __useGetProductByIdQuery__
 *
 * To run a query within a React component, call `useGetProductByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProductByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProductByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetProductByIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetProductByIdQuery,
    GetProductByIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetProductByIdQuery, GetProductByIdQueryVariables>(
    GetProductByIdDocument,
    options
  );
}
export function useGetProductByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetProductByIdQuery,
    GetProductByIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetProductByIdQuery, GetProductByIdQueryVariables>(
    GetProductByIdDocument,
    options
  );
}
export type GetProductByIdQueryHookResult = ReturnType<
  typeof useGetProductByIdQuery
>;
export type GetProductByIdLazyQueryHookResult = ReturnType<
  typeof useGetProductByIdLazyQuery
>;
export type GetProductByIdQueryResult = Apollo.QueryResult<
  GetProductByIdQuery,
  GetProductByIdQueryVariables
>;
export const GetProductDocumentsDocument = gql`
  query GetProductDocuments($productId: uuid!) {
    product_documents(
      where: { product_id: { _eq: $productId } }
      order_by: { created_at: desc }
    ) {
      id
      document {
        id
        name
        path
        size
        type_id
        party {
          id
          name
        }
        type {
          id
          name
          value
        }
        created_at
      }
      created_at
      product_id
    }
  }
`;

/**
 * __useGetProductDocumentsQuery__
 *
 * To run a query within a React component, call `useGetProductDocumentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProductDocumentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProductDocumentsQuery({
 *   variables: {
 *      productId: // value for 'productId'
 *   },
 * });
 */
export function useGetProductDocumentsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetProductDocumentsQuery,
    GetProductDocumentsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetProductDocumentsQuery,
    GetProductDocumentsQueryVariables
  >(GetProductDocumentsDocument, options);
}
export function useGetProductDocumentsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetProductDocumentsQuery,
    GetProductDocumentsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetProductDocumentsQuery,
    GetProductDocumentsQueryVariables
  >(GetProductDocumentsDocument, options);
}
export type GetProductDocumentsQueryHookResult = ReturnType<
  typeof useGetProductDocumentsQuery
>;
export type GetProductDocumentsLazyQueryHookResult = ReturnType<
  typeof useGetProductDocumentsLazyQuery
>;
export type GetProductDocumentsQueryResult = Apollo.QueryResult<
  GetProductDocumentsQuery,
  GetProductDocumentsQueryVariables
>;
export const GetProductNotesDocument = gql`
  query GetProductNotes($productId: uuid!) {
    product_interactions(where: { product_id: { _eq: $productId } }) {
      id
      interaction {
        id
        created_at
        party {
          id
          avatar
          name
          nickname
        }
        interaction_data {
          id
          form_field {
            id
            field_name
          }
          value
        }
      }
      created_at
      product_id
    }
  }
`;

/**
 * __useGetProductNotesQuery__
 *
 * To run a query within a React component, call `useGetProductNotesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProductNotesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProductNotesQuery({
 *   variables: {
 *      productId: // value for 'productId'
 *   },
 * });
 */
export function useGetProductNotesQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetProductNotesQuery,
    GetProductNotesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetProductNotesQuery, GetProductNotesQueryVariables>(
    GetProductNotesDocument,
    options
  );
}
export function useGetProductNotesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetProductNotesQuery,
    GetProductNotesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetProductNotesQuery,
    GetProductNotesQueryVariables
  >(GetProductNotesDocument, options);
}
export type GetProductNotesQueryHookResult = ReturnType<
  typeof useGetProductNotesQuery
>;
export type GetProductNotesLazyQueryHookResult = ReturnType<
  typeof useGetProductNotesLazyQuery
>;
export type GetProductNotesQueryResult = Apollo.QueryResult<
  GetProductNotesQuery,
  GetProductNotesQueryVariables
>;
export const GetProductItemsDocument = gql`
  query GetProductItems($productItemIds: [uuid!]!) {
    product_items(where: { id: { _in: $productItemIds } }) {
      id
      name
    }
  }
`;

/**
 * __useGetProductItemsQuery__
 *
 * To run a query within a React component, call `useGetProductItemsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProductItemsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProductItemsQuery({
 *   variables: {
 *      productItemIds: // value for 'productItemIds'
 *   },
 * });
 */
export function useGetProductItemsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetProductItemsQuery,
    GetProductItemsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetProductItemsQuery, GetProductItemsQueryVariables>(
    GetProductItemsDocument,
    options
  );
}
export function useGetProductItemsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetProductItemsQuery,
    GetProductItemsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetProductItemsQuery,
    GetProductItemsQueryVariables
  >(GetProductItemsDocument, options);
}
export type GetProductItemsQueryHookResult = ReturnType<
  typeof useGetProductItemsQuery
>;
export type GetProductItemsLazyQueryHookResult = ReturnType<
  typeof useGetProductItemsLazyQuery
>;
export type GetProductItemsQueryResult = Apollo.QueryResult<
  GetProductItemsQuery,
  GetProductItemsQueryVariables
>;
export const GetProductSubscriptionsDocument = gql`
  query GetProductSubscriptions($productId: uuid!) {
    subscriptions(
      where: { product_id: { _eq: $productId } }
      order_by: { party: { name: asc } }
    ) {
      ...SubscriptionBaseFields
    }
  }
  ${SubscriptionBaseFieldsFragmentDoc}
`;

/**
 * __useGetProductSubscriptionsQuery__
 *
 * To run a query within a React component, call `useGetProductSubscriptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProductSubscriptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProductSubscriptionsQuery({
 *   variables: {
 *      productId: // value for 'productId'
 *   },
 * });
 */
export function useGetProductSubscriptionsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetProductSubscriptionsQuery,
    GetProductSubscriptionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetProductSubscriptionsQuery,
    GetProductSubscriptionsQueryVariables
  >(GetProductSubscriptionsDocument, options);
}
export function useGetProductSubscriptionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetProductSubscriptionsQuery,
    GetProductSubscriptionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetProductSubscriptionsQuery,
    GetProductSubscriptionsQueryVariables
  >(GetProductSubscriptionsDocument, options);
}
export type GetProductSubscriptionsQueryHookResult = ReturnType<
  typeof useGetProductSubscriptionsQuery
>;
export type GetProductSubscriptionsLazyQueryHookResult = ReturnType<
  typeof useGetProductSubscriptionsLazyQuery
>;
export type GetProductSubscriptionsQueryResult = Apollo.QueryResult<
  GetProductSubscriptionsQuery,
  GetProductSubscriptionsQueryVariables
>;
export const GetProductsDocument = gql`
  query GetProducts {
    products(
      where: {
        type: {
          _not: {
            value: {
              _in: [
                "LOAN_PRODUCT"
                "STRAIGHT_LINE_SCHEDULE"
                "REDUCING_BALANCE_SCHEDULE"
                "ORDINARY_ANNUITY_SCHEDULE"
                "ANNUITY_DUE_SCHEDULE"
                "INTEREST_ONLY_SCHEDULE"
                "FIXED_INTEREST_UPFRONT_SCHEDULE"
              ]
            }
          }
        }
      }
    ) {
      ...ProductBaseFields
    }
  }
  ${ProductBaseFieldsFragmentDoc}
`;

/**
 * __useGetProductsQuery__
 *
 * To run a query within a React component, call `useGetProductsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProductsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProductsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetProductsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetProductsQuery,
    GetProductsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetProductsQuery, GetProductsQueryVariables>(
    GetProductsDocument,
    options
  );
}
export function useGetProductsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetProductsQuery,
    GetProductsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetProductsQuery, GetProductsQueryVariables>(
    GetProductsDocument,
    options
  );
}
export type GetProductsQueryHookResult = ReturnType<typeof useGetProductsQuery>;
export type GetProductsLazyQueryHookResult = ReturnType<
  typeof useGetProductsLazyQuery
>;
export type GetProductsQueryResult = Apollo.QueryResult<
  GetProductsQuery,
  GetProductsQueryVariables
>;
export const GetProductsSubscribersDocument = gql`
  query GetProductsSubscribers($productIds: [uuid!]!) {
    subscriptions(
      where: { product_id: { _in: $productIds } }
      order_by: { party: { name: asc } }
    ) {
      id
      party {
        id
        name
        type_id
      }
      product {
        id
        name
        ledger_id
      }
      subscription_items {
        ...SubscriptionItemFields
      }
      subscription_payments(limit: 1, order_by: { created_at: desc }) {
        id
        subscription_balance
        created_at
      }
      invoices(limit: 1, order_by: { created_at: desc }) {
        id
        amount_due
        created_at
      }
    }
  }
  ${SubscriptionItemFieldsFragmentDoc}
`;

/**
 * __useGetProductsSubscribersQuery__
 *
 * To run a query within a React component, call `useGetProductsSubscribersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProductsSubscribersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProductsSubscribersQuery({
 *   variables: {
 *      productIds: // value for 'productIds'
 *   },
 * });
 */
export function useGetProductsSubscribersQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetProductsSubscribersQuery,
    GetProductsSubscribersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetProductsSubscribersQuery,
    GetProductsSubscribersQueryVariables
  >(GetProductsSubscribersDocument, options);
}
export function useGetProductsSubscribersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetProductsSubscribersQuery,
    GetProductsSubscribersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetProductsSubscribersQuery,
    GetProductsSubscribersQueryVariables
  >(GetProductsSubscribersDocument, options);
}
export type GetProductsSubscribersQueryHookResult = ReturnType<
  typeof useGetProductsSubscribersQuery
>;
export type GetProductsSubscribersLazyQueryHookResult = ReturnType<
  typeof useGetProductsSubscribersLazyQuery
>;
export type GetProductsSubscribersQueryResult = Apollo.QueryResult<
  GetProductsSubscribersQuery,
  GetProductsSubscribersQueryVariables
>;
export const GetReadMessagesDocument = gql`
  query GetReadMessages($params: GetReadMessagesInput!) {
    getReadMessages(params: $params) {
      hasReadMessage
      lastReadMessage
    }
  }
`;

/**
 * __useGetReadMessagesQuery__
 *
 * To run a query within a React component, call `useGetReadMessagesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetReadMessagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetReadMessagesQuery({
 *   variables: {
 *      params: // value for 'params'
 *   },
 * });
 */
export function useGetReadMessagesQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetReadMessagesQuery,
    GetReadMessagesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetReadMessagesQuery, GetReadMessagesQueryVariables>(
    GetReadMessagesDocument,
    options
  );
}
export function useGetReadMessagesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetReadMessagesQuery,
    GetReadMessagesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetReadMessagesQuery,
    GetReadMessagesQueryVariables
  >(GetReadMessagesDocument, options);
}
export type GetReadMessagesQueryHookResult = ReturnType<
  typeof useGetReadMessagesQuery
>;
export type GetReadMessagesLazyQueryHookResult = ReturnType<
  typeof useGetReadMessagesLazyQuery
>;
export type GetReadMessagesQueryResult = Apollo.QueryResult<
  GetReadMessagesQuery,
  GetReadMessagesQueryVariables
>;
export const GetSubscriptionBalancesDocument = gql`
  query GetSubscriptionBalances($subscriptionIds: [uuid!]!) {
    subscriptions(where: { id: { _in: $subscriptionIds } }) {
      id
      subscription_payments {
        id
        amount
      }
      invoices(limit: 1, order_by: { created_at: desc }) {
        id
        invoice_items {
          id
          quantity
          amount
          product_item {
            id
            name
          }
          created_at
        }
      }
    }
  }
`;

/**
 * __useGetSubscriptionBalancesQuery__
 *
 * To run a query within a React component, call `useGetSubscriptionBalancesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSubscriptionBalancesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSubscriptionBalancesQuery({
 *   variables: {
 *      subscriptionIds: // value for 'subscriptionIds'
 *   },
 * });
 */
export function useGetSubscriptionBalancesQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetSubscriptionBalancesQuery,
    GetSubscriptionBalancesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetSubscriptionBalancesQuery,
    GetSubscriptionBalancesQueryVariables
  >(GetSubscriptionBalancesDocument, options);
}
export function useGetSubscriptionBalancesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetSubscriptionBalancesQuery,
    GetSubscriptionBalancesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetSubscriptionBalancesQuery,
    GetSubscriptionBalancesQueryVariables
  >(GetSubscriptionBalancesDocument, options);
}
export type GetSubscriptionBalancesQueryHookResult = ReturnType<
  typeof useGetSubscriptionBalancesQuery
>;
export type GetSubscriptionBalancesLazyQueryHookResult = ReturnType<
  typeof useGetSubscriptionBalancesLazyQuery
>;
export type GetSubscriptionBalancesQueryResult = Apollo.QueryResult<
  GetSubscriptionBalancesQuery,
  GetSubscriptionBalancesQueryVariables
>;
export const GetSubscriptionByIdDocument = gql`
  query GetSubscriptionById($id: uuid!) {
    subscriptions_by_pk(id: $id) {
      ...SubscriptionBaseFields
      subscription_payment_transactions(
        limit: 4
        order_by: { created_at: desc }
      ) {
        id
        amount
        created_at
        invoice {
          id
          due_at
        }
        product_item {
          id
          name
        }
      }
    }
  }
  ${SubscriptionBaseFieldsFragmentDoc}
`;

/**
 * __useGetSubscriptionByIdQuery__
 *
 * To run a query within a React component, call `useGetSubscriptionByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSubscriptionByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSubscriptionByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetSubscriptionByIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetSubscriptionByIdQuery,
    GetSubscriptionByIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetSubscriptionByIdQuery,
    GetSubscriptionByIdQueryVariables
  >(GetSubscriptionByIdDocument, options);
}
export function useGetSubscriptionByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetSubscriptionByIdQuery,
    GetSubscriptionByIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetSubscriptionByIdQuery,
    GetSubscriptionByIdQueryVariables
  >(GetSubscriptionByIdDocument, options);
}
export type GetSubscriptionByIdQueryHookResult = ReturnType<
  typeof useGetSubscriptionByIdQuery
>;
export type GetSubscriptionByIdLazyQueryHookResult = ReturnType<
  typeof useGetSubscriptionByIdLazyQuery
>;
export type GetSubscriptionByIdQueryResult = Apollo.QueryResult<
  GetSubscriptionByIdQuery,
  GetSubscriptionByIdQueryVariables
>;
export const GetSubscriptionDocumentsDocument = gql`
  query GetSubscriptionDocuments($subscriptionId: uuid!) {
    subscription_documents(
      where: { subscription_id: { _eq: $subscriptionId } }
      order_by: { created_at: desc }
    ) {
      id
      document {
        id
        name
        path
        size
        type_id
        party {
          id
          name
        }
        type {
          id
          name
          value
        }
        created_at
      }
      created_at
      subscription_id
    }
  }
`;

/**
 * __useGetSubscriptionDocumentsQuery__
 *
 * To run a query within a React component, call `useGetSubscriptionDocumentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSubscriptionDocumentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSubscriptionDocumentsQuery({
 *   variables: {
 *      subscriptionId: // value for 'subscriptionId'
 *   },
 * });
 */
export function useGetSubscriptionDocumentsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetSubscriptionDocumentsQuery,
    GetSubscriptionDocumentsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetSubscriptionDocumentsQuery,
    GetSubscriptionDocumentsQueryVariables
  >(GetSubscriptionDocumentsDocument, options);
}
export function useGetSubscriptionDocumentsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetSubscriptionDocumentsQuery,
    GetSubscriptionDocumentsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetSubscriptionDocumentsQuery,
    GetSubscriptionDocumentsQueryVariables
  >(GetSubscriptionDocumentsDocument, options);
}
export type GetSubscriptionDocumentsQueryHookResult = ReturnType<
  typeof useGetSubscriptionDocumentsQuery
>;
export type GetSubscriptionDocumentsLazyQueryHookResult = ReturnType<
  typeof useGetSubscriptionDocumentsLazyQuery
>;
export type GetSubscriptionDocumentsQueryResult = Apollo.QueryResult<
  GetSubscriptionDocumentsQuery,
  GetSubscriptionDocumentsQueryVariables
>;
export const GetSubscriptionNotesDocument = gql`
  query GetSubscriptionNotes($subscriptionId: uuid!) {
    subscription_interactions(
      where: { subscription_id: { _eq: $subscriptionId } }
    ) {
      id
      interaction {
        id
        created_at
        party {
          id
          avatar
          name
          nickname
        }
        interaction_data {
          id
          form_field {
            id
            field_name
          }
          value
        }
      }
      created_at
      subscription_id
    }
  }
`;

/**
 * __useGetSubscriptionNotesQuery__
 *
 * To run a query within a React component, call `useGetSubscriptionNotesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSubscriptionNotesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSubscriptionNotesQuery({
 *   variables: {
 *      subscriptionId: // value for 'subscriptionId'
 *   },
 * });
 */
export function useGetSubscriptionNotesQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetSubscriptionNotesQuery,
    GetSubscriptionNotesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetSubscriptionNotesQuery,
    GetSubscriptionNotesQueryVariables
  >(GetSubscriptionNotesDocument, options);
}
export function useGetSubscriptionNotesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetSubscriptionNotesQuery,
    GetSubscriptionNotesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetSubscriptionNotesQuery,
    GetSubscriptionNotesQueryVariables
  >(GetSubscriptionNotesDocument, options);
}
export type GetSubscriptionNotesQueryHookResult = ReturnType<
  typeof useGetSubscriptionNotesQuery
>;
export type GetSubscriptionNotesLazyQueryHookResult = ReturnType<
  typeof useGetSubscriptionNotesLazyQuery
>;
export type GetSubscriptionNotesQueryResult = Apollo.QueryResult<
  GetSubscriptionNotesQuery,
  GetSubscriptionNotesQueryVariables
>;
export const GetSubscriptionLedgerAccountsDocument = gql`
  query GetSubscriptionLedgerAccounts($id: uuid!) {
    subscriptions_by_pk(id: $id) {
      id
      subscription_items {
        id
        ledger_account_id
      }
    }
  }
`;

/**
 * __useGetSubscriptionLedgerAccountsQuery__
 *
 * To run a query within a React component, call `useGetSubscriptionLedgerAccountsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSubscriptionLedgerAccountsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSubscriptionLedgerAccountsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetSubscriptionLedgerAccountsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetSubscriptionLedgerAccountsQuery,
    GetSubscriptionLedgerAccountsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetSubscriptionLedgerAccountsQuery,
    GetSubscriptionLedgerAccountsQueryVariables
  >(GetSubscriptionLedgerAccountsDocument, options);
}
export function useGetSubscriptionLedgerAccountsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetSubscriptionLedgerAccountsQuery,
    GetSubscriptionLedgerAccountsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetSubscriptionLedgerAccountsQuery,
    GetSubscriptionLedgerAccountsQueryVariables
  >(GetSubscriptionLedgerAccountsDocument, options);
}
export type GetSubscriptionLedgerAccountsQueryHookResult = ReturnType<
  typeof useGetSubscriptionLedgerAccountsQuery
>;
export type GetSubscriptionLedgerAccountsLazyQueryHookResult = ReturnType<
  typeof useGetSubscriptionLedgerAccountsLazyQuery
>;
export type GetSubscriptionLedgerAccountsQueryResult = Apollo.QueryResult<
  GetSubscriptionLedgerAccountsQuery,
  GetSubscriptionLedgerAccountsQueryVariables
>;
export const GetSubscriptionsDocument = gql`
  query GetSubscriptions($limit: Int = 30, $offset: Int = 0) {
    subscriptions(
      order_by: { created_at: desc }
      limit: $limit
      offset: $offset
    ) {
      ...SubscriptionBaseFields
    }
  }
  ${SubscriptionBaseFieldsFragmentDoc}
`;

/**
 * __useGetSubscriptionsQuery__
 *
 * To run a query within a React component, call `useGetSubscriptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSubscriptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSubscriptionsQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useGetSubscriptionsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetSubscriptionsQuery,
    GetSubscriptionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetSubscriptionsQuery, GetSubscriptionsQueryVariables>(
    GetSubscriptionsDocument,
    options
  );
}
export function useGetSubscriptionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetSubscriptionsQuery,
    GetSubscriptionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetSubscriptionsQuery,
    GetSubscriptionsQueryVariables
  >(GetSubscriptionsDocument, options);
}
export type GetSubscriptionsQueryHookResult = ReturnType<
  typeof useGetSubscriptionsQuery
>;
export type GetSubscriptionsLazyQueryHookResult = ReturnType<
  typeof useGetSubscriptionsLazyQuery
>;
export type GetSubscriptionsQueryResult = Apollo.QueryResult<
  GetSubscriptionsQuery,
  GetSubscriptionsQueryVariables
>;
export const GetSubscriptionsLedgerAccountsDocument = gql`
  query GetSubscriptionsLedgerAccounts($ids: [uuid!]!) {
    subscriptions(where: { id: { _in: $ids } }) {
      id
      party {
        id
        name
      }
      subscription_items {
        id
        ledger_account_id
      }
    }
  }
`;

/**
 * __useGetSubscriptionsLedgerAccountsQuery__
 *
 * To run a query within a React component, call `useGetSubscriptionsLedgerAccountsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSubscriptionsLedgerAccountsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSubscriptionsLedgerAccountsQuery({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useGetSubscriptionsLedgerAccountsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetSubscriptionsLedgerAccountsQuery,
    GetSubscriptionsLedgerAccountsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetSubscriptionsLedgerAccountsQuery,
    GetSubscriptionsLedgerAccountsQueryVariables
  >(GetSubscriptionsLedgerAccountsDocument, options);
}
export function useGetSubscriptionsLedgerAccountsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetSubscriptionsLedgerAccountsQuery,
    GetSubscriptionsLedgerAccountsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetSubscriptionsLedgerAccountsQuery,
    GetSubscriptionsLedgerAccountsQueryVariables
  >(GetSubscriptionsLedgerAccountsDocument, options);
}
export type GetSubscriptionsLedgerAccountsQueryHookResult = ReturnType<
  typeof useGetSubscriptionsLedgerAccountsQuery
>;
export type GetSubscriptionsLedgerAccountsLazyQueryHookResult = ReturnType<
  typeof useGetSubscriptionsLedgerAccountsLazyQuery
>;
export type GetSubscriptionsLedgerAccountsQueryResult = Apollo.QueryResult<
  GetSubscriptionsLedgerAccountsQuery,
  GetSubscriptionsLedgerAccountsQueryVariables
>;
export const GetTaskDocumentsDocument = gql`
  query GetTaskDocuments($taskId: uuid!) {
    task_documents(
      where: { task_id: { _eq: $taskId } }
      order_by: { created_at: desc }
    ) {
      id
      document {
        id
        name
        path
        size
        type_id
        party {
          id
          name
        }
        type {
          id
          name
          value
        }
        created_at
      }
      created_at
      task_id
    }
  }
`;

/**
 * __useGetTaskDocumentsQuery__
 *
 * To run a query within a React component, call `useGetTaskDocumentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTaskDocumentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTaskDocumentsQuery({
 *   variables: {
 *      taskId: // value for 'taskId'
 *   },
 * });
 */
export function useGetTaskDocumentsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetTaskDocumentsQuery,
    GetTaskDocumentsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetTaskDocumentsQuery, GetTaskDocumentsQueryVariables>(
    GetTaskDocumentsDocument,
    options
  );
}
export function useGetTaskDocumentsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetTaskDocumentsQuery,
    GetTaskDocumentsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetTaskDocumentsQuery,
    GetTaskDocumentsQueryVariables
  >(GetTaskDocumentsDocument, options);
}
export type GetTaskDocumentsQueryHookResult = ReturnType<
  typeof useGetTaskDocumentsQuery
>;
export type GetTaskDocumentsLazyQueryHookResult = ReturnType<
  typeof useGetTaskDocumentsLazyQuery
>;
export type GetTaskDocumentsQueryResult = Apollo.QueryResult<
  GetTaskDocumentsQuery,
  GetTaskDocumentsQueryVariables
>;
export const GetTypesDocument = gql`
  query GetTypes {
    types {
      id
      name
      value
    }
    statuses {
      id
      name
      value
    }
  }
`;

/**
 * __useGetTypesQuery__
 *
 * To run a query within a React component, call `useGetTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTypesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetTypesQuery(
  baseOptions?: Apollo.QueryHookOptions<GetTypesQuery, GetTypesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetTypesQuery, GetTypesQueryVariables>(
    GetTypesDocument,
    options
  );
}
export function useGetTypesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetTypesQuery,
    GetTypesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetTypesQuery, GetTypesQueryVariables>(
    GetTypesDocument,
    options
  );
}
export type GetTypesQueryHookResult = ReturnType<typeof useGetTypesQuery>;
export type GetTypesLazyQueryHookResult = ReturnType<
  typeof useGetTypesLazyQuery
>;
export type GetTypesQueryResult = Apollo.QueryResult<
  GetTypesQuery,
  GetTypesQueryVariables
>;
export const GetUserMfaMethodsDocument = gql`
  query GetUserMFAMethods {
    user_mfa_factors {
      id
      mfa_type
      is_active
      expires_on
      created_at
      updated_at
    }
  }
`;

/**
 * __useGetUserMfaMethodsQuery__
 *
 * To run a query within a React component, call `useGetUserMfaMethodsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserMfaMethodsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserMfaMethodsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUserMfaMethodsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetUserMfaMethodsQuery,
    GetUserMfaMethodsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetUserMfaMethodsQuery,
    GetUserMfaMethodsQueryVariables
  >(GetUserMfaMethodsDocument, options);
}
export function useGetUserMfaMethodsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetUserMfaMethodsQuery,
    GetUserMfaMethodsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetUserMfaMethodsQuery,
    GetUserMfaMethodsQueryVariables
  >(GetUserMfaMethodsDocument, options);
}
export type GetUserMfaMethodsQueryHookResult = ReturnType<
  typeof useGetUserMfaMethodsQuery
>;
export type GetUserMfaMethodsLazyQueryHookResult = ReturnType<
  typeof useGetUserMfaMethodsLazyQuery
>;
export type GetUserMfaMethodsQueryResult = Apollo.QueryResult<
  GetUserMfaMethodsQuery,
  GetUserMfaMethodsQueryVariables
>;
export const GetWorkflowDocument = gql`
  query GetWorkflow($formId: uuid!) {
    forms_by_pk(id: $formId) {
      ...FormQueryOptions
      childForms: forms(where: { is_active: { _eq: true } }) {
        ...FormQueryOptions
      }
    }
  }
  ${FormQueryOptionsFragmentDoc}
`;

/**
 * __useGetWorkflowQuery__
 *
 * To run a query within a React component, call `useGetWorkflowQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWorkflowQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWorkflowQuery({
 *   variables: {
 *      formId: // value for 'formId'
 *   },
 * });
 */
export function useGetWorkflowQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetWorkflowQuery,
    GetWorkflowQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetWorkflowQuery, GetWorkflowQueryVariables>(
    GetWorkflowDocument,
    options
  );
}
export function useGetWorkflowLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetWorkflowQuery,
    GetWorkflowQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetWorkflowQuery, GetWorkflowQueryVariables>(
    GetWorkflowDocument,
    options
  );
}
export type GetWorkflowQueryHookResult = ReturnType<typeof useGetWorkflowQuery>;
export type GetWorkflowLazyQueryHookResult = ReturnType<
  typeof useGetWorkflowLazyQuery
>;
export type GetWorkflowQueryResult = Apollo.QueryResult<
  GetWorkflowQuery,
  GetWorkflowQueryVariables
>;
export const VerifyIfCanMakeProductPaymentDocument = gql`
  query VerifyIfCanMakeProductPayment($productId: uuid!, $partyId: uuid!) {
    canMakeProductPayment(
      params: { productId: $productId, partyId: $partyId }
    ) {
      canPayProduct
      productId
      balance
      product {
        name
      }
    }
  }
`;

/**
 * __useVerifyIfCanMakeProductPaymentQuery__
 *
 * To run a query within a React component, call `useVerifyIfCanMakeProductPaymentQuery` and pass it any options that fit your needs.
 * When your component renders, `useVerifyIfCanMakeProductPaymentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVerifyIfCanMakeProductPaymentQuery({
 *   variables: {
 *      productId: // value for 'productId'
 *      partyId: // value for 'partyId'
 *   },
 * });
 */
export function useVerifyIfCanMakeProductPaymentQuery(
  baseOptions: Apollo.QueryHookOptions<
    VerifyIfCanMakeProductPaymentQuery,
    VerifyIfCanMakeProductPaymentQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    VerifyIfCanMakeProductPaymentQuery,
    VerifyIfCanMakeProductPaymentQueryVariables
  >(VerifyIfCanMakeProductPaymentDocument, options);
}
export function useVerifyIfCanMakeProductPaymentLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    VerifyIfCanMakeProductPaymentQuery,
    VerifyIfCanMakeProductPaymentQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    VerifyIfCanMakeProductPaymentQuery,
    VerifyIfCanMakeProductPaymentQueryVariables
  >(VerifyIfCanMakeProductPaymentDocument, options);
}
export type VerifyIfCanMakeProductPaymentQueryHookResult = ReturnType<
  typeof useVerifyIfCanMakeProductPaymentQuery
>;
export type VerifyIfCanMakeProductPaymentLazyQueryHookResult = ReturnType<
  typeof useVerifyIfCanMakeProductPaymentLazyQuery
>;
export type VerifyIfCanMakeProductPaymentQueryResult = Apollo.QueryResult<
  VerifyIfCanMakeProductPaymentQuery,
  VerifyIfCanMakeProductPaymentQueryVariables
>;
export const VerifyAuthFactorDocument = gql`
  query VerifyAuthFactor($mfaMethod: String!, $otp: String!) {
    verifyAuthFactor(params: { mfaMethod: $mfaMethod, otp: $otp }) {
      response
    }
  }
`;

/**
 * __useVerifyAuthFactorQuery__
 *
 * To run a query within a React component, call `useVerifyAuthFactorQuery` and pass it any options that fit your needs.
 * When your component renders, `useVerifyAuthFactorQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVerifyAuthFactorQuery({
 *   variables: {
 *      mfaMethod: // value for 'mfaMethod'
 *      otp: // value for 'otp'
 *   },
 * });
 */
export function useVerifyAuthFactorQuery(
  baseOptions: Apollo.QueryHookOptions<
    VerifyAuthFactorQuery,
    VerifyAuthFactorQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<VerifyAuthFactorQuery, VerifyAuthFactorQueryVariables>(
    VerifyAuthFactorDocument,
    options
  );
}
export function useVerifyAuthFactorLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    VerifyAuthFactorQuery,
    VerifyAuthFactorQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    VerifyAuthFactorQuery,
    VerifyAuthFactorQueryVariables
  >(VerifyAuthFactorDocument, options);
}
export type VerifyAuthFactorQueryHookResult = ReturnType<
  typeof useVerifyAuthFactorQuery
>;
export type VerifyAuthFactorLazyQueryHookResult = ReturnType<
  typeof useVerifyAuthFactorLazyQuery
>;
export type VerifyAuthFactorQueryResult = Apollo.QueryResult<
  VerifyAuthFactorQuery,
  VerifyAuthFactorQueryVariables
>;
export const GetChannelMessagesDocument = gql`
  subscription GetChannelMessages($channelId: uuid!) {
    messages(
      where: { channel_id: { _eq: $channelId } }
      order_by: { created_at: desc }
    ) {
      id
      body
      created_at
      sender
      message_delivery_methods {
        delivery_method {
          id
          name
        }
      }
    }
  }
`;

/**
 * __useGetChannelMessagesSubscription__
 *
 * To run a query within a React component, call `useGetChannelMessagesSubscription` and pass it any options that fit your needs.
 * When your component renders, `useGetChannelMessagesSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetChannelMessagesSubscription({
 *   variables: {
 *      channelId: // value for 'channelId'
 *   },
 * });
 */
export function useGetChannelMessagesSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    GetChannelMessagesSubscription,
    GetChannelMessagesSubscriptionVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    GetChannelMessagesSubscription,
    GetChannelMessagesSubscriptionVariables
  >(GetChannelMessagesDocument, options);
}
export type GetChannelMessagesSubscriptionHookResult = ReturnType<
  typeof useGetChannelMessagesSubscription
>;
export type GetChannelMessagesSubscriptionResult =
  Apollo.SubscriptionResult<GetChannelMessagesSubscription>;

export interface PossibleTypesResultData {
  possibleTypes: {
    [key: string]: string[];
  };
}
const result: PossibleTypesResultData = {
  possibleTypes: {},
};
export default result;
