import React from "react";
import * as Icon from "react-feather";
import styled from "@emotion/styled";

import FlexWrapper from "components/ui/FlexWrapper";

const ContactsWrapper = styled(FlexWrapper)`
  color: ${(props) => props.theme.color.grey};
  font-size: 0.875rem;
  > svg {
    height: 16px;
    opacity: 0.33;
    margin-right: 0.5rem;
  }

  span {
    color: ${(props) => props.theme.color.primary};
    margin-left: 0.5rem;
    font-size: 0.85rem;
  }
`;

const Contacts: React.FC<{ contacts: string[] }> = ({ contacts }) => {
  return (
    <ContactsWrapper>
      <Icon.Phone />
      {contacts[0]}
      {contacts.length > 1 && <span>+ {contacts.length - 1} more</span>}
    </ContactsWrapper>
  );
};

export default Contacts;
