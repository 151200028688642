import React from "react";
import { NavLink } from "react-router-dom";
import styled from "@emotion/styled";

import FlexWrapper, { Container } from "./FlexWrapper";
import Typography from "./Typography";

const BreadcrumbWrapper = styled(Container)`
  position: sticky;
  top: 65px;
  z-index: 1;
  background: ${(props) => props.theme.color.default};
`;

const BreadcrumbContents = styled(FlexWrapper)`
  text-transform: capitalize;
  min-height: 35px;

  > div:first-of-type {
    flex-grow: 1;
    h4,
    a {
      text-decoration: none;
      color: ${(props) => props.theme.color.dark};
    }

    span {
      margin: -4px 0.35rem 0;
      color: ${(props) => props.theme.color.grey};
      height: 16px;
    }

    > div {
      color: ${(props) => props.theme.color.grey};
      font-size: 14px;
    }
  }

  > div:last-of-type {
    align-items: center;
    display: flex;
  }
`;

const Breadcrumb: React.FC<{ pathname: string; actions?: JSX.Element }> = ({
  pathname,
  actions,
}) => {
  const crumbs = pathname.split("/").filter((crumb) => crumb !== "");
  if (crumbs[0] === "loans") {
    if (crumbs[1] === "p") {
      crumbs[1] = "products";
    } else {
      crumbs[1] = "accounts";
    }
  }

  return (
    <BreadcrumbWrapper>
      <BreadcrumbContents justify='space-between'>
        <FlexWrapper justify='flex-start'>
          <Typography component='h4'>
            {crumbs.length === 1 ? (
              crumbs[0].replace("-", " ")
            ) : (
              <NavLink to={`/${crumbs[0]}`}>
                {crumbs[0].replace("-", " ")}
              </NavLink>
            )}
          </Typography>

          {crumbs[1] && (
            <>
              <span>/</span>
              <div>{crumbs[1].replace("-", " ")}</div>
            </>
          )}
        </FlexWrapper>

        {actions && <div>{actions}</div>}
      </BreadcrumbContents>
    </BreadcrumbWrapper>
  );
};

export default Breadcrumb;
