import React, { ButtonHTMLAttributes } from "react";
import { Theme } from "@emotion/react";
import * as Icon from "react-feather";
import styled from "@emotion/styled";
import { ReactComponent as PlusCircleIcon } from "assets/icons/plus-circle.svg";

const containedColors = (color: ColorVariant, theme: Theme) => {
  switch (color) {
    case "light":
      return `
        color: ${theme.color.dark};
      `;

    default:
      return `
        color: ${theme.color.default};
      `;
  }
};

const buttonSize = (size: SizeVariant) => {
  switch (size) {
    case "small":
      return `
        padding: 6px 10px;
        font-size: 0.85rem;
        border-radius: 6px;
      `;

    case "normal":
    default:
      return `
        padding: 7px 14px;
        font-size: 0.9rem;
        border-radius: 8px;
      `;
  }
};

const ButtonWrapper = styled("button")<
  {
    color: ColorVariant;
    contained: boolean;
    size: SizeVariant;
  } & MarginTypes
>`
  border: solid 1px ${(props) => props.theme.color.border};
  background: ${(props) => props.theme.color.default};
  color: ${(props) => props.theme.color[props.color]};
  max-height: 38px;
  cursor: pointer;

  svg {
    height: 16px;
    opacity: 0.66;
    margin-right: 0.5rem;
    margin-left: -5px;
    color: ${(props) => props.theme.color[props.color]};
    vertical-align: bottom;
  }

  ${(props) =>
    props.contained &&
    `
      background: ${props.theme.color[props.color]};
      ${containedColors(props.color, props.theme)}
      border: none;
      svg {
        color: ${props.theme.color.default};
        opacity: 1;
      }
  `}

  // Vary button size
  ${(props) => buttonSize(props.size)}

  // Spacing
  ${(props) => props.m && `margin: ${props.m}rem`};
  ${(props) => props.mt && `margin-top: ${props.mt}rem`};
  ${(props) => props.ml && `margin-left: ${props.ml}rem`};
  ${(props) => props.mb && `margin-bottom: ${props.mb}rem`};
  ${(props) => props.mr && `margin-right: ${props.mr}rem`};

  :disabled {
    opacity: 0.85;
    cursor: not-allowed;
  }
`;

interface ButtonProps {
  color?: ColorVariant;
  size?: SizeVariant;
  contained?: boolean;
  children?: any;
}

const Button: React.FC<
  ButtonProps & MarginTypes & ButtonHTMLAttributes<HTMLButtonElement>
> = React.forwardRef(
  (
    { children, color = "grey", contained = false, size = "normal", ...props },
    ref
  ) => {
    return (
      <ButtonWrapper
        contained={contained}
        color={color}
        size={size}
        {...props}
        // @ts-ignore
        ref={ref}
      >
        {children}
      </ButtonWrapper>
    );
  }
);

const ButtonGroupWrapper = styled("div")`
  display: flex;
  > button {
    border-right: none;
    border-radius: 0;

    :last-of-type {
      border-top-right-radius: 8px !important;
      border-bottom-right-radius: 8px !important;
      border-right: solid 1px ${(props) => props.theme.color.border} !important;
    }

    :first-of-type {
      border-top-left-radius: 8px !important;
      border-bottom-left-radius: 8px !important;
      border-left: solid 1px ${(props) => props.theme.color.border} !important;
    }
  }
`;

export const ButtonGroup: React.FC<{ children?: any }> = ({ children }) => {
  return <ButtonGroupWrapper>{children}</ButtonGroupWrapper>;
};

const IconButtonWrapper = styled("button")<{ color: ColorVariant }>`
  padding: 8px;
  border-radius: 50%;
  border: none;
  outline: none;
  background: ${(props) => props.theme.color.light};
  cursor: pointer;

  :hover {
    background: rgba(35, 100, 236, 0.15);
  }

  svg {
    width: 22px;
    height: 22px;
    color: ${(props) => props.theme.color[props.color]};
    vertical-align: middle;
  }
`;

export const IconButton: React.FC<
  {
    icon: React.ReactNode;
    color: ColorVariant;
    size?: SizeVariant;
  } & ButtonHTMLAttributes<HTMLButtonElement>
> = ({ icon, color, ...props }) => {
  return (
    <IconButtonWrapper color={color} {...props}>
      {icon}
    </IconButtonWrapper>
  );
};

const AddItemButtonWrapper = styled.button`
  border: 0;
  background: none;
  color: ${(props) => props.theme.color.primary};
  span {
    display: inline-block;
    font-size: 1rem;
    margin-top: -2px;
    padding-left: 0.35rem;
    vertical-align: top;
  }
`;

export const AddItemButton: any = (props: any) => {
  return (
    <AddItemButtonWrapper {...props}>
      <PlusCircleIcon />
      <span>{props.label ? props.label : "Add Item"}</span>
    </AddItemButtonWrapper>
  );
};

const DeleteItemButtonWrapper = styled.button`
  border: 0;
  background: none;
  color: ${(props) => props.theme.color.red.default};
`;

export const DeleteItemButton: any = (props: any) => (
  <DeleteItemButtonWrapper {...props}>
    <Icon.XCircle size={13} />
  </DeleteItemButtonWrapper>
);

const RemoveItemButtonWrapper = styled.button`
  border: 0;
  background: none;
  color: ${(props) => props.theme.color.red.default};
  span {
    display: inline-block;
    font-size: 0.9rem;
    margin-top: -1px;
    padding-right: 0.25rem;
    vertical-align: top;
  }
`;

export const RemoveItemButton: any = (props: any) => (
  <RemoveItemButtonWrapper {...props}>
    {/* <Icon.XOctagon size={15} /> */}
    <span>{props.label ? props.label : "Remove item"}</span>
  </RemoveItemButtonWrapper>
);

export default Button;
