import styled from "@emotion/styled";

const Delta = styled("small")<{ color: "red" | "green" }>`
  color: ${(props) => props.theme.color[props.color]["default"]};
  ::before {
    display: inline-block;
    content: "";
    border: solid 7px transparent;
    border-left-width: 5px;
    border-right-width: 5px;
    // border-bottom-color: ${(props) =>
      props.theme.color[props.color]["default"]};
    border-top-color: ${(props) => props.theme.color[props.color]["default"]};
    width: 0px;
    height: 0px;
    margin-left: 0.5em;
    margin-right: 0.75em;
    margin-bottom: -7px;
  }
`;

export default Delta;
