import { isEmpty } from "lodash";

export const validateField =
  ({
    errorMessageText,
    regexString,
    isRequired,
  }: {
    errorMessageText: string;
    isRequired: boolean;
    regexString: string;
  }) =>
  (value: string) => {
    let errorMessage;
    if (!value && isRequired) {
      errorMessage = "Required";
    }
    if (isEmpty(regexString)) {
      return errorMessage;
    }
    if (!new RegExp(regexString).test(value)) {
      errorMessage = errorMessageText;
    }
    return errorMessage;
  };
