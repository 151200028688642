import React from "react";
import styled from "@emotion/styled";
import { css, Theme } from "@emotion/react";
import { LabelVariant } from "components/maps";

const style = (theme: Theme, contained?: boolean) => ({
  file: css`
    color: ${theme.color.grey};
    background: ${theme.color.grey}15;
  `,

  success: css`
    color: ${theme.color.green.default};
    background: ${theme.color.green.light};
  `,

  error: css`
    color: ${contained ? theme.color.default : theme.color.red.default};
    background: ${contained ? theme.color.red.default : theme.color.red.light};
  `,

  warn: css`
    color: ${theme.color.orange.default};
    background: ${theme.color.orange.light};
  `,

  default: css`
    color: ${theme.color.dark};
    background: ${theme.color.blue.light};
  `,

  pill: css`
    color: ${theme.color.primary};
    background: ${theme.color.blue.default}26;
  `,

  type: css`
    color: ${theme.color.dark};
    background: ${theme.color.blue.default}15;
  `,

  posted: css`
    color: ${theme.color.grey};
    background: ${theme.color.light};
    font-weight: normal;
  `,

  processing: css`
    background: ${theme.color.light};
    color: ${theme.color.dark};
    font-weight: bold;
  `,
});

const LabelWrapper = styled("span")<{
  circular?: boolean;
  compact: boolean;
  contained: boolean;
  variant: LabelVariant;
}>`
  padding: ${(props) => (props.compact ? "3px 6px" : "4px 8px")};
  font-size: ${(props) => (props.compact ? ".75rem" : "0.85rem")};
  font-weight: bolder;
  border-radius: ${(props) => (props.circular ? "20px" : "5px")};
  ${(props) => style(props.theme, props?.contained)[props.variant]}
`;

const Label: React.FC<{
  variant?: LabelVariant;
  content?: string;
  circular?: boolean;
  compact?: boolean;
  contained?: boolean;
  children?: any;
}> = ({
  variant = "default",
  content,
  circular = false,
  children,
  compact = false,
  contained = false,
}) => {
  return (
    <LabelWrapper
      circular={circular}
      variant={variant}
      compact={compact}
      contained={contained}
    >
      {content || children}
    </LabelWrapper>
  );
};

export default Label;
