import { InputHTMLAttributes, TextareaHTMLAttributes } from "react";
import { css } from "@emotion/react";
import styled from "@emotion/styled";

export interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  error?: boolean;
}

export const baseInputStyles = css(`
  box-sizing: border-box;
  border-radius: 8px;
  width: 100%;
  padding: 8px 14px;
  outline: none;
  :focus {
    border-color: #80bdff;
    outline: 0;
  }
  &.error {
    border-color: #d50000;
  }
`);

const Input = styled("input")<InputProps>`
  color: ${(props) => props.theme.color.secondary};
  background: #fff;

  &[type="text"],
  &[type="tel"],
  &[type="number"],
  &[type="password"],
  &[type="email"],
  &[type="url"] {
    ${baseInputStyles}
    border: solid 1px ${(props) => props.theme.color.border};
    ::placeholder {
      color: ${(props) => props.theme.color.grey};
      opacity: 0.66;
    }
    ${(props) =>
      props!.error &&
      `border-color: #f56565;
      border-width: 2px;`}
  }

  &[type="checkbox"] {
    appearance: none;
    padding: 5px;
    fill: ${(props) => props.theme.color.light};
    border: solid 1px ${(props) => props.theme.color.border};
    border-radius: 3px;
    position: relative;
    background: ${(props) => props.theme.color.light};

    :checked {
      border-color: ${(props) => props.theme.color.blue.default};
      :after {
        content: "";
        display: flex;
        position: absolute;
        top: 1px;
        left: 1px;
        padding: 4px;
        border-radius: 2px;

        background: ${(props) => props.theme.color.dark};
      }
    }
  }
`;

Input.defaultProps = {
  type: "text",
};

export const InputError = styled("p")`
  color: #d50000;
  font-size: 0.75rem;
  font-style: italic;
  margin-top: 0.375rem;
`;

export const InputLabel = styled("label")`
  color: ${(props) => props.theme.color.grey};
  display: block;
  margin-bottom: 0.5rem;
  margin-left: 5px;
  font-size: 0.875rem;
  font-weight: bold;
`;

// Text area
export interface TextAreaInputProps
  extends TextareaHTMLAttributes<HTMLTextAreaElement> {
  error?: boolean;
}

export const TextAreaInput = styled("textarea")<TextAreaInputProps>`
  ${baseInputStyles}
  border: solid 1px ${(props) => props.theme.color.border};
  color: ${(props) => props.theme.color.secondary};
  background: #fff;
  ${(props) =>
    props!.error &&
    `border-color: #f56565;
    border-width: 2px;`}
`;

TextAreaInput.defaultProps = {
  rows: 4,
};

export default Input;
