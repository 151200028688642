import React from "react";
import styled from "@emotion/styled";

import Header from "./Header";
import Nav from "./Nav";
import {
  useGetAllOrganisationSettingsQuery,
  useGetMeQuery,
  useGetTypesQuery,
} from "@graphql";
import { LoggedInUserContext } from "contexts/user";
import { OrganisationSettingsContext } from "contexts/organisation";
import { TypesContext } from "contexts/types";
import { StatusesContext } from "contexts/statuses";
import useAuth from "auth/useAuth";
import ErrorBoundary from "components/common/ErrorBoundary";

const AppLayoutWrapper = styled("div")`
  height: 100%;
  width: 100%;
  display: grid;
  grid-template:
    [main-left] "nav  main" 1fr [main-right]
    / 70px calc(100% - ${(props) => props.theme.dimension.navWidth});

  .rc-tabs {
    border: unset !important;
  }
`;

const MainWrapper = styled("div")`
  background: ${(props) => props.theme.color.light};
  width: calc(100vw - ${(props) => props.theme.dimension.navWidth});
  margin-left: 69px;

  > main {
    height: calc(100% - ${(props) => props.theme.dimension.toolbarHeight});
    box-sizing: border-box;
    margin-top: ${(props) => props.theme.dimension.toolbarHeight};

    .content {
      position: relative;
    }
  }
`;

interface AppLayoutProps {
  children: React.ReactElement;
}

const arrReducer = (arr?: any[]) => {
  if (arr) {
    return arr
      .map(({ id, value, name }) => ({ [id]: { value, name, id } }))
      .reduce((acc, val) => ({ ...acc, ...val }), {});
  }
  return undefined;
};

const AppLayout: React.FC<AppLayoutProps> = ({ children }) => {
  const { data, error: meError } = useGetMeQuery({
    fetchPolicy: "cache-first",
  });
  const { data: orgSettings, loading: loadingOrgSettings } =
    useGetAllOrganisationSettingsQuery({
      fetchPolicy: "cache-first",
    });
  const { data: typesData, loading: loadingTypes } = useGetTypesQuery({
    fetchPolicy: "cache-first",
  });
  const { forceAuthentication } = useAuth();

  if (loadingTypes || loadingOrgSettings) {
    return null;
  }

  const typesIdMap = arrReducer(typesData?.types);
  const typesValueMap = typesData
    ?.types!.map(({ id, value, name }) => ({ [value]: { value, name, id } }))
    .reduce((acc, val) => ({ ...acc, ...val }), {});

  const statusesIdMap = arrReducer(typesData?.statuses);
  const statusesValueMap = typesData
    ?.statuses!.map(({ id, value, name }) => ({ [value]: { value, name, id } }))
    .reduce((acc, val) => ({ ...acc, ...val }), {});

  if (
    meError &&
    meError.message === "Authentication hook unauthorized this request"
  ) {
    forceAuthentication(document.location.pathname);
  }
  return (
    <AppLayoutWrapper>
      <Nav />
      <LoggedInUserContext.Provider value={data?.me!}>
        <OrganisationSettingsContext.Provider
          value={orgSettings?.organisation_settings}
        >
          <StatusesContext.Provider
            value={{ ...statusesIdMap, ...statusesValueMap }}
          >
            <TypesContext.Provider value={{ ...typesIdMap, ...typesValueMap }}>
              <MainWrapper>
                <Header />
                <ErrorBoundary>
                  <main>{children}</main>
                </ErrorBoundary>
              </MainWrapper>
            </TypesContext.Provider>
          </StatusesContext.Provider>
        </OrganisationSettingsContext.Provider>
      </LoggedInUserContext.Provider>
    </AppLayoutWrapper>
  );
};

export default AppLayout;
