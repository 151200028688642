import { lazy, Suspense } from "react";
import { Route, Routes, Navigate, useLocation } from "react-router-dom";

import AppLayout from "layout/default";
// Auth
import useAuth from "auth/useAuth";
import LoginPage from "auth/login";

// Pages
const HomePage = lazy(() => import("pages/home"));
const CustomersPage = lazy(() => import("pages/customers"));
const MessagesPage = lazy(() => import("pages/messages"));
const WorkflowsPage = lazy(() => import("pages/workflows"));
const NotFoundpage = lazy(() => import("pages/NotFoundpage"));
const AccountPage = lazy(() => import("pages/account"));
const ProductsPage = lazy(() => import("pages/products"));
const LoansPage = lazy(() => import("pages/loans"));
const SubscriptionsPage = lazy(() => import("pages/subscriptions"));
const SettingsPage = lazy(() => import("pages/settings"));

function SuspenseWrapper({
  component: Component,
}: {
  component: React.LazyExoticComponent<React.FC<{}>>;
}) {
  return (
    <Suspense fallback={<></>}>
      <Component />
    </Suspense>
  );
}

function RequireAuth({
  children,
  redirectTo,
}: {
  redirectTo: string;
  children: any;
}): any {
  const { isAuthenticated } = useAuth();
  return isAuthenticated ? (
    children
  ) : (
    <Navigate to='/login' state={{ from: redirectTo }} />
  );
}

const AppRoutes = () => {
  return (
    <AppLayout>
      <Routes>
        <Route path='' element={<SuspenseWrapper component={HomePage} />} />
        <Route
          path='account/*'
          element={<SuspenseWrapper component={AccountPage} />}
        />
        <Route
          path='customers/*'
          element={<SuspenseWrapper component={CustomersPage} />}
        />
        <Route
          path='conversations/*'
          element={<SuspenseWrapper component={MessagesPage} />}
        />
        <Route
          path='workflows/*'
          element={<SuspenseWrapper component={WorkflowsPage} />}
        />
        <Route
          path='products/*'
          element={<SuspenseWrapper component={ProductsPage} />}
        />
        <Route
          path='loans/*'
          element={<SuspenseWrapper component={LoansPage} />}
        />
        <Route
          path='subscriptions/*'
          element={<SuspenseWrapper component={SubscriptionsPage} />}
        />
        <Route
          path='settings/*'
          element={<SuspenseWrapper component={SettingsPage} />}
        />
      </Routes>
    </AppLayout>
  );
};

const App = () => {
  const location = useLocation();
  return (
    <Routes>
      <Route path='/login' element={<LoginPage />} />
      <Route
        path='/*'
        element={
          <RequireAuth redirectTo={location.pathname + location.search}>
            <AppRoutes />
          </RequireAuth>
        }
      />
      <Route path='*' element={<SuspenseWrapper component={NotFoundpage} />} />
    </Routes>
  );
};

export default App;
