// Node
import React from "react";
import { createRoot } from "react-dom/client";
import {
  useLocation,
  useNavigationType,
  createRoutesFromChildren,
  matchRoutes,
  BrowserRouter as Router,
} from "react-router-dom";
import { ApolloProvider } from "@apollo/client";
import { ThemeProvider } from "@emotion/react";
import "react-loading-skeleton/dist/skeleton.css";

// import * as Sentry from "@sentry/react";

// Local
import { ToastProvider } from "components/ui/toast/ToastContext";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { createClient } from "./lib/apollo";
import { theme } from "./theme";

import "./index.css";

const client = createClient();

// const release = process.env.REACT_APP_SENTRY_RELEASE
//   ? process.env.REACT_APP_SENTRY_RELEASE
//   : undefined;

// // Sentry.init({
// //   dsn: "https://50ae5a46e75e441d88f91d33b3c5e62b@apm.fsos.app/1",
// //   release,
// //   autoSessionTracking: false,
// //   integrations: [
// //     new Sentry.BrowserTracing({
// //       // See docs for support of different versions of variation of react router
// //       // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
// //       routingInstrumentation: Sentry.reactRouterV6Instrumentation(
// //         React.useEffect,
// //         useLocation,
// //         useNavigationType,
// //         createRoutesFromChildren,
// //         matchRoutes
// //       ),
// //     }),
// //     new Sentry.Replay(),
// //   ],

// //   // Set tracesSampleRate to 1.0 to capture 100%
// //   // of transactions for performance monitoring.
// //   tracesSampleRate: 1.0,

// //   // Capture Replay for 10% of all sessions,
// //   // plus for 100% of sessions with an error
// //   replaysSessionSampleRate: 0.1,
// //   replaysOnErrorSampleRate: 1.0,
// // });

const container = document.getElementById("root");
const root = createRoot(container!);

root.render(
  <React.StrictMode>
    <ApolloProvider client={client}>
      <ThemeProvider theme={theme}>
        <ToastProvider>
          <Router>
            <App />
          </Router>
        </ToastProvider>
      </ThemeProvider>
    </ApolloProvider>
  </React.StrictMode>
);

reportWebVitals();
