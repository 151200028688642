import React from "react";
import { FieldProps } from "formik";

import FormFieldWrapper from "./FormFieldWrapper";
import { InputError, InputLabel } from "../inputs/Input";
import DatePicker from "components/ui/DatePicker";

interface InputFormFieldProps {
  label?: string;
  icon?: React.ReactNode;
  onChange: any;
}

const DateFormField: React.FC<InputFormFieldProps & FieldProps> = ({
  label,
  ...props
}) => {
  return (
    <FormFieldWrapper>
      {label && <InputLabel>{label}</InputLabel>}
      <DatePicker {...props} />
      {
        // @ts-ignore
        props.touched &&
          // @ts-ignore
          props.touched[props.name] &&
          // @ts-ignore
          props.errors[props.name] && <InputError />
      }
      {props.form &&
        props.form.touched[props.field.name] &&
        props.form.errors[props.field.name] && <InputError />}
    </FormFieldWrapper>
  );
};

export default DateFormField;
