import React from "react";
import { Organisation_Settings } from "@graphql";

export type OrganisationSettingsType =
  | Array<
      { __typename?: "organisation_settings" } & Pick<
        Organisation_Settings,
        "id" | "name" | "value"
      >
    >
  | undefined;

export const OrganisationSettingsContext =
  React.createContext<OrganisationSettingsType>(undefined);
