import React, { useEffect, useState, useRef } from "react";
// @ts-ignore
import { DatePicker as DP } from "@atlaskit/datetime-picker";
import styled from "@emotion/styled";
import { parseISO } from "date-fns";

import { isEmpty } from "lodash";

const DatePickerWrapper = styled("div")`
  position: relative;
`;

const CalendarWrapper = styled("div")<{ show: boolean }>`
  // position: absolute;
  // left: 0;
  // top: 100%;
  // margin-top: 5px;
  border: solid 1px ${(props) => props.theme.color.border};
  border-radius: 8px;
  // display: ${(props) => (props.show ? "initial" : "none")};
  font-size: 0.85rem;

  > div > div {
    border-radius: 8px;
  }
`;

interface DatePickerState {
  show: boolean;
  value: Date;
  minDate?: string;
}

const DatePicker: React.FC<{
  onChange: (value: string) => void;
  initialValues?: any;
  minDate?: string;
  name?: any;
  key?: any;
  form?: any;
  field?: any;
}> = ({ onChange, ...rest }) => {
  const { initialValues, name, key, form, field, minDate } = rest;
  const pickerEl = useRef(null);
  const getInitialState = (): DatePickerState => {
    return {
      show: false,
      value: initialValues?.[name] ? new Date(initialValues[name]) : new Date(),
      minDate,
    };
  };

  const [state, setState] = useState<DatePickerState>(getInitialState);

  const open = () => setState({ ...state, show: true });
  const close = () => setState({ ...state, show: false });

  const handleChange = (value: any) => {
    if (!isEmpty(value)) {
      const selectedDate = parseISO(value);
      setState({ value: selectedDate, show: false });
      if (!isEmpty(form) && !isEmpty(field)) {
        form.setFieldValue(field.name, value);
      } else {
        onChange(new Date(value).toISOString());
      }
    }
  };

  useEffect(() => {
    const handleClick = (e: unknown) => {
      // @ts-ignore
      if (pickerEl?.current?.contains(e.target)) {
        open();
      } else {
        close();
      }
    };
    document.addEventListener("mousedown", handleClick, false);
    return () => {
      document.removeEventListener("mousedown", handleClick, false);
    };
  });

  return (
    <DatePickerWrapper ref={pickerEl} key={key}>
      <CalendarWrapper show={true}>
        <DP
          appearance='subtle'
          onChange={handleChange}
          dateFormat='DD MMMM YYYY'
          value={state.value.toISOString()}
          minDate={state.minDate}
          locale='en-UK'
        />
      </CalendarWrapper>
    </DatePickerWrapper>
  );
};

export default DatePicker;
