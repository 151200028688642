import styled from "@emotion/styled";
import { NavLink } from "react-router-dom";
import * as Icon from "react-feather";

import logo from "assets/images/brand.png";

const NavWrapper = styled("nav")`
  background: #fff;
  border-right: solid 1px rgba(35, 100, 236, 0.15);
  height: 100%;
  padding: 13px;
  box-sizing: border-box;
  position: fixed;
`;

export const Brand = styled("div")`
  width: 42px;
  height: 42px;
  img {
    width: 100%;
  }
`;

const NavItems = styled("div")`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 1.35rem;
`;

const NavItem = styled(NavLink)`
  text-decoration: none;
  padding: 8px;
  border-radius: 6px;
  color: ${(props) => props.theme.color.grey};
  margin-bottom: 1.25rem;

  svg {
    vertical-align: middle;
  }

  &.active {
    background: rgba(35, 100, 236, 0.15);
    svg {
      color: ${(props) => props.theme.color.primary};
    }
  }
`;

const navItems = [
  {
    key: "home",
    icon: <Icon.Home size='22' />,
    to: "/",
    exact: true,
  },
  {
    key: "customers",
    icon: <Icon.User size='22' />,
    to: "/customers",
  },
  {
    key: "message",
    icon: <Icon.MessageSquare size='22' />,
    to: "/conversations",
  },
  {
    key: "workflows",
    icon: <Icon.GitPullRequest size='22' />,
    to: "/workflows",
  },
  {
    key: "products",
    icon: <Icon.Package size='22' />,
    to: "/products",
  },
  {
    key: "loans",
    icon: <Icon.BookOpen size='22' />,
    to: "/loans",
  },
];

const Nav = () => {
  return (
    <NavWrapper>
      <Brand>
        <img src={logo} alt='FS/OS' />
      </Brand>
      <NavItems>
        {navItems.map((navItem) => (
          <NavItem
            to={navItem.to}
            end={navItem.exact || false}
            key={navItem.key}
          >
            {navItem.icon}
          </NavItem>
        ))}
      </NavItems>
    </NavWrapper>
  );
};

export default Nav;
