import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import * as Icon from "react-feather";
import styled from "@emotion/styled";

import { FlexWrapper, IconButton } from "components/ui";
import { Input } from "components/ui/form";
import { LoggedInUserContext } from "contexts/user";
import AuthAvatar from "./AuthAvatar";
import { MeOutput } from "@graphql";

const HeaderWrapper = styled(FlexWrapper)`
  border-bottom: solid 1px rgba(35, 100, 236, 0.15);
  padding: 0.75rem 1rem;
  background: ${(props) => props.theme.color.light};
  position: fixed;
  z-index: 10;
  width: calc(100% - 100px);

  input {
    max-width: 50%;
  }

  a {
    color: ${(props) => props.theme.color.grey};
    text-decoration: none;
  }
`;

const Header = () => {
  const data = useContext(LoggedInUserContext) as MeOutput;
  let navigate = useNavigate();

  return (
    <HeaderWrapper justify='space-between'>
      <Input type='text' />

      <FlexWrapper>
        <IconButton
          style={{ marginRight: "1rem" }}
          icon={<Icon.Settings strokeWidth={2.2} />}
          color='grey'
          onClick={() => navigate("/settings")}
        />
        <AuthAvatar name='FS/OS' alt={data?.name!} icon={data?.avatar!} />
      </FlexWrapper>
    </HeaderWrapper>
  );
};

export default Header;
