import { useState, useEffect } from "react";

const useBoolean = (
  initialValue?: boolean
): {
  value: boolean;
  toggle: () => void;
  on: () => void;
  off: () => void;
} => {
  const [value, setValue] = useState(false);

  const toggle = () => setValue(!value);

  useEffect(() => {
    if (initialValue) {
      setValue(initialValue);
    }
  }, [initialValue]);

  return {
    value,
    toggle,
    on: () => setValue(true),
    off: () => setValue(false),
  };
};

export default useBoolean;
