import {
  split,
  ApolloClient,
  InMemoryCache,
  HttpLink,
  NormalizedCacheObject,
} from "@apollo/client";
import { getMainDefinition } from "@apollo/client/utilities";
import { GraphQLWsLink } from "@apollo/client/link/subscriptions";
import { createClient as createSubscriptionsClient } from "graphql-ws";

/* Local */
import fragments from "@graphql/fragments";
import { storageKey } from "auth/useAuth";

// ----------------------------------------------------------------------------

export const createClient = (
  useHeaders: boolean = true
): ApolloClient<NormalizedCacheObject> => {
  // Match up fragments
  const cache = new InMemoryCache({ possibleTypes: fragments.possibleTypes });

  let token;

  try {
    token = JSON.parse(localStorage.getItem(storageKey)!).accessToken;
  } catch (error) {
    token = undefined;
  }

  let headers = Boolean(token)
    ? {
        Authorization: `Bearer ${token}`,
      }
    : {};

  let httpLink = new HttpLink({
    uri: process.env.REACT_APP_GRAPHQL_URL,
    headers: {
      "content-type": "application/json",
      ...(Boolean(token) && { Authorization: `Bearer ${token}` }),
    },
  });

  if (!useHeaders) {
    httpLink = new HttpLink({
      uri: process.env.REACT_APP_GRAPHQL_URL,
      headers: {
        "content-type": "application/json",
      },
    });
  }

  const wsLink = new GraphQLWsLink(
    createSubscriptionsClient({
      url: process.env.REACT_APP_GRAPHQL_URL?.replace(/^https?/, "wss") || "",
      connectionParams: () => {
        return {
          "content-type": "application/json",
          headers,
        };
      },
    })
  );

  const splitLink = split(
    ({ query }) => {
      const definition = getMainDefinition(query);
      return (
        definition.kind === "OperationDefinition" &&
        definition.operation === "subscription"
      );
    },
    wsLink,
    httpLink
  );

  return new ApolloClient({
    link: splitLink,
    cache,
    defaultOptions: { watchQuery: { fetchPolicy: "cache-and-network" } },
  });
};
