import React, { useEffect, useState, useRef } from "react";
// @ts-ignore
import { DateTimePicker as DTP } from "@atlaskit/datetime-picker";
import styled from "@emotion/styled";
import { isEmpty } from "lodash";

const DatePickerWrapper = styled("div")`
  position: relative;
`;

const DateWrapper = styled("div")<{ show: boolean }>`
  margin-top: 5px;
  border: solid 1px ${(props) => props.theme.color.border};
  border-radius: 8px;
`;

interface DatePickerState {
  show: boolean;
  value: string;
}

const DateTimePicker: React.FC<{
  onChange: (value: string) => void;
}> = ({ onChange }) => {
  const pickerEl = useRef(null);

  const getInitialState = (): DatePickerState => {
    return {
      show: true,
      value: new Date().toString(),
    };
  };

  const [state, setState] = useState<DatePickerState>(getInitialState);

  const open = () => setState({ ...state, show: true });

  const close = () => setState({ ...state, show: false });

  const handleChange = (value: any) => {
    if (!isEmpty(value)) {
      setState({ ...state, value: value.toString() });
      onChange(value.toString());
    }
  };

  useEffect(() => {
    const handleClick = (e: unknown) => {
      // @ts-ignore
      if (pickerEl?.current?.contains(e.target)) {
        open();
      } else {
        close();
      }
    };

    document.addEventListener("mousedown", handleClick, false);
    return () => {
      document.removeEventListener("mousedown", handleClick, false);
    };
  });

  return (
    <DatePickerWrapper ref={pickerEl}>
      <DateWrapper show={state.show}>
        <DTP
          appearance='subtle'
          onChange={handleChange}
          dateFormat='YYYY-MM-DD'
          timeFormat='HH:mm'
          value={state.value}
          datePickerProps={{
            parseInputValue: (date: string) => new Date(date),
            placeholder: "Select Date",
          }}
          timePickerProps={{
            placeholder: "Select Time",
          }}
          spacing='compact'
          onBlur={console.info}
          locale='en-UK'
        />
      </DateWrapper>
    </DatePickerWrapper>
  );
};

export default DateTimePicker;
