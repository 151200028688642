import { FieldProps } from "formik";
import React, { useState } from "react";
import styled from "@emotion/styled";
import * as Icon from "react-feather";

import { IconButton } from "components/ui/Button";
import { Input } from "components/ui/form";
import { InputLabel, InputProps } from "../inputs/Input";
import FormFieldWrapper from "./FormFieldWrapper";

const SelectField = styled("div")`
  position: relative;
  display: flex;
  align-items: center;
`;

const MultiSelectField = styled(SelectField)`
  background: #fff;
  border: solid 1px ${(props) => props.theme.color.border};
  border-radius: 0.5rem;
  box-sizing: border-box;
  flex-wrap: wrap;
  min-height: 1.9375rem;
  padding: 0.3625rem 0.875rem 0.3625rem 0.5rem;
  row-gap: 0.25rem;
`;

const MultiSelectValue = styled.div`
  background: ${(props) => props.theme.color.border};
  border-radius: 0.125rem;
  display: flex;
  margin-right: 0.5rem;
  padding-left: 0.125rem;

  span {
    font-size: 0.75rem;
    padding-right: 0.125rem;
  }

  button {
    background: none;
    border: 0;
    outline: none;

    &:hover {
      background-color: rgb(255, 189, 173);
      color: rgb(222, 53, 11);
    }

    svg {
      height: 12px;
      margin-top: 3px;
    }
  }
`;

const SelectMenu = styled("div")<{ active: boolean }>`
  position: absolute;
  z-index: 10;
  width: 100%;
  margin-top: 5px;
  visibility: ${(props) => (props.active ? "visible" : "hidden")};
  opacity: ${(props) => (props.active ? "1" : "0")};
  transform: translateY(${(props) => (props.active ? "0%" : "-50%")});
  transition: all 250ms ease-in-out;
  border-radius: 3px;
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
    0 4px 6px -2px rgba(0, 0, 0, 0.05);
  background: #fff;

  .search-field {
    border-left: 0;
    border-right: 0;
    border-top: 0;
    border-radius: 0;
    width: 100% !important;
  }
`;

const SelectOptions = styled("div")`
  border-radius: 0.375rem;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.05);
  max-height: 250px;
  overflow: scroll;
  padding: 5px 0;
`;

const SelectOption = styled("div")<{ selected?: boolean }>`
  padding: 12px 16px;
  position: relative;
  cursor: pointer;
  background: ${(props) =>
    props.selected ? props.theme.color.border : "#fff"};
  font-weight: ${(props) => (props.selected ? "700" : "300")};
  color: ${(props) => props.theme.color.dark};
  box-sizing: content-box;
  &.empty {
    text-align: center;
  }
  :hover {
    background: ${(props) => props.theme.color.border};
  }
`;

const SelectIcon = styled(IconButton)<{ active: boolean }>`
  background: #fff;
  cursor: pointer;
  position: absolute;
  z-index: 2;
  right: 3px;
  border-radius: 4px;
  padding: 0;
  height: 28px;
  width: 28px;
  transform: rotate(${(props) => (props.active ? "180" : "0")}deg);

  svg {
    height: 18px;
  }
`;

const ClearMultiSelect = styled(SelectIcon)`
  right: 2rem;

  svg {
    height: 16px;
  }
`;

const SelectFormField: React.FC<
  {
    options?: IOption[];
    label?: string | JSX.Element;
    placeholder?: string;
    compact?: boolean;
    isSearchable?: boolean;
    isMultiValue?: boolean;
  } & InputProps &
    FieldProps
> = ({
  options = [],
  label,
  placeholder,
  form,
  field: { name, value: val },
  isSearchable = true,
  isMultiValue = false,
}) => {
  const [active, setActive] = useState(false);
  const [value, setValue] = useState<IOption>();
  const [selectedMultiValues, setSelectedMultiValue] = useState<IOption[]>([]);
  const [searchTerm, setSearchTerm] = useState<string | undefined>(undefined);

  let selectedOption: IOption[] | undefined = [];
  if (isMultiValue && Array.isArray(val) && selectedMultiValues.length === 0) {
    selectedOption = options?.filter((opt) => val.includes(opt.value));
  }

  const getLabelFromValue = (v: string) => {
    const result = options?.filter((o) => o.value === v).map((o) => o.label);
    if (result && result.length > 0) {
      return result[0];
    }
    return v;
  };

  let fieldValue: IOption = {
    key: val,
    value: val,
    label: getLabelFromValue(val),
  };
  if (value) {
    fieldValue = value;
  }

  let opts = searchTerm
    ? options?.filter((opt) =>
        opt.label.toLowerCase().includes(searchTerm.toLowerCase())
      )
    : options;

  opts = isMultiValue
    ? opts?.filter(
        (opt) => !selectedMultiValues.map((o) => o.value).includes(opt.value)
      )
    : opts;

  return (
    <FormFieldWrapper style={{ position: "relative" }}>
      {label && <InputLabel>{label}</InputLabel>}
      {!isMultiValue && (
        <SelectField>
          <Input
            autoComplete='off'
            onBlur={() => setActive(false)}
            onFocus={() => setActive(true)}
            onChange={(e) => {
              if (isSearchable) {
                setActive(true);
                setValue(undefined);
                setSearchTerm(e.target.value);
              } else {
                setActive(false);
              }
            }}
            value={searchTerm ? searchTerm : fieldValue.label}
            name={name}
            placeholder={placeholder}
          />
          <SelectIcon
            type='button'
            icon={<Icon.ChevronDown />}
            active={active}
            color='grey'
            onClick={() => setActive(!active)}
          />
        </SelectField>
      )}
      {isMultiValue && (
        <MultiSelectField className='multi-select-field'>
          {[
            ...selectedMultiValues,
            ...selectedOption!,
            ...(opts! && opts?.filter((o) => o.value === val)),
          ].map((opt) => (
            <MultiSelectValue key={`multi-select-${opt.key}`}>
              <span>{opt.label}</span>
              <button
                onClick={() => {
                  setSelectedMultiValue(
                    selectedMultiValues.filter(
                      (sopt) => sopt.value !== opt.value
                    )
                  );
                }}
              >
                <Icon.X />
              </button>
            </MultiSelectValue>
          ))}
          {selectedMultiValues.length > 0 && (
            <ClearMultiSelect
              type='button'
              color='grey'
              icon={<Icon.X />}
              onClick={() => setSelectedMultiValue([])}
              active={false}
              className='clear-multi-select'
            />
          )}
          <SelectIcon
            type='button'
            icon={<Icon.ChevronDown />}
            active={active}
            color='grey'
            onClick={() => setActive(!active)}
          />
        </MultiSelectField>
      )}
      <SelectMenu active={active} className='select-menu'>
        {isMultiValue && (
          <Input
            autoComplete='off'
            onChange={(e) => {
              setSearchTerm(e.target.value);
            }}
            value={searchTerm ? searchTerm : ""}
            name={`${name}-search`}
            className='search-field'
            placeholder={`Search ${label}`}
          />
        )}
        <SelectOptions>
          {options &&
            opts?.map((option, index) => (
              <SelectOption
                key={option.key || `${name}-select-option-${index + 1}`}
                selected={fieldValue && fieldValue.value === option.value}
                onClick={() => {
                  if (isMultiValue) {
                    const selectedValues = Array.from(
                      new Set([...selectedMultiValues, option])
                    );
                    form.setFieldValue(
                      name,
                      selectedValues.map((opt) => opt.value)
                    );
                    setSelectedMultiValue(selectedValues);
                  } else {
                    form.setFieldValue(name, option.value);
                    setValue(option);
                  }

                  setActive(false);
                  setSearchTerm(undefined);
                }}
              >
                {option.label}
              </SelectOption>
            ))}
        </SelectOptions>
      </SelectMenu>
    </FormFieldWrapper>
  );
};

export default SelectFormField;

export const GenericSelectFormField: React.FC<{
  options?: IOption[];
  label?: string | JSX.Element;
  compact?: boolean;
  name: string;
  value?: IOption;
  onClick: any;
  active: boolean;
  setActive: any;
}> = ({ options, label, name, onClick, setActive, value, active }) => {
  return (
    <FormFieldWrapper style={{ position: "relative" }}>
      {label && <InputLabel>{label}</InputLabel>}
      <SelectField>
        <Input
          autoComplete='off'
          onBlur={() => setActive(false)}
          onFocus={() => setActive(true)}
          onChange={() => {
            setActive(false);
          }}
          value={value ? value!.label : ""}
          name={name}
        />
        <SelectIcon
          type='button'
          icon={<Icon.ChevronDown />}
          active={active}
          color='grey'
          onClick={() => setActive(!active)}
        />
      </SelectField>
      <SelectMenu active={active}>
        <SelectOptions>
          {options &&
            options.map((option, index) => (
              <SelectOption
                key={option.key || `${option.label}-${index}`}
                selected={value && value.value === option.value}
                onClick={() => {
                  onClick(option);
                  setActive(false);
                }}
              >
                {option.label}
              </SelectOption>
            ))}
        </SelectOptions>
      </SelectMenu>
    </FormFieldWrapper>
  );
};
