import React, { useCallback, useContext, useState } from "react";

import ToastContainer from "./ToastContainer";

const ToastContext = React.createContext<{
  toasts: Array<ToastType>;
  toastLocation: string;
  toast: (toast: AlertType) => void;
  removeToast: (id: string) => void;
  setToastLocation: (placing: "bottom" | "top") => void;
} | null>(null);

const ToastProvider: React.FC<{ children: any }> = (props: any) => {
  const [toasts, setToasts] = useState<Array<ToastType>>([]);
  const [toastLocation, setToastLocation] = useState<"bottom" | "top">(
    "bottom"
  );

  const addToast = useCallback(
    (toast: AlertType) => {
      setToasts((_toasts) => [
        ..._toasts,
        { id: (_toasts.length + 1).toString(), ...toast },
      ]);
    },
    [setToasts]
  );

  const removeToast = useCallback(
    (id: any) => {
      setToasts((_toasts) => _toasts.filter((toast) => toast?.id !== id));
    },
    [setToasts]
  );

  return (
    <ToastContext.Provider
      value={{
        toasts,
        toast: addToast,
        removeToast,
        setToastLocation,
        toastLocation,
      }}
    >
      <ToastContainer toasts={toasts} placing={toastLocation} />
      {props.children}
    </ToastContext.Provider>
  );
};

const useToast = (): {
  toasts: Array<ToastType>;
  toast: (toast: AlertType) => void;
  removeToast: (id: string) => void;
  setToastLocation: (placing: "bottom" | "top") => void;
} | null => useContext(ToastContext);

export { ToastProvider, useToast };
export default ToastContext;
