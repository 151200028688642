import React from "react";
import { FieldProps } from "formik";

import FormFieldWrapper from "./FormFieldWrapper";
import { InputError, InputLabel } from "../inputs/Input";
import DateTimePicker from "components/ui/DateTimePicker";

interface InputFormFieldProps {
  label?: string;
  icon?: React.ReactNode;
  onChange: any;
}

const DateTimeFormField: React.FC<InputFormFieldProps & FieldProps> = ({
  label,
  ...props
}) => {
  return (
    <FormFieldWrapper>
      {label && <InputLabel>{label}</InputLabel>}
      <DateTimePicker {...props} />
      {
        // @ts-ignore
        props.touched[props.name] && props.errors[props.name] && <InputError />
      }
    </FormFieldWrapper>
  );
};

export default DateTimeFormField;
