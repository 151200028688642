import { Field, Form, Formik } from "formik";
import React from "react";
import { object, string } from "yup";
import * as Icon from "react-feather";
import styled from "@emotion/styled";

import { Button } from "components/ui";
import { CheckboxFormField, InputFormField } from "components/ui/form";

const LoginButtonWrapper = styled(Button)`
  width: 100%;
  margin-top: 1.5rem;
  position: relative;

  svg {
    color: ${(props) => props.theme.color.default};
    position: absolute;
    right: 0;
  }
`;

const loginSchema = object().shape({
  email: string().email("Invalid email").required(),
  password: string().min(6).required(),
});

export type HandleLoginFn = (params: {
  email: string;
  password: string;
  rememberMe: boolean;
}) => void;

const LoginForm: React.FC<{
  handleSubmit: HandleLoginFn;
  loading: boolean;
}> = ({ handleSubmit, loading }) => {
  const initialFormState = {
    email: "",
    password: "",
    rememberMe: false,
  };

  return (
    <Formik
      initialValues={initialFormState}
      onSubmit={handleSubmit}
      validationSchema={loginSchema}
    >
      {({ isValid }) => (
        <Form>
          <Field
            component={InputFormField}
            icon={<Icon.AtSign />}
            type='email'
            label='Email'
            name='email'
            placeholder='example@fsos.app'
          />
          <Field
            component={InputFormField}
            icon={<Icon.Lock />}
            type='password'
            label='Password'
            name='password'
            placeholder='password'
          />

          <Field
            component={CheckboxFormField}
            icon={<Icon.Lock />}
            label='Remember me'
            name='rememberMe'
          />

          <LoginButtonWrapper
            contained
            color='primary'
            type='submit'
            disabled={loading || !isValid}
          >
            Login
            <Icon.ArrowRight />
          </LoginButtonWrapper>
        </Form>
      )}
    </Formik>
  );
};

export default LoginForm;
