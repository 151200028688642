import { Theme } from "@emotion/react";
import styled from "@emotion/styled";
import React from "react";

const chipBackgroundColor = (color: ColorVariant, theme: Theme) => {
  switch (color) {
    case "light":
      return `
        background: ${theme.color.grey};
        opacity: .66;
      `;

    default:
      return `
        background: ${theme.color.light};
      `;
  }
};

const ChipWrapper = styled("div")<{ compact: boolean; color: ColorVariant }>`
  border: none;
  cursor: default;
  height: ${(props) => (props.compact ? "18px" : "30px")};
  display: inline-flex;
  align-items: center;
  padding: 0px ${(props) => (props.compact ? "6px" : "15px")};
  border-radius: 16px;
  color: ${
    // @ts-ignore
    (props) => props.theme.color[props.color]
  };
  font-size: ${(props) => (props.compact ? "0.8rem" : "0.85rem")};
  ${(props) => chipBackgroundColor(props.color, props.theme)}

  svg {
    margin-right: 0.35rem;
    height: 16px;
    width: 16px;
  }
`;

const Chip: React.FC<{
  label: string;
  icon?: React.ReactNode;
  compact?: boolean;
  color?: ColorVariant;
}> = ({ label, icon, compact = false, color = "grey" }) => {
  return (
    <ChipWrapper compact={compact} color={color}>
      {icon}
      <span>{label}</span>
    </ChipWrapper>
  );
};

export default Chip;
