export const theme = {
  dimension: {
    toolbarHeight: "65px",
    navWidth: "70px",
  },
  color: {
    default: "#FFFFFF",
    primary: "#2364EC",
    secondary: "#1F2937",
    dark: "#072784",
    light: "#F6F7FE",

    grey: "#6B7280",
    border: "rgba(35, 100, 236, 0.15)",

    black: "#000000",

    blue: {
      default: "#2364EC",
      light: "rgba(35, 100, 236, .33)",
    },

    green: {
      default: "#10C96D",
      light: "rgba(16, 201, 109, .20)",
    },

    red: {
      default: "#DC2626",
      light: "rgba(220, 38, 38, .33)",
    },

    purple: {
      default: "#7C3AED",
      light: "rgba(124, 58, 237, .33)",
    },

    orange: {
      default: "#B45309",
      light: "rgba(255, 170, 6, .15)",
    },
  },
};
