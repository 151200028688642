import styled from "@emotion/styled";
import Button from "./Button";
import { ReactComponent as SortIcon } from "assets/icons/sort-by-icon.svg";
import Dropdown from "./Dropdown";
import useBoolean from "components/hooks/useBoolean";

type SortButtonType = {
  color?: "grey" | "default" | "primary" | "secondary" | "dark" | "light";
  size?: SizeVariant;
  sortOptions?: {
    key: string;
    label: string;
    iconRight?: React.ReactNode;
    onClick: (params?: any) => void;
    divider?: boolean;
    description?: string;
    severity?: SeverityTypes;
    active?: boolean;
  }[];
  onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
};

const SortButtonWrapper = styled.div`
  position: relative;

  svg {
    padding-left: 0.4rem;
    margin-right: 0.625rem;
  }
`;

const SortActionWrapper = styled.div`
  position: absolute;
  right: 1rem;
`;

const SortButton = ({
  color = "grey",
  size = "normal",
  sortOptions,
}: SortButtonType) => {
  const { value: show, on, off } = useBoolean();

  return (
    <SortButtonWrapper>
      <Button
        color={color}
        size={size}
        mr={1}
        onClick={() => {
          show ? off() : on();
        }}
      >
        <SortIcon />
        Sort By
      </Button>
      <SortActionWrapper>
        <Dropdown
          position='right'
          items={sortOptions}
          trigger={<></>}
          toggleVisibility={() => {
            show ? off() : on();
          }}
          itemsVisible={show}
        />
      </SortActionWrapper>
    </SortButtonWrapper>
  );
};

export default SortButton;
