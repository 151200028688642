import styled from "@emotion/styled";
import React, { createRef, RefObject, useEffect } from "react";

import useBoolean from "components/hooks/useBoolean";

const DropdownMenu = styled("ul")<{
  show: boolean;
  position: "right" | "left";
}>`
  list-style: none;
  border: solid 1px ${(props) => props.theme.color.border};
  background: ${(props) => props.theme.color.default};
  border-radius: 6px;
  min-width: 12rem;
  position: absolute;
  z-index: 10;
  margin-top: 5px;
  visibility: ${(props) => (props.show ? "visible" : "hidden")};
  opacity: ${(props) => (props.show ? "1" : "0")};
  ${(props) => props.position}: 0;
`;

export const DropdownItem = styled("li")<{
  header?: boolean;
  severity?: SeverityTypes;
}>`
  padding: ${(props) => (props.header ? "5px 10px" : "8px 10px")};
  font-weight: ${(props) => (props.header ? "bolder" : "normal")};
  font-size: 0.85rem;
  cursor: pointer;
  margin: 2px;
  border-radius: 3px;
  color: ${(props) =>
    props.header ? props.theme.color.secondary : props.theme.color.grey};

  &.active,
  :hover {
    .icon > svg {
      color: ${(props) => props.theme.color.secondary};
    }

    background: ${(props) =>
      props.header ? "#fff" : "rgba(35, 100, 236, 0.15)"};
    color: ${(props) => props.theme.color.blue.default};

    &:last-of-type {
      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 4px;
    }
  }

  svg {
    vertical-align: middle;
    width: 12px;
    height: 12px;
  }

  .icon {
    background: rgba(107, 114, 128, 0.33);
    color: ${(props) => props.theme.color.grey};
    border-radius: 3px;
    margin-right: 10px;
    height: 20px;
    width: 20px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
  }

  .description {
    color: #94a3b8;
    margin-top: 0.5rem;
    display: block;
    font-size: 11px;
  }

  ${(props) => {
    switch (props?.severity) {
      case "danger":
        return `
          color: ${props.theme.color.red.default};
          :hover {
            background: rgba(220, 38, 38, 0.15);
            color: ${props.theme.color.red.default};
          }

          .description {
            color: ${props.theme.color.red.default};
            opacity: 0.85;
          }
        `;
      case "warning":
        return `
          color: darkorange;
          :hover {
            background: rgba(220, 38, 38, 0.15);
          }

          .description {
            color: darkorange;
            opacity: 0.85;
          }
        `;
    }
  }}
`;

export const ItemDivider = styled("li")`
  border-bottom: solid 1px ${(props) => props.theme.color.border};
`;

export type DropdownOption = {
  divider?: boolean;
  header?: boolean;
  description?: string;
  severity?: SeverityTypes;
  active?: boolean;
  iconRight?: React.ReactNode;
  onClick?: (item: IOption) => void;
} & IOption;

const Dropdown: React.FC<{
  trigger: React.ReactElement;
  items?: DropdownOption[];
  position?: "right" | "left";
  onChange?: (item: IOption) => void;
  itemsVisible?: boolean;
  toggleVisibility?: () => void;
  informParent?: () => void;
  children?: any;
}> = ({
  children,
  trigger,
  items,
  onChange,
  position = "left",
  itemsVisible = false,
  toggleVisibility,
}) => {
  const { value: show, on, off } = useBoolean();

  const node: RefObject<HTMLDivElement> = createRef();

  const handleItemClick = (item: DropdownOption) => () => {
    if (item.onClick) {
      item.onClick(item);
    } else {
      onChange?.(item);
    }
    off();
    if (toggleVisibility) {
      toggleVisibility();
    }
  };

  const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    // @ts-ignore
    if (node.current?.contains(e.target)) {
      on();
    } else {
      off();
    }
  };

  useEffect(() => {
    // @ts-ignore
    document?.addEventListener("mousedown", handleClick, false);
    return () => {
      // @ts-ignore
      document?.removeEventListener("mousedown", handleClick, false);
    };
  });

  return (
    <div ref={node}>
      {React.cloneElement(trigger, { onClick: handleClick })}
      <DropdownMenu show={show || itemsVisible} position={position}>
        {children ||
          items?.map((item) => (
            <React.Fragment key={item.key}>
              <DropdownItem
                header={item?.header}
                onClick={handleItemClick(item)}
                severity={item?.severity}
                className={item.active ? "active" : undefined}
              >
                {item?.icon && <span className='icon'>{item?.icon}</span>}
                {item.active && <b>{item.label}</b>}
                {!item.active && item.label}
                {item?.iconRight}
                {item?.description && (
                  <small className='description'>{item?.description}</small>
                )}
              </DropdownItem>

              {item?.divider && <ItemDivider />}
            </React.Fragment>
          ))}
      </DropdownMenu>
    </div>
  );
};

export default Dropdown;
