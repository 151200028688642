import { Input } from "components/ui";

const focusInput = (elmnt: any) => {
  if (elmnt.key === "Delete" || elmnt.key === "Backspace") {
    const next = elmnt.target.tabIndex - 2;
    if (next > -1) {
      elmnt.target.form.elements[next].focus();
      elmnt.target.form.elements[next].select();
    }
  } else {
    const next = elmnt.target.tabIndex;
    if (next < 6) {
      elmnt.target.form.elements[next].focus();
      elmnt.target.form.elements[next].select();
    }
  }
};

export const generateOtpInput =
  (otpValues: any) =>
  ({ otpLength, setMfaCode }: { otpLength: number; setMfaCode: Function }) => {
    var index: number = 0;
    const result: Array<any> = [];
    for (index; index < otpLength; index++) {
      result.push(
        <Input
          key={`otp-${index}`}
          name={`mfa_code_${index}`}
          autoComplete='off'
          type='tel'
          value={otpValues[index]}
          className='otpInput'
          onChange={(e) => {
            setMfaCode({
              ...otpValues,
              [e.target.name.split("mfa_code_")[1]]: e.target.value,
            });
          }}
          tabIndex={index + 1}
          maxLength={1}
          onKeyUp={(e) => focusInput(e)}
        />
      );
    }
    return result;
  };
