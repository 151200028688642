import styled from "@emotion/styled";

const PopoverWrapper = styled("div")`
  position: absolute;
  color: ${(props) => props.theme.color.light};
  font-size: 0.85rem;
  padding: 5px;
  background: #0b0c0d;
  border-radius: 3px;
  max-width: 200px;
  right: 0px;
  z-index: 100;
`;

const Popover: React.FC<{
  trigger: React.ReactNode;
  content: React.ReactNode;
}> = ({ trigger, content }) => {
  return (
    <>
      {trigger}
      <PopoverWrapper>{content}</PopoverWrapper>
    </>
  );
};

export default Popover;
