import React from "react";
import styled from "@emotion/styled";

import avatar from "assets/images/avatar.jpeg";

const AvatarWrapper = styled("span")<{
  size?: number;
  border: boolean;
  background?: string;
  color?: string;
}>`
  width: ${(props) => (props.size ? props.size : 60)}px;
  height: ${(props) => (props.size ? props.size : 60)}px;
  border-radius: 50%;
  border: ${(props) =>
    props.border ? `solid 1px ${props.theme.color.border}` : "none"};
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bolder;
  font-size: ${(props) =>
    props.size && props.size <= 30 ? ".675rem" : "1rem"};
  color: ${(props) => (props.color ? props.color : props.theme.color.default)};

  background: ${(props) => (props.background ? props.background : "#3ac4ed")};
  img {
    width: 100%;
    border-radius: 50%;
    background-blend-mode: multiply;
  }
`;

const Avatar: React.FC<{
  src?: string | null;
  size?: number;
  alt?: string;
  background?: string;
  border?: boolean;
  color?: string;
  children?: any;
}> = ({ src, size, alt, border = false, background, color }) => {
  const getInitials = (value?: string) => {
    if (value && value.split(" ").length >= 2) {
      return `${value.split(" ")[0][0]}${value.split(" ")[1][0]}`.trim();
    } else if (value) {
      return value[0].toUpperCase();
    }

    return "N/A";
  };

  return (
    <AvatarWrapper
      className='avatar'
      size={size}
      border={border}
      background={background}
      color={color}
    >
      {src ? <img src={src || avatar} alt={alt} /> : getInitials(alt)}
    </AvatarWrapper>
  );
};

export default Avatar;
