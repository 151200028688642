import { useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import styled from "@emotion/styled";

import { useCompleteLoginMutation, useLoginMutation } from "@graphql";
import { Button, FlexWrapper, Typography } from "components/ui";
import { useToast } from "components/ui/toast/ToastContext";
import { Brand } from "layout/default/Nav";
import LoginForm, { HandleLoginFn } from "./LoginForm";

import logo from "assets/images/brand.png";
import useAuth from "auth/useAuth";
import { generateOtpInput } from "./functions";
import { createClient } from "lib/apollo";

const loginClient = createClient(false);

const LoginPageWrapper = styled("main")`
  width: 80%;
  height: 100%;
  margin: 0 auto;
  padding-top: 2rem;
  box-sizing: border-box;

  > div.content {
    height: calc(100% - 100px);

    > div {
      min-width: 340px;
    }

    h3 {
      margin-bottom: 2rem;
      font-weight: 900;
    }
  }

  .mfa {
    max-width: 320px;

    p {
      font-size: 1rem;
      margin-bottom: 1rem;
    }

    input {
      width: 3rem;
      height: 4rem;
      margin-right: 0.25rem;
      font-size: 2rem;
    }

    button {
      width: 100%;
      margin-top: 1.5rem;
      position: relative;

      svg {
        color: ${(props) => props.theme.color.default};
        position: absolute;
        right: 0;
      }
    }
  }
`;

const LoginHeader = styled(FlexWrapper)`
  padding: 1.25rem;

  > div:last-of-type {
    color: ${(props) => props.theme.color.grey};
    font-size: 0.9rem;

    a {
      font-weight: 700;
      text-decoration: none;
      color: ${(props) => props.theme.color.primary};
    }
  }
`;

const PasswordRecoveryLink = styled(NavLink)`
  font-weight: bolder;
  font-size: 0.8rem;
  text-decoration: none;
  color: ${(props) => props.theme.color.primary};
  text-align: center;
  display: block;
  margin-top: 2rem;
`;

const LoginPage = () => {
  const { isAuthenticated, login } = useAuth();
  const location = useLocation();
  const [showMFA, setShowMFA] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [chosenMFAMethod, _setChosenMFAMethod] = useState<
    "AUTHENTICATOR" | "TOTP"
  >("AUTHENTICATOR");
  const [mfaCode, setMfaCode] = useState<
    { [key: number]: number | string } | undefined
  >({});
  const helpers = useToast();

  const showErrorToast = (error: any) => {
    helpers?.toast({
      description: error.message,
      variant: "error",
    });
  };
  const otpLength = 6;

  const [loginMutation, { data, loading }] = useLoginMutation({
    onCompleted: (res) => {
      if (res?.login?.type === "SUCCESS") {
        if (res.login.requiresMFA) {
          setShowMFA(true);
        } else {
          login(
            {
              partyId: res?.login?.partyId,
              accessToken: res?.login?.accessToken,
            },
            // @ts-ignore
            location?.state?.from!
          );
        }
      }
    },
    onError: showErrorToast,
    client: loginClient,
  });

  const [completeLoginMutation, { loading: submittingMFALogin }] =
    useCompleteLoginMutation({
      onCompleted: (res) => {
        if (res?.completeLogin?.type === "SUCCESS") {
          login({
            partyId: res?.completeLogin?.partyId,
            accessToken: res?.completeLogin?.accessToken,
          });
        }
      },
      onError: showErrorToast,
      client: loginClient,
    });

  const handleSubmit: HandleLoginFn = async ({
    email,
    password,
    rememberMe,
  }) => {
    await loginMutation({
      variables: {
        params: { email, password, rememberMe },
      },
      client: loginClient,
    });
  };

  if (isAuthenticated) {
    // @ts-ignore
    document.location.href = location?.state?.from || "/";
    return null;
  }

  return (
    <LoginPageWrapper>
      <LoginHeader justify='space-between'>
        <Brand>
          <img src={logo} alt='FS/OS' />
        </Brand>

        <div style={{ display: "none" }}>
          Don't have an account?{" "}
          <NavLink to='register'>Create Account </NavLink>
        </div>
      </LoginHeader>

      <FlexWrapper className='content' justify='center' align='center'>
        {!showMFA && (
          <div>
            <h3>Log In.</h3>

            <LoginForm handleSubmit={handleSubmit} loading={loading} />

            <PasswordRecoveryLink to='/password-recovery'>
              Forgot password?
            </PasswordRecoveryLink>
          </div>
        )}
        {showMFA && (
          <div className='mfa'>
            <h3>Two-step authentication</h3>
            <form action='#'>
              <Typography component='p' color='grey'>
                To continue, please enter the 6-digit verification code
                generated by your <b>authenticator app</b>
              </Typography>
              <FlexWrapper>
                {generateOtpInput(mfaCode)({
                  otpLength,
                  setMfaCode,
                })}
              </FlexWrapper>
              <Button
                contained
                color='primary'
                type='button'
                onClick={() =>
                  completeLoginMutation({
                    variables: {
                      params: {
                        accessToken: data?.login?.accessToken!,
                        mfaMethod: chosenMFAMethod,
                        otp: Object.values(mfaCode!).join(""),
                      },
                    },
                  })
                }
                disabled={submittingMFALogin}
              >
                Continue
              </Button>
            </form>
          </div>
        )}
      </FlexWrapper>
    </LoginPageWrapper>
  );
};

export default LoginPage;
