import { Component, ErrorInfo, ReactNode } from "react";
import styled from "@emotion/styled";
import * as Icon from "react-feather";
import { Button, FlexWrapper, Typography } from "components/ui";
import uhOh from "assets/images/uh-oh.webp";

const Wrapper = styled.div`
  > div:first-of-type {
    height: 280px;
    margin-bottom: 0.5rem;
    img {
      height: 100%;
    }
  }

  h2 {
    text-align: center;
    margin-bottom: 1rem;
  }

  > p {
    margin: 0.75rem 0 2rem;
  }

  > a {
    border: none;
    background: ${(props) => props.theme.color.primary};
    color: ${(props) => props.theme.color.default};
    max-height: 38px;
    padding: 7px 14px;
    font-size: 0.9rem;
    border-radius: 6px;
    text-decoration: none;

    svg {
      height: 16px;
      opacity: 0.85;
      margin-right: 0.5rem;
      margin-left: -5px;
      color: ${(props) => props.theme.color.default};
      vertical-align: bottom;
    }
  }
`;

interface Props {
  children: ReactNode;
}

interface State {
  hasError: boolean;
}

class ErrorBoundary extends Component<Props, State> {
  public state: State = {
    hasError: false,
  };

  public static getDerivedStateFromError(_: Error): State {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.error("Uncaught error:", error, errorInfo);
  }

  public render() {
    if (this.state.hasError) {
      return (
        <>
          {/* // <Wrapper direction='column' align='center' mt={2}> */}
          <div>
            <img src={uhOh} alt='Empty' />
          </div>
          <Typography component='h2'>
            Uh, oh!
            <br /> An error has occurred
          </Typography>
          <Button
            color='primary'
            size='small'
            onClick={() => window.location.reload()}
            contained
          >
            <Icon.RefreshCcw style={{ marginRight: 0 }} />
            Please refresh to continue
          </Button>
          {/* // </Wrapper> */}
        </>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
