import { HTMLAttributes } from "react";
import { jsx, css } from "@emotion/react";
import { theme } from "theme";

type TypographyVariant =
  | "title"
  | "subtitle"
  | "drawer-title"
  | "feed-title"
  | "body";

type TagName =
  | "h1"
  | "h2"
  | "h3"
  | "h4"
  | "h5"
  | "h6"
  | "p"
  | "span"
  | "b"
  | "small";

const style = {
  h1: css``,
  h2: css``,
  h3: css``,
  h4: css`
    font-size: 0.9rem;
    padding: 0.25rem 0;
  `,
  h5: css`
    font-size: 0.875rem;
  `,
  h6: css``,
  p: css`
    font-size: 0.875rem;
  `,

  span: css``,
  b: css`
    font-size: 0.875rem;
  `,
  small: css``,
};

const vairianStyle = (variant?: TypographyVariant) => {
  switch (variant) {
    case "title":
      return css``;

    case "drawer-title":
      return css`
        font-weight: bold;
        font-size: 1rem;
      `;

    case "feed-title":
      return css`
        font-weight: bold;
        font-size: 0.85rem;
      `;

    case "subtitle":
      return css`
        font-size: 0.875rem;
      `;

    default:
      return css``;
  }
};

const colorStyle = (color: ColorVariant) => `
  color: ${theme.color[color]};
`;

const Typography: React.FC<
  {
    component: TagName;
    variant?: TypographyVariant;
    color?: ColorVariant;
  } & HTMLAttributes<HTMLHeadingElement>
> = ({ component, children, variant, color = "dark", ...props }) =>
  jsx(
    component,
    {
      ...props,
      css: [style[component], vairianStyle(variant), colorStyle(color)],
    },
    children
  );

export default Typography;
