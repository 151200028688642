import * as Icon from "react-feather";
import Button from "./Button";

type ExportCSVButtonType = {
  color?: "grey" | "default" | "primary" | "secondary" | "dark" | "light";
  size?: SizeVariant;
  onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
};

const ExportCSVButton = ({
  color = "grey",
  size = "normal",
  onClick,
}: ExportCSVButtonType) => (
  <Button
    style={{ background: "transparent", border: 0 }}
    color={color}
    size={size}
    mr={1}
    onClick={onClick}
  >
    <Icon.DownloadCloud />
    Export CSV
  </Button>
);

export default ExportCSVButton;
