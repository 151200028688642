import styled from "@emotion/styled";
import { createPortal } from "react-dom";

import Toast from "./Toast";

const ToastContainerWrapper = styled("ul")<{ placing: string }>`
  position: fixed;
  bottom: ${(props) => (props.placing === "bottom" ? "1.5rem" : "unset")};
  top: ${(props) => (props.placing === "top" ? "1.5rem" : "unset")};
  list-style: none;
  width: 100%;
  display: flex;
  z-index: 1000;
`;

const ToastContainer: React.FC<{ toasts: Array<ToastType>; placing?: string }> =
  ({ toasts, placing = "bottom" }) =>
    createPortal(
      <ToastContainerWrapper placing={placing || "bottom"}>
        {toasts.map((toast) => (
          <Toast key={toast?.id} {...toast} />
        ))}
      </ToastContainerWrapper>,
      document.body
    );

export default ToastContainer;
