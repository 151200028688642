import React from "react";
import * as Icon from "react-feather";
import styled from "@emotion/styled";

import FlexWrapper from "components/ui/FlexWrapper";

const LocationWrapper = styled(FlexWrapper)`
  color: ${(props) => props.theme.color.grey};
  font-size: 0.875rem;
  > svg {
    height: 16px;
    opacity: 0.33;
    margin-right: 0.5rem;
  }
`;

const Location: React.FC<{ location: string }> = ({ location }) => {
  return (
    <LocationWrapper>
      <Icon.MapPin />
      {location}
    </LocationWrapper>
  );
};

export default Location;
